import React, { Component } from 'react';
import { Button, Card, Col, message, Popover, Row, Spin, Modal, Typography, Tooltip, List, Space, Checkbox, DatePicker, Form, InputNumber, Pagination } from 'antd'
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';
//componentes
import { IconDetails, IconCheckGreen, IconGoToLink, IconSave, IconCheckAll, IconCheckTree, IconEye, IconArrowBack, IconDelete } from '../../Widgets/Iconos';
import Logged from "../../../Hooks/Logged";
import Timer from '../../Widgets/Timer';
import { SyncOutlined    } from '@ant-design/icons';

//css
import 'react-multi-carousel/lib/styles.css';
import '../../../Styles/finanzas.css'
//modales
import ModalClasificarFact from '../Modales/Belvo/ModalClasificarFacturas';
import ModalXML from '../Modales/Belvo/ModalXML';


import "@paybook/sync-widget/dist/widget.css";
import SyncWidget from "@paybook/sync-widget";


var QRCode = require('qrcode.react');
const moment = require('moment');
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

export default class FacturasSC extends Component {
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            facturas: {
                data: [],
                page: 1,
                limit: 200,
                total: 0,
                pages: 0,

                filter: {}
            },
            selectedFacturas: {},
            cuenta_id: this.props.match.params.cuenta_id,
            cuenta: {},
            vista_guardadas: false,
            update: true,
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCuenta()


    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method getAccount
     * @description Obtenemos la información de la cuenta, espcificamente dos campos, los de syncfy
     */
    getCuenta = (id = this.props.match.params.cuenta_id) => {
        this.setState({ loading: true })
        return axios.get("/cuentas/get", { params: { id } })
            .then(({ data }) => {
                this.setState(
                    { cuenta: data.data },
                    () => {
                        this.setState({ loading: true })
                        this.props.setUserId(this.state.cuenta.syncfy_usuario_id)
                            .finally(() => {
                                this.getFacturas()
                            })
                    }
                )
            })
            .catch(res => {
                this.setState({ loading: false })
                message.error('Error al obtener la información de la cuenta.')
            })
    }



    getFacturas = () => {
        if (this.props.guardadas)
            this.getFacturasGuardada()
        else
            this.getFacturasSyncfy()

    }

    /**
    * @memberof FacturasSC

    * @description  Lista las facturas de una cuenta, en un rango de fechas
    **/
    getFacturasSyncfy = () => {
        this.setState({ loading: true })
        axios.get('/syncfy/facturas', {
            params: {
                cuenta_id: this.state.cuenta._id,
                page: this.state.facturas.page,
                limit: this.state.facturas.limit,
                fechas: (Array.isArray(this.state.facturas.filter.fechas)) ? this.state.facturas.filter.fechas.map(e => e.toDate()) : null
            }
        })
            .then(response => {
                this.setState(state => {
                    state.facturas.data = response.data.data.data
                    state.facturas.total = response.data.data.total
                    state.facturas.pages = response.data.data.pages
                    return state;
                })
                this.props.restartTimer()
            })
            .catch(error => {
                message.error("No es posible traer las facturas. ")

                if (error?.response?.data?.data?.nombre) {
                    Modal.warning({
                        title: error.response?.data?.data?.nombre,
                        content: error.response?.data?.data?.description,
                        onOk: (error.response?.data?.data?.nombre === "Unauthorized" || error.response?.data?.data?.nombre === "Invalidtoken") ? this.renderWidget() : undefined
                    })
                }


            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof FacturasSC

    * @description  Lista las facturas de una cuenta, en un rango de fechas
    **/
    getFacturasGuardada = () => {

        this.setState({ loading: true })
        axios.get('/syncfy/facturas/guardadas', {
            params: {
                cuenta_id: this.state.cuenta._id,
                skip: this.state.facturas.skip,
                page: this.state.facturas.page,
                limit: this.state.facturas.limit,
                fechas: (Array.isArray(this.state.facturas.filter.fechas)) ? this.state.facturas.filter.fechas.map(e => e.toDate()) : null
            }
        })
            .then(response => {
                this.setState(state => {
                    state.facturas.data = response.data.data.data
                    state.facturas.total = response.data.data.total
                    state.facturas.pages = response.data.data.pages
                    return state;
                })
            })
            .catch(error => {
                console.log('error', error)
                message.error("No es posible traer las facturas. ")
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof FacturasSC
    * @method   gurdarDespues
    * @description  Las Facturas seleccionadas la guarda el la coleccion de Guardadas, para clasificarlas despues
    **/
    guardar = () => {
        if(this.state.loading === true) return;
        this.state.loading = true
        let { selectedFacturas } = this.state
        let array_selec = Object.values(selectedFacturas)
        if (array_selec.length === 0) {
            message.error('No hay Facturas Seleccionadas')
            this.setState({loading: false})
        } else {
            this.setState({ loading: true })
            axios.post('/syncfy/facturas/guardar', {
                seleccionadas: array_selec,
                tipo_documento: 0, //transacciones
                cuenta_id: this.props.match.params.cuenta_id,
            })
                .then(response => {
                    this.setState({selectedFacturas: []})
                    this.getFacturas()
                    message.success('Facturas Guardadas para despues')
                })
                .catch(error => {
                    message.error('Error al guardar')
                })
                .finally(() => this.setState({ loading: false }))
        }
    }


    /**
    * @memberof FacturasSC
    * @description añade las facturas a clasificar
    **/
    facturaChecked = (factura) => {
        let { selectedFacturas } = this.state

        // item.id_transaction
        // let id = (factura.id_transaction) ?  : factura._id

        if (selectedFacturas[factura.id_transaction])
            delete selectedFacturas[factura.id_transaction]
        else
            selectedFacturas[factura.id_transaction] = factura


        this.setState({ selectedFacturas: { ...selectedFacturas } })
    }

    /**
     * @memberof FacturasSC
     * @description  Abre el modal para clasificar las facturas
     **/
    addFacturas = () => {
        let { selectedFacturas } = this.state
        selectedFacturas = Object.values(selectedFacturas)
        if (selectedFacturas.length === 0) {
            message.error('No hay Facturas Seleccionadas')
        } else this.setState({ modalClasFactVisible: true })
    }



    renderTipo = (value) => {
        value = Math.sign(value)
        switch (value) {
            case 1:
                return "Ingreso"
            case 0:
                return "Sin cambios"
            case -1:
                return "Egreso"
        }
    }


    millisToMinutesAndSeconds = (millis) => {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);

        if (millis === 1000 && this.state.update) { //cuando falte un segundo
            this.state.update = false
            setTimeout(() => {
                console.log('contador')
                this.props.startSession()
                this.state.update = true
            }, 1500)

        }

        if (millis === 1000 * 60 * 3 && this.state.update) {
            this.state.update = false
            message.warning('En 3 minutos se cerrará la sesión')
            setTimeout(() => { this.state.update = true }, 1500)
        }

        if (millis <= 1000 * 60 * 3)
            return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        else return ''

    }

    /**
     *
     *
     * @param {*} item
     * @memberof FacturasSC
     * 
     * @description 
     */
    renderViews = (item) => {

        if (item.xml)
            return <Button
                type="primary"
                className="button-survey btn-eye button-eye"
                onClick={(e) => this.setState({ xml: item.xml, ModalXMLVisible: true })}
                title="Ver XML">
                <IconEye />
            </Button>



        let
            attachments = [],
            xml = false,
            pdf = false

        if (Array.isArray(item.attachments)) {
            for (const attachment of item.attachments) {

                console.log(attachment.mime)
                if (/xml/.test(attachment.mime)) {
                    attachments.push(<Button
                        type="primary"
                        className="button-survey btn-eye button-eye"
                        onClick={(e) => this.setState({ factura_xml: attachment, ModalXMLVisible: true })}
                        title="Ver XML">
                        <IconEye />
                    </Button>)
                    xml = true
                }

                if (/pdf/.test(attachment.mime)) {
                    attachments.push(<Button
                        type="primary"
                        className="button-survey btn-eye button-eye"
                        onClick={(e) => window.open(axios.defaults.baseURL + '/syncfy/facturas/attachment?id_attachment=' + attachment.attachment_id, "_blank")}
                        title="Ver PDF">
                        <IconEye />
                    </Button>)
                    pdf = false

                }


            }
            return attachments
        }
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof TransaccionesSC
     * 
     * @method renderWidget
     * @description En caso de que sea invalido, renderiza el widget.
     */
    renderWidget = (credential = this.state.cuenta.syncfy_credenciales_id) => {
        this.setState({ loadingRender: true })
        if (sessionStorage.getItem('syncfy') && sessionStorage.getItem('syncfy') !== null) {
            if (!window.syncWidget)
                window.syncWidget = new SyncWidget({
                    // Set up the token you created in the Quickstart:
                    token: sessionStorage.getItem('syncfy'),
                    config: {
                        // Set up the language to use:
                        locale: 'es',
                        navigation: {
                            displayStatusInToast: true,
                        },

                        ...(credential ? {
                            entrypoint: {
                                credential
                            },
                        } : {

                        })
                    },
                })
            else {
                window.syncWidget.setConfig({
                    ...(credential ? {
                        entrypoint: {
                            credential
                        },
                    } : {

                    })
                })
                window.syncWidget.setToken(sessionStorage.getItem('syncfy'))
            }


            const defaultFunction = () => {
                this.setState({ loadingRender: false })
                this.getFacturas()
            }
            window.syncWidget.$on("error", defaultFunction);
            window.syncWidget.$on("success", defaultFunction);
            window.syncWidget.$on("closed", () => {
                Modal.warning({
                    title: "Al cerrar, no se actualizarán las credenciales.",
                    content: "Si cierras este modal, no será posible obtener los datos mas recientes de la cuenta.",
                    onOk: () => defaultFunction()
                })
            });
            window.syncWidget.$on("api-error", defaultFunction);


            if (!credential)
                window.window.syncWidget.open()

            return
        }
        return Modal.error({
            title: "Debe seleccionar un Usuario de Syncfy para poder generar las credenciales."
        })
    }


    render() {
        const { facturas } = this.state

        return (
            <div style={{ marginBottom: '120px' }}>
                <Row className="row-encabezado">
                    <Col xs={24} xl={6} xxl={6} className="">
                        <h2>{(this.props.guardadas === true) ? "Facturas Guardadas" : "Facturas Sin Clasificar"}</h2>
                    </Col>
                    <Col xs={24} xl={17} xxl={17} className="center">
                        <Form
                            noStyle
                            // valuesAll
                            onValuesChange={(value, allValues) => this.setState(state => this.setState(state => {
                                state.facturas.filter = allValues
                                state.facturas.page = 1
                                return state
                            }, () => this.getFacturas()))}
                            style={{ width: '100%', position: 'relative', left: '-10px' }}
                        >
                            <Space style={{ float: "right" }}>
                                <Form.Item
                                    name="fechas"
                                    noStyle>
                                    <RangePicker
                                        placeholder={['Inicio', 'Fin']}
                                        className="input-picker"
                                        style={{
                                            background: 'transparent !important',
                                            color: '#FFF',
                                            float: 'right',
                                            width: '100%'
                                        }} />
                                </Form.Item>
                                {(this.props.guardadas !== true) ? <Tooltip title="Actualizar las credenciales de Syncfy.">
                                    <Button type="link" style={{ border: '1px solid white', borderRadius: 2 }} disabled={this.state.loadingRender} icon={<SyncOutlined spin={this.state.loadingRender} style={{ color: "white" }} />} onClick={() => this.renderWidget()} />
                                </Tooltip> : null}

                            </Space>
                        </Form>
                    </Col>
                    <Col>
                        {(this.props.guardadas === true) ? null :<Timer timerCount={this.props.timerCount} startSession={this.props.startSession} />}
                    </Col>
                </Row>

                <section className="p-1">
                    <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles">
                        <Col span={6} className="center">
                            <div className="filter-yellow">
                                Facturas
                                <div className="divider ml-1"></div>
                                <Button type="link" className="btn-transparent ml-1" onClick={this.selectAll}>
                                    <IconCheckAll />
                                </Button>
                            </div>
                        </Col>
                        <Col span={6} className="center">
                            <Text type="secondary">EMISOR</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">FECHA</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">MONTO</Text>
                        </Col>
                        <Col span={4} className="center">
                            Acciones
                        </Col>

                    </Row>
                    <div className="card">
                        <List
                            // style={{ marginBottom: 80 }}
                            className="component-list"
                            itemLayout="horizontal"
                            loading={this.state.loading}
                            dataSource={this.state.facturas.data}
                            locale={{
                                emptyText: <div>
                                    No hay Facturas registradas
                                    <br></br>
                                </div>
                            }}

                            pagination={{
                                onChange: (page, limit) => this.setState(state => {
                                    state.facturas.page = page;
                                    state.facturas.limit = limit;
                                    return state;
                                }, () => this.getFacturas()),


                                pageSizeOptions: [10, 20, 50, 100],
                                showSizeChanger: true,

                                pageSize: this.state.facturas.limit,
                                total: this.state.facturas.total,
                                current: this.state.facturas.page,



                                hideOnSinglePage: false,
                                showQuickJumper: false,
                                className: "pagination-list"
                            }}
                            renderItem={item => (
                                <List.Item className="component-list-item-small">
                                    <Card className="card-list-small">
                                        <Row style={{ width: '100%' }}>
                                            <Col span={2} className="center">
                                                <Checkbox className="radio-yellow"
                                                    checked={this.state.selectedFacturas[item.id_transaction]}
                                                    onChange={() => this.facturaChecked(item)}></Checkbox>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{this.renderTipo(item.amount)}</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <p style={{ width: '100%' }}>
                                                    <strong>{item.description}</strong><br />
                                                    <small>{item?.extra?.tax_id}</small>
                                                </p>
                                            </Col>
                                            <Col span={4} className="center">
                                                {/* moment.unix('1634256153'). */}
                                                <Text strong>{moment.unix(item.dt_transaction).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>$ {item.amount ? item.amount.toMoney() : '0.00'} <small>{item.currency}</small></Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                {this.renderViews(item)}
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </div>

                </section>
                <ModalClasificarFact
                    visible={this.state.modalClasFactVisible}
                    cuenta_id={this.props.match.params.cuenta_id}
                    onClose={() => this.setState({ modalClasFactVisible: false })}
                    onAccept={() => {
                        this.getFacturas();
                        this.setState({ modalClasFactVisible: false })
                    }}
                    selectedFacturas={this.state.selectedFacturas}
                />
                <ModalXML
                    visible={this.state.ModalXMLVisible}
                    closeMethod={() => this.setState({ ModalXMLVisible: false, factura_xml: undefined, xml: undefined })}
                    factura_xml={this.state.factura_xml}
                    xml={this.state.xml}

                />
                <Button className="btnCheckGreen" title="Clasificar Facturas" disabled={this.state.loading} onClick={() => this.addFacturas()} >
                    <IconCheckGreen />
                </Button>
                {(this.props.guardadas === true) ? null : <Button className="btnSaveBlue" disabled={this.state.loading} onClick={this.guardar}>
                    <IconSave />
                </Button>}
            </div>
        )
    }
}


