import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Carousel, Col, Form, Input, message, Modal, Row, Spin, Typography, Layout, Checkbox, AutoComplete } from 'antd';

import '../../Styles/usuarios.css';
import '../../Styles/login.css';


import Cookies from 'universal-cookie';

const axios = require('axios').default;

// axios.defaults.headers.post()

const { Title } = Typography;
const { Content } = Layout;

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,

            isRecovery: false,
            isRecoveryLoading: false,
            redirect: false,
            form: {}
        }
    }

    recoveryPassword = React.createRef();

    redirectTo = "/login"

    async componentDidMount(): void {
        const {token, email} = this.props.match.params;
        if (token === undefined || email === undefined){
            this.redirectTo = "/login";
            this.setState({redirect: true})
        }else{
            await this.setState({
                form: {email, token}
            })
            this.recoveryPassword.current.resetFields();
        }
    }


    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.redirectTo} />;
        }
    };

    onFinish = async values => {
        axios.put('/password/recovery/update',values)
            .then(()=>{
                this.to = '/login';
                this.setState({
                    redirect: true
                })

            })
            .catch(() => {
                Modal.error({
                    title: "No fue posible actualizar.",
                    content: "El token es invalido o el correo no existe."
                })
            })
    };


    render() {
        return (
            <Content className="login">
                {this.renderRedirect()}
                <Row align="center" style={{ width: '100%', maxWidth: '1650px', margin: 'auto', paddingTop: '20px' }}>
                    <Col xs={22} sm={20} md={18} lg={{ span: 8, pull: 0 }} xl={{ span: 8, pull: 1 }} xxl={{ span: 8, pull: 1 }} style={{ paddingTop: '30px' }}>

                        <Row style={{ marginBottom: '50px' }}>
                            <img width='100%' style={{ maxWidth: '400px' }} src="/images/logo-login.png" />
                        </Row>

                        <h1 className="titulo-login">{(this.props.title !== undefined)?this.props.title:'Inicia Sesión'}</h1>
                        <Form
                            name="basic"
                            onFinish={this.onFinish}
                            className="login-form"
                            initialValues={this.state.form}
                            ref={this.recoveryPassword}

                        >

                            <Form.Item
                                name="token"
                                noStyle
                            >
                                <Input type="hidden" />
                            </Form.Item>

                            <Form.Item
                                label="Correo Electrónico"
                                name="email"
                                rules={[{
                                    required: true,
                                    message: 'Para poder iniciar sesión, debe haber ingresado su correo electrónico'
                                }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor, ingrese la contraseña.',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor, confirme su contraseña.!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Las contraseñas no coinciden.');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item style={{ marginTop: '50px' }}>
                                <Button type="primary" htmlType="submit" className="btn-morado">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col xs={22} sm={20} md={18} lg={{ span: 15, push: 0 }} xl={{ span: 13, push: 0 }} xxl={{ span: 10, push: 1 }} style={{ paddingTop: '30px', height: '100%' }} className="cnt-imagen-login">
                        <div className="contenedor-login" align="center">
                            <img src="/images/GM-logo-blanco.png" width="50%" alt="" />
                        </div>
                    </Col>

                </Row>
            </Content>
        )
    }
}

