import React, { Component } from 'react';
import { Row, Col, Card, Table, Button, Typography, Popconfirm, Menu, DatePicker, message, Form, Checkbox, Dropdown, Statistic } from 'antd'
import { Link } from 'react-router-dom';

//componentes
import { FileTextOutlined } from "@ant-design/icons";
import { IconArrow as Arrow, IconPlus, IconBars, IconEdit, IconDelete, IconDownloadCustom as Download, IconDeudas } from '../../Widgets/Iconos';


import { CardInfoDashboard, CardInfo } from '../../Widgets/Cards/Cards';


import DeudasChart from './Widgets/DeudasChart';
import InversionesChart from './Widgets/InversionesChart';

//css
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import "../../../Styles/HealthFinance.css";

const { Text, Title } = Typography;

const moment = require('moment');
const axios = require('axios').default
const { RangePicker } = DatePicker;

axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


//Variables
export default class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: []
        }
    }

    componentDidMount(){
        this.getInfo()
    }

    getInfo = () => {
        axios.get('/inversiones/dashboard',{})
        .then(response => {
            console.log(response.data.data)
            this.setState({
                inversiones_total: response.data.data.inversiones_total.total_inversion,
                inversiones_nombre: response.data.data.inversiones_total.inversiones?.map(inv => inv.nombre),
                inversiones_monto: response.data.data.inversiones_total.inversiones?.map(inv => inv.monto),
                acreedores_nombre: response.data.data.acreedores_total.acreedores.map(ac => ac.nombre),
                acreedores_monto: response.data.data.acreedores_total.acreedores.map(ac => ac.monto),
                acreedores_total: response.data.data.acreedores_total.total_inversion,

            })

        }).catch(error => {
            console.log(error)
        })
    }


    render() {
        return (
            <div className="financial-health">
                <Row className="row-encabezado">
                    <Col xs={24} xl={16} xxl={18}>
                        <h2>Financial Health</h2>
                    </Col>

                    <Col xs={24} xl={8} xxl={6}>
                        <Row style={{ width: '100%', paddingTop: '12px' }} gutter={[10, 10]}>


                            {/*Proyecto*/}
                            <Col className="row-filter" xl={10} lg={10} md={11} sm={12} xs={12} >
                                <Dropdown
                                    className="filter-element"
                                    overlay={
                                        <Menu>
                                            {this.state.projects.map(({ _id, nombre, logo, color }, index) => (
                                                <Menu.Item key={index}>
                                                    <Checkbox>
                                                        <CustomAvatar
                                                            image={logo}
                                                            name={nombre}
                                                            color={color}
                                                            size="small"
                                                            style={{
                                                                margin: '0 5px'
                                                            }}
                                                        />
                                                        {nombre}
                                                    </Checkbox>
                                                </Menu.Item>
                                            ))}

                                        </Menu>
                                    }>
                                    <p>Proyectos <Arrow className="arrow" /></p>
                                </Dropdown>
                            </Col>

                            {/*Reporte*/}
                            <Col className="row-filter" span={1}>
                                <Button disabled={!this.state.datosConsultados} style={{
                                    background: "transparent", border: "none"
                                }} title="Reporte">
                                    <Download />

                                </Button>
                            </Col>

                        </Row>
                    </Col>
                </Row>

                <section style={{ margin: 24 }}>

                    <Row style={{ width: '100%', marginTop: 24  }} gutter={[24, 24]}>
                        <Col xs={24} lg={12} xl={8}>
                            <Link to={'/admin/financial-health/inversiones'}>
                                <CardInfoDashboard
                                    text="card-info-dsh-fh"
                                    title='Inversiones Totales'
                                    textAlign={false}
                                    hover={true}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Col span={16} >
                                            <Statistic prefix={'$'} value={this.state.inversiones_total} precision={2} suffix={<small>MXN</small>} className="statistic-financial" />
                                        </Col>
                                        <Col span={8}>
                                            <IconBars style={{ maxWidth: '100%' }} />
                                        </Col>

                                    </Row>
                                </CardInfoDashboard>
                            </Link>
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                            <Link to={'/admin/acreedores'}>
                                <CardInfoDashboard
                                    text="card-info-dsh-fh"
                                    title='Deudas Totales'
                                    textAlign={false}
                                    hover={true}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Col span={16} >
                                            <Statistic prefix={'$'} value={this.state.acreedores_total} precision={2} suffix={<small>MXN</small>} className="statistic-financial" />
                                        </Col>
                                        <Col span={8}>
                                            <IconDeudas style={{ maxwidth: '100%', width: '100%', height: '180px' }} />
                                        </Col>

                                    </Row>
                                </CardInfoDashboard>
                            </Link>
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                            <CardInfoDashboard
                                title={<Row style={{ width: '100%', marginTop: '0.5em' }} gutter={[20,20]}>
                                    <Col span={15}>   
                                        New Worth
                                    </Col>
                                    <Col span={9}>
                                        <img src="/images/GM-logo-blanco-lineas.png" style={{ width: '100%' }} />
                                    </Col>
                                </Row>}
                                background='linear-gradient(89.47deg, #3988D0 0.39%, #0066FF 126.36%)'
                                textAlign={false}
                                style={{height: '100%'}}
                            >
                                <Statistic prefix={'$'} value={this.state.inversiones_total - this.state.acreedores_total } precision={2} suffix={<small>MXN</small>} className="statistic-financial net-worth" />
                            </CardInfoDashboard>
                        </Col>
                    </Row>
{/* 
                    import DeudasChart from './Widgets/DeudasChart';
import InversionesChart from './Widgets/InversionesChart'; */}
                    <Row>
                        <Col span={24}>
                            <Card className="info-card-fh">
                                <InversionesChart  inversiones_nombre={this.state.inversiones_nombre} inversiones_monto={this.state.inversiones_monto}/>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card className="info-card-fh">
                                <DeudasChart acreedores_nombre={this.state.acreedores_nombre} acreedores_monto={this.state.acreedores_monto}/>
                            </Card>
                        </Col>
                    </Row>
                </section>
                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                    <IconPlus />
                </Button>
            </div>
        )
    }
}
