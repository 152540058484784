import React, { Component } from 'react';
import { Button, Popconfirm, Space, Row, List, Col, Spin, PageHeader, Card, Layout, message, Typography, Avatar, Collapse } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import { IconPlus } from '../../Widgets/Iconos';
import InfiniteScroll from "react-infinite-scroll-component";
import { MinusOutlined, PlusOutlined, FilterOutlined } from '@ant-design/icons';
//Componentes
import VerDetalle from '../../Widgets/VerDetalle';
import DrawerFiltros from "./DrawerFiltros";
import { IconDelete, IconMoney, IconEdit } from '../../Widgets/Iconos';
//modales
import ModalPrecio from "./ModalPrecio.js";
import ModalProductos from "./ModalProductos.js";
//css
import '../../../Styles/productos.css'

const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography

/**
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista de Productos
 */
export default class Productos extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			modalVisible: false,

			data: [],
			page: 1,
			limit: 50,
			total: 0,
			pages: 0,

			filtroSearch: '',
			searching: true,
			producto_code: '00000',
			hasMore: false,
			productos: {
			},

			filtros: {}
		}
	}

	componentDidMount() {
		//Mostrar input superior de busqueda 
		//Para acceder al valor del input = this.props.search
		this.props.updateFilterSearch(true);

		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		window.scrollTo(0, 0)
		this.getProductos();
	}


	/**
	 * @method componentDidUpdate
	 * @description Actualiza la vista si se buscan datos
	 */
	componentDidUpdate(prevProps) {

		const filtro = this.state.filtroSearch;
		const propFiltro = this.props.search;

		if (filtro !== propFiltro && this.state.searching == true) {
			this.getProductos();
			this.state.searching = false;
		}
	}



	/**
	 *
	 *
	 * @memberof Productos
	 * @description Obtiene la lista de productos agrupados por sku
	 */
	getProductos = ({
		filtros = this.state.filtros
	} = this.state) => {

		this.setState({ loading: true, })
		axios.get('/productos', {
			params: {
				page: 1,
				limit: this.state.limit,
				search: this.props.search,

				groupBySKU: true, //agrupados por producto padre
				producto_code: true, //codigo para el nuevo producto
				...filtros
			}
		}).then(({ data }) => {
			this.setState(state => {
				state.data = data.data.data
				state.total = data.data.total
				state.page = data.data.page
				state.searching = true
				state.filtroSearch = this.props.search
				state.producto_code = data.producto_code
				state.hasMore = data.data.data.length < data.data.total
				state.filtros = filtros
				return state;
			})
		})
			.catch(error => {
				message.error('Error al traer la información')
			}).finally(() => this.setState({ loading: false }))
	}

	/**
	 *
	 *
	 * @memberof Productos
	 * @description Obtiene la siguiente pagina de datos al scrollear hasta abajo
	 */
	getMore = ({
		filtros = this.state.filtros
	} = this.state) => {

		if(this.state.total > this.state.data.length){
			this.setState({ loading: true, })
			axios.get('/productos', {
				params: {
					page: this.state.page + 1,
					limit: this.state.limit,
					search: this.props.search,

					groupBySKU: true, //agrupados por producto padre
					producto_code: true, //codigo para el nuevo producto
					...filtros
				}
			}).then(({ data }) => {
				let arr = [...this.state.data, ...data.data.data]

				this.setState({
					data: arr,
					total: data.data.total,
					page: data.data.page,
					hasMore: arr.length < data.data.total,
					filtros: filtros,
				})
				
			})
				.catch(error => {
					console.log("error", error);
					message.error('Error al traer la información')
				}).finally(() => this.setState({ loading: false }))
			}
	}


	showModal = (producto_id = undefined) => this.setState({ modalProductos: true, producto_id })


	render() {

		const { showModal } = this

		const st = {
			color: '#ffffff',
			fontSize: 16,
			position: "absolute",

		}

		return (
			<div>
				<Spin spinning={false}>
					<Row className="row-encabezado">
						<Col xs={20} md={12} xl={18} xxl={18} className=" flex-left">
							<Title level={4}className="view-title">Productos</Title>
						</Col>


						<Col xs={4} md={6} xl={2} xxl={1} className="center">
	                        <Button
	                            style={{ backgroundColor: '#007BFF'}}
	                            onClick={() => this.setState({ visibleFilters: true })}
	                            icon={<FilterOutlined style={{ color: '#fff', fontWeight: 600 }} />}
	                        />
	                    </Col>

						<Col xs={24} md={6} xl={4} xxl={5} className="  flex-right">
							<Row align="right" style={{ textAlign: "right", display: "block" }}>
								<Title level={5} style={{ textAlign: "right", color: "#FFF", marginRight: '15px', marginTop: '7px', fontSize: '11pt' }}>{this.state.total} Productos</Title>
							</Row>
						</Col>
					</Row>
					<Content className="admin-content content-bg p-1">

						<Spin spinning={this.state.loading}>
					    <InfiniteScroll
					    	dataLength={this.state.data.length}
					    	next={this.getMore}
					    	hasMore={this.state.hasMore}
					    	loader={<Spin tip="Cargando..."><h4 style={{height: '100px'}}></h4></Spin>}
          					height={1000}
					    >
					        {this.state.data.map(item => <Collapse
 								bordered={false}
								defaultActiveKey={['1']}
								expandIcon={({ isActive }) => isActive ? <MinusOutlined className="icon-pro" /> : <PlusOutlined className="icon-pro" />}
								className="collapse-productos"
							>
								<Panel
									className='collapse-list'
									extra={
										<Row className="width-100 header-collapse" style={{width: '100%'}}>
											<Col xs={6} className="flex-left">
											{ (Array.isArray(item.producto?.imagenes) && item?.producto?.imagenes.length  > 0) ? 
												
												<Avatar style={{ marginRight: 10 }} shape="square" src={axios.defaults.baseURL + "/upload/" + item?.producto?.imagenes[0].filename } size={60} /> : null
											}
												<Text className="collapse-text-big">{item?.producto?.nombre} <small>{item?.producto?.sku_fabricante}</small></Text>
											</Col>
											<Col xs={4} className="flex-column">
												<Text>SKU Grupo Manantial</Text>
												<Text className="collapse-text-big">{item?.producto?.sku_manantial}</Text>
											</Col>
											<Col xs={4} className="flex-column">
												<Text>Fabricante</Text>
												<Text className="collapse-text-big">{item?.producto?.fabricante}</Text>
											</Col>
											<Col xs={4} className="flex-column">
												<Text>Categoria</Text>
												<Text>{item?.categoria_id?.nombre}</Text>
											</Col>
											<Col span={4} className="flex-column">
												<Text>Sub Categoria</Text>
												<Text>{item?.sub_categoria_id?.nombre}</Text>
											</Col>
											<Col span={2} className="flex-column">
												<Text>Productos</Text>
												<Text>{item?.count}</Text>
											</Col>
										</Row>
									}
								>
									<Row className="header-list width-100 pl-1 pr-1" >

										<Col xs={4} className="center">
											<Text strong>Proveedor</Text>
										</Col>
										<Col xs={4} className="center">
											<Text strong>SKU Proveedor</Text>
										</Col>
										<Col xs={10} className="center">
											<Text strong>Descripción</Text>
										</Col>
										<Col xs={3} className="center">
											<Text strong>Precio</Text>
										</Col>
										<Col xs={3} className="center">
											<Text strong>Acciones</Text>
										</Col>
									</Row>
									{item.productos?.map(producto => {
										return <Card className="card-list" key={item?._id}w><Row className="width-100">
											<Col xs={4} className="center">
												<Text>{producto.proveedor.alias}</Text>
											</Col>
											<Col xs={4} className="center">
												<Text>{producto.sku_proveedor}</Text>
											</Col>
											<Col xs={10} className="flex-left">
												<VerDetalle text={producto.descripcion}/>
											</Col>
											<Col xs={3} className="center">
												<Text>$ {producto.costo.toMoney()}</Text>
											</Col>
											<Col xs={3} className="center">
												<Space>
													<Button 
														className="btn btn-money" 
														type="primary"  
														icon={<IconMoney />} 
														onClick={() => this.setState({modal_precio: true, producto_opcion_id: producto._id})} 
													/>
													<Button className="btn-edit" type="primary" icon={<IconEdit />} onClick={() => showModal(producto._id)} />
													<Popconfirm
														placement="topRight"
														title="¿Deseas eliminar este Producto?"
														onConfirm={() => axios.delete('/producto/delete', { params: { id: producto._id } })
															.then((response) => {
																message.success("Eliminado Correctamente")
																this.getProductos()
															})
															.catch((error) => {
																message.error("No es posible eliminar")
																this.getProductos(this.state.page);
															})
														}
														okText="Si"
														cancelText="No"
													>
														<Button type="primary" className="btn-delete"  danger icon={<IconDelete />} title="Eliminar" />
													</Popconfirm>
												</Space>
											</Col>
										</Row>
									</Card>
									})}
								</Panel>
							</Collapse>)}
					    </InfiniteScroll>
					    </Spin>

						{/* <FloatingButton title="Nuevo registro" onClick={() => showModal()} /> */}
					</Content>
					<ModalProductos
						id={this.state.producto_id}
						visible={this.state.modalProductos}
						onCancel={() => {
							this.setState({ modalProductos: false, producto_id: undefined })
							this.getProductos(this.state.page)
						}}
						producto_code={this.state.producto_code}
					/>

					<ModalPrecio
						visible={this.state.modal_precio}
						producto_opcion_id={this.state.producto_opcion_id}
						onCancel={()=>{
							this.setState({modal_precio: false, producto_opcion_id: undefined})
							this.getProductos(this.state.page)
						}}
					/>					
				</Spin>

				<DrawerFiltros
                    title="Filtrar Productos"
                    ref={ref => this.drawerFiltros = ref}
                    updateFilters={filtros => {
                    	this.getProductos({
                    		filtros
                    	})
                    }}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}

                />

				{(this.state.tipo_user !==3) ?
                    <Button className="btnAddPlus" title="Nuevo registro" onClick={() => showModal()} >
                        <IconPlus />
                    </Button>
                    : null}
			</div>
			
		)
	}
}