import React, { Component } from 'react';
import { Button, Card, Col, message, Popover, Row, Spin, Modal, Typography, Tag, List, Radio, Checkbox, DatePicker, Form, InputNumber, Popconfirm } from 'antd'
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';
//componentes
import { CardTransaccionesSC } from '../../Widgets/Cards/Cards';
import { IconDetails, IconCheckGreen, IconGoToLink, IconCheckAll, IconCheckTree, IconEye, IconArrowBack, IconDelete } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import ValidaciónMFA from "../../Admin/Modales/Belvo/ValidaciónMFA";
import Logged from "../../../Hooks/Logged";
import FormularioValidacion from '../../Widgets/FormularioValidacion';


//css
import 'react-multi-carousel/lib/styles.css';
import '../../../Styles/finanzas.css'
//modales
import ModalClasificarFact from '../Modales/Belvo/ModalClasificarFacturas';
import ModalXML from '../Modales/Belvo/ModalXML';




var QRCode = require('qrcode.react');
const moment = require('moment');
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

export default class FacturasSC extends Component {
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            facturas: [],
            page: 1,
            size: 50,
            modalClasFactVisible: false,
            selectedFacturas: [],
            facturas_ids: [],
            fecha_inicio: moment('20210804').format('YYYY-MM-DD'),
            fecha_fin: moment().format('YYYY-MM-DD'),
            whit_payments: 0,
            belvo_facturas: this.props.match.params.id ? this.props.match.params.id : "",
            account_id: this.props.match.params.account_id ? this.props.match.params.account_id : "",
            factura_xml: undefined,
            vista_guardadas: false,
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


        console.log('this.props.guardadas', this.props.guardadas);
        if (this.props.guardadas === true) {
            this.getGuardadas()
        } else {
            this.getFacturas()
        }

    }

    /**
    * @memberof FacturasSC
    * @method   componentDidMount
    * @description  Cierra el modal y actualiza la informacion
    **/
    hideModal = (actualizar) => {
        this.setState({ modalClasFactVisible: false, ModalXMLVisible: false, factura_xml: undefined })
        if (actualizar) {
            if(this.state.vista_guardadas){
                this.getGuardadas()
            }else{
                this.getFacturas()
            }
            this.setState({ selectedFacturas: [], whit_payments: 0 })
        }
    }

    /**
    * @memberof FacturasSC
    * @method   componentDidMount
    * @description  Lista las facturas de una cuenta, en un rango de fechas
    **/
    getFacturas = () => {
        this.setState({ loading: true })
        axios.post('/belvo/cuenta/facturas', {
            belvo_facturas: this.state.belvo_facturas,
            account_id: this.state.account_id,
            fecha_inicio: this.state.fecha_inicio,
            fecha_fin: this.state.fecha_fin,
            page: this.state.page,
            size: this.state.size,
            clasificadas: this.props.clasificadas
        })


            .then(response => {

                this.setState({
                    facturas: response.data.data.facturas,
                    total: response.data.data.total
                })

            })
            .catch(response => {

                if(response.response?.status === 401)
                    message.error(response.response?.data?.message)
                else
                    ValidaciónMFA({
                        error: response?.response,
                        onOk: () => this.getFacturas(),
                        setLoading: loading => this.setState({ loading }),                   
                        onDoesNotExist: () => this.props.history.push('/admin/cuentas/belvo/' +  this.state.belvo_facturas + '/register' ), //Aqui si ocupa la cuenta_id por que se va a regenerar un link, ya que no existe en Belvo                    
                        onIsInvalid: () => this.props.history.push('/admin/cuentas/belvo/' +  this.state.belvo_facturas + '/update' )//Aqui no se ocupa mandar cuenta_id, por que el token solo ocupa actualizarse
                    })

            })
            .finally(() => { this.setState({ loading: false }) })
    }

    /**
    * @memberof FacturasSC
    * @method   componentDidMount
    * @description añade las facturas a clasificar
    **/
    facturaChecked = (factura) => {
        const { selectedFacturas, whit_payments } = this.state;
        let index = selectedFacturas.findIndex(fact => fact.id === factura.id)

        if (index === -1) {
            selectedFacturas.push(factura)

        } else {
            selectedFacturas.splice(index, 1)
        }



        this.setState({ selectedFacturas: selectedFacturas })

    }

    /**
   * @memberof FacturasSC
   * @method   componentDidMount
   * @description  Abre el modal para clasificar las facturas
   **/
    addFacturas = (factura) => {
        let { selectedFacturas } = this.state
        if (selectedFacturas.length === 0) {
            message.error('No hay Facturas Seleccionadas')
        } else this.setState({ modalClasFactVisible: true })

    }

    /**
    * @memberof FacturasSC
    * @method   isSelected
    * @description  retorna true o false si la factura esta en el arreglo de seleccionadas
    **/
    isSelected = (item) => {
        const { selectedFacturas } = this.state;
        let index = selectedFacturas.findIndex(s => s.id === item.id)
        if (index !== -1) return true
        else return false

    }

    /**
    * @memberof FacturasSC
    * @method   selectAll
    * @description  Selecciona todas las facturas faltantes, o las quita todas
    **/
    selectAll = () => {
        let { selectedFacturas, facturas } = this.state;
        if (selectedFacturas.length < facturas.length) {
            this.setState({ selectedFacturas: [...facturas] })
        } else {
            this.setState({ selectedFacturas: [], whit_payments: 0 })
        }


    }

    /**
    * @memberof FacturasSC
    * @method   gurdarDespues
    * @description  Las Facturas seleccionadas la guarda el la coleccion de Guardadas, para clasificarlas despues
    **/
    gurdarDespues = () => {
        let { selectedFacturas } = this.state
        
        if (selectedFacturas.length === 0) {
            message.error('No hay Facturas Seleccionadas')
        } else {
            axios.post('/belvo/guardar/despues',{
                seleccionadas: selectedFacturas,
                tipo_documento: 1, //facturas
                belvo_facturas: this.state.belvo_facturas,
            }).then(response => {
                message.success('Facturas Guardadas para despues')
                this.getFacturas()
            }).catch(error => {
                message.error('Error al guardar')
            })
        }
    }

    /**
    * @memberof FacturasSC
    * @method   getGuardadas
    * @description  Obtiene las facturas guardadas para clasificar despues
    **/
    getGuardadas = () => {
        this.setState({loading: true, vista_guardadas: true})
        axios.get('/belvo/guardadas',{
            params:{
                tipo_documento: 1,
                belvo_facturas: this.state.belvo_facturas,
            }
        }).then(response => {
            console.log(response.data)
            this.setState({
                selectedFacturas: [],
                page: response.data.data.currentPage,
                size: 10,
                total: response.data.data.itemCount,
                facturas: response.data.data.itemsList,
            })
        }).catch(error => {
            console.log(error)
        }).finally(()=>{this.setState({loading: false})})
    }

    /**
    * @memberof FacturasSC
    * @method   deleteGuardados
    * @description  Elimina de Guardados
    **/
    deleteGuardados = (id) => {
        this.setState({loading: true, vista_guardadas: true})
        axios.post('/belvo/guardadas/delete',{
            _id: id
        }).then(response => {
            message.success('Eliminado de Guardados')
            this.getGuardadas()
        }).catch(error => {
            console.log(error)
        }).finally(()=>{this.setState({loading: false})})
    }


    render() {

        const { vista_guardadas } = this.state

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} md={12} style={{display: 'flex', alignItems: 'center'}}>
                        {vista_guardadas ? <Button className="IconArrowBack" onClick={()=>this.setState({vista_guardadas: false},()=>this.getFacturas())}>
                            <IconArrowBack />
                        </Button>: null}
                        <h2>{(this.props.clasificadas === true) ? "Facturas Clasificadas" : "Facturas Sin Clasificar"}</h2>
                    </Col>
                    <Col xs={24} md={12} className="center">
                        {vista_guardadas === false && this.props.clasificadas === false ? <Button onClick={()=>{this.gurdarDespues()}}>
                            Guardar
                        </Button>:null}
                        {vista_guardadas === false && this.props.clasificadas === false ? <Button className="ml-1" onClick={()=>{this.getGuardadas()}}>
                            Ver Facturas Guardadas
                        </Button>: null }
                    </Col>
                </Row>

                <section className="p-1">
                    <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles">
                        <Col span={5} className="center">
                            <div className="filter-yellow">
                                Facturas
                                {this.props.clasificadas === false ? <>
                                    <div className="divider ml-1"></div>
                                    <Button className="btn-transparent ml-1" onClick={this.selectAll}>
                                        <IconCheckAll />
                                    </Button>

                                </> : null}


                            </div>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">EMISOR</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">RECEPTOR</Text>
                        </Col>
                        <Col span={2} className="center">
                            <Text type="secondary">FECHA</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">MONTO</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">IVA</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">TOTAL</Text>
                        </Col>

                    </Row>
                    <div className="card">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            loading={this.state.loading}
                            dataSource={this.state.facturas}
                            pagination={{
                                current: this.state.page,
                                total: this.state.total,
                                pageSize: this.state.size,
                                onChange: (page) => {
                                    this.setState({ page: page }, () => { this.getFacturas() })
                                }
                            }}
                            locale={{
                                emptyText: <div>
                                    No hay Facturas registradas del <strong>{moment('20210804').format('LL')}</strong> al <strong>{moment().format('LL')}</strong>
                                    <br></br>
                                </div>
                            }}
                            renderItem={item => (
                                <List.Item className="component-list-item-small">
                                    <Card className="card-list-small">
                                        <Row style={{ width: '100%' }} className="">
                                            {this.props.clasificadas === false ? <Col span={1} className="center">
                                                <Checkbox className="radio-yellow"
                                                    checked={this.isSelected(item)}
                                                    onChange={() => { this.facturaChecked(item) }}></Checkbox>
                                            </Col> : null}
                                            <Col span={this.props.clasificadas === false ? 4 : 5} className="center">
                                                <Text>{item.invoice_type}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{item.sender_name}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{item.receiver_name}</Text>
                                            </Col>
                                            <Col span={2} className="center">
                                                <Text strong>{moment(item.invoice_date).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>$ {item.subtotal_amount ? item.subtotal_amount.toMoney() : '0.00'} {item.currency}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>$ {item.tax_amount ? item.tax_amount.toMoney() : '0.00'} {item.currency}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>$ {item.total_amount ? item.total_amount.toMoney() : '0.00'} {item.currency}</Text>
                                            </Col>
                                            <Col span={2} className="center">
                                                {item.xml !== null && item.xml !== undefined ? <Button type="primary" className="button-survey btn-eye button-eye"
                                                    onClick={(e) => { this.setState({ factura_xml: item.xml, ModalXMLVisible: true }) }}
                                                    title="Ver XML">
                                                    <IconEye />
                                                </Button> : <Button type="primary" className="button-survey btn-eye button-eye" disabled={true}>
                                                    <IconEye />
                                                </Button>}
                                                { vista_guardadas ? <Popconfirm placement="topRight"
                                                    title="¿Eliminar de Guardados?" okText="Si, seguro" cancelText="No"
                                                    onConfirm={() => {
                                                        this.deleteGuardados(item._id)
                                                    }}>
                                                    <Button type="primary"
                                                        className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                        title="Eliminar">
                                                        <IconDelete />
                                                    </Button>
                                                </Popconfirm> : null
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </div>

                </section>


                <ModalClasificarFact
                    visible={this.state.modalClasFactVisible}
                    closeMethod={this.hideModal}
                    selectedFacturas={this.state.selectedFacturas}
                    whit_payments={this.state.whit_payments}
                    belvo_facturas={this.state.belvo_facturas}
                />

                <ModalXML
                    visible={this.state.ModalXMLVisible}
                    closeMethod={this.hideModal}
                    factura_xml={this.state.factura_xml}
                />

                {this.props.clasificadas === false ? <Button className="btnCheckGreen" title="" onClick={() => this.addFacturas()} >
                    <IconCheckGreen />
                </Button> : null}

            </div>
        )
    }
}


