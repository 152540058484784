import React from 'react'
import { Drawer, Form, Select, Cascader, message, Badge, Button, DatePicker } from 'antd'
import axios from 'axios'
import { DownloadOutlined } from '@ant-design/icons'





const { Option } = Select





/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
export default class FiltrosDrawer extends React.Component {

    state = {

        proveedores: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 15,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },
        categorias: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 15,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },
        subcategorias: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 15,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        }
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


        this.getProveedores()
        this.getCategorias()
    }


    formFiltros = React.createRef()


    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getProveedores
    * 
    * @description Obtenemos los proveedores
    */
    getProveedores = ({ page, limit, search } = this.state.proveedores, { proveedores } = this.state) => {
        proveedores.loading = true;
        proveedores.page = page;
        proveedores.limit = limit;
        this.setState({ proveedores })

        axios.get('/proveedores/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                proveedores.data = (page === 1) ? response.data.data.itemsList : [...proveedores.data, ...response.data.data.itemsList];


                proveedores.dictionary = {}
                proveedores.data.map(d => proveedores.dictionary[d._id] = d)

                proveedores.total = response.data.data.paginator.itemCount
                proveedores.pages = response.data.data.paginator.pageCount
                proveedores.loading = false;

                this.setState({ proveedores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.proveedores.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getCategorias
    * 
    * @description Obtenemos los proveedores
    */
    getCategorias = ({ page, limit, search } = this.state.categorias, { categorias } = this.state) => {

        categorias.loading = true;
        categorias.page = page;
        categorias.limit = limit;
        this.setState({ categorias })

        axios.get('/categorias/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                categorias.data = (page === 1) ? response.data.data.itemsList : [...categorias.data, ...response.data.data.itemsList];


                categorias.dictionary = {}
                categorias.data.map(d => categorias.dictionary[d._id] = d)

                categorias.total = response.data.data.paginator.itemCount
                categorias.pages = response.data.data.paginator.pageCount
                categorias.loading = false;

                this.setState({ categorias })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.proveedores.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getSubCategorias
    * 
    * @description Obtenemos los proveedores
    */
    getSubCategorias = ({ page, limit, search } = this.state.subcategorias, { subcategorias } = this.state,{ _id } = this.state.categorias) => {

        subcategorias.loading = true;
        subcategorias.page = page;
        subcategorias.limit = limit;
        this.setState({ subcategorias })

        axios.get('/sub-categorias/list', {
            params: {
                page, limit, search,
                categoria_id: _id
            }
        })
            .then(response => {

                subcategorias.data = (page === 1) ? response.data.data.itemsList : [...subcategorias.data, ...response.data.data.itemsList];


                subcategorias.dictionary = {}
                subcategorias.data.map(d => subcategorias.dictionary[d._id] = d)

                subcategorias.total = response.data.data.paginator.itemCount
                subcategorias.pages = response.data.data.paginator.pageCount
                subcategorias.loading = false;

                this.formFiltros.current.setFieldsValue({
                    sub_categoria_id: null
                })

                this.setState({ subcategorias })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.proveedores.loading = false
                    return state
                })
            })
    }


    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = (values) => {
       

        this.props.updateFilters(values)
    }


    render() {

        const { onClose, visible } = this.props
        const { clientes, proyectos, propiedades, cuentas, razones_sociales, clasificadores, ordenes, ordenes_compras, negocios, proveedores, sub_conceptos } = this.state

        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            visible={visible}
            >
            <Form
                ref={this.formFiltros}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >
                
                <Form.Item label="Proveedores" name="proveedores">
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        filterOption={false}
                        onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !proveedores?.loading && proveedores.page < proveedores.pages) ? this.getProveedores({ page: proveedores.page + 1 }) : null}
                        onSearch={search => this.getProveedores({ search, page: 1 })}
                    >
                        {proveedores.data.map(proveedor => <Option key={proveedor._id} value={proveedor._id}><Badge color={proveedor.color} />{proveedor.razon_social}</Option>)}
                    </Select>
                </Form.Item> 

                <Form.Item label="Categoria" name="categoria_id">
                    <Select
                        allowClear
                        
                        showSearch
                        filterOption={false}
                        onSearch={search => this.getCategorias({ search, page: 1 })}
                        onSelect={(categoria_id) => { 

                            this.setState(state => {
                                state.categorias._id = categoria_id

                                return state
                            }, () => this.getSubCategorias())
                        }}
                    >
                        {this.state.categorias.data.map(categoria => <Option key={categoria._id} value={categoria._id}>{categoria.nombre}</Option>)}
                    </Select>
                </Form.Item>

                 <Form.Item label="Sub categoria" name="sub_categoria_id">
                    <Select
                        allowClear
                        
                        showSearch
                        filterOption={false}
                        onSearch={search => this.getAreas({ search, page: 1 })}
                        onSelect={() => { }}
                    >
                        {this.state.subcategorias.data.map(sub => <Option key={sub._id} value={sub._id}>{sub.nombre}</Option>)}
                    </Select>
                </Form.Item>

            </Form>
        </Drawer>)
    }
};