import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, DatePicker, message, Pagination, Checkbox, Dropdown, Menu} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconEye, IconPlus, IconArrowCustom as Arrow, IconDownloadCustom as Download} from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
//modal
import ModalEmailVentas from '../Modales/Ventas/ModalEmailVentas';
//css
import '../../../Styles/ventas.css';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const axios = require('axios').default

export default class VentasEmail extends Component{

	constructor(props){
		super(props)
		this.state = {
			projects: [],
			projectsSelected: [],
			emails: [{
				prospecto: '{{prospecto}}',
				email: '{{email}}',
				estatus: 1,
				proyecto: '{{proyecto}}'
			}],
			modalEmailVisible: false,
			prospecto_id: undefined,

			/* Paginado */
            page: 1,
            total: 0,

		}
	}


	/**
	* @memberof VentasEmail
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getProjects();
	}

	/**
     * @methodOf  Prospectos
     * @function hideModal
     *
     * @description Cierra el modal
     *
     * */
    hideModal = () => {
        this.setState({ modalEmailVisible: false });
    };




    /**
     * @methodOf VentasEmail
     * @function getProjects
     * @description Obtiene los proyectos para los filtros
     */
    getProjects = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({
                projects: proyectos.data.data
            })
        }).catch((error) => {
        	message.error('No se cargaron los Proyectos')
        })
    }

    /**
     * @methodOf VentasEmail
     * @function onProjectsChange
     * @description Se ejecutat al cambiar el proyecto, 
     * 
     * @param _id ID del proyecto a filtrar
     * @param status ID del status (si se desea agregar al arreglo o no)
     */
    onProjectsChange = (_id, status) => {

        let { projectsSelected } = this.state;
        let index = projectsSelected.findIndex(item => item.toString() === _id.toString())
        if (status) {
            if (index === -1) projectsSelected.push(_id)
        } else {
            if (index !== -1) projectsSelected.splice(index, 1)
        }
        this.setState({ projectsSelected })
    };

    /**
    * @memberof OrdenesTrabajo
    *
    * @method   spanEstatus
    * @description retorna el span dependiendod el estatus
    *
    **/
    spanEstatus = (estatus) => {
        switch (estatus) {
            case 1:
                return <span className="span-estatus"> Primer Contacto  </span>
                break;
            case 2:
                return <span className="span-estatus"> Visita </span>
                break;
            case 3:
                return <span className="span-estatus"> Venta </span>
                break;
        }
    }


	render() {
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} lg={8} className="">
						<h2 style={{width: 'auto', float: 'left'}}>Emails</h2>
					</Col>
					<Col xs={24} lg={5} className="center">
						<RangePicker
                            className="input-picker"
                            style={{ background: 'transparent !important',	color: '#FFF' }}
                        />
					</Col>
					<Col xs={12} lg={3} className="center">
						<Dropdown
                            className="filter-element"
                            overlay={
                                <Menu>
                                	{this.state.projects.map(({ _id, nombre, logo, color }, index) => (
                                        <Menu.Item key={index}>
                                            <Checkbox
                                                onChange={(status) => this.onProjectsChange(_id, status.target.checked)}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        margin: '0 5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Checkbox>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }>
                            <p>Proyectos <Arrow className="arrow" /></p>
                        </Dropdown>
					</Col>
					<Col xs={12} lg={3} className="center">
						<Dropdown
                            className="filter-element"
                            overlay={
                                <Menu>
                                </Menu>
                            }>
                            <p>Rubros<Arrow className="arrow" /></p>
                        </Dropdown>
					</Col>
					<Col xs={12} lg={3} className="center">
						<Dropdown
                            className="filter-element"
                            overlay={
                                <Menu>
                                </Menu>
                            }>
                            <p>Subrubros<Arrow className="arrow" /></p>
                        </Dropdown>
					</Col>
					<Col xs={12} lg={2} className="center">
						<Button type="link" size="small">
                            <Download />
                        </Button>
					</Col>

				</Row>
				<section className="p-1">
                    <div className="scroll">
    					<Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
    						<Col span={2} className="center">
    							<Text type="secondary">#</Text>
    						</Col>
    						<Col span={6} className="center">
    							<Text type="secondary">PROSPECTO</Text>
    						</Col>
    						<Col span={6} className="center">
    							<Text type="secondary">EMAIL</Text>
    						</Col>
    						<Col span={7} className="center">
    							<Text type="secondary">PROYECTOS</Text>
    						</Col>
    						<Col span={3} className="center">
    							<Text type="secondary">ACCIONES</Text>
    						</Col>
    					</Row>

    					<List
    						className="component-list"
    						itemLayout="horizontal"
    						dataSource={this.state.emails}
    						loading={this.state.loading}
    						locale={{ emptyText: 'Sin Emails' }}
    						renderItem={item =>
    							(<List.Item className="component-list-item">
    								<Card className="card-list">
    									<Row style={{ width: '100%' }} className="">

    										<Col span={2} className="center">
    											<Text>#</Text>
    										</Col>
    										<Col span={6} className="center">
    											<Text strong>{item.prospecto}</Text>
    										</Col>
    										<Col span={6} className="center">
    											<Text>{item.email}</Text>
    										</Col>
    										<Col span={7} className="center">
    											{this.spanEstatus( item.estatus)}
    											<span className="span-proyecto" style={{marginLeft:'8px'}}> {item.proyecto} </span>
    										</Col>
    										<Col span={3} className="center">
    											<Link>
    												<Button type="primary" 
                                                        className="button-survey btn-eye button-eye" title="Ver detalle" 
                                                        onClick={(e) => { this.setState({modalEmailVisible: true, modalTitle: 'Ver'}) }}>
    													<IconEye />
    												</Button>
    											</Link>
    										</Col>
    									</Row>
    								</Card>
    							</List.Item>
    							)}
    					/>
                    </div>
					<Row className="mt-2 pb-3">
                        <Pagination
                            total={this.state.total}
                            current={this.state.page}
                            onChange={(page) => {
                                    
                                }}
                            />
                        </Row>
				</section>
				<Button
                    className="btnAddPlus"
                   	onClick={() => this.setState({modalEmailVisible: true, modalTitle: 'Enviar'})}>
                    <IconPlus/>
                </Button>
                <ModalEmailVentas
                	modalVisible={this.state.modalEmailVisible}
                	closeMethod={this.hideModal}
                	modalTitle={this.state.modalTitle}
                />
			</div>
		)
	}
}