import React, { Component } from 'react';
import { Button, Col, Row, message, Typography, List, Card, Pagination, Popconfirm } from 'antd'

//componentes
import { IconEye, IconEdit, IconDelete } from '../../../Widgets/Iconos';
import Avatar from '../../../Widgets/Avatar/Avatar'

//css
import '../../../../Styles/Modal/ModalDetalleBitacoraEstatusAvance.css';

//modal

import ModalEstatusAvanceDetalle from "../../Modales/ProjectManager/ModalEstatusAvanceDetalle"

import ModalEstatusAvance from "../../Modales/ProjectManager/ModalEstatusAvance"

const axios = require('axios').default;
const { Text, Title } = Typography;
const moment = require('moment');


export default class DetalleBitacoraEstatusAvance extends Component {

    constructor(props) {
        super(props)
        this.state = {

            filtroSearch: "",
            dataDetBitacora: [],

            loading: true,
            inmuebleId: '',
            inmuebleNombre: "",
            registroNombre: '',

            accionModal: "",
            regIdModal: "",
            visible: false,
            modalActualizado: false,

            modalEditarVisible: false,

            /* Paginado */
            itemCount: 1,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,
        }
    }


    /**
   * @memberof BitacoraEstatusAvance
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se muestra el filtro superior
    * Se establece el valor del header en axios
    * Se llama al metodo receivedData que hace la consulta de materiales a mostrar en la tabla
    **/
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


        var id = this.props.match.params.id;

        this.setState({
            inmuebleId: id,
            inmuebleNombre: "{{Inmueble}}",

            dataDetBitacora: [
                {
                    index: 1,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 1
                },
                {
                    index: 2,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 2
                },
                {
                    index: 3,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 3
                },
                {
                    index: 4,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 4
                },
                {
                    index: 5,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 5
                },

                {
                    index: 6,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 6
                },
                {
                    index: 7,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 7
                },
                {
                    index: 8,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 8
                },
                {
                    index: 9,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 9
                },
                {
                    index: 10,
                    fecha: new Date(),
                    usuario: "{{User}}",
                    numVideos: 10
                },
            ]
        })
        this.receivedData();
    }


    /**
   * @memberof BitacoraEstatusAvance
    * @method   componentDidUpdate
    * @description  Al actualizar el componente:
    * Se hace una comparacion entre el valor del filtro superior y el filtro del state para
    * saber si se actualizó y se tiene que llamar el metodo de consulta de materiales receivedData 
    * con el nuevo valor del filtro
    **/
    componentDidUpdate() {

        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro) {
            this.receivedData();
        }
    }


    /**
   * @memberof BitacoraEstatusAvance
    * @method   receivedData
    * @description  
    * Metodo de consulta de bitacora, 
    * tiene los parametros:
    * número de la página a consultar, 
    * el numero de registros por página
    * el filtro del input del header
    * 
    * La consulta se visualiza en la tabla
    **/
    receivedData = async (page = this.state.currentPage,
        limit = this.state.perPage,
        search = this.props.search
    ) => {
        var id = this.props.match.params.id;

        axios.post('/inmuebles-trabajo/getBitacoraDetalle', {
            params: {
                id: id,
                page: page,
                limit: limit,
                search: search
            }
        })
            .then(({ data }) => {

                console.log(data);

                var list = data.data.bit;
                var dataList = [];
                for (let index = 0; index < list.length; index++) {
                    const element = list[index];

                    dataList.push({
                        _id: element.bitacora._id,
                        fecha: element.bitacora.fecha,
                        usuario: element.usuario,
                        archivos: element.bitacora.archivos,
                        numVideos: element.bitacora.archivos.length,
                        ultimaActualizacion: moment(element.updatedAt).utc().format('DD-MM-YYYY'),
                        index: (index + 1)
                    });

                }

                this.setState({
                    loading: false,
                    loadingPage: false,
                    inmuebleId: data.data._id,
                    inmuebleNombre: data.data.inmueble.nombre,
                    dataDetBitacora: dataList,
                    currentPage: page,
                    itemCount: data.data.pagination.itemCount,
                    pageCount: data.data.pagination.pageCount,
                    filtroSearch: search
                })

            })
            .catch(error => {
                console.log('no traje nara', error);
            })

    }


    /**
   * @memberof BitacoraEstatusAvance
    * @method   eliminarRegistro
    * @description  
    * Metodo que elimina registro seleccionado 
    * tiene los parametros:
    * id: identificador del registro
    **/

    eliminarRegistro = id => {
        console.log(id)

        var inmuebleId = this.props.match.params.id;
        axios.post('/inmuebles-trabajo/bitacora/entrada/delete', {
            id: id,
            inmuebleId: inmuebleId
        })
            .then((response) => {
                message.success("Eliminado correctamente.");
                this.receivedData();
            })
            .catch((error) => {
                message.error(error.response.data.message)

            })
    }


    /**
    * @memberof BitacoraEstatusAvance
    * @method modalEditar 
    * @description Abrir modal tipo edición para registro seleccionado desde tabla.
    * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
    */
    modalDetalle = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;
        setTimeout(() => {
            this.setState({
                regIdModal: reg_id,
                accionModal: 'detalle',
                visible: true,
                modalActualizado: false,
            })
        }, 250);
    }


    /**
    * @memberof BitacoraEstatusAvance
    * @method modalEditar 
    * @description Abrir modal tipo edición para registro seleccionado desde tabla.
    * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
    */
    modalEditar = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;
        setTimeout(() => {
            this.setState({
                regIdModal: reg_id,
                accionModal: 'editar',
                modalEditarVisible: true,
            })
        }, 200);
    }


    /**
     *  @memberof BitacoraEstatusAvance
     * @method hideModal 
     * @description Cerrar modal y limpiar valor inmuebleId
    */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            regIdModal: '',
            visible: false,
            modalEditarVisible: false,
            modalActualizado: true,
        });

        this.receivedData();
    }



    render() {
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} className="flex-left">
                        <Title level={4} className="view-title">Bitacora de {this.state.inmuebleNombre}</Title>
                    </Col>
                </Row>

                <section className="p-1">
                    <Row className="p-1" >
                        <Col xs={2} className="center" >
                            <Text strong>#</Text>
                        </Col>
                        <Col xs={6} className="center" >
                            <Text strong>FECHA</Text>
                        </Col>

                        <Col xs={6} className="center" >
                            <Text strong>USUARIO</Text>
                        </Col>
                        <Col xs={6} className="center" >
                            <Text strong>NÚMERO DE ARCHIVOS</Text>
                        </Col>
                        <Col xs={4} className="center" >
                            <Text strong>ACCIONES</Text>
                        </Col>
                    </Row>

                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.dataDetBitacora}
                        renderItem={item => {
                            var bitacora = item.bitacora;
                            return <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">
                                        <Col span={2} className="center" >
                                            <Text strong>{item.index}</Text>
                                        </Col>
                                        <Col span={6} className="center" >
                                            <Text strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                        </Col>
                                        <Col span={6} className="center" >
                                            <Avatar
                                                name={item.usuario.nombre + " " + item.usuario.apellido}
                                                image={item.usuario.avatar} />

                                            <Text strong style={{ marginLeft: "10px" }}  >{item.usuario.nombre + " " + item.usuario.apellido}</Text>
                                        </Col>
                                        <Col span={6} className="center" >
                                            <Text >{item.numVideos}</Text>
                                        </Col>

                                        <Col span={4} className="center" >
                                            <Button className="btn btn-eye" title="Detalles" id={item._id} onClick={this.modalDetalle}  >
                                                <IconEye />
                                            </Button>


                                            <Button type="primary" className="button-survey btn-edit button-edit" id={item._id} onClick={this.modalEditar} title="Editar"  >
                                                <IconEdit />
                                            </Button>

                                            <Popconfirm placement="topRight" title="¿Eliminar este registro?" okText="Si, seguro" cancelText="No" name={item.fecha} id={item._id} onConfirm={() => { this.eliminarRegistro(item._id) }} >
                                                <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" name={item.fecha} title="Eliminar"  >
                                                    <IconDelete />
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                    </Row>

                                </Card>
                            </List.Item>
                        }}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.currentPage}
                            total={this.state.itemCount}
                            pageSize={this.state.perPage}
                            showSizeChanger={false}
                            onChange={(page) => {
                                this.receivedData(page)
                            }}
                        />
                    </Row>

                </section>


                <ModalEstatusAvanceDetalle
                    visible={this.state.visible}
                    closeMethod={this.hideModal}
                    regIdModal={this.state.regIdModal}
                    inmuebleId={this.state.inmuebleId}


                    accionModal={this.state.accionModal}
                    dataDetBitacora={this.state.dataDetBitacora}
                    actualizado={this.state.modalActualizado}
                >

                </ModalEstatusAvanceDetalle >


                <ModalEstatusAvance
                    visible={this.state.modalEditarVisible}

                    closeMethod={this.hideModal}

                    regIdModal={this.state.regIdModal}

                    inmuebleId={this.state.inmuebleId}

                    inmueble={this.state.inmuebleNombre}

                    accionModal={this.state.accionModal}

                    dataDetBitacora={this.state.dataDetBitacora}

                    actualizado={this.state.modalActualizado}
                >
                </ModalEstatusAvance>

            </div>
        )
    }
}
