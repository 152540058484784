import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Spin, Form, Select, message, InputNumber  } from 'antd';
//componentes
import { IconCloseModal } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalProforma extends Component {

	formEstatus = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		}
	}

	/**
	* @memberof ModalProforma
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getInmuebles()
	}

	/**
	* @memberof ModalProforma
	*
	* @method   componentDidUpdate
	* @description  
	*
	**/
	componentDidUpdate(prevProps) {
		if(this.props.modalVisible !== true && this.props.modalVisible !== prevProps.modalVisible ){
			this.getInmuebles()
		}
	}

	/**
	* @memberof ModalProforma
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	hideModal = () => {
		this.props.closeMethod()
		this.setState({loading: false})
	}	

	/**
	* @memberof ModalProforma
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	onFinish = (values) => {
		this.setState({loading: true})
		this.addProforma(values);
	}

	/**
	* @memberof ModalProforma
	*
	* @method   getInmuebles
	* @description	retorna los inmuebles para el select
	*
	**/
	getInmuebles = (search) => {
		axios.get('/proforma/inmuebles',{
			params:{
				search,
				page: 1,
				limit: 100,
			}
		}).then(response => {
			this.setState({
				inmuebles: response.data.data.itemsList
			})
		}).catch(error => {
			console.log(error)
			message.error('Error al cargar los Inmuebles')
		})
	}

	

	/**
	* @memberof ModalProforma
	*
	* @method   addProforma
	* @description	añade un registro a la coleccion de proforma
	*
	**/
	addProforma = (values) => {
		axios.post('/proforma/add',{
			inmueble_id: values.inmueble_id,
			presupuesto: values.presupuesto,
		}).then(response => {
			message.success('¡Proforma agregado Exitosamente!')
			this.hideModal()
		}).cathc(error => {
			console.log(error)
			this.setState({loading: false})
			message.error('Error al añadir el Profoma')
		})
	}


	/**
    * @memberof ModalProforma
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            if (array.length > 0) {

                array.forEach(function (item, index) {
                    arr.push(<Option value={item._id}>{item.nombre}</Option>)
                })
            }
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
        }
        return arr
    }


	render() {
		return (
			<Modal
				visible={this.props.modalVisible}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				closable={false}
				className="modal-form"
				destroyOnClose={true}
				zIndex={1000}
				width={750}
			>

				<div className="modal-header">
					<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
						<IconCloseModal />
					</Button>
					<Title level={3} className="modal-title">{this.props.modalTitle} Proforma</Title>
				</div>

				<Spin spinning={this.state.loading}>
					<Form
						layout="vertical"
						name="formulario-transacciones"
						onFinish={this.onFinish}
					>
						<Row align="center">
							<Col xs={20} md={15}>
								<Form.Item
									label="Inmueble"
									name="inmueble_id"//----------------------------------------------------------inmueble_id
									rules={[{
										required: true,
										message: "Por favor, seleccione un Inmueble"
									}]}
								>
									<Select
										className="form-select"
                                        showSearch
                                        filterOption={false}
                                        onSearch={search => this.getInmuebles(search)}
                                    >
                                       {this.selectOptions(this.state.inmuebles)}

                                    </Select>
								</Form.Item>
							</Col>
							<Col xs={20} md={15}>
								<Form.Item
									label="Presupuesto"
									name="presupuesto"//----------------------------------------------------------presupuesto
									rules={[{
										required: true,
										message: "Por favor, ingreso el presupuesto"
									}]}
								>
									<InputNumber/>
								</Form.Item>
							</Col>
							<Col span={20} className="center">
								<Button htmlType="submit" type="primary" className="btn-azul mt-3">
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		)
	}
}
