import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, Select, List, Typography, message, Pagination, Spin, Checkbox } from 'antd';
import './../../../../Styles/modales.css'
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { SearchOutlined } from "@ant-design/icons"


const moment = require('moment');
const axios = require('axios').default;




const { Option } = Select;
const { Title, } = Typography;
const { Search } = Input;

const pagination = {
    data: [],
    page: 1,
    limit: 10,

    total: 0,

    search: null,
}

/**
 * 
 * @class ModalTajos
 * @description Lista de tajos de un empleado en especifico dada una nomina.
 *  
 * @property modalVisible Ocultar mostrar el modal
 * @property hideModal Para ocultar el modal
 * @property empleado_id ID del empleado a obtener los tajos
 * @property nomina_id ID de la nomina a buscar
 * @property inmueble_id ID de la inmueble
 */
export default class ModalTajos extends Component {



    static propTypes = {
        modalVisible: PropTypes.bool,
        hideModal: PropTypes.func,
    };

    static defaultProps = {
        modalVisible: false,
        hideModal: () => {},
    };

    /**
    * @var candEdit Si se puede editar o no
    * @var tajos información de la pgaincion de tajos.
    */
    constructor(props) {
        super(props);
        this.state = {
            canEdit: false,
            tajos: pagination,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     *
     *
     * @memberof ModalSubRubro
     *
     * @function componentDidUpdate
     * @description Se ejecuta el actualizar cualqueir estado. Verifoica que haya un empleado definido y en caso de que si obtenemos sus tajos. Tambien los limpia.
     */
    componentDidUpdate() {

        console.log('MODAL TAJOS');

        // //Si se abre el modal y el editar esa desactivo, se activa para poder editar
        if (this.props.modalVisible && !this.state.canEdit && !(this.props.empleado_id === null || this.props.empleado_id === undefined)) {
            console.log('OBTENER');
            this.state.canEdit = true;
            this.state.empleado_id = this.props.empleado_id;
            this.getInmueblesEmpleadoTajos()
        }

        // //Si se cierra el modal y el editar esta acctivo, lo desactivamos para esperar un nuevo to update y limpia el modalcini.
        if (!this.props.modalVisible && this.state.canEdit) {
            console.log('ELMINAR');
            this.state.canEdit = false;
            this.state.empleado_id = undefined;
            this.tajos = pagination;
        }
    }


    /**
     * @memberof ModalTajos
     *
     * @function getInmueblesEmpleadoTajos
     * @description Obtenemos la lista de tajos del empleado
     * 
     * @param {*} page Pagina oficial de tajo
     * @param {*} limit Limite de tajos
     * @param {*} total Ttoa de tajos
     * @param {*} search tajos a busacr
     */
    getInmueblesEmpleadoTajos = (page = this.state.tajos.page, limit = this.state.tajos.limit, total = this.state.tajos.total, search = this.state.tajos.search) => {
        this.setState({ loading: true })
        axios.post('/nominas/inmueble/empleados/tajos', {
            inmueble_id: this.props.inmueble_id,
            nomina_id: this.props.nomina_id,
            empleado_id: this.state.empleado_id,
            page,
            limit,
            search
        })
            .then(({ data }) => {
                console.log('getInmueblesEmpleadoTajos', data);
                const { tajos } = data.data
                this.setState({
                    tajos: {
                        data: tajos.data,
                        page: page,
                        limit: limit,
                        total: tajos.total,
                        search: search,
                    },
                    loading: false
                })
            })
            .catch(e => {
                console.log('e', e);
            })


    }



    /**
     * @memberof ModalTajos
     *
     * @function actualizarTajosEmpleados
     * @description Actualizar tajos de empleados
     * 
     * @param {*} e Booleano, si se activa o se desactiva el tajo
     * @param {*} tajo_id ID del tajo
     */
    actualizarTajosEmpleados = (e, tajo_id) => {

        console.log('checked', tajo_id);
        axios.post('/nominas/inmueble/empleados/tajos/aceptar', {
            empleado_id: this.state.empleado_id,
            nomina_id: this.props.nomina_id,
            inmueble_id: this.props.inmueble_id,
            tajo: {
                tajo_id,
                status: e
            }
        })

            .then(({ data }) => {
                console.log('data', data);
            })
            .catch(({ response }) => {
                console.log('response', response);
            })
            .finally(e => {
                this.getInmueblesEmpleadoTajos()
            })
    }




    render() {
        const { modalVisible, hideModal, id, } = this.props;
        let { tajos, canEdit } = this.state;
        const { formModalCrearEmpleado } = this;
        return (
            <Modal
                visible={modalVisible}
                onCancel={hideModal}
                title={null}
                footer={null}
                closable={true}
                onOk={() => { }}
                className="modal-form modal-rubro"
                destroyOnClose={true}
                zIndex={1000}
            >
                <Spin spinning={this.state.loading}>
                    <div className="modal-header">
                        <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={hideModal}>
                            <IconCloseModal />
                        </Button>
                        <Title level={3} className="modal-title">Lista de Tajos</Title>
                    </div>
                    <List
                        itemLayout="horizontal"
                        style={{ marginBottom: "3em" }}
                        dataSource={tajos.data}
                        header={<List.Item
                        >
                            <Input />
                            <Button type="ghost" icon={<SearchOutlined />} />
                        </List.Item>}
                        renderItem={({ tajo, status }) => (
                            <List.Item
                                actions={[
                                    <Checkbox checked={status === 1} onChange={(e) => this.actualizarTajosEmpleados(e.target.checked, tajo._id)} />]} 
                            >
                                <List.Item.Meta
                                    title={tajo.nombre}
                                />
                            </List.Item>
                        )}
                    />
                    <Pagination size="small" total={tajos.total} onChange={this.getInmueblesEmpleadoTajos} showSizeChanger style={{ margin: "0px auto" }} />
                </Spin>
            </Modal>
        )
    }
}
