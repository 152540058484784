import React, { useState } from 'react'
import { Route, Switch } from "react-router-dom";
import { Modal } from "antd";
import axios from 'axios';

// Components
import Empresa from '../../Components/Admin/Contabilidad/Empresa';
import Contabilidad from '../../Components/Admin/Contabilidad/Contabilidad';
import FacturasSyncfy from '../../Components/Admin/Contabilidad/Facturas/FacturasSyncfy';

/**
 * 
 * @export
 * @function RouterContabilidad
 * @description Router para Contabilidad routes 
 */
 export default function(props) {

  const [token, setToken] = useState(null);
        const [syncfyUsuarioId, setSyncfyUsuarioId] = useState(null);
    
        // setUserId
        /**
         *
         *
         * @param {*} userId
         * @description setUserId
         */
        const setUserId = async (userId) => {
            console.log(userId)
            if (syncfyUsuarioId !== userId && userId !== undefined) {
                setSyncfyUsuarioId(userId)
                await startSession(userId)
            }
        }
    
        /**
         *
         *
         * @param {*} id_user_syncfy
         * @memberof CuentaForm
         * 
         * @method deleteSession
         * @description Eliminamos una sesión.
         */
        const deleteSession = () => {
            axios.defaults.headers.common['syncfy'] = sessionStorage.getItem('syncfy')
            return axios.delete('/syncfy/session', {
    
            })
                .then(({ data }) => {
                    sessionStorage.removeItem('syncfy')
                })
                .catch(error => {
                    console.log('Error al eliminar', error.response)
                    if (error.response?.status === 403) {
                        Modal.warning({
                            title: error.response?.data?.data?.nombre,
                            content: error.response?.data?.data?.description,
                        })
                    }
                })
        }
    
        /**
         *
         *
         * @param {*} id_user_syncfy
         * @memberof CuentaForm
         * 
         * @method startSession
         * @description Creamos una sesión para poder obtener las credenciales. Declaramos el token de synfy en la cabecera de axios, para que se envie siempre durante la solicitudes
         */
        const startSession = async (id = syncfyUsuarioId) => {
            if (sessionStorage.getItem('syncfy')) {
                try {
                    await deleteSession()
                } catch (error) {
                    console.log('error', error)
                }
            }
    
            return axios.get('/syncfy/session', {
                params: { id_user_syncfy: id }
            })
                .then(({ data }) => {
                    sessionStorage.setItem('syncfy', data.token)
                    axios.defaults.headers.common['syncfy'] = data.token
                    setToken(data.token)
                    // setTimerCount(timerCount + 1)
                })
                .catch((response) => {
                    console.log('error', response)
                })
                .finally(() => { })
        }

  return (
    <Switch>
      <Route exact path="/admin/contabilidad"  render={(props_h) => <Contabilidad {...props} {...props_h} />} />
      <Route exact path="/admin/contabilidad/:empresa_id"  render={(props_h) => <Empresa {...props} {...props_h} />}/>
      <Route exact path="/admin/contabilidad/facturas/:id"  render={(props_h) => <FacturasSyncfy {...props} {...props_h} setUserId={setUserId} />}/>
    </Switch>
  )
}

