import React, { Component, } from 'react';
import { Col, Row, Card, Typography, Button, Spin, Form, Breadcrumb, Modal, List, message, InputNumber, } from 'antd'
import { Link } from "react-router-dom";
import { CheckCircleTwoTone } from '@ant-design/icons';
import axios from 'axios';
//compònentes
import Logged from "../../../../Hooks/Logged";
import { IconArrowBack } from '../../../Widgets/Iconos';
import EmpleadosFilter from '../Widgets/Filtros/Empleados';
import InmueblesFilter from '../Widgets/Filtros/Inmuebles';


import ModalDiferencia from './ModalDiferencia';


const { Title, Paragraph, Text } = Typography;


/**
 * 
 * @class Avance
 * @description Esta vista permite ver todas las cartas de la seccion de Avance de nominas. Hace la diferencia segun sea Prelminar, Final o Finanzas. 
 * 
 * Dependiendo de las variables que tenga el componente ruta, es lo que va a mostrar.
 * 
 * @property tipo Hace la diferencia entre inmueble o destajo. Si es destajo, muestra las cartas modo empleado, si es inmueble muestra las cartas con la info de los inmuebles
 * @property redireccion Si es tajos va a redirigir a tajos. EN la vista de inmuebles o empleados agrupados, no estára definida, ya que es hasta la segunda vista (cuando ya hay un inmuelbe ID o un empleado). 
 * @property finanzas Es solo para distinguir si la vista es finanzas o nó. 
 */
export default class Avance extends Component {

    /**
     * 
     * @param contextType Obtenemos el usuario loggeado
     */
    static contextType = Logged;
    /**
     * 
     * @state loading Para establecer la carga de elementos
     * 
     * @state list Para la lista de empleados o inmuebles.
     * @state list.data Lista de empleados o inmuebles
     * @state list.page Lista de empleados o inmuebles
     * @state list.limit Limite de elmeentos por pagina
     * @state list.search Para buscar elementos
     * 
     */
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            inmuebles: [],
            empleados: [],
            etapa: 1,

            empleado_id: this.props.match.params.empleado_id,
            inmueble_id: this.props.match.params.inmueble_id,
            //dependiendo de info de props, veremos que tipo de agrupacion es, si es empleado o por inmueble
            tipo: this.props.tipo,


            filters: {
                empleado_id: this.props.match.params.empleado_id,
                inmueble_id: null,
                actividad_id: null
            },
            empleado: undefined,
            data: [],
            //arreglo donde se guarda una copia de monto_autorizado de cada tajo
            montos_autorizados: [],
            tajos: [],


            total_presupuesto: 0,
            total_pagado: 0,
            total_trabajado: 0,


            visibleModalDiferencias: false

        };
    }

    /**
     * @memberof NominaInmuebles
     * @description Se ejecuta al terminar de montar la vista de administar nominas;
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log('eeeeeeeeeeeeeeee')
        if (this.props.etapa === 1) {
            this.getListPreliminar();
        } else {
            this.getList();
        }
    }


    /**
    * @memberof Avance
    *
    * @description Trae la lista de inmuebles o de empleados a cargar, segun se al caso (tipo)
    *
    */
    getListPreliminar = () => {
        this.setState({loading: true})
        axios.post('/nominas/revision/preliminar', {
            nomina_id: this.props.match.params.nomina_id,
            tipo: this.state.tipo,
            empleado_id: this.state.empleado_id,
            inmueble_id: this.state.inmueble_id,
        }).then(({ data }) => {
            console.log('Avance nomina data', data)
            if (this.state.tipo === 'empleado')
                this.setState({
                    empleado: data.data.empleado,
                    inmuebles: data.data.inmuebles
                })
            else
                this.setState({
                    empleados: data.data.empleados,
                    inmueble: data.data.inmueble
                })
        }).catch(error => {
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     *
     *
     * @memberof Avance
     * @params filters Object {}
     * @description Trae la lista de inmuebles para revisar
     */
    getList = (
        filters = this.state.filters,
        tipo = 1,
        preliminar = true
    ) => {


        this.setState({ loading: true })
        axios.post('/nominas/revision', {
            nomina_id: this.props.match.params.nomina_id,
            filters,
            tipo,
            preliminar
        })
            .then(({ data }) => {
                console.log('data', data)
                //Suma total de Presupuestos (Presupuesto Total)
                let presupuesto = 0;
                let total_trabajado = 0;
                const inmuebles = data.data.list.map(({ inmueble, tajos }, index) => ({
                    inmueble,
                    tajos: tajos.map(tajo => {
                        //Si no hay un porcentaje_avance_final, entonces tomamos el que dejó el encargado
                        if (tajo.avance === null || tajo.avance === undefined)
                            tajo.avance = 0
                        tajo.avance_temp = tajo.avance
                        return tajo;
                    })
                }))



                console.log('data.data.diferencia?.total_pagado', data.data.diferencia?.total_pagado);
                // let empleado = (filters.empleado_id !== undefined) ? undefined : data.data.empleado;
                this.setState({


                    total_presupuesto: (!isNaN(data.data.diferencia.presupuesto_total) ? data.data.diferencia.presupuesto_total : 0),
                    total_pagado: (!isNaN(data.data.diferencia.total_pagado) ? data.data.diferencia.total_pagado : 0),
                    total_trabajado: (!isNaN(data.data.diferencia.total_trabajado) ? data.data.diferencia.total_trabajado : 0),

                    empleado: data.data.empleado,
                    inmuebles,
                    porcentajes_avances: data.data.list,//copia a manipular

                });
            })
            .catch(error => {
                console.log('error', error);
                Modal.confirm({
                    title: "Ha ocurrido un error al cargar los avances",
                    content: "Ha ocurrido un error al procesar "
                })
            })
            .finally(() => {
                this.setState({ loading: false })
                //si se actualizan los tajos en el state, no se pierdan los valores de monto_autorizado de cada tajo
                // if (this.state.montos_autorizados && this.props.etapa === 1) {
                //     const { tajos } = this.state;
                //     tajos.forEach((tajo, index) => {
                //         tajo.monto_autorizado = this.state.montos_autorizados[index].monto_autorizado
                //     })
                //     this.setState({ tajos })
                // }
            })
    }





    /**
     *
     *
     * @param {*} value
     * @param {*} tajo
     * @param {*} index
     */
    onChangeAvancePorcentual = (value, tajo, index, tajo_update_index) => {
        let { inmuebles, empleados } = this.state;

        //si el avance dado en la lista es menor al del avance del tajo de la consulta
        if (this.props.etapa === 1) {
            if (this.state.tipo === 'empleado') {
                let avance = inmuebles[index].tajos[tajo_update_index].porcentaje_avance ? inmuebles[index].tajos[tajo_update_index].porcentaje_avance : 0;
                if (avance <= parseInt(value)) inmuebles[index].tajos[tajo_update_index].porcentaje_avance_final = value;
                else message.warning({ content: 'El porcentaje no puede ser menor al actual' })
            } else if (this.state.tipo === 'inmueble') {
                let avance = empleados[index].tajos[tajo_update_index].porcentaje_avance ? empleados[index].tajos[tajo_update_index].porcentaje_avance : 0;
                if (avance <= parseInt(value)) empleados[index].tajos[tajo_update_index].porcentaje_avance_final = value;
                else message.warning({ content: 'El porcentaje no puede ser menor al actual' })
            }

        }

        //revision final
        if (this.props.etapa === 2) {
            inmuebles[index].tajos[tajo_update_index].porcentaje_avance_final = value
            const tajo = inmuebles[index].tajos[tajo_update_index]

            // A PAGAR =( (AVANCE ACTUAL- AVANCE ANTERIOR ) / 100 ) * PRESUPUESTO
            inmuebles[index].tajos[tajo_update_index].monto_autorizado = ((parseFloat(tajo.porcentaje_avance_final) - parseFloat(tajo.avance)) / 100) * parseFloat(tajo.presupuesto)  //(tajo.porcentaje_avance - )
        }

        this.setState({ inmuebles })

    }




    /**
     *
     *
     * @memberof Avance
     * @description Asigna los filtros para cargar los datos
     */
    filter = (value, tipo) => {
        let filtros = {};
        switch (tipo) {
            case 'empleado':
                filtros.empleado_id = value;
                break;
            case 'inmueble':
                filtros.inmueble_id = value
                break;
            case 'actividad':
                filtros.actividad_id = value
                break;
        }

        this.getList(filtros)
    }

    /**
     *
     *
     * @memberof Avance
     */
    save = (formValues = {}) => {
        this.setState({loading: true})
        axios.post(((this.props.etapa === 1) ? '/nominas/revision/preliminar/carga' : '/nominas/revision/final'), {
            nomina_id: this.props.match.params.nomina_id,
            inmuebles: this.state.inmuebles,
            empleados: this.state.empleados,
            usuario_id: ((this.state.empleado) ? this.state.empleado._id : undefined),
            inmueble_id: ((this.state.inmueble) ? this.state.inmueble._id : undefined),
            tipo: this.state.tipo,


            monto: parseFloat(formValues.monto),
            diferencia: this.state.diferencia,
            monto_pagado: this.state.monto_pagado,
            presupuesto_total: this.state.presupuesto_total,

        })
            .then(({ data }) => {
                message.success("Guardado Correctamente")
                this.props.history.goBack()
            })
            .catch(error => {
                console.log('error', error);
                Modal.confirm({
                    title: "No es posible guardar",
                    content: "Ha ocurrido un error al procesar "
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
     *
     *
     * @returns
     * @memberof Avance
     */
    render() {

        //data puede ser empleados o inmuebles
        let { inmuebles, inmueble, empleados, empleado } = this.state;

        let title = ''

        if (empleado) title = `${empleado.nombre} ${empleado.apellido}`
        else if (inmueble) title = inmueble.nombre



        ///Cantidades Totales
        const { total_presupuesto, total_pagado, total_trabajado } = this.state;

        console.log({ total_presupuesto, total_pagado, total_trabajado });


        //Cantidades actuales
        let actual_presupuesto = 0;
        let actual_trabajado = 0;
        let actual_pagado = 0;



        inmuebles.map(({ inmueble, tajos }, index) => ({
            inmueble,
            tajos: tajos.map(({ presupuesto, monto_autorizado, monto_pagado }) => {
                actual_presupuesto += !isNaN(parseFloat(presupuesto)) ? presupuesto : 0
                actual_trabajado += !isNaN(parseFloat(monto_autorizado)) ? monto_autorizado : 0
                actual_pagado += !isNaN(parseFloat(monto_pagado)) ? monto_pagado : 0
            })
        }))


        console.log({
            actual_presupuesto,
            actual_trabajado,
            actual_pagado
        });

        return (
            <Row className="content">
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado" justify="space-around">
                        <Col xs={24} xl={8} xxl={16} className="flex-left ">
                            <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline', paddingTop: '0px' }}><IconArrowBack /></Button>
                            <Breadcrumb separator={<span style={{ color: 'white' }}></span>}>
                                <Breadcrumb.Item style={{ color: 'white', fontSize: '1.5em' }}>
                                    Avance Nómina
                                </Breadcrumb.Item>
                                {(this.props.match.params.inmueble_id || this.props.match.params.empleado_id) ? <Breadcrumb.Item style={{ color: 'white', fontSize: '14' }}>
                                    Sublista
                                </Breadcrumb.Item> : null}
                            </Breadcrumb>
                        </Col>

                        <Col xs={24} xl={8} xxl={8} className="flex-left ">
                            <EmpleadosFilter onChange={value => this.filter(value, 'empleado')} nomina={this.props.match.params.nomina_id} />
                            <InmueblesFilter onChange={value => this.filter(value, 'inmueble')} tag="Inmuebles" tipo={2} nomina={this.props.match.params.nomina_id} />
                            <InmueblesFilter onChange={value => this.filter(value, 'actividad')} tag="Actividad" tipo={1} nomina={this.props.match.params.nomina_id} />
                        </Col>
                    </Row>

                    {(empleado || inmueble) ? <Row className="p-1" gutter={[0, 16]} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} xl={24}>
                            <Card className="content-card">
                                <Row>
                                    <Col span={18}>
                                        {<Title level={3} style={{ color: "#0047FF" }}>{title}</Title>}
                                    </Col>

                                </Row>
                                {this.props.etapa === 1 ? null : [
                                    <Row className={"information-widgets-content "}>
                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Presupuesto Total</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {parseFloat(actual_presupuesto).toMoney()}</Paragraph>
                                        </Col>

                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Monto Trabajado Total</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {parseFloat(parseFloat(total_trabajado) + parseFloat(actual_trabajado)).toMoney()}</Paragraph>

                                        </Col>

                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Monto Pagado Total</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {parseFloat(parseFloat(total_pagado) + parseFloat(actual_pagado)).toMoney()}</Paragraph>
                                        </Col>

                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Diferencia</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {parseFloat(parseFloat(parseFloat(total_trabajado) + parseFloat(actual_trabajado)) - parseFloat(parseFloat(parseFloat(total_pagado) + parseFloat(actual_pagado)))).toMoney()}</Paragraph>
                                        </Col>

                                    </Row>,
                                    <Row className={"information-widgets-content "}>
                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Presupuesto Actual</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {parseFloat(actual_presupuesto).toMoney()}</Paragraph>
                                        </Col>

                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Monto Trabajado Actual</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {parseFloat(actual_trabajado).toMoney()}</Paragraph>

                                        </Col>

                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Monto Pagado Actual</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {parseFloat(actual_pagado).toMoney()}</Paragraph>
                                        </Col>

                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Diferencia Actual</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {parseFloat(actual_trabajado - actual_pagado).toMoney()}</Paragraph>
                                        </Col>
                                    </Row>
                                ]}
                            </Card>
                        </Col>
                    </Row> : null}

                    <Row className="p-1" gutter={[0, 16]}>
                        {
                            this.props.etapa === 1 ? <PreliminarList
                                data={inmuebles?.length > 0 ? inmuebles : empleados?.length > 0 ? empleados : []}
                                empleado={this.state.empleado}
                                inmueble={this.state.inmueble}
                                tipo={this.state.tipo}
                                onChangeAvancePorcentual={this.onChangeAvancePorcentual}
                            /> :
                                inmuebles.map(({ inmueble, tajos }, index) => (
                                    <List
                                        bordered={false}
                                        size="large"
                                        className={"list-inmuebles-revision border-radius mt-1 "}
                                        header={
                                            <Row align="middle" justify="start" >
                                                <Col span={(this.props.etapa === 2) ? 4 : 6}  >
                                                    <div className="title-inmueble-revision item-tag">
                                                        <Text ellipsis className="title-row ">{inmueble?.nombre}</Text>
                                                    </div>
                                                </Col>
                                                <Col className="center" span={(this.props.etapa === 2) ? 3 : 8} >
                                                    <div className="title-inmueble-revision "><Text ellipsis className="title-row">{(this.props.etapa === 2) ? "Responsable" : "Empleado"}</Text></div>
                                                </Col>
                                                <Col className="center" span={(this.props.etapa === 2) ? 5 : 7} >
                                                    <div className="title-inmueble-revision "><Text ellipsis className="title-row">Actividad</Text></div>
                                                </Col>

                                                {(this.props.etapa === 2) ?
                                                    <Col className="center" span={3} >
                                                        <div className="title-inmueble-revision "><Text ellipsis className="title-row">Presupuesto</Text></div>
                                                    </Col>
                                                    :
                                                    null
                                                }

                                                {(this.props.etapa === 2) ?
                                                    <Col className="center" span={2} >
                                                        <div className="title-inmueble-revision "><Text ellipsis className="title-row">Avanzado</Text></div>
                                                    </Col>
                                                    :
                                                    null
                                                }

                                                {(this.props.etapa === 2) ?
                                                    <Col className="center" span={2}
                                                    >
                                                        <div className="title-inmueble-revision "><Text ellipsis className="title-row">Pagado</Text></div>
                                                    </Col>
                                                    :
                                                    null
                                                }

                                                <Col className="center"
                                                    span={(this.props.etapa === 2) ? 2 : 3}
                                                >
                                                    <div className="title-inmueble-revision "><Text ellipsis className="title-row">Avance</Text></div>
                                                </Col>


                                                {(this.props.etapa === 2) ?
                                                    <Col className="center" span={3}  >
                                                        <div className="title-inmueble-revision "><Text ellipsis className="title-row">A Pagar</Text></div>
                                                    </Col>
                                                    :
                                                    null
                                                }
                                            </Row>
                                        }
                                        bordered={false}
                                        dataSource={tajos}
                                        renderItem={(tajo, index_tajo) => <List.Item className="bg-white borderless " id={tajo._id}>
                                            <Row align="middle" justify="start" className="w100  ">
                                                <Col className="center" span={(this.props.etapa === 2) ? 4 : 6}>
                                                    <Text ellipsis className="item-row ">{tajo?.inmueble?.nombre}</Text>
                                                </Col>
                                                {(this.props.etapa === 2) ?
                                                    <Col className="center" span={(this.props.etapa === 2) ? 3 : 8}>
                                                        <Text ellipsis className="item-row ">{tajo?.responsable?.nombre} {tajo?.responsable?.apellido}</Text>
                                                    </Col>
                                                    :
                                                    null
                                                }
                                                {(this.props.etapa === 1) ?
                                                    <Col className="center" span={(this.props.etapa === 2) ? 3 : 8}>
                                                        <Text ellipsis className="item-row ">{tajo?.usuario?.nombre} {tajo?.usuario?.apellido}</Text>
                                                    </Col>
                                                    :
                                                    null
                                                }
                                                <Col className="center" span={(this.props.etapa === 2) ? 5 : 7}>
                                                    <Text ellipsis className="item-row ">{tajo.tajo.nombre}</Text>
                                                </Col>

                                                {(this.props.etapa === 2) ?
                                                    <Col className="center" span={3}>
                                                        <Text ellipsis className="item-row ">$ {parseFloat(tajo.presupuesto).toMoney()}</Text>
                                                    </Col>
                                                    :
                                                    null
                                                }

                                                {console.log('tajo.avance', tajo)}
                                                {(this.props.etapa === 2) ?
                                                    <Col className="center" span={2}>
                                                        <Text ellipsis className="item-row ">{parseFloat(tajo.avance)} % </Text>
                                                    </Col>
                                                    :
                                                    null
                                                }

                                                {(this.props.etapa === 2) ?
                                                    <Col className="center" span={2}>
                                                        <Text ellipsis className="item-row ">$ {parseFloat(tajo.total_pagado).toMoney()}</Text>
                                                    </Col>
                                                    :
                                                    null
                                                }

                                                <Col className="center" span={(this.props.etapa === 2) ? 2 : 3}>
                                                    <InputNumber
                                                        min={0}
                                                        max={100}
                                                        step={1}
                                                        formatter={value => `${value}%`}
                                                        parser={value => value.replace('%', '')}
                                                        onChange={(value) => this.onChangeAvancePorcentual(value, tajo, index, index_tajo)}
                                                        defaultValue={((this.props.etapa === 1) ? tajo.avance : tajo.porcentaje_avance_final)}
                                                    //value={((this.props.etapa === 1) ? tajo.porcentaje_avance_final : tajo.avance)}//porcentaje_avance GUARDADO del campo porcentaje_avance de la coleccion
                                                    />
                                                </Col>


                                                {(this.props.etapa === 2) ?
                                                    <Col className="center" span={3}>
                                                        <Text ellipsis className="item-row ">$ {!isNaN(parseFloat(tajo.monto_autorizado)) ? parseFloat(tajo.monto_autorizado).toMoney() : 0}</Text>

                                                    </Col>
                                                    :
                                                    null
                                                }
                                            </Row>
                                        </List.Item>}
                                    />
                                )
                                )
                        }

                    </Row>

                    <Button type="ghost" className="btn-save" disabled={this.state.saving} onClick={(this.props.etapa === 1) ? this.save : (() => this.setState({ visibleModalDiferencias: true }))}>
                        {this.state.saving ? <Spin style={{ position: 'relative', top: '3px' }} spinning={true} /> : <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41 3.82586C40.9961 5.11749 40.5257 6.0772 39.6873 6.8994C34.9386 11.551 30.1977 16.211 25.4539 20.8683C22.7917 23.4819 20.1315 26.0964 17.4674 28.7072C15.7045 30.436 13.2626 30.4284 11.5017 28.6996C8.07832 25.3359 4.64628 21.9807 1.23163 18.6094C0.0773175 17.4695 -0.282018 16.0897 0.223177 14.5581C0.722575 13.0446 1.82763 12.1389 3.42242 11.8829C4.80084 11.661 5.98606 12.1019 6.9723 13.0721C9.34759 15.4078 11.7316 17.736 14.0982 20.0802C14.3889 20.3685 14.5425 20.4197 14.87 20.0964C21.2511 13.8089 27.6476 7.53478 34.0422 1.25968C35.1994 0.123585 36.5846 -0.277558 38.1533 0.193762C39.6795 0.651805 40.6001 1.7054 40.9324 3.24074C40.9826 3.47119 40.9874 3.71017 41 3.82586Z" fill="white" />
                        </svg>}
                    </Button>

                </Spin>
                <ModalDiferencia
                    visible={this.state.visibleModalDiferencias}
                    onClose={() => this.setState({ visibleModalDiferencias: false })}
                    montoDeuda={parseFloat(parseFloat((total_trabajado*100) + (actual_trabajado*100)) -  parseFloat(parseFloat((total_pagado*100) + (actual_pagado*100))))/100}
                    etapa={this.props.etapa}
                    onFinish={this.save}
                />
            </Row >

        )
    }
}


class PreliminarList extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }


    render() {

        const { tipo, empleado, inmueble } = this.props
        let nombre = ''
        if (empleado) nombre = `${empleado.nombre} ${empleado.apellido}`
        else if (inmueble) nombre = `${inmueble.nombre}`
        return (
            <div style={{ width: '100%', marginBottom: '5rem' }}>
                {this.props.data ?
                    this.props.data.map(({ inmueble, tajos, empleado }, index) => (
                        <List
                            bordered={false}
                            size="large"
                            loading={this.props.loading}
                            className={"list-inmuebles-revision border-radius mt-1 "}
                            header={
                                <Row align="middle" justify="start" >
                                    <Col span={6}  >
                                        <div className="title-inmueble-revision item-tag">
                                            <Text ellipsis className="title-row ">{inmueble ? inmueble.nombre : `${empleado.nombre} ${empleado.apellido}`}</Text>
                                        </div>
                                    </Col>
                                    <Col className="center" span={8} >
                                        <div className="title-inmueble-revision "><Text ellipsis className="title-row">{tipo === 'empleado' ? 'Empleado' : 'Inmueble'}</Text></div>
                                    </Col>
                                    <Col className="center" span={7} >
                                        <div className="title-inmueble-revision "><Text ellipsis className="title-row">Actividad</Text></div>
                                    </Col>

                                    <Col className="center" span={3} >
                                        <div className="title-inmueble-revision "><Text ellipsis className="title-row">Avance</Text></div>
                                    </Col>
                                </Row>
                            }
                            bordered={false}
                            dataSource={tajos}
                            renderItem={(tajo, index_tajo) => <List.Item className="bg-white borderless " id={tajo._id}>
                                <Row align="middle" justify="start" className="w100  ">
                                    <Col className="center" span={1}>
                                        {tajo.autorizado_preliminar ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null}
                                    </Col>
                                    <Col className="center" span={6}>
                                        <Text ellipsis className="item-row ">{inmueble ? inmueble.nombre : `${empleado.nombre} ${empleado.apellido}`}</Text>
                                    </Col>
                                    <Col className="center" span={7}>
                                        <Text ellipsis className="item-row ">{nombre}</Text>
                                    </Col>
                                    <Col className="center" span={7}>
                                        <Text ellipsis className="item-row ">{tajo.tajo.nombre}</Text>
                                    </Col>

                                    <Col className="center" span={(this.props.etapa === 2) ? 2 : 3}>

                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={(value) => this.props.onChangeAvancePorcentual(value, tajo, index, index_tajo)}
                                            defaultValue={tajo.porcentaje_avance_final ? tajo.porcentaje_avance_final : tajo.porcentaje_avance}
                                        />
                                    </Col>
                                </Row>
                            </List.Item>}
                        />

                    ))

                    : null}

            </div>
        )
    }
}
