import React, { Component } from 'react';
import { Card, Statistic, Typography, List, Spin } from 'antd'


import axios from 'axios';
import CustomAvatar from '../../Widgets/Avatar/Avatar';


const { Title, } = Typography;



/**
 *
 *
 * @export
 * @class CardEgresosPorProyecto
 * @extends {Component}
 * 
 * @description Card de proyectos.
 */
export default class CardEgresosPorProyecto extends Component {


    /**
     *
     *
     * @state proyectos Arreglo de proyectos
     * @state filters Filtros del form de filtros
     */
    state = {
        proyectos: [],
        filters: {}
    }

    /**
     *
     *
     * @memberof CardEgresosPorProyecto
     * @method componentDidMount Inicializamos el componente
     */
    componentDidMount() {

        this.state.update = this.props.update
        this.getProyectos()
    }

    /**
     *
     *
     * @memberof CardEgresosPorProyecto
     * @method componentDidMount Actualizamos los filtros en caso de algún cambio
     */
    componentDidUpdate() {
        if (this.state.update !== this.props.update) {
            this.state.update = this.props.update
            this.state.filters = this.props.filters
            this.getProyectos()
        }
    }

    /**
     *
     *
     * @param {*} [{ filters = this.state.filters }={}]
     * @memberof CardEgresosPorProyecto
     * 
     * @method getProyectos Obtenemos los proyectos.
     */
    getProyectos = ({ filters = this.state.filters } = {}) => {
        this.setState({ loading: true })
        
        axios.post('/dashboard/egresos-proyectos', filters)
            .then(({ data }) => {
                console.log(data)
                this.setState({
                    proyectos: data.data,
                    loading: false
                })
            }).catch(error => {
                console.log(error)
            })
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Card className="card-dashboard" justify="center" style={{ textAlign: "center" }}>
                    <Title level={4} style={{ fontWeight: "bold", marginBottom: '1em' }}>Egresos por Proyecto</Title>
                    <List
                        itemLayout="horizontal"
                        dataSource={this.state.proyectos}
                        className="list-projects"
                        renderItem={({ nombre, monto, proyecto }) => (
                            <List.Item
                                actions={[
                                    <Statistic prefix="$" precision={2} value={monto} />
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <CustomAvatar
                                            image={proyecto?.logo}
                                            name={proyecto?.nombre}
                                            color={proyecto?.color}
                                        />
                                    }
                                    title={<Title level={5} style={{ textAlign: "start", fontWeight: "bold" }}>{nombre}</Title>}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Spin>
        )
    }
}
