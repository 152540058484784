import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Divider, Spin, DatePicker } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar'
import PrioridadSelect from '../../../Widgets/prioridadSelect'
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment  = require('moment')
/**
 *
 *
 * @class ModalRequisiciones
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalRequisiciones extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            almacenes: [],
            requisiciones: [],
        }
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function componentDidMount
     * @description Obtiene la informacion inicial
     */
    componentDidMount(){
        if(this.props.fecha_seleccionada)
            this.getData()
    }


    /***
     *
     * @methodOf CalenarioMaterial
     * @function getData
     * @description Obtenemos las requisiciones de la fecha indicada 
     */
    getData = () => {
        axios.get('/requisiciones/entrega/calendario',{
            params: {
                dia: moment(this.props.fecha_seleccionada).date(),
                mes: moment(this.props.fecha_seleccionada).month() + 1,
                año: moment(this.props.fecha_seleccionada).year()
            }
        }).then(response => {
            this.setState({
                requisiciones: response.data.data
            })
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        })
    }

    render() {

        const { requisiciones } = this.state

        return [

            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title">Requisiciones de {moment(this.props.fecha_seleccionada).format('DD-MM-YYYY')}</Title>
            </div>,
            <Spin spinning={this.state.loading}>


                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                >
                    <Row align="center" gutter={[16]} style={{ maxHeight: '530px', overflow: 'hidden', overflowY: 'auto', marginBottom: '1rem' }}>

                        {
                            requisiciones.map(req => <Col 
                                span={20} 
                                className={`row-requisicion tag-estatus-${req.estatus}`}
                                onClick={()=>this.props.openDetalle(req._id)}
                            >
                                <Text className="folio">{req.folio}</Text>
                                <Text className="fecha">{moment(req.fecha_entrega).format('DD-MM-YYYY HH:mm')}</Text>
                                <Text className="producto">{req.producto_id.nombre}</Text>
                            </Col>)
                        }

                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" onClick={()=>this.props.onCancel()}>
                                    Cerrar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}



export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalRequisiciones {...props} />
    </Modal>


}
