import React, {Component} from 'react';
import {Button, Col, Row, Spin, Statistic, Typography, List, Card, Divider, Breadcrumb, InputNumber, Modal} from 'antd'
import {FaArrowRight} from "react-icons/fa";
import {Link, Redirect} from 'react-router-dom';

//componentes
import {CardInfo} from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import { IconArrowBack} from '../../Widgets/Iconos';
//css
import '../../../Styles/projectManager.css'
import CustomAvatar from "../../Widgets/Avatar/Avatar";

const axios = require('axios').default;
const moment = require('moment')
const {Text} = Typography;


export default class PonderacionInmuebles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            manager_inmueble: {
                proyecto_id: {},
                manager_proyecto_id: {},
                inmueble_id: {},
                costo_presupuestado: 0,
                progreso_proyectado: 0,
                progreso_real: 0,
            },
            actividades: [],
            loadingHeader: false,
            loadingContent: false,
            total: 0,
            redirect: false,
            sumaProgresoProyectado: 0,
        }
    }


    /**
     *
     * @function componentDidMount
     * @description Iniciamos el componente obteniendo las actividades
     *
     */
    componentDidMount() {
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadActividades()
    }


    /**
     * @memberof PonderacionInmuebles
     *
     * @method   loadActividades
     * @description  trae la lista de actividades de un inmueble
     *
     **/
    loadActividades = (page) => {
        this.setState({loading: true})
        axios.get("/manager/proyectos/inmuebles/actividades", {
            params: {
                pagination: false,
                manager_inmueble_id: this.props.match.params.manager_inmueble_id //--------------------------id de url
            },
        })
            .then(({data}) => {
                let total = 0;
                this.setState({
                    loading: false,
                    actividades: data.data.actividades,
                    manager_inmueble: data.data.inmueble,
                    sumaProgresoProyectado: data.extra.sumaProgresoProyectado,
                    total: data.extra.sumaProgresoProyectado,
                })
            })
            .catch(e => console.log('error al cargar las actividades', e))
    }

    /**
     *
     * @memberof PonderacionInmuebles
     * @method updatePorcentaje
     * @description Actualizamos los porcentajes de la sactividades.
     *
     * @param {*} value Valor a actualizar en porcentaje
     * @param {*} index Posición en el arreglo
     */
    updatePorcentaje = (value, index)=>{
        let {actividades, total} = this.state;

        total = parseFloat(total);;
        value = parseFloat(value)

        actividades[index].progreso_proyectado = 0
        total = actividades.reduce((total, item)=>{return total + item.progreso_proyectado}, 0)

        if ((total + value) > 100)
        {
            actividades[index].error = true;
            actividades[index].progreso_proyectado = 100 - total
            setTimeout(() => {
                actividades[index].error = false;
                this.setState({
                    actividades,
                    total : actividades.reduce((total, item)=>{return total + item.progreso_proyectado}, 0)
                })
            }, 1500);
        }else{
            actividades[index].error = false;
            actividades[index].progreso_proyectado = value;
            total = actividades.reduce((total, item)=>{return total + item.progreso_proyectado}, 0)
        }
        this.setState({
            actividades,
            total
        })
    }


    /**
     *
     * @memberof PonderacionInmuebles
     * @method saveActividades
     * @description Guardamos todo el arreglo.
     *
     */
    saveActividades = () => {
        axios.put('/manager/proyecto/inmuebles/actividades/porcentajes',{
            manager_inmueble_id: this.props.match.params.manager_inmueble_id,
            actividades: this.state.actividades
        })
            .then(e=>{
                this.props.history.goBack()
            })
            .catch(e=>{
                console.log('e',e)
                Modal.error({
                    title: "No es posible guardar los porcentajes",
                    content: "Ocurrio un error al guardar."
                })
            })
    }

    render() {
        let {saveActividades, redirectTo} = this
        return (
            <div style={{ paddingBottom: "65px" }}>
                <Spin spinning={this.state.loadingHeader}>
                    <Row className="row-encabezado">
                        <Col xs={24} md={1} className="mb-1">
                            <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline', padding: '3px', top: '12px' }}>
                                <IconArrowBack size={0.7} />
                            </Button>
                        </Col>
                        <Col xs={23} className="flex-left">
                            <Breadcrumb className="breadcrumb" separator=">">
                                <Breadcrumb.Item>
                                    <Link to="/admin/manager">Proyecto</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrumb-actual"> {this.state.manager_inmueble.inmueble_id.nombre} </Breadcrumb.Item>

                            </Breadcrumb>
                        </Col>
                    </Row>
                </Spin>
                <Spin spinning={this.state.loadingContent}>
                    <section className="p-1">

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Proyecto"
                                    infoLeft={this.state.manager_inmueble.proyecto_id.nombre}
                                    infoRight={
                                        <CustomAvatar
                                            image={this.state.manager_inmueble.proyecto_id.logo}
                                            name={this.state.manager_inmueble.proyecto_id.nombre}
                                            color={this.state.manager_inmueble.proyecto_id.color}
                                            size={"large"}
                                            style={{width:"55px", height: "55px", fontSize:"20pt",display: 'flex', alignItems: 'center' }}
                                        />
                                    } />
                            </Col>

                            <Col xs={24} lg={8} className="gutter-row">

                                <CardInfo titleLeft="Ponderación Porcentual"
                                          infoLeft={`${this.state.sumaProgresoProyectado.toMoney(2)} %`}
                                          titleRight="Progreso Real"
                                          infoRight={`${this.state.manager_inmueble.progreso_real.toMoney(2)} %`} />

                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo titleLeft="Fecha Inicial"
                                          titleRight="Fecha Final"
                                          infoLeft={moment(this.state.manager_inmueble.fecha_inicio).format('DD-MM-YYYY')}
                                          infoRight={moment(this.state.manager_inmueble.fecha_conclusion).format('DD-MM-YYYY')} />
                            </Col>
                        </Row>

                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.actividades}
                            header={<List.Item className="component-list-item" style={{width: '100%'}}>
                            <Row style={{width: '100%'}} >
                                <Col span={18}>
                                    <p>Nombre</p>
                                </Col>
                                <Col span={6}>
                                    <p>Porcentaje</p>
                                </Col>
                            </Row>
                        </List.Item>}
                            renderItem={(item, index) => (
                                <List.Item className="component-list-item" style={{width: '100%'}}>
                                    <Row style={{width: '100%'}} gutter={[16, 0]}>
                                        <Col xs={12} md={18}>
                                            <Card className="card-list">
                                                <Row style={{width: '100%'}}>
                                                    <Col xs={24} md={10} className="center ">
                                                        <Text strong>{item.actividad_id.nombre}</Text>
                                                    </Col>

                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Card className={"card-list ponderacion-cantidad " + (item.error?"error-porcentaje":"")}  style={{padding: '7px'}}>
                                                <Row style={{width: '100%'}} className="">
                                                    <Col span={24} className="center " style={{ position: 'relative' }}>
                                                        {( item.error ?<p style={{
                                                            position: 'absolute',
                                                            bottom: '-27px',
                                                            left:'unset',
                                                            fontSize: '10px',
                                                            color: 'red'
                                                        }} >El porcentaje total debe de ser menor a 100%</p>:null)}
                                                        <InputNumber
                                                            style={{
                                                                textAlign: 'center',
                                                                color:  (item.error?"red":"unset")
                                                            }}

                                                            onChange={(value)=>this.updatePorcentaje(value, index)}
                                                            value={item.progreso_proyectado}
                                                            precision={2}
                                                            formatter={value => `${value}%`}
                                                            parser={value => value.replace('%', '')}
                                                            className="input-ghost"
                                                            bordered={false}
                                                            max={100}/>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                            footer={<List.Item className="component-list-item" style={{width: '100%'}}>
                                <Row style={{width: '100%'}} gutter={[16, 0]}>
                                    <Col xs={{span: 12, offset: 12}} md={{span: 6, offset: 18}}>
                                        <Divider />
                                        <Card className="card-list">
                                            <Row style={{width: '100%'}} className="">
                                                <Col span={24} className="center ">
                                                    <Statistic precision={2} suffix={'%'} title="Total" value={this.state.total} />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </List.Item>}
                        />

                    </section>


                    <Button type="ghost" className="btn-save" onClick={() => saveActividades()} disabled={this.state.uploadingImages}>
                        <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41 3.82586C40.9961 5.11749 40.5257 6.0772 39.6873 6.8994C34.9386 11.551 30.1977 16.211 25.4539 20.8683C22.7917 23.4819 20.1315 26.0964 17.4674 28.7072C15.7045 30.436 13.2626 30.4284 11.5017 28.6996C8.07832 25.3359 4.64628 21.9807 1.23163 18.6094C0.0773175 17.4695 -0.282018 16.0897 0.223177 14.5581C0.722575 13.0446 1.82763 12.1389 3.42242 11.8829C4.80084 11.661 5.98606 12.1019 6.9723 13.0721C9.34759 15.4078 11.7316 17.736 14.0982 20.0802C14.3889 20.3685 14.5425 20.4197 14.87 20.0964C21.2511 13.8089 27.6476 7.53478 34.0422 1.25968C35.1994 0.123585 36.5846 -0.277558 38.1533 0.193762C39.6795 0.651805 40.6001 1.7054 40.9324 3.24074C40.9826 3.47119 40.9874 3.71017 41 3.82586Z" fill="white" />
                        </svg>
                    </Button>
                </Spin>
            </div>
        )
    }
}
