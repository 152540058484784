import React, { Component, useState } from "react";
import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Spin, Tooltip, Typography, Upload, } from 'antd';
import './../../../../Styles/modales.css'
import { IconCloseModal } from '../../../Widgets/Iconos';
import PropTypes from "prop-types";
import { LoadingOutlined, PlusOutlined, QuestionCircleFilled, DeleteOutlined } from "@ant-design/icons";
import ColorPicker from '../../../Widgets/ColorPicker/ColorPicker';

const moment = require('moment');
const axios = require('axios').default;
const { Title, } = Typography;


export default class ModalProyecto extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };



    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            form: {},
            loadingImage: false
        }
    }


    componentDidMount(): void {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    /**
     * @methodOf ModalProyecto
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualiza una propiedad o un
     *
     * */
    componentDidUpdate() {
        //Si la propiedad cambia y es diferente al estado, actualizamos el estado con el ID de la poppiedad y buscamos al usuario
        if (this.props.id !== this.state.id && this.props.id !== undefined && this.props.id !== null) {
            this.state.id = this.props.id
            this.getProject(this.props.id)
        }

        //Si la propiedad es diferente de la actual y el estado es diferente de nada y la propiedad es nada, reiniciamos el formulario.
        if (this.props.id !== this.state.id && this.state.id !== undefined && this.props.id === undefined) {
            this.state.id = undefined;
            this.state.form = {};
            this.state.image = undefined;
            this.formModalProject.current.resetFields();
        }
    }

    /**
     * @methodOf ModalProyectos
     * @function getProject
     *
     * @description Obtiene el proyecto
     *
     * */
    getProject = (id) => axios.get("/proyectos/get", {
        params: { id }
    })
        .then(async ({ data }) => {
            const logo = data.data.logo;
            if (data.data.logo !== undefined && data.data.logo !== null && data.data.logo !== "") {
                this.setState({
                    image: {
                        url: axios.defaults.baseURL + '/upload/' + logo,
                        name: logo
                    }
                })
                data.data.logo = [{
                    uid: -1,
                    name: data.data.logo,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + data.data.logo,
                    response: { filename: data.data.logo }
                    // e.file.response.filename
                }];

            } else data.data.logo = []
            this.setState({
                form: data.data,
            })
            await this.formModalProject.current.resetFields();
        })
        .catch(res => {
            console.log("no obtenida", res);
        })


    /**
     * @methodOf ModalProyectos
     * @function saveProject
     *
     * @description Guarda el proyecto
     *
     * */
    saveProject = values => {
        if (this.state.image)
            values.logo = this.state.image.name;
        else
            values.logo = null;
        if (values._id) {

            axios.put('/proyectos/update', { ...values, id: values._id })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el proyecto!")
                    this.props.accept();
                })
                .catch((e) => {
                    message.error('No se pudo guardar el proyecto')
                    console.log('e', e)
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        } else {
            axios.post('/proyectos/add', { ...values, id: values._id })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el proyecto!")
                    this.props.accept();
                })
                .catch((e) => {
                    message.error('No se pudo guardar el proyecto')
                    console.log('e', e)
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        }
    }

    formModalProject = React.createRef();


    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            if (this.state.image)
                this.removeFile(this.state.image.name)

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })
            if (this.state.form._id)
                axios.put('/proyectos/update', { logo: e.file.response.filename, id: this.state.form._id })
                    .finally(() => {
                        this.props.update()
                    });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !== undefined) ? file.response.filename : file.name)


        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalProyectos
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        this.state.form.avatar = [];
        this.state.image = undefined;
        this.formModalProject.current.resetFields();
        if (this.state.form._id)
            axios.put('/proyectos/update', { logo: 0, id: this.state.form._id })
                .finally(() => {
                    this.props.update()
                    this.setState({ loadingImage: false })
                })
        else {
            this.props.update()
            this.setState({ loadingImage: false })
        }
    };

    render() {
        const { normFile, formModalProject, saveProject, deleteImage } = this;
        const { visible, hideModal, user } = this.props;
        const { form, image, loading, id, loadingImage } = this.state;



        return (
            <Modal
                visible={visible}
                onCancel={hideModal}
                title={null}
                footer={null}
                closable={false}

                maskClosable={!loadingImage}

                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{id ? "Editar Proyecto" : "Nuevo Proyecto"}</Title>
                </div>
                <Spin spinning={loading}>
                    <Form
                        layout="vertical"
                        ref={formModalProject}
                        initialValues={form}
                        className="frm-cuentas"
                        name="formulario-transacciones"
                        onFinish={saveProject}
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Row align="center">
                            <Form.Item
                                name="logo"
                                align="center"
                                getValueFromEvent={normFile}
                                // valuePropName={props?"fileList":"value"}
                                valuePropName="fileList"
                                help={image ? <Button
                                    shape="circle"
                                    danger style={{ position: 'absolute', right: '40%', bottom: '20px' }}
                                    onClick={deleteImage}
                                ><DeleteOutlined /></Button> : null}
                            >
                                <Upload
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    accept="image/*"
                                >

                                    {(loadingImage) ? <div>
                                        {(this.state.loading || loadingImage) ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div className="ant-upload-text">Upload</div>
                                    </div> : ((image) ?
                                        <img src={image.url} alt="avatar" style={{ width: '100%' }} />
                                        :
                                        <div>
                                            {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                            <div className="ant-upload-text">Upload</div>
                                        </div>)}
                                </Upload>
                            </Form.Item>
                        </Row>
                        <Row>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Input placeholder="Nombre"></Input>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese descripción"
                                    }]}
                                >
                                    <Input placeholder="Descripción"></Input>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={24} lg={{ span: 23 }}>
                                <Form.Item
                                    label="Razón Social"
                                    name="razon_social"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la razón social"
                                    }]}
                                >
                                    <Input placeholder="Razón Social"></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={12} style={{ margin: 0 }} className="mb-1">
                                <Form.Item
                                    label="Color"
                                    name="color"

                                >
                                    <ColorPicker
                                        colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                    />
                                </Form.Item>
                            </Col>


                            {(user && user.tipo === 1) ? <Col xs={24} lg={12} style={{ margin: 0 }} className="mb-1 flex-left">
                                <Form.Item
                                    style={{ height: '35px' }}
                                    name="privado"
                                    valuePropName="checked"
                                >
                                    <Checkbox>
                                        Privado <Tooltip title="Al marcar el proyecto como privado, no será visible para ningun usuario.">
                                            <QuestionCircleFilled />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                            </Col> : null}


                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-azul">
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
