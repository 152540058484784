import React, { Component } from 'react';
import { Button, Popconfirm, Tooltip, Space, Card, Row, List, Col, Spin, PageHeader, Layout, message, Typography } from 'antd'
import axios from 'axios';

//Componentes
import { IconDelete, IconEdit, IconArrowBack, IconPlus } from '../../../Widgets/Iconos';
//modal
import ModalProductoAlmacen from './ModalProductoAlamcen'


const { Content } = Layout;
const { Text, Title } = Typography

/**
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista de Productos
 */
export default class AlmacenProductos extends Component {

    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            inventario_id: this.props.match.params.inventario_id,
            proyecto_id: this.props.match.params.proyecto_id,
            almacen_id: this.props.match.params.almacen_id,
            proyecto: {},
            almacen:{},
            productos: [],

            page: 1,
            total: 0,
            limit: 10,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
        this.getProyecto()
        this.getAlmacen()
        this.getProductosAlmacen()

    }

    /**
    * @memberof Almacenes
    * @method getProyecto
    * @description  Obtiene la informacion del proyecto
    **/
    getProyecto = () => {
        axios.get('/proyectos/get',{
            params:{
                id: this.props.match.params.proyecto_id
            }
        }).then(response => {
            this.setState({proyecto: response.data.data})
        }).catch(error => {
            message.error('Error al obtener la información del proyecto')
        })
    }

    /**
    * @memberof Almacenes
    * @method getAlmacen
    * @description  Obtiene la informacion del proyecto
    **/
    getAlmacen = () => {
        axios.get('/proyectos/almacenes/get',{
            params:{
                almacen_id: this.props.match.params.almacen_id
            }
        }).then(response => {
            this.setState({almacen: response.data.data})
            
        }).catch(error => {
            message.error('Error al obtener la información del almacén')
        })
    }

    /**
    * @memberof Almacenes
    * @method getProductosAlmacen
    * @description  Obtiene los productos registrados del alamcen
    **/
    getProductosAlmacen = (page = this.state.page) => {
        axios.get('/almacen/productos',{
            params:{
                almacen_id: this.props.match.params.almacen_id,
                page,
            }
        }).then(response => {
            console.log("response", response.data.data);
            this.setState({
                productos: response.data.data.data
            })
            
        }).catch(error => {
            message.error('Error al obtener la información del almacén')
        })
    }

    


    render() {

        const { productos } = this.state

        return (
            <Spin spinning={false}>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <Title level={4} className="view-title">Productos del almacén {this.state.almacen?.nombre} de {this.state.proyecto?.nombre}</Title>
                    </Col>
                </Row>
                <Content className="p-1">
                    <List
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Productos " }}
                        dataSource={productos}
                        loading={this.state.loading}
                        pagination={{
                            onChange: (page, limit) => this.getProductosAlmacen(page),
                            current: this.state.page,
                            pageSize: 10,
                            total: this.state.total,

                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left"
                        }}
                        header={<Row className="header-list width-100 pr-1 pl-1" >

                            <Col lg={8} className="center">
                                <Text strong>Producto</Text>
                            </Col>
                            <Col lg={6} className="center">
                                <Text strong>Cantidad</Text>
                            </Col>
                            <Col lg={6} className="center">
                                <Text strong>Ubicación</Text>
                            </Col>
                            <Col lg={4} className="center">
                                <Text strong>Acciones</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col lg={8} className="flex-left-column">
                                            <Text>{item.inventario_producto_id?.producto_id?.sku_fabricante} <small>{item.inventario_producto_id?.producto_id?.sku_manantial}</small></Text>
                                            <Text>{item.inventario_producto_id?.producto_opcion_id.sku_proveedor}</Text>
                                        </Col>
                                        <Col lg={6} className="center">
                                            <Text>{item.cantidad}</Text>
                                        </Col>
                                        <Col lg={6} className="center">
                                            <Text>{item.ubicacion}</Text>
                                        </Col>
                                        <Col lg={4} className="center">
                                            <Space>
                                                <Button type="primary" className="btn-edit button-edit"icon={<IconEdit />}  onClick={() => this.setState({
                                                    modal_visible: true,
                                                    almacen_producto_id: item._id,
                                                })} />

                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este Producto  del Almacen?"
                                                    onConfirm={() => axios.delete('/almacen/productos/delete', { params:{ id: item._id }})
                                                        .then((response) => {
                                                            message.success("Eliminado Correctamente")
                                                            this.getProductosAlmacen()
                                                        })
                                                        .catch((error) => {
                                                            message.error("No es posible eliminar")
                                                            this.getProductosAlmacen();
                                                        })
                                                    }
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    
                                                    <Button className="btn btn-delete" icon={<IconDelete />} title="Eliminar" />
                                                    
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <Button className="btnAddPlus" title="Nuevo registro" onClick={()=>this.setState({modal_visible: true})}>
                        <IconPlus />
                    </Button>
                </Content>
                <ModalProductoAlmacen
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, almacen_producto_id: undefined})
                        this.getProductosAlmacen()
                    }}
                    inventario_id={this.state.inventario_id}
                    almacen_id={this.state.almacen_id}
                    almacen_producto_id={this.state.almacen_producto_id}
                />
                
            </Spin>
        )
    }
}