import React, { Component } from 'react';
import { Button, Popconfirm, Space, Row, List, Col, Spin, PageHeader, Card, Layout, message, Typography, Avatar, Collapse, Pagination } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import { IconPlus } from '../../Widgets/Iconos';
import InfiniteScroll from "react-infinite-scroll-component";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
//Componentes
import VerDetalle from '../../Widgets/VerDetalle';
import { IconDelete, IconMoney, IconEye } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar'
//modales



const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography
const moment = require('moment');

/**
 * @export
 * @class HistorialPrecioLista
 * @extends {Component}
 * @description Vista de HistorialPrecioLista
 */
export default class HistorialPrecioLista extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],

            page: 1,
            limit: 10,
            total: 0,


            search: null
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        window.scrollTo(0, 0)

        this.getProductos()
    }


    componentDidUpdate(prevProps) {
        var propFiltro = this.props.search;
        console.log("propFiltro", propFiltro);
        if(this.state.search != this.props.search){
            this.state.search = this.props.search
            this.getProductos()
        }
       
    }


    /**
    * @memberof HistorialPrecioLista
    * @method getProductos
    * @description  Obtiene los productos registrados del alamcen
    **/
    getProductos = (page = this.state.page) => {
        axios.get('/productos/historial/list',{
            params:{
                page,
                search: this.props.search
            }
        }).then(response => {
            console.log("response", response.data.data);
            this.setState({
                data: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount
            })
            
        }).catch(error => {
            message.error('Error al obtener la información del almacén')
        })
    }




    render() {

        return (
            <div>
                <Spin spinning={false}>
                    <Row className="row-encabezado">
                        <Col xs={12} xl={15} xxl={15} className="flex-left">
                            <Title level={4}className="view-title">Historial de precio</Title>
                        </Col>
                    </Row>
                    <Content className="admin-content content-bg p-1">

                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.data}
                            locale={{emptyText: 'Sin Requisisciones'}}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row style={{ width: '100%' }} className="">

                                            <Col xs={12} md={10} className="flex-left">
                                                { (Array.isArray(item.producto_id?.imagenes) && item?.producto_id?.imagenes.length  > 0) ? 
                                                    <Avatar style={{ marginRight: 10 }} shape="square" src={axios.defaults.baseURL + "/upload/" + item?.producto_id?.imagenes[0].filename } size={60} /> : 
                                                    <Avatar style={{ marginRight: 10 }} shape="square" src={'/images/img-video.png' } size={60} />
                                                }
                                                <Text>{item.producto_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text className="text-gray">Última Actualización {moment(item.createdAt).format('DD-MM-YYYY HH:mm')}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="flex-right">
                                               <Text>$ {item.precio.toMoney()} MXN</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Link to={`/admin/productos/historial/${item.producto_id?._id}`}>
                                                    <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle"  >
                                                        <IconEye />
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                        <Row>
                            <Col span={24}>

                                <Pagination
                                    current={this.state.page}
                                    total={this.state.total}
                                    pageSize={this.state.limit}
                                    showSizeChanger={false}
                                    onChange={(page) => this.getProductos(page)}

                                />
                            </Col>
                        </Row>
                   
                    </Content>
                </Spin>
            </div>
            
        )
    }
}