import React, { Component } from 'react';
import { DatePicker, PageHeader, Spin, Button, Col, Row, Typography, List, Card, Popconfirm, Space, message, InputNumber, Tooltip, Modal } from 'antd'
import { HighlightOutlined, PlusOutlined, CheckOutlined, CarryOutOutlined } from '@ant-design/icons';
import qs from 'qs';
//componentes
import { IconCheckGreen, IconDelete, IconEdit, IconTruck } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import ModalMaterialOrden from '../Modales/Ordenes/ModalMaterialOrden';
import Logged from "../../../Hooks/Logged";
import ModalTicketQr from './ModalTicketQr'
//css
import '../../../Styles/ordenesMaterial.css'

const moment = require('moment');
const axios = require('axios').default;
const { Text, Title, Paragraph, } = Typography;

export default class OrdenMaterial extends Component {

    static contextType = Logged;
    printQrRef = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            projects: [],
            materiales: [],
            fecha: moment(),
            folio: "GM-001",
            loading: false,
            orden_id: this.props.match.params.id,
            edicion: false,
            editable: true,
            estatus: 1,

            qr_status: false
        }
    }
    

    /**
    * @memberof OrdenMaterialCreacion
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se muestra el filtro superior
    * Se establece el valor del header en axios
    * Se llama al metodo receivedData que hace la consulta de materiales a mostrar en la tabla
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.props.updateFilterSearch(false);

        if (this.state.orden_id !== undefined) {
            this.getOrden(this.state.orden_id)
        } else {
            this.getFolio()
        }
    }

    /**
     *
     *
     * @param {*} values Son los nuevos valores de la orden de material
     * @param {*} id En caso de que haya un ID, se actualiza, si es undefined se agrega.
     * 
     * @memberof OrdenMaterial
     * @method setMateria
     * 
     * @description Actualizamos o agregamos el material
     */
    setMaterial = (values, id) => {
        let { materiales } = this.state
        if (typeof id === "number") {
            materiales[id] = values
        } else {
            materiales.push(values)
        }
        this.setState({
            materiales, visible: false,
            material: undefined,
            id: undefined,
            edicion: false,
            index: undefined,
        })
    }

    /**
     *
     *
     * @param {*} id Se elimina el ID
     * 
     * @memberof OrdenMaterial
     * @method deleteMaterial
     * 
     * @description Eliminamos el ID
     */
    deleteMaterial = (material_id, index) => {
        let { materiales } = this.state
        //const index = materiales.findIndex(e => e._id === id)
        materiales.splice(index, 1)
        this.setState({ materiales, visible: false })

        if (material_id) {
            axios.post('/ordenes/material/delete', {
                ordenMaterial_id: material_id,
                orden_id: this.state.orden_id,
            }).then(res => message.success('Material eliminado'))
                .catch(err => message.error('No se elimino el Material'))
        }
    }

    /**
     *
     *
     * @param {*} id Se elimina el ID
     * 
     * @memberof OrdenMaterial
     * @method getOrden
     * 
     * @description Obtiene la informacion de una orden
     */
    getOrden = (id = this.state.orden_id) => {
        axios.get('/ordenes/get', {
            params: {
                orden_id: id
            }
        }).then(response => {

            //saber si se puede editar
            let now = new Date().getTime()
            let creacion = new Date(response.data.data.createdAt).getTime()
            let editable = null
            let minutes = (now - creacion) / 60000

            if (minutes <= 5) {
                editable = true
            } else editable = false
            this.setState({
                estatus: response.data.data.estatus,
                orden: response.data.data,
                folio: response.data.data.folio_id,
                materiales: response.data.data.materiales,
                fecha: moment(response.data.data.fecha_creacion),
                edicion: true,
                editable: editable,
                qr_status: response.data.data.qr_status,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la Información')
        })
    }

    /**
     *
     *
     * @param {*} id Se elimina el ID
     * 
     * @memberof OrdenMaterial
     * @method getFolio
     * 
     * @description Obtiene el utilimo posible folio
     */
    getFolio = () => {
        axios.get('/ordenes/new/folio', {})
            .then(response => {
                this.setState({ folio: response.data.data.folio })
            }).catch(error => {
                console.log(error)
                message.error('Error al obtener el nuevo folio')
            })
    }


    /**
     * @memberof OrdenMaterial
     * 
     * @method crearOrden
     * 
     * @description Actualiza o crea una orden segun sea el caso
     */
    guardarOrden = () => {
        if (this.state.orden_id) {
            this.actualizarOrden()
        } else {
            this.crearOrden()
        }
    }



    /**
     * @memberof OrdenMaterial
     * 
     * @method crearOrden
     * 
     * @description Metodo que sirva para crear una nuava orden
     */
    crearOrden = () => {
        this.setState({ loading: true })
        axios.post('/ordenes/add', {

            fecha_creacion: this.state.fecha,
            ordenes_materiales: this.state.materiales.map(material => ({
                material_id: material.material_id._id,
                proyecto_id: material.proyecto_id._id,
                tipo_id: material.tipo_id._id,
                cantidad: material.cantidad,
                unidad: material.unidad,
                especificaciones: material.especificaciones
            }))
            // * @param request.body.fecha_creacion Fecha de Creación de la Orden
            // * 
            // * @param request.body.ordenes_materiales[*].material_id ID del material a agregar
            // * @param request.body.ordenes_materiales[*].proyecto_id ID del proyecto al que pertenece el material.
            // * @param request.body.ordenes_materiales[*].cantidad Cantidad de material (toneladas o piezas)
            // * @param request.body.ordenes_materiales[*].unidad unidad (0 piezas, 1 tons)
        })
            .then(e => this.props.history.push("/admin/ordenes/lista"))
    }

    /**
     * @memberof OrdenMaterial
     * 
     * @method actualizarOrden
     * 
     * @description Metodo que sirva para crear una nuava orden
     */
    actualizarOrden = () => {
        this.setState({ loading: true })
        axios.post('/ordenes/update', {
            orden_id: this.state.orden_id,
            fecha_creacion: this.state.fecha,
            //solo mandamos los materiales nuevos
            ordenes_materiales: this.state.materiales.filter(m => m._id === undefined).map(material => {
                return {
                    material_id: material.material_id._id,
                    proyecto_id: material.proyecto_id._id,
                    cantidad: material.cantidad,
                    unidad: material.unidad,
                    especificaciones: material.especificaciones
                }
            })
        }).then(e => { this.props.history.push("/admin/ordenes/lista") })
            .catch(error => {
                console.log(error)
                if (error.response?.status === 403)
                    message.error(error.response.data.message)
                else
                    message.error('Ocurrio un problema al guardar')
            }).finally(() => { this.setState({ loading: false }) })
    }




    /**
    * @memberof OrdenMaterial
    * 
    * @method actualizarCantidadPedida
    * 
    * @description Metodo que sirva para crear una nuava orden
    * @param cant_pedida int cantidad de material a pedir
    * @param id ID de la orden_material a editar
    * @param index index del arreglo de materiales en el state
    * @pre_save valor anterior antes de guardar la cantidad en la BD
    */
    actualizarCantidadPedida = (cant_pedida, id, index, pre_save, print_qrs) => {
        let { materiales } = this.state
        axios.post('/ordenes/material/update', {
            ordenMaterial_id: id,
            pedida: cant_pedida,
            print_qrs: print_qrs,
        }).then(e => {
            message.success('Pedido actualizado')
            if (cant_pedida === '') {
                materiales[index].show = false
                this.setState({ materiales })
            }

            this.getOrden()
        })
            .catch(error => {
                console.log(error)
                materiales[index].pedida = pre_save;
                this.setState({ materiales })
                if (error.response?.status === 403)
                    message.error(error.response.data.message)
                else if (error.response?.status === 401)
                    Modal.error({
                        title: 'Material no registrado',
                        content: 'Confirme el material antes de su modificación'
                    })
                else
                    message.error('Ocurrio un problema al guardar')
            })
    }

    /**
    * @memberof OrdenMaterial
    * 
    * @method renderPedidaInput
    * 
    * @description Renderiza el boton, el input para modificar la cantidad de material pedido, o el texto dela cantidad pedida
    */
    renderPedidaInput = (material, index) => {
        let { materiales } = this.state

        if ((material.pedida === null || material.pedida === '' || material.pedida === undefined) && material.show !== true) {
            return <Button className="btn btn-truck" onClick={() => {
                materiales[index].show = true
                this.setState({ materiales })
            }}> <IconTruck /> </Button>
        } else if (material.pedida !== null && material.pedida !== undefined && material.show !== true) {
            return <Text style={{ cursor: 'pointer' }} onClick={() => {
                materiales[index].show = true
                this.setState({ materiales })
            }}> {material.pedida} {material.unidad === 1 ? 'tons.' : 'pzas.'}  <CheckOutlined style={{ color: "#1dfc61" }} /> </Text>
        } else {
            return <Space>
                <InputNumber size="small"
                    min={0} max={100000} defaultValue={material.pedida ? material.pedida : material.cantidad}
                    onPressEnter={(e) => this.onInputChange(e.target.value, index)}
                    onChange={(temp_pedida) => {
                        materiales[index].temp_pedida = temp_pedida
                        this.setState({ materiales })
                    }}
                />
            </Space>
        }
    }

    /**
     * @memberof OrdenMaterial
     * 
     * @method setCantidadPedida
     * 
     * @description actualiza el elemento del array e el state con la nueva informacion de pedida y llama al metodo para guardar en la BD
     */
    setCantidadPedida = (str, index) => {
        let { materiales } = this.state
        let cantidad = parseFloat(str)
        if (typeof cantidad === 'number') {
            let pre_save = materiales[index].pedida
            materiales[index].pedida = str.toString();
            this.setState({ materiales })
            this.actualizarCantidadPedida(str, materiales[index]._id, index, pre_save)

        } else {
            message.error('Valor no valido')
        }
    }

    /**
    * @memberof OrdenMaterial
    * 
    * @method onInputChange
    * 
    * @description Al dar enter al input de pedida, cambia el state para mostrar la cantidad pedida en forma de texto
    */
    onInputChange = (int, index) => {
        let value = int
        let { materiales } = this.state
        this.setCantidadPedida(value, index)
        materiales[index].show = false
        this.setState({ materiales })
    }

    /**
    * @memberof OrdenMaterial
    * 
    * @method completeOrder
    * 
    * @description 
    */
    completeOrder = (material, index) => {



        const { materiales } = this.state
        console.log('material a completar', material)
        if (material.pedida === null || material.pedida === undefined || material.pedida < material.cantidad) {
            let temp_cant_ped = material.pedida
            material.pedida = material.cantidad
            material.show = false;
            materiales[index] = material
            this.setState({ materiales })


            message.success('Pedido actualizado')



            var a = document.createElement('a');
            a.target = "_blank";
            a.href = axios.defaults.baseURL + "/ordenes/material/qr?" + qs.stringify({
                orden_material_id: material._id,
                Authorization: sessionStorage.getItem('token')
            })
            a.click();

            // // console.log('a ',a );
            // // setTimeout(() => this.getOrden(this.state.orden_id), 2000);
            // this.actualizarCantidadPedida(material.cantidad, material._id, index, temp_cant_ped, true)
        } else {
            message.error('Ya esta completada la orden')
        }


    }


    hideModal = () => {
        this.setState({ 
            orden_material: undefined,
            ModalQr: false,
            visible: false,
            modalVisible: false,
        })
        this.getOrden(this.state.orden_id)
    }


    render() {
        
        const user = this.context
        
        let edicion_compras = this.state.orden_id && (user?.tipo === 1 || user?.roles?.compras)

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} className="flex-left">
                            <Title level={4} className="view-title">Ordenes de Material</Title>
                        </Col>
                        <a
                            ref={this.printQrRef}
                            target="_blank"
                            href={axios.defaults.baseURL + "/ordenes/material/qr?" + qs.stringify({
                                // orden_id: this.state.orden_id,
                                orden_material_id: this.state.ordenMaterial_id,
                                // cantidadQrs: this.state.cantidadQrs,
                                // tonsQr: this.state.tonsQr,
                                Authorization: sessionStorage.getItem('token')
                            })}>
                        </a>
                    </Row>
                    <section style={{ padding: '2em' }} >
                        <Row>
                            <Col xs={24}>
                                <Card className="p-1">
                                    <Row>
                                        <Title className="title-blue">Información de la Orden</Title>
                                    </Row>
                                    <Row className="w-100" style={{ marginTop: '2em' }}>
                                        <Col span={5}>
                                            <Paragraph style={{ color: "#A4A4A4" }}>ID</Paragraph>
                                            <Paragraph strong style={{ fontSize: '1.2em' }}>{this.state.folio}</Paragraph>
                                        </Col>
                                        <Col span={18}>
                                            <Paragraph style={{ color: "#A4A4A4" }}>Fecha de Requerimiento</Paragraph>
                                            <DatePicker value={this.state.fecha} onChange={fecha => this.setState({ fecha })} disabled={this.state.estatus > 1} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                        </Row>

                        <section style={{ width: "100%", overflow: "auto", marginTop: '1em' }}>

                            <PageHeader
                                className="site-page-header"
                                title="Materiales"

                                className="title-blue"
                                extra={[
                                    <Button className="button-new-material" type="primary" icon={<PlusOutlined />} size={"large"} onClick={() => this.setState({ visible: true, edicion: false })} />
                                ]}
                            // subTitle="This is a subtitle"
                            />
                            <Row style={{ width: "auto", marginTop: "20px", minWidth: "1000px", paddingRight: "10px" }}>

                                <Col span={24}>

                                    <List
                                        loading={this.state.loading}
                                        className="component-list"
                                        itemLayout="horizontal"
                                        dataSource={this.state.materiales}
                                        header={
                                            <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles title-list-ordenesMaterial">
                                                <Col span={3} className="center">
                                                    Material
                                                </Col>
                                                <Col xs={{ span: edicion_compras ? 3 : 4 }} className="center">
                                                    Detalles
                                                </Col>
                                                <Col xs={{ span: edicion_compras ? 3 : 4 }} className="center">
                                                    {this.state.estatus >= 3 ? 'Escaneadas' : 'Especificaciones'}
                                                </Col>

                                                <Col xs={{ span: 2 }} className="center">
                                                    Tipo
                                                </Col>
                                                <Col span={4} className="center">
                                                    Proyecto
                                                </Col>
                                                <Col span={3} className="center">
                                                    Cantidad
                                                </Col>
                                                {edicion_compras ? <Col span={4} className="center">
                                                    Cantidades a Pedir a Proveedor
                                                    {/* {edicion_compras ? this.renderPedidaInput(item, index) : null} */}
                                                </Col> : null}

                                                <Col span={edicion_compras ? 2 : 4} className="center">
                                                    Acciones
                                                </Col>

                                            </Row>
                                        }
                                        renderItem={(item, index) => (
                                            <List.Item className="component-list-item">
                                                <Card className="card-list">
                                                    <Row style={{ width: '100%' }} className="row-itemList-ordenesMaterial">
                                                        <Col span={3} className="center">
                                                            <Text strong>{item.material_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={{ span: edicion_compras ? 3 : 4 }} className="center">
                                                            <Text strong>{(item.material_id?.detalles) ? item.material_id?.detalles : "N/A"}</Text>
                                                        </Col>
                                                        <Col xs={{ span: edicion_compras ? 3 : 4 }} className="center">
                                                            {this.state.estatus >= 3 ? <Text strong>{item.escaneadas ? item.escaneadas.length : 0}</Text> :
                                                                <Text strong>{item.especificaciones ? item.especificaciones : '-'}</Text>}
                                                        </Col>

                                                        <Col xs={{ span: 2 }} className="center">
                                                            <Text strong>{item.tipo_id ? item.tipo_id.nombre : '-'}</Text>
                                                        </Col>
                                                        <Col span={4} className="center">
                                                            <Text strong><CustomAvatar
                                                                image={item.proyecto_id?.logo}
                                                                name={item.proyecto_id?.nombre}
                                                                color={item.proyecto_id?.color}
                                                                size="small"
                                                                style={{
                                                                    marginRight: '5px'
                                                                }}
                                                            /> {item.proyecto_id?.nombre}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text strong>{(item.cantidad).toMoney() + " " + ((item.unidad === 1) ? "tons." : "pzas.")}</Text>
                                                        </Col>
                                                        {(edicion_compras ) ? <Col span={4} className="center">
                                                            {edicion_compras ? this.renderPedidaInput(item, index) : null}
                                                        </Col> : null}

                                                        <Col span={edicion_compras ? 2 : 4} className="center">
                                                            <Space>
                                                                {(edicion_compras && (this.state.qr_status === true))  ? <Button
                                                                    className="btn btn-complete"
                                                                    disabled={!(item.cantidad > item.pedida && item.pedida > 0)}
                                                                    title="Completar material"
                                                                    onClick={e => this.setState({ 
                                                                        ModalQr: true, 
                                                                        orden_material: item._id
                                                                    })}
                                                                    >
                                                                    <CarryOutOutlined />
                                                                </Button> : null}

{/* 
                                                                {edicion_compras ?  ? <Popconfirm
                                                                    placement="topRight"
                                                                    title={"¿Deseas completar el material? Se imprimiran los QRs faltantes"}
                                                                    onCancel={e => e.stopPropagation()}
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                    // modalVisible={this.state.ModalQr}
                                                                    // closeMethod={this.hideModal}
                                                                    // orden_id={this.state.orden_id}
                                                                    // ordenMaterial_id={this.state.ordenMaterial_id}
                                                                    onConfirm={e => this.setState({ 
                                                                        ModalQr: true, 
                                                                    })}
                                                                >

                                                                    <Button className="btn btn-complete" title="Completar material">
                                                                        <CarryOutOutlined />
                                                                    </Button> </Popconfirm> : <Button className="btn btn-complete" title="Completar material" disabled={true}>
                                                                    <CarryOutOutlined />
                                                                </Button> : null} */}

                                                                {this.state.editable || user.tipo === 1 ? <Popconfirm
                                                                    placement="topRight"
                                                                    title={"¿Deseas eliminar  material ?"}
                                                                    onCancel={e => e.stopPropagation()}
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                    onConfirm={e => this.deleteMaterial(item._id, index)}
                                                                >
                                                                    <Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
                                                                        <IconDelete title="Eliminar" />
                                                                    </Button>
                                                                </Popconfirm> : <Button className="btn btn-delete" disabled={true}>
                                                                    <IconDelete title="Eliminar" />
                                                                </Button>}

                                                                {this.state.editable || user.tipo === 1 ? <Button className="btn btn-edit" onClick={e => {
                                                                    this.setState({
                                                                        material: item,
                                                                        visible: true,
                                                                        index: index,
                                                                        edicion: item._id ? true : false
                                                                    })
                                                                }}>
                                                                    <IconEdit title="Editar" />
                                                                </Button> : <Button className="btn btn-edit" disabled={true}>
                                                                    <IconEdit title="Editar" />
                                                                </Button>}
                                                            </Space>
                                                        </Col>

                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </section>
                        <Button className="btnCheckGreen" title="" onClick={() => this.guardarOrden()} >
                            <IconCheckGreen />
                        </Button>
                    </section>
                </Spin>
                <ModalMaterialOrden
                    index={this.state.index}
                    material={this.state.material}
                    visible={this.state.visible}
                    onCancel={() => this.setState({
                        visible: false,
                        material: undefined,
                        id: undefined,
                        edicion: false,
                        index: undefined,
                    })}
                    setMaterial={this.setMaterial}
                    edicion={this.state.edicion}
                />
                <ModalTicketQr
                    modalVisible={this.state.ModalQr}
                    closeMethod={this.hideModal}
                    orden_material={this.state.orden_material}
                />
            </div>
        )
    }
}
