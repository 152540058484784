import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, Image, Calendar, Menu, Badge, Dropdown} from 'antd'
import ReactECharts from 'echarts-for-react';
import { Link } from "react-router-dom";
//componentes
import { IconArrowToRight, IconMenuDots, IconDbDocumentosClock } from '../../Widgets/Iconos';
//css
import '../../../Styles/ventas.css';

const { Title, Text } = Typography;
const moment = require('moment');
const axios = require('axios').default


export default class VentasDashboard extends Component{

	constructor(props){
		super(props)
		this.state = {
			prospectos: [],
			total_prospectos: 0,
		}
	}

	/**
	* @memberof VentasDashboard
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getInfoProspectos();
	}


	/**
    * @memberof VentasDashboard
    *
    * @method   badgeEstatus
    * @description retorna el tipo de Badge correspondiente al estatus del evento
    * 
    **/
    badgeEstatus = (estatus) => {
        if(estatus === 1)
            return <Badge status="warning" text="En revision" />
        if(estatus === 2)
            return <Badge status="success" text="Aprobado y Agendado" />
        if(estatus === 3)
            return <Badge status="error" text="Denegado" />
    }

    /**
    * @memberof VentasDashboard
    *
    * @method   getInfoProspectos
    * @description retorna iformacion de los prospectos por proyecto
    * 
    **/
    getInfoProspectos = () => {
    	axios.get('/ventas/prospectos',{})
    	.then(response => {
    		let total = 0;
    		response.data.data.forEach(p=>{
    			total += p.prospectos_count
    		})
    		this.setState({
    			prospectos: response.data.data,
    			total_prospectos: total,
    		})
    	})
    	.catch(error => {
    		console.log(error)
    	})
    }

    /**
	 * @memberof VentasDashboard
	 * @function graficaPie
	 *
	 * @description informacion para la grafica
	 *
	 * */
	graficaPie = () => ({
		title : {
		  text: '',
		  subtext: '',
		  x:'center'
		},
		tooltip : {
		  trigger: 'item',
		  formatter: "{a} <br/>{b} : {c} ({d}%)"
		},
		legend: {
		  orient: 'vertical',
		  left: 'left',
		  data: this.state.prospectos.map(prosp => prosp.proyecto.nombre)
		},
		series : [
		  {
		  name: 'Prospecto',
		  type: 'pie',
		  radius : '55%',
		  center: ['50%', '60%'],
		  data: this.state.prospectos.map(prosp => ({value: prosp.prospectos_count, name: prosp.proyecto.nombre})),
		  itemStyle: {
			emphasis: {
			shadowBlur: 10,
			shadowOffsetX: 0,
			shadowColor: 'rgba(0, 0, 0, 0.5)'
			}
		  }
		  }
		]
	  })


	render() {
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} xl={6} xxl={8}>
						<h2 style={{width: 'auto', float: 'left'}}>Ventas</h2>
					</Col>
				</Row>
				<section className="ventas-dashboard-ctn p-1">
					<Row gutter={16}>
						<Col xs={24} xl={16} className="gutter-row mb-1">
							<Row style={{ width: "100%" }} >
								<Col span={24}>
									<Card className="card-bienvenido">
										<Row>
											<Col xs={24} lg={12} xl={12} className="flex-left-column-between p-1">
												<Title level={2} className="main-dashboard-title">Bienvenido {this.props.user?.nombre}</Title>
												<Text className="card-text">Actualmente hay {this.state.total_prospectos} prospectos</Text>
											</Col>
											<Col xs={24} lg={14} xl={12} className="center" style={{padding:'5px'}}>
												<Image className="imgBienvenido" src={"/images/pc.svg"} alt="imgBienvenido" preview={false} />
											</Col>
										</Row>


									</Card>
								</Col>
							</Row>
							<Row style={{ width: "100%" }}  >
								<Col span={24} className="flex-between">
									<Title level={3} className="sub-title ">Prospectos</Title>
									<Link to={"/admin/ventas/prospectos"}>
										<Button className="btn-verMas ">
											<Text>Ver todo</Text>
											<IconArrowToRight />
										</Button>
									</Link>
								</Col>
							</Row>
							<Row style={{ width: "100%" }}  >
								<Col span={24}>
									<Card className="card-pie">
										<ReactECharts
											option={this.graficaPie()}
											style={{ height: 400 }}
										  />
									</Card>
								</Col>
							</Row>
						</Col>
						<Col xs={24} xl={8} className="gutter-row" >
							<Row>
								<Col span={24}>
									<Card className="card-calendario-sm">
										<Title level={1} className="card-title">Calendario</Title>
										<Calendar 
											fullscreen={false}  
											onPanelChange={(value)=>{
												
											}}
										/>
									</Card>
								</Col>
							</Row>
							<Row>
								<Col span={24} className="flex-between">
									<Title level={3} className="sub-title ">Mis Citas</Title>
									<Link to={"/ventas/dashboard"}>
										<Button className="btn-verMas ">
											<Text>Ver todo</Text>
											<IconArrowToRight />
										</Button>
									</Link>
								</Col>
								<Col span={24}>
									<Card className={`card-evento error`}>
										<Row>
											<Col span={22}>
												<Title level={2}>Evento</Title>
												<p><IconDbDocumentosClock /> {`${moment().format('l LT')} -  ${moment().format('l LT')}`}</p>
												<p> {this.badgeEstatus(1)} </p>     
											</Col>
											<Col span={2} className="center ">
												<Dropdown
													trigger={['click']} 
													placement="bottomLeft"
													overlay={
														<Menu
															onClick={(item)=>{console.log(item)}}
														>	
														</Menu>
													} 
												>
													<Button className="btn-opcion-menuDot">
														<IconMenuDots />
													</Button>
												</Dropdown>
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</section>
			</div>
		)
	}
}