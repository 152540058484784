import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Divider, Spin, Space } from 'antd';
import { PlusOutlined, UploadOutlined, MinusCircleOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar'
import SalidaInventarioInputs from './SalidaInventarioInputs';
import PrioridadSelect from '../../../Widgets/prioridadSelect';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';

//css
import './../../../../Styles/modales.css'

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;

/**
 *
 *
 * @class ModalRequisiciones
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalRequisiciones extends Component {


	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			usuarios: [],
			proyectos: [],
		}
	}

	/**
	 *
	 * @methodOf ModalRequisiciones
	 * @function componentDidMount
	 * @description Obtiene la informacion inicial
	 */
	componentDidMount(){
		this.getUsuarios()
	}

	/**
	 * @memberof ModalRequisiciones
	 * @function getUsuarios
	 *
	 * @description Obtiene una lista de usuarios.
	 */
	getUsuarios = (search) => {
		axios.get("/usuarios", {
			params:{
				search
			}
		})
		.then(({ data }) => {
			this.setState({
				usuarios: data.data.itemsList
			})
			
		})
		.catch(e => console.log('er', e))
	}


	/**
	 * @memberof ModalRequisiciones
	 * @function onFinish
	 *
	 * @description Obtiene una lista de usuarios.
	 */
	onFinish = (values) => {
		axios.post('/almacen/productos/salida', {
			...values
		})
		.then(({ data }) => {
			message.success('Inventario Actualziado')
			this.props.onCancel(true)
		})
		.catch(e => {
			console.log('er', e)
			if(e.response?.status === 403)
				Modal.error({
					title: e.response.data.title,
					content: e.response.data.content
				})
			else
				message.error('No se actualizó el inventario')
		})
	}

	render() {


		return [

			<div className="modal-header">
				<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
					<IconCloseModal />
				</Button>
				<Title level={3} className="modal-title"> Salida de Material</Title>
			</div>,
			<Spin spinning={this.state.loading}>


				<Form
					layout="vertical"
					className="frm-cuentas"
					name="form-materiales"
					ref={this.modalMateriales}
					onFinish={this.onFinish}
					initialValues={{
						productos: [{}]
					}}

				>
					<Row align="center" gutter={[16]}>

						<Col span={10}>
						   <Form.Item
								label="Contratista"
								name="contratista_id"
								rules={[{
									required: true,
									message: "Por favor, seleccione un proveedor"
								}]}
							>

								 <Select
									className="form-select"
									onSearch={(search) => this.getUsuarios(search)}
									showSearch
									filterOption={false}
									
								>
									{this.state.usuarios.map((usuario) =>  <Option value={usuario._id}>
										{usuario.nombre} {usuario.apellido}
									</Option>)}
								</Select>
							</Form.Item>
						</Col>

					</Row>
					<Row align="center" gutter={[8]}>

						<Col span={6}>
							<Text className="text-gray">Producto</Text>
						</Col>
						<Col span={4}>
							<Text className="text-gray">Proyecto</Text>
						</Col>
						<Col span={4}>
							<Text className="text-gray">Almacén</Text>
						</Col>
						<Col span={4}>
							<Text className="text-gray">Inmueble</Text>
						</Col>
						<Col span={5}>
							<Text className="text-gray">Volumen</Text>
						</Col>
						<Col span={1}>
							
						</Col>
					</Row>
					<Row align="center">

						<Col span={24}>
							<Form.List name="productos">
								{(fields, { add, remove }) => (
							  		<>
										{fields.map(({ key, name, ...restField }) => (
								  			<SalidaInventarioInputs key={key} name={name} restField={{...restField}} add={add} remove={remove}/>
										))}

										<Form.Item>
								  			<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
												Add field
								  			</Button>
										</Form.Item>
							  		</>
								)}
							</Form.List>
						</Col>
						

					</Row>  

					<Row style={{ textAlign: "center" }}>
						<Col span={24}>
							<Form.Item >
								<Button htmlType="submit" type="primary" className="btn-azul" >
									Guardar
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Spin>
		]
	}
}



export default function (props) {

	const {visible, onCancel} = props

	return <Modal
		visible={visible}
		onCancel={onCancel}
		title={null}
		footer={null}
		closable={false}
		className="modal-form"
		destroyOnClose={true}
		zIndex={1000}
		style={{maxWidth: '1100px'}}

	>
		<ModalRequisiciones {...props} />
	</Modal>


}
