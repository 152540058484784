import React, { useState, useEffect } from 'react';
import { Button, Col, Dropdown, Input, Menu, Badge } from 'antd';
import { CloseOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import { BsBellFill } from "react-icons/bs";
import socketIOClient from 'socket.io-client'
import { SocketContext } from '../../Hooks/Socket';
import PropTypes from 'prop-types';
import './../../Styles/header.css'
import Logo from './../../assets/images/logo.png'
import Cookies from 'universal-cookie';
import CustomAvatar from "../Widgets/Avatar/Avatar";

import { Link, Redirect } from "react-router-dom";
import MenuUnfoldOutlined from "@ant-design/icons/lib/icons/MenuUnfoldOutlined";


const { Search } = Input;

const axios = require('axios').default


var myRef = React.createRef();


const Navbar = ({ setUser, user, showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }) => {

    const server = axios.defaults.baseURL;
    const [redirect, setRedirect] = useState(false)
    const [notificacionesCount, setnotificacionesCount] = useState(0);
    const socket = React.useContext(SocketContext)
    const [ search, setSearch ] = useState('')


    const cerrarSesion = () => {
        let cookie = new Cookies();
        setUser(undefined);
        sessionStorage.clear();
        cookie.remove('token')

        setRedirect(true);

    };

    let userAvatar;
    let nombre;

    if (typeof user === "object") {

        let { avatar, nombre, apellido } = user;
        nombre = `${user.nombre} ${user.apellido}`;

        userAvatar = <div><CustomAvatar
            name={[nombre, apellido]}
            image={avatar}
        /><span style={{ marginLeft: '10px' }}>{nombre}</span></div>
    }



    useEffect(()=>{
        socket.emit('suscripcion', ({ token: sessionStorage.getItem('token'), count: true }))
        socket.on("notificaciones", data => {
            //console.log(data);
            setnotificacionesCount(data);
        });
    },[])


    const limpiarBusqueda = () => {
        if (myRef.current) {
            console.log("myRef.current", myRef.current);
            myRef.current.value = ''
            setSearch('')
        }
        setFilterSearch("");
    }

    let reference = React.createRef();
    return (
        <div className="header">
            {redirect ? <Redirect to={'/login'} /> : null}
            <Col className="header-logo">
                <Button className="responsive-menu-button" type="link" size="large" onClick={showResponsiveSidebar}>
                    <MenuUnfoldOutlined />
                </Button>
                <img className="logo" src={Logo} alt="logo" />
            </Col>
            <Col className="header-content">
                <div className="header-search">
                    {(showSearch) ?
                        <div>
                            <Search
                                className="search-header"
                                placeholder="Buscar"
                                size="normal"
                                bordered={false}
                                onSearch={(e) => { 
                                    console.log("e", e);
                                    setFilterSearch(e) 
                                }}
                                onChange={(e=>{
                                    setSearch(e.target.value)
                                })}
                                id="inputFilterSearch"
                                ref={myRef}
                                allowClear={true}
                                value={search}
                            />
                            {(typeof filterSearchVal === "string" &&  filterSearchVal.length > 0) ? <Button className="btnSearchClear" style={{
                                    display: 'block',
                                    position: 'relative',
                                    left: '45px',
                                    border: 'none',
                                    width: '40px'
                            }}  onClick={limpiarBusqueda} >
                                <CloseOutlined/>
                            </Button> : null}

                        </div>
                        :
                        ''
                    }
                </div>

                <div className="header-options">
                    <div style={{ marginRight: '12px' }}>
                        <Link to="/admin/notificaciones" >
                            <Badge count={notificacionesCount}>
                                <BsBellFill className="icon-Notification" />
                            </Badge>
                        </Link>
                    </div>
                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item key="0" onClick={cerrarSesion}>
                                Cerrar Sesión
                            </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <div className="ml-1">
                            <a>
                                {userAvatar}
                            </a>
                            <a className="ant-dropdown-link header-user-drop" onClick={e => e.preventDefault()}
                                style={{ marginLeft: '10px' }}>
                                {nombre} <DownOutlined />
                            </a>
                        </div>
                    </Dropdown>
                </div>
            </Col>
        </div>
    );

}


Navbar.propTypes = {
    title: PropTypes.string,
    back: PropTypes.bool,
    onBack: PropTypes.func
};

Navbar.defaultProps = {
    back: false,
    title: 'Manantial',
};



export default Navbar;
