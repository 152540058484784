import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, Select, List, Typography, message, Spin,InputNumber } from 'antd';
//componentes
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios').default;
const { Option } = Select;
const { Title, } = Typography;


export default class ModalInmuebleTajo extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            tajos: [],
            inmueble: {},
            tajo:{},
        }
    }

    //Referencia de form
    formInmuebleTajo = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadTajos();

    }


    /**
     *
     *
     * @memberof ModalInmuebleTajo
     *
     * @function componentDidUpdate
     * @description cada vez que cambie la visivilidad del modal refresca los tajos diponibles, si se va editar trae la infomacion del tajo
     */
    componentDidUpdate(prevProps) {

        const {modalVisible, InmueblesTrabajo_id, tajos_array_element_id } = this.props

        if (modalVisible !== prevProps.modalVisible){
            if (InmueblesTrabajo_id !== prevProps.InmueblesTrabajo_id && InmueblesTrabajo_id !== undefined && tajos_array_element_id !== undefined) {
                this.getTajo(InmueblesTrabajo_id, tajos_array_element_id)
            }
            this.loadTajos();
        }
    }

    /**
    * @memberof ModalInmuebleTajo
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
        this.setState({loading: false, tajo: {}})
    }

     /**
    * @memberof ModalInmuebleTajo
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        if(this.props.modalTitle === 'Añadir')
            this.addTajo(values);
        else if( this.props.modalTitle === 'Editar' )
            this.updateInmueble(values)
    }

    /**
    * @memberof ModalInmuebleTajo
    *
    * @method   loadTajos
    * @description  Trae un listado de tags de tipo tajo
    *
    **/
    loadTajos = (search) => {
        axios.get('/tags', {
            params: {
                page: 1,
                limit: 50,
                type: "area_id", //tipo: 1 ()
                search: search,
                InmueblesTrabajo_id: this.props.InmueblesTrabajo_id,
            }
        })
            .then(res => {
                this.setState({ tajos: res.data.data.itemsList })
            })
            .catch(e =>
                console.log('error tajos get', e)
            )
    }

   

    /**
     * @memberof ModalInmuebleTajo
     *
     *
     * @param {*} values
     * @method addTajo
     * @description Manda peticion para agregar un tajo al arreglo de tajos de un registro de Inmuebles_trabajo
     */
    addTajo = (values) => {
        this.setState({loading: true})
        axios.post('/inmuebles-trabajo/add/tajo', {
            id: this.props.InmueblesTrabajo_id,
            tajo_id: values.tajo_id,
            precio_m2: values.precio_m2,
        })
        .then(res => {
            message.success('¡Actividad añadida!');
            this.hideModal()
        })
        .catch(error => {
            this.setState({ loading: false })
            console.log('error al añadir el tajo', error);
            message.error('¡Actividad no añadida');
        })

    }

    /**
     * @memberof ModalInmuebleTajo
     *
     * @method getTajo
     *
     * @param id ObjectId _id de Inmueble_trabajo
     * @param id_elemento_array ObjectId _id del elemento del array del Inmueble_trabajo
     * @description Manda peticion para traer la infomacion de un tajo dentro del array del regsitro de Inmueble_trabajo
     *
     */
    getTajo = (id, id_elemento_array) => {
        this.setState({loading: true})
        axios.post('/inmuebles-trabajo/get/tajo', {
            id: id,
            id_elemento_array: id_elemento_array
        })
        .then(res => {
            this.setState({tajo: res.data.data.tajos[0], loading: false })
            console.log(res.data.data.tajos[0])
            this.formInmuebleTajo.current.setFieldsValue({
                tajo_id: res.data.data.tajos[0].tajo_id._id,
                precio_m2: res.data.data.tajos[0].precio_m2,
            })
        })
        .catch(error => {
            console.log('error al buscar el Tajo', error);
            this.setState({ loading: false })
            message.error('Actividad no encontrada');
        })

    }


    /**
    * @memberof ModalInmuebleTajo
    *
    *
    * @param {*} Values
    * @method updateInmueble
    * @description Manda la peticion para actualizar un Inmueble
    */
    updateInmueble(values) {
        this.setState({ loading: true })
        axios.post('/inmuebles-trabajo/update/tajo', {
            id: this.props.InmueblesTrabajo_id,
            id_elemento_array: this.props.tajos_array_element_id,
            tajo_id: values.tajo_id,
            precio_m2: values.precio_m2,  
        })
        .then(res => {
            message.success('Actividad actualizada!');
            this.hideModal()
        })
        .catch(error => {
            this.setState({ loading: false })
            console.log('error al actualizar Tajo', error);
            message.error('Actividad no actualizada!');
        })
    }

    /**
    * @memberof ModalInmuebleTajo
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

       let arr = [];
       if(array){
            array.forEach(function ({ _id, nombre, apellido }, index) {
                arr.push (<Option value={_id}> {nombre} {apellido}</Option>)
            })
       }

       if(array && addElemnt){
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
       }
        return arr
    }

    render() {
        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-rubro"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} Actividad</Title>
                </div>
                 <Spin spinning={this.state.loading}>

                    <Form
                        layout="vertical"
                        name="formulario-InmuebleTrabajo"
                        onFinish={this.onFinish}
                        ref={this.formInmuebleTajo}
                    >

                        <Row align="center">
                            <Col xs={22} md={20}>
                                <Form.Item
                                    label="Actividad"
                                    name="tajo_id" //<--------------------------------------- tajo_id
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese una Actividad"
                                    }]}
                                >
                                    <Select
                                        onSearch={(search) => this.loadTajos(search)}
                                        showSearch
                                        filterOption={false}
                                    >
                                        {this.selectOptions(this.state.tajos, this.state.tajo.tajo_id)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col xs={22} md={20}>
                                <Form.Item
                                    label="Presupuesto total"
                                    name="precio_m2" //<--------------------------------------- precio_m2
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese un presupuesto"
                                    }]}
                                >
                                   <InputNumber min={0}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col xs={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
