import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, Typography, message, Spin, InputNumber, Radio } from 'antd';
import './../../../../Styles/modales.css'

import { IconCloseModal } from '../../../Widgets/Iconos';
import axios from 'axios'

const { Title, } = Typography;

class ModalConcepto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false

        }
    }

    //Referencia de form
    formConcepto = React.createRef();

    componentDidMount(): void {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.concepto_id)
            this.getConcepto()
    }

    /**
    * @memberof ModalConcepto
    * @method onFinish
    * @description Se ejecuta al realizar submit al formulario
    **/
    onFinish = (values) => {
        if (this.props.concepto_id) {
            this.updateConcepto(values)
        } else {
            this.addConcepto(values)
        }
    }

    /**
    * @memberof ModalConcepto
    * @method getConcepto
    * @description Obtiena la informacion del Almacen
    **/
    getConcepto = (values) => {
        this.setState({ loading: true })
        axios.get('/conceptos/get', {
            params: {
                concepto_id: this.props.concepto_id
            }
        }).then(response => {
            return this.formConcepto.current?.setFieldsValue({
                ...response.data.data
            })

        }).catch(error => {
            let msg = 'Error al obtener el Concepto'
            if (error.response?.status === 403)
                msg = error.response.data.message
            message.error(msg)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalConcepto
    * @method addConcepto
    * @description Añade una nuevo almacen
    **/
    addConcepto = (values) => {
        this.setState({ loading: true })
        axios.post('/conceptos/add', {
            ...values,
            proyecto_id: this.props.proyecto_id,
        }).then(response => {
            message.success('Concepto creado con exito')
            this.props.onCancel()
        }).catch(error => {
            let msg = 'Error al crear el Concepto'
            if (error.response?.status === 403)
                msg = error.response.data.message
            message.error(msg)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalConcepto
    * @method updateConcepto
    * @description Actualiza un almacen
    **/
    updateConcepto = (values) => {
        this.setState({ loading: true })
        axios.post('/conceptos/update', {
            ...values,
            concepto_id: this.props.concepto_id,
            proyecto_id: this.props.proyecto_id,
        }).then(response => {
            message.success('Concepto actualizado con éxito')
            this.props.onCancel()
        }).catch(error => {
            let msg = 'Error al actualizar el Concepto'
            if (error.response?.status === 403)
                msg = error.response.data.message
            message.error(msg)
        }).finally(() => this.setState({ loading: false }))
    }




    render() {


        return [
            <div className="modal-header flex">
                <Button type="ghost" className="btn-close-modal flex-left" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title"> {this.props.concepto_id ? 'Editar' : 'Añadir'} Concepto</Title>
            </div>,
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.formConcepto}
                    onFinish={this.onFinish}

                >
                    <Row align="center" gutter={[16]}>
                        <Col xs={22}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[
                                    { required: true, message: "Ingrese el nombre del Concepto" }
                                ]} >
                                <Input placeholder="Nombre" maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col xs={22}>
                            <Form.Item
                                label="Tipo"
                                name="tipo">
                                <Radio.Group
                                    optionType="button"
                                    buttonStyle="solid"
                                    options={[
                                        { label: 'Egresos', value: 2 },
                                        { label: 'Ingresos', value: 1 },
                                        { label: 'Sin clasificación', value: undefined },
                                    ]} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}

export default function (props) {

    const { visible, onCancel } = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}
        closeIcon={
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={props.onCancel}>
                <IconCloseModal />
            </Button>
        }

    >
        <ModalConcepto {...props} />
    </Modal>


}
