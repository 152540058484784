import React, { Component } from 'react';
import { Button, Col, Row, Typography, List, Card, Pagination,  } from 'antd'
import { Link } from 'react-router-dom';

//componentes
import { IconEye } from '../../../Widgets/Iconos';

//css
import '../../../../Styles/plano.css';

const axios = require('axios').default;

const { Text, Title } = Typography;

const moment = require('moment');


export default class BitacoraEstatusAvance extends Component {

    constructor(props) {
        super(props)
        this.state = {

            filtroSearch: "",

            dataBitacora: [],
            loading: true,

            registroId: '',
            registroNombre: '',


            /* Paginado */
            itemCount: 10,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,
        }
    }


    /**
   * @memberof BitacoraEstatusAvance
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se muestra el filtro superior
    * Se establece el valor del header en axios
    * Se llama al metodo receivedData que hace la consulta de materiales a mostrar en la tabla
    **/
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.receivedData();
    }


    /**
   * @memberof BitacoraEstatusAvance
    * @method   componentDidUpdate
    * @description  Al actualizar el componente:
    * Se hace una comparacion entre el valor del filtro superior y el filtro del state para
    * saber si se actualizó y se tiene que llamar el metodo de consulta de materiales receivedData 
    * con el nuevo valor del filtro
    **/
    componentDidUpdate() {

        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro) {
            console.log(filtro)
            this.receivedData();
        }
    }


    /**
   * @memberof BitacoraEstatusAvance
    * @method   receivedData
    * @description  
    * Metodo de consulta de bitacora, 
    * tiene los parametros:
    * número de la página a consultar, 
    * el numero de registros por página
    * el filtro del input del header
    * 
    * La consulta se visualiza en la tabla
    **/
    receivedData = async (
        page = this.state.currentPage,
        limit = this.state.perPage,
        search = this.props.search
    ) => {

        this.state.loading = true;

        axios.post('/inmuebles-trabajo/listBitacora', {
            params: {
                page: page,
                limit: limit,
                search: search
            }
        })
            .then(({ data }) => {

                var list = data.data.itemsList;
                var dataList = [];
                for (let index = 0; index < list.length; index++) {
                    const element = list[index];

                    dataList.push({
                        _id: element._id,
                        nombre: element.inmueble.nombre,
                        ultimaActualizacion: moment(element.updatedAt).utc().format('DD-MM-YYYY'),
                        index: (index + 1)
                    });

                }

                this.setState({
                    loading: false,
                    loadingPage: false,
                    dataBitacora: dataList,
                    currentPage: data.data.currentPage,
                    itemCount: data.data.itemCount,
                    perPage: data.data.perPage,
                    pageCount: data.data.pageCount,
                    filtroSearch: search
                })

            })
            .catch(error => {
                console.log('no traje nara', error);
            })
    }


    render() {
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} className="flex-left">
                        <Title level={4} className="view-title">Bitacora de Estatus de Avances</Title>
                    </Col>
                </Row>

                <section className="p-1">
                    <Row className="p-1" >
                        <Col xs={2} className="center" >
                            <Text strong>#</Text>
                        </Col>
                        <Col xs={12} className="center" >
                            <Text strong>NOMBRE</Text>
                        </Col>

                        <Col xs={8} className="center" >
                            <Text strong>ÚLTIMA ACTUALIZACIÓN</Text>
                        </Col>
                        <Col xs={2} className="center" >
                            <Text strong>ACCIONES</Text>
                        </Col>
                    </Row>

                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.dataBitacora}
                        renderItem={(item) => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">
                                        <Col span={2} className="center" >
                                            <Text strong>{item.index}</Text>
                                        </Col>
                                        <Col span={12} className="center" >
                                            <Text strong>{item.nombre}</Text>
                                        </Col>
                                        <Col span={8} className="center" >
                                            <Text >{item.ultimaActualizacion}</Text>
                                        </Col>

                                        <Col span={2} className="center" >
                                            <Link title="Ver" className="EditIcon" to={`/admin/project-manager/bitacoraEstatusAvance/detalle/${item._id}`} >
                                                <Button className="btn btn-eye" title="Detalles" >
                                                    <IconEye />
                                                </Button>
                                            </Link>
                                        </Col>

                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.currentPage}
                            total={this.state.itemCount}
                            pageSize={this.state.perPage}
                            showSizeChanger={false}
                            onChange={(page) => {
                                this.receivedData(page)
                            }}
                        />
                    </Row>

                </section>

            </div>
        )
    }
}
