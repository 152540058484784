import React, { Component } from 'react';
import { Card, Checkbox, Col, DatePicker, Divider, Dropdown, Input, Layout, Menu, Row, Typography, Empty, List, Button, Statistic, Spin, Radio } from 'antd'
import { Link } from 'react-router-dom';
//componentes
import { GraficaClientesPorProyecto, GraficaVentasPorUsuario } from '../../Widgets/graficas'
import { IconArrow, IconSalesGreen, IconUserBlue } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { FaArrowRight } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import { SearchOutlined, ArrowUpOutlined, ArrowRightOutlined } from '@ant-design/icons'
//modales
import ModalVentasPorUsuarios from '../Modales/CRM/ModalVentasPorUsuario';

//ReactECharts
import ReactECharts from 'echarts-for-react';

//css
import './../../../Styles/crm.css'

const echarts = require('echarts')



const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
const { Paragraph, Title } = Typography;
const { Content } = Layout;
const { RangePicker } = DatePicker;



/**
 * @class CRM
 * @description Obtiene los clientes del CRM
 */
export default class CRM extends Component {
    constructor(props) {
        super(props)
        this.state = {


            loading: true,
            projects: [],
            selectedProjects: [],


            accionModal: 'crear',
            registroId: '',
            modalVisible: false,

            areas: [],
            area: null,
            rubros: [],
            rubro: null,
            subrubros: [],
            subrubro: null,


            dropdowns: {
                areas: false,
                rubros: false,
                subrubros: false,
            },

            ventasTotales: 0,
            ventasCobrar: 0,
            totalCobrado: 0,
            porcentajePagado: 0,

            ventasPorAno: [],
            preciosPorInmueble: [],
            ultimasVentas: [],

            update: false,


        }
    }



    /**
     *
     *
     * @memberof CRM
     * 
     * @method componentDidMount
     * @description Trae la informacion de la vista
     */
    componentDidMount(): void {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);




        this.updateDashboard()
        this.getProjects();
        this.getAreas();

    }

    //Referencia
    refModalCRM = React.createRef();

     /**
     *
     *
     * @memberof CRM
     * @description Actualiza las consultas del dashboard y las renderiza
     */
    updateDashboard = () => {
        axios.get('/clientes/dashboard', {
            params: {
                proyectos: this.state.selectedProjects,
                area_id: this.state.area,
                rubro_id: this.state.rubro,
                start: this.state.start,
                end: this.state.end,
            }
        })
        .then(({ data }) => {
            console.log('data', data);
            this.setState({
                ...data.data
            })
        })
        .catch(res => {
            console.log("error", res);
        })
        .finally(e => this.setState({ loading: false }))
    }

    /**
    * @memberof PagosPendientes
    *
    * @memberof Acreedores
    * @function getProjects
    * @description Obtenemos los proyectos y los ponemos en el listado para filtro
    *
    * */
    getProjects = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({
                projects: proyectos.data.data
            })
        }).catch((error) => {
        })
    };

    /**
    * @memberof PagosPendientes
    *
    * @memberof Acreedores
    * @function getProjects
    * @description Obtenemos los proyectos y los ponemos en el listado para filtro
    *
    * */
    onProjectsChange = async (proyecto_id) => {
        let { selectedProjects } = this.state;

        let index = selectedProjects.findIndex(itemElement => itemElement.toString() === proyecto_id.toString());

        if(index !== -1){
            selectedProjects.splice(index,1)
        }else{
            selectedProjects.push(proyecto_id)
        }

        this.setState({selectedProjects},()=>this.updateDashboard())

    }

    /**
     *
     *
     * @memberof CRM
     * 
     * @method modalCrear
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {

        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }


    /**
     * @memberof CRM
     * 
     * @description Abrir modal tipo edición para registro seleccionado desde tabla.
     * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
     */
    modalEditar = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;

        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisible: true,
        })
    }

    /**
     * @memberof CRM
     * 
     * @description Cerrar modal y limpiar valor registroId
     */
    hideModal = () => {

        this.setState({
            registroId: '',
            modalVisible: false,
        })
    }


    handleVisibleChange = (type, flag) => {
        this.setState(state => {
            state[type].dropdown = flag;
            return { ...state };
        });
    };



    /**
     * @memberof CRM
     *
     * @function getAreas
     * @description Obtenemos las areas y los ponemos en el dropdown
     *
     * */
    getAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({ areas: response.data.data })
        })
    }

    /**
     * @memberof CRM
     *
     * @function updateAreas
     * @description Se actualiza el filtro de areas junto con el de rubros.
     *
     * @param id
     * @description ID de la area a agregar
     *  
     *
     * */
    updateAreas = async (id) => {
        this.setState({ area: id })
        //Si el ID es nulo, se borran todos los elementos
        if (this.state.area === id) {
            //Si el ID es igual al que esta en el state, entonces lo vamos a borrar junto con los rubros
            this.state.area = undefined;
            this.state.rubro = undefined;
            this.state.subrubro = undefined;
            this.state.rubros = [];
            this.state.subrubro = undefined;
            this.state.subrubros = [];
    
            //Si no, se buscan
        } else {
            this.updateDashboard()
            axios.get('/rubros/list', {
                params: {
                    paginate: false,
                    area_id: id
                }
            })
            .then(async (rubros) => {        
                this.setState({ rubro: null, rubros: rubros.data.data, })
            })
        }
    }

    /**
     * @memberof CRM
     *
     * @function updateRubros
     * @description Se actualizan los rubros en general. Evento cuando se cambia un rubro
     *
     * @param id
     * @description ID del rubro a agregar
     *
     * */
    updateRubros = async (id) => {

        this.setState({ rubro: id })
        if (this.state.rubro === id) {
            //Si el ID es igual al que esta en el state, entonces lo vamos a borrar junto con los rubros

            this.setState({rubro: undefined, subrubros: [], subrubro: undefined}, () => this.updateDashboard())

        } else {
            this.setState({ rubro: id }, () => this.updateDashboard() )
        }

                
    }


    /**
     * @memberof CRM
     *
     * @function updateSubrubros
     * @description Se actualizan los subrubros. Evento cuando se cambia un subrubro
     *
     * @param id
     * @description ID del subrubro a filrar
     *
     * */
    updateSubrubros = async (id) => {
        if (this.state.subrubro === id)
            id = undefined;
        await this.setState({ subrubro: id })

    }


    /**
     * @methodOf  Transacciones
     *
     * @memberof CRM
     * @description Se actualizan los valores de las fechas.
     *
     * @param values
     * @description Valores de los rangerspicers
     *
     * */
    onDatesChange = async (values) => {

        console.log(values)
        this.setState({
            start: values ? values[0].format('YYYY-MM-DD') : null,
            end: values ? values[1].format('YYYY-MM-DD') : null
        },()=>this.updateDashboard())
    };


   


    render() {
        const { handleVisibleChange, onProjectsChange, onDatesChange } = this;
        const { ventas, ventas_anuales, inmuebles, ultimas, projects, selectedElements,

            ventasTotales, ventasCobrar, totalCobrado, porcentajePagado, ventasPorAno, preciosPorInmueble, ultimasVentas
        } = this.state

        return (
            <div>
                {/* Encabezado */}
                <Row className="row-encabezado" >
                    <Col xs={24} lg={6} className="flex-left">
                        <Title level={4} className="view-title">Client Relationship Manager</Title>
                    </Col>

                    <Col xs={24} lg={18} style={{ paddingTop: '10px' }}>
                        <div className="text-white" >
                            <Row>
                                {/*Datepicker*/}
                                <Col xs={24} lg={6}>
                                    <RangePicker style={{ background: 'transparent !important' }} onChange={onDatesChange} />
                                </Col>
                                {/*PROYECTOS*/}
                                <Col xs={8} lg={4} className="center ">
                                    <Dropdown
                                        trigger={['click']}
                                        visible={projects.dropdown}
                                        onVisibleChange={(flag) => handleVisibleChange('projects', flag)}
                                        overlay={<Content className="dropdown-content">
                                            <Menu className="parent-element">
                                                {projects.map((item, index) => {
                                                    const { _id, nombre, logo, color } = item;
                                                    return (
                                                        <Menu.Item className="child-element" key={index}>
                                                            <Checkbox onChange={() => onProjectsChange(item._id)}>
                                                                <CustomAvatar image={logo} name={nombre} color={color} size="small" style={{ margin: '0 5px' }} />
                                                                {nombre}
                                                            </Checkbox>
                                                        </Menu.Item>
                                                    )

                                                })}
                                            </Menu>
                                        </Content>}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> Proyecto <IconArrow /> </a>
                                    </Dropdown>
                                </Col>
                                {/*Areas*/}
                                <Col xs={8} lg={3} className="center">
                                    <Dropdown
                                        trigger={['click']}
                                        visible={this.state.dropdowns.areas}
                                        onVisibleChange={(flag) => this.setState(state => {
                                            state.dropdowns.areas = flag
                                            return state
                                        })}
                                        overlay={
                                            <Menu style={{ minWidth: '260px', padding: '5px' }} >
                                                {/* <Empty /> */}
                                                {this.state.areas.length > 0 ? this.state.areas.map((area, index) => {

                                                    return <Menu.Item key={index}>
                                                        <input
                                                            onChange={() => this.updateAreas(area._id)}
                                                            type="radio"
                                                            name="area"
                                                            value={area._id}
                                                            style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                                            checked={this.state.area === area._id} 
                                                        />
                                                        {area.nombre}
                                                    </Menu.Item>

                                                }) : <Empty />}

                                            </Menu>
                                        }>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> Areas <IconArrow /> </a>
                                    </Dropdown>
                                </Col>
                                {/*Rubros*/}
                                <Col xs={8} lg={4} className="center ">
                                    <Dropdown
                                        trigger={['click']}
                                        visible={this.state.dropdowns.rubros}
                                        onVisibleChange={(flag) => this.setState(state => {
                                            state.dropdowns.rubros = flag
                                            return state
                                        })}
                                        overlay={
                                            <Menu style={{ minWidth: '260px', padding: '10px 5px',maxHeight: '300px', overflowY: 'auto' }} >
                                                {this.state.rubros.length > 0 ? this.state.rubros.map(({ _id, nombre }, index) => {

                                                    return <Menu.Item key={index}>
                                                        <Radio
                                                            //onChange={() => this.updateRubros(_id)}
                                                            onClick={() => this.updateRubros(_id)}
                                                            type="radio"
                                                            name="rubro"
                                                            value={_id}
                                                            style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                                            checked={this.state.rubro === _id}
                                                        >
                                                        {nombre}
                                                        </Radio>
                                                    </Menu.Item>

                                                }) : <Empty description="Debe seleccionar al menos un area." />}

                                            </Menu>
                                        }>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> Rubros <IconArrow /> </a>
                                    </Dropdown>
                                </Col>
                                {/*SubRubros*/}
                                {/*<Col xs={8} lg={5} className="center ">
                                    <Dropdown
                                        trigger={['click']}
                                        className="filter-element"
                                        visible={this.state.dropdowns.subrubros}
                                        onVisibleChange={(flag) => this.setState(state => {
                                            state.dropdowns.subrubros = flag
                                            return state
                                        })}
                                        overlay={
                                            <Menu style={{ minWidth: '260px', padding: '10px 5px' }}>
                                                {this.state.subrubros.length > 0 ? this.state.subrubros.map(({ _id, nombre }, index) => {

                                                    return <Menu.Item key={index} onClick={() => {
                                                        this.updateSubrubros(_id)
                                                    }}>
                                                        <input onChange={() => this.updateSubrubros(_id)} onClick={() => this.updateSubrubros(_id)} type="radio" name="subrubro" value={_id} style={{ margin: '0 5px', position: 'relative', top: '1px' }} checked={this.state.subrubro === _id} />
                                                        {nombre}
                                                    </Menu.Item>

                                                }) : <Empty description="Debe seleccionar un rubro." />}

                                            </Menu>
                                        }>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> Subrubros <IconArrow /> </a>
                                    </Dropdown>
                                </Col>*/}
                                {/* Download */}
                                {/*<Col xs={8} lg={2} className="center ">
                                    <a className="elm-download" title="Descargar">
                                        <BsDownload className="enc-downIco" />
                                    </a>
                                </Col>*/}
                            </Row>
                        </div>
                    </Col>
                </Row>
                {/* Cuerpo */}
                <Spin spinning={this.state.loading}>


                    <Row className="card-container crm" style={{ padding:'1em' }}>
                        <Col md={24} lg={16}>
                            <Row className="card-container" style={{ width: '100%' }} gutter={[20, 16]} >
                                <Col md={24} lg={12} >
                                    <Card className="crm-card card-ventas-totales">
                                        <Statistic
                                            className="crm-stats"
                                            title="Ventas Totales"
                                            value={ventas?.ventas_totales}
                                            precision={2}
                                            valueStyle={{ color: 'white' }}
                                            prefix="$"
                                        />
                                    </Card>
                                </Col>
                                <Col md={24} lg={12} >
                                    <Card className="crm-card card-ventas-cobrar">
                                        <Statistic
                                            className="crm-stats"
                                            title="Ventas por Cobrar"
                                            value={ventas?.ventas_cobrar}
                                            precision={2}
                                            valueStyle={{ color: 'white' }}
                                            prefix="$"
                                        />
                                    </Card>
                                </Col>
                                <Col md={24} lg={12} >
                                    <Card className="crm-card card-cobrado">
                                        <Statistic
                                            className="crm-stats"
                                            title="Total Cobrado"
                                            value={ventas?.total_cobrado}
                                            precision={2}
                                            valueStyle={{ color: 'white' }}
                                            prefix="$"
                                        />
                                    </Card>
                                </Col>
                                <Col md={24} lg={12} >
                                    <Card className="crm-card card-montos-vender">
                                        <Statistic
                                            className="crm-stats"
                                            title="Porcentaje Pagado"
                                            value={ventas?.porcentaje}
                                            precision={2}
                                            valueStyle={{ color: 'white' }}
                                            suffix="%"
                                        />
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ width: '100%' }} gutter={[20, 16]} >
                                <Col span={24}>
                                    <Card className="crm-card" title="Precio por Inmueble">
                                        <ReactECharts
                                            option={{
                                                tooltip: {
                                                    trigger: 'axis',
                                                    axisPointer: {            // Use axis to trigger tooltip
                                                        type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
                                                    }
                                                },
                                                xAxis: {
                                                    data: inmuebles?.map(({ inmueble }) => inmueble),
                                                    axisLabel: {
                                                        inside: true,
                                                        textStyle: {
                                                            color: '#fff'
                                                        }
                                                    },
                                                    axisTick: {
                                                        show: false
                                                    },
                                                    axisLine: {
                                                        show: false
                                                    },
                                                    z: 10
                                                },
                                                yAxis: {
                                                    axisLine: {
                                                        show: false
                                                    },
                                                    axisTick: {
                                                        show: false
                                                    },
                                                    axisLabel: {
                                                        textStyle: {
                                                            color: '#999'
                                                        }
                                                    }
                                                },
                                                dataZoom: [
                                                    {
                                                        type: 'inside'
                                                    }
                                                ],
                                                series: [
                                                    {
                                                        type: 'bar',
                                                        showBackground: true,
                                                        itemStyle: {
                                                            color: new echarts.graphic.LinearGradient(
                                                                0, 0, 0, 1,
                                                                [
                                                                    { offset: 0, color: '#83bff6' },
                                                                    { offset: 0.5, color: '#188df0' },
                                                                    { offset: 1, color: '#188df0' }
                                                                ]
                                                            )
                                                        },
                                                        emphasis: {
                                                            itemStyle: {
                                                                color: new echarts.graphic.LinearGradient(
                                                                    0, 0, 0, 1,
                                                                    [
                                                                        { offset: 0, color: '#2378f7' },
                                                                        { offset: 0.7, color: '#2378f7' },
                                                                        { offset: 1, color: '#83bff6' }
                                                                    ]
                                                                )
                                                            }
                                                        },
                                                        data: inmuebles?.map(({ costo }) => costo),
                                                    }
                                                ]
                                            }}
                                            notMerge={true}
                                            lazyUpdate={true}

                                            style={{ minHeight: 600 }}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Col >
                        <Col md={24} lg={8}>
                            <Row style={{ width: '100%' }} gutter={[20, 16]} >
                                <Col md={24}>
                                    <Card title="Ventas por Año" className="crm-card">
                                        <ReactECharts
                                            option={{
                                                xAxis: {
                                                    type: 'category',
                                                    data: ventas_anuales?.map(e => e._id)
                                                },
                                                yAxis: {
                                                    type: 'value'
                                                },
                                                tooltip: {
                                                    trigger: 'axis',
                                                    axisPointer: {
                                                        type: 'shadow'
                                                    },
                                                    formatter: "Ventas: {c} "
                                                },
                                                series: [{
                                                    data: ventas_anuales?.map(e => e.total),
                                                    type: 'bar'
                                                }]
                                            }}
                                            notMerge={true}
                                            lazyUpdate={true}
                                        />
                                    </Card>
                                </Col>
                                <Col md={24}>
                                    <Card className="crm-card" title="Ventas por Proyecto" extra={
                                        <Link to={"/admin/clientes"}>
                                            <Button type="primary" icon={<ArrowRightOutlined />} />
                                        </Link>}

                                    >
                                        <List
                                            className="demo-loadmore-list"
                                            itemLayout="horizontal"
                                            dataSource={ultimas}
                                            renderItem={item => (
                                                <List.Item actions={[parseFloat(item.total)]} >
                                                    <List.Item.Meta
                                                        avatar={<CustomAvatar image={item.proyecto?.logo} name={item.proyecto?.nombre} color={item.proyecto?.color} />}
                                                        title={item.proyecto?.nombre}
                                                        
                                                    />
                                                </List.Item>
                                            )}
                                        />

                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Spin>
                <ModalVentasPorUsuarios
                    ref={this.refModalCRM}
                    modalVisible={this.state.modalVisible}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                />
            </div>
        )
    }
}
