import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, Select, List, Typography, message, Spin } from 'antd';
//componentes
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios').default;
const { Option } = Select;
const { Title, } = Typography;


export default class ModalInmuebleTrabajo extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            inmuebles: [],
            empleados: [],
            proyectos: [],
            inmueble: {},
        }
    }

    //Referencia de form
    formInmuebleTrabajo = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadInmuebles();
        this.loadEmpleados();
        this.loadProyectos();

    }


    /**
     *
     *
     * @memberof ModalInmuebleTrabajo
     *
     * @function componentDidUpdate
     * @description cada vez que cambie la visivilidad del modal refresca los inmuebles diponibles, si se va editar trae la infomacion del inmuebles
     */
    componentDidUpdate(prevProps) {
        if (this.props.modalVisible !== prevProps.modalVisible){
            if (this.props.InmueblesTrabajo_id !== prevProps.InmueblesTrabajo_id && this.props.InmueblesTrabajo_id !== undefined) {
                this.getInmueble(this.props.InmueblesTrabajo_id)
            }
            this.loadInmuebles();
            this.loadEmpleados();
            this.loadProyectos();
        }
    }

    /**
    * @memberof ModalInmuebleTrabajo
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
        this.setState({loading: false, inmueble: {}})
    }

     /**
    * @memberof ModalInmuebleTrabajo
    * @param {*} values
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        if(this.props.modalTitle === 'Añadir')
            this.addInmueble(values);
        else if( this.props.modalTitle === 'Editar' )
            this.updateInmueble(values)
    }

    /**
    * @memberof ModalInmuebleTrabajo
    *
    * @method   loadInmuebles
    * @description  Trae un listado de tags de tipo inmueble
    *
    **/
    loadInmuebles = (search) => {
        axios.get('/tags', {
            params: {
                page: 1,
                limit: 50,
                type: "inmuebles", //tipo: 2
                search: search,
                inInmueblesTrabajos: true,
            }
        })
            .then(res => {
                this.setState({ inmuebles: res.data.data.itemsList })
            })
            .catch(e =>
                console.log('error inmuebles get', e)
            )
    }

    /**
     *
     * @memberof ModalInmuebleTrabajo
     *
     * @method loadEmpleados
     * @description Obtiene la lista de empleados (usuarios)
     */
    loadEmpleados = async (search = "") => {
        await axios.get("/usuarios", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                paginate: false,
            },
        })
            .then(({ data }) => {
                this.setState({ empleados: data.data })
            })
            .catch(e => console.log('er', e))
    }

    /**
     *
     *
     * @memberof ModalProyectoAdmin
     * @function loadProyectos
     *
     * @description Obtener proyectos.
     */
    loadProyectos = (search) => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
                search,
            }
        }).then((proyectos) => {
            this.setState({proyectos: proyectos.data.data})
        }).catch(e => console.log('er', e))
    }

    /**
     * @memberof ModalInmuebleTrabajo
     *
     *
     * @param {*} values
     * @method addInmueble
     * @description Manda peticion para crear una nueva actividad
     *
     */
    addInmueble = (values) => {
        this.setState({loading: true})
        axios.post('/inmuebles-trabajo/add', {
            proyecto_id: values.proyecto_id,
            inmueble_id: values.inmueble_id,
            responsable_id: values.responsable_id,
        })
        .then(res => {
            message.success('¡Inmueble registrado!');
            this.hideModal()
        })
        .catch(error => {
            this.setState({ loading: false })
            console.log('error al crear actividad', error);
            message.error('Inmueble no gurdaddo');
        })
       /* .finally(f => {
            this.loadActividades();
            this.hideModal();
        })*/

    }

    /**
     * @memberof ModalInmuebleTrabajo
     *
     *
     * @param id ObjectId
     * @method getInmueble
     * @description Manda peticion para traer un registro de Inmueble_trabajo por medio de su id
     *
     */
    getInmueble = (id) => {
        this.setState({loading: true})
        axios.post('/inmuebles-trabajo/get', {
            id: id,
        })
        .then(res => {
            this.setState({inmueble: res.data.data, loading: false })
            this.formInmuebleTrabajo.current.setFieldsValue({
                proyecto_id: res.data.data.proyecto_id,
                inmueble_id: res.data.data.inmueble_id._id,
                responsable_id: res.data.data.responsable_id,
            })
        })
        .catch(error => {
            console.log('error al buscar el Inmueble', error);
            this.setState({ loading: false })
            message.error('Inmueble no encontrado');
        })
       /* .finally(f => {
            this.loadActividades();
            this.hideModal();
        })*/

    }


    /**
    * @memberof ModalInmuebleTrabajo
    *
    *
    * @param {*} Values
    * @method updateInmueble
    * @description Manda la peticion para actualizar un Inmueble
    */
    updateInmueble(values) {
        this.setState({ loading: true })
        axios.post('/inmuebles-trabajo/update', {
            id: this.props.InmueblesTrabajo_id,
            proyecto_id: values.proyecto_id,
            inmueble_id: values.inmueble_id,
            responsable_id: values.responsable_id,

        })
        .then(res => {
            message.success('¡Inmueble Actualizado!');
            this.hideModal()
        })
        .catch(error => {
            this.setState({ loading: false })
            console.log('error al guardar Inmueble', error);
            message.error('¡Inmueble no Actualizado!');
        })
    }

    /**
    * @memberof ModalInmuebleTrabajo
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

       let arr = [];
       if(array){
            array.forEach(function ({ _id, nombre, apellido }, index) {
                arr.push (<Option value={_id}> {nombre} {apellido}</Option>)
            })
       }

       if(array && addElemnt){
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
       }
        return arr
    }

    render() {
        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-rubro"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} Inmueble</Title>
                </div>
                 <Spin spinning={this.state.loading}>

                    <Form
                        layout="vertical"
                        name="formulario-InmuebleTrabajo"
                        onFinish={this.onFinish}
                        ref={this.formInmuebleTrabajo}
                    >

                        <Row align="center">
                            <Col xs={22} md={20}>
                                <Form.Item
                                    label="Proyecto"
                                    name="proyecto_id" //<--------------------------------------- proyecto_id
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese un proyecto"
                                    }]}
                                >
                                    <Select
                                        onSearch={(search) => this.loadProyectos(search)}
                                        showSearch
                                        filterOption={false}
                                    >
                                        {this.state.proyectos.map((proyecto) =>  <Option value={proyecto._id}>
                                            <CustomAvatar
                                                image={proyecto.logo}
                                                name={proyecto.nombre}
                                                color={proyecto.color}
                                                size="small"
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            />
                                            {proyecto.nombre}
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col xs={22} md={20}>
                                <Form.Item
                                    label="Inmueble"
                                    name="inmueble_id" //<--------------------------------------- inmueble_id
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese un tajo"
                                    }]}
                                >

                                    <Select
                                        optionFilterProp="children"
                                        clearIcon={<IconArrowSelect />}
                                        showSearch
                                    >
                                        {this.selectOptions(this.state.inmuebles,this.state.inmueble.inmueble_id)}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row align="center">
                            <Col xs={22} md={20}>
                                <Form.Item
                                    label="Responsable"
                                    name="responsable_id" //<--------------------------------------- responsable_id
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese un responsable"
                                    }]}
                                >
                                    <Select
                                        optionFilterProp="children"
                                        clearIcon={<IconArrowSelect />}
                                        showSearch
                                    >
                                        {this.selectOptions(this.state.empleados)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col xs={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
