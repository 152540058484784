import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Popconfirm, Collapse, Typography, List, message, Pagination } from "antd";
import { ReloadOutlined, WarningFilled, MinusOutlined, PlusOutlined, CloseCircleFilled, CheckCircleFilled, AuditOutlined } from '@ant-design/icons'

//componentes
import { IconDelete, IconEdit, IconPlus } from './../../../../Widgets/Iconos';

//modales
import ModalProductosInventario from './../../Productos/ModalProductosInventario';
import ModalProductoAlmacenGeneral from './ModalProductoAlmacenGeneral';



const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;
const axios = require('axios');

var tabActiva = "";

/**
 * @export
 * @class ProductosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class ProductosList extends Component {


	constructor(props) {
		super(props)
		this.state = {
			productos: [],
			page: 1,
			limit: 10,
			total: 0,      

		}
	}

	componentDidMount = () => {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		this.getProductos()
	}



	/**
	* @memberof ProductosList
	* @method getProductos
	* @description  Obtiene los productos registrados del alamcen
	**/
	getProductos = (page = this.state.page) => {
		axios.get('/almacen/productos/detalles',{
			params:{
				page: page,
				proyecto_id: this.props.proyecto_id
			}
		}).then(response => {
			console.log("response", response.data.data);
			this.setState({
				productos: response.data.data.itemsList,
				page: response.data.data.currentPage,
				total: response.data.data.itemCount,
			})

		}).catch(error => {
			message.error('Error al obtener los alamcenes')
		})
	}
	

	render() {

		return (
			<>
				<Content>
					<Row className="">
						<Col span={24}>
							<List
								className="component-list lista-vmi"
								dataSource={this.state.productos}
								locale={{ emptyText: "Sin Productos" }}


								renderItem={({ _id, producto_id, producto_opcion_id, total, almacenes }) => {

									return <Collapse
										bordered={false}
										defaultActiveKey={['1']}
										expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} /> :
											<PlusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} />}
										className="collapse-almacenes"
									>
										<Panel
											className="w100 collapse-list"
											extra={
												<Row className="w100 header-collapse ">
													
													<Col span={6} className="flex-left-column">
														<Text ellipsis>{producto_id?.nombre} </Text>
														<Text style={{fontSize: '11px'}}>{producto_id.sku_manantial}</Text>
													</Col>

													<Col span={6} className="flex-left">
														<Text ellipsis>{producto_opcion_id?.sku_proveedor}</Text>
													</Col>

													<Col span={6} className="flex-column">
														<Text ellipsis>Total</Text>
														<Text ellipsis>{total}</Text>
													</Col>
													<Col span={6} className="center">
														<Button 
                                                            className="btn btn-edit" 
                                                            title="Editar" 
                                                            onClick={(e) => this.setState({
                                                                inventario_producto_id: _id, 
                                                                modal_visible_almacen: true
                                                            })}>
                                                            <IconPlus height="15px" width="15px"/>
                                                        </Button>
                                                        <Button className="btn btn-edit" title="Editar" onClick={(e) => this.setState({inventario_producto_id: _id, modal_visible: true})}>
                                                            <IconEdit />
                                                        </Button>
													</Col>

												</Row>
											}
										>

											<Row className="p-1">
												<Col span={6} className="center">
													<Text className="text-gray">Almacén</Text>
												</Col>
												<Col span={6} className="center">
													<Text className="text-gray">Ubicación</Text>
												</Col>
												<Col span={6} className="flex-right" style={{ paddingRight: '1.5rem' }}>
													<Text className="text-gray">Cantidad</Text>
												</Col>

											</Row>

											{
												almacenes.map((almacen) => {

													const { almacen_id, ubicacion, cantidad } = almacen;

													return (
														<Row className="border-bottom">
															<Col span={6} className="center">
															 	{almacen.almacen_id.nombre}
															</Col>
															<Col span={6} className="center">
															   {ubicacion}
															</Col>
															<Col span={6} className="flex-right" style={{ paddingRight: '1.5rem' }}>
																{cantidad}
															</Col>
															<Col span={6} className="center" style={{ paddingRight: '1.5rem' }}>
                                                                <Button 
                                                                    className="btn btn-edit" 
                                                                    title="Editar" 
                                                                    onClick={(e) => this.setState({
                                                                    	modal_visible_almacen: true,
                                                                    	almacen_producto_id: almacen._id,
                                                                    })}
                                                                >
                                                                    <IconEdit />
                                                                </Button>
                                                            </Col>

														</Row>
													)
												})
											}
										</Panel>
									</Collapse>

								}}
							/>

						</Col>

						<Col span={24}>

							<Pagination
								current={this.state.page}
								total={this.state.total}
								pageSize={10}
								showSizeChanger={false}
								onChange={(page) => this.getProductos(page)}

							/>
						</Col>
					</Row>

					<Button className="btnAddPlus" title="Nuevo registro" onClick={()=>this.setState({modal_visible: true})}>
						<IconPlus />
					</Button>
					<ModalProductosInventario 
						visible={this.state.modal_visible}
						onCancel={()=>{
							this.setState({modal_visible: false, inventario_producto_id: undefined})
							this.getProductos()
							this.props.getProyecto()
						}}
						proyecto_id={this.props.proyecto_id}
						inventario_producto_id={this.state.inventario_producto_id}
					/>
					<ModalProductoAlmacenGeneral
						visible={this.state.modal_visible_almacen}
						onCancel={()=>{
							this.setState({modal_visible_almacen: false, inventario_producto_id: undefined, almacen_producto_id: undefined})
							this.getProductos()
						}}
						proyecto_id={this.props.proyecto_id}
						inventario_producto_id={this.state.inventario_producto_id}
						almacen_producto_id={this.state.almacen_producto_id}
					/>
				</Content>


			</>
		)
	}
}