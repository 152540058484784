import React, { Component } from 'react';
import { Row, Col, Table, message, Radio, Checkbox, Spin, Button } from 'antd'
import socketIOClient from 'socket.io-client'
import { Link } from 'react-router-dom';

import {
    IconPlus,
    IconNotiRecordatorio, IconNotiSolicitud, IconNotiNotificacion
} from '../../Widgets/Iconos';
import Logged from "../../../Hooks/Logged";
import moment from 'moment'

const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');



const rutas = {
    Transacciones: '/admin/transacciones',
    Clientes:'/admin/clientes/detalle/',
    RegistrarNomina:'/admin/nomina/administrar-nomina-finanzas/',
    RevisarNomina:'/admin/nomina/administrar-nomina/',
    Nomina:'/admin/nomina'
}


export default class Notificaciones extends Component {

    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {
            dataNotificaciones: [],
            loading: true,
            tipo_user: 0,

            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,
            server: axios.defaults.baseURL,
        }
    }




    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Obtenemos el listado de Areas
        this.receivedData();
        //this.consultarNotificaciones()
    }
    /**
        * @method receivedData
        * @description Obtiene la informacion y actualiza los valores locales de la lista.
        */
    receivedData = () => {
        this.setState({loading: true})
        const { server } = this.state;
        const socket = socketIOClient(server);
        socket.connect();
        socket.on("notificaciones", data => {
          this.setState({
            dataNotificaciones: data,
            loading:false
          });
        }
        );
        socket.emit('suscripcion', {token: sessionStorage.getItem('token')})
    }


    /**
     * @memberof Notificaciones
     * @param id Id de la notificacion a cambiar
     * @param usuario_id Id del usuario que leera la notificacion
     */
    onCheckChange=(e,id, usuario_id)=>{
        this.setState({notificacion_select: id})
       if(e.target.checked){
            axios.post('/notificaciones/leer/id',{
                id:id,
                user_id:usuario_id._id,
            })
            .then( result=>{
                message.success('Notificacion leída')
                this.receivedData()
            })
            .catch(error=>{
                console.log('ERROR',error)
            })
       }
    }


    consultarNotificaciones=()=>{
        this.setState({
            loading:true,
            dataNotificaciones:[],
        });

        var tipo = document.getElementsByClassName('ant-radio-button-checked')[0].firstElementChild.value;
         axios.post('/notificaciones/consultar',{
             leidas : (tipo=="nuevas")
         })
         .then( result=>{
             setTimeout(() => {
                 this.setState({
                     dataNotificaciones: result.data.data,
                     loading:false
                 });
                 
             }, 1000);

         })
         .catch(error=>{
             console.log('ERROR')
         })
     }


    render() {
        const user = this.context;
        var tipo = "nuevas";
        if(document.getElementsByClassName('ant-radio-button-checked').length > 0){
            tipo = document.getElementsByClassName('ant-radio-button-checked')[0].firstElementChild.value;
        }
        var columns = [];

        if (tipo === "nuevas") {
            columns.push(
                {
                    title: '',
                    key: '_id',
                    width: 100,
                    render: (text, { _id, evento }) => {
                       return <Checkbox checked={this.state.notificacion_select === _id} onChange={(e)=>{ this.onCheckChange(e,_id,user) }} />
                    }
                },
            )
        }
        columns.push(
            {
                title: '',
                key: 'tipo',
                width: 200,

                render: (text, { _id, tipo, }) =>
                    <div>
                        {(tipo === 3) ? <IconNotiRecordatorio /> : ''}
                        {(tipo === 2) ? <IconNotiSolicitud /> : ''}
                        {(tipo ===1) ? <IconNotiNotificacion /> : ''}

                    </div>
            },
            {
                title: '',
                key: 'titulo',
                width: 400,
                render: (text, { _id, tipo ,evento,proyecto_id,coleccion,objeto_id }) =>
                    <div>
                        {
                            <div>
                                <Link to={ (coleccion=="Transacciones" || coleccion=="Nomina")?rutas[coleccion] :  rutas[coleccion]+objeto_id}>

                                <h2 style={{ fontSize: "17px", fontWeight: "800" }}> {evento}</h2>
                                <div className="record" style={{ backgroundColor: "#C4C4C4", padding: "3px", width: '50%' }}>
                                     {(proyecto_id)?proyecto_id.nombre:'N/A'}
                                </div>
                                </Link>
                            </div>}
                    </div>
            },

            {
                title: '',
                key: 'detalle',
                width: 400,
                render: (text, { _id, tipo, detalle}) =>
                    <div>
                        {detalle}
                    </div>
            },
        
            {
                title: '',
                dataIndex: 'fecha',
                key: 'fecha',
                render: (text, {createdAt}) => moment(createdAt).format('DD-MM-YYYY HH:mm')

            });

        return (
            <div>
                <Spin spinning={this.state.loading}>

                <Row className="row-encabezado">
                    <Col xs={24} sm={5} >
                        <h2>Notificaciones</h2>
                    </Col>

                    <Col xs={24} sm={18} className="" >
                        <Row align="left" style={{ textAlign: "right", display: "block" }} className="m-0">
                            <Col className="" style={{marginTop: '7px'}}>
                                <Radio.Group defaultValue="nuevas" className="radioTipo" onChange={this.consultarNotificaciones}>
                                    <Radio.Button value="nuevas">Nuevas</Radio.Button>
                                    <Radio.Button value="revisadas">Revisadas</Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <section style={{ background: '#FFF', padding:"10px" }}>
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={this.state.dataNotificaciones}
                        scroll={{ x: 1000  }}
                        className="blankTheme" />

                </section>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                    <IconPlus />
                </Button>
                </Spin>


            </div>
        )
    }
}
