import React, {Component} from 'react';

import {
    Button,
    Col,
    Row,
    Spin,
    Empty,
    Typography,
    Progress,
    List,
    Pagination,
    Badge,
    Card,
    Popconfirm,
    Breadcrumb,
    message,
    Popover
} from 'antd'

import {FaArrowRight} from "react-icons/fa";
import {Link} from 'react-router-dom';

//componentes
import {CardInfoDashboard, CardProgress, CardActivity, CardInfo, CardProgressBar} from '../../Widgets/Cards/Cards';
import {IconEdit, IconDelete, IconPlus, IconArrowBack, IconEye} from '../../Widgets/Iconos';
import Avatar from "../../Widgets/Avatar/Avatar";
//modales
import ModalEntrega from '../Modales/ProjectManager/ModalEntrega';
import ModalEntregaDetalle from '../Modales/ProjectManager/ModalEntregaDetalle';

//css
import '../../../Styles/projectManager.css'
import ColorPicker from '../../Widgets/ColorPicker/ColorPicker';

const moment = require('moment')
const {Text} = Typography;
const axios = require('axios').default;

 
export default class PMmaterialActividad extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalEntregasVisible: false,
            ModalEntregaDetalleVisible: false,

            accionModal: 'agregar',

            actividad_id: "",
            empleado_id: "",
            proyecto_id: "",
            material_id: "",
            inmueble_id: "",


            proyecto_nombre: "[Proyecto]",
            actividad_nombre: "[Actividad]",
            empleado_nombre: "[Empleado]",
            material_nombre: "[Material]",

            inmueble_nombre: "[Inmueble]",

            dataMateriales: [],
            materialesRequeridos: 0,
            materialesEntregados: 0,
            procesoDeEntrega: 0,

            dataBitacora: [],

            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,

            loadingPage: true,


            actividad: {
                responsable_id: {},
                actividad_id: {}
            },

            loading: false,
            material: {},
            materiales_entregados: {
                data: [],
                page: 1,
                limit: 10
            },


            suma_materiales: 0,

        }
    }

    /**
     * @memberof PMmaterialActividad
     *
     * @method   componentDidMount
     * @description
     *
     **/
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search

        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        var id = this.props.match.params.id.split('_');

        this.setState({
            manager_actividad_id: id[0], //Actividad
            material_id: id[1], //Material
            manager_proyecto_id: id[2] //Proyecto
        });

        setTimeout(() => {
            this.getBitacora();
        }, 0.5);

        this.getMaterialesDeActividadPorEmpleado()
    }


    /**
     * @memberof PMmaterialActividad
     *
     * @method   getBitacora
     * @description  Trae la lista de las entregas realizadas del material seleccionado a la actividad seleccionada
     *
     **/
    getBitacora = async (page = this.state.currentPage, size = this.state.perPage) => {
        var manager_proyecto_id = this.state.manager_proyecto_id;
        var manager_actividad_id = this.state.manager_actividad_id;
        var material_id = this.state.material_id;
        var search = this.props.search;

        axios.get('manager/proyecto/actividades/empleado/materiales', {
            params: {
                manager_proyecto_id: manager_proyecto_id,
                manager_actividad_id: manager_actividad_id,
                material_id: material_id,
                page: page,
                limit: size,
                search: search,

            }
        })
            .then(({data}) => {
                console.log('traje entregas de material', data);

                var materialRequerido = 0;
                var dataMat = [];
                //Entregas
                var dataReg = data.data.materiales_entregados;
                //console.log('traje materiales', data);

                var dataActividad = data.data.actividad.actividad_id;
                //console.log("dataActividad ", dataActividad);

                var dataInmueble = data.data.actividad.inmueble_id;
                //console.log("dataInmueble ", dataInmueble);

                var dataEmpleado = data.data.actividad.responsable_id;
                //console.log("dataEmpleado ", dataEmpleado);

                var dataMaterial = data.data.material;
                //console.log("dataMaterial ", dataMaterial);


                var dataProyecto = data.data.proyecto.proyecto_id;
                //console.log("dataProyecto ", dataProyecto);
 
               
                this.setState({

                    loadingPage: false,
                    proyecto_id: dataProyecto._id,
                    proyecto_nombre: dataProyecto.nombre,
                    proyecto_color: dataProyecto.color,
                    proyecto_descripcion: dataProyecto.descripcion,
                    proyecto_logo: dataProyecto.logo,
                    manager_proyecto_id: data.data.actividad.manager_proyecto_id,
                    empleado_id: dataEmpleado._id,
                    empleado_nombre: (dataEmpleado.nombre + " " + dataEmpleado.apellido),
                    actividad_id: dataActividad._id,
                    actividad_nombre: dataActividad.nombre,
                    material_nombre: dataMaterial.nombre,
                    materialesEntregados: ((data.data.cantMaterialEntregado).toMoney()), //data.data.cantMaterialEntregado,
                    materialesRequeridos: ((data.data.cantMaterialRequerido).toMoney()), //data.data.cantMaterialRequerido,
                    procesoDeEntrega:  data.data.avanceEntrega.toFixed(2),

                    itemCount: data.data.pagination.itemCount,
                    pageCount: data.data.pagination.pageCount,
                    dataBitacora: dataReg,
                });
            })
            .catch(error => {
                console.log('no traje nara', error);
            })
    }


    /**
     * @memberof PMmaterialActividad
     *
     * @method   modalAgregar
     * @description  Abre el modal de entregas en modo agregar para registrar entradas del material
     *
     **/

    modalAgregar = () => {
        this.setState({
            accionModal: 'agregar',
            modalEntregasVisible: true
        })
    }


    /**
     * @memberof PMmaterialActividad
     *
     * @method   hideModal
     * @description  Cambia state para cerrar cualquier modal
     *
     **/
    hideModal = () => {
        this.setState({modalEntregasVisible: false, ModalEntregaDetalleVisible: false})

        this.getMaterialesDeActividadPorEmpleado()
        this.getBitacora(1);
    }


    /**
     * @memberof PMmaterialActividad
     *
     * @method   getMaterialesDeActividadPorEmpleado
     * @description Obtenemos los mageriales de actividad por empleado
     *
     * @param page Pagina del material
     * @param limit Limite de elementos
     *
     **/
    getMaterialesDeActividadPorEmpleado = (page = this.state.materiales_entregados.page, limit = this.state.materiales_entregados.limit) => {

        this.setState({loading: true})

        const {manager_actividad_id, material} = this.props.match.params;
        axios.get('/manager/proyecto/actividades/empleado/materiales', {
            params: {
                manager_actividad_id,
                material_id: material,
                page,
                limit
            }
        })
            .then(({data}) => {
                data = data.data

                console.log('data', data)
                this.setState(state => {
                    state.loading = false;
                    state.materiales_entregados = {
                        data: data.materiales_entregados,
                        actividad: data.actividad,
                        page,
                        limit
                    };

                    state.actividad = data.actividad;
                    state.suma_materiales = data.suma_materiales;

                    state.material = data.material;

                    return state
                })
                console.log('response', data)
            })
            .catch(({response}) => {
                console.log('response', response)
            })

    }

    /**
     * @memberof PMmaterialActividad
     *
     * @method deleteActividad
     * @description Eliminamos la actividad
     *
     * @param entrega_id id de la entrega a eliminar
     *
     **/
    deleteActividad = (entrega_id) => {

        var id_manager_actividad = this.state.manager_actividad_id;
        var id_material = this.state.material_id;

       //  console.log('entrega_id',entrega_id)
       //  console.log('id_manager_actividad',id_manager_actividad)
       //  console.log('id_material',id_material)
        axios.delete('/manager/proyecto/actividades/empleado/entregas/delete', {
            params: {
                id_manager_actividad: id_manager_actividad,
                material_id: id_material,
                entrega_id
            }
        })
            .then(({data}) => {
                message.success("!Se eliminó entrega de material!");
                this.state.loadingPage = true;
                this.state.currentPage = 1;

                this.getBitacora();
                this.getMaterialesDeActividadPorEmpleado()


                console.log('response', data)
            })
            .catch(({response}) => {
                message.error("!Error, no se pudo eliminar entrega");

                console.log('response', response)
            })
    }


    render() {
        const {manager_actividad_id, material} = this.props.match.params

        let porcentaje = (parseFloat(this.state.suma_materiales) * 100) / parseFloat(this.state.actividad.materiales_presupuestados)

        if (isNaN(porcentaje)) porcentaje = 0
        else porcentaje = porcentaje.toFixed(2)

        //  console.log('porcentaje', porcentaje);

        const contentMenuBack = (
            <div className="div-menuplus div-menuBack">
                <Link to={`/admin/project-manager/material-empleado-actividad/${this.state.manager_actividad_id}`}
                      style={{color: "white"}}>
                    <Button htmlType="submit" type="primary" className="btn-azul">
                        Materiales asignados
                    </Button>
                </Link>
                <Link
                    to={`/admin/project-manager/material-empleado/${this.state.manager_proyecto_id + "_" + this.state.empleado_id}`}
                    style={{color: "white"}}>
                    <Button htmlType="submit" type="primary" className="btn-azul">
                        Actividades asignadas
                    </Button>
                </Link>
                <Link to={`/admin/project-manager/proyecto/${this.state.manager_proyecto_id}`}
                      style={{color: "white"}}>
                    <Button htmlType="submit" type="primary" className="btn-azul"
                            onClick={this.modalCrearTransaccionCliente}>
                        Proyecto
                    </Button>
                </Link>


                <Link to={`/admin/project-manager`}
                      style={{color: "white"}}>
                    <Button htmlType="submit" type="primary" className="btn-azul" to="/admin/project-manager"
                            onClick={this.modalCrearTransaccionCliente}>
                        Project Manager
                    </Button>
                </Link>


            </div>
        )

        return (
            <div>

                <Spin spinning={this.state.loadingPage}>
                    <Row className="row-encabezado">
                        <Col xs={24}>
                            <Breadcrumb className="breadcrumb" separator=""
                                        style={{fontSize: "1.5em", marginTop: "10px"}}>
                                <Breadcrumb.Item className="breadcrumb-actual">
                                    <Popover
                                        content={contentMenuBack}
                                    >
                                        <Button className="IconArrowBack" onClick={this.volver}>
                                            <IconArrowBack/>
                                        </Button>
                                    </Popover>

                                    {this.state.material_nombre + " de " + this.state.actividad_nombre + " de " + this.state.empleado_nombre}
                                </Breadcrumb.Item>
                            </Breadcrumb>

                        </Col>
                    </Row>


                    <section className="p-1">

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Material"
                                    infoLeft={this.state.material_nombre}
                                    titleRight="Actividad"
                                    infoRight={this.state.actividad_nombre}/>
                            </Col>

                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Materiales Presupuestados"
                                    infoLeft={this.state.materialesRequeridos + " tons"}
                                    titleRight="Materiales Entregados"
                                    infoRight={this.state.materialesEntregados + " tons"}/>
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardProgressBar
                                    title="Proceso de Entrega"
                                    percent={this.state.procesoDeEntrega}
                                />
                            </Col>
                        </Row>

                        <div className="list-title">Bitacora</div>

                        <div className="scroll">
                            
                            <Row style={{width: '100%', paddingLeft: '1rem', paddingRight: '1rem'}} className="title-list-inmuebles mb-1">
                                <Col span={6} className="center">
                                    <Text type="secondary">Fecha</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text type="secondary">Cantidad</Text>
                                </Col>
                                <Col span={9} className="center">
                                    <Text type="secondary">Proyecto</Text>
                                </Col>

                                <Col span={3} className="center">
                                    <Text type="secondary">Opciones</Text>

                                </Col>
                            </Row>

                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.dataBitacora}
                                locale={{emptyText: 'Sin entregas'}}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{width: '100%'}} className="">
                                                <Col span={6} className="center">
                                                    <Text strong> {moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text>{((item.cantidad).toMoney()) + " tons"} </Text>
                                                </Col>
                                                <Col span={9} className="center">
                                                    <div title={this.state.proyecto_nombre}>
                                                        <Avatar
                                                            name={this.state.proyecto_nombre}
                                                            // image={this.state.proyecto_logo}
                                                            color={this.state.proyecto_color}
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                fontSize: '20px',
                                                                paddingTop: '0px',
                                                                marginRight: '5px'
                                                            }}
                                                            className="avatar-info-card"
                                                        />
                                                        {this.state.proyecto_nombre}

                                                    </div>

                                                </Col>
                                                <Col span={3} className="center">
                                                    <Popconfirm
                                                        title={"¿Deseas eliminar entrada de " + moment(item.fecha).format('DD/MM/YYYY') + " ?"}
                                                        placement="topRight"
                                                        okText="Si"
                                                        cancelText="No"
                                                        onClick={e => e.stopPropagation()}
                                                        onConfirm={e => this.deleteActividad(item._id)}
                                                    >
                                                        <Button className="btn btn-delete"
                                                                onClick={e => e.stopPropagation()}>
                                                            <IconDelete/>
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />

                        </div>


                        <Row className="mt-2 pb-3">
                            <Pagination
                                defaultCurrent={1}
                                total={this.state.itemCount}
                                showSizeChanger={false}
                                pageSize={this.state.perPage}
                                onChange={(page) => {
                                    this.getBitacora(page)
                                }}
                            />
                        </Row>


                    </section>

                    <ModalEntrega
                        modalVisible={this.state.modalEntregasVisible}
                        accionModal={this.state.accionModal}

                        material_id={this.state.material_id}
                        material_nombre={this.state.material_nombre}
                        manager_actividad_id={this.state.manager_actividad_id}
                        responsable_id={this.state.empleado_id}


                        managerActividad={this.state.manager_actividad_id}
                        material={this.state.material_id}


                        closeMethod={this.hideModal}
                        accept={() => {
                            this.getBitacora(1);
                            this.hideModal();
                        }}
                    />

                    <Button type="ghost" className="btnAddPlus" onClick={() => this.modalAgregar()}>
                        <IconPlus/>
                    </Button>

                </Spin>
            </div>
        )
    }
}
