import React from 'react';
import { Row, Col, Card, Typography, Progress, Tag, Button, Image, Statistic } from 'antd';
import { CloseCircleFilled, WarningFilled, CheckCircleFilled } from '@ant-design/icons';
import './cards.css'
import { Link } from "react-router-dom";
import { IconEye, IconPDFColor ,IconError,
    IconSuccess,
    IconWarning,
	IconFacturaElectronica} from '../../Widgets/Iconos'
import CustomAvatar from '../Avatar/Avatar';

const { Text } = Typography;
const moment = require('moment');
const axios = require('axios')
/*
*  COMPOENENTES (CARDS) UTILIZADOS PARA EL MODULO DE PROJECT MANAGER Y MANAGER
*/

/*
* @function CardInfoDashboard
* @desrcition Card sencilla muestra un titulo y un dato
*
* @params background: color de fondo de la Card
* @params title: titulo principal
* @params number: numero o dato a deslpegar
*/
function CardInfoDashboard(props) {
	return (
		<Card style={{ width: '100%', background: props.background, flex: ' 1 1 auto', }} className={`PM-card-dashboard ${props.text} ${props.hover ? 'hover-effect' : ''}`}>
			<div>
				<h3
					className={`PM-card-title`}
					style={{ textAlign: (props.textAlign) ? "center" : undefined }}
				>{props.title}</h3>
			</div>
			<div><h4 className="PM-card-body" >{props.number ? props.number : null}</h4></div>
			{props.children}
		</Card>
	);
}

/*
* @function CardProgress
* @desrcition Card que muestra diferente tipo de informacion
*
* @params title: titulo principal
* @params number: dato a deslpegar
* @params avatar: Recibe un componente <Avatar> a desplegar
* @params titleBar: titulo de la barra de progreso
* @params start - finish: encaso de no contar con titleBar en su lugar se ingresa fechas
* @params percent: porcentage para la barra de progreso
* @params onClick: Metodo que se ejecuta al hacer click en la Card
*/
function CardProgress(props) {
	return (
		<Card style={{ width: '100%' }} className={`PM-card-progress ${props.hover ? 'hover-effect' : ''}`} onClick={props.onClick}>
			<Row>
				<Col span={16} className="card-progress-header">
					<p className="card-progress-title">{props.title}</p>
					<h3 className="card-progress-name">{props.name}</h3>
				</Col>
				<Col span={8} className="card-progress-avatar">
					{props.avatar}
				</Col>
			</Row>
			<Row>
				<Col span={24} className="card-progress-dates-col">
					{props.titleBar === undefined ? <Text className="card-progress-dates"> {props.start} - {props.finish}</Text>
						: <Text className="card-progress-dates"> {props.titleBar}</Text>}
				</Col>
			</Row>
			<Row>
				<Col span={24} className="card-progress-bar">
					<Progress percent={props.percent.toMoney(2)} />
				</Col>
			</Row>
		</Card>
	);
}

/*
* @function CardActivity
* @desrcition Card de Actividades que se encuetra en el Dashboard
*
* @params name: nombre principal
* @params date: fecha
* @params tar: texto del tag
* @params percent: porcentage para la barra de progreso
*/
function CardActivity(props) {
	return (
		<div>
			<Card style={{ width: '100%' }} className="PM-card-activity">
				<Row>
					<Col span={24} className="card-activty-tag">
						<Tag className="activty-tag" color="#bdd0ff">{props.tag}</Tag>
					</Col>
				</Row>
				<Row>
					<Col span={24} className="card-activity-main">
						<p className="card-activity-name"> {props.name} </p>
						<p className="card-activity-date"> {props.date} </p>
					</Col>
				</Row>
				<Row>
					<Col span={24} className="card-progress-bar">
						<Progress percent={props.percent} />
					</Col>
				</Row>
			</Card>
		</div>
	);
}

/*
* @function CardProgressBar
* @desrcition que solamente muestra un titulo y la barra de progreso
*
* @params title: titulo principal
* @params percent: porcentage para la barra de progreso
* @params hover (bool): en caso de que esta Card haga algo al hacer click, poner true para efecto hover
* @params onClick: Metodo que se ejecuta al hacer click en la Card
*/
function CardProgressBar(props) {
	return (
		<div style={{ width: '100%', padding: '0.5rem' }} className={`PM-card-bar ${props.hover ? 'hover-effect' : ''}`} onClick={props.onClick}>
			<Row>
				<Col span={24} className="card-bar-main">
					<Text className="card-bar-title"> {props.title} </Text>
				</Col>
			</Row>
			<Row>
				<Col span={24} className="card-progress-bar">
					<Progress percent={props.percent} status={props.status} />
				</Col>
			</Row>
		</div>
	);
}

/*
* @function CardInfo
* @desrcition Card dividida en 2 lados cada uno tiene un titulo y dato a mostrar, generalmente texto, grafica pequeña o Avatar
*
* @params titleLeft: titulo principal izquierdo
* @params titleRight: titulo principal derecho
* @params infoLeft: dato izquierdo
* @params infoRight: dato derecho
*/
function CardInfo(props) {
	return (
		<div style={{ width: '100%' }} className={"PM-card-info " + props.className}>
			<Row>
				<Col span={12} className="card-info-titles">
					<Text className="card-info-title">{props.titleLeft}</Text>
				</Col>
				<Col span={12} className="card-info-titles">
					<Text className="card-info-title">{props.titleRight}</Text>
				</Col>
				<Col span={12} className="card-info-infos">
					<Text className="card-progress-name">{props.infoLeft}</Text>
				</Col>
				<Col span={12} className={`card-info-infos ${props.titleRight ? '' : 'center-info'}`}>
					<Text className="card-progress-name">{props.infoRight}</Text>
				</Col>
			</Row>
		</div>
	)
}

/*
* @function CardProject
* @desrcition Card que muestra diferente tipo de informacion
*
* @params title: titulo principal
* @params number: dato a deslpegar
* @params avatar: Recibe un componente <Avatar> a desplegar
* @params titleBar: titulo de la barra de progreso
* @params start - finish: encaso de no contar con titleBar en su lugar se ingresa fechas
* @params percent: porcentage para la barra de progreso
* @params onClick: Metodo que se ejecuta al hacer click en la Card
*/
function CardProject({ title, to, name, onClick, avatar, onDelete, deleteProyecto }) {
	return (
		<Card style={{ width: '100%', position: 'relative' }} className="PM-card-progress PM-card-project">
			<Row>
				<Col span={16} className="card-progress-header">
					<p className="card-progress-title">{title}</p>
					<h3 className="card-progress-name">{name}</h3>
				</Col>
				<Col span={8} className="card-progress-avatar">
					{avatar}
				</Col>
			</Row>
			<Row>
				<Col span={24} className="center">
					<Button type="primary" className="btn-admin" onClick={onClick}>
						<Link title="Editar" to={to} >
							Administrar
						</Link>
					</Button>
				</Col>
			</Row>
			{(onDelete) ? <Button className="button-delete-project" onClick={deleteProyecto}>x</Button> : null}
		</Card>
	);
}

/*
* @function CardProveedor
* @desrcition Card sencilla muestra un titulo y un dato
*
* @params background: color de fondo de la Card
* @params title: titulo principal
* @params number: numero o dato a deslpegar
*/
function CardProveedor(props) {

	let icon = null
	if (props.vencidas > 0) icon = <CloseCircleFilled style={{ fontSize: '37px', color: '#ff4d4f' }} />
	else if (props.por_vencer > 0) icon = <WarningFilled style={{ fontSize: '37px', color: '#faad14' }} />
	else icon = <CheckCircleFilled style={{ fontSize: '37px', color: '#52c41a' }} />

	return (
		<>
			<Link to={`/admin/compras/proveedores/detalle/${props._id}`}>
				<Card style={{ width: '100%' }} className="card-proveedores hover-effect">
					<div>
						<Text className="card-title">proveedor</Text>
					</div>
					<div style={{ flex: '1 1 auto' }} className="content-title">
						<Text className="title-proveedor">{props.alias}</Text>
						{icon}
					</div>
					<div style={{ flex: '1 1 auto' }} className="content-title">
						<Text strong>Saldo Total: $ {props.saldo_total?.toMoney()} MXN</Text>
					</div>
					<Row>
						<Col span={6}>
							<Text className="text-data-title">Liquidadas</Text>
							<Text className="text-data">{props.liquidadas}</Text>
						</Col>
						<Col span={6}>
							<Text className="text-data-title">Vencidas</Text>
							<Text className="text-data">{props.vencidas}</Text>
						</Col>
						<Col span={6}>
							<Text className="text-data-title">Por Vencer</Text>
							<Text className="text-data">{props.por_vencer}</Text>
						</Col>
						<Col span={6}>
							<Text className="text-data-title">Cotizaciones</Text>
							<Text className="text-data">{props.cotizaciones}</Text>
						</Col>
					</Row>
				</Card>
			</Link>
		</>
	);
}

/*
* @function CardFacturas
* @desrcition Card sencilla muestra un titulo y un dato
*
* @params background: color de fondo de la Card
* @params title: titulo principal
* @params number: numero o dato a deslpegar
*/
function CardFacturas(props) {

	const tags = {
		'1': <Tag className="badge-facturas" color="#226dff">Vigente</Tag>,
		'2': <Tag className="badge-facturas" color="#e1c634">Por Vencer</Tag>,
		'3': <Tag className="badge-facturas" color="#fa1e1e">Vencida</Tag>,
		'4': <Tag className="badge-facturas" color="#3e8c12">Liquidada</Tag>,
		'5': <Tag className="badge-facturas" color="purple">Factura</Tag>
	}

	return (
		<Card style={{ width: '100%' }} className="card-proveedores hover-effect" onClick={() => { if (props.onClick) props.onClick() }}>
			<div>
				{tags[props.info_estatus]}
			</div>
			<Row>
				<Col span={16}>
					<Text className="text-concepto">{props.concepto}</Text>
					<Text strong className="text-proveedor">{props.proveedor_id.alias}</Text>
				</Col>
				<Col span={8}>
					<Text className="text-monto">$ {props.monto.toMoney(2)} MXN</Text>
				</Col>
			</Row>
		</Card>
	);
}

/*
* @function CardTransaccionesSC
* @desrcition Card sencilla que muestra una transaccion sin Clasificar
*
* @params background: color de fondo de la Card
* @params title: titulo principal
* @params number: numero o dato a deslpegar
*/
function CardTransaccionesSC(props) {

	let tags = {
		'1': <Tag className="tag-proyecto" color="#226dff">Vigente</Tag>,
		'2': <Tag className="tag-proyecto" color="#e1c634">Por Vencer</Tag>,
		'3': <Tag className="tag-proyecto" color="#fa1e1e">Vencida</Tag>,
		'4': <Tag className="tag-proyecto" color="#3e8c12">Liquidada</Tag>,
		'5': <Tag className="tag-proyecto" color="purple">Factura</Tag>
	}

	return (
		<Card style={{ width: '100%' }} className="card-transacciones hover-effect" onClick={() => { if (props.onClick) props.onClick() }}>
			<Row>
				<Col span={14}>
					{tags[props.info_estatus]}
					<Text className="text-concepto">{props.concepto}</Text>
					<Text strong className="text-fecha">{moment(props.createdAt).format('DD-MM-YYYY')}</Text>
				</Col>
				<Col span={10} className="center">
					<Text className="text-monto">$ {props.monto?.toMoney()} MXN</Text>
				</Col>
			</Row>
		</Card>
	);
}


function CardProyecto({
	image = null,
	fecha = new Date(),
	color = null,
	nombre = "Proyecto Nombre",
	onClick = () => { } }) {
	return <Card className='card card-proyecto grid'>
		<Row gutter={[8, 8]} >
			<Col span={12}>
				<CustomAvatar size={50} image={image} name={nombre} color={color} />
			</Col>
			<Col span={12} className="flex-right-column">
				<small className="text-gray">Fecha de Inicio:</small>
				<Text className="text-fecha">{moment(fecha).format('DD-MM-YYYY')}</Text>
			</Col>
			<Col span={20} className="flex-left-column">
				<Text className="text-gray">Nombre</Text>
				<Text className="text-black-lg">{nombre}</Text>
			</Col>
			<Col span={4} className="center">
				<Button size='large' ghost onClick={onClick} className="pd-1" icon={<IconPDFColor style={{ margin: '0px 5px' }} />} />
			</Col>
		</Row>
	</Card>
}

function CardPrespuesto({
	presupuesto = 0.00,
	ejercido = 0.00,
	ventas = 0.00,
	ejercer = 0.00,
	fecha_ultima_transaccion = new Date(),
	}) {

	return <Card className="card">
		<Row gutter={[8, 8]}>
			<Col span={12} className="flex-left">
				<Text className="text-gray">Presupuesto</Text>
			</Col>
			<Col span={12} className="flex-left">
				<Text className="text-gray">Ejercido a {moment(fecha_ultima_transaccion).format('DD-MM-YYYY')}</Text>
			</Col>
			<Col span={12} className="flex-left-column">
				<Text className="text-black">$ {presupuesto.toMoney(true)} MXN</Text>
			</Col>
			<Col span={12} className="flex-left-column">
				<Text className="text-black">$ {(ejercido).toMoney(true)} MXN</Text>
			</Col>
			<Col span={12} className="flex-left">
				<Text className="text-gray">Por Ejercer</Text>
			</Col>
			<Col span={12} className="flex-left">
				<Text className="text-gray">Ventas</Text>
			</Col>
			<Col span={12} className="flex-left-column">
				<Text className="text-black">$ {(ejercer)?.toMoney(true)} MXN</Text>
			</Col>
			<Col span={12} className="flex-left-column">{/*ultima transaccion */}
				<Text className="text-black">$ {ventas?.toMoney(true)} MXN</Text>
			</Col>
		</Row>
	</Card>
}

function CardTransaccionesClasificacion({
	clasificadas = 30,
	no_clasificadas = 0,
	total = 0,
	proyecto_id = null
}) {
	return <Card className="card card-proyecto grid">
		<Row gutter={[8, 8]}>
			<Col span={24}>
				<Text className="text-gray">Transacciones Clasificadas</Text>
			</Col>
			<Col span={24}>
				<Progress percent={parseInt((clasificadas * 100) / total)} />
			</Col>
			<Col span={20} className="flex-left-column">
				<Text className="text-gray">Transacciones Sin Clasificar</Text>
				<Text className="text-black-lg">{parseInt(total) - parseInt(clasificadas)}</Text>
			</Col>
			<Col span={4} className="center">
				<Link to={`/admin/budgeting/${proyecto_id}/transacciones/sin-clasificar`}>
					<Button type='primary' className='btn btn-azul' style={{padding: '0px'}}><IconEye /></Button>
				</Link>
			</Col>

		</Row>
	</Card>
}

function CardConcepto({
	concepto = "",
	subconcepto = 0
}) {
	return <Card className="card">
		<Row gutter={[8, 8]}>
			<Col span={24} className="flex-left-column">
				<Text className="text-gray-lg">Concepto</Text>
				<Text className="text-black">{concepto}</Text>
			</Col>
			<Col span={24} className="flex-left-column">
				<Text className="text-gray-lg">SubConceptos</Text>
				<Text className="text-black">{subconcepto}</Text>
			</Col>
		</Row>
	</Card>
}

function CardPresupuestoSimple({
	presupuesto = 0,
	fecha = new Date(),
	ejercido = 0,
	porcentaje=0
}) {
	return <Card className="card">
		<Row gutter={[8, 8]}>
			<Col span={24} className="flex-left-column">
				<Text className="text-gray-lg">Presupuesto</Text>
				<Text className="text-black">$ {presupuesto.toMoney(true)} MXN</Text>
			</Col>
			<Col span={24} className="flex-left-column">
				<Text className="text-gray-lg">Ejercido a {moment(fecha).format('DD-MM-YYYY')}</Text>
				<Text className="text-black">$ {ejercido.toMoney(true)} MXN <Statistic
					value={porcentaje}
					precision={2}
					style={{ display: 'inline-block' }}
					valueStyle={{ color: '#cf1322', fontSize: 12 }}
					prefix={<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<ellipse opacity="0.1" cx="9.06667" cy="9" rx="9.06667" ry="9" fill="#FF547D" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M8.86818 6.1791C8.90554 6.0408 8.9967 5.92288 9.12163 5.85129C9.24655 5.77969 9.39501 5.76028 9.53435 5.79732L12.5068 6.58794C12.6461 6.62502 12.7649 6.71551 12.8371 6.83952C12.9092 6.96352 12.9287 7.11088 12.8914 7.2492L12.095 10.1998C12.0556 10.3359 11.9641 10.4513 11.8399 10.521C11.7158 10.5907 11.569 10.6093 11.4312 10.5726C11.2934 10.536 11.1757 10.4471 11.1033 10.3251C11.0309 10.2031 11.0097 10.0577 11.0442 9.92033L11.5008 8.22889L6.04239 11.3571C5.91744 11.4287 5.76895 11.4481 5.62959 11.4111C5.49023 11.374 5.37141 11.2835 5.29927 11.1595C5.22713 11.0354 5.20759 10.888 5.24493 10.7497C5.28227 10.6114 5.37344 10.4934 5.49839 10.4218L10.9568 7.29358L9.25279 6.84036C9.11346 6.80328 8.99468 6.71279 8.92255 6.58878C8.85043 6.46478 8.83087 6.31742 8.86818 6.1791Z" fill="#FF547D" />
					</svg>
					}
					suffix="%"
				/></Text>


			</Col>
		</Row>
	</Card>
}

/**
 * @const CardEmpresa

 * @description Card con los datos de razon social
 */
const CardEmpresa = ({ empresa = {}, transacciones = [], facturas = [], onClick = () => { }, className = "" }) => {


    // * 1 - Ingreso , 2 - Egreso ,3 - Traspaso ,4 - Nulo
    let trans_ingresos = transacciones.find(trans => trans._id == 1);
    let trans_egresos = transacciones.find(trans => trans._id == 2);



    //0 - Sin Cambios ,1 - Ingreso ,2 - Egreso
    let fact_ingresos = facturas.find(fact => fact._id == 1 || fact._id == null);
    let fact_egresos = facturas.find(fact => fact._id == 2);

    return <Card
        onClick={onClick}
        hoverable={true}
        className={`card-project card-shadow card-medium-small ${className}`}       
    >
		<Row className='mt-1'>
                <Col span={3} className="center">
                    <CustomAvatar
                        color={empresa.color}
                        size="large"
                        image={empresa.logo}
                        name={empresa.razon_social}
                        style={{minWidth: '40px' }}
                    />
                </Col>
                <Col span={11} className="flex-left" style={{ whiteSpace: 'normal' }}>
                    <Text ellipsis style={{paddingLeft: '4px'}}>{empresa.razon_social}</Text>
                </Col>
				<Col span={10} className="flex-right">
				<Link to={'/admin/contabilidad/facturas/'+empresa._id}>
					<Tag className='factura-tag' style={{marginRight: '0px'}}><Text className='texto'>Facturas Electrónicas</Text></Tag>
				</Link>
			</Col>

            </Row>

        <Row align="middle" justify="start" style={{ marginTop: "24px" }}>
            <Col span={24} className="m-1 flex-left">
                <Text ellipsis className="text-gray"> 
                    <span className='mt-1'>
                        Alta {moment(empresa.createdAt).format('L')}
                    </span>
                </Text>
            </Col>
        </Row>

        {/* Bancos */}
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Bancos</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_ingresos?.total) ? trans_ingresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_egresos?.total) ? trans_egresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>
        </Row>

        {/* Facturación */}
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Facturación</Col>
                </Row>

            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start"  >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((fact_ingresos?.total) ? fact_ingresos?.total : 0).toMoney(true)} MXN</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >

                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((fact_egresos?.total) ? fact_egresos?.total : 0).toMoney(true)} MXN</Col>
                </Row>

            </Col>
        </Row>

    </Card>
}

/**
 * @const CardConciliacion_1

 * @description Card con los datos de razon social
 */
const MontosFaltantes = ({ transacciones = [], facturas = [], onClick = () => { } }) => {

    // * 1 - Ingreso , 2 - Egreso ,3 - Traspaso ,4 - Nulo
    let trans_ingresos = transacciones.find(trans => trans._id == 1);
    let trans_egresos = transacciones.find(trans => trans._id == 2);


    //0 - Sin Cambios ,1 - Ingreso ,2 - Egreso
    let fact_ingresos = facturas.find(fact => fact._id == 1 || fact._id == null);
    let fact_egresos = facturas.find(fact => fact._id == 2);



    return <Card
        onClick={onClick}
        hoverable={true}
        className="card-project card-shadow card-medium-small"

        title={
            <Row>
                <Text strong className='pt-5px'>
                    Montos Faltantes
                </Text>
            </Row>
        }
    >

        <Row align="middle" justify="start" style={{ marginTop: "3.5rem" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Bancos</Col>
                </Row>
            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start"  >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_ingresos?.total) ? trans_ingresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >

                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_egresos?.total) ? trans_egresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>
        </Row>

        {/* Facturación */}
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Facturación</Col>
                </Row>

            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start"  >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(fact_ingresos?.total) ? fact_ingresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >

                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(fact_egresos?.total) ? fact_egresos?.total.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>
        </Row>

    </Card>

}

/**
 * @const CardEstatusContabilidad

 * @description Card con los datos de razon social
 */
const CardEstatusContabilidad = ({ sin_soporte = 0, incompleto = 0, completas = 0, onClick = () => { } }) => {
    return <Card
        onClick={onClick}
        hoverable={true}
        className="card-project card-shadow card-medium-small"

        title={
            <Row className='mb-1'>
                <Text strong className='pt-5px'>
                    Estatus
                </Text>
            </Row>
        }
    >
        <Row align="middle" justify="start" style={{ marginTop: "34px" }}>
            <Col span={24} >
                <Row gutter={[16,16]}>
                    <Col span={20} className="flex-left">
                        <IconError />
                        <Text className='ml-1'>
                            Sin Soporte
                        </Text>
                    </Col>
                    <Col span={4} >
                        <Text strong >{sin_soporte}</Text>
                    </Col>
                    <Col span={20} className="flex-left">
                        <IconWarning />
                        <Text className='ml-1'>
                            Incompleto
                        </Text>

                    </Col>
                    <Col span={4} >
                        <Text strong >{incompleto}</Text>
                    </Col>
                    <Col span={20} className="flex-left">
                        <IconSuccess />
                        <Text className='ml-1'>
                            Completo
                        </Text>

                    </Col>
                    <Col span={4} >
                        <Text strong >{completas}</Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}

export {
	CardInfoDashboard,
	CardProgress,
	CardActivity,
	CardInfo,
	CardProgressBar,
	CardProject,
	CardProveedor,
	CardFacturas,
	CardTransaccionesSC,
	CardProyecto,
	CardPrespuesto,
	CardTransaccionesClasificacion,
	CardConcepto,
	CardPresupuestoSimple,
	CardEmpresa,
	MontosFaltantes,
	CardEstatusContabilidad
}
