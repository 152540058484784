import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Popconfirm, Collapse, Typography, List, message, Pagination } from "antd";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

//componentes
import { IconDelete, IconEdit, IconPlus, } from './../../../../Widgets/Iconos';
import VerDetalle from '../../../../Widgets/VerDetalle';

//modales
import ModalAlmacenes from './../../Almacenes/ModalAlmacenes'
import ModalAlmacenProductos from './../../ProductosAlmacen/ModalProductoAlamcen'



const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;
const axios = require('axios');

var tabActiva = "";

/**
 * @export
 * @class AlmacenesList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class AlmacenesList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            almacenes: [],
            page: 1,
            limit: 10,
            total: 0,
        }
    }

    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getAlmacenes()
    }



    /**
    * @memberof Almacenes
    * @method getAlmacenes
    * @description  Obtiene los productos registrados del almacen
    **/
    getAlmacenes = (page = this.state.page) => {
        axios.get('/proyectos/almacenes/detalle', {
            params: {
                page: page,
                proyecto_id: this.props.proyecto_id
            }
        }).then(response => {
            console.log("response", response.data.data);
            this.setState({
                almacenes: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount
            })

        }).catch(error => {
            message.error('Error al obtener los alamcenes')
        })
    }


    render() {

        return (
            <>
                <Content>
                    <Row className="">
                        <Col span={24}>
                            <List
                                className="component-list lista-vmi"
                                dataSource={this.state.almacenes}
                                locale={{ emptyText: "Sin Almacenes" }}


                                renderItem={({ _id, direccion, status, productos = [], nombre }) => {

                                    return <Collapse
                                        bordered={false}
                                        defaultActiveKey={['1']}
                                        expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} /> :
                                            <PlusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} />}
                                        className="collapse-almacenes"
                                    >
                                        <Panel
                                            className="w100 collapse-list"
                                            extra={
                                                <Row className="w100 header-collapse ">

                                                    <Col span={5} className="flex-left">
                                                        <Text>{nombre}</Text>
                                                    </Col>

                                                    <Col span={11} className="flex-left">
                                                        <Text className="collapse-text-big">{direccion?.length > 40 ? direccion.slice(0, 40) + "..." : direccion}</Text>
                                                    </Col>

                                                    <Col span={4} className=" flex-column">
                                                        <Text>Productos</Text>
                                                        <Text>{productos.length}</Text>
                                                    </Col>

                                                    <Col span={4} className="center">
                                                        <Button
                                                            className="btn btn-edit"
                                                            title="Editar"
                                                            onClick={(e) => this.setState({
                                                                almacen_id: _id,
                                                                modal_visible_productos: true
                                                            })}>
                                                            <IconPlus height="15px" width="15px" />
                                                        </Button>
                                                        <Button className="btn btn-edit" title="Editar" onClick={(e) => this.setState({ almacen_id: _id, modal_visible: true })}>
                                                            <IconEdit />
                                                        </Button>
                                                    </Col>

                                                </Row>
                                            }
                                        >

                                            <Row className="p-1">
                                                <Col span={5}>
                                                    <Text ellipsis className="text-gray">Producto</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text ellipsis className="text-gray">Categoria</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text className="text-gray">Ubicación</Text>
                                                </Col>
                                                <Col span={6} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    <Text className="text-gray">Cantidad</Text>
                                                </Col>

                                            </Row>

                                            {
                                                productos.map((producto) => {

                                                    const { ubicacion, cantidad, producto_id, inventario_id, categoria_id } = producto;

                                                    return (
                                                        <Row className="mb-1 border-bottom">
                                                            <Col span={5} className="flex-left-column">
                                                                <Text>{producto_id?.nombre} </Text>
                                                                <small>{producto_id.sku_manantial}</small>
                                                            </Col>
                                                            <Col span={6} className="center">
                                                                <VerDetalle text={categoria_id.nombre} />
                                                            </Col>
                                                            <Col span={5} className="center">
                                                                <Text> {ubicacion}</Text>
                                                            </Col>
                                                            <Col span={6} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                                <Text> {cantidad}</Text>
                                                            </Col>
                                                            <Col span={2} className="center" style={{ paddingRight: '1.5rem' }}>
                                                                <Button
                                                                    className="btn btn-edit"
                                                                    title="Editar"
                                                                    onClick={(e) => this.setState({ almacen_producto_id: producto._id, almacen_id: _id, modal_visible_productos: true })}
                                                                >
                                                                    <IconEdit />
                                                                </Button>
                                                            </Col>

                                                        </Row>
                                                    )
                                                })
                                            }
                                        </Panel>
                                    </Collapse>

                                }}
                            />

                        </Col>

                        <Col span={24}>

                            <Pagination
                                current={this.state.page}
                                total={this.state.total}
                                pageSize={10}
                                showSizeChanger={false}
                                onChange={(page) => this.getAlmacenes(page)}

                            />
                        </Col>
                    </Row>

                    <Button className="btnAddPlus" title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })}>
                        <IconPlus />
                    </Button>
                    <ModalAlmacenes
                        visible={this.state.modal_visible}
                        onCancel={() => {
                            this.setState({ modal_visible: false, almacen_id: undefined })
                            this.getAlmacenes()
                            this.props.getProyecto()
                        }}
                        proyecto_id={this.props.proyecto_id}
                        almacen_id={this.state.almacen_id}
                    />
                    <ModalAlmacenProductos
                        visible={this.state.modal_visible_productos}
                        onCancel={() => {
                            this.setState({ modal_visible_productos: false, almacen_id: undefined, almacen_producto_id: undefined })
                            this.getAlmacenes()
                        }}
                        proyecto_id={this.props.proyecto_id}
                        almacen_id={this.state.almacen_id}
                        almacen_producto_id={this.state.almacen_producto_id}
                    />
                </Content>


            </>
        )
    }
}