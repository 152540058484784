import React, { Component } from 'react';
import { Result, Form, Col, Row, Spin, Typography, Progress, Select } from 'antd'
import _ImageMapper from 'react-image-mapper';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import Logged from "../../../Hooks/Logged";
import axios from 'axios';
import ModalCasaDetalle from "../Modales/Plano/ModalCasaDetalle"
import '../../../Styles/plano.css';

const { Option } = Select;


export default class Plano extends Component {
    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {

            _id: undefined,
            loading: false,
            modalVisible: false,


            view_name: 'Catalogo',
            loading: true,
            currentSlide: 0,
            slides: 0,

            cantVendidas: 0,
            cantApartadas: 0,
            cantDisponible: 0,

            totalVendidas: 0,
            totalApartadas: 0,
            totalDisponible: 0,

            totalTotal: 0,
            totalMontoCobrado: 0,
            totalMontoPendiente: 0,

            avanceMontoCobrado: 0,
            avanceMontoPendiente: 0,

            v_nombre: "",
            v_seccion: "",
            v_cliente: "",
            v_monto: "0",
            v_estatus: "",
            v_monto_deuda: 0,

            proyectos: [],

            idParqueEsmeralda: "",
            idDiamante: "",
            idProyectoSeleccionado: "",
            verPlano: false,

            planoTipo: "",

            dataCasasDiamante: {
                name: "",
                areas: [
                    { id: 1, name: "Lote 1 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [120, 770, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 3, name: "Lote 3 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [120, 695, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 5, name: "Lote 5 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [120, 625, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 7, name: "Lote 7 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [120, 555, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 9, name: "Lote 9 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [120, 485, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 11, name: "Lote 11 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [120, 415, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 13, name: "Lote 13 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [120, 345, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 15, name: "Lote 15 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [120, 270, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 17, name: "Lote 17 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [120, 200, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },

                    { id: 2, name: "Lote 2 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 715, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 4, name: "Lote 4 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 640, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 6, name: "Lote 6 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 570, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 8, name: "Lote 8 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 490, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 10, name: "Lote 10 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 415, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 12, name: "Lote 12 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 345, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 14, name: "Lote 14 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 275, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 16, name: "Lote 16 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 200, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 18, name: "Lote 18 ", section: "", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 110, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                ]
            },

            dataCasas: {
                name: "",
                areas: [
                    { id: 1, name: "Casa 1 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [579, 1187, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 2, name: "Casa 2 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [514, 1187, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 3, name: "Casa 3 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [448, 1187, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 4, name: "Casa 4 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [384, 1187, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 5, name: "Casa 5 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [318, 1187, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 6, name: "Casa 6 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [255, 1187, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 7, name: "Casa 7 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [192, 1187, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 8, name: "Casa 8 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [124, 1187, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 9, name: "Casa 9 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [61, 1187, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 10, name: "Casa 10 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [107, 912, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 11, name: "Casa 11 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [150, 863, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 12, name: "Casa 12 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [195, 817, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 13, name: "Casa 13 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [239, 771, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 14, name: "Casa 14 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [283, 722, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 15, name: "Casa 15 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [327, 672, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 16, name: "Casa 16 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 624, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 17, name: "Casa 17 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [412, 575, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 18, name: "Casa 18 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [457, 528, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 19, name: "Casa 19 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [501, 482, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 20, name: "Casa 20 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [543, 433, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 21, name: "Casa 21 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [587, 384, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 22, name: "Casa 22 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [631, 334, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 23, name: "Casa 23 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [673, 288, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 24, name: "Casa 24 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [445, 926, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 25, name: "Casa 25 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [489, 880, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 26, name: "Casa 26 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [534, 832, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 27, name: "Casa 27 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [576, 785, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 28, name: "Casa 28 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [621, 734, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 29, name: "Casa 29 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [664, 686, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },



                    { id: 31, name: "Casa 1 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [910, 1186, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 32, name: "Casa 2 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [975, 1186, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 33, name: "Casa 3 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1039, 1186, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 34, name: "Casa 4 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1104, 1186, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 35, name: "Casa 5 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1169, 1186, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 36, name: "Casa 6 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1234, 1186, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 37, name: "Casa 7 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1298, 1188, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 38, name: "Casa 8 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1228, 983, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 39, name: "Casa 9 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1216, 917, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 40, name: "Casa 10 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1206, 852, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 41, name: "Casa 11 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1196, 788, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 42, name: "Casa 12 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1184, 727, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 43, name: "Casa 13 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1174, 663, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 44, name: "Casa 14 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1163, 598, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 45, name: "Casa 15 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1149, 537, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 46, name: "Casa 16 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1138, 473, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 47, name: "Casa 17 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1110, 351, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 48, name: "Casa 18 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1060, 309, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 49, name: "Casa 19 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1013, 264, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 50, name: "Casa 20 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [966, 222, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 51, name: "Casa 21 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [916, 178, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 52, name: "Casa 22 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [866, 136, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 53, name: "Casa 23 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [963, 960, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 54, name: "Casa 24 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [950, 895, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 55, name: "Casa 25 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [939, 834, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 56, name: "Casa 26 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [927, 762, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 57, name: "Casa 27 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [914, 696, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 58, name: "Casa 28 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [904, 634, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                    { id: 59, name: "Casa 29 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [893, 568, 5], preFillColor: "#0075FF", fillColor: "#0075FF", monto_pagado: "0.00" },
                ]
            }
        }
    }

    SelectReference = React.createRef();

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.state.loading = true;

        this.getProyectos();


        //-this.setDataCasas();

        setTimeout(() => {
            this.setState({ loading: false });
        }, 250);
    }


    /**
     * @memberof Plano
     * @description Obtiene la informacion de las casas que tienen cliente en CRM y actualiza los valores  del state para actualizar los que tienen clientes
     */
    setDataCasas = async () => {
        var dataCasas = (this.state.planoTipo === "esmeralda") ? this.state.dataCasas.areas : this.state.dataCasasDiamante.areas;
        var projecto_id = this.state.idProyectoSeleccionado;

        axios.get('/ventas/get', {
            params: {
                projecto_id: projecto_id
            }
        })
            .then(success => {
                console.log('inmueble plano', success)

                //Contadores
                let acumulador_vendidas = 0;
                let acumulador_apartadas = 0;
                //Montos
                let monto_vendidas = 0;
                let monto_apartadas = 0;

                let monto_total = 0

                //Se iteran las consultas
                success.data.data.map((inmueble, index) => {


                    //tipo esmeralda
                    if (this.state.planoTipo === "esmeralda") {
                        if (inmueble.cliente) {

                            //Tenemos cliente, no es nulo
                            //Se busca el indice en los valores predeterminados para actualizar el state.
                            const casa_seccion = (element) => element.name.trim() === inmueble.inmueble.trim() && element.section.trim() === inmueble.seccion.trim()

                            let indice = dataCasas.findIndex(casa_seccion)
                            //console.log('Indice predeterminado', indice, index)
                            //Se busca el valor predeterminado para actualizarlo con el indice
                            if (indice !== -1) {


                                dataCasas[indice].cliente = inmueble.cliente;
                                dataCasas[indice].estatus = inmueble.estatus;
                                dataCasas[indice].monto = inmueble.cliente.monto_venta;
                                dataCasas[indice].monto_pagado = inmueble.cliente.monto_pagado;
                                /**  
                                  * Vendida    #00FF19
                                  * Apartada   #EB00FF
                                  * Cancelada  #FF0000
                                */
                                 console.log('inmueble estatus', dataCasas[indice].estatus)

                                if (inmueble.estatus == "Vendida") {

                                    dataCasas[indice].fillColor = "#00FF19";
                                    dataCasas[indice].preFillColor = "#00FF19";
                                }
                                if (inmueble.estatus == "Apartada") {
                                    dataCasas[indice].fillColor = "#EB00FF";
                                    dataCasas[indice].preFillColor = "#EB00FF";
                                }
                                if (inmueble.estatus == "Cancelada") {

                                    dataCasas[indice].fillColor = "#FF0000";
                                    dataCasas[indice].preFillColor = "#FF0000";
                                }
                                //Contadores y montos
                                if (inmueble.estatus === 'Apartada') {
                                    acumulador_apartadas++
                                    monto_apartadas += parseFloat(inmueble.cliente.monto_pagado)
                                } else {
                                    acumulador_vendidas++;
                                    monto_vendidas += parseFloat(inmueble.cliente.monto_pagado)
                                }

                                monto_total += parseFloat(inmueble.cliente.monto_venta)
                            }

                        }
                    }
                    //tipo diamante
                    else {

                        if (inmueble.cliente) {
                            //Tenemos cliente, no es nulo
                            //Se busca el indice en los valores predeterminados para actualizar el state.

                            var nombreLote = inmueble.inmueble.trim();
                            for (let index = 0; index < dataCasas.length; index++) {
                                var lote = dataCasas[index];
                                var lote_nombre = lote.name.trim();

                                if (nombreLote === lote_nombre) {


                                    dataCasas[index].cliente = inmueble.cliente;
                                    dataCasas[index].estatus = inmueble.estatus;
                                    dataCasas[index].monto = inmueble.cliente.monto_venta;
                                    dataCasas[index].monto_pagado = inmueble.cliente.monto_pagado;
                                    /**  
                                      * Vendida    #00FF19
                                      * Apartada   #EB00FF
                                    */
                                    dataCasas[index].fillColor = (inmueble.estatus === 'Apartada') ? "#EB00FF" : "#00FF19"
                                    dataCasas[index].preFillColor = (inmueble.estatus === 'Apartada') ? "#EB00FF" : "#00FF19"

                                    //Contadores y montos
                                    if (inmueble.estatus === 'Apartada') {
                                        acumulador_apartadas++
                                        monto_apartadas += parseFloat(inmueble.cliente.monto_pagado)
                                    } else {
                                        acumulador_vendidas++;
                                        monto_vendidas += parseFloat(inmueble.cliente.monto_pagado)
                                    }
                                    monto_total += parseFloat(inmueble.cliente.monto_venta)

                                    break;
                                }
                            }
                        }
                    }
                })

                this.setState(state => {
                    state.cantVendidas = acumulador_vendidas;
                    state.cantApartadas = acumulador_apartadas;
                    state.cantDisponible = (this.state.planoTipo === "esmeralda") ? state.dataCasas.areas.length - (acumulador_vendidas + acumulador_apartadas) : state.dataCasasDiamante.areas.length - (acumulador_vendidas + acumulador_apartadas);

                    state.totalVendidas = parseFloat(monto_vendidas);
                    state.totalApartadas = parseFloat(monto_apartadas);

                    state.totalMontoCobrado = parseFloat(monto_vendidas + monto_apartadas)
                    state.totalMontoPendiente = parseFloat(monto_total - (monto_vendidas + monto_apartadas))

                    state.avanceMontoCobrado = (parseFloat(state.totalMontoCobrado / monto_total) * 100).toFixed(2)
                    state.avanceMontoPendiente = (parseFloat(state.totalMontoPendiente / monto_total) * 100).toFixed(2)

                    return state;
                })
            })
            .catch(error => {
                console.log('error', error)
                this.state.loading = false;
            })
    }

    /**
     * @memberof Plano
     * @function getProyectos
     *
     * @description Obtener proyectos.
     */
    getProyectos = async () => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
            }
        }).then((proyectos) => {

            var arrayProyectos = proyectos.data.data, idParqueEsmeralda = "", idDiamante = "";

            this.setState({ proyectos: arrayProyectos })
            for (let index = 0; index < arrayProyectos.length; index++) {
                const proyecto = arrayProyectos[index];
                if (proyecto.nombre === "Parque Esmeralda") {
                    idParqueEsmeralda = proyecto._id;
                }

                if (proyecto.nombre === "Residencial Diamante") {
                    idDiamante = proyecto._id;
                }

                if (this.state.idParqueEsmeralda !== "" && this.state.idDiamante !== "") {
                    break;
                }
            }


            this.setState({
                idParqueEsmeralda: idParqueEsmeralda,
                idDiamante: idDiamante
            });

            // if (this.state.idParqueEsmeralda) {
            //     setTimeout(() => {
            //         if (this.SelectReference.current !== null) {
            //             this.SelectReference.current.setFieldsValue({
            //                 proyecto: idDiamante
            //             });

            //             this.setState({ idProyectoSeleccionado: idDiamante, verPlano: true });

            //         }
            //     }, 250);
            // }

        }).catch((error) => {
        })
    }



    /**
     * @memberof Plano
     * @function clicked
     *
     * @description Obtener referencia de area clickeada, abrir modal detalle.
     */

    clicked(area) {
        this.modalDetalleCasa(area);
        this.setState({
            msg: `You clicked on ${area.shape} at coords ${JSON.stringify(
                area.coords
            )} !`
        });

    }



    /**
     * @memberof Plano
     * @function clickedOutside
     *
     * @description Obtener coordenadas al dar click en la imagen.
     */
    clickedOutside(evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        this.setState({
            msg: `You clicked on the image at coords ${JSON.stringify(coords)} !`
        });
    }


    /**
     * @memberof Plano
     * @function moveOnImage
     *
     * @description Obtener coordenadas al mover el mouse sobre la imagen.
     */
    moveOnImage(evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        this.setState({
            moveMsg: `You moved on the image at coords ${JSON.stringify(coords)} !`
        });
    }



    /**
     * @memberof Plano
     * @function enterArea
     *
     * @description Obtener coordenadas al dar enter sobre area en  la imagen.
     */
    enterArea(area) {
        this.setState({
            hoveredArea: area,
            msg: `You entered ${area.shape} ${area.name} at coords ${JSON.stringify(
                area.coords
            )} !`
        });
    }


    /**
     * @memberof Plano
     * @function leaveArea
     *
     * @description Obtener coordenadas al dejar un area de la imagen.
     */
    leaveArea(area) {
        this.setState({
            hoveredArea: null,
            msg: `You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(
                area.coords
            )} !`
        });
    }

    /**
    * @memberof Plano
    * @function moveOnArea
    *
    * @description Obtener coordenadas al mover el mouse sobre un area.
    */
    moveOnArea(area, evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        this.setState({
            moveMsg: `You moved on ${area.shape} ${area.name
                } at coords ${JSON.stringify(coords)} !`
        });
    }


    /**
     * @memberof Plano
     * @function getTipPosition
     *
     * @description Obtener coordenadas para desplegar el tooltip por casa / lote
     */
    getTipPosition(area) {
        let top = 0; let left = 0;

        if (this.state.planoTipo === "esmeralda") {

            left = area.center[0] + 90;
            if (area.estatus !== "Disponible") {
                top = area.center[1] + 425;
            }
            else {
                top = area.center[1] + 390;
            }

        }
        else {
            left = area.center[0] + 30;

            if (area.estatus !== "Disponible") {
                top = area.center[1] + 420;
            }
            else {
                top = area.center[1] + 380;
                //left = area.center[0] + 355;
            }
        }
        return { top: `${top}px`, left: `${left}px` };
    }



    /**
    * @memberof Plano
    * @function modalDetalleCasa
    *
    * @description Abrir modal de detalle de casa / lote seleccionado
    */
    modalDetalleCasa = (record) => {
        this.setState({
            modalVisible: true,
            v_nombre: record.name,
            v_seccion: record.section,
            v_cliente: (record.cliente) ? record.cliente.nombre + ' ' + record.cliente.apellido : 'Sin cliente',
            v_monto: (record.monto).toMoney(),
            v_estatus: record.estatus,
            v_monto_deuda: record.cliente ? record.cliente.monto_deuda : 0.00
        })
    }


    /**
     * @memberof Plano
     * @function hideModal
     *
     * @description Cerrar modal de detalle de casa / lote seleccionado
     */
    hideModal = () => {
        this.setState({
            modalVisible: false,
            v_nombre: "",
            v_seccion: "",
            v_cliente: "",
            v_estatus: "",
            v_monto: "0.00",
        })
    }



    /**
     * @memberof Plano
     * @function onProyectoChange
     *
     * @description Al cambiar de proyecto en el select, se cambia el valor en state idProyectoSeleccionado, 
     * si el proyecto seleccionado es diamante o esmeralda se ejecuta la consulta de valores y clientes para llenar info del plano según sea el caso
     */
    onProyectoChange = async (e) => {
        this.setState({ loading: true, idProyectoSeleccionado: e, verPlano: !(e !== this.state.idDiamante && e !== this.state.idParqueEsmeralda) });
        var tipo = "";
        if (e === this.state.idDiamante) { tipo = "diamante" }
        if (e === this.state.idParqueEsmeralda) { tipo = "esmeralda" }


        setTimeout(() => {
            this.setState({ loading: false, planoTipo: tipo });
            this.setDataCasas();
        }, 1000);
    }


    render() {

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={12} xl={15}>
                            <h2 style={{ width: 'auto', float: 'left' }}>Ventas</h2>
                        </Col>
                        <Col xs={24} xl={8} className="">

                            <Form layout="vertical" className="frm-select" name="formulario-select" ref={this.SelectReference} >
                                <Form.Item label="" name="proyecto">
                                    <Select
                                        placeholder="Seleccione proyecto"
                                        style={{ width: "100%", maxWidth: "400px", float: "left", top: "10px", marginBottom: "15px" }}
                                        onChange={this.onProyectoChange}

                                    >
                                        {this.state.proyectos.map(function (proyecto, index) {
                                            return <Option value={proyecto._id} >
                                                <CustomAvatar
                                                    image={proyecto.logo}
                                                    name={proyecto.nombre}
                                                    color={proyecto.color}
                                                    size="small"
                                                    style={{

                                                        margin: '0 5px'
                                                    }}

                                                />{proyecto.nombre}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                    {(this.state.verPlano) ?
                        <Row style={{ width: "100%" }}>
                            <Row className="" style={{ width: "100%", padding: "1rem", paddingBottom: "0px" }}>

                                <Col xs={24} sm={24} md={24} xl={12} xxl={10} >
                                    <div className="div_contenido cardPlano divEstadisticas" >

                                        <Row className="cardEstadisticasGenerales">

                                            <div className="div-titulo">
                                                <h2 className="titulo-divCont TituloCardPlano" style={{ fontSize: "20pt" }}>Estadisticas Generales</h2>
                                            </div>

                                            <Col className="div-vendidas">
                                                <div className="divInfo cardInfo" >
                                                    <span className="spnVendidas"></span>
                                                    <h1>Casas Vendidas</h1>
                                                    <h2>{this.state.cantVendidas}</h2>
                                                    <h4> ${((this.state.totalVendidas === undefined) ? 0 : this.state.totalVendidas).toMoney()}</h4>
                                                </div>
                                            </Col>

                                            <Col className="div-apartadas">
                                                <div className="divInfo cardInfo" >
                                                    <span className="spnApartadas"></span>
                                                    <h1>Casas Apartadas</h1>
                                                    <h2>{this.state.cantApartadas}</h2>
                                                    <h4>${((this.state.totalApartadas === undefined) ? 0 : this.state.totalApartadas).toMoney()}</h4>
                                                </div>
                                            </Col>

                                            <Col className="div-disponibles">
                                                <div className="divInfo cardInfo" >
                                                    <span className="spnDisponibles"></span>
                                                    <h1>Casas Disponibles</h1>
                                                    <h2>{(this.state.cantDisponible === undefined) ? 0 : this.state.cantDisponible}</h2>
                                                    <h4></h4>
                                                </div>
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>

                                <Col xs={24} sm={24} md={24} xl={12} xxl={14}  >
                                    <div className="div_contenido cardPlano ">
                                        <Row className="cardVentasGenerales">
                                            <div className="div-titulo">
                                                <h2 className="titulo-divCont TituloCardPlano" >Ventas Generales</h2>
                                            </div>

                                            <div className="div-ventasGenerales ">
                                                <Row className=" heigth-100">
                                                    <Col span={7} className=" center">
                                                        <Progress type="circle" width="70px" strokeColor="yellow" percent={(this.state.avanceMontoCobrado)} size="small" />
                                                    </Col>
                                                    <Col span={14} className="flex-left-column ">
                                                        <h1>Monto Cobrado</h1>
                                                        <h4> ${((this.state.totalMontoCobrado === undefined) ? 0 : this.state.totalMontoCobrado).toMoney()}</h4>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="div-ventasGenerales">
                                                <Row className=" heigth-100" >
                                                    <Col span={7} className="center">
                                                        <Progress type="circle" width="70px" strokeColor="#00FF19" percent={(this.state.avanceMontoPendiente)} size="small" />
                                                    </Col>
                                                    <Col span={14} className="flex-left-column">
                                                        <h1>Monto por Cobrar</h1>
                                                        <h4> ${((this.state.totalMontoPendiente === undefined) ? 0 : this.state.totalMontoPendiente).toMoney()}</h4>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Row>

                                    </div>
                                </Col>

                            </Row>


                            <Row className="" style={{ width: "100%", display: "inline-block", padding: "1rem", paddingTop: "5px" }}>
                                <div className="div_contenido"
                                    style={{
                                        background: "transparent",
                                        display: "block",
                                        overflow: "auto",
                                        position: "static",
                                        padding: "5px",
                                    }}>

                                    <div className="grid">
                                        <div className="presenter">
                                            <Row className="" id="rowImageMapper">

                                                {this.state.idProyectoSeleccionado === this.state.idParqueEsmeralda ?
                                                    <_ImageMapper
                                                        map={this.state.dataCasas}
                                                        src={`/images/plano.png`}
                                                        width={1400}

                                                        onClick={area => this.clicked(area)}
                                                        onMouseEnter={area => this.enterArea(area)}
                                                        onMouseLeave={area => this.leaveArea(area)}
                                                        onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
                                                        onImageClick={evt => this.clickedOutside(evt)}
                                                        onImageMouseMove={evt => this.moveOnImage(evt)}

                                                        lineWidth={4}
                                                        strokeColor={"white"}
                                                    />
                                                    :

                                                    <_ImageMapper
                                                        map={this.state.dataCasasDiamante}
                                                        src={`/images/planoDiamante.png`}
                                                        width={650}

                                                        onClick={area => this.clicked(area)}
                                                        onMouseEnter={area => this.enterArea(area)}
                                                        onMouseLeave={area => this.leaveArea(area)}
                                                        onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
                                                        onImageClick={evt => this.clickedOutside(evt)}
                                                        onImageMouseMove={evt => this.moveOnImage(evt)}

                                                        lineWidth={4}
                                                        strokeColor={"white"}
                                                    />
                                                }


                                                {this.state.hoveredArea && (
                                                    <span className="tooltip" style={{ ...this.getTipPosition(this.state.hoveredArea) }} >
                                                        <label style={{ display: "block" }}>{this.state.hoveredArea.name}</label>
                                                        <label style={{ display: "block" }}>{this.state.hoveredArea.section}  </label>
                                                        <label style={{ display: "block" }}>{'Estatus : ' + this.state.hoveredArea.estatus}  </label>

                                                        {(this.state.hoveredArea.estatus !== "Disponible") ?
                                                            <div style={{ width: '250px' }}>
                                                                <label style={{ display: "block" }}>{'Cliente : ' + (this.state.hoveredArea.cliente) ? this.state.hoveredArea.cliente.nombre + ' ' + this.state.hoveredArea.cliente.apellido : 'Sin cliente'}  </label>
                                                                <label style={{ display: "block" }}>{'Monto : $ ' + ((this.state.hoveredArea.monto === undefined) ? 0 : this.state.hoveredArea.monto).toMoney()}  </label>
                                                                <label style={{ display: "block" }}>{'Monto por Pagar : $ ' + (this.state.hoveredArea.cliente.monto_deuda).toMoney()}  </label>
                                                            </div>
                                                            : ""}
                                                    </span>
                                                )}


                                            </Row>
                                        </div>
                                    </div>

                                    <Row className="p-1">

                                        {this.state.idProyectoSeleccionado === this.state.idParqueEsmeralda ?
                                            <img src={'/images/PE.png'} width="200" />
                                            :
                                            <img src={'/images/diamante.png'} width="180" />
                                        }
                                    </Row>
                                </div>
                            </Row>
                            <ModalCasaDetalle
                                planoTipo={this.state.planoTipo}
                                modalVisible={this.state.modalVisible}
                                closeMethod={this.hideModal}
                                nombre={this.state.v_nombre}
                                seccion={this.state.v_seccion}
                                cliente={this.state.v_cliente}
                                monto={this.state.v_monto}
                                estatus={this.state.v_estatus}
                                monto_deuda={this.state.v_monto_deuda}
                            >
                            </ModalCasaDetalle>

                        </Row>
                        :
                        (this.state.idProyectoSeleccionado === "") ?
                            <Result className="txtAviso" title="Seleccione un proyecto para visualizar las propiedades" /> :
                            <Result className="txtAviso" title="El proyecto seleccionado no tiene un mapa disponible para mostrar" />
                    }
                </Spin>
            </div>
        )
    }
}

