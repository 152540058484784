import React, { Component } from 'react';
import { List, Button, Col, Row, Modal, Typography, InputNumber, Spin, message, Breadcrumb, Select, } from 'antd'
import { Link } from 'react-router-dom'
import { IconArrowBack, IconoMaquinaRegistradoraParaElGuardadoNominazFinanzas as IconNominas, IconCheck } from '../../../Widgets/Iconos';
import axios from 'axios';
import Avatar from '../../../Widgets/Avatar/Avatar'

import '../../../../Styles/nominas.css'

const { Title, } = Typography;
const { Option } = Select;
/**
 * 
 * @class ListaEmpleados
 * @description Lista de empleados del inmueble a agregar a la nómina.
*/
export default class ListaEmpleados extends Component {

    /**
     *
     * @constructor Inicia el componente junto a sus propidades
     *
     * @var nombre Nombre del Grupo
     * @var inmueble Objeto inmueble para poner enla descripción.
     * @var empleados Lista de empleados .
     * @var empleados.data Lista de usuarios del inmueble a agregar.
     * @var empleados.page Pagina de la lista de empleados
     * @var empleados.limit Limite de elementos por pagina
     * @var empleados.total Total de tajos agregados
     * @var empleados.search Empleados a busacar
     * @var modalTajosVisible Modal de tajo a buscar
     * @var empleado_id ID del empleado a enviar al modal
     */
    constructor(props) {
        super(props);

        this.state = {
            inmueble: {
                inmueble_id: {},
                responsable_id: {
                    avatar: '',
                    nombre: '',
                    apellido: ''
                }
            },
            empleado_id: null,

            empleados: [],
            proyectos: [],
            cuentas: [],
            subrubros: []
        };
    }

    /**
     * @methodOf ListaEmpleados
     * @function componentDidMount
     *
     * @description Obtenemos los empleados y la cantida d de tajos de la nómina.
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false)

        this.getEmpleados()
        this.loadCuentas()
        this.loadProyectos()
        this.loadSubRubros()
    }

    getEmpleados = (page = this.state.empleados.page, limit = this.state.empleados.limit, total = this.state.empleados.total, search = this.state.empleados.search) => {
        this.setState({ loading: true })
        axios.post('/nominas/final/empleados', {
            nomina_id: this.props.match.params.nomina_id,
            page,
            limit
        })
            .then(({ data }) => {

                console.log('data', data);

                // const { empleados } = data
                this.setState({
                    inmueble: null,
                    empleados: data.data.map(empleado => {

                        console.log('empleado', empleado);
                        console.log('empleado.monto_pagado', empleado.empleado.monto_pagado);
                        console.log('empleado.monto_autorizado', empleado.empleado.monto_autorizado);
                        
                        empleado.loading = false
                        
                        
                        if (empleado.empleado.registrado_finanzas !== true) {
                            
                            if (empleado.empleado.monto_autorizado === 0)
                                empleado.empleado.monto_autorizado = empleado.empleado.salario

                            empleado.empleado.monto_pagado = empleado.empleado.monto_autorizado              
                            
                            if (empleado.ultimo_pago_nominal !== null) {

                                console.log(empleado);
                                empleado.empleado.cuenta_id = empleado.ultimo_pago_nominal.cuenta_id
                                empleado.empleado.subrubro_id = empleado.ultimo_pago_nominal.subrubro_id
                                empleado.empleado.proyecto_id = empleado.ultimo_pago_nominal.proyecto_id
                            }

                            return empleado;
                        }





                        return empleado;

                    }),
                    loading: false
                })

            })
    }

    actualizarSalario = (index, e) => {
        console.log(' e', e);
        console.log(' index', index);
        let empleados = this.state.empleados;
        if (this.props.finanzas === true)
            empleados[index].empleado.monto_pagado = e
        else
            empleados[index].empleado.monto_autorizado = e

        this.setState({
            empleados: [...empleados]
        })
    }

    /**
     * 
     */
    save = () => {
        this.setState({ loading: true })
        axios.post('/nominas/final/empleados/aceptar', {
            nomina_id: this.props.match.params.nomina_id,
            empleados: this.state.empleados
        })
            .then(() => {
                message.success("Salarios Guardados Correctamente")
            })
            .catch(() => {
                message.error("Ocurrió un error al guardar los salarios")
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    }

    /**
 * @memberof ListaTajos
 * @method loadCuentas
 * @description Trae la lista de cuentas
 */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                pagination: false
            }
        })
            .then(res => {
                this.setState({ cuentas: res.data.data });
            })
            .catch(error => {
                console.log('lista cuentas error', error);
            })
    }


    loadProyectos = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({
                proyectos: proyectos.data.data
            })
        }).catch((error) => {

        })
    }

    loadSubRubros = () => {
        axios.get('/subrubros/list', {
            params: {
                paginate: false,
                salarios: true
            }
        }).then((proyectos) => {
            console.log(proyectos)

            this.setState({
                subrubros: proyectos.data.data
            })
        }).catch((error) => {

        })
    }

    /**
     * 
     * @param {*} index 
     */
    payEmpleado = (index) => {
        let empleado = this.state.empleados[index]
        if ((empleado.empleado.proyecto_id === undefined || empleado.empleado.proyecto_id === null) && (empleado.empleado.cuenta_id === undefined || empleado.empleado.cuenta_id === null))
            return Modal.error({
                title: "Debe indicar la cuenta y el proyecto para generar la transacción.",
            })

        if (empleado.empleado.cuenta_id === undefined || empleado.empleado.cuenta_id === null)
            return Modal.error({
                title: "Debe indicar la cuenta a la cual se le realizará el pago",
            })


        if (empleado.empleado.proyecto_id === undefined || empleado.empleado.proyecto_id === null)
            return Modal.error({
                title: "Debe indicar el proyecto ",
            })



        this.setState(state => {
            state.empleados[index].loading = true;
            return state;
        })

        // nomina_id, usuario_id, proyecto_id, cuenta_id, monto_pagado 
        axios.post('/nominas/finanzas/empleados/', {
            nomina_id: this.props.match.params.nomina_id,
            usuario_id: empleado.empleado.usuario_id,

            proyecto_id: empleado.empleado.proyecto_id,
            cuenta_id: empleado.empleado.cuenta_id,
            subrubro_id: empleado.empleado.subrubro_id,

            monto_pagado: empleado.empleado.monto_pagado,
        })
            .then(e => {

                console.log('e', e);
                this.setState(state => {
                    state.empleados[index] = e.data.data;
                    state.empleados[index].loading = false;
                    return state;
                })


            })
            .catch(e => {
                message.error("No es posible crear la transacción, consulta la información ingresada.")
            })
            .finally(e => {
                this.setState(state => {
                    state.empleados[index].loading = false;
                    return state;
                })
            })
    }

    actualizarCuenta = (index, value) => {
        console.log('index', index);
        console.log('value', value);
        this.setState(state => {
            state.empleados[index].empleado.cuenta_id = value;
            return state;
        })
    }

    actualizarProyectos = (index, value) => {
        this.setState(state => {
            state.empleados[index].empleado.proyecto_id = value;
            return state;
        })
    }

    actualizarSubrubros = (index, value) => {
        this.setState(state => {
            state.empleados[index].empleado.subrubro_id = value;
            return state;
        })
    }

    render() {

        const { empleados, } = this.state;

        let title = "";
        switch (this.props.etapa) {
            case 2:
                title = "Revisión Final"
                break;
            case 3:
                title = "Nóminas a Finanzas"
                break;
        }
        return (
            <div>
                <Spin spinning={this.state.loading}>

                    <Row className="row-encabezado">
                        <Col xs={24} xl={8} xxl={12} className="flex-left ">
                            <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline', paddingTop: '0px' }}><IconArrowBack /></Button>
                            <Breadcrumb separator={<span style={{ color: 'white' }}>></span>}>
                                <Breadcrumb.Item style={{ color: 'white', fontSize: '1.5em' }}>
                                    {title}
                                </Breadcrumb.Item>
                                {(this.props.match.params.inmueble_id || this.props.match.params.empleado_id) ? <Breadcrumb.Item style={{ color: 'white', fontSize: '1.5em' }}>
                                    Sublista
                                </Breadcrumb.Item> : null}
                            </Breadcrumb>
                        </Col>
                        <Col xs={24} xl={8} xxl={12} className="flex-left ">
                            {(this.props.redireccion !== "tajos") ? <div>
                                <Button disabled={this.props.tipo === "salarial"}>
                                    {/* this.props.match.params.nomina_id */}
                                    <Link to={`/admin/nomina/${(this.props.finanzas === true) ? "revision-finanzas" : "revision-final"}/${this.props.match.params.nomina_id}/salariales/`}>Ver por Salario</Link>
                                </Button>
                                <Button disabled={this.props.tipo === "destajo"}>
                                    {/* this.props.match.params.nomina_id */}
                                    <Link to={`/admin/nomina/${(this.props.finanzas === true) ? "revision-finanzas" : "revision-final"}/${this.props.match.params.nomina_id}`}>Ver por destajo</Link>
                                </Button>
                            </div> : null}
                        </Col>
                    </Row>
                    <Row style={{ padding: '24px' }} className="content">
                        <Row className="list-container" gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>Empleados</Title>
                            </Col>
                            <Col span={24} style={{ paddingTop: 0 }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={empleados}
                                    className="list-employees"
                                    renderItem={({ empleado, loading, registrado_finanzas, _id }, index) => (
                                        <Spin spinning={loading} style={{ width: '100%' }}>
                                            <List.Item className="list-employees-item-content">
                                                {(empleado.registrado_finanzas === true) ? <span style={{ position: "absolute", left: -11, top: 20, zIndex: 1, width: 30, height: 30, borderRadius: '100%', background: "#40F031" }}><IconCheck style={{ position: 'relative', top: 3, left: 5 }} color="#f7f7f7" /></span> : null}
                                                <Row className="list-employee-item" style={{ width: '100%' }}>
                                                    <Col span={4}>
                                                        <Avatar className="information-content-avatar"
                                                            image={empleado.usuario_id.avatar}
                                                            name={[
                                                                empleado.usuario_id.nombre,
                                                                empleado.usuario_id.apellido
                                                            ]}
                                                            style={{ position: 'relative', left: -10 }}
                                                        />
                                                        {empleado.usuario_id.nombre + ' ' + empleado.usuario_id.apellido}
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <InputNumber
                                                            precision={2}
                                                            className="input-ghost"
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            style={{ width: 'calc(100% - 20px)', margin: '0px auto' }}
                                                            value={(this.props.finanzas === true) ? empleado.monto_autorizado : empleado.salario}
                                                            disabled={true}
                                                        />
                                                    </Col>
                                                    <Col span={4} className="center">

                                                        {/* {console.log({

                                                            finanzas: this.props.finanzas,
                                                            'empleado.monto_pagado': empleado.monto_pagado,
                                                            'empleado.monto_autorizado': empleado.monto_autorizado,
                                                            'empleado.salario': empleado.salario,
                                                            _id

                                                        })} */}
                                                        <InputNumber
                                                            className="input-ghost"
                                                            style={{ width: 'calc(100% - 20px)', margin: '0px auto' }}
                                                            precision={2}
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            
                                                            value={((this.props.finanzas === true) ? empleado.monto_pagado : (
                                                                (empleado.monto_autorizado === 0)? empleado.salario : empleado.monto_autorizado
                                                            ))}
                                                            
                                                            
                                                            onChange={(value => this.actualizarSalario(index, value))}
                                                            max={((this.props.finanzas === true) ? empleado.monto_pagado : empleado.monto_autorizado)}
                                                            disabled={empleado.registrado_finanzas}
                                                            
                                                            max={((this.props.finanzas === true) ? empleado.monto_autorizado : empleado.salario)}
                                                        />
                                                   
                                                    </Col>
                                                    {(this.props.finanzas === true) ? <Col span={3} className="center">
                                                        <Select
                                                            style={{ maxWidth: '200px', width: 'calc(100% - 5px)', margin: '0px auto' }}
                                                            placeholder="Proyectos"
                                                            value={empleado.proyecto_id}
                                                            disabled={empleado.registrado_finanzas}
                                                            onChange={(value) => this.actualizarProyectos(index, value)}>
                                                            {
                                                                this.state.proyectos.map(function (proyecto, i) {
                                                                    return <Option value={proyecto._id}>{proyecto.nombre}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Col> : null}

                                                    {(this.props.finanzas === true) ? <Col span={3} className="center">
                                                        <Select
                                                            style={{ maxWidth: '200px', width: 'calc(100% - 5px)', margin: '0px auto' }}
                                                            placeholder="Cuenta"
                                                            value={empleado.cuenta_id}
                                                            disabled={empleado.registrado_finanzas}
                                                            onChange={(value) => this.actualizarCuenta(index, value)}>
                                                            {
                                                                this.state.cuentas.map(function (account, i) {
                                                                    return <Option value={account._id}>{account.nombre}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Col> : null}

                                                    {(this.props.finanzas === true) ? <Col span={3} className="center">
                                                        <Select
                                                            style={{ maxWidth: '200px', width: 'calc(100% - 5px)', margin: '0px auto' }}
                                                            placeholder="Subrubro"
                                                            value={empleado.subrubro_id}
                                                            onChange={(value) => this.actualizarSubrubros(index, value)}
                                                            disabled={empleado.registrado_finanzas}
                                                        >
                                                            {
                                                                this.state.subrubros.map(function (proyecto, i) {
                                                                    return <Option value={proyecto._id}>{proyecto.nombre}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Col> : null}
                                                    {(this.props.finanzas === true) ? <Col span={1} className="center">
                                                        <Button
                                                            type="primary"
                                                            // disabled={registrado_finanzas}
                                                            className="btn-morado"
                                                            icon={<IconNominas />}
                                                            onClick={() => this.payEmpleado(index)}
                                                            disabled={empleado.registrado_finanzas} onChange={(value) => this.actualizarCuenta(index, value)}

                                                        />
                                                    </Col> : null}
                                                </Row>
                                            </List.Item>
                                        </Spin>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Row>

                </Spin>
                {
                    (this.props.finanzas !== true) ? <Button type="ghost" className="btn-save" onClick={() => this.save()} disabled={false}>
                        <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41 3.82586C40.9961 5.11749 40.5257 6.0772 39.6873 6.8994C34.9386 11.551 30.1977 16.211 25.4539 20.8683C22.7917 23.4819 20.1315 26.0964 17.4674 28.7072C15.7045 30.436 13.2626 30.4284 11.5017 28.6996C8.07832 25.3359 4.64628 21.9807 1.23163 18.6094C0.0773175 17.4695 -0.282018 16.0897 0.223177 14.5581C0.722575 13.0446 1.82763 12.1389 3.42242 11.8829C4.80084 11.661 5.98606 12.1019 6.9723 13.0721C9.34759 15.4078 11.7316 17.736 14.0982 20.0802C14.3889 20.3685 14.5425 20.4197 14.87 20.0964C21.2511 13.8089 27.6476 7.53478 34.0422 1.25968C35.1994 0.123585 36.5846 -0.277558 38.1533 0.193762C39.6795 0.651805 40.6001 1.7054 40.9324 3.24074C40.9826 3.47119 40.9874 3.71017 41 3.82586Z" fill="white" />
                        </svg>
                    </Button> : null
                }
            </div>
        )
    }
}
