import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, message, Statistic, List, Typography, Pagination, Popover, Modal } from 'antd'
import { FileTextOutlined } from '@ant-design/icons';
import { IconEdit, IconPDF, IconEye, IconDelete, IconNewTarget, IconEmailSuccess, IconEmailWaitingForSend, IconTblPagos } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import Logged from '../../../Hooks/Logged';
import ModalTransacciónDetalle from '../Modales/Finanzas/ModalTransacciónDetalle';
import '../../../Styles/detallesClientes.css'

const moment = require('moment');
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
const { Text } = Typography


/**
 *
 *
 * @export
 * @class DetallePropiedad
 * @extends {Component}
 */
export default class DetallePropiedad extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            transacciones: [],
            transacciones_pro: [],
            loading: false,
            loading_p: false,
        }
    }

    componentDidMount() {
        this.getTransacciones()
        this.getTransaccionesProgramadas()
    }


    componentDidUpdate(prevProps) {
        //Si se creo o elimino una transaccion, actualiza la infomacion
        if (this.props.propiedad?.monto_pagado !== prevProps.propiedad.monto_pagado) {
            this.getTransacciones()
        }
        //Si se creo o elimino una transaccion programada, actualiza la infomacion
        if (this.props.propiedad?.transacciones_programadas?.length !== prevProps.propiedad?.transacciones_programadas?.length) {
            this.getTransaccionesProgramadas()
        }
    }

    /**
     * @memberof DetallePropiedad
     * @method getTransacciones
     * @description obtiene las transacciones hechas a una propiedad
     */
    getTransacciones = (page) => {
        this.setState({ loading: true })
        axios.post('/clientes/propiedad/transacciones/list', {
            id: this.props.propiedad._id,
            page,
        }).then(response => {
            this.setState({
                transacciones: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,
            })
        }).catch(error => {
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof DetallePropiedad
     * @method getTransaccionesProgramadas
     * @description obtiene las transacciones programadas paginadas del array que tiene la propiedad
     */
    getTransaccionesProgramadas = (page) => {
        this.setState({ loading_p: true })
        axios.post('/clientes/propiedad/transacciones/programadas/list', {
            id: this.props.propiedad._id,
            page,
        }).then(response => {
            this.setState({
                transacciones_pro: response.data.data.itemsList,
                currentPage: response.data.data.currentPage,
                itemCount: response.data.data.itemCount
            })
        }).catch(error => {
            console.log(error)
        }).finally(() => this.setState({ loading_p: false }))
    }


    /**
     * @memberof DetallePropiedad
     * @method deleteTransaccion
     * @description Elimina una transaccion y la quita de la propiedad
     */
    deleteTransaccion = (transaccion_id) => {
        axios.post('/transacciones/delete', {
            id: transaccion_id,
            propiedad_id: this.props.propiedad._id,
        }).then(response => {
            message.success('Transaccion Eliminada')
            this.props.receivedData()
        }).catch(error => {
            console.log(error)
            message.success('Error al eliminar la transaccion')
        })
    }


    /**
     * @memberof DetallePropiedad
     * @method eliminarTransaccionProgramada
     * @description Elimina la transaccion programada del arreglo de la propiedad.
     */
    eliminarTransaccionProgramada = (transaccion_id) => {
        axios.post('/clientes/delete/transaccionProgramada', {
            propiedad_id: this.props.propiedad._id,
            transaccion_id: transaccion_id,
        })
            .then((response) => {
                message.success('Transaccion Programada eliminada!');
                this.props.receivedData()
            })
            .catch((error) => {
                console.log(error)
                message.success('Error al eliminar la transaccion')
            })
    };

    /**
     * 
     * @memberof DetallePropiedad
     * @method registrarTransaccion
     * @description Registrar la transaccion programada a finanzas
     */
    registrarTransaccion = (transaccion_id) => {
        axios.post('/clientes/registrar/transaccionProgramada', {
            id: transaccion_id,
            propiedad_id: this.props.propiedad._id,
        }).then(result => {
            message.success('Transaccion Registrada')
            this.props.receivedData()
        }).catch(error => {
            console.log(error)
            if (error.response?.status === 403)
                message.error(error.response?.data?.message)
            else
                message.error('Error al registrar la transaccion')
        })
    }


    /**
     * @description retorna el url para obtener el recibo de ta transaccion
     * @param transaccion_id
     *
     * */
    getUrl = (transaccion_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/clientes/recibo');

        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('tran_id', transaccion_id)
        urlPDF.searchParams.append('propiedad_id', this.props.propiedad._id)
        urlPDF.searchParams.append('enviar', false)

        return urlPDF.href

    }

    /**
     *
     *
     * @memberof DetalleCliente
     * @method enviarMailRecibo
     * @description Envia por mail el recibo de la transaccion seleccionada
     */
    enviarMailRecibo = (transaccion_id) => {
        const key = 'mensaje';
        message.loading({ content: 'Enviando...', key });
        axios.get('/clientes/recibo/enviar', {
            params: {
                tran_id: transaccion_id,
                propiedad_id: this.props.propiedad._id,
                enviar: true,
            }
        })
            .then(result => {
                message.success({ content: 'Mail enviado correctamente!', key })
                this.props.receivedData()
            })
            .catch(error => {
                message.error({ content: 'Ocurrio un problema al enviar el mail.', key })
            })
    }

    /**
    *
    *
    * @memberof DetalleCliente
    * @method deletePropiedad
    * @description elimina la propiedad
    */
    deletePropiedad = (propiedad_id) => {
        Modal.warning({
            title: '¿Seguro de querer eliminar la propiedad?',
            onOk: () => {
                axios.post('/clientes/propiedad/delete', {
                    id: this.props.propiedad._id
                }).then(result => {
                    message.success('Propiedad eliminada')
                    this.props.receivedData()
                })
                    .catch(error => {
                        console.log(error)
                        message.error('Error al eliminar la propiedad')
                    })
            },
        });
    }

    renderEstatus = (estatus) => {
        let text = "";
        switch (estatus) {
            case 1: text =   <p className="pInfo text-green">Vendido </p>; break;
            case 2: text =   <p className="pInfo text-red">Cancelado </p>; break;
            default: text =   <p className="pInfo">No asignado </p>; break;
        }
        return text;
    }



    render() {

        let usuario = this.context;
        const { propiedad } = this.props;
        let monto_base = ((propiedad?.monto_base != undefined ? propiedad.monto_base : 0.00))
        let monto_adicionales = propiedad?.adicionales?.length > 0 ? propiedad?.adicionales?.reduce((accumulator, currentValue) => parseFloat(accumulator + currentValue.monto), 0) : 0
        let monto_venta = monto_base + monto_adicionales;


        return (
            <>
                <Row>
                    <Col span={24}>
                        <Card
                            title=""
                            loading={this.state.loading}
                            bordered
                            className="card-proyeccionesDetalle"
                        >
                            <Row>
                                <Col span={15} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h1 style={{ margin: '0' }}>Información de la Propiedad</h1>

                                    <Button type="primary" className="button-survey btn-edit button-edit ml-1" title="Editar"
                                        onClick={() => this.props.editPropiedad(propiedad)}
                                    >
                                        <IconEdit />
                                    </Button>


                                    <Button type="primary" className="btn btn-delete" title="Eliminar"
                                        onClick={() => this.deletePropiedad(propiedad._id)}
                                    >
                                        <IconDelete />
                                    </Button>


                                    <a target="_blank" className="ml-1"
                                        href={axios.defaults.baseURL + `/clientes/estado-cuenta?_id=${propiedad._id}&Authorization=${sessionStorage.getItem('token')}`}
                                    >
                                        <Button type="primary" className="button-survey btn-eye button-eye" style={{ background: "#7457F6" }}  >
                                            <FileTextOutlined />
                                        </Button>
                                    </a>
                                </Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <label className="lblTitulo" style={{ marginBottom: '0', marginRight: '0.7rem', width: 'auto' }}>Fecha de Venta</label>
                                    <p className="pInfo m-0" style={{ margin: '0' }}>{moment(propiedad?.fecha_venta).format('DD-MM-YYYY')}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} lg={12} xl={6}>
                                    <label className="lblTitulo">Área</label>
                                    <p className="pInfo">{propiedad?.area_id?.nombre}</p>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <label className="lblTitulo">Rubro</label>
                                    <p className="pInfo">{propiedad?.rubro_id?.nombre}</p>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <label className="lblTitulo">Estatus</label>
                                  {this.renderEstatus(propiedad?.estatus)}
                                </Col>
                                <Col xs={24} lg={12} xl={6}>

                                    <label className="lblTitulo">Asesor</label>
                                    <CustomAvatar name={[propiedad?.asesor_id?.nombre, propiedad?.asesor_id?.apellido]} />
                                    <p className="pInfo">{`${propiedad?.asesor_id?.nombre} ${propiedad?.asesor_id.apellido}`}</p>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <label className="lblTitulo">Proyecto</label>
                                    <CustomAvatar
                                        color={propiedad.proyecto_id?.color}
                                        name={propiedad.proyecto_id?.nombre} />
                                    <p className="pInfo">{propiedad.proyecto_id.nombre}</p>
                                </Col>

                                <Col xs={24} lg={12} xl={6}>
                                    <Row>
                                        <label className="lblTitulo">Monto de Venta</label>
                                        <p className="pInfo"><Statistic value={monto_venta} precision={2} prefix={'$'} suffix={"MXN"} /></p>

                                    </Row>
                                    <Row align='top'>
                                        <Col span={12} className="m-0">
                                            <label className="lblTitulo" >Precio Base</label>
                                            <p ><Statistic value={propiedad?.monto_base} precision={2} prefix={"$"} suffix="MXN" /></p></Col>
                                        <Col span={12} style={{ marginTop: '-7px' }}>
                                            <label className="lblTitulo" >Adicionales <a className="icon-element" onClick={() => this.props.editAdicionales(propiedad)}>
                                                <IconNewTarget />
                                            </a></label>
                                            <p ><Statistic value={propiedad?.adicionales?.reduce((accumulator, currentValue) => parseFloat(accumulator + currentValue.monto), 0)} precision={2} prefix={"$"} suffix="MXN" /></p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <label className="lblTitulo">Monto Actual Pagado</label>
                                    <p className="pInfo textGreen"><Statistic valueStyle={{ color: 'green' }}
                                        value={propiedad?.monto_pagado}
                                        precision={2} prefix={'$'}
                                        suffix={"MXN"} /></p>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <label className="lblTitulo">Monto por Pagar</label>
                                    <p className="pInfo textRed"><Statistic valueStyle={{ color: 'red' }}
                                        value={propiedad?.monto_deuda}
                                        precision={2} prefix={'$'}
                                        suffix={"MXN"} /></p>
                                </Col>
                                <Col xs={24}>

                                    <label className="lblTitulo">Documentos
                                        <a className="icon-element" onClick={() => this.props.editDocumentos(propiedad)}>
                                            <IconNewTarget />
                                        </a>
                                    </label>
                                    <div className="pInfo document">{
                                        (Array.isArray(propiedad.documentos)) ? propiedad.documentos.map(item => {
                                            //<a className="link-document" download href={axios.defaults.baseURL  "/upload/" + item} ></a>
                                            return <a target="_blank" className="link-document"
                                                style={{ display: "block" }} download
                                                href={axios.defaults.baseURL + "/voucher/" + item}>
                                                {item}
                                            </a>
                                        }) : null
                                    }</div>

                                </Col>

                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="p-1">
                        <Row>
                            <h1 className="blue-title">Transacciones</h1>
                        </Row>
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.transacciones}
                            renderItem={(item, i) => (

                                <List.Item className="component-list-item" key={item._id}>
                                    <Card className="card-list">
                                        <Row style={{ width: '100%' }} className="">
                                            <Col span={5} className="center">
                                                <Text strong>{moment(item.fecha).utc().format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text strong>{item.concepto}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>$ {item.monto.toMoney(2)} MXN</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{item.tipo_cliente ? item.tipo_cliente : 'Transacción'}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Popover placement="topLeft"
                                                    title={(item.mensaje && item.mensaje.enviado) ? "El mensaje ha sido enviado" : "El mensaje aún no se envía"}
                                                    content={(item.mensaje && item.mensaje.enviado) ? `El correo se envió el ${moment(item.mensaje.fecha).format("YYYY-MM-DD")} a las ${moment(item.mensaje.fecha).format("HH:MM")}` : undefined}>
                                                    <Button type="primary" className="button-survey btn-mail button-mail"
                                                        style={{ backgroundColor: (item.mensaje && item.mensaje.enviado) ? "#28a745" : "#ffc107" }}
                                                        name={item.nombre} id={item._id} title="Enviar" onClick={() => {
                                                            this.enviarMailRecibo(item._id)
                                                        }}>
                                                        {(item.mensaje && item.mensaje.enviado) ?
                                                            <IconEmailSuccess style={{ width: "20px", height: "20px" }} color="white" />
                                                            :
                                                            <IconEmailWaitingForSend color="white" style={{ width: "20px", height: "20px" }} />
                                                        }
                                                    </Button>
                                                </Popover>
                                                <a href={this.getUrl(item._id)} download target="_blank">
                                                    <Button type="primary" className="button-survey btn-pdf button-pdf"
                                                        title="PDF">
                                                        <IconPDF />
                                                    </Button>
                                                </a>
                                                <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle"
                                                    onClick={() => this.setState({ modalVisibleDetalle: true, transaccion_id: item._id })}
                                                >
                                                    <IconEye />
                                                </Button>
                                                {(usuario && (usuario.tipo === 1)) ? <Popconfirm placement="topRight"
                                                    title="¿Eliminar esta operacion?" okText="Si, seguro" cancelText="No" onConfirm={() => {
                                                        this.deleteTransaccion(item._id)
                                                    }}>
                                                    <Button className="btn btn-delete" title="Eliminar">
                                                        <IconDelete />
                                                    </Button>
                                                </Popconfirm> : null}
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
                <Row className="mt-2 pb-3">
                    <Pagination
                        total={this.state.total}
                        current={this.state.page}
                        pageSize={5}
                        onChange={(page) => {
                            this.getTransacciones(page)
                        }}
                    />
                </Row>
                <Row>
                    <Col span={24} className="p-1">
                        <Row>
                            <h1 className="blue-title">Transacciones Programadas</h1>
                        </Row>
                        <List
                            loading={this.state.loading_p}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.transacciones_pro}
                            renderItem={(item, i) => (

                                <List.Item className="component-list-item" key={item._id}>
                                    <Card className="card-list">
                                        <Row style={{ width: '100%' }} className="">
                                            <Col span={5} className="center">
                                                <Text strong>{moment(item.fecha).utc().format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text strong>{item.concepto}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>$ {item.monto.toMoney(2)} MXN</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{item.tipo_cliente ? item.tipo_cliente : 'Transacción'}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Registrar esta transacción?"
                                                    okText="Si, seguro"
                                                    cancelText="No"
                                                    onConfirm={() => this.registrarTransaccion(item._id)}
                                                >
                                                    <Button
                                                        type="primary"
                                                        className="button-survey btn-pdf button-pdf"
                                                        style={{ background: "transparent", paddingTop: "0px" }}
                                                        title="Registrar">
                                                        <IconTblPagos />
                                                    </Button>
                                                </Popconfirm>
                                                <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle"
                                                    onClick={() => this.props.verTransaccionProgramada(item)}
                                                >
                                                    <IconEye />
                                                </Button>
                                                <Button type="primary" className="button-survey btn-edit button-edit" title="Editar"
                                                    onClick={() => this.props.editarProgramada(item)}>
                                                    <IconEdit />
                                                </Button>
                                                {(usuario && (usuario.tipo === 1)) ? <Popconfirm
                                                    title="Eliminar esta transacción?"
                                                    okText="Si, seguro" cancelText="No"
                                                    placement="topRight"
                                                    onConfirm={() => this.eliminarTransaccionProgramada(item._id)}>
                                                    <Button type="primary" className="btn btn-delete" title="Eliminar">
                                                        <IconDelete />
                                                    </Button>
                                                </Popconfirm> : null}
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
                <Row className="mt-2 pb-3">
                    <Pagination
                        total={this.state.currentPage}
                        current={this.state.itemCount}
                        pageSize={5}
                        onChange={(page) => {
                            this.getTransaccionesProgramadas(page)
                        }}
                    />
                </Row>
                <ModalTransacciónDetalle
                    modalVisible={this.state.modalVisibleDetalle}
                    registroId={this.state.transaccion_id}
                    closeMethod={() => {
                        this.setState({ modalVisibleDetalle: false, transaccion_id: undefined })
                    }}
                />
            </>
        )
    }


}
DetallePropiedad.contextType = Logged;