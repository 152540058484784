import React, {Component} from 'react';
import {Row, Col, Card, Button, Popover, Table, Popconfirm, message, Statistic, Tabs, List, Typography, Pagination} from 'antd'
import {Link} from 'react-router-dom';
//componentes
import { IconEdit,IconPDF,IconEye,IconPlus,IconArrowBack,IconDelete, IconNewTarget, IconEmailSuccess, IconEmailWaitingForSend,IconTblPagos} from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import Logged from '../../../Hooks/Logged';
//modales
import ModalTransaccion from '../Modales/FinancialHealth/ModalTransaccionInversion';
import ModalTransacciónDetalle from '../Modales/Finanzas/ModalTransacciónDetalle';
//css
import '../../../Styles/detallesClientes.css'

const moment = require('moment');
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
const { TabPane } = Tabs
const { Text } = Typography

export default class InversionDetalle extends Component {
    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            inversion_id: this.props.match.params.id,
            inversion: {},
            currentPage: 1,
            itemCount: 0,
            limit: 10,
            transaccion_id: '',
        }
    }


    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        this.getInversion()
    }

    getInversion = (page) => {
        axios.get('/inversiones/get',{
            params:{
                _id: this.state.inversion_id,
                page,
                transacciones: true, //traer las transacciones hechas a la inversion
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({
                inversion: response.data.data.inversion,
                valor_estimado_actual: response.data.data.inversion.valor_estimado_actual,
                monto_venta: response.data.data.inversion.monto_venta,
                monto_restante: response.data.data.inversion.monto_restante,
                monto_pagado: response.data.data.inversion.monto_pagado,
                transacciones: response.data.data.transacciones.itemsList,
                currentPage: response.data.data.transacciones.currentPage,
                itemCount: response.data.data.transacciones.itemCount,
            })
        }).catch(error => {
            console.log(error)
        })
    }

    deleteTransaccion = (transaccion_id) => {
        axios.post('/transacciones/delete',{
            id: transaccion_id
        }).then(response => {
            message.success('Transaccion Eliminada')
            this.getInversion()
        }).catch(error => {
            message.error('Error al eliminar la transaccion')
        })
    }


    render() {
        let usuario = this.context;

        const { inversion } = this.state

        return (
            <div>
                <Row className="row-encabezado">

                    <Col xs={24} xl={6} xxl={8} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack/>
                        </Button>
                        <h2 style={{width: 'auto', float: 'left'}}> Inversiones </h2>
                    </Col>

                </Row>

                <section className="p-1">
                    <Row>
                        <Col span={24}>
                            <Card
                                title=""
                                loading={this.state.loading}
                                bordered
                                className="card-proyeccionesDetalle"
                            >
                                <Row>
                                    <h1 className="blue-title">Inversion: { inversion.nombre }</h1>
                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Proyecto</label>
                                        <CustomAvatar image={inversion.proyecto_id?.logo} name={inversion.proyecto_id?.nombre} color={inversion.proyecto_id?.color}/>
                                        <p className="pInfo">{inversion.proyecto_id?.nombre}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Contacto</label>
                                        <CustomAvatar image={inversion.usuario_id?.logo} name={inversion.usuario_id?.nombre} color={inversion.usuario_id?.color}/>
                                        <p className="pInfo">{inversion.proyecto_id?.nombre}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Valor Estimado Actual</label>
                                        <p className="pInfo">
                                            <Statistic 
                                                value={this.state.valor_estimado_actual} 
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"}/>
                                        </p>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Monto de Venta</label>
                                        <p className="pInfo">
                                            <Statistic
                                                valueStyle={{color: '#0047ff'}}
                                                value={this.state.monto_venta} 
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"}/>
                                        </p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Monto Actual Pagado</label>
                                        <p className="pInfo">
                                            <Statistic
                                                valueStyle={{color: '#00FF19'}}
                                                value={this.state.monto_pagado} 
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"}/>
                                        </p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Monto Por Pagar</label>
                                        <p className="pInfo">
                                            <Statistic
                                                valueStyle={{color: '#FF0000'}}
                                                value={this.state.monto_restante} 
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"}/>
                                        </p>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={8}>
                 
                                        <label className="lblTitulo">Documentos
                                            <a className="icon-element" onClick={this.modalDocumentos}>
                                                <IconNewTarget/>
                                            </a>
                                        </label>
                                        <div className="pInfo document">{
                                            (Array.isArray(inversion.documentos)) ? inversion.documentos.map(item => {
                                                //<a className="link-document" download href={axios.defaults.baseURL  "/upload/" + item} ></a>
                                                return <a target="_blank" className="link-document"
                                                          style={{display: "block"}} download
                                                          href={axios.defaults.baseURL + "/voucher/" + item}>
                                                    {item}
                                                </a>
                                            }) : null
                                        }</div>
                                    </Col>
                                  
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-1">
                        <Col span={24}>
                            <Row>
                                <h1 className="blue-title">Transacciones</h1>
                            </Row>
                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.transacciones}
                                renderItem={(item, i) => (
                                    <List.Item className="component-list-item" key={item._id}>
                                        <Card className="card-list">
                                            <Row style={{ width: '100%' }} className="">
                                                <Col span={5} className="center">
                                                    <Text strong>{moment(item.fecha).utc().format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text strong>{item.concepto}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text>$ {item.monto.toMoney()} MXN</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text>{item.tipo_cliente}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    

                                                    <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle"
                                                        onClick={()=>this.setState({transaccion_id: item._id, modalVisibleDetalle: true})}
                                                    >
                                                        <IconEye/>
                                                    </Button>

                                                    <Popconfirm placement="topRight"
                                                        title="¿Eliminar esta operacion?" 
                                                        okText="Si, seguro" cancelText="No"
                                                        onConfirm={()=>this.deleteTransaccion(item._id)}
                                                    >
                                                        <Button type="primary"
                                                                className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                                title="Eliminar">
                                                            <IconDelete/>
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}

                            />
                        </Col>
                    </Row>
                     <Row>
                        <Col span={24} className="mt-2 mb-3">
                            <Pagination 
                                current={this.state.currentPage}
                                total={this.state.itemCount}
                                onChange={(page)=>{
                                    this.getInversion(page)
                                }} 
                            />
                        </Col>
                    </Row>
                </section>
                <Button className="btnAddPlus" title="Nuevo registro" onClick={()=>{this.setState({modalVisible: true})}}>
                    <IconPlus />
                </Button>
                <ModalTransaccion
                    visible={this.state.modalVisible}
                    inversion_id={this.state.inversion_id}
                    onClose={()=>{
                        this.setState({modalVisible: false})
                        this.getInversion()
                    }}
                />

                 <ModalTransacciónDetalle
                    modalVisible={this.state.modalVisibleDetalle}
                    registroId={this.state.transaccion_id}
                    closeMethod={()=>{
                        this.setState({modalVisibleDetalle: false, transaccion_id: undefined})
                    }}
                />
            </div>
        )
    }
}
InversionDetalle.contextType = Logged;
