import React, { Component } from 'react';
import { Col, Row, Card, Typography, message, Spin, Breadcrumb, Modal, Empty, Button } from 'antd'
import { Link } from "react-router-dom";
import axios from 'axios';



import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";


//compònentes
import Logged from "../../../../Hooks/Logged";
import { InmuebleCard, InmuebleCardElement } from '../GroupCard'

import { IconArrowBack } from '../../../Widgets/Iconos';



// .finish-nomina {
//     position: fixed;
//     bottom: 0;
//     right: 4em;
// }

const { Title } = Typography;

/**
 * 
 * @class ListaInmuebles
 * @description Obtiene la lista de inmuebles
 */
export default class ListaInmuebles extends Component {

    /**
     * 
     * @param contextType Obtenemos el usuario loggeado
     */
    static contextType = Logged;


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            datos: [],
            tipo: 'Empleados',
            nomina_id: this.props.match.params.nomina_id,
            total_empleados: 0,


            preeliminarFinalizado: false
        };
    }

    /**
     * @memberof NominaInmuebles
     * @description Se ejecuta al terminar de montar la vista de administar nominas;
     */

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDatos()
    }



    /**
     * 
     * @methodOf ListaInmuebles
     * @function getDatos
     * @description Obtenemos los inmuebles agrupados del server
     * 
     * @param nomina_id ID de la nómina a obtener los grupos
     * @param modalidad Si es Destajo o salarial ( solo para el filtros )
     */
    getDatos = () => {
        this.setState({ loading: true })
        axios.post('/nominas/inmuebles', {
            tipo: this.state.tipo,
            nomina_id: this.state.nomina_id
        }).then(({ data }) => {
            console.log('datos carga', data.data)
            this.setState({ 
                datos: data.data,
                preeliminarFinalizado: data.cargada
                
            })
        }).catch(e => {
            console.log('e', e);
            message.error('Error al cargar la información')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
     * 
     * @methodOf ListaInmuebles
     * @function changeData
     * @description Cambia la vista de agrupacion
     */
    changeData = (tipo) => {
        this.setState({ tipo }, () => { this.getDatos() })
    }



    /**
     *
     *
     * @memberof ListaInmuebles
     * @description Validamos que la nomina de este encaragdo ha sido completada.
     */
    marcarNominaCompletada = () => {

        Modal.confirm({
            title: '¿Deseas marcar esta nomina como completada?',
            icon: <ExclamationCircleOutlined />,
            content: 'Una vez realizado este proceso, el Dueño recibirá una notificación confirmando que finalizaste de indicar los porcentajes de avance.',
            okText: 'Aceptar',
            cancelText: 'Cancelar',


            onOk: () => {
                axios.post('/nominas/revision/preliminar/completar',{
                    nomina_id: this.state.nomina_id
                })
                    .then(({response}) => {
                        message.success("Marcado como completada exitosamente")
                        this.setState({ preeliminarFinalizado: true })
                    })
                    .catch(error => {

                        message.error("No es posible marca la nómina como completada")

                    })
            }
          });


    }


    render() {

        const { getDatos } = this;
        // Solo para el dueño usuario ->tipo :1
        return (
            <Row className="content">
                <Row className="row-encabezado">
                    <Col xs={24} xl={8} xxl={12} className="flex-left ">
                        <Breadcrumb className="breadcrumb" separator=">">
                            <Breadcrumb.Item>
                                <Link to={`/admin/nomina`}>Nominas</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item className="breadcrumb-actual">
                                <strong>{this.state.tipo}</strong>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={24} xl={16} xxl={12} className="center">
                        <Button disabled={this.state.tipo === 'Empleados'} onClick={() => this.changeData('Empleados')}>
                            Empleados
                        </Button>
                        <Button disabled={this.state.tipo === 'Inmuebles'} onClick={() => this.changeData('Inmuebles')}>
                            Inmuebles
                        </Button>
                    </Col>
                </Row>
                <Spin spinning={this.state.loading}>
                    <Row style={{ padding: 24, marginBottom: '60px' }}>
                        <Row className="mt-1" gutter={[32, 14]} style={{ width: '100%' }}>
                            {this.state.datos.map(({ usuario_id, inmueble_id, empleados, inmuebles, actividades, autorizado_preliminar }) => <Col xs={24} sm={24} xl={8}>
                                <InmuebleCardElement
                                    tipo={this.state.tipo}
                                    inmueble={inmueble_id}
                                    usuario={usuario_id}
                                    empleados={empleados}
                                    inmuebles={inmuebles}
                                    actividades={actividades}
                                    autorizado={autorizado_preliminar}
                                    nomina_id={this.props.match.params.nomina_id}
                                />
                            </Col>)}
                        </Row>
                        {this.state.inmuebles?.length === 0 ? <Row style={{ width: '100%' }}>
                            <Col span={24} className="center">
                                <Empty description={'No hay Inmuebles asignados.'} />
                            </Col>
                        </Row> : null}
                        <Button
                            size="large"
                            // disabled={this.state.preeliminarFinalizado}
                            className="finish-nomina"
                            onClick={this.marcarNominaCompletada}
                            icon={<CheckOutlined />} type="primary"> {(this.state.preeliminarFinalizado) ? "La nomina ya fue cargada." : "Terminar de Carga Nomina"} </Button>
                    </Row>
                </Spin>
            </Row>

        )
    }
}
