import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, List, Typography, message, Spin } from 'antd';

import { IconCloseModal, IconEdit, IconDelete } from '../../../Widgets/Iconos';
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import axios from 'axios';

import './../../../../Styles/modales.css'

const moment = require('moment');
const { Title, } = Typography;

export default class ModalArea extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };
    constructor(props) {
        super(props);
        this.state = {

            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false,

            fileList: [],
            dataAreas: [],
            dataProyectos: [],
            dataCuentas: [],
            dataTipos: [],
        }
    }

    //Referencia de form
    ModalAreaRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    //Función para limpiar campos
    limpiarCamposModal = () => {
        if (this.ModalAreaRef.current !==undefined) {
            this.ModalAreaRef.current.setFieldsValue({
                nombre: '',
            });
        }
    }

    //Función que busca la info del registro seleccionado
    modalEditar = () => {
        const { registroId } = this.props;
        axios.post('/locaciones/get', {
            id: registroId,
        }).then((area) => {
            let data = area.data.data;

            //Actualiza los valores del form dentro del modal.
            this.ModalAreaRef.current.setFieldsValue({
                nombre: data.nombre
            })
            this.setState({
                datosConsultados: true,
            })
        })
    }

    //Función de guardado-edición dependiendo de la propiedad "accionModal"
    btnSaveClickModal = values => {

        const { accionModal } = this.props;
        if (accionModal === "crear") {

            axios.post('/locaciones/add', {
                nombre: values.nombre,
            })
                .then((res) => {
                    message.success("¡Área Registrada!");
                    this.props.accept()

                }).catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : 'Error al procesar registro. Verifique la información.'
                    });
                });
        }
        else if (accionModal === "editar") {
            const { registroId } = this.props;

            axios.post('/locaciones/update', {
                id: registroId,
                nombre: values.nombre,
            })
                .then((res) => {
                    message.success("¡Área Actualizada!");
                    this.props.accept()

                }).catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : 'Error al procesar edición de registro. Verifique la información.'
                    });
                });
        }
    }

    //Función que reinicializa los states y llama al método de cierre en componente padre
    hideModal = () => {
        //Reiniciar valores al cerrar modal
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
        })

        this.props.closeMethod();
    }

    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/admin/areas" />;
        }
    };

    render() {
        const { modalVisible } = this.props;
        const { accionModal } = this.props;
        const { registroId } = this.props;

        if (accionModal === "editar" && registroId !==undefined && registroId !=="" && (!this.state.datosConsultados)) {
            this.modalEditar();
        }

        var titulo = '';
        if (accionModal === "crear") {
            titulo = "Crear Area"
        }
        else if (accionModal === "editar") {
            titulo = "Editar Area"
        }

        var contenido = "";

        if (accionModal === "crear" || accionModal === "editar") {
            contenido = <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={this.ModalAreaRef}
                    className="frm-cuentas"
                    name="formulario-transacciones"
                    onFinish={this.btnSaveClickModal}
                >

                    <Row align="center">
                        <Col span={20}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                            </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        }

        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-rubro"
                destroyOnClose={true}
                zIndex={1000}
            >

                {this.renderRedirect()}

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{titulo}</Title>
                    {(accionModal === "detalle") ? <div className="modalEncOpciones">
                        <Button type="" className="ant-btn button-survey btn-edit button-edit ant-btn-primary" title="Editar" >
                            <IconEdit />
                        </Button>
                        <Button type="" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" title="Eliminar" >
                            <IconDelete />
                        </Button>
                    </div> : ''}
                </div>
                {contenido}
            </Modal>
        )
    }
}
