import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, Popconfirm, message, Pagination} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconEye, IconEdit,  IconDelete, IconPlus, IconArrowBack } from '../../Widgets/Iconos';
//css
import '../../../Styles/presupuesto.css'

const { Title, Text } = Typography;
const axios = require('axios').default

export default class PresupuestoCasasAct extends Component{

	constructor(props){
		super(props)
		this.state = {
			
			inmuebles:[],
			/* Paginado */
			page: 1,
			limit: 10,
			total: 0,

		}
	}
	
	/**
	 * @methodOf  PresupuestoCasasAct
	 * @function hideModal
	 *
	 * @description Regresa a la vista anterior
	 *
	 * */
	volver = () => {
        window.history.back();
    }


	/**
	* @memberof PresupuestoCasasAct
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.listInmuebles(1)
	}

	/**
	 * @methodOf  PresupuestoCasasAct
	 * @function hideModal
	 *
	 * @description Cierra el modal
	 *
	 * */
	hideModal = () => {
		this.setState({ });
	};

	/**
	 * @methodOf  PresupuestoCasasAct
	 * @function listInmuebles
	 *
	 * @description Cierra el modal
	 *
	 * */
	listInmuebles = (page) => {
		this.setState({loading:true})
		axios.get('/inmuebles-trabajo/list',{
			params:{
				page: page,
				limit: 10,
				proyecto: 'Parque Esmeralda'
			}
		}).then(response => {
			this.setState({
				inmuebles: response.data.data.itemsList,
				page: response.data.data.currentPage,
				total: response.data.data.itemCount,
				loading: false,
			})
		}).catch(error=>{
			console.log(error)
			message.error('No se cargaron los Inmuebles')
		})
	}



	render() {
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} className="">
					 	<Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
                            <IconArrowBack />
                        </Button>
						<h2 style={{width: 'auto', float: 'left'}}>Presupuesto Casas</h2>
					</Col>
				</Row>
				<section className="p-1">
					<div className="scroll">
						<Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
							<Col span={10} className="center">
								<Text type="secondary">INMUEBLE</Text>
							</Col>
							<Col span={10} className="center">
								<Text type="secondary">ACTIVIDADES</Text>
							</Col>
							<Col span={4} className="center">
								<Text type="secondary">ACCIONES</Text>
							</Col>
						</Row>

						<List
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.inmuebles}
							loading={this.state.loading}
							locale={{ emptyText: 'Sin Inmuebles' }}
							renderItem={item =>
								(<List.Item className="component-list-item">
									<Card className="card-list">
										<Row style={{ width: '100%' }} className="">
											<Col span={10} className="center">
												<Text>{item.inmueble?.nombre}</Text>
											</Col>
											<Col span={10} className="center">
												<Text>{item.tajos?.length}</Text>
											</Col>
											<Col span={4} className="center">
												<Link to={`/admin/presupuesto/inmueble/${item._id}/actividades`}>
													<Button type="primary" 
														className="button-survey btn-eye button-eye" title="Ver detalle" 
														onClick={(e) => { this.setState({modalTitle: 'Ver'}) }}>
														<IconEye />
													</Button>
												</Link>
												<Button className="btn btn-edit" onClick={(e) => { this.setState({modalTitle: 'Editar',}) }}>
													<IconEdit/>
												</Button>
												<Popconfirm
													placement="topRight"
													title={"¿Deseas eliminar a " + item.nombre + " ?"}
													onCancel={e => e.stopPropagation()}
													okText="Si"
													cancelText="No"
													onClick={e => e.stopPropagation()}
													onConfirm={e => {}}
												>
													<Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
														<IconDelete />
													</Button>
												</Popconfirm>

											</Col>
										</Row>
									</Card>
								</List.Item>
								)}
						/>
					</div>
					<Row className="mt-2 pb-3">
						<Pagination
							total={this.state.total}
							current={this.state.page}
							onChange={(page) => {
									this.listInmuebles(page)
								}}
							/>
						</Row>
				</section>
				{/*<Button
					className="btnAddPlus"
					onClick={() => this.setState({modalProspectosVisible: true, modalTitle: 'Añadir'})}>
					<IconPlus/>
				</Button>*/}
			</div>
		)
	}
}