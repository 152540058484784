import React, { Component } from 'react';
import { Layout, Button, Col, Popconfirm, Row, message, Card ,PageHeader, List, Spin, Typography, Space, Tooltip, Collapse } from 'antd'
import axios from 'axios'
import { BankOutlined, ConsoleSqlOutlined, PlusOutlined } from "@ant-design/icons";

//componentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { IconDelete, IconPlus ,IconEdit, IconBankError, IconSATSCR } from '../../Widgets/Iconos';

//modales
import ModalEmpresas from './ModalEmpresas';
/* import ModalServicio from '../Servicios/ModalServicios'; */
import ModalConfiguraciónSyncfy from '../Syncfy/ModalConfiguraciónSyncfy';
import ModalConfiguraciónTaxpayer from '../Syncfy/ModalConfiguraciónTaxpayer';



const { Title ,Text } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;

/**
 *
 *
 * @export
 * @class Empresas
 * @extends {Component}
 */
export default class Empresas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            id: null,
            empresas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            }
        }
    }


    /**
     *
     *
     * @memberof Empresas
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de los Empresas
     */

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getEmpresas()
    }

    /**
     *
     * @memberof Empresas
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra las empresas
     */
     componentDidUpdate(prevProps){
        if(this.props.search !== prevProps.search)
            this.getEmpresas()
    }


    /**
     *
     * @memberof Empresas
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
    getEmpresas = ({
        page = this.state.empresas.page,
        limit = this.state.empresas.limit,
        search = this.props.search
    } = this.state.empresas) => {
        console.log('cargando empresas...',search)
        this.setState({ loading: true, empresas: { ...this.state.empresas, page, limit } })
        axios.get('/empresas', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ empresas: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(()=>this.setState({loading: false}));
    }


    /**
     * @methodOf  Empresas
     * @function showModal
     *
     * @description Muestra el modal de la Empresa
     *
     * */
    showModal = (id) => {
        this.setState({
            visible: true,
            id: typeof id === 'string' ? id : undefined
        });
    };

    /**
     * @methodOf  Empresas
     * @function hideModal
     *
     * @description Oculta el modal de la Empresa
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };


    render() {
        const { getEmpresas, showModal, hideModal } = this;
        const { visible, empresas, id } = this.state;
        const user = this.context;

        return (
            <>
                <Spin spinning={false}>
                    <Row className="row-encabezado">
						<Col xs={24} xl={24} xxl={24} className="flex-left">
							<Title level={4}className="view-title">Empresas</Title>
						</Col>
					</Row>
                    <Content className="admin-content content-bg p-1">

                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Empresas " }}
                                loading={this.state.loading}
                                dataSource={empresas.data}
                                pagination={{
                                    current: empresas.page,
                                    pageSize: empresas.limit,
                                    total: empresas.total,
                                    onChange: (page, limit) => {
                                        this.getEmpresas({ page, limit })
                                    }
                                }}
                                header={<Row className="header-list width-100 pr-1 pl-1" >
                                    <Col lg={5} className="center">
                                        <Text strong>Alias</Text>
                                    </Col>
                                    <Col lg={5} className="center">
                                        <Text strong>Razón Social</Text>
                                    </Col>
                                    <Col lg={5} className="center">
                                        <Text strong>Descripción</Text>
                                    </Col>
                                    <Col lg={5} className="center">
                                        <Text strong>RFC</Text>
                                    </Col>
                                </Row>}
                                renderItem={(item) => {

                                    return <List.Item className="component-list-item">
                                    <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col span={5} className="">
                                            <CustomAvatar image={item.logo} name={item.alias} color={item.color} /> {item.alias}
                                        </Col>
                                        <Col span={5} className="center">
                                            {item.razon_social}
                                        </Col>
                                        <Col span={5} className="center">
                                            <Text >{item.descripcion}</Text>
                                        </Col>
                                        <Col span={5} className="center">
                                            <Text >{item.rfc}</Text>
                                        </Col>

                                        <Col span={4} className="center">
                                            <Space>
                                                <Tooltip
                                                    title={
                                                        (item.razon_social && item.rfc) ?
                                                            (!item.syncfy_taxpayer ? "Asignar el Certifcado y la Llave para Timbrar" : "Actualizar el Certifcado y la Llave para Timbrar")
                                                            : "Es necesario tener la razón social y el RFC para poder realizar el enlace"

                                                    }>
                                                    <Button
                                                        disabled={!(item.razon_social && item.rfc)}
                                                        type={!!item.syncfy_taxpayer ? "primary" : undefined}
                                                        icon={<IconSATSCR width={18} color="currentColor" ballsColor={(!!item.syncfy_taxpayer) ? "currentColor" : undefined} style={{ position: "relative", top: 1 }} />}
                                                        onClick={() => this.setState({ empresa_id: item._id, modalKeys: true })}
                                                    />
                                                </Tooltip>
                                                <Tooltip
                                                    title={
                                                        (item.razon_social && item.rfc) ?
                                                            (item.syncfy_usuario_id && item.syncfy_credenciales_id ? "Realizar el Enlace" : "Modificar el enlace")
                                                            : "Es necesario tener la razón social y el RFC para poder realizar el enlace"

                                                    }>
                                                    <Button
                                                        className='btn'
                                                        disabled={!(item.razon_social && item.rfc)}
                                                        onClick={() => this.setState({ syncfy_id: item._id, modalSyncfy: true })}
                                                        danger={!(item.syncfy_usuario_id && item.syncfy_credenciales_id)}
                                                        type="primary"
                                                        icon={
                                                            (item.syncfy_usuario_id && item.syncfy_credenciales_id)
                                                                ?
                                                                <BankOutlined style={{ fontSize: 22, color: "white", position: "relative", bottom: 2 }} />
                                                                :
                                                                <IconBankError color="white" style={{ position: 'relative', bottom: 2 }} />
                                                        }
                                                        title="Eliminar"
                                                        style={{ backgroundColor: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "#1890ff" : undefined, border: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "none" : undefined }}
                                                    />
                                                </Tooltip>
                                                <Button classname="btn btn-edit" type="primary" icon={<IconEdit />} onClick={() => showModal(item._id)} title="Editar"/>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar esta Empresa?"
                                                    onConfirm={() => axios.delete('/empresa/' + item._id).then((response) => {
                                                        message.success(response.data.message)
                                                        this.getEmpresas()
                                                    })
                                                        .catch((error) => {
                                                            message.error(error.response.data.message);
                                                            this.getEmpresas();
                                                        })
                                                    }
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button className='btn' type="primary" danger icon={<IconDelete />} title="Eliminar" />
                                                </Popconfirm>
                                            </Space>

                                        </Col>
                                    </Row>
                                    </Card>
                                </List.Item>
                                    
                                }}
                            />
                        {/* <FloatingButton title="Nuevo registro" onClick={() => showModal()} /> */}
                    </Content>
                    {(user.tipo !==3) ? <Button className="btnAddPlus" title="Nuevo registro" onClick={this.showModal} >
                        <IconPlus />
                    </Button> : null}
                </Spin>
                
                <ModalEmpresas
                    visible={visible}
                    hideModal={hideModal}
                    closeMethod={hideModal}
                    accept={() => {
                        hideModal();
                        getEmpresas();
                    }}
                    update={() => getEmpresas()}
                    id={id}

                />
                <ModalConfiguraciónSyncfy
                    visible={this.state.modalSyncfy}
                    id={this.state.syncfy_id}
                    onCancel={() => {
                        this.setState({ modalSyncfy: false, id: null, cuenta_id: undefined })
                        this.getEmpresas();
                    }}
                    tipo={2}
                />
                <ModalConfiguraciónTaxpayer
                    visible={this.state.modalKeys}
                    empresa_id={this.state.empresa_id}
                    onCancel={() => {
                        this.setState({ modalKeys: false, empresa_id: null, cuenta_id: undefined })
                        this.getEmpresas();
                    }}
                />
                {/* <ModalServicio
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, empresa_id: undefined, servicio_id: undefined})
                        this.getEmpresas()
                    }}
                    empresa_id={this.state.empresa_id}
                    servicio_id={this.state.servicio_id}
                /> */}
            </>

        )
    }
}
