import React, { Component } from 'react';
import { Spin, List, Button, Col, Row, Card, Typography, InputNumber, Modal, Form, Carousel } from 'antd'
import { FaMinus } from "react-icons/fa";
import axios from 'axios';

import Avatar from '../../../Widgets/Avatar/Avatar'
import '../../../../Styles/nominas.css'
import { IconArrowBack } from '../../../Widgets/Iconos';
import Logged from "../../../../Hooks/Logged";
import DropzoneAntDesign from "../../../Widgets/AntdDropzone/DropzoneAntDesign";

import 'pure-react-carousel/dist/react-carousel.cjs.css'

const { Title, Paragraph } = Typography;


/**
 *
 *
 * @export
 * @class RevisionDeGrupo
 * @extends {Component}
 */
export default class RevisionDeGrupo extends Component {
    static contextType = Logged;
    state = {
        group: {
            nombre: "",
            frecuencia: "",
            modalidad: "",
            precio_m2: "",
            fecha_nomina: "",
            responsable_id: {
                nombre: "",
                apellido: ""
            },
            empleados: [],


        },

        filesList: [],



        uploadingImages: false,
        loading:false
    };


    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function componentDidMount Obtenemos el grupo.
     *
     */
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        const { nomina_id, id } = this.props.match.params;
        this.state.nomina_id = nomina_id;
        this.state.grupo_id = id;

        this.getGroup(nomina_id, id)
    }


    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function getGroup Obtenemos la información del grupo
     *
     * @param nomina_id Nomina del ID
     * @param grupo_id ID del Grupo
     * @param empleados ID del Grupo
     */
    getGroup = (nomina_id, grupo_id) => {
        this.setState({ loading: true })
        axios.get('/nominas/revisiones/get', {
            params: {
                nomina_id,
                grupo_id
            }
        })
            .then(async ({ data }) => {
                this.setState({
                    group: data.data,
                    filesList: Array.isArray(data.fotos) ? data.fotos.map(e => {
                        return {
                            name: e,
                            status: 'done',
                            url: axios.defaults.baseURL + '/upload/' + e,
                        }
                    }) : []
                })

                if (this.formImageUpload.current)
                    this.formImageUpload.current.resetFields()

            })
            .catch(e => {
                console.log('e', e)
            })
            .finally(e=>this.setState({loading:false}))
    }


    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function saveGroup
     *
     * @param nomina_id Nomina del ID
     * @param grupo_id ID del Grupo
     * @param empleados ID del Grupo
     */
    saveGroup = (nomina_id = this.state.nomina_id, grupo_id = this.state.grupo_id, empleados = this.state.group.empleados) => {
        const user = this.context;
        let url = (user.tipo === 1) ? '/nominas/revision/final' : '/nominas/revision/preliminar';
        let  subirArchivos = false;

        for (const empleado of empleados) {
            if (empleado.monto > 0)
                subirArchivos = true;
        }

        let filesList = []
        if (user && user.tipo !== 1)
            filesList = (this.formImageUpload.current) ? this.formImageUpload.current.getFieldValue('filesList').map(({ name }) => name) : []

        if (this.state.group.modalidad === 2 && filesList.length < 1 && user && user.tipo !==1 && subirArchivos)
            return Modal.warning({
                title: "Es necesario ingresar al menos una imagen",
                content: "Para poder guardar este destajo, es necesario ingresar por lo menos una imagen."
            })

        Modal.warning({
            title: '¿Estás seguro de que quieres confirmar la nómina de este grupo?',
            cancelText: "Cancelar",
            okText: "Aceptar",
            okCancel: true,
            onOk: () => {
                this.setState({ loading: true })
                axios.post(url, {
                    nomina_id,
                    grupo_id,
                    empleados,
                    files: filesList
                })
                    .then(async ({ data }) => {

                        this.props.history.goBack();
                    })
                    .catch(e => {
                        console.log('e', e)
                    })
            }
        });


    }


    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     *
     * @function Actualizamos el valor de metros cuadrados de un empleado en especifico
     * @param {*} value
     * @param id Localización del empleado en el arreglo.
     *
     * @returns
     */
    updatePaymentOnSquareMeter = (value, id) => {
        let group = this.state.group;

        if (value !==null && value !==undefined && value !=='') {
            group.empleados[id].monto = parseFloat(value) * parseFloat(group.precio_m2)
            group.empleados[id].monto_autorizado = parseFloat(value) * parseFloat(group.precio_m2)
            group.empleados[id].m2_trabajados = parseFloat(value)
        }
        else
            group.empleados[id].monto = 0;
        this.setState({ group })
    }

    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function updateMonto
     * @description Actualizamos el monto de ese valor en especifico.
     *
     * @param {*} value
     *
     * @returns
     */
    updateMonto = (value, id) => {
        let group = this.state.group;
        group.empleados[id].monto = value;
        group.empleados[id].monto_autorizado = value;
        this.setState({ group })
    }


    /**
     *
     *
     * @memberof RevisionDeGrupo
     * @description Elimina el empleado.
     *
     * @param i index del empleado aliminar.
     */
    deleteEmployee = (i) => {

        //let group = this.state.group;
        //group.empleados[id].monto = value;
        this.setState(state => {
            let newA = []

            for (let index = 0; index < state.group.empleados.length; index++) {
                const element = state.group.empleados[index];

                if (index === i)
                    continue;
                else
                    newA.push(element)

            }
            state.group.empleados = newA;
            return state;
        })
    }

    ViewImagenesOrUpload = ({ modalidad }) => {


        const user = this.context;
        if (user && user.tipo !== 1) {
            return <Row className={"information-widgets-content " + ((modalidad === 2) ? "information-destajo" : "")}>
                <Col span={24}>
                    <Paragraph className="information-widgets-content-title">Fotografias</Paragraph>
                    <Form ref={this.formImageUpload} initialValues={{ filesList: this.state.filesList }} >
                        <Form.Item noStyle name='filesList' valuePropName="fileList" >
                            <DropzoneAntDesign />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        }
        else {
            return <Row style={{ marginTop: '1em', paddingBottom: '1em' }}>
                <Row>
                    <Paragraph className="information-widgets-content-title">Imagenes</Paragraph>
                </Row>
                <Row style={{ width: '100%', maxWidth: '100%' }} className="slider-element">
                    <Modal
                        visible={this.state.modalImagePreview}
                        onCancel={() => this.setState({ modalImagePreview: false })}
                        className="image-previewer"
                        cancelButtonProps={{ style: { display: 'none' } }}
                    >
                        <img width='100%' src={this.state.imageURL} zoomPosition="original" zoomWidth={500} />

                    </Modal>

                    <Carousel slidesToShow={(this.state.filesList.length > 5) ? 6 : this.state.filesList.length} style={{ width: '100%', minWidth: '100%' }} >
                        {this.state.filesList.map((e, index) => <div>
                            <div onClick={() => {
                                this.setState({
                                    modalImagePreview: true,
                                    imageURL: axios.defaults.baseURL + '/upload/' + e.name
                                })
                            }} >
                                <span style={{ fontSize: 1, color: 'white' }}>1</span>
                                <img
                                    className="image-preview"
                                    src={axios.defaults.baseURL + '/upload/' + e.name} style={{
                                        // height:'160px',
                                        display: 'block',
                                        maxWidth: 'calc(100% - 10px)',

                                    }} />
                            </div>
                        </div>)}
                    </Carousel>
                </Row>
            </Row>
        }
    }


    formImageUpload = React.createRef();


    render() {
        const { group } = this.state;

        const user = this.context;
        const { deleteEmployee, updatePaymentOnSquareMeter, updateMonto, saveGroup, updateFilesList } = this;


        const columns = (group.modalidad === 1) ? 6 : 4
        const columnsElement = (group.modalidad === 1) ? {
            input: 10,
            action: 4
        } : {
                input: 7,
                action: 3
            }
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={8} xxl={12}>
                            <h2> <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline' }}><IconArrowBack /></Button> Grupo</h2>
                        </Col>
                    </Row>
                    <Row style={{ padding: '24px' }} className="content">
                        <Row gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col xs={24} sm={24} xl={24}>
                                <Card className="content-card">
                                    <Row>
                                        <Col span={18}>
                                            <Title level={3} style={{ color: "#0047FF" }}>{group.nombre}</Title>
                                        </Col>
                                        <Col span={6} className="information-content">
                                            <Avatar className="information-content-avatar"
                                                image={group.responsable_id.avatar}
                                                name={[
                                                    group.responsable_id.nombre,
                                                    group.responsable_id.apellido
                                                ]}
                                            />
                                            <Title className="information-content-title">{group.responsable_id.nombre}</Title>
                                        </Col>
                                    </Row>
                                    <Row className={"information-widgets-content " + ((group.modalidad === 2) ? "information-destajo" : "")}>
                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Empleados</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{group.empleados.length}</Paragraph>
                                        </Col>
                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Frecuencia</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{(group.frecuencia === 1) ? "Semanal" : "Quincenal"}</Paragraph>
                                        </Col>
                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Modalidad</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{(group.modalidad === 1) ? "Salario" : "Destajo"}</Paragraph>
                                        </Col>
                                        {group.modalidad === 2 ? <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Precio m<sub>2</sub></Paragraph>
                                            <Paragraph className="information-widgets-content-description"> ${group.precio_m2.toString().toMoney(true)}</Paragraph>
                                        </Col> : null}
                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Monto Total</Paragraph>
                                            <Paragraph className="information-widgets-content-description">${group.empleados.reduce((accumulator, currentValue) => accumulator + currentValue.monto, 0).toString().toMoney(true)}</Paragraph>
                                        </Col>
                                    </Row>
                                    <Row className={"information-widgets-content " + ((group.modalidad === 2) ? "information-destajo" : "")} style={{ marginTop: '20px' }}>
                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Responsable</Paragraph>
                                            <Avatar className="information-content-avatar"
                                                image={group.responsable_id.avatar}
                                                name={[
                                                    group.responsable_id.nombre,
                                                    group.responsable_id.apellido
                                                ]}
                                            />
                                            <label className="information-widgets-content-description" style={{ marginLeft: '10px' }}>{group.responsable_id.nombre + ' ' + group.responsable_id.apellido}</label>
                                        </Col>
                                        {(group.inmueble_id !== undefined) ?
                                            <Col span={columns}>
                                                <Paragraph className="information-widgets-content-title">Inmueble</Paragraph>
                                                <Paragraph className="information-widgets-content-description">{(group.inmueble_id) ? group.inmueble_id.nombre : 'N/A'}</Paragraph>
                                            </Col> : ''}

                                        {(group.area_id !== undefined) ?
                                            <Col span={columns}>
                                                <Paragraph className="information-widgets-content-title">Area</Paragraph>
                                                <Paragraph className="information-widgets-content-description">{(group.area_id) ? group.area_id.nombre : 'N/A'}</Paragraph>
                                            </Col> : ''}
                                    </Row>

                                    {(group.modalidad === 2) ? this.ViewImagenesOrUpload(group.modalidad) : null}
                                </Card>
                            </Col>
                        </Row>
                        <Row className="list-container" gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>Empleados</Title>
                            </Col>
                            <Col span={24} style={{ paddingTop: 0 }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={group.empleados}
                                    className="list-employees"
                                    renderItem={(item, index) => (
                                        <List.Item className="list-employees-item-content">
                                            <Row className="list-employee-item" style={{ width: '100%' }}>
                                                <Col span={columnsElement.input}>
                                                    {(item.usuario_id) ? (item.usuario_id.nombre + ' ' + item.usuario_id.apellido) : <strong>Empleado Inexistente</strong>}
                                                </Col>
                                                {group.modalidad === 2 ? <Col span={columnsElement.input}>
                                                    <InputNumber
                                                        precision={5}
                                                        defaultValue={(item.m2_trabajados !== undefined) ? item.m2_trabajados : 0}
                                                        className="input-ghost"
                                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                        onChange={e => updatePaymentOnSquareMeter(e, index)}

                                                        style={{ width: 'calc(100% - 20px)', margin: '0px auto' }}
                                                    />
                                                </Col> : null}
                                                <Col span={columnsElement.input}>
                                                    {group.modalidad === 1 ? <Col span={columnsElement.input}>
                                                        <InputNumber
                                                            precision={2}
                                                            defaultValue={item.monto}
                                                            className="input-ghost"
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            onChange={e => updateMonto(e, index)}
                                                            style={{ width: 'calc(100% - 20px)', margin: '0px auto' }}
                                                        />
                                                    </Col> : ((item.monto !== undefined) ? `$${item.monto.toString().toMoney(true)}` : 0)}
                                                </Col>
                                                <Col span={columnsElement.action}>
                                                    <Button onClick={() => deleteEmployee(index)} className="button-list-employee-delete" style={{ background: "#FF0000", }} icon={<FaMinus color="white" style={{ position: "relative", top: "4px" }} />} />
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Row>
                    {/*<Tooltip placement="top" title={"Se estan subiendo las imagenes..."}>*/}
                    <Button type="ghost" className="btn-save" onClick={() => saveGroup()} disabled={this.state.uploadingImages}>
                        {this.state.uploadingImages ? <Spin style={{ position: 'relative', top: '3px' }} spinning={true} /> : <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41 3.82586C40.9961 5.11749 40.5257 6.0772 39.6873 6.8994C34.9386 11.551 30.1977 16.211 25.4539 20.8683C22.7917 23.4819 20.1315 26.0964 17.4674 28.7072C15.7045 30.436 13.2626 30.4284 11.5017 28.6996C8.07832 25.3359 4.64628 21.9807 1.23163 18.6094C0.0773175 17.4695 -0.282018 16.0897 0.223177 14.5581C0.722575 13.0446 1.82763 12.1389 3.42242 11.8829C4.80084 11.661 5.98606 12.1019 6.9723 13.0721C9.34759 15.4078 11.7316 17.736 14.0982 20.0802C14.3889 20.3685 14.5425 20.4197 14.87 20.0964C21.2511 13.8089 27.6476 7.53478 34.0422 1.25968C35.1994 0.123585 36.5846 -0.277558 38.1533 0.193762C39.6795 0.651805 40.6001 1.7054 40.9324 3.24074C40.9826 3.47119 40.9874 3.71017 41 3.82586Z" fill="white" />
                        </svg>}
                    </Button>
                    {/*</Tooltip>*/}

                </Spin>
            </div>
        )
    }
}
