import React, { Component } from "react";
import { Row, Col, Button, Modal, DatePicker, Typography, Form, Input, InputNumber, Select, message, Spin } from 'antd';
//componentes
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const { Title } = Typography;
const { Option } = Select;
const axios = require('axios');

//Modal para agregar una Tajo en ActividadAdmin
export default class ModalTajo extends Component {

    formTajo = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            loading: false,
            tajo_id: {},
            maxM2: 0,
            maxMonto: 0,
        }
    }

    /**
    * @memberof ModalTajo
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadTags();
    }

    /**
    * @memberof ModalTajo
    *
    * @method   componentDidUpdate
    * @description
    *
    **/
    componentDidUpdate(prevProps) {
        if (this.props.tajo_id !== prevProps.tajo_id && this.props.tajo_id !== undefined) {
            this.getTajo(this.props.tajo_id)
            this.loadTags();
        }
        if (this.props.modalVisible !== prevProps.modalVisible) {
            this.loadTags();
        }
    }

    /**
    * @memberof ModalTajo
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
        this.setState({ tajo_id: {} })
    }

    /**
    *
    *
    * @memberof ModalTajo
    * @method loadTags
    *
    * @description Obtienen los tags para el select de Tajos
    */
    loadTags = () => {
        axios.get('/tags', {
            params:{
                page: 1,
                limit: 1000,
                type: 'area_id',
                manager_actividad_id: this.props.manager_actividad_id,
            }
        })
            .then(res => {
                this.setState({ tags: res.data.data.itemsList })
            })
            .catch(e =>
                console.log('error actividad get', e)
            )
    }

    /**
   *
   *
   * @memberof ModalTajo
   * @method getTajo
   * @description Obtiene la informacion de un tajo por medio del id
   */
    getTajo = (id) => {
        this.setState({ loading: true })
        axios.get("/manager/proyectos/inmuebles/actividades/tajo", {
            params: {
                _id: id,
            }
        })
            .then(res => {
                this.setState({
                    loading: false,
                    tajo_id: res.data.data.tajo_id,
                    maxM2: res.data.data.m2_totales,
                    maxMonto: res.data.data.costo_presupuestado,
                })
                this.formTajo.current.setFieldsValue({
                    tajo_id: res.data.data.tajo_id._id,
                    fecha_inicio: moment(res.data.data.fecha_inicio),
                    fecha_conclusion: moment(res.data.data.fecha_conclusion),
                    costo_presupuestado: res.data.data.costo_presupuestado,
                    costo_actual: res.data.data.costo_actual,
                    m2_totales: res.data.data.m2_totales,
                    m2_actuales: res.data.data.m2_actuales,
                })
                this.loadTags();
            })
            .catch(e =>
                console.log('error tajo get', e)
            )
    }

    /**
    * @memberof ModalTajo
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {

        if (this.props.modalTitle === 'Editar') {



            this.updateTajo(values);
        } else {
            this.addTajos(values);
        }
    }


    /**
     *
     *
     * @param {*} values
     * @memberof ModalTajo
     * @method addTajos
     * @description Manda peticion para crear un tajo de la actividad
     *
     */
    addTajos(values) {

        var costo_presupuestado = parseFloat(values.costo_presupuestado),
            costo_pagado = parseFloat(values.costo_pagado),
            m2_actuales = parseFloat(values.m2_actuales),
            m2_totales = parseFloat(values.m2_totales);

        if (m2_totales >= m2_actuales) {
            if (costo_presupuestado > 0 && m2_totales > 0) {
                this.setState({ loading: true })

                axios.post('/manager/proyectos/inmuebles/actividades/tajos/add', {
                    manager_proyecto_id: this.props.manager_proyecto_id, 
                    manager_actividad_id: this.props.manager_actividad_id,
                    actividad_id: this.props.actividad_id,
                    tajo_id: values.tajo_id,
                    fecha_inicio: values.fecha_inicio,
                    fecha_conclusion: values.fecha_conclusion,
                    costo_presupuestado: values.costo_presupuestado,
                    costo_actual: values.costo_actual,
                    m2_totales: values.m2_totales,
                    m2_actuales: values.m2_actuales,
                    loading: false

                })
                    .then(res => {
                        message.success('¡Tajo creado!');
                    })
                    .catch(error => {
                        console.log('¡error al crear el Tajo!', error);
                        message.error('Error Tajo no creado');
                    })
                    .finally(f => {
                        this.props.closeMethod();
                    })
            }

            else {
                message.error('Ingresar M2 Planeados y Monto Presupuestado');
            }
        }
        else {
            message.error('Los m2 terminados no pueden ser mayores a los m2 planeados');
        }

    }


    /**
    *
    *
    * @param {*} Values
    * @memberof ModalTajo
    * @method updateTajo
    * @description Manda la peticion para actualizar un tajo
    */
    updateTajo(values) {
        var costo_presupuestado = parseFloat(values.costo_presupuestado),
            costo_pagado = parseFloat(values.costo_pagado),
            m2_actuales = parseFloat(values.m2_actuales),
            m2_totales = parseFloat(values.m2_totales);

        if (m2_totales >= m2_actuales) {

            if (costo_presupuestado > 0 && m2_totales > 0) {


                this.setState({ loading: true })
                axios.put('/manager/proyectos/inmuebles/actividades/tajos/update', {
                    _id: this.props.tajo_id,
                    tajo_id: values.tajo_id,
                    fecha_inicio: values.fecha_inicio,
                    fecha_conclusion: values.fecha_conclusion,
                    costo_presupuestado: values.costo_presupuestado,
                    costo_actual: values.costo_actual,
                    m2_totales: values.m2_totales,
                    m2_actuales: values.m2_actuales,

                })
                    .then(res => {
                        this.setState({ loading: false })
                        message.success('!Actividad Actualizada!');
                    })
                    .catch(error => {
                        this.setState({ loading: false })

                        console.log('error al guardar actividad', error);
                        message.error('!Actividad no ctualizada!');
                    })
                    .finally(f => {
                        this.props.closeMethod();
                    })
            }
            else {
                message.error('Ingresar M2 Planeados y Monto Presupuestado');
            }
        }

        else {
            message.error('Los m2 terminados no pueden ser mayores a los m2 planeados');
        }


    }

    /**
    * @memberof ModalTajo
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];

        if(array){ 
            array.forEach(function ({ _id, nombre }, index) {
                arr.push(<Option value={_id}> {nombre}</Option>)

            })
        }
        if(array && addElemnt){
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
        }



        return arr
    }

    render() {

        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-form-large modal-tajo"
                destroyOnClose={true}
                zIndex={1000}

            >

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} Tajo</Title>
                </div>
                <Spin spinning={this.state.loading}>

                    <Form
                        layout="vertical"
                        name="formulario-tajo"
                        onFinish={this.onFinish}
                        ref={this.formTajo}
                        initialValues={this.state.form}
                    >

                        <Row align="center" gutter={[24, 24]}>
                            <Col span={24}>
                                <Form.Item
                                    label="Tajo"
                                    name="tajo_id" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese un tajo"
                                    }]}
                                >

                                    <Select
                                        optionFilterProp="children"
                                        clearIcon={<IconArrowSelect />}
                                        showSearch
                                    >
                                        {this.selectOptions(this.state.tags, this.state.tajo_id)}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row align="center" gutter={[24, 24]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="M2 Planeados"
                                    name="m2_totales" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese los m2 planeados"
                                    }]}
                                >
                                    <InputNumber
                                        min={0}
                                        defaultValue={0}
                                        onChange={value => this.setState({ maxM2: value })} 
                                        precision={2}  />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="M2 Terminados"
                                    name="m2_actuales" //<--------------------------------------- verificar nombre
                                >
                                    <InputNumber
                                        defaultValue={0}
                                        min={0}
                                        max={this.state.maxM2}
                                        precision={2} />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row align="center" gutter={[24, 24]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Monto Presupuestado"
                                    name="costo_presupuestado" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el monto presupuestado"
                                    }]}
                                >
                                    <InputNumber
                                        min={0}
                                        defaultValue={0}
                                        onChange={value => this.setState({ maxMonto: value })}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        precision={2}
                                        />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Monto Actual Pagado"
                                    name="costo_actual" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el monto presupuestado"
                                    }]}
                                >
                                    <InputNumber
                                        defaultValue={0}
                                        min={0}
                                        max={this.state.maxMonto}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        precision={2}
                                        />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row align="center" gutter={[24, 24]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Fecha de inicio"
                                    name="fecha_inicio" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la Fecha de inicio"
                                    }]}
                                >
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Fecha de Finalizacion"
                                    name="fecha_conclusion" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la Fecha de Finalizacion"
                                    }]}
                                >
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col xs={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
