import React, { Component } from "react";
import { Row, Col, Button, Modal, Form, DatePicker, Typography,Input, InputNumber, Select, message, Spin } from 'antd';
import { IconCloseModal, IconEdit, IconDelete } from '../../Widgets/Iconos';



const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');
const { Title, } = Typography;


/**
 *
 *
 * @class ModalFacturas
 * @extends {Component}
 */
class ModalFacturas extends Component {

    defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            proveedores: [],
            proveedor: undefined,
            tipo_cambio: 0,
            disabled: false,

            clientes: {
				data: [],
				
				page: 1, 
				limit: 10,

				total: 0,
				pages: 0, 

				search: null
			},

            empresas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },

            proveedores: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
            proyectos: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
        }
    }

    ModalFacturas = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getClientes();
        this.getEmpresas();
        this.getTipoCambio();
        this.getProveedores()
        this.getProyectos();
        console.log(this.props)

        if (this.props.id !== undefined || this.props.id != null) {
            this.getFactura();
        }else if(this.props.cliente_id){
            this.ModalFacturas.current.setFieldsValue({
                cliente_id: this.props.cliente_id
            })
        }
    }

    /**
	 *
	 *
	 * @memberof ModalFacturas
	 * 
	 * @method getClientes
	 * @description Obtiene los Clientes
	 */
	getClientes = (search) => {
        axios.get('/clientes/list', {
            params: { page: 1, limit: 10, search }
        }).then(({ data }) => {
            console.log("get clientes response",data)
            this.setState(state => {
                state.clientes.data = data.data.itemsList
                state.clientes.total = data.data.itemCount
                state.clientes.page = data.data.currentPage

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
	 *
	 *
	 * @memberof ModalFacturas
	 * 
	 * @method getClientes
	 * @description Obtiene los Clientes
	 */
	getProveedores = (search) => {
        axios.get('/proveedores/list', {
            params: { page: 1, limit: 10, search }
        }).then(({ data }) => {
            console.log("get proveedores response",data)
            this.setState(state => {
                state.proveedores.data = data.data.itemsList
                state.proveedores.total = data.data.itemCount
                state.proveedores.page = data.data.currentPage

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
	 *
	 *
	 * @memberof ModalFacturas
	 * 
	 * @method getProyectos
	 * @description Obtiene los Clientes
	 */
	getProyectos = (search) => {
        axios.get('/proyectos', {
            params: { page: 1, limit: 10, search }
        }).then(({ data }) => {
            console.log("get proyectos response",data)
            this.setState(state => {
                state.proyectos.data = data.data.itemsList
                state.proyectos.total = data.data.itemCount
                state.proyectos.page = data.data.currentPage

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    getTipoCambio = () => {
        this.ModalFacturas.current.setFieldsValue({
            tipo_cambio: 19.50
        })
        this.setState({
            tipo_cambio: 19.50
        })
    }

    /**
     *
     * @memberof ModalFacturas
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
     getEmpresas = (search) => {
        axios.get('/empresas', {
            params: { limit:10, page:1, search }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ empresas: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(()=>this.setState({loading: false}));
    }

    /**
     * @memberof ModalFacturas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.id) {
            this.updateFactura(values)
        } else {
            this.addFactura(values)
        }
    }


    /**
     * @memberof ModalFacturas
     * @method getFactura
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    getFactura = () => {
        this.setState({ loading: true })

        axios.get(`/facturas/get`, {
            params: { 
                id: this.props.id,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(async response => {
            let factura = response.data.data

            console.log('factura', factura)

            this.ModalFacturas.current.setFieldsValue({
                concepto: factura.concepto,
                cliente_id: factura.cliente_id?._id,
                fecha: moment(factura.fecha),
                descripcion: factura.descripcion,
                tipo: factura.tipo,
                tipo_cambio: factura.tipo_cambio,
                monto: factura.monto,
                monto_dolar: factura.monto_dolar,
                proveedor_id: factura.proveedor_id,
                empresa_id: factura.empresa_id?._id,
                fecha_vigencia: moment(factura.fecha_vigencia),
                proyecto_id: factura.proyecto_id,
            })

            this.setState({
                proveedor: factura.proveedor_id,
                razon_social: factura.razon_social_id,
                orden: factura.orden_id,
                disabled: (!!factura.syncfy_id || factura.tipo_facturacion === 1)
            })

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la transaccion')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }


    /**
     * @memberof ModalFacturas
     * @method addFactura
     * @description Trae las cuentas 
     */
    addFactura = (values) => {
        console.log('values', values)

        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {

            axios.post('/factura/add', {
                ...values,
                monto_pesos: parseFloat(values.monto),
                monto_dolar: parseFloat(values.monto_dolar),
                tipo_cambio: parseFloat(values.tipo_cambio),
            }).then(response => {
                message.success('Factura creada')
                this.props.hideModal()
            }).catch(error => {
                console.log(error)
                message.error('Error al guardar')
            }).finally(() => this.setState({ loading: false }))
        })
        
    }

    /**
    * @memberof ModalFacturas
    * @method updateFactura
    * @description Actualiza la informacion de una factura
    */
    updateFactura = (values) => {
        this.setState({ loading: true })
        axios.post('/facturas/update', {
            ...values,
            monto: values.monto.toMoney(),
            monto_dolar: values.monto_dolar.toMoney(),
            tipo_cambio: values.tipo_cambio.toMoney(),
            factura_id: this.props.id
        }).then(response => {
            message.success('Transacción Actualizada')
            this.props.hideModal()
        }).catch(error => {
            console.log(error)
            message.error('Error al guardar')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalFacturas
     * @method onChangePesos
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares 
     * 
     */
    onChangePesos = (value) => {
        let tipo_cambio = this.ModalFacturas.current?.getFieldValue('tipo_cambio')
        this.ModalFacturas.current.setFieldsValue({
            monto_dolar: parseFloat(value / tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalFacturas
     * @method onChangeDolar
     * @description Hace la conversion de dolares a pesos para actualizar el campo de pesos 
     * 
     */
    onChangeDolar = (value) => {
        let tipo_cambio = this.ModalFacturas.current?.getFieldValue('tipo_cambio')
        this.ModalFacturas.current.setFieldsValue({
            monto_pesos: parseFloat(value * tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalFacturas
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        let monto_pesos = this.ModalFacturas.current?.getFieldValue('monto_pesos')
        this.ModalFacturas.current.setFieldsValue({
            monto_dolar: parseFloat(monto_pesos / value).toMoney()
        })
    }

    /**
    * @memberof ModalFacturas
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, razon_social, folio }, index) {
                arr.push(<Option value={_id}>{nombre}{razon_social} {folio}</Option>)
            })
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}{addElemnt.razon_social} {addElemnt.folio}</Option>)
            }
        }
        return arr
    }

    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalFacturas}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio,
                    tipo: 2,
                    ...(!disabled ? {estatus: 2} : null)
                }}

            >
                <Spin spinning={this.state.loading}>

                <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                /* rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]} */
                            >
                                <Input disabled={disabled} placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            // rules={[{
                            //     required: true,
                            //     message: "Por favor, ingrese una descipción"
                            // }]}
                            >
                                <Input placeholder="Descripción" ></Input>

                            </Form.Item>
                        </Col>
                        
                        
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                /* rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]} */
                            >
                                <Select
                                    placeholder="Seleccione al proveedor"
                                    showSearch
                                    onSearch={this.getProveedores}
                                    filterOption={false}
                                    disabled={disabled}
                                >
                                    {this.selectOptions(this.state.proveedores.data)}
                                </Select>
                            </Form.Item>
                        </Col>
                        

                        

                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Fecha de vigencia"
                                name="fecha_vigencia"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24}>
                            <Form.Item
                                label="Empresa"
                                name="empresa_id"
                                /* rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la razón social"
                                }]} */
                            >
                                <Select
                                    placeholder="Seleccione la razon social"
                                    showSearch
                                    onSearch={this.getEmpresas}
                                    filterOption={false}
                                    disabled={disabled}
                                >
                                    
                                    {this.selectOptions(this.state.empresas.data)}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Col xs={24} lg={11}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id"
                                /* rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]} */
                            >
                                <Select
                                    placeholder="Seleccione el proyecto"
                                    showSearch
                                    onSearch={this.getProyectos}
                                    filterOption={false}
                                    disabled={disabled}
                                >
                                    {this.selectOptions(this.state.proyectos.data)}
                                </Select>
                            </Form.Item>
                        </Col>

                    

                    <Row>
                        <Col xs={24} lg={6} >
                            <Form.Item
                                label="Tipo Cambio"
                                name="tipo_cambio"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber  min={1} defaultValue={0} className="width-100" onChange={this.onChangeTipo} disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8, push: 1 }} >
                            <Form.Item
                                label="Monto (Pesos)"
                                name="monto"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber disabled={false} min={0.01} defaultValue={0} className="width-100" decimalSeparator="," onChange={this.onChangePesos}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    />
                                
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8, push: 2 }} >
                            <Form.Item
                                label="Monto (Dolar)"
                                name="monto_dolar"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >   
                                <InputNumber disabled={false} min={0.01} defaultValue={0}  decimalSeparator="," className="width-100" onChange={this.onChangeDolar}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return (
        <Modal
            visible={props.visible}
            onCancel={props.hideModal}
            title={null}
            className="modal-form modal-proveedor"
            footer={null}
            closable={false}
            destroyOnClose={true}
            zIndex={1000}
        >
            <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={props.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{props?.id ? "Editar Facturas" : "Nueva Facturas"}</Title>
                </div>
            <ModalFacturas {...props} />
        </Modal>
    )

}