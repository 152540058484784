import React, { Component } from "react";

import { Avatar, Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Spin, Typography, Popconfirm } from 'antd';

import PropTypes from "prop-types";


//componentes
import { IconArrowSelect, IconCloseModal, IconDelete, IconEdit } from '../../../Widgets/Iconos';
import { MyUpload } from './../../../Widgets/Upload';
//css
import '../../../../Styles/finanzas.css'

import CustomAvatar from "../../../Widgets/Avatar/Avatar";

const moment = require('moment');
const axios = require('axios').default;
const { Option } = Select;
const { Title, } = Typography;

class ModalTraspasoDetalle extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false,
            edicion: false,
            dataAreas: [],
            dataRubros: [],
            dataSubrubros: [{ nombre: '--' }],
            dataProyectos: [],
            dataCuentas: [],
            cuentas_receptoras: [],
            /*Datos modal detalles*/
            det_concepto: '--',
            det_fecha: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
            det_proyecto: '--',
            det_rubro: '--',
            det_subrubro: '--',
            det_cuentaRem: '--',
            det_cuentaRec: '--',
            det_monto: '--',
            det_usuario: '--',
            det_usuarioNombre: '--',
            det_usuarioApellido: '--',
            det_comprobantes: [],
            editores: [],
            opciones: true

        }
    }

    //Referencia de form
    modalTraspaso = React.createRef();

    /**
     *
     *
     * @memberof ModalTraspaso
     * @description Se ejecuta al cambiar una propiedad o un estado de el componente desde la lista principal de Finanzas.
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    /**
     *
     *
     * @memberof ModalTraspaso
     * @method componentDidMount
     * @description Se ejecuta al finalizar de montar el modal.
     */
    componentDidUpdate() {

        console.log('Actualización');
        //Si se abre el modal y el editar esa desactivo, se activa para poder editar
        if (this.props.modalVisible && !this.state.updateChecker && !(this.props.registroId === null || this.props.registroId === undefined)) {

            console.log('Get Data');
            this.state.updateChecker = true;
            this.state.registroId = this.props.registroId;
            this.getTransaccionInfo()
        }

        //Si se cierra el modal y el editar esta acctivo, lo desactivamos para esperar un nuevo to update y limpia el modalcini.
        // if (!this.props.modalVisible && this.state.canEdit) {
        //     this.state.canEdit = false;
        //     this.state.form = {};
        //     this.state.tipo = null
        //     this.state.id = undefined
        //     this.setState({ update: !this.state.update })
        //     this.formModalCrearEmpleado.current.resetFields();
        //     this.loadEmpleadosList();
        // }

        //En caso de que sea nuevo, pues solo se limppia
        // if (!this.props.modalVisible && (this.props.id === null || this.props.id === undefined)) {
        //     this.state.form = {}
        // }


    }

    //Función para limpiar campos
    limpiarCamposModal = () => {
        if (this.modalTraspaso.current !==undefined) {
            this.modalTraspaso.current.setFieldsValue({
                nombre: '',
                numero: '',
                pais: '',
                estado: '',
                estatus: true
            });
        }

    }

    /**
     * @memberof ModalTraspaso
     * @method getTransaccionInfo
     * @description Obtiene la informacion de el traspaso para el modal de detalle. Actualiza el state
     * con informacion obtenida.
     */
    getTransaccionInfo = () => {
        
        this.setState({ spinning: true })

        axios.post('/transacciones/get', {
            id: this.props.registroId,
        }).then((transaccion) => {

            let data = transaccion.data.data;

            console.log('data', data);

            this.setState({
                datosConsultados: true,
                det_concepto: data.concepto,
                det_fecha: moment(data.fecha).format('MMMM Do YYYY '),
                det_proyecto: data.proyecto_id.nombre,
                det_rubro: data.rubro_id.nombre,
                det_subrubro: data.sub_rubro_id.nombre,
                det_cuentaRem: data.cuenta_id.nombre,
                det_cuentaRec: data?.cuenta_destino_id?.nombre,
                det_tipo: (data.tipo !==3) ? ((data.tipo === 1) ? 'Ingreso' : 'Egreso') : 'Traspaso',
                det_monto: data.monto,

                det_usuario: (data.usuario_id?.nombre + ' ' + data.usuario_id?.apellido),
                det_usuarioNombre: data.usuario_id?.nombre,
                det_usuarioApellido: data.usuario_id?.apellido,

                det_comprobantes: data.comprobantes,

                spinning: false
            })
        })

    }

    //Función que reinicializa los states y llama al método de cierre en componente padre
    hideModal = () => {
        //Reiniciar valores al cerrar modal
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false,
            edicion: false,
            det_concepto: '--',
            det_fecha: moment(new Date).format('MMMM Do YYYY'),
            det_proyecto: '--',
            det_rubro: '--',
            det_subrubro: '--',
            det_cuentaRem: '--',
            det_cuentaRec: '--',
            det_monto: '--',
        })
        this.state.updateChecker = false;
        this.props.closeMethod();
    }



    render() {
        const { modalVisible, accionModal, tipo_user, registroId } = this.props;
        const { editarTraspaso, eliminarTraspaso } = this.props;
        const { opciones } = this.state;

        if (accionModal === "detalle" && !this.state.datosConsultados && this.props.modalVisible) {
            this.getTransaccionInfo()
        }


        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className={(accionModal === "detalle") ? "modal-form modal-det" : "modal-form"}
                destroyOnClose={true}
                zIndex={1000}
            >


                <Spin spinning={this.state.spinning}>
                    <div className="modal-header">
                        <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                            <IconCloseModal />
                        </Button>
                        <Title level={3} className="modal-title">Detalle Traspaso</Title>

                    </div>

                    {(accionModal === "detalle" && opciones) ? <div className="modalEncOpciones" style={{ marginTop: "-60px" }}>
                        <Button type="" className="ant-btn button-survey btn-edit button-edit ant-btn-primary" title="Editar"
                            onClick={() => {
                                this.hideModal();
                                editarTraspaso(registroId)

                            }}>
                            <IconEdit />
                        </Button>
                        <Popconfirm placement="topRight" disabled={tipo_user !==1} title="¿Eliminar esta traspaso?" okText="Si, seguro" cancelText="No"
                            onConfirm={() => {
                                this.hideModal();
                                eliminarTraspaso(registroId)
                            }}>
                            <Button type="primary"
                                className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                title="Eliminar">
                                <IconDelete />
                            </Button>
                        </Popconfirm>
                    </div>
                        : null}




                    <Form
                        layout="vertical"
                        ref={this.modalTraspaso}
                        className="frm-transacciones"
                        name="formulario-transacciones"
                        onFinish={this.btnSaveClickModal}
                    >
                        <Row>
                            <Col xs={24} lg={11}>
                                <label className="lblTitulo">Concepto</label>
                                <p className="pInfo">{this.state.det_concepto}</p>
                            </Col>

                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Fecha</label>
                                <p className="pInfo">{this.state.det_fecha}</p>
                            </Col>
                        </Row>


                        <Row>
                            <Col xs={24} lg={11}>
                                <label className="lblTitulo">Proyecto</label>
                                <p className="pInfo">{this.state.det_proyecto}</p>
                            </Col>

                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Rubro</label>
                                <p className="pInfo">{this.state.det_rubro}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} lg={11}>
                                <label className="lblTitulo">Subrubro</label>
                                <p className="pInfo">{this.state.det_subrubro}</p>
                            </Col>
                            <Col xs={24} lg={{ span: 12, push: 1 }}>
                                <label className="lblTitulo">Cuenta Remitente</label>
                                <p className="pInfo">{this.state.det_cuentaRem}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} lg={11}>
                                <label className="lblTitulo">Cuenta Receptora</label>
                                <p className="pInfo">{this.state.det_cuentaRec}</p>

                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Monto</label>
                                <p className="pInfo">{this.state.det_monto}</p>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "" }}>
                            <Col span={24} className="readOnlyFiles">
                                <label className="lblTitulo">Comprobantes</label>
                                {this.state.det_comprobantes.map(comprobante => <a style={{ width: '100%', fontWeight: 'bold',display: 'block' }} href={axios.defaults.baseURL + '/voucher/' + comprobante} target="_blank" >{comprobante}</a>)}
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <span className="item-divider"></span>
                            <Col xs={24} lg={11}>
                                <div className="div-titulo">
                                    <h2 className="titulo-divCont">Autor</h2>
                                </div>
                                <Row className="row-transaccion">
                                    <Col>
                                        <Avatar />
                                    </Col>
                                    <Col>
                                        <h2>{this.state.det_usuario}</h2>
                                    </Col>
                                    <Col>
                                        <p>{this.state.det_fecha_creacion}</p>
                                    </Col>
                                </Row>
                            </Col>


                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <div className="div-titulo">
                                    <h2 className="titulo-divCont">Editores</h2>
                                </div>
                                {this.state.editores.map(({ nombre, fecha }, index) => (
                                    <Row className="row-transaccion">
                                        <Col>
                                            <CustomAvatar name={this.state.det_usuarioNombre, this.state.det_usuarioApellido} />
                                        </Col>
                                        <Col>
                                            <h2>{this.state.det_usuario}</h2>
                                        </Col>
                                        <Col>
                                            <p>{this.state.det_fecha_creacion}</p>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>

                        </Row>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

export default ModalTraspasoDetalle;
