import React, {Component} from 'react';
import {Button, Col, Row, Spin, Empty, Typography, List, Card, Popconfirm, Breadcrumb, Table, message, Pagination } from 'antd'
import {FaArrowRight} from "react-icons/fa";
import {Link} from 'react-router-dom';

//componentes
import {CardInfo} from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import {IconEdit, IconDelete, IconPlus, IconEye, IconCopy, IconGo, IconMaterialBtn} from '../../Widgets/Iconos';
//Modales
import ModalTajo from '../Modales/Manager/ModalTajo';
//css
import '../../../Styles/projectManager.css'

const axios = require('axios').default;
const {Text} = Typography;

export default class ActividadAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ModalTajoVisible: false,
            loadingData: false,
            loadingHeader: false,
            manager_actividad_id: this.props.match.params.manager_actividad_id,

            actividad: {
                actividad_id: {},
                manager_proyecto_id: {
                    proyecto_id: {}
                },
                inmueble_id: {},
                progreso_real: 0,
            },
            tajos: [],
            modalTitle: 'Crear',
            tajo_id: undefined, // campo _id de manager_tajos

            sumaTons: 0,
            costoActualAct: 0,
            presupuestoAct: 0,
            sumProgresoPonderadoTajos: 0,


            /*paginador*/
            page: 1,
            limit: 100,
            total: 0,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getActividad(this.state.manager_actividad_id);
        this.loadTajos(this.s)
    }


    /**
     * @memberof ActividadAdmin
     *
     * @method   hideModal
     * @description  Cambia state para cerrar cualquier modal
     *
     **/
    hideModal = () => {
        this.setState({ModalTajoVisible: false, tajo_id: undefined})
        this.loadTajos(this.state.page);
        this.getActividad(this.state.manager_actividad_id);
    }


    /**
     * @memberof ActividadAdmin
     * @method getActividad
     *
     * @description Obtiene la informacion de la actividad
     *
     */
    getActividad = (id) => {
        this.setState({loadingHeader: true})
        axios.get("/manager/proyects/inmuebles/actividades/get", {
            params: {
                id: id,
            }
        })
            .then(res => {
                let sumaTons = 0;
                res.data.data.materiales_requeridos.forEach(m=> sumaTons += m.cantidad)
                this.setState({
                    loadingHeader: false,
                    actividad: res.data.data,
                    manager_proyecto_id: res.data.data.manager_proyecto_id._id,
                    sumaTons,
                    //costoActualAct: res.data.extra.costoActualAct, suma de costo_actual de cada tajo, pero se quito
                    costoActualAct: res.data.extra.costoActualAct,
                    presupuestoAct: res.data.extra.presupuestoAct,
                })
            })
            .catch(e =>{
                    console.log('error actividad get', e)
                    message.error("No se pudo conseguir la informacion de la actividad")
                    this.setState({loadingHeader: false})
            })
    }

    /**
     * @memberof ActividadAdmin
     * @method loadTajos
     *
     * @description Obtiene los tajos de una actividad
     */
    loadTajos = (page) => {
        this.setState({loadingData: true})
        axios.get("/manager/proyectos/inmuebles/actividades/tajos/list", {
            params: {
                page: page,
                limit: this.state.limit,
                manager_actividad_id: this.state.manager_actividad_id,
            }
        })
            .then(res => {
                let sumProgresoPonderadoTajos = 0;
                res.data.data.itemsList.forEach(t=>sumProgresoPonderadoTajos += t.progreso_proyectado)
                this.setState({
                    loadingData: false,
                    tajos: res.data.data.itemsList,
                    page: res.data.data.paginator.currentPage,
                    total: res.data.data.paginator.itemCount,
                    sumProgresoPonderadoTajos,
                })
            })
            .catch(e =>{
                console.log('error actividad get', e)
                message.error("No se pudo conseguir la informacion de los tajos")
                this.setState({loadingData: false})

            })
    }

    /**
     * @memberof ActividadAdmin
     *
     * @method   deleteTajo
     * @description  Elimina una Actividad de Manager_actividades
     *
     *
     **/
    deleteTajo = (id) => {
        this.setState({loadingData: true})
        axios.delete('/manager/proyectos/inmuebles/actividades/tajos/delete', {
            params: {_id: id}
        })
            .then((res) => {
                this.loadTajos(this.state.page)
                this.getActividad(this.state.manager_actividad_id)
                message.success("!Se eliminó actividad!");
            })
            .catch(res => {
                message.error("Error, no se pudo eliminar la actividad");
                console.log("error", res);
                 this.setState({loadingData: false})
            });
    }


    render() {
        return (
            <div>


                <Spin spinning={this.state.loadingHeader}>
                    <Row className="row-encabezado">
                        <Col xs={24} className="flex-left">
                            <Breadcrumb className="breadcrumb" separator=">">
                                <Breadcrumb.Item>
                                    <Link to="/admin/manager">Proyectos</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={'/admin/manager/proyecto/' + this.state.actividad.manager_proyecto_id._id}>
                                        <Avatar
                                            name={this.state.actividad.manager_proyecto_id.proyecto_id.nombre}
                                            color={this.state.actividad.manager_proyecto_id.proyecto_id.color}
                                            image={this.state.actividad.manager_proyecto_id.proyecto_id.logo}
                                            className="mr-1"/>
                                        {this.state.actividad.manager_proyecto_id.proyecto_id.nombre}
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={"/admin/manager/proyecto/inmueble/" + this.state.actividad.manager_inmueble_id}>{this.state.actividad.inmueble_id.nombre}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrumb-actual">
                                    {this.state.actividad.actividad_id.nombre}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Spin>
                <Spin spinning={this.state.loadingData}>
                    <section className="p-1">

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo titleLeft="Actividad" infoLeft={this.state.actividad.actividad_id.nombre}
                                          titleRight="Materiales Presupuestados"
                                          infoRight={<Link to={"/admin/manager/proyecto/inmueble/actividad/material/"+ this.state.actividad._id}> {this.state.sumaTons} tons <IconGo/></Link>}/>
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Pondaracion Porcentual"
                                    infoLeft={<div>{this.state.sumProgresoPonderadoTajos?.toMoney(2)}% </div>}
                                    titleRight="Progreso Real"
                                    infoRight={`${this.state.actividad.progreso_real?.toMoney(2)} %`}/>
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Costo Actual Actividad"
                                    infoLeft={`$ ${this.state.costoActualAct.toMoney(2)} MXN`}
                                    titleRight="Presupuesto Actividad"
                                    infoRight={`$ ${this.state.presupuestoAct.toMoney(2)} MXN`}/>
                            </Col>
                        </Row>

                        <div className="scroll">
                            <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                                <Col span={5} className="center ">
                                    <Text type="secondary">Nombre</Text>
                                </Col>
                                <Col span={4} className="center ">
                                    <Text type="secondary">Metros Totales</Text>
                                </Col>
                                <Col span={4} className="center ">
                                    <Text type="secondary">Metros Trabajados</Text>
                                </Col>
                                <Col span={4} className="center ">
                                    <Text type="secondary">Costo Presupuestado</Text>
                                </Col>
                                 <Col span={4} className="center ">
                                    <Text type="secondary">Costo Actual</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text type="secondary">Acciones</Text>
                                </Col>
                            </Row>

                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.tajos}
                                renderItem={item => (
                                    <List.Item  className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{ width: '100%' }} className="">
                                                <Col span={5} className="center ">
                                                    <Text strong>{item.tajo_id.nombre}</Text>
                                                </Col>
                                                <Col span={4} className="center ">
                                                   <Text strong>{item.m2_totales} m2</Text>
                                                </Col>
                                                <Col span={4} className="center ">
                                                   <Text strong>{item.m2_actuales} m2</Text>
                                                </Col>
                                                <Col span={4} className="center ">
                                                    <Text>$ {item.costo_presupuestado?.toMoney(2)} MXN</Text>
                                                </Col>
                                                <Col span={4} className="center ">
                                                    <Text>$ {item.costo_actual?.toMoney(2)} MXN</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Button className="btn btn-edit" onClick={(e) => {
                                                        this.setState({ModalTajoVisible: true, modalTitle: 'Editar', tajo_id: item._id})
                                                    }}>
                                                        <IconEdit/>
                                                    </Button>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas Eliminar?"
                                                        onConfirm={e => {
                                                            this.deleteTajo(item._id)
                                                        }}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
                                                            <IconDelete/>
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </div>

                        <Row className="mt-2 pb-3">
                            <Pagination
                                total={this.state.total}
                                defaultPageSize={this.state.limit}
                                showSizeChanger={true}
                                onChange={(page,limit) => {
                                    this.state.limit = limit;
                                    this.loadTajos(page)
                                }}
                                // defaultCurrent={1}
                                // total={this.state.total}
                                // showSizeChanger={true}
                                //
                                //
                                //
                                //
                                // onChange={(page)=>{
                                //     this.loadTajos(page)
                                // }}
                            />
                        </Row>
                    </section>


                    <Button className="btnAddPlus"
                            onClick={() => this.setState({ModalTajoVisible: true, modalTitle: 'Crear'})}>
                        <IconPlus/>
                    </Button>
                </Spin>
                <ModalTajo
                    tajo_id={this.state.tajo_id}
                    modalTitle={this.state.modalTitle}
                    manager_proyecto_id={this.state.manager_proyecto_id}
                    manager_actividad_id={this.state.manager_actividad_id}
                    actividad_id={this.state.actividad.actividad_id._id}
                    modalVisible={this.state.ModalTajoVisible}
                    closeMethod={this.hideModal}/>
            </div>
        )
    }
}
