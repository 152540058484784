import React, { Component } from 'react';
import { Row, Col, Card, Button, } from 'antd'

import { Link } from 'react-router-dom';

import { IconPlus, IconMenuDot, IconBag, IconSales } from '../../Widgets/Iconos';

import './../../../Styles/proyecciones.css'

export default class Proyecciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            /* Paginado */
            itemCount: undefined,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        const pid = this.props.match.params.id;

        this.setState({
            loading: false,
        });
    }


    /**
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }

    /**
   * @description Abrir modal tipo edición para registro seleccionado desde tabla.
   * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
   */
    modalEditar = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;

        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisible: true,
        })
    }

    /**
     * @description Cerrar modal y limpiar valor registroId
    */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false,
        })
    }

    render() {
        return (
            <div>
                <Row className="row-encabezado">

                    <Col xs={24} xl={20} xxl={210}>
                        <h2>Proyecciones</h2>
                    </Col>
                </Row>

                <section>
                    <Row>
                        <Col xs={24} md={12} xl={8}>
                            <Card
                                title=""
                                loading={this.state.loading}
                                bordered
                                className="card-proyecciones"
                            >
                                <Row>
                                    <h4>Proyecciones</h4>
                                    <Button className="IconMenuDot" >
                                        <IconMenuDot className="IconMenuDot" />
                                    </Button>
                                </Row>

                                <Row>
                                    <h2>Compras</h2>
                                    <Button type="primary" className="btn-bag"  >
                                        <IconBag className="IconBag" />
                                    </Button>
                                </Row>

                            </Card>
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <Link to={"/admin/proyecciones/ventas"}>
                                <Card
                                    title=""
                                    loading={this.state.loading}
                                    bordered
                                    className="card-proyecciones"
                                    hoverable={true}
                                    style={{ cursor: 'pointer !important' }}
                                >
                                    <Row>
                                        <h4>Proyecciones</h4>
                                        <Button className="IconMenuDot" >
                                            <IconMenuDot className="IconMenuDot" />
                                        </Button>
                                    </Row>

                                    <Row>
                                        <h2>Ventas</h2>
                                        <Button type="primary" className="btn-sales" >
                                            <IconSales className="IconSales" />
                                        </Button>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </section>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear}>
                    <IconPlus />
                </Button>
            </div>
        )
    }
}
