import React, { Component } from 'react';
import { Button, Col, Row, message, Typography, List, Card, Pagination, Popover, Form, Select, Checkbox, Avatar, Space, Tag, Modal, Image } from 'antd'

//componentes
import { IconEdit, IconDelete, IconPlus } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';

import { DownOutlined } from '@ant-design/icons';


//
//Modales
import ModalMaterial from '../Modales/Material/ModalMateriales';
//css
import '../../../Styles/projectManager.css'
import qs from 'qs';

const axios = require('axios').default;
const { Text, Title } = Typography;


const { Option } = Select;

const IconText = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
);


export default class Material extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ModalMaterialVisible: false,
            filtroSearch: "",


            dataMateriales: [],
            loading: true,
            accionModal: 'crear',
            registroId: '',
            registroNombre: '',
            registro: {},
            loading: false,


            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,

            resize: true,

            tipos: [],

            tipo: {}
        }
    }

    //Referencia
    refModalMateriales = React.createRef();

    /**
    * @memberof Material
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se muestra el filtro superior
    * Se establece el valor del header en axios
    * Se llama al metodo receivedData que hace la consulta de materiales a mostrar en la tabla
    **/
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');



        if (this.props.match.params.id)
            this.state.tipo_id = this.props.match.params.id


        this.receivedData();
        this.getTipos()
        this.getTipo()
        window.addEventListener('resize', this.windowsResize);
        this.windowsResize()
    }


    /**
    * @memberof Material
    * @method   componentDidUpdate
    * @description  Al actualizar el componente:
    * Se hace una comparacion entre el valor del filtro superior y el filtro del state para
    * saber si se actualizó y se tiene que llamar el metodo de consulta de materiales receivedData 
    * con el nuevo valor del filtro
    **/
    componentDidUpdate() {

        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

 
        if (filtro !== propFiltro) {
            this.receivedData();
        }
    }

    windowsResize = () => {
        console.log('window.innerWidth', window.innerWidth);
        if (window.innerWidth < 576 && this.state.resize === true) {
            this.setState({ resize: false })
            console.log('this.setState({ resize: false })');
        } else if (window.innerWidth > 576 && this.state.resize === false) {
            this.setState({ resize: true })
            console.log('this.setState({ resize: true })');
        }
    }


    /**
    * @memberof Material
    * @method   receivedData
    * @description  
    * Metodo de consulta de materiales a mostrar, 
    * tiene los parametros:
    * número de la página a consultar, 
    * el numero de registros por página
    * el filtro del input del header
    * 
    * La consulta se visualiza en la tabla
    **/
    receivedData = async (
        {
            page = this.state.currentPage,
            size = this.state.perPage,
            tipo_id = this.state.tipo_id,
            tipos_ids = this.state.tipos_ids,
        } = {}
    ) => {

        this.setState({ loading: true, tipos_ids })
        var numPage = (page === undefined) ? 1 : page, numReg = 0;
        var search = this.props.search;

        this.setState({
            filtroSearch: search
        })

        axios.get('/materiales/list', {
            params: {
                page: page,
                limit: size,
                search: search,
                tipo_id,
                tipos_ids
            }
        })
            .then(res => {
                const data = res.data.data.itemsList;
                var dataL = data, data_list = [];

                if (numPage === null || numPage === 1) { numReg = 0 }
                else { numReg = ((numPage - 1) * 10) }

                for (let index = 0; index < dataL.length; index++) {
                    const element = dataL[index];

                    numReg = numReg + 1;

                    data_list.push({
                        _id: element._id,
                        nombre: element.nombre,
                        unidad: element.unidad,
                        detalles: element.detalles,
                        existencias: element.existencias,
                        tipo_id: element.tipo_id,
                        imagen: element.imagen,
                        index: numReg
                    });
                }
                var pag = res.data.data.paginator;

                this.setState({
                    dataMateriales: data_list,

                    loading: false,
                    currentPage: pag.currentPage,
                    itemCount: pag.itemCount,
                    perPage: pag.perPage,
                    pageCount: pag.pageCount,
                    filtroSearch: search
                })

            })
            .catch(res => {
                console.log("error", res);
            });
    }


    /**
    * @memberof Material
    *
    * @method   hideModal
    * @description  Cambia state para cerrar cualquier modal
    *
    **/
    hideModal = () => {
        this.setState({
            accionModal: "",
            registroId: '',
            registro: {},
            registroNombre: '',
            ModalMaterialVisible: false
        });
        this.receivedData();
    }


    /**
    * @memberof Material
    * @method   modalCrear
    * @description Abrir modal tipo creación para nuevo registro.
    *
    **/
    modalCrear = () => {
        this.setState({
            registroId: null,
            ModalMaterialVisible: true,

        })
    }



    /**
    * @memberof Material
    * @method   modalEditar
    * @description Abrir modal tipo edición para registro seleccionado desde tabla.
    * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
    */
    modalEditar = (id, item) => {

        this.setState({
            registroId: id,
            ModalMaterialVisible: true,
        })
    }


    /**
     * @memberof Material
     * @method   deleteMaterial
     * @description  Manda llamar el método para eliminar un material por su id
     * @param id Id del registro a eliminar
     **/
    deleteMaterial = (id) => {

        axios.post('/materiales/delete', { params: { id } })
            .then((res) => {
                this.receivedData()
                message.success("¡Se eliminó Material!");
            })

            .catch(error => {
                Modal.error({
                    title: "No es posible eliminar",
                    content: error.response?.data?.error
                })

            });
    }


    /**
     *
     *
     * @param {*} search
     * @memberof Material
     * 
     * @description Obtemenmos los tipos
     */
    getTipos = (search) => {
        axios.get('/tipos/list', {
            params: {
                paginate: false
            }
        }).then(response => {
            this.setState({ tipos: response.data.data })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })
    }

    getTipo = () => {
        axios.get('/tipos/get', {
            params: {
                id: this.state.tipo_id
            }
        })
            .then(({ data }) => {
                // console.log('XXXX', data);
                this.setState({ tipo: data.data })
            })

    }


    render() {
        return (
            <div>
                <Form
                    onValuesChange={(valuesChanged, allValues) => {

                        this.receivedData({ tipos_ids: allValues.tipos_ids })
                        // tipos_ids
                    }}
                >
                    <Row className="row-encabezado">
                        <Col xs={12} xl={20} xxl={21} className="flex-left">
                             {/* <Title level={4} className="view-title">Lista de Materiales {(this.state.tipo.nombre) ? [" de tipo ", <strong style={{ textDecoration: "underline" }}>{this.state.tipo.nombre}</strong>] : null} </Title>  */}
                             <Title level={4} className="view-title">Tipo de materiales </Title> 

                        </Col>

                        {(this.state.tipo_id === null || this.state.tipo_id === undefined) ? <Col xs={12} xl={20} xxl={3}>
                            <Popover
                                content={
                                    <Form.Item name="tipos_ids" className="popover-filter">
                                        <Checkbox.Group>
                                            <Row>
                                                {this.state.tipos.map(({ _id, nombre }) => <Col span={24}>
                                                    <Checkbox value={_id} style={{ lineHeight: '32px' }}> {nombre} </Checkbox>
                                                </Col>)}
                                            </Row>
                                        </Checkbox.Group>

                                    </Form.Item>
                                }
                                placement="bottom"
                                title="Tipos"
                            >
                                <span className="filter-element"> Tipo<DownOutlined /></span>
                            </Popover>

                        </Col>
                            : null}

                    </Row>
                </Form>

                <Row>
                    <Col span={24}>
                        <Card
                            title=""
                            loading={this.state.loading}
                            bordered
                            className="card-proyeccionesDetalle"
                        >
                            <Row>
                                <h1>{this.state.tipo.nombre}</h1>
                            </Row>

                            <Row>
                                <Col xs={24} lg={14} >
                                    <label className="lblTitulo">Detalles</label>
                                    <p className="pInfo">{this.state.tipo.detalles}</p>
                                </Col>
                                <Col xs={24} lg={6} >
                                    <label className="lblTitulo">Unidad</label>
                                    <p className="pInfo"> {(this.state.tipo.unidad !== "" && this.state.tipo.unidad !== undefined) ? (this.state.tipo.unidad === 0) ? "Piezas" : "Toneladas" : ""} </p>

                                </Col>
                                <Col xs={24} lg={4} >
                                    <img
                                        style={{ maxWidth: "90%", padding: "1px" }}
                                        src={(this.state.tipo.imagen !== "" && this.state.tipo.imagen !== undefined) ? `${axios.defaults.baseURL}/upload/${this.state.tipo.imagen}` : ""}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>


                <section className="p-1">
                    <Row className="card-proyeccionesDetalle">
                        <h1>Materiales</h1>
                    </Row>
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.dataMateriales}
                        header={<Row className="header-list" style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>

                            <Col span={2} className="gutter-row center">
                                <Text className="ml-2" strong>#</Text>
                            </Col>

                            <Col xs={12} className="gutter-row center">
                                <Text strong>Nombre</Text>
                            </Col>

                            <Col xs={6} className="gutter-row  center">
                                <Text strong>Existencias</Text>
                            </Col>

                            <Col xs={4} className="gutter-row center">
                                <Text strong>ACCIONES</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={item => (
                            (this.state.resize === true) ?
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row style={{ width: '100%' }} className="">

                                            <Col span={2} className="center">
                                                <Text strong>{item.index}</Text>
                                            </Col>

                                            <Col xs={12} className="center">
                                                <Text strong>{item.nombre}</Text>
                                            </Col>

                                            <Col xs={6} className="center">
                                                <Text strong>{(item.existencias) ? (item.existencias).toMoney() : 0}</Text>
                                            </Col>

                                            <Col xs={4} className="center">
                                                <Button className="btn btn-edit" title="Editar" onClick={(e) => this.modalEditar(item._id, item)}>
                                                    <IconEdit />
                                                </Button>

                                                <Button className="btn btn-delete" title="Editar" onClick={(e) => this.deleteMaterial(item._id, item)}>
                                                    <IconDelete />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                                :
                                <Card className="card-list mb-1">
                                    <List.Item className="component-list-item"
                                        key={item._id}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar size="large" src={`${axios.defaults.baseURL}/upload/${item.imagen}`} />}
                                            title={item.nombre}
                                            description={item.detalles}
                                        />
                                        <Button className="btn btn-edit" title="Editar" >
                                            <IconEdit />
                                        </Button>
                                    </List.Item>
                                    <p style={{ textAlign: 'start' }}>
                                        <Text><strong>Unidad:</strong> {(item.unidad === 0) ? "Piezas" : "Toneladas"}</Text>
                                        <br />
                                        <Text><strong>Tipo:</strong> {item.tipo_id?.nombre}</Text>
                                        <Text><Tag style={{ fontSize: 14, position: 'absolute', right: 0 }}> {item?.existencias} {(item?.unidad === 0) ? "piezas." : "tons"}</Tag></Text>
                                    </p>
                                </Card>
                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.currentPage}
                            total={this.state.itemCount}
                            pageSize={this.state.perPage}
                            showSizeChanger={false}
                            onChange={(page) => {
                                this.receivedData({ page })
                            }}
                        />
                    </Row>

                </section>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear}>
                    <IconPlus />
                </Button>

                <ModalMaterial
                    visible={this.state.ModalMaterialVisible}
                    materialId={this.state.registroId}

                    tipoId={this.state.tipo_id}
                    accionModal={this.state.accionModal}
                    onClose={() => {
                        this.receivedData();
                        this.hideModal();
                    }}
                    onSuccess={() => {
                        this.receivedData();
                        this.hideModal();
                    }}
                />

            </div>
        )
    }
}
