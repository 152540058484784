import React, { Component, createRef } from "react";
import { Avatar, Button, Col, DatePicker, Form, Input, InputNumber, List, message, Modal, Row, Select, Spin, Statistic, Typography, Upload } from 'antd';
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
//componentes
import { IconArrowSelect, IconCloseModal, IconTemplate } from '../../../Widgets/Iconos';
import DropzoneAntDesign from './../../../Widgets/Documents/Documents';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { MyUpload } from './../../../Widgets/Upload';
//css
import './../../../../Styles/modales.css'


const moment = require('moment');
const axios = require('axios').default;
const { RangePicker } = DatePicker;
moment.locale('es');
const { Option } = Select;
const { Title, } = Typography;
var contConsulta = 0;

export default class ModalTranCliente extends Component {


    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            accionModal: '',
            datosConsultados: false,

            return: false,
            edicion: false,
            dataDetalleCliente: [],

            dataCuentas: [],
            det_concepto: '',
            det_fecha: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
            det_comprobantes: [],
            loading: false,
            update: false,
            titulo: "",
        }
    }

    //Referencia de form dentro del modal.
    modalTransaccion = createRef();
    refDocumentos = React.createRef();


    /**
     * @memberof ModalTran
     * @method componentDidMount
     * @description Se ejecuta al finalizar el render de la vista.
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadCuentas()
    }


    /**
     * @memberof componentDidUpdate
     * @description Obtiene la informacion de una transaccion si se va a editar o visualizar
     */
    componentDidUpdate(prevProps) {
        if(this.props.transaccion_id !== undefined && prevProps.transaccion_id !== this.props.transaccion_id){
            this.getTransaccion(this.props.transaccion_id)
        }
    }

    /**
     * @memberof ModalTranCliente
     * @method getTransaccion
     * @description obtiene la informacion de una transaccion
     */
    getTransaccion = (id) => {
        axios.post('/transacciones/get', {
            id
        }).then((transaccion) => {
            let data = transaccion.data.data;
            this.setState({
                edicion: true,
                datosConsultados: true,
            })
            this.modalTransaccion.current.setFieldsValue({
                concepto: data.concepto,
                cuenta: data.cuenta_id?._id,
                monto: data.monto,
                proyecto: data.proyecto_id._id,
                rubro: data.rubro_id._id,
                subrubro: data.sub_rubro_id.nombre,
                tipo: data.tipo,
                docs: data.comprobantes,
                fecha: moment(data.fecha, 'DD-MM-YYYY')
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }

    /**
     * @memberof ModalTranCliente
     * @method onFinish
     * @description Guarda una transaccion segun sea el caso
     */
    onFinish = values => {
        const { accionModal, propiedad } = this.props;

        if (accionModal === "crear") {
            this.addTransaccion(values)
        }
        else if (accionModal === "especie") {

            axios.post('/propiedad/add/transaccion', {
                propiedad_id: propiedad._id,
                concepto: values.concepto,
                descripcion: values.descripcion,
                monto: values.monto,
                fecha: values.fecha,

                proyecto_id: propiedad.proyecto_id._id,
                usuario_id: propiedad.asesor_id._id,
                area_id: propiedad.area_id._id,
                rubro_id: propiedad.rubro_id._id,
                sub_rubro_id: propiedad.subrubro_id._id,
                tipo: 1,
                tipo_cliente: 'Especie',
                comprobantes: (Array.isArray(values.documentos)) ? values.documentos.map(comprobante => comprobante.response.filename) : [],
            }).then((res) => {
                message.success("Transaccion Registrada!");
                this.props.hideModal()
            }).catch((error) => {
                console.log(error)
                message.error('Error al registrar la transaccion')
            });
        }

    }

    /**
     * @memberof ModalTranCliente
     * @method receivedData
     * @description Crea una nueva transaccion y la relaciona con una propiedad
     */
    addTransaccion = (values) => {
        const { propiedad } = this.props;

        axios.post('/propiedad/add/transaccion', {
            propiedad_id: propiedad._id,
            concepto: values.concepto,
            cuenta_id: values.cuenta,
            monto: values.monto,
            fecha: values.fecha,
            proyecto_id: propiedad.proyecto_id._id,
            usuario_id: propiedad.asesor_id._id,
            area_id: propiedad.area_id._id,
            rubro_id: propiedad.rubro_id._id,
            sub_rubro_id: propiedad.subrubro_id._id,
            tipo: 1,
            tipo_cliente: 'Transaccion',
            comprobantes: (Array.isArray(values.documentos)) ? values.documentos.map(comprobante => comprobante.name) : [],
        }).then((res) => {
            message.success("Transaccion Registrada!");
            this.props.hideModal()
        }).catch((error) => {
            console.log(error)
            message.error('Error al registrar la transaccion')
        });
    }

     /**
     *
     * @memberof ModalProveedor
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
                fileList[x].url = `${axios.defaults.baseURL}/voucher/${fileList[x].response.filename}`
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };

    /**
     *
     * @memberof ModalProveedor
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        const { accionModal, area_id } = this.props;
        axios.post("/voucher/delete", {
            filename: image
        }).then(res => {
            console.log("imagen removida con exito", res);
        }).catch(res => {
            console.log("imagen no se puedo remover", res);
        })
    };

    /**
     * @memberof ModalTranCliente
     * @method saveDocumentos
     * @description Guarada los documentos en la propiedad
     */
    saveDocumentos = async (values) => {

        console.log(values)

        axios.post('/clientes/propiedad/update', {
            documentos: values.documentos.map(doc => doc.name),
            propiedad_id: this.props.propiedad._id,
        })
        .then((e) => {
            message.success("Documentos Guardados!");
            this.props.hideModal()    
        }).catch(error=> {
            console.log(error)
            message.error('Error al guardar')    
        })

    }


    /*-----------------------------------------------------------------------------------------------------*/




    getComprobantes = () => {

        console.log('Consulta comprobantes');
        contConsulta++;

        axios.post('/clientes/get', {
            id: this.props.clienteId,
            proyectos: ''
        }).then(async (cliente) => {
            let data = cliente.data.data;

            this.state.det_comprobantes = data.documentos
            
            this.setState({
                det_comprobantes: data.documentos,
                datosConsultados: true,
            })
            this.refDocumentos.current.resetFields()

        })
    }


    //Función para limpiar campos
    limpiarCamposModal = () => {
        if (this.ModalTran.current !==undefined) {
            this.ModalTran.current.setFieldsValue({});
        }

    }



    /**
     * @memberof ModalTranCliente
     * @method hideModal
     * @description Cierra el modal
     */
    hideModal = () => {
        this.props.hideModal();
    }


    /**
     * @memberof ModalTran
     * @method loadCuentas
     * @description Obtiene la informacion de las cuentas registradas en la base de datos
     *
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200
            }
        }).then((cuentas) => {
            this.setState({
                dataCuentas: cuentas.data.data.itemsList
            })
        }).catch((error) => {
        })
    }


   



    



    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/admin/clientes" />;
        }
    };


    eliminarDocumento = async (doc, index) => {
        console.log("Eliminar archivo")
        console.log(doc)
        console.log(index)


        var docs = this.refDocumentos.current.getFieldValue('documentos');


        let filesTemp = docs;
        filesTemp.splice(index, 1);
        
        console.log(filesTemp)
        
            axios.put('/clientes/update', {
                documentos: filesTemp.map(doc => doc.name),
                id: this.state.dataDetalleCliente._id
            })
                .then((e) => {
                    this.removeFile(doc.name)
                    this.hideModal();
                    this.props.accept();
                })
                .then(f => {
                })
    };


    render() {

        const { modalVisible, accionModal } = this.props;
        const { modalTransaccion, normFile } = this;


        var titulo = '';
        if (accionModal === "crear") {
            titulo = "Nueva  Transacción"

        } else if (accionModal === "especie") {
            titulo = "Nuevo Pago en Especie"

        } else if (accionModal === "documentos") {
            titulo = "Documentos"
        }

        var contenido = "";

        if (accionModal === "crear" || accionModal === "editar") {
            contenido = <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={modalTransaccion}
                    className="frm-transacciones"
                    name="formulario-transacciones"
                    onFinish={this.onFinish}
                >
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                initialValue={(!this.state.edicion) ? moment(new Date, 'DD/MM/YYYY') : ''}
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker showTime disabled={this.state.edicion}
                                    defaultValue={(!this.state.edicion) ? moment(new Date, 'DD/MM/YYYY') : ''} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ margin: '0' }}>
                        <Col xs={24} lg={11} style={{ margin: '0' }}>
                            <Form.Item
                                label="Monto"
                                name="monto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={0} defaultValue={0} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                            <Form.Item
                                label="Cuenta"
                                name="cuenta"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <Select placeholder="Seleccione cuenta" disabled={this.state.edicion}>
                                    {this.state.dataCuentas.map(function ({ _id, nombre, logo, color }, index) {
                                        return <Option value={_id}>
                                            <CustomAvatar
                                                image={logo}
                                                name={nombre}
                                                // color={color}
                                                size="small"
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            />
                                            {nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={22}>
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                               <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    multiple={false}
                                >
                                    <Button>
                                        <UploadOutlined /> Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul"
                                    disabled={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        }


        if (accionModal === "especie") {
            contenido = <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={modalTransaccion}
                    className="frm-transacciones"
                    name="formulario-transacciones"
                    onFinish={this.onFinish}

                >
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                initialValue={(!this.state.edicion) ? moment(new Date, 'DD/MM/YYYY') : ''}
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker showTime disabled={this.state.edicion}
                                    defaultValue={(!this.state.edicion) ? moment(new Date, 'DD/MM/YYYY') : ''} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ margin: '0' }}>
                        <Col xs={24} lg={11} style={{ margin: '0' }}>
                            <Form.Item
                                label="Monto"
                                name="monto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={0} defaultValue={0} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese descripción"
                                }]}
                            >
                                <Input className="txt-descripcion" style={{ height: '100px !important' }} />
                            </Form.Item >

                        </Col>
                    </Row>



                    <Row style={{ textAlign: "center" }}>
                        <Col span={22}>
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                               <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    multiple={false}
                                >
                                    <Button>
                                        <UploadOutlined /> Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul"
                                    disabled={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        }


        if (accionModal === "documentos") {
            contenido = <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={this.refDocumentos}
                    className="frm-transacciones"
                    name="formulario-transacciones"
                    //getValueFromEvent={normFile}
                    onFinish={this.saveDocumentos}
                    initialValues={{
                        documentos: this.props.propiedad.documentos.map(file => {
                            return {
                                lastModified: new Date().getMilliseconds(),
                                lastModifiedDate: new Date(),
                                name: file,
                                url: axios.defaults.baseURL + '/voucher/' + file,
                                status: 'start',
                                percent: 0,
                                //accionDelete : this.eliminarDocumento(file),
                                deleteFileEvent: (e, index) => {
                                    this.eliminarDocumento(e, index);

                                },
                            }
                        })
                    }}
                >
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                name="documentos"
                                valuePropName="fileList"
                            >
                                <DropzoneAntDesign
                                    type="list"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul"
                                    disabled={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        }

        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className={(accionModal === "detalle") ? "modal-form modal-det" : "modal-form"}
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{(this.state.det_concepto !== "") ? this.state.det_concepto : titulo}</Title>
                </div>
                {contenido}
            </Modal>
        )
    }
}
