import React, { Component } from "react";
import { Layout, PageHeader, Button, Col, Row, message, Dropdown, Form, Typography, Tabs, Space } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined, BarChartOutlined } from '@ant-design/icons'

//componentes
import { IconArrowBack, } from '../../../Widgets/Iconos';
import AlmacenesList from './Listas/AlmacenesList'
import ProductosList from './Listas/ProductosList'
import RequisicionesList from './Listas/RequisicionesList'
import CustomAvatar from "./../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/proyectos.css'


const { Content } = Layout;
const { Title } = Typography
const { TabPane } = Tabs;
const axios = require('axios')
/**
 *
 *
 * @export
 * @class ProyectoDetalles
 * @extends {Component}
 * @description Vista que permite visualizar de forma general el inventario del Proyecto
 */
export default class ProyectoDetalles extends Component {

    volver = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            proyecto_id : this.props.match.params.proyecto_id,
            proyecto: {},
            almacenes: 0,
            productos: 0,
        }
    }

    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getProyecto()
    }


    /**
    * @memberof Almacenes
    * @method getProyecto
    * @description  Obtiene la informacion del proyecto
    **/
    getProyecto = () => {
        axios.get('/proyectos/get',{
            params:{
                id: this.props.match.params.proyecto_id,
                almacenes: true
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                proyecto: response.data.data,
                almacenes: response.data.inventario.almacenes,
                productos: response.data.inventario.productos,
            })
        }).catch(error => {
            message.error('Error al obtener la información del proyecto')
        })
    }

    render() {

        const { proyecto } = this.state

        return (
            <>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <Title level={4} className="view-title">Detalle de {this.state.proyecto.nombre}</Title>
                    </Col>
                </Row>
                <Content className="p-1">
                    <Row gutter={[16,16]} className="mb-1">
                        <Col xs={24} md={8} className="">
                            <div className="card">
                                <div style={{display: 'flex', minHeight: '89px', display: 'flex', alignItems: 'center'}} >
                                    <CustomAvatar
                                        image={proyecto.logo}
                                        color={proyecto.color}
                                        name={proyecto.nombre}
                                        size="large"
                                    />
                                    <Title level={4} style={{marginTop: '8px', marginLeft: '3px'}}>{this.state.proyecto.nombre}</Title>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={8} className="">
                            <div className="card">
                                <div className="flex-column">
                                    <Title level={3}>Almacenes</Title>
                                    <Title level={4} style={{marginTop: '8px', marginLeft: '3px'}}>{this.state.almacenes}</Title>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={8} className="">
                            <div className="card">
                                <div className="flex-column">
                                    <Title level={3}>Productos</Title>
                                    <Title level={4} style={{marginTop: '8px', marginLeft: '3px'}}>{this.state.productos}</Title>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>

                        <Col span={24}>
                            <Tabs 
                                defaultActiveKey="Almacenes" 
                                destroyInactiveTabPane={true} 
                                className="tabs-inventario" 
                                id="tab-inventario"
                                onChange={tabStatus => this.setState({ tabStatus })}
                            >

                                <TabPane tab="Almacenes" key="Almacenes">
                                    <AlmacenesList proyecto_id={this.state.proyecto_id} getProyecto={this.getProyecto}/>
                                </TabPane>
                                
                                <TabPane tab="Productos" key="Productos">
                                   <ProductosList proyecto_id={this.state.proyecto_id} getProyecto={this.getProyecto}/>
                                </TabPane>

                                <TabPane tab="Requisiciones" key="Requisiciones">
                                    <RequisicionesList proyecto_id={this.state.proyecto_id} getProyecto={this.getProyecto}/>
                                </TabPane>

                                
                            </Tabs>
                        </Col>
                    </Row>
                </Content>
            </>
        )
    }
}