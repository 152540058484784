import React, { Component } from 'react';
import { Button, Col, Row, Spin, Empty, Typography, List, Card, Popconfirm, Breadcrumb, message,  Pagination } from 'antd'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

//componentes
import { CardInfo } from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { IconEdit, IconDelete, IconPlus, IconEye, IconCopy, IconGo } from '../../Widgets/Iconos';
//Modales
import ModalObra from '../Modales/Inmuebles/ModalObra';
import ModalCopiarObra from '../Modales/Inmuebles/CopiarInmueble';
//css
import '../../../Styles/projectManager.css'

const moment = require('moment');
const axios = require('axios').default;
const { Text } = Typography;

export default class ProjectAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalObraVisible: false,
            ModalCopyObraVisible:false,
            filtroSearch: "",

            //inmuebles
            dataObras: [],

            //inmuebles tags list
            dataObra:[],
            loadingProject: false,
            loading: false,
            accionModal: 'crear',
            registroId: '',
            registroNombre: '',

            proyecto_id: "",
            proyecto_nombre: "",
            proyecto_color: "",
            proyecto_descripcion: "",
            proyecto_logo: null,
            proyecto_privado: false,
            proyecto_createdAt: "",
            proyecto_updatedAt: "",


            pm_costo_actual: "",
            pm_costo_presupuestado: "",
            pm_m2_actuales: "",
            pm_m2_totales: "",
            pm_materiales_entregados: "",
            pm_materiales_presupuestados: "",
            pm_progreso_proyectado: 0,
            pm_progreso_real: 0,
            pm_id: "",

            /* Paginado */
            itemCount: 0,
            perPage: 100,
            pageCount: 1,
            currentPage: 1,


        }
    }

    refModalObras = React.createRef();


    /**
     * @memberof ProjectAdmin
     *
     * @method   componentDidMount
     * @description  
     *
    **/
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProject();

    }


    /**
     * @memberof ProjectAdmin
     *
     * @method   componentDidUpdate
     * @description Si se realiza una un busqueda por la barra, actuliza el contenido
     *
    **/
    componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro) {
            this.getInmuebles();
        }
    }

    /**
     * @memberof ProjectAdmin
     *
     * @method   getProject
     * @description Trae la infomecion de un proyecto por medio de su id
     *
    **/
    getProject = async () => {
        this.setState({loadingProject: true})
        var id = this.props.match.params.id;
        axios.get('/manager/proyecto', {
            params: {
                id: id
            }
        })
            .then(async ({ data }) => {
                let dataproyect = data.data.proyecto_id;
                let datapm = data.data;

                if (data.success) {
                    this.setState({
                        proyecto_id: dataproyect._id,
                        proyecto_nombre: dataproyect.nombre,
                        proyecto_color: dataproyect.color,
                        proyecto_descripcion: dataproyect.descripcion,
                        proyecto_logo: dataproyect.logo,
                        proyecto_privado: dataproyect.privado,
                        proyecto_createdAt: dataproyect.createdAt,
                        proyecto_updatedAt: dataproyect.updatedAt,


                        pm_costo_presupuestado: datapm.costo_presupuestado,
                        pm_fecha_conclusion: datapm.fecha_conclusion,
                        pm_fecha_inicio: datapm.fecha_inicio,
                        pm_m2_actuales: datapm.m2_actuales,
                        pm_m2_totales: datapm.m2_totales,
                        pm_materiales_entregados: datapm.materiales_entregados,
                        pm_materiales_presupuestados: datapm.materiales_presupuestados,
                        pm_progreso_proyectado: datapm.progreso_proyectado,
                        pm_progreso_real: datapm.progreso_real,
                        pm_id: datapm._id,

                        loadingProject: false,
                    })
                    await this.getInmuebles();
                }
                else {
                    this.setState({
                        proyecto_id: "[id]",
                        proyecto_nombre: "[proyecto]",
                        proyecto_color: "[color]",
                        proyecto_descripcion: "[descripcion]",
                        proyecto_logo: null,
                        proyecto_privado: false,
                        proyecto_createdAt: "",
                        proyecto_updatedAt: "",
                    })
                }

            })
            .catch(res => {
                console.log("no obtenida", res);
            })
    }


    /**
     * @memberof ProjectAdmin
     *
     * @method   getInmuebles
     * @description Trae la lista paginada de los inmuebles que pertenecen al previamente cargado proyecto
     *
    **/
    getInmuebles = async (page = this.state.currentPage, size = this.state.perPage) => {
        this.setState({ loading: true });
        var search = this.props.search;
        var proyectoID = this.state.proyecto_id;
        var managerProyectoID = this.state.pm_id;

        axios.post('/manager/proyecto/inmuebles', {
            page: page,
            limit: size,
            search: search,
            proyecto_id: proyectoID,
            manager_proyecto_id: managerProyectoID
        })
            .then(res => {
                const data = res.data.data.itemsList;
                const paginator = res.data.data.paginator;
                this.setState({
                    dataObras: data,
                    currentPage: paginator.currentPage,
                    itemCount: paginator.itemCount,
                    perPage: paginator.perPage,
                    pageCount: paginator.pageCount,
                    filtroSearch: search,
                    pm_progreso_proyectado: res.data.extra.sumaProgresoProyectado
                })

            })
            .catch(res => {
                console.log("error inmuebles list", res);
            })
            .finally(f => {
                this.setState({ loading: false });
            });
    }

    /**
    * @memberof ProjectAdmin
    *
    * @method   hideModal
    * @description  Cambia state para cerrar cualquier modal
    *
    **/
    hideModal = () => {
        this.setState({
            modalObraVisible: false,
            accionModal: "crear",
            registroId: '',
            registroNombre: '',
        })
    }

    /**
    * @memberof ProjectAdmin
    *
    * @method   modalCrear
    * @description  Cambia el state para abrir el modal de creacion
    *
    **/
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            registroId: '',
            registroNombre: '',
            modalObraVisible: true,

        })
    }


    /**
    * @memberof ProjectAdmin
    *
    * @method   modalCrear
    * @description  Cambia el state para abrir el modal de Edicion
    *
    **/
    modalEditar = (id, nombre) => {
        this.setState({
            accionModal: 'editar',
            registroId: id,
            registroNombre: nombre,
            modalObraVisible: true,
        })
    }

     /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function showModalCopyGroup Abre el modal de copia. Copiamos la copia.
     * @param e (Event) currentTarget.id => valor del inmueble_manager seleccionado
     * @description  Cambia el state para abrir el modal de copiar una obre (inmueble)
     */
    ModalCopyObra = (e) => {
        this.setState({ ModalCopyObraVisible: true, Obra_toCopy: e.currentTarget.id })
    }

    /**
    * @memberof ProjectAdmin
    *
    * @method   hideModalCopy
    * @description  Cambia state para cerrar el modal de copiar obra
    *
    **/
   hideModalCopy=()=>{
        this.setState({
            ModalCopyObraVisible: false
        })
        this.getProject();
    }

    /**
     *
     * @memberof ProjectAdmin
     * @method deleteObra
     * @description Elimina un inmueble del proyecto
     */
    deleteObra = (id) => {
        axios.post('/manager/proyectos/inmuebles/delete', { params: { id } })
            .then((res) => {
                message.success("Se eliminó Inmueble!");
                this.getProject();
                this.hideModal();
            })

            .catch(res => {
                console.log("error", res);
                message.error("Error, no se pudo eliminar inmueble");
            });
    }


    render() {
        return (
            <div>
                <Spin spinning={this.state.loadingProject}>
                    <Row className="row-encabezado">
                        <Col xs={24} className="flex-left">
                            <Breadcrumb className="breadcrumb" separator=">">
                                <Breadcrumb.Item>
                                    <Link to="/admin/manager">Proyectos</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrumb-actual">
                                    <CustomAvatar
                                        image={this.state.proyecto_logo}
                                        name={this.state.proyecto_nombre}
                                        color={this.state.proyecto_color}
                                        className=""
                                        style={{marginRight:"5px"}}/>
                                        {this.state.proyecto_nombre}
                                </Breadcrumb.Item>

                            </Breadcrumb>
                        </Col>
                    </Row>

                    <section className="p-1">

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Proyecto"
                                    infoLeft={this.state.proyecto_nombre}
                                    infoRight={
                                        <CustomAvatar
                                            image={this.state.proyecto_logo}
                                            name={this.state.proyecto_nombre}
                                            color={this.state.proyecto_color}
                                            size={"large"}
                                            //style={{width:"55px", height: "55px", fontSize:"20pt" }}
                                            style={{ width: '60px', height: '60px', fontSize: '28px' }}
                                            className="avatar-group-card-manager"
                                        />

                                    } />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Ponderación Porcentual"
                                    infoLeft={<Link to={'/admin/manager/ponderacion/proyecto/' + this.props.match.params.id}> {this.state.pm_progreso_proyectado.toMoney(2)} % <IconGo /></Link>}
                                    titleRight="Progreso Real"
                                    infoRight={`${this.state.pm_progreso_real.toMoney(2)} %`}
                                />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Fecha Inicial"
                                    titleRight="Fecha Final"
                                    infoLeft={this.state.pm_fecha_inicio ? moment(this.state.pm_fecha_inicio).format('DD-MM-YYYY') : 'N/A' }
                                    infoRight={this.state.pm_fecha_conclusion ? moment(this.state.pm_fecha_conclusion).format('DD-MM-YYYY'): 'N/A' }
                                />
                            </Col>
                        </Row>

                        <div className="scroll">
                            <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                                <Col span={5} className="center">
                                    <Text type="secondary">Inmueble</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Progreso Ponderado</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Progreso Real</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Costo Presupuestado</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Costo Actual</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text type="secondary">Acciones</Text>
                                </Col>
                            </Row>

                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.dataObras}
                                loading={this.state.loading}
                                locale={{ emptyText: 'Sin inmuebles' }}
                                renderItem={item =>
                                    (<List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{ width: '100%' }} className="">

                                                <Col span={5} className="center">
                                                    <Text strong>{item.inmueble_id.nombre}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text className="text-middle">{item.progreso_proyectado.toMoney(2)} %</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text className="text-middle">{item.progreso_real.toMoney(2)} % </Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text className="text-middle">$ {item.costo_presupuestado.toMoney(2)} MXN</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text className="text-middle">$ {item.costo_actual.toMoney(2)} MXN</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Button className="btn btn-copy"  id={item._id} onClick={e =>this.ModalCopyObra(e)}>
                                                        <IconCopy />
                                                    </Button>
                                                    <Link to={`/admin/manager/proyecto/inmueble/${item._id}`}>
                                                        <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle"  >
                                                            <IconEye />
                                                        </Button>
                                                    </Link>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title={"¿Deseas eliminar " + item.inmueble_id.nombre + " ?"}
                                                        onCancel={e => e.stopPropagation()}
                                                        okText="Si"
                                                        cancelText="No"
                                                        onClick={e => e.stopPropagation()}
                                                        onConfirm={e => this.deleteObra(item._id)}
                                                    >
                                                        <Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
                                                            <IconDelete />
                                                        </Button>
                                                    </Popconfirm>

                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                    )}
                            />
                        </div>


                        <Row className="mt-2 pb-3">
                            <Pagination
                                total={this.state.itemCount}
                                showSizeChanger={true}
                                pageSize={this.state.perPage}
                                onChange={(page)=>{
                                    this.getInmuebles(page)
                                }}
                            />
                        </Row>

                    </section>


                    <Button className="btnAddPlus" onClick={() => this.setState({ modalObraVisible: true })}>
                        <IconPlus />
                    </Button>
                </Spin>

                <ModalObra
                    modalVisible={this.state.modalObraVisible}
                    closeMethod={this.hideModal}
                    accionModal={this.state.accionModal}
                    proyecto_id={this.state.proyecto_id}
                    manager_proyecto_id={this.state.pm_id}
                    registroId={this.state.registroId}
                    registroNombre={this.state.registroNombre}
                    ref={this.refModalMateriales}
                    accept={() => {
                        this.getInmuebles();
                        this.hideModal();
                    }}
                />
                <ModalCopiarObra
                    visible={this.state.ModalCopyObraVisible}
                    hideModalCopy={this.hideModalCopy}
                    data={this.state.dataObra}
                    proyecto_id={this.state.proyecto_id}
                    manager_proyecto_id={this.state.pm_id}
                    manager_inmueble_id={this.state.Obra_toCopy}
                    registroId={this.state.registroId}
                    registroNombre={this.state.registroNombre}

                />

            </div>
        )
    }
}
