import React, { Component } from 'react';
import {
    Button,
    Col,
    Row,
    Spin,
    Empty,
    Typography,
    Progress,
    List,
    Badge,
    Card,
    Popconfirm,
    Breadcrumb,
    Pagination,
    message
} from 'antd'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

//componentes
import { CardInfoDashboard, CardProgress, CardActivity, CardInfo, CardProgressBar } from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import { IconEdit, IconDelete, IconPlus, IconEye, IconArrowBack } from '../../Widgets/Iconos';
//css
import '../../../Styles/projectManager.css'

const axios = require('axios').default;
const { Text } = Typography;


export default class PMmaterialesEmpleado extends Component {

    constructor(props) {
        super(props)
        this.state = {

            manager_proyecto_id: "",
            proyecto_id: "",
            proyecto_nombre: "[Proyecto]",

            dataActividades: [],
            empleado_id: "",
            empleado_nombre: "[Empleado]",

            materialesRequeridos: 0,
            materialesEntregados: 0,
            progresoEntrega: 0,
            loadingPage: true,


            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
        }
    }

    /**
     * @memberof PMmaterialesEmpleado
     * @method   componentDidMount
     * @description
     *
     **/
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        var id = this.props.match.params.id.split('_');
        this.setState({
            manager_proyecto_id: id[0],
            empleado_id: id[1]
        });

        setTimeout(() => {
            this.getInfoEmpleado();
        }, 0.5);
    }


    /**
     * @memberof PMmaterialesEmpleado
     * @method   getInfoEmpleado
     * @description Trae la info del empleado seleccionado
     *
     **/
    getInfoEmpleado = async () => {
        var responsable_id = this.state.empleado_id;
        axios.get('/usuarios/get', {
            params: {
                id: responsable_id
            }
        })
            .then(({ data }) => {
                var dataEmpleado = data.data;

                this.setState({
                    empleado_id: dataEmpleado._id,
                    empleado_nombre: dataEmpleado.nombre + " " + dataEmpleado.apellido
                });

                this.getInfoActividades();
            })
            .catch(error => {
                console.log('no traje nara', error);
            })
    }


    /**
     * @memberof PMmaterialesEmpleado
     * @method   getInfoActividades
     * @description Trae la info de las actividades del empleado seleccionada
     *
     **/
    getInfoActividades = async (page = this.state.currentPage, size = this.state.perPage) => {
        var manager_proyecto_id = this.state.manager_proyecto_id;
        var responsable_id = this.state.empleado_id;
        var search = this.props.search;

        axios.get('/manager/proyecto/actividades/empleado', {
            params: {
                page: page,
                limit: size,
                search: search,

                manager_proyecto_id: manager_proyecto_id,
                responsable_id: responsable_id
            }
        })
            .then(({ data }) => {
                //console.log('data data data data data ', data)

                var data_act = [];
                var dataReg = data.data.itemsList;
                var dataEmpleado = data.empleado;


                for (let index = 0; index < dataReg.length; index++) {
                    const element = dataReg[index];

                    var materialRequerido = element.materiales_presupuestados;

                    //  console.log('element.materiales_entregados ', element.materiales_entregados)
                    //  console.log('materialRequerido ', materialRequerido)
                    var status = "";

                    if (materialRequerido === 0) {
                        status = "Sin Materiales Requeridos"
                    } else {
                        if (element.materiales_entregados === 0) {
                            status = "Sin entregas"
                        }
                        else if (element.materiales_entregados > materialRequerido) {
                            status = "Excedido"
                        } else if (element.materiales_entregados < materialRequerido) {
                            status = 'En Proceso'
                        } else if (element.materiales_entregados === materialRequerido) {
                            status = 'Concluido'
                        }
                    }
                    // if (materialEntregado > materialRequerido)
                    // else if (materialEntregado < materialRequerido)
                    // else if (materialEntregado === materialRequerido)

                    var avanceEntrega = 0;
                    if (parseFloat(element.materiales_entregados) > 0) {
                        avanceEntrega = ((parseFloat(element.materiales_entregados) * 100) / parseFloat(element.materiales_presupuestados))
                    }

                    console.log("x : " + avanceEntrega)


                    data_act.push({
                        _id: element._id,
                        actividad: element.actividad_id.nombre,
                        nombre: (element.responsable_id.nombre + " " + element.responsable_id.apellido),
                        materialEntregado: element.materiales_entregados,
                        materialRequerido: materialRequerido,
                        avance: avanceEntrega,
                        manager_actividad_id: element._id,
                        status: status
                    })
                }


                var paginator = data.data.paginator
                    ;
                this.setState({
                    dataActividades: data_act,

                    materialesRequeridos: data.totalMaterialesRequeridos,
                    materialesEntregados: data.totalMaterialesEntregados,
                    progresoEntrega: data.totalProcesoEntrega,

                    currentPage: paginator.currentPage,
                    itemCount: paginator.itemCount,
                    perPage: paginator.perPage,
                    pageCount: paginator.pageCount,

                    loadingPage: false
                });
            })
            .catch(error => {
                console.log('no traje nara', error);
            })
    }


    /**
     * @memberof PMmaterialesEmpleado
     *
     * @method   deleteActividad
     * @description  Elimina una Actividad de Manager_actividades
     *
     *
     **/
    deleteActividad = (manager_actividad_id) => {

        axios.delete('/manager/proyectos/inmuebles/actividades/delete', { params: { actividad_id: manager_actividad_id } })
            .then((res) => {
                message.success("!Se eliminó actividad!");
                this.state.loadingPage = true;
                this.state.currentPage = 1;


                this.getInfoActividades(1);
            })
            .catch(res => {
                message.error("!Error, no se pudo eliminar la actividad");
                console.log("error", res);
            });
    }

    /**
     * @memberof PMmaterialesEmpleado
     *
     * @method   badgeStatus
     * @description  Obteneemos el tipo de status para el badge
     *
     *
     **/
    badgeStatus = item => {
        switch (item.status) {
            case 'Sin Materiales Requeridos':
                return "warning"
                break;
            case 'Sin entregas':
                return "default"
                break;
            case 'Excedido':
                return "error"
                break;
            case 'En Proceso':
                return "processing"
                break;
            case 'Concluido':
                return "success"
                break;
        }
    }

    render() {
        return (
            <div>
                <Spin spinning={this.state.loadingPage}>
                    <Row className="row-encabezado">
                        <Col xs={24}>
                            <Breadcrumb className="breadcrumb" separator=" " style={{ fontSize: "1.5em", marginTop: "10px" }}>
                                <Breadcrumb.Item>
                                    <Link to={`/admin/project-manager/material/${this.state.manager_proyecto_id}`}
                                        style={{ color: "white" }}>
                                        <Button className="IconArrowBack" onClick={this.volver}>
                                            <IconArrowBack />
                                        </Button>
                                    </Link>
                                </Breadcrumb.Item>

                                <Breadcrumb.Item className="breadcrumb-actual">
                                    {"Materiales de " + this.state.empleado_nombre}
                                </Breadcrumb.Item>
                            </Breadcrumb>

                        </Col>
                    </Row>

                    <section className="p-1">
                        <Row gutter={[16, 24]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo titleLeft="Empleado"
                                    infoLeft={this.state.empleado_nombre} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo titleLeft="Materiales Presupuestados"
                                    infoLeft={((this.state.materialesRequeridos).toMoney()) + " tons"}
                                    titleRight="Materiales Entregados"
                                    infoRight={((this.state.materialesEntregados).toMoney()) + " tons"} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardProgressBar
                                    title="Proceso de Entrega"
                                    percent={this.state.progresoEntrega.toFixed(2)} />
                            </Col>
                        </Row>

                        <div className="list-title">Actividades Asignadas</div>

                        <div className="scroll">
                            <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}
                                className="title-list-inmuebles mb-1">
                                <Col span={5} className="center">
                                    <Text type="secondary">Actividad</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Material Presupuestado</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Material Entregado</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Estatus</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Avance</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text type="secondary">Opciones</Text>

                                </Col>
                            </Row>

                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.dataActividades}
                                locale={{ emptyText: 'Sin actividades' }}
                                renderItem={item => (
                                    <List.Item className="component-list-item lista-materiales">
                                        {console.log(item)}
                                        <Card className="card-list">
                                            <Row style={{ width: '100%' }} className="">
                                                <Col span={5} className="center">
                                                    <Text strong>{item.actividad}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text>{(new Intl.NumberFormat().format(item.materialRequerido)) + " tons"}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text>{(new Intl.NumberFormat().format(item.materialEntregado)) + " tons"}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Badge status={this.badgeStatus(item)} text={item.status}
                                                        className="badge-status" />
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Progress style={{ padding: "0px 15px 0px 0px" }} percent={item.avance.toFixed(2)} status={item.status} />
                                                </Col>
                                                <Col span={3} className="">
                                                    <Link
                                                        to={`/admin/project-manager/material-empleado-actividad/${item.manager_actividad_id}`}>
                                                        <Button type="primary" className="button-survey btn-eye button-eye"
                                                            title="Ver detalle">
                                                            <IconEye />
                                                        </Button>
                                                    </Link>
                                                    <Popconfirm
                                                        title={"¿Deseas eliminar actividad " + item.actividad + " ?"}
                                                        placement="topRight"
                                                        onConfirm={e => {
                                                        }}
                                                        onCancel={e => e.stopPropagation()}
                                                        okText="Si"
                                                        cancelText="No"
                                                        onConfirm={e => this.deleteActividad(item.manager_actividad_id)}
                                                    >

                                                        <Button className="btn btn-delete"
                                                            onClick={e => e.stopPropagation()}>
                                                            <IconDelete />
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />


                        </div>
                        <Row className="mt-2 pb-3">

                            <Pagination
                                defaultCurrent={1}
                                total={this.state.itemCount}
                                showSizeChanger={false}
                                pageSize={this.state.perPage}
                                onChange={(page) => {
                                    this.getInfoActividades(page)
                                }}
                            />
                        </Row>

                    </section>
                </Spin>
            </div>
        )
    }
}
