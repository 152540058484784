import React, { Component } from 'react';
import { Button, Checkbox, Col, DatePicker, Dropdown, Spin, Menu, Radio, Row, Select, Card, Statistic, Typography, List, Avatar } from 'antd'

import echarts from 'echarts';

import ReactEcharts from 'echarts-for-react';
import axios from 'axios';


const { Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;


export default class GraficaNominasPorGrupos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            inversiones:[],
            acreedores:[],
            // nomina_id: null
        }
    }

    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
    
    }





    render() {


        return (
            <Spin spinning={false}>
                <ReactEcharts
                    option={
                        {
						  tooltip: {
						    trigger: 'item'
						  },
						  legend: {
						    top: '5%',
						    left: 'center'
						  },
						  series: [
						    {
						      name: 'Access From',
						      type: 'pie',
						      radius: ['40%', '70%'],
						      avoidLabelOverlap: false,
						      label: {
						        show: false,
						        position: 'center'
						      },
						      emphasis: {
						        label: {
						          show: true,
						          fontSize: '40',
						          fontWeight: 'bold'
						        }
						      },
						      labelLine: {
						        show: false
						      },
						      data: [
						        { value: 1048, name: 'Search Engine' },
						        { value: 735, name: 'Direct' },
						        { value: 580, name: 'Email' },
						        { value: 484, name: 'Union Ads' },
						        { value: 300, name: 'Video Ads' }
						      ]
						    }
						  ]
						}
                    }
                    style={{ height: '400px', width: '100%' }}
                    opts={{ renderer: 'svg' }}
                    className='react_for_echarts' />
            </Spin>
        )
    }
}


