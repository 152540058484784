import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Spin, Form, Select, message, InputNumber  } from 'antd';
//componentes
import { IconCloseModal  } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalProformaMaterial extends Component {

	formEstatus = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			materiales:[],
			material_edit: undefined,
		}
	}

	/**
	* @memberof ModalProformaMaterial
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getMateriales();
	}

	/**
	* @memberof ModalProformaMaterial
	*
	* @method   componentDidUpdate
	* @description si se va a editar un registro del arreglo de materiales, formatea los datos para el formulario
	*
	**/
	componentDidUpdate(prevPros) {
		const {material_edit} = this.props;
		if(this.props.material_edit !== prevPros.material_edit && this.props.material_edit !== undefined){
			this.setState({
				material_edit: {
					fecha: moment(material_edit.fecha),
					monto: material_edit.monto,
					cantidad: material_edit.cantidad,
					material_id: material_edit.material_id._id,
				}
			})
		}
	}

	/**
	* @memberof ModalProformaMaterial
	*
	* @method   hideModal
	* @description	 Cierra el modal
	*
	**/
	hideModal = () => {
		this.props.closeMethod()
		this.setState({loading: false, material_edit: {}})
	}	

	/**
	* @memberof ModalProformaMaterial
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	onFinish = (values) => {
		this.setState({loading: true})
		if(this.props.modalTitle === "Editar"){
			this.updateMaterial(values)
		}else{
			this.addMaterial(values)
		}
	}

	/**
	* @memberof ModalProformaMaterial
	*
	* @method   getMateriales
	* @description	lista de materiales registrados en la BD
	*
	**/
	getMateriales = (search) => {
		axios.get('/materiales/list',{
			params:{
				pagination: false,
				search: search
			}
		}).then(response => {
			this.setState({materiales: response.data.data})
		}).catch( error =>{
			console.log(error)
			message.error('No se cargaron los materiales')
		})
	}

	/**
	* @memberof ModalProformaMaterial
	*
	* @method   addMaterial
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	addMaterial = (values) => {
		axios.post('/proforma/conceptos/materiales/add',{
			proforma_id: this.props.proforma_id,
			proforma_concepto_id: this.props.proforma_concepto_id,
			fecha: values.fecha,
			material_id: values.material_id,
			cantidad: values.cantidad,
			monto: values.monto,

		}).then(response => {
			this.hideModal()
			message.success('Marterial añadido Exitosamente!')
		}).catch( error =>{
			console.log(error)
			message.error('No se añadio el Material')
		})
	}

	/**
	* @memberof ModalProformaMaterial
	*
	* @method   updateMaterial
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	updateMaterial = (values) => {
		axios.post('/proforma/conceptos/materiales/update',{
			proforma_id: this.props.proforma_id,
			proforma_concepto_id: this.props.proforma_concepto_id,
			proforma_material_id: this.props.material_edit._id,
			fecha: values.fecha,
			material_id: values.material_id,
			cantidad: values.cantidad,
			monto: values.monto,
		}).then(response => {
			this.hideModal();
			message.success('Marterial actualizado Exitosamente!')
		}).catch( error =>{
			console.log(error)
			message.error('No se actualizó el Material')
		})
	}

	/**
    * @memberof ModalProformaMaterial
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            if (array.length > 0) {

                array.forEach(function (item, index) {
                    arr.push(<Option value={item._id}>{item.nombre}</Option>)
                })
            }
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
        }
        return arr
    }



	render() {
		return (
			<Modal
				visible={this.props.modalVisible}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				closable={false}
				className="modal-form"
				destroyOnClose={true}
				zIndex={1000}
				width={750}
			>

				<div className="modal-header">
					<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
						<IconCloseModal />
					</Button>
					<Title level={3} className="modal-title">{this.props.modalTitle} Material</Title>
				</div>

				<Spin spinning={this.state.loading}>
					<Form
						layout="vertical"
						name="formulario-transacciones"
						onFinish={this.onFinish}
						initialValues={this.state.material_edit}
					>
						<Row align="center">
							<Col xs={20} md={13}>
								<Form.Item
									label="Fecha"
									name="fecha"//----------------------------------------------------------fecha
									rules={[{
										required: true,
										message: "Por favor, ingrese una Fecha"
									}]}
								>
									<DatePicker/>
								</Form.Item>
							</Col>
							<Col xs={20} md={13}>
								<Form.Item
									label="Material"
									name="material_id"//----------------------------------------------------------material_id
									rules={[{
										required: true,
										message: "Por favor, seleccione un Material"
									}]}
								>
									<Select
										className="form-select"
                                        showSearch
                                        filterOption={false}
                                        onSearch={search => this.getMateriales(search)}
                                    >
                                       {this.selectOptions(this.state.materiales)}

                                    </Select>
								</Form.Item>
							</Col>
							<Col xs={20} md={13}>
								<Form.Item
									label="Cantidad"
									name="cantidad"//----------------------------------------------------------cantidad
									rules={[{
										required: true,
										message: "Por favor, ingrese una Cantidad"
									}]}
								>
									<InputNumber/>
								</Form.Item>
							</Col>
							<Col xs={20} md={13}>
								<Form.Item
									label="Monto"
									name="monto"//----------------------------------------------------------monto
									rules={[{
										required: true,
										message: "Por favor, ingrese el Monto"
									}]}
								>
									<InputNumber/>
								</Form.Item>
							</Col>
							<Col span={20} className="center">
								<Button htmlType="submit" type="primary" className="btn-azul mt-3">
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		)
	}
}

