import React, { Component } from "react";
import { Row, Col, Button, Modal, Select, Typography, Form, Input, message, Spin, InputNumber, DatePicker } from 'antd';

import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios');
const moment = require('moment');
//Modal para agregar una obra en InmuebleAdmin
export default class ModalActividadesManager extends Component {

    formActividad = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            empleados: [],
            actividades: [],
            actividad: undefined,
            id_actividad: undefined,
            loading: false,

            update: true,
            inmueble: {},
        }
    }

    /**
    * @memberof ModalActividadesManager
    *
    * @method   componentDidMount
    * @description  Recibe el inmueble seleccionado y obtiene la lista de usuarios ( empleados )
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    /**
    * @memberof ModalActividadesManager
    *
    * @method   componentDidUpdate
    * @description  Recibe el inmueble seleccionado y obtiene la lista de usuarios ( empleados )
    *
    **/
    componentDidUpdate(prevProps) {
        if (this.state.update === true && this.props.modalVisible === true) {
            this.state.update = false;
            this.loadActividades();
        }


        if (this.state.update === false && this.props.modalVisible === false) {
            this.state.update = true;
            this.loadActividades();
        }

        //Si se va a editar un actividad, busca los datos de la actividad
        if(this.props.actividad_id !== prevProps.actividad_id && this.props.actividad_id !== undefined){
            this.getActividad(this.props.actividad_id)
        }
    }


    /**
    * @memberof ModalActividadesManager
    *
    *
    * @method getActividad
    * @description Obtiene la informacion de la actividad
    */
    getActividad = (id) => {
        this.setState({loading: true})
        axios.get("/manager/proyects/inmuebles/actividades/get", {
            params:{
                id: id,
            }
        })
        .then(res => {
            console.log('getActividad',res.data.data)
            this.setState({
                actividad: {
                    _id: res.data.data.actividad_id._id,
                    nombre: res.data.data.actividad_id.nombre,
                    costo_presupuestado: res.data.data.costo_presupuestado,
                }, 
                loading: false})
            this.formActividad.current.setFieldsValue({
                actividad_id: res.data.data.actividad_id._id,
                costo_presupuestado: res.data.data.costo_presupuestado,
                costo_actual: res.data.data.costo_actual,
                fecha_inicio: moment(res.data.data.fecha_inicio),
                fecha_conclusion: moment(res.data.data.fecha_conclusion),
            })
        })
        .catch(e =>
            console.log('error actividad get', e)
        )
    }

    /**
    * @memberof ModalActividadesManager
    *
    *
    * @method loadActividades
    * @description Obtiene la informacion de la actividad
    */
    loadActividades = () => {
        axios.get('/inmuebles-trabajo/get/actividades', {
            params: {
                proyecto_id: this.props.proyecto_id,
                inmueble_id: this.props.inmueble_id,
                actividadesRestantes: true,
                manager_inmueble_id: this.props.manager_inmueble._id,
            }
        })
            .then(res => {
                console.log(res.data.data)
                this.setState({
                    inmueble: res.data.data,
                    actividades: res.data.data.tajos.map(act => act.tajo_id)
                })
            })
            .catch(e =>
                console.log('error actividad get', e)
            )


    }


    /**
    * @memberof ModalActividadesManager
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
        this.setState({actividad: undefined, loading: false})
    }


    /**
     * @memberof ModalActividadesManager
     *
     *
     * @param {*} values
     * @method newActividad
     * @description Manda peticion para crear una nueva actividad
     *
     */
    addActividad = (values) => {
        axios.post('/manager/proyectos/inmuebles/actividades/add', {
            actividad_id: values.actividad_id,
            inmueble_id: this.props.manager_inmueble.inmueble_id._id,
            manager_inmueble_id: this.props.manager_inmueble._id,
            manager_proyecto_id: this.props.manager_inmueble.manager_proyecto_id._id,
            responsable_id: this.state.inmueble.responsable_id._id,
            costo_presupuestado: values.costo_presupuestado,
            costo_actual: values.costo_actual,
            fecha_inicio: values.fecha_inicio,
            fecha_conclusion: values.fecha_conclusion,
            materiales_requeridos: [],
        })
        .then(res => {
            message.success('Actividad creada!');
        })
        .catch(error => {
            console.log('error al crear actividad', error);
            message.error('Actividad no creada');
        })
        .finally(f => {
            this.loadActividades();
            this.hideModal();
        })

    }


    /**
     * @memberof ModalActividadesManager
     *
     *
     * @param {*} Values
     * @method updateActividad
     * @description Manda la peticion para actualizar una actividad
     */
    updateActividad = (values) => {
        this.setState({loading: true})
        axios.post('/manager/proyects/inmuebles/actividades/update', {
            _id: this.props.actividad_id,
            actividad_id: values.actividad_id,
            responsable_id: this.state.inmueble.responsable_id._id,
            costo_presupuestado: values.costo_presupuestado,
            costo_actual: values.costo_actual,
            fecha_inicio: values.fecha_inicio,
            fecha_conclusion: values.fecha_conclusion,
        })
            .then(res => {
                message.success('!Actividad Actualizada!');
            })
            .catch(error => {
                console.log('error al guardar actividad', error);
                message.error('!Actividad no ctualizada!');
            })
            .finally(f => {
                this.setState({loading: false})
                this.loadActividades();
                this.props.closeMethod();
            })

    }

    /**
    * @memberof ModalActividadesManager
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        this.setState({loading: true})
        if(this.props.modalTitle === 'Editar'){
            this.updateActividad(values);
        }else{
       	    this.addActividad(values);
        }
    }

    /**
    * @memberof ModalActividadesManager
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

       let arr = [];
       if(array){
            array.forEach(function ({ _id, nombre }, index) {
                arr.push (<Option value={_id}> {nombre}</Option>)
            })
       }

       if(array && addElemnt){
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
       }
        return arr
    }

    /**
    * @memberof ModalActividadesManager
    *
    * @method   onChangeActividad
    * @description  Al seleccionar un actividad del Select llena el campo de costo_prespuestado automaticamente por el precio_m2 de la actividad,
    * registrada en la seccion de Inmueble trabajados de Nomminas
    *
    **/
    onChangeActividad = (value) => {
        let actividad = this.state.inmueble.tajos.find(actividad=>actividad.tajo_id._id === value)
        if(actividad){
            this.formActividad.current.setFieldsValue({
                costo_presupuestado: actividad.precio_m2,
            })
        }else{
            //si se esta editando una actividad, this.state.inmueble.tajos no tiene la info actividad que se esta editando, por lo cual
            //se tiene que sacar la informacacion de this.state.actividad que tiene el costo_presupestado que teia anteriormente
            this.formActividad.current.setFieldsValue({
                costo_presupuestado: this.state.actividad.costo_presupuestado,
            })
        }
    }


    render() {
        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >

            <Spin spinning={this.state.loading}>
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} Actividad</Title>
                </div>


                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    ref={this.formActividad}
                    name="formulario-entrega"
                    onFinish={this.onFinish} >
                    <Row>
                        <Col span={20} offset={2}>
                            <Form.Item label="Actividad" name="actividad_id" //<--------------------------------------- actividad_id
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un actividad"
                                }]}
                            >
                                <Select
                                	clearIcon={<IconArrowSelect />}
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeActividad(value)}
                                    //filterOption={false}
                                    //onSearch={search => this.loadActividades(search)}
                                >

                                    {this.selectOptions(this.state.actividades, this.state.actividad)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={20} offset={2}>
                            <Form.Item label="Presupuesto Total"
                                name="costo_presupuestado"//<--------------------------------------- costo_presupuestado
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un Empleado"
                                }]}
                            >
                                <InputNumber disabled={true}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={20} offset={2}>
                            <Form.Item label="Monto Pagado" 
                                name="costo_actual" //<--------------------------------------- costo_actual
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el monto pagado de la actividad"
                                }]}
                            >
                                <InputNumber min={0}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col  xs={{span:20, offset: 2}} md={{span: 9 ,offset: 2}}>
                            <Form.Item
                                label="Fecha de inicio"
                                name="fecha_inicio" //<--------------------------------------- fecha_inicio
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la Fecha de inicio"
                                }]}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col xs={{span:20, offset: 2}} md={{span: 9 ,offset: 2}}>
                            <Form.Item
                                label="Fecha de Finalizacion"
                                name="fecha_conclusion" //<--------------------------------------- fecha_conclusion
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la Fecha de Finalizacion"
                                }]}
                            >
                                <DatePicker />
                            </Form.Item>
                            </Col>
                        </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Spin>
            </Modal>
        )
    }
}
