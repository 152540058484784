import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Divider, Upload, Spin } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;

/**
 *
 *
 * @class ModalMateriales
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalInventario extends Component {

    tipoRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            accionModal: '',
            spinning: false,
            datosConsultados: false,
            return: false,
            tipos: [],
            proyectos: [],
            materiales: [],
            folio: null,
            tipoMaterialSeleccionado: "",

            materialFijo: false,
            modalActualizado: false,
        }
    }



    /**
    * @memberof ModalMateriales
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if ( this.props.material === undefined && this.props.material._id === undefined) {
            this.getTipos();
            this.getMateriales();
        }


        setTimeout(() => {
            if (this.props.movimiento_id !== null && this.props.movimiento_id !== undefined)
                this.getMovimiento()
            else
                this.getFolio()
        }, 250);
    }


    componentDidUpdate = async () => {

        if (!this.state.modalActualizado && this.props.modalVisible && this.props.material !== undefined && this.props.material._id !== undefined) {

            var _materiales = [{
                _id: this.props.material._id,
                nombre: this.props.material.nombre,
                existencias: this.props.material.existencias,
                tipo_id: { unidad : this.props.material.tipo_id.unidad}
            }]

            var _tipos = [{
                _id: this.props.material.tipo_id._id,
                nombre: this.props.material.tipo_id.nombre
            }]

            this.setState({
                tipos: _tipos,
                materiales: _materiales,
                materialFijo: true,
                modalActualizado: true,

            })

            this.modalMateriales.current.setFieldsValue({
                material_id: this.props.material._id,
                tipo_id: this.props.material.tipo_id._id
            })
        }

        else if (!this.state.modalActualizado || !this.state.materialFijo) {

            this.setState({
                materialFijo: false,
                modalActualizado: true
            })
        }
    }




    /**
     *
     *
     * @param {*} [movimiento_id=this.props.movimiento_id]
     * @memberof ModalMateriales
     * 
     * @name getFolio
     * @description Obtenemos los dolios
     */
    getFolio = async () => {
        axios.get('/inventarios/folio').then(({ data }) => {
            this.setState({
                folio: data.data.folio
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los folios')
        })
    }


    /**
     *
     *
     * @param {*} [movimiento_id=this.props.movimiento_id]
     * @memberof ModalMateriales
     * 
     * @description Obtenemos el movimiento
     */
    getMovimiento = async (movimiento_id = this.props.movimiento_id) => {
        axios.get('/inventarios/get', {
            params: {
                material_movimiento_id: movimiento_id
            }
        }).then(({ data }) => {
            this.setState({
                folio: data.data.folio_id,
                unidad: data.data.material_id.unidad,
                disabled: true

            })
            this.modalMateriales.current.setFieldsValue({
                ...data.data,
                imagen: (typeof data.data.imagen === "string") ? [
                    {
                        uid: '-1',
                        name: data.data.imagen,
                        filename: data.data.imagen,
                        status: 'done',
                        url: axios.defaults.baseURL + '/upload/' + data.data.imagen
                    }
                ] : [],
            })

        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })

    }

    /**
     *
     *
     * @param {*} search
     * @memberof ModalMateriales
     * @description Obtenemos las los tipos material
     */
    getTipos = async (search) => {
        axios.get('/tipos/list', {
            params: {
                search
            }
        }).then(response => {
            this.setState({ tipos: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })
    }


    /**
     *
     *
     * @param {*} search
     * @memberof ModalMateriales
     * @description Obtenemos las materiales
     */
    getMateriales = async (search) => {
        var tipo_id = this.state.tipoMaterialSeleccionado;
        if (tipo_id !== undefined && tipo_id !== "") {
            this.setState({ loading: true });

            axios.get('/materiales/list', {
                params: {
                    search,
                    pagination: false,
                    tipo_id
                }
            }).then(response => {
                setTimeout(() => {
                    this.setState({
                        materiales: response.data.data,
                        loading: false
                    })
                }, 1000);
            }).catch(error => {
                console.log(error)

                this.setState({
                    loading: false
                })
                message.error('Error al cargar los tipos')
            })
        }
    }


    /**
     * @memberof modalInventario
     * @method   onFinish
     * @description  Al completar formulario :
     * Se valida el tipo de accion modal para crear nuevo material o editar el registro seleccionado
     **/
    onFinish = async (values) => {

        values.imagen = (Array.isArray(values.imagen) && values.imagen.length > 0) ? values.imagen[0].name : undefined
        values.material_movimiento_id = this.props.movimiento_id

        if (this.props.movimiento_id !== null && this.props.movimiento_id !== undefined)
            axios.put('/inventarios/update', values)
                .then((res) => {
                    message.success("Material Registrado!");


                    this.props.onSuccess()

                })
                .catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : <div>
                            Error al procesar registro. Verifique la información.
                            <br />
                            <p>
                                El campo nombre de material es unico e irrepetible.
                            </p>
                        </div>
                    });
                });

        else axios.post('/inventarios/add', values)
            .then((res) => {
                message.success("¡Se ha registrado el material de inventario!")
                this.props.onSuccess()
            })
            .catch((error) => {
                Modal.warning({
                    title: 'Información de Registro Incorrecta',
                });
            });
    }


    modalMateriales = React.createRef()

    render() {


        return [

            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title"> Solicitud de Inventario</Title>
                <Title level={5} className="modal-title"> {this.state.folio}</Title>
            </div>,
            <Spin spinning={this.state.loading}>


                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.modalMateriales}
                    onFinish={this.onFinish}

                >
                    <Row align="center">

                        <Col span={20}>
                            <Form.Item
                                label="Tipo de Material"
                                name="tipo_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo material."
                                }]}
                            >
                                <Select
                                    onSelect={(value, option) => {
                                        this.setState({ tipoMaterialSeleccionado: value })
                                        this.getMateriales();
                                    }
                                    }
                                >
                                    {this.state.tipos.map(tipo => <Option value={tipo._id} tipo={tipo}>
                                        {tipo.nombre}
                                        {/* <strong> {tipo.nombre} </strong> - {tipo.tipo_id.nombre} ({tipo.existencias} {(tipo.unidad === 0) ? "pzs." : "tons."}) */}
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={20}>
                            <Form.Item
                                label="Material"
                                name="material_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el material."
                                }]}
                            >
                                <Select
                                    disabled={this.state.disabled}
                                    onSelect={(value, option) => this.setState({ unidad: option?.material?.unidad })}
                                >
                                    {this.state.materiales.map(material => {
                                        return <Option value={material?._id} >
                                            <strong> {material.nombre} </strong> ({material.existencias} {(material.tipo_id.unidad === 0) ? "pzs." : "tons."})
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={20}>
                            <Form.Item
                                label="Movimiento"
                                name="movimiento"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo de movimiento."
                                }]}
                            >
                                <Select
                                    clearIcon={<IconArrowSelect />}
                                    placeholder={"Tipo de Movimiento"}
                                    showArrow={false}
                                    filterOption={false}
                                >
                                    <Option value={true}>Entrada de Materia Prima</Option>
                                    <Option value={false}>Salida de Materia Prima</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={20}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"

                                extra={(this.state.unidad !== undefined) ? (
                                    (this.state.unidad === 0) ?
                                        "Piezas"
                                        :
                                        "Toneladas"
                                ) : undefined}
                            >
                                <InputNumber
                                    placeholder="Cantidad"
                                    min={0}
                                    precision={(this.state.unidad) ? 2 : undefined}
                                />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}



export default function (props) {

    return <Modal
        visible={props.modalVisible}
        onCancel={props.onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalInventario {...props} />
    </Modal>


}
