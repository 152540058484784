import React, { Component } from 'react';
import { Button, Col, Row, Card, Typography, Image, Tooltip, Menu, Badge, Dropdown, List, Popconfirm, DatePicker, message, Pagination, Checkbox } from 'antd'
import ReactECharts from 'echarts-for-react';
import { Link } from "react-router-dom";
//componentes
import {
	IconEye, IconEdit, IconWhatsapp, IconDelete, IconPlus, IconMailOpen,
	IconArrowCustom as Arrow, IconDownloadCustom as Download
} from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
//modal
import ModalProspectos from '../Modales/Ventas/ModalProspectos';
import ModalEmailProspectos from '../Modales/Ventas/ModalEmailProspectos';
//css
import '../../../Styles/ventas.css';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const axios = require('axios').default

export default class Prospectos extends Component {

	constructor(props) {
		super(props)
		this.state = {
			projects: [],
			projectsSelected: [],
			prospectos: [],
			modalProspectosVisible: false,
			prospecto_id: undefined,

			/* Paginado */
			page: 1,
			total: 0,

		}
	}


	/**
	* @memberof Prospectos
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.listProspectos(1);
		this.getProjects();
	}

	/**
	 * @methodOf  Prospectos
	 * @function hideModal
	 *
	 * @description Cierra el modal
	 *
	 * */
	hideModal = () => {
		this.setState({
			prospecto_id: undefined,
			modalProspectosVisible: false,
			modalEmailVisible: false,
			modalEmailVisible: false,
		});
		this.listProspectos(this.state.page)
	};

	/**
	 * @methodOf  Prospectos
	 * @function listProspectos
	 *
	 * @description Lista los prospectos
	 *
	 * */
	listProspectos = (page) => {
		this.setState({ loading: true })
		axios.get('/prospectos/list', {
			params: {
				limit: 10,
				page: page,
				projects: this.state.projectsSelected,
			}
		}).then(response => {
			this.setState({
				prospectos: response.data.data.itemsList,
				page: response.data.data.paginator.currentPage,
				total: response.data.data.paginator.itemCount,
				pageCount: response.data.data.paginator.pageCount,
				loading: false,
			})
		})
			.then(() => {
				//Si se elimina el utlimo registro de una pagina, te manda a la primera pagina
				if (this.state.page > this.state.pageCount) {
					this.listProspectos(1)
				}
			})
			.catch(error => {
				message.error('No se cargaron los Prospectos')
				this.setState({ loading: false })
			})
	};

	/**
	 * @methodOf  Prospectos
	 * @function deleteProspecto
	 *
	 * @description Elimina un prospecto
	 *
	 * */
	deleteProspecto = (_id) => {
		this.setState({ loading: true })
		axios.post('/prospectos/delete', {
			_id: _id
		}).then(response => {
			message.success('El prospecto ha sido eliminado')
			this.listProspectos(this.state.page)
		}).catch(error => {
			message.error('No se elimino el Prospectos')
		})
	};

	/**
	 * @methodOf Prospectos
	 * @function getProjects
	 * @description Obtiene los proyectos para los filtros
	 */
	getProjects = () => {
		axios.get('/proyectos', {
			params: {
				pagination: false
			}
		}).then((proyectos) => {
			this.setState({
				projects: proyectos.data.data
			})
		}).catch((error) => {
			message.error('No se cargaron los Proyectos')
		})
	}

	/**
	 * @methodOf Prospectos
	 * @function onProjectsChange
	 * @description Se ejecutat al cambiar el proyecto, 
	 * 
	 * @param _id ID del proyecto a filtrar
	 * @param status ID del status (si se desea agregar al arreglo o no)
	 */
	onProjectsChange = (_id, status) => {

		let { projectsSelected } = this.state;
		let index = projectsSelected.findIndex(item => item.toString() === _id.toString())
		if (status) {
			if (index === -1) projectsSelected.push(_id)
		} else {
			if (index !== -1) projectsSelected.splice(index, 1)
		}
		this.setState({ projectsSelected }, this.listProspectos(this.state.page))
	};


	getColor = (status) => {
		let color
		switch (status) {

			case 0:
				color = "#dc3545"
				break;

			case 1:
				color = "#198754"
				break;

			case 2:
				color = "#ffc107"
				break;

			default:
				color = "#ffc107"
		}
		return color
	}


	getTooltipText = (status) => {
		let text
		switch (status) {
			case 0:
				text = "Error al enviar"
				break;
			case 1:
				text = "Enviado"
				break;
			case 2:
				text = "Sin Enviar"
				break;
			default:
				text = "Sin Enviar"
		}
		return text
	}


	render() {
		return (
			<div style={{ minHeight: '100' }}>
				<Row className="row-encabezado">
					<Col xs={24} lg={8} className="">
						<h2 style={{ width: 'auto', float: 'left' }}>Prospectos</h2>
					</Col>
					<Col xs={24} lg={5} className="center">
						<RangePicker
							className="input-picker"
							style={{ background: 'transparent !important', color: '#FFF' }}
						/>
					</Col>
					<Col xs={12} lg={3} className="center">
						<Dropdown
							className="filter-element"
							overlay={
								<Menu>
									{this.state.projects.map(({ _id, nombre, logo, color }, index) => (
										<Menu.Item key={index}>
											<Checkbox
												onChange={(status) => this.onProjectsChange(_id, status.target.checked)}>
												<CustomAvatar
													image={logo}
													name={nombre}
													color={color}
													size="small"
													style={{
														margin: '0 5px'
													}}
												/>
												{nombre}
											</Checkbox>
										</Menu.Item>
									))}
								</Menu>
							}>
							<p>Proyectos <Arrow className="arrow" /></p>
						</Dropdown>
					</Col>
					<Col xs={12} lg={3} className="center">
						<Dropdown
							className="filter-element"
							overlay={
								<Menu>
								</Menu>
							}>
							<p>Rubros<Arrow className="arrow" /></p>
						</Dropdown>
					</Col>
					<Col xs={12} lg={3} className="center">
						<Dropdown
							className="filter-element"
							overlay={
								<Menu>
								</Menu>
							}>
							<p>Subrubros<Arrow className="arrow" /></p>
						</Dropdown>
					</Col>
					<Col xs={12} lg={2} className="center">
						<Button type="link" size="small">
							<Download />
						</Button>
					</Col>

				</Row>
				<section className="p-1">
					<div className="scroll">
						<Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
							<Col span={4} className="center">
								<Text type="secondary">NOMBRE</Text>
							</Col>
							<Col span={3} className="center">
								<Text type="secondary">APELLIDO</Text>
							</Col>
							<Col span={4} className="center">
								<Text type="secondary">EMAIL</Text>
							</Col>
							<Col span={3} className="center">
								<Text type="secondary">TELÉFONO</Text>
							</Col>
							<Col span={4} className="center">
								<Text type="secondary">ESTATUS</Text>
							</Col>
							<Col span={3} className="center">
								<Text type="secondary">COMUNICACIÓN</Text>
							</Col>
							<Col span={3} className="center">
								<Text type="secondary">ACCIONES</Text>
							</Col>
						</Row>

						<List
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.prospectos}
							loading={this.state.loading}
							locale={{ emptyText: 'Sin Prospectos' }}
							renderItem={item =>
							(<List.Item className="component-list-item">
								<Card className="card-list">
									<Row style={{ width: '100%' }} className="">

										<Col span={4} className="center">
											<Text strong>{item.nombre}</Text>
										</Col>
										<Col span={3} className="center">
											<Text>{item.apellidos}</Text>
										</Col>
										<Col span={4} className="center">
											<Text>{item.email}</Text>
										</Col>
										<Col span={3} className="center">
											<Text>+{item.lada_pais}{item.telefono}</Text>
										</Col>
										<Col span={4} className="center">
											<span className="span-estatus" style={{ backgroundColor: item.estatus_id?.color }}>
												{item.estatus_id?.nombre}
											</span>
										</Col>
										<Col span={3} className="center">
											<Link to={"/admin/ventas/prospectos/mensaje/" + item._id}>
												<Button type="primary" className={item.mb_conversacion_id ? "btn btn-whatsapp-1" : "btn btn-whatsapp-2"}>
													<IconWhatsapp />
												</Button>
											</Link>

											<Tooltip placement="top" title={this.getTooltipText(item.email_status)}>
												<Button type="primary" className="btn btn-mail-2" onClick={e => this.setState({
													modalEmailVisible: true,
													prospecto_id: item._id
												})} style={{ backgroundColor: this.getColor(item.email_status) }}>
													<IconMailOpen />
												</Button>
											</Tooltip>

										</Col>
										<Col span={3} className="center">
											<Link>
												<Button
													type="primary"
													className="button-survey btn-eye button-eye" title="Ver detalle"
													onClick={(e) => { this.setState({ modalProspectosVisible: true, modalTitle: 'Ver', prospecto_id: item._id }) }}>
													<IconEye />
												</Button>
											</Link>
											<Button className="btn btn-edit" onClick={(e) => { this.setState({ modalProspectosVisible: true, modalTitle: 'Editar', prospecto_id: item._id }) }}>
												<IconEdit />
											</Button>
											<Popconfirm
												placement="topRight"
												title={"¿Deseas eliminar a " + item.nombre + " ?"}
												onCancel={e => e.stopPropagation()}
												okText="Si"
												cancelText="No"
												onClick={e => e.stopPropagation()}
												onConfirm={e => { this.deleteProspecto(item._id) }}
											>
												<Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
													<IconDelete />
												</Button>
											</Popconfirm>

										</Col>
									</Row>
								</Card>
							</List.Item>
							)}
						/>
					</div>
					<Row className="mt-2 pb-3">
						<Pagination
							total={this.state.total}
							current={this.state.page}
							onChange={(page) => {
								this.listProspectos(page)
							}}
						/>
					</Row>
				</section>
				<Button
					className="btnAddPlus"
					onClick={() => this.setState({ modalProspectosVisible: true, modalTitle: 'Añadir' })}>
					<IconPlus />
				</Button>
				<ModalProspectos
					modalVisible={this.state.modalProspectosVisible}
					closeMethod={this.hideModal}
					modalTitle={this.state.modalTitle}
					prospecto_id={this.state.prospecto_id}
					spanEstatus={this.spanEstatus}
				/>
				<ModalEmailProspectos
					modalVisible={this.state.modalEmailVisible}
					prospecto_id={this.state.prospecto_id}
					closeMethod={this.hideModal}
				/>
			</div>
		)
	}
}