import React, { Component } from 'react';
import { Col, Row, Typography, Button, Spin, Collapse, Card, Popconfirm, Select, List, InputNumber, message, Modal, Carousel,Result, Tag } from 'antd'


import Logged from "../../../../Hooks/Logged";

// iconos
import { IconoMaquinaRegistradoraParaElGuardadoNominazFinanzas as IconNominas, IconEye } from '../../../Widgets/Iconos'
import { FaMinus } from "react-icons/fa";

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { IconArrowBack } from '../../../Widgets/Iconos'

// estilos
import '../../../../Styles/nominas.css'

import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import {Img} from 'react-image'
const { Title, Paragraph } = Typography
const { Panel } = Collapse;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class RevisionDeEmpleado
 * @extends {Component}
 *
 * @description Vista para la revisión de empleados con sus grupos.
 */
export default class RevisionEmpleado extends Component {

    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            employee: [],
            data: [],
            loading: false,
            processing: false,
            accounts: [],
            nomina_id: '',
            employee_groups: [],

            images: [],
            loadingImage: false
        }
    }

    /**
     * @memberof componentDidMount
     * @description Manda a llamar a la carga de datos del empleado y las cuentas para el select
     */
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        let id_nom = this.props.match.params.nomina_id;
        let id_employee = this.props.match.params.id;
        this.setState({ nomina_id: id_nom });
        this.getEmpleadoInfo(id_nom, id_employee)
        this.loadCuentas()
    }

    /**
     * @memberof RevisionEmpleado
     * @method getEmpleadoInfo
     * @description Trae la informacion del empleado
     */
    getEmpleadoInfo(id_nom = this.props.match.params.nomina_id, id_empl = this.props.match.params.id) {
        this.setState({ loading: true });


        axios.get((this.props.finanzas) ? '/nominas/revisiones/finanzas/empleado' : '/nominas/revisiones/empleado', {
            params: {
                nomina_id: id_nom,
                empleado_id: id_empl,
                finanzas: this.props.finanzas
            }
        })
            .then(res => {
                this.setState({
                    employee: res.data.empleado,
                    employee_groups: res.data.data.grupos.map(i => {

                        if (i.monto)
                            i.autorizado_final = i.monto;
                        else {
                            i.autorizado_final = 0;
                            i.monto = 0;
                        }
                        i.monto_deuda = 0;
                        i.grupo_id = i._id;
                        return i
                    }),
                    data: res.data.data,
                    loading: false
                });

            })
            .catch(({ response }) => {
                this.setState({
                    // employee: response.data.empleado,
                    data: [],
                    employee_groups: [],
                    loading: false
                });
            })
    }

    /**
     * @memberof RevisionEmpleados
     * @method loadCuentas
     * @description Trae la lista de cuentas
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200
            }
        })
            .then(res => {
                this.setState({ accounts: res.data.data.itemsList });
            })
            .catch(error => {
                console.log('lista cuentas error', error);
            })
    }


    /**
     * @memberof RevisionEmpleado
     *
     * @function updateMonto
     * @description Actualizamos el monto de ese valor en especifico.
     *
     * @param {*} value
     *
     * @returns
     */
    updateMonto = (value, id) => {
        let employee_groups = this.state.employee_groups;

        //Si es finanzas, se actualiza el monto real, caso contrario se actualiza el autorizado final ya que el dueño esta revisando
        employee_groups[id].monto = parseFloat(value)
        if (this.props.finanzas) {

            if (employee_groups[id].monto < employee_groups[id].monto_autorizado) {
                employee_groups[id].monto_deuda = employee_groups[id].monto_autorizado - employee_groups[id].monto

                //actualizo los metros trabajados por el monto escrito
                employee_groups[id].m2_trabajados = parseFloat(parseFloat(value) / parseFloat(employee_groups[id].precio_m2).toFixed(2))

            } else employee_groups[id].monto_deuda = 0
        }

        employee_groups[id].m2_trabajados = parseFloat(value) / parseFloat(employee_groups[id].precio_m2);

        this.setState({ employee_groups });
    }

    /**
     * @memberof RevisionEmpleado
     *
     * @function updateMonto
     * @description Actualizamos el monto de ese valor en especifico.
     *
     * @param {*} value
     *
     *
     * @returns
     */
    UpdateCuenta = (value, id) => {
        let group = this.state.employee_groups[id];
        group.cuenta_id = value;
        this.setState({ group })
    }

    /**
     *
     *
     * @memberof RevisionEmpleados
     * @description Elimina el grupo del empleado.
     *
     * @param i => Index del grupo a eliminar.
     */
    deleteEmpleadoGrupo = (i) => {
        let group = this.state.employee_groups[i];
        axios.post('/nominas/registrar/desatajo/eliminar', {
            nomina_id: this.state.nomina_id,
            grupo_id: group._id,
            empleado_id: this.state.employee._id,
            cuenta_id: group.cuenta,
            autorizado_final: group.autorizado_final,
        })
            .then(res => {
                message.success('Eliminado!');
                this.getEmpleadoInfo();
            })
            .catch(error => {
                message.error('Ha ocurrido un error al eliminar el grupo');
            })
    }


    /**
     *
     *
     * @memberof RevisionEmpleados
     * @method SucessPagoGrupo
     * @description Manda la peticion al WS para guardar los cambios del pago para el grupo que se asigno el pago
     *
     * @param index (indice) del elemento de la lista seleccionado
     */
    SucessPagoGrupo = (index) => {
        const user = this.context;

        let group = this.state.employee_groups[index];
        if (this.props.finanzas && (!group.cuenta_id || (group.monto === undefined || group.monto === null))) {
            return Modal.error({
                title: "Debe indicar la cuenta y la cantidad",
                content: "Es necesario disponer de la cuenta y el monto autorizado para aceptar la cantidad. "
            })
        }
        if (!this.props.finanzas && !group.monto) {
            return Modal.error({
                title: "Debe indicar la cantidad",
                content: "Es necesario disponer del monto autorizado para aceptar la cantidad. "
            })
        }
        let url = '';
        // boss
        if (this.props.finanzas)
            url = '/nominas/registrar/finanzas/destajo'
        else
            url = '/nominas/revision/destajo/final'

        this.setState({ loading: true })
        axios.post(url, {
            nomina_id: this.state.nomina_id,
            grupo_id: group._id,
            empleado_id: this.state.employee._id,
            cuenta_id: group.cuenta_id,
            monto_pago: group.monto,
            monto_deuda: group.monto_deuda,
        })
            .then(res => {
                message.success('Registro correcto!');
                this.getEmpleadoInfo()
            })
            .catch(error => {
                if (error.response.error === 1) {
                    message.error('No se ha encontrado un SubRubro con el nombre del grupo');
                } else {
                    message.error('Ha ocurrido un error');
                }
                this.getEmpleadoInfo()
            })

    }



    /**
    *
    *
    * @memberof RevisionDeGrupo
    *
    *
    * @function Actualizamos el valor de metros cuadrados de un empleado en especifico
    * @param {*} value
    * @param id Localización del empleado en el arreglo.
    *
    */
    updatePaymentOnSquareMeter = (value, id) => {
        let { employee_groups } = this.state;
        if (this.props.finanzas) {
            employee_groups[id].m2_trabajados = (value)
            employee_groups[id].monto = parseFloat(value) * parseFloat(employee_groups[id].precio_m2)

            if (employee_groups[id].monto < employee_groups[id].monto_autorizado) {
                employee_groups[id].monto_deuda = parseFloat((parseFloat(employee_groups[id].monto_autorizado) - parseFloat(employee_groups[id].monto)).toFixed(2))
            } else
                employee_groups[id].monto_deuda = 0
        } else {
            if (value !==null && value !==undefined && value !=='') {
                employee_groups[id].monto = parseFloat((parseFloat(value) * parseFloat(employee_groups[id].precio_m2)).toFixed(2))
                employee_groups[id].m2_trabajados = parseFloat(value)
            } else
                employee_groups[id].monto = 0

        }

        employee_groups[id].m2_trabajados = value
        this.setState({ employee_groups })
    }


    /**
    *
    *
    * @memberof RevisionDeGrupo
    *
    *
    * @function Actualizamos el valor de cuadrados de un empleado en especifico
    * @param {*} value
    * @param id Localización del empleado en el arreglo.
    *
    */
    updateMetersOnPayment = (value, id) => {
        let { employee_groups } = this.state;
        if (this.props.finanzas === true) {

            employee_groups[id].monto = parseFloat((parseFloat(employee_groups[id].precio_m2) / parseFloat(value)).toFixed(2))

            if (employee_groups[id].monto < employee_groups[id].monto_autorizado) {
                employee_groups[id].monto_deuda = parseFloat((parseFloat(employee_groups[id].monto_autorizado) - parseFloat(employee_groups[id].monto)).toFixed(2))
            } else
                employee_groups[id].monto_deuda = 0

            this.setState({ employee_groups })
        } else {
            if (value !==null && value !==undefined && value !=='') {
                employee_groups[id].monto = parseFloat((parseFloat(employee_groups[id].precio_m2) / parseFloat(value)).toFixed())
                employee_groups[id].monto = parseFloat(value)
            } else
                employee_groups[id].monto = 0
            this.setState({ employee_groups })
        }

    }




    /**
   *
   *
   * @memberof RevisionDeGrupo
   *
   *
   * @function onSave
   *
   */
    onSave = () => {

        let { employee_groups } = this.state;

        axios.post('/nominas/revision/destajo/final', {
            nomina_id: this.state.nomina_id,
            empleado_id: this.state.employee._id,
            grupos: employee_groups
        })
            .then(res => {

                message.success('Registro correcto!');
                this.getEmpleadoInfo()
            })
            .catch(error => {
                message.error('No se ha encontrado un SubRubro con el nombre del grupo');
            })

    }



    carousel = React.createRef();



    render() {

        const { onSave } = this;

        const user = this.context;//usuario logeado

        const nomina_id = this.state.nomina_id;
        let redirect;
        if (this.props.finanzas) {
            redirect = '/admin/nomina/administrar-nomina-finanzas/' + nomina_id
        }
        else {
            redirect = '/admin/nomina/administrar-nomina/' + nomina_id
        }
        return (
            <div className="content">
                {/* Back Button */}
                <Row className="row-encabezado">
                    <Col xs={24} xl={8} xxl={12}>
                        <h2>
                            <Button type="link" style={{ display: 'inline' }}>
                                <Link to={redirect + '/Destajo'} >
                                    <IconArrowBack />
                                </Link>
                            </Button>
                            {this.state.employee.nombre} {this.state.employee.apellido}
                        </h2>
                    </Col>
                </Row>

                {/* Card Employee Info */}
                <Row style={{ padding: '24px' }} className="content">
                    <Spin spinning={this.state.loading}>

                        <Col span={24} style={{ paddingTop: 0 }}>
                            <Card className="content-card">
                                <Row className="information-widgets-content information-destajo">
                                    <Col span={24}>
                                        <Title level={3}
                                            style={{ color: "#0047FF" }}>{this.state.employee.nombre} {this.state.employee.apellido}</Title>
                                    </Col>
                                    <Col span={6}>
                                        <Paragraph className="information-widgets-content-title">Nombre</Paragraph>
                                        <Paragraph
                                            className="information-widgets-content-description">{this.state.employee.nombre}</Paragraph>
                                    </Col>
                                    <Col span={6}>
                                        <Paragraph className="information-widgets-content-title">Apellido</Paragraph>
                                        <Paragraph
                                            className="information-widgets-content-description">{this.state.employee.apellido}</Paragraph>
                                    </Col>
                                    <Col span={6}>
                                        <Paragraph className="information-widgets-content-title">Email</Paragraph>
                                        <Paragraph className="information-widgets-content-description">{this.state.employee.email}</Paragraph>
                                    </Col>
                                    <Col span={6}>
                                        <Paragraph className="information-widgets-content-title">Grupos</Paragraph>
                                        <Paragraph
                                            className="information-widgets-content-description">{(this.state.data.gruposCantidad > 0) ? this.state.data.gruposCantidad : '0'}</Paragraph>
                                    </Col>
                                    <Col span={6}>
                                        <Paragraph className="information-widgets-content-title">Monto Pendiente
                                            Total</Paragraph>
                                        <Paragraph
                                            className="information-widgets-content-description">{(this.state.data.montoPendienteTotal > 0) ? this.state.data.montoPendienteTotal.toString().toMoney() : '$ 0.00'}
                                            <small>MXN</small></Paragraph>
                                    </Col>
                                </Row>
                            </Card>

                        </Col>
                    </Spin>
                </Row>
                {/* List Groups Employee */}
                <Row className="list-container" style={{ padding: '24px' }}>
                    <Col span={24}>
                        <Col span={18}>
                            <Title level={3} style={{ color: "#0047FF" }}>Grupos</Title>
                        </Col>
                        <List
                            itemLayout="horizontal"
                            dataSource={this.state.employee_groups}
                            className="list-employees"
                            loading={this.state.loading}
                            locale={{ emptyText: 'No hay grupos pendientes de revisión' }}
                            header={
                                <Row className="list-employee-item" style={{ width: '100%' }}>

                                    <Col span={!this.props.finanzas ? 4 : 3}>
                                        Nombre del Grupo
                                    </Col>
                                    <Col span={!this.props.finanzas ? 4 : 3}>
                                        Precio por metro (m<sup>2</sup>)
                                    </Col>
                                    <Col span={!this.props.finanzas ? 4 : 3}>
                                        Metros Trabajados
                                    </Col>
                                    {/*Solo para Finanzas  */}
                                    <Col span={!this.props.finanzas ? 4 : 3}>
                                        {this.props.finanzas ? "Monto a pagar" : "Monto a autorizar"}
                                    </Col>
                                    <Col span={!this.props.finanzas ? 5 : 3}>
                                        Monto autorizado
                                    </Col>

                                    {(this.props.finanzas) ?
                                        <Col span={!this.props.finanzas ? 5 : 3}>
                                            Pago pendiente
                                        </Col> : null
                                    }
                                    {/*Solo para Finanzas  */}
                                    {(this.props.finanzas) ?
                                        <Col span={this.props.finanzas ? 4 : 2}>
                                            Cuenta
                                        </Col> : ''
                                    }
                                    <Col span={!this.props.finanzas ? 3 : 2} style={{ display: 'flex' }}>
                                        Acciones
                                    </Col>
                                </Row>
                            }
                            renderItem={(item, index) => (
                                <List.Item className="list-employees-item-content">
                                    <Row className="list-employee-item" style={{ width: '100%' }}>
                                        <Col span={!this.props.finanzas ? 4 : 3}>
                                            {item.nombre}
                                        </Col>
                                        <Col span={!this.props.finanzas ? 3 : 3}>
                                            $ {item.precio_m2.toString().toMoney()}
                                        </Col>
                                        {/* Metros trabajados */}
                                        <Col span={!this.props.finanzas ? 3 : 3}>
                                            <InputNumber
                                                value={item.m2_trabajados}
                                                className="input-ghost"
                                                formatter={value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={e => this.updatePaymentOnSquareMeter(e, index)}
                                                style={{
                                                    width: 'calc(100% - 20px)',
                                                    maxWidth: '150px',
                                                    textAlign: 'center',
                                                    margin: '0px auto'
                                                }}
                                                precision={5}
                                            />
                                        </Col>
                                        {/*Solo para Finanzas  */}
                                        {/* Monto a autorizar */}
                                        <Col span={!this.props.finanzas ? 4 : 3}>
                                            <InputNumber
                                                value={item.monto}
                                                precision={2}
                                                className="input-ghost"
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={e => this.updateMonto(e, index)}
                                                style={{
                                                    width: 'calc(100% - 20px)',
                                                    maxWidth: '150px',
                                                    textAlign: 'center',
                                                    margin: '0px auto'
                                                }}
                                            />
                                        </Col>
                                        <Col span={!this.props.finanzas ? 4 : 3}>
                                            $ {(this.props.finanzas) ? (item.monto_autorizado ? item.monto_autorizado.toString().toMoney() : 0) : (item.autorizado_final ? item.autorizado_final.toString().toMoney() : 0)} <small>MXN</small>
                                        </Col>

                                        {(this.props.finanzas) ?
                                            <Col span={!this.props.finanzas ? 4 : 3}>
                                                $ {item.monto_deuda.toString().toMoney()} <small>MXN</small>
                                            </Col> : null
                                        }
                                        {/*Solo para Finanzas  */}
                                        {(this.props.finanzas) ?
                                            <Col span={this.props.finanzas ? 3 : 2}>
                                                <Select style={{ maxWidth: '200px', width: '100%' }} placeholder="Cuenta" onChange={e => this.UpdateCuenta(e, index)} value={item.cuenta_id}>
                                                    {
                                                        this.state.accounts.map(function (account, i) {
                                                            return <Option value={account._id}>{account.nombre}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Col> : ''
                                        }
                                        {(!this.props.finanzas)?<Col  span={!this.props.finanzas ? 3 : 2} >
                                            <Tag>{item.area_id.nombre}</Tag>
                                        </Col>:null}
                                        <Col span={!this.props.finanzas ? 3 : 2} style={{ display: 'flex' }}>
                                            {this.props.finanzas ? <Button
                                                type="primary"
                                                className="btn-morado"
                                                icon={<IconNominas />}
                                                onClick={() => this.SucessPagoGrupo(index)} /> : null}

                                            {(this.props.finanzas === true && user.tipo === 1) ?
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Estas seguro de eliminar este empleado?"
                                                    okText="Si, seguro"
                                                    cancelText="No"
                                                    onConfirm={() => this.deleteEmpleadoGrupo(index)}>
                                                    <Button className=" btn-minus" icon={<FaMinus color="white" />} />
                                                </Popconfirm>
                                                : null}
                                            {
                                                ((Array.isArray(item.fotos) && item.fotos.length > 0) ?
                                                    <Button onClick={() => {
                                                        this.setState({
                                                            modalCarouselViewer: true,
                                                            images: item.fotos
                                                        })

                                                    }} className="btn-view" icon={<IconEye color="white" style={{ position: 'relative', bottom: '2px', left: 1 }} />} />
                                                    : null)
                                            }
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        />

                    </Col>
                </Row>
                {
                    (this.props.finanzas !== true) ? <Button type="ghost" className="btn-save" onClick={() => onSave()} disabled={this.state.uploadingImages}>
                        {this.state.uploadingImages ? <Spin style={{ position: 'relative', top: '3px' }} spinning={true} /> : <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41 3.82586C40.9961 5.11749 40.5257 6.0772 39.6873 6.8994C34.9386 11.551 30.1977 16.211 25.4539 20.8683C22.7917 23.4819 20.1315 26.0964 17.4674 28.7072C15.7045 30.436 13.2626 30.4284 11.5017 28.6996C8.07832 25.3359 4.64628 21.9807 1.23163 18.6094C0.0773175 17.4695 -0.282018 16.0897 0.223177 14.5581C0.722575 13.0446 1.82763 12.1389 3.42242 11.8829C4.80084 11.661 5.98606 12.1019 6.9723 13.0721C9.34759 15.4078 11.7316 17.736 14.0982 20.0802C14.3889 20.3685 14.5425 20.4197 14.87 20.0964C21.2511 13.8089 27.6476 7.53478 34.0422 1.25968C35.1994 0.123585 36.5846 -0.277558 38.1533 0.193762C39.6795 0.651805 40.6001 1.7054 40.9324 3.24074C40.9826 3.47119 40.9874 3.71017 41 3.82586Z" fill="white" />
                        </svg>} </Button> : null
                }

                <Modal
                    visible={this.state.modalCarouselViewer}
                    onCancel={() => this.setState({ modalCarouselViewer: false })}
                    className="image-previewer empleado"
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    destroyOnClose={true}
                >
                    <Carousel
                        dots={true}
                        slidesToShow={1}
                        style={{ width: '100%', minWidth: '100%' }}
                        ref={this.carousel}
                        autoplay={false}

                    >
                        {this.state.images.map((e, index) => <div>
                            <div onClick={() => {
                                this.setState({
                                    modalImagePreview: true,
                                    imageURL: axios.defaults.baseURL + '/upload/' + e.name
                                })
                            }} >
                                <span style={{ fontSize: 1, color: 'white' }}>1</span>
                                <Img
                                    className="image-preview-empleado"
                                    src={axios.defaults.baseURL + '/upload/' + e}
                                    loader={<Spin
                                        style={{
                                            display: 'block',
                                            margin: '0px auto',
                                            minWidth: '100px',
                                            minHeight: '100px'
                                        }}

                                        message={"Estamos cargando tu imagen, espera por favor. "}


                                        spinning={true} />}
                                    unloader={  <Result
                                        status="warning"
                                        title="No fue posible cargar la imagen."
                                        extra={
                                            <Button type="primary" key="console" onClick={() => this.setState({ modalCarouselViewer: false })}>
                                                Cerrar
                                            </Button>
                                        }
                                    />}
                                    style={{
                                        display: 'block',
                                        margin: '0px auto',
                                        minWidth: '100px',
                                        minHeight: '100px'
                                    }}
                                />
                                {/*<img*/}
                                {/*    className="image-preview-empleado"*/}
                                {/*    onError={(e)=>{*/}
                                {/*        e.target.onerror = null;*/}
                                {/*        e.target.src="/images/error.png"*/}
                                {/*        e.target.style.maxWidth = '200px'*/}
                                {/*        e.target.style.margin = '0px auto'*/}
                                {/*    }}*/}
                                {/*    src={axios.defaults.baseURL + '/upload/' + e} style={{*/}
                                {/*    display: 'block',*/}
                                {/*    maxWidth: 'calc(100% - 10px)',*/}

                                {/*}} />*/}
                            </div>
                        </div>)}

                    </Carousel>
                    <Button onClick={e => this.carousel.current.prev()} className="carousel-button left-control" type="dashed" icon={<LeftOutlined style={{ color: "white" }} />}></Button>



                </Modal>
            </div>
        )
    }
}
