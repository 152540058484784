import React, { Component, useContext } from 'react';
import { Card, Avatar, Badge, Button, Col, Row, Comment, Input, Typography, Tooltip, Modal, Radio, message, Spin, Form, Select } from 'antd'

//componentes
import { IconAttach, IconImage, IconSend, IconArrowBack } from '../../Widgets/Iconos'
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import Logged from '../../../Hooks/Logged';
import { CheckOutlined, CheckCircleOutlined, StopOutlined, FileProtectOutlined } from '@ant-design/icons';
//css
import './../../../Styles/ventas.css';
import socketIOClient from 'socket.io-client'

const moment = require("moment");
const { TextArea } = Input;
const { Title, Text } = Typography;
const axios = require('axios').default
const { Option } = Select;
let socket = {}
let server = ''
/**
 *
 *
 * @class MensajesWhatsapp
 * @extends {Component}
 * 
 * @description Componente de chat para hablar con el prospecto por medio de whatsapp
 */
class MensajesWhatsapp extends Component {

    chatContainer = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            prospecto: {
                nombre: '',
                apellidos: '',
                telefono: '',
                lada_pais: ''

            },
            showList: 'all',
            loadingUsers: false,
            mensajes: [],
            chat: [],
            id_conversacion: '',
            validacion: false
        }
    }
    /**
     * @memberof MensajesWhatsapp
     *
     * @method   componentDidMount
     * @description  
     *
     **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.listProspectos(1)
        this.scrollToMyRef();
        server = axios.defaults.baseURL;
        socket = socketIOClient(server);
    }

    componentWillUnmount() {
        socket.emit('disconnect') //se envia el id del socket al servidor
    }


    actualizar = true;
    configurarSocket = () => {
        console.log('configuramos socket', this.state.prospecto)
        if (this.actualizar === true && this.state.prospecto.mb_conversation_id !==undefined && this.state.prospecto.mb_conversation_id !=='') {
            this.actualizar = false;
            console.log('SOCKETS', socket)
            socket.connect(); //Se conecta el s al servidor
            socket.emit('conversacion', this.state.prospecto.mb_conversation_id) //se envia el id del socket al servidor
            socket.on("webhook", data => {

                this.actualizar = true;
                console.log('Webhook desd esocket')
                //Hubo un mensaje nuevo y se buscan de nuevo 
                this.listProspectos()
            });
        }
    }


    testMensajesList = () => {
        axios.get('/mensajes/list')
            .then(response => {
                console.log('Alo aaa', response.data.data)
                this.setState({
                    mensajes: response.data.data
                })
            })
            .catch(error => {
                console.log(error)
                message.error('No se pudo enviar el mensaje')
                this.setState({ loadingUsers: false })
            })




    }


    /**
     * @methodOf  MensajesWhatsapp
     * @function listProspectos
     *
     * @description Lista los prospectos
     *
     * */
    listProspectos = (page) => {
        this.setState({ loadingUsers: true })

        console.log('params', this.props.match)
        axios.post('/mensajes/conversacion/start', {
            prospecto_id: this.props.match.params.id

        }).then(response => {
            console.log('response.data', response)
            this.setState({
                chat: response.data.data,
                prospecto: response.data.prospecto,
                loadingUsers: false
            })


            this.configurarSocket()
            this.scrollToMyRef()
        })
            .catch(error => {
                console.log(error)
                message.error('No se cargaron los Prospectos')
                this.setState({ loadingUsers: false })
            })


    };

    /**
    * @memberof MensajesWhatsapp
    *
    * @method   volver
    * @description  regresa a la vista anterior
    *
    **/
    volver = () => {
        window.history.back();
    }

    /**
    * @memberof MensajesWhatsapp
    *
    * @method   scrollToMyRef
    * @description  cada vez que se llama a aeste metodo, el scroll del chat baja hasta el limite
    *
    **/
    scrollToMyRef = () => {
        const scroll =
            this.chatContainer.current.scrollHeight -
            this.chatContainer.current.clientHeight;
        this.chatContainer.current.scrollTo(0, scroll);
    };

    /**
    * @memberof MensajesWhatsapp
    *
    * @method   onChange
    * @description  cambai la lista de cards de usuarios a renderizar
    *
    **/
    onChange = (value) => {
        this.setState({ showList: value.target.value })
    }

    /**
     * @memberof MensajesWhatsapp
     * @description Envia una plantilla a la conversacion del usuario con el valor seleccionado
     */
    enviarPlantilla = (values) => {
        console.log('values', values)


        axios.post('/mensajes/plantilla', {
            prospecto_id: this.state.prospecto._id,
            plantilla_id: values.plantilla_id
        })
            .then(response => {
                message.success('Mensaje enviado correcamente')
            })
            .catch(error => {

                message.error('Error al enviar la plantilla.')
            })


    }


    /**
    * @memberof MensajesWhatsapp
    *
    * @method   renderCards
    * @description renderiza la card de informacion del usuario del lado izquierdo
    *
    **/
    renderCards = () => {
        let { prospecto } = this.state;
        return <Card className="card-mensaje">
            <Row className="row-top" >
                <Col span={5} className="center">
                    <CustomAvatar
                        color={"#E6EDF1"}
                        size="large"
                    />
                </Col>
                <Col span={18} className="flex-left">
                    <Text>{prospecto.nombre} {prospecto.apellidos} </Text>
                </Col>
                <Col span={1} className="center">
                    <Badge status="success" />
                </Col>
            </Row>
            <Row span={24} className="">

                <Form
                    layout="horizontal"
                    name="formulario-plantillas"
                    onFinish={this.enviarPlantilla}
                    style={{ width: "100%" }}
                >
                    <Row >

                        <Col span={15}>
                            <Form.Item
                                label="Plantilla "
                                name="plantilla_id"//----------------------------------------------------------proyecto_id
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione una plantilla"
                                }]}
                            >
                                <Select

                                    filterOption={false}
                                >
                                    <Option value={"continuar_conversacion"}>
                                        {"Continuar chat"}
                                    </Option>)
                                        <Option value={"info_conversacion"}>
                                        {"Iniciar chat"}
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col push={1} span={4} >
                            <Button htmlType="submit" type="primary" className="btn-azul">
                                Enviar
								</Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </Card>
    }



    /**
     * @memberof MensajesWhatsapp
     * @description 
     */
    renderMessages = (id_conversacion) => {

        axios.get('/mensajes/conversacion/get', {
            params: {
                id_conversacion: id_conversacion
            }
        })
            .then(response => {
                console.log('chat', response.data.data)
                this.setState({
                    chat: response.data.data,
                    id_conversacion: id_conversacion
                })

            })
            .catch(error => {
                console.log(error)
                message.error('No se cargaron los mensajes de WA')
                this.setState({ loadingUsers: false })
            })

    }



    /**
     *
     *
     * @memberof MensajesWhatsapp
     */
    reply = (values) => {
        this.formReply.setFieldsValue({ content: '' })
        axios.post('/mensajes/conversacion/reply', {
            text: values.content,
            id_conversacion: this.state.prospecto.mb_conversation_id
        })
            .then(success => {
                this.listProspectos()

            })
            .catch(error => {
                console.log(error)
                message.error('No se cargaron los mensajes de WA')
                this.setState({ loadingUsers: false })
            })

    }

    render() {

        let { user } = this.props;
        let { prospecto } = this.state
        return (
            <div style={{ minHeight: '100' }}>
                <Form ref={e => this.formReply = e } onFinish={this.reply}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={6} xxl={8}>
                            <Button className="IconArrowBack" onClick={this.volver} style={{ top: "12px" }}>
                                <IconArrowBack />
                            </Button>
                            <h2 style={{ width: 'auto', float: 'left' }}>Whatsapp</h2>
                        </Col>
                    </Row>
                    <section className="mensajes-ctn" style={{ paddingTop: '1rem', paddingRight: '1rem', paddingBottom: '1rem' }}>
                        <Row>
                            <Col xs={24} xl={8} className="col-users">
                                <Row style={{ paddingLeft: '1rem' }}>
                                    <Radio.Group onChange={value => { this.onChange(value) }} defaultValue={this.state.showList} className="radio-chat">
                                        <Radio.Button value="actives">Activos</Radio.Button>
                                        <Radio.Button value="all">Todas</Radio.Button>
                                    </Radio.Group>
                                </Row>
                                <Spin spinning={this.state.loadingUsers}>
                                    <div className="div-user-cards">
                                        {this.state.showList === 'actives' ? this.renderCards() : this.renderCards()}
                                    </div>
                                </Spin>
                            </Col>
                            <Col xs={24} xl={16} className="">
                                <Card className="card-chat">
                                    <Row>
                                        <Col xs={4} md={3} className="center">
                                            <CustomAvatar
                                                color={"#E6EDF1"}
                                                size="large"
                                            />
                                        </Col>
                                        <Col xs={20} md={19} className="flex-left">
                                            <Text className="">{prospecto.nombre + ' ' + prospecto.apellidos}</Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} ref={this.chatContainer} className=" col-body-chat">
                                            {
                                                this.state.chat.map(function (value, index) {
                                                    if (value.error === undefined && prospecto)
                                                        return <Comment
                                                            className="comment-chat"
                                                            author={(value.from === ('+' + prospecto.lada_pais + '1' + prospecto.telefono)) ? prospecto.nombre : 'Tú'}
                                                            content={
                                                                <div>

                                                                    <Row className="">
                                                                        <Col xs={4} md={3} className="col-avatar">
                                                                            <CustomAvatar
                                                                                color={"#E6EDF1"}
                                                                                size="large"
                                                                            />
                                                                        </Col>
                                                                        <Col xs={19} md={21}>
                                                                            {(value.content.text) ? value.content.text : 'PLANTILLA : ' + value.content.hsm.templateName}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Tooltip key="comment-basic-like" title={value.status}>
                                                                            <span >
                                                                                {((value.status === 'received' || value.status === 'sent' || value.status === 'delivered') ? <CheckOutlined /> : (value.status === 'read') ? <CheckCircleOutlined /> : <StopOutlined />)}
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Row>
                                                                </div>
                                                            }
                                                            datetime={
                                                                <Tooltip title={moment(value.createdDatetime).format('YYYY-MM-DD HH:mm:ss')}>
                                                                    <span>{moment(value.createdDatetime).fromNow()}</span>
                                                                </Tooltip>
                                                            }
                                                        />
                                                })
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} className=" ">
                                            <Row className="mensajes-opciones">
                                                <Col xs={4} md={3} className="center">
                                                    <CustomAvatar color={"#0047FF"} size="large" />
                                                </Col>
                                                <Col xs={15} md={16} className="flex-left">
                                                    <Form.Item name={'content'} style={{ width: '100%' }}>
                                                        <Input placeholder="Escribe tu mensaje aqui..." style={{ width: '100%' }}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={5} md={5} className="mensajes-opciones-btns">
                                                    <Form.Item>
                                                        <Button title="Enviar" htmlType="submit" onClick={this.scrollToMyRef}>
                                                            <IconSend />
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </section>
                </Form>
            </div>
        );
    }
}

export default function (props) { return <MensajesWhatsapp {...props} user={useContext(Logged)} /> }

//(props => )

