import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, Popconfirm, message, Pagination} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconEye, IconEdit,  IconDelete, IconPlus, IconArrowBack, IconOpen } from '../../Widgets/Iconos';
//modal
import ModalProformaMaterial from '../Modales/Presupuesto/ModalProformaMaterial';
//css
import '../../../Styles/presupuesto.css'

const { Title, Text } = Typography;
const axios = require('axios').default
const moment = require('moment');

export default class DetalleMaterialConcepto extends Component{

	constructor(props){
		super(props)
		this.state = {
			proforma_id: this.props.match.params.proforma_id,
			proforma_concepto_id: this.props.match.params.proforma_concepto_id,
			ModalProformaMaterialVisible: false,
			materiales:[],
			concepto:{},
			material_edit: undefined,
			/* Paginado */
			page: 1,
			total: 0,

		}
	}

	volver = () => {
        window.history.back();
    }


	/**
	* @memberof DetalleMaterialConcepto
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.listMateriales();
	}

	/**
	 * @methodOf  DetalleMaterialConcepto
	 * @function hideModal
	 *
	 * @description Cierra el modal
	 *
	 * */
	hideModal = () => {
		this.setState({ ModalProformaMaterialVisible: false, material_edit: undefined });
		this.listMateriales(this.state.page)
	};

	/**
	 * @methodOf  DetalleMaterialConcepto
	 * @function listMateriales
	 *
	 * @description lista los materiales del concepto
	 *
	 * */
	listMateriales = (page=1) => {
		this.setState({loading: true})
		axios.get('/proforma/conceptos/materiales/list',{
			params:{
				page: page,
				limit: 10,
				proforma_id: this.state.proforma_id,
				proforma_concepto_id: this.state.proforma_concepto_id,
			}
		}).then(response => {
			this.setState({
				materiales: response.data.data.materiales.itemsList,
				page: response.data.data.materiales.currentPage,
				total: response.data.data.materiales.itemCount,
				concepto: response.data.data.concepto,
				loading: false,
			})
		}).catch(error => {
			console.log(error)
			message.error('Error al cargar la información')
		})
	};

	/**
	 * @methodOf  DetalleMaterialConcepto
	 * @function deleteMeterial
	 *
	 * @description elimina un material
	 *
	 * */
	deleteMeterial = (id) => {
		this.setState({loading: true})
		axios.post('/proforma/conceptos/materiales/delete',{
			proforma_id: this.state.proforma_id,
			proforma_concepto_id: this.state.proforma_concepto_id,
			proforma_material_id: id
		}).then(response => {
			message.success('Material eliminado')
			this.listMateriales(this.state.page)
		}).catch(error => {
			console.log(error)
			this.setState({loading: false})
			message.error('Error al cargar la información')
		})
	};



	render() {
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} className="">
					 	<Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
                            <IconArrowBack />
                        </Button>
						<h2 style={{width: 'auto', float: 'left'}}>Material de {this.state.concepto.conceptos?.nombre}</h2>
					</Col>
				</Row>
				<section className="p-1">
					<Row>
                        <Col span={24}>
                            <Card
                                loading={this.state.loading}
                                bordered
                                className="card-proyeccionesDetalle"
                            >
                                <Row>
                                    <h1>Material para {this.state.concepto.conceptos?.nombre} </h1>
                                </Row>

                                <Row>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Monto Pagado</label>
                                       	<div>
                                        	<p className="pInfo">$ {this.state.concepto.conceptos?.monto_material.toMoney(2)} MXN</p>
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Presupuesto</label>
                                        <div>
                                        	<p className="pInfo">$ {this.state.concepto.presupuesto?.toMoney(2)} MXN</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <div className="list-title">
                    	Bitacora
                    </div>
					<div className="scroll">
						
						<List
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.materiales}
							loading={this.state.loading}
							locale={{ emptyText: 'Sin Nominas' }}
							renderItem={item =>
								(<List.Item className="component-list-item">
									<Card className="card-list">
										<Row style={{ width: '100%' }} className="">
											<Col span={3} className="center">
												<Text>{moment(item.conceptos.materiales.fecha).format('DD / MM / YYYY')}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>{item.conceptos.materiales.material_id.nombre}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>{item.conceptos.materiales.cantidad}</Text>
											</Col>
											<Col span={6} className="center">
												<Text> $ {item.conceptos.materiales.monto.toMoney(2)} MXN</Text>
											</Col>
											<Col span={3} className="center">
												<Button className="btn btn-edit" 
													onClick={(e) => { this.setState({ModalProformaMaterialVisible: true, modalTitle: 'Editar', material_edit: item.conceptos.materiales}) }}>
													<IconEdit />
												</Button>
												<Popconfirm
													placement="topRight"
													title={"¿Deseas eliminar el concepto " + item.conceptos?.materiales.material_id.nombre + " ?"}
													onCancel={e => e.stopPropagation()}
													okText="Si"
													cancelText="No"
													onClick={e => e.stopPropagation()}
													onConfirm={e => {this.deleteMeterial(item.conceptos?.materiales._id)}}
												>
													<Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
														<IconDelete />
													</Button>
												</Popconfirm>
											</Col>
										</Row>
									</Card>
								</List.Item>
								)}
						/>
					</div>
					<Row className="mt-2 pb-3">
						<Pagination
							total={this.state.total}
							current={this.state.page}
							onChange={(page) => {
									this.listMateriales(page)
								}}
							/>
						</Row>
				</section>
				<Button
					className="btnAddPlus"
					onClick={() => this.setState({ModalProformaMaterialVisible: true, modalTitle: 'Añadir'})}>
					<IconPlus/>
				</Button>
				<ModalProformaMaterial
					modalVisible={this.state.ModalProformaMaterialVisible}
					closeMethod={this.hideModal}
					modalTitle={this.state.modalTitle}
					proforma_id={this.state.proforma_id}
					proforma_concepto_id={this.state.proforma_concepto_id}
					material_edit={this.state.material_edit}
				/>
			</div>
		)
	}
}