import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, Typography, message, Spin, InputNumber, DatePicker, Select } from 'antd';
import axios from 'axios'
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const { Title, } = Typography;
const { Option } = Select;

class PropiedadForm extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataAreas: [],
            dataRubros: [],
            dataSubrubros: [],
            dataProyectos: [],
            inmuebles: [],
        }
    }


    //Referencia de form
    ModalPropiedadRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadAreas();
        this.getProjects();
        this.getAsesores();
        this.loadInmuebles();

        if (this.props.propiedad
            // !== undefined && this.props.propiedad !== prevProps.propiedad
        ) {
            this.setPropiedad(this.props.propiedad)
        }
    }


    /**
     * @memberof ModalPropiedad
     * @function hideModal
     * @description Cierra el modal
     * */
    hideModal = () => {
        this.props.onClose();
        this.setState({ inmueble: undefined })
    }

    /**
     * @memberof ModalPropiedad
     * @function onFinish
     * @description Se ejecuta al aceptar el formulario 
     * */
    onFinish = (values) => {
        if (this.props.propiedad !== undefined)
            this.updatePropiedad(values)
        else
            this.addPropiedad(values)
    }


    /**
     * @memberof ModalPropiedad
     * @function setPropiedad
     * @description pone los datos de la propiedad en el formulario 
     * */
    setPropiedad = (propiedad) => {


        axios.get('/clientes/propiedad/get', {
            params: {
                id: propiedad._id,
            }
        }).then(response => {
            let propiedad = response.data.data

            console.log(propiedad)

            this.setState({ inmueble: propiedad.inmueble_id })

            this.onAreaChange(propiedad.area_id, propiedad.rubro_id, propiedad.subrubro_id)

            this.ModalPropiedadRef.current.setFieldsValue({
                proyecto_id: propiedad.proyecto_id,
                area_id: propiedad.area_id,
                rubro_id: propiedad.rubro_id,
                subrubro_id: propiedad.subrubro_id,
                asesor_id: propiedad.asesor_id,
                inmueble_id: propiedad.inmueble_id?._id,
                fecha_venta: moment(propiedad.fecha_venta),
                estatus:propiedad.estatus,
                monto_base:propiedad.monto_base,
                monto_venta: propiedad.monto_venta,
            })

        }).catch(error => {
            console.log(error)
        })


    }

    /**
     * @memberof ModalPropiedad
     * @function addPropiedad
     * @description añade un nuevo Cliente
     * */
    addPropiedad = (values) => {
        axios.post('/clientes/propiedad/add', {
            ...values,
            cliente_id: this.props.cliente_id,
        }).then(response => {
            message.success('Cliente registrado')
            this.hideModal()
        }).catch(error => {
            console.log(error)
            message.error('Error al registrar')
        })
    }

    /**
    * @memberof ModalPropiedad
    * @function addPropiedad
    * @description añade un nuevo Cliente
    * */
    updatePropiedad = (values) => {
        axios.post('/clientes/propiedad/update', {
            ...values,
            propiedad_id: this.props.propiedad._id,
        }).then(response => {
            message.success('Cliente registrado')
            this.hideModal()
        }).catch(error => {
            console.log(error)
            message.error('Error al registrar')
        })
    }

    /**
   * @memberof ModalPropiedad
   *
   * @method   loadInmuebles
   * @description  Trae un listado de tags de tipo inmueble
   *
   **/
    loadInmuebles = (search) => {
        axios.get('/tags', {
            params: {
                pagination: false,
                type: "inmuebles", //tipo: 2
                search: search,
                propiedades: true,
            }
        })
            .then(res => {
                this.setState({ inmuebles: res.data.data })
            })
            .catch(e =>
                console.log('error inmuebles get', e)
            )
    }

    /**
    * @function getProjects
    * @description Obtenemos los proyectos
    * */
    getProjects = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({ dataProyectos: proyectos.data.data })
        }).catch((error) => {
        })
    };

    /**
    * @function getAsesores
    * @description Obtenemos los usuarios y se desplegan como opcion de asesor
    * */
    getAsesores = async () => {
        await axios.get("/usuarios", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                paginate: false
            },
        })
            .then(({ data }) => {
                this.setState({
                    dataAsesores: data.data,
                })
            })
            .catch(e => console.log('er', e))
    }

    /**
    *
    *
    * @memberof ModalPropiedad
    * @method loadAreas
    * @description Obtiene la informacion de los proyectos registradas en la base de datos
    *
    */
    loadAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                dataAreas: response.data.data
            })

            let index = response.data.data.findIndex(area => area.nombre === 'Ventas')


            if (index !== -1) {
                console.log(response.data.data[index]._id)
                this.setState({ initial_area: response.data.data[index]._id })

                if (this.ModalPropiedadRef.current) {
                    this.ModalPropiedadRef.current.setFieldsValue({
                        area_id: response.data.data[index]._id,
                    });
                }

                this.onAreaChange(response.data.data[index]._id)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * @methodOF ModalPropiedad
     *
     * @function onAreaChange
     * @description Al actualizar un area, se deben traer los rubros y subrubros iniciales.
     * */
    onAreaChange = (area_id, rubro_id, subrubro_id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: area_id
            }
        }).then(async (rubros) => {
            this.setState({ dataRubros: rubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataRubros.length > 0) {

                let r = rubro_id ? rubro_id : rubros.data.data[0]._id
                this.ModalPropiedadRef.current.setFieldsValue({
                    rubro_id: r
                });
                this.onRubroChange(r, subrubro_id)
            } else {
                console.log('NO');
                this.ModalPropiedadRef.current.setFieldsValue({
                    rubro_id: undefined,
                    subrubro_id: undefined
                })
            }
        }).catch((error) => {
        })
    }

    /**
     *
     *
     * @memberof ModalPropiedad
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (rubro_id, subrubro_id) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: rubro_id
            }
        }).then((subrubros) => {
            this.setState({
                dataSubrubros: subrubros.data.data
            })

            let index_rubro = this.state.dataRubros.findIndex(rub => rub._id.toString() === rubro_id.toString())

            let index_inm = this.state.inmuebles.findIndex(inm => inm.nombre === this.state.dataRubros[index_rubro].nombre)
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataSubrubros.length > 0) {
                this.ModalPropiedadRef.current.setFieldsValue({
                    subrubro_id: subrubro_id ? subrubro_id : subrubros.data.data[0]._id,
                    inmueble_id: this.state.inmuebles[index_inm] ? this.state.inmuebles[index_inm]._id : undefined
                });
            } else {
                this.ModalPropiedadRef.current.setFieldsValue({
                    subrubro_id: undefined,
                    inmueble_id: undefined,
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
    * @memberof ModalPropiedad
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, apellido }, index) {
                arr.push(<Option value={_id}> {nombre} {apellido}</Option>)
            })
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
        }
        return arr
    }


    render() {

        return (
            <Spin spinning={this.state.loading}>
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} Propiedad</Title>
                </div>
                <Form
                    layout="vertical"
                    ref={this.ModalPropiedadRef}
                    onFinish={this.onFinish}
                >
                    <Row align="center" gutter={20}>
                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id" //<--------------------------------------- proyecto_id
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un material"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione proyecto"
                                >
                                    {this.state.dataProyectos?.map(function ({ _id, nombre, logo, color }, index) {
                                        return <Option value={_id}>
                                            <CustomAvatar
                                                image={logo}
                                                name={nombre}
                                                color={color}
                                                size="small"
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            />
                                            {nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Área"
                                name="area_id"//----------------------------------area_id
                                listType="text"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Área a que corresponde la transaccción."
                                }]}
                            >
                                <Select
                                    className="form-select"
                                    placeholder="Seleccione el Área"
                                    onChange={(id) => this.onAreaChange(id)}
                                    disabled={false}
                                    initialValue={this.state.initial_area}
                                >
                                    {this.state.dataAreas.map(function (area, index) {
                                        return <Option value={area._id}>{area.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Rubro"
                                name="rubro_id"//----------------------------------rubro_id
                                listType="text"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el rubro"
                                }]}
                            >
                                <Select className="form-select" placeholder="Seleccione rubro"
                                    onChange={(id) => this.onRubroChange(id)}>
                                    {this.state.dataRubros.map((rubro, index) => {
                                        return <Option value={rubro._id}>{rubro.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Cliente"
                                name="subrubro_id"//----------------------------------subrubro_id
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el subrubro"
                                }]}
                            >
                                <Select className="form-select" placeholder="Seleccione subrubro"
                                >
                                    {this.state.dataSubrubros.map(function (subrubro, index) {
                                        return <Option value={subrubro._id}>{subrubro.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Contacto"
                                name="asesor_id" //<--------------------------------------- asesor_id
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un Contacto"
                                }]}
                            >
                                <Select>
                                    {this.state.dataAsesores?.map(function ({ _id, nombre, logo, color, apellido }, index) {
                                        return <Option value={_id}>
                                            <CustomAvatar
                                                image={logo}
                                                name={nombre}
                                                color={color}
                                                size="small"
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            />
                                            {nombre} {apellido}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Inmueble"
                                name="inmueble_id" //<--------------------------------------- inmueble_id
                            >
                                <Select allowClear={true}>
                                    {this.selectOptions(this.state.inmuebles, this.state.inmueble)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Fecha de Venta"
                                name="fecha_venta" //<--------------------------------------- fecha_venta
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Estatus"
                                name="estatus" //<--------------------------------------- estatus
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un estatus"
                                }]}
                            >
                                <Select className="form-select" placeholder="Seleccione estatus" >
                                    <Option value={1}>Vendida</Option>
                                    <Option value={2}>Cancelada</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Monto Base"
                                name="monto_base" //<--------------------------------------- monto base
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Monto Venta"
                                name="monto_venta" //<--------------------------------------- monto_venta
                            >
                                <InputNumber disabled={true}/>
                            </Form.Item>
                        </Col>




                    </Row>

                    <Col span={24} className="text-center">
                        <Form.Item>
                            <Button htmlType="submit" type="primary" className="btn-azul">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Form>
            </Spin >



        )
    }
}


export default function (props) {

    const { visible, onClose } = props


    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className="modal-form modal-form-large"
        destroyOnClose={true}
        zIndex={1000}
    >

        <PropiedadForm {...props} />
    </Modal>
}