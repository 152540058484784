import React, { Component, useContext } from 'react';
import { Button, Col, Row, Select, Typography, List, Card, Collapse, Form, Input, Spin, InputNumber, Upload, message, Pagination, Popconfirm, Space, Modal, Tooltip, Tag , Empty} from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { PlusOutlined, UploadOutlined, CheckOutlined, DeleteOutlined , FormOutlined} from '@ant-design/icons';
//componentes
import Logged from '../../../../Hooks/Logged';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconPlus, IconFlag, IconEdit, IconCheckMedalColor } from '../../../Widgets/Iconos';

//Modales
import ModalRequisiciones from '../Modales/ModalRequisiciones'
import ModalAddRequesiciones from '../Modales/ModalAddRequisiciones'

//css
import './../../../../Styles/dashboard.css'
import './../../../../Styles/compras.css'

const { Title, Text } = Typography;
const { Panel } = Collapse;
const moment = require('moment')

let colors = {
    0: '#B706F5', //Requerido
    1: '#EDF10A', //Cotizado
    2: '#F57906', //Pausado
    3: '#F50606', //Cancelado
    4: '#6106F5', //Entrega
    5: '#F506B2', //Recoleccion
    6: '#08E453', //Entregado
}

let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

/**
 * @class ListasCotizacionRequerido
 * @description Retorna una lista de las requisiciones, en estatus de requerido, para el usuario comprador realice la cotizacion 
 */
class ListasCotizacionRequerido extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            requisicion_id: undefined,

            page: 1,
            limit: 10,
            total: 0
        }
    }

    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function componentDidMount
     * @description
     */
    componentDidMount = () => {
        this.getRequisiciones()
    }

    componentDidUpdate(prevProps){
        if(prevProps.search != this.props.search){
            this.getRequisiciones()
        }
    }
    
    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function getRequisiciones
     * @description
     */
    getRequisiciones = () => {
        this.setState({ loading: true })
        axios.get('/requisiciones', {
            params: {
                page: 1,
                limit: this.state.limit,
                estatus: 0,
                requisiciones_opciones: true, //retorna un array con los requisiciones_opciones relacionados
                search: this.props.search
            }
        }).then(response => {

            if (this.state.data.length > 0) {
                this.setState({ 
                    data: [], 
                    hasMore: response.data.data.itemsList.length < response.data.data.itemCount
                }, () => this.setRequisiciones(response))
            }
            else {
                this.setState({ hasMore: response.data.data.itemsList.length < response.data.data.itemCount})
                this.setRequisiciones(response)
            }

        }).catch(error => {
            console.log(error)
            message.success('Error al obtener la información')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function setRequisiciones
     * @description Recorre las requisiciones obtenidas y crea un referencia a cada requisicion_opcion para modificar el formulario
     */
    setRequisiciones = (response) => {
        let requisiciones = response.data.data.itemsList;

        for (const requesicion of requisiciones) {
            for (const requisicion_opcion of requesicion.requisiciones_opciones) {
                requisicion_opcion.ref = React.createRef()
                requisicion_opcion.archivos = requisicion_opcion.archivos?.map((e, index) => {
                    return {
                        uid: index,
                        name: e.name,
                        filename: e.filename,
                        response: {
                            filename: e.filename

                        },
                        status: 'done',
                        url: axios.defaults.baseURL + "/voucher/" + e.filename
                    }
                })
            }
        }

        this.setState({
            data: [...requisiciones],
            page: response.data.data.currentPage,
            total: response.data.data.itemCount,

        })
    }

    /***
     *
     * @methodOf ListasRequisiciones
     * @function getMore
     * @description Obtiene mas requisisciones al hacer scroll sobre los estatus
     */
    getMore = () => {
        if(this.state.total > this.state.data.length){
            this.setState({ loading: true, })

            axios.get('/requisiciones', {
                params: {
                    page: this.state.page + 1,
                    limit: this.state.limit,
                    estatus: 0,
                    requisiciones_opciones: true, //retorna un array con los requisiciones_opciones relacionados
                    search: this.props.search
                }
            }).then(({ data }) => {

                let new_requisisciones = data.data.itemsList

                for (const requesicion of new_requisisciones) {
                    for (const requisicion_opcion of requesicion.requisiciones_opciones) {
                        requisicion_opcion.ref = React.createRef()
                        requisicion_opcion.archivos = requisicion_opcion.archivos?.map((e, index) => {
                            return {
                                uid: index,
                                name: e.name,
                                filename: e.filename,
                                response: {
                                    filename: e.filename

                                },
                                status: 'done',
                                url: axios.defaults.baseURL + "/voucher/" + e.filename
                            }
                        })
                    }
                }

                let arr = [...this.state.data, ...new_requisisciones]

                this.setState({
                    data: arr,
                    total: data.data.itemCount,
                    page: data.data.currentPage,
                    hasMore: arr.length < data.data.itemCount,
                })
                
            })
                .catch(error => {
                    console.log("error", error);
                    message.error('Error al traer la información')
                }).finally(() => this.setState({ loading: false }))
            }
    }

    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function aceptarRequesicionOpcion
     * @description Recorre las requisiciones obtenidasr y crea un referencia a cada requisicion_opcion para modificar el formulario
     */
    aceptarRequesicionOpcion = (requisicion_id, requisicion_opcion_id) => {
        axios.post('/requisicion/opcion/aceptar', {
            requisicion_id,
            requisicion_opcion_id
        }).then(response => {
            message.success('Opción aceptada correctamente')
            this.getRequisiciones()
            this.props.getRequisiciones()
        }).catch(error => {
            message.error('Error al aceptar la opción')
        })
    }



    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function onChange
     * @description Actualiza los registros de requerimientos_opcionales en la BD
     * 
     * @params allValues Obj todos los valores del formualario de las requisicion_opcion
     * @params requisicion_opcion_id Id id de la requesicion a editar
     * @params index indice que localiza la requesicion
     * @params index_b indice que localiza la requisicion_opcion
     */
    onChange = (allValues, requisicion_opcion_id, index, index_b) => {

        let data = this.state.data
        let { costo_envio, precio_unitario } = allValues

        let volumen = data[index].volumen

        //actualizamos la informacion en la BD
        axios.post('/requisicion/opcion/update', {
            requisicion_opcion_id,
            precio_unitario,
            costo_envio,
            total: costo_envio + (precio_unitario * volumen),
            dias_entrega: allValues.dias_entrega,
            archivos: allValues.archivos,
        }).then(() => console.log('Actualizado'))
            .catch(error => console.log(error))

        return data[index].requisiciones_opciones[index_b].ref?.current.setFieldsValue({
            total: costo_envio + (precio_unitario * volumen)
        })

    }

     /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function activate
     * @description Actualiza el marcador de activacion de la requisicion
     * 
     * @params requisicion_id id de la requisisicion a actualizar
     */
    activate = (requisicion_id, index) => {
        axios.post('/requisiciones/update',{
            requisicion_id,
            activate: true
        }).then(response => {
            console.log("response", response.data);
            let data = this.state.data
            data[index].activate = response.data.activate
            this.setState({data})
        }).catch(error => {
            if(error?.response?.status === 403)
                message.error(error?.response?.data.message)
            else
                message.error('Error al actualizar')
        })
    }

    /**
     *
     * @memberof ListasCotizacionRequerido
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
            }
        }



        if (file.status === "done") {
            e.file.filename = file.response.filename
        }

        if (file.status === "removed")
            this.removeFile((file.response.filename !== undefined) ? file.response.filename : file.name);

        return e && e.fileList;
    };


    /**
     *
     * @memberof ListasCotizacionRequerido
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/voucher/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con éxito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    render() {

        let { user } = this.props;

        return (
            <Spin spinning={this.state.loading}>
                <div>
                    <Text className={`list-header list-header-estatus-0`}>Requerido</Text>
                </div>
                {this.state.data.length > 0 ? <InfiniteScroll
                    dataLength={this.state.data.length}
                    next={this.getMore}
                    hasMore={this.state.hasMore}
                    loader={<Spin tip="Cargando..."><h4 style={{height: '100px'}}></h4></Spin>}
                    height={550}
                    className="mb-2"
                >
                        <Collapse accordion className={`cotizacion-collapse  collapse-estatus-0 mb-1`}>
                            {this.state.data.map((item, index) => <Panel


                                className='panel'
                                key={index}
                                showArrow={true}
                                extra={<Row className="w100" style={{ minWidth: "100%" }}>
                                    <Col span={4} className="flex-column">
                                        <Tooltip placement="topLeft" title={item.producto_id?.nombre}>
                                            <Text ellipsis>{item.producto_id?.nombre}</Text>
                                        </Tooltip>
                                        <Text>{item.producto_id?.sku_manantial}</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text>{item.producto_id?.categoria_id?.nombre}</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text>{item.volumen} {item.producto_id?.unidad ? item.producto_id?.unidad.toUnidad() : 'unidades'}</Text>
                                    </Col>
                                    <Col span={1} className="center">
                                        <CustomAvatar
                                            size="large"
                                            image={item.proyecto_id?.logo}
                                            name={item.proyecto_id?.nombre}
                                            color={item.proyecto_id?.color}
                                        />
                                    </Col>
                                    <Col span={2} className="center">
                                        <IconFlag fill={colors_flag[item.prioridad] ? colors_flag[item.prioridad] : '#A4A4A4'} />
                                    </Col>
                                    <Col span={3} className="center">
                                        {item.propiedades.length > 0 ? <Tag color="green" style={{marginRight: '4px', marginLeft: '0px'}}>{item.propiedades[0].nombre}</Tag> : ''}
                                        {item.propiedades.length > 1 ? <Tooltip placement="topLeft" title={item.propiedades.map((elm, index)=> index > 0 ? elm.nombre + ', ' : '')}>
                                            <Tag color="green" style={{marginRight: '4px', marginLeft: '0px'}}>...</Tag>
                                        </Tooltip> : ''}
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                    </Col>
                                    <Col span={1} className="center">
                                        <Button
                                            className={`btn-permission ${item.activate ? 'active' : ''}`}
                                            type="ghost"
                                            icon={<IconCheckMedalColor/>}
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                this.activate(item._id, index)
                                            }}
                                        />
                                    </Col>
                                    <Col span={3} className="center">
                                        <Space>
                                            <Button
                                                icon={<PlusOutlined />}
                                                className="btn-blue"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setState({ modal_visible: true, requisicion_id: item._id })
                                                }}
                                            />
                                            {user?.tipo === 1 ? <Button
                                                icon={<FormOutlined />}
                                                className="btn-blue"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setState({ modal_visible_edit: true, requisicion_id: item._id })
                                                }}
                                            /> : null }
                                            <Button
                                                //size='small'
                                                className="btn-red"
                                                htmlType="submit"
                                                type="primary"
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => Modal.confirm({
                                                    title: "¿Deseas eliminar esta requisición?",
                                                    onOk: () => axios.delete('/requisiciones/delete', { params: { requisicion_id: item._id } })
                                                        .then(({ data }) => {
                                                            message.success("Se eliminó correctamente")
                                                            this.getRequisiciones()
                                                        })
                                                        .catch(({ data }) => {
                                                            message.error("No se pudo eliminar")
                                                        })
                                                })

                                                }
                                            />
                                        </Space>

                                    </Col>
                                </Row>}
                            // header={}
                            >
                                <Row>
                                    <Col span={4} className="center">
                                        <Text className="text-gray">Proveedor</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text className="text-gray">Precio Unitario</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text className="text-gray">Dias de entrega</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text className="text-gray">Costo Envio</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text className="text-gray">Monto Total</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text className="text-gray">Archivos</Text>
                                    </Col>
                                </Row>
                                {item.requisiciones_opciones?.map((requisicion_opcion, index_b) => {

                                    return <Form
                                        className="form-collapse"
                                        ref={requisicion_opcion.ref}
                                        initialValues={{ ...requisicion_opcion }}
                                        onValuesChange={(value, all) => this.onChange(all, requisicion_opcion._id, index, index_b)}
                                    >
                                        <Row>
                                            <Col span={4} className="center">
                                                <Form.Item name="proveedor">
                                                    <Text>{requisicion_opcion.proveedor_id?.alias}</Text>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Form.Item name="precio_unitario">
                                                    <InputNumber
                                                        className="w100"
                                                        bordered={false}
                                                        placeholder="Precio Unitario"
                                                        prefix="$"
                                                        precision={2}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Form.Item name="dias_entrega">
                                                    <InputNumber
                                                        className="w100"
                                                        bordered={false}
                                                        placeholder="Dias Entrega"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Form.Item name="costo_envio">
                                                    <InputNumber
                                                        className="w100"
                                                        bordered={false}
                                                        placeholder="Costo Envio"
                                                        precision={2}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Form.Item name="total">
                                                    <InputNumber
                                                        className="w100"
                                                        bordered={false}
                                                        placeholder="Monto Total"
                                                        prefix="$"
                                                        precision={2}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Form.Item
                                                    name="archivos"
                                                    getValueFromEvent={this.normFile}
                                                    valuePropName="fileList"
                                                >
                                                    <Upload
                                                        className="upload-sm"
                                                        action={axios.defaults.baseURL + "/voucher/add"}
                                                        multiple={true}
                                                    >
                                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2} className="center">
                                                    <Form.Item>
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title="¿Quieres aceptar esta opción?"
                                                            okText="Si, seguro" cancelText="No"
                                                            onConfirm={() => this.aceptarRequesicionOpcion(item._id, requisicion_opcion._id)}
                                                        >
                                                            <Button
                                                                htmlType="submit"
                                                                type="primary"
                                                                size='small'
                                                                className="btn-green"
                                                                icon={<CheckOutlined />}
                                                            />
                                                        </Popconfirm>
                                                    </Form.Item>
                                                    <Button
                                                        size='small'
                                                        htmlType="submit"
                                                        type="primary"
                                                        className="btn-delete"
                                                        danger
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => Modal.confirm({
                                                            title: "¿Deseas eliminar esta opción?",
                                                            onOk: () => axios.delete('/requisicion/opcion/delete', { params: { requisicion_id: item._id, requisicion_opcion_id: requisicion_opcion._id } })
                                                                .then(({ data }) => {
                                                                    message.success("Se eliminó correctamente")
                                                                    this.getRequisiciones()
                                                                })
                                                                .catch(({ data }) => {
                                                                    message.error("No se pudo eliminar")
                                                                })
                                                        })

                                                        }
                                                    />
                                               

                                            </Col>
                                        </Row>
                                    </Form>
                                })}
                            </Panel>)}
                        </Collapse>
                </InfiniteScroll> : <Empty description={"Sin Requisisciones"} className="mb-3 mt-1"/> }
                {/*<Pagination
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.limit}
                    onChange={this.getRequisiciones}
                    className="mb-1"
                    hideOnSinglePage={true}
                />*/}
                <ModalAddRequesiciones
                    visible={this.state.modal_visible}
                    onCancel={(flag) => {
                        this.setState({ modal_visible: false, requisicion_id: undefined })
                        if (flag === true) {
                            this.getRequisiciones()
                        }
                    }}
                    requisicion_id={this.state.requisicion_id}
                />

                <ModalRequisiciones
                    visible={this.state.modal_visible_edit}
                    onCancel={(estatus)=>{
                        this.setState({modal_visible_edit: false, requisicion_id: undefined})
                        this.getRequisiciones()
                    }}
                    requisicion_id={this.state.requisicion_id}
                />
            </Spin>
        )
    }
}


export default function (props) { return <ListasCotizacionRequerido {...props} user={useContext(Logged)} /> }