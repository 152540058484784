import React, { Component } from 'react';
import { Button, Card, Col, DatePicker, message, Popconfirm, Popover, Row, Spin, Statistic, Table, Typography } from 'antd'
import { FaArrowRight } from "react-icons/fa";
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';
//componentes
import { IconDelete, IconDetails, IconEdit, IconPlus, IconCheckMedal } from '../../Widgets/Iconos';
import { GraficaMovimientosPie } from '../../Widgets/graficas'
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import Logged from '../../../Hooks/Logged';
//modales
import ModalTraspasoDetalle from '../Modales/Finanzas/ModalTraspasoDetalle';
import ModalTransaccion from '../Modales/Finanzas/ModalTransaccion';
import ModalTraspaso from '../Modales/Finanzas/ModalTraspaso';
import ModalMultiple from '../Modales/Finanzas/ModalMultiple';

import ModalTransacciónDetalle from '../Modales/Finanzas/ModalTransacciónDetalle';

//css
import '../../../Styles/finanzas.css'

const moment = require('moment');
const { Text } = Typography;


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 2
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default class Finanzas extends Component {
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            cards: [],
            dataTran: [],
            saldoTotal: 0,
            cuentas_incluidas: 0,
            loading: true,
            tipo_user: 0,
            accionModal: 'crear',
            registroId: undefined,


            modalVisibleTran: false,
            modalVisibleTraspaso: false,


            modalVisibleDetalle: false,
            modalVisibleTraspasoDetalle: false,

            infoMovimientos: [],
            /* Paginado */
            itemCount: undefined,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
        }
    }


    //Referencia de form
    modalTransaccion = React.createRef();
    modalTraspaso = React.createRef();

    componentDidMount() {

        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        ////Obtenemmos la informacion de las transacciones.
        this.receivedData();
        this.getGraficaValues();

    }
    componentDidUpdate() {
        if (this.context !==0 && this.state.tipo_user === 0)
            this.setState({
                tipo_user: this.context.tipo
            })
    }

    /*Modal transaccion */
    modalCrearTransaccion = () => {
        this.setState({
            accionModal: 'crear',
            modalVisibleTraspaso: false,
            modalVisibleTran: true,
        })
    }


    modalEditarTransaccion = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;
        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisibleTran: true
        })
    }

    modalMultipleTransaccion = () => {
        this.setState({
            modalMultiple: true
        })
    }

    modalDetalleTransaccion = (item) => {
        const reg_id = item.currentTarget.id;
        this.setState({
            registroId: reg_id,
            modalVisibleDetalle: true
        })
    }


    hideModal = () => {
        this.setState({
            registroId: undefined,
            modalVisibleTran: false,
            modalVisibleTraspaso: false,
            modalVisibleTraspasoDetalle: false,
            modalVisibleDetalle: false,
            modalMultiple: false
        })
    }

    /*Modal traspaso */
    modalCrearTraspaso = () => {
        this.setState({
            accionModal: 'crear',
            modalVisibleTraspaso: true,
        })
    }

    modalEditarTraspaso = (item) => {
        const reg_id = item.currentTarget.id;
        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisibleTraspaso: true,
        })
    }

    modalMultipleTraspaso = () => {
        this.setState({
            accionModal: 'multiple',
            modalVisibleTraspaso: true,
        })
    }

    modalDetalleTraspaso = (item) => {
        const reg_id = item.currentTarget.id
        this.setState({
            accionModal: 'detalle',
            registroId: reg_id,
            modalVisibleTran: false,
            modalVisibleTraspaso: false,
            modalVisibleTraspasoDetalle: true
        })
    }

    /**
     *
     * @memberof Finanzas
     * @method receivedData
     * @description Obtenemos la informacion de las transacciones
     */
    receivedData = values => {


        this.setState({
            loading: true
        })

        axios.get('/transacciones/list', {
            params: {
                page: values,
                limit: 5,
                sortfield: { "order": "descend", "field": "fecha" }
            }
        })
            .then(res => {
                const data = res.data.data.itemsList
                this.setState({
                    dataTran: data,
                    loading: false,
                    currentPage: res.data.data.paginator.currentPage,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: res.data.data.paginator.perPage,
                    pageCount: res.data.data.paginator.pageCount
                })
                this.loadCuentas();
            })
            .catch(res => {
                console.log("error", res);
            });
        this.getGraficaValues()
    }


    /**
     *
     *
     * @memberof Finanzas
     * @method loadCuentas
     * @description Obtiene la informacion de las cuentas registradas en la base de datos
     *
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200,
                sat: true, //quita las cuentas tipo sat
            }
        }).then((cuentas) => {
            let cuentas_total = cuentas.data.data.itemsList.length;
            let saldo_total = 0;
            cuentas.data.data.itemsList.map((registro, undex) => {
                saldo_total += parseFloat(registro.saldo);

            })

            this.setState({
                cards: cuentas.data.data.itemsList,
                saldoTotal: saldo_total,
                cuentas_incluidas: cuentas_total,
            })
        }).catch((error) => {
        })
    }


    eliminarTransaccion = e => {
        axios.post('/transacciones/delete', {
            id: e
        })
            .then((response) => {
                message.success(response.data.message);
                this.receivedData();
            })
            .catch((error) => {
                message.error(error.response.data.message)
            })
    }

    /**
     * @memberof Finanzas
     * @method getGraficaValues
     * @description Obtiene la informacion para la grafica de movimientos.
     */
    getGraficaValues = () => {
        axios.post('/transacciones/movimientos')
            .then((response) => {
                this.setState({
                    infoMovimientos: response.data.data
                })
            })
            .catch((error) => {

            })
    }


    render() {
        const contentMenuPlus = (
            <div className="div-menuplus">
                <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.modalCrearTransaccion}>
                    Transacción
                </Button>
                <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.modalMultipleTransaccion}>
                    Transacción multiple
                </Button>
                <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.modalCrearTraspaso}>
                    Traspaso
                </Button>
            </div>
        );


        const columns = [
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
                width: "12%",
                className: "table-transacciones-column",
                render: (text, record) => moment(record.fecha).format('DD-MM-YYYY')

            },
            {
                title: 'Concepto',
                dataIndex: 'concepto',
                width: "12%",
                key: 'concepto',

            },
            {
                title: 'Cuenta',
                dataIndex: ['cuenta_id', 'nombre'],
                width: "10%",
                key: 'cuenta',
            },
            {
                title: 'Rubro',
                dataIndex: ['rubro_id', 'nombre'],
                width: "10%",
                key: 'rubro',
            },
            {
                title: 'Proyecto',
                key: 'proyecto',
                width: '20%',
                dataIndex: 'proyecto_id',
                render: (text, record) => {
                    let backgroundColor = (record.proyecto_id.color) ? record.proyecto_id.color : "#bbbbbb";

                    const colorHex = function getContrastYIQ(hexcolor) {
                        hexcolor = hexcolor.replace("#", "");
                        var r = parseInt(hexcolor.substr(0, 2), 16);
                        var g = parseInt(hexcolor.substr(2, 2), 16);
                        var b = parseInt(hexcolor.substr(4, 2), 16);
                        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
                        return (yiq >= 128) ? 'black' : 'white';
                    };
                    let color = colorHex(backgroundColor);
                    return (
                        <div className="record" style={{ backgroundColor: backgroundColor, color: color }}>
                            {record.proyecto_id.nombre}
                        </div>
                    )
                },

            },
            {
                title: 'Monto',
                key: 'monto',
                width: "12%",
                render: (text, record) => (
                    (record.tipo === 2) ?
                        <Statistic value={record.monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }} prefix={'$'} /> :
                        (record.tipo === 3) ?
                            <Statistic value={record.monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#000000' }} prefix={'$'} /> :

                            <Statistic value={record.monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF19' }} prefix={'$'} />

                ),
            },
            {
                title: 'Tipo',
                key: 'tipo',
                width: "12%",
                render: (text, record) => ((record.tipo !==3) ? 'Transacción' : 'Traspaso'),
            },
            {
                title: 'Acciones',
                key: 'actions',
                fixed: "right",
                width: "12%",
                render: (text, record) => (
                    <div className="tbl-opciones">
                        <Button type="" className="detTable-icon" name={record.nombre} id={record._id}
                            onClick={(record.tipo !==3) ? this.modalDetalleTransaccion : this.modalDetalleTraspaso}
                            title="Ver detalles">
                            <IconDetails />
                        </Button>

                        <Button type="primary" className="button-survey btn-edit button-edit" name={record.nombre}
                            id={record._id}
                            onClick={(record.tipo !==3) ? this.modalEditarTransaccion : this.modalEditarTraspaso}
                            title="Editar">
                            <IconEdit />
                        </Button>

                        {(this.state.tipo_user === 1) ? <Popconfirm placement="topRight" disabled={this.state.tipo_user !==1} title="¿Eliminar esta operacion?" okText="Si, seguro" cancelText="No"
                            name={record.nombre} id={record._id} onConfirm={() => {
                                this.eliminarTransaccion(record._id)
                            }}>
                            <Button type="primary"
                                className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                title="Eliminar">
                                <IconDelete />
                            </Button>
                        </Popconfirm> : null
                        }
                    </div>
                ),
            },
        ];

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24}>
                        <h2>Finanzas</h2>
                    </Col>
                </Row>

                <section className="p-1">
                    <Row gutter={[16, 24]} className="card-container">
                        <Col xs={24} xl={8} xxl={6} className="gutter-row">
                            <Card loading={this.state.loading} className="card-saldo card-saldo-green">
                                <Row>
                                    <h2 style={{ color: '#979797', fontSize: '13pt', fontWeight: '700' }}>Saldo al Momento</h2>
                                </Row>

                                <Row>
                                    <h2 style={{ color: '#000', fontSize: '18pt', fontWeight: '800', margin: '15px 0px 20px 0px' }}>
                                        <Statistic value={this.state.saldoTotal} decimalSeparator={","} precision={2} prefix={"$"} />
                                    </h2>
                                </Row>

                                <Row>
                                    <h3 style={{
                                        color: '#979797',
                                        fontSize: '10pt',
                                        fontWeight: '600',
                                        width: '100%'
                                    }}>Cuentas Incluidas:</h3>

                                    <h4 style={{
                                        color: '#0047FF',
                                        fontSize: '10pt',
                                        fontWeight: '500'
                                    }}>{this.state.cuentas_incluidas}</h4>
                                </Row>
                            </Card>

                        </Col>
                        <Col xs={24} xl={16} xxl={18} className="gutter-row">
                            <Carousel
                                responsive={responsive}
                                centerMode={true}
                                className="sec-carousel"
                                style={{ height: "40vh", float: 'left' }}>
                                {this.state.cards.map((element, index) => {



                                    const isSyncfy = (
                                        element.syncfy_usuario_id && element.syncfy_credenciales_id && element.syncfy_cuenta_bancaria_id
                                    )

                                    const hayFecha = (isSyncfy && element.syncfy_fecha_actualizacion_saldo && element.syncfy_fecha_actualizacion_saldo !== null)

                                    return (
                                        <Col xs={24}>
                                            <Card
                                                title=""
                                                loading={this.state.loading}
                                                bordered
                                                className="card-saldo card-saldo-green mr-1"
                                            >
                                                <Row style={{ height: '60px' }}>
                                                    <Col xs={24} lg={18} xl={16} xll={18} className="flex-left">
                                                        <Text className="text-banco">{element.banco}</Text>
                                                    </Col>
                                                    <Col xs={24} lg={6} xl={8} xll={6} className="center" style={{ padding: "0px" }}>
                                                        <CustomAvatar image={element.logo} name={element.nombre} />
                                                    </Col>
                                                    {(hayFecha) ?
                                                        <Col span={24}>
                                                            <span>
                                                                <IconCheckMedal />
                                                                <small style={{ position: 'relative', bottom: 10, left: 5 }}>
                                                                    <strong>Actualizado: </strong>{moment(element.syncfy_fecha_actualizacion_saldo).format('lll')}
                                                                </small>
                                                            </span>
                                                        </Col> : null}

                                                </Row>

                                                <Row style={{ height: '60px' }}>
                                                    <Text className="noCuenta flex-left">{element.cuenta}</Text>
                                                </Row>

                                                {element.belvo_facturas ? null : <Row style={{ height: '60px' }}>
                                                    <div className="flex-left" style={{ fontWeight: "bold", fontFamily: "arial" }}>

                                                        {isSyncfy ?
                                                            <Row className="">
                                                                <Col span={24} className="flex-left-column">
                                                                    <Text className="text-grey-sm">Saldo en Cuenta {element.banco}</Text>
                                                                    <Text className="text-money">$ {(element.syncfy_saldo) ? element.syncfy_saldo.toMoney() : '0.0'.toMoney()} <small>MXN</small></Text>
                                                                    <Text className="text-grey-sm">Saldo en ERP Grupo Manantial</Text>
                                                                    <Text className="text-money">$ {element.saldo?.toMoney()} <small>MXN</small></Text>
                                                                </Col>
                                                            </Row>
                                                            : [
                                                                <Text>
                                                                    <Statistic
                                                                        value={element.saldo}
                                                                        decimalSeparator={","}
                                                                        precision={2} prefix={"$"} />
                                                                </Text>,
                                                                <Text className="titleMxn" style={{ paddingTop: '8px', paddingLeft: '5px' }}> MXN</Text>
                                                            ]}


                                                    </div>
                                                    {/*<EllipsisOutlined style={{transform: 'rotate(90deg)'}}/>*/}
                                                </Row>}

                                            </Card>
                                        </Col>
                                    )
                                })
                                }
                            </Carousel>
                        </Col>
                    </Row>

                    <Row gutter={[16, 24]}>

                        <Col xs={24} sm={24} md={16} xl={16} xxl={18} className="gutter-row">
                            <Spin spinning={this.state.loading}>
                                <div className="card">
                                    <div className="div-titulo">
                                        <h2 className="titulo-divCont">Últimas Transacciones</h2>
                                        <Link to={"/admin/transacciones"}>
                                            <Button className="btn-go"> <FaArrowRight /> </Button>
                                        </Link>
                                    </div>
                                    <div className="contenido">
                                        <Table
                                            className="table-transacciones"
                                            columns={columns}
                                            dataSource={this.state.dataTran}
                                            pagination={false}
                                            scroll={{ x: 950, y: 295 }} rowClassName="table-transacciones-row " />
                                    </div>
                                </div>
                            </Spin>
                        </Col>
                        <Col xs={24} sm={24} md={8} xl={8} xxl={6} className="gutter-row">
                            <div className="card">
                                <div className="div-titulo">
                                    <h2 className="titulo-divCont">Movimientos</h2>
                                    <Button className="btn-go"> <FaArrowRight /> </Button>
                                </div>
                                <GraficaMovimientosPie datos={this.state.infoMovimientos} />
                            </div>
                        </Col>
                    </Row>

                </section>



                <ModalTransaccion
                    modalVisible={this.state.modalVisibleTran}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    tipo_user={this.state.tipo_user}
                    budgeting={true}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                />

                <ModalTransacciónDetalle
                    modalVisible={this.state.modalVisibleDetalle}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                />
                <ModalTraspasoDetalle
                    modalVisible={this.state.modalVisibleTraspasoDetalle}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                />


                <ModalTraspaso
                    ref={this.modalTraspaso}
                    modalVisible={this.state.modalVisibleTraspaso}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                >
                </ModalTraspaso>


                <ModalMultiple
                    modalVisible={this.state.modalMultiple}
                    hideModal={this.hideModal}
                />

                <Popover content={contentMenuPlus} title="Nuevo registro" >
                    <Button className="btnAddPlus"  >
                        <IconPlus />
                    </Button>
                </Popover>
            </div>
        )
    }
}


