import React, { Component } from 'react';
import { Button, Card, Col, message, Popover, Row, Spin, Statistic, Typography, Modal, List, DatePicker, Checkbox, Form, InputNumber, Popconfirm } from 'antd'
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';
//componentes
import { CardTransaccionesSC } from '../../Widgets/Cards/Cards';
import { IconDetails, IconPlus, IconCheckGreen, IconCheckAll, IconCheckTree, IconArrowBack, IconDelete } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import Logged from "../../../Hooks/Logged";
//css
import 'react-multi-carousel/lib/styles.css';
import '../../../Styles/finanzas.css'
//modales
import ModalClasificar from '../Modales/Belvo/ModalClasificarTransaccion';

import FormularioValidacion from '../../Widgets/FormularioValidacion';

import ValidaciónMFA from "../../Admin/Modales/Belvo/ValidaciónMFA";

var QRCode = require('qrcode.react');
const moment = require('moment');
const { Text, Title } = Typography;
const { RangePicker } = DatePicker


export default class TRansaccionesSC extends Component {

    static contextType = Logged

    static defaultProps = {
        transacciones: []
    }

    constructor(props) {
        super(props)

        this.state = {
            transacciones: [],
            page: 1,
            size: 50,
            of: moment('20210820'),
            to: moment(),
            modalClasificarVisible: false,
            seleccionadas: {},
            vista_guardadas: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        console.log('this.props.guardadas', this.props.guardadas);
        if (this.props.guardadas === true)
            this.getGuardadas()
        else
            this.receivedData()

    }


    /**
     *
     * @memberof TRansaccionesSC
     * 
     * @method receivedData
     * @description Obtiene la informacion y actualiza los valores locales de la lista.
     */
    receivedData = async ({
        page = this.state.page,
        size = this.state.size,

        of = this.state.of.clone().format("YYYY-MM-DD"),
        to = this.state.to.clone().format("YYYY-MM-DD"),

        link = this.props.match.params.link,

        account_id = this.props.match.params.account_id
    } = {}) => {

        this.setState({ loading: true })

        axios.post('/belvo/cuenta/transacciones', {
            belvo_link: link,
            account_id,
            page,
            size,
            of,
            to,
            clasificadas: this.props.clasificadas
        })
            .then(res => {
                const data = res.data.transacciones;
                this.state.searching = true
                if (Array.isArray(data))
                    this.setState({
                        transacciones: data,
                        searching: false,
                        total: res.data.total,
                    })
                else throw "No es posible obtener las transacciones"
            })
            .catch(response => {

                if (response.response?.status === 401)
                    message.error(response.response?.data?.message)
                else {
                    let res = { data: response?.response?.data?.error }
                    ValidaciónMFA({
                        cuenta: response?.response?.data?.cuenta,
                        error: res,
                        onOk: () => this.receivedData(),
                        setLoading: loading => this.setState({ loading }),
                        onDoesNotExist: () => this.props.history.push('/admin/cuentas/belvo/' + this.props.match.params.link + '/register'), //Aqui si ocupa la cuenta_id por que se va a regenerar un link, ya que no existe en Belvo
                        onIsInvalid: () => this.props.history.push('/admin/cuentas/belvo/' + this.props.match.params.link + '/update')//Aqui no se ocupa mandar cuenta_id, por que el token solo ocupa actualizarse
                    })
                }

            })
            .finally(f => {
                this.setState({ loading: false })
            });
    }


    /**
    *
    * @memberof TRansaccionesSC
    * 
    * @method actualizarTodas
    * @description Selecciona toas si se hace click en el boton
    */
    actualizarTodas = () => {

        let { seleccionadas, transacciones } = this.state

        const selecionadasLenght = Object.keys(this.state.seleccionadas).length

        if (selecionadasLenght !== 0 && selecionadasLenght === this.state.transacciones.length) {

            seleccionadas = {}

        } else {
            for (const transaccion of transacciones)
                seleccionadas[transaccion.id] = transaccion
        }
        this.setState({ seleccionadas: { ...seleccionadas } })
    }

    /**
    *
    * @memberof TRansaccionesSC
    * 
    * @method guardarTransaccionesSeleccionadas
    * @description Abre el modal si se seleccionaron transacciones
    */
    guardarTransaccionesSeleccionadas = () => {

        const selecionadasLenght = Object.keys(this.state.seleccionadas).length

        if (selecionadasLenght === 0)
            return Modal.error({
                title: "Debe seleccionar algunas transacciones",
                content: "Para poder agregar transacciones "
            })

        this.setState({
            modalClasificarVisible: true
        })
    }

    /**
    * @memberof FacturasSC
    * @method   gurdarDespues
    * @description  Las Facturas seleccionadas la guarda el la coleccion de Guardadas, para clasificarlas despues
    **/
    gurdarDespues = () => {
        let { seleccionadas } = this.state
        let array_selec = []

        for (var [key, value] of Object.entries(seleccionadas)) {
            array_selec.push(value)
        }

        if (array_selec.length === 0) {
            message.error('No hay Facturas Seleccionadas')
        } else {
            axios.post('/belvo/guardar/despues', {
                seleccionadas: array_selec,
                tipo_documento: 0, //transacciones
                belvo_transacciones: this.props.match.params.link,
                belvo_cuenta_id: this.props.match.params.account_id
            }).then(response => {
                message.success('Transacciones Guardadas para despues')
                this.receivedData()
            }).catch(error => {
                message.error('Error al guardar')
            })
        }
    }

    /**
   * @memberof FacturasSC
   * @method   getGuardadas
   * @description  Obtiene las facturas guardadas para clasificar despues
   **/
    getGuardadas = () => {
        this.setState({ loading: true, vista_guardadas: true })
        axios.get('/belvo/guardadas', {
            params: {
                tipo_documento: 0,
                tipo_documento: 0, //transacciones
                belvo_transacciones: this.props.match.params.link,
                belvo_cuenta_id: this.props.match.params.account_id
            }
        }).then(response => {
            console.log(response.data)
            this.setState({
                seleccionadas: [],
                page: response.data.data.currentPage,
                size: 10,
                total: response.data.data.itemCount,
                transacciones: response.data.data.itemsList,
            })
        }).catch(error => {
            console.log(error)
        }).finally(() => { this.setState({ loading: false }) })
    }

    /**
    * @memberof FacturasSC
    * @method   deleteGuardados
    * @description  Elimina de Guardados
    **/
    deleteGuardados = (id) => {
        this.setState({ loading: true, vista_guardadas: true })
        axios.post('/belvo/guardadas/delete', {
            _id: id
        }).then(response => {
            message.success('Eliminado de Guardados')
            this.getGuardadas()
        }).catch(error => {
            console.log(error)
        }).finally(() => { this.setState({ loading: false }) })
    }


    render() {
        const { vista_guardadas } = this.state
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} md={12} style={{ display: 'flex', alignItems: 'center' }}>
                        {vista_guardadas ? <Button className="IconArrowBack" onClick={() => this.setState({ vista_guardadas: false }, () => this.receivedData())}>
                            <IconArrowBack />
                        </Button> : null}
                        <h2>{(this.props.clasificadas === true) ? "Transacciones Clasificadas" : "Transacciones Sin Clasificar"}</h2>
                    </Col>
                    <Col xs={24} md={12} className="center">
                        {vista_guardadas === false && this.props.clasificadas === false ? <Button onClick={() => { this.gurdarDespues() }}>
                            Guardar
                        </Button> : null}
                        {vista_guardadas === false && this.props.clasificadas === false ? <Button className="ml-1" onClick={() => { this.getGuardadas() }}>
                            Ver Transacciones Guardadas
                        </Button> : null}
                    </Col>
                </Row>

                <section className="p-1">
                    <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles">
                        <Col span={8} className="center">
                            <Button onClick={this.actualizarTodas} className="filter-purple">Transacciones <div className="title-divider">
                                <IconCheckAll className="ml-1" />
                            </div>
                            </Button>
                        </Col>
                        <Col span={6} className="center">
                            <Text type="secondary">Categoria</Text>
                        </Col>
                        <Col span={5} className="center">
                            <Text type="secondary">Fecha</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Monto</Text>
                        </Col>
                    </Row>
                    <div className="card">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            loading={this.state.loading}
                            dataSource={this.state.transacciones}
                            pagination={{
                                current: this.state.page,
                                total: this.state.total,
                                pageSize: this.state.size,
                                onChange: (page) => {
                                    this.setState({ page: page }, () => { this.receivedData() })
                                }
                            }}
                            locale={{
                                emptyText: <div>
                                    No hay Transacciones registradas del <strong>{this.state.of.format("LL")}</strong> al <strong>{this.state.to.format("LL")}</strong>
                                    <br></br>
                                </div>
                            }}
                            renderItem={item => {
                                const { amount, value_date, description, transaccion, id, type, category } = item;
                                return (
                                    <List.Item className="component-list-item-small">
                                        <Card className="card-list-small">
                                            <Row style={{ width: '100%' }} className="">
                                                <Col span={2} className="center">
                                                    <Checkbox className="radio-purple"
                                                        disabled={(transaccion && transaccion._id)}
                                                        checked={(transaccion && transaccion._id) || (this.state.seleccionadas[id])}
                                                        onChange={value => {
                                                            this.setState(state => {
                                                                if (value.target.checked === true)
                                                                    state.seleccionadas[id] = item
                                                                else
                                                                    delete state.seleccionadas[id]
                                                                return state;
                                                            })
                                                        }}
                                                    ></Checkbox>
                                                </Col>
                                                <Col span={6}>
                                                    <Text>{description}</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text>{category}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text strong>{moment(value_date).format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Statistic value={amount} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: (type === "OUTFLOW") ? '#FF0000' : '#00FF19' }} prefix={'$'} />
                                                </Col>
                                                <Col span={1} className="center">
                                                    {vista_guardadas ? <Popconfirm placement="topRight"
                                                        title="¿Eliminar de Guardados?" okText="Si, seguro" cancelText="No"
                                                        onConfirm={() => {
                                                            this.deleteGuardados(item._id)
                                                        }}>
                                                        <Button type="primary"
                                                            className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                            title="Eliminar">
                                                            <IconDelete />
                                                        </Button>
                                                    </Popconfirm> : null
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )
                            }}
                        />
                    </div>
                </section>
                <ModalClasificar
                    visible={this.state.modalClasificarVisible}
                    onClose={() => {
                        this.setState({ modalClasificarVisible: false })
                        if (this.state.vista_guardadas) {
                            this.getGuardadas()
                        } else {
                            this.receivedData()
                        }
                    }}
                    transacciones={this.state.seleccionadas}
                    clearTransacciones={() => { this.setState({ seleccionadas: {} }) }}
                    cuenta_id={this.props.match.params.link}
                    account_id={this.props.match.params.account_id}
                />
                <Button className="btnCheckGreen" title="" onClick={() => this.guardarTransaccionesSeleccionadas()} >
                    <IconCheckGreen />
                </Button>
            </div>
        )
    }
}


