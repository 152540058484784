import React, {Component} from 'react';
import {Button, Col, Row, Spin, Empty, Typography, List, Card, Popconfirm, Breadcrumb, Pagination } from 'antd'
import {FaArrowRight} from "react-icons/fa";
import {Link} from 'react-router-dom';

//componentes
import { CardInfo } from'../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import { IconEdit, IconDelete, IconPlus, IconEye, IconCopy } from '../../Widgets/Iconos';
//Modales
import ModalMaterial from '../Modales/Manager/ModalMaterial';
//css
import '../../../Styles/projectManager.css'

const axios = require('axios').default;
const { Text } = Typography;


export default class MaterialAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            ModalMaterialVisible: false,
            manager_actividad_id: this.props.match.params.manager_actividad_id,
            actividad: {},
            modalTitle: 'Añadir',
            material_id: undefined,

            /*pagination*/
            total: 0,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getActividad(1);
    }

    /**
    * @memberof MaterialAdmin
    *
    * @method   hideModal
    * @description  Cambia state para cerrar cualquier modal
    *
    **/
    hideModal = () => {
        this.setState({ModalMaterialVisible: false, material_id: undefined})
        this.getActividad(1);
    }

    /**
     * @memberof MaterialAdmin
     * @method getActividad
     *
     * @description Obtiene la informacion de la actividad
     *
     */
    getActividad = (page) => {
        this.setState({loading: true})
        axios.get("/manager/proyects/inmuebles/actividades/get", {
            params: {
                id: this.state.manager_actividad_id,
                paginationMateriales: true,
                limit: 10,
                page: page,

            }
        })
            .then(res => {
                this.setState({
                    loading: false,
                    manager_proyecto_id: res.data.data.manager_proyecto_id._id,
                    inmueble_id: res.data.data.manager_inmueble_id,
                    actividad_id: res.data.data._id,
                    materiales_requeridos: res.data.data.materiales_requeridos,
                    actividad: res.data.data,
                    total: res.data.extra.totalMateriales, 
                })
            })
            .catch(e =>
                console.log('error actividad get', e)
            )
    }


    /**
     * @memberof MaterialAdmin
     * @method getActividad
     *
     * @description Elimina un material
     *
     */
    deleteMaterial = (actividad_id, material_id) => {
        this.setState({loading: true})
        axios.post('/manager/proyectos/inmuebles/actividades/material/delete', {
            actividad_id,
            material_id
        })
            .then(res => {
                this.getActividad(1)
            })
            .catch(e =>
                console.log('error actividad get', e)
            )
        //
    }


    render() {
        return (
             <div>
                 <Spin spinning={this.state.loading}>


                <Row className="row-encabezado">
                    <Col xs={24} className="flex-left">
                        <Breadcrumb className="breadcrumb" separator=">">
                            <Breadcrumb.Item>
                                <Link to="/admin/manager">Proyectos</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={'/admin/manager/proyecto/' + this.state.manager_proyecto_id}><Avatar
                                        name={this.state.actividad.manager_proyecto_id?.proyecto_id.nombre}
                                        color={this.state.actividad.manager_proyecto_id?.proyecto_id.color}
                                        image={this.state.actividad.manager_proyecto_id?.proyecto_id.logo}
                                        /> {this.state.actividad.manager_proyecto_id?.proyecto_id.nombre}
                                    </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={"/admin/manager/proyecto/inmueble/" + this.state.actividad.manager_inmueble_id}>
                                    {this.state.actividad.inmueble_id?.nombre}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {this.state.actividad.actividad_id?.nombre}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item className="breadcrumb-actual">
                                Materiales
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <section className="p-1">

                   <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                        <Col span={8} className="center">
                            <Text type="secondary">Material</Text>
                        </Col>
                        <Col span={8} className="center">
                            <Text type="secondary">Cantidad</Text>
                        </Col>
                        <Col span={8} className="center">
                            <Text type="secondary">Acciones</Text>
                        </Col>
                    </Row>

                    <List
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.materiales_requeridos}
                        renderItem={item => (
                            <List.Item  className="component-list-item">
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">
                                        <Col span={8} className="center">
                                            <Text strong>{item.material?.nombre}</Text>
                                        </Col>
                                        <Col span={8} className="center">
                                           <Text strong>{item.cantidad}</Text>
                                        </Col>
                                        <Col span={8} className="center">
                                            <Button className="btn btn-edit" onClick={() => {this.setState({ModalMaterialVisible: true, material_id: item.material._id, modalTitle: 'Editar'})}}>
                                               <IconEdit/>
                                            </Button>
                                            <Popconfirm
                                                title="¿Deseas Eliminar?"
                                                placement="topRight"
                                                onConfirm={e => this.deleteMaterial(this.state.manager_actividad_id,item.material._id)}
                                                onCancel={e => e.stopPropagation()}
                                                okText="Si"
                                                cancelText="No"
                                                onClick={e =>  e.stopPropagation()}
                                            >
                                                <Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
                                                    <IconDelete />
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            defaultCurrent={1}
                            total={this.state.total}
                            showSizeChanger={false}
                            onChange={(page) => {
                                this.getActividad(page)
                            }}
                        />
                    </Row>

                </section>


                <Button className="btnAddPlus"  onClick={()=>this.setState({ModalMaterialVisible: true, modalTitle: 'Añadir'})}>
                    <IconPlus/>
                </Button>
                 </Spin>
                <ModalMaterial
                    modalTitle = {this.state.modalTitle}
                    manager_proyecto_id = {this.state.manager_proyecto_id}
                    inmueble_id = {this.state.inmueble_id}
                    actividad_id = {this.state.actividad_id}
                    material_id = {this.state.material_id}
                    modalVisible={this.state.ModalMaterialVisible}


                    material={this.state.material}
                    closeMethod={this.hideModal}/>
            </div>
        )
    }
}
