import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Spin, Form, Select, message, InputNumber,Statistic, Avatar  } from 'antd';
//componentes
import { IconCloseModal  } from '../../../Widgets/Iconos';
import { MyUpload } from './../../../Widgets/Upload';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalDetalleNomina extends Component {

    formEstatus = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    /**
    * @memberof ModalDetalleNomina
    *
    * @method   componentDidMount
    * @description  
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    /**
    * @memberof ModalDetalleNomina
    *
    * @method   onFinish
    * @description  Se ejecuta al aceptar el formualario
    *
    **/
    hideModal = () => {
        this.props.closeMethod()
        this.setState({loading: false})
    }   


    render() {

        const { transaccion } = this.props
        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
                width={750}
            >

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Concepto: {transaccion.concepto}</Title>
                </div>

                <Spin spinning={this.state.loading}>
                    <Form
                        layout="vertical"
                        className="frm-transacciones"
                        name="formulario-transacciones"
                        onFinish={this.onFinish}
                    >
                        {(this.state.det_descripcion)?<Row>
                            <Col xs={24} lg={{ span: 23, push: 1 }}>
                                <label className="lblTitulo">Descripcion</label>
                                <p className="pInfo">{this.state.det_descripcion}</p>
                            </Col>
                        </Row>:null}

                        <Row>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Cuenta</label>
                                <p className="pInfo">{transaccion.cuenta_id?.nombre}</p>
                            </Col>

                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Fecha</label>
                                <p className="pInfo">{moment(transaccion.fecha).format('DD / MM / YYYY')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Proyecto</label>
                                <p className="pInfo">{transaccion.proyecto_id?.nombre}</p>
                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Area</label>
                                <p className="pInfo">{transaccion.area_id?.nombre}</p>
                            </Col>

                        </Row>
                        <Row style={{ margin: '0' }}>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Rubro</label>
                                <p className="pInfo">{transaccion.rubro_id?.nombre}</p>
                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Subrubro</label>
                                <p className="pInfo">{transaccion.sub_rubro_id?.nombre}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Monto</label>
                                <p className="pInfo">
                                    <Statistic
                                        value={transaccion.monto}
                                        valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}
                                        prefix={'$'}
                                        suffix={'MXN'}
                                    />
                                </p>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "" }}>
                            <Col xs={24} lg={{ span: 22, push: 1 }} className="readOnlyFiles">
                                <label className="lblTitulo">Comprobantes</label>
                                <MyUpload
                                    tipo="detalle"
                                    fileList={transaccion.comprobantes?.map((imagen, index) => new Object({
                                        uid: index * -1,
                                        name: imagen,
                                        status: 'done',
                                        url: axios.defaults.baseURL + '/voucher/' + imagen,
                                    }))}
                                />
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <span className="item-divider"></span>
                            <Col xs={24} lg={{ span: 22, push: 1 }}>
                                <label className="lblTitulo">Autor</label>
                                <p className="pInfo">{transaccion.usuario_id?.nombre} {transaccion.usuario_id?.apellido}</p>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
