import React, { Component } from 'react';
import { Row, Col, Table, Button, Popconfirm, message, Spin, Typography } from 'antd'
import { Link } from 'react-router-dom';
import { IconPlus, IconEye, IconDelete, IconEdit } from '../../../Widgets/Iconos';
import '../../../../Styles/Global.css'
import ModalCrearEmpleado from '../../Modales/Empleados/ModalCrearEmpleado';
import { Redirect } from 'react-router-dom';
const axios = require('axios').default
const { Title, } = Typography;
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


export default class Empleados extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            data: [],
            page: 1,
            limit: 100,
            id: null,
            modalCrearvisible: false,
            search: "",
            searching: true,


            filtroSearch: "",
        }
    }



    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.receivedData();
    }


    /**
     * @memberof Empleados
     *
     * @method componentDidUpdate
     * @description Actualiza la vista si se ha realizado una busqueda
     */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.state.searching = false;
            this.receivedData();
        }
    }



    /**
     *
     * @memberof Empleados
     * @method receivedData
     * @description Obtiene la informacion y actualiza los valores locales de la lista.
     */

    receivedData = async (page = this.state.page, limit = this.state.limit, search = this.props.search) => {
        this.setState({ loading: true });
        var search = this.props.search

        axios.get('/empleados/list', {
            params: {
                page,
                limit,
                search: search
            }
        })
            .then(res => this.setState(state => {
                state.limit = limit;
                state.page = page;
                state.total = res.data.data.paginator.itemCount;
                state.data = res.data.data.itemsList;

                state.search = search;
                state.searching = true;
                state.loading = false;
                state.search = search;
                state.id = null;
                this.filtroSearch = search;
                return state;
            }))

            .catch(res => {
                console.log("error", res);
            }).finally(f => {
                this.setState({ loading: false })
            });
    }



    eliminarEmpleado = async (id_usuario) => {

        axios.post('/empleados/delete', {
            _id: id_usuario,
        })
            .then(e => {
                this.receivedData()
                message.success(e.data.message)
            })
            .catch(error => {
                message.error(error.data.message)
            })


    }




    /**
     * @memberof Empleados
     * @method modalCrearEmpleado
     * * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrearEmpleado = () => {
        this.setState({
            modalCrearvisible: true,
        })
    }


    /**
     *  @memberof Empleados
     * @method modalEditar 
    * @description Abrir modal tipo edición para registro seleccionado desde tabla.
    * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
    */
    modalEditarEmpleado = (item) => {
        const reg_id = item.currentTarget.id;
        this.setState({
            id: reg_id,
            modalCrearvisible: true,
        })
    }


    /**
     * @memberof Empleados
     * @method hideModal
     * @description Cerrar modal y limpiar valor registroId
     */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            id: null,
            modalCrearvisible: false,
        })
    }

    render() {
        const columns = [
            {
                title: 'Nombre',
                render: (text, record) => (
                    <h3>{record.usuario_id.nombre + ' ' + record.usuario_id.apellido}</h3>
                ),
            },
            {
                title: 'Modalidad',
                dataIndex: 'modalidad',
                render: (text) => (text === 1) ? "Salarial" : "Destajo"
            },
            {
                title: 'Acciones',
                key: 'actions',
                fixed: "right",
                width: 150,
                render: (text, record) => (
                    <div className="tbl-opciones">
                        <Link title="Ver" to={`/admin/nomina/empleados/${record.usuario_id._id}`}>
                            <Button type="" disabled={(record.modalidad === 1)} className="button-survey btn-eye button-eye" title="Ver"  >
                                <IconEye />
                            </Button>
                        </Link>

                        <Button type="primary" className="button-survey btn-edit button-edit" id={record.usuario_id._id} onClick={this.modalEditarEmpleado} title="Editar"  >
                            <IconEdit />
                        </Button>
                        <Popconfirm placement="topRight" title="¿estas seguro de eliminar el empleado? Ya no podras agregarlo en próximas nóminas." okText="Si, seguro" cancelText="No" name={record.nombre} id={record._id} onConfirm={() => this.eliminarEmpleado(record.usuario_id._id)} >
                            <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" title="Eliminar"  >
                                <IconDelete />
                            </Button>
                        </Popconfirm>
                    </div>
                ),
            },
        ];

        let { data } = this.state;
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={20}>
                        <h2>Empleados</h2>
                    </Col>
                    <Col xs={12} xl={4} xxl={4} className="flex-right">
                        <Title level={5} style={{
                            textAlign: "right", color: "#FFF", fontSize: '11pt', marginBottom: '0', marginRight: '2rem'
                        }}>
                            Empleados total: {this.state.total}
                        </Title>
                    </Col>
                </Row>

                <Row className="p-1">
                    <Col span={24} >
                        <div className="div_contenido">
                            <Table
                                columns={columns}
                                dataSource={data}
                                loading={this.state.loading}
                                scroll={{ x: 800, y: 570 }}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: this.state.limit,
                                    total: this.state.total,
                                    showSizeChanger: true
                                }}
                                onChange={({ current, pageSize, total }, b, sort) => {
                                    console.log(current, pageSize)
                                    this.receivedData(current, pageSize)
                                }}
                                className="table-bordered" />

                        </div>
                    </Col>
                </Row>
                <ModalCrearEmpleado
                    modalVisible={this.state.modalCrearvisible}
                    hideModal={this.hideModal}
                    id={this.state.id}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                >
                </ModalCrearEmpleado>
                <Button type="ghost" className="btnAddPlus" onClick={this.modalCrearEmpleado}>
                    <IconPlus />
                </Button>
            </div>
        )
    }




}