import React, {Component} from 'react';
import {Button, Col, Row, Spin, Empty, Typography, Pagination, Modal} from 'antd'
import {FaArrowRight} from "react-icons/fa";
import {Link, Redirect} from 'react-router-dom';

//componentes
import {CardProject} from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import {IconEdit, IconDelete, IconPlus} from '../../Widgets/Iconos';
//Modales
import ModalProyectoAdmin from '../Modales/Manager/ModalProyectoAdmin';
//css
import '../../../Styles/projectManager.css'

import Logged from '../../../Hooks/Logged';

const axios = require('axios').default;
const {Text} = Typography;

export default class Manager extends Component {


    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            redirectToProjectAdmin: false,
            modalProyectoVisible: false,

            data: [],
            page: 1,
            limit: 10,
            total: 0,
            search: null,


            loading: false

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);
        this.getProyectosManager()
    }

    /**
     * @memberof Manager
     *
     * @method   hideModal
     * @description  Cambia state para cerrar cualquier modal
     *
     **/
    hideModal = () => {
        this.setState({modalProyectoVisible: false})
    }


    /**
     *
     *
     * @memberof Manager
     * @function getProyectosManager
     *
     * @description Obtenemos los proyectos del módulo de proyectos.
     *
     * @param page Página actual
     * @param limit Limite de elementos por página
     * @param search Texto a buscar.
     *
     */
    getProyectosManager = (page = this.state.page, limit = this.state.limit, search = this.state.search) => {
        this.setState({loading: true})
        axios.get('/manager/proyectos', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({data}) => {
                this.setState(state => {
                    state.data = data.data.itemsList;
                    state.page = page;
                    state.limit = limit;
                    state.search = search;
                    state.total = data.data.itemCount;

                    state.loading = false;
                    return state
                })
            })
            .catch(e => {
                Modal.confirm({
                    title: "No es posible obtener los proyectos.",
                    content: "Ocurrió un error al obtener los proyectos."
                })
            })
    }

    /**
     *
     *
     * @memberof Manager
     * @function deleteProyecto
     *
     * @description Elimina un proyecto
     *
     * @param id ID del proyecto a eliminar
     *
     */
    deleteProyecto = (id) => {
        let component = this;
        Modal.confirm({
            title: "¿Estas seguro de eliminar el proyecto?",
            content: "Esto provocará que se elimine toda la información y los datos al respecto de este proyecto dentro del Manager. No se borrará el proyecto como tal.",
            cancelText: "Cancelar",
            okText: "Quiero eliminar el Proyecto",
            onOk: function () {
                component.setState({loading: true})
                axios.delete('/manager/proyectos/delete', {
                    params: {
                        id
                    }
                })
                    .finally(e => {
                        component.getProyectosManager()
                    })
            }
        })
    }

    render() {
        const {data} = this.state
        const user = this.context;
        return (
            <div style={{minHeight: '100'}}>
                <Row className="row-encabezado">
                    <Col xs={24} xl={6} xxl={8}>
                        <h2 style={{width: 'auto', float: 'left'}}>Manager</h2>
                    </Col>
                </Row>

                <section className="p-1">
                    <Spin spinning={this.state.loading}>
                        <Row gutter={[22, 24]} style={{padding: '10px'}}>
                            {(data.length < 1 && !this.state.loading) ? <Empty

                                style={{margin: '40px auto'}}

                                description={<h1 style={{fontSize: '20px'}}>
                                    No hay ningun proyecto <a onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({modalProyectoVisible: true})
                                }}>¡Agrega uno!</a>
                                </h1>}/> : null}
                            {data.map(({proyecto, _id}, data) => <Col xs={24} lg={8} className="gutter-row">
                                <CardProject
                                    id={proyecto._id}
                                    onDelete={user && user.tipo === 1}

                                    deleteProyecto={() => this.deleteProyecto(proyecto._id)}

                                    title='PROYECTO'
                                    name={proyecto.nombre}
                                    to={`/admin/manager/proyecto/${_id}`}
                                    avatar={<Avatar
                                        name={proyecto.nombre}
                                        image={proyecto.image}
                                        color={proyecto.color}
                                        style={{width: '60px', height: '60px', fontSize: '30px'}}
                                        className="avatar-group-card"
                                    />}
                                />

                            </Col>)}
                        </Row>
                        <Row className="mt-2 pb-3">
                            <Pagination
                                total={this.state.total}
                                onShowSizeChange={true}
                                onChange={(page) => {
                                    this.getProyectosManager(page)
                                }}/>
                        </Row>
                    </Spin>
                </section>
                <Button className="btnAddPlus" onClick={() => this.setState({modalProyectoVisible: true})}>
                    <IconPlus/>
                </Button>
                <ModalProyectoAdmin
                    modalVisible={this.state.modalProyectoVisible}
                    closeMethod={() => {
                        this.getProyectosManager()
                        this.hideModal()
                    }}/>
            </div>

        )
    }
}
