import React, { Component } from 'react';
import { Collapse, List, Button, Col, Dropdown, Menu, Row, Typography, Pagination, Spin, Popconfirm, Tooltip, Modal, Tag, message } from 'antd'
import axios from 'axios';

//componentes
import { IconPlus, IconArrowCustom as Arrow, IconPeople, IconPlusGroup, IconEdit, IconDelete, IconCopy, IconArrow } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//modales
import ModalInmuebleTrabajo from "../../Modales/Nomina/ModalInmuebleTrabajo";
import ModalInmuebleTajo from "../../Modales/Nomina/ModalInmuebleTajo";

const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

const data = [
	{
		elem: 0
	}, {
		elem: 1
	}
]

export default class InmueblesTrabajo extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			modalInmuebleTrabajoVisible: false,
			modalInmuebleTajoVisible: false,
			inmuebles: [],
			InmueblesTrabajo_id: undefined,
			tajos_array_element_id: undefined,
			modalTitle: 'Añadir',

			/*paginador*/
			total: 0,
			page: 1,
			limit: 100,
			total_pages: 0,
		}
	}

	/**
	* @memberof InmueblesTrabajo
	* @description Iniciamos los valores para obtener los grupos
	*/
	componentDidMount() {
		//Mostrar input superior de busqueda 
		//Para acceder al valor del input = this.props.search
		this.props.updateFilterSearch(true);
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

		this.loadInmuebles();
	}

	/**
	 * @memberof InmueblesTrabajo
	 * @function closeModals Cierra todos los modales
	 *
	 */
	closeModals = () => {
		this.setState({
			modalInmuebleTrabajoVisible: false,
			InmueblesTrabajo_id: undefined,
			modalInmuebleTajoVisible: false,
			tajos_array_element_id: undefined,
		})
		this.loadInmuebles();
	}

	/**
	* @memberof InmueblesTrabajo
	*
	* @method   loadInmuebles
	* @description  Trae un listado de los inmuebles regsitrados en Inmuebles_trabajo
	*
	**/
	loadInmuebles = (page) => {
		this.setState({ loading: true })

		axios.get('/inmuebles-trabajo/list', {
			params: {
				page: this.state.page,
				limit: this.state.limit,
			}
		})
			.then(res => {

				console.log('res inmueble trabajo',res);

				this.setState({
					inmuebles: res.data.data.itemsList,
					total: res.data.data.itemCount,
					page: res.data.data.currentPage,
					limit: res.data.data.perPage,
					total_pages: res.data.data.pageCount,
					loading: false,
				})
			})
			.catch(e => {
				console.log('error inmuebles get', e)
			}
			)
	}

	/**
   * @memberof InmueblesTrabajo
   *
   * @method   deleteInmueble
   * @param id ObjectId _id de Inmueble_trabajo
   * @param inmueble_id {*} Objeto Inmueble
   * @description  Trae un listado de los inmuebles regsitrados en Inmuebles_trabajo por medio de su id
   *
   **/
	deleteInmueble = (id, inmueble_id) => {
		axios.post('/inmuebles-trabajo/delete', {
			id: id,
			inmueble_id: inmueble_id
		})
			.then(res => {
				message.success('¡Inmueble eliminado!');
				this.loadInmuebles(1)
			})
			.catch(e => {
				console.log('error inmuebles get', e)
				if (e.response?.status === 403) {
					message.error(e.response.data.message)
				} else {
					message.error('Inmueble no eliminado');
				}
			})
	}

	/**
	 * @memberof InmueblesTrabajo
	 *
	 * @method deleteActividad
	 *
	 * @param id ObjectId _id de Inmueble_trabajo
	 * @param id_elemento_array ObjectId _id del elemento del array del Inmueble_trabajo
	 * @description Elimina una Actividad de un Inmueble
	 */
	deleteActividad = (id, id_elemento_array) => {
		this.setState({ loading: true })
		axios.post('/inmuebles-trabajo/delete/tajo', {
			id: id,
			id_elemento_array: id_elemento_array
		})
			.then(res => {
				message.success('¡Actividad eliminada!');
				this.loadInmuebles(1)
			})
			.catch(error => {
				this.setState({ loading: false })
				if (error.response?.status === 403) {
					message.error(error.response.data.message)
				} else {
					message.error('¡Actividad no eliminada!');
				}
			})

	}

	render() {

		return (
			<div>
				<Row className="row-encabezado text-white">
					<Col xs={24} xl={8} xxl={12}>
						<h2>Inmuebles - Trabajo</h2>
					</Col>
				</Row>
				<section className="p-1">
					<Spin spinning={this.state.loading}>
						<Row className="list-container scroll " gutter={[32, 32]} style={{ width: '100%' }}>
							<Col span={24} className="">
								<Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>Inmuebles</Title>
							</Col>
							<Col span={24} className="">
								<Collapse
									bordered={false}
									expandIcon={({ isActive }) => <div>
										<div className={"plus_icon" + (isActive ? " open" : "")} />
									</div>}
									className="group-collapse"
								>
									{this.state.inmuebles.map(({ _id, inmueble_id, inmueble, responsable_id, ArrayTajos, tajos, proyecto_id }, index) => {
										return <Panel header={<Row style={{ width: '100%' }} key={index}>
											<Col xs={7} xl={7} className="flex-left">
												<Text strong> {inmueble?.nombre} </Text>
											</Col>
											<Col xs={4} xl={4} className="center">
												<CustomAvatar
													image={proyecto_id.logo}
													name={proyecto_id.nombre}
													color={proyecto_id.color}
													style={{
														marginRight: '5px'
													}}
												/>
												<Text>{proyecto_id.nombre}</Text>
											</Col>
											<Col xs={4} xl={4} className="center">
												<CustomAvatar
													image={responsable_id ? responsable_id.avatar : ''}
													name={[responsable_id ? responsable_id.nombre : 'N', responsable_id ? responsable_id.apellido : 'A']}
													style={{
														marginRight: '5px'
													}}
												/>
												<Text>{responsable_id.nombre} {responsable_id.apellido}</Text>
											</Col>
											<Col xs={5} xl={5} className="center">
												<Text className="group-title "> Actividades: {tajos.length} </Text>
											</Col>
											<Col xs={4} xl={4} className="center">
												<Button className="btn btn-plus" onClick={e => {
													e.stopPropagation();
													this.setState({ modalInmuebleTajoVisible: true, modalTitle: 'Añadir', InmueblesTrabajo_id: _id })
												}}>
													<IconPlusGroup />
												</Button>
												<Button className="btn btn-edit" onClick={(e) => {
													e.stopPropagation()
													this.setState({ modalInmuebleTrabajoVisible: true, modalTitle: 'Editar', InmueblesTrabajo_id: _id })
												}}>
													<IconEdit />
												</Button>
												<Popconfirm
													placement="topRight"
													title="¿Estás seguro de querer eliminar el Inmueble?"
													onConfirm={e => {
														e.stopPropagation();
														this.deleteInmueble(_id, inmueble);
													}}
													onCancel={e => e.stopPropagation()}
													okText="Si"
													cancelText="No"
													onClick={e => e.stopPropagation()}
												>
													<Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
														<IconDelete />
													</Button>
												</Popconfirm>
											</Col>
										</Row>} className="group-collapse-panel" onClick={e => e.stopPropagation()}>
											<Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
												<Col span={10} offset={2} className="flex-left">
													<Text type="secondary">Actividad</Text>
												</Col>
												<Col span={5} className="center ">
													<Text type="secondary">Presupuesto total</Text>
												</Col>
											</Row>
											<List
												itemLayout="horizontal"
												dataSource={tajos}
												className="list-employees"
												renderItem={(tajo, index) => {

													//console.log(tajo)
													//console.log(ArrayTajos)

													var NombreTajo = "";
													for (let index = 0; index < ArrayTajos.length; index++) {
														const element = ArrayTajos[index];
														//console.log(element)

														if (element._id === tajo.tajo_id) {
															NombreTajo = element.nombre;
															break
														}
													}

													return <List.Item>
														<Row style={{ width: '100%' }}>
															<Col span={10} offset={2} className="">
																{(tajo.tajo_id) ? <strong>{NombreTajo}</strong> : "Esta actividad ha sido eliminada."}
															</Col>
															<Col span={5} className="center">
																<Text> $ {tajo.precio_m2.toMoney(2)}</Text>
															</Col>
															<Col span={5} className="center">
																<Button className="btn btn-edit" onClick={(e) => {
																	e.stopPropagation()
																	this.setState({
																		modalInmuebleTajoVisible: true,
																		modalTitle: 'Editar',
																		InmueblesTrabajo_id: _id,
																		tajos_array_element_id: tajo._id
																	})
																}}> <IconEdit />
																</Button>
																<Popconfirm
																	placement="topRight"
																	title="¿Estás seguro de querer eliminar la Actividad del Inmueble?"
																	onConfirm={e => {
																		e.stopPropagation();
																		this.deleteActividad(_id, tajo._id);
																	}}
																	onCancel={e => e.stopPropagation()}
																	okText="Si"
																	cancelText="No"
																	onClick={e => e.stopPropagation()}
																>
																	<Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
																		<IconDelete />
																	</Button>
																</Popconfirm>
															</Col>
														</Row>
													</List.Item>
												}}
											/>
										</Panel>
									})}
								</Collapse>
							</Col>
						</Row>
					</Spin>

					<Row className="mt-2 pb-3">
						<Pagination
							// defaultCurrent={1}
							current={this.state.page}
							total={this.state.total}
							defaultPageSize={this.state.limit}
							showSizeChanger={false}
							onChange={(page) => {
								this.state.page = page;
								this.loadInmuebles()
							}}
						/>
					</Row>
				</section>
				<Button type="ghost" className="btnAddPlus" onClick={() => { this.setState({ modalInmuebleTrabajoVisible: true, modalTitle: 'Añadir' }) }}>
					<IconPlus />
				</Button>
				<ModalInmuebleTrabajo
					modalVisible={this.state.modalInmuebleTrabajoVisible}
					modalTitle={this.state.modalTitle}
					InmueblesTrabajo_id={this.state.InmueblesTrabajo_id}
					closeMethod={this.closeModals}
				/>
				<ModalInmuebleTajo
					modalVisible={this.state.modalInmuebleTajoVisible}
					modalTitle={this.state.modalTitle}
					InmueblesTrabajo_id={this.state.InmueblesTrabajo_id}
					tajos_array_element_id={this.state.tajos_array_element_id}
					closeMethod={this.closeModals}
				/>
			</div>
		)
	}
}

