import React, { Component } from "react";
import { Button, Modal, Select, Typography, message } from 'antd';
import { Redirect } from "react-router-dom";
//componentes
import { GraficaVentasPorUsuario } from '../../../Widgets/graficas';
import { IconCloseModal, } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios').default;
const { Option } = Select;
const { Title } = Typography;

export default class ModalProyecto extends Component {

    constructor(props) {
        super(props);
        this.state = {

            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false

        }
    }

    //Referencia de form
    ModalVentasPorUsuario = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    //Función para limpiar campos
    limpiarCamposModal = () => {
        if (this.modalTran.current !==undefined) {
            this.modalTran.current.setFieldsValue({

            });
        }
    }

    //Función que busca la info del registro seleccionado
    modalEditar = () => {
        const { registroId } = this.props;
    }

    //Función de guardado-edición dependiendo de la propiedad "accionModal"
    btnSaveClickModal = values => {

        const { accionModal } = this.props;
        if (accionModal === "crear") {

        }
        else if (accionModal === "editar") {
            const { registroId } = this.props;
        }
    }

    //Función que reinicializa los states y llama al método de cierre en componente padre
    hideModal = () => {
        //Reiniciar valores al cerrar modal
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
        })

        this.props.closeMethod();
    }


    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/admin/finanzas" />;
        }
    };


    render() {
        const { modalVisible } = this.props;
        const { accionModal } = this.props;
        const { registroId } = this.props;

        if (accionModal === "editar" && registroId !==undefined && registroId !=="" && (!this.state.datosConsultados)) {
            this.modalEditar();
        }


        var contenido = "";


        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-ventasXusuario"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Ventas por Usuario</Title>

                </div>
                <div>
                    <GraficaVentasPorUsuario />
                </div>
            </Modal>
        )
    }
}

