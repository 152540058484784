import React, { Component } from 'react';
import { Row, Col, Card, Button, Popover, message, Tabs, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
//componentes
import { IconArrowBack, IconPlus } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import Logged from '../../../Hooks/Logged';
import DetallePropiedad from './DetallePropiedad';
//modales
import ModalTransaccionProgramada from '../Modales/Clientes/ModalTransaccionProgramada';
import ModalTransaccionCliente from '../Modales/Clientes/ModalTransaccionCliente';
import ModalPropiedad from '../Modales/Clientes/ModalPropiedad';
import ModalDocumentos from '../Modales/Clientes/ModalDocumento';
//css
import '../../../Styles/detallesClientes.css'
import ModalAdicionales from '../Modales/Clientes/ModalAdicionales';

const moment = require('moment');
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
const { TabPane } = Tabs
const { Text } = Typography

export default class DetalleCliente extends Component {
    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            cliente: {},
            propiedades: [],
            propiedad: undefined,
            transaccion_programada: undefined,
            transaccion: undefined,
            edit_propiedad: undefined,
            defaultActiveKey: '1',
            modal_adicionales: false,

            propiedad_id: this.props.match.params.propiedad_id
        }
    }


    componentDidMount() {
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        window.scrollTo(0, 0)
        this.receivedData();
    }

    /**
     * @method receivedData
     * @description Obtiene la informacion del cliente con sus propiedades y actualiza los valores locales de la vista.
     */
    receivedData = () => {
        this.setState({ loading: true })

        axios.post('/clientes/get', {
            id: this.props.match.params.id,
        }).then(response => {
            //creamos referecias para cada propiedad, para despues llamar los metodos para traer informacion mas facil
            if (response.data.propiedades.length !== this.state.propiedades.length)
                for (const prop of response.data.propiedades) {
                    this["myRef" + prop._id] = React.createRef();
                }


            let pro;

            //Si ya hay una propiedad en el link a declarar por defecto, se declara.
            if (this.state.propiedad_id) {
                pro = response.data.propiedades.find(prop => prop._id === this.state.propiedad_id)
                console.log('this.tabs', this.tabs);
                if (this.tabs) {
                    this.state.defaultActiveKey = pro._id
                } else {
                    this.state.defaultActiveKey = pro._id
                }
            }

            //Buscamos la propiedad que se esta siendo visualizada para actualizar los datos
            if (this.state.propiedad !== undefined) {
                pro = response.data.propiedades.find(prop => prop._id === this.state.propiedad._id)
            }
                console.clear()
                console.log("pro", pro);


            this.setState({
                cliente: response.data.data,
                propiedades: response.data.propiedades,
                propiedad: (this.state.propiedad_id) ? pro : (this.state.propiedad === undefined ? response.data.propiedades[0] : pro),
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => {
            this.setState({ loading: false })
        })

    }


    /**
     * @method onSelectPropiedad
     * @description Pone en el state todo el objeto propiedad, del cual se esta viendo, para que los modales tengan la propiedad actutal
     */
    onSelectPropiedad = (propiedad_id) => {
        let propiedad = this.state.propiedades.find(prop => prop._id === propiedad_id)
        this.setState({ propiedad })
    }

    /**
     * @method modalDetalleTransaccionProgramada
     * @description abre el modal para ver con detalle la transaccion Programada
     * @param transaccion_propgramada obj
     */
    modalDetalleTransaccionProgramada = (transaccion_programada) => {
        this.setState({
            transaccion_programada,
            detalle: true,
            modalVisibleTranProgramada: true
        })
    }


    /**
    * @method modalDetalleTransaccionProgramada
    * @description abre el modal para editar una transaccion programada
    * @param transaccion obj
    */
    modalEditarTransaccionProgramada = (tra_progr) => {
        this.setState({
            transaccion_programada: {
                _id: tra_progr._id,
                concepto: tra_progr.concepto,
                fecha: moment(tra_progr.fecha),
                monto: tra_progr.monto,
                cuenta_id: tra_progr.cuenta_id._id
            },
            modalVisibleTranProgramada: true,
            accionModal: 'editar',
        })
    }


    /**
     * @description Cierra todos los modales, o/y actualiza las transacciones de la propiedad, dependiendo de los que se pida
     * @param update indica que tipo de transacciones actualizar
     *
     * */
    hideModal = (update) => {
        const { propiedad } = this.state

        this.setState({
            detalle: false,
            transaccion: undefined,
            transaccion_programada: undefined,
            modalTranClienteVisible: false,
            modalVisibleTranProgramada: false,
            accionModal: '',

            registroId: '',
            transaccion_id: '',
            transaccion_seleccionada: {},
            modalVisibleTran: false,
            modalVisible: false,
            modalCrearTransaccionProgramada: false,

        })
        this.receivedData();

        //Actualización la info de las Transacciones de la propiedad actual
        if (update === 't_programadas')
            this['myRef' + propiedad._id].current.getTransaccionesProgramadas()

        if (update === 'transc')
            this['myRef' + propiedad._id].current.getTransacciones()
    }

    render() {
        const contentMenuPlus = (
            <div className="div-menuplus">
                <Button htmlType="submit" type="primary" className="btn-azul"
                    onClick={() => this.setState({ accionModal: 'crear', modalTranClienteVisible: true })}>
                    Transacción
                </Button>
                <Button htmlType="submit" type="primary" className="btn-azul"
                    onClick={() => this.setState({ modalVisibleTranProgramada: true })}>
                    Transacción Programada
                </Button>
                <Button htmlType="submit" type="primary" className="btn-azul"
                    onClick={() => this.setState({ accionModal: 'especie', modalTranClienteVisible: true, })}>
                    Pago en Especie
                </Button>
                <Button htmlType="submit" type="primary" className="btn-azul"
                    onClick={() => this.setState({ accionModal: 'documentos', modalTranClienteVisible: true })}>
                    Documento
                </Button>
                {/* <Button htmlType="submit" type="primary" className="btn-azul" onClick={()=>this.setState({modalPropiedad: true})}>
                    Añadir Propiedad
                </Button>*/}
            </div>
        );


        this.tabs = React.createRef()
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={6} xxl={8} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <h2 style={{ width: 'auto', float: 'left' }}> Clientes </h2>
                    </Col>
                </Row>
                <section className="p-1">
                    <Row>
                        <Col span={24}>
                            <Card bordered className="card-proyeccionesDetalle" >
                                <Row>
                                    <h1 className="blue-title">Información Personal</h1>
                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Nombre</label>
                                        <p className="pInfo">{this.state.cliente?.nombre}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Apellido</label>
                                        <p className="pInfo">{this.state.cliente?.apellido}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Email</label>
                                        <p className="pInfo">{this.state.cliente.email}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Teléfono</label>
                                        <p className="pInfo">{this.state.cliente.telefono}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="pt-1">
                            <Tabs
                                ref={this.tabs}
                                activeKey={this.state.propiedad?._id}
                                onTabClick={(key) => this.onSelectPropiedad(key)}
                                type="editable-card"
                                addIcon={<Button onClick={() => this.setState({ modalPropiedad: true })} className="btn-add-propiedad"> <PlusOutlined /> <Text>Añadir Propiedad </Text></Button>}
                            >

                                {
                                    this.state.propiedades.map((propiedad, index) => <TabPane closable={false} tab={<div>
                                        <Text>{propiedad.inmueble_id?.nombre ? propiedad.inmueble_id?.nombre : propiedad.rubro_id?.nombre}</Text>
                                        <CustomAvatar
                                            name={propiedad.proyecto_id?.nombre}
                                            color={propiedad.proyecto_id?.color}
                                            size="small"
                                            style={{
                                                marginLeft: '5px'
                                            }}
                                        />
                                    </div>} key={propiedad._id}>
                                        <DetallePropiedad
                                            ref={this["myRef" + propiedad._id]}
                                            propiedad={propiedad}
                                            receivedData={this.receivedData}
                                            verTransaccionProgramada={this.modalDetalleTransaccionProgramada}
                                            editarProgramada={this.modalEditarTransaccionProgramada}
                                            editPropiedad={(p) => this.setState({ modalPropiedad: true, edit_propiedad: p })}
                                            editDocumentos={(p) => this.setState({
                                                modalDocumentos: true,
                                                edit_propiedad: p,
                                                modalPropiedad: false,
                                            })}
                                            editAdicionales={(p) => this.setState({
                                                modal_adicionales: true,
                                                edit_propiedad: p,
                                            })}
                                        />
                                    </TabPane>)
                                }

                            </Tabs>
                        </Col>
                    </Row>
                </section>

                <Popover content={contentMenuPlus} >
                    <Button className="btnAddPlus">
                        <IconPlus />
                    </Button>
                </Popover>

                {/*Modal para crear transaccion*/}
                <ModalTransaccionCliente
                    modalVisible={this.state.modalTranClienteVisible}
                    accionModal={this.state.accionModal}
                    propiedad={this.state.propiedad}
                    hideModal={this.hideModal}
                />

                {/*Modal para crear, editar transaccion programada*/}
                <ModalTransaccionProgramada
                    visible={this.state.modalVisibleTranProgramada}
                    propiedad={this.state.propiedad}
                    transaccionProgramada={this.state.transaccion_programada}
                    detalle={this.state.detalle}
                    accionModal={this.state.accionModal}
                    hideModal={this.hideModal}
                />

                {/*Modal para crear una propiedad*/}
                <ModalPropiedad
                    visible={this.state.modalPropiedad}
                    onClose={() => {
                        this.setState({ modalPropiedad: false, edit_propiedad: undefined })
                        this.receivedData();
                    }}
                    cliente_id={this.props.match.params.id}
                    propiedad={this.state.edit_propiedad}
                />

                <ModalAdicionales
                    visible={this.state.modal_adicionales}
                    onClose={() => {
                        this.setState({ modal_adicionales: false, edit_propiedad: null, })
                        this.receivedData();
                    }}
                    cliente_id={this.props.match.params.id}
                    propiedad={this.state.edit_propiedad}
                />

                <ModalDocumentos
                    visible={this.state.modalDocumentos}
                    onClose={() => {
                        this.setState({ modalDocumentos: false, edit_propiedad: null, modalPropiedad: false })
                        this.receivedData();
                    }}
                    cliente_id={this.props.match.params.id}
                    propiedad={this.state.edit_propiedad}
                />
            </div>
        )
    }
}
DetalleCliente.contextType = Logged;
