import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Spin, Form, Select, message, InputNumber  } from 'antd';
//componentes
import { IconCloseModal  } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalProformaConcepto extends Component {

	formEstatus = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		}
	}

	/**
	* @memberof ModalProformaConcepto
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
	}

	/**
	* @memberof ModalProformaConcepto
	*
	* @method   hideModal
	* @description	 Cierra el modal
	*
	**/
	hideModal = () => {
		this.props.closeMethod()
		this.setState({loading: false})
	}	

	/**
	* @memberof ModalProformaConcepto
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	onFinish = (values) => {
		if(this.props.modalTitle === 'Editar'){
			this.updateConcepto(values)
		}else{
			this.addConcepto(values)
		}
	}

	/**
	* @memberof ModalProformaConcepto
	*
	* @method   addConcepto
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	addConcepto = (values) => {
		axios.post('/proforma/conceptos/add',{
			proforma_id: this.props.proforma_id,
			nombre: values.nombre, 
		}).then(response => {
			this.hideModal();
			message.success('¡Concepto añadido Exitosamente!')
		}).catch( error =>{
			console.log(error)
			message.error('No se añadio el Concepto')
		})
	}

	/**
	* @memberof ModalProformaConcepto
	*
	* @method   updateConcepto
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	updateConcepto = (values) => {
		axios.post('/proforma/conceptos/update',{
			proforma_id: this.props.proforma_id,
			proforma_concepto_id: this.props.concepto._id,
			nombre: values.nombre, 
		}).then(response => {
			this.hideModal();
			message.success('¡Concepto añadido Exitosamente!')
		}).catch( error =>{
			console.log(error)
			message.error('No se añadio el Concepto')
		})
	}



	render() {
		return (
			<Modal
				visible={this.props.modalVisible}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				closable={false}
				className="modal-form"
				destroyOnClose={true}
				zIndex={1000}
				width={750}
			>

				<div className="modal-header">
					<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
						<IconCloseModal />
					</Button>
					<Title level={3} className="modal-title">{this.props.modalTitle} Concepto</Title>
				</div>

				<Spin spinning={this.state.loading}>
					<Form
						layout="vertical"
						name="formulario-transacciones"
						onFinish={this.onFinish}
						initialValues={this.props.concepto}
					>
						<Row align="center">
							<Col xs={20} md={13}>
								<Form.Item
									label="Concepto"
									name="nombre"//----------------------------------------------------------nombre
									rules={[{
										required: true,
										message: "Por favor, ingrese un Concepto"
									}]}
								>
									<Input/>
								</Form.Item>
							</Col>
							<Col span={20} className="center">
								<Button htmlType="submit" type="primary" className="btn-azul mt-3">
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		)
	}
}

