import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, Popconfirm, message, Pagination} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconEye, IconEdit,  IconDelete, IconPlus, IconArrowBack } from '../../Widgets/Iconos';
//css
import '../../../Styles/presupuesto.css'

const { Title, Text } = Typography;
const axios = require('axios').default

export default class ActividadPorCasa extends Component{

	constructor(props){
		super(props)
		this.state = {
			inmueble_trabajo_id: this.props.match.params.inmueble_trabajo_id,
			inmueble:{},
			actividades:[],
			/* Paginado */
			page: 1,
			total: 0,

		}
	}

	volver = () => {
        window.history.back();
    }


	/**
	* @memberof ActividadPorCasa
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getActividades(this.props.match.params.inmueble_trabajo_id)
	}

	/**
	 * @methodOf  ActividadPorCasa
	 * @function hideModal
	 *
	 * @description Cierra el modal
	 *
	 * */
	hideModal = () => {
		this.setState({ });
	};

	/**
	 * @methodOf  ActividadPorCasa
	 * @function getActividades
	 *
	 * @description Trae las actividades de un inmueble, que esta registrado en inmuebles_trabajados
	 *
	 * */
	getActividades = (id) => {
		this.setState({loading: true})
		axios.get('/presupuesto/detalle/inmueble',{
			params:{
				inmueble_trabajo_id: id
			}
		}).then(response => {

			//se suman el monto pagado en las nominas y el monto extra de la actividad
			let actividades = response.data.data.itemsList.forEach(act => {
				let nomina = act.nominas ? act.nominas.monto_pagado_act : 0
				let monto = act.tajos.monto_extra ? act.tajos.monto_extra : 0
				act.total_monto = nomina + monto
			})
			this.setState({	
				actividades: response.data.data.itemsList,
				inmueble: response.data.inmueble.inmueble_id,
				page: response.data.data.currentPage,
				total: response.data.data.itemCount,
				loading: false,
			})

		}).catch(error => {
			console.log(error)
			message.error('No se cargaron las actividades')
		})
	};



	render() {

		const {inmueble, inmueble_trabajo_id } = this.state;
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} className="">
					 	<Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
                            <IconArrowBack />
                        </Button>
						<h2 style={{width: 'auto', float: 'left'}}>Actividades de {this.state.inmueble?.nombre}</h2>
					</Col>
				</Row>
				<section className="p-1">
					<div className="scroll">
						<Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
							<Col span={8} className="center">
								<Text type="secondary">ACTIVIDAD</Text>
							</Col>
							<Col span={8} className="center">
								<Text type="secondary">MANO DE OBRA</Text>
							</Col>
							<Col span={8} className="center">
								<Text type="secondary">MATERIAL</Text>
							</Col>
						</Row>

						<List
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.actividades}
							loading={this.state.loading}
							locale={{ emptyText: 'Sin Actividades' }}
							renderItem={item =>
								(<List.Item className="component-list-item">
									<Card className="card-list">
										<Row style={{ width: '100%' }} className="">
											<Col span={8} className="center">
												<Text>{item.tajos.tajo_id?.nombre}</Text>
											</Col>
											<Col span={8} className="center">
												<Link to={`/admin/presupuesto/inmueble/${inmueble_trabajo_id}/actividad/${item.tajos.tajo_id?._id}/detalle`}>$ {item.total_monto.toMoney(2)} MXN </Link>
											</Col>
											<Col span={8} className="center">
												<Link to={`/admin/presupuesto/inmueble/${this.state.inmueble?._id}/material/${item.tajos.tajo_id?._id}/detalle`}>
													$ {item.materiales ? item.materiales.total_materiales.toMoney(2) : '0.00'} MXN
												</Link>
											</Col>
										</Row>
									</Card>
								</List.Item>
								)}
						/>
					</div>
					<Row className="mt-2 pb-3">
						<Pagination
							total={this.state.total}
							current={this.state.page}
							onChange={(page) => {
									
								}}
							/>
						</Row>
				</section>
				
			</div>
		)
	}
}