import React, { Component } from 'react';
import {
    Button,
    Col,
    Row,
    Spin,
    Pagination,
    Typography,
    Progress,
    List,
    Badge,
    Card,
    Breadcrumb,
    Tabs,
    Modal, Carousel, Result
} from 'antd'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

//componentes
import { CardInfoDashboard, CardProgress, CardActivity, CardInfo, CardProgressBar } from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import { IconEye } from '../../Widgets/Iconos';
//css
import '../../../Styles/projectManager.css'
import { Img } from "react-image";
import { LeftOutlined } from "@ant-design/icons";
import { getDefaultNormalizer } from '@testing-library/react';


const moment = require('moment')

const axios = require('axios').default;
const { Text } = Typography;
const { TabPane } = Tabs;

/**
 *
 *
 * @export
 * @class PMActividad
 * @extends {Component}
 * @description clase que contiene la vista de una actividad especifica de un inmueble
 */
export default class PMActividad extends Component {

    /**
     *
     * @param tajos Variable de paginación
     * @param tajos.data Lista de elementos
     * @param tajos.page Número de página
     * @param tajos.limit Límite de elementos
     * @param tajos.search Cuando la busqueda está activida
     * @param tajos.total Número total de elementos
     *
     * @param actividad Variable actividad
     *
     * @param tajos_terminadas Número de elenentos terminadas
     * @param tajos_proceso Número de elementos en procecso
     * @param tajos_sin_comienzo Elemetos sin comenzar
     *
     * @param loading
     *
     */
    constructor(props) {
        super(props)
        this.state = {
            tajos: {
                data: [],
                page: 1,
                limit: 0,
                search: null,
                total: 0
            },

            manager_proyecto_id: "",
            manager_actividad_id: "",
            manager_inmueble_id: "",

            proyecto_nombre: "",
            inmueble_nombre: "",
            actividad_nombre: "",

            costo_actual: 0,
            costo_presupuestado: 0,
            avance: 0,

            actividad: {
                manager_proyecto_id: {
                    _id: "",
                    proyecto_id: {
                        nombre: ""
                    }
                },

                manager_inmueble_id: {
                    _id: ""
                },
                actividad_id: {},
                tajo_id: {},
                inmueble_id: {
                    nombre: ""
                },
                responsable_id: {
                    nombre: '',
                    apellido: '',
                    logo: '',
                    color: '',
                },

                progreso_proyectado: 0,
                progreso_real: 0,

                costo_presupuestado: 0,
                costo_actual: 0,

                materiales_presupuestados: 0,
                materiales_entregados: 0,

                m2_totales: 0,
                m2_actuales: 0,

                bitacora: []
            },
            tajos_terminadas: 0,
            tajos_proceso: 0,
            tajos_sin_comienzo: 0,
            bitacora:false,
            avancePonderado: 0 ,

            loading: false,

            vista: "tajos",
            images: []
        }
    }

    /**
     *
     *
     * @memberof PMActividad
     * @method componentDidMount
     * @description Ejecuta la consulta para traer la informacion de la actividad seleccionada
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        // const id = (this.props.params.match.actividad_id);


        this.state.manager_actividad_id = this.props.match.params.id.split('_')[0]
        this.state.manager_inmueble_id = this.props.match.params.id.split('_')[1]

        // this.getActividad();

        this.loadActividad();
    }


    /**
    * @memberof PMActividad
    * @method   loadActividad
    * @description  trae la info de actividad de un inmueble
    * 
    * @param {*} page Página de actividad
    * @param {*} limit Limite de elementos
    * @param {*} search Busqueda
    *
    * */
    loadActividad = async (page = this.state.page, limit = this.state.limit) => {
        this.setState({ loading: true })
        axios.get("/manager/proyectos/inmuebles/actividades/get", {
            params: {
                page: page,
                limit: limit,
                manager_actividad_id: this.props.match.params.id.split('_')[0],
                manager_inmueble_id: this.props.match.params.id.split('_')[1]  //--------id de Inmueble de url
            },
        })
            .then(({ data }) => {
                console.log(" ");

                console.log('Actividades', data)
                var dataActividad = data.data.actividad;
                console.log(dataActividad);

                console.log(" --- ");

                var dataInmueble = data.data.inmueble;
                console.log(dataInmueble);

                console.log(" --- ");

                var dataActividades = data.data.manager_actividades;
                console.log(dataActividades);

                console.log(" --- ");

                var dataExtras = data.extra;
                console.log(dataExtras);

                console.log(" --- ");

                var avancePonderado = dataActividad.progreso_proyectado  //( dataActividad.costo_actual * 100 )/ dataActividad.costo_presupuestado;

                this.setState({
                    loading: false,
                    actividad: dataActividad,
                    proyecto_nombre: dataInmueble.proyecto_id.nombre,
                    inmueble_nombre: dataInmueble.inmueble_id.nombre,
                    actividad_nombre: dataActividad.actividad_id.nombre,

                    manager_proyecto_id: dataActividad.manager_proyecto_id,

                    tajos: {
                        data: dataActividades.itemsList,
                        page: dataActividades.page,
                        limit: dataActividades.limit,
                        //search,
                    },

                    costo_actual: data.extra.cantPagado,
                    costo_presupuestado: data.extra.cantPresupuestado,
                    avance: data.extra.avance,

                    avancePonderado: avancePonderado
                })
            })
            .catch(e => console.log('error al cargar las actividades', e))
    }


    carousel = React.createRef()

    render() {
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} className="flex-left">
                            <Breadcrumb className="breadcrumb" separator=">">
                                <Breadcrumb.Item>

                                    <Link to="/admin/manager">Proyectos</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={'/admin/project-manager/proyecto/' + this.state.manager_proyecto_id}>{this.state.proyecto_nombre}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={'/admin/project-manager/proyecto/inmueble/' + this.state.manager_inmueble_id}>{this.state.inmueble_nombre}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    className="breadcrumb-actual"> {this.state.actividad_nombre} </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <section className="p-1">

                        <Row gutter={[16, 16]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo titleLeft="Actividad" infoLeft={this.state.actividad_nombre} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Progreso Real"
                                    titleRight="Progreso Ponderado"
                                    infoLeft={<Progress
                                        type="circle"
                                        percent={(parseFloat(this.state.avance)).toMoney(2)}
                                        width={50} />}
                                    infoRight={<Progress
                                        type="circle"
                                        showInfo={true}
                                        percent={(parseFloat(this.state.avancePonderado)).toMoney(2)}
                                        
                                        width={50} />}
                                />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Responsable"
                                    infoLeft={
                                        <div>
                                            <Avatar
                                                name={[this.state.actividad.responsable_id.nombre, this.state.actividad.responsable_id.apellido]}
                                                image={this.state.actividad.responsable_id.logo}
                                                color={this.state.actividad.responsable_id.color}
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    fontSize: '20px',
                                                    paddingTop: '0px',
                                                    marginRight: '5px',
                                                }}
                                                className="avatar-info-card"
                                            />
                                            <Text>{this.state.actividad.responsable_id.nombre} {this.state.actividad.responsable_id.apellido} </Text>
                                        </div>
                                    }
                                    infoRight={
                                        <div>
                                            <Text>Ver Bitacora</Text>
                                        </div>
                                    } />
                            </Col>

                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Costo Presupuestado"
                                    infoLeft={`$ ${this.state.costo_presupuestado.toMoney()} MXN`}  //"$ 100,000 MXN"

                                    titleRight="Costo Actual"
                                    infoRight={`$ ${this.state.costo_actual.toMoney()} MXN`}
                                />
                            </Col>


                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Fecha Inicio"
                                    titleRight="Fecha Final"
                                    
                                    infoLeft={(this.state.actividad.fecha_inicio) ? moment(this.state.actividad.fecha_inicio).format('YYYY-MM-DD') : 'N/A'}
                                    infoRight={(this.state.actividad.fecha_conclusion) ? moment(this.state.actividad.fecha_conclusion).format('YYYY-MM-DD') : 'N/A'}
                                    />
                            </Col>
                                 {(this.state.actividad.inmueble_id[0])?
                             <Col xs={24} lg={8} className="gutter-row">

                                        <CardInfo
                                            titleLeft="Imagenes estatus"
                                            infoLeft= {((Array.isArray(this.state.actividad.inmueble_id[0].bitacora) &&this.state.actividad.inmueble_id[0].bitacora.length > 0) ?
                                                <Button
                                                onClick={() => {
                                                    this.setState({
                                                        modalCarouselViewer: true,
                                                        images: this.state.actividad.inmueble_id[0].bitacora.map(  imagen => imagen.archivos ),
                                                        bitacora:true,
                                                    })
                                                }} className="btn-view" icon={<IconEye color="white" style={{ position: 'relative', bottom: '2px', left: 1 }} />} />
                                                : null)}
                                                />
                            </Col> 
                                  :null          }
                        </Row>

                        <Tabs defaultActiveKey="1">
                            <TabPane tab={<div className="list-title">Bitacora de Nómina</div>} key="1">
                                <div className="scroll">

                                    <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                                        <Col span={5} className="center ">
                                            <Text type="secondary">Fecha</Text>
                                        </Col>
                                        <Col span={10} className="center ">
                                            <Text type="secondary">Monto</Text>
                                        </Col>
                                        <Col span={4} className="center ">
                                            <Text type="secondary">Imágenes</Text>
                                        </Col>
                                    </Row>

                                    <List
                                        className="component-list"
                                        itemLayout="horizontal"
                                        dataSource={this.state.actividad.bitacora}
                                        style={{maxHeight:"325px", overflow:"auto"}}
                                        renderItem={bitacora => (
                                            <List.Item className="component-list-item">
                                                <Card className="card-list">
                                                    <Row style={{ width: '100%' }} className="">
                                                        <Col span={5} className="center ">
                                                            <Text>{moment(bitacora.fecha).format("YYYY-MM-DD")}</Text>
                                                        </Col>
                                                        <Col span={10} className="center ">
                                                            <Text>$ {bitacora.monto_pagado.toMoney(2)}</Text>
                                                        </Col>
                                                        
                                                        <Col span={4} className="center ">
                                                            {((Array.isArray(bitacora.fotos) && bitacora.fotos.length > 0) ?
                                                                <Button
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            modalCarouselViewer: true,
                                                                            images: bitacora.fotos
                                                                        })
                                                                    }} className="btn-view" icon={<IconEye color="white" style={{ position: 'relative', bottom: '2px', left: 1 }} />} />
                                                                : null)}
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </TabPane>
                            {/* <TabPane tab={<div className="list-title">Actividades</div>} key="2">
                                <div className="scroll">

                                <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                                        <Col span={7} className="center ">
                                            <Text type="secondary">Nombre</Text>
                                        </Col>
                                        <Col span={7} className="center ">
                                            <Text type="secondary">Fecha Inicio - Fin</Text>
                                        </Col>
                                        <Col span={5} className="center ">
                                            <Text type="secondary">Progreso Real</Text>
                                        </Col>
                                        <Col span={5} className="center ">
                                            <Text type="secondary">Avance</Text>
                                        </Col>
                                    </Row>

                                    <List
                                        className="component-list"
                                        itemLayout="horizontal"
                                        dataSource={this.state.tajos.data}
                                        renderItem={tajo => (
                                            <List.Item className="component-list-item">

                                                {console.log('tajo', tajo)}
                                                <Card className="card-list">
                                                    <Row style={{ width: '100%' }} className="">
                                                        <Col span={7} className="center ">
                                                            <Text>{tajo.actividad_id.nombre}</Text>
                                                        </Col>
                                                        <Col span={7} className="center ">
                                                            <Text>{moment(tajo.fecha_inicio).format('YYYY-MM-DD')} - {moment(tajo.fecha_conclusion).format('YYYY-MM-DD')}</Text>
                                                        </Col>
                                                        <Col span={5} className="center ">
                                                            <Text
                                                                className="text-middle">$ {tajo.costo_actual.toMoney()} MXN</Text>
                                                        </Col>
                                                        <Col span={5} className="center ">
                                                            <Progress percent={tajo.progreso_real.toMoney(2)} />
                                                        </Col>
                                                        <Col span={5} className="center ">
                                                            <Badge status={tajo.status} text={tajo.status} />
                                                        </Col>
                                                         
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                                <Row gutter={[16, 24]} style={{ width: '100%' }}>
                                    <Col xs={24} lg={8} className="gutter-row" style={{ margin: '1em 0' }}>
                                        <Pagination
                                            defaultCurrent={1}
                                            showSizeChanger={true}
                                            onChange={(page, limit) => this.getActividad(page, limit)}
                                            total={this.state.tajos.total} />
                                    </Col>
                                </Row>
                            </TabPane>
                         */}
                        
                        </Tabs>
                    </section>
                </Spin>


                <Modal
                    visible={this.state.modalCarouselViewer}
                    onCancel={() => this.setState({ modalCarouselViewer: false,bitacora:false })}
                    className="image-previewer empleado"
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    destroyOnClose={true}
                >
                      { console.log('bitacora',this.state.bitacora)  }
                    <Carousel
                        dots={true}
                        slidesToShow={1}
                        style={{ width: '100%', minWidth: '100%' }}
                        ref={this.carousel}
                        autoplay={false}

                    >
                        {this.state.images.map((e, index) => <div>
                            <div onClick={() => {
                                console.log('aaa',e)
                                this.setState({
                                    modalImagePreview: true,
                                    imageURL: axios.defaults.baseURL + ((!this.state.bitacora)?'/upload/':'/voucher/') + e
                                })
                            }}>
                                <span style={{ fontSize: 1, color: 'white' }}>1</span>
                              
                                <Img
                                    className="image-preview-empleado"
                                    src={axios.defaults.baseURL + ((!this.state.bitacora)?'/upload/':'/voucher/') + e}
                                    loader={<Spin
                                        style={{
                                            display: 'block',
                                            margin: '0px auto',
                                            minWidth: '100px',
                                            minHeight: '100px'
                                        }}

                                        message={"Estamos cargando tu imagen, espera por favor. "}


                                        spinning={true} />}
                                    unloader={<Result
                                        status="warning"
                                        title="No fue posible cargar la imagen."
                                        extra={
                                            <Button type="primary" key="console"
                                                onClick={() => this.setState({ modalCarouselViewer: false })}>
                                                Cerrar
                                            </Button>
                                        }
                                    />}
                                    style={{
                                        display: 'block',
                                        margin: '0px auto',
                                        minWidth: '100px',
                                        minHeight: '100px'
                                    }}
                                />
                            </div>
                        </div>)}

                    </Carousel>
                    <Button onClick={e => this.carousel.current.prev()} className="carousel-button left-control"
                        type="dashed" icon={<LeftOutlined style={{ color: "white" }} />}></Button>


                </Modal>


            </div>
        )
    }
}
