import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography,   } from 'antd';

import { IconCloseModal  } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const moment = require('moment');
const { Title } = Typography;


export default class ModalRecordatorioDetalle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            concepto:'',
            fecha: '',
            tipo:'',
            monto:'',
        }
    }

    /**
    * @memberof ModalRecordatorioDetalle
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
        this.setState({
            concepto: this.props.concepto,
            fecha: this.props.fecha,
            tipo:this.props.tipo,
            monto:this.props.monto,
        });
    }

    /**
    * @memberof ModalRecordatorioDetalle
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props  
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
    }

    render() {
        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form  modal-recibo"
                destroyOnClose={true}
                zIndex={1000}
            >

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Recibo</Title>
                </div>
                <Row>
                    <Col xs={{span: 20, offset: 2}} lg={{span: 10, offset: 2}} >
                        <label className="lblTitulo">Concepto</label>
                        <p className="pInfo">{this.props.concepto}</p>
                    </Col>

                    <Col xs={{span: 20, offset: 2}} lg={{span: 10, offset: 1}} >
                        <label className="lblTitulo">Fecha y Hora</label>
                            <DatePicker
                                format="DD/MM/YYYY HH:mm"
                                showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                defaultValue= { moment(this.props.fecha)}
                                disabled
                            />
                    </Col>
                </Row>

                <Row>
                    <Col xs={{span: 20, offset: 2}} lg={{span: 10, offset: 2}}>
                        <label className="lblTitulo">Tipo</label>
                        <p className="pInfo">{this.props.tipo === 1 ? 'Transaccion' : this.props.tipo === 2 ? 'Transaccion Multiple': this.props.tipo === 3 ? 'Traspaso' : 'na'}</p>
                    </Col>

                    <Col xs={{span: 20, offset: 2}} lg={{span: 10, offset: 1}}>
                        <label className="lblTitulo">Monto</label>
                        <p className="pInfo">$ {this.props.monto} MXN</p>
                    </Col>
                </Row>

                <Row style={{ textAlign: "center" }}>
                    <Col span={24}>
                        <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.hideModal}>
                            Cerrar
                        </Button>
                    </Col>
                </Row>
            </Modal>
        )
    }
}
