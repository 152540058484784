import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Upload, Select, InputNumber, AutoComplete, Divider } from 'antd';


import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";

const axios = require('axios').default;


const { Title,Text  } = Typography;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class FormPrecio
 * @extends {Component}
 * @descricion Modal que acualiza unicamante el precio de un producto
 */
class FormPrecio extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

   formModalProductos = React.createRef()

    componentDidMount() {
        if(this.props.producto_opcion_id){
            this.get()
        }   
    }

    /**
     *
     *
     * @memberof FormPrecio
     * 
     * @method get
     * @description Obtenemos la informacion de producto (padre e hijo en uno)
     */
    get = (id = this.props.producto_opcion_id) => {
        this.setState({ loading: true })
        axios.get("/producto/get", {
            params: { id }
        })
            .then(async ({ data }) => {
                data = data.data

                this.formModalProductos.current.setFieldsValue({
                    ...data,
                })

            })
            .catch(res => {
                message.error('No se pudo cargar el Producto')
                console.log("no obtenida", res);
            }).finally(() => this.setState({ loading: false }))
    }


    /**
     *
     *
     * @memberof FormPrecio
     * 
     * @method save
     * @description Guardamos el producto
     */
    save = (values) => {
        this.setState({ loading: true })
        axios.put('/producto/update', { ...values, id: this.props.producto_opcion_id })
            .then(() => {
                message.success("¡Se ha guardado correctamente el costo!")
                this.props.onCancel();
            })
            .catch((e) => {
                message.error('No se pudo guardar el costo')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
            }))
    }



    render() {
        const { normFile, formModalProductos, } = this;
        const { form, loading, } = this.state;
        return (

            <Spin spinning={loading}>
                <Title level={3} className="text-center mb-1">Editar costo</Title>
                <Form 
                    layout="vertical" 
                    ref={formModalProductos} initialValues={form} onFinish={this.save} >           
                    <Row gutter={[16,0]} justify="center">

                        
                        <Col xs={22} lg={11}>
                            <Form.Item
                                label="Costo (con IVA)"
                                name="costo"
                                rules={[
                                    { required: true, message: "Ingrese el costo" }
                                ]}>
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder="Costo"
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul" loading={this.state.loading} >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            className="modal-form"
        >
            <FormPrecio {...props} />
        </Modal>
    )
}
