import React, { Component } from 'react';
import { Row, Col, Card, Button, Table, message } from 'antd'
//componentes
import { IconEye } from '../../Widgets/Iconos'
import Logged from '../../../Hooks/Logged';
//modales
import ModalReciboDetalle from '../Modales/Recibos/ModalReciboDetalle';
//css
import '../../../Styles/proyecciones.css'

import { FaTruckMonster } from 'react-icons/fa';

const moment = require('moment');
const axios = require('axios').default;


export default class Recibo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataRecibos: [],
            loading: true,
            modalVisible: false,
            montoTotal: 0,
            /**paginador */
            currentPage     : null,
            itemCount       : null,
            perPage         : null,
            pageCount       : null,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setState({
            loading: true,
        });
        this.getTransactionsofTheLoggedUser(1)
    }

    componentDidUpdate(){
        
    }


    /**
    * @memberof Recibo
    *
    * @method   modalDetalleRecibo
    * @description  Abre el modal y actualiza el state con los datos a visualizar
    *
    **/
    modalDetalleRecibo = (record) => {
        this.setState({
            modalVisible: true,
            v_concepto: record.concepto,
            v_fecha: record.fecha,
            v_tipo: record.tipo,
            v_monto: record.monto,

        })
    }

    /**
    * @memberof Recibo
    *
    * @method   hideModal
    * @description  Cierra el modal de visualizacion del recibo
    *
    **/
    hideModal = () => {
        this.setState({
            modalVisible: false,
        })
    }

    /**
    * @memberof Recibo
    *
    * @method   handlePageClick
    * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
    *
    **/
    handlePageClick = (page) => {
        this.getTransactionsofTheLoggedUser(page)
    }

    /**
    * @memberof Recibo
    *
    * @description Busca las transacciones cuyo concepto se el nombre y apellido del usuario qu este logeado 
    * @param page int
    */
    getTransactionsofTheLoggedUser = (page) =>{
        axios.get('/usuario/recibos', {
            params: {
                page    : page,
                limit   : 10,
            }
        })
        .then((response) => {
            const data = response.data;
            this.setState({
                dataRecibos: data.data.itemsList,
                montoTotal: data.montoTotal,
                currentPage: data.data.currentPage,
                itemCount: data.data.itemCount,
                pageSize: data.data.perPage,
                loading: false,
            })
        })
        .catch((response) => {
            this.setState({loading: false})
            message.error("Ocurrio un error al cargar los Recibos");
        })
    }


    render() {
        const columns = [
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
                render: (text, record) => (
                    <label className="lblTitulo">
                        {moment(record.fecha).format('DD-MM-YYYY')}
                    </label>
                ),

            },
            {
                title: 'Concepto',
                dataIndex: 'concepto',
                key: 'concepto',
                render: (text, record) => (
                    <label>
                        {record.concepto}
                    </label>
                ),
            },
            {
                title: 'Tipo',
                dataIndex: 'tipo',
                key: 'tipo',
                render: (text, record) => (
                    <label className="lblTitulo">
                        {record.tipo === 1 ? 'Transaccion' : record.tipo === 2 ? 'Transaccion Multiple': record.tipo === 3 ? 'Traspaso' : 'na' }
                    </label>
                )
            },
            {
                title: 'Monto',
                dataIndex: 'monto',
                key: 'monto',
                render: (text, record) => (
                    <label className="lblTitulo">
                        $ {record.monto} MXN
                    </label>
                )
            },

            {
                title: 'Acciones',
                key: 'actions',
                width: 120,
                fixed: 'right',
                render: (text, record) => (

                    <div className="tbl-opciones">
                        <Button
                            type="primary"
                            className="button-survey btn-eye button-eye"
                            title="Ver detalle"
                            onClick={()=>this.modalDetalleRecibo(record)}>
                            <IconEye />
                        </Button>
                    </div>
                ),
            },
        ];

        let usuario = this.context;

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={6} xxl={8}>
                        <h2 style={{ width: 'auto', float: 'left' }}> Mis Recibos</h2>
                    </Col>
                </Row>

                <section className="p-1">
                    <Row>
                        <Col span={24}>
                            <Card
                                title=""
                                loading={this.state.loading}
                                bordered
                                className="card-proyeccionesDetalle"
                            >
                                <Row>
                                    <h1>Información Personal </h1>
                                </Row>

                                <Row>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Nombre</label>
                                        <p className="pInfo">{usuario.nombre}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Apellido</label>
                                        <p className="pInfo">{usuario.apellido}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Email</label>
                                        <p className="pInfo">{usuario.email}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Teléfono</label>
                                        <p className="pInfo">{usuario.telefono}</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} lg={11} >
                                        <label className="lblTitulo">Monto Total Recibido</label>
                                        <p className="pInfo">$ {parseFloat(Math.round(this.state.montoTotal * 100)/100).toFixed(2)} MXN</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Card
                                title=""
                                loading={this.state.loading}
                                bordered
                                className="card-proyeccionesDetalle"
                            >
                                <Row>
                                    <h1>Pagos Recibidos ({this.state.itemCount})</h1>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        <div className="div_contenido div-contenedor">
                                            <Table
                                                columns={columns}
                                                dataSource={this.state.dataRecibos}
                                                className="blankTheme"
                                                pagination={{
                                                    current: this.state.currentPage,
                                                    total: this.state.itemCount,
                                                    pageSize: this.state.pageSize,
                                                    onChange: this.handlePageClick,
                                                }}
                                                // scroll={{ y: 170 }}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </Card>
                        </Col>
                    </Row>
                </section>


                <ModalReciboDetalle
                    modalVisible={this.state.modalVisible}
                    closeMethod={this.hideModal}
                    concepto={this.state.v_concepto}
                    fecha={this.state.v_fecha}
                    tipo={this.state.v_tipo}
                    monto={this.state.v_monto}
                >
                </ModalReciboDetalle>
            </div>
        )
    }
}

Recibo.contextType = Logged;
