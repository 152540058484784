import React, { Component } from "react";
import { Row, Col, DatePicker, Input, Switch, Button, Modal, Form, Select, Typography, message, Spin, InputNumber, Upload, Avatar,
      Avatar as ImageAvatar,   Steps} from 'antd';
import {PlusOutlined, UploadOutlined, LeftOutlined, LoadingOutlined, RightOutlined} from '@ant-design/icons';


const axios = require("axios").default;

class MyUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        fileList: [],
      };
    }

    render() {
      const props = {
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange: this.handleChange,
        multiple: true,
      };
      return (
        <Upload {...props} fileList={this.props.fileList}>
          <Button>
            <UploadOutlined /> Upload
          </Button>
        </Upload>
      );
    }
}

class UploadLogo extends React.Component {
    constructor(props) {
        super(props);
        const pid = this.props.nuevoProyecto;

        this.state = {


            fileList: [],
            loading: false,
            nuevoProyecto: pid,
            guardar:true,
            spinning:false,
            valores_form: {},

        }
    }


    // handleSubmit = (values) => {
    //     console.log('cambio color');

    //     if (this.state.guardar) {
    //         this.setState({valores_form: values});
    //         document.getElementById('btn-negocioNext').click();
    //     }
    // };

    // handleChange = info => {
    //     console.log(info)

    //     try {
    //         this.setState({loading: true});

    //         if (info.file.status === 'uploading') {
    //             this.setState({loading: true,
    //                 guardar: true,
    //                 spinning:true
    //             });
    //             return;
    //         }


    //         if (info.file.status === 'done') {
    //             this.setState({
    //                 image: info.file.response.filename,
    //                 loading: false,
    //                 spinning:false

    //             });
    //         }
    //     } catch (error) {

    //     }
    // };

    handleChange = info => {
        if (info.file.status === 'uploading') {
            info.fileList = []
          this.setState({ loading: true,  fileList: [] });
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl =>
            this.setState({
              imageUrl,
              loading: false,
            }),
          );
        }
      };


    render() {
        // const uploadButton = (
        //     <div>
        //         {this.state.loading ? <LoadingOutlined/> : <PlusOutlined/>}
        //         <div className="ant-upload-text">Upload</div>
        //     </div>
        // );
        // const {imageUrl} = this.state;
        // const {image} = this.state;

        const uploadButton = (
            <div>
              {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Upload</div>
            </div>
          );
          const { imageUrl } = this.state;

        return (
            //  <Upload
            //      name="file"
            //      listType="picture-card"
            //      className="avatar-uploader center w100"
            //      showUploadList={false}
            //      action={axios.defaults.baseURL + 'upload/add'}
            //      beforeUpload={beforeUpload}
            //      onChange={this.handleChange}

            //      TextArea="Seleccionar"
            //  >
            //      {image ?
            //          <img src={axios.defaults.baseURL + 'upload/' + image} alt="avatar"
            //              style={{width: '100%'}}/> :
            //          <div>
            //              {this.state.loading ? <PlusOutlined/> : <PlusOutlined/>}
            //              <div className="ant-upload-text">Seleccionar</div>
            //          </div>
            //      }
            //  </Upload>


        <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
        >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
        )
    }
}



function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }


export {MyUpload, UploadLogo} ;
