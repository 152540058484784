import React, { Component } from 'react';
import { Button, Col, Row, Select, Typography, Tag, Progress } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
//componentes
import { IconPlus } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import GraficaCompras from './GraficaCompras';
//css
import './../../../Styles/dashboard.css'

const { Title, Text } = Typography;
const moment = require('moment')


let colors = {
    0: '#B706F5', //Requerido
    1: '#EDF10A', //Cotizado
    2: '#F57906', //Pausado
    3: '#F50606', //Cancelado
    4: '#6106F5', //Entrega
    5: '#F506B2', //Recoleccion
    6: '#08E453', //Entregado
}

let estatus = {
    0: 'Requerido',
    1: 'Cotizado',
    2: 'Pausado',
    3: 'Cancelado',
    4: 'Entrega',
    5: 'Recoleccion',
    6: 'Entregado',
}

/**
 * @class ComprasDashboard
 * @description Dashboard principal del modulo de  compras y requisiciones
 */
export default class ComprasDashboard extends Component {

    constructor(props){
        super(props)
        this.state = {
            requisiciones: [],
            proyectos: [],
            entregas: [],
            inventario: [{},{},{},{},{},{},{},{},{},{}],
            proveedores: [{},{},{},{},{},{},{},{},{},{}],

            //PROVISIONAL
            //Encargado / Maerstro - 1
            //comprador - 2
            tipo_usuario: 2 
        }
    }

    /***
     *
     * @methodOf ComprasDashboard
     * @function componentDidMount
     * @description Obtenemos la informacion 
     */
    componentDidMount = () => {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        this.getData()

    }

    /***
     *
     * @methodOf ComprasDashboard
     * @function getData
     * @description Obtenemos la informacion dels dashbaord
     */
    getData = () => {
        this.setState({loading: true})
        axios.get('/requisiciones/dashboard',{
            params:{}
        }).then(response => {
            console.log("response", response.data.data);
            this.setState({
                requisiciones: response.data.data.requisiciones,
                proyectos: response.data.data.requisiciones_proyectos,
                entregas: response.data.data.entregas
            })

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        const { onDatesChange, onProjectsChange } = this;
        const { start, end, projectsSelected, area, rubro } = this.state;
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={7} xxl={10} className="flex-left">
                        <Title level={4} className="view-title">Inventario</Title>
                    </Col>
                </Row>

                <section className="p-1">
                    
                    <Row gutter={[16,16]}>
                        <Col xs={24} md={12} lg={16}>
                            <div className="card" style={{height: '500px'}}>
                                <div className="div-titulo">
                                    <h2 className="titulo-divCont">Compras Categorizadas por Proyecto</h2>
                                </div>
                                <GraficaCompras proyectos={this.state.proyectos}/>
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <div className="card" style={{height: '500px'}}>
                                <div className="div-titulo">
                                    <h2 className="titulo-divCont">Requisición de Materiales</h2>
                                    <Link to={'/admin/compras/requisiciones'}>
                                        <Button className="btn-go"> <FaArrowRight /> </Button>
                                    </Link>
                                </div>
                                <Row className="scroll-body">
                                    {this.state.requisiciones.map(item => <Col span={24} className="border-bottom">
                                        <Row>
                                            <Col span={5} className="center">
                                                <CustomAvatar 
                                                    size="large" 
                                                    image={item.proyecto_id?.logo}
                                                    name={item.proyecto_id?.nombre}
                                                    color={item.proyecto_id?.color}/>
                                            </Col>
                                            <Col span={19} className="flex-left-column">
                                                <Text className="col-title">{item.proyecto_id?.nombre}</Text>
                                                <Text className="text-blue">SKUs: [#]</Text>
                                                <Text strong>Ultima Adición: {moment(item.updatedAt).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                        </Row>
                                    </Col>)}
                                </Row>
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <div className="card" style={{height: '400px'}}>
                                <div className="div-titulo">
                                    <h2 className="titulo-divCont">Entregas y Recolección</h2>
                                    
                                        <Button className="btn-go"> <FaArrowRight /> </Button>
                                    
                                    
                                </div>
                                <Row className="scroll-body">
                                    {this.state.entregas.map(e => <Col span={24} className="border-bottom">
                                        <Row>
                                            <Col span={8} className="flex-left-column">
                                                <Text strong>[OC]</Text>
                                                <Text className="text-gray">{e.requisicion_opcion_id?.proveedor_id?.alias}</Text>
                                            </Col>
                                            <Col span={8} className="center">
                                                <Tag className="status-tag" style={{color: 'white'}} color={colors[e.estatus]}>{estatus[e.estatus]}</Tag>
                                            </Col>
                                            <Col span={8} className="center">
                                                <Text strong>{moment(e.fecha_entrega).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                        </Row>
                                    </Col>)}
                                </Row>
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <div className="card" style={{height: '400px'}}>


                                {/**si es  usuario tipo Encargado*/}
                                {this.state.tipo_usuario === 1 ? <><div className="div-titulo">
                                    <h2 className="titulo-divCont">Inventario</h2>
                                    <Button className="btn-go"> <FaArrowRight /> </Button>
                                </div>
                                <Row className="scroll-body">
                                    <Col span={6} className="center mb-1">
                                        <Text className="text-blue">Producto</Text>
                                    </Col>
                                    <Col span={6} className="center mb-1">
                                        <Text className="text-blue">SKU</Text>
                                    </Col>
                                    <Col span={6} className="center mb-1">
                                        <Text className="text-blue">Almacen</Text>
                                    </Col>
                                    <Col span={6} className="center mb-1">
                                        <Text className="text-blue">Existencias</Text>
                                    </Col>
                                </Row>
                                <Row className="scroll-body" style={{maxHeight: '75%'}}>
                                    {this.state.inventario.map(e => <Col span={24} className="mb-1">
                                        <Row>
                                            <Col span={6} className="center">
                                                <Text>[producto]</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text>[SKU]</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text>[Almacen]</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text>[#]</Text>
                                            </Col>
                                        </Row>
                                    </Col>)}
                                </Row>

                                {/**si es  usuario tipo Comprador*/}
                                </> : <>

                                <div className="div-titulo">
                                    <h2 className="titulo-divCont">Lineas de Credito</h2>
                                    <Link to={'/admin/compras/calendario'}>
                                        <Button className="btn-go"> <FaArrowRight /> </Button>
                                    </Link>
                                </div>
                                <Row className="scroll-body mt-1" style={{maxHeight: '75%'}}>
                                    {this.state.proveedores.map(e => <Col span={24} className="mb-1">
                                        <Row>
                                            <Col span={9} className="center">
                                                <Text>[Proveedor]</Text>
                                            </Col>
                                            <Col span={15} className="pr-1">
                                                <Progress percent={30} />
                                            </Col>
                                        </Row>
                                    </Col>)}
                                </Row>


                                </>}
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <div className="card" style={{height: '400px'}}>
                                {this.state.tipo_usuario === 1 ? <>
                                    <div className="div-titulo">
                                        <h2 className="titulo-divCont">Rendimientos</h2>
                                        <Button className="btn-go"> <FaArrowRight /> </Button>
                                    </div>
                                
                                </> : <>
                                    <div className="div-titulo">
                                        <h2 className="titulo-divCont">Precios por Producto</h2>
                                        <Link to={'/admin/compras/cotizaciones'}>
                                            <Button className="btn-go"> <FaArrowRight /> </Button>
                                        </Link>
                                    </div>
                                    <Row className="scroll-body">
                                        <Col span={6} className="center mb-1">
                                            <Text className="text-blue">Producto</Text>
                                        </Col>
                                        <Col span={6} className="center mb-1">
                                            <Text className="text-blue">SKU</Text>
                                        </Col>
                                        <Col span={6} className="center mb-1">
                                            <Text className="text-blue">Almacen</Text>
                                        </Col>
                                        <Col span={6} className="center mb-1">
                                            <Text className="text-blue">Existencias</Text>
                                        </Col>
                                    </Row>
                                    <Row className="scroll-body" style={{maxHeight: '75%'}}>
                                        {this.state.inventario.map(e => <Col span={24} className="mb-1">
                                            <Row>
                                                <Col span={6} className="center">
                                                    <Text>[producto]</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text>[SKU]</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text>[Almacen]</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text>[#]</Text>
                                                </Col>
                                            </Row>
                                        </Col>)}
                                    </Row>

                                </>}
                            </div>
                        </Col>
                    </Row>

                </section>
                <Button className="btnAddPlus" title="Nuevo registro" >
                    <IconPlus />
                </Button>
            </div>
        )
    }
}
