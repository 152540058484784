import React, { useState, useEffect } from 'react';
import { Select, message, Typography } from 'antd';

const { Text } = Typography;
const { Option } = Select ;
const axios = require("axios");

/**
 * @const SubRubroSelect
 * @description Select para los subrubros del rubro que se seleccione, solo buscara los subrubros del rubro definida
 * 
 * @param rubro_id ID id del area del cual se buscaran sus subrubros
 */
const SubRubroSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el Subrubro", onSelect = () => {}, rubro_id, disabled = false, className = "form-select"} = props

    const [ subrubros, setSubrubros ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
        rubro_id: null
    })


    /**
     * @const getRubros
     * @description Obitiene los subrubros del rubro seleccionado
     */
    const getRubros = ({
        page = subrubros.page, 
        limit = subrubros.limit, 
        search = subrubros.search, 
        rubro_id = subrubros.rubro_id
    } = subrubros) => {

        axios.get('/subrubros/list', {
            params: { 
                page, 
                limit, 
                search,
                rubro_id,
                paginate: false,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setSubrubros({
                ...subrubros,
                rubro_id: rubro_id,
                data: response.data.data,
            })

        }).catch(error => {
            message('Error al obtener los subrubros')
        })
    }
    
    //componentDidUpdate
    useEffect(() => {
        if(rubro_id)
            getRubros({rubro_id})
        if(rubro_id == null)
            setSubrubros({
                ...subrubros,
                data: [],
            })
    }, [rubro_id])


    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getRubros({search})}
            onSelect={(subrubro)=> {
                onChange(subrubro.value)
                onSelect(subrubro.value)
            }}
            value={value}
        >
            {
                subrubros.data.map(subrubro => <Option value={subrubro._id} key={subrubro._id}>{subrubro.nombre}</Option>)
            }
        </Select>
    );
}



export default SubRubroSelect;