import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, message, Spin, Tag, Card, Pagination, List, Dropdown, Menu, Checkbox, Input, DatePicker } from 'antd'
//componentes
import { IconEye, IconMoney, IconCross, IconArrowCustom as Arrow  } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import ModalFactura from '../Modales/Proveedores/ModalFactura'
import ModalDetalle from '../Modales/Proveedores/ModalDetalle'


const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
const { Title, Text, } = Typography;
const moment = require('moment')
const { Search } = Input;
const { RangePicker } = DatePicker;

export default class Facturas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            facturas: [],
            modalDetalleVisible: false,
            modalFacturaVisible: false,
            factura: undefined,
            proyectos_select: [],
            proyectos: [],
            proveedores: [],
            proveedores_select: []
        }
    }


    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getFacturas(1)
        this.getProyectos(1)
        this.getProveedores()
    }

    /**
     * @description Cerrar modal
    */
    closeMethod = () => {
        this.setState({modalDetalleVisible: false, modalFacturaVisible: false, factura: undefined})
        this.getFacturas(1)
    }

    /**
    *
    * @memberof Facturas
    * @method getProyectos
    * @description obtiene la lista de todos los proyectos
    */
    getProyectos = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
            }
        }).then((proyectos) => {
            this.setState({proyectos: proyectos.data.data})
        }).catch((error) => {
            
        })
    }

    /**
    *
    * @memberof Facturas
    * @method getProveedores
    * @description obtiene la lista de todos los proveedores
    */
    getProveedores = () => {
        axios.get('/proveedores/list', {
            params: {
                search: this.state.search,
            }
        }).then((response) => {
            console.log(response)
            let proveedores =  [] 

            response.data.data.itemsList.forEach(pro => {
                let index = this.state.proveedores_select.findIndex(p => p._id === pro._id)
                if(index === -1) proveedores.push(pro);
               
            })
            console.log(proveedores)
            this.setState({proveedores: proveedores})
        }).catch((error) => {
            
        })
    }
    

     /**
    *
    * @memberof Facturas
    * @method onSearchProveedor
    * @description obtiene la lista de todos los proveedores
    */
    onSearchProveedor = (search) => {
        console.log(search)
    }

    /**
    *
    * @memberof Facturas
    * @method getFacturas
    * @description obtiene la lista de todas las facturas
    */
    getFacturas = (page) => {
        this.setState({loading: true})
        axios.get('/facturas/list',{
            params:{
                page: page,
                estatus: 2,
                proyectos: this.state.proyectos_select,
                proveedores: this.state.proveedores_select.map(pro=>pro._id),
                dates: this.state.dates,
            }
        })
        .then(response=>{
            this.setState({
                facturas: response.data.data.itemsList,
                currentPage: response.data.data.currentPage,
                itemCount: response.data.data.itemCount,
            })
        }).catch(error=>{
            console.log(error)
            message.error('Error al cargar las facturas')
        }).finally(()=>{this.setState({loading: false})})
    }

    /**
     *
     *
     * @memberof Facturas
     * 
     * @method renderTags
     * @description renderiza tags dependiendo del estatus o el proyecto
     */
    renderTags = (tipo, item) => {
       if(tipo === 'estatus'){

            let tags = {
                '1': <Tag className="tag-proyecto" color="#226dff">Vigente</Tag>,
                '2': <Tag className="tag-proyecto" color="#e1c634">Por Vencer</Tag>,
                '3': <Tag className="tag-proyecto" color="#fa1e1e">Vencida</Tag>,
                '4': <Tag className="tag-proyecto" color="#3e8c12">Liquidada</Tag>,
                '5': <Tag className="tag-proyecto" color="purple">Factura</Tag> 
            }

            return tags[item]
        }else{
            return <Tag className="tag-proyecto" color={item.color}>{item.nombre}</Tag>
        }
    }

    /**
     *
     *
     * @memberof Facturas
     * 
     * @method rechazarCotizacion
     * @description Cambia el estatus de una factura a rechazada
     */
    rechazarCotizacion = (id) => {
        axios.post('/facturas/rechazar',{
            factura_id: id,
        })
        .then(response=>{
            message.success('Factura rechazada')
            this.getFacturas(1)
        }).catch(error=>{
            console.log(error)
        })
    }

    /**
     *
     *
     * @memberof Facturas
     * 
     * @method onChangeProyecto
     * @description cambia al estatus para filtrar por los proyectos selecionados
     */
    onChangeProyecto = (proyecto_id ) => {
        const { proyectos_select } = this.state

        let index = proyectos_select.indexOf(proyecto_id)
        if(index !== -1) proyectos_select.splice(index,1)
        else proyectos_select.push(proyecto_id)

        this.setState({proyectos_select},()=>{this.getFacturas(1)})
    }

    /**
     *
     *
     * @memberof Facturas
     * 
     * @method onSelectProveedor
     * @description Cambia el estatus de una factura a rechazada
     */
    onSelectProveedor = (proveedor_id,checked) => {

        const { proveedores_select, proveedores } = this.state;

        let index = proveedores_select.findIndex(p => p._id === proveedor_id)
        let index_main = proveedores.findIndex( p => p._id === proveedor_id)

        if(checked && index === -1 && index_main !== -1){
            proveedores_select.push(proveedores[index_main])
            proveedores.splice(index_main,1);
        }if(!checked && index !== -1 && index_main === -1){
            proveedores.push(proveedores_select[index])
            proveedores_select.splice(index, 1)
        }
        
        this.setState({proveedores_select, proveedores},()=>{this.getFacturas(1)})
    }

     /**
     *
     *
     * @memberof Facturas
     * 
     * @method onSearchProveedor
     * @description Cambia el state para filtrar los proveedores por la busqueda de alias
     */
    onSearchProveedor = (search) => {
        this.setState({search: search},()=>{this.getProveedores()})
    }

    /**
     *
     *
     * @memberof Facturas
     * 
     * @method onChangeDates
     * @description filtra por fecha de vencimiento
     */
    onChangeDates = (dates, datesString) => {
        console.log(datesString)
        this.setState({dates: datesString},()=>{this.getFacturas(1)})
    }








    render() {
        

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={6} xxl={8}>
                        <h2 style={{ width: 'auto', float: 'left' }}> Facturas </h2>
                    </Col>
                    <Col span={4} className='center'>
                        <Dropdown
                            trigger={['click']}
                            className="filter-element"
                            overlay={
                                <Menu>
                                    {this.state.proyectos?.map(({ _id, nombre, logo, color }, index) => (
                                        <Menu.Item key={index}>
                                            <Checkbox
                                                onChange={(e) => {
                                                    this.onChangeProyecto(_id, e.target.checked,)
                                                }}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        margin: '0 5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Checkbox>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }>
                            <p>Proyectos <Arrow className="arrow" /></p>
                        </Dropdown>
                        </Col>
                         <Col span={4} className='center'>
                        <Dropdown
                            trigger={['click']}
                            className="filter-element"
                            overlay={<>
                                <Menu>
                                    <Row>
                                        <Col span={24} style={{padding: '5px 16px'}}>
                                            <Search
                                              placeholder="Buscar"
                                              size="small"
                                              onSearch={this.onSearchProveedor}
                                            />
                                        </Col>
                                    </Row>
                                    {this.state.proveedores_select?.map(({ _id, alias}, index) => (
                                        <Menu.Item key={_id}>
                                            <Checkbox
                                                checked
                                                value={_id}
                                                onChange={(e) => {
                                                    this.onSelectProveedor(_id, e.target.checked,)
                                                }}>
                                                {alias}
                                            </Checkbox>
                                        </Menu.Item>
                                    ))}
                                    {this.state.proveedores?.map(({ _id, alias}, index) => (
                                        <Menu.Item key={_id}>
                                            <Checkbox
                                                value={_id}
                                                checked={false}
                                                onChange={(e) => {
                                                    this.onSelectProveedor(_id, e.target.checked,)
                                                }}>
                                                {alias}
                                            </Checkbox>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            </>}>
                            <p>Proveedores <Arrow className="arrow" /></p>
                        </Dropdown>
                    </Col>
                    <Col span={5} className="center">
                        <RangePicker
                            onChange={this.onChangeDates}
                            className="input-picker"
                            style={{ background: 'transparent !important', color: '#FFF' }}
                        />
                    </Col>
                </Row>
                <div className="p-1">
                    <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                        <Col span={3} className="center">
                            <Text type="secondary">Fecha Vigencia</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">Estatus</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Concepto</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Proyecto</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Proveedor</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">Monto</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">Acciones</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <List
                            className="component-list"
                            itemLayout="horizontal"
                            loading={this.state.loading}
                            dataSource={this.state.facturas}
                            locale={{ emptyText: 'Sin Facturas' }}
                            renderItem={item => (
                                <List.Item className="component-list-item lista-materiales">
                                    <Card className="card-list">
                                        <Row style={{ width: '100%' }} className="">
                                            <Col span={3} className="center">
                                                <Text strong>{moment(item.fecha_vigencia).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{this.renderTags('estatus',item.info_estatus)}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item.concepto}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{this.renderTags('pro',item.proyecto_id)}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item.proveedor.alias}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text strong>$ {item.monto.toMoney()} MXN</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                
                                                <Button type="primary" className="button-survey btn-eye button-eye"
                                                    onClick={(e) => {this.setState({factura: item, modalDetalleVisible:true})}}
                                                    title="Ver detalle">
                                                    <IconEye />
                                                </Button>
                                                
                                                <Button className="btn btn-money" title="Pagar" 
                                                    disabled={item.estatus === 3 ? true : false}
                                                    onClick={(e) => {this.setState({factura: item, modalFacturaVisible:true})}}
                                                >
                                                    <IconMoney />
                                                </Button>
                                                {item.estatus !== 3 ? <Popconfirm
                                                    title={"¿Deseas eliminar la factura ?"}
                                                    placement="topRight"
                                                    onConfirm={e => {
                                                    }}
                                                    onCancel={e => e.stopPropagation()}
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={e => this.rechazarCotizacion(item._id)}
                                                >

                                                    <Button className="btn btn-delete"
                                                        onClick={e => e.stopPropagation()}>
                                                        <IconCross />
                                                    </Button>
                                                </Popconfirm> : <Button className="btn btn-delete" disabled={true}> <IconCross />
                                                    </Button> }
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="mt-2 mb-3">
                            <Pagination 
                                current={this.state.currentPage}
                                total={this.state.itemCount}
                                onChange={(page)=>{
                                    this.getFacturas(page)
                                }} 
                            />
                        </Col>
                    </Row>
                </div>
                <ModalDetalle
                    visible={this.state.modalDetalleVisible}
                    closeMethod={this.closeMethod}
                    factura={this.state.factura}
                />
                <ModalFactura
                    visible={this.state.modalFacturaVisible}
                    closeMethod={this.closeMethod}
                    factura={this.state.factura}
                   // proveedor={this.state.proveedor}
                />
            </div>
        )
    }
}
