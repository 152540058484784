import React, { Component } from 'react';
import { Row, Col, Typography, Table, Button, Popconfirm, message, Pagination, List, Card, Tag } from 'antd'
import { Link } from 'react-router-dom';

//componentes

import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
// import { IconEdit, IconPackage, IconUbicacion, IconEye, IconPlus, IconArrowBack, IconCloseModal, IconPackagePlus } from '../../../Widgets/Iconos'
import { IconPackage, IconUbicacion, IconEye, IconPlus, IconArrowBack, IconCloseModal, IconPackagePlus, IconEdit, IconDelete,  } from '../../../Widgets/Iconos';
import Logged from "../../../../Hooks/Logged";


import ModalMovimientos from "../Productos/FormMovimientos";

import {
    FloatingMenu,
    MainButton,
    ChildButton,
    Directions
} from 'react-floating-button-menu';

const moment = require('moment');
const { Title, Text } = Typography;
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

export default class Locaciones extends Component {
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            dataAreas: [],
            area: {},
            loading: false,

            accionModal: 'crear',
            registroId: '',
            modalVisible: false,
            tipo_user: 0,

            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            filtroSearch: ""

        }
    }

    ModalLocacionesRef = React.createRef();

    /**
     * @methodOf  Locaciones
     * @function volver
     *
     * @description Regresa a la vista anterior
     *
     * */
    volver = () => {
        window.history.back();
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Obtenemos el listado de Areas
        this.getArea(this.props.match.params.area_id);
        this.getMovimientos();
    }


    /**
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }



    /**
     * @description Cerrar modal y limpiar valor registroId
     */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false,
        })
    }

    /**
    * @memberof Locaciones
    * @method   getArea
    * @description  Trae info de Area
    **/
    getArea = (area_id) => {
        axios.post('/locaciones/get', {
            id: area_id
        }).then(response => {
            console.log(response.data.data)
            this.setState({ 
                area: response.data.data})
        }).catch(error => {
            console.log(error)
            message.error('Error al traer el Area')
        })
    }

    /**
    * @memberof Locaciones
    * @method   getMovimientos
    * @description  Trae info de Area
    **/
    getMovimientos = (page = 1) => {
        axios.get('/movimientos/list', {
            params:{
                id: this.props.match.params.area_id,
                area: true,
                page: page,
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({
                dataAreas: response.data.data.data,
                currentPage: response.data.data.page,
                itemCount: response.data.data.total
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los movimientos')
        })
    }

    renderTipoMovimiento = (tipo) => {
        let movimientos = {
            0: 'Compra',
            1: 'Venta',
            2: 'Reubicación'
        }

        let color = {
            0: '#0047ff',
            1: '#00cd98',
            2: '#ffd954'
        }

        return <Tag className="tag-proyecto" color={color[tipo]}>{movimientos[tipo]}</Tag>
    }

    render() {
        const user = this.context;


        return (
            <div>
                <Row className="row-encabezado">

                    <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <Title level={4} className="view-title">Movimientos {this.state.area?.nombre}</Title>
                    </Col>
                </Row>

                <section className="p-1">
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.dataAreas}
                        header={<Row className="header-list" style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>

                            <Col span={1} className="gutter-row center">
                                <Text type="secondary">#</Text>
                            </Col>
                            <Col span={5} className="gutter-row center">
                                <Text type="secondary">PRODUCTO</Text>
                            </Col>
                            <Col span={3} className="gutter-row center">
                                <Text type="secondary">CANTIDAD</Text>
                            </Col>
                            <Col span={5} className="gutter-row center">
                                <Text type="secondary">DESTINO</Text>
                            </Col>
                            <Col span={5} className="gutter-row center">
                                <Text type="secondary">TIPO</Text>
                            </Col>
                            <Col span={3} className="gutter-row center">
                                <Text type="secondary">FECHA</Text>
                            </Col>
                            <Col xs={2} className="gutter-row center">
                                <Text type="secondary">ACCIONES</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={(item, index) => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">

                                        <Col span={1} className="center">
                                            <Text strong>{index + 1 * this.state.currentPage}</Text>
                                        </Col>
                                        <Col xs={5} className="center">
                                            <Text strong>{item.articulo_id.nombre}</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text strong>{item.cantidad}</Text>
                                        </Col>
                                        <Col xs={5} className="center">
                                            <Text strong>{item.area_destino_id ?  item.area_destino_id.nombre : 'N/A' }</Text>
                                        </Col>
                                        <Col xs={5} className="center">
                                            <Text strong>{this.renderTipoMovimiento(item.tipo_movimiento)}</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text strong>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
                                        </Col>
                                        <Col xs={2} className="center">
                                            <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle"
                                                onClick={()=>{this.setState({movimiento_id: item._id, modalVisible: true })}}
                                            >
                                                <IconEye />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>

                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.currentPage}
                            total={this.state.itemCount}
                            onChange={(page) => {
                                this.getMovimientos(page)
                            }}
                        />
                    </Row>

                </section>

                <FloatingMenu

                    slideSpeed={500}
                    direction={Directions.Left}
                    spacing={8}
                    isOpen={this.state.isOpen}
                    style={{
                        position: 'fixed',
                        bottom: '30px',
                        right: '10px'
                    }}
                >
                    <MainButton
                        // PlusOutlined, MinusOutlined
                        iconResting={<PlusOutlined style={{ fontSize: 20 }} style={{ color: 'white', fontSize: 45 }} />}
                        iconActive={<CloseOutlined style={{ fontSize: 20 }} style={{ color: 'white', fontSize: 45 }} />}
                        background="linear-gradient(126.63deg, #C274FF 11.98%, #7000FF 83.35%)"
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                        size={80}
                    />

                    {/* 0 -> Compra
    1 -> Venta 
    2 -> Reubicación */}
                    <ChildButton
                        icon={<IconPackage style={{ fontSize: 15 }} />}
                        background="linear-gradient(126.63deg, #C274FF 11.98%, #7000FF 83.35%)"
                        size={42}
                        // this.modalToogle(1, 2)
                        onClick={() => this.setState({ modalVisible: true, tipo_movimiento: 1 })}

                    />
                    <ChildButton
                        icon={<IconPackagePlus style={{ fontSize: 15 }} />}
                        background="linear-gradient(126.63deg, #C274FF 11.98%, #7000FF 83.35%)"
                        size={42}
                        onClick={() => this.setState({ modalVisible: true, tipo_movimiento: 0 })}
                    />
                    <ChildButton
                        icon={<IconUbicacion style={{ fontSize: 15 }} />}
                        background="linear-gradient(126.63deg, #C274FF 11.98%, #7000FF 83.35%)"
                        size={42}
                        onClick={() => this.setState({ modalVisible: true, tipo_movimiento: 2 })}
                    />

                </FloatingMenu>


                <ModalMovimientos
                    area_id={this.props.match.params.area_id}
                    tipo={this.state.tipo_movimiento}
                    visible={this.state.modalVisible}
                    movimiento_id={this.state.movimiento_id}
                    onCancel={() => this.setState({ modalVisible: false, movimiento_id: undefined })}
                    onSuccess={() => {
                        this.setState({ modalVisible: false, movimiento_id: undefined })
                        this.getMovimientos()
                        // this.getExistencias()
                        // this.getProducto()
                    }}

                />

            </div>
        )
    }
}
