import React, { Component } from 'react';
import { Row, Col, Table, Button,Popconfirm,message, Typography } from 'antd'
import { Link } from 'react-router-dom';
import { IconPlus, IconEdit, IconDelete } from '../../Widgets/Iconos';
import ModalSubrubros from '../Modales/Rubros/ModalSubrubros';
import { Redirect } from "react-router-dom";
import Logged from "../../../Hooks/Logged";
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

const { Title } = Typography;

export default class Subrubros extends Component {
    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {
            dataSubrubros: [],
            loading: false,
            rubroNombre: 'Rubro x',
            rubroId: '',
            accionModal: 'crear',
            registroId: '',
            modalVisible: false,

            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,
        }
    }

    refModalSubrubro = React.createRef();


    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        const pid = this.props.match.params.id;
        //Obtiene la informacion del rubro del ID de la URL
        this.getRubroInfo()
        this.receivedData();
    }


    /**
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }

    /**
   * @description Abrir modal tipo edición para registro seleccionado desde tabla.
   * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
   */
    modalEditar = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;

        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisible: true,
        })
    }

    /**
 * @description Cerrar modal y limpiar valor registroId
*/
    hideModal = () => {
        //this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false,
        })
    }

    /**
     *
     *
     * @memberof Subrubros
     * @method getRubroInfo
     * @description Obtiene la informacion del rubro con el ID del parametro de la URL
     */
    getRubroInfo = () => {

        axios.post('/rubros/get', {
            id: this.props.match.params.id,
        }).then((success) => {

            this.setState({
                rubroNombre: success.data.data.nombre,
            })
        })

    }


    /**
        *
        * @memberof SubRubros
        * @method receivedData
        * @description Obtiene la informacion y actualiza los valores locales de la lista.
        */
    receivedData = (page,size) => {
        this.setState({loading: true})
        var numPage = (page === undefined) ? 1 : page, numReg = 0;

        axios.get('/subrubros/list', {

            params: {
                page: page,
                limit: size,
                rubro_id: this.props.match.params.id,
            }
        })
            .then(res => {
                const data = res.data.data.itemsList

                var dataL = data, data_list = [];

                if (numPage === null || numPage === 1) { numReg = 0}
                else { numReg = ((numPage - 1) * 10) }

                for (let index = 0; index < dataL.length; index++) {
                    const element = dataL[index];
                    numReg = numReg + 1;
    
                    data_list.push({
                        _id: element._id,
                        nombre: element.nombre,
                        index: numReg
                    });
                }


                this.setState({
                    dataSubrubros: data_list,
                    loading: false,
                    currentPage: res.data.data.paginator.currentPage,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: res.data.data.paginator.perPage,
                    pageCount: res.data.data.paginator.pageCount,
                    slNo: res.data.data.paginator.slNo,
                    hasPrevPage: res.data.data.paginator.hasNextPage,
                    hasNextPage: res.data.data.paginator.hasNextPage,
                    prev: res.data.data.paginator.prev,
                    next: res.data.data.paginator.next
                })
            })
            .catch(res => {
                console.log("error", res);
            });
    }

  /**
   *
   *
   * @memberof Subrubros
   * @method
   */
  eliminarSubrubro = e =>{
        axios.post('/subrubros/delete',{
            id:e
        })
        .then((response)=>{
            message.success("Eliminado correctamente.");
            this.receivedData();
        })
        .catch((error)=>{
            message.error(error.response.data.message)

        })
    }

    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to={`/admin/areas/rubros/subrubros/${this.props.match.params.id}`} />;
        }
    };

    render() {
        const user = this.context;
        const columns = [
            {
                title: 'Index',
                key: 'index',
                dataIndex: 'index',
                // render: (text, record) => (
                //     <Avatar/>
                // ),

            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                //key: 'nombre',
                render: (text, record) => (
                    <h3>{record.nombre}</h3>
                ),
            },

            {
                title: 'Acciones',
                key: 'actions',
                width: 150,
                render: (text, record) => (
                    <div className="tbl-opciones">
                        {(user.tipo !==3)?
                         <div><Button type="primary" className="button-survey btn-edit button-edit" name={record.nombre} id={record._id} onClick={this.modalEditar} title="Editar"  >
                         <IconEdit />
                     </Button>
                     <Popconfirm placement="topRight" title="Eliminar este subrubro?" okText="Si, seguro" cancelText="No" name={record.nombre} id={record._id} onConfirm={()=>{ this.eliminarSubrubro(record._id)}} >
                      <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" title="Eliminar"  >
                         <IconDelete />
                     </Button>
                  </Popconfirm>
                  </div>
                        :null}

                    </div>
                ),
            },
        ];

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Title level={4}className="view-title">Subrubros de {this.state.rubroNombre}</Title>
                    </Col>

                    <Col xs={12} xl={4} xxl={4}  className="flex-right">
                        <Row align="right" style={{ textAlign: "right", display: "block" }}>
                           <Title level={5} style={{ textAlign: "right", color: "#FFF", marginRight: '15px', marginTop: '7px', fontSize: '11pt' }}> {this.state.itemCount} Subrubros</Title>
                        </Row>
                    </Col>
                </Row>

                <section className="p-1">
                <Row style={{background:'#FFF'}}>
                            <Table
                                loading={this.state.loading}
                                columns={columns}
                                dataSource={this.state.dataSubrubros}
                                scroll={{ x: 800, y: 570 }}
                                pagination={{
                                    current: this.state.currentPage,
                                    pageSize: this.state.perPage,
                                    total: this.state.itemCount,
                                    showSizeChanger: true,
                                    position: ['bottomLeft'],
                                }}
                                onChange={({current, pageSize, total}, b, sort) => {
                                    this.receivedData(current, pageSize)
                                }}
                                className="blankTheme" /> 
                    </Row>
                </section>

                <ModalSubrubros
                    ref={this.refModalSubrubro}
                    modalVisible={this.state.modalVisible}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    rubro_id={this.props.match.params.id}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                >
                </ModalSubrubros>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                    <IconPlus />
                </Button>
            </div>
        )
    }
}
