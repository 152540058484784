
import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, InputNumber, Select, message, Tag, Spin } from 'antd';
//compoenentes
import { IconCloseModal } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');


class FormMovimientos extends Component {


    static defaultProps = {
        onFinish: () => { }
    }

    constructor(props) {
        super(props);
        this.state = {
            productos: [],
            areas_destino: [],

            existencias: [],
            areas: [],

            existencia: null,
            loading: false,
        }
    }

    tipoRef = React.createRef()
    formMovimientos = React.createRef();
    /**
    * @memberof FormMovimientos
    * @method   componentDidMount
    * @description  Al montar el componente:
    **/
    componentDidMount() {

        console.log('props', this.props);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getExistencias();
        this.getAreas();
        this.getArticulos();
    }

    /**
     *
     *
     * @param {*} values
     * @description getExistencias
     * @memberof FormMovimientos
     * 
     * @description Obtenemos las existencias.
     */
    getExistencias = () => {
        axios.get('/articulos/existencias-area', {
            params: {
                id: this.props.articulo_id,
                paginate: false
            }
        })
            .then(({ data }) => {
                this.setState({ existencias: data.data })
            })
            .catch(({ response }) => message.warning(''))
    }


    /**
     *
     *
     * @param {*} values
     * @description getAreas
     * @memberof FormMovimientos
     * 
     * @description Obtenemos las areas.
     */
    getAreas = () => {
        axios.get('/locaciones/list', {
            params: {
                paginate: false
            }
        })
            .then(({ data }) => {
                this.setState({ areas: data.data })
            })
            .catch(({ response }) => message.warning(''))
    }

     /**
     *
     *
     * @param {*} values
     * @description getArticulos
     * @memberof FormMovimientos
     * 
     * @description Obtenemos las areas.
     */
    getArticulos = () => {
        axios.get('/articulos/list', {
            params: {
                paginate: false
            }
        })
            .then(({ data }) => {
                console.log(data)
                this.setState({ productos: data.data.itemsList })
            })
            .catch(({ response }) => message.warning(''))
    }




    /**
     *
     *
     * @param {*} values
     * @description saveMovimiento
     * @memberof FormMovimientos
     * 
     * @description Guardamos el movimiento
     */
    saveMovimiento = (values) => {

        try{
        this.setState({loading: true})

        axios.post('/movimientos/add', {
            ...values,
            articulo_id: this.props.articulo_id ? this.props.articulo_id : values.articulo_id,
            area_id: this.props.area_id ? this.props.area_id : values.area_id,
            tipo: this.props.tipo
        })
            .then(({ data }) => {
                this.props.onSuccess()
                message.success('Guardado exitosamente')
                // this.setState({ areas: data.data })
            })
            .catch(({ response }) => {
                if(response.data?.error){
                    message.error(response.data.error)
                }else{
                    message.error('No fue posible generar el movimiento.')
                }
            }).finally(()=>this.setState({loading: false}))

        }catch(e){
            console.log(e)
        }

    }



    render() {


        // tipo={}

        // 0 -> Compra
        // 1 -> Venta 
        // 2 -> Reubicación

        console.log('thi0', this.props.tipo)


        let areas = this.state.existencias

        if (this.props.tipo === 0) {
            areas = this.state.areas
            console.log('areas', areas)
        }

        return (

            <Form layout="vertical" className="frm-cuentas" name="form-materiales" ref={this.formMovimientos} onFinish={this.saveMovimiento}>
                <Spin spinning={this.state.loading}>
                <Row gutter={[20,20]} >
                    {this.props.area_id ? <Col xs={12} >  
                        <Form.Item 
                            label="Producto" 
                            name="articulo_id" 
                            rules={[{ required: true, message: "Por favor, seleccione el Producto" }]} >
                            <Select 
                                className="form-select"
                                placeholder="Seleccionar..."
                            >
                                {this.state.productos.map(({ _id, nombre, unidad }, index) => {
                                    return <Option value={_id}>{nombre} {unidad}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col> : 
                    <Col xs={12}>
                        <Form.Item
                            label={'Area ' + ((this.props.tipo === 2) ? "de Origen" : '')}
                            name="area_id"
                            rules={[{
                                required: true,
                                message: "Por favor, seleccione el Area"
                            }]}
                            extra={(this.state.existencia) ? <div>Cantidad de Producto: {this.state.existencia}</div> : null}
                        >
                            <Select
                                className="form-select"
                                onSelect={(value, obj) => this.setState({ existencia: obj.cantidad })}
                                placeholder="Seleccionar..."  >
                                {areas.map(
                                        /**
                                         *
                                         *
                                         * @param {*} values
                                         * 
                                         * @description Segun sea el caso, declaramos la cantidad de producto disponible en el area, o el area de producto en si. Si es Compra, no mostramos cantidad. Si es reubibicación de cantidad o venta, entonces debemos mostrar cuanta cantidad tenemos en el area.
                                         */
                                    ({ nombre, _id, locacion_id, cantidad }, index) => {
                                        let title, value
                                        if (this.props.tipo === 0) {
                                            title = nombre
                                            value = _id
                                        } else {
                                            title = locacion_id.nombre
                                            value = locacion_id._id
                                        }
                                        return <Option key={_id} value={value} cantidad={cantidad}>{title}</Option>
                                    }
                                )}
                            </Select>
                        </Form.Item>
                    </Col>}
                    {
                        (this.props.tipo === 2) ? <Col xs={12}>
                            <Form.Item
                                label="Area Destino"
                                name="area_destino_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Area"
                                }]}
                            >
                                <Select className="form-select" onSelect={(x, e) => this.setState({ material: e.material })} placeholder="Seleccionar..."  >
                                    {this.state.areas.map(({ nombre, _id }, index) => {
                                        return <Option value={_id}>
                                            {nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>

                        </Col> : null
                    }
                    <Col xs={12}>
                        <Form.Item label="Cantidad" name="cantidad" rules={[
                            { required: true, message: "Por favor, ingrese una cantidad" },
                            () => ({
                                validator: (_, value) => {
                                    if (typeof this.state.existencia === "number" && value > this.state.existencia)
                                        return Promise.reject(new Error('La cantidad a reducir es mayor a la disponible.'))
                                    else
                                        return Promise.resolve()
                                },
                            }),

                        ]}   >
                            <InputNumber min={0} defaultValue={0} />
                        </Form.Item>
                    </Col>


                </Row>
                <Row style={{ textAlign: "center" }}>
                    <Col span={24} className="center">
                        <Form.Item >
                            <Button htmlType="submit" type="primary" className="btn-azul" >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                </Spin>
            </Form>
        )
    }
}


class DetalleMovimiento extends Component{
    constructor(props){
        super(props);
        this.state = {
            movimiento: {}
        }
    }

    componentDidMount(){
        if(this.props.movimiento_id){
            this.getMovimiento()
        }
    }

    getMovimiento(){
        axios.get('/movimientos/get',{
            params:{
                id: this.props.movimiento_id
            }
        }).then(response => {
            console.log(response)
            this.setState({movimiento: response.data.data})
        }).catch(error => {
            message.error('Error al obtener la información')
        })
    }

    renderTipoMovimiento = (tipo) => {
        let movimientos = {
            0: 'Compra',
            1: 'Venta',
            2: 'Reubicación'
        }

        let color = {
            0: '#0047ff',
            1: '#00cd98',
            2: '#ffd954'
        }

        return <Tag className="tag-proyecto" color={color[tipo]}>{movimientos[tipo]}</Tag>
    }

    render(){

        let { movimiento } = this.state;

        return(
            <Form align="vertical">
            <Row align="center">
                    <Col span={23}>
                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item label="Producto">
                                   <Text strong>  {movimiento.articulo_id?.nombre} </Text>
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={{span: 11, offset:2}}>
                                <Form.Item label="Cantidad" >
                                    <Text>  {movimiento.cantidad} </Text>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item label="Area" >
                                   <Text strong> {movimiento.area_id?.nombre} </Text>
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={{span: 11, offset:2}}>
                                <Form.Item label="Area Destino" >
                                   <Text strong> {movimiento.area_destino_id ? movimiento.area_destino_id.nombre : 'N/A'} </Text>
                                </Form.Item>
                            </Col>  
                        </Row>

                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item label="Tipo" >
                                    {this.renderTipoMovimiento(movimiento.tipo_movimiento)}
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={{span: 11, offset:2}}>
                                <Form.Item label="Fecha" >
                                   <Text>{moment(movimiento.createdAt).format('DD/MM/YYYY')}</Text>
                                </Form.Item>
                            </Col>  
                        </Row>
                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item label="Cantidad Actual" >
                                    <Text>{movimiento.articulo_id?.cantidad}</Text>
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={{span: 11, offset:2}}>
                                <Form.Item label="Usuario" >
                                    <CustomAvatar 
                                        image={movimiento.usuario_id?.avatar} 
                                        name={[movimiento.usuario_id?.nombre,movimiento.usuario_id?.apellido]} 
                                        size="small"  
                                        style={{ marginRight: '5px' }} />            
                                        {movimiento.usuario_id?.nombre + ' ' + movimiento.usuario_id?.apellido }
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Form>
        )
    }
}



export default function (props) {


    const { tipo } = props

    // 0 -> Compra
    // 1 -> Venta 
    // 2 -> Reubicación

    let title
    switch (tipo) {
        case 0:
            title = "Compra"
            break;

        case 1:
            title = "Venta"
            break;

        case 2:
            title = "Reubicación"
            break;
    }


    return <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}
    >

        <div className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={props.onCancel}>
                <IconCloseModal />
            </Button>
            <Title level={3} className="modal-title"> Movimiento {title}</Title>
        </div>
        {props.movimiento_id ? <DetalleMovimiento {...props}/> : <FormMovimientos {...props}/> }
        
    </Modal>
}