import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Form, InputNumber, Checkbox, Select, message, Carousel, Radio, Spin   } from 'antd';
import axios from 'axios'
import {CheckCircleFilled, UploadOutlined} from '@ant-design/icons';
//componentes
import { IconCloseModal,IconArrowSelect, IconAbono, IconFactura  } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'
import './../../../../Styles/Proveedores.css'
const moment = require('moment');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalFactura extends Component {

    formFactura = React.createRef();
    carouselRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
           cuentas:[],
           dataAreas:[],
           dataRubros:[],
           dataSubrubros:[],
           proyectos:[],
           tipo_transaccion: null,
           modalTitle: 'Tipo de Transacción',
           loading: false
        }
    }

    /**
    * @memberof ModalFactura
    *
    * @method   componentDidMount
    * @description  
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getCuentas();
        this.loadAreas();
        this.getProyectos();
    }


    /**
    * @memberof ModalFactura
    *
    * @method   componentDidUpdate
    * @description  
    *
    **/
    componentDidUpdate(prevProps) {
        if(this.props.transaccion_id !== prevProps.transaccion_id && this.props.transaccion_id !== undefined && this.props.visible === true){
            this.getTransaccion(this.props.transaccion_id)
        }

        if(this.props.tipo_transaccion && this.props.tipo_transaccion !== prevProps.tipo_transaccion){
            this.setState({modalTitle: this.props.tipo_transaccion, tipo_transaccion: this.props.tipo_transaccion})
        }

        if(this.carouselRef.current && this.props.tipo_transaccion === 'Abono'){
            this.carouselRef.current.goTo(1)
        }
    }

    /**
    * @memberof ModalFactura
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props  
    *
    **/
    hideModal = (item) => {
        this.setState({tipo_transaccion: null, modalTitle: 'Tipo de Transacción'})
        this.props.closeMethod(item);
    }

     /**
     *
     *
     * @memberof ModalCotizacion
     * @function getProyectos
     *
     * @description Obtener proyectos.
     */
    getProyectos = (search) => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
                search,
            }
        }).then((proyectos) => {
            this.setState({proyectos: proyectos.data.data})
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }

     /**
     *
     *
     * @memberof ModalFactura
     * @method getCuentas
     * @description Obtiene la informacion de las cuentas registradas en la base de datos
     *
     */
    getCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200
            }
        }).then((cuentas) => {
            this.setState({
                cuentas: cuentas.data.data.itemsList
            })
        }).catch((error) => {
        })
    }

    /**
     *
     *
     * @memberof ModalFactura
     * @method loadAreas
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                dataAreas: response.data.data
            })
        }).catch((error) => {
        })
    }


    /**
     * @methodOF ModalFactura
     *
     * @function onAreaChange
     * @description Al actualizar un area, se deben traer los rubros y subrubros iniciales.
     * */
    onAreaChange = (id, rubro_id,sub_rubro_id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        }).then(async (rubros) => {
            console.log('rubros', rubros)
            this.setState({ dataRubros: rubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (rubros.data.data.length > 0) {
                if(typeof rubro_id !== 'object'){
                    console.log('rubro',rubro_id)
                    this.formFactura.current.setFieldsValue({ rubro: rubro_id});
                    this.onRubroChange(rubro_id, sub_rubro_id)
                }else{
                    this.formFactura.current.setFieldsValue({ rubro: rubros.data.data[0]._id});
                    this.onRubroChange(rubros.data.data[0]._id)
                }
            } else {
                this.formFactura.current.setFieldsValue({
                    rubro: undefined,
                    subrubro: undefined
                })
            }
        }).catch((error) => {

        })
    }

    /**
     *
     *
     * @memberof ModalFactura
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (rubro_id,sub_rubro_id) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: rubro_id
            }
        }).then((subrubros) => {
            this.setState({ dataSubrubros: subrubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataSubrubros.length > 0) {
                if(typeof sub_rubro_id !== 'object'){
                    this.formFactura.current.setFieldsValue({ subrubro: sub_rubro_id});
                }else{
                    this.formFactura.current.setFieldsValue({ subrubro: subrubros.data.data[0]._id});
                }
            } else {
                this.formFactura.current.setFieldsValue({
                    subrubro: undefined
                })
            }
        }).catch((error) => {
        })
    }

    /**
     *
     *
     * @memberof ModalFactura
     * @method onFinish
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onFinish = (values) => {
        if(this.props.transaccion_id){
            this.updateTransaccion(values)
        }else if(this.state.tipo_transaccion === "Abono"){
            this.abonoFacturas(values)
        }else{
            this.addTransaccion(values)
        }
        

    }

    /**
     *
     * @memberof ModalFactura
     *
     * @method addTransaccion
     * @description Crea una nueva transaccion para pagar la factura y actualiza la factura
     */
    addTransaccion = (values) => {
        const { factura } = this.props
        this.setState({loading: true})
        axios.post('/factura/transaccion', {
            concepto: factura.concepto,
            cuenta_id: values.cuenta,
            monto: values.monto,
            proyecto_id: factura.proyecto_id._id,
            rubro_id: values.rubro,
            sub_rubro_id: values.subrubro,
            area_id: values.area_id,
            tipo: 2,
            fecha: values.fecha,
            proveedor_id: factura.proveedor_id,
            /*campos para la factura*/
            factura_id: this.props.factura._id,
            monto_a_pagar: values.monto,
            transaccion_id: values.transaccion_id,
        }).then((response) => {
            message.success('Se creo la Transacción')
            this.props.closeMethod(true)
        }).catch((error) => {
            console.log(error)
            message.error('No se creo la Transacción')
        }).finally(()=>{this.setState({loading: false})})
    }

    /**
     *
     *
     * @memberof ModalFactura
     * @function getTransaccion
     *
     * @description obtiene los datos de una transaccion, llama la los metodos para poner el rubro y subrubro correspondientes
     */

    getTransaccion = (id) => {
        axios.post('/transacciones/get', {
            id: id
        }).then((transaccion) => {
            let data = transaccion.data.data;
            this.formFactura.current.setFieldsValue({
                cuenta: data.cuenta_id?._id,
                monto: data.monto,
                area_id: data.area_id._id,
                rubro: data.rubro_id._id,
                subrubro: data.sub_rubro_id._id,
            })
            this.onAreaChange(data.area_id._id, data.rubro_id._id, data.sub_rubro_id._id)
        }).catch(error=>{
            console.log(error)
        })  
    }

    /**
     *
     *
     * @memberof ModalFactura
     * @function updateTransaccion
     *
     * @description actualiza una transaccion
     */

    updateTransaccion = (values) => {
       axios.post('/transacciones/update', {
            id: this.props.transaccion_id,
            cuenta_id: values.cuenta,
            monto: values.monto,
            rubro_id: values.rubro,
            sub_rubro_id: values.subrubro,
            area_id: values.area_id,
        }).then(() => {
            message.success('Transaccion Actualizada')
            this.props.closeMethod(true)
        }).catch(() => {
            message.error('Ocurrio un error, no se edito la transaccion.')
        })  
    }

    /**
     *
     *
     * @memberof ModalFactura
     * @function abonoFacturas
     *
     * @description actualiza una transaccion
     */

    abonoFacturas = (values) => {

        const { factura } = this.props
        this.setState({loading: true})
        axios.post('/facturas/abono', {
            proveedor_id: factura?.proveedor_id ? factura.proveedor_id : this.props.proveedor._id,
            cuenta_id: values.cuenta,
            monto: values.monto,
            rubro_id: values.rubro,
            sub_rubro_id: values.subrubro,
            area_id: values.area_id,
            fecha: values.fecha,
            concepto: values.concepto,
            proyecto_id: values.proyecto_id,
        }).then(() => {
            message.success('Facturas Abonadas')
            this.props.closeMethod(true)
        }).catch(() => {
            message.error('Ocurrio un error al abonar a Facturas.')
        }).finally(()=>{this.setState({loading: false})})
    }
    

     /**
    * @memberof ModalFactura
    *
    * @method   renderFactura
    * @description muestra a detalle la informacion de la factura
    *
    **/
    renderFactura = (factura) => {
        return <Row align="center">
                    <Col span={23}>
                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item label="Concepto">
                                   <Text strong> {factura.concepto} </Text>
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={{span: 11, offset:2}}>
                                <Form.Item label="Proyecto" >
                                    <Text strong> <CustomAvatar
                                        image={factura.proyecto_id.logo}
                                        name={factura.proyecto_id.nombre}
                                        color={factura.proyecto_id.color}
                                        size="small"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    />
                                    {factura.proyecto_id.nombre}</Text>
                                </Form.Item>
                            </Col>
                        </Row>

                        {factura.factura_xml ? <>
                            <Row>
                                <Col span={22}>
                                    <Title level={5}>Dartos Generados por el XML</Title>
                                </Col>
                                <Col xs={22} md={11}>
                                    <Form.Item label="Folio" >
                                        <Text strong> {factura.folio} </Text>
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item label="Fecha" >
                                       <Text strong> {moment(factura.fecha).format('DD-MM-YYYY')} </Text>
                                    </Form.Item>
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={24} className="hr"></Col>
                                <Col span={22}>
                                    <Title level={5}>Emisor</Title>
                                </Col>
                                <Col xs={22} md={11}>
                                    <Form.Item label="RFC" >
                                        <Text strong>{factura.emisor?.rfc} </Text>
                                    </Form.Item>
                                </Col> 
                                 <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item label="Nombre" >
                                        <Text strong>{factura.emisor?.nombre}</Text>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className="hr"></Col>
                                <Col span={22}>
                                    <Title level={5}>Receptor</Title>
                                </Col>
                                <Col xs={22} md={11}>
                                    <Form.Item label="RFC" >
                                        <Text strong>{factura.receptor?.rfc} </Text>
                                    </Form.Item>
                                </Col> 
                                 <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item label="Nombre" >
                                        <Text strong>{factura.receptor?.nombre}</Text>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>: null }
                    </Col>
                </Row>
    }


    /**
     *
     * @memberof ModalFactura
     * @function continuar
     * @description Valida si ya esta selccionado el tipo de transaccion, y cambia de slide
     * */
    continuar = () => {
        let titulos = {
            Completa: 'Factura Completa',
            Abono: 'Abono'
        }

        if (this.state.tipo_transaccion === null)
            Modal.error({
                title: "Debe seleccionar un tipo de transaccion",
                zIndex: 10000
            })
        else
            this.setState({modalTitle: titulos[this.state.tipo_transaccion] })
            this.carouselRef.current.goTo(1)
           
    };



    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.state.modalTitle}</Title>
                </div>

                <Carousel
                    dots={false}
                    infinite={false}
                    touchMove={false}
                    ref={this.carouselRef}
                    initialSlide={this.props.initSlide ? this.props.initSlide : 0}
                >   
                    <div>
                        <Radio.Group className="radio-group-modal" onChange={(e) => this.setState({ tipo_transaccion: e.target.value })}>
                            <Row>
                                <Col span={12} className="center">
                                    <Radio.Button value="Abono" className="radio-btn-modal">
                                        <div className="radio-btn-inner">
                                            <IconAbono/>
                                            <Text className="radio-btn-text">Abono</Text>
                                        </div>
                                    </Radio.Button>
                                </Col>
                                <Col span={12} className="center">
                                    <Radio.Button value="Completa" className="radio-btn-modal">
                                        <div className="radio-btn-inner">
                                            <IconFactura/>
                                            <Text className="radio-btn-text">Factura Completa</Text>
                                        </div>
                                    </Radio.Button>
                                </Col>
                            </Row>
                        </Radio.Group>
                        <Row>
                            <Col span={24} className="center">
                                <Button onClick={this.continuar} type="primary" className="btn-azul" >
                                    Continuar
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <div className="scroll-modal" style={{maxHeight: '552px', overflowY: 'auto'}}>
                        {
                            (this.props.factura && this.state.tipo_transaccion === 'Completa') ? <Form layout="vertical" >
                                {this.renderFactura(this.props.factura)} </Form> : null
                        }
                        <Spin spinning={this.state.loading}>
                        <Form
                            layout="vertical"
                            onFinish={this.onFinish}
                            initialValues={{
                                ...this.props.factura, 
                                monto: this.props.factura?.monto_restante,
                                proyecto_id: this.props.factura?.proyecto_id?._id, 
                                ...this.props.transaccion, 
                                fecha: moment()
                            }}
                            ref={this.formFactura}
                        >
                            <Row align="center">
                                <Col span={23}>
                                    {this.state.tipo_transaccion === 'Abono' ? <Row>
                                        <Col xs={22} md={11}>
                                            <Form.Item
                                                label="Concepto"
                                                name="concepto" //<--------------------------------------- concepto
                                                rules={[{
                                                    required: true,
                                                    message: "Por favor, ingrese el concepto"
                                                }]}
                                            >
                                               <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={22} md={{span: 11, offset:2}}>
                                            <Form.Item
                                                label="Proyecto"
                                                name="proyecto_id" //<--------------------------------------- proyecto_id
                                                rules={[{
                                                    required: true,
                                                    message: "Por favor, seleccione un proyecto"
                                                }]}
                                            >
                                                <Select
                                                    className="form-select"
                                                    onSearch={(search) => this.getProyectos(search)}
                                                    showSearch
                                                    filterOption={false}
                                                >
                                                    {this.state.proyectos.map((proyecto) =>  <Option value={proyecto._id}>
                                                        <CustomAvatar
                                                            image={proyecto.logo}
                                                            name={proyecto.nombre}
                                                            color={proyecto.color}
                                                            size="small"
                                                            style={{
                                                                marginRight: '5px'
                                                            }}
                                                        />
                                                        {proyecto.nombre}
                                                    </Option>)}
                                                </Select>
                                            </Form.Item>
                                            <IconArrowSelect />
                                        </Col>
                                    </Row>: null}
                                    <Row>
                                        <Col xs={22} md={11}>
                                            <Form.Item
                                                label="Monto"
                                                name="monto" //<--------------------------------------- monto
                                                rules={[{
                                                    required: true,
                                                    message: "Por favor, ingrese el Monto"
                                                }]}
                                            >
                                               <InputNumber 
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                min={1} max={this.state.tipo_transaccion === 'Abono' ? this.props.abono_maximo : this.props.factura?.monto_restante}/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={22} md={{span: 11, offset:2}}>
                                            <Form.Item
                                                label="Cuenta"
                                                name="cuenta" //<--------------------------------------- cuenta
                                                rules={[{
                                                    required: true,
                                                    message: "Por favor, seleccione la cuenta"
                                                }]}
                                            >
                                               <Select className="form-select" placeholder="Seleccione cuenta">
                                                {this.state.cuentas.map(function ({ _id, nombre, logo, color }, index) {
                                                    return <Option value={_id}>
                                                        <CustomAvatar
                                                            image={logo}
                                                            name={nombre}
                                                            // color={color}
                                                            size="small"
                                                            style={{
                                                                marginRight: '5px'
                                                            }}
                                                        />
                                                        {nombre}
                                                    </Option>
                                                })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={22} md={11}>
                                            <Form.Item
                                                label="Area"
                                                name="area_id"
                                                listType="text"
                                                rules={[{
                                                    required: true,
                                                    message: "Por favor, seleccione el Area a que corresponde la transacccion."
                                                }]}
                                            >
                                                <Select
                                                    className="form-select"
                                                    placeholder="Seleccione el Area"
                                                    disabled={this.state.edicion}
                                                    onChange={this.onAreaChange}
                                                >
                                                    {this.state.dataAreas.map(function (area, index) {
                                                        return <Option value={area._id}>{area.nombre}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <IconArrowSelect />
                                        </Col>
                                        <Col  xs={22} md={{span: 11, offset:2}}>
                                            <Form.Item
                                                label="Rubro"
                                                name="rubro"
                                                listType="text"
                                                rules={[{
                                                    required: true,
                                                    message: "Por favor, seleccione el rubro"
                                                }]}
                                            >
                                                <Select className="form-select" placeholder="Seleccione rubro" disabled={this.state.edicion}
                                                    onChange={this.onRubroChange}>
                                                    {this.state.dataRubros.map( (rubro, index) => {
                                                        
                                                        return <Option value={rubro._id}>{rubro.nombre}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <IconArrowSelect />
                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col xs={22} md={11}>
                                            <Form.Item
                                                label="Subrubro"
                                                name="subrubro"
                                                rules={[{
                                                    required: true,
                                                    message: "Por favor, seleccione el subrubro"
                                                }]}
                                            >
                                                <Select className="form-select" placeholder="Seleccione subrubro" disabled={this.state.edicion}
                                                    >
                                                    {this.state.dataSubrubros.map(function (subrubro, index) {
                                                        return <Option value={subrubro._id}>{subrubro.nombre}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <IconArrowSelect />
                                        </Col>
                                        <Col xs={22} md={{span: 11, offset:2}}>
                                            <Form.Item
                                                label="Fecha de Transacción"
                                                name="fecha"
                                                rules={[{
                                                    required: true,
                                                    message: "Por favor, seleccione la fecha."
                                                }]}
                                            >
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>  
                                    </Row>
                                    
                                </Col>
                            </Row>
                            <Row style={{ textAlign: "center" }}>
                                <Col xs={24}>
                                    <Form.Item >
                                        <Button htmlType="submit" type="primary" className="btn-azul" >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        </Spin>
                    </div>

                </Carousel>


                
            </Modal>
        )
    }
}
