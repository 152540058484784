import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, Popconfirm, message, Pagination} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconEye, IconEdit,  IconDelete, IconPlus, IconArrowBack, IconOpen } from '../../Widgets/Iconos';
//modal
import ModalMonto from '../Modales/Presupuesto/ModalMonto';
import ModalDetalleNomina from '../Modales/Presupuesto/ModalDetalleNomina';
//css
import '../../../Styles/presupuesto.css'

const { Title, Text } = Typography;
const axios = require('axios').default
const moment = require('moment');

export default class DetalleActividad extends Component{

	constructor(props){
		super(props)
		this.state = {
			inmueble_trabajo_id: this.props.match.params.inmueble_trabajo_id,
			tajo_id: this.props.match.params.tajo_id,
			modalMontoVisible: false,
			modalDetalleNomina: false,
			transaccion: {},
			actividad: {
				tajo_id:{nombre: '-'}
			},
			inmueble: {nombre: '-'},
			nominas:[],
			loading: false,
			loadingNominas: false,
			monto_total_pagado: 0,
			/* Paginado */
			page: 1,
			total: 0,

		}
	}

	volver = () => {
        window.history.back();
    }


	/**
	* @memberof DetalleActividad
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getActividad(this.props.match.params.inmueble_trabajo_id,this.props.match.params.tajo_id)
	}

	/**
	 * @methodOf  DetalleActividad
	 * @function hideModal
	 *
	 * @description Cierra el modal
	 *
	 * */
	hideModal = () => {
		this.setState({ modalMontoVisible: false, modalDetalleNominaVisible: false, transaccion: {} });
	};

	/**
	 * @methodOf  DetalleActividad
	 * @function refresh
	 *
	 * @description refresaca la infomacion
	 *
	 * */
	refresh = () => {
		this.getActividad(this.props.match.params.inmueble_trabajo_id,this.props.match.params.tajo_id)
	};

	/**
     * @memberof ModalInmuebleTajo
     *
     * @method getActividad
     *
     * @param id ObjectId _id de Inmueble_trabajo
     * @param tajo_id ObjectId _id del tajo (actividad a buscar)
     * @description trae la infomracion de un inmueble trabajado, para depsues bucar el tajo (actividad) , cuyo id conicida, 
     * con el que se esta buscando por medio de la url
     *
     */
    getActividad = (id, tajo_id) => {
        this.setState({loading: true})
        axios.post('/inmuebles-trabajo/get/', {
            id: id,
        })
        .then(res => {
            let actividades = res.data.data.tajos
            let indexActividad = actividades.findIndex(act=> act.tajo_id._id === tajo_id)
            this.setState({
            	inmueble_trabajo_id: res.data.data._id,
            	actividad: actividades[indexActividad],
            	inmueble:res.data.data.inmueble_id,
            	loading: false,
            },()=>{
            	this.getDetallesNominas()
            })
        })
        .catch(error => {
            console.log('error al buscar el Tajo', error);
            this.setState({ loading: false })
            message.error('Actividad no encontrada');
        })

    }

    /**
     * @memberof ModalInmuebleTajo
     *
     * @method getDetallesNominas
     *
     * @param inmueble_id ObjectId _id de inmueble (tags)
     * @param actividad_id ObjectId _id de actividad (tags)
     * @description trae la informacion de las nominas, donde se han registrado gastos de mano de obra
     *
     */
    getDetallesNominas = (page = 1) => {
    	this.setState({loadingNominas: true})
        axios.get('/presupuesto/detalle/actividad', {
        	params:{
        		page: page,
        		limit: 10,
            	inmueble_id: this.state.inmueble._id,
            	actividad_id: this.state.actividad.tajo_id._id,
        	}
        })
        .then(res => {
            //se suman el monto pagado en las nominas y el monto extra de la actividad
            let a = this.state.actividad.monto_extra ? this.state.actividad.monto_extra : 0;
            let b = res.data.monto ? res.data.monto : 0;

        	this.setState({
        		monto_total_pagado: a + b,
        		nominas: res.data.data.itemsList,
        		page: res.data.data.currentPage,
        		total: res.data.data.itemCount,
        		loadingNominas: false,
        	})
            

        })
        .catch(error => {
            console.log('error al buscar el Tajo', error);
            this.setState({ loading: false })
            message.error('Actividad no encontrada');
        })

    }


	render() {
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} className="">
					 	<Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
                            <IconArrowBack />
                        </Button>
						<h2 style={{width: 'auto', float: 'left'}}>{this.state.actividad.tajo_id?.nombre} de {this.state.inmueble.nombre}</h2>
					</Col>
				</Row>
				<section className="p-1">
					<Row>
                        <Col span={24}>
                            <Card
                                loading={this.state.loading}
                                bordered
                                className="card-proyeccionesDetalle"
                            >
                                <Row>
                                    <h1>{this.state.actividad?.tajo_id?.nombre}</h1>
                                </Row>

                                <Row>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Inmueble</label>
                                        <p className="pInfo">{this.state.inmueble.nombre}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Monto Pagado</label>
                                       	<div>
                                        	<p className="pInfo">$ {this.state.monto_total_pagado.toMoney(2)} MXN</p>
                                        	<IconOpen className="card-svg-open" onClick={()=>{this.setState({modalMontoVisible: true})}}/>
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Presupuesto</label>
                                        <div>
                                        	<p className="pInfo">$ {this.state.actividad?.precio_m2?.toMoney(2)} MXN</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <div className="list-title">
                    	Nominas
                    </div>
					<div className="scroll">
						<List
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.nominas}
							loading={this.state.loadingNominas}
							locale={{ emptyText: 'Sin Nominas' }}
							renderItem={item =>
								(<List.Item className="component-list-item">
									<Card className="card-list">
										<Row style={{ width: '100%' }} className="">
											<Col span={3} className="center">
												<Text>{moment(item.nomina_id.fecha).format('DD/MM/YYYY')}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>{item.actividad.transaccion_id.concepto}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>{item.actividad.transaccion_id.rubro_id.nombre}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>$ {item.actividad.monto_pagado.toMoney(2)} MXN</Text>
											</Col>
											<Col span={3} className="center">
												<Button type="primary" 
														className="button-survey btn-eye button-eye" title="Ver detalle" 
														onClick={(e) => { this.setState({modalDetalleNominaVisible: true, transaccion: item.actividad.transaccion_id}) }}>
													<IconEye />
												</Button>
											</Col>
										</Row>
									</Card>
								</List.Item>
								)}
						/>
					</div>
					<Row className="mt-2 pb-3">
						<Pagination
							total={this.state.total}
							current={this.state.page}
							onChange={(page) => {
									this.getDetallesNominas(page)
								}}
							/>
						</Row>
				</section>
				{/*<Button
					className="btnAddPlus"
					onClick={() => this.setState({modalProspectosVisible: true, modalTitle: 'Añadir'})}>
					<IconPlus/>
				</Button>*/}
				<ModalMonto
					modalVisible={this.state.modalMontoVisible}
					closeMethod={this.hideModal}
					refresh={this.refresh}
					objetivo={'monto_extra'}
					inmueble_trabajo_id={this.state.inmueble_trabajo_id}
					tajo_id={this.state.actividad.tajo_id._id}
					monto={{monto: this.state.actividad.monto_extra}}

				/>
				<ModalDetalleNomina
					modalVisible={this.state.modalDetalleNominaVisible}
					closeMethod={this.hideModal}
					transaccion={this.state.transaccion}

				/>
			</div>
		)
	}
}