import React, { Component } from 'react';
import { Row, Col, Card, Button, Select, Table, Modal, message, Spin, InputNumber } from 'antd'

import { IconTblPagos, IconTblPagosDelete, IconArrowBack } from '../../../Widgets/Iconos';


import '../../../../Styles/detalle.css';
import Logged from '../../../../Hooks/Logged';

import CustomAvatar from "../../../Widgets/Avatar/Avatar";

const moment = require('moment');
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

const { Option } = Select;



/**
 *
 *
 * @export
 * @class DetalleCliente
 * @extends {Component}
 *
 * @description Detalle de las pendientes.
 */
export default class DetalleCliente extends Component {



    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            registroId: '',

            empleadoNombre: '',

            dataTran: [],
            dataCuentas: [],

            transacciones: [],
            pagos_pendientes: [],

            cliente: null,
            fecha: new Date,
            nombre: '',
            apellido: '',
            email: '',
            grupos: [],
            montoTotal: 0,

            page: 1,
            limit: 10,
            total: 0,
            loadingDeuda: false
        }
    }



    /**
     *
     *
     * @memberof DetalleCliente
     *
     * @description Obtenemos las cuentas, los pagos pendientes  y la información.
     */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Obtenemos detalles

        this.getCardInfo();
        this.loadCuentas();
       
    }

    /**
     *
     *
     * @memberof DetalleCliente
     *
     * @method loadCuentas
     * @description Obtiene la informacion de las cuentas registradas en la base de datos
     *
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                pagination: false
            }
        }).then((cuentas) => {
            this.setState({
                dataCuentas: cuentas.data.data
            })
        }).catch((error) => {
            console.log('error',error)
        })
    }

    /**
     *
     *
     * @memberof DetalleCliente
     *
     * @description Obtenemos la informacion del usuario
     * @param nomina_id Id de la nomina en la que esta el empleado
     * @param empleado_id Id del empleado del cual se buscan transacciones pendientes
     */
    getCardInfo = () => {
        axios.get('/pendientes/get', {
            params: {
                nomina_id: this.props.match.params.nomina_id,
                empleado_id: this.props.match.params.usuario_id,
           
            }
        })
            .then(({ data }) => {
                const { datos, empleado } = data.data;
                console.log('pago pendientes detalle', data.data)
                this.setState({
                    empleadoNombre: empleado.nombre + ' ' + empleado.apellido,
                    cliente: "aa",
                    fecha: new Date,
                    nombre: empleado.nombre,
                    apellido: empleado.apellido,
                    email: empleado.email,
                    grupos: (Array.isArray(datos.grupos)) ? datos.grupos : [],
                    montoTotal: (datos.monto_total) ? datos.monto_total : 0,
                    loading: false,
                    transacciones: (Array.isArray(datos.pagos_pendientes)) ? datos.pagos_pendientes : [],
                    pagos_pendientes: (Array.isArray(datos.pagos_pendientes) && datos.pagos_pendientes.length > 0) ? datos.pagos_pendientes.map((item, index) => {
                        return {
                            _id: item._id,
                            monto_pagado: 0,
                            cuenta_id: null
                        }
                    }) : [],

                })

            })
    }


    /**
     *
     *
     * @memberof DetalleCliente
     * @function getPagosPendientes
     *
     * @description Obtenemos los pagos pendientes paaginados.
     *
     * @param page -> pagina actusl
     * @param limit -> Limite de Cantidades-
     */
    getPagosPendientes = (page = this.state.page, limit = this.state.limit) => {
        axios.get('/pendientes/list', {
            params: {
                nomina_id: this.props.match.params.nomina_id,
                empleado_id: this.props.match.params.usuario_id,
                only_pagado: true,
                page,
                limit
            }
        })
            .then(({ data }) =>
             this.setState({
                transacciones: data.data.itemsList,
                pagos_pendientes: data.data.itemsList.map((item, index) => {
                    return {
                        _id: item._id,
                        monto_pagado: 0,
                        cuenta_id: null
                    }
                }),
                page, limit,
                total: data.data.paginator.itemCount
            }))

    }





    /**
     *
     *
     * @memberof DetalleCliente
     *
     * @function onChangeMonto
     * @description Obtenemos el monton total de cambio
     */

    onChangeMonto = (e, index) => {
        let { pagos_pendientes } = this.state;
        pagos_pendientes[index].monto_pagado = e;
        this.setState({
            pagos_pendientes
        })
    }



    /**
     *
     *
     * @memberof DetalleCliente
     *
     * @function onChangeAccount
     * @param e Valor a cambiar
     * @param index Localizacion en el arreglo a enviar
     */
    onChangeAccount = (e, index) => {
        let { pagos_pendientes } = this.state;
        pagos_pendientes[index].cuenta_id = e;
        this.setState({
            pagos_pendientes
        })
    }


    /**
     *
     *
     * @memberof DetalleCliente
     *
     * @function proceed
     * @param index Localizacion en el arreglo a enviar al server.
     */
    proceed = (index) => {
        let data = this.state.pagos_pendientes[index];

    
        let monto = false
        let cuenta = false

        if (data.monto_pagado <= 0)
            monto = true;

        if (data.cuenta_id === null)
            cuenta = true;


        if (monto || cuenta) return Modal.error({
            title: "Debe seleccionar una cuenta y debe de tener un monto mínimo."
        })

        this.setState({ loadingDeuda: true })
        axios.post('/pendientes/update', data)
            .then(e => {
                Modal.success({
                    title: "Pagado exitosamente."
                })
                this.getCardInfo()
                this.setState({ loadingDeuda: false })
            })
            .catch(error => {
                message.error('Ocurrio un error al registrar.')
                this.setState({ loadingDeuda: false })
            })
    }




    /**
     *
     *
     * @memberof DetalleCliente
     *
     * @function eliminarPago
     * @description Por medio del ID, se elimina la transaccion en cuestion.
     *
     * @param _id ID del tag
     */
    eliminarPago = (_id) => {
        Modal.confirm({
            title: '¿Deseas eliminar este pago pendiente?',
            content: 'Se eliminará este adeudo permanentemente',
            onOk: () => {
                axios.post('/pendientes/delete', {
                    _id
                })
                    .then(e => {
                        this.getCardInfo()
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    render() {
        const user = this.context;
        const columns = [
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
                width: 130,
                render: (text, record) => (
                    <label className="lblTitulo">
                                                {console.log('recird',record)}
                        {moment(record.createdAt).utc().format('DD-MM-YYYY')}
                    </label>
                ),
            },

            {
                title: 'Inmueble',
                key: 'inmueble',
                render: (text, record, index) => {
                   return  (record.inmueble_id)?( <div> {record.inmueble_id.nombre} </div>):'N/A'
                }
            },
            {
                title: 'Cuenta',
                dataIndex: 'cuenta',
                render: (text, record, index) => (
                    <div>
                        <Select value={this.state.pagos_pendientes[index].cuenta_id} onChange={(value) => this.onChangeAccount(value, index)} placeholder="Seleccionar" style={{ width: '100%' }} className="cuenta-select">
                            {this.state.dataCuentas.map(function ({ _id, nombre, logo, color }, index) {
                                return <Option value={_id}>
                                    <CustomAvatar
                                        image={logo}
                                        name={nombre}
                                        size="small"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    />
                                    {nombre}
                                </Option>
                            })}
                        </Select>
                    </div>
                ),
            },
            {
                title: 'Monto',
                key: 'monto',
                render: (text, record, index) => {
                    return (

                        <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            value={this.state.pagos_pendientes[index].monto_pagado}
                            precision={2}
                            max={this.state.transacciones[index].monto_deuda}
                            onChange={(value) => this.onChangeMonto(value, index)}
                        />
                    )
                },
            },

            {
                title: 'Monto Deuda',
                key: 'monto_deuda',
                dataIndex: 'monto_deuda',
            },

            {
                title: 'Acciones',
                key: 'actions',
                width: 180,
                render: (text, record, index) => {
                    return <div className="tbl-opciones">


                        <Button
                            type="primary"
                            className="button-survey btn-pdf button-pdf"
                            style={{ background: "transparent", margin: "2px" }}
                            name={record.nombre} id={record._id}
                            onClick={() => this.proceed(index)}
                        >
                            <IconTblPagos />
                        </Button>

                        {(user && user.tipo === 1) ?
                            <Button onClick={() => this.eliminarPago(record._id)} type="primary" className="button-survey btn-pdf button-pdf" style={{ background: "transparent", margin: "2px" }}>
                                <IconTblPagosDelete />
                            </Button>
                            : null}



                    </div>
                },
            },
        ];

        return (
            <div>
                <Row className="row-encabezado">

                    <Col xs={24} xl={6} xxl={8}>
                        <Button className="IconArrowBack" onClick={() => this.props.history.goBack()}>
                            <IconArrowBack />
                        </Button>
                        <h2 style={{ width: 'auto', float: 'left' }}> Pagos Pendientes {this.state.empleadoNombre} </h2>
                    </Col>

                </Row>

                <section>
                    <Row>
                        <Col span={24}>
                            <Card title="" loading={this.state.loading} bordered className="card-proyeccionesDetalle">
                                <Row>
                                    <h1>Empleado</h1>
                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Nombre</label>
                                        <p className="pInfo">{this.state.nombre}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Apellido</label>
                                        <p className="pInfo">{this.state.apellido}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Email</label>
                                        <p className="pInfo">{this.state.email}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Grupos</label>
                                        <p className="pInfo">{this.state.grupos.length}</p>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Monto Pendiente Total</label>
                                        <p className="pInfo"> {this.state.montoTotal.toString().toMoney(true)} </p>
                                    </Col>

                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Card title="" loading={this.state.loading} bordered className="card-proyeccionesDetalle" >
                                <Row>
                                    <h1>Transacciones Pendientes</h1>
                                    <div className="div_contenido div-contenedor" >
                                        <Spin spinning={this.state.loadingDeuda}>
                                            <Table

                                                columns={columns}
                                                dataSource={this.state.transacciones}
                                                scroll={{ x: 800, y: 200 }}
                                                className="blankTheme table-deudas"
                                                pagination={{
                                                    total: this.state.total
                                                }}

                                                onChange={(e, f, t) => {
                                                    console.log('e', e)
                                                }}


                                                style={{
                                                    minHeight: '250px'
                                                }}
                                            />
                                        </Spin>
                                    </div>

                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </div>
        )
    }
}