import React, { useState } from "react";
import { Typography } from 'antd';

const { Text } = Typography;

const ReadMore = ({ children  = "" }) => {

	const text = children;
	const [isReadMore, setIsReadMore] = useState(true);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};
	return (
		<Text className="text-left">
			{isReadMore ? text.slice(0, 80) : text}
			{text.length > 100 ? <span onClick={toggleReadMore} className="hover text-blue">
				{isReadMore ? "...Ver más" : " Ver menos"}
			</span> : null }
		</Text>
	);
};
	
const Content = (props) => {
	return (
		<ReadMore>
			{props.text}
		</ReadMore>
	);
};
	
export default Content;