import React, { Component } from 'react';
import { Button, Col, Row, message, Typography, List, Card, Pagination, Popover, Form, Select, Checkbox, Avatar, Space, Tag, Modal, Popconfirm } from 'antd'
import { Link } from 'react-router-dom';
import { DownOutlined, CodepenOutlined, InfoCircleOutlined } from '@ant-design/icons';
//componentes
import { IconEdit, IconDelete, IconPlus, IconDetails } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { BiStoreAlt } from 'react-icons/bi'

//modal
import ModalInventarios from './ModalInventarios'

const axios = require('axios').default;
const { Text, Title } = Typography;


const { Option } = Select;



export default class Inventarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading:  false,
            inventarios: [],

            page: 1,
            total: 0,
            limit: 10

        }
    }


    /**
    * @memberof Inventarios
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se muestra el filtro superior
    * Se establece el valor del header en axios
    * Se llama al metodo receivedData que hace la consulta de materiales a mostrar en la tabla
    **/
    componentDidMount() {
        this.getInventarios()
    }

    /**
     * @memberof Inventarios
     * @method getInventarios
     * @description Obtiene la lista de inventarios
     */
    getInventarios = (page = this.state.page) => {
        let search = this.props.search;

        this.setState({ loading: true })
        axios.get('/proyectos/inventarios/list', {
            params: { 
                page: page, 
                paginate: true,
                search
            } 

        }).then(response => {
            console.log(response.data.data)
            this.setState({
                inventarios: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información.')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof Inventarios
     * @method deleteInventario
     * @description Elimina un inventario
     */
    deleteInventario = (inventario_id) => {
        axios.post('/proyectos/inventarios/delete',{
            id: inventario_id
        }).then(response => {
            message.success('Inventario eliminado')
            this.getInventarios()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al eliminar')
        })
    }

    render() {
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={21} className="flex-left">
                        <Title level={4} className="view-title">Inventarios</Title> 
                    </Col>
                </Row>
                <section className="p-1">
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.inventarios}
                        locale={{emptyText: 'Sin Inventarios'}}
                        header={<Row className="header-list" style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>
                            <Col xs={10} className="gutter-row center">
                                <Text strong>Proyecto</Text>
                            </Col>

                            <Col xs={10} className="gutter-row  center">
                                <Text strong>Descripción</Text>
                            </Col>

                            <Col xs={4} className="gutter-row center">
                                <Text strong>Acciones</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">

                                        <Col xs={10} className="center">
                                            <CustomAvatar
                                                image={item.proyecto_id?.logo}
                                                name={item.proyecto_id?.nombre}
                                                color={item.proyecto_id?.color}
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            /><Text strong>{item.proyecto_id?.nombre}</Text>
                                        </Col>

                                        <Col xs={10} className="center">
                                            <Text strong>{item.descripcion}</Text>
                                        </Col>

                                        <Col xs={4} className="center">
                                            <Link to={`/admin/proyectos/${item.proyecto_id._id}/detalles`}>
                                                <Button 
                                                    type="" 
                                                    className="detTable-icon" 
                                                    title="Ver Almacenes"
                                                    icon={<InfoCircleOutlined style={{ position: 'relative', top: -2 }}/>}
                                                />
                                            </Link>
                                            <Link to={`/admin/proyectos/${item.proyecto_id._id}/almacenes/${item._id}`}>
                                                <Button 
                                                    type="" 
                                                    className="detTable-icon" 
                                                    title="Ver Almacenes"
                                                    icon={<BiStoreAlt/>}
                                                />
                                            </Link>
                                            <Link to={`/admin/proyectos/${item.proyecto_id?._id}/productos/${item._id}`}>
                                                <Button
                                                    type="" 
                                                    className="detTable-icon" 
                                                    title="Productos"
                                                    icon={<CodepenOutlined style={{ position: 'relative', top: -2 }} />}
                                                />
                                            </Link>
                                            <Button className="btn btn-edit" title="Editar" onClick={()=>this.setState({inventario_id: item._id, modal_visible: true})}>
                                                <IconEdit />
                                            </Button>

                                            <Popconfirm 
                                                placement="topRight"
                                                title="¿Eliminar este inventario?" 
                                                okText="Si, seguro" 
                                                cancelText="No" onConfirm={() => {
                                                    this.deleteInventario(item._id)
                                            }}>
                                                <Button className="btn btn-delete" title="Eliminar">
                                                    <IconDelete />
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.page}
                            total={this.state.total}
                            pageSize={this.state.limit}
                            showSizeChanger={false}
                            onChange={(page) => {
                                this.receivedData({ page })
                            }}
                        />
                    </Row>

                </section>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={()=>this.setState({modal_visible: true})}>
                    <IconPlus />
                </Button>

                <ModalInventarios
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, inventario_id: undefined})
                        this.getInventarios()
                    }}
                    inventario_id={this.state.inventario_id}
                />
            </div>
        )
    }
}
