import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, List, Typography, message, Spin, Select, InputNumber } from 'antd';

//compoenentes
import { IconCloseModal } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

import axios from 'axios'
const moment = require('moment');

const { Title, Text } = Typography;
const { Option } = Select;

class ModalProductosAlmacen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            productos: [],
            producto_opciones: [],
            producto_id: undefined,
            producto_en_inventario: true,
        }
    }

    //Referencia de form
    formProductoInventario = React.createRef();

    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProductos()

        if(this.props.almacen_producto_id){
            this.getProductoAlmacen()
        }
    }


    /**
     * @memberof ModalProductosAlmacen
     * @function getProductos
     *
     * @description Obtener lista de productos, que no esten  dados de alta en el Inventario
     */
    getProductos = (search) => {
        axios.get('/productos/list', {
            params: {
                search,
            }
        }).then((response) => {
            this.setState({productos: response.data.data.data})
            
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }

    /**
     * @memberof ModalProductosAlmacen
     * @function onSelectProducto
     *
     * @description Obtiene informacion del producto seleccionado, y si esta registrado en el inventario, en caso de que no,
     * muestra los campos 
     */
    onSelectProducto = (producto_id) => {
        return axios.get('/productos/get',{
            params:{
                id: producto_id.value,
                proyecto_id: this.props.proyecto_id,
                inventariado: true, //verifica si esta en el inventario
            }
        }).then(response => {

            //El producto ya esta en el inventario, por lo tanto ocultamos los campos del inventario_producto
            this.setState({producto_en_inventario: response.data.producto_inventario ? true : false})

        }).catch(error => {
            console.log("error", error);

        })
    }


     /**
     * @memberof ModalProductoAlmacen
     * @function getProductoAlmacen
     *
     * @description Obtiene la informacion de un almacen_producto
     */
    getProductoAlmacen = () => {
        axios.get('/almacen/productos/get', {
            params: {
                almacen_producto_id: this.props.almacen_producto_id
            }
        }).then((response) => {
            console.log("getProductoAlmacen", response.data.data);

            let producto = response.data.data

            this.formProductoInventario.current.setFieldsValue({
                producto_id: {
                    value: producto.inventario_producto_id.producto_id._id,
                    key: producto.inventario_producto_id.producto_id._id,
                    label: <>
                        <span>{producto.inventario_producto_id?.producto_id?.nombre}</span> 
                        <small>{producto.inventario_producto_id?.producto_id?.sku_manantial}</small> 
                    </>
                },
                cantidad: producto.cantidad,
                ubicacion: producto.ubicacion
            })
            
        }).catch((error) => {
            console.log("error", error);
            Modal.error({
                title: "No es posible cargar la información"
            })
        })
    }

    /**
     * @memberof ModalProductosAlmacen
     * @function onFinish
     *
     * @description Se ejecuta al hacer submit al formulario
     */
    onFinish = (values) => {

        //formatemos los valores
        values.producto_id = values.producto_id.value

        if(this.props.almacen_producto_id){
            this.updateProductoAlmacen(values)
        }else{
            this.addInventarioProducto(values)
        }
    }


    /**
     * @memberof ModalProductosAlmacen
     * @function addInventarioProducto
     *
     * @description Añade un nuevo producto al inventario
     */
    addInventarioProducto = (values) => {

        this.setState({loading: true})
        axios.post('/inventarios/productos/add',{
            ...values,
            almacen_id: this.props.almacen_id,
            inventario_id: this.props.inventario_id,
            proyecto_id: this.props.proyecto_id,//en caso de no haber inventario_id
        }).then(response => {
            message.success('Producto agregado con exito')
            this.props.onCancel()
        }).catch(error => {
            console.log("error", error);
            let msg = 'Error al agregar el producto'
            if(error?.response?.status === 403)
                msg = error.response.data.message
            message.error(msg)
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalProductoAlmacen
     * @function addProductoAlmacen
     *
     * @description Actualiza la informacion del producto del almacen
     */
    updateProductoAlmacen = (values) => {
        this.setState({loading: true})
        axios.post('/almacen/productos/update',{
            ...values,
            almacen_producto_id: this.props.almacen_producto_id,
            proyecto_id: this.props.proyecto_id//si no hay inventario_id
        }).then(response => {
            message.success('Producto actualizado')
            this.props.onCancel()
        }).catch(error => {
            let msg = 'Error al actualizar el producto'
            if(error.response.status === 403)
                msg = error.response.data.message
            message.error(msg)
        }).finally(()=>this.setState({loading: false}))
    }



    render() {

        const { producto_en_inventario } = this.state

        return [
            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title"> {this.props.almacen_producto_id ? 'Editar producto del' : 'Añadir producto al'} Almacén</Title>
            </div>,
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.formProductoInventario}
                    onFinish={this.onFinish}
                    initialValues={{
                        
                    }}

                >
                    <Row align="center" gutter={[16]}>

                        <Col xs={22}>
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                                rules={[
                                    { required: true, message: "Seleccione un proyecto" }
                                ]} 
                            >
                                <Select
                                    labelInValue
                                    showSearch
                                    className="form-select"
                                    placeholder="Seleccione un Producto"
                                    onSearch={(value)=>this.getProductos(value)}
                                    onSelect={(value)=>this.onSelectProducto(value)}
                                    filterOption={false}
                                    disabled={this.props.almacen_producto_id !== undefined}
                                >
                                    {this.state.productos.map((producto) =>  <Option key={producto._id} value={producto._id}>
                                        {console.log("producto.", producto)}
                                        <span>{producto.nombre  || producto.descripcion || producto.sku_fabricante}</span><br/> 
                                        <small>{producto.sku_manantial}</small>
                                    </Option>)}

                                </Select>    
                            </Form.Item>
                        </Col>

                        {!producto_en_inventario ? <Col span={11}>
                            <Form.Item
                                label="Inventario mínimo"
                                name="inv_minimo"
                                rules={[
                                    { required: true, message: "Ingrese el inventario mínimo" }
                                ]} 
                            >
                                <InputNumber min={1}/>
                            </Form.Item>
                        </Col> : null}

                        {!producto_en_inventario ?  <Col span={11}>
                            <Form.Item
                                label="Inventario máximo"
                                name="inv_maximo"
                                rules={[
                                    { required: true, message: "Ingrese el inventario máximo" }
                                ]} 
                            >
                                <InputNumber min={2}/>
                            </Form.Item>
                        </Col> : null}
                    </Row>

                    {/*<Row>
                        {!producto_en_inventario ? <Col xs={{span: 22, offset: 2}} md={{span: 10, offset: 1}}>
                            <Form.Item
                                label="Precio"
                                name="precio"
                                rules={[
                                    { required: true, message: "Ingrese el precio del producto" }
                                ]} 
                            >
                                <InputNumber min={1}/>
                            </Form.Item>
                        </Col> : null}

                    </Row>*/}

                     <Row align="center" gutter={[16]}>

                        <Col span={11}>
                            <Form.Item
                                label="Cantidad en Almacén"
                                name="cantidad"
                                rules={[
                                    { required: true, message: "Ingrese la centidad en el almacén" }
                                ]} 
                            >
                                <InputNumber min={1}/>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="Ubicación"
                                name="ubicacion"
                                rules={[
                                    { required: true, message: "Ingrese la ubicación" }
                                ]} 
                            >
                                <Input max={200}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}

export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalProductosAlmacen {...props} />
    </Modal>


}
