import React, { Component, createRef } from "react";
import { Button, Space, Divider, Col, DatePicker, Form, Input, InputNumber, List, message, Modal, Row, Select, Spin, Typography, Upload, } from 'antd';
import { UploadOutlined, MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import moment from 'moment'
import 'moment/locale/es';
//componentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { AreaSelect, RubroSelect, SubRubroSelect } from '../../../Widgets/Inputs/Selects';
import { IconArrowSelect, IconCloseModal } from '../../../Widgets/Iconos';

//css
import './../../../../Styles/finanzas.css'

const axios = require('axios').default;
moment.locale('es');
const { Option } = Select;
const { Title, } = Typography;
const { confirm } = Modal;
export default class ModalTran extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        },

    };

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false,
            edicion: false,
            dataRubros: [],
            dataSubrubros: [{ nombre: '--' }],
            dataProyectos: [],
            dataCuentas: [],
            cuentasRequired:false,


            conceptos: [],
            sub_conceptos: [],

            dataAreas: [],
            dataTipos: [{
                _id: 1,
                nombre: 'Ingreso'
            },
            {
                _id: 2,
                nombre: 'Egreso',
            },
            ],


            form: {},
            empresas: {
                data: [],
                limit: 20,
                page: 1,
                total: 0,
                pages: 0,
            },

            loading: false,

            update: false,
            titulo: "",


            updateChecker: false
        }
    }

    modalTransaccion = createRef();

    /**
     * @memberof ModalTran
     * @method componentDidMount
     * @description Se ejecuta al finalizar el render de la vista.
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadCuentas();
        this.loadProyectos();
        this.getEmpresas();
    }


    componentDidUpdate() {
        //Si se abre el modal y el editar esa desactivo, se activa para poder editar
        if (this.props.modalVisible && !this.state.updateChecker && !(this.props.registroId === null || this.props.registroId === undefined)) {
            this.state.updateChecker = true;
            this.state.registroId = this.props.registroId;
            if (this.props.registroId !== null) {
                this.state.edicion = true;
                this.getTransaccionInfo();

            }
        }
    }

    //Función que reinicializa los states y llama al método de cierre en componente padre
    hideModal = () => {
        //Reiniciar valores al cerrar modal
        this.state.updateChecker = false
        this.state.form = {}
        if (this.modalTransaccion && this.modalTransaccion.current) this.modalTransaccion.current.resetFields()
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
            edicion: false,
        })

        this.props.closeMethod();
    }
    /**
     *
     *
     * @memberof ModalTran
     * @method loadCuentas
     * @description Obtiene la informacion de las cuentas registradas en la base de datos
     *
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200,
                transacciones: true
            }
        }).then((cuentas) => {

           let cuentas_disponibles = cuentas.data.data.itemsList.length > 0 ? true : false;
            this.setState({
                dataCuentas: cuentas.data.data.itemsList,
                cuentasRequired: cuentas_disponibles
            })
        }).catch((error) => {
        })
    }
    /**
     *
     *
     * @memberof ModalTran
     * @method loadProyectos
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadProyectos = () => {
        axios.get('/proyectos', {
            params: {
                page: 1,
                limit: 200
            }
        }).then((proyectos) => {
            this.setState({
                dataProyectos: proyectos.data.data.itemsList
            })
        }).catch((error) => {
        })
    }



    /**
    *
    *
    * @memberof Transacciones
    * @method getTransacciones
    * @description Obtiene todas todas las transacciones
    * @param page página actual de la lista
    * @param limit cantidad de elementos por página
    * @param filters Filtros a aplicar a la página
    * @param sort Ordenar elementos
    * 
    */
    getConceptos = (proyecto_id) => {

        axios.get('/conceptos/list', {
            params: {
                proyecto_id,
                paginate: false
            }
        })
            .then(({ data }) => {

                this.setState({
                    conceptos: data.data,
                });
            })
            .catch(error => {
                console.log('error', error)
                message.error('No se pudieron cargar las conceptos')
            })
    }

    /**
     *
     * @memberof ModalTransacciones
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
    getEmpresas = (
        {
            page = this.state.empresas.page,
            limit = this.state.empresas.limit,
            search = this.state.empresas.search,
        } = this.state.empresas
    ) => {

        axios.get("/empresas", {
            params: {
                page,
                limit,
                search

            }
        })
            .then(({ data }) => {
                this.setState({ empresas: { ...data, search } });
            })
            .catch((res) => {
                message.error(res.response.data.message);
            })

    };

    /**
   * @memberof Almacenes
   * @method getSubConceptos
   * @description  Obtiene la informacion del proyecto seleccionado
   **/
    getSubConceptos = (concepto_id, proyecto_id) => {

        axios.get('/sub-conceptos/list', {
            params: {
                proyecto_id,
                concepto_id,
            }
        }).then(res => {

            this.setState({
                sub_conceptos: res.data.data
            })

        }).catch(error => {
            message.error('Error al obtener los Conceptos')
        })
    }
    /**
     * @memberof ModalTran
     * @method getTransaccionInfo
     * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
     * con informacion obtenida.
     */
    getTransaccionInfo = () => {

        this.setState({ spinning: true })

        axios.post('/transacciones/get', {
            id: this.props.registroId,
        }).then((transaccion) => {
            let data = transaccion.data.data;

            console.log('data transacciones', data)

            this.setState({
                edicion: true,
                datosConsultados: true,
                det_concepto: data.concepto,
                tipo_cliente: data.tipo_cliente,
                spinning: false,
                conceptos: data.conceptos.map((concepto) => concepto.concepto_id),
                sub_conceptos: data.conceptos.map((concepto) => concepto.sub_concepto_id),
                area_id: data.area_id._id,
                rubro_id: data.rubro_id._id,
            })

            let conceptos = []

            for (const concepto of data.conceptos) {
                if (concepto?.concepto_id?._id)
                    conceptos.push({
                        concepto_id: concepto?.concepto_id?._id,
                        sub_concepto_id: concepto?.sub_concepto_id?._id,
                        proyecto_id: concepto?.proyecto_id?._id,
                        monto: concepto?.monto

                    })

            }

            if (this.modalTransaccion){


                this.modalTransaccion.current.setFieldsValue({
                    concepto: data.concepto,
                    descripcion: data.descripcion,
                    cuenta: data.cuenta_id?._id,
                    monto: data.monto,
                    proyecto: data.proyecto_id?._id,
                    empresa_id: data.empresa_id?._id,
                    area_id: data.area_id._id,
                    rubro: data.rubro_id._id,
                    subrubro: data.sub_rubro_id._id,
                    tipo: data.tipo,
                    conceptos,
                    comprobantes: data.comprobantes?.map((comprobante, index) => {
                        return {
                            uid: index,
                            name: comprobante,
                            response: {
                                filename: comprobante

                            },
                            status: 'done',
                            url: axios.defaults.baseURL + "/voucher/" + comprobante
                        }
                    }),
                    fecha: moment(data.fecha, 'DD-MM-YYYY')
                })
            }else
                this.state.form = {
                    concepto: data.concepto,
                    descripcion: data.descripcion,
                    cuenta: data.cuenta_id?._id,
                    monto: data.monto,
                    proyecto: data.proyecto_id?._id,
                    empresa_id: data.empresa_id?._id,
                    area_id: data.area_id._id,
                    rubro: data.rubro_id.nombre,
                    subrubro: data.sub_rubro_id.nombre,
                    tipo: data.tipo,
                    comprobantes: data.comprobantes?.map((comprobante, index) => {
                        return {
                            uid: index,
                            name: comprobante,
                            status: 'done',
                            url: axios.defaults.baseURL + "/voucher/" + comprobante
                        }
                    }),
                    fecha: moment(data.fecha, 'DD-MM-YYYY')
                }
        })



    }
    /**
     *
     * @memberof ModalTransaccion
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !== undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalCuenta
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/voucher/delete", {
            filename: image
        })
            .then(res => {

            })
            .catch(res => {

            })
    };

    handleSubmit = values => {
        console.log("values", values);
        this.setState({ spinning: true })
        values.comprobantes = values.comprobantes?.map(c => c.name)

        if (Array.isArray(values.conceptos) || values.conceptos !== undefined) {
            let total = values.conceptos.reduce((accumulator, concepto) => {
                accumulator += concepto.monto
                return accumulator
            }, 0)
            if (total !== values.monto) {
                return confirm({
                    title: 'Montos no coinciden',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Los montos de los CONCEPTOS no coinciden con el monto de la TRANSACCIÓN. ¿Continuar de todos modos?',
                    onOk: () => {
                        if (this.props.registroId && this.props.registroId !== null && this.props.registroId !== undefined) {
                            this.update(values)
                        } else {
                            this.add(values)
                        }
                    },
                    onCancel() {

                    },
                });
            }
            else {
                if (this.props.registroId && this.props.registroId !== null && this.props.registroId !== undefined) {
                    this.update(values)
                } else {
                    this.add(values)
                }
            }
        }
        else {
            if (this.props.registroId && this.props.registroId !== null && this.props.registroId !== undefined) {
                this.update(values)
            } else {
                this.add(values)
            }
        }

    }

    add = (values) => {
        axios.post('/transacciones/add', {
            ...values,
            concepto: values.concepto,
            cuenta_id: values.cuenta,
            descripcion: values.descripcion,
            monto: values.monto,
            proyecto_id: values.proyecto,
            rubro_id: values.rubro,
            sub_rubro_id: values.subrubro,
            area_id: values.area_id,
            tipo: values.tipo,
            comprobantes: (Array.isArray(values.comprobantes)) ? values.comprobantes.map(comprobante =>
                comprobante.response.filename
            ) : [],
            actualiza_saldo: true,
            fecha: values.fecha,
            conceptos: values.conceptos
        })
            .then((res) => {
                this.setState({ updateChecker: false })
                message.success("Transaccion Registrada!");
                this.props.accept();
                this.setState({ spinning: false })
            }).catch((error) => {

                Modal.warning({
                    title: 'Error',
                    content:
                        <List
                            size="small"
                            bordered
                            dataSource="No se ha podido registrar la transacción"
                        />
                });
            });
    }

    update = (values) => {
        axios.post('/transacciones/update', {
            id: this.props.registroId,
            concepto: values.concepto,
            cuenta_id: values.cuenta,
            descripcion: values.descripcion,
            monto: values.monto,
            comprobantes: values.comprobantes,
            conceptos: values.conceptos,
            empresa_id: values.empresa_id,
            area_id: values.area_id,
            rubro_id: values.rubro,
            sub_rubro_id: values.subrubro,

        })
            .then(() => {
                message.success('Transaccion Actualizada')
                this.props.accept()
                this.state.updateChecker = false
                this.setState({ spinning: false })


            })
            .catch(() => {
                message.error('Ocurrio un error, no se edito la transaccion.')
            })
    }

    render() {
        const { modalVisible, accionModal, registroId } = this.props;
        const { modalTransaccion, normFile } = this;

        var titulo = (registroId) ? "Editar Transacción" : "Nueva Transacción";
        const gutter = [15, 15]

        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className={(accionModal === "detalle") ? "modal-form modal-det modal-transaccion" : "modal-form modal-transaccion"}
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{(accionModal === "detalle") ? "Detalle " + this.state.det_concepto : titulo}</Title>
                </div>
                <Spin spinning={this.state.spinning}>
                    <Form
                        layout="vertical"
                        ref={modalTransaccion}
                        initialValues={this.state.form}
                        className="frm-transacciones"
                        name="formulario-transacciones"
                        onFinish={this.handleSubmit}
                    >
                        <Row gutter={gutter}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Concepto"
                                    name="concepto"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el concepto"
                                    }]}
                                >
                                    <Input placeholder="Concepto"></Input>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Fecha"
                                    name="fecha"
                                    initialValue={(!this.state.edicion) ? moment(new Date, 'DD/MM/YYYY') : ''}
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la fecha"
                                    }]}
                                >
                                    <DatePicker disabled={this.state.edicion}
                                        defaultValue={(!this.state.edicion) ? moment(new Date, 'DD/MM/YYYY') : ''} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={gutter}>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                >
                                    <Input.TextArea placeholder="Descripción"></Input.TextArea>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={gutter}>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Proyecto"
                                    name="proyecto"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el proyecto"
                                    }]}
                                >
                                    <Select placeholder="Seleccione proyecto" className="form-select"  disabled={this.state.edicion}>
                                        {this.state.dataProyectos.map((proyecto) =>  <Option value={proyecto._id}>
                                            <CustomAvatar
                                                image={proyecto.logo}
                                                name={proyecto.nombre}
                                                color={proyecto.color}
                                                size="small"
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            />
                                            {proyecto.nombre}
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                                
                            </Col>
                        </Row>

                        <Row gutter={gutter}>
                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label="Área"
                                    name="area_id"
                                    listType="text"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el Área a que corresponde la transaccciÓn."
                                    }]}
                                >
                                    <AreaSelect onSelect={(area_id)=>{
                                        this.setState({area_id, rubro_id: null})
                                        modalTransaccion.current.setFieldsValue({rubro: null, subrubro: null})
                                    }}/>
                                </Form.Item>
                               
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label="Rubro"
                                    name="rubro"
                                    listType="text"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el rubro"
                                    }]}
                                >
                                    <RubroSelect area_id={this.state.area_id} onSelect={(rubro_id)=>{
                                        this.setState({rubro_id})
                                        modalTransaccion.current.setFieldsValue({subrubro: null})
                                    }}/>
                                </Form.Item>
                                
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item
                                    label="Subrubro"
                                    name="subrubro"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el subrubro"
                                    }]}
                                >
                                    <SubRubroSelect rubro_id={this.state.rubro_id} />
                                </Form.Item>
                                
                            </Col>
                        </Row>

                        <Row gutter={gutter}>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Cuenta"
                                    name="cuenta"

                                    rules={
                                        (this.state.tipo_cliente != "Especie")?[
                                            {
                                                required: true,
                                                message: "Ingrese la cuenta"
                                            }
                                        ]:[]
                                    }
                                >
                                    <Select placeholder="Seleccione cuenta" className="form-select"  disabled={(this.state.edicion === true && this.props.tipo_user === 3)}>
                                        {this.state.dataCuentas.map(function ({ _id, nombre, logo, color }, index) {
                                            return <Option  value={_id}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    // color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                
                            </Col>
                        </Row>

                        <Row gutter={gutter}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Tipo"
                                    name="tipo"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el tipo"
                                    }]}
                                >
                                    <Select placeholder="Seleccione tipo"  className="form-select" disabled={this.state.edicion}>
                                        {this.state.dataTipos.map(function (tipo, index) {
                                            return <Option value={tipo._id}>{tipo.nombre}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Monto"
                                    name="monto"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese monto"
                                    }]}
                                >
                                    <InputNumber min={1} defaultValue={0} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Empresa"
                                    name="empresa_id"
                                >
                                    <Select
                                        placeholder="Seleccione la empresa"
                                        onSearch={(value) => this.getEmpresas({ search: value })}
                                        showSearch
                                        filterOption={false}
                                        showArrow={false}
                                        className="form-select"
                                    >
                                        {
                                            this.state.empresas.data.map(empresa => {
                                                return <Option value={empresa._id}>
                                                    <Space>
                                                        <CustomAvatar
                                                            size="small"
                                                            image={empresa.logo}
                                                            name={empresa.alias}
                                                            color={empresa.color}
                                                        /> {empresa.alias}
                                                    </Space>
                                                </Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                                
                            </Col>
                        </Row>


                        {/* Budgeting */}
                        {(this.props.budgeting == true || this.props.budgeting !== undefined) ?
                            <>
                                <Divider className="modal-divider">Budgeting</Divider>
                                <Form.List name="conceptos">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space
                                                    key={key}
                                                    style={{
                                                        display: 'flex',
                                                        marginBottom: 8,
                                                    }}
                                                    align="baseline"
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'proyecto_id']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Seleccione el proyecto',
                                                            },
                                                        ]}
                                                    >
                                                        <Select style={{ width: 140, }} onChange={this.getConceptos}  >
                                                            {this.state.dataProyectos.map((item) => (
                                                                <Option key={item} value={item._id}>
                                                                    {item.nombre}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'concepto_id']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'seleccione el concepto',
                                                            },
                                                        ]}
                                                    >
                                                        <Select style={{ width: 140, }} onChange={value => this.getSubConceptos(value, modalTransaccion.current.getFieldValue([name, 'proyecto_id']))} >
                                                            {this.state.conceptos?.itemsList?.map((item) => (

                                                                (item) ? <Option key={item} value={item?._id}>
                                                                    {item.nombre}
                                                                </Option> : null
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'sub_concepto_id']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'seleccione el sub concepto',
                                                            },
                                                        ]}
                                                    >
                                                        <Select style={{ width: 140, }}   >
                                                            {this.state.sub_conceptos?.itemsList?.map((item) => (
                                                                (item) ? <Option key={item} value={item?._id}>
                                                                    {item.nombre}
                                                                </Option> : null
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'monto']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Ingrese el monto',
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber min={1} defaultValue={0} />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    Add field
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </>
                            : null}
                        <Row gutter={gutter}>
                            <Col span={24} className="colDocsComprobantes">
                                <Form.Item
                                    label="Comprobantes"
                                    name="comprobantes"
                                    getValueFromEvent={normFile}
                                    valuePropName="fileList"
                                    className="content-uploader"
                                >
                                    <Upload
                                        className="avatar-uploader"
                                        action={axios.defaults.baseURL + "/voucher/add"}
                                        multiple={true}
                                    >
                                        <Button>
                                            <UploadOutlined /> Upload
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={gutter} align="center">
                            <Col span={24}>
                                <Form.Item style={{ textAlign: "center" }}>
                                    <Button htmlType="submit" type="primary" className="btn-azul"
                                        disabled={this.state.loading}>
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}
