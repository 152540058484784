import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Select, Avatar, InputNumber } from 'antd';

//componentes
import { IconCloseModal } from '../../../../Widgets/Iconos';


const axios = require('axios').default;


const { Title, Text } = Typography;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class ModalProductoAlmacenGeneral
 * @extends {Component}
 */
class ModalProductoAlmacenGeneral extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            productos: [],
            almacenes: []
        }
    }

    formProductoAlmacen = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProductos()
        this.getAlmacenes()

        if(this.props.almacen_producto_id){
            this.getProductoAlmacen()
        }
    }


    /**
     * @memberof ModalProductoAlmacenGeneral
     * @function getProductos
     *
     * @description Obtener lista de productos disponibles del inventario y que no esten dados de alta en el Almacen
     */
    getProductos = (search) => {
        axios.get('/inventarios/productos', {
            params: {
                inventario_id: this.props.inventario_id,
                almacen_id: this.props.almacen_id,
                proyecto_id: this.props.proyecto_id, //si no hay inventario_id
                search,
            }
        }).then((response) => {
            this.setState({productos: response.data.data.data})
            
        }).catch((error) => {
            console.log("error", error);
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }

    /**
     * @memberof ModalProductoAlmacenGeneral
     * @function getAlmacenes
     *
     * @description Obtener lista de productos disponibles del inventario y que no esten dados de alta en el Almacen
     */
    getAlmacenes = (search) => {
        axios.get('/proyectos/almacenes', {
            params: {
                proyecto_id: this.props.proyecto_id,
                inventario_producto_id: this.props.inventario_producto_id,
                paginate: false,
                search,
            }
        }).then((response) => {
        	console.log("response", response.data.data);
            this.setState({almacenes: response.data.data})
            
        }).catch((error) => {
            console.log("error", error);
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }

    /**
     * @memberof ModalProductoAlmacenGeneral
     * @function getProductoAlmacen
     *
     * @description Obtiene la informacion de un almacen_producto
     */
    getProductoAlmacen = () => {
        axios.get('/almacen/productos/get', {
            params: {
                almacen_producto_id: this.props.almacen_producto_id
            }
        }).then((response) => {
            console.log("response", response.data.data);

            let producto = response.data.data
            this.formProductoAlmacen.current.setFieldsValue({
                inventario_producto_id: {
                    value: producto.inventario_producto_id._id,
                    key: producto.inventario_producto_id._id,
                    label: <Text>{producto.inventario_producto_id?.producto_id?.nombre} <small>{producto.inventario_producto_id?.producto_opcion_id?.np_manantial}</small> </Text>
                },
                cantidad: producto.cantidad,
                ubicacion: producto.ubicacion
            })
            
        }).catch((error) => {
            console.log("error", error);
            Modal.error({
                title: "No es posible cargar la información"
            })
        })
    }


    /**
     * @memberof ModalProductoAlmacenGeneral
     * @function onFinish
     *
     * @description Se ejecuta al hacer submit al formualrio
     */
    onFinish = (values) => {
        if(this.props.almacen_producto_id){
            this.updateProductoAlmacen(values)
        }else{
            this.addProductoAlmacen(values)
        }

    }

    /**
     * @memberof ModalProductoAlmacenGeneral
     * @function addProductoAlmacen
     *
     * @description Se añade le producto al almacen
     */
    addProductoAlmacen = (values) => {
        this.setState({loading: true})
        axios.post('/almacen/productos/add',{
            ...values,
            inventario_producto_id: this.props.inventario_producto_id,
            proyecto_id: this.props.proyecto_id//si no hay inventario_id
        }).then(response => {
            message.success('Producto agregado al almacén')
            this.props.onCancel()
        }).catch(error => {
            let msg = 'Error al agregar el producto'
            if(error?.response?.status === 403)
                msg = error.response?.data.message
            message.error(msg)
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalProductoAlmacenGeneral
     * @function addProductoAlmacen
     *
     * @description Se añade le producto al almacen
     */
    updateProductoAlmacen = (values) => {
        this.setState({loading: true})
        axios.post('/almacen/productos/update',{
            ...values,
            almacen_producto_id: this.props.almacen_producto_id,
            proyecto_id: this.props.proyecto_id//si no hay inventario_id
        }).then(response => {
            message.success('Producto actualizado')
            this.props.onCancel()
        }).catch(error => {
            let msg = 'Error al actualizar el producto'
            if(error?.response?.status === 403)
                msg = error.response?.data?.message
            message.error(msg)
        }).finally(()=>this.setState({loading: false}))
    }


    render() {

        return [
            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title"> {this.props.almacen_producto_id ? 'Editar producto del' : 'Añadir producto al'} Almacén</Title>
            </div>,
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.formProductoAlmacen}
                    onFinish={this.onFinish}

                >
                    <Row align="center" gutter={[16]}>

                    	{this.props.almacen_producto_id ? null : <Col xs={22}>
                            <Form.Item
                                label="Almacén"
                                name="almacen_id"
                                rules={[
                                    { required: true, message: "Seleccione un proyecto" }
                                ]} 
                            >
                                <Select
                                    showSearch
                                    className="form-select"
                                    placeholder="Seleccione un Producto"
                                    onSearch={(value)=>this.getAlmacenes(value)}
                                    filterOption={false}
                                >
                                    {this.state.almacenes.map((almacen) =>  <Option key={almacen._id} value={almacen._id}>
                                        <Text>{almacen.nombre}</Text>
                                    </Option>)}

                                </Select>    
                            </Form.Item>
                        </Col>}

                        <Col span={11}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[
                                    { required: true, message: "Ingrese la centidad en el almacén" }
                                ]} 
                            >
                                <InputNumber min={1}/>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="Ubicación"
                                name="ubicacion"
                                rules={[
                                    { required: true, message: "Ingrese la ubicación" }
                                ]} 
                            >
                                <Input max={200}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalProductoAlmacenGeneral {...props} />
    </Modal>


}
