import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, Spin, message } from 'antd';
//componentes
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;

//Modal para agregar un material en MaterialAdmin
export default class ModalMaterial extends Component {

    formMaterial = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            materiales: [],
            loading: false,
            material: {},
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadMateriales()
    }


    /**
     * @memberof ModalMaterial
     *
     * @method   componentDidUpdate
     * @description  Recibe el inmueble seleccionado y obtiene la lista de usuarios ( empleados )
     *
     **/
    componentDidUpdate(prevProps) {

        //Si se va a editar un materal, busca los datos del material
        if (this.props.material_id !== prevProps.material_id && this.props.material_id !== undefined) {
            this.getMaterial(this.props.material_id)
            this.loadMateriales();
        }

        if (this.props.modalVisible !== prevProps.modalVisible) {
            this.loadMateriales();
        }
    }

    /**
     * @memberof ModalMaterial
     *
     * @method   hideModal
     * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
     *
     **/
    hideModal = () => {
        this.props.closeMethod();
        this.setState({ material: {} })
    }

    /**
     * @memberof ModalMaterial
     *
     * @method   onFinish
     * @description  Metodo que se ejecuta al guardar el formulario
     *
     **/
    onFinish = (values) => {
        this.setState({ loading: true })
        if (this.props.modalTitle === 'Editar') {
            this.updateMaterial(values);
        } else {
            this.addMateriales(values);
        }


    }

    /**
     * @memberof ModalMaterial
     *
     * @method   loadMateriales
     * @description  Obtiene la lista de materiales
     **/
    loadMateriales = (search) => {
        axios.get('/materiales/list', {
            params: {
                pagination: 'false',
                actividad_id: this.props.actividad_id,
                search,
            }
        })
            .then(res => {
                if (Array.isArray(res.data.data))
                    this.setState({ materiales: res.data.data })
            })
            .catch(res => {
                console.log("error", res);
            });
    }

    /**
     * @memberof ModalMaterial
     *
     * @method
     * @description  añade materiales a la actividad
     **/
    addMateriales = (values) => {
        var cantidad = parseFloat(values.cantidad);
        if (cantidad > 0) {
            axios.post('/manager/proyectos/inmuebles/actividades/material/add', {
                manager_proyecto_id: this.props.manager_proyecto_id,
                inmueble_id: this.props.inmueble_id,
                actividad_id: this.props.actividad_id,
                material_id: values.material_id,
                cantidad: values.cantidad,
            })
                .then(res => {

                    if (Array.isArray(res.data.data))
                        this.setState({ materiales: res.data.data })

                    message.success('¡Material añadido!');

                    this.setState({ loading: false })
                    this.props.closeMethod();
                })
                .catch(res => {
                    console.log("error", res);
                    message.error('Error, no se pudo agregar material');

                });
        } else {
            message.error('Ingresa una cantidad en toneladas');
        }
    }


    /**
     * @memberof ModalMaterial
     *
     * @method
     * @description
     **/
    updateMaterial = (values) => {

        var cantidad = parseFloat(values.cantidad);
        if (cantidad > 0) {

            axios.post('/project-manager/proyectos/inmuebles/actividades/material/update', {
                actividad_id: this.props.actividad_id,
                material_id: values.material_id,
                cantidad: values.cantidad,
                prevMaterial_id: this.state.material._id
            })
                .then(res => {
                    message.success('¡Material actualizado!');
                    this.props.closeMethod();
                    this.setState({ loading: false })
                })
                .catch(res => {
                    message.error('¡Material NO actualizado!');
                    console.log("error", res);
                });
        } else {
            message.error('Ingresa una cantidad en toneladas');
        }
    }


    /**
     * @memberof ModalMaterial
     *
     * @method
     * @description
     **/
    getMaterial = (material_id) => {
        this.setState({ loading: true })
        axios.get('/project-manager/proyectos/inmuebles/actividades/material/get', {
            params: {
                actividad_id: this.props.actividad_id,
                material_id: material_id,
            }
        })
            .then(res => {
                this.setState({ loading: false, material: res.data.data.material })
                this.formMaterial.current.setFieldsValue({
                    material_id: res.data.data.material._id,
                    cantidad: res.data.data.cantidad,
                })
            })
            .catch(res => {
                this.setState({ loading: false })
                console.log("error", res);
            });
    }

    
    /**
     * @memberof ModalTajo
     *
     * @method   selectOptions
     * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
     * ademas de agrear al arreglo el elemento a editar
     *
     **/
    selectOptions = (array, addElemnt) => {
        let arr = [];
        array.forEach(function ({ _id, nombre }, index) {
            arr.push(<Option value={_id}> {nombre}</Option>)
        })

        // let arr = [];
        // array.forEach(function ({_id, nombre}, index) {
        //     arr.push(<Option value={_id}> {nombre}</Option>)
        // })
        //
        // if (!arr.some(element => element._id === addElemnt._id)) {
        //     arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
        // }
        // return arr
    }


    render() {
        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                <Spin spinning={this.state.loading}>
                    <div className="modal-header">
                        <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                            <IconCloseModal />
                        </Button>
                        <Title level={3} className="modal-title">{this.props.modalTitle} Material</Title>
                    </div>
                    <Form
                        layout="vertical"
                        className="frm-cuentas"
                        name="formulario-entrega"
                        onFinish={this.onFinish}
                        ref={this.formMaterial}
                    >
                        <Row align="center">
                            <Col span={20} offset={2}>
                                <Form.Item
                                    label="Material"
                                    name="material_id" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese un material"
                                    }]}
                                >
                                    <Select
                                        clearIcon={<IconArrowSelect />}
                                        onSearch={search => this.loadMateriales(search)}
                                        showSearch
                                        filterOption={false}
                                    >
                                        {this.state.materiales.map(({ _id, nombre }) => <Option value={_id}> {nombre}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col span={20} offset={2}>
                                <Form.Item
                                    label="Cantidad en Toneladas"
                                    name="cantidad" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese una cantidad"
                                    }]}
                                >
                                    <InputNumber min={0} defaultValue={0} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-azul">
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
