import React, { Component } from 'react';
import { Button, Checkbox, Col, DatePicker, Dropdown, Form, Menu, Row, Spin, Statistic, Table } from 'antd'
import 'react-multi-carousel/lib/styles.css';
import axios from 'axios';
//componentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { IconDetails, IconArrow } from '../../Widgets/Iconos';
import Reporte from './ReporteHistorialEmpleados'
import Logged from "../../../Hooks/Logged";
//modales
import ModalTransaccion from './../Modales/Finanzas/ModalTransaccion';
import ModalTransacciónDetalle from './../Modales/Finanzas/ModalTransacciónDetalle';
import ModalTraspasoDetalle from './../Modales/Finanzas/ModalTraspasoDetalle';
//css
import './../../../Styles/finanzas.css'

const moment = require('moment');

function Arrow(props) {
    return <svg {...props} width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.98817 0.00230214C5.10632 0.00230214 6.22447 0.00528392 7.34261 3.03092e-05C7.60672 -0.0012476 7.84092 0.0366636 7.95916 0.347053C8.08234 0.670505 7.90441 0.87 7.72407 1.07092C6.64496 2.27328 5.56699 3.47693 4.48787 4.6793C4.10679 5.10399 3.89515 5.10598 3.52244 4.69066C2.43623 3.47991 1.35129 2.26775 0.264064 1.05814C0.0803047 0.853814 -0.0783622 0.648497 0.0419053 0.32902C0.16268 0.00783972 0.40727 3.03092e-05 0.664787 0.000740257C1.77254 0.00414801 2.88029 0.00230214 3.98817 0.00230214Z"
            fill="white" />
    </svg>;
}

function Download(props) {
    return <svg {...props} width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.86347 11.1094C8.86347 8.56789 8.86347 6.3264 8.86347 4.0849C8.86347 3.03965 8.82048 1.99244 8.88887 0.953078C8.91037 0.62558 9.22887 0.317693 9.4106 0C9.60014 0.315731 9.94795 0.629502 9.95381 0.947195C9.99485 3.94959 9.97922 6.95198 9.97922 9.95633C9.97922 10.2387 9.97922 10.5211 9.97922 10.8035C10.0632 10.8604 10.1473 10.9172 10.2332 10.9741C11.3216 9.8367 12.3963 8.6836 13.5101 7.56971C13.8345 7.24614 14.1784 6.49113 14.7373 7.04022C15.3293 7.6207 14.5927 8.02076 14.2429 8.37963C12.8731 9.77983 11.4604 11.131 10.1004 12.5332C9.59623 13.0528 9.23864 13.047 8.73646 12.5273C7.28852 11.029 5.77806 9.58961 4.34186 8.08155C4.12301 7.85211 4.16209 7.37753 4.08002 7.01473C4.41025 7.10494 4.83818 7.09513 5.05703 7.30301C6.28025 8.46592 7.45071 9.6857 8.86347 11.1094Z"
            fill="white" />
        <path
            d="M17.2996 15.9283C17.2996 14.7264 17.2607 13.586 17.3248 12.4493C17.3423 12.1442 17.6804 11.8558 17.8709 11.56C18.0322 11.8502 18.3159 12.1349 18.3295 12.4307C18.3839 13.5432 18.3489 14.6595 18.3489 15.7757C18.3489 16.5757 17.9136 16.9887 17.0975 16.9906C11.8254 17.0017 6.5513 16.9999 1.27724 16.9999C0.443575 16.9999 0.0277133 16.5608 0.0160537 15.7869C-0.00143584 14.6092 -0.0150388 13.4316 0.0335432 12.2558C0.0432595 12.0195 0.328921 11.7944 0.48827 11.5637C0.680655 11.7944 1.02656 12.0195 1.03822 12.2577C1.09457 13.4558 1.06348 14.6576 1.06348 15.9283C6.48328 15.9283 11.8079 15.9283 17.2996 15.9283Z"
            fill="white" />
    </svg>

}


const { RangePicker } = DatePicker;


export default class HistorialEmpleados extends Component {
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            cards: [],
            dataTran: [],
            saldoTotal: 0,
            cuentas_incluidas: 0,
            loading: false,


            accionModal: 'crear',
            registroId: '',
            infoMovimientos: [],
            modalVisibleTran: false,
            modalVisibleTranDetalle: false,
            modalVisibleTraspaso: false,
            modalVisibleTraspasoDetalle: false,
            datosConsultados: false,
            numConsulta: 0,
            dataRpt: {
                fecha: '',
                espacio: '',
                dataInfo: [],
                author: '{{Autor}}',
                totalTran: 0,
                sinFiltros: false,

                fecha_inicio: '',
                fecha_fin: '',
                area: '',
                rubro: '',
                subrubro: '',
                proyectos: [],
                cuentas: [],
                numConsulta: 0
            },


            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,


            start: undefined,
            end: undefined,
            data: [],


            projects: [],
            areas: [],
            rubros: [],
            subrubros: [],
            tipos: [],
            cuentas: [],
            usuarios: [],

            projectsSelected: [],
            cuentasSelected: [],
            usuariosSelected: [],
            selectedSubRubros: [],



            dropdowns: {
                cuentas: false,
                projects: false,
                areas: false,
                rubros: false,
                subrubros: false,
                tipo: false,
                usuarios: false
            },


            area: null,
            rubro: null,
            subrubro: null,
            cuenta: null,
            tipo: null,
            usuario: null,


            active_reporte: false,

            filtroSearch: "",
            searching: true,

        }
    }

    modalTransaccion = React.createRef();


    /**
     *
     *
     * @memberof HistorialEmpleados
     * 
     * @method componentDidMount
     * @description Trae la informacion de las transacciones de los empleados y los datos del buscador
     */
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.receivedData();
        this.getCuentas();
        this.getProjects();
        this.getAreas();
        this.getUsers();
    }



    /**
     *
     *
     * @memberof HistorialEmpleados
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista cuando se buscan registros en la barra de busqueda de texto
     */
    async componentDidUpdate() {
        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching === true) {
            this.state.searching = false;
            this.receivedData();
        }


    }

    /**
  * @methodOf Transacciones
  * @function receivedData
  *
  * @description Obtiene las transacciones con base a los filtros indicados.
  *
  * @param page
  * @description Pagina Actual
  *
  * @param quantity
  * @description Limite de elementos
  *
  * @param sort
  * @description Generado por la tabla de ANTD, obtenemos el campo que se actualizará.
  *
  * @param projects
  * @description Arreglo de proyectos a filtrar.
  *
  * @param area
  * @description ID del area a filtrar
  *
  * @param rubro
  * @description ID del rubro a filtrar
  *
  * @param subrubro
  * @description ID del subrubro a filtrar
  *
  * @param cuentas
  * @description Arreglo de cuentas a filtrar.
  *
  * @param tipo
  * @description Tipo de transaccion a filtrar
  *
  * @param end
  * @description Fecha final
  *
  * @param end
  * @description Fecha de Inicio
  * */
    receivedData = (
        page = this.state.currentPage,
        quantity = this.state.perPage,
        filter = {},
        sort = { "order": "descend", "field": "fecha" },
        projects = this.state.projectsSelected,
        area = this.state.area,
        rubro = this.state.rubro,
        subrubros = this.state.selectedSubRubros,
        cuentas = this.state.cuentasSelected,
        usuario = this.state.usuario,
        end = this.state.end,
        start = this.state.start
    ) => {



        this.receivedConsulta()

        this.setState({ loading: true });
        var search = this.props.search;
        axios.get('/transacciones/list', {
            params: {
                page: page,
                limit: quantity,
                filter,
                search: search,
                sortfield: sort,
                projects,
                cuentas,
                area,
                rubro,
                subrubros,
                end,
                start,
                usuario,
                empleados: 1
            }
        })
            .then(res => {
                const data = res.data.data.itemsList
                this.setState({
                    dataTran: data,
                    currentPage: page,
                    itemCount: res.data.data.itemCount,
                    perPage: quantity,
                    pageCount: res.data.data.pageCount,
                    filtroSearch: search,

                })
                this.state.searching = true;
                // this.loadCuentas();
            })
            .catch(res => {
                console.log("error", res);
            })
            .finally(f => {
                this.setState({ loading: false })
            });
    };

    modalCrearTransaccion = () => {

        this.setState({
            accionModal: 'crear',
            modalVisibleTran: true,
        })
    }

    modalEditarTransaccion = (item) => {
        const reg_id = item.currentTarget.id;
        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisibleTran: true
        })
    };

    modalMultipleTransaccion = () => {
        this.setState({
            accionModal: 'multiple',
            modalVisibleTran: true
        })
    };

    modalDetalleTransaccion = (item) => {
        const reg_id = item.currentTarget.id;
        this.setState({
            registroId: reg_id,
            modalVisibleTranDetalle: true
        })
    };


    hideModal = () => {
        this.setState({
            registroId: '',
            modalVisibleTran: false,
            modalVisibleTraspaso: false,
            modalVisibleTraspasoDetalle: false,
            modalVisibleTranDetalle:false
        })
    }



    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200
            }
        }).then((cuentas) => {


            let cuentas_total = cuentas.data.data.itemsList.length;
            let saldo_total = 0;
            cuentas.data.data.itemsList.map((registro, undex) => {
                saldo_total += parseFloat(registro.saldo);

            })

            this.setState({
                cards: cuentas.data.data.itemsList,
                saldoTotal: saldo_total,
                cuentas_incluidas: cuentas_total,
            })
        }).catch((error) => {
        })
    };

    getUsers = () => {
        axios.get('/usuarios', {
            params: {
                paginate: false
            }
        }).then((proyectos) => {
            this.setState({
                usuarios: proyectos.data.data
            })
        }).catch((error) => {
        })


    }


    /**
     * @methodOf  Transacciones
     *
     * @function getProjects
     * @description Obtenemos los proyectos y los ponemos en el dropdown
     *
     * */
    getProjects = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({
                projects: proyectos.data.data
            })
        }).catch((error) => {
        })
    };

    /**
     * @methodOf  Transacciones
     *
     * @function getAreas
     * @description Obtenemos las areas y los ponemos en el dropdown
     *
     * */
    getAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({ areas: response.data.data })
        })
    }

    /**
     * @methodOf  Transacciones
     *
     * @function getCuentas
     * @description Obtenemos las areas y los ponemos en el dropdown
     *
     * */
    getCuentas = () => {

        axios.get('/cuentas/list', {
            params: {
                pagination: false
            }
        }).then((response) => {
            var dataCuentas = [];

            if (response.data.data.length > 0) {
                dataCuentas = response.data.data;
            }
            this.setState({ cuentas: dataCuentas })

        })
    }

    onDatesChange = (values) => {
        try {
            this.state.start = values[0].format('YYYY-MM-DD')
            this.state.end = values[1].format('YYYY-MM-DD')
        } catch (e) {
            this.state.start = null;
            this.state.end = null;

        }
        this.setState({ update: !this.state.update })
        this.receivedData();

    };

    /**
     * @methodOf  Transacciones
     *
     * @function onProjectsChange
     * @description Se actualiza el arreglo de proyectos seleccionados jutno con los arreglos IDs
     *
     * @param _id
     * @description El ID del proyeccto a agregar al arreglo de proyectos seleccionados
     *
     * @param status
     * @description True, si se va a agregar, ffalse si no
     * */
    onProjectsChange = (_id, status) => {

        let { projectsSelected } = this.state;
        let filter = projectsSelected.findIndex(item => item.toString() === _id.toString())

        if (status) {
            if (filter === -1) projectsSelected.push(_id)
        } else {
            if (filter !==-1) projectsSelected.splice(filter, 1)
        }
        this.setState({ projectsSelected })
        this.receivedData();
    };

    /**
     * @methodOf  Transacciones
     *
     * @function onCuentasChange
     * @description Se actualiza el arreglo de cuentas seleccionadass jutno con los arreglos IDs
     *
     * @param _id
     * @description El ID de la cuenta a agregar al arreglo de proyectos seleccionados
     *
     * @param status
     * @description True, si se va a agregar, ffalse si no
     * */
    onCuentasChange = async (_id, status) => {

        let { cuentasSelected } = this.state;
        let filter = cuentasSelected.findIndex(item => item.toString() === _id.toString())
        if (status) {
            if (filter === -1) cuentasSelected.push(_id)
        } else {
            if (filter !==-1) cuentasSelected.splice(filter, 1)
        }
        await this.setState({ cuentasSelected })
        this.receivedData();
    };


    /**
     * @methodOf  Transacciones
     *
     * @function updateTipo
     * @description Se actualiza el el filtro de tipo de transacción.
     *
     * @param tipo
     * @description 1, 2, 3 segun sea traspaso, egreso o ingreso.
     *
     * */
    updateTipo = async (tipo) => {
        await this.setState({ tipo })
        this.receivedData();
    };

    /**
     * @methodOf  Transacciones
     *
     * @function updateAreas
     * @description Se define un area y se filtra en la tabla
     *
     * @param id ID del area a filtrar
     */
    updateAreas = async (id) => {
        this.setState({ area: id })
        //Si el ID es nulo, se borran todos los elementos
        if (this.state.area === id) {
            //Si el ID es igual al que esta en el state, entonces lo vamos a borrar junto con los rubros
            await this.setState({
                area: undefined,
                rubro: undefined,
                subrubro: undefined,
                rubros: [],
                subrubros: [],
            })
            this.receivedData();

            //Si no, se buscan
        } else return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        })
            .then(async (rubros) => {
                await this.setState({
                    rubro: null,
                    rubros: rubros.data.data,
                    subrubro: undefined,
                    subrubros: [],
                })
                this.receivedData();
            })
    }

    /**
     * @methodOf  Transacciones
     *
     * @function updateRubros
     * @description Se define un rubro y se filtra en la tabla
     *
     * @param id ID del subrubro a filtrar
     */
    updateRubros = async (id) => {
        this.setState({ rubro: id })
        if (this.state.rubro === id) {
            //Si el ID es igual al que esta en el state, entonces lo vamos a borrar junto con los rubros
            await this.setState({
                rubro: undefined,
                subrubro: undefined,
                subrubros: []
            })
            this.receivedData();

        } else return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: id
            }
        })
            .then(async (rubros) => {
                await this.setState({
                    subrubro: null,
                    subrubros: rubros.data.data
                })
                this.receivedData();
            })
    }



    /**
     * @methodOf  Transacciones
     *
     * @function updateSubrubros
     * @description Se define un subrubor y se filtra en la tabla
     *
     * @param id ID del subrubro a filtrar
     */
    updateSubrubros = (id) => {
        const { selectedSubRubros } = this.state

        let index = selectedSubRubros.indexOf(id)
        if(index === -1) selectedSubRubros.push(id)
        else selectedSubRubros.splice(index,1)

        this.setState({selectedSubRubros: selectedSubRubros},()=>{
            this.receivedData()
        })

    }



    /**
     * @methodOf  Transacciones
     *
     * @function onUsuariosChange
     * @description Se ejecuta cuando se actualiza un usuario, filtramos por usuario
     *
     * @param id ID del usuario a filtrar
     */
    onUsuariosChange = async (id) => {
        if (this.state.usuario === id)
            id = undefined;

        await this.setState({ usuario: id })



        console.log('this', this);
        this.receivedData();
    }



    /**
     * @methodOf  Transacciones
     *
     * @function handleVisibleChange
     * @description Permite mostrar u ocultar los dropdowns en general
     *
     * @param type
     * @description Tipo de dropdown (proyeto, rubro, subruro, area, cuenta y tipo)
     *
     * @flag
     * @description Si se mostrará u ocultará.
     *
     * */
    handleVisibleChange = (type, flag) => {
        let { dropdowns } = this.state;
        dropdowns[type] = flag;
        this.setState({ dropdowns });
    };



    /**
     * @methodOf  Transacciones
     *
     * @function modalMultipleTraspaso
     * @description Abre el modal de traspasp
     *
     * @param type
     * @description Tipo de dropdown (proyeto, rubro, subruro, area, cuenta y tipo)
     *
     *
     * */
    modalMultipleTraspaso = () => {
        this.setState({
            accionModal: 'multiple',
            modalVisibleTraspaso: true,
        })
    }


    modalDetalleTraspaso = (item) => {
        const reg_id = item.currentTarget.id
        this.setState({
            accionModal: 'detalle',
            registroId: reg_id,
            modalVisibleTran: false,
            modalVisibleTraspaso: false,
            modalVisibleTraspasoDetalle: true
        })
    }



    /**
     * 
     * 
     * @methodOf HistorialEmpleados
     * @function receivedConsulta
     * @description Actualiza el rerpote a fitrar.
     * 
     */
    receivedConsulta = () => {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setState({ loadingReport: true })

        var
            _projects = (this.state.projectsSelected === undefined) ? [] : this.state.projectsSelected,
            _area = (this.state.area === undefined) ? null : this.state.area,
            _usuario = (this.state.usuario === undefined) ? null : this.state.usuario,
            _rubro = (this.state.rubro === undefined) ? null : this.state.rubro,
            _subrubros = (this.state.selectedSubRubros === undefined) ? [] : this.state.selectedSubRubros,
            _cuentas = (this.state.cuentasSelected === undefined) ? [] : this.state.cuentasSelected,
            _tipo = (this.state.tipo === undefined) ? null : this.state.tipo,
            _end = (this.state.end === undefined) ? null : this.state.end,
            _start = (this.state.start === undefined) ? null : this.state.start;


        var search = this.props.search

        axios.post('/transacciones/reportEmpleados', {
            search,
            projects: _projects,
            area: _area,
            rubro: _rubro,
            subrubros: _subrubros,
            usuario: _usuario,
            cuentas: _cuentas,
            tipo: _tipo,
            end: _end,
            start: _start,
        })
            .then(res => {
                console.log('*********************************************');
                console.log("BIEN REPORTE", res);
                console.log('*********************************************');
                var data = res.data.data;
                var consultaN = this.state.dataRpt.numConsulta;
                consultaN = consultaN + 1;

                var data_rpt = {
                    fecha: data.fecha,
                    espacio: '',
                    dataInfo: data.transacciones,
                    author: data.author,
                    totalTran: data.total,


                    sinFiltros: res.data.sinFiltros,
                    fecha_inicio: (res.data._fecha_inicio !==undefined) ? moment((res.data._fecha_inicio)).format('DD-MM-YYYY') : undefined,
                    fecha_fin: (res.data._fecha_fin !==undefined) ? moment((res.data._fecha_fin)).format('DD-MM-YYYY') : undefined,
                    area: res.data._area,
                    rubro: res.data._rubro,
                    subrubro: res.data._subrubro,

                    proyectos: data.proyectos,
                    cuentas: data.cuentas,

                    numConsulta: consultaN
                };

                this.setState({
                    dataRpt: data_rpt,
                    loadingReport: false,
                    datosConsultados: true,
                    numConsulta: consultaN
                })



            })
            .catch(res => {
                console.log('*********************************************');
                console.log("ERROR REPORTE", res);
                console.log('*********************************************');
            });
    };



    /**
     * 
     * 
     * @methodOf HistorialEmpleados
     * @function getUrlReporte
     * @description Retorna un URL con los filtras de reportes.
     * 
     * @param {*} sort  Orden del reporte
     * @param {*} projects Lista de proyectos a filgrar
     * @param {*} area Area a filtrar
     * @param {*} rubro Rubro a filtrar
     * @param {*} subrubro Subrubro a filtrar
     * @param {*} cuentas Lista de Cuentas a fitlrar 
     * @param {*} usuario Usuario a filtrar
     * @param {*} end Fecha de inicio a filtrar
     * @param {*} start Fina final a filtrar
     */
    getUrlReporte = (
        sort = { "order": "descend", "field": "fecha" },
        projects = this.state.projectsSelected,
        area = this.state.area,
        rubro = this.state.rubro,
        subrubro = this.state.subrubro,
        cuentas = this.state.cuentasSelected,
        usuario = this.state.usuario,
        end = this.state.end,
        start = this.state.start) => {

        let url = new URL(axios.defaults.baseURL + "/transacciones/reportEmpleados");

        url.searchParams.append('Authorization', sessionStorage.getItem('token'));
        // if (sort !== undefined && sort !== null) url.searchParams.append('sort', rubro);
        // if (rubro !== undefined && rubro !== null) url.searchParams.append('projects', rubro);
        if (rubro !== undefined && rubro !== null) url.searchParams.append('rubro', rubro);
        if (subrubro !== undefined && subrubro !== null) url.searchParams.append('subrubro', subrubro);
        if (cuentas !== undefined && cuentas !== null) url.searchParams.append('cuentas', cuentas);
        if (usuario !== undefined && usuario !== null) url.searchParams.append('usuario', usuario);
        if (end !== undefined && end !== null) url.searchParams.append('end', end);
        if (start !== undefined && start !== null) url.searchParams.append('start', start);


        return url;
    }



    render() {

        // no se usa en ningun lado,
        const contentMenuPlus = (
            <div className="div-menuplus">
                <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.modalCrearTransaccion}>
                    Transacción
                </Button>
                <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.modalMultipleTransaccion}>
                    Transacción multiple
                </Button>
                <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.modalCrearTraspaso}>
                    Traspaso
                </Button>
            </div>
        );
        const { onDatesChange, onProjectsChange, onCuentasChange, onUsuariosChange } = this;

        const columns = [
            {
                title: 'Concepto',
                dataIndex: 'concepto',
                key: 'concepto',
                sorter: true,
                fixed: 'left',
                width: 200,
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
                sorter: true,
                render: (text, record) => (
                    <label className="lblTitulo">
                        {moment(record.fecha).format('DD-MM-YYYY')}
                    </label>
                ),

            },
            {
                title: 'Areas',
                dataIndex: ['area_id', 'nombre'],
                key: 'area',
                // sorter: true,
            },
            {
                title: 'Rubro',
                dataIndex: ['rubro_id', 'nombre'],
                key: 'rubro',
                // sorter: true,
            },
            {
                title: 'Subrubro',
                dataIndex: ['sub_rubro_id', 'nombre'],
                key: 'subrubro',
                // sorter: true,
            },
            {
                title: 'Proyecto',
                key: 'proyecto_id',
                dataIndex: 'proyecto_id',
                sorter: true,
                render: (text, record) => {
                    let backgroundColor = (record.proyecto_id.color) ? record.proyecto_id.color : "#bbbbbb";

                    const colorHex = function getContrastYIQ(hexcolor) {
                        hexcolor = hexcolor.replace("#", "");
                        var r = parseInt(hexcolor.substr(0, 2), 16);
                        var g = parseInt(hexcolor.substr(2, 2), 16);
                        var b = parseInt(hexcolor.substr(4, 2), 16);
                        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
                        return (yiq >= 128) ? 'black' : 'white';
                    };
                    let color = colorHex(backgroundColor);

                    return (
                        <div className="record text-right" style={{ backgroundColor: backgroundColor, color: color }}>
                            {record.proyecto_id.nombre}
                        </div>
                    )
                },
            },
            {
                title: 'Cuenta',
                dataIndex: ['cuenta_id', 'nombre'],
                key: 'cuenta',
                // sorter: true,
            },
            {
                title: 'Monto',
                key: 'monto',
                dataIndex: 'monto',
                // dataIndex: 'monto',
                render: (text, record) => {
                    let color = "#17A2B8";
                    switch (record.tipo) {
                        case 1:
                            color = "#28A745";
                            break;

                        case 2:
                            color = "#DC3545";
                            break;

                        case 3:
                            color = "#343A40";
                            break;

                    }
                    return (
                        <Statistic
                            className="text-right"
                            valueStyle={{ color: color }}
                            value={parseFloat(text).toFixed(2)}
                            prefix={'$'}
                        />
                    )
                },
                sorter: true,
                // fixed: 'right',
            },
            {
                title: 'Acciones',
                key: 'actions',
                width: 120,
                fixed: 'right',
                render: (text, record) => (
                    <div className="tbl-opciones">
                        <Button type="" className="detTable-icon" name={record.nombre} id={record._id}
                            onClick={(record.tipo !==3) ? this.modalDetalleTransaccion : this.modalDetalleTraspaso}
                            title="Ver detalles">
                            <IconDetails />
                        </Button>
                    </div>
                ),
            },
        ];

        return (
            <div>

                <Row className="row-encabezado text-white ">
                    <Col xs={24} xl={3} xxl={3}>
                        <h2>Transacciones</h2>
                    </Col>
                    <Col xs={24} xl={20} xxl={20} className="center ">
                        <Row style={{ width: '100%' }} gutter={[10, 10]} className=" m-0">
                            {/*1599*/}
                            <Col className="center " xl={5} lg={12} md={24} sm={24}>
                                <Form.Item
                                    name="fechas"
                                    noStyle>
                                    <RangePicker
                                        className="input-picker"
                                        format={'DD/MM/YYYY'}



                                        style={{
                                            background: 'transparent !important',
                                            color: '#FFF',
                                            float: 'right',
                                            width: '100%'
                                        }}
                                        onChange={this.onDatesChange} />
                                </Form.Item>
                            </Col>
                            {/*Projects*/}
                            <Col className="center " xl={3} lg={6} md={12} sm={12} xs={11}>
                                <Dropdown
                                    className=""
                                    // Solamente para la visibilidad del Dropdown
                                    visible={this.state.dropdowns.projects}
                                    trigger={['click']}
                                    //Para que no se cierre al darle click a algun elemento
                                    onVisibleChange={(flag) => this.handleVisibleChange('projects', flag)}
                                    overlay={
                                        <Menu
                                        >
                                            {/*Iteramos el arreglo de Proyectos*/}
                                            {this.state.projects.map(({ _id, nombre, logo, color }, index) => (
                                                <Menu.Item key={index}>
                                                    {/*Este es el checbox, recibe un evento*/}
                                                    <Checkbox
                                                        /*El evento onProjectsChange recibe el ID del evento, y lo arregla y o loq quita, segun sea el caso.*/
                                                        onChange={(status) => onProjectsChange(_id, status.target.checked)}>
                                                        <CustomAvatar
                                                            image={logo}
                                                            name={nombre}
                                                            color={color}
                                                            size="small"
                                                            style={{ margin: '0 5px' }}
                                                        />
                                                        {nombre}
                                                    </Checkbox>
                                                </Menu.Item>
                                            ))}

                                        </Menu>
                                    }>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>Proyectos <IconArrow /></a>
                                </Dropdown>
                            </Col>
                            {/*Areas*/}
                            <Col className="center " xl={3} lg={6} md={12} sm={12} xs={12}>
                                <Dropdown
                                    className=""
                                    visible={this.state.dropdowns.areas}
                                    trigger={['click']}
                                    onVisibleChange={(flag) => this.handleVisibleChange('areas', flag)}
                                    overlay={
                                        <Menu
                                            style={{
                                                minWidth: '260px',
                                                padding: '10px 5px'
                                            }}
                                        >
                                            {this.state.areas.map((area, index) => {

                                                return <Menu.Item key={index} onClick={() => {
                                                    this.updateAreas(area._id)
                                                }}>
                                                    <input
                                                        //onChange={() => this.updateAreas(area._id)}
                                                        type="radio"
                                                        name="area"
                                                        value={area._id}
                                                        style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                                        checked={this.state.area === area._id} s
                                                    />
                                                    {area.nombre}
                                                </Menu.Item>

                                            })}

                                        </Menu>
                                    }>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>Areas <IconArrow /></a>
                                </Dropdown>
                            </Col>
                            {/*Rubros*/}
                            <Col className="center " xl={3} lg={6} md={12} sm={12} xs={12}>
                                <Dropdown
                                    className=""
                                    trigger={['click']}
                                    visible={this.state.dropdowns.rubros}
                                    onVisibleChange={(flag) => this.handleVisibleChange('rubros', flag)}
                                    overlay={
                                        <Menu
                                            style={{
                                                minWidth: '260px',
                                                padding: '10px 5px'
                                            }}
                                        >
                                            {this.state.rubros.map(({ _id, nombre }, index) => {

                                                return <Menu.Item key={index} onClick={() => {
                                                    this.updateRubros(_id)
                                                }}>
                                                    <input
                                                        onChange={() => this.updateRubros(_id)}
                                                        onClick={() => this.updateRubros(_id)}
                                                        type="radio"
                                                        name="rubro"
                                                        value={_id}
                                                        style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                                        checked={this.state.rubro === _id}
                                                    />
                                                    {nombre}
                                                </Menu.Item>

                                            })}

                                        </Menu>
                                    }>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>Rubros <IconArrow /></a>
                                </Dropdown>
                            </Col>
                            {/*SubRubros*/}
                            <Col className="center " xl={3} lg={6} md={12} sm={12} xs={12}>
                                <Dropdown
                                    className=""
                                    visible={this.state.dropdowns.subrubros}
                                    trigger={['click']}
                                    onVisibleChange={(flag) => this.handleVisibleChange('subrubros', flag)}
                                    overlay={
                                        <Menu
                                            style={{
                                                minWidth: '260px',
                                                padding: '10px 5px'
                                            }}
                                        >
                                            {this.state.subrubros.map(({ _id, nombre }, index) => {

                                                return <Menu.Item key={index}>
                                                    <Checkbox
                                                        onChange={() => this.updateSubrubros(_id)}
                                                    >
                                                    {nombre}
                                                    </Checkbox>
                                                </Menu.Item>

                                            })}

                                        </Menu>
                                    }>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>Subrubros <IconArrow /></a>
                                </Dropdown>
                            </Col>
                            {/*Project*/}
                            <Col className="center " xl={3} lg={6} md={12} sm={12} xs={12}>
                                <Dropdown
                                    className=""
                                    visible={this.state.dropdowns.cuentas}
                                    trigger={['click']}
                                    onVisibleChange={(flag) => this.handleVisibleChange('cuentas', flag)}
                                    overlay={
                                        <Menu
                                        >
                                            {this.state.cuentas.map(({ _id, nombre, logo, color }, index) => (
                                                <Menu.Item key={index}>
                                                    <Checkbox
                                                        onChange={(status) => onCuentasChange(_id, status.target.checked)}>
                                                        <CustomAvatar
                                                            image={logo}
                                                            name={nombre}
                                                            size="small"
                                                            style={{
                                                                margin: '0 5px'
                                                            }}
                                                        />
                                                        {nombre}
                                                    </Checkbox>
                                                </Menu.Item>
                                            ))}

                                        </Menu>
                                    }>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>Cuentas <IconArrow /></a>
                                </Dropdown>
                            </Col>
                            {/*Usuarios*/}
                            <Col className="center " xl={3} lg={6} md={12} sm={12} xs={12}>
                                <Dropdown
                                    className=""
                                    visible={this.state.dropdowns.usuarios}
                                    trigger={['click']}
                                    onVisibleChange={(flag) => this.handleVisibleChange('usuarios', flag)}
                                    overlay={
                                        <Menu
                                            style={{ maxHeight: '300px', overflow: 'auto' }}
                                        >
                                            {this.state.usuarios.map(({ _id, nombre, apellido, image }, index) => (
                                                <Menu.Item key={index}>
                                                    <input
                                                        onChange={() => this.onUsuariosChange(_id)}
                                                        //onClick={() => this.onUsuariosChange(_id)}
                                                        type="radio"
                                                        name="subrubro"
                                                        value={_id}
                                                        style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                                        checked={this.state.usuario === _id}
                                                    />
                                                    {nombre + ' ' + apellido}
                                                </Menu.Item>
                                            ))}

                                        </Menu>
                                    }>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>Usuarios <IconArrow /></a>
                                </Dropdown>
                            </Col>

                            <Col className="center " span={1}>
                                {/*<a href={this.getUrlReporte()} target="_blank"><Download /></a>*/}
                                <Spin spinning={this.state.loadingReport}>
                                    <Reporte
                                        rpt={this.state.dataRpt}
                                        reporte={this.state.datosConsultados}
                                        numConsulta={this.state.numConsulta}
                                        active={this.state.active_reporte}
                                        trigger={() =>
                                            <Button
                                                type="link" size="small"
                                                style={{ position: 'relative', top: '0.4em', right: '0.5em' }}
                                                onClick={this.receivedConsulta}>
                                                <Download />
                                            </Button>}
                                    />
                                </Spin>
                            </Col>

                        </Row>
                    </Col>
                </Row>

                <Spin spinning={this.state.loading}>
                    <section className="p-1">
                        <Row>
                            <Col xs={24}>
                                <div className="div_contenido div-contenedor">
                                    <Table
                                        columns={columns}
                                        dataSource={this.state.dataTran}
                                        pagination={{
                                            current: this.state.currentPage,
                                            pageSize: this.state.perPage,
                                            total: this.state.itemCount,
                                            showSizeChanger: true,
                                            position: ['bottomLeft'],
                                            showQuickJumper: true
                                        }}
                                        onChange={({ current, pageSize, total }, b, sort) => {
                                            this.receivedData(current, pageSize, {}, sort)
                                        }}
                                        className="blankTheme"
                                        scroll={{ x: 1500 }}
                                    />
                                </div>
                            </Col>
                        </Row>

                    </section>
                </Spin>


                <ModalTransacciónDetalle
                    modalVisible={this.state.modalVisibleTranDetalle}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                />

                <ModalTransaccion
                    ref={this.modalTransaccion}
                    modalVisible={this.state.modalVisibleTran}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                >
                </ModalTransaccion>


                <ModalTraspasoDetalle
                    ref={this.modalTraspaso}
                    modalVisible={this.state.modalVisibleTraspasoDetalle}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    tipo_user={this.state.tipo_user}
                    opciones={false}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}


                >
                </ModalTraspasoDetalle>

            </div>
        )
    }
}
