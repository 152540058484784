import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Drawer, Layout, Menu, } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, UsergroupAddOutlined, TagsOutlined, ReconciliationOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { FaShoppingCart, FaBoxes, FaDollyFlatbed, FaCalendar, FaCartArrowDown, FaBuilding, FaClipboardList, FaWarehouse, FaExchangeAlt } from "react-icons/fa";
import { FiUsers, FiBox } from "react-icons/fi";

import './../../Styles/sidebar.css'

import { Collapse } from 'react-collapse';
import {

    IconCRM,
    IconCuentas,
    IconFinanzas,
    IconDashboard,
    IconProyectos,
    IconRubros,
    IconUsuarios,
    IconProveedores,
    IconCalendario,
    IconNomina,
    IconDeuda,
    IconRecibo,
    IconProjectManager2,
    IconManager,
    IconMaterial,
    IconPagoEmpleado,
    IconVentas,
    IconProspecto,
    IconDisponibilidad,
    IconPresupuesto,
    IconPM,
    IconOrdenes,
    IconStatus,
    IconWheelbarrow,
    IconBelvo,
    IconFinancialHealth,
    IconFileCalculator
} from '../Widgets/Iconos.js'

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;

class SiderBar extends Component {


    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,

            nominasMenu: false
        }
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });

    };

    render() {
        const { user } = this.props;
        let owner = false;

        let nombre = ""

        let roles = {
            finanzas: false,
            crm: false,
            p_manager: false,
            legal: false,
            cuentas: false,
            categorias: false,
            rubros: false,
            proyectos: false,
            usuarios: false,

            manager: false,

            estatus_avance: false,
            bitacora_estatus_avance: false,
        };

        if (typeof user === "object") {
            if (user.roles !== undefined)
                roles = user.roles;

            if (user.tipo === 1)
                owner = true;
        }


        const MenuSidebar = <Menu className="sidebar-menu"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline">
            <Menu.ItemGroup key="g1" title="NAVEGACIÓN">
                {(owner || roles.finanzas) ?
                    <Menu.Item key="1" icon={<IconDashboard />}>
                        <Link to={"/admin/dashboard"}>Dashboard</Link>
                    </Menu.Item>
                    : null}

                {(owner || roles.ventas) ?
                    <SubMenu
                        key="ventas"
                        icon={<IconVentas />}
                        title={<Link
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginLeft: '20px' }}
                            to={'/admin/crm'}>Ventas</Link>}
                    >
                        <Menu.Item key="115" icon={<IconProspecto />}>
                            <Link to={'/admin/ventas/dashboard/'}>Prospectos</Link>
                        </Menu.Item>
                        <Menu.Item key="110" icon={<IconDisponibilidad />}>
                            <Link to={'/admin/ventas/plano'}>Disponibilidad</Link>
                        </Menu.Item>
                        {(owner || roles.crm) ?
                            <Menu.Item key="2" icon={<IconCRM />}>
                                <Link to={"/admin/clientes"}>Clientes</Link>
                            </Menu.Item>
                            : null}
                    </SubMenu>
                    : null}
                     <Menu.Item key="110" icon={<IconDisponibilidad />}>
                            <Link to={'/admin/ventas/plano'}>Disponibilidad</Link>
                        </Menu.Item>


                {(owner || roles.compras) ?
                    <Menu.Item key="compras" icon={<FaShoppingCart style={{ fontSize: "20px" }} />}>
                        <Link to={'/admin/compras/proveedores/dashboard'}>Proveedores</Link>
                    </Menu.Item>
                    : null}

                {(owner || roles.ordenes || true) ?
                    <Menu.Item key="ordenes" icon={<IconOrdenes style={{ height: 20, width: 20 }} />}>
                        <Link to={'/admin/ordenes'}>Ordenes de Materiales</Link>
                    </Menu.Item>

                    : null}

                {(owner || roles.inventario || true) ?
                    <Menu.Item key="inventario" icon={<FaBoxes style={{ height: 20, width: 20 }} />}>
                        <Link to={'/admin/materiales/inventario'}>Inventario</Link>
                    </Menu.Item>
                    : null} 



                {(owner || roles.finanzas) ?

                    <SubMenu
                        icon={<IconFinanzas />}
                        title={<Link
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginLeft: '20px' }}
                            to={"/admin/finanzas"}>Finanzas</Link>}
                        key="3">

                        <Menu.Item
                            key="transacciones"
                            icon={<IconDeuda />}
                            className="icon-sidebar">
                            <Link

                                to={"/admin/transacciones"}>Transacciones</Link>
                        </Menu.Item>

                        <Menu.Item
                            key="3-4"
                            icon={<IconDeuda />}
                            className="icon-sidebar">
                            <Link

                                to={"/admin/nomina/pagosPendientes"}>Montos Pendientes</Link>
                        </Menu.Item>

                        <Menu.Item
                            key="4-2"
                            icon={<IconPagoEmpleado />}
                            className="icon-sidebar">
                            <Link
                                to={"/admin/historial-empleados"}>Historial de Empleados</Link>
                        </Menu.Item>
                    </SubMenu>

                    : null}


                {(owner || roles.financial_health) ? <SubMenu
                    icon={<IconFinancialHealth />}
                    title={<Link
                        onClick={(e) => e.stopPropagation()}
                        style={{ marginLeft: '20px' }}
                        to={"/admin/financial-health"}>Financial Health</Link>}
                    key="389">

                    <Menu.Item
                        key="3-4-56"
                        icon={<IconDeuda />}
                        className="icon-sidebar">
                        <Link
                            to={"/admin/financial-health/inversiones"}>Inversiones</Link>
                    </Menu.Item>

                    <Menu.Item
                        key="4-2-784"
                        icon={<IconFinanzas />}
                        className="icon-sidebar">
                        <Link
                            to={"/admin/acreedores"}>Acredores</Link>
                    </Menu.Item>
                </SubMenu> : null}


                {/* 
                {(owner || roles.finanzas) ?
                    <Menu.Item
                        key="5-3"
                        icon={<img src="/images/belvo.svg" style={{ width: 24, height: 24, position: 'relative', top: 0 }} />}
                        className="icon-sidebar">
                        <Link to={"/admin/belvo/finanzas"}>Belvo</Link>
                    </Menu.Item>

                    : null} */}

                {(owner || roles.finanzas) ?
                    <Menu.Item
                        key="5-6"
                        icon={<img src="/images/syncfy-logo.png" style={{ width: 24, height: 24, position: 'relative', top: -5 }} />}
                        className="icon-sidebar">
                        <Link to={"/admin/syncfy/finanzas"}>Syncfy</Link>
                    </Menu.Item>

                    : null}


                {(owner || roles.presupuestos) ? <Menu.Item key="4" icon={<IconCalendario />}>
                    <Link to={"/admin/calendario"}>Calendario</Link>
                </Menu.Item> : null}

                {(owner || roles.presupuestos) ? <Menu.Item key="4-1" icon={<IconFileCalculator />}>
                    <Link to={"/admin/budgeting"}>Budgeting</Link>
                </Menu.Item> : null}


                {(owner || roles.finanzas) ?

                    <SubMenu
                        icon={<IconDashboard />}
                        title={<Link
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginLeft: '20px' }}
                            to={"/admin/contabilidad"}>Contabilidad</Link>}
                        key="contabilidad">

                        <Menu.Item
                            key="facturas"
                            icon={<IconDeuda />}
                            className="icon-sidebar">
                            <Link

                                to={"/admin/facturas"}>Facturas</Link>
                        </Menu.Item>
                    </SubMenu>

                    : null}


                {/*                 <Menu.Item key="contabilidad" icon={<IconDashboard />}>
                    <Link to={"/admin/Contabilidad"}>Contabilidad</Link>
                </Menu.Item> */}
                {owner ? <SubMenu
                    icon={<IconNomina />}
                    title="Nomina"
                    // title={
                    //     <Link
                    //         onClick={(e) => e.stopPropagation()}
                    //         style={{ marginLeft: '20px' }}
                    //         to={"/admin/nomina"}>Nomina</Link>
                    // }
                    key="5">
                    <Menu.Item key="5-0" icon={<ReconciliationOutlined />}>
                        <Link to="/admin/nomina">Administración</Link>
                    </Menu.Item>
                    <Menu.Item key="5-4" icon={<IconWheelbarrow style={{ marginLeft: '30px' }} />}>
                        <Link to="/admin/nomina/inmuebles-trabajo/">Inmuebles Trabajados</Link>
                    </Menu.Item>
                    <Menu.Item key="5-1" icon={<FiUsers style={{ marginLeft: '30px' }} />}>
                        <Link to="/admin/nomina/empleados">Empleados</Link>
                    </Menu.Item>
                    <Menu.Item key="5-2" icon={<TagsOutlined />}>
                        <Link to="/admin/nomina/tajos">Actividades</Link>
                    </Menu.Item>
                    <Menu.Item key="5-3" icon={<TagsOutlined />}>
                        <Link to="/admin/nomina/inmuebles">Inmuebles</Link>
                    </Menu.Item>

                </SubMenu> : null}

                {(owner) ?
                    <Menu.Item key="50" icon={<IconPresupuesto />}>
                        <Link to={"/admin/presupuesto"}>Presupuesto</Link>
                    </Menu.Item>
                    : null}

                {(owner || roles.empleados) ?
                    <Menu.Item key="6" icon={<IconRecibo />}>
                        <Link to={"/admin/recibos"}>Recibos</Link>
                    </Menu.Item>
                    : null}

                <SubMenu
                    icon={<FaDollyFlatbed style={{ fontSize: '24px' }} />}
                    title={<Link
                        onClick={(e) => e.stopPropagation()}
                        style={{ marginLeft: '20px' }}
                        to={"/admin/compras/dashboard"}>Materiales</Link>}
                    key="materiales">

                    <Menu.Item
                        key="materiales-1"
                        icon={<FaClipboardList style={{ fontSize: '24px' }} />}
                        className="icon-sidebar">
                        <Link
                            to={"/admin/compras/requisiciones"}>Requisiciones</Link>
                    </Menu.Item>

                    {(owner || roles.compras) ? <Menu.Item
                        key="materiales-2"
                        icon={<FaCartArrowDown style={{ fontSize: '24px' }} />}
                        className="icon-sidebar">
                        <Link
                            to={"/admin/compras/cotizaciones"}>Compras</Link>
                    </Menu.Item>
                        : null}

                    <Menu.Item
                        key="materiales-3"
                        icon={<FaCalendar style={{ fontSize: '24px' }} />}
                        className="icon-sidebar">
                        <Link
                            to={"/admin/compras/calendario"}>Calendario</Link>
                    </Menu.Item>

                    <Menu.Item
                        key="materiales-4"
                        icon={<FaWarehouse style={{ fontSize: '24px' }} />}
                        className="icon-sidebar">
                        <Link
                            to={"/admin/compras/inventario"}>Inventario</Link>
                    </Menu.Item>

                    <Menu.Item
                        key="materiales-5"
                        icon={<FaExchangeAlt style={{ fontSize: '24px' }} />}
                        className="icon-sidebar">
                        <Link
                            to={"/admin/compras/movimientos"}>Movimientos</Link>
                    </Menu.Item>


                </SubMenu>


                {(owner || roles.p_manager) ?
                    <SubMenu
                        icon={<IconProjectManager2 />}
                        title={<Link
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginLeft: '20px' }}
                            to={"/admin/project-manager"}>Project Manager</Link>}
                        key="7">

                        {(owner || roles.estatus_avance) ?
                            <Menu.Item
                                key="7-1"
                                icon={<IconPM />}
                                className="icon-sidebar">
                                <Link
                                    to={"/admin/project-manager/estatusAvance"}>Estatus Avance</Link>
                            </Menu.Item>
                            : null}

                        {(owner || roles.bitacora_estatus_avance) ?
                            <Menu.Item
                                key="7-2"
                                icon={<IconStatus />}
                                className="icon-sidebar">
                                <Link
                                    to={"/admin/project-manager/bitacoraEstatusAvance"}>Bitacora Estatus Avance</Link>
                            </Menu.Item>
                            : null}

                    </SubMenu>
                    : null}

            </Menu.ItemGroup>




            {/* {(owner || roles.inventario || true) ?


                <Menu.ItemGroup key="inv" title="INVENTARIOS V2">
                    <Menu.Divider />
                    <SubMenu
                        icon={<FaBoxes style={{ height: 20, width: 20 }} />}
                        title={<Link
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginLeft: '20px' }}
                            to={"/admin/inventario/dashboard"}>Nuevo Inventario</Link>}
                        key="Inv2">

                        <Menu.Item key="inv2-prod" icon={<IconMaterial style={{ height: 20, width: 20 }} />}>
                            <Link to={'/admin/inventario/productos'}>Productos</Link>
                        </Menu.Item>

                        <Menu.Item key="inv2-area" icon={<IconRubros style={{ height: 20, width: 20 }} />}>
                            <Link to={'/admin/inventario/areas'}>Áreas</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu.ItemGroup>
                : null} */}





            <Menu.Divider />
            <Menu.ItemGroup key="g2" title="AJUSTES">
                {(owner || roles.usuarios) ?
                    <Menu.Item key="8" icon={<IconUsuarios />}>
                        <Link to={'/admin/usuarios'}>Usuarios</Link>
                    </Menu.Item>
                    : null}
                {(owner || roles.usuarios) ?
                    <Menu.Item key="clientes" icon={<IconUsuarios />}>
                        <Link to={'/admin/clientes'}>Clientes</Link>
                    </Menu.Item>
                    : null}
                {(owner || roles.proyectos) ?
                    <Menu.Item key="proyectos" icon={<IconProyectos />}>
                        <Link to={"/admin/proyectos"}>Proyectos</Link>
                    </Menu.Item>
                    : null}
                {(owner || roles.cuentas) ?
                    <Menu.Item key="10" icon={<IconCuentas />}>
                        <Link to={'/admin/cuentas'}>Cuentas</Link>
                    </Menu.Item>
                    : null}
                {(owner || roles.areas) ?
                    <Menu.Item key="11" icon={<IconRubros />}>
                        <Link to={'/admin/areas'}>Areas</Link>
                    </Menu.Item>
                    : null}
                {(owner) ?
                    <Menu.Item key="proveedores" icon={<IconProveedores />}>
                        <Link to={'/admin/compras/proveedores/'}>Proveedores</Link>
                    </Menu.Item> : null}

                {(owner) ?
                    <Menu.Item key="empresas" icon={<FaBuilding />}>
                        <Link to={'/admin/empresas/'}>Empresas</Link>
                    </Menu.Item> : null}

                {(owner) ?
                    <SubMenu
                        icon={<FiBox style={{ fontSize: '24px' }} />}
                        title={<Link
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginLeft: '20px' }}
                            to={"/admin/productos"}>Productos</Link>}
                        key="productos">

                        <Menu.Item
                            key="productos-1"
                            icon={<FaClipboardList style={{ fontSize: '24px' }} />}
                            className="icon-sidebar">
                            <Link
                                to={"/admin/productos/historial"}>Historial de precio</Link>
                        </Menu.Item>
                    </SubMenu> : null}

                {(owner) ?
                    <Menu.Item key="manager" icon={<IconManager />}>
                        <Link to={'/admin/manager'}>Manager</Link>
                    </Menu.Item> : null}

                {(owner || roles.inventario) ?
                    <Menu.Item key="materiales-555" icon={<IconMaterial />}>
                        <Link to={'/admin/tipos'}>Materiales</Link>
                    </Menu.Item> : null}

                {(owner || roles.ventas) ?
                    <Menu.Item key="115" icon={<IconStatus />}>
                        <Link to={'/admin/ventas/prospectos/estatus'}>Prospectos Estatus</Link>
                    </Menu.Item>
                    : null}
            </Menu.ItemGroup>
        </Menu>

        return (
            <Sider className="sidebar" trigger={null} collapsible collapsed={this.state.collapsed}>
                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: this.toggle,
                })}
                {MenuSidebar}
                <Drawer
                    visible={this.props.responsiveSidebar}
                    maskClosable={true}
                    onClose={this.props.hideSidebar}
                    placement="left"
                    className="sidebar-responsive"
                >
                    {MenuSidebar}
                </Drawer>
            </Sider>
        )
    }
}

export default SiderBar;

