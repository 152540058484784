import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Spin, Form, Select, message, InputNumber  } from 'antd';
//componentes
import { IconCloseModal  } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalEmailVentas extends Component {

	formProspectos = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			proyectos: [],
			prospecto: {},
		}
	}

	/**
	* @memberof ModalEmailVentas
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getProyectos();
	}

	/**
	* @memberof ModalEmailVentas
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	hideModal = () => {
		this.props.closeMethod()
	}	

	/**
	* @memberof ModalEmailVentas
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	onFinish = (values) => {
		console.log(values)
	}



	/**
	 *
	 *
	 * @memberof ModalEmailVentas
	 * @function getProyectos
	 *
	 * @description Obtener proyectos.
	 */
	getProyectos = (search) => {
		axios.get('/proyectos', {
			params: {
				pagination: false,
				search,
			}
		}).then((proyectos) => {
			this.setState({proyectos: proyectos.data.data})
		}).catch((error) => {
			message.error('No se cargaron los Proyectos')
		})
	}


	render() {
		return (
			<Modal
				visible={this.props.modalVisible}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				closable={false}
				className="modal-form"
				destroyOnClose={true}
				zIndex={1000}
				width={750}
			>

				<div className="modal-header">
					<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
						<IconCloseModal />
					</Button>
					<Title level={3} className="modal-title">{this.props.modalTitle} Mail</Title>
				</div>

				<Spin spinning={this.state.loading}>
					<Form
						layout="vertical"
						name="formulario-transacciones"
						onFinish={this.onFinish}
						ref={this.formProspectos}
					>
						<Row align="center">
							<Col xs={20} md={13}>
								<Form.Item
									label="Propspecto"
									name="prospecto_id"//----------------------------------------------------------prospecto_id
									rules={[{
										required: true,
										message: "Por favor, ingrese un Prospecto"
									}]}
								>
									<Select
										className="form-select"
										onSearch={(search) => this.getProyectos(search)}
										showSearch
										filterOption={false}
										>
										{this.state.proyectos.map((proyecto) =>  <Option value={proyecto._id}>
											<CustomAvatar
												image={proyecto.logo}
												name={proyecto.nombre}
												color={proyecto.color}
												size="small"
												style={{
													marginRight: '5px'
												}}
											/>
											{proyecto.nombre}
										</Option>)}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={20} md={13}>
								<Form.Item
									label="Proyecto a Enviar"
									name="proyecto_id"//----------------------------------------------------------proyecto_id
									rules={[{
										required: true,
										message: "Por favor, ingrese un proyecto"
									}]}
								>
									<Select
										className="form-select"
										onSearch={(search) => this.getProyectos(search)}
										showSearch
										filterOption={false}
										>
										{this.state.proyectos.map((proyecto) =>  <Option value={proyecto._id}>
											<CustomAvatar
												image={proyecto.logo}
												name={proyecto.nombre}
												color={proyecto.color}
												size="small"
												style={{
													marginRight: '5px'
												}}
											/>
											{proyecto.nombre}
										</Option>)}
									</Select>
								</Form.Item>
							</Col>
							<Col span={20} className="center">
								<Button htmlType="submit" type="primary" className="btn-azul">
									Enviar
								</Button>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		)
	}
}
