import React, { Component } from 'react';
import { Button, Popconfirm, Space, Row, List, Col, Spin, PageHeader, Card, Layout, message, Typography, Avatar, Collapse, Pagination, Tooltip, Tag } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
//Componentes
import VerDetalle from '../../../Widgets/VerDetalle';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconDelete, IconMoney, IconEdit, IconPlus } from '../../../Widgets/Iconos';

//modales
import ModalSalidaInventario from "../Inventario/ModalSalidaInventario";
import ModalMovimientosDetalle from "./ModalMovimientoDetalle";


const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography

/**
 * @export
 * @class Movimientos
 * @extends {Component}
 * @description Vista de Movimientos
 */
export default class Movimientos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            movimientos:{
                data: [],
                page: 1,
                limit: 20,
                total: 0
            }
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        window.scrollTo(0, 0)

        this.getMovimientos()
    }




    /**
    * @memberof Movimientos
    * @method getMovimientos
    * @description  Obtiene los movimientos de productos de los almacenes
    **/
    getMovimientos = ({
        page = this.state.movimientos.page,
        limit = this.state.movimientos.limit
    } = this.state.movimientos) => {
        console.log("page", page);
        this.setState({loading: true})
        axios.get('/almacen/productos/movimientos',{
            params:{
                page,
                limit,
                search: this.props.search
            }
        }).then(response => {
            console.log("respo", response.data.data);

            this.setState({
                movimientos: response.data
            })
           
            
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información del almacén')
        }).finally(()=>this.setState({loading: false}))
    }




    render() {

        return (
            <div>
                <Spin spinning={false}>
                    <Row className="row-encabezado">
                        <Col xs={12} xl={15} xxl={15} className="flex-left">
                            <Title level={4}className="view-title">Entradas / Salidas</Title>
                        </Col>
                    </Row>
                    <Content className="admin-content content-bg p-1">
                        
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.movimientos.data}
                            locale={{emptyText: 'Sin Requisisciones'}}
                            renderItem={item => (
                                <List.Item className="component-list-item"> 
                                    <div className={`bookmark-${item.tipo}`}></div>
                                    <Card 
                                        className="card-list hover"
                                        onClick={()=>this.setState({modal_detalle: true, movimiento_id: item._id})}
                                    >
                                        <Row style={{ width: '100%' }} className="">

                                            <Col xs={12} md={2} className="flex-left">
                                                { (Array.isArray(item.producto_id?.imagenes) && item?.producto_id?.imagenes.length  > 0) ? 
                                                    <Avatar style={{ marginRight: 10 }} shape="square" src={axios.defaults.baseURL + "/upload/" + item?.producto_id?.imagenes[0].filename } size={60} /> : null
                                                }
                                            </Col>
                                            <Col xs={12} md={5} className="flex-column">
                                                <Tooltip placement="topLeft" title={item.producto_id?.nombre}>
                                                    <Text ellipsis>{item.producto_id?.nombre}</Text>
                                                </Tooltip>
                                                <small>{item.producto_id?.sku_manantial}</small>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Text>{item.categoria_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={1} className="center">
                                                <CustomAvatar
                                                    image={item.proyecto_id?.logo}
                                                    name={item.proyecto_id?.nombre}
                                                    color={item.proyecto_id?.color}
                                                />
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                {item.propiedades.length > 0 ? <Tag color="green" className="tag-normal">{item.propiedades[0].nombre}</Tag> : '-'}
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                {item.contratista_id ? <>
                                                    <CustomAvatar
                                                        image={item.contratista_id?.logo}
                                                        name={item.contratista_id?.nombre + " " + item.contratista_id.apellido}
                                                        color={item.contratista_id?.color}
                                                        style={{marginRight: '4px'}}
                                                    />
                                                    <Text ellipsis>{item.contratista_id.nombre} {item.contratista_id.apellido}</Text>
                                                </> : 'Sin Contratista'}
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Text>{item?.volumen}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Text>{item.producto_id?.unidad?.toUnidad()}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                        <Row>
                            <Col span={24}>

                                <Pagination
                                    current={this.state.movimientos.page}
                                    total={this.state.movimientos.total}
                                    pageSize={this.state.movimientos.limit}
                                    showSizeChanger={true}
                                    onChange={(page, limit) => this.getMovimientos({page, limit})}

                                />
                            </Col>
                        </Row>
                   
                    </Content>
                </Spin>
                <Button className="btnAddPlus" title="Nuevo movimiento" 
                    onClick={()=>{
                        this.setState({modal_visible: true})
                    }} 
                >
                    <IconPlus />
                </Button>
                <ModalSalidaInventario
                    visible={this.state.modal_visible}
                    onCancel={(flag)=>{
                        this.setState({modal_visible: false})
                        if(flag === true){
                            this.getMovimientos()
                        }
                    }}
                />

                <ModalMovimientosDetalle
                    visible={this.state.modal_detalle}
                    onCancel={(flag)=>{
                        this.setState({modal_detalle: false, movimiento_id: undefined})
                    }}
                    movimiento_id={this.state.movimiento_id}
                />
                
            </div>
            
        )
    }
}