import React, { Component, useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Input, InputNumber, message, Modal, Row, Spin, Typography, Upload, Select, Tag } from 'antd';
import PropTypes from "prop-types";
import ReactDOM from "react-dom"
import { Redirect, Link } from 'react-router-dom'
//componentes
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import Logged from '../../../../Hooks/Logged';
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";

import ValidaciónMFA from "../../../Admin/Modales/Belvo/ValidaciónMFA";

//css
import '../../../../Styles/modales.css'
//var QRCode = require('qrcode');
// import { useQRCode } from 'react-qrcodes';
//import QRCode from 'react-qr-code'
const moment = require('moment');
const axios = require('axios').default;
const { Title, } = Typography;
const { Option } = Select;


export default class ModalCuenta extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    static contextType = Logged;

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            form: {},
            loadingImage: false,
            cuentas_belvo: [],
            cuenta_seleccionada: {},
            eidion: false,
            link_accounts: [], //Cuentas que provienen de un link de Belvo

        }
    }

    /**
     * @methodOf ModalCuentas
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se monta el componente
     *
     * */
    componentDidMount(): void {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.onLoadBelvoLinks()
    }


    /**
     * @methodOf ModalCuentas
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualiza una propiedad o un estado.
     *
     * */
    componentDidUpdate(prevProps, prevState, snapshot) {
        //Si la propiedad cambia y es diferente al estado, actualizamos el estado con el ID de la poppiedad y buscamos al usuario
        if (this.props.id !== this.state.id && this.props.id !== undefined && this.props.id !== null) {
            this.state.id = this.props.id
            this.getAccount(this.props.id)
        }


        if (this.props.id !== this.state.id && this.state.id !== undefined && this.props.id === undefined) {
            this.state.id = undefined;
            this.state.form = {};
            this.state.image = undefined;
            this.formModalAccount.current.resetFields();
        }
    }


    formModalAccount = React.createRef();


    /**
     * @methodOf ModalCuenta
     * @function getAccount
     *
     * @description Trae la informacion de una cuenta.
     *
     * */
    getAccount = (id) => {
        this.setState({spinning: true})

        axios.get("/cuentas/get", {
            params: { id }
        }).then(async ({ data }) => {

            //buscamos la cuenta enlace dentro de las que ya estan cargadas
            let belvo_link = data.data.belvo_transacciones ? data.data.belvo_transacciones : data.data.belvo_facturas ? data.data.belvo_facturas : null
            let cuenta_enlace = this.state.cuentas_belvo.find(element => element.belvo_transacciones === belvo_link || element.belvo_facturas === belvo_link)
            
            //carga la imagen
            const logo = data.data.logo;
            if (data.data.logo !== undefined && data.data.logo !== null && data.data.logo !== "") {
                this.setState({
                    image: {
                        url: axios.defaults.baseURL + '/upload/' + logo,
                        name: logo
                    }
                })
                data.data.logo = [{
                    uid: -1,
                    name: data.data.logo,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + data.data.logo,
                    response: { filename: data.data.logo }
                    // e.file.response.filename
                }];
            } else data.data.logo = []

            this.setState({
                form: data.data,
                cuenta_enlace: cuenta_enlace,
                edicion: true,
            })
            await this.formModalAccount.current.resetFields();
        })
        .catch(res => {
            console.log("no obtenida", res);
            message.error('Error al obtener la Información')
        }).finally(()=>this.setState({spinning: false}))
    }


    /**
     * @methodOf ModalCuenta
     * @function saveAccount
     *
     * @description Guarda la cuenta, si hay un ID, la edita
     *
     * */
    saveAccount = values => {

        var saldo = parseFloat(values.saldo);
        if (saldo > 0) {


            if (this.state.image)
                values.logo = this.state.image.name;
            else
                values.logo = null;
            if (values._id) {
                axios.put('/cuentas/update', { ...values, id: values._id })
                    .then(() => {
                        message.success("¡Se ha guardado correctamente la cuenta!")
                        this.props.accept();
                    })
                    .catch((e) => {
                        message.error(e.response.data.message)
                        console.log('e', e)
                    })
                    .finally(() => this.setState({
                        loading: false,
                        loadingImage: false,
                        image: undefined
                    }))
            } else {
                axios.post('/cuentas/add', { ...values, id: values._id })
                    .then(() => {
                        message.success("¡Se ha guardado correctamente la cuenta!")
                        this.props.accept();
                    })
                    .catch((e) => {
                        message.error(e.response.data.message)
                        console.log('e', e)
                    })
                    .finally(() => this.setState({
                        loading: false,
                        loadingImage: false,
                        image: undefined
                    }))
            }
        }
        else {
            message.error("Ingrese saldo")

        }
    }



    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con éxito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };


    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            if (this.state.image)
                this.removeFile(this.state.image.name)

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })
            if (this.state.form._id)
                axios.put('/cuentas/update', { logo: e.file.response.filename, id: this.state.form._id })
                    .finally(() => {
                        this.props.update()
                    });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name)


        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalProyectos
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        this.state.form.avatar = [];
        this.state.image = undefined;
        this.formModalAccount.current.resetFields();
        if (this.state.form._id)
            axios.put('/cuentas/update', { logo: 0, id: this.state.form._id })
                .finally(() => {
                    this.props.update()
                    this.setState({ loadingImage: false })
                })
        else {
            this.props.update()
            this.setState({ loadingImage: false })
        }
    };


    /**
     * @memberof ModalCuenta
     * @name onLoadBelvoLinks
     * @description Obtiene los links de velvo que no esten vinculados anteriormente a las cuentas
     */
    onLoadBelvoLinks = () => {

        axios.get('/cuentas/belvo/link')
            .then(response => {
                //Response success
                console.log('Response', response.data.data)
                this.setState({ cuentas_belvo: response.data.data });
            })
            .catch(error => {
                console.log('Error al traer cuentas Belvo', error)

            })

    }


    /**
     * @memberof ModalCuenta
     * @name onBelvoSelect
     * @description Se ejecuta cada que se selecciona una nueva cuenta asignada a belvo. 
     * Limpia el campo account y busca las cuentas de ese enlace con Belvo
     */
    onBelvoChange = (cuenta_id) => {

        //Buscamos el objeto cuenta
        let cuenta = this.state.cuentas_belvo.find(element => element._id === cuenta_id)

        this.setState({ cuenta_seleccionada: cuenta, spinning: true })

        let belvo_link = (cuenta.belvo_transacciones) ? cuenta.belvo_transacciones : cuenta.belvo_facturas
        
        const getBelvoAccounts = () => axios.get('/belvo/link/accounts', {
            params: {
                belvo_link
            }
        })
            .then(response => {

                this.setState(function (state, props) {
                    return {
                        link_accounts: response.data.data,
                        spinning: false,
                    };
                });

                this.formModalAccount.current.setFieldsValue({
                    cuenta_id: undefined,
                });

            })
            .catch(error => {
                ValidaciónMFA({
                    cuenta: cuenta,
                    error: error?.response,
                    onOk: () => getBelvoAccounts(),
                    setLoading: loading => this.setState({ spinning: loading }),
                    onDoesNotExist: () => this.props.history.push('/admin/cuentas/belvo/' + this.props.match.params.link + '/register'), //Aqui si ocupa la cuenta_id por que se va a regenerar un link, ya que no existe en Belvo,
                    onIsInvalid: () => this.props.history.push('/admin/cuentas/belvo/' + this.props.match.params.link + '/update') //Aqui no se ocupa mandar cuenta_id, por que el token solo ocupa actualizarse
                    
                })
            })
        
        getBelvoAccounts()
    }

    /**
     * @memberof ModalCuenta
     * @description Envia la slicitud enviando ID de la cuenta ERP y ID cuenta bancaria para vincularlo
     */
    onAccountSelected = (account_id) => {
        console.log('Account_id', account_id)
        //Se hace post con los datos de la cuenta ero, cuenta belvo y enlace belvo
        let belvo_link = (this.state.cuenta_seleccionada.belvo_transacciones) ? this.state.cuenta_seleccionada.belvo_transacciones : this.state.cuenta_seleccionada.belvo_facturas
        let payload = {
            belvo_link,
            cuenta_id: this.props.id,
            account_id,
            belvo_institucion: this.state.cuenta_seleccionada.belvo_institucion,
            belvo_tipo_institucion: this.state.cuenta_seleccionada.belvo_tipo_institucion,
            tipo_link: (this.state.cuenta_seleccionada.belvo_transacciones) ? "belvo_transacciones" : "belvo_facturas"
        }

        axios.post('/belvo/save/account', payload)
            .then(response => {
                message.success('Enlace guardado con exito')

            })
            .catch(error => {
                console.log('error', error)
                message.error('Ocurrio un error inesperado')

            })

    }

    hideModal = () => {
        this.props.hideModal()
        this.setState({
            cuenta_enlace: {},
            edicion: false,
        })
    }


    render() {
        const { normFile, formModalAccount, saveAccount, deleteImage } = this;
        const { visible, hideModal, id } = this.props;
        const { form, image, spinning, loadingImage } = this.state;

        const user = this.context;

        return (
            <Modal
                visible={visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                maskClosable={!loadingImage}
                className={"modal-form"}
                destroyOnClose={true}
                closable={false}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{(typeof id === "string") ? "Editar Cuenta" : "Nueva Cuenta"}</Title>
                </div>
                <Spin spinning={spinning || loadingImage}>
                    <Form
                        layout="vertical"
                        ref={formModalAccount}
                        className="frm-cuentas"
                        name="formulario-transacciones"
                        onFinish={saveAccount}

                        initialValues={form}
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Row align="center">
                            <Form.Item
                                name="logo"
                                align="center"
                                getValueFromEvent={normFile}
                                // valuePropName={props?"fileList":"value"}
                                valuePropName="fileList"
                                help={image ? <Button
                                    shape="circle"
                                    danger style={{ position: 'absolute', right: '40%', bottom: '20px' }}
                                    onClick={deleteImage}
                                ><DeleteOutlined /></Button> : null}
                            >
                                <Upload
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    accept="image/*"
                                >

                                    {(loadingImage) ? <div>
                                        {(this.state.loading || loadingImage) ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div className="ant-upload-text">Upload</div>
                                    </div> : ((image) ?
                                        <img src={image.url} alt="avatar" style={{ width: '100%' }} />
                                        :
                                        <div>
                                            {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                            <div className="ant-upload-text">Upload</div>
                                        </div>)}
                                </Upload>
                            </Form.Item>
                        </Row>
                        <Row>
                            <Col xs={24} lg={11} >
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Input placeholder="Nombre" ></Input>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={{ span: 11, push: 1 }} >
                                <Form.Item
                                    label="Titular"
                                    name="titular"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el titular"
                                    }]}
                                >
                                    <Input placeholder="Titular" ></Input>

                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={11} >
                                <Form.Item
                                    label="Banco"
                                    name="banco"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el banco"
                                    }]}
                                >
                                    <Input placeholder="Banco" ></Input>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={{ span: 11, push: 1 }} >
                                <Form.Item
                                    label="Número de Cuenta"
                                    name="cuenta"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el número de cuenta"
                                    }]}
                                >

                                    <Input placeholder="Número de cuenta" maxLength={24} ></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>


                            {(user && user.tipo === 1) ?
                                <Col xs={24} lg={11} >
                                    <Form.Item
                                        label="Cuenta privada"
                                        name="privado"
                                        valuePropName="checked"
                                    >
                                        <Checkbox> Privada</Checkbox>
                                    </Form.Item>
                                </Col>
                                : null}


                            {(user && user.tipo === 1) ?
                                <Col xs={24} lg={{ span: 11, push: 1 }} >
                                    <Form.Item
                                        label="Saldo"
                                        name="saldo"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese el saldo de la cuenta"
                                        }]}
                                    >
                                        <InputNumber placeholder="Saldo" min={0} />
                                    </Form.Item>
                                </Col>
                                : null}
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-azul" loading={spinning || loadingImage}>
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
