import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, Select, List, Typography, message, Spin,DatePicker} from 'antd';
import './../../../../Styles/modales.css'
import { IconCloseModal,IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const moment = require('moment');
const axios = require('axios').default;

const { Option } = Select;

const { Title, } = Typography;


export default class ModalCrearEmpleado extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            
            id: null,
            form: {},

            dataEmpleados:[],
            salarial:false,
            canEdit: false,
            update: false,
            loading: false
       
        }
    }

    //Referencia de form
    formModalCrearEmpleado = React.createRef();

    
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadEmpleadosList();
    }


    /**
     *
     *
     * @memberof ModalSubRubro
     *
     * @function componentDidUpdate
     * @description Se ejecuta el actualizar cualqueir estado. Veririca que el groupID de las propiedades sea diferente al del estado. En caso de que sea nulo, se inicia el proceso de nuevo grupo, caso contrario, obtenemos el grupo para editarlo.
     */
    componentDidUpdate(prevProps) {
        //Si se abre el modal y el editar esa desactivo, se activa para poder editar
       
        if (this.props.modalVisible && !this.state.canEdit && !(this.props.id === null || this.props.id === undefined)) {
            this.state.canEdit = true;
            this.state.id = this.props.id;
            this.loadEmpleadosList();
            if (this.props.id !== null) {
                this.getEmpleado(this.props.id)
            }
        }

        //Si se cierra el modal y el editar esta acctivo, lo desactivamos para esperar un nuevo to update y limpia el modalcini.
        if (!this.props.modalVisible && this.state.canEdit){
            this.state.canEdit = false;
            this.state.form = {};
            this.state.tipo = null
            this.state.id = undefined
            this.setState({ update: !this.state.update   })
            this.formModalCrearEmpleado.current.resetFields();
            this.loadEmpleadosList();
        }

        //En caso de que sea nuevo, pues solo se limppia
        if (!this.props.modalVisible && (this.props.id === null || this.props.id === undefined)) {
            this.state.form = {}
        }

        //Se actualiza la lista de empleados cada vez que se abre el modal
        if(this.props.modalVisible === true && this.props.modalVisible !== prevProps.modalVisible){
            this.loadEmpleadosList();
        }
    }


    /**
     *
     *
     * @memberof ModalCrearEmpleado
     * @function getEmpleado
     * 
     * @description Se obtienen los valores del tag
     * 
     * @param id Obtenemos el ID
     */
    getEmpleado = (id) => {
        axios.get('/empleados/get',{
            params: {
                id
            }
        })
        .then(e => {
            let d = e.data.data
            this.state.form = {
                _id:d._id,
                usuario_id: d.usuario_id._id,
                frecuencia:d.frecuencia,
                modalidad: d.modalidad,
               fecha_nomina: moment(d.fecha_nomina),
               salario: (d.salario)?d.salario:0
            };
            this.formModalCrearEmpleado.current.setFieldsValue(
                {
                    _id:d._id,
                    usuario_id: d.usuario_id._id,
                    frecuencia:d.frecuencia,
                    modalidad: d.modalidad,
                    fecha_nomina:  moment(d.fecha_nomina),
                    salario: (d.salario)?d.salario:0
                }
            );
            this.onModalidadChange(d.modalidad)
        })


    }



    /**
     * @memberof ModalCrearEmpleado
     * @method loadEmpleadosList
     * @description Obtiene la lista usuarios que no estan 
     * @param search string nompre o apellido del empleado a buscar
     */
    loadEmpleadosList = (search)=>{
        const {id} = this.props;
        axios.get('/empleados/disponibles',{
            params:{
                id,
                search
            } 
        })
        .then(e => {
          this.setState({
              dataEmpleados:e.data.data
          })
        })
        .catch(
            error=>{
                message.error('No se cargaron empleados')
            }
        )

    }


    onModalidadChange = (e) => {
        
        this.setState({
            salarial:(e!=1)
        })
        if(e!=1){
            this.formModalCrearEmpleado.current.setFieldsValue({
                salario: 0
            });
        }
    }

    /**
     *
     *
     * @memberof ModalCrearEmpleado
     * @function save
     * 
     * @description Se guardan los valores del fomulario del TAG
     * 
     * @param values Valores del formulario
     */
    save = values => {
        const { accionModal } = this.props;
        if (values._id) {
            axios.put('/empleados/update',values)
            .then(e=>{
                message.success(e.data.message)
                this.props.accept()
            })
            .catch(error=>{
                Modal.error(error.response.data.message)
            })
        } else {
            axios.post('/empleados/add',values)
            .then(e=>{
                this.props.accept()
                message.success(e.data.message)
            })
            .catch(error=>{
                Modal.error(error.data.message)
            })
        }
    }


    render() {
        const { modalVisible, hideModal, id,} = this.props;
        let {canEdit,salarial} = this.state;
        const {formModalCrearEmpleado} = this;
       
        return (
            <Modal
            
                visible={modalVisible}
                onCancel={hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-rubro"
                destroyOnClose={true}
                zIndex={1000}
            >
             <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{(id === null) ? "Nuevo Empleado" : "Editar Empleado"}</Title>
                </div>
                <Spin spinning={this.state.loading}>
                    <Form
                        layout="vertical"
                        ref={formModalCrearEmpleado}
                        className="frm-cuentas"
                        name="formulario-transacciones"
                        onFinish={this.save}
                        initialValues={this.state.form}
                    >
                        <Form.Item
                            name="_id"
                            noStyle
                        >
                            <Input type="hidden" ></Input>
                        </Form.Item>
                        <Row align="center">
                            <Col span={23}>
                                <Form.Item
                                    label="Empleado"
                                    name="usuario_id"//------------------------------------------------------------usuario_id
                                    rules={[{
                                        required: true,
                                        message: "Seleccione el usuario a registrar"
                                    }]}
                                >
                                    <Select
                                        optionFilterProp="children"
                                        clearIcon={<IconArrowSelect />}
                                        showSearch
                                        onSearch={search => this.loadEmpleadosList(search)}
                                        disabled={id!=null}
                                    >
                                        {this.state.dataEmpleados.map(function ({ _id, nombre,apellido, logo }, index) {
                                            return <Option value={_id}>
                                                <CustomAvatar image={logo} name={[nombre,apellido]} size="small"  style={{ marginRight: '5px' }} />
                                                    {nombre + ' ' + apellido }
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>



  
                        <Row align="center">
                            <Col span={11}>
                                <Form.Item
                                    label="Modalidad de pago"
                                    name="modalidad"
                                    rules={[{
                                        required: true,
                                        message: "Seleccione la modalidad de pago "
                                    }]}
                                >
                                    <Select
                                    onChange={this.onModalidadChange}>
                                        <Select.Option value={1}>
                                            Salarial
                                        </Select.Option>
                                        <Select.Option value={2}>
                                            Destajo
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={1}>
                                <Form.Item
                                    label="Frecuencia de pago"
                                    name="frecuencia"
                                    rules={[{
                                        required: true,
                                        message: "Seleccione la frecuencia de pago "
                                    }]}
                                >
                                    <Select

                                    >
                                        <Select.Option value={1}>
                                            Semanal
                                        </Select.Option>
                                        <Select.Option value={2}>
                                            Quincenal
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col span={11}>
                                <Form.Item
                                    label="Próxima Nomina"
                                    name="fecha_nomina"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la fecha de nomina"
                                        }]}
                                >
                                    <DatePicker
                                        disabled={canEdit}
                                        placeholder={"Fecha de Nómina"}
                                        disabledDate={value=> value <= moment()} 
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={1}>
                                <Form.Item
                                    label="Salario"
                                    name="salario"
                                >
                                    <Input
                                        disabled={salarial}
                                        min={0}
                                        type="number" 
                                        defaultValue={0}/>
                                </Form.Item>
                             </Col>
                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
