//Dependencias
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button, Row, Col, } from 'antd';

import { Layout } from 'antd';

import '../../Styles/index.css'
import '../../Styles/header.css'

import Logo from './../../assets/images/logo.png'



const { Header, Content } = Layout;

class Home extends Component {
	render() {
		return (
			<Layout className="index">
				<Header className="header">
					<Row>
						<Col xxl={6} xl={6} md={{ span: 6 }} sm={12} xs={12} >
							<img className="logo" src={Logo} alt="logo" className="logo" />
						</Col>
						<Col xxl={{ span: 4, offset: 13 }} xl={{ span: 4, offset: 13 }} md={{ span: 4, offset: 13 }} sm={12} xs={12}   >
							<Link to="/login" >
								<Button className="btn-azul btn-session">INICIAR SESIÓN</Button>
							</Link>
						</Col>
					</Row>
				</Header>

				<Content>
					<Row className="contenedor-enc" align="center">
						<Col>
							<Button className="btn-azul btn-ERP"> ENTERPRISE RESOURCE PLANNING</Button>
						</Col>

						<Col>
							<img src="/images/GM-logo-blanco.png" width="400" alt="logo" />
						</Col>
					</Row>
				</Content>
			</Layout>
		);
	}
}


export default Home;