import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, List, Typography, message, Spin, InputNumber, Select, Statistic } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { IconCloseModal, IconEdit, IconDelete, IconAddAcount } from '../../../Widgets/Iconos';
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import axios from 'axios';

import './../../../../Styles/modales.css'

const moment = require('moment');
const { Title, Text, Paragraph } = Typography;

const { Option } = Select;

class ModalDiferencia extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accounts: [],
            selectedCuentas: [],
            restante: 0,
            loading: false
        }
    }

    componentDidMount() {
        console.log('props', this.props)

        this.setState({ restante :this.props.total_autorizado })
        this.loadCuentas()
    }


    /**
     * @memberof ModalDiferencia
     * @method loadCuentas
     * @description Trae la lista de cuentas
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                pagination: false
            }
        })
            .then(res => {
                this.setState({ accounts: res.data.data });
            })
            .catch(error => {
                console.log('lista cuentas error', error);
            })
    }


    /**
     * @memberof ModalDiferencia
     * @method onSelectCuenta
     * @description Guarda en el state las cuentas seleccionadas
     */
    onSelectCuenta = (cuenta, key) => {
        console.log(cuenta, key)
        const { selectedCuentas } = this.state;

        let index = selectedCuentas.findIndex(cuenta => cuenta.key === key)

        if (index === -1) selectedCuentas.push({ cuenta_id: cuenta, key: key })
        else {
            selectedCuentas.splice(index, 1)
            selectedCuentas.push({ cuenta_id: cuenta, key: key })
        }
        this.setState({ selectedCuentas })
    }

    /**
    * @memberof ModalDiferencia
    * @method onFinish
    * @description 
    */
    onFinish = (values) => {
        this.setState({loading: true})
        let total_a_pagar = 0
        if (values.montos) {
            values.montos.forEach(monto => total_a_pagar += monto.monto)
            if (total_a_pagar > this.props.total_autorizado) {
                message.error('Monto excede del autorizado')
            } else {
                this.props.onFinish(values)
            }
        }
    }



    validateValues = (changedValues, { montos }) => {
        
        
        let suma = 0
        for (let index = 0; index < montos.length; index++) {

            const monto = montos[index]

            console.log('monto ',monto );
            if (!isNaN(monto?.monto))
                suma += parseFloat(monto.monto)
        }

        console.log('suma',suma);
        // 
        this.setState({ restante: (parseFloat(this.props.total_autorizado) - suma) })

    }



    render() {


        let form_pago_finanzas = <Form
            className="frm-cuentas"
            name="formulario-transacciones"
            onFinish={this.onFinish}
            onValuesChange={this.validateValues}
        >
            <Spin spinning={this.state.loading}>
                <Row justify="center">
                    <Statistic
                        // title="Total a Pagar"
                        value={this.props.total_autorizado}
                        precision={2}
                        // valueStyle={{ color: '#3f8600' }}
                        prefix="$"
                        suffix="MXN"
                    />
                </Row>
                <Row justify="center">
                    <Text style={{ marginTop: '1em', color: "rgba(0,0,0,0.5)" }}>Restante a Pagar</Text>
                    {(this.state.restante < 0)?<Paragraph style={{ fontSize: '0.5em', color: "rgba(0,0,0,0.5)" }}>Hay un excedente en la cantidad.</Paragraph> : null}

                </Row>
                <Row justify="center" style={{ marginBottom: '1em', }}>
                    <Statistic
                        // title="Restante"
                        value={this.state.restante}
                        precision={2}
                        valueStyle={{ fontSize: 16, color: (this.state.restante < 0)?"red":undefined }}
                        prefix="$"
                        suffix="MXN"
                    />
                </Row>

                <Form.List   name="montos" initialValue={[{}]}>

                    {(fields, { add, remove }) => (
                        <Row>
                            <Col span={4} cl>
                                <Form.Item>
                                    <Button style={{ paddingTop: '0px' }} type="link" onClick={() => add()}>
                                        <IconAddAcount />
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Row gutter={[12, 12]}>
                                        <Col span={12} className="gutter-row">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'monto']}
                                                fieldKey={[fieldKey, 'monto']}
                                                rules={
                                                    [
                                                        { required: true, message: 'Ingrese el monto' },
                                                        {
                                                            validator: (_, value) => (value > 0) ? Promise.resolve() : Promise.reject(new Error('Debe de ser una cantidad mayor a 0.')),
                                                        },
                                                    ]
                                                }
                                            >
                                                <InputNumber
                                                    min={0}
                                                    max={this.props.total_autorizado}
                                                    step={1}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    placeholder="Cantidad" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} style={{ position: 'relative' }} className="gutter-row">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'cuenta_id']}
                                                fieldKey={[fieldKey, 'monto']}
                                                rules={[{ required: true, message: 'Seleccione una cuenta' }]}
                                            >
                                                <Select
                                                    style={{ width: '90%' }}
                                                    placeholder="Cuenta"
                                                    className="form-select"
                                                    onSelect={(e) => this.onSelectCuenta(e, fieldKey)}
                                                >
                                                    {
                                                        this.state.accounts.map((account, i) => {
                                                            let { selectedCuentas } = this.state
                                                            let index = selectedCuentas.findIndex(cuenta => cuenta.cuenta_id === account._id)
                                                            if (index === -1)
                                                                return <Option value={account._id}>{account.nombre}</Option>
                                                            else if (index !== -1 && selectedCuentas[index].key === fieldKey)
                                                                return <Option value={account._id}>{account.nombre}</Option>
                                                            else
                                                                return null
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <MinusCircleOutlined style={{ position: 'absolute', top: '12px', right: '3px' }} onClick={() => {
                                                remove(name)
                                                const { selectedCuentas } = this.state
                                                let index = selectedCuentas.findIndex(cuenta => cuenta.key === fieldKey)
                                                if (index !== -1) {
                                                    selectedCuentas.splice(index, 1)
                                                    this.setState({ selectedCuentas })
                                                }
                                            }} />
                                        </Col>
                                    </Row>
                                ))}
                            </Col>


                        </Row>
                    )}
                </Form.List>

                <Row style={{ textAlign: "center" }}>
                    <Col span={24}>
                        <Form.Item >
                            <Button disabled={this.state.restante !== 0} htmlType="submit" type="primary" className="btn-azul" >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Spin>
        </Form>

        let form_revision_final = <Form
            layout="vertical"
            className="frm-cuentas"
            name="formulario-transacciones"
            onFinish={this.props.onFinish}
        >

            <Row align="center" gutter={[16, 16]}>
                <Col span={(this.props.etapa === 3) ? 16 : 24}>
                    <Form.Item
                        label="Monto a Pagar"
                        name="monto"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                        initialValue={this.props.montoDeuda}

                    >
                        <InputNumber
                            min={0}
                            //max={this.props.montoDeuda}
                            step={1}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            placeholder="Cantidad" />
                    </Form.Item>
                </Col>

            </Row>

            <Row style={{ textAlign: "center" }}>
                <Col span={24}>
                    <Form.Item >
                        <Button htmlType="submit" type="primary" className="btn-azul" >
                            Guardar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>

        return (
            <>
                {/* {console.log('etapane ', this.props.etapa)} */}
                {this.props.etapa === 2 ? form_revision_final : form_pago_finanzas}
            </>
        )
    }
}



export default function (props) {

    const { visible = true, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}
        style={{ width: '600px' }}
    >
        <div className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={onClose}>
                <IconCloseModal />
            </Button>
            <Title level={3} className="modal-title">Pago Nomina</Title>
        </div>
        <ModalDiferencia {...props} />
    </Modal>

}


{/**/ }