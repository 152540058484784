import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Spin, Form, Select, message, InputNumber  } from 'antd';
//componentes
import { IconCloseModal  } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios');
const { Title } = Typography;
const { Option } = Select;


export default class ModalBitacoraConcepto extends Component {

	formBitacora = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			areas:[],
			rubros:[],
			subRubros:[]

		}
	}

	/**
	* @memberof ModalBitacoraConcepto
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.loadAreas();
	}

	/**
	* @memberof ModalBitacoraConcepto
	*
	* @method   componentDidUpdate
	* @description si se va a editar un registro de la bitacora, formatea los datos para el formulario
	*
	**/
	componentDidUpdate(prevPros) {
		if(this.props.bitacora_edit !== prevPros.bitacora_edit && this.props.bitacora_edit !== undefined){
			const {bitacora_edit} = this.props;
			this.onAreaChange( bitacora_edit.area_id)
			this.setState({
				bitacora_edit: {
					fecha: moment(bitacora_edit.fecha),
					concepto: bitacora_edit.concepto,
					monto: bitacora_edit.monto,
					area_id: bitacora_edit.area_id,
					rubro_id: bitacora_edit.rubro_id,
					sub_rubro_id: bitacora_edit.sub_rubro_id._id,
					loading: true,
				}
			})
		}
	}

	/**
	* @memberof ModalBitacoraConcepto
	*
	* @method   hideModal
	* @description	 Cierra el modal
	*
	**/
	hideModal = () => {
		this.props.closeMethod()
		this.setState({loading: false, bitacora_edit: undefined})
	}	

	/**
	* @memberof ModalBitacoraConcepto
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	onFinish = (values) => {
		if(this.props.modalTitle === 'Editar'){
			this.updateRegistro(values)
		}else{
			this.addRegistro(values)
		}
	}

	/**
     * @memberof ModalBitacoraConcepto
     *
     *
     * @method loadAreas
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     */
    loadAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({ areas: response.data.data })
        }).catch((error) => { })
    }

    /**
     * @methodOF ModalBitacoraConcepto
     *
     * @function onAreaChange
     * @description Al actualizar un area, se deben traer los rubros y subrubros iniciales.
     * */
    onAreaChange = (id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        }).then(async (rubros) => {
            this.setState({ rubros: rubros.data.data })
            if (this.state.rubros.length > 0) {
                this.formBitacora.current.setFieldsValue({ rubro_id: rubros.data.data[0]._id });
                this.onRubroChange(rubros.data.data[0]._id)
            } else {
                this.formBitacora.current.setFieldsValue({
                    rubro_id: undefined,
                    sub_rubro_id: undefined
                })
            }
        }).catch((error) => {

        })
    }

     /**
     *
     * @memberof ModalBitacoraConcepto
     *
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (e) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: e
            }
        }).then((subrubros) => {
            this.setState({
                subrubros: subrubros.data.data
            })
            if (this.state.subrubros.length > 0) {
                this.formBitacora.current.setFieldsValue({
                    sub_rubro_id: subrubros.data.data[0]._id
                });
            } else {
                this.formBitacora.current.setFieldsValue({
                    sub_rubro_id: undefined
                })
            }


        }).catch((error) => {
        }).finally(()=>{this.setState({loading: false})})
    }



	/**
	* @memberof ModalBitacoraConcepto
	*
	* @method   addRegistro
	* @description	se añade un registro a la BD
	*
	**/
	addRegistro = (values) => {
		this.setState({loading: true})
		axios.post('/proforma/conceptos/bitacora/add',{
			proforma_id: this.props.proforma_id,
			proforma_concepto_id: this.props.proforma_concepto_id,
			concepto: values.concepto, 
			fecha: values.fecha, 
			monto: values.monto,
			area_id: values.area_id,
			rubro_id: values.rubro_id, 
			sub_rubro_id: values.sub_rubro_id, 
		}).then(response => {
			this.hideModal();
			message.success('¡Registro añadido Exitosamente!')
		}).catch( error =>{
			console.log(error)
			message.error('No se añadio el Registro')
			this.setState({loading: false})
		})
	}

	/**
	* @memberof ModalBitacoraConcepto
	*
	* @method   updateRegistro
	* @description	Se actualiza un registro de la bitacora
	*
	**/
	updateRegistro = (values) => {
		this.setState({loading: true})
		axios.post('/proforma/conceptos/bitacora/update',{
			proforma_id: this.props.proforma_id,
			proforma_concepto_id: this.props.proforma_concepto_id,
			bitacora_id: this.props.bitacora_edit._id,
			concepto: values.concepto, 
			fecha: values.fecha, 
			monto: values.monto,
			area_id: values.area_id,
			rubro_id: values.rubro_id, 
			sub_rubro_id: values.sub_rubro_id, 
		}).then(response => {
			this.hideModal();
			message.success('¡Registro actualizado Exitosamente!')
		}).catch( error =>{
			console.log(error)
			message.error('No se actualizó el Registro')
			this.setState({loading: false})
		})
	}



	/**
    * @memberof ModalBitacoraConcepto
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            if (array.length > 0) {

                array.forEach(function (item, index) {
                    arr.push(<Option value={item._id}>{item.nombre}</Option>)
                })
            }
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}</Option>)
            }
        }
        return arr
    }


	render() {
		return (
			<Modal
				visible={this.props.modalVisible}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				closable={false}
				className="modal-form"
				destroyOnClose={true}
				zIndex={1000}
				width={750}
			>

				<div className="modal-header">
					<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
						<IconCloseModal />
					</Button>
					<Title level={3} className="modal-title">{this.props.modalTitle} Registro</Title>
				</div>

				<Spin spinning={this.state.loading}>
					<Form
						layout="vertical"
						name="formulario-transacciones"
						onFinish={this.onFinish}
						ref={this.formBitacora}
						initialValues={this.state.bitacora_edit}
					>
						<Row align="center">
							<Col xs={20} md={{span:10}}>
								<Form.Item
									label="Fecha"
									name="fecha"//----------------------------------------------------------fecha
									rules={[{
										required: true,
										message: "Por favor, ingrese un Fecha"
									}]}
								>
									 <DatePicker />
								</Form.Item>
							</Col>
							<Col xs={20} md={{span:10, offset: 1}}>
								<Form.Item
									label="Concepto"
									name="concepto"//----------------------------------------------------------concepto
									rules={[{
										required: true,
										message: "Por favor, ingrese un Concepto"
									}]}
								>
									 <Input />
								</Form.Item>
							</Col>
						</Row>							
						<Row align="center">
							<Col xs={20}  md={{span:10}}>
								<Form.Item
									label="Monto"
									name="monto"//----------------------------------------------------------monto
									rules={[{
										required: true,
										message: "Por favor, ingrese un Monto"
									}]}
								>
									<InputNumber/>
								</Form.Item>
							</Col>
							<Col xs={20} md={{span:10, offset: 1}}>
								<Form.Item
									label="Area"
									name="area_id"//----------------------------------------------------------area_id
									rules={[{
										required: true,
										message: "Por favor, seleccione un Area"
									}]}
								>
                                    <Select
										className="form-select"
                                        onChange={this.onAreaChange}
                                    >
                                       {this.selectOptions(this.state.areas)}

                                    </Select>
								</Form.Item>
							</Col>
						</Row>

						<Row align="center">
							<Col xs={20} md={{span: 10}}>
								<Form.Item
									label="Rubro"
									name="rubro_id"//----------------------------------------------------------rubro
									rules={[{
										required: true,
										message: "Por favor, seleccione un Rubro"
									}]}
								>
                                    <Select
										className="form-select"
                                        onChange={this.onRubroChange}
                                    >
                                       {this.selectOptions(this.state.rubros)}

                                    </Select>
								</Form.Item>
							</Col>
							<Col xs={20} md={{span: 10, offset: 1}}>
								<Form.Item
									label="Rubro"
									name="sub_rubro_id"//----------------------------------------------------------sub_rubro_id
									rules={[{
										required: true,
										message: "Por favor, seleccione un Sub Rubro"
									}]}
								>
                                    <Select
										className="form-select"
                                        onChange={this.onRubroChange}
                                    >
                                       {this.selectOptions(this.state.subrubros)}

                                    </Select>
								</Form.Item>
							</Col>
							<Col span={20} className="center">
								<Button htmlType="submit" type="primary" className="btn-azul mt-3">
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		)
	}
}


