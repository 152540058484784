import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, Divider, message, Spin } from 'antd';

//compoenentes
import { PlusOutlined } from '@ant-design/icons';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";

//css
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;

class FinanzasForm extends Component {

    modalForm = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dataRubros: [],
            dataSubrubros: [],
            loading: false
        }
    }

    /**
     * @methodOF ModalClasificarTransacciones
     *
     * @function onAreaChange
     * @description Al actualizar un area, se deben traer los rubros y subrubros iniciales.
     * */
    onAreaChange = (id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        }).then(async (rubros) => {
            this.setState({ dataRubros: rubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataRubros.length > 0) {
                this.modalForm.current.setFieldsValue({
                    rubro: rubros.data.data[0]._id,

                });
                this.onRubroChange(rubros.data.data[0]._id)
            } else {
                this.modalForm.current.setFieldsValue({
                    rubro: undefined,
                    subrubro: undefined
                })
            }
        }).catch((error) => {

        })
    }

    /**
     *
     *
     * @memberof ModalClasificarTransacciones
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (e) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: e
            }
        }).then((subrubros) => {
            this.setState({
                dataSubrubros: subrubros.data.data
            })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataSubrubros.length > 0) {
                this.modalForm.current.setFieldsValue({
                    subrubro: subrubros.data.data[0]._id
                });
            } else {
                this.modalForm.current.setFieldsValue({
                    subrubro: undefined
                })
            }


        }).catch((error) => {
        })


    }

    /**
     * @memberof ModalClasificarTransacciones
     *
     * @method onFinish
     * @description Guarda las transacciones en la BD
     *
     */
    onFinish = (values) => {

        this.setState({loading: true})
        axios.post('/belvo/cuenta/transacciones/add', {
            ...values,
            cuenta_id: this.props.cuenta_id,
            account_id: this.props.account_id,
            transacciones: Object.values(this.props.transacciones)
        }).then(response=>{
            message.success('Transacciones clasificadas')
            this.props.onClose()
            this.props.clearTransacciones()
        }).catch(error=>{
            let mensaje = 'Error al Clasificar las Transacciones'
            if (error.response?.status === 403)
                mensaje = error.response?.data?.message
            message.error(mensaje)
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    ref={this.modalForm}
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    onFinish={this.onFinish}
                >
                    <Row align="center">
                        <Col span={24}>
                            <Row gutter={[20, 20]} style={{margin: '0'}}>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Concepto"//----------------------------------concepto
                                        name="concepto"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el concepto"
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Area"
                                        name="area_id"//----------------------------------area_id
                                        listType="text"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el Area a que corresponde la transacccion."
                                        }]}
                                    >
                                        <Select
                                            className="form-select"
                                            placeholder="Seleccione el Area"
                                            onChange={this.onAreaChange}
                                        >
                                            {this.props.areas?.map(function (area, index) {
                                                return <Option value={area._id}>{area.nombre}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Rubro"
                                        name="rubro"//----------------------------------rubro
                                        listType="text"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el rubro"
                                        }]}
                                    >
                                        <Select className="form-select" placeholder="Seleccione rubro"
                                            onChange={this.onRubroChange}>
                                            {this.state.dataRubros.map((rubro, index) => {

                                                return <Option value={rubro._id}>{rubro.nombre}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Subrubro"
                                        name="subrubro"//----------------------------------subrubro
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el subrubro"
                                        }]}
                                    >
                                        <Select className="form-select" placeholder="Seleccione subrubro"
                                        >
                                            {this.state.dataSubrubros.map(function (subrubro, index) {
                                                return <Option value={subrubro._id}>{subrubro.nombre}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Proyecto"
                                        name="proyecto"//----------------------------------proyecto
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el proyecto"
                                        }]}
                                    >
                                        <Select placeholder="Seleccione proyecto" disabled={this.state.edicion}>
                                            {this.props.proyectos?.map(function ({ _id, nombre, logo, color }, index) {
                                                return <Option value={_id}>
                                                    <CustomAvatar
                                                        image={logo}
                                                        name={nombre}
                                                        color={color}
                                                        size="small"
                                                        style={{
                                                            marginRight: '5px'
                                                        }}
                                                    />
                                                    {nombre}
                                                </Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>
                            </Row>


                            <Row style={{ textAlign: "center" }}>
                                <Col span={24}>
                                    <Form.Item >
                                        <Button htmlType="submit" type="primary" className="btn-azul" >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}

class ProveedoresForm extends Component {

    modalForm = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dataRubros: [],
            dataSubrubros: [],
            loading: false,
        }
    }

    /**
     * @methodOF ModalClasificarTransacciones
     *
     * @function onAreaChange
     * @description Al actualizar un area, se deben traer los rubros y subrubros iniciales.
     * */
    onAreaChange = (id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        }).then(async (rubros) => {
            this.setState({ dataRubros: rubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataRubros.length > 0) {
                this.modalForm.current.setFieldsValue({
                    rubro: rubros.data.data[0]._id,
                });
                this.onRubroChange(rubros.data.data[0]._id)
            } else {
                this.modalForm.current.setFieldsValue({
                    rubro: undefined,
                    subrubro: undefined
                })
            }
        }).catch((error) => {
        })
    }

    /**
     *
     *
     * @memberof ModalClasificarTransacciones
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (e) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: e
            }
        }).then((subrubros) => {
            this.setState({
                dataSubrubros: subrubros.data.data
            })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataSubrubros.length > 0) {
                this.modalForm.current.setFieldsValue({
                    subrubro: subrubros.data.data[0]._id
                });
            } else {
                this.modalForm.current.setFieldsValue({
                    subrubro: undefined
                })
            }
        }).catch((error) => {

        })
    }

     /**
     * @memberof ModalClasificarTransacciones
     *
     * @method onFinish
     * @description Guarda las transacciones en la BD
     *
     */
    onFinish = (values) => {

        this.setState({loading: true})
        axios.post('/belvo/cuenta/transacciones/add', {
            ...values,
            cuenta_id: this.props.cuenta_id,
            account_id: this.props.account_id,
            transacciones: Object.values(this.props.transacciones)
        }).then(response=>{
            message.success('Transacciones clasificadas')
            this.props.onClose()
            this.props.clearTransacciones()
        }).catch(error=>{
            let mensaje = 'Error al Clasificar las Transacciones'
            if (error.response?.status === 403)
                mensaje = error.response?.data?.message
            message.error(mensaje)
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {

        const { transacciones } = this.props;

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    onFinish={this.onFinish}
                    ref={this.modalForm}
                >
                    <Row align="center">
                        <Col span={24}>
                            <Row gutter={[20, 20]} style={{margin: '0'}}>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Concepto"//----------------------------------concepto
                                        name="concepto"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el concepto"
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Area"
                                        name="area_id"//----------------------------------area_id
                                        listType="text"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el Area a que corresponde la transacccion."
                                        }]}
                                    >
                                        <Select
                                            className="form-select"
                                            placeholder="Seleccione el Area"
                                            onChange={this.onAreaChange}
                                        >
                                            {this.props.areas?.map(function (area, index) {
                                                return <Option value={area._id}>{area.nombre}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Rubro"
                                        name="rubro"//----------------------------------rubro
                                        listType="text"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el rubro"
                                        }]}
                                    >
                                        <Select className="form-select" placeholder="Seleccione rubro"
                                            onChange={this.onRubroChange}>
                                            {this.state.dataRubros.map((rubro, index) => {
                                                return <Option value={rubro._id}>{rubro.nombre}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Subrubro"
                                        name="subrubro"//----------------------------------subrubro
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el subrubro"
                                        }]}
                                    >
                                        <Select className="form-select" placeholder="Seleccione subrubro"
                                        >
                                            {this.state.dataSubrubros.map(function (subrubro, index) {
                                                return <Option value={subrubro._id}>{subrubro.nombre}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Proyecto"
                                        name="proyecto"//----------------------------------proyecto
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el proyecto"
                                        }]}
                                    >
                                        <Select
                                            placeholder="Seleccione proyecto"
                                        >
                                            {this.props.proyectos?.map(function ({ _id, nombre, logo, color }, index) {
                                                return <Option value={_id} >
                                                    <CustomAvatar
                                                        image={logo}
                                                        name={nombre}
                                                        color={color}
                                                        size="small"
                                                        style={{
                                                            marginRight: '5px'
                                                        }}
                                                    />
                                                    {nombre}
                                                </Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Proveedor"
                                        name="proveedor_id"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el proveedor"
                                        }]}
                                    >
                                        <Select className="form-select"
                                            showSearch
                                            filterOption={(input, option) => option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Seleccione el proveedor"
                                            optionFilterProp="children"
                                        >
                                            {this.props.proveedores?.map(function (proveedor, index) {
                                                return <Option value={proveedor._id}>{proveedor.alias} <strong>({proveedor.razon_social})</strong></Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                            </Row>


                            <Row style={{ textAlign: "center" }}>
                                <Col span={24}>
                                    <Form.Item >
                                        <Button htmlType="submit" type="primary" className="btn-azul" >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}

class CRMForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientes: [],
            propiedades: [],
        }
    }


    componentDidMount(){
        this.getClientes()

    }

    /**
     * @methodOF ModalClasificarTransacciones
     *
     * @function onAreaChange
     * @description Trae los clientes disponibles
     * */
    getClientes = () => {
        axios.get('/clientes/list',{
            params:{
                pagination: false
            }
        }).then(response => {
            console.log('CLIENTES',response.data.data)
            this.setState({clientes: response.data.data})
        }).catch(error=> {
            console.log(error)
            message.error('Error al traer los clientes')
        })
    }

    /**
     * @methodOF ModalClasificarTransacciones
     *
     * @function clienteOnChange
     * @description Trae los clientes disponibles
     * */
    clienteOnChange = (cliente_id) => {
        axios.post('/clientes/get',{
            id: cliente_id
        }).then(response => {
            console.log('propiedades',response.data.propiedades)
            this.setState({propiedades: response.data.propiedades})
        }).catch(error=> {
            console.log(error)
            message.error('Error al traer los clientes')
        })
    }

     /**
     * @memberof ModalClasificarTransacciones
     *
     * @method onFinish
     * @description Guarda las transacciones en la BD
     *
     */
    onFinish = (values) => {

        this.setState({loading: true})

        let propiedad = this.state.propiedades.find(pro => pro._id.toString() === values.propiedad_id)

        axios.post('/belvo/cuenta/transacciones/add', {
            ...values,
            area_id: propiedad.area_id._id,
            rubro: propiedad.rubro_id._id,
            subrubro: propiedad.subrubro_id._id,
            proyecto: propiedad.proyecto_id._id,
            cuenta_id: this.props.cuenta_id,
            account_id: this.props.account_id,
            transacciones: Object.values(this.props.transacciones),
            tipo_cliente: 'Transacción',
        }).then(response=>{
            message.success('Transacciones clasificadas')
            this.props.onClose()
            this.props.clearTransacciones()
        }).catch(error=>{
            let mensaje = 'Error al Clasificar las Transacciones'
            if (error.response?.status === 403)
                mensaje = error.response?.data?.message
            message.error(mensaje)
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {

        const { transacciones } = this.props;

        return (
            <Form
                layout="vertical"
                className="frm-cuentas"
                name="form-materiales"
                onFinish={this.onFinish}
            >
                <Row align="center">
                    <Col span={24}>
                        <Row gutter={[20, 20]} style={{margin: '0'}}>

                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Concepto"//----------------------------------concepto
                                    name="concepto"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, Ingrese el concepto"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Cliente"
                                    name="cliente_id"//----------------------------------cliente_id
                                    listType="text"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el Cliente"
                                    }]}
                                >
                                    <Select 
                                        onChange={this.clienteOnChange}
                                        className="form-select" placeholder="Seleccione rubro">
                                        {this.state.clientes?.map((cliente, index) => {
                                            return <Option value={cliente._id}>{cliente.nombre} {cliente.apellido}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>
                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Inmueble"
                                    name="propiedad_id"//----------------------------------propiedad_id
                                    listType="text"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el inmueble"
                                    }]}
                                >
                                    <Select
                                        className="form-select"
                                        placeholder="Seleccione el Area"
                                    >
                                        {this.state.propiedades.map(function (propiedad, index) {
                                            return <Option value={propiedad._id}>{(propiedad.inmueble_id ? propiedad.inmueble_id?.nombre : propiedad.rubro_id.nombre)}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>


                        </Row>


                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        )
    }
}

class TraspasoForm extends Component {

    modalForm = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dataRubros: [],
            dataSubrubros: [],
            cuentas: [],
            loading: false,
        }
    }

    componentDidMount(){
        this.getCuentas()
    }

    /**
     * @methodOF ModalClasificarTransacciones
     *
     * @function onAreaChange
     * @description Al actualizar un area, se deben traer los rubros y subrubros iniciales.
     * */
    onAreaChange = (id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        }).then(async (rubros) => {
            this.setState({ dataRubros: rubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataRubros.length > 0) {
                this.modalForm.current.setFieldsValue({
                    rubro: rubros.data.data[0]._id,

                });
                this.onRubroChange(rubros.data.data[0]._id)
            } else {
                this.modalForm.current.setFieldsValue({
                    rubro: undefined,
                    subrubro: undefined
                })
            }
        }).catch((error) => {

        })
    }

    /**
     *
     *
     * @memberof ModalClasificarTransacciones
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (e) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: e
            }
        }).then((subrubros) => {
            this.setState({
                dataSubrubros: subrubros.data.data
            })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataSubrubros.length > 0) {
                this.modalForm.current.setFieldsValue({
                    subrubro: subrubros.data.data[0]._id
                });
            } else {
                this.modalForm.current.setFieldsValue({
                    subrubro: undefined
                })
            }


        }).catch((error) => {
        })


    }

    getCuentas = () => {
        axios.get('/cuentas/list',{
            params:{
                transacciones: true,
                pagination: false,
            }
        }).then(response => {
            console.log(response)
            this.setState({cuentas: response.data.data})
        }).catch(error => {
            console.log(error)
        })
    }

    onFinish = (values) => {
        this.setState({loading: true})

        axios.post('/belvo/traspaso/add',{
            ...values,
            cuenta_id: this.props.cuenta_id,
            account_id: this.props.account_id,
            transacciones: Object.values(this.props.transacciones)

        }).then(response =>{
            message.success('Traspasos realizados')
            this.props.onClose()
            this.props.clearTransacciones()
        }).catch(error => {
            let mensaje = 'Error al Clasificar las Transacciones'
            if (error.response?.status === 403)
                mensaje = error.response?.data?.message
            message.error(mensaje)
        }).finally(()=>{
            this.setState({loading: false})
        })
    } 


    render() {

        const { transacciones } = this.props;

        return (
            <Spin spinning={this.state.loading}>
            <Form
                layout="vertical"
                className="frm-cuentas"
                name="form-materiales"
                onFinish={this.onFinish}
                ref={this.modalForm}
            >
                <Row align="center">
                    <Col span={24}>
                        <Row gutter={[20, 20]} style={{margin: '0'}}>

                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Concepto"//----------------------------------concepto
                                    name="concepto"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, Ingrese el concepto"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Proyecto"
                                    name="proyecto"//----------------------------------proyecto
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el proyecto"
                                    }]}
                                >
                                    <Select placeholder="Seleccione proyecto" disabled={this.state.edicion}>
                                        {this.props.proyectos?.map(function ({ _id, nombre, logo, color }, index) {
                                            return <Option value={_id}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>

                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Area"
                                    name="area_id"//----------------------------------area_id
                                    listType="text"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el Area a que corresponde la transacccion."
                                    }]}
                                >
                                    <Select
                                        className="form-select"
                                        placeholder="Seleccione el Area"
                                        onChange={this.onAreaChange}
                                    >
                                        {this.props.areas.map(function (area, index) {
                                            return <Option value={area._id}>{area.nombre}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>

                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Rubro"
                                    name="rubro"//----------------------------------rubro
                                    listType="text"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el rubro"
                                    }]}
                                >
                                    <Select className="form-select" placeholder="Seleccione rubro"
                                        onChange={this.onRubroChange}>
                                        {this.state.dataRubros.map((rubro, index) => {

                                            return <Option value={rubro._id}>{rubro.nombre}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>

                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Subrubro"
                                    name="subrubro"//----------------------------------subrubro
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el subrubro"
                                    }]}
                                >
                                    <Select className="form-select" placeholder="Seleccione subrubro"
                                    >
                                        {this.state.dataSubrubros.map(function (subrubro, index) {
                                            return <Option value={subrubro._id}>{subrubro.nombre}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>

                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Cuenta Receptora"
                                    name="cuenta_destino_id"//----------------------------------cuenta
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione la cuenta"
                                    }]}
                                >
                                    <Select className="form-select" placeholder="Seleccione la cuenta"
                                    >
                                        {this.state.cuentas.map(function (cuenta, index) {
                                            return <Option value={cuenta._id}>{cuenta.nombre}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>

                            <Col xs={22} md={12}>
                                <Form.Item
                                    label="Proveedor"
                                    name="proveedor_id"
                                    rules={[{
                                        required: false,
                                        message: "Por favor, seleccione el proveedor"
                                    }]}
                                >
                                    <Select className="form-select"
                                        showSearch
                                        filterOption={(input, option) => option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        placeholder="Seleccione el proveedor"
                                        optionFilterProp="children"
                                    >
                                        {this.props.proveedores?.map(function (proveedor, index) {
                                            return <Option value={proveedor._id}>{proveedor.alias} <strong>({proveedor.razon_social})</strong></Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>

                        </Row>


                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            </Spin>
        )
    }
}


class AcreedoresForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            acreedores: [],
            loading: false
        }
    }

    componentDidMount(){
        this.getAcredores()
    }

    /**
     * @memberof ModalClasificarTransacciones
     *
     * @method getAcredores
     * @description Trae la info de los acredores
     *
     */
    getAcredores = () => {
        axios.get('/acreedores/list',{
            params:{
                pagination: false
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({acreedores: response.data.data})
        }).catch(error => {
            console.log(error)
        })
    }

    /**
     * @memberof ModalClasificarTransacciones
     *
     * @method onFinish
     * @description Crea multiples Transacciones a un Acreedor
     *
     */
    onFinish = (values) => {
        
        this.setState({loading: true})

        let acreedor = this.state.acreedores.find( ac => ac._id === values.acreedor_id )

        axios.post('/belvo/acreedor/add', {
            concepto: values.concepto,
            cuenta_id: this.props.cuenta_id,
            account_id: this.props.account_id,
            transacciones: Object.values(this.props.transacciones),

            acreedor_id: acreedor._id,
            proyecto_id: acreedor.proyecto_id._id,
            usuario_id: acreedor.contacto_id._id,
            area_id: acreedor.area_id._id,
            rubro_id: acreedor.rubro_id._id,
            sub_rubro_id: acreedor.subrubro_id._id,

        }).then((res) => {
            message.success("Transacciones Registradas!");
            this.props.onClose()
            this.props.clearTransacciones()
        }).catch((error) => {
            if(error.response?.status === 403)
                message.error(error.response?.data?.message)
            else
                message.error('Error al clasificar las Transacciones')
        }).finally(()=>{
            this.setState({loading: false})
        });
    }


    render() {

        const { transacciones } = this.props;

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    onFinish={this.onFinish}
                >
                    <Row align="center">
                        <Col span={24}>
                            <Row gutter={[20, 20]} style={{margin: '0'}}>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Concepto"//----------------------------------concepto
                                        name="concepto"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el concepto"
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xs={22} md={12}>
                                    <Form.Item
                                        label="Acreedor"
                                        name="acreedor_id"//----------------------------------acreedor_id
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el proyecto"
                                        }]}
                                    >
                                        <Select placeholder="Seleccione proyecto" disabled={this.state.edicion}>
                                            {this.state.acreedores?.map((acreedor, index) => {
                                                return <Option value={acreedor._id}>{acreedor.acreedor}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                            </Row>


                            <Row style={{ textAlign: "center" }}>
                                <Col span={24}>
                                    <Form.Item >
                                        <Button htmlType="submit" type="primary" className="btn-azul" >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}


export {
    FinanzasForm,
    ProveedoresForm,
    CRMForm,
    TraspasoForm,
    AcreedoresForm,
} 





