import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, List, Typography, message, Spin, Select } from 'antd';

//compoenentes
import { IconCloseModal } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

import axios from 'axios'
const moment = require('moment');

const { Title, } = Typography;
const { Option } = Select;

class ModalInventario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proyectos: []
        }
    }

    //Referencia de form
    formInventario = React.createRef();

    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProyectos()
        if(this.props.inventario_id){
            this.getInventario()
        }

    }


    /**
     * @memberof ModalInventario
     * @function getProyectos
     *
     * @description Obtener lista de proyectos, que no tengan un Inventario.
     */
    getProyectos = (search) => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
                search,
                inventarios: false
            }
        }).then((proyectos) => {
            this.setState({proyectos: proyectos.data.data})
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }

     /**
     * @memberof ModalInventarios
     * @method getInventario
     * @description Obtiene un cliente de la DB
     */
    getInventario = () => {
        axios.get('/proyectos/inventarios/get', {
            params: {
                id: this.props.inventario_id
            }
        }).then(response => {
            console.log('labelIn', response.data.data)
            this.formInventario.current.setFieldsValue({
                proyecto_id: {
                    value: response.data.data.proyecto_id?._id,
                    key: response.data.data.proyecto_id?._id,
                    label: response.data.data.proyecto_id?.razon_social || response.data.data.proyecto_id?.nombre,
                },
                descripcion: response.data.data.descripcion,
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al traer el inventario')
        })
    }

    
    /**
     * @memberof ModalInventarios
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {

        values.proyecto_id = (values.proyecto_id?.value) ? values.proyecto_id?.value : this.props.proyecto_id
        if (this.props.inventario_id) {
            this.updateInventario(values)
        } else {
            this.addInventario(values)
        }
    }

    /**
     * @memberof ModalInventarios
     * @method addInventario
     * @description Añade un cliente a la BD
     */
    addInventario = (values) => {
        console.log("values", values);

        this.setState({loading: true})      
        axios.post('/proyectos/inventarios/add', {
            ...values
        }).then(response => {
            message.success('Inventario Añadido')
            this.props.onCancel()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al crear el Inventario.')
        }).finally(() => this.setState({ loading: false }))
        
        
    }

    /**
     * @memberof ModalInventarios
     * @method updateInventario
     * @description Actualiza la información de un cliente
     */
    updateInventario = (values) => {

        this.setState({ loading: true })
        axios.post('/proyectos/inventarios/update', {
            id: this.props.inventario_id,
            ...values
        }).then(response => {

           message.success("Inventario Actualizado")
           this.props.onCancel()

        }).catch(error => {
            message.error('Error al actualizar Inventario')
        }).finally(() => this.setState({ loading: false }))
    }

    render() {


        return [
            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title"> {this.props.inventario_id ? 'Editar' : 'Añadir'} Inventario</Title>
            </div>,
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.formInventario}
                    onFinish={this.onFinish}

                >
                    <Row align="center" gutter={[16]}>

                        {this.props.proyecto_id ? null : <Col xs={22}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id"
                                rules={[
                                    { required: true, message: "Seleccione un proyecto" }
                                ]} 
                            >
                                <Select
                                    labelInValue
                                    showSearch
                                    className="form-select"
                                    placeholder="Seleccione un Proyecto"
                                    onSearch={(value)=>this.getProyectos(value)}
                                    filterOption={false}
                                >
                                    {this.state.proyectos.map((proyecto) =>  <Option value={proyecto._id}>
                                        <CustomAvatar
                                            image={proyecto.logo}
                                            name={proyecto.nombre}
                                            color={proyecto.color}
                                            size="small"
                                            style={{
                                                marginRight: '5px'
                                            }}
                                        />
                                        {proyecto.nombre}
                                    </Option>)}

                                </Select>    
                            </Form.Item>
                        </Col>}
                        <Col xs={22}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea rows={4} placeholder="Descripción" maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>  

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}

export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalInventario {...props} />
    </Modal>


}
