import React, {
    Component
} from 'react';
import {
    Button,
    Col,
    Row,
    Select,
    Typography,
    Tag
} from 'antd'
import axios from 'axios';

import ReactEcharts from "echarts-for-react";

const {
    Title,
    Text
} = Typography;
const moment = require('moment')



/**
 * @class GraficaCompras
 * @description Grafica que indica las compras categorizadoas por proyecto
 */
export default class GraficaCompras extends Component {

    constructor(props) {
        super(props)
        this.state = {
            meses: []
        }
    }

    /***
     *
     * @methodOf GraficaCompras
     * @function componentDidMount
     * @description Obtenemos los proyectos y las Areas
     */
    componentDidMount = () => {
        let meses = []
        for(let i = 0; i < 5; i++){
            let month  = moment().subtract(i, "month").format('MMMM');
            meses.unshift(month)
            this.setState({meses})
        }

    }



    render() {

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: [{
                type: 'category',
                axisTick: {
                    show: false
                },
                data: this.state.meses
            }],
            yAxis: [{
                type: 'value'
            }],
            series: this.props.proyectos?.map(p => {
                return {
                    name: p.proyecto_id?.nombre,
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: p.montos
                }
            })
            
        };


        return ( <
            ReactEcharts option = {option} style = {{ minHeight: '400px'}}/> 
        )
    }
}