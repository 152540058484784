import React, { Component } from 'react';
import {Spin, Button, Col, Row, Typography, Progress, Breadcrumb, Pagination } from 'antd'

import { Link, Redirect } from 'react-router-dom';

//componentes
import { CardInfoDashboard, CardProgress, CardInfo } from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import { IconPlus } from "../../Widgets/Iconos";
import ModalObra from '../Modales/Inmuebles/ModalObra'
//css
import '../../../Styles/projectManager.css'

const axios = require('axios').default;

const moment = require('moment');

const { Text } = Typography;

export default class PMProject extends Component {

    constructor(props) {
        super(props)
        this.state = {
            redirectToInmueble: false,
            visible: false,
            view:'Crear',


            inmuebles: {
              data: [],
              page: 1,
              limit: 0,
              search: null,
              total: 0
            },


            proyecto: {
                proyecto_id: {

                },

                progreso_proyectado: 0,
                progreso_real: 0,


                costo_presupuestado: 0,
                costo_actual: 0,

                materiales_presupuestados: 0,
                materiales_entregados: 0,
                progresoMateriales:0, 


                m2_totales: 0,
                m2_actuales: 0,


            },

            obras_terminadas: 0,
            obras_proceso: 0,
            obras_sin_comienzo: 0,

            progresoProyectado: 0


        }
    }


    /**
     *
     *
     * @memberof PMProject
     */
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProject()
    }


    /**
     *
     *
     * @memberof PMProject
     */
    setRedirect = () => {
        this.setState({ redirectToInmueble: true })
    }



    /**
     *
     *
     * @memberof PMProject
     */
    renderInmueble = () => {
        if (this.state.redirectToInmueble) {
            return <Redirect to='/admin/project-manager/inmueble/1' />
        }
    }


    /**
     *
     *
     * @memberof PMProject
     * @method showModal
     * @description Cambia el state.visible a true, para mostrar al modal
     */
    modalToogle =() =>{

        const modal_visible = this.state.visible;
      //  console.log('visible toogle',modal_visible);
        this.setState({ visible: !modal_visible });
    }

    /**
     * @memberof PMProject
     * @method getProject
     * @description Cambia el state.visible a true, para mostrar al modal
     *
     *
     * @param {*} page Número de paginas
     * @param {*} limit Limite de elementos
     * @param {*} search Texto a buscar
     */
    getProject = (page= this.state.inmuebles.page, limit= this.state.inmuebles.limit, search= this.state.inmuebles.search) => {
        this.setState({loading: true})
        axios.get('/project-manager/proyecto',{
            params: {
                manager_proyecto_id: this.props.match.params.id
            }
        })
            .then(({data}) => {
                console.log('traje manager proyectos', data);

                this.setState(state => {
                    state.loading = false;
                    state.inmuebles = {
                        data: data.data.inmuebles.itemsList,
                        total: data.data.inmuebles.paginator.itemCount,
                        page,
                        limit,
                        search,
                    }
                    state.obras_terminadas = data.data.obras_terminadas
                    state.obras_proceso = data.data.obras_proceso
                    state.obras_sin_comienzo = data.data.obras_sin_comienzo
                    state.proyecto = data.data.proyecto

                    state.materiales_presupuestados= data.data.totalMaterialesRequeridos
                    state.materiales_entregados=  data.data.totalMaterialesEntregados
                    state.progresoMateriales= data.data.totalProcesoEntrega

                    //Actualizacion avance por fechas
                    if(state.proyecto){
                        console.log(data)

                        var fechaInicio = new Date( data.data.proyecto.fecha_inicio);
                        var fechaFin = new Date( data.data.proyecto.fecha_conclusion);
                        var fechaActual = new Date();
                        var avance = 0;
                        var fechaA = moment(fechaInicio);
                        var fechaB = moment(fechaFin);
                        var fechaC = moment(fechaActual);

                        var diferenciaAB = fechaB.diff (fechaA, 'days'); if(diferenciaAB < 0) {diferenciaAB =  (diferenciaAB * -1 )}
                        var diferenciaAC = fechaC.diff (fechaA, 'days');   if(diferenciaAC < 0) {diferenciaAC =  (diferenciaAC * -1 )}

                        avance = ( diferenciaAC * 100 ) / diferenciaAB

                        this.state.progresoProyectado =  avance;
                    }

                    return state;
                });

            })
            .catch(error => {
                console.log('no traje nanai', error);
            })
    }



    render() {

        return (
            <div>
                {/*{this.renderInmueble()}*/}
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} className="flex-left">
                            <Breadcrumb className="breadcrumb" separator=">">
                                <Breadcrumb.Item>
                                    <Link to="/admin/project-manager">Project Manager</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrumb-actual"> {this.state.proyecto.proyecto_id.nombre} </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <section className="p-1">

                        <Row gutter={[16, 24]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfoDashboard 
                                    title='Obras Terminadas' 
                                    number={this.state.obras_terminadas === 0 ? '0' : this.state.obras_terminadas} 
                                    background='linear-gradient(89.47deg, #39D0BB 0.39%, #00FF38 126.36%)' />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfoDashboard 
                                    title='Obras en Proceso' 
                                    number={this.state.obras_proceso === 0 ? '0' : this.state.obras_proceso} 
                                    background='linear-gradient(87.63deg, #ECC169 1.68%, #E76B38 141.52%)' />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfoDashboard 
                                    title='Obras Planeadas' 
                                    number={this.state.obras_sin_comienzo === 0 ? '0' : this.state.obras_sin_comienzo} 
                                    background='linear-gradient(89.26deg, #534DFC 0.54%, #9B22FF 91.03%)' />
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Fecha Inicio"
                                    titleRight="Fecha de Conclusion"

                                    infoLeft={(this.state.proyecto.fecha_inicio)?moment(this.state.proyecto.fecha_inicio).format('YYYY-MM-DD'):'N/A'}
                                    infoRight={(this.state.proyecto.fecha_conclusion)?moment(this.state.proyecto.fecha_conclusion).format('YYYY-MM-DD'):'N/A'} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Progreso Real"
                                    titleRight="Progreso Proyectado"
                                    infoLeft={<Progress type="circle" percent={this.state.proyecto.progreso_real.toMoney(2)} width={50} />}
                                    
                                    infoRight={<Progress type="circle" percent={this.state.progresoProyectado.toMoney(2)} width={50} />} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo

                                    titleLeft="Proyecto"
                                    infoLeft={<div>
                                        <Avatar
                                            name={this.state.proyecto.proyecto_id.nombre}
                                            color={this.state.proyecto.proyecto_id.color}
                                            image={this.state.proyecto.proyecto_id.logo}
                                            size="large"
                                        />
                                        &nbsp;
                                        {this.state.proyecto.proyecto_id.nombre}
                                    </div>}
                                  
                                />
                            </Col>

                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Costo Presupuestado"
                                    infoLeft={`${this.state.proyecto.costo_presupuestado.toMoney(2)} MXN`}

                                    titleRight="Costo Actual"
                                    infoRight={`${this.state.proyecto.costo_actual.toMoney(2)} MXN`}
                                />
                            </Col>
                            
                            {/* <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="M2 Totales"
                                    infoLeft={<div>{this.state.proyecto.m2_totales.toFixed(2)} m<sup>2</sup></div>}

                                    titleRight="M2 Actuales"
                                    infoRight={<div>{this.state.proyecto.m2_actuales.toFixed(2)} m<sup>2</sup></div>} />
                            </Col>
                             */}
                            <Col xs={24} lg={8} className="gutter-row">
                                <Link to={`/admin/project-manager/material/${this.props.match.params.id}`}>
                                <CardInfo
                                    className="hover-effect"

                                    titleLeft="Materiales Entregados"
                                    infoLeft={<Progress type="circle" percent={(parseFloat(this.state.progresoMateriales).toFixed(2))} width={50} style={{padding:"5px"}} />}

                                    titleRight="Materiales Presupuestados"
                                    infoRight={parseFloat(this.state.materiales_presupuestados).toMoney(2) + ' tons'}/>
                                </Link>


                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            {this.state.inmuebles.data.map(inmueble=> <Col xs={24} lg={8} className="gutter-row">
                                <Link to={`/admin/project-manager/proyecto/inmueble/${inmueble._id}`}>
                                    <CardProgress
                                        hover={true}
                                        title="Inmueble"
                                        name={inmueble.inmueble_id.nombre}
                                        start={(inmueble.fecha_inicio?moment(inmueble.fecha_inicio).format('YYYY-MM-DD'):null)}
                                        finish={(inmueble.fecha_conclusion?moment(inmueble.fecha_conclusion).format('YYYY-MM-DD'):null)}
                                        percent={inmueble.progreso_real} />
                                </Link>
                            </Col>
                        )}
                        </Row>

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <Pagination
                                    defaultCurrent={1}
                                    showSizeChanger={true}
                                    onChange={(page,limit) => this.getProject (page,limit)}
                                    total={this.state.inmuebles.total} />
                            </Col>
                        </Row>
                    </section>
                    <ModalObra
                        view={this.state.view}
                        projecto={this.state.projecto_id}
                        modalVisible={this.state.visible}
                        closeMethod={this.modalToogle}
                    />
                </Spin>
            </div>
        )
    }
}
