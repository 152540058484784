import React, { useEffect, useState } from "react";
import {  Form, InputNumber, Tag } from 'antd';



export default function FormValidation({ formRef, instructions = "", type = "", value = "", timing = null } = {}) {
    
    let timeout;
    let [timingRest, setTimingRest] = useState(0);
    let [update, setUpdate] = useState(false);

    useEffect(() => {
        setTimingRest(timing)        
        const interval = setInterval(() => {
            setTimingRest(seconds => {

                if (seconds === 0)
                    clearInterval(interval)

                return seconds - 1
            });


          }, 1000);
    }, []);




    var minutes = Math.floor(timingRest / 60);

    var seconds = timingRest - (minutes * 60);

    return <div className="modal-form">
        {instructions}
        
        <Form ref={formRef}>
            {(type === "numeric") ?
                <Form.Item>
                    <InputNumber value={value} disabled />
                </Form.Item>
                :
                (type === "qr") ?
                    <img src={'data:image/png;base64,' + value} />
                    : null}

            <br></br>
            {type === "qr" ? "Usar el codigo antes de" : "Ingrese un código antes de" }: <Tag>{(timingRest < 1) ? '0:00' : `${minutes}:${seconds}` }</Tag> segundos

            <Form.Item name="token" className="mt-1">
                <InputNumber disabled={timingRest < 1} />
            </Form.Item>
        </Form>
    </div>
}
