import React, { Component } from "react";
import { Layout, Row, Col, PageHeader, Space, Button, Dropdown, Form, List, Tag, Collapse, Typography, Card, message, Spin, Popconfirm } from "antd";
import { PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

//componentes
import { color_transaccion_tipo, color_tipos_facturas } from "../../Utils"
import { IconEye, IconEdit, IconArrowBack, IconDelete, IconLink, } from '../../Widgets/Iconos';
import { CardEmpresa, MontosFaltantes, CardEstatusContabilidad } from "../../Widgets/Cards/Cards";

//Modlaes
import ModalDetalle from '../Modales/Proveedores/ModalDetalle'
import ModalAsociacionFacturasTransacciones from "./ModalAsociacionFacturasTransacciones";
import ModalTransacciónDetalle from '../Modales/Finanzas/ModalTransacciónDetalle';
import ModalTransaccion from '../Modales/Finanzas/ModalTransaccion';
// import ModalXML from '../Finanzas/Facturas/ModalXML'
// import ModalFacturas from "../Finanzas/Facturas/ModalFacturas";
// import ModalTransacciones from "../Finanzas/Transacciones/ModalTransaccion";
// import ModalTransaccionesDetalle from "../Finanzas/Transacciones/ModalTransaccionDetalle";

const { Content } = Layout;
const { Title, Text } = Typography;
const axios = require('axios')
const moment = require('moment')
const { Panel } = Collapse;

const tipo = { "0": 'Sin Cambios', "1": ' Ingreso', "2": 'Egreso' }
const estatus_facturas = ['Factura Cancelada', 'Pendiente', 'Liquidad']

/**
 *
 *
 * @export
 * @class Empresa
 * @extends {Component}
 * @description Vista del listado de Empresa
 */
class Empresa extends Component {

    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            //active_panel: 1,
            empresa: {},
            facturas: {
                data: [],
                loading: false,
                page: 1,
                limit: 10,
                total: 0,
                filtroSearch: '',
                searching: true,

            },
            transacciones: {
                data: [],
                loading: false,
                page: 1,
                limit: 10,
                total: 0,
                filtroSearch: '',
                searching: true,

            },
            facturas_info: { incompleto: 0, sin_soporte: 0, completas: 0 },
            transacciones_faltantes: [],
            facturas_faltantes: [],

            filtroSearch: '',
            searching: true,
            showFiltro: {
            },

            modal_trans_editar: false,
            modal_trans_detalle: false,

            modal_fact_detalle: false,
            modal_factura_editar: false,
            modal_factura: false,


            transaccion_id: undefined,
            factura_id: undefined,
        }
    }

    filterForms = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getEmpresa();
        this.getFacturas();
        this.getTransacciones();
        this.getMontosFaltantes();

    }


    /**
      * @memberOf Empresa
      * 
      * @method getEmpresa
      * @description Obtiene la informacion de la empresa
      */
    getEmpresa = () => {
        axios.get(`/empresa/${this.props.match.params.empresa_id}`, {
            params: {
                amounts: true, //total de montos de transacciones y facturas
                info_facturas: true // cantidad de facturas por estatus
            }
        }).then(response => {
            this.setState({
                empresa:response.data,
                transacciones_montos: response.data.transacciones_montos,
                facturas_montos: response.data.facturas_montos,
                facturas_info: response.data.facturas_info,
            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer Montos Faltantes')
        })
    }

    /**
      * @memberOf Empresa
      * 
      * @method getFacturas
      * @description Obtiene la informacion de la empresa
      */
    getFacturas = (
        {
            page = this.state.facturas.page,
            limit = this.state.facturas.limit,
            search = this.state.facturas.search,

        } = this.state.facturas,
        { facturas } = this.state,
        //filters = this.filterForms.getFieldsValue()
    ) => {
        facturas.loading = true;
        facturas.page = page;
        facturas.limit = limit;
        facturas.search = search;
        this.setState({ facturas })

        axios.get('/facturas/list', {
            params: {
                page,
                limit,
                //filters,
                search,
                empresa_id: this.props.match.params.empresa_id
            }
        })
            .then(response => {
                this.setState({
                    facturas: {
                        ...this.state.facturas,
                        data: response.data.data.itemsList,
                        loading: false,
                        page: response.data.data.currentPage,
                        limit: response.data.data.perPage,
                        total: response.data.data.itemCount,
                    }
                })
            })
            .catch(error => {
                facturas.loading = false;
                this.setState({facturas})
                message.error('Error al Cargar las facturas')
              
            })

    }

    /**
     * @memberOf Empresa
     * @method getTransacciones
     * @description Obtiene todas todas las transacciones
    */
    getTransacciones = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
            search = this.state.transacciones.search,

        } = this.state.transacciones,
        { transacciones } = this.state,
        //filters = this.filterForms.getFieldsValue()
    ) => {
        transacciones.loading = true;
        transacciones.page = page;
        transacciones.limit = limit;
        transacciones.search = search;
        this.setState({ transacciones })
        axios.post('/transacciones', {
            page,
            limit,
            //filters,
            search,
            empresa_id: this.props.match.params.empresa_id
        })
            .then(res => {
                this.setState({
                    transacciones: {
                        ...this.state.transacciones,
                        data: res.data.data.data,
                        loading: false,
                        page: res.data.data.page,
                        limit: limit,
                        total: res.data.data.total,
                    }
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            })
    }

    /**
     * @memberOf Empresa
     * @method getMontosFaltantes
     * @description Obtiene los montos de transacciones y factutas que no estan relacionadas entre si
    */
    getMontosFaltantes = () => {
        axios.get(`/empresa/montos/${this.props.match.params.empresa_id}`, {
            params: {
            }
        }).then(response => {
            this.setState({
                transacciones_faltantes: response.data.transacciones,
                facturas_faltantes: response.data.facturas
            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Razon Socials')
        })
    }

   


    


    toogleFilters = (value) => {
        this.setState({ showFiltro: value });

        this.renderFiltros()
    }

    renderFiltros = () => {
        let tags = []
        let { showFiltro } = this.state;
        for (const filtro in showFiltro) {
            let array_filtro = showFiltro[filtro];
            for (const key in array_filtro) {
                tags.push(<Tag closable className="tag-filtro" closeIcon={<div className="point"></div>}>{array_filtro[key]}</Tag>)
            }

        }
        return tags;
    }

    onChangePanel = (key) => {
        this.setState({ active_panel: key })
    }

    updateInfo = () => {
        this.getEmpresa();
        this.getFacturas();
        this.getTransacciones();
        this.getMontosFaltantes();
    }

    /**
     *
     *
     * @param {*} tipo
     * @memberof Empresa
     * @description Renderiza el icono según el estatus
     */
    renderIconTransaccion = (tipo) => {
        switch (tipo) {
            case 1:
                return <PlusOutlined style={{ color: "currentColor" }} />
            case 2:
                return <MinusOutlined style={{ color: "currentColor" }} />
            case 3:
                return <SwapOutlined style={{ color: "currentColor" }} />
            case 4:
                return <StopOutlined style={{ color: "currentColor" }} />
        }
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <Row className="row-encabezado">

	                    <Col xs={24} xl={6} xxl={8} className="flex-left">
	                        <Button className="IconArrowBack" onClick={this.volver}>
	                            <IconArrowBack />
	                        </Button>
                            <Title level={4} className="view-title">Detalle Empresa</Title>
	                    </Col>
	                </Row>
                    <Content className=" p-1 ">
                        <Row>
                            <Col span={24}><Title className="subtitle-dashboard" level={2}>Conciliación</Title></Col>
                        </Row>
                        <Row className="mb-1">
                            <Col sm={24} lg={12} xl={8} xxl={6}>
                                <CardEmpresa 
                                    empresa={this.state.empresa} 
                                    transacciones={this.state.empresa.transacciones_amounts} 
                                    facturas={this.state.empresa.facturas_amounts} />
                            </Col>
                            <Col sm={24} lg={12} xl={8} xxl={6} className="grid">
                                <MontosFaltantes 
                                    transacciones={this.state.transacciones_faltantes} 
                                    facturas={this.state.facturas_faltantes} 
                                />
                            </Col>
                            <Col sm={24} lg={12} xl={8} xxl={6} className="grid">
                                <CardEstatusContabilidad {...this.state.facturas_info}/>
                            </Col>
                        </Row>
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col span={24}>
                                <Collapse defaultActiveKey={['1','2']} onChange={this.onChangePanel} ghost>
                                    <Panel header={<Title level={3}>Facturas</Title>} key="1">
                                        <List
                                            loading={this.state.facturas.loading}
                                            className="component-list "
                                            itemLayout="horizontal"
                                            locale={{ emptyText: 'Sin Facturas' }}
                                            dataSource={this.state.facturas.data}
                                            renderItem={item => (
                                                <List.Item className="component-list-item">
                                                    <Card className="card-list" bordered={false}>
                                                        <Row className="width-100">
                                                            <Col xs={24} md={6} lg={2} xl={3} className="center item-info">
                                                                <Text ellipsis strong>{moment(item?.fecha).format('DD/MM/YYYY')}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={9} className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={2} className="center item-info">
                                                                <span className="spn-tipo">
                                                                    <Text ellipsis className="text-gray-dark">{tipo[item?.tipo] ? tipo[item?.tipo] : 'Sin Tipo'}</Text>
                                                                </span>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={3}  className="center item-info">
                                                                <Text style={{ color: 'gray', fontWeight: "bold" }} >
                                                                $ {item.monto_restante.toMoney(true)} MXN</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={4} className="center item-info">
                                                                <Text style={{ color: color_tipos_facturas(item.tipo), fontWeight: "bold" }} >
                                                                {this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney(true)} MXN</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={3} className="center item-info">
                                                                <Space direction="horizontal">
                                                                    <Button
				                                                        className="button-survey btn-eye button-eye" 
				                                                        type="primary"
				                                                        icon={<IconEye/>}
				                                                        onClick={(e) => this.setState({factura: item, modalDetalleVisible:true})}
				                                                        //onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
				                                                        title="Ver XML"
				                                                        disabled={!!item.factura_xml}
				                                                    ></Button>

                                                                    <Button
				                                                        className="btn btn-link"
				                                                        icon={<IconLink />}
				                                                        type="primary"
				                                                        title="Link"
				                                                        onClick={() => this.setState({ modal_link: true, factura_id: item._id, factura: item })}

				                                                    >
				                                                    </Button>

                                                                    {/*<Button
                                                                        className="btn-editar"
                                                                        icon={<IconEdit />}
                                                                        type="primary"
                                                                        title="Editar"
                                                                        onClick={() => { this.setState({ modal_factura_editar: true, factura_id: item._id }) }}

                                                                    >
                                                                    </Button>*/}
                                                                    <Popconfirm
				                                                        placement="topRight"
				                                                        title="¿Deseas eliminar esta factura?"
				                                                        onConfirm={() => axios.post('/facturas/rechazar',{
				                                                                factura_id: item._id,
				                                                            })
				                                                            .then((response) => {
				                                                                message.success('Factura Eliminada')
				                                                                this.props.updateInfo()
				                                                            }).catch((error) => {
				                                                                message.error('Factura NO Eliminada')
				                                                                
				                                                            })
				                                                        }
				                                                        okText="Si"
				                                                        cancelText="No"
				                                                    >
				                                                        <Button
				                                                            className="btn btn-delete"
				                                                            type="primary"
				                                                            danger
				                                                            icon={<IconDelete />} title="Eliminar" />
				                                                    </Popconfirm>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                            pagination={{
                                                onChange: (page, limit) => {
                                                    this.getFacturas({ page, limit })
                                                },
                                                hideOnSinglePage: true,
                                                total: this.state.facturas.total
                                            }}
                                        />
                                    </Panel>
                                    <Panel header={<Title level={3}>Transacciones</Title>} key="2">
                                        <List
                                            loading={this.state.transacciones.loading}
                                            className="component-list "
                                            size="large"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: 'Sin Transacciones' }}
                                            dataSource={this.state.transacciones.data}
                                            renderItem={item => (

                                                <List.Item className="component-list-item ">
                                                    <Card className="card-list" bordered={false}>
                                                        <Row className="width-100">
                                                            <Col xs={24} md={6} lg={3} className="center item-info">
                                                                <Text ellipsis strong>{moment(item?.fecha).format('DD/MM/YYYY')}</Text>
                                                            </Col>
                                                            <Col xs={24} md={5}  className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                                            </Col>
                                                            <Col xs={24} md={4}  className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{(item?.area_id !== undefined) ? item?.area_id.nombre : 'Sin Área'}</Text>
                                                            </Col>
                                                            {/*<Col xs={24} md={6} lg={3} xl={3} className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{(item?.rubro_id !== undefined) ? item?.rubro_id.nombre : 'Sin Rubro'}</Text>
                                                            </Col>*/}

                                                            <Col xs={24} md={6} lg={4} xl={4} className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{item?.cuenta_id?.nombre}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={4} xl={4} className="center item-info">
                                                                <Text style={{ color: color_transaccion_tipo(item.tipo), fontWeight: "bold" }} >
                                                                {this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney()} MXN</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={4} xl={4} className="center item-info">
                                                                <Space direction="horizontal">
                                                                    <Button
				                                                        className="button-survey btn-eye button-eye" 
				                                                        icon={<IconEye />}
				                                                        type="primary"
				                                                        title="Ver Detalle"
				                                                        onClick={() => this.setState({ modalVisibleDetalle: true, transaccion_id: item._id })}

				                                                    >
				                                                    </Button>

                                                                    <Button
				                                                        className="btn btn-link"
				                                                        icon={<IconLink />}
				                                                        type="primary"
				                                                        title="Link"
				                                                        onClick={() => this.setState({ modal_link: true, transaccion_id: item._id })}

				                                                    >
				                                                    </Button>

                                                                    <Button
				                                                        className="btn btn-edit"
				                                                        icon={<IconEdit />}
				                                                        type="primary"
				                                                        title="Editar"
				                                                        onClick={() => this.setState({ modalVisibleTran: true, transaccion_id: item._id })}

				                                                    >
				                                                    </Button>
                                                                    <Popconfirm
				                                                        placement="topRight"
				                                                        title="¿Deseas eliminar esta Transacción?"
				                                                        onConfirm={() => axios.post('/transacciones/delete', { id: item._id })
				                                                            .then((response) => {
				                                                                message.success('Transaccion Eliminada')
				                                                                this.props.updateInfo()
				                                                            }).catch((error) => {
				                                                                message.error('Transacción NO Eliminada')
				                                                                
				                                                            })
				                                                        }
				                                                        okText="Si"
				                                                        cancelText="No"
				                                                    >
				                                                        <Button
				                                                            className="btn btn-delete"
				                                                            type="primary"
				                                                            danger
				                                                            icon={<IconDelete />} title="Eliminar" />
				                                                    </Popconfirm>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                            pagination={{
                                                onChange: (page, limit) => {
                                                    this.getTransacciones({ page, limit })
                                                },
                                                hideOnSinglePage: true,
                                                total: this.state.transacciones.total,
                                            }}
                                        />
                                    </Panel>
                                </Collapse>

                            </Col>
                        </Row>

                        <ModalDetalle
		                    visible={this.state.modalDetalleVisible}
		                    closeMethod={()=>this.setState({
		                        modalDetalleVisible: false, factura: undefined, transaccion_id: undefined
		                    })}
		                    factura={this.state.factura}
		                    transaccion_id={this.state.transaccion_id}
		                />
		                <ModalAsociacionFacturasTransacciones
		                    visible={this.state.modal_link}
		                    onClose={() => {
		                        this.setState({ modal_link: false, transaccion_id: undefined, factura_id: undefined, factura: undefined})
		                        this.updateInfo()
		                    }}
		                    factura_id={this.state.factura_id}
		                    transaccion_id={this.state.transaccion_id}
		                    factura={this.state.factura}
		                    empresa_id={this.props.match.params.empresa_id}
		                />
		                <ModalTransacciónDetalle
		                    modalVisible={this.state.modalVisibleDetalle}
		                    registroId={this.state.transaccion_id}
		                    closeMethod={()=>this.setState({modalVisibleDetalle: false, transaccion_id: undefined})}
		                    accept={() => {
		                        this.setState({modalVisibleDetalle: false, transaccion_id: undefined})
		                        
		                    }}
		                />
		                <ModalTransaccion
		                    modalVisible={this.state.modalVisibleTran}
		                    accionModal={"Editar"}
		                    registroId={this.state.transaccion_id}
		                    closeMethod={()=>this.setState({
		                        modalVisibleTran: false,
		                        transaccion_id: undefined
		                    })}
		                    budgeting={false}
		                    accept={() => {
		                        this.setState({
		                            modalVisibleTran: false,
		                            transaccion_id: undefined
		                        })
		                        this.props.updateInfo();
		                    }}
		                />

                        {/*<ModalTransacciones
                            visible={this.state.modal_trans_editar}
                            transaccion_id={this.state.transaccion_id}
                            onClose={() => {
                                this.setState({ modal_trans_editar: false, transaccion_id: undefined })
                                this.getFacturas()
                                this.getEmpresa();
                            }}
                        />
                        <ModalFacturas
                            visible={this.state.modal_factura_editar}
                            onClose={() => {
                                this.setState({ modal_factura_editar: false, factura_id: undefined })
                                this.getFacturas()
                                this.getEmpresa();
                            }}
                            factura_id={this.state.factura_id}
                        />
                        <ModalTransaccionesDetalle
                            visible={this.state.modal_trans_detalle}
                            onClose={() => this.setState({
                                modal_trans_detalle: false,
                                transaccion_id: undefined
                            })}
                            id={this.state.transaccion_id}
                        />

                        <ModalAsociacionFacturasTransacciones
                            visible={this.state.modal_link}
                            onClose={(flag) => { 
                                this.setState({
                                    modal_link: false,
                                    transaccion_id: undefined,
                                    factura_id: undefined
                                })
                                if(flag === true ){
                                    this.updateInfo()
                                }
                            }}
                            factura_id={this.state.factura_id}
                            transaccion_id={this.state.transaccion_id}
                            //razon_social_id={this.props.match.params.razon_social_id}

                        />
                        <ModalXML
                            visible={this.state.ModalXMLVisible}
                            onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml_storage: undefined, xml: undefined })}
                            factura_xml_storage={this.state.factura_xml_storage}
                        />*/}


                        {/* <FloatingButton title="Nuevo registro" /> */}
                    </Content>
                </Spin>


            </>
        )
    }
}


export default function (params) {
    return <Empresa {...params}/>
}