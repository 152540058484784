import React, { Component } from 'react';
import { Button, Popconfirm, Space, Card, Row, List, Col, Spin, PageHeader, Layout, message, Typography } from 'antd'
import axios from 'axios';

//Componentes
import { IconDelete, IconEdit,IconArrowBack, IconPlus } from '../../../Widgets/Iconos';

//modal
import ModalProductosInventario from "./ModalProductosInventario";


const { Content } = Layout;
const { Text, Title } = Typography

/**
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista de Productos, Productos que estan asignados a un inventario en especifico
 */
export default class Productos extends Component {

    volver = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            inventario_id: this.props.match.params.inventario_id,
            proyecto_id: this.props.match.params.proyecto_id,
            proyecto:{},
            data: [],
            page: 1,
            limit: 10,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.props.updateFilterSearch(true);

        this.getProyecto()

        this.getProductos();
    }

    /**
    * @memberof Almacenes
    * @method getProyecto
    * @description  Obtiene la informacion del proyecto
    **/
    getProyecto = () => {
        axios.get('/proyectos/get',{
            params:{
                id: this.props.match.params.proyecto_id
            }
        }).then(response => {
            this.setState({proyecto: response.data.data})
        }).catch(error => {
            message.error('Error al obtener la información del proyecto')
        })
    }

    /**
     * @memberof Transacciones
     * 
     * @method getProductos
     * @description Obtiene los productos relacionados a este inventario
     */
    getProductos = (page = this.state.page) => {

        axios.get('/inventarios/productos',{
            params:{
                inventario_id: this.state.inventario_id,
                page
            }
        }).then(response => {
                console.log("response.data.data.data", response.data.data.data);
            this.setState({
                productos: response.data.data.data,
                page: response.data.data.page,
                total: response.data.data.total,

            })

        }).catch(error =>{
            message.error('Error al obtener los productos')
        })
       
    }


    showModal = (producto_id = undefined) => this.setState({ modalAlmacenProducto: true, producto_id })

   
    render() {

        const { showModal } = this
        const { productos, page, limit, total } = this.state


        return (
            <Spin spinning={false}>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <Title level={4} className="view-title">Productos del Inventario de {this.state.proyecto?.nombre}</Title>
                    </Col>
                </Row>
                <Content className="p-1">
                    <List
                        className="component-list scroll"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Productos en el Inventario" }}
                        dataSource={productos}
                        pagination={{
                            onChange: (page, limit) => this.getProductos(page),
                            current: page,
                            pageSize: limit,
                            total: total,

                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left"
                        }}
                        header={<Row className="header-list width-100 pr-1 pl-1" >
                            <Col span={4} className="center">
                                <Text>Nombre</Text>
                            </Col>
                            <Col span={4} className="center">
                                <Text>SKU Proveedor</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text>Cantidad</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text>Inventario Mínimo</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text>Inventario Maximo</Text>
                            </Col>
                            <Col span={4} className="center">
                                <Text>Precio del Producto</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col span={4} className="center">
                                            <Text>{item.producto_id?.nombre}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <Text>{item.producto_opcion_id?.sku_proveedor}</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text>{item.cantidad}</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text>{item.inv_minimo}</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text>{item.inv_maximo}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <Text>$ {item.precio.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Space>
                                                <Button type="primary" className="btn-edit button-edit"icon={<IconEdit />} 
                                                    onClick={() => this.setState({modal_visible: true, inventario_producto_id: item._id})} 
                                                />
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este producto?"
                                                    onConfirm={() => axios.post('/inventarios/productos/deleteProducto', { id: item._id  })
                                                        .then((response) => {
                                                            message.success("Eliminado Correctamente")
                                                            this.getProductos()
                                                        })
                                                        .catch((error) => {
                                                            message.error("No es posible eliminar")
                                                            this.getProductos();
                                                        })
                                                    }
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button className="btn btn-delete" icon={<IconDelete />} title="Eliminar" />
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <Button className="btnAddPlus" title="Nuevo registro" onClick={()=>this.setState({modal_visible: true})}>
                        <IconPlus />
                    </Button>
                </Content>
                <ModalProductosInventario
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, inventario_producto_id: undefined})
                        this.getProductos()
                    }}
                    inventario_id={this.state.inventario_id}
                    inventario_producto_id={this.state.inventario_producto_id}
                />
                
            </Spin>
        )
    }
}