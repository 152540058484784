import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, Select, List, Typography, message, Spin,InputNumber, Carousel } from 'antd';
//componentes
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import DropzoneAntDesign from "../../../Widgets/AntdDropzone/DropzoneAntDesign";
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios').default;
const { Option } = Select;
const { Title, } = Typography;


export default class ModalImagenesTajo extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            tajos: [],
            inmueble: {},
            tajo:{},
            filesList: [],
            filesListInit: 0,
        }
    }

    //Referencia de form
    formImagenesTajo = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     *
     *
     * @memberof ModalImagenesTajo
     *
     * @function componentDidUpdate
     * @description cada vez que cambie la visivilidad del modal refresca los tajos diponibles, si se va editar trae la infomacion del tajo
     */
    componentDidUpdate(prevProps) {
    	if(this.props.tajo_id !== prevProps.tajo_id && this.props.tajo_id !== undefined){
            this.setState({
                filesList: Array.isArray(this.props.Tajo_fotos) ? this.props.Tajo_fotos.map(e => {
                        return {
                            name: e,
                            status: 'done',
                            url: axios.defaults.baseURL + '/upload/' + e,
                        }
                    }) : [],
                filesListInit: this.props.Tajo_fotos ? this.props.Tajo_fotos.length : 0 ,
            })
    	}
    }

    /**
    * @memberof ModalImagenesTajo
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        if(this.props.etapa === 1){
            this.saveImages();
        }else{
            this.props.closeMethod();
        }
    }

    /**
    * @memberof ModalImagenesTajo
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
    	this.saveImages()
    }

    /**
    * @memberof ModalImagenesTajo
    *
    * @method   onFinish
    * @description  Guarga los nombres de las fotos guardadas por tajo
    *
    **/
    saveImages = () => {
        let filesList = this.formImagenesTajo.current.getFieldValue('filesList')
        filesList = filesList ? filesList.map(({name}) => name ) : []
        console.log('FilesList',filesList)
        if(filesList.length !== this.state.filesListInit){
            axios.post('/nominas/add/images/tajo',{
                nomina_id:      this.props.nomina_id,
                inmueble_id:    this.props.inmueble_id,
                tajo_id:        this.props.tajo_id,
                empleado_id:    this.props.empleado_id,
                filesList:      filesList,
            }).then(response => {
                console.log('guardadas', response)
                this.props.closeMethod();
            }).catch(error => {
                console.log('error',error)
                
            })
            
        }else{
            this.props.closeMethod();
        }
    }


    loadingFiles = (loading=false)=>{
        console.log('METODO PROP',loading)
        return loading
    }
   
    render() {
        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-rubro"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.modalTitle} Imagenes</Title>
                </div>
                 <Spin spinning={this.state.loading}>

                    { 
                        this.props.etapa === 1 ?<Form
                            layout="vertical"
                            name="formulario-imagenesTajos"
                            onFinish={this.onFinish}
                            ref={this.formImagenesTajo}
                            initialValues={{ filesList: this.state.filesList }}
                        >

                            <Row align="center">
                                <Col xs={22} md={20}>
                                    <Form.Item
                                    noStyle
                                    	valuePropName="fileList"
                                        name='filesList'//<--------------------------------------- filesList
                                    >
                                        <DropzoneAntDesign metodo={this.loadingFiles}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row style={{ textAlign: "center" }}>
                                <Col xs={24}>
                                    <Form.Item >
                                        <Button loading={this.loadingFiles()}  htmlType="submit" type="primary" className="btn-azul" >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        :
                        <div>
                            <Modal
                                visible={this.state.modalImagePreview}
                                footer={null}
                                onCancel={() => this.setState({ modalImagePreview: false })}
                                className="image-previewer"
                                cancelButtonProps={{ style: { display: 'none' } }}
                            >
                                <img width='100%' src={this.state.imageURL} zoomPosition="original" zoomWidth={500} />

                            </Modal>

                            <Carousel slidesToShow={(this.state.filesList.length > 5) ? 6 : this.state.filesList.length} style={{ width: '100%', minWidth: '100%' }} >
                                {this.state.filesList.map((e, index) => <div>
                                    <div onClick={() => {
                                        this.setState({
                                            modalImagePreview: true,
                                            imageURL: axios.defaults.baseURL + '/upload/' + e.name
                                        })
                                    }} >
                                        <span style={{ fontSize: 1, color: 'white' }}>1</span>
                                        <img
                                            className="image-preview"
                                            src={axios.defaults.baseURL + '/upload/' + e.name} style={{
                                                // height:'160px',
                                                display: 'block',
                                                maxWidth: 'calc(100% - 10px)',

                                            }} />
                                    </div>
                                </div>)}
                            </Carousel>
                        </div>
                    }
                </Spin>
            </Modal>
        )
    }
}
