import React, { Component } from "react";
import {
    Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List,
    message, Divider, Upload, DatePicker, Spin
} from 'antd';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

class ModalInversion extends Component {

    modalInversion = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dataAreas:[],
            dataRubros:[],
            dataSubrubros:[],
            dataProyectos: {
                
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0

            },
            loading: false
        }
    }




    /**
    * @memberof ModalInversion
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
         axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadAreas();
        this.getProjects();
        this.getAsesores()

        if( this.props.inversion_id !== undefined ){
            
            this.state.inversion_id = this.props.inversion_id
            this.getInvenrsion(this.props.inversion_id)

        } else{
            this.onAreaChange('5f47fb3e833e8a58c6997281', '61280bc9b99e3642de6250d0');
            this.modalInversion.current.setFieldsValue({area_id: '5f47fb3e833e8a58c6997281'});
            
        }
    }


    getInvenrsion = (_id) =>{
        this.setState({loading: true})
        axios.get('/inversiones/get',{
            params:{
                _id
            }
        }).then(response => {
            console.log(response.data.data)
            //Actualiza los valores del form dentro del modal.
            let data = response.data.data.inversion

            this.onAreaChange(data.area_id._id, data.rubro_id._id, data.subrubro_id._id )

            let archivos = []
            if(data.documentos && data.documentos.length > 0){
                archivos = data.documentos.map(img=>{
                    return {uid: 1,
                    response: {
                        filename: img
                    },
                    name: img,
                    url: axios.defaults.baseURL + "/voucher/" + img,
                    status: 'done',
                }})
            }
            this.modalInversion.current.setFieldsValue({

                nombre: data.nombre,
                area_id: data.area_id._id,
                rubro_id: data.rubro_id._id,
                subrubro_id: data.subrubro_id._id,
                proyecto_id: {
                    label: data?.proyecto_id?.nombre,
                    key: data?.proyecto_id?._id,
                    value: data?.proyecto_id?._id
                },
                usuario_id: data.usuario_id._id,
                fecha_venta: moment(data.fecha_venta),
                monto_venta: data.monto_venta,
                valor_estimado_actual: data.valor_estimado_actual,
                documentos: archivos,

            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
  * @memberof ModalInversion
  * @method   onFinish
  * @description  Al completar formulario :
  * Se valida el tipo de accion modal para crear nuevo material o editar el registro seleccionado
  **/
    onFinish = async (values) => {
        values.proyecto_id =  values?.proyecto_id?.value ?? values?.proyecto_id
        let archivos = []
        if(values.documentos && values.documentos.length > 0){
            archivos = values.documentos.map(img=>img.name)
        }


        if(this.props.modalTitle === 'Editar'){
            axios.post('/inversiones/update',{
                _id: this.state.inversion_id,
                ...values,
                documentos: archivos,
            }).then(response =>{
                message.success('Inversion Actualizada')
                this.props.onClose()
            }).catch(error=>{
                message.error('Error')
                console.log(error)
            }) 

        }else{
            axios.post('/inversiones/add',{
                ...values,
                documentos: archivos,
            }).then(response =>{
                message.success('Inversion Creada')
                this.props.onClose()
            }).catch(error=>{
                message.error('Error')
                console.log(error)
            }) 
        }


    }

    /**
     * @function getProjects
     * @description Obtenemos los proyectos
     * */
    getProjects = ({

        page = this.state.dataProyectos.page,
        limit = this.state.dataProyectos.limit,
        search = this.state.dataProyectos.search,

    } = this.state.dataProyectos) => {

        this.setState({ dataProyectos: {...this.state.dataProyectos, page, limit, search} })

        axios.get('/proyectos', {
            params: {
                page, limit, search
            }
        }).then(({ data }) => {
            data=  data.data
            console.log("data", data)
            this.setState({ dataProyectos: {
                data: data.itemsList,
                total: data?.paginator?.itemCount,
                pages: data?.paginator?.pageCount,
            }})
        }).catch((error) => {
        })
    };

     /**
     * @function getAsesores
     * @description Obtenemos los usuarios y se desplegan como opcion de asesor
     * */
    getAsesores = async () => {
        await axios.get("/usuarios", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                paginate: false
            },
        })
            .then(({data}) => {
                this.setState({
                    dataAsesores: data.data,
                })
            })
            .catch(e => console.log('er', e))
    }

     /**
     *
     *
     * @memberof ModalClasificarTransacciones
     * @method loadAreas
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                dataAreas: response.data.data
            })
        }).catch((error) => {
        })
    }

    /**
     * @methodOF ModalClasificarTransacciones
     *
     * @function onAreaChange
     * @description Al actualizar un area, se deben traer los rubros y subrubros iniciales.
     * */
    onAreaChange = (area_id, rubro_id, sub_rubro_id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: area_id
            }
        }).then(async (rubros) => {
            this.setState({ dataRubros: rubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataRubros.length > 0) {
                console.log(rubro_id)
                this.modalInversion.current.setFieldsValue({
                    rubro_id: rubro_id ? rubro_id : rubros.data.data[0]._id,
                });

                let r = rubro_id ? rubro_id : rubros.data.data[0]._id
                this.onRubroChange(r, sub_rubro_id)
            } else {
                this.modalInversion.current.setFieldsValue({
                    rubro_id: undefined,
                    subrubro_id: undefined
                })
            }
        }).catch((error) => {
        })
    }

    /**
     *
     *
     * @memberof ModalClasificarTransacciones
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (rubro_id, sub_rubro_id) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: rubro_id
            }
        }).then((subrubros) => {
            this.setState({
                dataSubrubros: subrubros.data.data
            })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataSubrubros.length > 0) {
                this.modalInversion.current.setFieldsValue({
                    subrubro_id: sub_rubro_id ? sub_rubro_id : subrubros.data.data[0]._id
                });
            } else {
                this.modalInversion.current.setFieldsValue({
                    subrubro_id: undefined
                })
            }
        }).catch((error) => {

        })
    }

     /**
     *
     * @memberof ModalProveedor
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
                fileList[x].url = `${axios.defaults.baseURL}/voucher/${fileList[x].response.filename}`
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalProveedor
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        const { accionModal, area_id } = this.props;
        axios.post("/voucher/delete", {
            filename: image
        }).then(res => {
            console.log("imagen removida con éxito", res);
        }).catch(res => {
            console.log("imagen no se puedo remover", res);
        })
    };

    render() {


        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.modalInversion}
                    onFinish={this.onFinish}

                >
                    <Row align="center" gutter={20}>
                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Nombre"
                                name="nombre" //<--------------------------------------- nombre
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un material"
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Area"
                                name="area_id"//----------------------------------area_id
                                listType="text"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Area a que corresponde la transacccion."
                                }]}
                            >
                                <Select
                                    className="form-select"
                                    placeholder="Seleccione el Area"
                                    onChange={(value)=>this.onAreaChange(value)}
                                >
                                    {this.state.dataAreas.map(function (area, index) {
                                        return <Option value={area._id}>{area.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Rubro"
                                name="rubro_id"//----------------------------------rubro_id
                                listType="text"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el rubro"
                                }]}
                            >
                                <Select className="form-select" placeholder="Seleccione rubro"
                                    onChange={(value)=>this.onRubroChange(value)}>
                                    {this.state.dataRubros.map((rubro, index) => {
                                        return <Option value={rubro._id}>{rubro.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Subrubro"
                                name="subrubro_id"//----------------------------------subrubro_id
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el subrubro"
                                }]}
                            >
                                <Select className="form-select" placeholder="Seleccione subrubro"
                                >
                                    {this.state.dataSubrubros.map(function (subrubro, index) {
                                        return <Option value={subrubro._id}>{subrubro.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Monto Venta"
                                name="monto_venta" //<--------------------------------------- monto_venta
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un monto"
                                }]}
                            >
                                <InputNumber/>
                            </Form.Item>
                        </Col>
                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id" //<--------------------------------------- proyecto_id
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un material"
                                }]}
                            >
                            <Select
                                placeholder="Seleccione proyecto"

                                showSearch
                                filterOption={false}
                                showArrow
                                onSearch={search => this.getProjects({ search })}
                                labelInValue

                            >
                                {this.state.dataProyectos?.map(function ({ _id, nombre, logo, color }, index) {
                                    return <Option value={_id}>
                                        <CustomAvatar
                                            image={logo}
                                            name={nombre}
                                            color={color}
                                            size="small"
                                            style={{
                                                marginRight: '5px'
                                            }}
                                        />
                                        {nombre}
                                    </Option>
                                })}
                            </Select>
                        </Form.Item>
                        <IconArrowSelect />
                        </Col>
                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Contacto"
                                name="usuario_id" //<--------------------------------------- usuario_id
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un Contacto"
                                }]}
                            >   
                                <Select>
                                {this.state.dataAsesores?.map(function ({ _id, nombre, logo, color , apellido}, index) {
                                    return <Option value={_id}>
                                        <CustomAvatar
                                            image={logo}
                                            name={nombre}
                                            color={color}
                                            size="small"
                                            style={{
                                                marginRight: '5px'
                                            }}
                                        />
                                        {nombre} {apellido}
                                    </Option>
                                })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col sm={24} lg={8} className="gutter-row">
                            <Form.Item
                                label="Fecha de Venta"
                                name="fecha_venta" //<--------------------------------------- fecha_venta
                            >
                                 <DatePicker/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                    	<Col span={24}>
                    		<Title level={3} className="modal-title"> Información de la Inversión  </Title>
                    	</Col>
                    </Row>

                    <Row align="center" gutter={20}>
                    	<Col span={8}>
                    		<Form.Item
                                label="Monto Estimado Actual"
                                name="valor_estimado_actual" //<--------------------------------------- valor_estimado_actual
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un monto"
                                }]}
                            >
                                <InputNumber/>
                            </Form.Item>
                    	</Col>
                    </Row>

                    <Row align="center" gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                               <Upload
                                className="avatar-uploader"
                                action={axios.defaults.baseURL + "/voucher/add"}
                                multiple={false}
                                //maxCount = {2}

                                >
                                    <Button>
                                        <UploadOutlined /> Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {

    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className="modal-form modal-form-large"
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={onClose}>
                <IconCloseModal />
            </Button>
            <Title level={3} className="modal-title"> {props.modalTitle} Inversión  </Title>
        </div>
        <ModalInversion {...props} />
    </Modal>

}