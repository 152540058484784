import React, { Component } from 'react';
import { Button, Col, Modal, Row, Spin, Table, Typography, Popover, message } from 'antd'

//componentes
import { IconDelete, IconEdit, IconPlus, IconEmailSuccess, IconEmailWaitingForSend, IconCross, IconCuentas, IconEye } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import Logged from "../../../Hooks/Logged";
import { Redirect, Link } from 'react-router-dom'
//modales
import ModalCuenta from '../Modales/Cuentas/ModalCuentas';
import Search from 'antd/lib/input/Search';

//var QRCode = require('qrcode');
// import { useQRCode } from 'react-qrcodes';
// import QRCode from 'react-qr-code'

const { Title } = Typography;
const axios = require('axios').default;

export default class TEST extends Component {

    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            dataCuentas: [],
            loading: true,

            accionModal: 'crear',
            registroId: '',
            visible: false,
            tipo_user: 0,
            redirect: false,
            id_cuenta: '',
            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1, 
            mounted:false,

            filtroSearch: "",
            searching: true

        }
    }

    //Referencia
    modalCuenta = React.createRef();


    /**
     *
     *
     * @memberof Cuentas
     * 
     * @method componentDidMount
     * @description Manda a llamar al metodo receiveData para cargar los datos a la vista
     */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        console.log('RENDERING COMPONENT')
        console.log('VALUE',this.props.match.params.value)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setState({loading:false,mounted:true});
        


    }



    /**
     * @memberof Cuentas
     * @function renderRedirect
     * @description se encarga de enviar a la vista de enlace de cuenta con Belvo
     */
    renderRedirect = () => {
        if (this.state.redirect) {

            return <Redirect to={'/admin/cuentas/belvo/' + this.state.id_cuenta} />
        }
    }


    renderQR = () =>{
        console.log('Mira como renderizo papu')
        // if(this.state.mounted)
        // return <QRCode value={this.props.match.params.value} />
    }
 

    render() {
      


        const { visible, loading } = this.state;
        const user = this.context;
        return (
            <div>
                <Logged.Consumer>
                    {value => {
                        this.context = value
                    }}
                </Logged.Consumer>
                <Spin spinning={loading}>
                    <Row className="row-encabezado">
                        <Col xs={12} xl={20} xxl={20} className="flex-left">
                            <Title level={4} className="view-title">Cuentas</Title>
                        </Col>
                        <Col xs={12} xl={4} xxl={4} className="flex-right">
                            <Row align="right" style={{ textAlign: "right", display: "block" }}>
                                <Title level={5} style={{
                                    textAlign: "right",
                                    color: "#FFF",
                                    marginRight: '15px',
                                    marginTop: '7px',
                                    fontSize: '11pt'
                                }}> {this.state.itemCount} QR</Title>
                            </Row>
                        </Col>
                    </Row>


                    <Row className="p-1">
                        <div className="div_contenido">
                               {this.renderQR()}
                        </div>
                    </Row>
                </Spin>
            </div>
        )
    }
}
