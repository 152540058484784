import React from "react";
import { message, Modal, Typography, Select, Col, Row } from 'antd';

import FormularioValidacion from "../../../Widgets/FormularioValidacion";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
// import { useQRCode } from 'react-qrcodes';
//import QRCode from 'react-qr-code'
const axios = require('axios').default;




/**
 *
 * @param {*} params Contiene los parametros generales.
 * 
 * @param {*} params.error Respuesta del error, es la respuesta que viene por defecto en BELVO, tiene la siguiente estructura.
 * @param {*} params.onOk Cuando se valida exitosamente el Modal, se ejecuta y listo. 
 * @param {*} params.onDoesNotExist Cuando Belvo dice que no existe, comumente redirige a "No existe"
 * @param {*} params.onIsInvalid Normlamente cuando el token está vencido. 
 * @param {*} params.setLoading Declaramos cuando está cargando o no. 
 * 
 * 
 * @return {*} 
 * 
 * @method ValidaciónMFA CUando sale algúnb erropr de belvo, especificamente el 428, se ejecuta esta consulta que valida que el topken sea correcto.
 * 
 * 
 */
function ValidaciónMFA(params) {
    
    let { error, onOk = () => {}, onDoesNotExist = () => {}, onIsInvalid = () => {}, setLoading = () => {}, cuenta = null } = params;

    console.log(error)

    if(error.data?.statusCode !== 428 && error.data?.statusCode >= 400 && error.data?.handle){
        setLoading(false)
        return Modal.error({title: error.data.message})
    }
    
    error = error?.data?.detail?.find(detail => (detail.code === "does_not_exist" || detail.code === "invalid" || detail.code === "token_required"))
    if (error) {
        let errorContent = {}
        switch (error.code) {
            case "does_not_exist":
                errorContent = {
                    title: <Row>
                            <Col span={24}>La sesión de la cuenta en Belvo no es válida.</Col>
                            {cuenta ? <Col span={24} className="mt-1 center">Cuenta: <CustomAvatar image={cuenta.logo} name={cuenta.nombre} size="small" style={{marginLeft:'5px', marginRight: '5px'}}/>
                                {cuenta.nombre}
                            </Col>: null }
                        </Row>,
                    content: "Para poder seguir utilizando Belvo, es necesario registrar su cuenta.",
                    okText: "Iniciar sesión",
                    cancelText: "Cancelar",
                    onOk: onDoesNotExist,
                    onCancel: () => setLoading(false)
                }
                break;
            case "invalid":
                errorContent = {
                    title: <Row>
                            <Col span={24}>La sesión de la cuenta en Belvo se ha vencido.</Col>
                            {cuenta ? <Col span={24}  className="mt-1 center">Cuenta: <CustomAvatar image={cuenta.logo} name={cuenta.nombre} size="small" style={{marginLeft:'5px', marginRight: '5px'}}/>
                                {cuenta.nombre}
                            </Col>: null }
                        </Row>,
                    content: "Para poder seguir utilizando Belvo, es necesario volver a iniciar sesión.",
                    cancelText: "Cancelar",
                    okText: "Volver a iniciar sesión.",
                    onOk: onIsInvalid,
                    onCancel: () => setLoading(false)
                }
                break;
            case "token_required":
                var refForm = React.createRef();
                //console.log('TOKEN QR', (error.token_generation_data.type === "qr") ? error.token_generation_data.value : "N/A-")
                errorContent = {
                    title: <Row>
                            <Col span={24}>La sesión de la cuenta requiere validación adicional.</Col>
                            {cuenta ? <Col span={24} className="mt-1 center">Cuenta: <CustomAvatar image={cuenta.logo} name={cuenta.nombre} size="small" style={{marginLeft:'5px', marginRight: '5px'}}/>
                                {cuenta.nombre}
                            </Col>: null }
                        </Row>,
                    cancelText: "Cancelar",
                    content: <FormularioValidacion
                        formRef={refForm}
                        instructions={error.token_generation_data.instructions}
                        type={error.token_generation_data.type}
                        value={error.token_generation_data.value}
                        timing={parseInt(error.expiry)}
                    />,
                    okText: "Enviar",
                    onOk: () => {
                        let values = refForm.current.getFieldsValue();
                        axios.post('/belvo/cuenta/MFA', {
                            session: error.session,
                            link: error.link,
                            token: values.token
                        })
                            .then(response => {
                                console.log('Token MFA', response)
                                onOk()
                            })
                            .catch(error => {
                                console.log('error',error.response.data);
                                let errorElement = error?.response?.data?.detail?.find(detail => (detail.code === "does_not_exist" || detail.code === "invalid" || detail.code === "token_required"))
                                if (error?.response?.data.statusCode === 428 && errorElement !== null) {
                                    ValidaciónMFA(params)
                                }else if (error.response.data.handle){
                                    Modal.error({title: `${error.response.data.message}`})
                                }
                            })

                        .finally(() => setLoading(false))
                    },
                    onCancel: () => setLoading(false)
                }
                break;

        }
        return Modal.confirm(errorContent)

    } else message.error('Ocurrio un error')
}




export default ValidaciónMFA