import React, { Component } from "react";
import {
    Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List,
    message, Divider, Upload
} from 'antd';

import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;


class ModalMateriales extends Component {

    tipoRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            spinning: false,
            datosConsultados: false,
            return: false,
            tipos: [],
        }
    }

    modalMateriales = React.createRef();



    /**
    * @memberof ModalMateriales
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTipos();


        console.log('this.props.registroId', this.props.materialId);

        if (this.props.materialId !== null && this.props.materialId !== undefined) {
            this.getMaterial()
        } else {
            this.modalMateriales.current.setFieldsValue({
                tipo_id: this.props.tipoId
            })
        }
    }


    getMaterial = (id = this.props.materialId) => {
        axios.post('/materiales/get', {
            id
        })
            .then(({ data }) => {

                if (typeof data?.data?.imagen === "string")
                    data.data.imagen = [
                        {
                            name: data?.data?.imagen,
                            status: 'done',
                            url: axios.defaults.baseURL + "/upload/" + data?.data?.imagen,
                        },
                    ]
                this.modalMateriales.current.setFieldsValue(data.data)

            })
            .catch(error => console.log('error,', error))
    }

    /**
  * @memberof ModalMateriales
  * @method   onFinish
  * @description  Al completar formulario :
  * Se valida el tipo de accion modal para crear nuevo material o editar el registro seleccionado
  **/
    onFinish = async (values) => {
        console.log('values', values);
        const { materialId } = this.props;


        // imagen: [{uid: "rc-upload-1625595962538-3", lastModified: 1591594723623,…}]
        if (Array.isArray(values.imagen) && values.imagen.length > 0) {
            values.imagen = values.imagen[0].name
        }

        console.log('values', values);


        if (materialId !== undefined && materialId !== null) {

            axios.post('/materiales/update', {
                id: materialId,
                ...values
                // nombre,
                // detalles,
                // unidad,
                // existencias,
                // tipo_id: values.tipo_id,
                // imagen: image_name,
            })
                .then((res) => {
                    message.success("Material actualizado!");
                    this.props.onSuccess()

                })
                .catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : <div>
                            Error al procesar edición de registro. Verifique la información.
                            <br />
                            <p>
                                El campo nombre de material es unico e irrepetible.
                            </p>
                        </div>
                    });
                });



        } else {
            axios.post('/materiales/add', 
                  {
                      nombre : values.nombre,
                      existencias : values.existencias,
                      tipo_id:  this.props.tipoId
                  }
            )
                .then((res) => {
                    message.success("Material Registrado!");
                    this.props.onSuccess()

                })
                .catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : <div>
                            Error al procesar registro. Verifique la información.
                            <br />
                            <p>
                                El campo nombre de material es unico e irrepetible.
                            </p>
                        </div>
                    });
                });
        }
    }

    getTipos = (search) => {
        axios.get('/tipos/list', {
            params: {
                search
            }
        }).then(response => {
            console.log(response)
            this.setState({ tipos: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })
    }


    addTipo = (values) => {

        let tipo = this.tipoRef.current.state.value
        axios.post('/tipos/add', {
            nombre: tipo
        }).then(response => {
            let tipos = this.state.tipos
            tipos.push(response.data.data)
            this.setState({ tipos: tipos })
            this.tipoRef.current.state.value = null
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })
    }


    /**
    *
    * @memberof ModalProveedor
    *
    * @method normFile
    * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
    *
    * @param images (string)
    * Recibe el nombre de la imagen.
    */
    normFile = (e) => {
        const { file, fileList } = e;

        console.log(file, fileList)

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
                fileList[x].url = `${axios.defaults.baseURL}/upload/${fileList[x].response.filename}`
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalProveedor
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {

        axios.post("/upload/delete", {
            filename: image
        }).then(res => {
            console.log("imagen removida con éxito", res);
        }).catch(res => {
            console.log("imagen no se puedo remover", res);
        })
    };


    render() {
        const { modalVisible } = this.props;
        const { accionModal } = this.props;
        const { registroId } = this.props;
        const { registroNombre } = this.props;

        return (
            <Form
                layout="vertical"
                className="frm-cuentas"
                name="form-materiales"
                ref={this.modalMateriales}
                onFinish={this.onFinish}

            >
                <Row align="center">
                    <Col span={20}>
                        <Form.Item
                            label="Material"
                            name="nombre" //<--------------------------------------- nombre
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese un material"
                            }]}
                        >
                            <Input placeholder="Nombre material" />
                        </Form.Item>
                    </Col>

                    <Col span={20}>
                        <Form.Item
                            label="Existencias"
                            name="existencias" //<--------------------------------------- existencias
                        >
                            <InputNumber placeholder="Existencias" min={0} defaultValue={0} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ textAlign: "center" }}>
                    <Col span={24}>
                        <Form.Item >
                            <Button htmlType="submit" type="primary" className="btn-azul" >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={onClose}>
                <IconCloseModal />
            </Button>
            {/* {(accionModal === "crear") ? "Agregar" : "Editar"} */}
            <Title level={3} className="modal-title">  Material</Title>
        </div>
        <ModalMateriales {...props} />
    </Modal>

}