import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Input, Form, Space, Select, message, InputNumber, Typography } from 'antd'

import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
const { Option } = Select;
const { Text } = Typography;
const axios = require('axios')

/**
 * @const SalidaInventarioInputs
 * @description Inputs de el Form.List para la salida de inventario 
 */
const SalidaInventarioInputs = (props) => {

    const { value, onChange, key, name, restField, remove } = props

    const [proyecto, setProyecto] = useState([])
    const [producto, setProducto] = useState([])
    const [unidad, setUnidad] = useState('-')

    const [productos, setProductos] = useState([])
    const [proyectos, setProyectos] = useState([])
    const [almacenes, setAlmacenes] = useState([])
    const [inmuebles, setInmuebles] = useState([])


    const [conceptos, setConceptos] = useState([])
    const [subconceptos, setSubonceptos] = useState([])


    /**
     * 
     * @method getProductos
     * @decripcion Obtiene la lista de productos que hay en los almacenes
     * */
    const getProductos = (search) => {
    	axios.get('/almacen/productos',{
            params:{
                search: search,
                productos_general: true, //retorna los productos sin que se repitan
            }
        }).then(response => {
            setProductos(response.data.data.itemsList)
            
        }).catch(error => {
            message.error('Error al obtener la información del almacén')
        })
    }

    /**
     * 
     * @method getProductos
     * @decripcion Obtiene la lista de proyectos que tengan el producto en su inventario
     * */
    const getProyectos = (producto_id) => {
    	axios.get('/proyectos',{
    		params:{
    			pagination: false,
    			producto_id: producto_id,
    		}
    	}).then(response => {
    		setProducto(producto_id)
    		setProyectos(response.data.data)
    		let index = productos.findIndex(e=>e._id.toString() === producto_id.toString())
    		if(index !== -1){
    			setUnidad(productos[index].producto_id.unidad)
    		}

    	}).catch(error => {
    		console.log("error", error);

    	})
    }

    /**
     * 
     * @method getAlmacenes
     * @decripcion Obtiene la lista de almacenes que tiene le proyecto y que tengan el producto disponible
     * */
    const getAlmacenes = (proyecto_id) => {
    	axios.get('/proyectos/almacenes',{
    		params:{
    			paginate: false,
    			proyecto_id: proyecto_id,
    			producto_id: producto,
    		}
    	}).then(response => {
    		setAlmacenes(response.data.data)

    	}).catch(error => {
    		console.log("error", error);

    	})
    }

    /**
     * 
     * @method getPropiedades
     * @decripcion Obtiene la lista de inmuebles
     * */
    const getPropiedades = (search) => {
        axios.get('/tags', {
            params: {
                pagination: false,
                type: "inmuebles", //tipo: 2
                search: search,
            }
        })
            .then(res => {
                setInmuebles(res.data.data)
            })
            .catch(e =>
                console.log('error inmuebles get', e)
            )
    }

    

    //componentDidMount
    useEffect(() => {
        getProductos()
        getPropiedades()
    }, [])

    return (
        <Row
            key={key}
            style={{
                marginBottom: 8,
            }}
            gutter={[8]}
        >
        	<Col span={6} className="">

	            <Form.Item
	                {...restField}
	                name={[name, 'producto_id']}
	                rules={[
	                    {
	                        required: true,
	                        message: 'Seleccione el producto',
	                    },
	                ]}
	            >
	                <Select 
	                    showSearch
                        filterOption={false}
	                    onSearch={(search) => getProductos(search)}
	                    placeholder="Producto"
	                    className="form-select"
	                    onSelect={(proyecto_id) => { 
	                    	getProyectos(proyecto_id)
	                    }}
	                >
	                    {productos.map(({producto_id}) => (
	                        <Option value={producto_id._id} key={producto_id._id}>{producto_id.nombre}</Option>
	                    ))}
	                </Select>
	            </Form.Item>
			</Col>

			
	        <Col span={4} className="">
	            <Form.Item
	                {...restField}
	                name={[name, 'proyecto_id']}
	                rules={[
	                    {
	                        required: true,
	                        message: 'Seleccione el proyecto',
	                    },
	                ]}
	            >
	                <Select 
	                	className="form-select"
	                   
	                    placeholder="Proyecto"
	                    onSelect={(proyecto_id)=>{
	                    	getAlmacenes(proyecto_id)
	                    }}
	                >
	                    {proyectos.map((item) => (
	                        <Option key={item._id} value={item?._id}>
	                            {item.nombre}
	                        </Option>
	                    ))}
	                </Select>
	            </Form.Item>
            </Col>

       		<Col span={4} className="">
	            <Form.Item
	                {...restField}
	                name={[name, 'almacen_id']}
	                rules={[
	                    {
	                        required: true,
	                        message: 'Seleccione el Almacén',
	                    },
	                ]}
	            >
	                <Select 
	                    className="form-select"   
	                    
	                    placeholder="Almacén"
	                >
	                    {almacenes.map((item) => (
	                        <Option key={item._id} value={item?._id}>
	                            {item.nombre}
	                        </Option>
	                    ))}
	                </Select>
	            </Form.Item>
            </Col>

            <Col span={4} className="">
	            <Form.Item
	                {...restField}
	                name={[name, 'inmueble_id']}
	                rules={[
	                    {
	                        required: true,
	                        message: 'Seleccione el Inmueble',
	                    },
	                ]}
	            >
	                <Select 
	                       
	                    
	                    placeholder="Inmueble"
	                    className="form-select"
                        onSearch={(search) => getPropiedades(search)}
                        showSearch
                        filterOption={false}
	                >
	                    {inmuebles.map((item) => (
	                        <Option key={item._id} value={item?._id}>
	                            {item.nombre}
	                        </Option>
	                    ))}
	                </Select>
	            </Form.Item>
            </Col>

            <Col span={5} className="flex-left ">
	            <Form.Item
	                {...restField}
	                name={[name, 'volumen']}
	                rules={[
	                    {
	                        required: true,
	                        message: 'Ingrese el Volumen',
	                    },
	                ]}
	                style={{ width: 100, }}
	            >

	                <InputNumber style={{ width: 100, }} min={0} placeholder="Volumen"/>
	            </Form.Item>
	            <Text style={{ paddingBottom: 12, marginLeft: 8 }}>
	            	{unidad.toUnidad() === 'Toneladas' ? <Text>Ton <small>(Bultos)</small></Text> : unidad.toUnidad() }
	            </Text>
	        </Col>
           
            <Col span={1} className="center">
            	<MinusCircleOutlined onClick={() => remove(name)} />
            </Col>
        </Row>
    );
}



export default SalidaInventarioInputs;