import React, { Component } from "react";
import { Layout, Tooltip, Row, Col, Space, Button, List, Tag, Typography, Card, message, Spin, Popconfirm, Statistic, Popover, Avatar } from "antd";
import { IconEdit, IconDelete, IconDetails, IconArrowBack, IconPlus } from '../../Widgets/Iconos';
import { PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, } from "@ant-design/icons";


import CustomAvatar from "../../Widgets/Avatar/Avatar";
import ModalTransaccion from '../Modales/Finanzas/ModalTransaccion';
import ModalTransacciónDetalle from '../Modales/Finanzas/ModalTransacciónDetalle';
import ModalTraspaso from "../Modales/Finanzas/ModalTraspaso";
import ModalTraspasoDetalle from "../Modales/Finanzas/ModalTraspasoDetalle";

import ModalMultiple from '../Modales/Finanzas/ModalMultiple';
import DrawerFiltros from "../../Widgets/DrawerFiltros/DrawerFiltros";
import Sort from "../../Widgets/Sort";


const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')




/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de transacciones
 */
export default class Transacciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transaccion_id: undefined,


            transacciones: {
                data: [],

                page: 1,
                limit: 20,

                total: 0,
                pages: 0,


                filters: [],
                sort: {},

                loading: false
            }


        }
    }

    filterForms = React.createRef();
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.clasificadas) {
            this.setState({
                transacciones: {
                    filters: [
                        {
                            _id: 1,
                            name: 'tipo',
                            objectName: 'tipo'
                        },
                        {
                            _id: 2,
                            name: 'tipo',
                            objectName: 'tipo'
                        }]
                }
            })
            this.getTransacciones({
                filters: [
                    {
                        _id: 1,
                        name: 'tipo',
                        objectName: 'tipo'
                    },
                    {
                        _id: 2,
                        name: 'tipo',
                        objectName: 'tipo'
                    }]
            });

        }
        else {
            this.getTransacciones(1);
        }
    }

    componentDidUpdate() {
        if (

            (this.props.search != null && this.props.search != undefined && this.props.search != "")
            && this.state.search !== this.props.search
        )

            this.getTransacciones({ search: this.props.search });

    }

    /**
    *
    *
    * @memberof Transacciones
    * @method getTransacciones
    * @description Obtiene todas todas las transacciones
    * @param page página actual de la lista
    * @param limit cantidad de elementos por página
    * @param filters Filtros a aplicar a la página
    * @param sort Ordenar elementos
    * 
    */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        filters = this.state.transacciones.filters,
        sort = this.state.transacciones.sort,
        proyecto_id = this.props.match.params.proyecto_id,
        clasificacion = this.props.clasificadas,
        search = this.state.search
    } = this.state.transacciones) => {
        this.setState({ search, transacciones: { ...this.state.transacciones, page, limit, filters, sort, loading: true } });
        axios.post('/transacciones', {
            page, limit, filters, sort, search, clasificacion, proyecto_id
        })
            .then(({ data }) => {
                data = data.data;

                this.setState({
                    transacciones: {
                        ...this.state.transacciones,
                        data: data.data,
                        total: data.total,
                        pages: data.pages,
                        loading: false
                    }
                });
            })
            .catch(error => {
                console.log('error', error)
                message.error('No se pudieron cargar las transacciones')
            })
            .finally(() => this.setState({ loading: false }))
    }

    renderMonto = (tipo, monto) => {
        let colors = ['#00FF19', '#FF0000', '#000000', '#000000'];
        return <Statistic value={monto.toMoney()} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }
    /**
     *
     *
     * @param {*} tipo
     * @memberof Transacciones
     * @description Renderiza el icono según el estatus
     */
    renderIconTransaccion = (tipo) => {
        switch (tipo) {
            case 1:
                return <PlusOutlined style={{ color: "currentColor" }} />
            case 2:
                return <MinusOutlined style={{ color: "currentColor" }} />
            case 3:
                return <SwapOutlined style={{ color: "currentColor" }} />
            case 4:
                return <StopOutlined style={{ color: "currentColor" }} />
        }
    }

    /**
     *
     *
     * @param {*} key
     * @memberof Transacciones
     */
    setSort = (key) => {
        let value;
        switch (this.state.transacciones.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getTransacciones({
            sort: {
                ...this.state.transacciones.sort,
                [key]: value
            }
        })
    }
    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Transacciones
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters } = this.state.transacciones) => {
        return filters.map(filtro => {
            return <Tag
                className="text-black-tag"
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px', color: "black" }}
            // style={{  }}
            >
                <small className="text-black-tag">{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.nombre || filtro?.filter?.razon_social || filtro?.filter?.email || filtro?.filter?.folio}
            </Tag>
        });
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Transacciones
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        const { objectName } = filtro
        try {
            let values = this?.drawerFiltros?.formFiltros.getFieldValue(objectName)
            let index = values.findIndex(e => e === filtro._id)
            values.splice(index, 1)

            let newValues = {}
            newValues[objectName] = values

            if (this.drawerFiltros && this.drawerFiltros.formFiltros) this.drawerFiltros.formFiltros.setFieldsValue(newValues)

            if (this.drawerFiltros && this.drawerFiltros.formFiltros) this.drawerFiltros.formFiltros.submit()
            // this?.drawerFiltros?.formFiltros?.submit()
        } catch (error) {
            console.log('error', error)
            this.setState({ visibleFilters: true })
        }
    }

    modalCrearTransaccion = () => {
        this.setState({
            accionModal: 'crear',
            modalVisibleTran: true,
        })
    }

    modalEditarTransaccion = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;
        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisibleTran: true
        })
    };

    modalMultipleTransaccion = () => {
        this.setState({
            modalMultiple: true
        })
    };

    modalDetalleTransaccion = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;
        this.setState({
            registroId: reg_id,
            modalVisibleTranDetalle: true
        })
    };

    modalEditarTraspaso = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;
        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisibleTraspaso: true,
        })
    }

    modalDetalleTraspaso = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id
        this.setState({
            accionModal: 'detalle',
            registroId: reg_id,
            modalVisibleTran: false,
            modalVisibleTraspaso: false,
            modalVisibleTraspasoDetalle: true
        })
    }

    /*Modal traspaso */
    modalCrearTraspaso = () => {
        this.setState({
            accionModal: 'crear',
            modalVisibleTraspaso: true,
        })
    }

    hideModal = () => {
        this.setState({
            modalVisibleTran: false,
            modalVisibleTraspaso: false,
            modalVisibleTraspasoDetalle: false,
            modalVisibleTranDetalle: false,
            modalMultiple: false,
            transaccion_id: undefined
        })
    }

    renderTransaccionColor = (tipo) => {
        switch (tipo) {
            case 1:
                return "#28A745"
            case 2:
                return "#DC3545"
            case 3:
                return "#007BFF"
            case 4:
                return "#17A2B8"
        }
    }

    generarReporte = () => {

        const { filters } = this.state.transacciones

        let urlPDF = new URL(axios.defaults.baseURL + '/transacciones/reporte');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('filters', JSON.stringify(filters))

        window.open(urlPDF.href, '_blank')

    }

    back = () => {
        window.history.back();
    }

    render() {

        const contentMenuPlus = (
            <div className="div-menuplus">
                <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.modalCrearTransaccion}>
                    Transacción
                </Button>
                <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.modalMultipleTransaccion}>
                    Transacción multiple
                </Button>
                {(!this.props.clasificadas) ?
                    <Button htmlType="submit" type="primary" className="btn-azul" onClick={this.modalCrearTraspaso}>
                        Traspaso
                    </Button> : null}
            </div>
        );

        return (
            <>
                <Row className="row-encabezado text-white  pl-1" align="middle" wrap gutter={[16]}>
                    <Col flex="auto" style={{ display: 'flex', alignItems: 'center' }}>
                        {(this.props.clasificadas) ?
                            <Button className="IconArrowBack " onClick={this.back} >
                                <IconArrowBack />
                            </Button> : null}
                        <h2>
                            Transacciones
                        </h2>
                    </Col>
                    <Col flex="none">
                        <Button
                            style={{ backgroundColor: '#007BFF' }}
                            onClick={() => this.generarReporte()}
                            icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />}
                        >
                            Generar Reporte
                        </Button>
                    </Col>
                    <Col flex="none">
                        <Button
                            style={{ backgroundColor: '#007BFF', marginRight: "1em" }}
                            onClick={() => this.setState({ visibleFilters: true })}
                            icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />}
                        />
                    </Col>
                    <Col span={24}>
                        {this.renderFiltros()}
                    </Col>
                </Row>
                <Spin spinning={this.state.loading}>
                    <section className="p-1">

                        <Spin spinning={false}>
                            <Content className="admin-content content-bg pd-1">
                                <List
                                    loading={this.state.transacciones.loading}
                                    className="component-list"
                                    itemLayout="horizontal"
                                    dataSource={this.state.transacciones.data}
                                    pagination={{
                                        current: this.state.transacciones.page,
                                        total: this.state.transacciones.total,
                                        pageSize: this.state.transacciones.limit,
                                        showSizeChanger: true,
                                        onChange: (page, limit) => this.getTransacciones({ page, limit })
                                    }}

                                    header={<Row className="header-list width-100 pl-1 pr-1">
                                        <Col span={2} className=" center">
                                            <Sort
                                                sort={this.state.transacciones.sort["fecha"]} onClick={() => this.setSort("fecha")} >
                                                <Text strong >FECHA</Text>
                                            </Sort>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Sort
                                                sort={this.state.transacciones.sort["concepto"]} onClick={() => this.setSort("concepto")} >
                                                <Text strong >CONCEPTO</Text>
                                            </Sort>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Sort
                                                sort={this.state.transacciones.sort["area_id.nombre"]} onClick={() => this.setSort("area_id.nombre")} >
                                                <Text strong >ÁREA / RUBRO <br /> SUBRUBRO</Text>
                                            </Sort>
                                        </Col>
                                        {/* <Col xs={2} className="center">
                                            <Sort
                                                sort={this.state.transacciones.sort["rubro_id.nombre"]}
                                                onClick={() => this.setSort("rubro_id.nombre")} >
                                                <Text strong >RUBRO</Text>
                                            </Sort>
                                        </Col> */}
                                        <Col xs={3} className="center" style={{ textAlign: "center" }}>
                                            <Text strong >CLIENTE / PROVEEDOR / INVERSIÓN</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Sort
                                                sort={this.state.transacciones.sort["proyecto_id.nombre"]}
                                                onClick={() => this.setSort("proyecto_id.nombre")} >
                                                <Text strong >PROYECTO</Text>
                                            </Sort>
                                        </Col>
                                        <Col xs={3} className=" center">
                                            <Sort
                                                sort={this.state.transacciones.sort["cuenta_id.nombre"]}
                                                onClick={() => this.setSort("cuenta_id.nombre")} >
                                                <Text strong >CUENTA</Text>
                                            </Sort>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Sort
                                                sort={this.state.transacciones.sort["monto"]}
                                                onClick={() => this.setSort("monto")} >
                                                <Text strong >MONTO</Text>
                                            </Sort>
                                        </Col>
                                    </Row>
                                    }

                                    renderItem={item => {

                                        const renderProveedorInversionCliente = (item) => {
                                            if (item.cliente_id)
                                                if (item.propiedad_id && item.inmueble_id)
                                                    return (
                                                        <Space>
                                                            <Text>{item.cliente_id.nombre} {item.cliente_id.apellido}</Text>
                                                            <br />
                                                            <Text>{item.inmueble_id.nombre}</Text>
                                                        </Space>
                                                    )
                                                else
                                                    return (
                                                        <Space>
                                                            <Tooltip title="Cliente">
                                                                <Avatar size={"small"}>CLI</Avatar>
                                                            </Tooltip>
                                                            <Text>{item.cliente_id.nombre} {item.cliente_id.apellido}</Text>
                                                        </Space>
                                                    )

                                            if (item.proveedor_id)
                                                return <Space>
                                                    <Tooltip title="Proveedor">
                                                        <Avatar size={"small"} >PRV</Avatar>
                                                    </Tooltip>
                                                    <Text>{item.proveedor_id.alias}</Text>
                                                </Space>

                                            if (item.inversion_id)
                                                return <Space>
                                                    <Tooltip title="Inversión">
                                                        <Avatar size={"small"} >INV</Avatar>
                                                    </Tooltip>

                                                    <Text>{item.inversion_id.nombre}</Text>
                                                </Space>


                                        }

                                        return (
                                            <List.Item className="component-list-item">
                                                <Card className="card-list">
                                                    <div className={`badge-card tipo-${item.tipo}`}>
                                                    </div>
                                                    <Row className="width-100">
                                                        <Col span={2} className="center">
                                                            <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center">
                                                            <Text className="text-gray-dark">{item.concepto}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center">
                                                            <Text className="text-gray-dark">{item.area_id?.nombre} / {item.rubro_id?.nombre} <br /> {item.sub_rubro_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center">
                                                            <Text className="text-gray-dark">{renderProveedorInversionCliente(item)}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center">
                                                            <Text className="text-gray-dark">{(item.proyecto_id) ? <Space><CustomAvatar image={item.proyecto_id?.logo} name={item.proyecto_id?.nombre} color={item.proyecto_id?.color} />{item.proyecto_id?.nombre}</Space> : 'Sin Proyecto'}</Text>
                                                        </Col>
                                                        <Col xs={3} className=" center">
                                                            <Text className="text-gray-dark"> <CustomAvatar image={item.cuenta_id?.avatar} name={item.cuenta_id?.nombre} color={item.cuenta_id?.color} /> {item.cuenta_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center">
                                                            <Text style={{ fontWeight: "bold", color: this.renderTransaccionColor(item.tipo) }} >
                                                                {this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney()} MXN</Text>
                                                        </Col>
                                                        <Col xs={2} className="center">
                                                            <Space>
                                                                <Button
                                                                    icon={<IconDetails />}
                                                                    type="primary"
                                                                    title="Ver Detalle"
                                                                    className="btn"
                                                                    ghost
                                                                    onClick={() => {
                                                                        if (item.tipo == 3)
                                                                            this.setState({ modalVisibleTraspasoDetalle: true, transaccion_id: item._id })
                                                                        else
                                                                            this.setState({ modalVisibleTranDetalle: true, transaccion_id: item._id })
                                                                    }}
                                                                >
                                                                </Button>
                                                                <Button
                                                                    icon={<IconEdit />}
                                                                    type="primary"
                                                                    title="Editar"
                                                                    className="btn button-edit"
                                                                    onClick={() => {
                                                                        if (item.tipo == 3)
                                                                            this.setState({ modalVisibleTraspaso: true, transaccion_id: item._id })
                                                                        else
                                                                            this.setState({ modalVisibleTran: true, transaccion_id: item._id })
                                                                    }}
                                                                >

                                                                </Button>
                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    title="¿Deseas eliminar esta Transacción?"
                                                                    onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                                        message.success('Transaccion Eliminada')
                                                                        this.getTransacciones()
                                                                    }).catch((error) => {
                                                                        message.success('Transacción NO Eliminada')
                                                                        this.getTransacciones();
                                                                    })
                                                                    }
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                >
                                                                    <Button type="primary" className="btn ant-btn" danger icon={<IconDelete />} title="Eliminar" />
                                                                </Popconfirm>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )
                                    }}

                                />
                            </Content>
                        </Spin>

                    </section>
                </Spin>
                <DrawerFiltros
                    title="Filtrar Transacciones"
                    ref={ref => this.drawerFiltros = ref}
                    updateFilters={filters => this.getTransacciones({
                        page: 1,
                        filters
                    })}
                    csv="transacciones/list"
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    proveedores={false}
                    tipos={!this.props.clasificadas}
                    proyectos={!this.props.clasificadas}

                />


                <ModalTransaccion
                    ref={this.modalTransaccion}
                    modalVisible={this.state.modalVisibleTran}
                    accionModal={this.state.accionModal}
                    registroId={this.state.transaccion_id}
                    closeMethod={this.hideModal}
                    budgeting={true}
                    accept={() => {
                        this.hideModal();
                        this.getTransacciones();
                    }}
                >
                </ModalTransaccion>

                <ModalTransacciónDetalle
                    modalVisible={this.state.modalVisibleTranDetalle}
                    registroId={this.state.transaccion_id}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                />
                <ModalTraspaso
                    ref={this.modalTraspaso}
                    modalVisible={this.state.modalVisibleTraspaso}
                    accionModal={this.state.accionModal}
                    registroId={this.state.transaccion_id}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.setState({ modalVisibleTraspaso: false })
                        this.receivedData();
                    }}
                >
                </ModalTraspaso>

                <ModalTraspasoDetalle
                    modalVisible={this.state.modalVisibleTraspasoDetalle}
                    registroId={this.state.transaccion_id}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                />

                <ModalMultiple
                    modalVisible={this.state.modalMultiple}
                    hideModal={this.hideModal}
                />

                <Popover
                    content={contentMenuPlus}
                    title="Nuevo registro"
                >
                    <Button className="btnAddPlus">
                        <IconPlus />
                    </Button>
                </Popover>


            </>
        )
    }
}