import React, { Component } from 'react';
import { Button, Card, Col, message, Popover, Row, Spin, Statistic, Typography, Tag, List, Radio, Tooltip, DatePicker } from 'antd'
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';
//componentes
import { CardTransaccionesSC } from '../../Widgets/Cards/Cards';
import { IconDetails, IconCheckGreen, IconGoToLink, IconCheckAll, IconCheckTree, IconEye, IconArrowBack } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import Logged from "../../../Hooks/Logged";
//css
import 'react-multi-carousel/lib/styles.css';
import '../../../Styles/finanzas.css'
//modales
import ModalClasificarFact from '../Modales/Belvo/ModalClasificarFacturas';
import ModalXML from '../Modales/Belvo/ModalXML';

const moment = require('moment');
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

export default class FacturasSC extends Component {
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            bitacora: [],
            page: 1,
            limit: 10,
            loading: false,
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getBitacora()

    }


    /**
    * @memberof FacturasSC
    * @method   componentDidMount
    * @description  Lista las facturas de una cuenta, en un rango de fechas
    **/
    getBitacora = (page) => {
        this.setState({loading: true})
        axios.get('/belvo/bitacora', {
            params: {
                page: page,
                limit: this.state.limit
            }
        }).then(response => {
            this.setState({
                bitacora: response.data.data.itemsList,
                total: response.data.data.itemCount,
                page: response.data.data.currentPage

            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>{
            this.setState({loading: false})
        })
    }

    /**
    * @memberof FacturasSC
    * @method   componentDidMount
    * @description  Lista las facturas de una cuenta, en un rango de fechas
    **/
    renderMovimiento = (item) => {

        let facturas_mov = {
            0: 'Vinculo la cuenta a Belvo',
            1: 'Realizo una peticion a Facturas',
            2: `Clasifico ${item.facturas_ids ? item.facturas_ids.length : ''} Factura(s)`
        }

        let transacciones_mov = {
            0: 'Vinculo la cuenta a Belvo',
            1: 'Realizo una peticion a Transacciones',
            2: `Clasifico ${item.transacciones_ids ? item.transacciones_ids.length : ''} transaccione(s)`,
            3: `Clasifico ${item.transacciones_ids ? item.transacciones_ids.length : ''} transaccione(s) a un acreedor`,
            4: `Clasifico ${item.transacciones_ids ? item.transacciones_ids.length : ''} transaccione(s) para realizar traspaso a la cuenta ${item.cuenta_receptora_id?.nombre}`,
        }

        if (item.tipo_cuenta === 0) {
            return <Text>{transacciones_mov[item.tipo_movimiento]}</Text>
        } else if (item.tipo_cuenta === 1) {
            return <Text>{facturas_mov[item.tipo_movimiento]}</Text>
        }

    }




    render() {
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} md={12} className="flex-left">
                        <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline', paddingTop: '0px' }}><IconArrowBack /></Button>
                        <h2>Bitacora</h2>
                    </Col>
                </Row>

                <section className="p-1">
                    <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem' }} className="title-list-inmuebles">
                        <Col span={4} className="center">
                            <Text type="secondary">USUARIO</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">MOVIMIENTO</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">CUENTA</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">REQUEST ID</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">MENSAJE</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">FECHA</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">STATUS</Text>
                        </Col>
                    </Row>
                    <div className="card">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            loading={this.state.loading}
                            dataSource={this.state.bitacora}
                            pagination={{
                                current: this.state.page,
                                total: this.state.total,
                                pageSize: this.state.limit,
                                onChange: (page) => {
                                    this.setState({ page: page }, () => { this.getBitacora(page) })
                                },
                                onShowSizeChange: (page,size) => {
                                    this.setState({limit: size}, ()=>{this.getBitacora(1)})
                                }
                            }}
                            locale={{
                                emptyText: <div>
                                    No hay Movimientos
                                </div>
                            }}
                            renderItem={item => (
                                <List.Item className="component-list-item-small">
                                    <Card className="card-list-small">
                                        <Row style={{ width: '100%' }} className="">
                                            <Col span={4} className="center">
                                                <Text strong>{item.usuario_id.nombre} {item.usuario_id.apellido}</Text>&nbsp;&nbsp;
                                            </Col>
                                            <Col span={4} className="left">
                                                {this.renderMovimiento(item)}
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item.cuenta_id.nombre}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                
                                                <Text>{(item.belvo_request_id) ? <Tooltip title={item.belvo_request_id}>
                                                    {item.belvo_request_id.slice(0, 15)}&nbsp;<br />
                                                    {item.belvo_request_id.slice(15 )}
                                                    
                                                </Tooltip> : 'N/A'}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{(item.belvo_msg) ? item.belvo_msg : 'N/A'}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                {moment(item.createdAt).format('DD-MM-YYYY HH:mm')}
                                            </Col>
                                            <Col span={3} className="center">
                                                <Tag color={(item.belvo_request_id) ? '#f50' : '#87d068'}>{(item.belvo_request_id) ? 'Error' : 'Correcta'}</Tag>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </div>

                </section>

            </div>
        )
    }
}


