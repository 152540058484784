import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, DatePicker, message, Pagination, Checkbox, Dropdown, Menu, Popconfirm} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconEye, IconPlus,IconEdit, IconDelete, IconArrowCustom as Arrow, IconDownloadCustom as Download} from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
//modal
import ModalProspectosEstatus from '../Modales/Ventas/ModalProspectosEstatus';
//css
import '../../../Styles/ventas.css';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const axios = require('axios').default

export default class ProspectosEstatus extends Component{

	constructor(props){
		super(props)
		this.state = {
			projects: [],
			projectsSelected: [],
			estatuses: [],
			modalEstatusVisible: false,
			estatus: undefined,

			/* Paginado */
			page: 1,
			total: 0,
			pageCount: 10,

		}
	}


	/**
	* @memberof ProspectosEstatus
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.listEstatus(1)
	}

	/**
	 * @methodOf  Prospectos
	 * @function hideModal
	 *
	 * @description Cierra el modal
	 *
	 * */
	hideModal = () => {
		this.setState({ modalEstatusVisible: false, estatus: undefined });
		this.listEstatus(this.state.page)
	};

	/**
	 * @methodOf  Prospectos
	 * @function listEstatus
	 *
	 * @description Lista de los estatus en la BD
	 *
	 * */
	listEstatus = (page) => {
		this.setState({loading: true})
		axios.get('/estatus/list',{
			params:{
				page: page,
				limit: 10
			}
		}).then(response => {
			this.setState({
				estatuses: response.data.data.itemsList,
				page: response.data.data.paginator.currentPage,
        		total: response.data.data.paginator.itemCount,
        		pageCount: response.data.data.paginator.pageCount,
        		loading: false,
			})
		}).then(() => {
			//Si se elimina el utlimo registro de una pagina, te manda a la primera pagina
			if(this.state.page > this.state.pageCount){
				this.listEstatus(1)
			}
		})
        .catch(error => {
        	console.log(error)
        	message.error('No se cargaron los Estatus')
        	this.setState({loading: false})
        })
	};

	/**
	 * @methodOf  Prospectos
	 * @function deleteEstatus
	 *
	 * @description Lista de los estatus en la BD
	 *
	 * */
	deleteEstatus = (id) => {
		this.setState({loading: true})
		axios.post('/estatus/delete',{
			_id: id
		}).then(response => {
        	message.success('El estatus ha sido eliminado')
        	this.listEstatus(this.state.page)
        }).catch(error => {
        	console.log(error)
        	if(error.response.status === 403){
        		message.error(error.response.data.message)
        	}else
        		message.error('No se elimino el Estatus')

        	this.setState({loading:false})
        })
	};





	render() {
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} lg={8} className="">
						<h2 style={{width: 'auto', float: 'left'}}>Estatus</h2>
					</Col>
				</Row>
				<section className="p-1">
					<div className="scroll">
						<Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
							<Col span={10} className="center">
								<Text type="secondary">NOMBRE</Text>
							</Col>
							<Col span={10} className="center">
								<Text type="secondary">COLOR</Text>
							</Col>
							<Col span={4} className="center">
								<Text type="secondary">ACCIONES</Text>
							</Col>
						</Row>

						<List
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.estatuses}
							loading={this.state.loading}
							locale={{ emptyText: 'Noy ningun Estatus' }}
							renderItem={item =>
								(<List.Item className="component-list-item">
									<Card className="card-list">
										<Row style={{ width: '100%' }} className="">
											<Col span={10} className="center">
												<Text strong>{item.nombre}</Text>
											</Col>
											<Col span={10} className="center">
												<CustomAvatar
													color={item.color}
													size="large"
												/> 
											</Col>
											<Col span={4} className="center">
												<Button className="btn btn-edit" onClick={(e) => { this.setState({modalEstatusVisible: true, modalTitle: 'Editar', estatus: item}) }}>
													<IconEdit/>
												</Button>
												<Popconfirm
													placement="topRight"
													title={"¿Deseas eliminar el estatus " + item.nombre + " ?"}
													onCancel={e => e.stopPropagation()}
													okText="Si"
													cancelText="No"
													onClick={e => e.stopPropagation()}
													onConfirm={e => {this.deleteEstatus(item._id)}}
												>
													<Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
														<IconDelete />
													</Button>
												</Popconfirm>

											</Col>
										</Row>
									</Card>
								</List.Item>
								)}
						/>
					</div>
					<Row className="mt-2 pb-3">
						<Pagination
							total={this.state.total}
							current={this.state.page}
							onChange={(page) => {
									this.listEstatus(page)
								}}
							/>
						</Row>
				</section>
				<Button
					className="btnAddPlus"
					onClick={() => this.setState({modalEstatusVisible: true, modalTitle: 'Crear'})}>
					<IconPlus/>
				</Button>
				<ModalProspectosEstatus
					modalVisible={this.state.modalEstatusVisible}
					closeMethod={this.hideModal}
					modalTitle={this.state.modalTitle}
					estatus={this.state.estatus}
				/>
			</div>
		)
	}
}