import React, { Component } from 'react';
import { Button, Col, Row, Select, Typography } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
//componentes
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import ListaEntregasRecoleccion from './Listas/ListaEntregasRecoleccion';

import { IconPlus, IconArrowBack } from '../../Widgets/Iconos';
//modal

//css
import './../../../Styles/dashboard.css'

const { Title,Text } = Typography;
const moment = require('moment')



/**
 * @class CotizacionMaterialesComprador
 * @description Vista que se encarga de desplegar las requsiciones divididas por estatus, al comprador, que se encarga de realizar las cotizaciones de cada requesicion
 */
export default class CotizacionMaterialesComprador extends Component {

    volver = () => {
        window.history.back();
    }

    constructor(props){
        super(props)
        this.state = {
            modal_visible: false,
            modal_detalle: false,
        }
    }

    /**
     *
     * @methodOf CotizacionMaterialesComprador
     * @function componentDidMount
     * @description Obtenemos los proyectos y las Areas
     */
    componentDidMount = () => {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        window.scrollTo(0, 0);
    }

    /**
     *
     * @methodOf CotizacionMaterialesComprador
     * @function openModal
     * @description Abre el modal para per el detalle de la requesicion
     */
    openModal = () => {
        this.setState({modal_detalle: true})
    }



    render() {
        const { onDatesChange, onProjectsChange } = this;
        const { start, end, projectsSelected, area, rubro } = this.state;
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <Title level={4} className="view-title">[DIA] [MES] [AÑO]</Title>
                    </Col>
                </Row>

                <section className="p-1">

                    <ListaEntregasRecoleccion title="Entrega" estatus={4} />

                    <ListaEntregasRecoleccion title="Recoleccion" estatus={5} />

                    
                </section>

            </div>
        )
    }
}
    