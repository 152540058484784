import React, { Component } from "react";
import { Button, Modal, Typography, Row, Col, Select, List, message, Card, Popconfirm } from 'antd';

import { IconCloseModal } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';

import './../../../Styles/modales.css'


const { Title, Text } = Typography;
const { Option } = Select;

const axios = require('axios').default;
const moment = require('moment');


export default class ModalTicketQr extends Component {


    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            accionModal: '',
            spinning: true,
            datosConsultados: false,
            return: false,
            folio: "",
            orden: {},
            materiales: [],

        }
    }



    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    componentDidUpdate = (prevProps) => {
        console.log(this.props.registroId !== undefined && this.props.registroId !== prevProps.registroId);

        if (this.props.registroId !== undefined && this.props.registroId !== prevProps.registroId ) {
            this.getInfoOrden();
        }

    }

    hideModal = () => {
        this.setState({
            visible: false,
            accionModal: '',
            spinning: false,
            datosConsultados: false,
            return: false
        });

        this.props.closeMethod();
    }


    getInfoOrden = async () => {
        console.log("Consulta datos")
        const { modalVisible, accionModal, registroId, registroNombre } = this.props;
        console.log(registroId);

            axios.get('/ordenes/get', {
            params: {
                orden_id: registroId
            }
        }).then(response => {
            console.log(response)

            this.setState({
                folio: response.data.data.folio_id,
                orden: response.data.data,
                materiales: response.data.data.materiales,
                datosConsultados: true
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la Información')
            this.setState({ datosConsultados: true });
        })


    }



    render() {
        const { modalVisible, accionModal, registroId, registroNombre, orden, materiales } = this.props;
        var tituloModal = "";
        var contenido = "";

        if (accionModal === "detalle") {
            tituloModal = "Detalle orden " + this.state.folio;
            contenido = <Row className="detalleOrden">
                <Col span={24} className="col-date">
                    <Title className="title-date title-blue">Fecha de Requerimiento </Title>
                    <Text strong>{moment(orden?.fecha_creacion).format('DD-MM-YYYY')}</Text>
                </Col>

                <Col span={24}>
                    <Title className="title-blue" >Materiales </Title>

                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.materiales}
                        header={
                            <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles title-list-ordenesMaterial">
{/* 
                                <Col span={4} className="center">
                                    <Text type="secondary">Proyecto</Text>
                                </Col>
                                <Col span={10} className="center">
                                    <Text type="secondary">Nombre</Text>
                                </Col>
                                <Col span={10} className="center">
                                    <Text type="secondary">Detalles</Text>
                                </Col> */}


                            </Row>
                        }
                        renderItem={(item, index) => (
                            <List.Item className="component-list-item">
                                <List.Item.Meta
                                    style={{ width: "100%", paddingTop: "5px" }}
                                    className="item-meta"
                                    avatar={
                                        <Col >
                                            <CustomAvatar
                                                image={item.proyecto_id?.logo}
                                                name={item.proyecto_id?.nombre}
                                                color={item.proyecto_id?.color}
                                                size="large"
                                                style={{
                                                    marginRight: '5px',
                                                }}
                                            />
                                            {/* {item.proyecto_id?.nombre} */}
                                        </Col>
                                    }
                                    title={  item.material_id?.nombre} 
                                    description={  ((item.material_id?.detalles) ? item.material_id?.detalles : "") + "  " +  (item.especificaciones ? item.especificaciones : '') + "  " +(item.cantidad).toMoney() + "  " + ((item.unidad === 1) ? "tons." : "pzas.")} 
                                    
                                />

                                {/* <Row style={{ width: '100%' }} className="row-itemList-ordenesMaterial">
                                    <Col span={4} className="center">
                                        <Text strong>{item.material_id?.nombre}</Text>
                                    </Col>
                                    <Col span={6} className="center">
                                        <Text strong>{(item.material_id?.detalles) ? item.material_id?.detalles : "N/A"}</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>{item.especificaciones ? item.especificaciones : '-'}</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong><CustomAvatar
                                            image={item.proyecto_id?.logo}
                                            name={item.proyecto_id?.nombre}
                                            color={item.proyecto_id?.color}
                                            size="small"
                                            style={{
                                                marginRight: '5px'
                                            }}
                                        /> {item.proyecto_id?.nombre}</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>{(item.cantidad).toMoney() + " " + ((item.unidad === 1) ? "tons." : "pzas.")}</Text>
                                    </Col>


                                </Row> */}

                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        }




        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-ordenMaterial"
                destroyOnClose={true}
                zIndex={1500}
            >

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>

                    <Title level={3} className="modal-title"> {tituloModal} </Title>
                </div>

                {contenido}

            </Modal>
        )
    }
}
