import React, { Component } from 'react';
import { Row, Col, Card, Button, Avatar } from 'antd'

import { Link } from 'react-router-dom';

import { IconPlus, IconArrowBack, } from '../../../Widgets/Iconos';

import './../../../../Styles/proyecciones.css'

const moment = require('moment');

export default class DetalleProyeccionVenta extends Component {
    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            dataVentas: [],
            loading: true,

            accionModal: 'crear',
            registroId: '',
            loading: true,
            modalVisible: false,

            cliente: '',
            det_fecha: '',
            det_proyecto: '',
            det_rubro: '',
            det_subrubro: '',
            det_usuario: '',

            det_montoVenta: '',
            det_enganche: '',
            det_residual: '',
            det_plazo: '',
            det_tasaInteres: '',
            det_tipoTasa: '',
            det_pagos: '',
            det_montoFinal: '',

            /* Paginado */
            itemCount: undefined,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,
        }
    }

    componentDidMount() {

 //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        this.setState({
            loading: false,

            cliente: '{{Cliente}}',
            det_fecha: '{{Fecha}}',
            det_proyecto: '{{Proyecto}}',
            det_rubro: '{{Rubro}}',
            det_subrubro: '{{Subrubro}}',
            det_usuario: '{{Usuario}}',

            det_montoVenta: '{{Monto}}',
            det_enganche: '{{Enganche}}',
            det_residual: '{{Residual}}',
            det_plazo: '{{Plazo}}',
            det_tasaInteres: '{{Interes}}',
            det_tipoTasa: '{{TipoTasa}}',
            det_pagos: '{{Pagos}}',
            det_montoFinal: '{{MontoFinal}}',

        });
    }

    render() {

        return (
            <div>
                <Row className="row-encabezado">

                    <Col xs={24} xl={6} xxl={8}>
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <h2 style={{ width: 'auto', float: 'left' }}> Ventas</h2>
                    </Col>

                </Row>

                <section>
                    <Row>
                        <Col span={24}>
                            <Card
                                title=""
                                loading={this.state.loading}
                                bordered
                                className="card-proyeccionesDetalle"
                            >
                                <Row>
                                    <h1>{this.state.cliente} </h1>
                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Fecha</label>
                                        <p className="pInfo">{this.state.det_fecha}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Proyecto</label>
                                        <p className="pInfo">{this.state.det_proyecto}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Rubro</label>
                                        <p className="pInfo">{this.state.det_rubro}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Subrubro</label>
                                        <p className="pInfo">{this.state.det_subrubro}</p>
                                    </Col>

                                </Row>
                                <Col xs={24} lg={11} >
                                    <label className="lblTitulo">Usuario</label>
                                    <Avatar size="large" />
                                    <p className="pInfo">{this.state.det_usuario}</p>

                                </Col>
                                <Row>
                                </Row>

                            </Card>

                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Card
                                title=""
                                loading={this.state.loading}
                                bordered
                                className="card-proyeccionesDetalle"
                            >
                                <Row>
                                    <h1>Proyección de Venta</h1>
                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Monto Venta</label>
                                        <p className="pInfo">{this.state.det_montoVenta}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Enganche</label>
                                        <p className="pInfo">{this.state.det_enganche}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Residual</label>
                                        <p className="pInfo">{this.state.det_residual}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Plazo</label>
                                        <p className="pInfo">{this.state.det_plazo}</p>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Tasa de Interés</label>
                                        <p className="pInfo">{this.state.det_tasaInteres}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Tipo de Tasa</label>
                                        <p className="pInfo">{this.state.det_tipoTasa}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Pagos</label>
                                        <p className="pInfo">{this.state.det_pagos}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Monto Final</label>
                                        <p className="pInfo">{this.state.det_montoFinal}</p>
                                    </Col>
                                </Row>
                                <Row>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </section>

                <Button className="btnAddPlus" title="Nuevo registro" >
                    <IconPlus />
                </Button>
            </div>
        )
    }
}
