import React, { Component } from 'react';
import { Button, Col, Row, Spin, Empty, Typography, Card, Menu, Dropdown, message } from 'antd'
import { CloseCircleFilled, WarningFilled, CheckCircleFilled } from '@ant-design/icons';
import { FaArrowRight } from "react-icons/fa";
import { DownOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

//componentes
import { CardInfoDashboard, CardProveedor, CardFacturas } from '../../Widgets/Cards/Cards';
import { IconGoToLink, IconPlus } from '../../Widgets/Iconos';
import Avatar from "../../Widgets/Avatar/Avatar";
import ModalCotizacion from '../Modales/Proveedores/ModalCotizacion'
import ModalFactura from '../Modales/Proveedores/ModalFactura'
import ModalDetalle from '../Modales/Proveedores/ModalDetalle'
import ReporteFilter from './ReporteFilter'
import { ProveedoresReporte } from './ReporteCuenta'


//css
import '../../../Styles/Proveedores.css'

const axios = require('axios').default;
const { Text, Title } = Typography;
const moment = require('moment');



export default class ProveedoresDashboard extends Component {

    reporteRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            search: undefined,
            loading: false,
            proveedores: [],
            facturas: [],
            cotizaciones: 0,
            por_vencer: 0,
            vencidas: 0,
            modalDetalleVisible: false,
            factura: undefined,
            saldo_total: 0,
            saldo_por_vencer: 0,
        }
    }


    /**
     *
     *
     * @memberof ProveedoresDashboard
     * 
     * @method componentDidMount
     * @description 
     */
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.search !== undefined && this.props.search !== this.state.search) {
            this.setState({ search: this.props.search }, () => { this.getInfo() })
        } else {
            this.getInfo()
        }
    }

    /**
     *
     *
     * @memberof ProveedoresDashboard
     * 
     * @method componentDidUpdate
     * @description 
     */
    componentDidUpdate(prevProps) {
        console.log(this.props.search)
        if (this.props.search !== undefined && this.props.search !== this.state.search && this.props.search !== prevProps.search) {
            this.setState({ search: this.props.search }, () => { this.getInfo() })
        }
    }

    /**
    * @memberof ProveedoresDashboard
    * 
    * @method getInfo
    * @description 
    */
    getInfo() {
        this.setState({ loading: true })
        axios.get('/factura/dashboard', {
            params: {
                search: this.state.search
            }
        })
            .then(response => {
                this.setState({
                    proveedores: response.data.data.proveedores,
                    facturas: response.data.data.ultimas,
                    liquidadas: response.data.data.generales?.liquidadas,
                    por_vencer: response.data.data.generales?.por_vencer,
                    vencidas: response.data.data.generales?.vencidas,
                    saldo_total: response.data.data.generales.saldo_total,
                    saldo_por_vencer: response.data.data.generales.saldo_por_vencer,
                })
            }).catch(error => {
                console.log(error)
                message.error('Error al cargar los datos')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ProveedoresDashboard
     * 
     * @method closeMethod
     * @description 
     */
    closeMethod = () => {
        this.setState({ modalDetalleVisible: false, factura: undefined })
    }

    /**
    * @memberof getInfoReports
    * 
    * @method getTransacciones
    * @description trae las facturas de un proveedor para hacer el reporte
    */
    getInfoReports = async (proveedores, estatus) => {
        console.log('peticion', proveedores)
        axios.get('/proveedores/reporte', {
            params: {
                proveedores: proveedores,
                estatus: estatus
            }
        }).then(response => {
            console.log('response.data.LOM ', response.data.data)
            if (response.data.data.length > 0)
                this.reporteRef.current.printReporte(response.data.data[0])
            else
                message.error('No hay resultados para esta consultas')
        }).catch(error => {
            console.log(error)
        })

    }


    render() {
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={16} xxl={16}>
                            <h2 style={{ width: 'auto', float: 'left' }}> Proveedores </h2>
                        </Col>
                        <Col xs={24} xl={8} xxl={8} >
                            <ReporteFilter printReporte={this.getInfoReports} />
                            <ProveedoresReporte ref={this.reporteRef} />
                        </Col>

                    </Row>

                    <section className="p-1">

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row flex">
                                <CardInfoDashboard title='Saldo Total Proveedores' number={`$ ${this.state.saldo_total.toMoney()} MXN`}
                                    background='white' text={'black'} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row flex">
                                <CardInfoDashboard title='Saldo por Vencer' number={`$ ${this.state.saldo_por_vencer.toMoney()} MXN`}
                                    background='white' text={'black'} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <Card style={{ width: '100%', background: 'white' }} className={`PM-card-dashboard`}>
                                    <h3
                                        className={`PM-card-title black`}
                                        style={{ textAlign: 'left' }}
                                    > Resumen Facturas</h3>
                                    <Row>
                                        <Col span={8}><Text className="flex-between" style={{ width: '100%', color: '#FF4D4F', paddingRight: '1rem' }}><CloseCircleFilled />Vencidas</Text></Col>
                                        <Col span={12} className="center">{this.state.vencidas}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}><Text className="flex-between" style={{ width: '100%', color: '#FAAD14' }}><WarningFilled />Por Vencer</Text></Col>
                                        <Col span={12} className="center">{this.state.por_vencer}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}><Text className="flex-between" style={{ width: '100%', color: '#52C41A' }}><CheckCircleFilled />Liquidadas</Text></Col>
                                        <Col span={12} className="center">{this.state.liquidadas}</Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={[16, 24]} className="mt-1">
                            <Col xs={24} lg={16} className="gutter-row">
                                <Row>
                                    <Col span={24}>
                                        <Title level={3} className="sub-section-title">Proveedores</Title>
                                    </Col>
                                </Row>

                                <Row gutter={[16, 16]} className="scroll-proveedores">
                                    {
                                        this.state.proveedores.map(prov => <Col span={12}>
                                            <CardProveedor {...prov} />
                                        </Col>)
                                    }
                                </Row>

                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <Row>
                                    <Col span={24} className="flex-between">
                                        <Title level={3} className="sub-section-title">Facturas</Title>
                                        <Link to="/admin/compras/proveedores/facturas" className="button-link-to mr-1" type="link" ><IconGoToLink /></Link>
                                    </Col>
                                </Row>
                                <Row className="scroll-proveedores" style={{ paddingTop: '8px' }}>
                                    {
                                        this.state.facturas.map(fact => {
                                            return <Col span={24} className="mb-1">
                                                <CardFacturas {...fact} onClick={() => {
                                                    this.setState({ modalDetalleVisible: true, factura: fact })
                                                }} />
                                            </Col>
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Button className="btnAddPlus" title="Nuevo registro" onClick={() => { this.setState({ modalCotizacionVisible: true }) }} style={{ padding: "20px 20px" }}>
                            <IconPlus />
                        </Button>

                        <ModalCotizacion
                            visible={this.state.modalCotizacionVisible}
                            closeMethod={() => {
                                this.setState({ modalCotizacionVisible: false })
                                this.getInfo()
                            }}
                            crearCotizacion={true}
                        />
                        <ModalDetalle
                            visible={this.state.modalDetalleVisible}
                            closeMethod={this.closeMethod}
                            factura={this.state.factura}
                        />
                    </section>
                </Spin>
            </div>
        )
    }
}
