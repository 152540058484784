import React, { Component } from "react";
import { Dropdown, Button, Menu, Avatar } from "antd";
import { DownOutlined } from '@ant-design/icons';

const axios = require('axios')
/**
 *
 *
 * @export
 * @class EmpleadosFilter
 * @extends {Component}
 */
export default class EmpleadosFilter extends Component {

    state = {
        start: undefined,
        end: undefined,
        next: null,
        data: [],
        empleado: null
    }


    componentDidMount() {
        this.getEmpleados()
    }
    /**
     *
     *
     * @memberof EmpleadosFilter
     * @description Trae los empleados que estan cargados en la nomina
     */
    getEmpleados = (page = this.state.page) => {
        axios.get('/nominas/revision/filtro/empleados', {
            params: {
                page: page,
                nomina: this.props.nomina
            }
        })
            .then(res => {
                console.log('res', res.data.data);
                let data = res.data.data.itemsList;
                this.setState({ data: data, page: res.data.data.currentPage })
            })
            .catch(error => {
                console.log('error', error);
            })
    }


    /**
     *
     *
     * @memberof EmpleadosFilter
     * @description Carga mas datoa de los empleados
     */
    loadMore = (page) => {
        this.getEmpleados(page)
    }

    updateEmpleado = async (id) => {
        if (this.state.empleado === id)
            id = undefined;
        await this.setState({ empleado: id })

        this.props.onChange(id)
    }

    render() {

        return (

            <Dropdown
                trigger={["click"]}
                className="dropdown-transparent"
                overlay={(
                    <Menu >
                        {this.state.data.map(({ _id, nombre, apellido }, index) => (
                         <Menu.Item key={_id} onClick={() => { this.updateEmpleado(_id) }}>
                            <input
                                onChange={() => this.updateEmpleado(_id)}
                                onClick={() => this.updateEmpleado(_id)}
                                type="radio"
                                name="empleado"
                                value={_id}
                                style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                checked={this.state.empleado === _id}
                            />
                            {nombre} {apellido}
                            </Menu.Item>
                        ) )}


                            {this.state.next !== null ? <Menu.Item  key='N' >
                                <Button disabled={this.state.loadMore} onClick={(e) => {
                                    this.loadMore(this.state.page + 1)
                                    e.stopPropagation();
                                }}> Cargar más </Button>
                            </Menu.Item> : null
                            }
                    </Menu>
                )}>
                        <Button style={{ minWidth: '150px' }}> Empleados  <DownOutlined /></Button>
            </Dropdown>

                )
                }
}