import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, List, Typography, message, Spin, InputNumber, Upload } from 'antd';
import './../../../../Styles/modales.css'

import { IconCloseModal, IconEdit, IconDelete } from '../../../Widgets/Iconos';
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { UploadOutlined} from '@ant-design/icons';
import axios from 'axios'
const moment = require('moment');

const { Title, } = Typography;

export default class ModalProveedor extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {

            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false,

            fileList: [],

            dataProveedor: [],
        }
    }

    //Referencia de form
    ModalProveedorRef = React.createRef();

    componentDidMount(): void {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    //Función para limpiar campos
    limpiarCamposModal = () => {
        if (this.ModalProveedorRef.current !==undefined) {
            this.ModalProveedorRef.current.setFieldsValue({
                nombre: '',
            });
        }
    }

    //Función que busca la info del registro seleccionado
    modalEditar = () => {
        const { registroId } = this.props;
        axios.post('/proveedores/get', {
            id: registroId,
        }).then((proveedor) => {
            let data = proveedor.data.data;
            let archivos = []
            if(data.documentos && data.documentos.length > 0){
                archivos = data.documentos.map(img=>{
                    return {uid: 1,
                    response: {
                        filename: img
                    },
                    name: img,
                    url: axios.defaults.baseURL + "/voucher/" + img,
                    status: 'done',
                }})
            }

            //Actualiza los valores del form dentro del modal.
            this.ModalProveedorRef.current.setFieldsValue({

                alias : data.alias,
                linea_credito: data.linea_credito,
                razon_social: data.razon_social,
                rfc:data.rfc,
                plazo: data.plazo,
                documentos: archivos,
 
            })
            this.setState({
                datosConsultados: true,
            })
        })
    }

    //Función de guardado-edición dependiendo de la propiedad "accionModal"
    btnSaveClickModal = async (values) => {
        const { accionModal, area_id } = this.props;

        if (accionModal === "crear") {
            let archivos = []
            if(values.documentos && values.documentos.length > 0){
                archivos = values.documentos.map(img=>img.name)
            }
            axios.post('/proveedores/add', {
                alias: values.alias,
                linea_credito: values.linea_credito,
                razon_social: values.razon_social,
                rfc: values.rfc,
                plazo: values.plazo,
                documentos: archivos,
            })
                .then((res) => {
                    message.success("¡Proveedor Registrado!");
                    this.props.accept()

                }).catch((error) => {
                    console.log(error);

                    Modal.warning({
                        zIndex: 10000,
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : <div>
                            Error al procesar registro.
                            <br />Verifique los datos ingresados.
                            <br /> <br />
                            <p>Los campos <strong>Alias, Razón Social y RFC</strong> son unicos e irrepetibles.</p>
                        </div>
                    });
                });
        }
        else if (accionModal === "editar") {
            const { registroId } = this.props;
            let archivos = []
            if(values.documentos && values.documentos.length > 0){
                archivos = values.documentos.map(img=>img.name)
            }
            axios.post('/proveedores/update', {
                id: registroId,
                alias: values.alias,
                linea_credito: values.linea_credito,
                razon_social: values.razon_social,
                rfc: values.rfc,
                plazo: values.plazo,
                documentos: archivos
            })
                .then((res) => {
                    message.success("¡Proveedor Actualizado!");
                    this.props.accept()

                }).catch((error) => {

                    Modal.warning({
                        zIndex:1000,
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : 
                        <div>
                            Error al procesar registro.
                            <br />Verifique los datos ingresados.
                            <br /> <br />
                            <p>Los campos <strong>Alias, Razón Social y RFC</strong> son unicos e irrepetibles.</p>
                        </div>
                    });
                });
        }
    }

    //Función que reinicializa los states y llama al método de cierre en componente padre
    hideModal = () => {
        //Reiniciar valores al cerrar modal
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
        })

        this.props.closeMethod();
    }

     /**
     *
     * @memberof ModalProveedor
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
                fileList[x].url = `${axios.defaults.baseURL}/voucher/${fileList[x].response.filename}`
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalProveedor
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        const { accionModal, area_id } = this.props;
        axios.post("/voucher/delete", {
            filename: image
        }).then(res => {
            console.log("imagen removida con éxito", res);
        }).catch(res => {
            console.log("imagen no se puedo remover", res);
        })
    };

    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/admin/compras/proveedores" />;
        }
    };

    render() {
        const { modalVisible } = this.props;
        const { accionModal } = this.props;
        const { registroId } = this.props;

        if (accionModal === "editar" && registroId !==undefined && registroId !=="" && (!this.state.datosConsultados)) {
            this.modalEditar();
        }

        var titulo = '';
        if (accionModal === "crear") {
            titulo = "Agregar Proveedor"
        }
        else if (accionModal === "editar") {
            titulo = "Editar Proveedor"
        }

        var contenido = "";

        if (accionModal === "crear" || accionModal === "editar") {
            contenido = <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={this.ModalProveedorRef}
                    className="frm-cuentas frm-proveedor"
                    name="formulario-proveedor"
                    onFinish={this.btnSaveClickModal}
                >

                    <Row align="">
                        <Col xs={24} md={12}  >
                            <Form.Item
                                label="Alias"
                                name="alias"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el alias"
                                }]}
                            >
                                <Input placeholder="Alias" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}  >
                            <Form.Item
                                label="Razón Social"
                                name="razon_social"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la razón social"
                                }]}
                            >
                                <Input placeholder="Razón Social" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}  >
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el RFC"
                                },{
                                    pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                                    message: 'RFC invalido'
                                }]}
                            >
                                <Input placeholder="RFC" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}  >
                            <Form.Item
                                label="Linea de Credito"
                                name="linea_credito"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la linea de credito"
                                }]}
                            >
                                <InputNumber placeholder="Linea de Credito" min={0} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}  >
                            <Form.Item
                                label="Plazo del credito"
                                name="plazo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el plazo de credito"
                                }]}
                            >
                                <InputNumber placeholder="Plazo de Credito" min={0} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                               <Upload
                                className="avatar-uploader"
                                action={axios.defaults.baseURL + "/voucher/add"}
                                multiple={false}
                                //maxCount = {2}

                                >
                                    <Button>
                                        <UploadOutlined /> Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                            </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        }

        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-proveedor"
                destroyOnClose={true}
                zIndex={100}
            >

                {this.renderRedirect()}

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{titulo}</Title>
                    {(accionModal === "detalle") ? <div className="modalEncOpciones">
                        <Button type="" className="ant-btn button-survey btn-edit button-edit ant-btn-primary" title="Editar" >
                            <IconEdit />
                        </Button>
                        <Button type="" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" title="Eliminar" >
                            <IconDelete />
                        </Button>
                    </div> : ''}
                </div>
                {contenido}
            </Modal>
        )
    }
}
