import React from 'react'
import { Drawer, Form, Select, Cascader, message, Badge, Button, DatePicker } from 'antd'
import axios from 'axios'
import { DownloadOutlined } from '@ant-design/icons'





const { Option } = Select





/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
export default class FiltrosDrawer extends React.Component {

    state = {

        categorias: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 15,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },
        subcategorias: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 15,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },
        proyectos: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 20,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },
        almacenes: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 20,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCategorias()
        this.getProyectos()
    }


    formFiltros = React.createRef()


    /**
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getProyectos
    * 
    * @description Obtenemos los proveedores
    */
    getProyectos = ({ page, limit, search } = this.state.proyectos, { proyectos } = this.state) => {

        proyectos.loading = true;
        proyectos.page = page;
        proyectos.limit = limit;
        this.setState({ proyectos })

        axios.get('/proyectos', {
            params: {
                page, limit, search,
                inventarios: true,
            }
        })
            .then(response => {
            	this.setState({
            		proyectos: {
            			data: response.data.data.itemsList,
            			page: response.data.data.paginator.currentPage,
            			total:  response.data.data.paginator.itemCount,
            		}
            	})
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.proyectos.loading = false
                    return state
                })
            })
    }

    /**
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getAlmacenes
    * 
    * @description Obtenemos los proveedores
    */
    getAlmacenes = ({ page, limit, search } = this.state.almacenes, { almacenes } = this.state) => {
        axios.get('/proyectos/almacenes',{
    		params:{
    			paginate: false,
    			proyecto_id: this.state.proyectos._id,

    		}
    	}).then(response => {
    		console.log("response", response.data);
    		this.setState({
    			almacenes:{
    				...this.state.almacenes,
    				data: response.data.data
    			}
    		})

    	}).catch(error => {
    		console.log("error", error);

    	})

    }


    /**
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getCategorias
    * 
    * @description Obtenemos los proveedores
    */
    getCategorias = ({ page, limit, search } = this.state.categorias, { categorias } = this.state) => {

        categorias.loading = true;
        categorias.page = page;
        categorias.limit = limit;
        this.setState({ categorias })

        axios.get('/categorias/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {
                categorias.data = (page === 1) ? response.data.data.itemsList : [...categorias.data, ...response.data.data.itemsList];
                categorias.dictionary = {}
                categorias.data.map(d => categorias.dictionary[d._id] = d)
                categorias.total = response.data.data.paginator.itemCount
                categorias.pages = response.data.data.paginator.pageCount
                categorias.loading = false;

                this.setState({ categorias })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.categorias.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getSubCategorias
    * 
    * @description Obtenemos los proveedores
    */
    getSubCategorias = ({ page, limit, search } = this.state.subcategorias, { subcategorias } = this.state,{ _id } = this.state.categorias) => {

        subcategorias.loading = true;
        subcategorias.page = page;
        subcategorias.limit = limit;
        this.setState({ subcategorias })

        axios.get('/sub-categorias/list', {
            params: {
                page, limit, search,
                categoria_id: _id
            }
        })
            .then(response => {

                subcategorias.data = (page === 1) ? response.data.data.itemsList : [...subcategorias.data, ...response.data.data.itemsList];


                subcategorias.dictionary = {}
                subcategorias.data.map(d => subcategorias.dictionary[d._id] = d)

                subcategorias.total = response.data.data.paginator.itemCount
                subcategorias.pages = response.data.data.paginator.pageCount
                subcategorias.loading = false;

                this.formFiltros.current.setFieldsValue({
                    sub_categoria_id: null
                })

                this.setState({ subcategorias })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.subcategorias.loading = false
                    return state
                })
            })
    }


    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = (values) => {
        this.props.updateFilters(values)
    }


    render() {

        const { onClose, visible } = this.props
        const { clientes, proyectos, propiedades, cuentas, razones_sociales, clasificadores, ordenes, ordenes_compras, negocios, proveedores, sub_conceptos } = this.state

        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            visible={visible}
            >
            <Form
                ref={this.formFiltros}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >
                
                <Form.Item label="Categoria" name="categoria_id">
                    <Select
                        allowClear
                        
                        showSearch
                        filterOption={false}
                        onSearch={search => this.getCategorias({ search, page: 1 })}
                        onSelect={(categoria_id) => { 

                            // this.setState(state => {
                            //     state.categorias._id = categoria_id

                            //     return state
                            // }, () => this.getSubCategorias())
                        }}
                    >
                        {this.state.categorias.data.map(categoria => <Option key={categoria._id} value={categoria._id}>{categoria.nombre}</Option>)}
                    </Select>
                </Form.Item>

                {/*<Form.Item label="Sub categoria" name="sub_categoria_id">
                    <Select
                        allowClear
                        
                        showSearch
                        filterOption={false}
                        onSearch={search => this.getAreas({ search, page: 1 })}
                        onSelect={() => { }}
                    >
                        {this.state.subcategorias.data.map(sub => <Option key={sub._id} value={sub._id}>{sub.nombre}</Option>)}
                    </Select>
                </Form.Item>*/}

                <Form.Item label="Proyecto" name="proyecto_id">
                    <Select
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={search => this.getProyecto({ search, page: 1 })}
                        onSelect={(proyecto_id) => { 

                            this.setState(state => {
                                state.proyectos._id = proyecto_id

                                return state
                            }, () => this.getAlmacenes())
                        }}
                    >
                        {this.state.proyectos.data.map(proyecto => <Option key={proyecto._id} value={proyecto._id}>{proyecto.nombre}</Option>)}
                    </Select>
                </Form.Item>

                <Form.Item label="Almacen del proyecto" name="almacen_id">
                    <Select
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={search => this.getProyecto({ search, page: 1 })}
                        onSelect={() => { }}
                    >
                        {this.state.almacenes.data.map(proyecto => <Option key={proyecto._id} value={proyecto._id}>{proyecto.nombre}</Option>)}
                    </Select>
                </Form.Item>

            </Form>
        </Drawer>)
    }
};