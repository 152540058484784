import React, { Component } from 'react';
import { Spin, List, Button, Col, Row, Card, Typography, InputNumber, Modal, Select, Breadcrumb, message, Badge } from 'antd'
import { CameraOutlined,CheckOutlined } from '@ant-design/icons';
import { FaMinus } from "react-icons/fa";
import axios from 'axios';

import Avatar from '../../../Widgets/Avatar/Avatar'
import '../../../../Styles/nominas.css'
import { IconArrowBack, IconCamera, IconoMaquinaRegistradoraParaElGuardadoNominazFinanzas as IconNominas, IconCheck,IconCheckCircle } from '../../../Widgets/Iconos';
import Logged from "../../../../Hooks/Logged";
import DropzoneAntDesign from "../../../Widgets/AntdDropzone/DropzoneAntDesign";

import 'pure-react-carousel/dist/react-carousel.cjs.css'
import { parse } from 'accounting-js';
//modales
import ModalImagenesTajos from '../../Modales/Nomina/ModalImagenesTajos'


const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

/**
 * 
 * @class ListaTajos
 * @description Esta vista permite ver todas los tajos dado el empleado_id y el inmueble_id
 * 
 * @property finanzas Es solo para distinguir si la vista es finanzas o nó. 
 */
export default class ListaTajos extends Component {

    /**
     * 
     * @param contextType Obtenemos el usuario loggeado
     */
    static contextType = Logged;

    /**
     * 
     * @state loading Para establecer la carga de elementos
     * 
     * @state cuentas Arreglo de cuentas para el select. Solo en finanzas
     * @state tajos Arreglo de tajos para la lista. Poder poner el monto y todo.
     * @state inmueble Objeto inmueble con el nombre
     * @state empleado Objeto empleado con el nombre
     * @state etapa Si es prelminar, final o finanzas
     * @state Si es final, preelminar o finanzas.
     * 
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cuentas: [],
            tajos: [],

            inmueble: {},
            empleado: {},
            inmueble_id_fotos:null,
            etapa: 1,
            title: "",

            ModalImagenesTajosVisible: false,
            Tajo_idAddImages: undefined,
            Tajo_fotos: [],

            montos_editados: false, //variable bandera que indica si los montos fueron editados
            montos_autorizados: [ //arreglo donde se guarda una copia de monto_autorizado de cada tajo
                {
                    id: '',
                    monto_autorizado: 0
                }
            ]
        }
    }

    componentDidMount() {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setEtapa()
        this.getTajos();
        this.loadCuentas()
    }


    /**
     *
     *
     * @memberof ListaTajos
     *
     * @function setEtapa Declaramos la etapa actual de la nomina, sea 1) preliminar, 2) final y 3) finanzas
     *
     */
    setEtapa = () => {
        const user = this.context;

        let etapa, title;
        // Establcemos la etapa actual
        if (this.props.finanzas === true) {
            // Finanzas
            etapa = 3;
            title = "Nominas a Finanzas"
        } else if (user && user.tipo === 1) {
            // Final
            etapa = 2
            title = "Revisión Final"
        } else {
            // Preliminar
            etapa = 1
            title = "Revisión Preliminar"
        }
        //this.state.etapa = etapa
        this.setState({ title, etapa })
    }


    /**
     * @memberof ListaTajos
     *
     * @function updateMonto 
     *
     * @description Ejecuta cuando se cambia el monto a pagar y actualiza los m2 trabajados en relacion al precio por m2 y el monto a pagar
     * @param value int valor nuevo
     * @param index int index del elemto que se modifico
     */
    updateMonto = (value, id) => {
        let { tajos, montos_autorizados } = this.state;
        //Si es finanzas, se actualiza el monto real, caso contrario se actualiza el autorizado final ya que el dueño esta revisando

        if (this.props.finanzas === true) {
            tajos[id].monto_pagado = parseFloat(value)
            let deudaTemp = parseFloat(tajos[id].monto_original) - parseFloat(tajos[id].monto_pagado)

            tajos[id].monto_deuda = deudaTemp >= 0 ? deudaTemp : 0
        } else {
            tajos[id].monto_autorizado = parseFloat(value)
            montos_autorizados[id].monto_autorizado = parseFloat(value)
        }


        if (isNaN(tajos[id].m2_trabajados) || value === null || value === "" || tajos[id].precio_m2 === 0) {
            tajos[id].m2_trabajados = 0;
            if (this.props.finanzas === true) tajos[id].monto_deuda = 0;
        }

        this.setState({ tajos, montos_autorizados });

    }

    /**
     * @memberof ListaTajos
     *
     * @function getTajos 
     *
     * @description Obtememos la lista de tajos
     */
    getTajos = () => {
        this.setState({ loading: true })
        axios.post('/nominas/revision', {
            nomina_id: this.props.match.params.nomina_id,
            inmueble_id: this.props.match.params.inmueble_id,
            empleado_id: this.props.match.params.empleado_id,
        })
            .then(({ data }) => {
                const { inmueble, list,empleado } = data.data
                console.log('DATA DATA', data.data)
                this.setState({
                    tajos: list,
                    inmueble,
                    empleado,
                    montos_autorizados: this.state.montos_editados ? this.state.montos_autorizados : list.map(({ monto_autorizado, tajo_id }) => ({ tajo_id, monto_autorizado }))
                })
            })
            .catch(error => {
                console.log('error', error);
                Modal.confirm({
                    title: "No es posible guardar",
                    content: "Ha ocurrido un error al procesar "
                })
            })
            .finally(() => {
                this.setState({ loading: false })
                //si se actualizan los tajos en el state, no se pierdan los valores de monto_autorizado de cada tajo
                if (this.state.montos_autorizados && this.state.etapa === 1) {
                    const { tajos } = this.state;
                    tajos.forEach((tajo, index) => {
                        tajo.monto_autorizado = this.state.montos_autorizados[index].monto_autorizado
                    })
                    this.setState({ tajos })
                }
            })
    }

    /**
     * @memberof ListaTajos
     *
     * @function saveTajos 
     *
     * @description Guardamos los tajos en cuestion.
     */
    saveTajos = async () => {
        //verificar que todos los tajos tengan por lo menos una imagen si tiene un monto autorizado
        let tajos_con_imagenes = 0, tajos_con_monto_autizado = 0
        this.state.tajos.forEach(tajo => {
            if (tajo.empleado.inmuebles.tajos.fotos && tajo.empleado.inmuebles.tajos.fotos.length > 0) {
                tajos_con_imagenes++
            }
            if (tajo.monto_autorizado > 0) {
                tajos_con_monto_autizado++
            }
        })

        //si todos los tajos tienen lo guarda
        if (tajos_con_imagenes >= tajos_con_monto_autizado || this.state.etapa === 2) {
            this.setEtapa()
            this.setState({ loading: true })
            axios.post(((this.state.etapa === 1) ? '/nominas/revision/preliminar' : '/nominas/revision/final'), {
                nomina_id: this.props.match.params.nomina_id,
                inmueble_id: this.props.match.params.inmueble_id,
                usuario_id: this.props.match.params.empleado_id,
                tajos: this.state.tajos
            })
                .then(({ data }) => {
                    message.success("Guardado Correctamente")
                    this.props.history.goBack()
                })
                .catch(error => {
                    console.log('error', error);
                    Modal.confirm({
                        title: "No es posible guardar",
                        content: "Ha ocurrido un error al procesar "
                    })
                })
                .finally(() => {
                    this.setState({ loading: false })
                })

        } else {

            message.error("Los Tajos deben tener por lo menos una imagen");
        }
    }

 
    /**
     * @memberof ListaTajos
     *
     * @function UpdateCuenta
     * @description Ponemos la cuenta_id en cuestion
     *
     * @param {*} value ID de mongo de la cuenta
     * @param {*} id Indice del elemento a declarar la cuenta
     *
     * @returns
     */
    UpdateCuenta = (value, id) => {
        let { tajos } = this.state;
        tajos[id].cuenta_id = value
        this.setState({ tajos: [...tajos] })
    }

    /**
     * @memberof tajos
     *
     * @function hideModal
     * @description Cierra el modal
     */
    hideModal = () => {
        this.setState({
            ModalImagenesTajosVisible: false,
            Tajo_idAddImages: undefined,
            empleado_id: null
        })
        this.getTajos();
    }


    /**
     * @memberof ListaTajos
     * @method loadCuentas
     * @description Trae la lista de cuentas
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                pagination: false
            }
        })
            .then(res => {
                this.setState({ cuentas: res.data.data });
            })
            .catch(error => {
                console.log('lista cuentas error', error);
            })
    }




    /**
     * @memberof ListaTajos
     *
     * @function payTajo 
     *
     * @description realizarmos el pago (solo finanzas)
     * @param index Indice de los valores a enviar
     */
    payTajo = (index) => {
        let { tajos } = this.state;

        this.setState(state => {
            state.tajos[index].loading = true;
            return state;
        })

        let tajo = tajos[index]


        if (tajo.cuenta_id === null || tajo.cuenta_id === undefined) {
            this.setState(state => {
                state.tajos[index].loading = false;
                return state;
            })
            return Modal.error({
                title: "Debe seleccionar la cuenta"
            })
        }
        
        axios.post('/nominas/revision/finanzas', {
            nomina_id: tajo.nomina_id,
            inmueble_id: tajo.inmueble_id,
            usuario_id: tajo.usuario_id,
            cuenta_id: tajo.cuenta_id,
            tajo
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.tajos[index].registrado_finanzas = true;
                    return state;
                })
            })
            .catch(({ response }) => {
                console.log('response',response);
                Modal.error({
                    title: "No es posible generar la transaccion."
                })
            })
            .finally(e => this.setState(state => {
                state.tajos[index].loading = false;
                return state;
            }))

    }



    render() {
        // tipo="inmueble"
        // tipo="destajo" 

        const user = this.context;

        let etapa = this.state.etapa;
        let title = this.state.title

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={8} xxl={12} className="flex-left ">
                        <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline', paddingTop: '0px' }}><IconArrowBack /></Button>
                        <Breadcrumb separator={<span style={{ color: 'white' }}>></span>}>
                            <Breadcrumb.Item style={{ color: 'white', fontSize: '1.5em' }}>
                                {title}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item style={{ color: 'white', fontSize: '1.5em' }}>
                                Lista
                            </Breadcrumb.Item>
                            <Breadcrumb.Item style={{ color: 'white', fontSize: '1.5em' }}>
                                Tajos
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <Spin spinning={this.state.loading}>
                    <section className="p-1 content">
                        <Row gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col xs={24} sm={24} xl={24}>
                                <Card className="content-card">
                                    <Row>
                                        <Col span={18}>
                                            {(this.state.empleado !== null && this.state.empleado !== undefined) ? <Title level={3} style={{ color: "#0047FF", position: "relative", marginTop: 20, minWidth: '50%', display: "inline-block" }}> <span style={{ position: 'absolute', color: "rgba(0,0,0,0.5)", fontSize: 12, fontWeight: "lighter", top: -20, left: 3 }}>Empleado</span> {this.state.empleado.nombre} {this.state.empleado.apellido} </Title> : null}
                                            {(this.state.inmueble !== null && this.state.inmueble !== undefined) ? <Title level={3} style={{ color: "#0047FF", position: "relative", marginTop: 20, minWidth: '50%', display: "inline-block" }}> <span style={{ position: 'absolute', color: "rgba(0,0,0,0.5)", fontSize: 12, fontWeight: "lighter", top: -20, left: 3 }}>Inmueble</span>  {this.state.inmueble.nombre} </Title> : null}

                                        </Col>
                                    </Row>
                                    <Row className="information-widgets-content">
                                        <Col span={5}>
                                            <Paragraph className="information-widgets-content-title">Numero de {(this.state.inmueble !== null && this.state.inmueble !== undefined) ?'Empleados':'Inmuebles'}</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{this.state.tajos.length}</Paragraph>
                                        </Col>
                                        <Col span={10}>
                                            <Paragraph className="information-widgets-content-title">Total  {(this.state.inmueble !== null && this.state.inmueble !== undefined) ?'Inmueble':'Empleado'}</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {this.state.tajos.reduce((acomulador, tajo) => acomulador + tajo.monto_autorizado, 0).toString().toMoney()}</Paragraph>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="list-container" gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>{(this.state.inmueble !== null && this.state.inmueble !== undefined) ?'Empleados':'Inmuebles'}</Title>
                            </Col>
                            <Col span={24} style={{ paddingTop: 0 }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.tajos}
                                    className="list-employees"

                                    header={

                                        <Row style={{ width: '100%' }}>
                                            <Col span={(etapa === 1) ? ((this.props.tipo === "inmueble") ? 5 : 4) : (etapa === 2) ? ((this.props.tipo === "inmueble") ? 5 : 4) : ((this.props.tipo === "inmueble") ? 5 : 4)} >
                                                <Text strong>
                                                {(this.state.inmueble !== null && this.state.inmueble !== undefined) ?'Empleado':'Inmueble'}
                                                </Text>
                                            </Col>
                                            <Col span={(etapa === 1) ? 4 : (etapa === 2) ? 3 : 2} className="center">
                                                Actividad
                                            </Col>
                                            <Col span={(etapa === 1) ? 4 : (etapa === 2) ? 3 : 3} className="center">
                                                Total Pagado
                                            </Col>
                                            <Col span={(etapa === 1) ? 4 : (etapa === 2) ? 3 : 3} className="center">
                                                Presupuesto Autorizado
                                            </Col>

                                            {(etapa === 2 || etapa === 3) ? <Col span={(etapa === 1) ? 6 : (etapa === 2) ? 5 : 2} className="center">
                                                Monto Autorizado
                                            </Col> : null}
                                            <Col span={(etapa === 1) ? 4 : (etapa === 2) ? 4 : 3} className="">
                                                {(etapa === 3)?"Monto a Pagar":"Monto a Autorizar"}
                                            </Col>

                                            {(etapa === 3) ? <Col span={(etapa === 1) ? 6 : (etapa === 2) ? 5 : 2} className="center">
                                                <Text strong>Monto Deuda</Text>
                                            </Col> : null}
                                            {(etapa === 3) ? <Col span={(etapa === 1) ? 6 : (etapa === 2) ? 5 : 3} className="center">
                                                Cuenta
                                            </Col> : null}
                                            {(etapa === 3) ? <Col span={(etapa === 1) ? 1 : (etapa === 2) ? 5 : 1} className="center" style={{ display: 'flex' }}>
                                                Pagar
                                            </Col> : null}
                                            {etapa !==3 ? <Col span={(etapa === 1) ? 1 : (etapa === 2) ? 1 : 1} className="center" style={{ display: 'flex' }}>
                                                Fotos
                                            </Col> : null}
                                        </Row>
                                    }
                                    renderItem={

                                        /**
                                         * @memberof ListaTajos
                                         *
                                         * @function map 
                                         *
                                         * @description Declaramos la lista de tajos segun corresponda.
                                         * @param tajo Objeto TAJO de mongo
                                         * @param precio_m2 Precio en metros cuadrados
                                         * @param m2_trabajados Cantidad de metros cuadrados traajados
                                         * @param monto_autorizado Monto aurorizado por el empleado responsable y el empleado final
                                         * @param loading En finanzas, esto es para que spin este cargando. No aplica a Final ni preliminar
                                         * @param monto_original Esta cantidad es solo para mostrar la cantidad original y que el usuario pueda comprar. no se modifica ni se utiliza, solo de muestra
                                         * @param cuenta_id ID de la cuenta a la cual se ele ejcutara la transacino. Solo aplcai a finanzas
                                         * @param monto_deuda Si el monto pagado es menor al autoriado, la diferencia se agrega como monto pedniuente y se agrega a esta variable
                                         * @param monto_pagado Es la cantidad pagada previamente. SI no hay tal, aparece el mismo valor que el monto autorizado.
                                         * @param registrado_finanzas Status si ya esta registrada en finanzas o no. Para el purop span de la palomita
                                         */
                                        ({ tajo, inmueble, usuario, total_pagado, monto_autorizado, loading, empleado, cuenta_id, monto_deuda, monto_pagado, registrado_finanzas, precio_m2 }, index) => (
                                            <List.Item className="component-list-item spinner-element" style={{ width: '100%', position: 'relative' }}>
                                                {(registrado_finanzas === true) ? <span style={{ position: "absolute", left: -5, top: 20, zIndex: 1, width: 30, height: 30, borderRadius: '100%', background: "#40F031" }}><IconCheck style={{ position: 'relative', top: 3, left: 5 }} color="#f7f7f7" /></span> : null}
                                                <Spin spinning={loading} style={{ width: '100%' }}>
                                                    <Card className="card-list" style={{ width: '100%' }}>
                                                        <Row style={{ width: '100%' }}>
                                                            <Col span={(etapa === 1) ? ((this.props.tipo === "inmueble") ? 5 : 4) : (etapa === 2) ? ((this.props.tipo === "inmueble") ? 5 : 4) : ((this.props.tipo === "inmueble") ? 5 : 4)} >
                                                                <Text strong>
                                                                    {(this.props.tipo === "inmueble") ? <div style={{ textAlign: 'left' }}><Avatar
                                                                        style={{ margin: 'relative', left: -10 }}
                                                                        image={usuario.avatar}
                                                                        nombre={[
                                                                            usuario.nombre,
                                                                            usuario.apellido
                                                                        ]}
                                                                    />{usuario.nombre} {usuario.apellido}</div> : inmueble.nombre}
                                                                </Text>
                                                            </Col>
                                                            <Col span={(etapa === 1) ? 4 : (etapa === 2) ? 3 : 2} className="center">
                                                                <Text strong>{tajo.tajo.nombre}</Text>
                                                            </Col>
                                                            <Col span={(etapa === 1) ? 4 : (etapa === 2) ? 3 : 3} className="center">
                                                                <Text strong>$ {parseFloat(total_pagado).toMoney(2)}</Text>
                                                            </Col>
                                                            <Col span={(etapa === 1) ? 4 : (etapa === 2) ? 3 : 3} className="center">
                                                                <Text strong>$ {parseFloat(precio_m2).toMoney(2)}</Text>
                                                            </Col>

                                                            {(etapa === 2 || etapa === 3) ? <Col span={(etapa === 1) ? 6 : (etapa === 2) ? 5 : 2} className="center">
                                                                <Text strong>$ {parseFloat(monto_autorizado).toMoney(2)}</Text>
                                                            </Col> : null}
                                                            <Col span={(etapa === 1) ? 4 : (etapa === 2) ? 4 : 3} className="">
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    value={(this.props.finanzas === true) ? monto_pagado : monto_autorizado}
                                                                    className="input-ghost"
                                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                    style={{ width: 'calc(100% - 20px)', margin: '0px auto' }}
                                                                    onChange={(value) => {
                                                                        this.state.montos_editados = true;
                                                                        this.updateMonto(value, index)
                                                                    }}

                                                                    disabled={registrado_finanzas}
                                                                />
                                                            </Col>

                                                            {(etapa === 3) ? <Col span={(etapa === 1) ? 6 : (etapa === 2) ? 5 : 2} className="center">
                                                                <Text strong>$ {parseFloat(monto_deuda).toMoney(2)}</Text>
                                                            </Col> : null}
                                                            {(etapa === 3) ? <Col span={(etapa === 1) ? 6 : (etapa === 2) ? 5 : 3} className="center">
                                                                <Select style={{ maxWidth: '200px', width: '100%' }} placeholder="Cuenta" onChange={e => this.UpdateCuenta(e, index)} value={cuenta_id} disabled={registrado_finanzas}>
                                                                    {
                                                                        this.state.cuentas.map(function (account, i) {
                                                                            return <Option value={account._id}>{account.nombre}</Option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Col> : null}
                                                            {(etapa === 3) ? <Col span={(etapa === 1) ? 1 : (etapa === 2) ? 5 : 1} className="center" style={{ display: 'flex' }}>
                                                                <Button
                                                                    type="primary"
                                                                    disabled={registrado_finanzas}
                                                                    className="btn-morado"
                                                                    icon={<IconNominas />}
                                                                    onClick={() => this.payTajo(index)} />
                                                            </Col> : null}
                                                            {etapa !==3 ? <Col span={(etapa === 1) ? 1 : (etapa === 2) ? 1 : 1} className="center" style={{ display: 'flex' }}>
                                                                <Badge className="text-white"
                                                                    showZero={true}
                                                                    count={tajo.fotos ? tajo.fotos.length : 0}
                                                                    style={{ backgroundColor: tajo.fotos ? (tajo.fotos.length ? '#108ee9' : '#f5222d') : '#f5222d' }}>
                                                                    <Button className="btn btn-edit" onClick={() => {
                                                                        this.setState({
                                                                            ModalImagenesTajosVisible: true,
                                                                            Tajo_idAddImages: tajo.tajo._id,
                                                                            Tajo_fotos: empleado.inmuebles.tajos.fotos,
                                                                            empleado_id: empleado.usuario_id,
                                                                            inmueble_id_fotos:inmueble._id
                                                                        })
                                                                    }
                                                                    }>
                                                                        <IconCamera />
                                                                    </Button>
                                                                </Badge>
                                                            </Col> : null}
                                                        </Row>
                                                    </Card>
                                                </Spin>
                                            </List.Item>)}
                                />
                            </Col>
                        </Row>
                    </section>


                    {(etapa !== 3) ?
                        <Button type="ghost" className="btn-save" onClick={() => this.saveTajos()} disabled={false}>
                            <CheckOutlined style={{ color:'white', fontSize:34}} />
                        </Button> : null
                    }


                </Spin>

                <ModalImagenesTajos
                    modalVisible={this.state.ModalImagenesTajosVisible}
                    closeMethod={this.hideModal}
                    nomina_id={this.props.match.params.nomina_id}
                    empleado_id={this.state.empleado_id}
                    inmueble_id={this.state.inmueble_id_fotos}
                    tajo_id={this.state.Tajo_idAddImages}
                    Tajo_fotos={this.state.Tajo_fotos}
                    etapa={this.state.etapa}
                />

            </div>
        )
    }
}
