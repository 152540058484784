import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Spin, Form, Select, message, InputNumber  } from 'antd';
//componentes
import { IconCloseModal  } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalProspectos extends Component {

	formProspectos = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			proyectos: [],
			estatus: [],
			prospecto: {},
		}
	}

	/**
	* @memberof ModalProspectos
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getProyectos();
		this.getEstatus();
	}

	/**
	* @memberof ModalProspectos
	*
	* @method   componentDidUpdate
	* @description  
	*
	**/
	componentDidUpdate(prevProps) {
		if(this.props.prospecto_id !== prevProps.prospecto_id && this.props.prospecto_id !== undefined && this.props.modalVisible === true){
			this.getProspecto(this.props.prospecto_id)
		}
	}

	/**
	* @memberof ModalProspectos
	*
	* @method   hideModal
	* @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props  
	*
	**/
	hideModal = () => {
		this.props.closeMethod();
		this.setState({loading: false})
	}

	/**
	* @memberof ModalProspectos
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	onFinish = (values) => {
		console.log(values)
		if(values.lada_pais === '01') values.lada_pais = '1'
		if(this.props.modalTitle === "Editar"){
			this.updateProspecto(values);
		}else{
			this.addProspecto(values);
		}
	}


	/**
	 *
	 *
	 * @memberof ModalProspectos
	 * @function getProyectos
	 *
	 * @description Obtener proyectos.
	 */
	getProyectos = (search) => {
		axios.get('/proyectos', {
			params: {
				pagination: false,
				search,
			}
		}).then((proyectos) => {
			this.setState({proyectos: proyectos.data.data})
		}).catch((error) => {
			message.error('No se cargaron los Proyectos')
		})
	}

	/**
	 *
	 *
	 * @memberof ModalProspectos
	 * @function getEstatus
	 *
	 * @description Obtener los diferentes estartus para los prospectos.
	 */
	getEstatus = (search) => {
		axios.get('/estatus/list', {
			params: {
				search,
			}
		}).then((proyectos) => {
			this.setState({estatus: proyectos.data.data.itemsList})
		}).catch((error) => {
			message.error('No se cargaron los estatus')
		})
	}

	/**
	 *
	 *
	 * @memberof ModalProspectos
	 * @function addProspecto
	 *
	 * @description Obtener proyectos.
	 */
	addProspecto = (values) => {
		this.setState({loading: true})
		axios.post('/prospectos/add', {
			nombre: values.nombre,
			apellidos: values.apellidos,
			email: values.email,
			telefono: values.telefono,
			lada_pais: values.lada_pais,
			proyecto_id: values.proyecto_id,
			estatus_id: values.estatus_id,
		}).then((proyectos) => {
			message.success('¡Prospecto agregado con exito!');
			this.hideModal();
		}).catch((error) => {
			console.log(error)
			message.error('No se agrego el prospecto');
			this.setState({loading: false})
		})
	}

	/**
	 *
	 *
	 * @memberof ModalProspectos
	 * @function getProspecto
	 *
	 * @description Obtener proyectos.
	 */
	getProspecto = (_id) => {
		this.setState({loading: true})
		axios.get('/prospectos/get', {
			params: {
				_id: _id
			}
		}).then(res => {
			if(this.props.modalTitle === 'Ver'){
				this.setState({prospecto: res.data.data})
			}else{
				this.formProspectos.current.setFieldsValue({
					nombre: res.data.data.nombre,
					apellidos: res.data.data.apellidos,
					email: res.data.data.email,
					telefono: res.data.data.telefono,
					proyecto_id: res.data.data.proyecto_id._id,
					estatus_id: res.data.data.estatus_id._id,
					lada_pais: res.data.data.lada_pais,
				})
			}
		}).catch((error) => {
			message.error('No se cargo la informacion del Prospecto')
		}).finally(()=>this.setState({loading: false}))
	}

	/**
	 *
	 *
	 * @memberof ModalProspectos
	 * @function updateProspecto
	 *
	 * @description Obtener proyectos.
	 */
	updateProspecto = (values) => {
		this.setState({loading: true})
		axios.post('/prospectos/update', {
			_id: this.props.prospecto_id,
			nombre: values.nombre,
			apellidos: values.apellidos,
			email: values.email,
			telefono: values.telefono,
			proyecto_id: values.proyecto_id,
			estatus_id: values.estatus_id,
			lada_pais: values.lada_pais,
		}).then((proyectos) => {
			message.success('¡Prospecto actualizado con exito!');
			this.hideModal();
		}).catch((error) => {
			console.log(error)
			message.error('No se actualizó el prospecto');
			this.setState({loading: false})
		})
	}

	/**
	 *
	 *
	 * @memberof ModalProspectos
	 * @function renderForm
	 *
	 * @description renderiza el Formulario, dependiendo del la accion del modal
	 */
	renderForm = () => {
		return <Form
				layout="vertical"
				name="formulario-transacciones"
				onFinish={this.onFinish}
				ref={this.formProspectos}
			>
				<Row align="center">
					<Col xs={20} md={11}>
						<Form.Item
							label="Nombre"
							name="nombre"//----------------------------------------------------------nombre
							rules={[{
								required: true,
								message: "Por favor, ingrese el nombre"
							}]}
						>
							<Input/>
						</Form.Item>
					</Col>
					<Col xs={20} md={{span:11, offset:1}}>
						<Form.Item
							label="Apellidos"
							name="apellidos"//----------------------------------------------------------apellidos
							rules={[{
								required: true,
								message: "Por favor, ingrese el Apellidos"
							}]}
						>
							<Input/>
						</Form.Item>
					</Col>
					<Col  xs={20} md={11}>
						<Form.Item
							label="Email"
							name="email"//----------------------------------------------------------email
							rules={[{
								required: true,
								message: "Por favor, ingrese el email",
								type: 'email'
							}]}
						>
							<Input/>
						</Form.Item>
					</Col>
					<Col xs={20} md={{span:11, offset:1}}>
						<Form.Item
							label="Telefono"
							name="telefono"//----------------------------------------------------------telefono
							rules={[{
								required: true,
								message: "Por favor, ingrese el Telefono",
							}]}
						>
							<Input addonBefore={<Form.Item name="lada_pais" noStyle>
								    <Select
								    	className="form-select"
								    	rules={[{
											required: true,
											message: "",
										}]}
								    >
								        {codes.map(code=><Option value={code.dial_code}>
								        	{code.code} +{code.dial_code}
								        	</Option>)}
								     </Select>
							    </Form.Item>} keyboard={false}/>
						</Form.Item>
					</Col>
					<Col xs={20} md={11}>
						<Form.Item
							label="Proyecto"
							name="proyecto_id"//----------------------------------------------------------proyecto_id
							rules={[{
								required: true,
								message: "Por favor, ingrese proyecto"
							}]}
						>
							<Select
								className="form-select"
								onSearch={(search) => this.getProyectos(search)}
								showSearch
								filterOption={false}
								>
								{this.state.proyectos.map((proyecto) =>  <Option value={proyecto._id}>
									<CustomAvatar
										image={proyecto.logo}
										name={proyecto.nombre}
										color={proyecto.color}
										size="small"
										style={{
											marginRight: '5px'
										}}
									/>
									{proyecto.nombre}
								</Option>)}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={20} md={{span:11, offset:1}}>
						<Form.Item
							label="Estatus"
							name="estatus_id"//----------------------------------------------------------estatus_id
							rules={[{
								required: true,
								message: "Por favor, ingrese el estatus"
							}]}
						>
							<Select 
								className="form-select"
								onSearch={(search) => this.getEstatus(search)}
								showSearch
								filterOption={false}>
								{this.state.estatus.map((estatus) =>  <Option value={estatus._id}>
									<CustomAvatar
										color={estatus.color}
										size="small"
										style={{
											marginRight: '5px'
										}}
									/>
									{estatus.nombre}
								</Option>)}
							</Select>
						</Form.Item>
					</Col>
					<Col span={20} className="center">
						<Button htmlType="submit" type="primary" className="btn-azul">
							Guardar
						</Button>
					</Col>
				</Row>
			</Form>
	}

	renderInfo = () => {
		const { prospecto } = this.state;
		return <Form
				className="form-info-prospectos"
				layout="vertical"
				name="formulario-transacciones"
			>
				<Row align="center">
					<Col xs={20} md={10}>
						<Form.Item label="Nombre" >
							<Text className="text-info">{prospecto.nombre}</Text>
						</Form.Item>
					</Col>
					<Col xs={20} md={{span:10, offset:1}}>
						<Form.Item label="Apellidos" >
							<Text className="text-info">{prospecto.apellidos}</Text>
						</Form.Item>
					</Col>
					<Col  xs={20} md={10}>
						<Form.Item label="Email">
							<Text className="text-info">{prospecto.email}</Text>
						</Form.Item>
					</Col>
					<Col xs={20} md={{span:10, offset:1}}>
						<Form.Item label="Télefono">
							<Text className="text-info">+{prospecto.lada_pais}{prospecto.telefono}</Text>
						</Form.Item>
					</Col>
					<Col xs={20} md={10}>
						<Form.Item label="Estatus">
							<span className="span-estatus" style={{backgroundColor: prospecto.estatus_id?.color}}> 
                                {prospecto.estatus_id?.nombre}
                            </span>
						</Form.Item>
					</Col>
					<Col xs={20} md={{span:10, offset:1}}>
						<Form.Item label="Proyecto">
							<span className="span-proyecto">{prospecto.proyecto_id?.nombre}</span>
						</Form.Item>
					</Col>
					
				</Row>
				<br></br>
				<Row align="center">
					<Col xs={20} className="center">
						<Title level={3} className="">Comunicación</Title>
					</Col>
					<Col xs={20} className="center">
						<Text className="text-label">Mails Enviados</Text>
					</Col>
					<Col  xs={20} className="center">
						<span className="span-proyecto">{prospecto.proyecto_id?.nombre}</span>
						<span className="span-proyecto-blue">{prospecto.proyecto_id?.nombre}</span>
					</Col>
					<Col xs={20} className="center">
						<Text className="text-label">Whatsapp</Text>
					</Col>
					<Col xs={20} className="center">
						<Text className="text-label-bold">Fecha</Text>
					</Col>				
				</Row>
			</Form>
	}

	render() {
		return (
			<Modal
				visible={this.props.modalVisible}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				closable={false}
				className="modal-form"
				destroyOnClose={true}
				zIndex={1000}
				width={750}
			>

				<div className="modal-header">
					<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
						<IconCloseModal />
					</Button>
					<Title level={3} className="modal-title">{this.props.modalTitle} Prospecto</Title>
				</div>

				<Spin spinning={this.state.loading}>
					{this.props.modalTitle === 'Ver' ? this.renderInfo() : this.renderForm()}
				</Spin>
			</Modal>
		)
	}
}



const codes = [
	
{
"name": "Mexico",
"dial_code": "52",
"code": "MEX",
"emoji": "🇲🇽",
selected:true
},
{
"name": "Estados Unidos",
"dial_code": "01",
"code": "USA",
    "emoji": "🇺🇸"
},
{
"name": "Canada",
"dial_code": "1",
"code": "CAN",
    "emoji": "🇨🇦"
},
{
"name": "Argentina",
"dial_code": "54",
"code": "ARG",
"emoji": "🇦🇷"
},
{
"name": "Belice",
"dial_code": "501",
"code": "BLZ",
    "emoji": "🇧🇿"
},
{
"name": "Bolivia",
"dial_code": "591",
"code": "BOL",
    "emoji": "🇧🇴"
},
{
"name": "Chile",
"dial_code": "56",
"code": "CHL",
    "emoji": "🇨🇱"
},
{
"name": "Colombia",
"dial_code": "57",
"code": "COL",
    "emoji": "🇨🇴"
},
{
"name": "Costa Rica",
"dial_code": "506",
"code": "CRI",
    "emoji": "🇨🇷"
},
{
"name": "Ecuador",
"dial_code": "593",
"code": "ECU",
    "emoji": "🇪🇨"
},
{
"name": "El Salvador",
"dial_code": "503",
"code": "SLV",
    "emoji": "🇸🇻"
},
{
"name": "Guatemala",
"dial_code": "502",
"code": "GTM",
    "emoji": "🇬🇹"
},
{
"name": "Honduras",
"dial_code": "504",
"code": "HND",
    "emoji": "🇭🇳"
},
{
"name": "Nicaragua",
"dial_code": "505",
"code": "NIC",
    "emoji": "🇳🇮"
},
{
"name": "Panama",
"dial_code": "507",
"code": "PAN",
    "emoji": "🇵🇦"
},
{
"name": "Paraguay",
"dial_code": "595",
"code": "PRY",
    "emoji": "🇵🇾"
},
{
"name": "Peru",
"dial_code": "51",
"code": "PER",
    "emoji": "🇵🇪"
},
{
"name": "Puerto Rico",
"dial_code": "1939",
"code": "PRI",
    "emoji": "🇵🇷"
},
{
"name": "España",
"dial_code": "34",
"code": "ESP",
    "emoji": "🇪🇸"
},
{
"name": "Uruguay",
"dial_code": "598",
"code": "URY",
    "emoji": "🇺🇾"
},
]

