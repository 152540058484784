import React, { Component } from 'react';
import { Spin, List, Button, Col, Row, Card, Typography, InputNumber, Modal, Select, Carousel, message } from 'antd'
import { FaMinus } from "react-icons/fa";
import axios from 'axios';

import Avatar from '../../../Widgets/Avatar/Avatar'
import '../../../../Styles/nominas.css'
import { IconArrowBack, IconoMaquinaRegistradoraParaElGuardadoNominazFinanzas } from '../../../Widgets/Iconos';
import Logged from "../../../../Hooks/Logged";
import DropzoneAntDesign from "../../../Widgets/AntdDropzone/DropzoneAntDesign";

import 'pure-react-carousel/dist/react-carousel.cjs.css'
import { parse } from 'accounting-js';

const { Title, Paragraph, Text } = Typography;

const { Option } = Select;

/**
 *
 *
 * @export
 * @class RevisionDeInmueble
 * @extends {Component}
 * 
 */
export default class RevisionDeInmueble extends Component {

    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            empleados: [],


            cuentas: [],

            tajo_info: {}
        }
    }

    /**
     *
     *
     * @memberof RevisionDeInmueble
     *
     * @function componentDidMount Obtenemos el grupo.
     *
     */
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);


        this.getInmuebleAgrupado()
        this.loadCuentas()
    }

    /**
     * @memberof RevisionDeInmueble
     *
     * @function changeM2Trabajados
     *
     * @description Ejecuta cuando se cambia el valor de m2 trabajados de un empleado para actualizar el monto total
     * @param value Float valor nuevo
     * @param index Float index del elemto que se modifico
     */
    updatePaymentOnSquareMeter = (value, id) => {

        console.log('value', value);
        let { empleados } = this.state;
        if (this.props.finanzas) {
            empleados[id].m2_trabajados = (value)
            empleados[id].monto = parseFloat(value) * parseFloat(empleados[id].precio_m2)

            if (empleados[id].monto < empleados[id].monto_autorizado) {
                empleados[id].monto_deuda = parseFloat((parseFloat(empleados[id].monto_autorizado) - parseFloat(empleados[id].monto)).toFixed(2))
            } else
            empleados[id].monto_deuda = 0
        } else {
            if (value !==null && value !==undefined && value !=='' && !isNaN(value)) {
                console.log('value', value);
                console.log('empleados[id].precio_m2', empleados[id].precio_m2);
                console.log('multiplicar', parseFloat(empleados[id].precio_m2) * parseFloat(value));
                empleados[id].monto = parseFloat((parseFloat(value) * parseFloat(empleados[id].precio_m2)).toFixed(2))
                empleados[id].m2_trabajados = parseFloat(value)
            } else
                empleados[id].monto = 0
        }

        empleados[id].m2_trabajados = value
        this.setState({ empleados })
    }

    /**
     * @memberof RevisionDeInmueble
     *
     * @function changeMontoTotal 
     *
     * @description Ejecuta cuando se cambia el valor de m2 trabajados de un empleado para actualizar el monto total
     * @param value int valor nuevo
     * @param index int index del elemto que se modifico
     * @param empleado obj elemento empleado
     */
    updateMonto = (value, id) => {
        let { empleados } = this.state;

        //Si es finanzas, se actualiza el monto real, caso contrario se actualiza el autorizado final ya que el dueño esta revisando
        empleados[id].monto = parseFloat(value)

        if (this.props.finanzas) {

            if (empleados[id].monto < empleados[id].monto_autorizado) {
                empleados[id].monto_deuda = empleados[id].monto_autorizado - empleados[id].monto
                //actualizo los metros trabajados por el monto escrito
                empleados[id].m2_trabajados = parseFloat(parseFloat(value) / parseFloat(empleados[id].precio_m2).toFixed(2))
            } else empleados[id].monto_deuda = 0
        }

        empleados[id].m2_trabajados = parseFloat(value) / parseFloat(empleados[id].precio_m2);


        console.log('parseFloat(parseFloat(value) / parseFloat(empleados[id].precio_m2).toFixed(2))',parseFloat(parseFloat(value) / parseFloat(empleados[id].precio_m2)));
        if (isNaN(empleados[id].m2_trabajados) || value === null || value === "")
            empleados[id].m2_trabajados = 0;


        this.setState({ empleados });
    }

    /**
     * @memberof RevisionDeInmueble
     *
     * @function save 
     *
     * @description guarda la informacion 
     */
    save = (nomina_id = this.props.match.params.nomina_id, inmueble_id = this.props.match.params.inmueble_id, empleados = this.state.empleados) => {
        this.setState({
            loading: true
        })

        const user = this.context;

        axios.post('/nominas/revisiones/inmuebles/registrar', {
            nomina_id,
            empleados,
            tipo: (user === 1) ? 2 : 1
        })
            .then(response => {

                this.setState({
                    loading: false
                })
                console.log('response', response);
                this.props.history.goBack()

            })
            .catch(error => {

                Modal.error({
                    title: "No es posible guardar"
                })
                console.log('response', error);
            })


    }
    /**
     * @memberof RevisionDeInmueble
     * @method loadCuentas
     * @description Trae la lista de cuentas
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200
            }
        })
            .then(res => {
                this.setState({ cuentas: res.data.data.itemsList });
            })
            .catch(error => {
                console.log('lista cuentas error', error);
            })
    }


    // * @param {request.squery} nomina_id El ID de la nomina
    // * @param {request.query} inmueble_id El id del tag inmueble_id

    /**
     * @memberof RevisionDeInmueble
     * 
     * @function Obtener inmueble junto con sus grupos y empleados.
     * 
     * 
     * @param {*} nomina_id 
     * @param {*} inmueble_id 
     */
    getInmuebleAgrupado = (nomina_id = this.props.match.params.nomina_id, inmueble_id = this.props.match.params.inmueble_id) => {

        this.setState({
            loading: true
        })

        const user = this.context;

        console.log('nomina_id', nomina_id);
        console.log('inmueble_id', inmueble_id);
        axios.get('/nominas/revisiones/inmuebles/get', {
            params: {
                nomina_id,
                inmueble_id,
                tipo: (user === 1) ? 2 : 1

            }
        })
            .then(({ data }) => {
                console.log('grupos',data.data);
                const { empleados, tajo_info } = data.data


                this.setState({
                    empleados,
                    tajo_info,
                    loading: false
                })

                // let empleados = [];
                // console.log('grupos', grupos);
                // for (let indexTotalAreas = 0; indexTotalAreas < total_areas.length; indexTotalAreas++) {
                //     const total_area = total_areas[indexTotalAreas]
                //     let grupoIndex = grupos.findIndex(grupo => {
                //         return grupo.grupos.revision.area_id._id.toString() === total_area._id.toString()
                //     })

                //     console.log('AAAAAAAAAAA',m);
                //     // console.log('total_area', total_area);
                //     try {
                //         console.log('[grupoIndex]', grupoIndex);
                //         console.log('grupos[grupoIndex]', grupos[grupoIndex]);
                //         if (grupoIndex !==-1) {
                //             console.log('AGREGADO CORRECTAMENTE');
                //             empleados.push({
                //                 empleado_id: grupos[grupoIndex].grupos.revision.empleado._id,
                //                 usuario_id: grupos[grupoIndex].grupos.revision.empleado._id,
                //                 grupo_id: grupos[grupoIndex].grupos.revision._id,
                //                 monto: grupos[grupoIndex].grupos.revision.empleados.monto,
                //                 //Pago original
                //                 monto_original: grupos[grupoIndex].grupos.revision.empleados.monto,
                //                 monto_autorizado: grupos[grupoIndex].grupos.revision.empleados.monto,
                //                 precio_m2: (grupos[grupoIndex].grupos.revision.precio_m2 && grupos[grupoIndex].grupos.revision.precio_m2 !== null) ? grupos[grupoIndex].grupos.revision.precio_m2 : 0,
                //                 nombre: grupos[grupoIndex].grupos.revision.empleado.nombre + " " + grupos[grupoIndex].grupos.revision.empleado.apellido,
                //                 area: grupos[grupoIndex].grupos.revision.area_id.nombre,
                //                 m2_trabajados: grupos[grupoIndex].grupos.revision.empleados.m2_trabajados,
                //                 total_area: total_area.total_Area
                //             })
                //         }
                //     } catch (error) {
                        
                //         console.log('grupos[grupoIndex].grupos.revision',grupos[grupoIndex].grupos.revision);
                //         console.log('grupos[grupoIndex].grupos.revision._id',grupos[grupoIndex].grupos.revision._id);
                //         console.log('grupos[grupoIndex].grupos.revision._id',grupos[grupoIndex].grupos.revision.nombre);
                //         console.log('grupos[grupoIndex].grupos.revision._id',grupos[grupoIndex].grupos.revision.area_id);
                //         console.log('grupos[grupoIndex].grupos.revision._id',grupos[grupoIndex].grupos.revision.inmueble_id);

                        
                //         console.log('error', error);
                //     }
                // }


            })
            .catch(error => {
                console.log('error', error);
                console.log('error.response', error.response);
            })
    }



    saveFinanzas = (nomina_id = this.props.match.params.nomina_id, inmueble_id = this.props.match.params.inmueble_id) => {
        let { empleados } = this.state;


        for (let index = 0; index < empleados.length; index++) {
            const empleado = empleados[index];
            if (empleado.cuenta_id === undefined || empleado.cuenta_id === null || empleado.cuenta_id === '') {
                return Modal.error({
                    title: "No es posible guardar estos pagos",
                    content: "Todos los registros deben tener cuentas para poder procesar."
                })
            }
        }


        //PROYECTO ID
        //EMPLEADO DEUDA

        // * @param nomina_id El ID de la nomina
        // * @param grupo_id El id del grupo de la revision
        // * @param empleados Arreglo de empleados con informacion a reemplazar
        // * @param proyecto_id Id del proyecto en el que trabaja el grupo
        // * @param empleados_deuda Arreglo de empleados a los que se les debe dinero del pago

        // * @param nomina_id Id de la nomina a la que se va a actualizar
        // * @param empleados Arreglo de empleados con la siguiente estructura:
        // * @param tipo Tipo de vista que se manda a llamar 1= preliminar, 2 = final, 3=registrar finanzas



        axios.post('/nominas/revisiones/inmuebles/registrar', {
            empleados,
            nomina_id,
            tipo: 3
        })
            .then(e => {

                message.success("Se han guardado las transacciones")

            })
            .catch(e => {


                console.log('e', e);
            })



    }

    /**
     * @memberof RevisionDeInmueble
     *
     * @function updateMonto
     * @description Actualizamos el monto de ese valor en especifico.
     *
     * @param {*} value
     *
     *
     * @returns
     */
    UpdateCuenta = (value, id) => {
        let { empleados } = this.state;
        empleados[id].cuenta_id = value
        this.setState({ empleados: [...empleados] })
    }


    render() {

        console.log('this.props.finanzas === true', this.props.finanzas === true);

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={8} xxl={12} className="flex-left ">
                        <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline', paddingTop: '0px' }}><IconArrowBack /></Button>
                        <h2 style={{ width: 'auto', float: 'left', padding: '0px', margin: '0px' }} className="">{(this.props.finanzas === true) ? "Revision de Inmueble a Finanzas" : "Revision de Inmueble"}</h2>
                    </Col>
                </Row>

                <Spin spinning={this.state.loading}>
                    <section className="p-1 content">
                        <Row gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col xs={24} sm={24} xl={24}>
                                <Card className="content-card">
                                    <Row>
                                        <Col span={18}>
                                            <Title level={3} style={{ color: "#0047FF" }}>{this.state.tajo_info.nombre}</Title>
                                        </Col>
                                    </Row>
                                    <Row className="information-widgets-content">
                                        <Col span={5}>
                                            <Paragraph className="information-widgets-content-title">Numero de Empleados</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{this.state.empleados.length}</Paragraph>
                                        </Col>
                                        <Col span={10}>
                                            <Paragraph className="information-widgets-content-title">Total Inmueble</Paragraph>
                                            <Paragraph className="information-widgets-content-description">$ {this.state.empleados.reduce((acomulador, empleado) => acomulador + empleado.monto, 0).toString().toMoney()}</Paragraph>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>


                        {/*Lista*/}
                        <Row className="list-container" gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>Empleados</Title>
                            </Col>
                            <Col span={24} style={{ paddingTop: 0 }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.empleados}
                                    className="list-employees"
                                    header={
                                        <Row style={{ width: '100%' }}>
                                            <Col span={4} className="center">
                                                <Text>Empleado</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>Area</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>Precio m<sup>2</sup></Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>Total Pagado</Text>
                                            </Col>
                                            <Col span={4}>
                                                <Text>m<sup>2</sup> Trabajados</Text>
                                            </Col>
                                            <Col span={4}>
                                                <Text>Monto a Pagar</Text>
                                            </Col>
                                        </Row>

                                    }

                                    renderItem={(empleado, index) => (
                                        <List.Item className="component-list-item">
                                            <Card className="card-list">
                                                <Row style={{ width: '100%' }}>
                                                    <Col span={(this.props.finanzas === true) ? 3 : 4} className="center">
                                                        <Text strong>{empleado.nombre}</Text>
                                                    </Col>
                                                    <Col span={(this.props.finanzas === true) ? 3 : 4} className="center">
                                                        <Text strong>{empleado.area}</Text>
                                                    </Col>
                                                    <Col span={(this.props.finanzas === true) ? 3 : 4} className="center">
                                                        <Text strong>$ {parseFloat(empleado.precio_m2).toFixed(2)}</Text>
                                                    </Col>
                                                    <Col span={(this.props.finanzas === true) ? 3 : 4} className="center">
                                                        <Text strong>$ {parseFloat(empleado.total_area).toFixed(2)}</Text>
                                                    </Col>
                                                    <Col span={(this.props.finanzas === true) ? 3 : 4}>
                                                        <InputNumber
                                                            precision={2}
                                                            min={0}
                                                            value={empleado.m2_trabajados}
                                                            className="input-ghost"
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            style={{ width: 'calc(100% - 20px)', margin: '0px auto' }}
                                                            onChange={value => this.updatePaymentOnSquareMeter(value, index)}

                                                        />
                                                    </Col>
                                                    <Col span={(this.props.finanzas === true) ? 3 : 2}>
                                                        <InputNumber
                                                            precision={2}
                                                            min={0}
                                                            value={empleado.monto}
                                                            className="input-ghost"
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            style={{ width: 'calc(100% - 20px)', margin: '0px auto' }}
                                                            onChange={value => this.updateMonto(value, index)}
                                                        />
                                                    </Col>
                                                    {(this.props.finanzas === true) ?
                                                        <Col span={4}>
                                                            <Select style={{ maxWidth: '200px', width: '100%' }} placeholder="Cuenta" onChange={e => this.UpdateCuenta(e, index)} value={empleado.cuenta_id}>
                                                                {
                                                                    this.state.cuentas.map(function (account, i) {
                                                                        return <Option value={account._id}>{account.nombre}</Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Col> : ''
                                                    }
                                                </Row>
                                            </Card>
                                        </List.Item>)}
                                />{/*termina list*/}
                            </Col>
                        </Row>
                    </section>


                    {(this.props.finanzas === true) ? <Button type="ghost" className="btn-save" onClick={() => this.saveFinanzas()} disabled={false}>
                        <IconoMaquinaRegistradoraParaElGuardadoNominazFinanzas />
                    </Button> : <Button type="ghost" className="btn-save" onClick={() => this.save()} disabled={false}>
                            <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41 3.82586C40.9961 5.11749 40.5257 6.0772 39.6873 6.8994C34.9386 11.551 30.1977 16.211 25.4539 20.8683C22.7917 23.4819 20.1315 26.0964 17.4674 28.7072C15.7045 30.436 13.2626 30.4284 11.5017 28.6996C8.07832 25.3359 4.64628 21.9807 1.23163 18.6094C0.0773175 17.4695 -0.282018 16.0897 0.223177 14.5581C0.722575 13.0446 1.82763 12.1389 3.42242 11.8829C4.80084 11.661 5.98606 12.1019 6.9723 13.0721C9.34759 15.4078 11.7316 17.736 14.0982 20.0802C14.3889 20.3685 14.5425 20.4197 14.87 20.0964C21.2511 13.8089 27.6476 7.53478 34.0422 1.25968C35.1994 0.123585 36.5846 -0.277558 38.1533 0.193762C39.6795 0.651805 40.6001 1.7054 40.9324 3.24074C40.9826 3.47119 40.9874 3.71017 41 3.82586Z" fill="white" />
                            </svg>
                        </Button>}


                </Spin>
            </div>
        )
    }
}
