import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, InputNumber, message} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconPlus, IconArrowBack} from '../../Widgets/Iconos';
//modal
import ModalProforma from '../Modales/Presupuesto/ModalProforma';
//css
import '../../../Styles/presupuesto.css'

const { Text } = Typography;
const axios = require('axios');



  
export default class PresupuestoProforma extends Component{

	constructor(props){
		super(props);
		this.state= {
            loading: false,
            proformas:[],
            por_pagar: 0,
            pagado: 0,
            porcentaje_grl: 0,
		}
	}

    volver = () => {
        window.history.back();
    }

    /**
    * @memberof PresupuestoProforma
    *
    * @method   componentDidMount
    * @description  
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.listInmuebles()
    }

    onChange = (value) => {
        console.log('changed', value);
    }

    /**
    * @memberof PresupuestoProforma
    *
    * @method   hideModal
    * @description  Cierra modal
    *
    **/
    hideModal = () => {
        this.setState({modalProformaVisible: false})
        this.listInmuebles();
    }

    /**
    * @memberof PresupuestoProforma
    *
    * @method   listInmuebles
    * @description  Cierra modal
    *
    **/
    listInmuebles = () => {
        this.setState({loading: true})
        axios.get('/proforma/list',{
            params:{}
        }).then(response => {
            this.setState({
                proformas: response.data.data.data,
                por_pagar: response.data.data.por_pagar ? response.data.data.por_pagar : 0, 
                pagado: response.data.data.pagado ? response.data.data.pagado : 0, 
                porcentaje_grl: response.data.data.porcentaje_grl ? response.data.data.porcentaje_grl : 0, 
                loading: false
            })
        }).catch(error=>{
            console.log(error)
            message.error('Error al cargar los registros')
        })
    }

	render(){
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24}>
                        <Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
                            <IconArrowBack />
                        </Button>
						<h2 style={{width: 'auto', float: 'left'}}>Presupuesto Proforma</h2>
					</Col>
				</Row>
				<section className="presupuesto-proforma-ctn p-1">
					<div className="scrll">
                        <Row>
                            <Col xs={6} xl={4} >
                                <div className="card-img center">
                                    <img src={'/images/PE.png'} width='150'/>
                                </div> 
                            </Col>
                            <Col xs={6} xl={4} className="center">
                                <div className="card">
                                    <Text className="text-greenLigth">Pagado</Text>
                                </div>
                            </Col>
                            <Col xs={6} xl={4} className="center">
                                <div className="card">
                                    <Text className="text-yellow">Por Pagar</Text>
                                </div>
                            </Col>
                            <Col xs={6} xl={4} className="center">
                                <div className="card">
                                    <Text className="text-purple">Porcentaje</Text>
                                </div>
                            </Col>
                        </Row>
                        <List
                            loading={this.state.loading}
                            style={{maxHeight: '500px', overflowX: 'auto', minWidth: '710px'}}
                            dataSource={this.state.proformas}
                            renderItem={item => {
                                return <Link to={`/admin/presupuesto/proforma/${item._id}/concepto`}>
                                    <Row className="hover-effect-all" >
                                        <Col xs={6} xl={4}  className="center">
                                            <div className="card">
                                                <Text className="text-green">{item.inmueble_id.nombre}</Text>
                                            </div>
                                        </Col>
                                        <Col xs={6} xl={4}  className="center">
                                            <div className="card">
                                                <Text className="text-greenLigth">$ {item.monto_inmueble.toMoney(2)} MXN</Text>
                                            </div>
                                        </Col>
                                        <Col xs={6} xl={4} className="center">
                                            <div className="card">
                                                {/*<InputNumber size="small" min={0} defaultValue={item.por_pagar} onChange={this.onChange}/>*/}
                                                <Text className="text-yellow">$ {item.presupuesto.toMoney(2)} MXN</Text>
                                            </div>
                                        </Col>
                                        <Col xs={6} xl={4} className="center">
                                            <div className="card">
                                                <Text className="text-purple">{item.porcentaje_imueble.toMoney(2)} %</Text>
                                            </div>
                                        </Col>
                                    </Row>
                                </Link>
                            }}
                        />
                        <Row className="footer-list">
                            <Col xs={{span:6, offset:6}} xl={{span:4,offset:4}} className="center">
                                <div className="card">
                                    <Text className="text-greenLigth">$ {this.state.pagado.toMoney(2)}</Text>
                                </div>
                            </Col>
                            <Col xs={6} xl={4} className="center">
                                <div className="card">
                                    <Text className="text-yellow">$ {this.state.por_pagar.toMoney(2)}</Text>
                                </div>
                            </Col>
                            <Col xs={6} xl={4} className="center">
                                <div className="card">
                                    <Text className="text-purple">{this.state.porcentaje_grl.toMoney(2)} %</Text>
                                </div>
                            </Col>
                        </Row>
                    </div>    
				</section>
                <Button
                    className="btnAddPlus"
                    onClick={() => this.setState({modalProformaVisible: true, modalTitle: 'Añadir'})}>
                    <IconPlus/>
                </Button>
                <ModalProforma
                    modalVisible={this.state.modalProformaVisible}
                    closeMethod={this.hideModal}
                />
			</div>
		)
	}
}