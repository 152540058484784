import React, { useState } from 'react';
import { Space, Button, Card, Typography, Tooltip } from 'antd'
// ../../Widgets/Avatar/Avatar
import Avatar from "../../../Widgets/Avatar/Avatar";


import { CloseCircleOutlined } from '@ant-design/icons'
import { IconCheckCircle, IconRegister, IconDeniedRegister, IconCheck, } from '../../../Widgets/Iconos';
import { Link } from "react-router-dom";

const { Title } = Typography;

/**
 * @function EmpleadoCard
 * @description Componente para los elementos de empleados * 
 * 
 * @param {} inmueble Objeto inmueble (con la información del mismo)
 * @param {} usuario Objeto usuario (empleado) (con la información del mismo)
 * @param {} responsable Objeto usuario (responsable) (con la información del mismo)
 * @param {} etapa Etapa de la nómina. 1) Preeliminar, 2) Final, 3) Finanzas
 * @param {} status Si está autorizada o no está autorizada
 * @param {} nomina_id ID de la nómina para las URLS
 * @param {} redireccion Si es nulo, va a redirigir a la lista de empleados o inmuebles de una agrupación. Si es TAJOS, va a redigir a la seccion de tajos
 * Recoerdando que 1) Lista general Agrupada(sea inmuebles o tajos de la nómina) Aqui la redireccion es nula -> 2) Ya tenemos seleccionada un inmuelbe o empleado, lo siguiente es que rediriga a tajos
 * @param {} monto Cantidad de dinero sea preelminar, o final
 * @param {} disabled Si está disabled, no se puede acceder a la aseccion de tajos o de inmuebles.
 * 
 */
function EmpleadoCard({ inmueble = {}, usuario = {}, responsable = {}, etapa = null, status = false, nomina_id = "", redireccion, monto = 0, disabled = false }) {

    /**
     * @function EmpleadoCard
     * @description Componente para los elementos de empleados * 
     * 
     * @param {} cardContent Contenido de los botones segun sea preeliminar, final o finanzas
     * @param {} hoverable Esto es para que se pueda quitar la opacidad y se muestre el boton de Editar revision
     * @param {} subTitle Subtitulo (Preeliminar, Final, Pagado)
     */
    let cardContent;
    let [hoverable, setHover] = useState(false);
    let subTitle = ""


    //Si es preliminar o final, se muestrahn los botones de autorizados o sin autorizad.
    if (etapa === 1 || etapa === 2) {
        //Si la cantidad es preeliminar, aparezca preelminar, caso contrario final
        if (status === true) {
            if (etapa === 1)
                subTitle = "Preliminar"
            else
                subTitle = "Final"

            //Si está activo, te muestra editar revision y segun sea tajo o lista de inmuelbes / empleado
            cardContent = <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                <Button type="primary" className={"button-group-card " + ((hoverable === true) ? "button-review" : "button-authorize")}

                    // Esto sifnicia que ya fue autorizado finalmente, por lo que el empleado no puede editar.
                    disabled={(disabled === true && etapa === 1)}>
                    <Link to={`/admin/nomina/revision/${nomina_id}/empleado/${usuario._id}/`}>
                        {(hoverable === false) ? <div><IconCheckCircle className="icon-button" color="white" /> Autorizado</div> : "Editar Revisión"}
                    </Link>
                </Button>
            </Space>
        } else {
            //EL preelminar aparece segun la cantidad 
            subTitle = "Preliminar"
            cardContent = <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                <Button type="primary" className="button-group-card button-no-authorize" >
                    <Link to={`/admin/nomina/revision/${nomina_id}/empleado/${usuario._id}/`}>
                        Sin autorizar
                    </Link>
                </Button>
            </Space>
        }
    } else {
        //Esto es para el boton de finazas, si esta activado apoarece registradop


        if (status === true) {
            subTitle = "Pagado"
            cardContent = <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                <Button type="primary" className="button-group-card button-register" >
                    <Link to={`/admin/nomina/finanzas/${nomina_id}/empleado/${usuario._id}/`}>
                        <IconRegister size={1} className="icon-button" color="white" /> &nbsp; Registrado
                    </Link>
                </Button>
            </Space>
        }
        else {
            //Caso contrario que rediriga igual pero que muestre que no se ha registrrado
            subTitle = "Autorizado Final"
            cardContent = <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                <Button type="primary" className="button-group-card button-unregistered" >
                    <Link to={`/admin/nomina/finanzas/${nomina_id}/empleado/${usuario._id}/`}>
                        <IconDeniedRegister size={0.7} className="icon-button" color="white" /> &nbsp;  Sin Registrar
            </Link>
                </Button>
            </Space>

        }

    }

    return <Tooltip title="Este empleado ha sido autorizado finalmente, por lo que no se puede modificar." trigger={(disabled === true && etapa === 1) ? ["hover"] : []}><Card className={"content-card content-card-payroll " + ((status === true) ? "authorized" : "null")} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
        <Title level={3} style={{ marginBottom: '0.5em' }}>{usuario.nombre + ' ' + usuario.apellido}</Title>
        <Title level={3} style={{ marginBottom: '0.5em', textAlign: "center", width: "100%" }}>
        </Title>
        {cardContent}
    </Card></Tooltip>
}


/**
 * @function InmuebleCard
 * @description Componente para los elementos de inmueble * 
 * 
 * @param {} inmueble Objeto inmueble (con la información del mismo)
 * @param {} usuario Objeto usuario (empleado) (con la información del mismo)
 * @param {} responsable Objeto usuario (responsable) (con la información del mismo)
 * @param {} etapa Etapa de la nómina. 1) Preeliminar, 2) Final, 3) Finanzas
 * @param {} status Si está autorizada o no está autorizada
 * @param {} nomina_id ID de la nómina para las URLS
 * @param {} redireccion Si es nulo, va a redirigir a la lista de empleados o inmuebles de una agrupación. Si es TAJOS, va a redigir a la seccion de tajos
 * Recoerdando que 1) Lista general Agrupada(sea inmuebles o tajos de la nómina) Aqui la redireccion es nula -> 2) Ya tenemos seleccionada un inmuelbe o empleado, lo siguiente es que rediriga a tajos
 * @param {} monto Cantidad de dinero sea preelminar, o final
 * @param {} disabled Si está disabled, no se puede acceder a la aseccion de tajos o de inmuebles.
 * 
 */
function InmuebleCard({ inmueble = {}, usuario = {}, responsable = {}, proyecto = {}, etapa = null, status = false, nomina_id = "", redireccion, monto = 0, disabled = false }) {

    let cardContent;

    const [hoverable, setHover] = useState(false);

    let subTitle = ""
    console.log('etapa', etapa);
    if (etapa === 1 || etapa === 2) {

        if (status === true) {
            if (etapa === 1)
                subTitle = "Preliminar"
            else
                subTitle = "Final"
            cardContent = <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                <Button type="primary" className={"button-group-card " + ((hoverable === true) ? "button-review" : "button-authorize")} disabled={(disabled === true && etapa === 1)}>
                    <Link to={`/admin/nomina/revision/${nomina_id}/inmueble/${inmueble._id}/`}>
                        {(hoverable === false) ? <div><IconCheckCircle className="icon-button" color="white" /> Autorizado</div> : "Editar Revisión"}
                    </Link>
                </Button>
            </Space>
        } else {
            subTitle = "Preliminar"
            cardContent = <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                <Button type="primary" className="button-group-card button-no-authorize" >
                    <Link to={`/admin/nomina/revision/${nomina_id}/inmueble/${inmueble._id}/`}>
                        Sin autorizar
                    </Link>
                </Button>
            </Space>
        }
    } else {
        if (status === true) {
            subTitle = "Pagado"
            cardContent = <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                <Button type="primary" className="button-group-card button-register" >
                    <Link to={`/admin/nomina/finanzas/${nomina_id}/inmueble/${inmueble._id}/`}>
                        <IconRegister size={1} className="icon-button" color="white" /> &nbsp; Registrado
                    </Link>
                </Button>
            </Space>
        }
        else {
            subTitle = "Autorizado Final"
            cardContent = <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                <Button type="primary" className="button-group-card button-unregistered" >
                    <Link to={`/admin/nomina/finanzas/${nomina_id}/inmueble/${inmueble._id}/`}>
                        <IconDeniedRegister size={0.7} className="icon-button" color="white" /> &nbsp;  Sin Registrar
                    </Link>
                </Button>
            </Space>

        }


    }



    return <Tooltip title="Este inmueble ha sido autorizado finalmente, por lo que no se puede modificar." trigger={(disabled === true && etapa === 1) ? ["hover"] : []}><Card className={"content-card content-card-payroll " + ((status === true) ? "authorized" : "null")} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
        <Title level={3} style={{ marginBottom: '0.5em' }}>{inmueble.nombre}</Title>
        <Title level={3} style={{ marginBottom: '0.5em', textAlign: "center", width: "100%" }}>
            <div style={{ width: '100%', position: 'relative', textAlign: 'center' }}>
                <span style={{ position: 'absolute', color: "rgba(255,255,255,0.5)", fontSize: 12, fontWeight: "lighter", top: -20, margin: '0px auto', textAlign: 'center', display: 'block', left: 0, right: 0 }}>{subTitle}</span>
            $ {parseFloat(monto).toMoney(2)} <small>MXN</small>
            </div>
        </Title>
        <Avatar
            size="large"
            style={{ position: 'absolute', right: 15, top: 15 }}
            name={proyecto.nombre}
            image={proyecto.logo}
            color={proyecto.color}
        />
        <Avatar
            size="large"
            style={{ position: 'absolute', right: 40, top: 15 }}
            name={[
                responsable.nombre,
                responsable.apellido,
            ]}
            image={responsable.avatar}
        />
        {cardContent}
    </Card></Tooltip>
}


export default InmuebleCard;

export {
    EmpleadoCard,
    InmuebleCard,
}
