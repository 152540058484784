import React, { Component } from 'react';
import { Button, Col, Row, Space, Select, Typography, List, Card, Collapse, Form, Input, Modal, Spin, InputNumber, Upload, Switch, message, Pagination, Popover, Menu, Dropdown, Tooltip, Tag, Empty } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { PlusOutlined, UploadOutlined, CheckOutlined, EllipsisOutlined, DeleteOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconPlus, IconFlag, IconPaper, IconChecked, IconCheckMedalColor } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/compras.css'
import './../../../../Styles/dashboard.css'
//modales
import MenuItem from 'antd/lib/menu/MenuItem';
import ModalAlmacenes from '../Modales/ModalAlmacenes'

const { Title, Text } = Typography;
const { Panel } = Collapse;
const moment = require('moment')

let colors = {
    0: '#B706F5', //Requerido
    1: '#EDF10A', //Cotizado
    2: '#F57906', //Pausado
    3: '#F50606', //Cancelado
    4: '#6106F5', //Entrega
    5: '#F506B2', //Recoleccion
    6: '#08E453', //Entregado
}

let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

/**
 * @class ListasCotizaciones
 * @description Retorna una lista de las requisiciones, con el estatus que se mande por props, para el usuario comprador realice la cotizacion 
 */
export default class ListasCotizaciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],

            page: 1,
            limit: 20,
            total: 0
        }
    }

    /***
     *
     * @methodOf ListasCotizaciones
     * @function componentDidMount
     * @description
     */
    componentDidMount = () => {
        this.getRequisiciones()
    }

    componentDidUpdate(prevProps){
        if(prevProps.search != this.props.search){
            this.getRequisiciones()
        }
    }

    /***
     *
     * @methodOf ListasCotizaciones
     * @function getRequisiciones
     * @description
     */
    getRequisiciones = () => {
        this.setState({ loading: true })
        axios.get('/requisiciones', {
            params: {
                page: 1,
                limit: this.state.limit,
                estatus: this.props.estatus,
                requisiciones_opciones: true, //retorna un array con los requisiciones_opciones relacionados
                search: this.props.search
            }
        }).then(response => {
            if (this.state.data.length > 0) {
                this.setState({ 
                    data: [],
                    hasMore: response.data.data.itemsList.length < response.data.data.itemCount
                }, () => this.setRequisiciones(response))
            }
            else {
                this.setState({ hasMore: response.data.data.itemsList.length < response.data.data.itemCount})
                this.setRequisiciones(response)
            }

        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la información')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }


    /***
     *
     * @methodOf ListasCotizaciones
     * @function setRequisiciones
     * @description Recorre las requisiciones obtenidasr y crea un referencia a cada requisicion_opcion para modificar el formulario
     */
    setRequisiciones = (response) => {
        let requisiciones = response.data.data.itemsList;

        for (const requesicion of requisiciones) {
            for (const requisicion_opcion of requesicion.requisiciones_opciones) {
                requisicion_opcion.ref = React.createRef()
            }
        }

        this.setState({
            data: [...requisiciones],
            page: response.data.data.currentPage,
            total: response.data.data.itemCount,
        })
    }

    /***
     *
     * @methodOf ListasRequisiciones
     * @function getMore
     * @description Obtiene mas requisisciones al hacer scroll sobre los estatus
     */
    getMore = () => {
        if(this.state.total > this.state.data.length){
            this.setState({ loading: true, })

            axios.get('/requisiciones', {
                params: {
                    page: this.state.page + 1,
                    limit: this.state.limit,
                    estatus: this.props.estatus,
                    requisiciones_opciones: true, //retorna un array con los requisiciones_opciones relacionados
                    search: this.props.search
                }
            }).then(({ data }) => {

                let new_requisisciones = data.data.itemsList

                for (const requesicion of new_requisisciones) {
                    for (const requisicion_opcion of requesicion.requisiciones_opciones) {
                        requisicion_opcion.ref = React.createRef()
                    }
                }

                let arr = [...this.state.data, ...new_requisisciones]

                this.setState({
                    data: arr,
                    total: data.data.itemCount,
                    page: data.data.currentPage,
                    hasMore: arr.length < data.data.itemCount,
                })
                
            })
                .catch(error => {
                    console.log("error", error);
                    message.error('Error al traer la información')
                }).finally(() => this.setState({ loading: false }))
            }
    }


    /***
     *
     * @methodOf ListasCotizacion
     * @function onChange
     * @description Actualiza los registros de requerimientos_opcionales en la BD
     * 
     * @params allValues Obj todos los valores del formualario de las requisicion_opcion
     * @params requisicion_opcion_id Id id de la requesicion a editar
     * @params index indice que localiza la requesicion
     * @params index_b indice que localiza la requisicion_opcion
     */
    onChange = (allValues, requisicion_opcion_id, index, index_b) => {

        let data = this.state.data
        let { costo_envio, precio_unitario, volumen_final, forma_pago } = allValues


        //actualizamos la informacion en la BD
        axios.post('/requisicion/opcion/update', {
            requisicion_opcion_id,
            precio_unitario,
            volumen_final,
            forma_pago: forma_pago ? 1 : 0,
            total: costo_envio + (precio_unitario * volumen_final),
            dias_entrega: allValues.dias_entrega,
        }).then(() => console.log('Actualizado'))
            .catch(error => console.log(error))

        data[index].requisiciones_opciones[index_b].forma_pago = forma_pago

        this.setState({ data })

        return data[index].requisiciones_opciones[index_b].ref?.current.setFieldsValue({
            total: costo_envio + (precio_unitario * volumen_final)
        })

    }


    /***
     *
     * @methodOf ListasCotizaciones
     * @function updateEstatus
     * @description Actualiza el estatus de una requisicion 
     */
    updateEstatus = (requisicion_id, estatus) => {
        this.setState({ loading: true })
        axios.post('/requisiciones/update', {
            requisicion_id,
            estatus
        }).then(response => {
            message.success('Requisición actualizada')
            this.props.getRequisiciones()
        }).catch(error => {
            message.error('Error al actualizar la requisición')
        }).finally(() => this.setState({ loading: false }))
    }

    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function activate
     * @description Actualiza el marcador de activacion de la requisicion
     * 
     * @params requisicion_id id de la requisisicion a actualizar
     */
    activate = (requisicion_id, index) => {
        axios.post('/requisiciones/update',{
            requisicion_id,
            activate: true
        }).then(response => {
            let data = this.state.data
            data[index].activate = response.data.activate
            this.setState({data})
        }).catch(error => {
            if(error?.response?.status === 403)
                message.error(error?.response?.data.message)
            else
                message.error('Error al actualizar')
        })
    }

    
    render() {

        const { title, estatus } = this.props;


        return (
            <Spin spinning={this.state.loading}>
                <div>
                    <Text className={`list-header list-header-estatus-${estatus}`}>{title}</Text>
                </div>

                    {this.state.data.length > 0 ? <InfiniteScroll
                        dataLength={this.state.data.length}
                        next={this.getMore}
                        hasMore={this.state.hasMore}
                        loader={<Spin tip="Cargando..."><h4 style={{height: '100px'}}></h4></Spin>}
                        height={550}
                        className="mb-2"
                    >
                        <Collapse accordion className={`cotizacion-collapse  collapse-estatus-${estatus} mb-1`}>
                            {this.state.data.map((item, index) => <Panel

                        className='panel'
                        key={index}
                        extra={<Row className="w100">
                            <Col span={4} className="flex-column">
                                <Tooltip placement="topLeft" title={item.producto_id?.nombre}>
                                    <Text ellipsis>{item.producto_id?.nombre}</Text>
                                </Tooltip>
                                <Text>{item.producto_id?.sku_manantial}</Text>
                            </Col>
                            <Col span={4} className="center">
                                <Text>{item.producto_id?.categoria_id?.nombre}</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text>{item.volumen} {item.producto_id?.unidad ? item.producto_id?.unidad.toUnidad() : 'unidades'}</Text>
                            </Col>
                            <Col span={2} className="center">
                                <CustomAvatar
                                    size="large"
                                    image={item.proyecto_id?.logo}
                                    name={item.proyecto_id?.nombre}
                                    color={item.proyecto_id?.color}
                                />
                            </Col>
                            <Col span={2} className="center">
                                <IconFlag fill={colors_flag[item.prioridad] ? colors_flag[item.prioridad] : '#A4A4A4'} />
                            </Col>
                            <Col span={4} className="center">
                                {item.propiedades.length > 0 ? <Tag color="green" style={{marginRight: '4px', marginLeft: '0px'}}>{item.propiedades[0].nombre}</Tag> : ''}
                                {item.propiedades.length > 1 ? <Tooltip placement="topLeft" title={item.propiedades.map((elm, index)=> index > 0 ? elm.nombre + ', ' : '')}>
                                    <Tag color="green" style={{marginRight: '4px', marginLeft: '0px'}}>...</Tag>
                                </Tooltip> : ''}
                            </Col>
                            <Col span={3} className="center">
                                <Text>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                            </Col>
                            <Col span={1} className="center">
                                <Button
                                    className={`btn-permission ${item.activate ? 'active' : ''}`}
                                    type="ghost"
                                    icon={<IconCheckMedalColor/>}
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                        this.activate(item._id, index)
                                    }}
                                />
                            </Col>
                            <Col span={1} className="center">
                                <Dropdown
                                    overlay={<Menu onClick={({ key }) => {
                                        if(key !== '4')
                                            this.updateEstatus(item._id, key)
                                    }}>
                                        <Menu.Item key="0">
                                            Requerir
                                        </Menu.Item>
                                        {estatus === 1 ? null : <Menu.Item key="1">
                                            Cotizar
                                        </Menu.Item>}
                                        {estatus === 2 ? null : <Menu.Item key="2">
                                            Pausar
                                        </Menu.Item>}
                                        {estatus === 3 ? null : <Menu.Item key="3">
                                            Cancelar
                                        </Menu.Item>}
                                        <MenuItem key="4" icon={<DeleteOutlined  />} onClick={() => Modal.confirm({
                                            title: "¿Deseas eliminar esta requisición?",
                                            onOk: () => axios.delete('/requisiciones/delete', { params: { requisicion_id: item._id } })
                                                .then(({ data }) => {
                                                    message.success("Se eliminó correctamente")
                                                    this.getRequisiciones()
                                                })
                                                .catch(({ data }) => {
                                                    message.error("No se pudo eliminar")
                                                })
                                        })}>
                                            Eliminar
                                        </MenuItem>
                                    </Menu>}
                                    placement="bottomRight"
                                    arrow
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <Button
                                        style={{border: 'none'}}
                                        type="ghost"
                                        icon={<EllipsisOutlined style={{ color: "currentcolor" }} />}
                                    />
                                </Dropdown>
                            </Col>
                        </Row>}
                    >
                        <Row>
                            <Col span={4} className="center">
                                <Text className="text-gray">Proveedor</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text className="text-gray">Precio Unitario</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text className="text-gray">Días de entrega</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text className="text-gray">Volumen final</Text>
                            </Col>
                            <Col span={2} className="center">
                                <Text className="text-gray">Archivos</Text>
                            </Col>
                            <Col span={4} className="center">
                                <Text className="text-gray">Monto</Text>
                            </Col>
                        </Row>
                        {item.requisiciones_opciones?.map((requisicion_opcion, index_b) => {

                            return <Form
                                className="form-collapse"
                                ref={requisicion_opcion.ref}
                                initialValues={{ ...requisicion_opcion }}
                                onValuesChange={(value, all) => this.onChange(all, requisicion_opcion._id, index, index_b)}
                            >
                                <Row gutter={[16, 16]}>
                                    {requisicion_opcion.aceptada ? <IconChecked className="icon-checked" /> : null}
                                    <Col span={4} className="center pl-1">
                                        <Form.Item name="proveedor">
                                            <Text>{requisicion_opcion.proveedor_id?.alias}</Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Form.Item name="precio_unitario">
                                            <InputNumber
                                                className="w100"
                                                bordered={false}
                                                placeholder="Precio Unitario"
                                                prefix="$"
                                                precision={2}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} className="center ">
                                        <Form.Item name="dias_entrega">
                                            <InputNumber
                                                className="w100"
                                                bordered={false}
                                                placeholder="Dias Entrega"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} className="center ">
                                        <Form.Item name="volumen_final">
                                            <InputNumber
                                                className="w100"
                                                bordered={false}
                                                placeholder="Volumen Final"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} className="center ">
                                        <Popover placement="topRight"
                                            content={<div className="flex-left-column">
                                                {requisicion_opcion.archivos.length === 0 ? <Text className="text-gray">Sin Archivos</Text> : null}
                                                {requisicion_opcion.archivos.map(arch => <a target="_blank" href={`${axios.defaults.baseURL}/voucher/${arch.filename}`}>{arch.name}
                                                </a>)}
                                            </div>}
                                            trigger="click"
                                        >
                                            <Text className="text-blue hover">Ver Archivos</Text>
                                        </Popover>
                                    </Col>
                                    <Col span={4} className="center ">
                                        <Form.Item name="total">
                                            <InputNumber
                                                className="w100"
                                                bordered={false}
                                                placeholder="Monto Total"
                                                prefix="$"
                                                precision={2}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} className="flex-left">
                                        <Form.Item name="forma_pago" valuePropName="checked">
                                            <Switch />
                                        </Form.Item>
                                        <Text style={{ marginLeft: '8px' }}>{requisicion_opcion.forma_pago ? 'Credito' : `${requisicion_opcion.folio}`}</Text>
                                    </Col>
                                    <Form.Item name="costo_envio" hidden={true}>
                                    </Form.Item>
                                    <Col span={2} className="center ">
                                            <Form.Item name="total">
                                                <Button
                                                    size='small'
                                                    htmlType="submit"
                                                    type="primary"
                                                    className="btn-purple"
                                                    icon={<IconPaper />}
                                                    onClick={() => this.setState({
                                                        modal_visible: true,
                                                        requisicion_id: item._id,
                                                        requisicion_opcion_id: requisicion_opcion._id,
                                                        proyecto_id: item.proyecto_id?._id,
                                                    })}
                                                />
                                            </Form.Item>
                                            <Button
                                                size='small'
                                                htmlType="submit"
                                                type="primary"
                                                className="btn-delete"
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => Modal.confirm({
                                                    title: "¿Deseas eliminar esta opción?",
                                                    onOk: () => axios.delete('/requisicion/opcion/delete', { params: { requisicion_id: item._id, requisicion_opcion_id: requisicion_opcion._id } })
                                                        .then(({ data }) => {
                                                            message.success("Se eliminó correctamente")
                                                            this.getRequisiciones()
                                                        })
                                                        .catch(({ response }) => {
                                                            if(response.status === 403)
                                                                message.error(response.data.message)
                                                            else
                                                                message.error("No se pudo eliminar")
                                                        })
                                                })

                                                }
                                            />


                                    </Col>
                                </Row>
                            </Form>
                        })}
                    </Panel>)}
                        </Collapse>
                    </InfiniteScroll> : <Empty description={"Sin Requisisciones"} className="mb-3 mt-1"/> }

               {/* <Pagination
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.limit}
                    onChange={this.getRequisiciones}
                    className="mb-1"
                    hideOnSinglePage={true}
                />*/}
                <ModalAlmacenes
                    visible={this.state.modal_visible}
                    onCancel={(flag) => {
                        this.setState({ modal_visible: false, requisicion_id: undefined, requisicion_opcion_id: undefined, proyecto_id: undefined })
                        //this.getRequisiciones()
                        if (flag === true) {
                            this.props.getRequisiciones()
                        }
                    }}
                    requisicion_id={this.state.requisicion_id}
                    requisicion_opcion_id={this.state.requisicion_opcion_id}
                    proyecto_id={this.state.proyecto_id}
                />
            </Spin>
        )
    }
}
