import React, { Component } from 'react';
import { Button, Col, Modal,   Row, Typography,  Input, Form,   Select, DatePicker,  message, Spin } from 'antd'
 

import "../../../../Styles/Modal/ModalNuevoGrupo.css";
import {  IconCloseModal  } from "../../../Widgets/Iconos";

 

const axios = require("axios").default;
const moment = require('moment');

const { Title, Paragraph } = Typography;
const { Option } = Select;

 
export default class ModalCopiaGrupo extends Component {


    /**
     * @param formQuestion
     * @description Referencia para el formulario.
     * */
    groupForm = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: false,
        };


    }


    /**
     *
     *
     * @memberof ModalNuevoGrupo
     *
     * @function componentDidMount
     * @description 
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setState({data: this.props.data},()=>{
            //this.groupForm.current.resetFields()
        })
    }

    /**
     *
     *
     * @memberof ModalNuevoGrupo
     *
     * @function componentDidUpdate
     * @description 
     */
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({data: this.props.data})
        }

        if (this.props.visible !== prevProps.visible) {
            this.setState({loading: false})
        }

    }


    /**
     *
     *
     * @memberof ModalNuevoGrupo
     *
     * @function onFinish
     * @description Crea o actualiza un grupo.
     *
     * @param values
     * @description Objeto generando por el form de antd del gurpo
     */
    onFinish = async (values) => {
        this.setState({loading: true})
        let datosGrupo = this.state.data;
        
        datosGrupo._id = undefined;
        datosGrupo.nombre = values.nombre;
        datosGrupo.fecha_nomina = values.fecha_nomina
        
        axios.post('/grupos/add', datosGrupo)
            .then(data => {
                message.success("El grupo fue copiado ¡Exitosamente!");
                this.hideModal()
            })
            .catch(error => {
                message.error("No se pudo crear el grupo");
                console.log("error", error)
            })
    }

    hideModal = () => {
        this.props.hideModal()
        this.setState({loading: false})
    }


    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                className="modal-grupos modal-form"
                destroyOnClose={true}
                zIndex={1000}
                closable={false}
            >
                <div className="modal-header ">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Copiar Grupo</Title>
                </div>
                <Row className="center">
                    <Title level={5} className="modal-title">{this.state.data.nombre}</Title>
                </Row>
                <Spin spinning={this.state.loading}>
                    <Form
                        onFinish={this.onFinish}
                        className=""
                        layout="vertical"
                        //ref={this.groupForm}
                        //initialValues={this.state.data}
                    >
                        <Row>
                            <Col xs={{span: 24, offset: 0}} md={{span: 20, offset: 2}}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                    >
                                        <Input placeholder="Nombre" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 24, offset: 0}} md={{span: 20, offset: 2}}>
                                <Form.Item
                                    label="Próxima Nomina"
                                    name="fecha_nomina"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, la fecha de Nomina"
                                        }]}
                                    >
                                    <DatePicker
                                        laceholder={"Fecha de Nómina"}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col  className="center" xs={24} lg={24}>
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>              
            </Modal>
        )
    }
}