import React, { Component } from 'react';
import { Button, Col, Row, Select, Typography } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import { FilterOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import ListasRequisiciones from './ListasRequisiciones';
import { IconPlus, IconArrowBack } from '../../Widgets/Iconos';
//modal
import ModalRequisiciones from './Modales/ModalRequisiciones'
import ModalRequisicionDetalle from './Modales/ModalRequisicionDetalle'
import DrawerFiltrosRequisiciones from './DrawerFiltrosRequisiciones'
//css
import './../../../Styles/dashboard.css'

const { Title,Text } = Typography;
const moment = require('moment')



/**
 * @class RequisicionMateriales
 * @description Vista que se encarga de desplegar las requsiciones divididas por estatus, esta vista corresponde a los Encargados / Maestros
 * ya que son los que crean nuevas requisiciones
 */
export default class RequisicionMateriales extends Component {

    volver = () => {
        window.history.back();
    }

    constructor(props){
        super(props)
        this.state = {
            modal_visible: false,
            modal_detalle: false,
            requisicion_id: undefined,

            search:  this.props.search,
            filtros: {}
        }
    }

    estatus_0 = React.createRef()
    estatus_1 = React.createRef()
    estatus_2 = React.createRef()
    estatus_3 = React.createRef()
    estatus_4 = React.createRef()
    estatus_5 = React.createRef()
    estatus_6 = React.createRef()

    /**
     *
     * @methodOf RequisicionMateriales
     * @function componentDidMount
     * @description Obtenemos los proyectos y las Areas
     */
    componentDidMount = () => {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps){

        if(prevProps.search != this.props.search){
            this.setState({
                search: this.props.search
            })
        }

    }

    /**
     *
     * @methodOf RequisicionMateriales
     * @function openModal
     * @description Abre el modal para per el detalle de la requesicion
     */
    openModal = (item_id) => {
        this.setState({modal_detalle: true, requisicion_id: item_id})
    }


    render() {
        const { onDatesChange, onProjectsChange } = this;
        const { start, end, projectsSelected, area, rubro } = this.state;
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={15} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <Title level={4} className="view-title">Requisicion de Materiales</Title>
                    </Col>
                    <Col xs={9} xl={6} className="flex-right">
                        <Button
                            style={{ backgroundColor: '#007BFF'}}
                            onClick={() => this.setState({ drawer_filtros: true })}
                            icon={<FilterOutlined style={{ color: '#fff', fontWeight: 600 }} />}
                        />
                    </Col>
                </Row>

                <section className="p-1">
                    
                   {/*REQUERIDO*/}
                   <ListasRequisiciones title={'Requerido'} estatus={0} openModal={this.openModal} filtros={this.state.filtros} search={this.state.search} ref={this.estatus_0}/>
                   {/*Cotizado*/}
                   <ListasRequisiciones title={'Cotizado'} estatus={1} openModal={this.openModal} filtros={this.state.filtros} search={this.state.search}  ref={this.estatus_1}/>
                   {/*Pausado*/}
                   <ListasRequisiciones title={'Pausado'} estatus={2} openModal={this.openModal} filtros={this.state.filtros} search={this.state.search} ref={this.estatus_2}/>
                   {/*Cancelado*/}
                   <ListasRequisiciones title={'Cancelado'} estatus={3} openModal={this.openModal} filtros={this.state.filtros} search={this.state.search} ref={this.estatus_3}/>
                   {/*Entrega*/}
                   <ListasRequisiciones title={'Entrega'} estatus={4} openModal={this.openModal} filtros={this.state.filtros} search={this.state.search} ref={this.estatus_4}/>
                   {/*Recolección*/}
                   <ListasRequisiciones title={'Recolección'} estatus={5} openModal={this.openModal} filtros={this.state.filtros} search={this.state.search} ref={this.estatus_5}/>
                   {/*Entregado*/}
                   <ListasRequisiciones title={'Entregado'} estatus={6} openModal={this.openModal} filtros={this.state.filtros} search={this.state.search} ref={this.estatus_6}/>


                </section>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={()=>this.setState({modal_visible: true})}>
                    <IconPlus />
                </Button>
                
                <ModalRequisiciones
                    visible={this.state.modal_visible}
                    onCancel={(estatus)=>{
                        this.setState({modal_visible: false})
                        this.estatus_0.current.getRequisiciones()
                    }}
                />

                <ModalRequisicionDetalle
                    visible={this.state.modal_detalle}
                    onCancel={()=>this.setState({modal_detalle: false})}
                    requisicion_id={this.state.requisicion_id}
                />

                <DrawerFiltrosRequisiciones
                    title="Filtrar Requisiciones"
                    visible={this.state.drawer_filtros}
                    onClose={()=>{
                        this.setState({drawer_filtros: false})
                    }}
                    updateFilters={filtros => {
                        this.setState({filtros},()=>{
                            this.estatus_0.current.getRequisiciones()
                            this.estatus_1.current.getRequisiciones()
                            this.estatus_2.current.getRequisiciones()
                            this.estatus_3.current.getRequisiciones()
                            this.estatus_4.current.getRequisiciones()
                            this.estatus_5.current.getRequisiciones()
                            this.estatus_6.current.getRequisiciones()
                        })
                    }}
                />

            </div>
        )
    }
}
