import React, { Component } from 'react';
import { Button, Col, Row, Spin, Empty, Typography, Progress, List, Badge, Card, Popconfirm, Breadcrumb, Pagination, message } from 'antd'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

//componentes
import { CardInfoDashboard, CardProgress, CardActivity, CardInfo, CardProgressBar } from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import { IconEdit, IconDelete, IconArrowBack, IconPlus, IconEye } from '../../Widgets/Iconos';
//css
import '../../../Styles/projectManager.css'

const axios = require('axios').default;
const { Text } = Typography;


export default class PMmaterialesEmpleadoAct extends Component {

    constructor(props) {
        super(props)

        this.state = {
            manager_proyecto_id: "",
            manager_actividad_id: "",

            actividad_id: "",
            empleado_id: "",
            proyecto_id: "",
            inmueble_id: "",

            proyecto_nombre: "[Proyecto]",
            actividad_nombre: "[Actividad]",
            empleado_nombre: "[Empleado]",
            inmueble_nombre: "[Inmueble]",

            dataMateriales: [],
            materialesRequeridos: 0,
            materialesEntregados: 0,
            procesoDeEntrega: 0,

            dataMateriales: [],
            loadingPage: true,

            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
        }
    }

    /**
    * @memberof PMmaterialesEmpleadoAct
    *
    * @method   componentDidMount
    * @description
    *
    **/
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.setState({
            manager_actividad_id: this.props.match.params.id
        });

        // console.log(this.state);
        setTimeout(() => {
            this.getInfoActividad();
        }, 0.5);
    }

    /**
       * @memberof PMmaterialesEmpleadoAct
       *
       * @method   getMateriales
       * @description Se hace la consulta de los materiales por actividad
       *
       **/
    getInfoActividad = async (page = this.state.currentPage, size = this.state.perPage) => {
        this.state.loadingPage = true;

        var _id = this.state.manager_actividad_id;
        var search = this.props.search;

        axios.get('/manager/proyecto/actividades/empleado/getMateriales', {
            params: {
                id: _id,
                page: page,
                limit: size,
                search: search,
            }
        })
            .then(({ data }) => {

                var materialRequerido = 0;
                var dataMat = [];

                for (let x = 0; x < data.data.length; x++) {
                    const req = data.data[x];
                    // console.log(req);
                    materialRequerido = req.cantidad;

                    var materialEntregado = 0;
                    for (let x = 0; x < req.entregas.length; x++) {
                        const ent = req.entregas[x];
                        console.log("entrega ", ent)
                        materialEntregado += ent.cantidad;
                    }

                    let status;
                    if (materialRequerido === 0) {
                        status = "Sin Materiales Requeridos"
                    } else {
                        if (materialEntregado === 0) {
                            status = "Sin entregas"
                        }
                        else if (materialEntregado > materialRequerido) {
                            status = "Excedido"
                        } else if (materialEntregado < materialRequerido) {
                            status = 'En Proceso'
                        } else if (materialEntregado === materialRequerido) {
                            status = 'Concluido'
                        }
                    }

                    var avance = (materialEntregado * 100) / materialRequerido;


                    dataMat.push({
                        material_id: req.material._id,
                        material_nombre: req.material.nombre,
                        material_requerido: req.cantidad,
                        material_entregado: materialEntregado,
                        material_status: status,
                        avance: avance
                    });
                }


                if (this.state.manager_proyecto_id === "") {

                    //   console.log('traje materiales', data);

                    var dataActividad = data.actividad.actividad_id;
                    //     console.log("dataActividad ", dataActividad);

                    var dataInmueble = data.actividad.inmueble_id;
                    //    console.log("dataInmueble ", dataInmueble);

                    var dataEmpleado = data.actividad.responsable_id;
                    //   console.log("dataEmpleado ", dataEmpleado);



                    var avanceEntrega = 0;
                    if (parseFloat(data.actividad.materiales_entregados) > 0) {
                        avanceEntrega = ((parseFloat(data.actividad.materiales_entregados) * 100) / parseFloat(data.actividad.materiales_presupuestados))
                    }


                    this.setState({
                        empleado_id: dataEmpleado._id,
                        empleado_nombre: (dataEmpleado.nombre + " " + dataEmpleado.apellido),

                        actividad_id: dataActividad._id,
                        actividad_nombre: dataActividad.nombre,

                        inmueble_id: dataInmueble._id,
                        inmueble_nombre: dataInmueble.nombre,

                        materialesEntregados: ((data.actividad.materiales_entregados).toMoney()),   //data.actividad.materiales_entregados,
                        materialesRequeridos: ((data.actividad.materiales_presupuestados).toMoney()),  //data.actividad.materiales_presupuestados,
                        procesoDeEntrega: avanceEntrega.toFixed(2),


                        dataMateriales: dataMat,
                        manager_proyecto_id: data.actividad.manager_proyecto_id,
                        itemCount: data.pagination.itemCount,
                        pageCount: data.pagination.pageCount,
                        loadingPage: false,
                    });
                }

                else {
                    this.setState({
                        materialesEntregados: data.actividad.materiales_entregados,
                        materialesRequeridos: materialRequerido,
                        procesoDeEntrega: data.actividad.progreso_real,
                        dataMateriales: dataMat,

                        itemCount: data.pagination.itemCount,
                        pageCount: data.pagination.pageCount,

                        loadingPage: false
                    });
                }

            })
            .catch(error => {
                console.log('no traje nara', error);
            })
    }


    /**
    * @memberof PMmaterialesEmpleadoAct
    * @method deleteMaterial
    *
    * @description Elimina un material asignado a una actividad
    *
    */
    deleteMaterial = (material_id) => {
        var actividad_id = this.state.manager_actividad_id;
        axios.post('/manager/proyectos/inmuebles/actividades/material/delete', {
            actividad_id,
            material_id
        })
            .then(res => {
                message.success("!Se eliminó material de actividad!");
                this.state.loadingPage = true;
                this.state.currentPage = 1;

                setTimeout(() => {
                    this.getInfoActividad(1);
                }, 0.5);

            })
            .catch(e => {
                message.error("!Error, no se eliminó material de actividad!")
                console.log('error actividad get', e)
            }
            )
        //
    }


    /**
     * @memberof PMmaterialesEmpleado
     *
     * @method   badgeStatus
     * @description  Obteneemos el tipo de status para el badge
     *
     *
     **/
    badgeStatus = item => {
        switch (item) {
            case 'Sin Materiales Requeridos':
                return "warning"
                break;
            case 'Sin entregas':
                return "default"
                break;
            case 'Excedido':
                return "error"
                break;
            case 'En Proceso':
                return "processing"
                break;
            case 'Concluido':
                return "success"
                break;
        }
    }

    render() {

        //  console.log('MMMSS')
        return (
            <div>
                <Spin spinning={this.state.loadingPage}>
                    <Row className="row-encabezado">
                        <Col xs={24}>
                            <Breadcrumb className="breadcrumb" separator=" " style={{ fontSize: "1.5em", marginTop: "10px" }}>

                                <Breadcrumb.Item>
                                    <Link to={`/admin/project-manager/material-empleado/${this.state.manager_proyecto_id + "_" + this.state.empleado_id}`}
                                        style={{ color: "white" }}>
                                        <Button className="IconArrowBack" onClick={this.volver}>
                                            <IconArrowBack />
                                        </Button>
                                    </Link>
                                </Breadcrumb.Item>

                                <Breadcrumb.Item className="breadcrumb-actual">
                                    {"Materiales de " + this.state.actividad_nombre + " de " + this.state.empleado_nombre}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <section className="p-1">

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo titleLeft="Actividad" infoLeft={this.state.actividad_nombre}
                                    titleRight="Inmueble" infoRight={this.state.inmueble_nombre} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Materiales Presupuestados"
                                    infoLeft={this.state.materialesRequeridos + " tons"}

                                    titleRight="Materiales Entregados"
                                    infoRight={this.state.materialesEntregados + " tons"} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardProgressBar
                                    title="Proceso de Entrega"
                                    percent={parseFloat(this.state.procesoDeEntrega)} />
                            </Col>
                        </Row>

                        <div className="list-title">Materiales</div>

                        <div className="scroll">
                            <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                                <Col span={5} className="center">
                                    <Text type="secondary">Material</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Material Presupuestado</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Material Entregado</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Estatus</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text type="secondary">Avance</Text>
                                </Col>
                                <Col span={2} className="center">
                                    <Text type="secondary">Opciones</Text>

                                </Col>
                            </Row>

                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.dataMateriales}
                                locale={{ emptyText: 'Sin materiales' }}
                                renderItem={item => {
                                    //    console.log('itemitemNN', item.material_status)

                                    let st = this.badgeStatus(item.material_status);
                                    //    console.log('st', st)
                                    return (
                                        <List.Item className="component-list-item lista-materiales">
                                            <Card className="card-list">
                                                <Row style={{ width: '100%' }} className="">
                                                    <Col span={5} className="center">
                                                        <Text strong>{item.material_nombre}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <Text>{((item.material_requerido).toMoney()) + " tons"}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <Text>{((item.material_entregado).toMoney()) + " tons"}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <Badge status={st} text={item.material_status} />
                                                    </Col>
                                                    <Col span={5} className="center">
                                                        <Progress style={{ padding: "0px 15px 0px 0px" }} percent={item.avance.toFixed(2)} status={st} />
                                                    </Col>

                                                    <Col span={2} className="">
                                                        <Link to={`/admin/project-manager/material-actividad/${this.state.manager_actividad_id + "_" + item.material_id + "_" + this.state.manager_proyecto_id}`}>
                                                            <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle">
                                                                <IconEye />
                                                            </Button>
                                                        </Link>
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title={"¿Deseas eliminar " + item.material_nombre + " ?"}
                                                            onCancel={e => e.stopPropagation()}
                                                            okText="Si"
                                                            cancelText="No"
                                                            onClick={e => e.stopPropagation()}
                                                            onConfirm={e => this.deleteMaterial(item.material_id)}
                                                        >
                                                            <Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
                                                                <IconDelete />
                                                            </Button>
                                                        </Popconfirm>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )
                                }}
                            />
                        </div>

                        <Row className="mt-2 pb-3">
                            <Pagination
                                defaultCurrent={1}
                                total={this.state.itemCount}
                                showSizeChanger={false}
                                pageSize={this.state.perPage}
                                onChange={(page) => {
                                    this.getInfoActividad(page)
                                }}
                            />
                        </Row>
                    </section>
                </Spin>

            </div>
        )
    }
}
