import React, { useState, useEffect } from 'react'
import { Route, Switch } from "react-router-dom";
import { Modal } from 'antd'
import axios from 'axios';
import useCountDown from "react-countdown-hook";
//Syncfy
import FinanzasSyncfy from '../Components/Admin/Syncfy/Finanzas'
import TransaccionesSyncfy from '../Components/Admin/Syncfy/TransaccionesSC'
import FacturasSyncfy from '../Components/Admin/Syncfy/FacturasSC'
import SyncfyBitacora from '../Components/Admin/Syncfy/Bitacora'


export default function SyncfyRoutes(props) {

	const [token, setToken] = useState(null);
	const [syncfyUsuarioId, setSyncfyUsuarioId] = useState(null);

	const [timerCount, setTimerCount] = useState(0);

	const setUserId = async (userId) => {
		if (syncfyUsuarioId !== userId && userId !== undefined) {
			setSyncfyUsuarioId(userId)
			await startSession(userId)
		}
	}

	/**
	 *
	 *
	 * @param {*} id_user_syncfy
	 * @memberof CuentaForm
	 * 
	 * @method deleteSession
	 * @description Eliminamos una sesión.
	 */
	const deleteSession = () => {
		axios.defaults.headers.common['syncfy'] = sessionStorage.getItem('syncfy')
		return axios.delete('/syncfy/session',{

		})
			.then(({ data }) => {
				sessionStorage.removeItem('syncfy')
			})
			.catch(error => {
				console.log('Error al eliminar', error.response)
				if(error.response?.status === 403){
					Modal.warning({
						title: error.response?.data?.data?.nombre,
						content:  error.response?.data?.data?.description,
					})
				}
			})
			// .finally(() => {
			// 	console.log('Sessión Eliminada')
			// })
	}



    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method startSession
     * @description Creamos una sesión para poder obtener las credenciales. Declaramos el token de synfy en la cabecera de axios, para que se envie siempre durante la solicitudes
     */
	const startSession = async (id = syncfyUsuarioId ) => {
		if (sessionStorage.getItem('syncfy')) {
			try {
				await deleteSession()
			} catch (error) {
				console.log('error', error)
			}
		}

		return axios.get('/syncfy/session', {
			params: { id_user_syncfy: id }
		})
			.then(({ data }) => {
				sessionStorage.setItem('syncfy', data.token)
				axios.defaults.headers.common['syncfy'] = data.token
				setToken(data.token)
				setTimerCount(timerCount + 1)
			})
			.catch((response) => {
				console.log('error', response)
			})
			.finally(() => {})
	}


	const restartTimer = () => {
		setTimerCount( timerCount + 1  )
	}


	return (
		<Switch>
			<Route exact path="/admin/syncfy/finanzas" render={(props) => <FinanzasSyncfy {...props} />} />

			<Route exact path="/admin/syncfy/transacciones/:cuenta_id" render={(props) => <TransaccionesSyncfy startSession={startSession} restartTimer={restartTimer} timerCount={timerCount} setUserId={setUserId} token={token} clasificadas={false} {...props} />} />

			<Route exact path="/admin/syncfy/transacciones/guardadas/:cuenta_id" render={(props) => <TransaccionesSyncfy setUserId={setUserId} guardadas={true} {...props} />} />

			<Route exact path="/admin/syncfy/facturas/sin-clasificar/:cuenta_id" render={(props) => <FacturasSyncfy startSession={startSession} restartTimer={restartTimer} timerCount={timerCount} key="route-sin-clasificar" setUserId={setUserId} {...props} />} />

			<Route exact path="/admin/syncfy/facturas/guardadas/:cuenta_id" render={(props) => <FacturasSyncfy key="route-guardadas"  setUserId={setUserId} guardadas={true} {...props} />} />

			<Route exact path="/admin/syncfy/bitacora" render={(props) => <SyncfyBitacora {...props} />} />
			
		</Switch>
	)

}
