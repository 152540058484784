import React, { Component } from 'react';
import { DatePicker, Typography } from 'antd';

import echarts from 'echarts';

import ReactEcharts from 'echarts-for-react';


export default class GraficaNominasPorGrupos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inmuebles: [],
            nomina_id: null
        }
    }

    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidUpdate() {


        // if (this.props.nomina_id)

        // if (this.props.nomina_actual !==undefined) {
        //     this.setState({ grupos: this.props.nomina_actual.grupos })
        // }

        // this.setState({ inmuebles: this.props.inmuebles })

    }


    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   componentDidUpdate
    * @description Actualiza el state al recibir nuevos valores del props
    *
    **/
    componentDidUpdate(prevProps) {
        console.log('this',this.props.inmuebles);
        // if (this.props.nomina_actual !==undefined) {
        //     if (this.props.nomina_actual.grupos !== prevProps.nomina_actual.grupos) {    
        //         this.setState({ grupos: this.props.nomina_actual.grupos }, () => {
        //             this.filterData();
        //         })
        //     }
        // }
        // if (this.props.inmuebles !== prevProps.inmuebles) {
        //     this.setState({ inmuebles: this.props.inmuebles })
        // }
    }


    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   filterData
    * @description Separa los datos necesarios para utilizarlos en la grafica
    *
    **/
    // filterData = () => {
    //     //base: [{nombre: "..", total: $}]
    //     let datos = []
    //     console.log(this);

    //     if (this.state.grupos !==undefined) {
    //         this.state.grupos.forEach(grupo => {
    //             let nombreGrupo = grupo.revision.nombre;
    //             let TotalMontoEmpleado = 0;
    //             grupo.revision.empleados.forEach(empleado => {
    //                 TotalMontoEmpleado += empleado.monto
    //             })
    //             datos.push({ nombreGrupo, TotalMontoEmpleado })
    //         });

    //     }
    //     this.setState({ datosFiltrados: datos })
    // }



    render() {

        return (
            <ReactEcharts
                option={{
                    color: ['#3398DB'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.props.inmuebles.map(inmueble => inmueble.inmueble),
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: 'Monto Total',
                            type: 'bar',
                            barWidth: '60%',

                            showBackground: true,
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#6E83FF'},
                                        {offset: 0.5, color: '#6E83FF'},
                                        {offset: 1, color: '#192FFF'}
                                    ]
                                )
                            },
                            emphasis: {
                                itemStyle: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            {offset: 0, color: '#2378f7'},
                                            {offset: 0.7, color: '#2378f7'},
                                            {offset: 1, color: '#83bff6'}
                                        ]
                                    )
                                }
                            },

                            data: this.props.inmuebles.map(inmueble => {

                                // salarios:29300
                                // monto_autorizado:29300
                                // monto_pagado:29300
                                return (this.props.nomina?.registrada_finanzas)?inmueble.monto_pagado:inmueble.monto_autorizado
                            }),
                        }
                    ]
                }}
                style={{ height: '400px', width: '100%' }}
                opts={{ renderer: 'svg' }}
                className='react_for_echarts' />
        )
    }
}
