import React, { Component } from "react";
import { Dropdown, Button, Menu, Avatar } from "antd";
import { DownOutlined } from '@ant-design/icons';

const axios = require('axios')
/**
 *
 *
 * @export
 * @class TagFilter
 * @extends {Component}
 */
export default class TagFilter extends Component {

    state = {
        start: undefined,
        end: undefined,
        next: null,
        page: 1,
        data: [],
        tag: null,
    }

    /**
     *
     *
     * @memberof EmpleadosFilter
     */
    componentDidMount() {
        this.getTags();
    }


    /**
    *
    *
    * @memberof EmpleadosFilter
    * @description Carga los inmuebles/actividades que estan en la nomina
    */
    getTags = (page = this.state.page) => {
        axios.get('/nominas/revision/filtro/inmuebles', {
            params: {
                page: page,
                tag: this.props.tipo,
                nomina: this.props.nomina
            }
        })
            .then(res => {
                //console.log('res', res.data.data);
                let data = res.data.data.itemsList;
                this.setState({ data: data, page: res.data.data.currentPage })
            })
            .catch(error => {
                //console.log('error', error);
            })
    }

    /**
     *
     *
     * @memberof TagFilter
     * @description Carga mas datos
     */
    loadMore = (page) => {
        this.getTags(page)
    }

    updateTag = async (id) => {


        if (this.state.tag === id)
            id = undefined;
        await this.setState({ tag: id })

        this.props.onChange(id)
    }


    render() {

        return (
            <Dropdown
                trigger={["click"]}
                className="dropdown-transparent"
                overlay={(
                    <Menu >
                        {this.state.data.map(({ _id, nombre }, index) => (
                            <Menu.Item key={_id} onClick={() => { this.updateTag(_id) }}>
                                <input
                                    onChange={() => this.updateTag(_id)}
                                    onClick={() => this.updateTag(_id)}
                                    type="radio"
                                    name="tag"
                                    value={_id}
                                    style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                    checked={this.state.tag === _id}
                                />
                                {nombre}
                            </Menu.Item>
                        ))}

                        {this.state.next !== null ? <Menu.Item key='N' >
                            <Button disabled={this.state.loadMore} onClick={(e) => {
                                this.loadMore(this.state.page + 1)
                                e.stopPropagation();
                            }}> Cargar más </Button>
                        </Menu.Item> : null}
                    </Menu>
                )}>
                <Button style={{ minWidth: '150px' }}>{this.props.tag}   <DownOutlined /></Button>
            </Dropdown>

        )
    }
}
