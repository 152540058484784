import React, { Component } from 'react';
import { Avatar, Select, Form, Checkbox, Button, Col, Row, message, Typography, List, Card, Pagination, Spin, Modal, Popover, Popconfirm, Tag, Tooltip, } from 'antd'




import { Link } from "react-router-dom"

import { DeleteOutlined, DownOutlined } from '@ant-design/icons'

//componentes
import { IconEdit, IconStock, IconPlus, IconMaterial } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';



//Modales
import ModalTipos from '../Modales/Tipos/Tipos';



//css

import moment from 'moment';

import '../../../Styles/projectManager.css'


const axios = require('axios').default;
const { Text, Title } = Typography;
const { Option } = Select

export default class Tipos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            modalInventario: false,
            tipo_id: null,

            tipos: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                totalPages: 0,

                search: ''
            },
        }
    }


    /**
    * @memberof Tipos
    * @description Se monta componente
    */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        this.getTipos()

    }



    /**
    * @memberof Tipos
    * @description Se actualiza componente
    */
    componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.tipos.search;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro) {
            this.getTipos();
        }
    }


    /**
     * @param {*} search
     * @memberof Tipos
     * 
     * @description Obtemenmos los tipos
     */
    getTipos = async ({
        page = this.state.tipos.page,
        limit = this.state.tipos.limit,
    } = {}) => {

        console.log("get")
        var search = this.props.search;

        console.log("get", this.props.inventario)

        if (!this.props.inventario) {

            axios.get('/tipos/list', {
                params: {
                    page,
                    limit,
                    search
                }
            }).then(({ data }) => {
                this.setState(state => {
                    state.tipos.data = data.data.itemsList
                    state.tipos.total = data.data.paginator.itemCount
                    state.tipos.totalPages = data.data.paginator.itemCount
                    state.tipos.search = search
                    state.tipos.page = page
                    state.tipos.limit = limit


                    return state

                })
            }).catch(error => {
                console.log(error)
                message.error('Error al cargar los tipos')
            })
        }

        else {
            axios.post('/inventarios', {
                page,
                limit,
            })
                .then(({ data }) => {
                    console.log('data', data);

                    this.setState(state => {
                        state.tipos.data = data.data.itemsList
                        state.tipos.total = data.data.paginator.itemCount
                        state.tipos.totalPages = data.data.paginator.itemCount
                        state.tipos.search = search
                        state.tipos.page = page
                        state.tipos.limit = limit

                        // total: 0,
                        // totalPages: 0,
                        //state.tipos.data = data.data.itemsList
                        //state.tipos.total = data.data.paginator.itemCount
                        //state.tipos.totalPages = data.data.paginator.pageCount
                        state.loading = false

                        return state;

                    })

                })


        }
    }


    /**
     * @param {*} item_id
     * @memberof Tipos
     * 
     * @description Eliminamos el tipo
     */
    delete = (item_id) => {
        axios.delete('/tipos/delete', {
            params: {
                id: item_id
            }
        }).then(({ data }) => {
            message.success("Registro eliminado");
            this.getTipos()

        }).catch(error => {
            console.log(error.response)
            Modal.error({
                title: "No es posible eliminar",
                content: error.response?.data?.error
            })
        })
    }



    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={15} className="flex-left">
                        <Title level={4} className="view-title">{this.props.inventario ? "Lista de Materiales en Inventario" : "Materiales"}</Title>
                    </Col>
                </Row>

                <section className="p-1">
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.tipos.data}

                        header={<Row style={{ width: "100%", padding: "0px 2%" }}>
                            <Col span={3} className="gutter-row center">
                            </Col>

                            <Col span={8} className="gutter-row center"  >
                                Nombre
                            </Col>

                            <Col span={4} className="gutter-row center ">
                                Unidad
                            </Col>

                            <Col span={6} className="gutter-row center">
                                Detalles
                            </Col>

                            <Col span={3} className="gutter-row center">
                                Acciones
                            </Col>
                        </Row>}
                        renderItem={item => (

                            <List.Item className="component-list-item">
                                {console.log(item)}

                                <Card className="card-list" key={item._id}>
                                    <Row style={{ width: '100%' }} className="">
                                        <Col span={3} className="gutter-row">

                                            <Avatar
                                                width={80}
                                                size={"large"}
                                                style={{ background: "#555" }}
                                                src=
                                                {
                                                    (this.props.inventario) ?
                                                        (item.material_id.tipo_id.imagen !== "" && item.material_id.tipo_id.imagen !== undefined) ? `${axios.defaults.baseURL}/upload/${item.material_id.tipo_id.imagen}` : ""
                                                        :
                                                        (item.imagen !== "" && item.imagen !== undefined) ? `${axios.defaults.baseURL}/upload/${item.imagen}` : ""
                                                }
                                            />

                                        </Col>

                                        <Col span={8} className="gutter-row center">
                                            <Text>
                                                {(this.props.inventario) ? item.material_id.nombre : item?.nombre}
                                            </Text>

                                            {(this.props.inventario) ? <strong style={{ marginLeft: "5px" }}>{item.material_id.tipo_id.nombre}  </strong> : ""}
                                        </Col>

                                        <Col span={4} className="gutter-row center">

                                            <Text>
                                                {(this.props.inventario) ? <strong style={{ marginRight: "2px" }}>{ item.material_id.existencias }  </strong> : ""}
                                                {(this.props.inventario) ? (item.material_id.tipo_id.unidad === 0) ? "Piezas" : "Toneladas" : (item.unidad === 0) ? "Piezas" : "Toneladas"}
                                            </Text>
                                        </Col>


                                        <Col span={6} className="gutter-row center">
                                            <Text>
                                                {(this.props.inventario) ? item.material_id.tipo_id.detalles : item?.detalles}
                                            </Text>
                                        </Col>

                                        <Col span={3} className="gutter-row center">
                                            {
                                                (this.props.inventario === true) ?
                                                    <Link to={"/admin/materiales/inventario/" + item._id}>
                                                        <Button className="btn btn-mail" title="Ir a Inventario del Material"
                                                            style={{ background: "#FF45E1", padding: "0px" }}>
                                                            <IconStock color="#FFFFFF" height={22} width={22} style={{ position: "relative", top: 3 }} />
                                                        </Button>
                                                    </Link>
                                                    :
                                                    <Link to={"/admin/material/" + item._id}>
                                                        <Button className="btn btn-pdf" title="Ir a Materiales"
                                                            style={{ background: "#FF45E1", padding: "0px" }}>
                                                            <IconMaterial color="white" />
                                                        </Button>
                                                    </Link>
                                            }
                                            <Button className="btn btn-edit" title="Editar"

                                                onClick={(e) => this.setState({
                                                    modalTipo: true,
                                                    tipo_id: item._id
                                                })}>

                                                <IconEdit />
                                            </Button>
                                            <Popconfirm
                                                placement="topRight"
                                                title={"¿Quieres eliminar el tipo de material " + item.nombre + " ?"}
                                                onConfirm={() => this.delete(item._id)}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button className="btn btn-delete" title="Eliminar">
                                                    <DeleteOutlined style={{ position: "relative", bottom: 2, color: "white" }} />
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.tipos.page}
                            total={this.state.tipos.total}
                            showSizeChanger={false}
                        // onChange={(page) => this.getInventario({ page })}
                        />
                    </Row>
                </section>
                <Button
                    className="btnAddPlus"
                    title="Nuevo registro"
                    onClick={() => this.setState({
                        modalTipo: true,
                        tipo_id: null
                    })}
                >
                    <IconPlus />
                </Button>

                <ModalTipos
                    tipo_id={this.state.tipo_id}
                    modalVisible={this.state.modalTipo}

                    onCancel={() => this.setState({
                        modalTipo: false,
                        tipo_id: null
                    })}
                    onSuccess={() => {
                        this.setState({
                            modalTipo: false,
                            tipo_id: null
                        })
                        this.getTipos()
                    }}
                />
            </Spin>
        )
    }
}
