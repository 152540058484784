import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, Divider, message } from 'antd';
//compoenentes

import { PlusOutlined } from '@ant-design/icons';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;

class ModalMateriales extends Component {

    static defaultProps = {
        onFinish: () => { }
    }

    constructor(props) {
        super(props);
        this.state = {
            dataProyectos: [],
            dataMateriales: [],
            dataTipos: [],
        }
    }


    /**
    * @memberof ModalMateriales
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadProyectos();
        // this.loadMateriales();

        if (typeof this.props.material === "object") {
            this.loadMateriales(this.props.material.tipo_id._id)

            this.modalMateriales.current.setFieldsValue({
                material_id: this.props.material.material_id._id,
                proyecto_id: this.props.material.proyecto_id._id,
                tipo_id: this.props.material.tipo_id._id,
                cantidad: this.props.material.cantidad,
                especificaciones: this.props.material.especificaciones,
            })

            this.setState({
                material: this.props.material.material_id,
                proyecto: this.props.material.proyecto_id,
                tipo: this.props.material.tipo_id,
                unidad: this.props.material.material_id.unidad,
                ordenMaterial_id: this.props.material._id,
            })
        }
        this.loadTipos();
    }


    loadTipos = ({ search } = {}) => {
        axios.get('/tipos/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {
            this.setState({ dataTipos: response.data.data })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })
    }



    /**
     * @memberof ModalMateriales
     * @method loadProyectos
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadProyectos = () => {
        axios.get('/proyectos', {
            params: {
                pagination: 'false'
            }
        }).then((proyectos) => {
            this.setState({
                dataProyectos: proyectos.data.data
            })
        }).catch((error) => {
        })
    }



    /**
     *
     *
     * @memberof ModalMateriales
     * @method loadProyectos
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadMateriales = (tipo_id = this.state.tipo_id) => {
        axios.get('/materiales/list', {
            params: {
                pagination: 'false',
                tipo_id
            }
        }).then((proyectos) => {
            this.setState({
                dataMateriales: proyectos.data.data
            })
        }).catch((error) => {
        })
    }


    /**
     * @memberof ModalMateriales
     *
     * @method onFinish
     * @description Metodo que se ejecuta al responder el formulario
     *
     */
    onFinish = (values) => {
        if (this.props.edicion && this.state.ordenMaterial_id) {
            this.updateOrdenMaterial(values)
        } else {
            this.props.setMaterial({
                ...values,
                _id: this.state.ordenMaterial_id,
                proyecto_id: this.state.proyecto,
                material_id: this.state.material,
                tipo_id: this.state.tipo,
                unidad: this.state.unidad,
            }, this.props.index)
        }
    }


    /**
    * @memberof ModalMateriales
    *
    * @method updateOrdenMaterial
    * @description Actualiza la informacion de un material de una orden
    *
    */
    updateOrdenMaterial = (values) => {
        axios.post('/ordenes/material/update', {
            ordenMaterial_id: this.state.ordenMaterial_id,
            material_id: values.material_id,
            proyecto_id: values.proyecto_id,
            cantidad: values.cantidad,
            especificaciones: values.especificaciones,
            unidad: this.state.unidad,
            tipo_id: values.tipo_id,
        }).then(response => {
            message.success('Se actualizó el material')
            this.props.setMaterial({
                ...values,
                _id: this.state.ordenMaterial_id,
                proyecto_id: this.state.proyecto,
                material_id: this.state.material,
                tipo_id: this.state.tipo,
            }, this.props.index)
        }).catch(error => {
            console.log(error)
            if (error.response?.status === 403)
                message.error(error.response.data.message)
            else
                message.error('No se actualizó el material')
        })
    }


    addTipo = (values) => {
        let tipo = this.tipoRef.current.state.value
        axios.post('/tipos/add', {
            nombre: tipo
        }).then(response => {
            let tipos = this.state.tipos
            tipos.push(response.data.data)
            this.setState({ tipos: tipos })
            this.tipoRef.current.state.value = null
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })
    }

    tipoRef = React.createRef()

    modalMateriales = React.createRef();


    render() {
        return (
            <Form
                layout="vertical"
                className="frm-cuentas"
                name="form-materiales"
                ref={this.modalMateriales}
                onFinish={this.onFinish}
            >
                <Form.Item name="_id" noStyle>
                    <input type="hidden" />
                </Form.Item>
                <Row align="center">
                    <Col span={23}>
                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item
                                    label="Tipo de material"
                                    name="tipo_id"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el tipo de Material"
                                    }]}
                                >
                                    <Select
                                        onSelect={(x, e) => {
                                            this.setState({ tipo: e.tipo, unidad: e.tipo.unidad });
                                            this.loadMateriales(e.tipo._id);

                                            this.modalMateriales.current.setFieldsValue({
                                                material_id: "",
                                            })

                                            
                                        }}
                                        className="form-select"
                                        placeholder="Seleccione tipo de material"
                                        disabled={this.state.edicion}>
                                        {this.state.dataTipos.map((element, index) => {
                                            const { _id, nombre, unidad } = element
                                            return <Option tipo={element} unidad={unidad} value={_id}>{nombre}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>

                            <Col xs={22} md={{ span: 11, offset: 2 }}>
                                <Form.Item
                                    label="Material"
                                    name="material_id"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el material"
                                    }]}
                                >
                                    <Select
                                        className="form-select"
                                        onSelect={(x, e) => this.setState({material: e.material })}
                                        placeholder="Seleccione material"
                                        disabled={this.state.edicion}>
                                        {this.state.dataMateriales.map((element, index) => {
                                            const { _id, nombre, unidad } = element
                                            return <Option material={element} unidad={unidad} value={_id}>
                                                {nombre}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item
                                    label="Proyecto"
                                    name="proyecto_id"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el proyecto"
                                    }]}
                                >
                                    <Select
                                        className="form-select"
                                        onSelect={(x, e) => this.setState({ proyecto: e.proyecto })}
                                        placeholder="Seleccione proyecto" disabled={this.state.edicion}>
                                        {this.state.dataProyectos.map((element, index) => {

                                            const { _id, nombre, logo, color } = element

                                            return <Option proyecto={element} value={_id}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>

                            <Col xs={22} md={{ span: 11, offset: 2 }}>
                                <Form.Item
                                    label="Cantidad"
                                    name="cantidad"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el proyecto"
                                    }]}

                                    extra={(this.state.unidad !== undefined) ? ((this.state.unidad === 1) ? <strong>(toneladas)</strong> : <strong>(piezas)</strong>) : undefined}
                                >
                                    <InputNumber addonAfter={"M N  S"} min={0} defaultValue={0} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24}>
                                <Form.Item
                                    label={"Especificaciones"}
                                    name="especificaciones"
                                >
                                    <Input.TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        )
    }
}


export default function (props) {

    return <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}
    >

        <div className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={props.onCancel}>
                <IconCloseModal />
            </Button>
             <Title level={3} className="modal-title"> Agregar Material</Title> 
        </div>
        <ModalMateriales
            {...props}
        />
    </Modal>
}