import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Menu, DatePicker, Avatar, message, List, Card, Pagination, Checkbox, Dropdown, Layout, Empty, Radio, Popover } from 'antd'
import { Link } from 'react-router-dom';
import { IconArrow, IconPlus, IconEye, IconEdit, IconDelete, IconDownloadCustom as Download } from '../../Widgets/Iconos';
import Reporte from './ReporteClientes'
import ModalClientes from '../Modales/Clientes/ModalClientes';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import "../../../Styles/clientes.css";

const axios = require('axios').default

axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
const { RangePicker } = DatePicker;
const { Text } = Typography
const { Content } = Layout;


function Item({ propiedades, ...props }) {
    return <span {...props}>
        <Avatar.Group >
            {propiedades?.map(({ proyecto_id, inmueble_id, rubro_id, _id }, index) => (
                proyecto_id ?

                    <CustomAvatar
                        image={proyecto_id?.logo}
                        name={proyecto_id?.nombre}
                        title={proyecto_id?.nombre}
                        color={(proyecto_id?.color !== undefined) ? proyecto_id.color : '#1890ff'}
                        style={{ backgroundColor: (proyecto_id?.color !== undefined) ? proyecto_id.color : '#1890ff', marginRight: "2px", cursor: "pointer" }}
                    />

                    : 'N/A'
            ))}
        </Avatar.Group>
    </span>
}

export default class Clientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clientes: [],
            dataRpt: {
                fecha: '',
                espacio: '',
                dataInfo: [],
                author: '',
                totalTran: 0,
                sinFiltros: true,

                fecha_inicio: '',
                fecha_fin: '',
                area: '',
                rubro: '',
                subrubro: '',
                proyectos: [],
                cuentas: [],
                numConsulta: 0,
                montoPagado: 0,

                totalVenta: 0,
                totalPagado: 0,
                totalDeuda: 0
            },
            dropdowns: {
                projects: false,
                areas: false,
            },

            /* Paginado */
            itemCount: 0,
            perPage: 100,
            pageCount: 1,
            currentPage: 1,

            projectsSelected: [],
            projects: [],
            areas: [],
            rubros: [],

        }
    }

    //Referencia
    ModalClientesRef = React.createRef();


    /**
   * @memberof Clientes
   * @method   componentDidMount
   * @description  Al montar el componente:
   * Se muestra el filtro superior
   * Se establece el valor del header en axios
   * Se llama al metodo receivedData que hace la consulta de clientes a mostrar en la tabla
   * Se llama al metodo getProjects que hace la consulta de proyectos para mostrar en la lista de filtro
   * Se llama al metodo getAreas que hace la consulta de areas para mostrar en la lista de filtro
   **/
    componentDidMount() {

        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Obtenemos el listado de Clientes
        this.receivedData();

        this.getProjects();
        this.getAreas();
    }

    /**
     *
     *
     * @memberof Clientes
     *
     * @method componentDidUpdate
     * @description actualiza la vista si se ha realizado una busqueda
     */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.receivedData();
            this.state.searching = false;
        }
    }

    /**
    * @memberof Clientes
    * @method receivedData
    * @description Obtiene la informacion y actualiza los valores locales de la lista.
    */
    receivedData = async () => {

        this.setState({ loading: true });

        axios.get('/clientes/list', {
            params: {
                //pagination:false,
                search: this.props.search,
                page: this.state.currentPage,
                limit: this.state.perPage,
                projects: this.state.projectsSelected,
                area_id: this.state.area,
                rubro_id: this.state.rubro,
            }
        })
            .then(response => {
                console.log(response.data.data)
                this.setState({
                    clientes: response.data.data.itemsList,
                    currentPage: response.data.data.currentPage,
                    itemCount: response.data.data.itemCount,
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('Error al traer la información')
            })
            .finally(f => {
                this.setState({ loading: false })
            });

    }


    /**
    * @memberof Clientes
    * @method eliminar 
    * @description Elimina registro de cliente por su id
    */
    eliminarCliente = id => {

        axios.post('/clientes/delete', {
            id: id
        })
            .then((response) => {
                message.success("Eliminado correctamente.");
                this.receivedData();
            })
            .catch((error) => {
                message.error(error.response.data.message)

            })

    }

    /**
     *  @memberof Clientes
     * @method hideModal 
     * @description Cerrar modal y limpiar valor registroId
    */
    hideModal = () => {

        this.setState({
            cliente: undefined,
            modalVisible: false,
        })

        this.receivedData();
    }

    /**
     * @memberof Clientes
     * @description Actualiza los valores del state;
     */
    update = () => this.setState({ update: !this.state.update })

    /**
     * @memberof Clientes
     * @function getProjects
     * @description Obtenemos los proyectos y los ponemos en el listado para filtro
     *
     * */
    getProjects = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({
                projects: proyectos.data.data
            })
        }).catch((error) => {
        })
    };

    /**
     
     * @memberof Clientes
     * @function getAreas
     * @description Obtenemos las areas y los ponemos en el dropdown
     *
     * */
    getAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {

            this.setState({ areas: response.data.data })
        })
    }

    /**
      * @memberof Clientes
     *
     * @function onProjectsChange
     * @description Se actualiza el arreglo de proyectos seleccionados jutno con los arreglos IDs
     * */
    onProjectsChange = (_id, status) => {

        let { projectsSelected } = this.state;
        let index = projectsSelected.findIndex(p => p === _id)

        if (index !== -1)
            projectsSelected.splice(index, 1)
        else
            projectsSelected.push(_id)

        this.setState({ projectsSelected }, () => this.receivedData())
    };

    /**
      * @memberof Clientes
     *
     * @function updateAreas
     * @description Se actualiza el filtro de areas junto con el de rubros.
     *
     * @param id
     * @description ID del area a agregar
     *
     * */
    updateAreas = async (id) => {
        this.setState({ area: id })
        //Si el ID es nulo, se borran todos los elementos
        if (this.state.area === id) {
            //Si el ID es igual al que esta en el state, entonces lo vamos a borrar junto con los rubros
            await this.setState({
                area: undefined,
                rubro: undefined,
                subrubro: undefined,
                rubros: [],
                subrubros: [],
            })
            this.receivedData();

            //Si no, se buscan
        } else return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        })
            .then(async (rubros) => {
                await this.setState({
                    rubro: null,
                    rubros: rubros.data.data,
                    subrubro: undefined,
                    subrubros: [],
                })
                this.receivedData();
            })
    }

    /**
      * @memberof Clientes
     *
     * @function updateRubros
     * @description Se actualizan los rubros en general. Evento cuando se cambia un rubro
     *
     * @param id
     * @description ID del rubro a agregar
     *
     * */
    updateRubros = async (id) => {
        if (this.state.rubro === id) {
            //Si el ID es igual al que esta en el state, entonces lo vamos a borrar junto con los rubros
            this.setState({ rubro: undefined }, () => this.receivedData())
        } else {
            this.setState({ rubro: id }, () => this.receivedData())
        }
    }



    /**
    * @memberof Clientes
     * @function handleVisibleChange
     * @description Permite mostrar u ocultar los dropdowns en general
     * @param type
     * @description Tipo de dropdown (proyeto, rubro, subruro, area, cuenta y tipo)
     * @flag
     * @description Si se mostrará u ocultará.
     *
     * */
    handleVisibleChange = (type, flag) => {
        let { dropdowns } = this.state;
        dropdowns[type] = flag;
        this.setState({ dropdowns });
    };


    /**
        * @memberof Clientes
       * @function receivedConsulta
       * @description Consulta de clientes para mostrar en la tabla
       * */
    receivedConsulta = () => {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setState({ loading: true })

        var page = this.state.currentPage,
            quantity = 10000,
            filter = {},
            sort = {},
            projects = this.state.projectsSelected,
            area = this.state.area,
            rubro = this.state.rubro,
            subrubro = this.state.subrubro,
            cuentas = this.state.cuentasSelected,
            tipo = this.state.tipo,
            end = this.state.end,
            start = this.state.start


        var numPage = 1, numReg = 0;

        axios.post('/clientes/reporteInfo', {
            params: {
                page: page,
                limit: quantity,
                filter,
                sortfield: sort,
                projects,
                cuentas,
                area,
                rubro,
                subrubro,
                tipo,
                end,
                start
            }
        })
            .then(res => {
                var data = res.data.data;
                var consultaN = this.state.dataRpt.numConsulta;
                consultaN = consultaN + 1;


                var data_rpt = {
                    fecha: data.fecha,
                    espacio: '',
                    dataInfo: data.clientes,
                    author: data.author,

                    totalTran: data.total,
                    sinFiltros: data.sinFiltros,

                    fecha_inicio: data.fecha_inicio,
                    fecha_fin: data.fecha_fin,
                    area: data.area,
                    rubro: data.rubro,
                    subrubro: data.subrubro,
                    proyectos: data.proyectos,
                    numConsulta: consultaN,
                    montoPagado: data.montoPagado,

                    totalVenta: data.totalVenta,
                    totalPagado: data.totalPagado,
                    totalDeuda: data.totalDeuda
                }


                this.setState({
                    dataRpt: data_rpt,
                    loading: false,
                    datosConsultados: true,
                    numConsulta: consultaN
                });


            })
            .catch(res => {
                console.log("error", res);
                this.setState({ dataClientes: [] });
            });



    };



    render() {


        return (
            <div>
                <Row className="row-encabezado text-white">
                    <Col xs={24} xl={4} xxl={4}>
                        <h2>Clientes</h2>
                    </Col>
                    <Col xs={8} lg={4} className="center ">
                        <Dropdown
                            trigger={['click']}
                            visible={this.state.dropdowns.projects}
                            onVisibleChange={(flag) => this.handleVisibleChange('projects', flag)}
                            overlay={<Content className="dropdown-content">
                                <Menu className="parent-element">
                                    {this.state.projects.map((item, index) => {
                                        const { _id, nombre, logo, color } = item;
                                        return (
                                            <Menu.Item className="child-element" key={index}>
                                                <Checkbox onChange={() => this.onProjectsChange(item._id)}>
                                                    <CustomAvatar image={logo} name={nombre} color={color} size="small" style={{ margin: '0 5px' }} />
                                                    {nombre}
                                                </Checkbox>
                                            </Menu.Item>
                                        )

                                    })}
                                </Menu>
                            </Content>}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> Proyecto <IconArrow /> </a>
                        </Dropdown>
                    </Col>
                    <Col xs={8} lg={3} className="center">
                        <Dropdown
                            trigger={['click']}
                            visible={this.state.dropdowns.areas}
                            onVisibleChange={(flag) => this.setState(state => {
                                state.dropdowns.areas = flag
                                return state
                            })}
                            overlay={
                                <Menu style={{ minWidth: '260px', padding: '5px' }} >
                                    {this.state.areas.length > 0 ? this.state.areas.map((area, index) => {
                                        return <Menu.Item key={index}>
                                            <input
                                                onChange={() => this.updateAreas(area._id)}
                                                type="radio"
                                                name="area"
                                                value={area._id}
                                                style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                                checked={this.state.area === area._id}
                                            />
                                            {area.nombre}
                                        </Menu.Item>

                                    }) : <Empty />}

                                </Menu>
                            }>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> Areas <IconArrow /> </a>
                        </Dropdown>
                    </Col>
                    <Col xs={8} lg={4} className="center ">
                        <Dropdown
                            trigger={['click']}
                            visible={this.state.dropdowns.rubros}
                            onVisibleChange={(flag) => this.setState(state => {
                                state.dropdowns.rubros = flag
                                return state
                            })}
                            overlay={
                                <Menu style={{ minWidth: '260px', padding: '10px 5px', maxHeight: '300px', overflowY: 'auto' }} >
                                    {this.state.rubros.length > 0 ? this.state.rubros.map(({ _id, nombre }, index) => {

                                        return <Menu.Item key={index}>
                                            <Radio
                                                //onChange={() => this.updateRubros(_id)}
                                                onClick={() => this.updateRubros(_id)}
                                                type="radio"
                                                name="rubro"
                                                value={_id}
                                                style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                                checked={this.state.rubro === _id}
                                            >
                                                {nombre}
                                            </Radio>
                                        </Menu.Item>

                                    }) : <Empty description="Debe seleccionar al menos un area." />}

                                </Menu>
                            }>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> Rubros <IconArrow /> </a>
                        </Dropdown>
                    </Col>

                    {/*<Col className="row-filter" span={1}>
                                <Button disabled={!this.state.datosConsultados} style={{
                                    background: "transparent", border: "none"
                                }} title="Reporte">

                                    <Reporte
                                        rpt={this.state.dataRpt}
                                        reporte={this.state.datosConsultados}
                                        numConsulta={this.state.numConsulta}

                                        trigger={() =>
                                            <Button
                                                type="link" size="small"
                                                style={{ position: 'relative', top: '0.4em', right: '0.5em' }}
                                                onClick={this.receivedConsulta}>
                                                <Download />
                                            </Button>}
                                    />
                                </Button>
                            </Col>*/}

                </Row>

                <section className="p-1">


                    <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles">
                        <Col span={1} className="center">
                            <Text type="secondary">#</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Nombre</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Apellido</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Proyectos</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Correo</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text type="secondary">Telefono</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Acciones</Text>
                        </Col>
                    </Row>
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.clientes}
                        renderItem={(item, i) => (
                            <List.Item className="component-list-item" key={item._id}>
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">
                                        <Col span={1} className="center">
                                            <Text strong>{(i + 1) * this.state.currentPage}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <Text strong>{item.nombre}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <Text strong>{item.apellido}</Text>
                                        </Col>
                                        <Col span={4} className="center">

                                            <Dropdown overlay={
                                                <Menu>
                                                    {item?.propiedades.map(({ proyecto_id, inmueble_id, rubro_id, _id }) => <Menu.Item>


                                                        {proyecto_id ? <Link to={`/admin/clientes/detalle/${item._id}/${_id}`} >

                                                            <CustomAvatar
                                                                image={proyecto_id?.logo}
                                                                name={proyecto_id?.nombre}
                                                                size="small"
                                                                title={proyecto_id?.nombre}
                                                                color={(proyecto_id?.color !== undefined) ? proyecto_id.color : '#1890ff'}
                                                                style={{ backgroundColor: (proyecto_id?.color !== undefined) ? proyecto_id.color : '#1890ff', marginRight: "2px", cursor: "pointer" }}
                                                            />
                                                            {inmueble_id ? inmueble_id.nombre : rubro_id.nombre}
                                                        </Link> : null}
                                                    </Menu.Item>)}

                                                </Menu>
                                            }>
                                                <Item propiedades={item?.propiedades} />
                                            </Dropdown>


                                        </Col>
                                        <Col span={4} className="center">
                                            <Text>{item.email}</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text>{item.telefono}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <Link title="Ver" className="EditIcon" to={`/admin/clientes/detalle/${item._id}`} >
                                                <Button type="primary" className="button-survey btn-eye button-eye" name={item.nombre} id={item.id} title="Ver detalle"  >
                                                    <IconEye />
                                                </Button>
                                            </Link>
                                            <Button type="primary" className="button-survey btn-edit button-edit" title="Editar"
                                                onClick={() => { this.setState({ cliente: item, modalVisible: true, modalTitle: 'Editar' }) }}
                                            >
                                                <IconEdit />
                                            </Button>
                                            <Popconfirm placement="topRight" title="¿Eliminar este cliente?" okText="Si, seguro" cancelText="No" name={item.nombre} id={item._id} onConfirm={() => { this.eliminarCliente(item._id) }} >
                                                <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" name={item.nombre} title="Eliminar"  >
                                                    <IconDelete />
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.currentPage}
                            total={this.state.itemCount}
                            pageSize={this.state.perPage}
                            showSizeChanger={false}
                            onChange={(page) => {
                                this.receivedData(page)
                            }}
                        />
                    </Row>

                </section>

                <ModalClientes
                    modalVisible={this.state.modalVisible}
                    modalTitle={this.state.modalTitle}
                    closeMethod={this.hideModal}
                    cliente={this.state.cliente}
                />


                <Reporte
                    rpt={this.state.dataRpt}
                    reporte={this.state.datosConsultados}
                    numConsulta={this.state.numConsulta}

                    trigger={() =>
                        <Button
                            type="link" size="small"
                            style={{ position: 'relative', top: '0.4em', right: '0.5em' }}
                            onClick={this.receivedConsulta}>
                            <Download />
                        </Button>}
                />

                <Button className="btnAddPlus" title="Nuevo registro" onClick={() => this.setState({ accionModal: 'Añadir', modalVisible: true })} >
                    <IconPlus />
                </Button>
            </div>
        )
    }
}