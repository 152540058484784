import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, Select, Typography, message, Spin, InputNumber, Space, Statistic } from 'antd';
import './../../../../Styles/modales.css'
import { IconCloseModal, IconArrowSelect, } from '../../../Widgets/Iconos';
import PropTypes from "prop-types";

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const axios = require('axios').default;
const { Option } = Select;
const { Title, } = Typography;

/**
 *
 *
 * @export
 * @class ModalCrearInmuebleEmpleado
 * @extends {Component}
 */
class InmuebleEmpleado extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            form: {},

            dataInmuebles: [],
            dataTajos: [],//Muestra los tajos seleccionados por el filtro de Inmueble
            tajo: {},
            canEdit: false,
            update: false,
            loading: false,

            selectedTajos: {},
            tajos: []

        }
    }

    //Referencia de form
    formInmueble = React.createRef();



    /**
     *
     *
     * @memberof ModalCrearInmuebleEmpleado
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     *
     *
     * @memberof ModalCrearInmuebleEmpleado
     *
     * @function componentDidUpdate
     * @description Se ejecuta el actualizar cualquier estado. Verifica que el groupID de las propiedades sea diferente al del estado. 
     * En caso de que sea nulo, se inicia el proceso de nuevo grupo, caso contrario, obtenemos el grupo para editarlo.
     */

    componentDidMount() {
        this.state.canEdit = true;
        this.state.id = this.props.id;
        this.state.tajos = this.props.tajos;
        this.loadInmueblesList();

        this.loadTajos(this.props.tajos)
    }
    /**
     * @memberof ModalCrearInmuebleEmpleado
     * @method loadInmueblesList
     * @description Obtiene la lista usuarios que no estan 
     */
    loadInmueblesList = () => {
        console.log('this.props', this.props)
        const { usuario_id, id } = this.props;
        axios.get('/empleados/inmuebles/disponibles', {
            params: {
                id: usuario_id,
                inmueble_id: id
            }
        })
            .then(e => {
                console.log('load data list', e);
                this.setState({
                    dataInmuebles: e.data.data,
                    tajo: this.props.tajos[0]
                })
                //Si no es nulo, es una edicion
                if (id !==null) {
                    this.onInmuebleChange(id)
                    this.formInmueble.current.setFieldsValue({
                        inmueble_id: id,
                        tajos: this.props.tajos.map(tajo => ({
                            tajo_id: tajo.tajo_id,
                            porcentaje_avance: tajo.porcentaje_avance
                        })),
                        _id: id,
                    });
                }

                this.state.canEdit = false
            })
            .catch(
                error => {
                    message.error('No se cargaron empleados')
                }
            )


    }



    /**
     *
     *
     * @memberof ModalCrearInmuebleEmpleado
     */
    onInmuebleChange = (e) => {
        let arreglo_tajos = []
        for (const inmueble of this.state.dataInmuebles) {
            if (inmueble.inmueble_id._id === e) {
                arreglo_tajos = inmueble.tajos;
                break;
            }
        }

        console.log('arreglo_tajos', arreglo_tajos);

        this.setState({
            dataTajos: arreglo_tajos.sort((a, b) => a.tajo_id.nombre.localeCompare(b.tajo_id.nombre))
        })
    }



    /**
     *
     *
     * @memberof ModalCrearInmuebleEmpleado
     * @function save
     * 
     * @description Se guardan los valores del formulario para agregar inmueble
     * @param values Valores del formulario
     */
    save = values => {
        console.log(values);

        if (!Array.isArray(values.tajos))
            Modal.error({
                title: "No hay ningun tajo",
                content: "Para poder guardar el inmueble es necesario agregar un tajo."
            })

        axios.post('/empleados/inmuebles/save', {
            inmueble_id: values.inmueble_id,
            tajos: values.tajos,
            usuario_id: this.props.usuario_id,

            _id: values._id,
            avance_tajo: values.avance
        })
            .then(e => {
                message.success(e.data.message)
                this.props.hideModal()
            })
            .catch(error => {
                console.log('ERROR', error)
                Modal.error(error.response.data.message)
            })
    }




    valuesChange = (valuesChanged, values) => {
        if (valuesChanged.tajos) {

            const form = this.formInmueble.current.getFieldsValue();
            console.log();
            // console.log(valuesChanged.tajos);
            this.loadTajos(form.tajos)
        }
    }


    loadTajos = (tajos = []) => {

        console.log('tajos', tajos);
        console.log('tajos', tajos);


        let selectedTajos = {}
        for (let index = 0; index < tajos.length; index++) {
            selectedTajos[tajos[index].tajo_id] = true
        }
        this.setState({ selectedTajos })
    }


    obtenerCantidades = ({ tajo_id, key } = {}) => {
        const { dataTajos } = this.state
        const tajo = dataTajos.find(tajo => tajo.tajo_id._id === tajo_id)


        console.log({ tajo, tajo2: tajo });
        this.setState(state => {
            state.tajos[key] = tajo
            return state;
        })
    }


    render() {
        const { modalVisible, hideModal, id, tajos } = this.props;
        const { canEdit, tajo } = this.state;
        const { formInmueble } = this;


        window.ref = formInmueble
        return (

            <Spin spinning={this.state.loading}>
                <Form layout="vertical" ref={formInmueble} className="frm-cuentas" name="formulario-transacciones" onFinish={this.save} initialValues={this.state.form} onValuesChange={this.valuesChange}>
                    <Form.Item name="_id" noStyle>
                        <Input type="hidden" ></Input>
                    </Form.Item>
                    <Row align="center">
                        <Col span={22}>
                            <Form.Item label="Inmueble" name="inmueble_id" rules={[{ required: true, message: "Seleccione el usuario a registrar" }]} >
                                <Select onSelect={this.obtenerCantidades} clearIcon={<IconArrowSelect />} disabled={id !==null} onChange={this.onInmuebleChange} optionFilterProp="children" showSearch >
                                    {this.state.dataInmuebles.map(function ({ inmueble_id }, index) {
                                        return <Option value={inmueble_id?._id}>
                                            {inmueble_id?.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="center">
                        <Col span={22}>* Tajos</Col>
                        <Col span={22}>
                            <Form.List name="tajos">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                                            <Row style={{ width: '100%' }} gutter={[16, 16]}>
                                                <Col span={14}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'tajo_id']}
                                                        fieldKey={[fieldKey, 'tajo_id']}
                                                        rules={[{ required: true, message: 'Seleccione un tajo o elimine la actividad.' }]}
                                                    >
                                                        <Select
                                                            style={{ minWidth: '100%' }}
                                                            optionFilterProp="children"
                                                            clearIcon={<IconArrowSelect />}
                                                            showSearch
                                                            onSelect={tajo_id => this.obtenerCantidades({ tajo_id, key: fieldKey })}
                                                        >
                                                            {this.state.dataTajos.map(({ tajo_id }, index) => {
                                                                return <Option value={tajo_id._id} disabled={this.state.selectedTajos[tajo_id._id]}>
                                                                    {tajo_id.nombre}
                                                                </Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5} className="info-empleado-pago">
                                                    <small>Avanzado</small><br />
                                                    {console.log({
                                                        'tajos[]': this.state.tajos,
                                                        'tajos[fieldKey]': this.state.tajos[fieldKey]
                                                    })}
                                                    <span>{parseFloat(this.state.tajos[fieldKey] ? this.state.tajos[fieldKey].porcentaje_avance_final : 0).toMoney()} % </span>
                                                </Col>
                                                <Col span={4} >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'porcentaje_avance']}
                                                        fieldKey={[fieldKey, 'porcentaje_avance']}

                                                        rules={[

                                                            () => ({
                                                                validator: (_, value) => {
                                                                    
                                                                    console.log('A X V',{
                                                                        _, value
                                                                    })
                                                                    
                                                                    if (value === null || value === undefined)
                                                                        return Promise.reject(new Error('Debe de ingresar un valor.'))
                                                                    

                                                                    console.log(' value < parseFloat(this.state.tajos[fieldKey].porcentaje_avance_final)',  value < parseFloat(this.state.tajos[fieldKey].porcentaje_avance_final));
                                                                    if (this.state.tajos[fieldKey] && !isNaN(parseFloat(this.state.tajos[fieldKey].porcentaje_avance_final))  && value < parseFloat(this.state.tajos[fieldKey].porcentaje_avance_final))
                                                                        return Promise.reject(new Error('El porcentaje debe ser mayor a ' + parseFloat(this.state.tajos[fieldKey] ? this.state.tajos[fieldKey].porcentaje_avance_final : 0) + '%'))
                                                                    
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <InputNumber

                                                            // min={}
                                                            precision={0}
                                                            disabled={(this.props.tajos && this.props.tajos[key] && this.props.tajos[key].porcentaje_avance !== undefined)}
                                                            placeholder="Porcentaje"
                                                            formatter={value => `${value}%`}
                                                            parser={value => value.replace('%', '')}

                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}>
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add({
                                                porcentaje_avance: 0
                                            })} block icon={<PlusOutlined />}>
                                                Agregar una actividad
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>

                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


export default function (props) {

    const { modalVisible, hideModal, id, } = props;

    return <Modal visible={modalVisible} onCancel={hideModal} title={null} footer={null} closable={false} className="modal-form modal-empleado-tajos" destroyOnClose={true} zIndex={1000} width="100vw">
        <div className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={props.hideModal}>
                <IconCloseModal />
            </Button>
            <Title level={3} className="modal-title">{(id === null) ? "Nuevo Inmueble" : "Editar Inmueble"}</Title>
        </div>
        <InmuebleEmpleado {...props} />
    </Modal>
}

// class ModalCrearInmuebleEmpleado
