import React, { Component, createRef } from "react";
import { Avatar, Button, Col, DatePicker, Form, Input, InputNumber, List, message, Modal, Row, Select, Spin, Statistic, Typography, Upload, Checkbox, Tooltip } from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Redirect, Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from 'moment'
import 'moment/locale/es';
//componentes
import { IconArrowSelect, IconCloseModal, IconTemplate } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { MyUpload } from './../../../Widgets/Upload';
import './../../../../Styles/finanzas.css'

const axios = require('axios').default;
moment.locale('es');
const { Option } = Select;
const { Title, } = Typography;

export default class ModalTran extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        },

    };

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            updateChecker: false
        }
    }

    //Referencia de form dentro del modal.
    modalTransaccion = createRef();

    /**
     * @memberof ModalTran
     * @method componentDidMount
     * @description Se ejecuta al finalizar el render de la vista.
     */
    componentDidMount() { axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token'); }


    render() {
        const { modalVisible, accionModal, registroId } = this.props;
        const { modalTransaccion, normFile } = this;

        var titulo = (registroId) ? "Editar Transacción" : "Nueva Transacción";
        const gutter = [15, 45]


        return (
            <Modal
                visible={modalVisible}
                onCancel={this.props.hideModal}
                title={null}
                footer={null}
                closable={false}
                className={(accionModal === "detalle") ? "modal-form modal-det modal-transaccion" : "modal-form modal-transaccion"}
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Multiples Transacciones</Title>
                </div>


                <Spin spinning={this.state.spinning}>
                    <Form
                        layout="vertical"
                        ref={this.modalTransaccion}
                        className="frm-transacciones"
                        name="formulario-transacciones"
                    >
                        <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                            <List
                                style={{ justifyContent: "center" }}
                                align="center"
                                size="small"
                                header={<div>Toma en cuenta las siguientes consideraciones.</div>}
                                footer={<div>Descarga el template CSV para subir múltiples transacciones.</div>}
                                dataSource={[
                                    <div style={{ justifyContent: 'center', width: '100%' }}>- El formato de fecha en dd/mm/aaaa</div>,
                                    <div style={{ justifyContent: 'center', width: '100%' }}>- Disminuir el uso de acentos, o evitarlo para prevenir una mala lectura</div>,
                                    <div style={{ justifyContent: 'center', width: '100%' }}>- Eliminar el formato de moneda ($123)</div>,
                                    <div style={{ justifyContent: 'center', width: '100%' }}>- Asegurarse que el dato esté escrito correctamente  igual que en el sistema (incluyendo mayúsculas y acentos)</div>]}
                                renderItem={item => <List.Item>{item}</List.Item>}
                            />

                        </Row>
                        <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                            <a href={`${axios.defaults.baseURL}/files/template.csv`} download>
                                <IconTemplate />
                            </a>
                        </Row>
                        <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                            <label> Una vez que hayas guardado tu archivo CSV con las transacciones, súbelo aqui. </label>
                        </Row>
                        <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                            <Col span={24} className="colDocsComprobantes">
                                <Form.Item
                                    name="actualizacion"
                                    initialValue={false}
                                >
                                    <Checkbox onChange={(e) => {
                                        this.modalTransaccion.current.setFieldsValue({ actualizacion: e.target.checked })
                                    }}>
                                        Actualizar saldos
                                       <Tooltip title="Habilita la opcion para actualizar los saldos de las cuentas con los ingresos o egresos incluidos en el archivo CSV.">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center", margin: "20px 0px", padding: '50px 0px' }}>
                            <Col span={24} className="colDocsComprobantes">
                                <Form.Item
                                    label=""
                                    name="docs"
                                >
                                    <Upload
                                        accept={"csv"}
                                        data={() => {
                                            return {
                                                actualizar: this.modalTransaccion.current.getFieldValue('actualizacion')
                                            }
                                        }}
                                        headers={{ Authorization: axios.defaults.headers.common['Authorization'] }}
                                        className="avatar-uploader"
                                        action={axios.defaults.baseURL + "/voucher/add/csv"}
                                        onChange={
                                            (info) => {
                                                const { status } = info.file;
                                                console.log('status',status)
                                                if (status === 'done') {
                                                    if (info.file.response.erroneos.length > 0) {
                                                        Modal.warning({
                                                            title: 'Se guardaron parcialmente los archivos.',
                                                            content: <List style={{ maxHeight: '300px', overflow: 'auto' }}
                                                                align="left"
                                                                size="small"
                                                                header={<div>Algunos elementos no pudieron guardarse:</div>}
                                                                dataSource={info.file.response.erroneos}
                                                                renderItem={item => <List.Item>{item}</List.Item>}
                                                            />,
                                                            onOk: this.props.accept


                                                        })
                                                    } else {
                                                        message.success(`${info.file.response.message}`);
                                                    }

                                                } else if (status === 'error') {
                                                    message.error(`${info.file.name} No se pudo subir el archivo.`);
                                                }
                                            }
                                        }

                                    >
                                        <Button>
                                            <UploadOutlined /> Cargar
                                    </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Spin>


            </Modal>
        )
    }
}