import React, { Component } from 'react';
import { Button, Card, Col, message, Popover, Row, Spin, Statistic, Typography, Tag, List } from 'antd'
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaShoppingCart } from "react-icons/fa";
//componentes
import { CardTransaccionesSC } from '../../Widgets/Cards/Cards';
import { IconDetails, IconCheckMedal, IconFinanzas } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import Logged from "../../../Hooks/Logged";
//css
import 'react-multi-carousel/lib/styles.css';
import '../../../Styles/finanzas.css'

const moment = require('moment');
const { Text, Title } = Typography;




const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1.9
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default class FinanzasBelvo extends Component {
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            cuentas: [],
            transacciones: [],
            facturasC: []
        }
    }


    componentDidMount() {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getCuentas()
        this.getUltimas()
        this.getTransacciones()
    }


    /**
     *
     *
     * @memberof Facturas
     * 
     * @method renderTags
     * @description renderiza tags dependiendo del estatus o el proyecto
     */
    renderTags = (tipo, item) => {

        return <Tag className="tag-proyecto" color={item.color}>{item.nombre}</Tag>

    }

    /**
    *
    *
    * @memberof Facturas
    * 
    * @method getCuentas
    * @description trae las Cuentas que estan ligados a Belvo 
    */
    getCuentas = () => {
        this.setState({ cuentas_s: true })
        axios.get('/cuentas/list', {
            params: {
                belvo_link: true,
                pagination: false
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({ cuentas: response.data.data })

        }).catch(error => {
            console.log(error)
        }).finally(() => {
            this.setState({ cuentas_s: false })
        })
    }

    /**
    *
    *
    * @memberof Facturas
    * 
    * @method getCuentas
    * @description trae las Cuentas que estan ligados a Belvo 
    */
    getTransacciones = () => {
        this.setState({ transacciones_s: true })
        axios.get('/transacciones/list', {
            params: {
                belvo_link: true,
                sort: true
            }
        }).then(response => {
            console.log('transacciones', response.data.data.itemsList)
            this.setState({ transacciones: response.data.data.itemsList })

        }).catch(error => {
            console.log(error)
        }).finally(() => {
            this.setState({ transacciones_s: false })
        })
    }


    /**
    *
    *
    * @memberof Facturas
    * 
    * @method getUltimas
    * @description trae las Cuentas que estan ligados a Belvo 
    */
    getUltimas = () => {
        this.setState({ facturas_s: true })
        axios.get('/belvo/dashboard', {
            params: {
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({ facturasC: response.data.data.facturas })
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            this.setState({ facturas_s: false })
        })
    }


    render() {

        const { cuentas_s, transacciones_s, facturas_s } = this.state

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={12}>
                        <h2>Belvo</h2>
                    </Col>
                    <Col xs={12} className="center">
                        <Link to={'/admin/belvo/bitacora'}><h2>Bitacora</h2></Link>
                    </Col>
                </Row>

                <section className="p-1">

                    <Spin spinning={cuentas_s || transacciones_s || facturas_s}>
                        <Row gutter={[16, 24]} className="card-container">
                            <Col xs={24} className="gutter-row">
                                <Carousel
                                    responsive={responsive}
                                    centerMode={true}
                                    className="sec-carousel"
                                    style={{ height: "40vh", float: 'left' }}>
                                    {this.state.cuentas.map((item, index) => {
                                        return (
                                            <Col xs={24}>
                                                <Card
                                                    title=""
                                                    loading={this.state.loading}
                                                    bordered
                                                    className="card-cuenta"
                                                >
                                                    <Row className="" style={{ height: '70px' }}>
                                                        <Col xs={12} lg={16} xl={18} className="flex-left">
                                                            <Text className="text-banco">{item.banco}</Text>
                                                            {(
                                                                (item.belvo_link || item.belvo_transacciones) && 
                                                                !item.belvo_facturas &&
                                                                (item.belvo_fecha_actualizacion_saldo && item.belvo_fecha_actualizacion_saldo !== null)
                                                            ) ?
                                                                <span>
                                                                    <IconCheckMedal />
                                                                    <small style={{ position: 'relative', bottom: 10, left: 5 }}>
                                                                        <strong>Actualizado: </strong>{moment(item.belvo_fecha_actualizacion_saldo).format('lll')}
                                                                    </small>
                                                                </span>
                                                                : null}

                                                        </Col>
                                                        <Col xs={12} lg={8} xl={6} className="center">
                                                            <CustomAvatar
                                                                image={item.logo}
                                                                color={item.color}
                                                                name={item.nombre}
                                                                size="large"
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row className="">
                                                        <Col span={10}>
                                                            <Text className="text-gray">{item.cuenta}</Text>
                                                        </Col>
                                                        <Col span={14} className="flex-left-column  p-0">

                                                            {(item.belvo_link || item.belvo_transacciones) ?
                                                                [

                                                                    <Link className="btn-container" to={`/admin/cuentas/belvo/transacciones/guardadas/${(item.belvo_link) ? item.belvo_link : item.belvo_transacciones}/${item.belvo_cuenta_id}`}>
                                                                        <Button
                                                                            type="primary"
                                                                            className="btn-belvo-link btn-transacciones clasificar"
                                                                        >
                                                                            <small style={{ position: 'relative', top: -4 }}> <IconFinanzas style={{ width: 15, position: "relative", top: 5 }} /> Transacciones Guardadas</small>
                                                                        </Button>
                                                                    </Link>,
                                                                    <Link className="btn-container" to={`/admin/cuentas/belvo/transacciones/sin-clasificar/${(item.belvo_link) ? item.belvo_link : item.belvo_transacciones}/${item.belvo_cuenta_id}`}>
                                                                        <Button
                                                                            type="primary"
                                                                            className="btn-belvo-link btn-transacciones sin-clasificar"
                                                                        >
                                                                            <small style={{ position: 'relative', top: -4 }}> <IconFinanzas style={{ width: 15, position: "relative", top: 5 }} /> Transacciones Sin Clasificar</small>
                                                                        </Button>
                                                                    </Link>
                                                                ]
                                                                : null}

                                                            {(item.belvo_facturas) ?
                                                                [

                                                                    <Link className="btn-container" to={`/admin/cuentas/belvo/facturas/guardadas/${item.belvo_facturas}/${item.belvo_cuenta_id}`}>
                                                                        <Button
                                                                            type="primary"
                                                                            className="btn-belvo-link btn-facturas clasificar"
                                                                        >
                                                                            <small style={{ position: 'relative', top: -1 }}> <FaShoppingCart style={{ width: 15, position: "relative", top: 0 }} /> Facturas Guardadas</small>
                                                                        </Button>
                                                                    </Link>,
                                                                    <Link className="btn-container" to={`/admin/cuentas/belvo/facturas/sin-clasificar/${item.belvo_facturas}/${item.belvo_cuenta_id}`}>
                                                                        <Button
                                                                            type="primary"
                                                                            className="btn-belvo-link btn-facturas sin-clasificar"
                                                                        >
                                                                            <small style={{ position: 'relative', top: -1 }}> <FaShoppingCart style={{ width: 15, position: "relative", top: 0 }} /> Facturas Sin Clasificar</small>
                                                                        </Button>
                                                                    </Link>
                                                                ]
                                                                : null}


                                                        </Col>
                                                    </Row>

                                                    {item.belvo_facturas ? null : <Row className="">
                                                        <Col span={24} className="flex-left-column">
                                                            <Text className="text-grey-sm">Saldo en Cuenta {item.banco}</Text>
                                                            <Text className="text-money">$ {(item.belvo_saldo) ? item.belvo_saldo.toMoney() : '0.0'.toMoney()} <small>MXN</small></Text>
                                                            <Text className="text-grey-sm">Saldo en ERP Grupo Manantial</Text>
                                                            <Text className="text-money">$ {item.saldo?.toMoney()} <small>MXN</small></Text>
                                                        </Col>
                                                    </Row>}

                                                </Card>
                                            </Col>
                                        )
                                    })
                                    }
                                </Carousel>
                            </Col>
                        </Row>

                        <Row gutter={[16, 24]}>
                            <Col xs={24} md={15} className="gutter-row">
                                <div className="card" style={{ minHeight: '60vh' }}>
                                    <Row>
                                        <Col span={24} className="flex-between">
                                            <Title className="title-card" level={3}>Últimas Transacciones</Title>
                                            {/* <Link className="button-link-to mr-1" type="link" ><IconGoToLink /></Link> */}
                                        </Col>
                                        <Col span={24}>
                                            <List
                                                className="component-list"
                                                itemLayout="horizontal"
                                                loading={this.state.loading}
                                                dataSource={this.state.transacciones}
                                                locale={{ emptyText: 'Sin Facturas' }}
                                                renderItem={item => (
                                                    <List.Item className="component-list-item-small">
                                                        <Card className="card-list-small">
                                                            <Row style={{ width: '100%' }}>
                                                                <Col span={3} className="center">
                                                                    <Text strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                                                </Col>
                                                                <Col span={4} className="center">
                                                                    <Text>{item.concepto}</Text>
                                                                </Col>
                                                                <Col span={4} className="center">
                                                                    <Text>{item?.cuenta_id?.nombre}</Text>
                                                                </Col>
                                                                <Col span={4} className="center">
                                                                    <Text>{item?.rubro_id?.nombre}</Text>
                                                                </Col>
                                                                <Col span={4} className="center">
                                                                    <Tag className="tag-proyecto" color={item.proyecto_id.color}>{item?.proyecto_id?.nombre}</Tag>
                                                                </Col>
                                                                <Col span={3} className="center">
                                                                    <Text strong>
                                                                        <Statistic value={item?.monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: (item?.tipo === 1) ? '#00FF19' : '#FF0000' }} prefix={'$'} />
                                                                    </Text>
                                                                </Col>
                                                                <Col span={2} className="center">

                                                                    <Button type="secondary" className="detTable-icon"
                                                                        onClick={(e) => { this.setState({ factura: item, modalDetalleVisible: true }) }}
                                                                        title="Ver detalle">
                                                                        <IconDetails />
                                                                    </Button>

                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </List.Item>
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={24} md={9} className="gutter-row">
                                <div className="card" style={{ minHeight: '60vh' }}>
                                    <Row>
                                        <Col span={24} className="flex-between">
                                            <Title className="title-card" level={3}>Ultimas Facturas Clasificadas</Title>
                                            {/*<Link to={'/admin/belvo/transacciones'} className="button-link-to mr-1" type="link" ><IconGoToLink /></Link>*/}
                                        </Col>
                                    </Row>
                                    <Row className="scroll-proveedores" style={{ paddingTop: '8px' }}>
                                        {
                                            this.state.facturasC.map(fact => {
                                                return <Col span={24} className="mb-1">
                                                    <CardTransaccionesSC {...fact} onClick={() => {
                                                        this.setState({ modalDetalleVisible: true, factura: fact })
                                                    }} />
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Spin>

                </section>





                {/* <Popover title="Nuevo registro" >
                    <Button className="btnAddPlus"  >
                        <IconPlus />
                    </Button>
                </Popover> */}
            </div>
        )
    }
}