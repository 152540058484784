import React, { Component } from 'react';
import { Button, Col, message, Modal, Row, Spin, Table, Typography } from 'antd'
import { BrowserRouter, Redirect, Route } from 'react-router-dom';

import { useEffect } from "react";
import { render } from '@testing-library/react';



const axios = require('axios').default;






export default class BelvoWidget extends Component {


  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    }
  }

  /**
   * 
   * @memberof BelvoWidget
   */
  componentDidMount() {

    this.useScript('https://cdn.belvo.io/belvo-widget-1-stable.js');

  }



  renderRedirect = () => {
    if (this.state.redirect) {
      window.location.replace(this.props.redirect)
      // console.log('this.props.redirect', );
      // return <Redirect to="/admin/cuentas" />;
    }
  };



  /**
   *
   * @memberof BelvoWidget
   */
  useScript = async (src) => {
    console.log('Props', this.props)
    console.log('Function use script');
    // Create script
    const node = document.createElement('script');
    node.src = src;
    node.type = 'text/javascript';
    node.async = true;
    node.onload = this.createWidget
    // Add script to document body
    document.body.appendChild(node);
  }

  // Function to call your server-side to generate the access_token and retrieve the your access token
  getAccessToken = async () => {
    //console.log('Props',this.props.match.params)
    // Make sure to change /get-access-token to point to your server-side.
    //Validamos que exista el parametro, para hacer update o creacion
    let params = (this.props.match.params.token) ? {
      token_id: this.props.match.params.token
    } : {}

    // console.log('params, params, params');
    return axios.get('/belvo/request', {
      params
    })
      .then(response => {
        console.log('response, OK', response)
        return response.data
      })
      .catch(error => console.error('Error:', error))
  }

  // Insert the following code after useScript() function from Step 1.
  createWidget = async () => {


    const callback = () => { }

    //Callback de exito
    const successCallbackFunction = (link, institution, m, n) => {


      console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - ');
      console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - ');
      console.log('- - - - - - - - - - - - BANCO - - - - - - - - - - - - - ');
      console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - ');
      console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - ');

      console.log({ link, institution, m, n })
      console.log('Result', link, institution)

      console.log('Props', this.props.match.params)

      axios.post('/belvo/cuenta/save', {
        id: this.props.match.params.id,
        belvo_link: link,
        institucion: institution
      })
        .then(response => {
          console.log('Todo Correcto')
          //recibimos los datos de manera correcta
          message.success(response.data.message)
          this.setState({ redirect: true })

        })
        .catch(error => {
          //Ocurrio un error
          message.error('Ocurrio un error')
        })



    }

    //Callback al cerrar el widget
    const onExitCallbackFunction = (data) => {
      // Do something with the exit data.
      console.log('Exit call', data)
    }


    //Callback
    const onEventCallbackFunction = (data) => {
      // Do something with the exit data.
      console.log('An event occured', data)
    }
    const config = {
      // Add your startup configuration here.
      callback: (link, institution) => successCallbackFunction(link, institution),
      onExit: (data) => onExitCallbackFunction(data),
      onEvent: (data) => onEventCallbackFunction(data),
      show_intro: true,
      country_codes: ['MX'],
    }

    // console.log('access', access)
    try{

      const {
        access
      } = await this.getAccessToken()
      console.log('access', access)
      
      window.belvoSDK.createWidget(access, config).build()
    }
    catch(error){
      this.setState({
        redirect:true
      })
      message.error('Ocurrio un error al procesar la solicitud. Contacte a soporte técnico.')
    }
  }



  render() {
    return (

      <div id="belvo">
        {this.renderRedirect()}
      </div>
    )
  }


}



