import React, { Component } from 'react';
import { Pagination, Button, Col, Row, Spin, Empty, Typography, Breadcrumb, Progress, List, Badge, Card } from 'antd'
import { FaArrowRight, FaThermometerThreeQuarters } from "react-icons/fa";
import { Link } from 'react-router-dom';

//componentes
import { CardInfoDashboard, CardProgress, CardActivity, CardInfo, CardProgressBar } from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import { IconEye, IconArrowBack } from '../../Widgets/Iconos';
//css
import '../../../Styles/projectManager.css'


const axios = require('axios').default;
const { Text } = Typography;


export default class PMmateriales extends Component {

    constructor(props) {
        super(props)
        this.state = {

            manager_proyecto_id: "",
            proyecto_id: "",

            proyecto_nombre: "[Proyecto]",
            dataEmpleados: [],
            materialesPresupuestados: 0,
            materialesEntregados: 0,
            progresoMateriales: 0,
            loadingPage: true,

              /* Paginado */
              itemCount: 0,
              perPage: 10,
              pageCount: 1,
              currentPage: 1,


            empleados: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                search: null
            }

        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        var id = this.props.match.params.id;
        this.setState({ manager_proyecto_id: id });
        //         console.log(id);

        setTimeout(() => {
            this.getProjectInfo();
            this.getInfoEmpleados();
        }, 0.5);
    }



    /**
    * @memberof PMmateriales
    *
    * @method   getProjectInfo
    * @description Se hace la consulta de la informacion del proyecto
    *
    **/
    getProjectInfo = async () => {
        var id = this.state.manager_proyecto_id;

        axios.get('/project-manager/proyecto', {
            params: {
                manager_proyecto_id: id
            }
        })
            .then(({ data }) => {
                //   console.log('traje manager proyecto', data);
                var pro = data.data.proyecto;
                this.setState({
                    proyecto_id: pro.proyecto_id._id,
                    proyecto_nombre: pro.proyecto_id.nombre,
                    materialesEntregados: data.data.totalMaterialesEntregados,
                    materialesPresupuestados: data.data.totalMaterialesRequeridos,
                    progresoMateriales: data.data.totalProcesoEntrega
                })
            })
            .catch(error => {
                console.log('no traje nara', error);
            })
    }



    /**
    * @memberof PMmateriales
    *
    * @method   getInfoEmpleados
    * @description Se hace la consulta de la informacion de los empleados asignados a alguna actividad del proyecto
    *
    **/

    // empleados: {
    //     data: [],
    //     page: 1,
    //     limit: 10,

    //     search: null
    // }

    getInfoEmpleados = async (
        page = this.state.empleados.page,
        limit = this.state.empleados.limit,
        total = this.state.empleados.total,
        search = this.state.empleados.search
    ) => {
        var id = this.state.manager_proyecto_id;

        axios.get('/manager/proyecto/materiales', {
            params: {
                manager_proyecto_id: id
            }
        })
            .then(({ data }) => {
                    console.log('traje empleados proyecto', data);
                let empleados = {
                    data: data.data.itemsList,
                    page,
                    limit,
                    search: null
                }
                //    console.log('empleados',empleados)
                this.setState({
                    empleados,
                    loadingPage: false,
                    
                    currentPage: data.data.currentPage,
                    itemCount: data.data.itemCount,
                    perPage: data.data.perPage,
                    pageCount: data.data.pageCount,


                })


            })
            .catch(error => {
                console.log('no traje nara', error);
            })
    }



    render() {
        return (
            <div>
                <Spin spinning={this.state.loadingPage}>
                    <Row className="row-encabezado">
                        <Col xs={24} >
                            <Breadcrumb className="breadcrumb" separator=" " style={{ fontSize: "1.5em", marginTop: "10px" }}>
                                <Breadcrumb.Item >
                                    <Link to={`/admin/project-manager/proyecto/${this.state.manager_proyecto_id}`}
                                        style={{ color: "white" }}>
                                        <Button className="IconArrowBack" onClick={this.volver}>
                                            <IconArrowBack />
                                        </Button>
                                    </Link>
                                </Breadcrumb.Item>

                                <Breadcrumb.Item className="breadcrumb-actual">
                                    Materiales de {this.state.proyecto_nombre}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>


                    <section className="p-1">

                        <Row gutter={[16, 24]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Materiales Presupuestados"
                                    infoLeft={((this.state.materialesPresupuestados).toMoney()) + " tons"}
                                    titleRight="Materiales Entregados"
                                    infoRight={((this.state.materialesEntregados).toMoney()) + " tons"}
                                />
                            </Col>


                            <Col xs={24} lg={8} className="gutter-row">
                                <CardProgressBar title="Progreso Materiales"
                                    percent={parseFloat(this.state.progresoMateriales.toFixed(2))}
                                />
                            </Col>

                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo titleLeft="Bitacora" infoLeft="Ver Bitacora" />
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            {this.state.empleados.data.map((data) => <Col xs={24} lg={8} className="gutter-row">
                                <Link
                                    to={`/admin/project-manager/material-empleado/${this.state.manager_proyecto_id + "_" + data._id}`}
                                >
                                    <CardProgress hover={true}
                                        title='MATERIALES ENTREGADOS'
                                        name={data.responsable.nombre + " " + data.responsable.apellido}
                                        titleBar='Materiales Presupuestados'
                                        percent={data.porcentaje}
                                    />
                                </Link>
                            </Col>)}
                        </Row>

                        <div className="scroll">
                            <Row gutter={[16, 24]}>
                                <Col xs={24} lg={8} className="gutter-row">
                                    <Pagination
                                        defaultCurrent={1}
                                        showSizeChanger={true}

                                        
                                        total={this.state.itemCount}
                                        pageSize={this.state.perPage}
                                        onChange={(page) => {
                                            this.getInfoEmpleados(page)
                                        }}

                                    // onChange={(page,limit) => this.getInmueble(page,limit)}
                                    // total={this.state.actividades.total}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </section>
                </Spin>
            </div>
        )
    }
}
