import React, { Component } from 'react';
import { Button, Col, Modal, Radio, Row, Typography, message, Spin, Input, Form, Space, Select, InputNumber, Empty } from 'antd'
import MinusCircleOutlined from "@ant-design/icons/lib/icons/MinusCircleOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

//componentes
import { IconArrowSelect, IconCloseModal, IconArrowBack } from "../../Widgets/Iconos";

//css
import "../../../Styles/Modal/ModalNuevoGrupo.css";

const axios = require("axios").default;
const moment = require('moment');

const { Title, Paragraph } = Typography;
const { Option } = Select;


/**
 * @class ModalAnexarTajo
 * @extends Component
 *
 *
 * */
export default class ModalAnexarTajo extends Component {

    carousel = React.createRef();


    constructor(props) {
        super(props);
        this.state = {
            actividadId: null,
            cargando: false,
            tipo: '',

            tajos: {
                data: [],
                page: 1,
                limit: 8,
                search: null
            },

            update: false,

            groupId: null,
            usuarios: {
                data: [],
                page: 1,
                limit: 8,
                search: null
            },
        };
    }



    /**
     *
     * @methodOf ModalAnexarTajo
     *
     * @function componentDidMount
     *
     * @description Obtenemos los repsonsbales para ponerlos en el objeto.
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    componentDidUpdate() {

        if (this.props.visible && !this.state.update) {
            this.state.update = true;
            this.getTajos()
        }


        if (!this.props.visible && this.state.update) {
            this.state.update = false;
        }
    }


    /**
    * @function getAsesores
    * @description Obtenemos los usuarios y se desplegan como opcion de asesor
    * */
    getTajos = async (
        page = this.state.usuarios.page,
        limit = this.state.usuarios.limit,
        search = this.state.usuarios.search
    ) => {

        this.state.cargando = true;
        axios.get('/tags', {
            params: {
                page: 1,
                limit: 5000,
                type: "inmueble_id",
                search: search,
                actividad_id: this.props.id,
            }
        })
            .then(res => {

                //this.setState({ dataObras: res.data.data.itemsList })
                this.setState(state => {
                    state["tajos"].data = res.data.data.itemsList;
                    state["tajos"].page = page;
                    state["tajos"].limit = limit;
                    this.state.cargando = false;


                    return state;
                })
            })
            .catch(e =>
                console.log('error tajos get', e)
            )

    }


    /**
     *
     * @memberof ModalPregunta
     *
     *
     * @function accept
     * @description Valida si ya esta selccionado el tipo de modal. Activamos el slider en caso de que sea de texto.
     *
     * @param values
     * @description valores del formulario de
     * */
    accept = async (values) => {
        this.state.cargando = true;
        axios.post('/actividades/add/tajos', {
            tajo_id: values.tajo_id,
            actividad_id: this.props.id,
        })
            .then(({ data }) => {
                if (data.success) {
                    message.success('¡Tajo anexado correctamente');
                }
                else {
                    message.error('¡Error!');
                }
                setTimeout(() => {
                    this.props.hideModal()
                    this.props.accept();
                    this.state.cargando = false;
                }, 100);

            })
            .catch((error) => {

            })
    };


    /**
     * @param formModalTajo
     * @description Referencia de formulario
     * */
    formModalTajo = React.createRef();


    render() {

        const { visible, hideModal, id, tipo } = this.props;
        const { tajos, usuarios } = this.state;
        const { getTajos, accept } = this;

        return (
            <Modal
                visible={visible}
                title={null}
                footer={null}
                className={"modal-form"}
                destroyOnClose={true}
                zIndex={1000}
                closable={false}
                onCancel={hideModal}
                afterClose={hideModal}
            >

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Anexar Tajo</Title>
                </div>

                <Spin spinning={this.state.cargando}>
                <Form
                    onFinish={accept}
                    layout="vertical"
                >
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Tajo"
                                    name="tajo_id"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el tajo."
                                    }]}
                                    
                                >
                                    <Select
                                        clearIcon={<IconArrowSelect />}
                                        showSearch
                                        placeholder={"Tajo"}
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={search => getTajos(tajos.page, tajos.limit, search)}
                                        notFoundContent={"No se encontraron tajos disponibles"}
                                        
                                    >
                                        
                                        
                                        {tajos.data.map(function ({ nombre, descripcion, _id }, index) {
                                            return <Option value={_id}> {nombre + ' - ' + descripcion} </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={24} lg={24}>
                                <Button htmlType="submit" type="primary" className="continue" disabled={(tajos.length === 0)} >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </Form>
                </Spin>

            </Modal>
        )
    }
}
