const Frecuencias = [
    {
        value: 1,
        text: "Semanal"
    },
    {
        value: 2,
        text: "Quincenal"
    }
]





export {

    Frecuencias,
    

}