import React, { Component } from 'react';
import { Button, Col, Modal, Row, Spin, Table,Typography,Popover, message } from 'antd'

//componentes
import { IconDelete, IconEdit, IconPlus,IconEmailSuccess, IconEmailWaitingForSend,IconCross,IconCuentas,IconEye } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import Logged from "../../../Hooks/Logged";
import { Redirect,Link } from 'react-router-dom'
//modales
import ModalCuenta from '../Modales/Cuentas/ModalCuentas';
import Search from 'antd/lib/input/Search';



const { Title } = Typography;
const axios = require('axios').default;

export default class FacturasBelvo extends Component {

    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            dataFacturas: [],
            loading: true,

            accionModal: 'crear',
            registroId: '',
            visible: false,
            tipo_user: 0,
            redirect: false,
            id_cuenta:'',
            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,

            filtroSearch:"",
            searching:true
           
        }
    }

    //Referencia
    modalCuenta = React.createRef();


    /**
     *
     *
     * @memberof Cuentas
     * 
     * @method componentDidMount
     * @description Manda a llamar al metodo receiveData para cargar los datos a la vista
     */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        //this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.receivedData();
    }


    /**
     *
     * @memberof Cuentas
     * 
     * @method receivedData
     * @description Obtiene la informacion y actualiza los valores locales de la lista.
     */
    receivedData = async (page, size) => {
        this.setState({ loading: true });
        var link = this.props.match.params.link
        axios.post('/belvo/cuenta/facturas',{
            belvo_link:link
        })
        .then(res => {
                const data = res.data;
                console.log('DATA RECIBIDA',data)
                this.state.searching = true;
               this.setState({
                    dataFacturas: data,
                    searching:false
                })               
            })
            .catch(res => {
                console.log("error", res);
                message.error('Ocurrio un error')
            })
            .finally(f=>{
                this.setState({loading:false})
            });
    }


    /**
     * @memberof Cuentas
     * 
     * @method modalCrear
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }

    /**
     * @methodOf  Cuentas
     * @method showModal
     *
     * @description Muestra el modal del cuenta
     *
     * */
    showModal = (id = undefined) => this.setState({
        visible: true,
        id: id
    });

    /**
     * @methodOf  Cuentas
     * @function hideModal
     *
     * @description Oculta el modal del cuenta
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };


    /**
     * @methodOf  Cuentas
     * @function hideModal
     *
     * @description Oculta el modal del cuenta
     *
     * */
    deleteModal = (id) => {
        let comp = this;
        Modal.confirm({
            title: '¿Estas seguro de que deseaas eliminar esta cuenta?',
            icon: <ExclamationCircleOutlined />,
            content: 'No será posible recuperar al información al respecto de esta cuenta.',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                axios.delete('/cuentas/delete', { params: { id } })
                    .then(() => {
                        comp.receivedData()
                    })
                    .catch(({ response }) => {

                        Modal.error({
                            title: "No es posible eliminar.",
                            content: response.data.message
                        })

                    })

            },
        });
    }

    /**
     * @memberof Cuentas
     * @function renderRedirect
     * @description se encarga de enviar a la vista de enlace de cuenta con Belvo
     */
    renderRedirect = () => {
        if (this.state.redirect) {

          return <Redirect to={'/admin/cuentas/belvo/' + this.state.id_cuenta} />
        }
      }


      onCuentaSelect =(id_cuenta)=>{
        console.log('Id de la cuenta',id_cuenta)
        
        this.setState (state => {
            state.id_cienta = id_cuenta
            state.redirect = true;
            return state;
        })

      }

    render() {
        const columns = [
            {
                title: 'Sub total',
                key: 'subtotal',
                dataIndex: 'subtotal_amount',
                render: text => '$ ' + new Intl.NumberFormat().format(text)
            },
            {
                title: 'IVA',
                key: 'iva',
                dataIndex: 'tax_amount',
                render: text => '$ ' + new Intl.NumberFormat().format(text)
            },
            {
                title: 'Total',
                key: 'total',
                dataIndex: 'total_amount',
                render: text => '$ ' + new Intl.NumberFormat().format(text)
            },
            {
                title: 'Concepto',
                key: 'description',
                dataIndex: 'invoice_type',
            },
            {
                title: 'Emisor',
                key: 'emisor',
                dataIndex: 'sender_name',
            },
            {
                title: 'Receptor',
                dataIndex: 'receiver_name',
                key: 'receiver_name',
                
            },
            {
                title: 'Detalles',
                dataIndex: 'invoice_details',
                render: element =>  element.length + ' detalles'
            },
            {
                title: 'Pagos',
                dataIndex: 'payments',
                render: element =>  element.length + ' pagos'
            },
            {
                title: 'Estatus',
                dataIndex: 'status',
                key: 'status',

            },
            {
                title: 'Fecha',
                dataIndex: 'collected_at',
                key: 'collected_at',
               // render: text => '$ ' + new Intl.NumberFormat().format(text)
            },
        ];


        const { visible, loading } = this.state;
        const user = this.context;
        return (
            <div>
                <Logged.Consumer>
                    {value => {
                        this.context = value
                    }}
                </Logged.Consumer>
                <Spin spinning={loading}>
                    <Row className="row-encabezado">
                       <Col xs={12} xl={20} xxl={20} className="flex-left">
                            <Title level={4}className="view-title">Facturas</Title>
                        </Col>
                        <Col xs={12} xl={4} xxl={4}  className="flex-right">
                            <Row align="right" style={{ textAlign: "right", display: "block" }}>
                                <Title level={5}  style={{
                                    textAlign: "right",
                                    color: "#FFF",
                                    marginRight: '15px',
                                    marginTop: '7px',
                                    fontSize: '11pt'
                                }}> { this.state.itemCount} Facturas de cuentas</Title>
                            </Row>
                        </Col>
                    </Row>


                    <Row className="p-1">
                        <div className="div_contenido">
                            <Table
                                loading={this.state.loading}
                                pagination={{
                                    current: this.state.currentPage,
                                    pageSize: this.state.perPage,
                                    total: this.state.itemCount,
                                    showSizeChanger: true,
                                    position: ['bottomLeft'],
                                }}
                                onChange={({ current, pageSize, total }, b, sort) => {
                                    this.receivedData(current, pageSize)
                                }}
                                columns={columns}
                                dataSource={this.state.dataFacturas}
                                scroll={{ x: 1000, y: 1000 }}
                                locale={{emptyText:'Sin Cuentas Disponibles'}} />
                        </div>
                    </Row>

                </Spin>                
            </div>
        )
    }
}
