import React, { Component } from 'react';
import { Button, Col, Row, Select, Typography, List, Card, Collapse, Form, Input, Spin, InputNumber, Upload, Switch, message, Pagination, Popover, Space, Tooltip, Modal, Tag, Empty } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { PlusOutlined, UploadOutlined, CheckOutlined, RedoOutlined } from '@ant-design/icons';
//componentes
import { IconPlus, IconFlag, IconPaper, IconChecked, IconEye, IconTruck, IconPDF } from '../../../Widgets/Iconos';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
//modal
import ModalRequisicionDetalle from '../Modales/ModalRequisicionDetalle'
import ModalEntrega from '../Modales/ModalEntrega'

//css
import './../../../../Styles/dashboard.css'
import './../../../../Styles/compras.css'

const { Title,Text } = Typography;
const { Panel } = Collapse;
const moment = require('moment')

let colors = {
    0: '#B706F5', //Requerido
    1: '#EDF10A', //Cotizado
    2: '#F57906', //Pausado
    3: '#F50606', //Cancelado
    4: '#6106F5', //Entrega
    5: '#F506B2', //Recoleccion
    6: '#08E453', //Entregado
}

let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

/**
 * @class ListasEntregasRecoleccion
 * @description Retorna una lista de las requisiciones, con el estatus Entega y Recoleccion, para el usuario comprador realice la cotizacion 
 */
export default class ListasEntregasRecoleccion extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            data: [],

            page: 1,
            limit: 20,
            total: 0
        }
    }

    /***
     *
     * @methodOf ListasEntregasRecoleccion
     * @function componentDidMount
     * @description
     */
    componentDidMount = () => {
        this.getRequisiciones()
    }

    componentDidUpdate(prevProps){
        if(prevProps.search != this.props.search){
            this.getRequisiciones()
        }
    }


    /***
     *
     * @methodOf ListasEntregasRecoleccion
     * @function getRequisiciones
     * @description
     */
    getRequisiciones = () => {
        this.setState({loading: true})
        axios.get('/requisiciones',{
            params:{
                page: 1,
                limit: this.state.limit,
                estatus: this.props.estatus,
                entrega: true,
                search: this.props.search,
            }
        }).then(response => {
            this.setState({
                data: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,
                hasMore: response.data.data.itemsList.length < response.data.data.itemCount
            })

        }).catch(error => {
            console.log(error)
            message.success('Error al obtener la información')
        }).finally(()=>{
            this.setState({loading: false})
        })
    }

    /***
     *
     * @methodOf ListasRequisiciones
     * @function getMore
     * @description Obtiene mas requisisciones al hacer scroll sobre los estatus
     */
    getMore = () => {

        if(this.state.total > this.state.data.length){
            this.setState({ loading: true, })

            axios.get('/requisiciones', {
                params: {
                    page: this.state.page + 1,
                    limit: this.state.limit,
                    estatus: this.props.estatus,
                    entrega: true,
                    search: this.props.search
                }
            }).then(({ data }) => {

                let arr = [...this.state.data, ...data.data.itemsList]

                this.setState({
                    data: arr,
                    total: data.data.itemCount,
                    page: data.data.currentPage,
                    hasMore: arr.length < data.data.itemCount,
                })
                
            })
                .catch(error => {
                    console.log("error", error);
                    message.error('Error al traer la información')
                }).finally(() => this.setState({ loading: false }))
            }
    }

    /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method getPDF
     * @description Generamos el PDF de la contizacion
     */
    getPDF = (requisicion_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/requisiciones/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('requisicion_id', requisicion_id)
        urlPDF.searchParams.append('por_proveedor', true)
        return urlPDF.href
    }

    render() {

    	const { title, estatus } = this.props

        return (
            <Spin spinning={this.state.loading}>
                <div>
                    <Text className={`list-header list-header-estatus-${estatus}`}>{title}</Text>
                </div>
                {this.state.data.length > 0 ? <InfiniteScroll
                        dataLength={this.state.data.length}
                        next={this.getMore}
                        hasMore={this.state.hasMore}
                        loader={<Spin tip="Cargando..."><h4 style={{height: '100px'}}></h4></Spin>}
                        height={550}
                        className="mb-2"
                    >
                        <Collapse accordion className={`cotizacion-collapse  collapse-estatus-${estatus} mb-1`}>
                            {this.state.data.map((item,index) => <Panel 

                                className='panel'
                                key={index}
                                extra={<Row className="w100">
                                    <Col span={4} className="center">
                                        <Tooltip placement="topLeft" title={item.producto_id?.nombre}>
                                            <Text ellipsis>{item.producto_id?.nombre}</Text>
                                        </Tooltip>
                                    </Col>
                                    
                                    <Col span={4} className="center">
                                        <Text ellipsis>{item.requisiciones_opciones[0]?.proveedor_id?.alias}</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text ellipsis>{item.volumen} {item.producto_id?.unidad ? item.producto_id?.unidad.toUnidad() : 'unidades'}</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text ellipsis>{item.estatus === 4 ? item.volumen_entregado + ' Entrgeado' : item.volumen_recolectado + ' Recolectado'}</Text>
                                    </Col>
                                    <Col span={1} className="center">
                                        <CustomAvatar 
                                            size="large" 
                                            image={item.proyecto_id?.logo}
                                            name={item.proyecto_id?.nombre}
                                            color={item.proyecto_id?.color}
                                        />
                                    </Col>
                                    <Col span={1} className="center">
                                        <IconFlag fill={colors_flag[item.prioridad] ? colors_flag[item.prioridad] : '#A4A4A4'}/>
                                    </Col>
                                    <Col span={3} className="center">
                                        {item.propiedades.length > 0 ? <Tag color="green" style={{marginRight: '4px', marginLeft: '0px'}}>{item.propiedades[0].nombre}</Tag> : ''}
                                        {item.propiedades.length > 1 ? <Tooltip placement="topLeft" title={item.propiedades.map((elm, index)=> index > 0 ? elm.nombre + ', ' : '')}>
                                            <Tag color="green" style={{marginRight: '4px', marginLeft: '0px'}}>...</Tag>
                                        </Tooltip> : ''}
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text ellipsis>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                    </Col>
                                    <Col span={2} className="center">
                                            <Button type="primary" className="button-survey btn-eye button-eye"
                                                onClick={(e) => { 
                                                    e.stopPropagation()
                                                    this.setState({ requisicion_id: item._id, modal_detalle: true }) 
                                                }}
                                                title="Ver detalle">
                                                <IconEye />
                                            </Button>
                                    </Col>
                                    
                                </Row>}
                            >   
                                {item.requisiciones_opciones?.map((requisicion_opcion, index_b)=>{

                                    return <Form
                                        className="form-collapse"
                                        ref={requisicion_opcion.ref}
                                        initialValues={{...requisicion_opcion}}
                                    >
                                        <Row>
                                            <Col span={10} className="center">
                                                <Text strong >Almacén: <span className="text-gray">{requisicion_opcion.almacen_id?.nombre}</span ></Text>
                                            </Col>
                                            <Col span={10} className="center">
                                                <Text strong>Fecha de Entrega: <span className="text-gray">{moment(item.fecha_entrega).format('DD-MM-YYYY')}</span></Text>
                                            </Col>
                                            <Col span={4}>
                                                <Space>
                                                    
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Form>
                                })}
                            </Panel>)}
                        </Collapse>
                    </InfiniteScroll> : <Empty description={"Sin Requisisciones"} className="mb-3 mt-1"/> }
                {/*<Pagination
                    current={this.state.page}
                    total={ this.state.total}
                    pageSize={this.state.limit}
                    className="mb-1"
                    onChange={this.getRequisiciones}
                    showSizeChanger={false}
                />*/}

                <ModalRequisicionDetalle
                    visible={this.state.modal_detalle}
                    onCancel={()=>this.setState({modal_detalle: false, requisicion_id: undefined})}
                    requisicion_id={this.state.requisicion_id}
                />

                <ModalEntrega
                    visible={this.state.modal_entrega}
                    onCancel={(flag)=>{
                        this.setState({modal_entrega: false, requisicion_id: undefined})
                        if(flag === true){
                            this.props.getRequisicionesEntregas()
                        }
                    }}
                    requisicion_id={this.state.requisicion_id}
                    estatus={estatus}
                />
            </Spin>
        )
    }
}
