import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, List, Typography, message, Spin, Select, InputNumber, DatePicker, Upload } from 'antd';
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import axios from 'axios'
import { UploadOutlined } from '@ant-design/icons';
//componentes
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import Logged from "../../../../Hooks/Logged";
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const { Title, } = Typography;
const { Option } = Select;
var contConsulta = 0;

export default class ModalAcreedor extends Component {

    static contextType = Logged;

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }

    };


    constructor(props) {
        super(props);
        this.state = {

            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false,

            area_id: '',

            projectsSelected: [],

            fileList: [],
            dataProyectos: [],
            dataSubrubros: [],
            dataAsesores: [],


            sel_proyecto_id: "",
            sel_contacto_id: "",
            sel_subrubro_id: "",
            edicion: false,

        }
    }


    //Referencia de form
    ModalAcreedorRef = React.createRef();

    componentDidMount() {
        contConsulta = 0;
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProjects();
        this.getAsesores();
        this.getSubrubros();
    }

    componentDidUpdate() {

    }

    //Función para limpiar campos
    limpiarCamposModal = () => {
        if (this.ModalAcreedorRef.current !==undefined) {

            this.ModalAcreedorRef.current.setFieldsValue({
                nombre: '',
            });

        }
    }

    //Función que busca la info del registro seleccionado
    modalEditar = () => {
        this.state.loading = true;

        try {
        setTimeout(() => {
            this.state.loading = false;
        }, 100);

        const { registroId } = this.props;

        if (this.ModalAcreedorRef !==undefined) {
            contConsulta++;
            this.state.spinning = true;
            axios.post('/acreedores/get', {
                id: registroId,
                proyectos: ''
            }).then((acreedor) => {


                let data = acreedor.data.data;
                var fecha = (data.fecha_prestamo !==undefined) ?  (data.fecha_prestamo).toString().substring(0,10) : new Date().toDateString();
                
                let archivos = []
                if(data.documentos && data.documentos.length > 0){
                    archivos = data.documentos.map(img=>{
                        return {uid: 1,
                        response: {
                            filename: img
                        },
                        name: img,
                        url: axios.defaults.baseURL + "/voucher/" + img,
                        status: 'done',
                    }})
                }
                        console.log("archivos", archivos);

                if (this.ModalAcreedorRef.current !==null) {

                    //Actualiza los valores del form dentro del modal.
                    this.ModalAcreedorRef.current.setFieldsValue({

                        acreedor: data.acreedor,
                        email: data.email,
                        telefono: data.telefono,

                        monto_prestamo: data.monto_prestamo,
                        monto_pendiente: data.monto_pendiente,
                        monto_retorno: data.monto_retorno,
                        proyecto: data.proyecto_id._id,
                        subrubro: data.subrubro_id._id,
                        contacto: data.contacto_id._id,

                        fecha_prestamo: moment(fecha),
                        documentos: archivos,

                    });

                }
                this.state.spinning = false;

                this.setState({
                    datosConsultados: true,
                    loading: false,
                    edicion: true,
                    spinning: false,
                    sel_proyecto_id: data.proyecto_id._id,
                    sel_contacto_id: data.contacto_id._id,
                    sel_subrubro_id: data.subrubro_id._id,
                });

                

            })

        }
        } catch (error) {
         console.log(error);
        }
        
        finally {
            this.state.spinning = false;
        }
    }




    //Función que reinicializa los states y llama al método de cierre en componente padre
    hideModal = () => {
        //Reiniciar valores al cerrar modal
        contConsulta = 0;
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
        })

        this.props.closeMethod();
    }

    //Funcion que redirecciona a vista principal
    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/admin/acreedores" />;
        }
    };


    /**
     * @function getProjects
     * @description Obtenemos los proyectos
     * */
    getProjects = async () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({ dataProyectos: proyectos.data.data })
        }).catch((error) => {
        })
    };

 
    /**
     * @function getSubrubros
     * @description Obtenemos los subrubros
     * */
    getSubrubros = async () => {
        await axios.get("/acreedores/subrubrosList", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                paginate: false
            },
        })
            .then(({ data }) => {
                console.log(data);

                this.setState({
                    dataSubrubros: data.data,
                })
            })
            .catch(e => console.log('er', e))
    }


    /**
     * @function getAsesores
     * @description Obtenemos los usuarios y se desplegan como opcion de asesor
     * */
    getAsesores = async () => {
        await axios.get("/usuarios", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                paginate: false
            },
        })
            .then(({ data }) => {
                this.setState({
                    dataAsesores: data.data,
                })
            })
            .catch(e => console.log('er', e))
    }




    //Función de guardado-edición dependiendo de la propiedad "accionModal"
    btnSaveClickModal = values => {
        const { accionModal } = this.props;
        console.log(values);
        let archivos = []
        if(values.documentos && values.documentos.length > 0){
            archivos = values.documentos.map(img=>img.name)
        }
            console.log("archivos", archivos);

        if (values.monto_retorno >= values.monto_prestamo) {

            if (accionModal === "crear") {
                axios.post('/acreedores/add', {
                    acreedor: values.acreedor,
                    email: values.email,
                    telefono: values.telefono,

                    monto_prestamo: values.monto_prestamo,
                    monto_pendiente: values.monto_pendiente,
                    monto_retorno: values.monto_retorno,
                    fecha_prestamo: values.fecha_prestamo,

                    proyecto_id: values.proyecto,
                    subrubro_id: values.subrubro,
                    contacto_id: values.contacto,
                    documentos: archivos,

                })
                    .then((res) => {
                        message.success("¡Acreedor Registrado!");
                        this.hideModal();
                        this.props.hideModal();

                    }).catch((error) => {
                        Modal.warning({
                            title: 'Error',
                            content:
                                <List
                                    size="small"
                                    bordered
                                    dataSource="No se ha podido registrar el acreedor"
                                />
                        });
                    });

            }

            else if (accionModal === "editar") {
                const { registroId } = this.props;

                axios.put('/acreedores/update', {
                    id: registroId,
                    acreedor: values.acreedor,
                    email: values.email,
                    telefono: values.telefono,

                    monto_prestamo: values.monto_prestamo,
                    monto_pendiente: values.monto_pendiente,
                    monto_retorno: values.monto_retorno,
                    fecha_prestamo: values.fecha_prestamo,

                    proyecto_id: values.proyecto,
                    subrubro_id: values.subrubro,
                    contacto_id: values.contacto,
                    documentos: archivos,

                })
                    .then((res) => {
                        message.success("¡Acreedor Actualizado!");


                        this.hideModal();
                        this.props.hideModal();
                        contConsulta = 0;
                        //window.location.reload();

                    }).catch((error) => {
                        Modal.warning({
                            title: 'Error',
                            content:
                                <List
                                    size="small"
                                    bordered
                                    dataSource="No se ha podido actualizar el acreedor"
                                />
                        });
                    });
            }
        }
        else {
            message.error("El monto a retornar debe ser mayor o igual al monto de prestamo");
        }
    }

     /**
     *
     * @memberof ModalProveedor
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
                fileList[x].url = `${axios.defaults.baseURL}/voucher/${fileList[x].response.filename}`
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };

    /**
     *
     * @memberof ModalProveedor
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        const { accionModal, area_id } = this.props;
        axios.post("/voucher/delete", {
            filename: image
        }).then(res => {
            console.log("imagen removida con éxito", res);
        }).catch(res => {
            console.log("imagen no se puedo remover", res);
        })
    };

    render() {

        const user = this.context;
        var { modalVisible } = this.props;
        var { accionModal } = this.props;
        var { registroId } = this.props;
        var titulo = '';


        if ((accionModal === "crear" || accionModal === "editar") && (!this.state.datosConsultados)) {

            this.setState({
                datosConsultados: true,
                loading: false
            })
        }
        // if (accionModal === "editar" && registroId !==undefined && registroId !=="" && (!this.state.datosConsultados)) {
        //     //this.modalEditar()
        // }

        if (accionModal === "crear") {
            titulo = "Nuevo Acreedor"
            this.state.spinning = false;

        } else if (accionModal === "editar") {
            titulo = "Editar Acreedor"
            this.state.spinning = true;
        }

        var contenido = "";

        if (accionModal === "crear" || accionModal === "editar") {
            contenido = <Form
                layout="vertical"
                ref={this.ModalAcreedorRef}
                className="frm-cliente"
                name="formulario-cliente"
                onFinish={this.btnSaveClickModal}
                style={{ padding: "0px 5px 0px 10px" }}
            >


                <Row>
                    <Col xs={24} lg={{ span: 11 }} xl={{ span: 7, push: 0 }}>
                        <Form.Item
                            label="Acreedor"
                            name="acreedor"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese el acreedor"
                            }]}
                        >
                            <Input placeholder="Acreedor"></Input>
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={{ span: 11, push: 1 }} xl={{ span: 7, push: 1 }}>
                        <Form.Item
                            label="Teléfono"
                            name="telefono"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese telefono"
                            }]}
                        >
                            <Input placeholder="Teléfono"></Input>
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={{ span: 11 }} xl={{ span: 7, push: 2 }}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese email"
                            }]}
                        >
                            <Input placeholder="Email"></Input>
                        </Form.Item>
                    </Col>


                    <Col xs={24} lg={{ span: 11, push: 1 }} xl={{ span: 7, push: 0 }}>
                        <Form.Item
                            label="Subrubro"
                            name="subrubro"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese subrubro"
                            }]}
                        >
                            <Select placeholder="Seleccione contacto">
                                {this.state.dataSubrubros.map(function ({ _id, nombre, }, index) {
                                    return <Option value={_id}> {nombre} </Option>
                                })
                                }
                            </Select>
                        </Form.Item>
                        <IconArrowSelect />

                    </Col>

                    <Col xs={24} lg={{ span: 11 }} xl={{ span: 7, push: 1 }}>
                        <Form.Item
                            label="Proyecto"
                            name="proyecto"
                            rules={[{
                                required: true,
                                message: "Por favor, seleccione proyecto"
                            }]}
                        >
                            <Select placeholder="Seleccione proyecto">
                                {this.state.dataProyectos.map(function ({ _id, nombre, logo, color }, index) {
                                    return <Option value={_id}>
                                        <CustomAvatar
                                            image={logo}
                                            name={nombre}
                                            color={color}
                                            size="small"
                                            style={{
                                                marginRight: '5px'
                                            }}
                                        />
                                        {nombre}
                                    </Option>
                                })}
                            </Select>
                        </Form.Item>
                        <IconArrowSelect />
                    </Col>

                    <Col xs={24} lg={{ span: 11, push: 1 }} xl={{ span: 7, push: 2 }}>
                        <Form.Item
                            label="Contacto"
                            name="contacto"
                            rules={[{
                                required: true,
                                message: "Por favor, seleccione contacto"
                            }]}
                        >
                            <Select placeholder="Seleccione contacto">
                                {this.state.dataAsesores.map(function ({ _id, nombre, apellido, activo, tipo }, index) {
                                    if (activo) {
                                        return <Option value={_id}> {nombre} {apellido}</Option>
                                    }
                                })
                                }
                            </Select>
                        </Form.Item>
                        <IconArrowSelect />
                    </Col>

                </Row>


                <Row>
                    <Col xs={24} style={{ width: '100%', margin: '45px 0px 10px 0px' }}>
                        <Title level={3} className="modal-title">Información del Prestamo</Title>
                    </Col>

                    <Col xs={24} lg={{ span: 11 }} xl={{ span: 7, push: 0 }}>
                        <Form.Item
                            label="Monto de Prestamo"
                            name="monto_prestamo"

                            rules={[{
                                required: true,
                                message: "Por favor, ingrese monto de prestamo"
                            }]}
                        >
                            <InputNumber min={0} defaultValue={0} />
                        </Form.Item>

                    </Col>

                    <Col xs={24} lg={{ span: 11, push: 1 }} xl={{ span: 7, push: 1 }}>
                        <Form.Item
                            label="Monto a Retornar"
                            name="monto_retorno"

                            rules={[{
                                required: true,
                                message: "Por favor, ingrese monto a retornar"
                            }]}

                        >
                            <InputNumber min={0} defaultValue={0} />

                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={{ span: 11 }} xl={{ span: 7, push: 2 }}>
                        <Form.Item
                            label="Fecha de Prestamo"
                            name="fecha_prestamo"

                            rules={[{
                                required: true,
                                message: "Por favor, ingrese la fecha"
                            }]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>

                </Row>

                <Row align="center" gutter={20}>
                    <Col span={8}>
                        <Form.Item
                            label="Documentos"
                            name="documentos"
                            getValueFromEvent={this.normFile}
                            valuePropName="fileList"
                            className="content-uploader"
                        >
                           <Upload
                                className="avatar-uploader"
                                action={axios.defaults.baseURL + "/voucher/add"}
                                multiple={false}
                            >
                                <Button>
                                    <UploadOutlined /> Upload
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>


                <Row style={{ textAlign: "center" }} className="pd-1">
                    <Col span={24}>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" className="btn-azul">
                                Guardar
                                </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        }

        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-cliente"
                destroyOnClose={true}
                zIndex={1000}
            >
                {this.renderRedirect()}
                <Spin spinning={this.state.loading}>
                    <div className="modal-header">
                        <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                            <IconCloseModal />
                        </Button>
                        <Title level={3} className="modal-title">{titulo}</Title>
                    </div>
                    {contenido}

                    {(accionModal === "editar" && registroId !==undefined && registroId !=="" && contConsulta < 2) ?
                            this.modalEditar()
                        : ''}

                </Spin>
            </Modal>
        )
    }
}
