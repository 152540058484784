import React, { Component } from 'react';
import { Row, Col, Typography, Table, Button,Popconfirm,message, Spin } from 'antd'

import { Link } from 'react-router-dom';

import { IconPlus, IconEdit, IconDelete, IconDetails } from '../../Widgets/Iconos';

import Logged from "../../../Hooks/Logged";
import ModalRubros from '../Modales/Rubros/ModalRubros';
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
const { Title, Paragraph, } = Typography;

export default class Rubros extends Component {
    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {
            dataRubros: [],
            loading: true,

            accionModal: 'crear',
            registroId: '',
            modalVisible: false,
            areaNombre: 'Area x',

            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,

            searching:true,
            filtroSearch:""



        }
    }

    ModalRubrosRef = React.createRef();

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Obtenemos el listado de Rubros
        this.getAreaInfo();
        this.receivedData();
    }



    
    /**
     *
     * @method componentDidUpdate
     * @description Acualiza la vista cuando se busca un elemento en la barra 
     */
    componentDidUpdate() {

        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;
      
        if (filtro !== propFiltro && this.state.searching === true) {
            this.receivedData();
            this.state.searching = false;
        }
        if (this.context !==0 && this.state.tipo_user === 0)
            this.setState({
                tipo_user: this.context.tipo
            })
    }




    /**
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }


    /**
   * @description Abrir modal tipo edición para registro seleccionado desde tabla.
   * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
   */
    modalEditar = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;

        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisible: true,
        })
    }


    /**
     * @description Cerrar modal y limpiar valor registroId
    */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false,
        })
    }

    /**
        *
        * @memberof Rubros
        * @method receivedData
        * @description Obtiene la informacion y actualiza los valores locales de la lista.
        */
    receivedData = async (page,size) => {
        this.setState({loading: true})
        var numPage = (page === undefined) ? 1 : page, numReg = 0, search = this.props.search;
        
        axios.get('/rubros/list', {
            params: {
                page: page,
                limit: size,
                area_id: this.props.match.params.id,
                search:search
            }
        })
            .then(res => {
                const data = res.data.data.itemsList

                var dataL = data, data_list = [];

                if (numPage === null || numPage === 1) { numReg = 0}
                else { numReg = ((numPage - 1) * 10) }

                for (let index = 0; index < dataL.length; index++) {
                    const element = dataL[index];
                    numReg = numReg + 1;
    
                    data_list.push({
                        _id: element._id,
                        nombre: element.nombre,
                        index: numReg
                    });
                }


                this.setState({
                    dataRubros: data_list,
                    loading: false,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: size,
                    pageCount: res.data.data.paginator.pageCount,
                    currentPage:page,
                    slNo: res.data.data.paginator.slNo,
                    hasPrevPage: res.data.data.paginator.hasNextPage,
                    hasNextPage: res.data.data.paginator.hasNextPage,
                    prev: res.data.data.paginator.prev,
                    next: res.data.data.paginator.next,
                    filtroSearch: search,
                    searching:true
                })
            })
            .catch(res => {
                console.log("error", res);
            });
    }
   /**
   *
   *
   * @memberof Rubros
   * @method eliminarRubro
   * @description Elimina el rubro validando que no tenga transacciones registradas.
   */
  eliminarRubro = e =>{
    axios.post('/rubros/delete',{
        id:e
    })
    .then((response)=>{
        message.success("Eliminado correctamente.");
        this.receivedData();
    })
    .catch((error)=>{
        message.error(error.response.data.message)

    })
}
  /**
     *
     *
     * @memberof Rubros
     * @method getAreaInfo
     * @description Obtiene la informacion del area con el ID del parametro de la URL
     */
    getAreaInfo = () => {

        axios.post('/areas/get', {
            id: this.props.match.params.id,
        }).then((success) => {

            this.setState({
                areaNombre: success.data.data.nombre,
            })
        })

    }


    render() {
        const user = this.context;
        const columns = [
            {
                title: 'Index',
                key: 'index',
                dataIndex: 'index',
                // render: (text, record) => (
                //     <Avatar/>
                // ),
            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                //key: 'nombre',
                render: (text, record) => (
                    <h3>{record.nombre}</h3>
                ),
            },
            {
                title: 'Acciones',
                key: 'actions',
                width: 180,
                render: (text, record) => (
                    <div className="tbl-opciones">

                        <Link title="Editar" to={`/admin/areas/rubros/subrubros/${record._id}`} >
                            <Button type="" className="detTable-icon" name={record.nombre} id={record._id} title="Ver subrubros" >
                                <IconDetails />
                            </Button>
                        </Link>
                    {(user.tipo !==3)?<div> <Button type="primary" className="button-survey btn-edit button-edit" name={record.nombre} id={record._id} onClick={this.modalEditar} title="Editar"  >
                            <IconEdit />
                        </Button>

                        <Popconfirm placement="topRight" title="Eliminar este rubro?" okText="Si, seguro" cancelText="No" name={record.nombre} id={record._id} onConfirm={()=>{ this.eliminarRubro(record._id)}} >
                            <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" name={record.nombre} title="Eliminar"  >
                                <IconDelete />
                            </Button>
                        </Popconfirm> </div>:null}

                    </div>
                ),
            },
        ];

        return (
            <div>
                <Spin spinning={this.state.loading}>

                <Row className="row-encabezado">
                     <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Title level={4}className="view-title">Rubros de {this.state.areaNombre}</Title>
                    </Col>

                    <Col xs={12} xl={4} xxl={4}  className="flex-right">
                        <Row align="right" style={{ textAlign: "right", display: "block" }}>
                           <Title level={5} style={{ textAlign: "right", color: "#FFF", marginRight: '15px', marginTop: '7px', fontSize: '11pt' }}> {this.state.itemCount}  Rubros</Title>
                        </Row>
                    </Col>
                </Row>

                <section className="p-1">
                    <Row style={{background:'#FFF'}}>
                        <Col span={24}>
                            <Table
                                //loading={this.state.loading}
                                columns={columns}
                                dataSource={this.state.dataRubros}
                                pagination={{
                                    current: this.state.currentPage,
                                    pageSize: this.state.perPage,
                                    total: this.state.itemCount,
                                    showSizeChanger: true,
                                    position: ['bottomLeft'],
                                }}
                                onChange={({current, pageSize, total}, b, sort) => {
                                    this.receivedData(current, pageSize)
                                }}
                                className="blankTheme" /> 
                        </Col>
                    </Row>
                </section>

                <ModalRubros
                    ref={this.ModalRubrosRef}
                    modalVisible={this.state.modalVisible}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    area_id={this.props.match.params.id}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                >
                </ModalRubros>
                    {(user.tipo !==3)? <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                    <IconPlus />
                </Button>:null}
                </Spin>
            </div>
        )
    }
}
