
/**
 * Estilos del header de cards del dashboard de Nominas
 */
const CardHeader = {
    fontStyle: 'normal',
    border: 'none',
    padding: '0px 1rem',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    color:'#858997',
}
/**
 * Estilos del body de cards del dashboard de Nominas
 */
const CardBody = {
    padding: '0px 1rem'
}
export {
    CardHeader,
    CardBody,
}