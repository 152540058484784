import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, Divider, message, Checkbox, DatePicker, Spin, Space } from 'antd';
//compoenentes

import { PlusOutlined } from '@ant-design/icons';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;

export default class ModalClasificarTransacciones extends Component {
    
    modalFacturas = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            uso_credito: false,
            proveedores: [],
            dataProyectos: [],
            dataAreas: [],
            dataRubros: [],
            dataSubrubros: [],
            belvo_facturas: this.props.belvo_facturas,
            pagos: false,
            loading: false,
            empresas: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },
        }
    }


    /**
    * @memberof ModalClasificarTransacciones
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
        this.loadProyectos();
        this.getProveedores();
        this.loadAreas();
        this.getEmpresas();
    }

    /**
     * @memberof ModalClasificarTransacciones
     * @method loadProyectos
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadProyectos = () => {
        axios.get('/proyectos', {
            params: {
                pagination: 'false'
            }
        }).then((proyectos) => {
            this.setState({
                dataProyectos: proyectos.data.data
            })
        }).catch((error) => {
        })
    }

    /**
     *
     *
     * @memberof ModalClasificarTransacciones
     * @function getProveedores
     *
     * @description Obtener provedores.
     */
    getProveedores = (search) => {
        axios.get('/proveedores/list', {
            params: {
                paginate: false,
                search,
            }
        }).then((proyectos) => {
            this.setState({ proveedores: proyectos.data.data })
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los proveedores.",
                content: "No fue posible obtener los proveedores."
            })
        })
    }

    /**
    *
    *
    * @memberof ModalClasificarTransacciones
    * @method loadAreas
    * @description Obtiene la informacion de los proyectos registradas en la base de datos
    *
    */
    loadAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                dataAreas: response.data.data
            })
        }).catch((error) => {
        })
    }

    /**
     *
     * @memberof ModalTransacciones
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
    getEmpresas = (
        {
            page = this.state.empresas.page,
            limit = this.state.empresas.limit,
            search = this.state.empresas.search,
        } = this.state.empresas
    ) => {
        
        axios.get("/empresas", {
               params: {
                    page,
                    limit,
                    search

                }
            })
            .then(({ data }) => {
                this.setState({ empresas: {...data, search } });
            })
            .catch((res) => {
                message.error(res.response.data.message);
            })
            
    };

    /**
     * @methodOF ModalClasificarTransacciones
     *
     * @function onAreaChange
     * @description Al actualizar un area, se deben traer los rubros y subrubros iniciales.
     * */
    onAreaChange = (id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        }).then(async (rubros) => {
            this.setState({ dataRubros: rubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataRubros.length > 0) {
                this.modalFacturas.current.setFieldsValue({
                    rubro: rubros.data.data[0]._id,

                });
                this.onRubroChange(rubros.data.data[0]._id)
            } else {
                this.modalFacturas.current.setFieldsValue({
                    rubro: undefined,
                    subrubro: undefined
                })
            }
        }).catch((error) => {

        })
    }

    /**
     *
     *
     * @memberof ModalClasificarTransacciones
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (e) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: e
            }
        }).then((subrubros) => {
            this.setState({
                dataSubrubros: subrubros.data.data
            })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataSubrubros.length > 0) {
                this.modalFacturas.current.setFieldsValue({
                    subrubro: subrubros.data.data[0]._id
                });
            } else {
                this.modalFacturas.current.setFieldsValue({
                    subrubro: undefined
                })
            }


        }).catch((error) => {
        })


    }

    /**
     * @memberof ModalClasificarTransacciones
     * @method onFinish
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    onFinish = (values) => {
        if(this.state.loading === true) return
        this.state.loading = true


        this.setState({ loading: true })
        const { selectedFacturas } = this.props;
        // this.setState({loading: true})

        axios.post('/syncfy/facturas/add', {
            proveedor_id: values.proveedor_id,
            proyecto_id: values.proyecto_id,
            credito: values.credito,
            fecha_vigencia: values.fecha_vigencia,
            cuenta_id: this.props.cuenta_id,
            empresa_id: this.props.empresa_id,
            // rubro_id: values.rubro,
            // sub_rubro_id: values.subrubro,
            facturas: Object.values(selectedFacturas)
        })
            .then(({ data }) => {
                
                this.props.onAccept()
            })
            .catch((error) => {
                console.log("error", error);
                message.error("No fue posible guardar, por favor contacte a soporte.")
            }).finally(()=>this.setState({ loading: false }))
    }

    hideModal = () => {
        this.props.onClose()
        this.setState({ uso_credito: false })
    }

    /**
     * @memberof ModalClasificarTransacciones
     * @method onFinish
     * @description verifica si hay facturas con pagos retorna true si tiene
     *
     */
    checkPayments = () => {
        let pagos = false
        if (this.props.selectedFacturas.length > 0) {
            for (const factura of this.props.selectedFacturas) {
                if (factura.payments.length > 0) {
                    pagos = true
                    break;
                }
            }
        }

        return pagos
    }


    render() {
        return (<Modal
            visible={this.props.visible}
            onCancel={this.hideModal}
            title={null}
            footer={null}
            closable={false}
            maskClosable={false}
            className="modal-form"
            destroyOnClose={true}
            zIndex={1000}
        >

            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title"> Clasificar Facturas</Title>
            </div>

            <Form
                layout="vertical"
                className="frm-cuentas"
                name="form-materiales"
                ref={this.modalFacturas}
                onFinish={this.onFinish}
                initialValues={{
                    empresa_id: this.props.empresa_id,
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col span={12}>
                            <Row align="center">
                                {this.props.selectedFacturas?.length === 1 ? <Col span={22}>
                                    <Form.Item
                                        label="Concepto"
                                        name="concepto"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el concepto"
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col> : null}
                                <Col span={22}>
                                    <Form.Item
                                        label="Proveedor"
                                        name="proveedor_id" //<--------------------------------------- proveedor
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione un proveedor"
                                        }]}
                                    >

                                        <Select
                                            className="form-select"
                                            onSearch={(search) => this.getProveedores(search)}
                                            showArrow={false}
                                            showSearch
                                            filterOption={false}
                                            disabled={this.props.proveedor ? true : false}
                                        >
                                            {this.state.proveedores.map((proveedor) => <Option value={proveedor._id}>
                                                {proveedor.alias}
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                                <Col span={22}>
                                    <Form.Item
                                        label="Proyecto"
                                        name="proyecto_id"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el proyecto"
                                        }]}
                                    >
                                        <Select
                                            showArrow={false}
                                            className="form-select"
                                            onSelect={(x, e) => this.setState({ proyecto: e.proyecto })}
                                            placeholder="Seleccione proyecto" disabled={this.state.edicion}>
                                            {this.state.dataProyectos.map((element, index) => {

                                                const { _id, nombre, logo, color } = element

                                                return <Option proyecto={element} value={_id}>
                                                    <CustomAvatar
                                                        image={logo}
                                                        name={nombre}
                                                        color={color}
                                                        size="small"
                                                        style={{
                                                            marginRight: '5px'
                                                        }}
                                                    />
                                                    {nombre}
                                                </Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>

                                <Col span={22}>
                                    <Form.Item
                                        label="Empresa"
                                        name="empresa_id"
                                    >
                                        <Select
                                            placeholder="Seleccione la empresa"
                                            onSearch={(value) => this.getEmpresas({ search: value })}
                                            showSearch
                                            filterOption={false}
                                            showArrow={false}
                                            className="form-select"
                                        >
                                            {
                                                this.state.empresas.data.map(empresa => {
                                                    return <Option value={empresa._id}>
                                                        <Space>
                                                            <CustomAvatar 
                                                                size="small" 
                                                                image={empresa.logo} 
                                                                name={empresa.alias} 
                                                                color={empresa.color} 
                                                            /> {empresa.alias}
                                                        </Space>
                                                    </Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col> 
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row align="center">
                                <Col span={22}>
                                    <Form.Item
                                        valuePropName="checked"
                                        label="Linea de Credito"
                                        name="credito" //<--------------------------------------- credito
                                    >
                                        <Checkbox onChange={e => { this.setState({ uso_credito: e.target.checked }) }}>Si</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={22} style={{ marginTop: '10px' }}>
                                    {!this.state.uso_credito ? <Form.Item
                                        label="Fecha de Vencimiento"
                                        name="fecha_vigencia" //<--------------------------------------- fecha_vigencia
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese la fecha de monto"
                                        }]}
                                    >
                                        <DatePicker />
                                    </Form.Item> : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>

        </Modal>

        )
    }
}

