import React, { Component } from 'react';
import { Col, Row, Card, Typography, message, Spin } from 'antd'

import axios from 'axios';

import Logged from "../../../../Hooks/Logged";

import { EmpleadoCard, GroupCard } from '../GroupCard';

const { Title } = Typography;

/**
 *
 * @param {*} usuario Usuario logeado
 * @param {*} objGrupo Grupo de la revision comparando
 * @param {*} nomina Tipo de vista que se esta trabajando 1 = finanzas nominas 2 =  revisiones
 * @returns
 */

export default class NominaDestajo extends Component {
    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {
            start: undefined,
            end: undefined,
            data_revisiones: [],
            modalidad: "destajo",
            loading: false
        };
    }

    /**
     * @memberof NominaDashboard
     * @description Se ejecuta al terminar de montar la vista de administar nominas;
     */

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getNominas();
    }


    /**
    * @memberof NominaDashboard
    * @method getNominas
    * @description Metodo que ejecuta la peticion al Web Service para traerse las nominas de los grupos que sean destajo
    */
    getNominas() {
        const user = this.context;
        let url = '';
        this.setState({ loading: true })

        url = '/nominas/revisiones/destajo/list'

        if (this.props.finanzas)
            url = '/nominas/revisiones/finanzas/list'

        axios.get(url, {
            params: {
                nomina_id: this.props.nomina_id,
                modalidad: this.state.modalidad
            }
        })
            .then(res => {
                if (res.data.data.length === 0) message.warn('No tiene grupos asignados.')
                //Se asigna a la variable local
                this.setState({ data_revisiones: res.data.data })
            })
            .catch(error => {
                console.log("errr", error)
            })
            .finally(f => {
                this.setState({ loading: false })
            }
            )

    }






    render() {
        const { nomina } = this.state;

        const user = this.context;
        // Solo para el dueño usuario ->tipo :1
        return (
            <Row style={{ padding: '24px' }} className="content">
                <Row gutter={[32, 32]} style={{ width: '100%' }}>
                    <Col xs={24} sm={24} xl={8}>
                        <Card className="content-card">
                            <Title level={3} style={{ color: "#858997" }}>Total Autorizado</Title>
                            {/*parseFloat(revision.empleados.reduce((total, item) => total +  item.monto, 0)*/}
                            <Title level={2} style={{ textAlign: "center" }}>$ {this.state.data_revisiones.reduce(function (a, b) { return (parseFloat(a) + parseFloat(b.monto_total)).toFixed(2) }, 0)} &nbsp;<small>MXN</small></Title>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} xl={8}>
                        <Card className="content-card">
                            <Title level={3} style={{ color: "#858997" }}>Número de Empleados</Title>
                            <Title level={2} style={{ textAlign: "center" }}>{this.state.data_revisiones.length}</Title>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} xl={8}>
                        <Card className="content-card">
                            <Title level={3} style={{ color: "#858997" }}>Fecha de Nomina</Title>
                            <Title level={2} style={{ textAlign: "center" }}>0</Title>
                        </Card>
                    </Col>
                </Row>
                <Spin spinning={this.state.loading}>
                    <Row gutter={[32, 32]} style={{ width: '100%' }}>
                        {this.state.data_revisiones.map(i =>
                            <Col xs={24} sm={24} xl={8}>
                                {/* <Spin loading={false}> */}
                                <EmpleadoCard
                                    usuario={user}
                                    objEmpleado={i}
                                    vistaNomina={(this.props.finanzas) ? 1 : 2}
                                    finanzas={true}
                                    nomina_id={this.props.nomina_id}
                                />
                                {/* </Spin> */}
                            </Col>
                        )}
                    </Row>
                </Spin>
            </Row>
        )
    }
}
