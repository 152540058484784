import React from 'react';
import { Space, Button, Card, Typography, Tag} from 'antd'
import Avatar from "../../Widgets/Avatar/Avatar";

import { CloseCircleOutlined } from '@ant-design/icons'
import { IconCheckCircle, IconRegister, IconDeniedRegister, IconCheck, IconInmuebles, IconTools, IconWorker } from '../../Widgets/Iconos';
import { Link } from "react-router-dom";


const { Title, Paragraph, } = Typography;

/**
 * @function GroupCard
 * @description Componente para los card de grupo
 * @param usuario usuario logeado en el sistema
 * @param objEmpleado objeto con la informacion a cargar en el card
 * @param nomina_id ID de la nomina a cargar
 */
function GroupCard({ usuario, objGrupo, vistaNomina, nomina_id }) {

    return <Card className="content-card content-card-payroll">
        <Avatar
            name={[
                objGrupo.revision.responsable_id.nombre,
                objGrupo.revision.responsable_id.apellido
            ]}
            image={objGrupo.revision.responsable_id.avatar}
            style={{
                width: '60px',
                height: '60px',
                float: "right",
                fontSize: '30px'
            }}
            className="avatar-group-card"
        />
        <Title level={3} style={{ marginBottom: '0.5em' }}>{objGrupo.revision.nombre}</Title>
        <Paragraph style={{ marginBottom: '0.5em' }}>{(objGrupo.revision.modalidad === 1) ? 'Salario' : 'Destajo'} - {(objGrupo.revision.frecuencia === 1) ? 'Semanal' : 'Quincenal'} </Paragraph>

        <Paragraph style={{ marginBottom: '0.5em' }}> <label
            title="Area"> {(objGrupo.revision.area_id !==undefined) ? objGrupo.revision.area_id.nombre : 'N/A'} </label>{' '} - {' '}<label
                title="Inmueble"> {' ', (objGrupo.revision.inmueble_id) ? objGrupo.revision.inmueble_id.nombre : 'N/A'} </label>
        </Paragraph>

        {
            (usuario && (usuario.roles['finanzasnominas'] || usuario.tipo === 1) && vistaNomina === 1) ?
                <Paragraph>Autorización Final
                    {(objGrupo.autorizado_final) ? <IconCheck color="#23C496" style={{ float: "right" }} /> :
                        <CloseCircleOutlined style={{
                            right: '3px',
                            position: 'relative',
                            fontSize: '20px',
                            width: "20px",
                            height: "20px",
                            float: "right"
                        }} />}
                </Paragraph>
                :
                <Paragraph>Autorización Preliminar
                    {(objGrupo.autorizado_preliminar) ? <IconCheck color="#23C496" style={{ float: "right" }} /> :
                        <CloseCircleOutlined style={{
                            right: '3px',
                            position: 'relative',
                            fontSize: '20px',
                            width: "20px",
                            height: "20px",
                            float: "right"
                        }} />}
                </Paragraph>
        }

        <Title level={3} style={{ marginBottom: '0.5em', textAlign: "center", width: "100%" }}>
            $ {(objGrupo.revision.empleados.reduce(function (a, empleado) {
            return a + (empleado.monto)
        }, 0)).toString().toMoney()}
            <small>MXN</small>
        </Title>
        {
            (() => {
                if (vistaNomina === 1) {//Vista de finanzas nominas

                    return <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                        {(objGrupo.registrado_finanzas) ?
                            <Button type="primary" className="button-group-card button-register">
                                <Link
                                    to={`/admin/nomina/administrar-nomina-finanzas/${nomina_id}/grupo/${objGrupo.revision._id}`}>
                                    <IconRegister size={1} className="icon-button" color="white" /> &nbsp; Registrado
                                </Link>
                            </Button>
                            :
                            <Button type="primary" className="button-group-card button-unregistered">
                                <Link
                                    to={`/admin/nomina/administrar-nomina-finanzas/${nomina_id}/grupo/${objGrupo.revision._id}`}>
                                    <IconDeniedRegister size={1} className="icon-button" color="white" /> &nbsp;  Sin
                                    Registrar
                                </Link>
                            </Button>
                        }
                    </Space>
                } else {
                    //En caso de que sea dueño
                    if (usuario && (usuario.tipo === 1)) {
                        return (<Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                            {(objGrupo.autorizado_final) ?
                                <Button type="primary" className="button-group-card button-authorize">
                                    <Link
                                        to={`/admin/nomina/administrar-nomina/${nomina_id}/grupo/${objGrupo.revision._id}`}>
                                        <IconCheckCircle className="icon-button" color="white" /> Autorizado</Link>
                                </Button>
                                :
                                <Button type="primary" className="button-group-card button-no-authorize">
                                    <Link
                                        to={`/admin/nomina/administrar-nomina/${nomina_id}/grupo/${objGrupo.revision._id}`}>
                                        Sin autorizar
                                    </Link>
                                </Button>}
                            <Button type="primary" className="button-group-card button-review">
                                <Link
                                    to={`/admin/nomina/administrar-nomina/${nomina_id}/grupo/${objGrupo.revision._id}`}>
                                    Editar Revision
                                </Link>
                            </Button>
                        </Space>)
                    } else { //Es para el usuario responsable de grupo
                        return (<Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                            {(objGrupo.autorizado_preliminar) ?
                                <Button type="primary" className="button-group-card button-authorize">
                                    <Link
                                        to={`/admin/nomina/administrar-nomina/${nomina_id}/grupo/${objGrupo.revision._id}`}>
                                        <IconCheckCircle className="icon-button" color="white" /> Autorizado</Link>
                                </Button>
                                : <Button type="primary" className="button-group-card button-no-authorize">
                                    <Link
                                        to={`/admin/nomina/administrar-nomina/${nomina_id}/grupo/${objGrupo.revision._id}`}>
                                        Sin autorizar
                                    </Link>
                                </Button>}
                            <Button type="primary" className="button-group-card button-review">
                                <Link
                                    to={`/admin/nomina/administrar-nomina/${nomina_id}/grupo/${objGrupo.revision._id}`}>
                                    Editar Revision
                                </Link>
                            </Button>
                        </Space>)
                    }
                }

            })()
        }
    </Card>
}


/**
 * @function EmpleadoCard
 * @description Componente para los card de empleados
 * @param usuario usuario logeado en el sistema
 * @param objEmpleado objeto con la informacion a cargar en el card
 * @param nomina_id ID de la nomina a cargar
 */
function EmpleadoCard({ usuario, objEmpleado, vistaNomina, nomina_id }) {
    return <Card className="content-card content-card-payroll">
        <Title level={3} style={{ marginBottom: '0.5em' }}>{objEmpleado.nombre} {objEmpleado.apellido}</Title>
        <Title level={3} style={{ marginBottom: '0.5em', textAlign: "center", width: "100%" }}>
            $ {objEmpleado.monto_total.toMoney()}
            <small>MXN</small>
        </Title>
        {/* <Button  type="primary"  type="primary" className="button-group-card button-register" href={`/admin/nomina/administrar-nomina-finanzas/${nomina_id}/empleado/${objEmpleado._id}`}>Revisar</Button> */}

        {
            (() => {
                if (vistaNomina === 1) {//Vista de finanzas nominas
                    return <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                        {(objEmpleado.registrada) ?
                            <Button type="primary" className="button-group-card button-register" disabled>
                                <IconRegister size={1} className="icon-button" color="white" /> &nbsp; Registrado
                            </Button>
                            :
                            <Button type="primary" className="button-group-card button-unregistered">
                                <Link
                                    to={`/admin/nomina/administrar-nomina-finanzas/${nomina_id}/empleado/${objEmpleado._id}`}>
                                    <IconDeniedRegister size={1} className="icon-button" color="white" /> &nbsp;  Sin
                                    Registrar
                                </Link>
                            </Button>
                        }
                    </Space>
                } else {
                    //En caso de que sea dueño
                    if (usuario && (usuario.tipo === 1)) {
                        return (<Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                            {(objEmpleado.registrada) ?
                                <Button type="primary" className="button-group-card button-authorize">
                                    <Link
                                        to={`/admin/nomina/administrar-nomina/${nomina_id}/empleado/${objEmpleado._id}`}>
                                        <IconCheckCircle className="icon-button" color="white" /> Autorizado</Link>
                                </Button>
                                :
                                <Button type="primary" className="button-group-card button-no-authorize" >
                                    <Link to={`/admin/nomina/administrar-nomina/${nomina_id}/empleado/${objEmpleado._id}`}>Sin autorizar  </Link>
                                </Button>}
                            <Button type="primary" className="button-group-card button-review">
                                <Link to={`/admin/nomina/administrar-nomina/${nomina_id}/empleado/${objEmpleado._id}`}>
                                    Editar Revision
                                </Link>
                            </Button>
                        </Space>)
                    } else { //Es para el usuario responsable de grupo
                        return (<Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                            {(objEmpleado.autorizado_preliminar) ?
                                <Button type="primary" className="button-group-card button-authorize">
                                    <IconCheckCircle className="icon-button" color="white" /> Autorizado
                                </Button>
                                : <Button type="primary" className="button-group-card button-no-authorize" disabled>
                                    Sin autorizar
                                </Button>}
                            <Button type="primary" className="button-group-card button-review">
                                <Link to={`/admin/nomina/administrar-nomina/${nomina_id}/empleado/${objEmpleado._id}`}>
                                    Editar Revision
                                </Link>
                            </Button>
                        </Space>)
                    }
                }
            })()
        }
    </Card>
}



/**
 * @function InmuebleCard
 * @description Componente para los cards de inmuebles
 * @param usuario usuario logeado en el sistema
 * @param objEmpleado objeto con la informacion a cargar en el card
 * @param nomina_id ID de la nomina a cargar
 */
function InmuebleCard({ autorizada, monto_total, total_autorizados, total_autorizar, _id, usuario = {}, inmueble = {}, nomina_id, vistaNomina = 0 }) {
    return <Card className="content-card content-card-payroll">
        <Title level={3} style={{ marginBottom: '0.5em' }}>{inmueble.nombre}</Title>
        <Title level={3} style={{ marginBottom: '0.5em', textAlign: "center", width: "100%" }}>
            $ {monto_total.toMoney()}
            <small>MXN</small>
        </Title>
        {
            (() => {
                if (vistaNomina === 1) {//Vista de finanzas nominas
                    return <Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                        {(autorizada) ?
                            <Button type="primary" className="button-group-card button-register" disabled>
                                <IconRegister size={1} className="icon-button" color="white" /> &nbsp; Registrado
                            </Button>
                            :
                            <Button type="primary" className="button-group-card button-unregistered">
                                <Link
                                    to={`/admin/nomina/administrar-nomina-finanzas/${nomina_id}/inmueble/${inmueble._id}`}>
                                    <IconDeniedRegister size={1} className="icon-button" color="white" /> &nbsp;  Sin
                                    Registrar
                                </Link>
                            </Button>
                        }
                    </Space>
                } else {
                    //En caso de que sea dueño
                    return (<Space style={{ width: '100%', justifyContent: "center", marginTop: '1em' }}>
                        {(autorizada) ?
                            <Button type="primary" className="button-group-card button-authorize">
                                <Link
                                    to={`/admin/nomina/administrar-nomina/${nomina_id}/inmueble/${inmueble._id}`}
                                >
                                    <IconCheckCircle className="icon-button" color="white" /> Autorizado</Link>
                            </Button>
                            :
                            <Button type="primary" className="button-group-card button-no-authorize" >
                                <Link to={`/admin/nomina/administrar-nomina/${nomina_id}/inmueble/${inmueble._id}`}>Sin autorizar  </Link>
                            </Button>}
                        <Button type="primary" className="button-group-card button-review">
                            <Link to={`/admin/nomina/administrar-nomina/${nomina_id}/inmueble/${inmueble._id}`}>
                                Editar Revision
                                </Link>
                        </Button>
                    </Space>)

                }
            })()
        }
    </Card>
}


/**
 * @function InmuebleCard
 * @description Componente para los cards de inmuebles
 * @param usuario usuario logeado en el sistema
 * @param objEmpleado objeto con la informacion a cargar en el card
 * @param nomina_id ID de la nomina a cargar
 */

 function InmuebleCardElement({ inmuebles, actividades, empleados, nomina_id, usuario, inmueble, tipo, autorizado }){

    let tags = {
        'Empleados' : <Tag className="tag-cargar-1" color='gray'><IconInmuebles/>{inmuebles}</Tag>,
        'Inmuebles' : <Tag className="tag-cargar-1" color='black'><IconWorker/>{empleados}</Tag>
    }

    let links = {
        'Empleados' : `/admin/nomina/revision-preliminar/${nomina_id}/empleado/${usuario?._id}`,
        'Inmuebles' : `/admin/nomina/revision-preliminar/${nomina_id}/inmueble/${inmueble?._id}`
    }

    return <Card className="content-card content-card-payroll card-cargar">
        {tags[tipo]}
        <Tag className="tag-cargar-2" color='#f50'><IconTools/>{actividades}</Tag>
        <Title level={3} style={{ marginBottom: '0.1em' }}>{inmueble ? inmueble.nombre : `${usuario.nombre} ${usuario.apellido}`}</Title>
        <Button type="primary" className={`button-group-card ${autorizado === true ? 'button-authorize': 'button-no-authorize'}`} style={{ margin: '0px auto', display: 'block'}}>
            <Link to={links[tipo]}>{autorizado === true ? 'Cargado' : 'Cargar' }</Link>
        </Button>

    </Card>
}



/**
 * @function InmuebleCard
 * @description Componente para los cards de inmuebles
 * @param usuario usuario logeado en el sistema
 * @param objEmpleado objeto con la informacion a cargar en el card
 * @param nomina_id ID de la nomina a cargar
 */

function EmpleadoCardElement({ usuario, nomina_id, inmueble_id, total_tajos = 0  }) {
    return <Card className="content-card content-card-payroll">
        <Title level={3} style={{ marginBottom: '0.1em' }}>{usuario.nombre} {usuario.apellido}</Title>
        <Title style={{ fontSize: 14, fontWeight: 'lighter' }}>{total_tajos} tajos</Title>
        <Avatar
            size="large"
            style={{ position: 'absolute', right: 15, top: 15 }}
            name={[
                usuario.nombre,
                usuario.apellido,
            ]}
            image={usuario.avatar}
        />

        <Button type="primary" className="button-group-card button-authorize" style={{ margin: '0px auto', display: 'block'}}>
            <Link to={`/admin/nomina/inicio/nomina/${nomina_id}/inmueble/${inmueble_id}/empleado/${usuario._id}`}>Ir a Tajos</Link>
        </Button>

    </Card>
}




export default GroupCard;

export {
    EmpleadoCard,
    GroupCard,
    InmuebleCard,

    //VERSION 2

    InmuebleCardElement,
    EmpleadoCardElement
}
