import React, { Component } from "react";
import { Dropdown, Button, Menu, Avatar, Typography, Checkbox, message, Input, Row, Col } from "antd";
import { DownOutlined } from '@ant-design/icons';
//compoenentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";

const { Text } = Typography;
const { SubMenu } = Menu;
const axios = require('axios')
/**
 *
 *
 * @export
 * @class ReporteFilter
 * @extends {Component}
 */
export default class ReporteFilter extends Component {

    state = {
        visible: false,
        proveedores: [],
        selectedProveedores: [],
        selectedEstatus: []
    }


    componentDidMount() {
        this.getProveedores()
    }

    handleMenuClick = e => {
        if (e.key === '3') {
            this.setState({ visible: false });
        }
    };

    handleVisibleChange = flag => {
        this.setState({ visible: flag });
    };

    /**
     * @memberof getProveedores
     *
     * @description obtiene los proveedores
     *
     */
    getProveedores = (search) => {
        axios.get('/proveedores/list', {
            params: {
                search: search,
                limit: 15
            }
        }).then(response => {
            this.setState({ proveedores: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los proveedores')
        })
    }

    onCheckProveedor = (proveedor, checked) => {
        const { selectedProveedores } = this.state

        let index = selectedProveedores.findIndex(sP => sP._id === proveedor._id)
        console.log(index)
        if (index === -1) selectedProveedores.push(proveedor)
        else selectedProveedores.splice(index, 1)
        console.log(selectedProveedores)
        this.setState({ selectedProveedores })
    }

    onCheckEstatus = (estatus) => {
        const { selectedEstatus } = this.state

        let index = selectedEstatus.indexOf(estatus)

        if (index === -1) selectedEstatus.push(estatus)
        else selectedEstatus.splice(index, 1)

        this.setState({ selectedEstatus })
    }



    render() {

        return (
            <Row style={{ width: '100%', position: 'relative', top: 5 }}>
                <Col span={8}>
                    <Dropdown
                        trigger={["click"]}
                        className="dropdown-transparent dropdown-reporte"
                        onVisibleChange={(value) => this.setState({visibleProveedor: value })}
                        visible={this.state.visibleProveedor}
                        style={{ }}
                        overlay={(
                            <Menu mode="vertical" style={{ maxHeight: '400px', overflow: 'auto' }}>
                                <Input
                                    onChange={(e) => { this.getProveedores(e.target.value) }}
                                    placeholder="Buscar"
                                    style={{ width: 'calc(100% - 10px)', display: 'block', margin: 'auto' }}
                                />
                                {this.state.selectedProveedores.map((proveedor, index) => (
                                    <Menu.Item key={proveedor._id}>
                                        <Checkbox
                                            checked={true}
                                            onClick={() => this.onCheckProveedor(proveedor)}>
                                            {proveedor.alias}
                                        </Checkbox>
                                    </Menu.Item>
                                ))}
                                {this.state.proveedores.map((proveedor, index_) => {
                                    const { selectedProveedores } = this.state
                                    let index = selectedProveedores.findIndex(sP => sP._id === proveedor._id)

                                    if (index === -1) return <Menu.Item key={proveedor._id}>
                                        <Checkbox
                                            checked={false}
                                            onClick={() => this.onCheckProveedor(proveedor)}>
                                            {proveedor.alias}
                                        </Checkbox>
                                    </Menu.Item>
                                    else return null


                                })}
                            </Menu>

                        )}>
                        <a style={{ minWidth: '150px', position: 'relative', top: '10px'  }} onClick={e => e.preventDefault()}> Proveedores  <DownOutlined /></a>
                    </Dropdown>
                </Col>
                <Col span={8}>
                    <Dropdown
                        trigger={["click"]}
                        className="dropdown-transparent dropdown-reporte"
                        onVisibleChange={(value) => this.setState({visibleEstatus: value })}
                        visible={this.state.visibleEstatus}
                        style={{ }}
                        overlay={(
                            <Menu mode="vertical">
                                <Menu.Item key={'e-1'}>
                                    <Checkbox onClick={() => this.onCheckEstatus(1)}>Vigente</Checkbox>
                                </Menu.Item>
                                <Menu.Item key={'e-2'}>
                                    <Checkbox onClick={() => this.onCheckEstatus(2)}>Por Vencer</Checkbox>
                                </Menu.Item>
                                <Menu.Item key={'e-3'}>
                                    <Checkbox onClick={() => this.onCheckEstatus(3)}>Vencida</Checkbox>
                                </Menu.Item>
                            </Menu>
                        )}>
                        <a style={{ minWidth: '150px', position: 'relative', top: '10px'  }} onClick={e => e.preventDefault()}> Estatus  <DownOutlined /></a>
                    </Dropdown>
                </Col>
                <Col span={8} >
                    <Button  onClick={()=>{this.props.printReporte(this.state.selectedProveedores, this.state.selectedEstatus)}} type="ghost" style={{ color: "white", position: 'relative', top: '5px', right: '9px' }}>Generar Reporte</Button>
                </Col>
            </Row>


        )
    }
}