import React, { Component } from 'react';
import { Button, Col, Modal, Row, Spin, Table, Typography, Popover } from 'antd'

import { IconDelete, IconEdit, IconPlus, IconBankError } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { ExclamationCircleOutlined } from "@ant-design/icons";


import Logged from "../../../Hooks/Logged";
import { Redirect } from 'react-router-dom'
import ModalCuenta from '../Modales/Cuentas/ModalCuentas';
import ModalCuentaSYNCFY from '../Modales/Cuentas/ModalCuentaSYNCFY';
import { BankOutlined } from '@ant-design/icons';



const { Title } = Typography;
const axios = require('axios').default;

export default class Cuentas extends Component {

    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            dataCuentas: [],
            loading: true,

            accionModal: 'crear',
            registroId: '',
            visible: false,
            tipo_user: 0,
            redirect: false,
            id_cuenta: '',
            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,

            filtroSearch: "",
            searching: true

        }
    }

    //Referencia
    modalCuenta = React.createRef();


    /**
     *
     *
     * @memberof Cuentas
     * 
     * @method componentDidMount
     * @description Manda a llamar al metodo receiveData para cargar los datos a la vista
     */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.receivedData();
    }


    /**
     *
     *
     * @memberof Cuentas
     * 
     * @method componentDidUpdate
     * @description Acualiza la vista cuando se busca un elemento en la barra 
     */
    componentDidUpdate() {

        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.state.searching = false;
            this.receivedData();
        }
        if (this.context !== 0 && this.state.tipo_user === 0)
            this.setState({
                tipo_user: this.context.tipo
            })
    }

    /**
     *
     * @memberof Cuentas
     * 
     * @method receivedData
     * @description Obtiene la informacion y actualiza los valores locales de la lista.
     */
    receivedData = async (page, size) => {
        this.setState({ loading: true });
        var search = this.props.search
        axios.get('/cuentas/list', {
            params: {
                page: page,
                limit: size,
                search: search
            }
        })
            .then(res => {
                const data = res.data.data.itemsList
                this.state.searching = true;
                this.setState({
                    dataCuentas: data.map(e => ({ ...e, key: e._id })),
                    currentPage: res.data.data.paginator.currentPage,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: res.data.data.paginator.perPage,
                    pageCount: res.data.data.paginator.pageCount,
                    filtroSearch: search,
                    searching: true
                })


            })
            .catch(res => {
                console.log("error", res);
            })
            .finally(f => {
                this.setState({ loading: false })
            });
    }


    /**
     * @memberof Cuentas
     * 
     * @method modalCrear
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }

    /**
     * @methodOf  Cuentas
     * @method showModal
     *
     * @description Muestra el modal del cuenta
     *
     * */
    showModal = (id = undefined) => this.setState({
        visible: true,
        id: id
    });

    /**
     * @methodOf  Cuentas
     * @function hideModal
     *
     * @description Oculta el modal del cuenta
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };


    /**
     * @methodOf  Cuentas
     * @function hideModal
     *
     * @description Oculta el modal del cuenta
     *
     * */
    deleteModal = (id) => {
        let comp = this;
        Modal.confirm({
            title: '¿Estas seguro de que deseaas eliminar esta cuenta?',
            icon: <ExclamationCircleOutlined />,
            content: 'No será posible recuperar al información al respecto de esta cuenta.',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                axios.delete('/cuentas/delete', { params: { id } })
                    .then(() => {
                        comp.receivedData()
                    })
                    .catch(({ response }) => {

                        Modal.error({
                            title: "No es posible eliminar.",
                            content: response.data.message
                        })

                    })

            },
        });
    }

    /**
     * @memberof Cuentas
     * @function renderRedirect
     * @description se encarga de enviar a la vista de enlace de cuenta con Belvo
     */
    renderRedirect = () => {
        if (this.state.redirect) {

            return <Redirect to={'/admin/cuentas/belvo/' + this.state.id_cuenta} />
        }
    }


    onCuentaSelect = (id_cuenta) => {
        console.log('Id de la cuenta', id_cuenta)

        this.setState(state => {
            state.id_cienta = id_cuenta
            state.redirect = true;
            return state;
        })

    }

    render() {
        const columns = [
            {
                title: 'Logo',
                key: 'logo',
                dataIndex: 'logo',
                width: '80px',
                render: (text, { nombre, color, logo }) => <CustomAvatar
                    image={logo}
                    color={color}
                    name={nombre}
                />,

            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
                render: (text, record) => (
                    <h3>{record.nombre}</h3>
                ),
            },
            {
                title: 'Saldo',
                dataIndex: 'saldo',
                //render: text => '$ ' + parseFloat(text).toFixed(2),
                render: text => '$ ' + new Intl.NumberFormat().format(text)
            },
            {
                title: 'Titular',
                dataIndex: 'titular',
                key: 'titular',
            },
            {
                title: 'Banco',
                dataIndex: 'banco',
                key: 'banco',

            },
            {
                title: 'Número Cuenta',
                dataIndex: 'cuenta',
                key: 'numerocuenta',

            },
            {
                title: 'Acciones',
                key: 'actions',
                width: '200px',
                render: (text, record) => (
                    <div className="tbl-opciones">
                        {(this.state.tipo_user !== 3) ?
                            <div className="center">
                                <Popover placement="topLeft"
                                    title={(record.syncfy_usuario_id && record.syncfy_credenciales_id) ? "Cuenta con enlace bancario" : "Cuenta sin enlace bancario"}
                                >


                                    <Button
                                        className="btn-edit"
                                        type={"primary"}
                                        onClick={() => {
                                            console.log(record)
                                            this.setState({ syncfy_id: record._id, modalSyncfy: true })
                                        }}
                                        danger={!(record.syncfy_usuario_id && record.syncfy_credenciales_id)}
                                        icon={
                                            (record.syncfy_usuario_id && record.syncfy_credenciales_id)
                                                ?
                                                <BankOutlined style={{ position: 'relative', bottom: 4 }} />
                                                :
                                                <IconBankError color="white" style={{ position: 'relative', bottom: 4 }} />
                                        } />

                                    {/* (record.syncfy_usuario_id  && record.syncfy_credenciales_id) */}
                                    {/* {(record.belvo_transacciones || record.belvo_facturas || record.belvo_link) ?
                                        //Ya esta registrado
                                        <Button type="primary" className="button-survey btn-edit button-edit"
                                            style={{ backgroundColor: "#28a745" }}
                                            name={record.nombre} id={record._id} title="Enviar" >
                                            <IconCuentas color="white" />
                                        </Button>
                                        :
                                        //No esta vinculada
                                        <Link title="Enlazar" to={`/admin/cuentas/belvo/${record._id}`}>
                                            <Button type="primary" className="btn-edit button-edit btn-edit"
                                                style={{ backgroundColor: "#ffc107" }}
                                                name={record.nombre} id={record._id} title="Enviar" >
                                                <IconCross color="white" style={{ width: "20px", height: "20px" }} />
                                            </Button>
                                        </Link>
                                    } */}
                                </Popover>
                                <Button type="primary" className="button-survey btn-edit button-edit"
                                    onClick={() => this.showModal(record._id)} title="Editar">
                                    <IconEdit />
                                </Button>
                                <Button type="primary"
                                    className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                    onClick={() => this.deleteModal(record._id)} title="Eliminar">
                                    <IconDelete />
                                </Button>
                            </div> : null
                        }
                    </div>
                ),
            },
        ];


        const { visible, loading } = this.state;
        const user = this.context;
        return (
            <div>
                <Logged.Consumer>
                    {value => {
                        this.context = value
                    }}
                </Logged.Consumer>
                <Spin spinning={loading}>
                    <Row className="row-encabezado">
                        <Col xs={12} xl={20} xxl={20} className="flex-left">
                            <Title level={4} className="view-title">Cuentas</Title>
                        </Col>
                        <Col xs={12} xl={4} xxl={4} className="flex-right">
                            <Row align="right" style={{ textAlign: "right", display: "block" }}>
                                <Title level={5} style={{
                                    textAlign: "right",
                                    color: "#FFF",
                                    marginRight: '15px',
                                    marginTop: '7px',
                                    fontSize: '11pt'
                                }}> {this.state.itemCount} Cuentas</Title>
                            </Row>
                        </Col>
                    </Row>


                    <Row className="p-1">
                        <div className="div_contenido">
                            <Table
                                loading={this.state.loading}
                                pagination={{
                                    current: this.state.currentPage,
                                    pageSize: this.state.perPage,
                                    total: this.state.itemCount,
                                    showSizeChanger: true,
                                    position: ['bottomLeft'],
                                }}
                                onChange={({ current, pageSize, total }, b, sort) => {
                                    this.receivedData(current, pageSize)
                                }}
                                columns={columns}
                                dataSource={this.state.dataCuentas}

                                scroll={{ x: 1000, y: 1000 }}
                                locale={{ emptyText: 'Sin Cuentas Disponibles' }} />
                        </div>
                    </Row>

                </Spin>
                <ModalCuenta
                    visible={visible}
                    hideModal={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                    id={this.state.id}
                    update={() => this.receivedData()}
                >
                </ModalCuenta>

                <ModalCuentaSYNCFY
                    visible={this.state.modalSyncfy}
                    id={this.state.syncfy_id}
                    onCancel={() => {
                        this.setState({ modalSyncfy: false, id: null, cuenta_id: undefined })
                        this.receivedData();
                    }}
                />

                {(user && user.tipo !== 3) ?
                    <Button className="btnAddPlus" title="Nuevo registro" onClick={() => this.showModal()}>
                        <IconPlus />
                    </Button> : null}
            </div>
        )
    }
}
