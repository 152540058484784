
import React, { Component } from 'react';
import { Button, Col, Row, Spin, Empty, Typography, Progress, List, Badge, Card } from 'antd'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';


 
//componentes
import {CardInfoDashboard, CardProgress, CardActivity, CardInfo, CardProgressBar} from '../../Widgets/Cards/Cards';

import Avatar from "../../Widgets/Avatar/Avatar";
import {IconEye} from '../../Widgets/Iconos';
//css
import '../../../Styles/projectManager.css'

const axios = require('axios').default;


const moment = require('moment')
const {Text} = Typography;

const data = [
    {
        key: 1,
        fecha: '{{Fecha}}',
        tag: '{{TagActividad}}',
        montoPagado: '{{MontoPagado}}',
        m2Trabajados: '{{m2Trabajados}}',
        fotos: 8,
    },
    {
        key: 2,
        fecha: '{{Fecha}}',
        tag: '{{TagActividad}}',
        montoPagado: '{{MontoPagado}}',
        m2Trabajados: '{{m2Trabajados}}',
        fotos: 8,
    },
    {
        key: 3,
        fecha: '{{Fecha}}',
        tag: '{{TagActividad}}',
        montoPagado: '{{MontoPagado}}',
        m2Trabajados: '{{m2Trabajados}}',
        fotos: 8,
    },
    {
        key: 4,
        fecha: '{{Fecha}}',
        tag: '{{TagActividad}}',
        montoPagado: '{{MontoPagado}}',
        m2Trabajados: '{{m2Trabajados}}',
        fotos: 8,
    },
];


export default class PMTajo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            proyecto_id: "0",
            bitacoras_nomina: [],
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTajosPorNomina()

    }



    getTajosPorNomina = () => {
        axios.get('/project-manager/proyecto/inmueble/actividad/tajos', {
            params: {
                manager_tajo_id: this.props.match.params.id
            }
        })
            .then(({data}) => {
                console.log('traje manager proyectos', data);
                this.setState({
                    loading: false,
                    ...data.data
                });
            })
            .catch(error => {
                console.log('no traje nanai', error);
            })

    }


    render() {
        return (
            <div>
 
                <Spin spinning={this.state.loading}>

                    <Row className="row-encabezado">
                        <Col xs={24} xl={6} xxl={8}>
                            <h2 style={{width: 'auto', float: 'left'}}> Tajo de [Actividad] de [Inmueble] </h2>
                        </Col>
                    </Row>


                    <section className="p-1">

                    <Row gutter={[16, 24]}>
                        <Col xs={24} lg={8} className="gutter-row">
                            <CardInfo titleLeft="TAJO" infoLeft="Tajo" />
                        </Col>
                        <Col xs={24} lg={8} className="gutter-row">
                            <CardInfo titleLeft="Progreso Real"

                                titleRight="Progreso Ponderado"
                                infoLeft={<Progress type="circle" percent={30} width={50} />}
                                infoRight={<Progress type="circle" percent={100} width={50} />} />
                        </Col>
                         
                        <Col xs={24} lg={8} className="gutter-row">
                            <CardInfo titleLeft="Porcentajo de Actividad" infoLeft='50%'/>

                        </Col>
                    </Row>

                    <Row gutter={[16, 24]}>
                        <Col xs={24} lg={8} className="gutter-row">


                            <CardInfo titleLeft="Costo Presupuestado" titleRight="Costo Actual" infoLeft="$ 100,000 MXN"
                                      infoRight="$ 56,000 MXN"/>

                        </Col>
                        
                        {/* <Col xs={24} lg={8} className="gutter-row">
                            <CardInfo titleLeft="M2 Totales" titleRight="M2 Actuales" infoLeft="1000 M2"
                                      infoRight="154 M2"/>
                        </Col> */}

                        <Col xs={24} lg={8} className="gutter-row">
                            <CardInfo titleLeft="Fecha de Inicio" titleRight="fecha de Conclusion" infoLeft="10-11-2020"
                                      infoRight="23-11-2020"/>
                        </Col>
                    </Row>


                    <div className="list-title">Bitacora</div>

                    <List
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.bitacoras_nomina}
                        renderItem={item => (
                            <List.Item className="component-list-item">

                                <Card className="card-list">
                                    <Row style={{width: '100%'}} className="">
                                        <Col span={3} className="center ">
                                            <Text strong>{moment(item.fecha_nomina).format('YYYY-MM-DD')}</Text>
                                        </Col>
                                        <Col span={5} className="center ">
                                            <Text>{item.tag}</Text>
                                        </Col>
                                        <Col span={4} className="center ">
                                            <Text className="text-middle">{item.revision.empleados.monto}</Text>
                                        </Col>
                                        <Col span={5} className="center ">
                                            <Text className="text-middle">{item.revision.empleados.m2_trabajados}</Text>
                                        </Col>
                                        <Col span={4} className="center ">
                                            <Text className="text-middle">{item.fotos} Fotos</Text>
                                        </Col>
                                        <Col span={3} className="">

                                            <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle"  >
                                                <IconEye />
                                            </Button>

                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                </section>
                </Spin>
            </div>
        )
    }
}
