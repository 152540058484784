import React, {useState} from "react";
import {Avatar, Popover,  } from 'antd';

import {TwitterPicker} from "react-color";

import "./ColorPicker.css"

const Color = "#808080";
interface ReactColorInputs
{
    value ? : Color ;
    onChange ? : (value: Color ) => void;
}

const ColorPicker: React.FC<ReactColorInputs> = ({value = "#808080", onChange, title = "", placement = "topLeft", colors = undefined}) => {
    const [color, setColor] = useState(value);

    const triggerChange = changedValue => {
        if (onChange) {
            onChange(changedValue);
        }
    };

    const onColorChange = (newColor) => {
        setColor(newColor.hex);
        triggerChange(newColor.hex);
    }

    return <Popover
        overlayClassName="color-picker-hover"
        content={<TwitterPicker
            color={value || color}
            onChange={onColorChange}
            colors = {colors}
        />}
        title={title}
        placement={placement}
        trigger="click">
        <Avatar style={{backgroundColor: color}} />
    </Popover>

}


export default ColorPicker;
