import React, { Component } from 'react';
import { Button, Col, Row, DatePicker, message, Typography, List, Card, Pagination, Popover, Form, Select, Checkbox, Avatar, Space, Tag, Modal, Popconfirm } from 'antd'

import { Link } from 'react-router-dom';
//componentes
import { IconEdit, IconDelete, IconPlus, IconEye } from '../../../Widgets/Iconos';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';



import { DownOutlined } from '@ant-design/icons';


//Modales
import ModalProductos from './ModalProductos';

const axios = require('axios').default;
const { Text, Title } = Typography;

const { RangePicker } = DatePicker;
const { Option } = Select;

const IconText = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
);


export default class Productos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ModalProductosVisible: false,
            filtroSearch: "",

            dataMateriales: [],
            loading: true,
            accionModal: 'crear',
            registroId: '',
            registroNombre: '',
            registro: {},
            loading: false,

            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,

            resize: true,

            tipos: [],
            areas: [],
            tipo: {}
        }
    }


    //Referencia
    refModalProductos = React.createRef();

    componentDidMount() {
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.match.params.id)
            this.state.tipo_id = this.props.match.params.id


        this.receivedData();
        this.getTipos()
        this.getAreas()
        window.addEventListener('resize', this.windowsResize);
        this.windowsResize()

    }


    componentDidUpdate() {
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro) {
            this.receivedData();
        }
    }



    getAreas = () => {
        axios.get('/locaciones/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                areas: response.data.data
            })
        }).catch((error) => {

        })
    }


    getTipos = (search) => {
        axios.get('/tiposArt/list', {
            params: {
                search
            }
        }).then(response => {
            this.setState({ tipos: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los areas')
        })
    }




    windowsResize = () => {
        if (window.innerWidth < 576 && this.state.resize == true) {
            this.setState({ resize: false })
        } else if (window.innerWidth > 576 && this.state.resize == false) {
        }
    }


    receivedData = async (
        {
            page = this.state.currentPage,
            size = this.state.perPage,
            tipo_id = this.state.tipo_id,
            tipos_ids = this.state.tipos_ids,
            areas_ids = this.state.areas_ids,

        } = {}
    ) => {

        this.setState({ loading: true, tipos_ids })
        var numPage = (page === undefined) ? 1 : page, numReg = 0;
        var search = this.props.search;

        this.setState({
            filtroSearch: search
        })

        axios.get('/articulos/list', {
            params: {
                page: page,
                limit: size,
                search: search,
                tipo_id,
                tipos_ids,
                areas_ids
            }
        })
            .then(res => {

                const data = res.data.data.itemsList;
                var dataL = data, data_list = [];

                if (numPage === null || numPage === 1) { numReg = 0 }
                else { numReg = ((numPage - 1) * 10) }

                for (let index = 0; index < dataL.length; index++) {
                    const element = dataL[index];

                    numReg = numReg + 1;

                    data_list.push({
                        _id: element._id,
                        nombre: element.nombre,
                        unidad: element.unidad,
                        detalles: element.detalles,
                        existencias: element.existencia,

                        imagen: element.imagen,
                        tipo_articulo_id: element.tipo_articulo_id,
                        estatus: element.estatus,
                        index: numReg
                    });
                }
                var pag = res.data.data;

                this.setState({
                    dataMateriales: data_list,

                    loading: false,
                    currentPage: pag.currentPage,
                    itemCount: pag.itemCount,
                    perPage: pag.perPage,
                    pageCount: pag.pageCount,
                    filtroSearch: search
                })

            })
            .catch(res => {
                console.log("error", res);
            });
    }

    hideModal = () => {
        this.setState({
            accionModal: "",
            registroId: '',
            registro: {},
            registroNombre: '',
            ModalProductosVisible: false
        });
        this.receivedData();
    }


    modalCrear = () => {
        this.setState({
            registroId: null,
            ModalProductosVisible: true,

        })
    }


    modalEditar = (id, item) => {

        this.setState({
            registroId: id,
            ModalProductosVisible: true,
        })
    }


    deleteMaterial = (id) => {

        axios.post('/articulos/delete', { params: { id } })
            .then((res) => {
                this.receivedData()
                message.success("¡Se eliminó Material!");
            })

            .catch(error => {
                Modal.error({
                    title: "No es posible eliminar",
                    content: error.response?.data?.error
                })

            });
    }


    getTipo = () => {
        axios.get('/tipos/get', {
            params: {
                id: this.state.tipo_id
            }
        })
            .then(({ data }) => {
                this.setState({ tipo: data.data })
            })

    }

    render() {
        return (
            <div>
                <Form
                    onValuesChange={(valuesChanged, allValues) => {
                        this.receivedData({ tipos_ids: allValues.tipos_ids, areas_ids: allValues.areas_ids })
                        // tipos_ids
                    }}
                >
                    <Row className="row-encabezado">
                        <Col xs={24} xl={10} xxl={14} className="flex-left">
                            {/* <Title level={4} className="view-title">Lista de Materiales {(this.state.tipo.nombre) ? [" de tipo ", <strong style={{ textDecoration: "underline" }}>{this.state.tipo.nombre}</strong>] : null} </Title>  */}
                            <Title level={4} className="view-title">Productos </Title>

                        </Col>

                        <Col xs={6} xl={4} xxl={3}>
                            <Popover
                                content={
                                    <Form.Item name="tipos_ids" className="popover-filter">
                                        <Checkbox.Group>
                                            <Row>
                                                {this.state.tipos.map(({ _id, nombre }) => <Col span={24}>
                                                    <Checkbox value={_id} style={{ lineHeight: '32px' }}> {nombre} </Checkbox>
                                                </Col>)}
                                            </Row>
                                        </Checkbox.Group>

                                    </Form.Item>
                                }
                                placement="bottom"
                                title="Tipos"
                            >
                                <span className="filter-element"> Tipo<DownOutlined /></span>
                            </Popover>
                        </Col>


                        {/* <Col xs={6} xl={4} xxl={3}>
                            <Popover
                                content={
                                    <Form.Item name="areas_ids" className="popover-filter">
                                        <Checkbox.Group>
                                            <Row>
                                                {this.state.areas.map(({ _id, nombre }) => <Col span={24}>
                                                    <Checkbox value={_id} style={{ lineHeight: '32px' }}> {nombre} </Checkbox>
                                                </Col>)}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                }
                                placement="bottom"
                                title="Areas"
                            >
                                <span className="filter-element"> Area<DownOutlined /></span>
                            </Popover>
                        </Col> */}

                        <Col xs={12} xl={6} xxl={4}>
                            <RangePicker
                                className="input-picker"
                                style={{
                                    marginTop: '10px',
                                    background: 'transparent !important',
                                    color: '#FFF'
                                }} />
                        </Col>
                    </Row>
                </Form>



                <section className="p-1">

                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.dataMateriales}
                        header={<Row className="header-list" style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>

                            <Col span={2} className="gutter-row center">
                                <Text className="ml-2" strong>#</Text>
                            </Col>

                            <Col xs={4} className="gutter-row center">
                                <Text strong>Nombre</Text>
                            </Col>


                            <Col xs={4} className="gutter-row center">
                                <Text strong>Descripción</Text>
                            </Col>

                            <Col xs={4} className="gutter-row  center">
                                <Text strong>Existencias</Text>
                            </Col>

                            <Col xs={4} className="gutter-row center">
                                <Text strong>Tipo</Text>
                            </Col>

                            <Col xs={3} className="gutter-row center">
                                <Text strong>Estatus</Text>
                            </Col>

                            <Col xs={3} className="gutter-row center">
                                <Text strong>ACCIONES</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={item => (
                            (this.state.resize === true) ?
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row style={{ width: '100%' }} className="">

                                            <Col span={2} className="center">
                                                <Text strong>{item.index}</Text>
                                            </Col>

                                            <Col xs={4} className="center">

                                                {(item.imagen !== undefined) ?
                                                    <Avatar size="large" src={`${axios.defaults.baseURL}/upload/${item.imagen}`} style={{ marginRight: "5px" }} />
                                                    : null
                                                }
                                                <Text strong>{item.nombre}</Text>
                                            </Col>

                                            <Col xs={4} className="center">
                                                <Text strong>{item.detalles}</Text>
                                            </Col>

                                            <Col xs={4} className="center">

                                                <Text strong>{(item.existencias) ? (item.existencias).toMoney() : 0} {" " + item.unidad} </Text>
                                            </Col>

                                            <Col xs={4} className="center">
                                                <Text strong>{
                                                    (item.tipo_articulo_id !== undefined) ?
                                                        item.tipo_articulo_id.nombre : "N/A"
                                                }</Text>
                                            </Col>

                                            <Col xs={3} className="center">
                                                <Text strong>{(item.estatus === 1) ? "Disponible" : "No disponible"}</Text>
                                            </Col>

                                            <Col xs={3} className="center">

                                                <Link to={"/admin/inventario/productos/detalle/" + item._id}>
                                                    <Button className="btn btn-eye" title="Editar">
                                                        <IconEye />
                                                    </Button>
                                                </Link>

                                                <Button className="btn btn-edit" title="Editar" onClick={(e) => this.modalEditar(item._id, item)}>
                                                    <IconEdit />
                                                </Button>


                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este producto?"
                                                    onConfirm={(e) => this.deleteMaterial(item._id, item)}
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="primary"
                                                        className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                        title="Eliminar">
                                                        <IconDelete />
                                                    </Button>
                                                </Popconfirm>



                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                                :
                                <Card className="card-list mb-1">
                                    <List.Item className="component-list-item"
                                        key={item._id}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar size="large" src={`${axios.defaults.baseURL}/upload/${item.imagen}`} />}
                                            title={item.nombre}
                                            description={item.detalles}
                                        />
                                        <Button className="btn btn-edit" title="Editar" >
                                            <IconEdit />
                                        </Button>
                                    </List.Item>
                                    <p style={{ textAlign: 'start' }}>
                                        <Text><strong>Unidad:</strong> {(item.unidad === 0) ? "Piezas" : "Toneladas"}</Text>
                                        <br />
                                        <Text><strong>Tipo:</strong> {item.tipo_id?.nombre}</Text>
                                        <Text><Tag style={{ fontSize: 14, position: 'absolute', right: 0 }}> {item?.existencias} {(item?.unidad === 0) ? "piezas." : "tons"}</Tag></Text>
                                    </p>
                                </Card>
                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.currentPage}
                            total={this.state.itemCount}
                            pageSize={this.state.perPage}
                            showSizeChanger={false}
                            onChange={(page) => {
                                this.receivedData({ page })
                            }}
                        />
                    </Row>

                </section>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear}>
                    <IconPlus />
                </Button>


                <ModalProductos
                    visible={this.state.ModalProductosVisible}
                    materialId={this.state.registroId}
                    tipoId={this.state.tipo_id}

                    update={() => { this.receivedData(1); }}
                    onClose={() => {
                        this.receivedData(1);
                        this.hideModal();
                    }}
                    onSuccess={() => {
                        this.receivedData(1);
                        this.hideModal();
                    }}
                />

            </div>
        )
    }
}
