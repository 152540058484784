import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, message, Pagination, Popconfirm} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconEye, IconPlus,IconEdit, IconDelete, IconArrowBack} from '../../Widgets/Iconos';
//modal
import ModalProformaConcepto from '../Modales/Presupuesto/ModalProformaConcepto';
//css
import '../../../Styles/presupuesto.css'

const { Title, Text } = Typography;
const axios = require('axios').default

export default class PresupuestoProformaConcepto extends Component{

	constructor(props){
		super(props)
		this.state = {
			conceptos:[],
			proforma_id: this.props.match.params.proforma_id,
			concepto: {},

			/* Paginado */
			page: 1,
			total: 0,
			pageCount: 10,

		}
	}
	
	volver = () => {
        window.history.back();
    }

	/**
	* @memberof PresupuestoProformaConcepto
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.listConceptos();
	}

	/**
	 * @methodOf  PresupuestoProformaConcepto
	 * @function hideModal
	 *
	 * @description Cierra el modal
	 *
	 * */
	hideModal = () => {
		this.setState({modalProformaConceptoVisible: false, concepto: {} });
		this.listConceptos(this.state.page);
		
	};

	/**
	 * @methodOf  PresupuestoProformaConcepto
	 * @function listConceptos
	 *
	 * @description Cierra el modal
	 *
	 * */
	listConceptos = (page=1) => {
		this.setState({loading: true})
		axios.get('/proforma/conceptos/list',{
			params:{
				page: page,
				proforma_id: this.state.proforma_id
			}
		}).then(response => {
			this.setState({
				conceptos : response.data.data.itemsList,
				total: response.data.data.itemCount,
				page: response.data.data.currentpage,
				loading: false,
			})
		}).catch(error => {
			console.log(error)
			message.error('No se cargaron los conpectos')
		})
	};

	/**
	 * @methodOf  PresupuestoProformaConcepto
	 * @function deleteConcepto
	 *
	 * @description Cierra el modal
	 *
	 * */
	deleteConcepto = (proforma_concepto_id) => {
		this.setState({loading: true})
		axios.post('/proforma/conceptos/delete',{
			proforma_id: this.state.proforma_id,
			proforma_concepto_id: proforma_concepto_id
		}).then(response => {
			this.listConceptos(this.state.page)
			message.success('¡Concepto eliminado Exitosamente!')
		}).catch(error => {
			console.log(error)
			message.error('No se elimino el conpecto')
		})
	};
	render() {
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} className="">
						<Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
                            <IconArrowBack />
                        </Button>
						<h2 style={{width: 'auto', float: 'left'}}>Presupuesto Proforma</h2>
					</Col>
				</Row>
				<section className="p-1">
					<div className="scroll">
						<Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
							<Col span={7} className="center">
								<Text type="secondary">NOMBRE</Text>
							</Col>
							<Col span={7} className="center">
								<Text type="secondary">MANO DE OBRA</Text>
							</Col>
							<Col span={7} className="center">
								<Text type="secondary">MATERIAL</Text>
							</Col>
							<Col span={3} className="center">
								<Text type="secondary">ACCIONES</Text>
							</Col>
						</Row>

						<List
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.conceptos}
							loading={this.state.loading}
							locale={{ emptyText: 'No hay ningún concepto ' }}
							renderItem={item =>
								(<List.Item className="component-list-item">
									<Card className="card-list">
										<Row style={{ width: '100%' }} className="">
											<Col span={7} className="center">
												<Text strong>{item.conceptos?.nombre}</Text>
											</Col>
											<Col span={7} className="center">
												<Link to={`/admin/presupuesto/proforma/${this.state.proforma_id}/concepto/${item.conceptos._id}/mano-obra`}>	
													$ {item.conceptos?.monto_bitacora.toMoney(2)} MXN
												</Link>
											</Col>
											<Col span={7} className="center">
												<Link to={`/admin/presupuesto/proforma/${this.state.proforma_id}/concepto/${item.conceptos._id}/material`}>
													$ {item.conceptos?.monto_material.toMoney(2)} MXN
												</Link>
											</Col>
											<Col span={3} className="center">
												<Button className="btn btn-edit" 
													onClick={(e) => { this.setState({modalProformaConceptoVisible: true, modalTitle: 'Editar', concepto: item.conceptos}) }}>
													<IconEdit />
												</Button>
												<Popconfirm
													placement="topRight"
													title={"¿Deseas eliminar el concepto " + item.conceptos?.nombre + " ?"}
													onCancel={e => e.stopPropagation()}
													okText="Si"
													cancelText="No"
													onClick={e => e.stopPropagation()}
													onConfirm={e => {this.deleteConcepto(item.conceptos?._id)}}
												>
													<Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
														<IconDelete />
													</Button>
												</Popconfirm>

											</Col>
										</Row>
									</Card>
								</List.Item>
								)}
						/>
					</div>
					<Row className="mt-2 pb-3">
						<Pagination
							total={this.state.total}
							current={this.state.page}
							onChange={(page) => {
									this.listConceptos(page)
								}}
							/>
						</Row>
				</section>
				<Button
					className="btnAddPlus"
					onClick={() => this.setState({modalProformaConceptoVisible: true, modalTitle: 'Crear'})}>
					<IconPlus/>
				</Button>
				<ModalProformaConcepto
					modalVisible={this.state.modalProformaConceptoVisible}
					modalTitle={this.state.modalTitle}
                    closeMethod={this.hideModal}
                    proforma_id={this.state.proforma_id}
                    concepto={this.state.concepto}
				/>
			</div>
		)
	}
}