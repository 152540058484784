import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Spin, Form, Select, message, InputNumber  } from 'antd';
//componentes
import { IconCloseModal  } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalProspectosEstatus extends Component {

	formEstatus = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		}
	}

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
	}

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	hideModal = () => {
		this.props.closeMethod()
		if(this.props.refresh) this.props.refresh()
		this.setState({loading: false})
	}	

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	onFinish = (values) => {
		this.setState({loading: true})
		if(this.props.objetivo === 'monto_material'){
			this.updateMontoMaterial(values)
		}else{
			this.updateMontoActividad(values)
		}
	}

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   updateMontoActividad
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	updateMontoActividad = (values) => {
		axios.post('/presupuesto/update/monto',{
			inmueble_trabajo_id: this.props.inmueble_trabajo_id,
			tajo_id: this.props.tajo_id,
			monto_extra: values.monto
		}).then(response => {
			message.success('¡Monto Actualizadao con Éxito!')
			this.hideModal();
		}).catch(error => {
			console.log(error);
			this.setState({loading: false})
			message.error('No se actualizó el Monto')
		})
	}

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   updateMontoMaterial
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	updateMontoMaterial = (values) => {
		axios.post('/presupuesto/update/monto/material',{
			inmueble_id: this.props.inmueble_id,
			actividad_id: this.props.actividad_id,
			material_requerido_id: this.props.material_requerido_id,
			material_entrega_id: this.props.material_entrega_id,
			monto: values.monto
		}).then(response => {
			message.success('¡Monto Actualizadao con Exito!')
			this.hideModal();
		}).catch(error => {
			console.log(error);
			this.setState({loading: false})
			message.error('No se actualizó el Monto')
		})
	}




	render() {
		return (
			<Modal
				visible={this.props.modalVisible}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				closable={false}
				className="modal-form"
				destroyOnClose={true}
				zIndex={1000}
				width={750}
			>

				<div className="modal-header">
					<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
						<IconCloseModal />
					</Button>
					<Title level={3} className="modal-title">{this.props.modalTitle} Monto</Title>
				</div>

				<Spin spinning={this.state.loading}>
					<Form
						layout="vertical"
						name="formulario-transacciones"
						onFinish={this.onFinish}
						initialValues={this.props.monto}
					>
						<Row align="center">
							<Col xs={20} md={13}>
								<Form.Item
									label="Monto"
									name="monto"//----------------------------------------------------------monto
									rules={[{
										required: true,
										message: "Por favor, ingrese un Monto"
									}]}
								>
									<InputNumber min={0}/>
								</Form.Item>
							</Col>
							<Col span={20} className="center">
								<Button htmlType="submit" type="primary" className="btn-azul mt-3">
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		)
	}
}
