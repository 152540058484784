import React, { Component, useContext } from 'react';
import axios from 'axios';
import ifIsImage from 'if-is-image';
import SocketIOFileClient from 'socketio-file-upload';
import { FaFilePdf, FaImage, FaFileAlt, FaTimes } from 'react-icons/fa'
import { Card, Avatar, Button, Col, Row, Comment, Input, Typography, Tooltip, Modal, Upload, Progress, Image } from 'antd'

//componentes
import Logged from '../../../../Hooks/Logged';
import MentionInput from '../../../Widgets/MentionInput';
import { SocketContext } from '../../../../Hooks/Socket';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconAttach, IconSend } from '../../../Widgets/Iconos'

//css
import '../../../../Styles/mensajes.css';


const moment = require("moment");
const { Title } = Typography;


function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}



/**
 *
 *
 * @class Mensajes
 * @extends {Component}
 * 
 * @description Componente de chat para hablar con parque esmeralda y vicerversa.
 */
class Mensajes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            usuarios: [],
            data: [],
            filesArray: [],
            from: undefined,
            limit: 20,
            page: 1,
            filesDictionary: {},

            text: null,
            mentions: []
        }
    }


    inputRef = React.createRef()

    static contextType = SocketContext;

    loading = false

    scrollToBottomOption = true

    /***
 *
 * @methodOf Mensajes
 * @function componentDidMount
 * 
 * @description Iniciamos el chat, si se agrega al usuario a la lista de sockets exitomsanete, se encadena los emits iniciales
 */
    componentDidMount() {
        document.getElementById("messages-body").addEventListener('scroll', this.handleScroll, true);

        this.context.emit("start", sessionStorage.getItem("token"))
        this.context.removeListener('successful');
        this.context.on('successful', this.IO_start)

        this.uploader = new SocketIOFileClient(this.context);
        this.uploader.addEventListener("start", this.startUpload);
        this.uploader.addEventListener("progress", this.progressUpload);
        this.uploader.addEventListener("complete", this.completeUpload);
    }

    /***
     *
     * @methodOf Mensajes
     * @function componentWillUnmount
     * 
     * @description Removemos el event listener del scroll
     */
    componentWillUnmount() {
        document.getElementById("messages-body").removeEventListener('scroll', this.handleScroll);
        this.context.emit("header", sessionStorage.getItem("token"))
    }


    /***
     *
     * @methodOf Mensajes
     * @function IO_start
     * 
     * @description Inicio del socket, obtebemos los mensajes, 
     * nos regresa el usuario logeado y lo ponemos el state
     */
    IO_start = ({ user_id }) => {
        const { page, limit } = this.state;

        this.setState({ from: user_id })

        //Eliminamos estos metodos para volverlos a declarar
        this.context.removeListener('get_messages');
        this.context.removeListener('mensaje_nuevo');

        //Se declaran de nuevo
        this.context.on('get_messages', this.IO_get_messages)
        this.context.on('mensaje_nuevo', this.IO_mensaje_nuevo)

        //Obetenemos los mensajes relacionado a la orden
        this.context.emit('get_messages', { page, limit, requisicion_id: this.props.requisicion_id })
    }

    /**
     *
     * @methodOf Mensajes
     * @function IO_get_messages
     * 
     * @description Obtenemos los mensajes completos y los desplegamos el chat 
     */
    IO_get_messages = ({ mensajes }) => {
        let mensajesList = this.state.data
        mensajesList.unshift(...mensajes.list)
        this.loading = false
        this.setState(state => {
            state.data = mensajesList;
            state.page = mensajes.page;
            state.limit = mensajes.limit;

            //Se hace scroll hasta abajo
            if (this.scrollToBottomOption === true) {
                setTimeout(this.scrollToBottom, 250)
            }

            return state;
        })
    }

    /**
     *
     * @methodOf Mensajes
     * @function IO_mensaje_nuevo
     * 
     * @description Cuando llegue un mensaje nuevo, se agrega a la lista de mensajes
     */
    IO_mensaje_nuevo = ({ mensaje }) => {
        //console.log("mensaje", mensaje);
        let mensajes = this.state.data
        mensajes.push(mensaje)
        this.setState({ data: mensajes })

        //Se hace scroll hasta abajo
        if (this.scrollToBottomOption === true) {
            setTimeout(this.scrollToBottom, 250)
        }
    }

    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar ENTER, enviamos el mensaje y limpiamos el input
     * 
     * */
    onKeyDownPress = (event) => {

        let { user } = this.props;

        if (event.key === 'Enter' && ((event.target.value && event.target.value !== null && event.target.value !== "") || this.state.filesArray.length > 0)) {
            this.context.emit('new_message', {
                message: event.target.value,
                requisicion_id: this.props.requisicion_id,
                files: this.state.filesArray.map(event => event.fileSend.name),
                mentions: this.state.mentions
            })
            this.setState({ filesArray: [], filesDictionary: {},value: '' })
            this.inputRef.current.clean()
        }
    }

    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al presionar el boton de enviar, enviamos el mensaje y limpiamos el input
     * 
     * */
    onPress = (event) => {
        let { user } = this.props;
        if ((event.target.value && event.target.value !== null && event.target.value !== "") || this.state.filesArray.length > 0) {
            this.context.emit('new_message', {
                message: event.target.value,
                requisicion_id: this.props.requisicion_id,
                files: this.state.filesArray.map(event => event.fileSend.name),
                mentions: this.state.mentions
            })
            this.setState({ filesArray: [], filesDictionary: {},value: '' })
            this.inputRef.current.clean()
        }
    }

    /**
     *
     * @methodOf Mensajes
     * @function handleScroll
     * 
     * @description Se ejecuta cuando se scrollea el chat hasta arriba, cargamos mas mensajes antiguos.
     * 
     * */
    handleScroll = (event) => {
        let { scrollTop, scrollHeight } = document.getElementById("messages-body");
        let { page, limit, search } = this.state;
        if (scrollTop === 0 && this.loading === false) {
            this.loading = true;
            this.scrollToBottomOption = false;
            page = page + 1
            this.context.emit('get_messages', { page, search, limit, requisicion_id: this.props.requisicion_id })

        }
    }

    /**
     *
     * @methodOf Mensajes
     * @function scrollToBottom
     * 
     * @description Scrolleamos el chat al final
     * 
     * */
    scrollToBottom = () => {
        this.scrollToBottomOption = true;
        const messages_body = document.getElementById("messages-body");
        if (messages_body !== null && messages_body !== undefined && messages_body !== null)
            messages_body.scrollTop = messages_body.scrollHeight;
    }


    progressUpload = (event) => {
        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = (parseInt(event.bytesLoaded) * 100) / parseInt(event.file.size);
        this.state.filesArray[index - 1].status = "progress";
        this.setState({ update: !this.state.update })
    }

    completeUpload = (event) => {
        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = 100;
        this.state.filesArray[index - 1].status = "complete";
        this.state.filesArray[index - 1].fileSend = event.file;
        this.setState({ update: !this.state.update })
    }

    startUpload = (event) => {

        let name = "";
        let fileSplit = event.file.name.split("_");
        for (let index = 0; index < (fileSplit.length - 1); index++) {
            name += ((index == 0) ? "" : '_') + fileSplit[index];
        }
        let extension = fileSplit[fileSplit.length - 1].split('.')[1];


        let index = this.state.filesArray.push({
            file: event.file,
            progress: 0,
            status: 'start',
            name,
            extension
        });

        this.state.filesDictionary[event.file.name] = index;
        this.setState({ update: !this.state.update })
    }

    uploadFile = ({ file }) => {
        let fileSplit = file.name.split(".");
        let rename = "";
        for (let index = 0; index < (fileSplit.length - 1); index++)
            rename += fileSplit[index]
        let fileName = rename + "_" + Math.random().toString(36).substring(2, 10) + "." + fileSplit[fileSplit.length - 1];
        this.uploader.submitFiles([renameFile(file, fileName)])
    }

    deleteFile = (file) => {
        let index = this.state.filesDictionary[file];
        this.state.filesArray.splice(index, 1);
        this.context.emit("delete_file", file)
        this.setState({ update: !this.state.update })
    }


    messagesContentReference = React.createRef();


    render() {

        let { user } = this.props;
        let { from } = this.state;

        return (
            <section className="">
                <Row className="witdh-100 m-0">
                    <Col xs={24} className="m-0">
                        <div className="cnt-Mensajes customer">
                            <Row>
                                <Col span={24}>
                                    <div className="cnt-Mensajes-body "
                                        ref={messageContentReference => { this.messageContentReference = messageContentReference; }}
                                        id="messages-body">

                                        <Row className="card-msj">
                                            <Card className="cnt-msj ">
                                                <Row>
                                                    {this.state.data.map((comment, ind) => {

                                                        const { owner, remitente_id, mensaje, fecha, primero, archivos, casa } = comment;

                                                        let autor = remitente_id.nombre + " " + remitente_id.apellido;

                                                        if(comment.actualizacion){
                                                            return <Row>
                                                                <Col span={4} style={{display: 'flex', justifyContent: 'center'}}>
                                                                    <CustomAvatar
                                                                        name={autor}
                                                                        image={remitente_id.avatar}
                                                                        size="large"
                                                                    />
                                                                </Col>
                                                                <Col span={20}>
                                                                    <Card className="card-update" size="small">
                                                                        <Row>
                                                                            <Col span={24}>
                                                                                {autor}
                                                                            </Col>
                                                                        </Row>
                                                                        {mensaje} el {moment(fecha).format('DD-MM-YYYY HH:mm')}
                                                                    </Card>
                                                                </Col>
                                                            </Row>

                                                        }

                                                        return <Comment
                                                            key={ind}
                                                            style={{ width: '100%' }}
                                                            className={(remitente_id._id === from) ? "owner" : null}
                                                            avatar={
                                                                <CustomAvatar
                                                                    name={autor}
                                                                    image={remitente_id.avatar}
                                                                />
                                                            }
                                                            content={<div>
                                                                {mensaje} <br></br>
                                                                {archivos.map(filename => {
                                                                    let name = "";
                                                                    let fileSplit = filename.split("_");
                                                                    for (let index = 0; index < (fileSplit.length - 1); index++) {
                                                                        name += ((index == 0) ? "" : '_') + fileSplit[index];
                                                                    }
                                                                    let extension = fileSplit[fileSplit.length - 1].split('.')[1];


                                                                    let extensionImage = null;
                                                                    if (ifIsImage(filename)) {
                                                                        extensionImage = <FaImage style={{ marginRight: '0.5em' }} />
                                                                    }
                                                                    else if (extension == "pdf" || extension == "PDF") {
                                                                        extensionImage = <FaFilePdf style={{ marginRight: '0.5em' }} />
                                                                    }
                                                                    else {
                                                                        extensionImage = <FaFileAlt style={{ marginRight: '0.5em' }} />
                                                                    }


                                                                    if (ifIsImage(`${name}.${extension}`))
                                                                        return <a className="archivito">
                                                                            <Image src={axios.defaults.baseURL + '/upload/' + filename} style={{ maxWidth: '100px', margin: '1em' }} />
                                                                        </a>
                                                                    else
                                                                        return <>
                                                                            <br />
                                                                            <a target="_blank" download={true} href={axios.defaults.baseURL + '/upload/' + filename} className="archivito">
                                                                                {extensionImage}{`${name}.${extension}`}
                                                                            </a>
                                                                        </>
                                                                })}
                                                            </div>}
                                                            datetime={
                                                                <Tooltip title={moment(fecha).format('YYYY-MM-DD HH:mm:ss')}>
                                                                    <span>{moment(fecha).fromNow()}</span>
                                                                </Tooltip>
                                                            }
                                                        />
                                                    })}
                                                </Row>
                                            </Card>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col span={24} className="m-0">
                                    <div className={this.state.filesArray.length > 0 ? "filesList" : null}>

                                        {this.state.filesArray.map(file => {

                                            let extensionImage = null;
                                            if (ifIsImage(file.file.name)) {
                                                extensionImage = <FaImage />
                                            }
                                            else if (file.extension == "pdf" || file.extension == "PDF") {
                                                extensionImage = <FaFilePdf />
                                            }
                                            else {
                                                extensionImage = <FaFileAlt />
                                            }

                                            let name = (file.name.length > 10) ? (file.name.slice(0, 10) + '... ') : file.name;
                                            return <div className="file-container">
                                                <div className="file">
                                                    {(file.status != "complete") ? <Progress percent={parseInt(file.progress)} size="small" style={{
                                                        position: 'absolute',
                                                        top: '36%',
                                                        left: '5px',
                                                        width: 'calc(100% - 10px)',
                                                    }} /> : extensionImage}
                                                    <Button onClick={() => this.deleteFile(file.file.name)} className="buttom-close"><FaTimes /></Button>
                                                </div>
                                                <span style={{
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    textAlign: ' center',
                                                    left: 0,
                                                    color: '#7b7373',
                                                    fontSize: 12

                                                }}>{name + '.' + file.extension}</span>
                                            </div>
                                        })}
                                    </div>
                                    <div className="cnt-Mensajes-opciones">
                                        
                                        <MentionInput
                                            ref={this.inputRef}
                                            updateText={({text, mentions})=>this.setState({value: text, mentions})}
                                            onKeyDownPress={this.onKeyDownPress}
                                        />
                                        {/*<Input
                                            ref={ref => this.inputRef = ref}
                                            onKeyDown={this.onKeyDownPress}
                                            
                                        />*/}

                                        <div className="cnt-msj-opciones">

                                            <Button title="Adjuntar archivo">
                                                <Upload
                                                    multiple={true}
                                                    showUploadList={false}
                                                    customRequest={this.uploadFile}

                                                >
                                                    <IconAttach />
                                                </Upload>
                                            </Button>
                                            <Button title="Enviar" onClick={() =>this.onPress({ target: { value: this.state.value } })}>
                                                <IconSend />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </section>
        );
    }
}

export default function (props) { return <Mensajes {...props} user={useContext(Logged)} /> }


