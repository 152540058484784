import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Upload, Typography } from 'antd';

import PropTypes from "prop-types";
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import ColorPicker from '../../Widgets/ColorPicker/ColorPicker';
import { IconCloseModal, IconEdit, IconDelete } from '../../Widgets/Iconos';
import '../../../Styles/modales.css'
const axios = require('axios').default;
const { Title, } = Typography;


/**
 *
 *
 * @export
 * @class ModalEmpresa
 * @extends {Component}
 */
class ModalEmpresa extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            form: {},
            loadingImage: false
        }
    }

    formModalEmpresa = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log('props', this.props);
        if (this.props.id !== this.state.id && this.props.id !== undefined) {
            this.getEmpresa()
        }
    }



    /**
     * @methodOf ModalEmpresas
     * @function getEmpresa
     *
     * @description Obtiene la Empresa
     *
     * */
    getEmpresa = () => axios.get("/empresa/" + this.props.id)
        .then(async ({ data }) => {
            console.log("Info empresa",data)
            this.formModalEmpresa.current.setFieldsValue({
                ...data,
                color: data.color,
                logo: (data.logo && data.logo !== null) ? [{
                    uid: -1,
                    name: data.logo,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + data.logo,
                    response: { filename: data.logo }
                }] : []
            })
            if (data.logo) this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + data.logo,
                    name: data.logo
                }
            })
        })
        .catch(res => {
            message.error('No se pudo cargar la Empresa')
            console.log("no obtenida", res);
        })


    /**
     * @methodOf ModalEmpresas
     * @function saveEmpresa
     *
     * @description Guarda la Empresa
     *
     * */
    saveEmpresa = values => {
        if (this.state.image)
            values.logo = this.state.image.name;
        else
            values.logo = null;

        let savePromise = (values._id) ? axios.put("/empresa", { ...values, id: values._id }) : axios.post("/empresa", values);

        savePromise
            .then(() => {
                message.success("¡Se ha guardado correctamente la Empresa!")
                this.props.accept();
            })
            .catch((e) => {
                message.error('No se pudo guardar la Empresa')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }



    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            if (this.state.image)
                this.removeFile(this.state.image.name)

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })
            if (this.state.form._id)
                axios.put('/empresa', { logo: e.file.response.filename, id: this.state.form._id })
                    .finally(() => {
                        this.props.update()
                    });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !== undefined) ? file.response.filename : file.name)


        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalEmpresas
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        let values = this.formModalEmpresa.current.getFieldsValue();
        axios.put("/empresa", { logo: null, id: values._id })
            // axios.put('/empresa', { logo: 0, id: this.state.form._id })
            .finally(() => {
                this.setState({ loadingImage: false, image: null })
                this.formModalEmpresa.current.setFieldsValue({ logo: [] })
            })
        // this.state.form.avatar = [];
        // this.state.image = undefined;
        // if (this.state.form._id)

        // else {
        //     this.props.update()
        //     this.setState({ loadingImage: false })
        // }
    };

    render() {
        const { normFile, formModalEmpresa, saveEmpresa, deleteImage } = this;
        const { form, image, loading, loadingImage } = this.state;

        return (
            <Spin spinning={loading}>
                <Form id="form-empresa" layout="vertical" ref={formModalEmpresa} initialValues={form} onFinish={saveEmpresa} >
                    <Form.Item name="_id" noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                    <Row align="center">
                        <Form.Item
                            name="logo"
                            align="center"
                            getValueFromEvent={normFile}
                            valuePropName="fileList"
                            help={image ? <Button className="btn-upload-delete" shape="circle" danger onClick={deleteImage}><DeleteOutlined /></Button> : null}
                        >
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={axios.defaults.baseURL + "/upload/add"}
                                accept="image/*"
                            >
                                {(loadingImage) ? <div>
                                    {(this.state.loading || loadingImage) ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div className="ant-upload-text">Upload</div>
                                </div> : ((image) ?
                                    <img src={image.url} alt="avatar" style={{ width: '100%' }} />
                                    :
                                    <div>
                                        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div className="ant-upload-text">Upload</div>
                                    </div>)}
                            </Upload>
                        </Form.Item>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} >
                            <Form.Item label="Alias de la Empresa" name="alias" rules={[{ required: true, message: "La empresa debe tener un Alias" }]}>
                                <Input placeholder="I See You Tech" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Razón Social" name="razon_social"  >
                                <Input placeholder="Empresa"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="RFC" name="rfc"
                                rules={[
                                    { pattern: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, message: "El RFC es inválido" }
                                ]}
                            >
                                <Input placeholder="RFC" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item label="Descripción" name="descripcion" >
                                <Input.TextArea placeholder="Descripción" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item label="Domicilio Fiscal de la Empresa" name="domicilio" rules={[{ required: true, message: "La empresa debe tener un domicilio fiscal" }]}>
                                <Input  />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item label="Color" name="color" rules={[{ required: true, message: "La empresa debe tener un color" }]}>
                                <ColorPicker onChange={console.log}
                                    colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                            </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalNegocios
 *@description 
 */
export default function Modals(props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.hideModal}
            title={null}
            className="modal-form modal-proveedor"
            footer={null}
            closable={false}
            destroyOnClose={true}
            zIndex={1000}
        >
            <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={props.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{props?.id ? "Editar Empresa" : "Nueva Empresa"}</Title>
                </div>
            <ModalEmpresa {...props} />
        </Modal>
    )
}
