import React, { Component } from 'react';
import { Row, Col, Typography, Table, Button, Popconfirm, message } from 'antd'
import { Link } from 'react-router-dom';

//componentes
import { IconPlus, IconEdit, IconDelete, IconDetails } from '../../Widgets/Iconos';
import Logged from "../../../Hooks/Logged";
//modales
import ModalAreas from '../Modales/Areas/ModalAreas';

const { Title } = Typography;
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

export default class Tajos extends Component {
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            dataAreas: [],
            loading: true,

            accionModal: 'crear',
            registroId: '',
            modalVisible: false,
            tipo_user: 0,

            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            filtroSearch: ""

        }
    }

    ModalAreasRef = React.createRef();

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Obtenemos el listado de Areas
        this.receivedData();
    }
    componentDidUpdate() {

        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;
       
        if (filtro !== propFiltro) {
            this.receivedData();
        }


        if (this.context !==0 && this.state.tipo_user === 0)
            this.setState({
                tipo_user: this.context.tipo
            })
    }

    /**
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }


    /**
     * @description Abrir modal tipo edición para registro seleccionado desde tabla.
     * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
     */
    modalEditar = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;

        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisible: true,
        })
    }


    /**
     * @description Cerrar modal y limpiar valor registroId
     */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false,
        })
    }

    /**
        * @method receivedData
        * @description Obtiene la informacion y actualiza los valores locales de la lista.
        */
    receivedData = async (
        page = this.state.currentPage,
        quantity = this.state.perPage,
    ) => {

        var numPage = (page === undefined) ? 1 : page, numReg = 0;
        var search = this.props.search;

        axios.get('/areas/list', {
            params: {
                page: page,
                limit: quantity,
                search: search

            }
        })
            .then(res => {
                const data = res.data.data.itemsList
                var dataL = data, data_list = [];

                if (numPage === null || numPage === 1) { numReg = 0 }
                else { numReg = ((numPage - 1) * 10) }


                for (let index = 0; index < dataL.length; index++) {
                    const element = dataL[index];
                    numReg = numReg + 1;

                    data_list.push({
                        _id: element._id,
                        nombre: element.nombre,
                        index: numReg
                    });
                }

                this.setState({
                    dataAreas: data_list,
                    loading: false,
                    currentPage: page,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: quantity,
                    pageCount: res.data.data.paginator.pageCount,
                    filtroSearch: search

                })

            })
            .catch(res => {
                console.log("error", res);
            });
    }

    /**
     * @method eliminarArea
     * @description Elimina area
     */
    eliminarArea = e => {
        axios.post('/areas/delete', {
            id: e
        })
            .then((response) => {
                message.success("Eliminado correctamente.");
                this.receivedData();
            })
            .catch((error) => {
                message.error(error.response.data.message)

            })
    }

    render() {
        const user = this.context;
        const columns = [
            {
                title: 'Index',
                dataIndex: 'index',
                key: 'index',

            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                //key: 'nombre',
                render: (text, record) => (
                    <h3>{record.nombre}</h3>
                ),
            },
            {
                title: 'Acciones',
                key: 'actions',
                width: 180,
                render: (text, record) => (
                    <div className="tbl-opciones">

                        <Link title="Editar" to={`/admin/areas/rubros/${record._id}`}>
                            <Button type="" className="detTable-icon" name={record.nombre} id={record._id}
                                    title="Ver rubros">
                                <IconDetails/>
                            </Button>
                        </Link>
                        {(this.state.tipo_user !==3) ? <div>  <Button type="primary" className="button-survey btn-edit button-edit" name={record.nombre} id={record._id} onClick={this.modalEditar} title="Editar"  >
                            <IconEdit />
                        </Button>

                            <Popconfirm placement="topRight" title="¿Eliminar esta área?" okText="Si, seguro" cancelText="No" name={record.nombre} id={record._id} onConfirm={() => { this.eliminarArea(record._id) }} >
                                <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" name={record.nombre} title="Eliminar"  >
                                    <IconDelete />
                                </Button>
                            </Popconfirm>
                        </div> : null
                        }

                    </div>
                ),
            },
        ];

        return (
            <div>
                <Row className="row-encabezado">

                   <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Title level={4}className="view-title">Tajos</Title>
                    </Col>

                    <Col xs={12} xl={4} xxl={4}>
                        <Row align="right" style={{ textAlign: "right", display: "block" }}>
                           <Title level={5} style={{ textAlign: "right", color: "#FFF", marginRight: '15px', marginTop: '7px', fontSize: '11pt' }}> {this.state.itemCount}  Tajos</Title>
                        </Row>
                    </Col>
                </Row>


                <Row className="p-1" >
                    <div className="div_contenido">
                        <Table
                            columns={columns}
                            dataSource={this.state.dataAreas}
                            scroll={{ x: 800, y: 570 }}
                            locale={{emptyText:'Sin Tajos Disponibles'}}
                            pagination={{
                                current: this.state.currentPage,
                                pageSize: this.state.perPage,
                                total: this.state.itemCount,
                                showSizeChanger: true,
                                position: ['bottomLeft'],
                            }}
                            onChange={({ current, pageSize, total }, b, sort) => {
                                this.receivedData(current, pageSize)
                            }}
                        />
                    </div>
                </Row>


                <ModalAreas
                    ref={this.ModalAreasRef}
                    modalVisible={this.state.modalVisible}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                >
                </ModalAreas>
                {(this.state.tipo_user !==3) ?
                    <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                        <IconPlus />
                    </Button> : null}

            </div>
        )
    }
}
