import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Divider, Spin } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar'
import PrioridadSelect from '../../../Widgets/prioridadSelect'
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;

let unidades = {
    'H87' : 'Pieza',
    'EA': 'Elemento',
    'KGM': 'Kilogramo',
    'MTR' : 'Metro',
    'KT': 'Kit',
    'SET' : 'Conjunto',
    'LTR' : 'Litro',
    'XBX' : 'Caja',
    'xun' : 'Unidad',
    'GRM' : 'Gramo',
    'BUT': 'Bulto',
    'TON': 'Tonelada',
}

/**
 *
 *
 * @class ModalRequisiciones
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalRequisiciones extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            productos: [],
            proyectos: [],
            usuarios: [],
            propiedades: [],
            producto: {},
            proyecto_id: undefined,
        }
    }

    modalMateriales = React.createRef()

    /**
     *
     * @methodOf ModalRequisiciones
     * @function componentDidMount
     * @description Obtiene la informacion inicial
     */
    componentDidMount(){
        this.getProductos()
        this.getProyectos()
        this.getUsuario()
        this.getPropiedades()

        if(this.props.requisicion_id){
            this.getRequisicion()
        }

    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function getProductos
     * @description Obtiene un listado de productos
     */
    getProductos = (search) => {
        axios.get('/productos',{
            params:{
                productos_general: true,
                search
            }
        }).then(response => {
            this.setState({productos: response.data.data.data})

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los productos')

        })
    }

    /**
     * @memberof ModalRequisiciones
     * @function getProyectos
     *
     * @description Obtener proyectos.
     */
    getProyectos = (search) => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
                limit: 20,
                inventarios: true, //proyectos con inventario
                search,
            }
        }).then((proyectos) => {
            this.setState({proyectos: proyectos.data.data})
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }


    /**
     * @memberof ModalRequisiciones
     * @function getUsuario
     *
     * @description Obtiene una lista de usuarios.
     */
    getUsuario = (search) => {
        axios.get("/usuarios", {
            params:{
                search
            }
        })
        .then(({ data }) => {
            this.setState({
                usuarios: data.data.itemsList
            })
            
        })
        .catch(e => console.log('er', e))
    }

    /**
     * @memberof ModalRequisiciones
     * @function getPropiedades
     *
     * @description Obtiene Propiedades de los clientes.
     */
    getPropiedades = (search) => {
        axios.get('/tags', {
            params: {
                pagination: false,
                type: "inmuebles", //tipo: 2
                search: search,
            }
        })
            .then(res => {
                this.setState({ propiedades: res.data.data })
            })
            .catch(e =>
                console.log('error inmuebles get', e)
            )
    }


    /**
     *
     * @methodOf ModalRequisiciones
     * @function getRequisicion
     * @description Obtiene la informacion de una requisicion
     */
    getRequisicion = () => {
        this.setState({loading: true})
        axios.get('/requisiciones/get',{
            params:{
                requisicion_id: this.props.requisicion_id
            }
        }).then(response => {
            console.log("response.data.data", response.data.data);
            let requisicion = response.data.data

            this.setState({producto: requisicion.producto_id})

            this.modalMateriales.current.setFieldsValue({
                producto_id:{
                    key: requisicion?.producto_id?._id,
                    value: requisicion?.producto_id?._id,
                    label: <>
                        <span>{requisicion?.producto_id?.nombre}</span><br/> 
                        <small>{requisicion?.producto_id?.sku_manantial}</small>
                    </>
                },
                proyecto_id: requisicion?.proyecto_id?._id,
                volumen: requisicion.volumen,
                contratista_id:{
                    key: requisicion?.contratista_id?._id,
                    value: requisicion?.contratista_id?._id,
                    label: <>{requisicion?.contratista_id?.nombre} {requisicion?.contratista_id?.apellido}</>
                },
                propiedades: requisicion.propiedades.length > 0 ? requisicion.propiedades.map(e => e._id) : [],
                prioridad: requisicion.prioridad
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los datos')
        }).finally(()=>this.setState({loading: false}))

    }


    /**
     *
     * @methodOf ModalRequisiciones
     * @function onFinish
     * @description Se ejecuta al terminar al hacer submit al formulario
     */
    onFinish = (values) => {

        values.producto_id = values.producto_id.value
        values.contratista_id = values.contratista_id.value

        if(this.props.requisicion_id){
            this.updateRequisicion(values)
        }else{
            this.addRequisicion(values)
        }
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function addRequisicion
     * @description Añade una nueva requisiscion
     */
    addRequisicion = (values) => {
        this.setState({loading: true})
        axios.post('/requisiciones/add',{
            ...values
        }).then(response => {
            message.success('¡Requisicion creada!')
            this.props.onCancel()
        }).catch(error => {
            console.log(error)
            if(error.response.status === 403){
                message.error(error.response.data.message)
            }else{
                message.error('Error al crear la requesicion')
            }
        }).finally(()=>{
            this.setState({loading: false})
        })
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function updateRequisicion
     * @description Añade una nueva requisiscion
     */
    updateRequisicion = (values) => {
        this.setState({loading: true})
        axios.post('/requisiciones/update',{
            ...values,
            requisicion_id: this.props.requisicion_id
        }).then(response => {
            message.success('¡Requisicion actualizada!')
            this.props.onCancel()
        }).catch(error => {
            console.log(error)
            if(error.response.status === 403){
                message.error(error.response.data.message)
            }else{
                message.error('Error al actualizar la requesicion')
            }
        }).finally(()=>{
            this.setState({loading: false})
        })
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function selectProducto
     * @description pone en el state el producto seleccionado
     */
    selectProducto = ({value}) => {
        let index = this.state.productos.findIndex(p => p._id.toString() === value)

        if(index != -1){
            this.setState({producto: this.state.productos[index]})
        }
    }
   


    render() {


        return [

            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title">{this.props.requisicion_id ? 'Editar' : 'Nueva'} Requisición</Title>
            </div>,
            <Spin spinning={this.state.loading}>


                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.modalMateriales}
                    onFinish={this.onFinish}

                >
                    <Row align="center" gutter={[16]}>

                        <Col span={22}>
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Producto."
                                }]}
                            >
                                <Select
                                    className="form-select"
                                    onSearch={(search) => this.getProductos(search)}
                                    onSelect={(producto)=>this.selectProducto(producto)}
                                    showSearch
                                    filterOption={false}
                                    labelInValue={true}
                                    disabled={this.props.requisicion_id}
                                >
                                    {this.state.productos.map(producto => <Option value={producto._id}>
                                        <span>{producto.nombre}</span><br/> 
                                        <small>{producto.sku_manantial}</small>
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Proyecto."
                                }]}
                            >
                                <Select
                                    className="form-select"
                                    onSearch={(search) => this.getProyectos(search)}
                                    showSearch
                                    filterOption={false}
                                >
                                    {this.state.proyectos.map((proyecto) =>  <Option value={proyecto._id}>
                                        <CustomAvatar
                                            image={proyecto.logo}
                                            name={proyecto.nombre}
                                            color={proyecto.color}
                                            size="small"
                                            style={{
                                                marginRight: '5px'
                                            }}
                                        />
                                        {proyecto.nombre}
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="" span={7}>
                            <Form.Item
                                label="Volumen"
                                name="volumen"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el volumen."
                                }]}
                            >
                                <InputNumber className="w-100"/>
                            </Form.Item>
                        </Col>
                        <Col className="center pt-1" span={4}>
                            <Text strong>{this.state.producto.unidad ? unidades[this.state.producto.unidad] : '-'}</Text>
                        </Col>
                        <Col span={22}>
                            <Form.Item
                                label="Contratista"
                                name="contratista_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Contratista."
                                }]}
                            >
                                <Select
                                    className="form-select"
                                    onSearch={(search) => this.getUsuario(search)}
                                    showSearch
                                    filterOption={false}
                                    labelInValue={true}
                                >
                                    {this.state.usuarios.map((usuario) =>  <Option value={usuario._id}>
                                        {usuario.nombre} {usuario.apellido}
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item
                                label="Inmueble / SubProyecto"
                                name="propiedades"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Inmueble."
                                }]}
                            >
                                <Select
                                    mode="multiple"
                                    className="form-select"
                                    onSearch={(search) => this.getPropiedades(search)}
                                    showSearch
                                    filterOption={false}
                                >
                                    {this.state.propiedades.map((propiedad) =>  <Option value={propiedad._id}>
                                        {propiedad.nombre}
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row align="center" gutter={[16]}>

                       
                        <Col span={22}>
                            <Form.Item
                                label="Prioridad"
                                name="prioridad"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la prioridad."
                                }]}
                            >
                                <PrioridadSelect/>
                            </Form.Item>
                        </Col>

                    </Row>  

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}



export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalRequisiciones {...props} />
    </Modal>


}
