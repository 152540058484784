import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Tag, Spin, Divider } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
//componentes
import { IconFlag, IconPDFColor } from '../../../Widgets/Iconos';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import PrioridadSelect from '../../../Widgets/prioridadSelect'
import Chat from './ChatRequisiciones'
//css
import './../../../../Styles/modales.css'

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment')


let unidades = {
    'H87': 'Pieza',
    'EA': 'Elemento',
    'KGM': 'Kilogramo',
    'MTR': 'Metro',
    'KT': 'Kit',
    'SET': 'Conjunto',
    'LTR': 'Litro',
    'XBX': 'Caja',
    'xun': 'Unidad',
    'GRM': 'Gramo',
    'BUT': 'Bulto',
    'TON': 'Tonelada',
}

let estatus = {
    0: 'Requerido',
    1: 'Cotizado',
    2: 'Pausado',
    3: 'Cancelado',
    4: 'Entrega',
    5: 'Recoleccion',
    6: 'Entregado',
}

let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}


/**
 * @class ModalRequisiciones
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalRequisiciones extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            requisicion: {
                proyecto_id: {},
                propiedades: [],
            },
        }
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function componentDidMount
     * @description obtiene la informacion inicial
     */
    componentDidMount = () => {
        this.getRequisicion()
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function getRequisicion
     * @description Obtiene la informacion de una requisicion
     */
    getRequisicion = () => {
        this.setState({loading: true})
        axios.get('/requisiciones/get',{
            params:{
                requisicion_id: this.props.requisicion_id
            }
        }).then(response => {
            this.setState({requisicion: response.data.data})

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los datos')
        })

    }


    /**
     *
     * @methodOf ModalRequisiciones
     * @function getDates
     * @description Obtiene la informacion de una requisicion
     */
    getDates = (fecha_entrega) => {
        if(!fecha_entrega){
            return  <Text style={{color: '#FC0000'}}></Text>
        }else{
            let now = moment()
            let date = moment(fecha_entrega).add(5,'days')

            let diff = date.diff(now, 'days')

            if(diff < 0){
                return  <Text style={{color: '#FC0000'}}>Retrasado</Text>
            }else{
                return  <Text style={{color: '#287a11'}}>A tiempo</Text>
            }
        }
    }

    /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method getPDF
     * @description Generamos el PDF de la contizacion
     */
    getPDF = (requisicion_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/requisiciones/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('requisicion_id', requisicion_id)
        return urlPDF.href
    }
   


    render() {

        const { requisicion } = this.state;

        return [

            <div>
                <Row>
                    <Col xs={24} md={12} className="col-text">
                        <Row>
                            <Col span={24} className="center">
                                <Text>{requisicion.folio}</Text>
                            </Col>
                        </Row>
                        <div className="flex-between">
                            <Tag className={`tag tag-estatus-${requisicion.estatus}`}>{estatus[requisicion.estatus]}</Tag>
                            <Text className="modal-title">{requisicion.producto_id?.nombre}</Text>
                            <IconFlag fill={colors_flag[requisicion.prioridad] ? colors_flag[requisicion.prioridad] : '#A4A4A4'}/>
                        </div>
                        <Title className="text-blue text-center mt-1 mb-1" level={5}>Información General</Title>
                        <Form
                            layout="vertical"
                        >
                            <Row justify="center">
                                <Col span={12}>
                                    <Form.Item
                                        label="SKU Grupo Manantial"
                                    >
                                        <Text strong>{requisicion.producto_id?.sku_manantial}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Fabricante"
                                    >
                                        <Text strong>{requisicion.producto_id?.fabricante}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Categoria"
                                    >
                                        <Text strong>{requisicion.producto_id?.categoria_id?.nombre}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Proyecto"
                                    >   
                                        <CustomAvatar 
                                            size="large" 
                                            style={{marginRight: '4px'}}
                                            image={requisicion.proyecto_id.logo}
                                            name={requisicion.proyecto_id.nombre}
                                            color={requisicion.proyecto_id.color}/>
                                        <Text strong>{requisicion.proyecto_id.nombre}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Volumen"
                                    >
                                        <Text strong>{requisicion.volumen}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Unidad"
                                    >
                                        <Text strong>{unidades[requisicion.producto_id?.unidad]}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label="Contratista"
                                    >
                                        <CustomAvatar
                                            style={{marginRight: '4px'}}
                                            size="large" 
                                            image={requisicion.contratista_id?.avatar}
                                            name={`${requisicion.contratista_id?.nombre} ${requisicion.contratista_id?.apellido}`}
                                            color={"#ff8227"}
                                        />
                                        <Text strong>{requisicion.contratista_id?.nombre ? `${requisicion.contratista_id?.nombre} ${requisicion.contratista_id?.apellido}` : '-'}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label="Propiedades"
                                    >
                                        <Text strong ellipsis>{requisicion.propiedad_id?.inmueble_id ? requisicion.propiedad_id?.inmueble_id?.nombre : ''}</Text>
                                        {requisicion.propiedades.length > 0 ? requisicion.propiedades.map(e =><Tag color="green" style={{marginRight: '4px', marginLeft: '0px'}}>{e.nombre}</Tag>) : '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider/>
                            <Title className="text-blue text-center" level={5}>Entrega</Title>
                            <Row justify="center">
                                <Col span={12}>
                                    <Form.Item
                                        label="Tipo de Entrega"
                                    >
                                        <Tag 
                                            className="tag" 
                                            style={{color: 'white', background: '#54d9df'}}
                                        >{requisicion.requisicion_opcion_id?.tipo_entrega === 0 ? 'Directa' : 'Envío'}</Tag>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Proveedor"
                                    >
                                        <Text strong>{requisicion.requisicion_opcion_id?.proveedor_id ? requisicion.requisicion_opcion_id?.proveedor_id.alias : '-'}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Lugar"
                                    >   
                                        <div className="flex-left-column">
                                            <Text strong>{requisicion.requisicion_opcion_id?.almacen_id ? requisicion.requisicion_opcion_id?.almacen_id.nombre : '-'} </Text>
                                            <small>{requisicion.requisicion_opcion_id?.almacen_id ? requisicion.requisicion_opcion_id?.almacen_id.direccion : ''} </small>
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Fecha y Hora"
                                    >   
                                        <div className="flex-left-column">
                                            {requisicion.estatus === 6 ? <Text style={{color: '#287a11'}}>Entregado</Text> : <>   
                                                <Text strong>{requisicion.fecha_entrega ?  moment(requisicion.fecha_entrega).format('DD-MM-YYYY HH:mm') : '-'}</Text>
                                                {this.getDates(requisicion.fecha_entrega)}
                                            </>}
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Archivos"
                                    >
                                        <div className="flex-left-column">
                                            {requisicion.requisicion_opcion_id?.archivos.length === 0 ? <Text className="text-gray">Sin Archivos</Text> : null}
                                            {requisicion.requisicion_opcion_id?.archivos.map(arch => <a target="_blank" href={`${axios.defaults.baseURL}/voucher/${arch.filename}`}>{arch.name}
                                            </a>)}
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    {requisicion.estatus >= 4 ? <Form.Item label="Orden de Compra">
                                        <a href={this.getPDF(requisicion._id)} download target="_blank">
                                            <IconPDFColor/>
                                        </a>
                                    </Form.Item> : null}
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={24} md={12} className="m-0">
                        <div className="chat-side">
                            <Title level={4} className="text-center title-chat">Actualizaciones</Title>
                            <Chat
                                requisicion_id={this.props.requisicion_id}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        ]
    }
}



export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form modal-detalle-chat"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalRequisiciones {...props} />
    </Modal>


}
