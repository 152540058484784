import React, { Component } from 'react';
import { Button, Popconfirm, Row, Col, Card, message, Typography, List, } from 'antd'
import axios from 'axios';

import { IconEdit, IconDelete } from '../../../Widgets/Iconos'
import ModalSubConcepto from './ModalSubConcepto'
import '../../../../Styles/budgeting.css'

const { Text, Title } = Typography

/**
 * @export
 * @class Conceptos
 * @extends {Component}
 * @description Vista de Conceptos
 */
class Conceptos extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			data: [],
			proyectos: [],
			proyecto: {},
			concepto: {},
			page: 1,
			limit: 10,
			total: 0,
			pages: 0,
			proyecto_id: this.props.proyecto_id,
			concepto_id: this.props.concepto_id,
			testing: 'Aun no llega nada'
		}
	}

	refForm = React.createRef()

	componentDidMount() {
		//Mostrar input superior de busqueda 
		//Para acceder al valor del input = this.props.search

		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		window.scrollTo(0, 0)
		this.getProyecto()
		this.getConcepto()
		this.getSubConceptos()

	}

	/**
	* @memberof ModalSubConcepto
	* @method getConcepto
	* @description Obtiena la informacion del Almacen
	**/
	getConcepto = () => {
		this.setState({ loading: true })
		axios.get('/conceptos/get', {
			params: {
				concepto_id: this.state.concepto_id
			}
		}).then(response => {
			this.setState({ concepto: response.data.data })

		}).catch(error => {
			let msg = 'Error al obtener el Concepto'
			if (error.response?.status === 403)
				msg = error.response.data.message
			message.error(msg)
		}).finally(() => this.setState({ loading: false }))
	}


	/**
	* @memberof Almacenes
	* @method getProyecto
	* @description  Obtiene la informacion del proyecto seleccionado
	**/
	getProyecto = () => {
		axios.get('/proyectos/get', {
			params: {
				id: this.state.proyecto_id
			}
		}).then(response => {
			this.setState({ proyecto: response.data.data })
		}).catch(error => {
			message.error('Error al obtener la información del proyecto')
		})
	}


	/**
	* @memberof Almacenes
	* @method getSubConceptos
	* @description  Obtiene la informacion del proyecto seleccionado
	**/
	getSubConceptos = (page = this.state.page,limit=this.state.limit) => {
		
		axios.get('/sub-conceptos/list', {
			params: {
				proyecto_id: this.state.proyecto_id,
				concepto_id: this.state.concepto_id,
				page,
				limit
			}
		}).then(response => {
			console.log("response", response.data.data);
			this.setState({
				data: response.data.data.itemsList,
				page: response.data.data.paginator.currentPage,
				total: response.data.data.paginator.itemCount,
			})

		}).catch(error => {
			message.error('Error al obtener los Conceptos')
		})
	}

	render() {

		return (
			<>
				<List
					loading={this.state.loading}
					className="component-list mt-1 scroll"
					itemLayout="horizontal"
					dataSource={this.state.data}
					pagination={{
						current: this.state.page,
						total: this.state.total,
						pageSize: this.state.limit,
						showSizeChanger: false,
						onChange: (page) => this.getSubConceptos(page)

					}}
					renderItem={(item, i) => (

						<List.Item className="component-list-item" key={item._id}>
							<Card className="card-list borderless">
								<Row className="width-100">
									<Col span={10} className="flex-left">
										<Text>{item.nombre}</Text>
									</Col>
									<Col span={10} className="flex-left">
										<Text>$ {item.presupuesto.toMoney()} MXN</Text>
									</Col>
									<Col span={4} className="center">
										<Button
											size="small"
											className="btn btn-edit"
											title="Editar"
											onClick={() => this.setState({ modal_visible: true, sub_concepto_id: item._id })}
											icon={<IconEdit style={{ width: 12, heigth: 12, marginTop: '5px' }} />}
										/>

										<Popconfirm
											placement="topRight"
											title="¿Deseas eliminar este Subconcepto?"
											onConfirm={() => axios.post('/sub-conceptos/delete', { id: item._id })
												.then((response) => {
													message.success("Eliminado Correctamente")
													this.getSubConceptos(this.state.page)
													this.props.updateFather()
												})
												.catch((error) => {
													console.log("error", error);
													message.error("No es posible eliminar")
													this.getSubConceptos(this.state.page);
													this.props.updateFather()
												})
											}
											okText="Si"
											cancelText="No"
										>
											<Button className="btn btn-delete" size="small" icon={<IconDelete style={{ width: 12, heigth: 12, marginTop: '5px' }} />} title="Eliminar" />
										</Popconfirm>
									</Col>

								</Row>
							</Card>
						</List.Item>
					)}
				/>
				<ModalSubConcepto
					visible={this.state.modal_visible}
					onCancel={() => {
						this.setState({ modal_visible: false, sub_concepto_id: undefined })
						this.getSubConceptos()
						this.props.updateFather()
					}}
					proyecto_id={this.state.proyecto_id}
					concepto_id={this.state.concepto_id}
					sub_concepto_id={this.state.sub_concepto_id}
				/>
			</>

		)
	}
}

/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default Conceptos