import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Divider, Spin, Space, Tag } from 'antd';
import { PlusOutlined, UploadOutlined, MinusCircleOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar'
import PrioridadSelect from '../../../Widgets/prioridadSelect';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';

//css
import './../../../../Styles/modales.css'

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment')

/**
 *
 *
 * @class ModalMovimientoDetalle
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalMovimientoDetalle extends Component {


	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			movimiento: {
				propiedades: []
			},
		}
	}

	/**
	 *
	 * @methodOf ModalMovimientoDetalle
	 * @function componentDidMount
	 * @description Obtiene la informacion inicial
	 */
	componentDidMount(){
		if(this.props.movimiento_id)
			this.getMovimiento()
	}

	/**
	 *
	 * @methodOf ModalMovimientoDetalle
	 * @function getMovimiento
	 * @description Obtiene la informacion de un movimiento
	 */
	getMovimiento = () => {
		this.setState({loading: true})
		axios.get('/almacen/productos/movimiento',{
			params:{
				movimiento_id: this.props.movimiento_id
			}
		}).then(response => {
			console.log("response", response.data);
			this.setState({
				movimiento: response.data
			})

		}).catch(error => {
			message.error('Movimiento no encontrado')
		}).finally(() => {
			this.setState({loading: false})
		})
	}

	render() {

		const { movimiento } = this.state

		return [

			<div className="modal-header">
				<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
					<IconCloseModal />
				</Button>
				<Title level={3} className="modal-title">{movimiento.tipo == 1 ? 'Entreda' : 'Salida' }</Title>
			</div>,
			<Spin spinning={this.state.loading}>


				<Form
					layout="vertical"
					className="frm-cuentas"
					name="form-materiales"
					ref={this.modalMateriales}

				>
					<Row align="center" gutter={[16]}>

						<Col xs={22} md={7}>
							<Form.Item
								label={<Text className="text-gray">Producto</Text>}
							>
								<Text>{movimiento.producto_id?.nombre}</Text>
							</Form.Item>
						</Col>

						<Col xs={22} md={7}>
							<Form.Item
								label={<Text className="text-gray">SKU</Text>}
							>
								<Text>{movimiento.producto_id?.sku_manantial}</Text>
							</Form.Item>
						</Col>

						<Col xs={22} md={7}>
							<Form.Item
								label={<Text className="text-gray">Categoria</Text>}
							>
								<Text>{movimiento.producto_id?.categoria_id?.nombre}</Text>
							</Form.Item>
						</Col>

						<Col xs={22} md={7}>
							<Form.Item
								label={<Text className="text-gray">Proyecto</Text>}
							>
								{ movimiento.proyecto_id ? <> 
									<CustomAvatar
		                                image={movimiento.proyecto_id?.logo}
		                                name={movimiento.proyecto_id?.nombre}
		                                color={movimiento.proyecto_id?.color}
		                                style={{marginRight: '4px'}}
		                            />
		                            <Text>{movimiento.proyecto_id?.nombre}</Text>
		                        </> : 'Sin Proyecto' }
							</Form.Item>
						</Col>

						<Col xs={22} md={7}>
							<Form.Item
								label={<Text className="text-gray">Contratista</Text>}
							>
								{ movimiento.contratista_id ? <> 
									<CustomAvatar
		                                image={movimiento.contratista_id?.logo}
		                                name={movimiento.contratista_id?.nombre + " " + movimiento.contratista_id.apellido}
		                                color={movimiento.contratista_id?.color}
		                                style={{marginRight: '4px'}}
		                            />
		                            <Text>{movimiento.contratista_id?.nombre} {movimiento.contratista_id?.apellido}</Text>
		                        </>
		                        : 'Sin Contratista' }
							</Form.Item>
						</Col>

						<Col xs={22} md={7}>
							<Form.Item
								label={<Text className="text-gray">Inmuebles</Text>}
							>
								{
									movimiento.propiedades.length > 0 ? movimiento.propiedades.map(e => <Tag color="green" className="tag-normal">{e.nombre}</Tag>) : 'Sin Propiedades'
								}
							</Form.Item>
						</Col>


						<Col xs={22} md={7}>
							<Form.Item
								label={<Text className="text-gray">Volumen</Text>}
							>
								<Text>{movimiento.volumen}</Text>
							</Form.Item>
						</Col>

						<Col xs={22} md={7}>
							<Form.Item
								label={<Text className="text-gray">Registro</Text>}
							>
								{ movimiento.usuario_id ? <> 
									<CustomAvatar
		                                image={movimiento.usuario_id?.logo}
		                                name={movimiento.usuario_id?.nombre + " " + movimiento.usuario_id.apellido}
		                                color={movimiento.usuario_id?.color}
		                                style={{marginRight: '4px'}}
		                            />
		                            <Text>{movimiento.usuario_id?.nombre} {movimiento.usuario_id?.apellido}</Text>
		                        </>
		                        : 'Sin Usuario' }
							</Form.Item>
						</Col>

						<Col xs={22} md={7}>
							<Form.Item
								label={<Text className="text-gray">Fecha & Hora</Text>}
							>
								{moment(movimiento.createdAt).format('DD-MM-YYYY HH:mm')}
							</Form.Item>
						</Col>

					</Row>  

					<Row style={{ textAlign: "center" }}>
						<Col span={24}>
							<Form.Item >
								<Button htmlType="submit" type="primary" className="btn-azul" >
									Cerrar
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Spin>
		]
	}
}



export default function (props) {

	const {visible, onCancel} = props

	return <Modal
		visible={visible}
		onCancel={onCancel}
		title={null}
		footer={null}
		closable={false}
		className="modal-form"
		destroyOnClose={true}
		zIndex={1000}
		style={{maxWidth: '770px'}}

	>
		<ModalMovimientoDetalle {...props} />
	</Modal>


}
