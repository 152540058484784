import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin } from 'antd';

import { IconCloseModal } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const { Title } = Typography;
const axios = require('axios');

/**
 *
 *
 * @export
 * @class ModalActividad
 * @extends {Component}
 * @description Modal para agregar una obra en InmuebleAdmin
 */

export default class ModalActividad extends Component {

    formActividad = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            empleados: [],
            actividad: [
                {
                    nombre: ''
                },
            ],
            id_actividad: undefined,
            loading: false,
        }
    }


    /**
    * @memberof ModalActividad
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
    }


    /**
     *
     *
     * @memberof ModalActividad
     *
     * @method LoadActividad
     * @description Carga los valores de la actividad en el formulario
     */

    LoadActividad() {
        setTimeout(() => {
            const nombre = this.props.nombre_actividad
          
            if (this.formActividad.current !== null && this.props.modalVisible) {
                this.formActividad.current.setFieldsValue({
                    nombre: nombre
                })
            }
        }, 180);
    }

    /**
     *
     *
     * @param {*} values
     * @memberof ModalActividad
     * @method newActividad
     * @description Manda peticion para crear una nueva actividad
     */
    newActividad(values) {
      
        axios.post('/actividades/add', {
            nombre:values.nombre,
        })
            .then(res => {
                message.success('Actividad creada!');
            })
            .catch(error => {
                console.log('error al crear actividad', error);
            })
            .finally(f => {
                this.props.accept();
                this.setState({loading: false})
            })

    }


    /**
     *
     *
     * @param {*} Values
     * @memberof ModalActividad
     * @method updateActividad
     * @description Manda la peticion para actualizar una actividad
     */
    updateActividad(values) {
        const id=this.props.id;
      
        axios.post('/actividades/update', {
            id: id,
            nombre: values.nombre,

        })
            .then(res => {
                message.success('Actividad Actualizada!');
            })
            .catch(error => {
                message.error('No se pudo actualizar la actividad!');
                console.log('error al guardar actividad', error);
            })
            .finally(f => {
                this.props.accept();
                 this.setState({loading: false})
            })

    }

    /**
    * @memberof ModalActividad
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        this.setState({loading: true})
        if (this.props.id === undefined) {
            this.newActividad(values)
        }
        else {
            this.updateActividad(values)
        }

    }


    render() {
        const { id, accionModal } = this.props;
     
        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                {(accionModal === "Editar" && id !== undefined && id !== "") ? this.LoadActividad() : ''}

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.nombreView} Actividad</Title>
                </div>
                <Spin spinning={this.state.loading} >
                    <Form layout="vertical" className="frm-cuentas" name="formulario-entrega" onFinish={this.onFinish} ref={this.formActividad} >
                        <Row align="center">
                            <Col span={20} offset={2}>
                                <Form.Item label="Actividad" name="nombre" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese un actividad"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
