import React, { Component } from 'react';
import { Button, Col, Modal, Row, Typography, Input, Form, Select, message, Spin } from 'antd'


import "../../../../Styles/Modal/ModalNuevoGrupo.css";
import { IconCloseModal, IconArrowSelect } from "../../../Widgets/Iconos";


const axios = require("axios").default;

const { Title } = Typography;
const { Option } = Select;


export default class ModalCopiarObra extends Component {


    /**
     * @param formQuestion
     * @description Referencia para el formulario.
     * */
    inmuebleForm = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            _dataObras: [],
            dataObras: [],
            obras: {},

            loading: false,
            datosConsultados: false,
            return: false,
            update: true,
        };
    }
    modalCopiarObra = React.createRef();


    /**
     *
     *
     * @memberof ModalNuevoGrupo
     *
     * @function componentDidMount
     * @description Asigna los valores de la obra seleccionada al state.data
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setState({ data: this.props.data });
        this.getObras();
    }

    /**
     *
     *
     * @memberof ModalNuevoGrupo
     *
     * @function componentDidUpdate
     * @description  obtiene el ID del manager_inmueble seleccionado
     */
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({ manager_inmueble_id: this.props.data })
        }


        if(this.props.manager_proyecto_id !== prevProps.manager_proyecto_id){
            this.getObras();
        }
        if (this.props.visible !== prevProps.visible){
            this.getObras();
        }

    }


    /**
    * @memberof ModalObra
    *
    * @method   getObras
    * @description  trae un listado de las obras (inmuebles) para desplgarlos en el select, que no esten siendo usados
    *
    **/
    getObras = (search) => {
        axios.get('/tags', {
            params: {
                page: 1,
                limit: 5000,
                type: "inmueble_id",
                search: search,
                manager_proyecto_id: this.props.manager_proyecto_id,
                proyecto_id: this.props.proyecto_id
            }
        })
            .then(res => {
                this.setState({ dataObras: res.data.data.itemsList })
            })
            .catch(e =>
                console.log('error inmuebles get', e)
            )
    }


    /**
     *
     *
     * @memberof ModalNuevoGrupo
     *
     * @function onFinish
     * @description Crea o actualiza un grupo.
     *
     * @param values
     * @description Objeto generando por el form de antd del gurpo
     */
    onFinish = async (values) => {

        this.setState({loading: true})
        let manager_inmueble = this.props.manager_inmueble_id;
        axios.post('/manager/proyecto/inmuebles/copy', {
            inmueble_manager_id: manager_inmueble,
            inmueble_id: values.nombre,
            dias: values.dias_recorridos
        })
            .then(res => {
                message.success("El inmueble fue copiado ¡Exitosamente!");
                 this.hideModalCopy()
            })
            .catch(error => {
                message.error("No se pudo crear el grupo");
                console.log("error", error)
            })
    }


    /**
     *
     *
     * @memberof ModalCopiarObra
     * @method hideModalCopy 
     * @description Oculta el modal
     */
    hideModalCopy = () => {

        this.setState({
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false
        });


        this.props.hideModalCopy()
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.hideModalCopy}
                title={null}
                footer={null}
                className="modal-grupos modal-form"
                destroyOnClose={true}
                zIndex={1000}
                closable={false}
            >
                <div className="modal-header ">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModalCopy}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Copiar Obra</Title>
                </div>
                <Spin spinning={this.state.loading}>
                    <Form
                        onFinish={this.onFinish}
                        layout="vertical"
                        ref={this.modalCopiarObra}
                    >
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Select 
                                        clearIcon={<IconArrowSelect />}
                                        filterOption={false} 
                                        showSearch 
                                    >
                                        {this.state.dataObras.map(function ({ _id, nombre }, index) {
                                            return <Option value={_id}> {nombre}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
                                <Form.Item
                                    label="Dias a recorrer"
                                    name="dias_recorridos"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, los dias a recorrerse las actividades"
                                    }]}
                                >
                                    <Input placeholder="Dias a recorrer actividades" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center" xs={24} lg={24}>
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}



