import React, { Component } from 'react';
import { Row, Col, Card, Button, Popover, DatePicker, Table, Popconfirm, message, Statistic, List, Typography, Space } from 'antd'
import { IconPDF, IconEye, IconPlus, IconArrowBack, IconDelete,  IconEmailSuccess, IconEmailWaitingForSend } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import Logged from '../../../Hooks/Logged';
import ModalTransaccion from '../Modales/Acreedores/ModalAcreedorTransaccion';


const { Text } = Typography;
const moment = require('moment');
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


export default class DetalleAcreedor extends Component {
    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            dataTran: [],
            dataDetalles: [],
            loading: true,

            detalle: true,
            accionModal: 'crear',
            registroId: '',
            transaccion_id: '',
            loading: true,

            modalVisibleTran: false,

            /* Paginado */
            itemCount: undefined,
            perPage: 5,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,

        }
    }

    ModalTranRef = React.createRef();

    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);


        //Obtenemos detalles
        this.receivedData();


        this.setState({
            loading: false,
            dataTran: [],
            dataDetalles: [],
            transaccion_seleccionada: {},
            tranSelId:"",
            registroId: '',
            transaccion_id:"",


            acreedor: "{{Acreedor}}",
            monto: "{{Monto}}",
            cliente: '{{Cliente}}',
            fecha_prestamo: "",

            nombre: '{{Nombre}}',
            apellido: '{{Apellido}}',
            email: '{{Email}}',
            telefono: '{{Telefono}}',

            proyecto: '{{Proyecto}}',
            rubro: '{{Rubro}}',
            subrubro: '{{Subrubro}}',

            asesorNombre: '',
            asesorApellido: '',
            usuario: '{{Usuario}}',

            montoPrestamo: '{{MontoPrestamo}}',
            montoaRetornar: '{{MontoaRetornar}}',
            montoPagado: '{{MontoPagado}}',
            montoPendiente: '{{MontoPendiente}}',
            documentos: []

        });
    }


    /**
     * @method receivedData
     * @description Obtiene la informacion y actualiza los valores locales de la vista.
     */
    receivedData = values => {

        var id = this.props.match.params.id;

        axios.post('/acreedores/get', {
            id: id,
            proyectos: ''
        }).then((acreedor) => {
            let data = acreedor.data.data;
            var fecha_prestamo = (data.fecha_prestamo !==undefined) ? moment(data.fecha_prestamo).format('DD/MM/YYYY') : moment(data.createdAt).format('DD/MM/YYYY');

            //Actualiza los valores de los states.
            this.setState({
                loading: false,
                dataDetalles: data,
                registroId: data._id,
                acreedor: data.acreedor,


                fecha_prestamo: data.fecha_prestamo,

                email: data.email,
                telefono: data.telefono,

                proyecto: data.proyecto_id.nombre,
                rubro: data.rubro_id.nombre,
                subrubro: data.subrubro_id.nombre,


                asesorNombre: data.contacto_id.nombre,
                asesorApellido: data.contacto_id.apellido,
                usuario: data.contacto_id.nombre + ' ' + data.contacto_id.apellido,

                montoPrestamo: data.monto_prestamo,
                montoaRetornar: data.monto_retorno,
                montoPagado: acreedor.data.monto_pagado,
                montoPendiente: data.monto_pendiente,
                dataTran: data.transacciones,
                documentos: data.documentos,
            });
            this.setState({
                datosConsultados: true,
            })
        })
        let idCliente = '';

    }




    /**
       * @description Cerrar modal y limpiar valor registroId
       *
       *
       * */
    hideModal = () => {
        this.setState({
            accionModal: '',
            registroId: '',
            transaccion_id: '',
            modalVisible: false,
            modalVisibleTran: false,
            transaccion_seleccionada: {},
        })
        this.receivedData();
    }



    /*Modal transaccion */
    modalCrearTransaccion = () => {
        this.setState({
            accionModal: 'crear',
            modalVisibleTran: true,

        })
    }


    /**
      * @function eliminarTransaccion
      * @description Obtiene la collección de transacciones paginada.
      * @param e ID de la transacción a eliminar
      * */
    eliminarTransaccion = e => {
        axios.post('/transacciones/delete', {
            id: e,
            acreedor_id : this.state.registroId
        })
            .then((response) => {
                message.success(response.data.message);
                this.receivedData();
            })
            .catch((error) => {
                message.error(error.response.data.message)
            })
    };


        /*Modal transaccion detalle */
        modalDetalleTransaccion = (item) => {
            this.setState({
                accionModal: 'detalle',
                transaccion_id: item,
                modalVisibleTran: true
            })
        };


    /**
     *
     *
     * @memberof Detalle 
     * @method enviarMailRecibo
     * @description Envia por mail el recibo de la transaccion seleccionada
     */
    enviarMailRecibo = (transaccion_id) => {
        const key = 'mensaje';
        message.loading({content: 'Enviando...', key});
        axios.get('/acreedores/recibo', {
            params: {
                tran_id: transaccion_id,
                acreedor_id: this.props.match.params.id,
                enviar: true,
            }
        })
            .then(result => {
                message.success({content: 'Mail enviado correctamente!', key})
                this.receivedData()
            })
            .catch(error => {
                message.success({content: 'Ocurrio un problema al enviar el mail.', key})
            })
    }

    /**
     * @memberof Detalle 
     * @method getURL
     * @description Obtiene el Reciboen PDF
     */
    getURL = (item_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/acreedores/recibo');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('tran_id', item_id)
        urlPDF.searchParams.append('acreedor_id', this.state.registroId)
        urlPDF.searchParams.append('enviar', false)

        return urlPDF
    }


    render() {
        let usuario = this.context;
        

        return (
            <div>
                <Row className="row-encabezado">

                    <Col xs={24} xl={6} xxl={8}>
                        <Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
                            <IconArrowBack />
                        </Button>
                        <h2 style={{ width: 'auto', float: 'left' }}> Deudas por Pagar </h2>
                    </Col>
                </Row>

                <section className="p-1">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Card
                                title=""
                                loading={this.state.loading}
                                bordered
                                className="card-proyeccionesDetalle"
                            >
                                <Row>
                                    <Col xs={24} lg={12} >
                                        <h1>{this.state.acreedor}</h1>
                                    </Col>
                                    <Col xs={24} lg={12} >
                                        <label style={{ width: "auto !important", fontWeight: "normal", fontSize: "15px", marginRight: "12px", lineHeight: "18px", color: "#A4A4A4", marginbottom: "10px", fontWeight: "bold" }}>Fecha de Prestamo</label>
                                        <DatePicker disabled defaultValue={(!this.state.fecha) ? moment(new Date, 'DD/MM/YYYY') : ''} />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Acreedor</label>
                                        <p className="pInfo">{this.state.acreedor}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Email</label>
                                        <p className="pInfo">{this.state.email}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Teléfono</label>
                                        <p className="pInfo">{this.state.telefono}</p>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Proyecto</label>
                                        <p className="pInfo">{this.state.proyecto}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Contacto</label>
                                        <CustomAvatar name={[this.state.asesorNombre, this.state.asesorApellido]} />
                                        <p className="pInfo">{this.state.usuario}</p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Principal del Prestamo</label>
                                        <p className="pInfo"><Statistic value={this.state.montoPrestamo} precision={2} prefix={'$'} suffix={"MXN"} /></p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Monto a Retornar</label>
                                        <p className="pInfo"><Statistic value={this.state.montoaRetornar} precision={2} prefix={'$'} suffix={"MXN"} /></p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Monto Actual Pagado</label>
                                        <p className="pInfo textGreen"><Statistic valueStyle={{ color: 'green' }}
                                            value={this.state.montoPagado}
                                            precision={2} prefix={'$'}
                                            suffix={"MXN"} /></p>
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <label className="lblTitulo">Monto por Pagar</label>
                                        <p className="pInfo textRed"><Statistic valueStyle={{ color: 'red' }}
                                            value={this.state.montoPendiente}
                                            precision={2} prefix={'$'}
                                            suffix={"MXN"} /></p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} lg={12} xl={8}>
                 
                                        <label className="lblTitulo">Documentos
                                        </label>
                                        <div className="pInfo document">{
                                            (Array.isArray(this.state.documentos)) ? this.state.documentos.map(item => {
                                                return <a target="_blank" className="link-document"
                                                          style={{display: "block"}} download
                                                          href={axios.defaults.baseURL + "/voucher/" + item}>
                                                    {item}
                                                </a>
                                            }) : null
                                        }</div>
                                    </Col>
                                  
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                                <h1 className="blue-title">Transacciones</h1>
                                <List
                                    loading={this.state.loading}
                                    className="component-list"
                                    itemLayout="horizontal"
                                    dataSource={this.state.dataTran}
                                    header={
                                        <Row className="header-list" style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>
                                            <Col xs={5} className="center">
                                                <Text strong>Fecha</Text>
                                            </Col>
                                            <Col xs={6} className=" center">
                                                <Text strong>Concepto</Text>
                                            </Col>
                                            <Col xs={4} className="center">
                                                <Text strong>Monto</Text>
                                            </Col>
                                            <Col xs={5} className="center">
                                                <Text strong>Tipo</Text>
                                            </Col>
                                        </Row>
                                    }
                                    renderItem={(item, i) => (
                                        <List.Item className="component-list-item" key={item._id}>
                                            <Card className="card-list">
                                                <Row style={{ width: '100%' }} className="">
                                                    <Col span={5} className="center">
                                                        <Text strong>{moment(item.fecha).utc().format('DD-MM-YYYY')}</Text>
                                                    </Col>
                                                    <Col span={6} className="center">
                                                        <Text strong>{item.concepto}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <Text>$ {item.monto.toMoney(2)} MXN</Text>
                                                    </Col>
                                                    <Col span={5} className="center">
                                                        <Text>{item.tipo_cliente ? item.tipo_cliente : 'Transacción'}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">

                                                            <Popover placement="topLeft"
                                                                title={(item.mensaje && item.mensaje.enviado) ? "El mensaje ha sido enviado" : "El mensaje aún no se envía"}
                                                                content={(item.mensaje && item.mensaje.enviado) ? `El correo se envió el ${moment(item.mensaje.fecha).format("YYYY-MM-DD")} a las ${moment(item.mensaje.fecha).format("HH:MM")}` : undefined}>
                                                                <Button type="primary" className="button-survey btn-mail button-mail"
                                                                    style={{ backgroundColor: (item.mensaje && item.mensaje.enviado) ? "#28a745" : "#ffc107" }}
                                                                    name={item.nombre} id={item._id} title="Enviar" onClick={() => {
                                                                        this.enviarMailRecibo(item._id)
                                                                    }}>
                                                                    {(item.mensaje && item.mensaje.enviado) ?
                                                                        <IconEmailSuccess style={{ width: "20px", height: "20px" }} color="white" />
                                                                        :
                                                                        <IconEmailWaitingForSend color="white" style={{ width: "20px", height: "20px" }} />
                                                                    }
                                                                </Button>
                                                            </Popover>
                                                        
                                                            <a href={this.getURL(item._id)} download target="_blank">
                                                                <Button 
                                                                    type="primary" 
                                                                    className="button-survey btn-pdf button-pdf"
                                                                    title="PDF"
                                                                >
                                                                    <IconPDF />
                                                                </Button>
                                                            </a>

                                                            <Button 
                                                                type="primary" 
                                                                className="button-survey btn-eye button-eye" 
                                                                title="Ver detalle" 
                                                                onClick={() => { this.modalDetalleTransaccion(item._id)}}
                                                            >
                                                                <IconEye />
                                                            </Button>
                                                            {(usuario && (usuario.tipo === 1)) ? <Popconfirm placement="topRight"
                                                                title="¿Eliminar esta operacion?" okText="Si, seguro" cancelText="No"
                                                                onConfirm={() => {
                                                                    this.eliminarTransaccion(item._id)
                                                                }}>
                                                                <Button type="primary"
                                                                    className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                                    title="Eliminar">
                                                                    <IconDelete />
                                                                </Button>
                                                            </Popconfirm> : null}
                                                        
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                        </Col>
                    </Row>


                </section>

                <Button className="btnAddPlus" onClick={e => this.modalCrearTransaccion()}>
                    <IconPlus />
                </Button>


                <ModalTransaccion
                    ref={this.ModalTran}
                    modalVisible={this.state.modalVisibleTran}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    dataAcreedor={this.state.dataDetalles}
                    transaccion_id={this.state.transaccion_id}


                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                    }}
                >
                </ModalTransaccion>
            </div>
        )
    }
}

DetalleAcreedor.contextType = Logged;
