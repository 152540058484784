import React, { Component } from "react";
import { Row,  Input, Button,  List,   Typography,  Select,   Drawer,  Space, Tag } from 'antd';
//componentes
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { IconCloseModal, } from '../../Widgets/Iconos';
import PropTypes from "prop-types"; 
import axios from 'axios'
//modales
import ModalRecordatorios from "../Modales/Calendario/ModalRecordatorios";
//css
import '../../../Styles/drawer.css'

const moment = require('moment');
const { Title, } = Typography;

const IconText = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
);

export default class DrawerListaRecordatorios extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        hideDrawer: PropTypes.func,
        accept: PropTypes.func,

        className: PropTypes.string,

    };

    static defaultProps = {
        visible: false,
        hideDrawer: () => { },
        accept: () => { },
        className: ""
    };



    constructor(props) {
        super(props);
        this.state = {

            selectedDate: null,

            remindersList: [],


            formModal: false,




            reminderId: undefined

        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     *
     *
     * @memberof DrawerListaRecordatorios
     * 
     * @description actualizamos la lista de reminders con la fecha enviada por el calendario
     */
    componentDidUpdate() {
        if (this.state.selectedDate !== this.props.selectedDate && this.props.selectedDate !== null) {

            this.state.selectedDate = this.props.selectedDate;
            this.getReminders(this.props.selectedDate)

        }
    }



    /**
     *
     *
     * @memberof DrawerListaRecordatorios
     * 
     * @function getReminders
     * @description Obtenemos la lista de reminders
     */
    getReminders = (selectedDate = this.state.selectedDate) => {
        axios.get('/recordatorios/list', {
            params: {
                selectedDate: selectedDate.format('YYYY-MM-DD'),
                dateOrder: true
            }
        }).then(({ data }) => {
            let remindersList = data.data;
            this.setState({ remindersList })
        })
            .catch(e => { })
    }

    /**
     *
     *
     * @memberof DrawerListaRecordatorios
     * 
     * @function showModal
     * @description Mostramos el modal 
     * 
     */
    showModal = (id = null) => this.setState({
        formModal: true,
        reminderId: id
    })


    /**
     *
     *
     * @memberof DrawerListaRecordatorios
     * 
     * @function hideModal
     * @description Ocultamos al modal 
     * 
     */
    hideModal = () => {
        this.setState({ formModal: false, reminderId: null })
        this.getReminders();
    }


    render() {
        const { showModal, hideModal } = this;
        const { remindersList, formModal, reminderId } = this.state;
        const { hideDrawer, visible, className, selectedDate } = this.props;



        return (
            <Drawer
                title={null}
                placement="right"
                closable={false}
                onClose={hideDrawer}
                visible={visible}
                className={"drawer-reminders" + className}
            >
                <div style={{ margin: '20px 0px 40px 0px' }}>
                    <Button type="link" className="closable-button" title="Cerrar" onClick={hideDrawer}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="drawer-title">Recordatorios <small className="drawer-subtitle">{(selectedDate !== null) ? selectedDate.format("YYYY-MM-DD") : null}</small></Title>
                </div>

                <Row style={{ width: '100%' }}>

                    <List
                        style={{ width: '100%' }}
                        itemLayout="vertical"
                        size="large"
                        dataSource={remindersList}
                        renderItem={({ _id, nombre, detalle, fecha }, index) => (
                            <List.Item
                                className={"reminder-item"}
                                key={index}
                            // actions={[
                            //     <IconText icon={EditOutlined} key="list-vertical-like-o" />,
                            //     <IconText icon={EyeOutlined} key="list-vertical-message" />,
                            // ]}
                            >
                                <List.Item.Meta
                                    title={<div> <Tag color="magenta">{moment(fecha).format("HH:MM")}</Tag> {nombre}   </div>}
                                    description={detalle}
                                />
                                <div className="icon-button-container">
                                    <Button size="small" onClick={()=>showModal(_id)}><EditOutlined /></Button>
                                    <Button size="small"><EyeOutlined></EyeOutlined></Button>
                                </div>

                            </List.Item>
                        )}
                    />
                </Row>
                <ModalRecordatorios
                    visible={formModal}
                    hideModal={hideModal}
                    reminderId={reminderId}
                />
            </Drawer>
        )
    }
}
