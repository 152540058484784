import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, Popconfirm, message, Pagination} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconEye, IconEdit,  IconDelete, IconPlus, IconArrowBack, IconOpen } from '../../Widgets/Iconos';
//modal
import ModalMonto from '../Modales/Presupuesto/ModalMonto';
//css
import '../../../Styles/presupuesto.css'

const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');

export default class DetalleMatierial extends Component{

	constructor(props){
		super(props)
		this.state = {
			inmueble_id: this.props.match.params.inmueble_id,
			actividad_id: this.props.match.params.tajo_id,
			materiales:[],
			actividad: {},
			inmueble: {},
			total_monto_materiales: 0,
			material_requerido_id: undefined,
			material_entrega_id: undefined,
			/* Paginado */
			page: 1,
			total: 0,

		}
	}

	volver = () => {
		window.history.back();
	}


	/**
	* @memberof DetalleMatierial
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getMateriales();
	}

	/**
	 * @methodOf  DetalleMatierial
	 * @function hideModal
	 *
	 * @description Cierra el modal
	 *
	 * */
	hideModal = () => {
		this.setState({ modalMontoVisible: false });
		this.getMateriales();
	};

	/**
	 * @methodOf  DetalleMatierial
	 * @function getMateriales
	 *
	 * @description trae la lista de materiales de un inmueble
	 *
	 * */
	getMateriales = (page = 1) => {
		this.setState({loading: true})
		axios.get('/presupuesto/detalle/materiales',{
			params:{
				page,
				inmueble_id: this.state.inmueble_id,
				actividad_id: this.state.actividad_id,
			}
		}).then(response => {
			this.setState({
				materiales: response.data.data.materiales.itemsList,
				inmueble: response.data.data.inmueble,
				actividad: response.data.data.actividad,
				total_monto_materiales: response.data.data.total,
				page: response.data.data.materiales.currentPage,
				total: response.data.data.materiales.itemCount,
				loading: false,
			})
		}).catch(error => {
			message.error('No se cargaron los materiales')
		})
	};



	render() {
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} className="">
						<Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
							<IconArrowBack />
						</Button>
						<h2 style={{width: 'auto', float: 'left'}}>Material para {this.state.actividad.nombre} {this.state.inmueble.nombre}</h2>
					</Col>
				</Row>
				<section className="p-1">
					<Row>
						<Col span={24}>
							<Card
								loading={this.state.loading}
								bordered
								className="card-proyeccionesDetalle"
							>
								<Row>
									<h1>Material para {this.state.actividad.nombre} </h1>
								</Row>

								<Row>
									<Col xs={24} lg={12} xl={6}>
										<label className="lblTitulo">Inmueble</label>
										<p className="pInfo">{this.state.inmueble.nombre}</p>
									</Col>
									<Col xs={24} lg={12} xl={6}>
										<label className="lblTitulo">Monto Pagado</label>
										<div>
											<p className="pInfo">$ {this.state.total_monto_materiales.toMoney(2)} MXN</p>
										</div>
									</Col>
									{/*<Col xs={24} lg={12} xl={6}>
										<label className="lblTitulo">Presupuesto</label>
										<div>
											<p className="pInfo">[Monto]</p>
										</div>
									</Col>*/}
								</Row>
							</Card>
						</Col>
					</Row>
					<div className="list-title">
						Bitacora
					</div>
					<div className="scroll">
						
						<List
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.materiales}
							loading={this.state.loading}
							locale={{ emptyText: 'Sin Prospectos' }}
							renderItem={item =>
								(<List.Item className="component-list-item">
									<Card className="card-list">
										<Row style={{ width: '100%' }} className="">
											<Col span={3} className="center">
												<Text>{moment(item.materiales_requeridos.entregas.fecha).format('DD/MM/YYYY')}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>{item.materiales_requeridos.material.nombre}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>{item.materiales_requeridos.entregas.cantidad}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>$ {item.materiales_requeridos.entregas.monto ? item.materiales_requeridos.entregas.monto.toMoney(2) : '0.00'} MXN</Text>
											</Col>
											<Col span={3} className="center">
												<Button type="primary" 
														className="button-survey btn-eye button-eye" title="Ver detalle" 
														onClick={()=>{this.setState({
															modalMontoVisible: true,
															material_requerido_id: item.materiales_requeridos._id,
															material_entrega_id: item.materiales_requeridos.entregas._id,
															monto_material: item.materiales_requeridos.entregas.monto ? item.materiales_requeridos.entregas.monto : 0
														})}}>
													<IconEye />
												</Button>
											</Col>
										</Row>
									</Card>
								</List.Item>
								)}
						/>
					</div>
					<Row className="mt-2 pb-3">
						<Pagination
							total={this.state.total}
							current={this.state.page}
							onChange={(page) => {
									this.getMateriales(page)
								}}
							/>
						</Row>
				</section>
				{/*<Button
					className="btnAddPlus"
					onClick={() => this.setState({modalProspectosVisible: true, modalTitle: 'Añadir'})}>
					<IconPlus/>
				</Button>*/}
				<ModalMonto
					modalVisible={this.state.modalMontoVisible}
					closeMethod={this.hideModal}
					objetivo='monto_material'
					inmueble_id={this.state.inmueble._id}
					actividad_id={this.state.actividad._id}
					material_requerido_id={this.state.material_requerido_id}
					material_entrega_id={this.state.material_entrega_id}
					monto={{monto: this.state.monto_material}}

				/>
			</div>
		)
	}
}