import React, { Component } from 'react';
import { Button, Col, Row, Spin, Empty, Typography, Card, Menu, Dropdown, message, List, Popconfirm, Tabs, Pagination, Tag, Popover, 
    Checkbox, Select } from 'antd'
import { FaArrowRight } from "react-icons/fa";
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link } from 'react-router-dom';

//componentes
import { CardInfoDashboard, CardProveedor, CardFacturas } from '../../Widgets/Cards/Cards';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import { IconEye, IconDelete, IconEdit, IconMoney, IconCross, IconChecks, IconPlus,IconArrowCustom as Arrow, IconBreak } from '../../Widgets/Iconos';
import ModalCotizacion from '../Modales/Proveedores/ModalCotizacion'
import ModalFactura from '../Modales/Proveedores/ModalFactura'
import ModalDetalle from '../Modales/Proveedores/ModalDetalle'
import {Reporte} from './ReporteCuenta'
//css
import '../../../Styles/Global.css'
import '../../../Styles/Proveedores.css'

const { Text, Title } = Typography;
const moment = require('moment');
const { TabPane } = Tabs;


export default class ProveedoresDetalle extends Component {

    reporteRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalCotizacionVisible: false,
            proveedor_id: this.props.match.params.id,
            proveedor: {
                linea_credito: 0
            },
            cotizaciones: [],
            pagCotizaciones: {},
            pagFacturas: {},
            pagTransacciones: {},
            facturas: [],
            factura: undefined,
            saldo: 0,
            monto_pagado: 0,
            vencido: 0,
            modalDetalleVisible: false,
            tipo_transaccion: undefined,
            abono_maximo: 0,
            proyectos: [],
            pro_facturas: [],
            pro_transacciones: [],
            pro_cotizaciones: [],
            loadingFacturas: false,
            loadingcotizaciones: false,
            loadingTran: false,
            monto_facturado: 0,
            monto_cotizado: 0,
            cotizaciones_monto: 0,

        }
    }


    /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method componentDidMount
     * @description 
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getProveedor(this.props.match.params.id)
        this.getProyectos()
    }

    /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method closeMethod
     * @description metodo que se ejecuta para cerrar el modal
     * @param flag bool si es true indica que se realizao un cambio en las facturas, por lo tanto actualiza la info
     */
    closeMethod = (flag) => {
        this.setState({
            modalCotizacionVisible: false, 
            crearCotizacion: true, 
            factura: undefined, 
            modalFacturaVisible: false,
            modalDetalleVisible: false,
            transaccion_id:undefined,
            tipo_transaccion: undefined
        })
        
        this.getSaldos();
        this.getFacturas(1,1) //facturas estatus 1 (cotizaciones)
        this.getFacturas(1,2) //facturas estatus 2
        this.getTransacciones(1) //transacciones hechas al proveedor
        
    }

    /**
     *
     *
     * @memberof ProveedoresDetalle
     * @function getProyectos
     *
     * @description Obtener proyectos.
     */
    getProyectos = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
            }
        }).then((proyectos) => {
            this.setState({proyectos: proyectos.data.data})
        }).catch((error) => {
            
        })
    }

    /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method getProveedor
     * @description Otiene la informacion del proveedor y sus facturas
     */
    getProveedor = (id) => {
        axios.get('/facturas/proveedor',{
            params:{
                proveedor_id: id
            }
        })
        .then(response=>{

            let transacciones_monto = 0, cotizaciones_monto = 0

            if(response.data.data.proveedor.transacciones?.length > 0){
                transacciones_monto = response.data.data.proveedor.transacciones[0] ? response.data.data.proveedor.transacciones[0].total_abonado : 0
                cotizaciones_monto = response.data.data.proveedor.transacciones[1] ? response.data.data.proveedor.transacciones[1].total_abonado : 0
            }


            this.setState({
                proveedor: response.data.data.proveedor,
                saldo: response.data.data.proveedor.saldo,
                monto_pagado: transacciones_monto,
                vencido: response.data.data.proveedor.vencido,
                saldo_total: response.data.data.proveedor.vencido + response.data.data.proveedor.saldo,
                abono_maximo: response.data.data.proveedor.abono_maximo,
                monto_facturado: response.data.data.proveedor.monto_facturado,
                monto_cotizado: response.data.data.proveedor.monto_cotizado,
                cotizaciones_monto: cotizaciones_monto,
                
                cotizaciones: response.data.data.cotizaciones.itemsList,
                pagCotizaciones:{
                    currentPage: response.data.data.cotizaciones.currentPage,
                    itemCount: response.data.data.cotizaciones.itemCount,
                },
                facturas: response.data.data.facturas.itemsList,
                pagFacturas:{
                    currentPage: response.data.data.facturas.currentPage,
                    itemCount: response.data.data.facturas.itemCount,
                },
                transacciones: response.data.data.transacciones.itemsList,
                pagTransacciones:{
                    currentPage: response.data.data.transacciones.currentPage,
                    itemCount: response.data.data.transacciones.itemCount,
                }
            })
        }).catch(error=>{
            console.log(error)
        })
    }

     /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method rechazarCotizacion
     * @description Cambia el estatus de una factura a rechazada
     */
    rechazarCotizacion = (id, estatus) => {
        this.setState({loadingFacturas: true})
        axios.post('/facturas/rechazar',{
            proveedor_id: this.props.match.params.id,
            factura_id: id,
            estatus
        })
        .then(response=>{
            message.success('Eliminada')
            this.getSaldos();
            this.getFacturas(1,1) //facturas estatus 1 (cotizaciones)
            this.getFacturas(1,2) //facturas estatus 2
            this.getTransacciones(1) //transacciones hechas al proveedor

        }).catch(error=>{
            message.error('Error, no se pudo eliminar')
            this.setState({loadingFacturas: false})
            console.log(error)
        })
    }

    /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method getFacturas
     * @description Obitiene cotizaciones o facturas dependiendo del estato que se le mande
     * @param estatus numeric 1:cotizaciones, 2:facturas
     */
    getFacturas = (page, estatus = 1 ) => {
        this.setState({loadingFacturas: true, loadingCotizaciones: true})
        axios.get('/facturas/list',{
            params:{
                page: page,
                proveedor_id: this.props.match.params.id,
                estatus,
                proyectos: estatus === 1 ? this.state.pro_cotizaciones : this.state.pro_facturas,
                sort: estatus === 1 ? this.state.sort_cotizaciones : this.state.sort_facturas,
            }
        })
        .then(response=>{
            if(estatus === 1){
                this.setState({ cotizaciones: response.data.data.itemsList,
                    pagCotizaciones:{
                        currentPage: response.data.data.currentPage,
                        itemCount: response.data.data.itemCount,
                    }})
            }else{ this.setState({
                    facturas: response.data.data.itemsList,
                    pagFacturas:{
                        currentPage: response.data.data.currentPage,
                        itemCount: response.data.data.itemCount,
                    }
                })
            }

        }).catch(error=>{
            console.log(error)
            this.setState({loadingFacturas: false, loadingCotizaciones: false})
        }).finally(()=>{
            if(estatus === 1){
                this.setState({loadingCotizaciones: false})
            }else{
                this.setState({loadingFacturas: false})
            }
        })
    }

    /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method getSaldos
     * @description Obitiene los saldos actualizados
     */
    getSaldos = () => {
        axios.get('/facturas/saldos',{
            params:{
                proveedor_id: this.props.match.params.id,
            }
        })
        .then(response=>{
            console.log(response.data.data)

            let transacciones_monto = 0, cotizaciones_monto = 0

            if(response.data.data.transacciones?.length > 0){
                transacciones_monto = response.data.data.transacciones[0] ? response.data.data.transacciones[0].total_abonado : 0
                cotizaciones_monto = response.data.data.transacciones[1] ? response.data.data.transacciones[1].total_abonado : 0
            }

            this.setState({
                saldo: response.data.data.saldo,
                monto_pagado: transacciones_monto,
                vencido: response.data.data.vencido,
                saldo_total: response.data.data.saldo + response.data.data.vencido,
                abono_maximo: response.data.data.abono_maximo,
                cotizaciones_monto: cotizaciones_monto,
                monto_cotizado: response.data.data.monto_cotizado,
                monto_facturado: response.data.data.monto_facturado,
            })

        }).catch(error=>{
            console.log(error)
        })
    }

     /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method getTransacciones
     * @description Obitiene cotizaciones o facturas dependiendo del estato que se le mande
     * @param estatus numeric 1:cotizaciones, 2:facturas
     */
    getTransacciones = (page = 1) => {
        this.setState({loadingTran: true})
        axios.get('/facturas/transacciones',{
            params:{
                page: page,
                proveedor_id: this.props.match.params.id,
                sort: this.state.sort_transacciones,
                proyectos: this.state.pro_transacciones
            }
        })
        .then(response=>{
            this.setState({
                transacciones: response.data.data.itemsList,
                pagTransacciones:{
                    currentPage: response.data.data.currentPage,
                    itemCount: response.data.data.itemCount,
                }
            })
           
        }).catch(error=>{
            console.log(error)
        }).finally(()=>{this.setState({loadingTran: false})})
    }

     /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method deleteTransaccion
     * @description Obitiene cotizaciones o facturas dependiendo del estato que se le mande
     * @param estatus numeric 1:cotizaciones, 2:facturas
     */
    deleteTransaccion = (id,unlink) => {

        let url = (unlink === true )? '/transacciones/unlink' : '/transacciones/delete'
        let message_ = (unlink === true )? 'Transaccion Desvinculada' : 'Transaccion Eliminada'

        axios.post(url,{
            id,
        })
        .then(response=>{
            message.success(message_)
            this.getTransacciones(1)
            this.getFacturas(1,2)
            this.getFacturas(1,1) //facturas estatus 1 (cotizaciones)
            this.getSaldos();
        }).catch(error=>{
            console.log(error)
            message.success('Error')
        })
    }


    /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method renderTags
     * @description renderiza tags dependiendo del estatus o el proyecto
     */
    renderTags = (tipo, item, estatus) => {
        if(tipo === 'estatus'){

            let tags = {
                '1': <Tag className="tag-proyecto" color="#226dff">Vigente</Tag>,
                '2': <Tag className="tag-proyecto" color="#e1c634">Por Vencer</Tag>,
                '3': <Tag className="tag-proyecto" color="#fa1e1e">Vencida</Tag>,
                '4': <Tag className="tag-proyecto" color="#3e8c12">Liquidada</Tag>,
                '5': <Tag className="tag-proyecto" color="purple">Factura</Tag> 
            }

            return tags[item]
        }else{
            return <Tag className="tag-proyecto" color={item.color}>{item.nombre}</Tag>
        }
    }

     /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method onChangeProyecto
     * @description cambia el state para filtrar por proyecto
     */
    onChangeProyecto = (proyecto_id, checked, tipo) => {
        
        const { pro_transacciones, pro_facturas, pro_cotizaciones } = this.state;
        let index = null
        switch(tipo){
            case 'transacciones':
                index = pro_transacciones.indexOf(proyecto_id.toString())
                if(index !== -1) pro_transacciones.splice(index,1)
                else pro_transacciones.push(proyecto_id)
                this.setState({pro_transacciones},()=>{this.getTransacciones(1)})
            break;
            case 'facturas':
                index = pro_facturas.indexOf(proyecto_id.toString())
                if(index !== -1) pro_facturas.splice(index,1)
                else pro_facturas.push(proyecto_id)
                this.setState({pro_facturas},()=>{this.getFacturas(1,2)})
            break;
            case 'cotizaciones':
                index = pro_cotizaciones.indexOf(proyecto_id.toString())
                if(index !== -1) pro_cotizaciones.splice(index,1)
                else pro_cotizaciones.push(proyecto_id)
                this.setState({pro_cotizaciones},()=>{this.getFacturas(1,1)})
            break;
       }
    }

     /**
     *
     *
     * @memberof ProveedoresDetalle
     * 
     * @method onChangeSelect
     * @description cambia el state para ordenar los resultados
     */
    onChangeSelect = (sort, tipo) => {
        
        const { sort_transacciones, sort_facturas, sort_cotizaciones } = this.state;
        switch(tipo){
            case 'transacciones':
                this.setState({sort_transacciones: sort},()=>{this.getTransacciones(1)})
            break;
            case 'facturas':
                this.setState({sort_facturas: sort},()=>{this.getFacturas(1,2)})
            break;
            case 'cotizaciones':
                this.setState({sort_cotizaciones: sort},()=>{this.getFacturas(1,1)})
            break;
       }
    }


    /**
     * @memberof getInfoReports
     * 
     * @method getTransacciones
     * @description trae las facturas de un proveedor para hacer el reporte
     */
    getInfoReports = async (proveedor_id,proveedor) => {

        let facturas = await  axios.get('/facturas/list',{
            params: {
                paginate: false,
                proveedor_id: proveedor_id,
                estatus: 2, //se buscan facturas de estatus 2 y 3
            }
        })

         let transacciones = await axios.get('/facturas/transacciones',{
            params: {
                paginate: false,
                proveedor_id: proveedor_id,
                sin_cotizaciones: true // quitamos las transcciones hechas a cotizaciones
            }
        })

        let saldos = await axios.get('/facturas/saldos',{
            params: {
                proveedor_id: proveedor_id,
                reporte: true,
            }
        })

        if(transacciones && saldos && facturas){
            this.reporteRef.current.loadFacturas(facturas.data.data, proveedor, transacciones.data.data, saldos.data.data)
        }
    }


    render() {

        const contentMenuPlus = (
            <div className="div-menuplus">
                <Button htmlType="submit" type="primary" className="btn-azul" 
                    onClick={()=>{this.setState({modalCotizacionVisible: true, factura: undefined, crearCotizacion: true})}}>
                    Crear Cotización
                </Button>
                <Button htmlType="submit" type="primary" className="btn-azul" 
                    onClick={()=>this.setState({modalFacturaVisible: true, factura:undefined, tipo_transaccion: 'Abono'})}>
                    Abono a Facturas
                </Button>
            </div>
        );

        const { proveedor, saldo, monto_pagado, vencido, saldo_total, monto_facturado, monto_cotizado, cotizaciones_monto } = this.state;

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={6} xxl={8}>
                            <h2 style={{ width: 'auto', float: 'left' }}> Proveedor {proveedor.alias} </h2>
                        </Col>
                        <Col span={4} className="center">
                            <Button className="btn-reportes" onClick={()=>{this.getInfoReports(this.state.proveedor._id, this.state.proveedor)}}>
                               Estado de Cuenta <img src="/images/invoice.svg" alt="facturas" height="42"/>
                            </Button>
                        </Col>
                        <Reporte ref={this.reporteRef}/>
                    </Row>

                    <section className="p-1">
                        <Row>
                            <Col span={24}>
                                <Card
                                    title=""
                                    loading={this.state.loading}
                                    bordered
                                    className="card-proyeccionesDetalle"
                                >
                                    <Row>
                                        <Title className="list-title-blue " level={3}>Información Proveedor</Title>
                                    </Row>

                                    <Row>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Nombre</label>
                                            <p className="pInfo">{proveedor.alias}</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Razon Social</label>
                                            <p className="pInfo">{proveedor.razon_social}</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Linea de Credito</label>
                                            <p className="pInfo">$ {proveedor.linea_credito?.toMoney(2)} MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Plazo de Credito</label>
                                            <p className="pInfo">{proveedor.plazo} dias</p>
                                        </Col>
                                    </Row>
                                    { proveedor.documentos ? <Row>
                                        <Col span={24}>
                                            <label className="lblTitulo">Archivos</label>
                                            {proveedor.documentos?.map(doc=><a className="mr-1" target="_blank" href={`${axios.defaults.baseURL}/voucher/${doc}`}>{doc}</a>)}
                                        </Col>
                                    </Row> : null}
                                    <Row>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Monto Facturado</label>
                                            <p className="pInfo" style={{color: '#FF0000'}}>$ {monto_facturado?.toMoney(2) } MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Monto Actual Pagado</label>
                                            <p className="pInfo" style={{color: '#04A514'}}>$ { monto_pagado?.toMoney(2)} MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Saldo Total</label>
                                            <p className="pInfo">$ {(monto_facturado - monto_pagado).toMoney(2)} MXN</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Monto Cotizados</label>
                                            <p className="pInfo" style={{color: '#FF0000'}}>$ {monto_cotizado?.toMoney(2) } MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Monto Cotizado Pagado</label>
                                            <p className="pInfo" style={{color: '#04A514'}}>$ { cotizaciones_monto?.toMoney(2)} MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Saldo Total</label>
                                            <p className="pInfo">$ {(monto_cotizado - cotizaciones_monto).toMoney(2)} MXN</p>
                                        </Col>
                                    </Row>
                                    {/*<Row>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Saldo Sin Vencer</label>
                                            <p className="pInfo" style={{color: '#FAAD14'}}>$ {saldo.toMoney(2)} MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Monto Actual Pagado</label>
                                            <p className="pInfo" style={{color: '#04A514'}}>$ { monto_pagado.toMoney(2)} MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Saldo Vencido</label>
                                            <p className="pInfo" style={{color: '#FF0000'}}>$ {vencido.toMoney(2) } MXN</p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Saldo Total</label>
                                            <p className="pInfo">$ {saldo_total?.toMoney(2)} MXN</p>
                                        </Col>
                                    </Row>*/}

                                </Card>
                            </Col>
                        </Row>

                        <Tabs className="tabs-proveedor">
                            <TabPane tab="Transacciones" key="1">
                                <Row align="end mt-1 mb-1">
                                    <Col span={4} className='center'>
                                        <Dropdown
                                            trigger={['click']}
                                            className="dropdown-filtro"
                                            overlay={
                                                <Menu>
                                                    {this.state.proyectos?.map(({ _id, nombre, logo, color }, index) => (
                                                        <Menu.Item key={index}>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    this.onChangeProyecto(_id, e.target.checked,'transacciones')
                                                                }}>
                                                                <CustomAvatar
                                                                    image={logo}
                                                                    name={nombre}
                                                                    color={color}
                                                                    size="small"
                                                                    style={{
                                                                        margin: '0 5px'
                                                                    }}
                                                                />
                                                                {nombre}
                                                            </Checkbox>
                                                        </Menu.Item>
                                                    ))}
                                                </Menu>
                                            }>
                                            <p>Proyectos <Arrow className="arrow-blue" /></p>
                                        </Dropdown>
                                    </Col>
                                    <Col span={4} className='center'>
                                        <Select 
                                            onChange={value=>{this.onChangeSelect(value,'transacciones')}}
                                            className="select-filtro" 
                                            placeholder="Ordenar por">
                                            <Select.Option value={'fecha'}>Fecha</Select.Option>
                                            <Select.Option value={'concepto'}>Concepto</Select.Option>
                                            <Select.Option value={'proyecto'}>Proyecto</Select.Option>
                                        </Select>
                                    </Col>
                                    <Col span={1}>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                                    <Col span={3} className="center">
                                        <Text type="secondary">Fecha</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Concepto</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Rubro</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Sub Rubro</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Proyecto</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Cuenta</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Monto</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Acciones</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <List
                                        className="component-list"
                                        itemLayout="horizontal"
                                        dataSource={this.state.transacciones}
                                        locale={{ emptyText: 'Sin Transacciones' }}
                                        loading={this.state.loadingTran}
                                        renderItem={item => (
                                            <List.Item className="component-list-item lista-materiales">
                                                <Card className="card-list">
                                                    <Row style={{ width: '100%' }} className="">
                                                        <Col span={3} className="center">
                                                            <Text strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text>{item.concepto}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text>{item.rubro_id.nombre}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text>{item.sub_rubro_id.nombre}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text>{this.renderTags('pro',item.proyecto_id)}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text>{item.cuenta_id.nombre}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text strong>$ {item.monto.toMoney()} MXN</Text>
                                                        </Col>
                                                        <Col span={3} className="">
                                                            <Link>
                                                                <Button type="primary" className="button-survey btn-eye button-eye"
                                                                    title="Ver detalle"
                                                                    onClick={()=>{
                                                                        this.setState({transaccion_id: item._id, modalDetalleVisible: true})
                                                                    }}
                                                                >
                                                                    <IconEye />
                                                                </Button>
                                                            </Link>
                                                            {/*<Button className="btn btn-edit"
                                                                onClick={()=>{
                                                                    this.setState({transaccion_id: item._id, modalFacturaVisible: true})
                                                                }}
                                                            >
                                                                <IconEdit />
                                                            </Button>*/}
                                                            <Popconfirm
                                                                title={"¿Deseas DESVINCULAR la transaccción?"}
                                                                placement="topRight"
                                                                onConfirm={e => {
                                                                }}
                                                                onCancel={e => e.stopPropagation()}
                                                                okText="Si"
                                                                cancelText="No"
                                                                onConfirm={e => this.deleteTransaccion(item._id, true)}
                                                            >
                                                                <Button type="primary" className="btn-eye button-eye"
                                                                    style={{ backgroundColor: "#ffc107" }}
                                                                >
                                                                        <IconBreak color="white" />
                                                                </Button>
                                                            </Popconfirm>
                                                            <Popconfirm
                                                                title={"¿Deseas Eliminar la transaccción?"}
                                                                placement="topRight"
                                                                onConfirm={e => {
                                                                }}
                                                                onCancel={e => e.stopPropagation()}
                                                                okText="Si"
                                                                cancelText="No"
                                                                onConfirm={e => this.deleteTransaccion(item._id)}
                                                            >

                                                                <Button className="btn btn-delete"
                                                                    onClick={e => e.stopPropagation()}>
                                                                    <IconDelete />
                                                                </Button>
                                                            </Popconfirm>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className="mt-2 mb-3">
                                        <Pagination 
                                            current={this.state.pagTransacciones.currentPage}
                                            total={this.state.pagTransacciones.itemCount}
                                            onChange={(page)=>{
                                                this.getTransacciones(page)
                                            }} 
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Facturas" key="2">
                                <Row align="end mt-1 mb-1">
                                    <Col span={4} className='center'>
                                        <Dropdown
                                            trigger={['click']}
                                            className="dropdown-filtro"
                                            overlay={
                                                <Menu>
                                                    {this.state.proyectos?.map(({ _id, nombre, logo, color }, index) => (
                                                        <Menu.Item key={index}>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    this.onChangeProyecto(_id, e.target.checked,'facturas')
                                                                }}>
                                                                <CustomAvatar
                                                                    image={logo}
                                                                    name={nombre}
                                                                    color={color}
                                                                    size="small"
                                                                    style={{
                                                                        margin: '0 5px'
                                                                    }}
                                                                />
                                                                {nombre}
                                                            </Checkbox>
                                                        </Menu.Item>
                                                    ))}
                                                </Menu>
                                            }>
                                            <p>Proyectos <Arrow className="arrow-blue" /></p>
                                        </Dropdown>
                                    </Col>
                                    <Col span={4} className='center'>
                                        <Select 
                                            onChange={value=>{this.onChangeSelect(value,'facturas')}}
                                            className="select-filtro" placeholder="Ordenar por">
                                            <Select.Option value={'fecha'}>Fecha</Select.Option>
                                            <Select.Option value={'concepto'}>Concepto</Select.Option>
                                            <Select.Option value={'proyecto'}>Proyecto</Select.Option>
                                            <Select.Option value={'estatus'}>Estatus</Select.Option>
                                        </Select>
                                    </Col>
                                    <Col span={1}>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                                    <Col span={2} className="center">
                                        <Text type="secondary">Fecha Vigencia</Text>
                                    </Col>
                                    <Col span={2} className="center">
                                        <Text type="secondary">Estatus</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text type="secondary">Concepto</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text type="secondary">Proyecto</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Monto</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Monto Pagado</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Monto Restante</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Acciones</Text>
                                    </Col>
                                </Row>
                               <Row>
                                    <Col span={24}>
                                        <List
                                        className="component-list"
                                        itemLayout="horizontal"
                                        dataSource={this.state.facturas}
                                        loading={this.state.loadingFacturas}
                                        locale={{ emptyText: 'Sin Facturas' }}
                                        renderItem={item => (
                                            <List.Item className="component-list-item lista-materiales">
                                                <Card className="card-list">
                                                    <Row style={{ width: '100%' }} className="">
                                                        <Col span={2} className="center">
                                                            <Text strong>{moment(item.fecha_vigencia).format('DD-MM-YYYY')}</Text>
                                                        </Col>
                                                        <Col span={2} className="center">
                                                            <Text>{this.renderTags('estatus',item.info_estatus)}</Text>
                                                        </Col>
                                                        <Col span={4} className="center">
                                                            <Text>{item.concepto}</Text>
                                                        </Col>
                                                        <Col span={4} className="center">
                                                            <Text>{this.renderTags('pro',item.proyecto_id)}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text strong>$ {item.monto.toMoney()} MXN</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text strong>$ {item?.monto_pagado?.toMoney()} MXN</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text strong>$ {item?.monto_restante?.toMoney()} MXN</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Button type="primary" className="button-survey btn-eye button-eye"
                                                            onClick={(e) => this.setState({factura: item, modalDetalleVisible:true})}
                                                                title="Ver detalle">
                                                                <IconEye />
                                                            </Button>
                                                            <Button disabled={item.estatus === 3} className="btn btn-money" title="Pagar" 
                                                                onClick={(e) => {this.setState({factura: item, modalFacturaVisible:true})}}
                                                            >
                                                                <IconMoney />
                                                            </Button>
                                                            {item.estatus !== 3 ? <Popconfirm
                                                                title={"¿Deseas eliminar la factura ?"}
                                                                placement="topRight"
                                                                onCancel={e => e.stopPropagation()}
                                                                okText="Si"
                                                                cancelText="No"
                                                                onConfirm={e => this.rechazarCotizacion(item._id,2)}
                                                            >

                                                                <Button className="btn btn-delete"
                                                                    onClick={e => e.stopPropagation()}>
                                                                    <IconCross />
                                                                </Button>
                                                            </Popconfirm> : <Button disabled={true} className="btn btn-delete"><IconCross /> </Button>}
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className="mt-2 mb-3">
                                        <Pagination 
                                            current={this.state.pagFacturas.currentPage}
                                            total={this.state.pagFacturas.itemCount}
                                            onChange={(page)=>{
                                                this.getFacturas(page,2)
                                            }} 
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Cotizaciones" key="3">
                                <Row align="end mt-1 mb-1">
                                    <Col span={4} className='center'>
                                        <Dropdown
                                            trigger={['click']}
                                            className="dropdown-filtro"
                                            overlay={
                                                <Menu>
                                                    {this.state.proyectos?.map(({ _id, nombre, logo, color }, index) => (
                                                        <Menu.Item key={index}>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    this.onChangeProyecto(_id, e.target.checked,'cotizaciones')
                                                                }}>
                                                                <CustomAvatar
                                                                    image={logo}
                                                                    name={nombre}
                                                                    color={color}
                                                                    size="small"
                                                                    style={{
                                                                        margin: '0 5px'
                                                                    }}
                                                                />
                                                                {nombre}
                                                            </Checkbox>
                                                        </Menu.Item>
                                                    ))}
                                                </Menu>
                                            }>
                                            <p>Proyectos <Arrow className="arrow-blue" /></p>
                                        </Dropdown>
                                    </Col>
                                    <Col span={4} className='center'>
                                        <Select 
                                            onChange={value=>{this.onChangeSelect(value,'cotizaciones')}}
                                            className="select-filtro" placeholder="Ordenar por">
                                            <Select.Option value={'fecha'}>Fecha</Select.Option>
                                            <Select.Option value={'concepto'}>Concepto</Select.Option>
                                            <Select.Option value={'proyecto'}>Proyecto</Select.Option>
                                        </Select>
                                    </Col>
                                    <Col span={1}>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                                    <Col span={3} className="center">
                                        <Text type="secondary">Fecha Creación</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Estatus</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text type="secondary">Concepto</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text type="secondary">Proyecto</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Monto</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Monto Restante</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text type="secondary">Acciones</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <List
                                        className="component-list"
                                        itemLayout="horizontal"
                                        dataSource={this.state.cotizaciones}
                                        //loading={this.state.loadingCotizaciones}
                                        locale={{ emptyText: 'Sin Cotizaciones' }}
                                        renderItem={item => (
                                            <List.Item className="component-list-item lista-materiales">
                                                <Card className="card-list">
                                                    <Row style={{ width: '100%' }} className="">
                                                        <Col span={3} className="center">
                                                            <Text strong>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text>{this.renderTags('estatus',item.info_estatus)}</Text>
                                                        </Col>
                                                        <Col span={4} className="center">
                                                            <Text>{item.concepto}</Text>
                                                        </Col>
                                                        <Col span={5} className="center">
                                                            <Text>{this.renderTags('pro',item.proyecto_id)}</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text strong>$ {item.monto?.toMoney(2)} MXN</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            <Text strong>$ {item.monto_restante?.toMoney(2)} MXN</Text>
                                                        </Col>
                                                        <Col span={3} className="center">
                                                            {item.monto_restante < item.monto ?<Button className="btn btn-edit" title="Editar"
                                                                disabled={true}
                                                            >
                                                                <IconEdit />
                                                            </Button>: <Button className="btn btn-edit" title="Editar"
                                                                onClick={()=>{
                                                                    let obj = item
                                                                    if(item.cotizacion_pdf){
                                                                        obj.archivo =  [{
                                                                            uid: 1,
                                                                            response: {
                                                                                filename: item.cotizacion_pdf
                                                                            },
                                                                            name: item.cotizacion_pdf,
                                                                            url: axios.defaults.baseURL + "/voucher/" + item.cotizacion_pdf,
                                                                            status: 'done',
                                                                        }] 
                                                                    }
                                                                    this.setState({
                                                                        factura: obj,
                                                                        modalCotizacionVisible: true,
                                                                        crearCotizacion: true
                                                                    })
                                                                }}
                                                            >
                                                                <IconEdit />
                                                            </Button>}
                                                            {item.monto_restante < item.monto ? <Button 
                                                                type="primary" className="btn btn-check"
                                                                disabled={true}
                                                                title="Aprobar Cotización">
                                                                <IconChecks />
                                                            </Button>
                                                            :<Button type="primary" className="btn btn-check"
                                                                onClick={()=>{
                                                                    item.archivo = []
                                                                    this.setState({
                                                                        factura: item,
                                                                        modalCotizacionVisible: true,
                                                                        crearCotizacion: false
                                                                    })
                                                                }}
                                                                title="Aprobar Cotización">
                                                                <IconChecks />
                                                            </Button>}
                                                            <Popconfirm
                                                                title={"¿Deseas rechazar la cotizacion?"}
                                                                placement="topRight"
                                                                onConfirm={e => {
                                                                }}
                                                                onCancel={e => e.stopPropagation()}
                                                                okText="Si"
                                                                cancelText="No"
                                                                onConfirm={e => this.rechazarCotizacion(item._id,1)}
                                                            >

                                                                <Button className="btn btn-delete"
                                                                    onClick={e => e.stopPropagation()}>
                                                                     <IconCross />
                                                                </Button>
                                                            </Popconfirm>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24} className="mt-2 mb-3">
                                        <Pagination 
                                            current={this.state.pagCotizaciones.currentPage}
                                            total={this.state.pagCotizaciones.itemCount}
                                            onChange={(page)=>{
                                                this.getFacturas(page,1)
                                            }} 
                                        />
                                    </Col>
                                </Row>
                            </TabPane>  
                        </Tabs>                    
                    </section>
                </Spin>
                <Popover content={contentMenuPlus}>
                    <Button className="btnAddPlus" style={{ padding: "20px 20px" }}>
                        <IconPlus />
                    </Button>
                </Popover>
                <ModalCotizacion
                    visible={this.state.modalCotizacionVisible}
                    closeMethod={this.closeMethod}
                    factura={this.state.factura}
                    crearCotizacion={this.state.crearCotizacion}
                    proveedor={this.state.proveedor}
                />
                <ModalFactura
                    visible={this.state.modalFacturaVisible}
                    closeMethod={this.closeMethod}
                    factura={this.state.factura}
                    proveedor={this.state.proveedor}
                    transaccion_id={this.state.transaccion_id}
                    abono_maximo={this.state.abono_maximo}
                    tipo_transaccion={this.state.tipo_transaccion}
                />
                <ModalDetalle
                    visible={this.state.modalDetalleVisible}
                    closeMethod={this.closeMethod}
                    factura={this.state.factura}
                    transaccion_id={this.state.transaccion_id}
                />
            </div>
        )
    }
}
