import React from 'react'
import { Route, Switch } from "react-router-dom";


import Budgeting from '../../Components/Admin/Budgeting/Budgeting';
import Conceptos from '../../Components/Admin/Budgeting/Conceptos';
import Transacciones from '../../Components/Admin/Transacciones/Transacciones';


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
 export default function(props) {

    return (
        <Switch>
            <Route exact path="/admin/budgeting" render={() => <Budgeting {...props} />} />
            <Route exact path="/admin/budgeting/:proyecto_id" render={(props_h) => <Budgeting {...props} {...props_h} />} />
            <Route exact path="/admin/budgeting/conceptos/:concepto_id/:sub_concepto_id" render={(props_h) => <Conceptos {...props} {...props_h}/>} />
            <Route exact path="/admin/budgeting/conceptos/:concepto_id" render={(props_h) => <Conceptos {...props} {...props_h}/>} />
            <Route exact path="/admin/budgeting/:proyecto_id/transacciones/sin-clasificar/" render={(props_h) => <Transacciones clasificadas={true}  {...props} {...props_h} />} />
        
        </Switch>
    )
}

