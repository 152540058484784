import React, { Component } from "react";
import {
    Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List,
    message, Divider, Upload, DatePicker
} from 'antd';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

class ModalTransaccionInversion extends Component {

    modalInversion = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            cuentas: []
        }
    }

    /**
    * @memberof ModalTransaccionInversion
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
        this.getCuentas()
    }


    /**
    * @memberof ModalTransaccionInversion
    * @method   getCuentas
    * @description  Trae la lista de cuentas
    **/
    getCuentas = () => {
        axios.get('/cuentas/list',{
            params:{
                transacciones: true,
                pagination: false,
            }
        }).then(response => {
            console.log(response)
            this.setState({cuentas: response.data.data})
        }).catch(error => {
            console.log(error)
        })
    }

    /**
    * @memberof ModalTransaccionInversion
    * @method   onFinish
    * @description  Añade una nueva transaccion
    **/
    onFinish = (values) => {
        axios.post('/inversiones/transaccion/add',{
            ...values,
            inversion_id: this.props.inversion_id
        }).then(response => {
            message.success('Transaccion creada')
            this.props.onClose()
        }).catch(error => {
            if(error.response?.status === 403)
                message.error(error.response?.data?.message)
            else
                message.error('Error al crear la transaccion')
        })
    }

    
    render() {


        return (
            <Form
                layout="vertical"
                className="frm-cuentas"
                name="form-materiales"
                ref={this.modalInversion}
                onFinish={this.onFinish}

            >
                <Row align="center" gutter={20} style={{margin: '0'}}>

                    <Col sm={24} lg={12} className="gutter-row">
                        <Form.Item
                            label="Concepto"
                            name="concepto" //<--------------------------------------- concepto
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese el monto"
                            }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col sm={24} lg={12} className="gutter-row">
                        <Form.Item
                            label="Monto"
                            name="monto" //<--------------------------------------- monto
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese el monto"
                            }]}
                        >
                            <InputNumber/>
                        </Form.Item>
                    </Col>

                    <Col sm={24} lg={12} className="gutter-row">
                        <Form.Item
                            label="Fecha"
                            name="fecha_venta" //<--------------------------------------- fecha_venta
                        >
                             <DatePicker/>
                        </Form.Item>
                    </Col>


                    <Col sm={24} lg={12} className="gutter-row">
                       <Form.Item
                            label="Cuenta"
                            name="cuenta_id"//----------------------------------cuenta
                            rules={[{
                                required: true,
                                message: "Por favor, seleccione la cuenta"
                            }]}
                        >
                            <Select className="form-select" placeholder="Seleccione la cuenta"
                            >
                                {this.state.cuentas.map(function (cuenta, index) {
                                    return <Option value={cuenta._id}>{cuenta.nombre}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <IconArrowSelect />
                    </Col>
                </Row>

                <Row style={{ textAlign: "center" }}>
                    <Col span={24}>
                        <Form.Item >
                            <Button htmlType="submit" type="primary" className="btn-azul" >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}



export default function (props) {

    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={onClose}>
                <IconCloseModal />
            </Button>
            <Title level={3} className="modal-title"> {props.modalTitle} Inversión  </Title>
        </div>
        <ModalTransaccionInversion {...props} />
    </Modal>

}