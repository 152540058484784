import React, {Component} from 'react'

const Logged = React.createContext("ma");
let User = React.createContext(null);

export{
    User
}

export default Logged;
