import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, List, Typography, message, Spin, Select, InputNumber } from 'antd';

//compoenentes
import { IconCloseModal } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'

import axios from 'axios'
const moment = require('moment');

const { Title, Text } = Typography;
const { Option } = Select;

class ModalProductosInventario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            productos: [],
            producto_opciones: [],
            producto_id: undefined,
        }
    }

    //Referencia de form
    formProductoInventario = React.createRef();

    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProductos()

        if(this.props.inventario_producto_id){
            this.getProductoOpcion()
        }
    }


    /**
     * @memberof ModalProductosInventario
     * @function getProductos
     *
     * @description Obtener lista de productos, que no esten  dados de alta en el Inventario
     */
    getProductos = (search) => {
        axios.get('/productos/list', {
            params: {
                search,
            }
        }).then((response) => {
        	this.setState({productos: response.data.data.data})
            
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }

    /**
     * @memberof ModalProductosInventario
     * @function onSelectProducto
     *
     * @description Pone en el state el producto seleccionado y busca los productos_opciones del mismo
     */
    onSelectProducto = (producto_id) => {
        this.formProductoInventario.current.setFieldsValue({
            precio: null,
            producto_opcion_id: null,
        })
    	this.setState({producto_id: producto_id.value},()=>{
    		this.getProductoOpciones()
    	})
    }

    /**
     * @memberof ModalProductosInventario
     * @function getProductoOpciones
     *
     * @description Obtiene los productos_opciones del producto seleccionado
     */
    getProductoOpciones = (search) => {
    	this.setState({loading: true})
    	axios.get('/productos', {
            params: {
                producto_id: this.state.producto_id,
                inventario_id: this.props.inventario_id,//productos que no esten en este inventario
                proyecto_id: this.props.proyecto_id,//en caso de no haber inventario_id
                search
            }
        }).then((response) => {
        	this.setState({producto_opciones: response.data.data.data})
            
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof ModalProductosInventario
     * @function onSelectProductoOpcion
     *
     * @description Actualiza el campo precio del formulario al seleccinar un producto_objeto
     */
    onSelectProductoOpcion = (producto_opcion_id) => {
    	let index = this.state.producto_opciones.findIndex(p_o => p_o._id.toString() === producto_opcion_id.value.toString())

    	if(index != -1){
    		return this.formProductoInventario.current?.setFieldsValue({
    			precio: this.state.producto_opciones[index].costo,
    		})
    	}
    }

    /**
     * @memberof ModalProductosInventario
     * @function getProductoOpcion
     *
     * @description Obtiene el inventario_producto para editar
     */
    getProductoOpcion = () => {

        this.setState({loading: true})
        axios.get('/inventarios/productos/get',{
            params:{
                id: this.props.inventario_producto_id
            }
        }).then(response => {

            let inventario_producto_id = response.data.data
            this.setState({producto_id: inventario_producto_id.producto_id?._id},()=>{
                this.getProductoOpciones()
            })

            this.formProductoInventario.current.setFieldsValue({
                producto_id: {
                    value: inventario_producto_id.producto_id?._id,
                    key: inventario_producto_id.producto_id?._id,
                    label: <Text>{inventario_producto_id.producto_id?.nombre} <small>{inventario_producto_id.producto_id?.sku_manantial}</small> </Text>
                },
                inv_minimo: inventario_producto_id.inv_minimo,
                inv_maximo: inventario_producto_id.inv_maximo,
                precio: inventario_producto_id.precio,
            })
            
        }).catch(error => {
            let msg = 'Error al obtener la información'
            message.error(msg)
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalProductosInventario
     * @function onFinish
     *
     * @description Se ejecuta al hacer submit al formulario
     */
    onFinish = (values) => {

    	//formatemos los valores
    	values.producto_id = values.producto_id.value

    	if(this.props.inventario_producto_id){
            this.updateInventarioProducto(values)
        }else{
            this.addInventarioProducto(values)
        }
    }


    /**
     * @memberof ModalProductosInventario
     * @function addInventarioProducto
     *
     * @description Añade un nuevo producto al inventario
     */
    addInventarioProducto = (values) => {

        this.setState({loading: true})
        axios.post('/inventarios/productos/add',{
            ...values,
            inventario_id: this.props.inventario_id,
            proyecto_id: this.props.proyecto_id,//en caso de no haber inventario_id
        }).then(response => {
            message.success('Producto agregado con exito')
            this.props.onCancel()
        }).catch(error => {
            console.log("error", error);
            let msg = 'Error al agregar el producto'
            if(error?.response?.status === 403)
                msg = error.response.data.message
            message.error(msg)
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalProductosInventario
     * @function updateInventarioProducto
     *
     * @description Actualiza la informacion del producto del inventario
     */
    updateInventarioProducto = (values) => {

        this.setState({loading: true})
        axios.put('/inventarios/productos/update',{
            ...values,
            inventario_id: this.props.inventario_id,
            proyecto_id: this.props.proyecto_id,//en caso de no haber inventario_id
            id: this.props.inventario_producto_id,
        }).then(response => {
            message.success('Producto actualizado con exito')
            this.props.onCancel()
        }).catch(error => {
            let msg = 'Error al actualizar el producto'
            if(error.response.status === 403)
                msg = error.response.data.message
            message.error(msg)
        }).finally(()=>this.setState({loading: false}))
    }



    render() {


        return [
            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title"> {this.props.inventario_producto_id ? 'Editar producto del' : 'Añadir producto al'} Inventario</Title>
            </div>,
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.formProductoInventario}
                    onFinish={this.onFinish}
                    initialValues={{
                    	inv_minimo: 10,
                    	inv_maximo: 100,
                    }}

                >
                    <Row align="center" gutter={[16]}>

                        <Col xs={22}>
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                                rules={[
                                    { required: true, message: "Seleccione un proyecto" }
                                ]} 
                            >
                                <Select
                                    labelInValue
                                    showSearch
                                    className="form-select"
                                    placeholder="Seleccione un Producto"
                                    onSearch={(value)=>this.getProductos(value)}
                                    onSelect={(value)=>this.onSelectProducto(value)}
                                    filterOption={false}
                                    disabled={this.props.inventario_producto_id !== undefined}
                                >
                                    {this.state.productos.map((producto) =>  <Option key={producto._id} value={producto._id}>
                                        <span>{producto.nombre}</span><br/> 
                                        <small>{producto.sku_manantial}</small>
                                    </Option>)}

                                </Select>    
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                        	<Form.Item
                                label="Inventario mínimo"
                                name="inv_minimo"
                                rules={[
                                    { required: true, message: "Ingrese el inventario mínimo" }
                                ]} 
                            >
                                <InputNumber min={1}/>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                        	<Form.Item
                                label="Inventario máximo"
                                name="inv_maximo"
                                rules={[
                                    { required: true, message: "Ingrese el inventario máximo" }
                                ]} 
                            >
                                <InputNumber min={2}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                    	<Col xs={{span: 22, offset: 2}} md={{span: 10, offset: 1}}>
                        	<Form.Item
                                label="Precio"
                                name="precio"
                                rules={[
                                    { required: true, message: "Ingrese el precio del producto" }
                                ]} 
                            >
                                <InputNumber min={1}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}

export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalProductosInventario {...props} />
    </Modal>


}
