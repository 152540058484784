import React, { Component } from 'react';
import { Button, Col, Row, Select, Typography, Card, Checkbox, message, Spin, Empty, Tag, Space } from 'antd'
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";



// import ModalTicketQr from './ModalTicketQr';


//componentes
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import { IconFlag, IconCheckMedalColor } from '../../Widgets/Iconos';

//css
import './../../../Styles/dashboard.css'
import './../../../Styles/compras.css'
import { QrcodeOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const moment = require('moment')

let colors = {
    0: '#B706F5', //Requerido
    1: '#EDF10A', //Cotizado
    2: '#F57906', //Pausado
    3: '#F50606', //Cancelado
    4: '#6106F5', //Entrega
    5: '#F506B2', //Recoleccion
    6: '#08E453', //Entregado
}

let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

let unidades = {
    'H87': 'Pieza',
    'EA': 'Elemento',
    'KGM': 'Kilogramo',
    'MTR': 'Metro',
    'KT': 'Kit',
    'SET': 'Conjunto',
    'LTR': 'Litro',
    'XBX': 'Caja',
    'xun': 'Unidad',
    'GRM': 'Gramo',
    'BUT': 'Bulto',
    'TON': 'Tonelada',
}

/**
 * @class ListasRequisiciones
 * @description Retorna una lista de las requisiciones, dependiendo del estatus 
 */
export default class ListasRequisiciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],

            page: 1,
            limit: 10,
            total: 0
        }
    }

    /***
     *
     * @methodOf ListasRequisiciones
     * @function componentDidMount
     * @description
     */
    componentDidMount() {
        this.getRequisiciones()

    }

    componentDidUpdate(prevProps) {
        if (prevProps.search != this.props.search) {
            this.getRequisiciones()
        }
    }

    /***
     *
     * @methodOf ListasRequisiciones
     * @function getRequisiciones
     * @description obtiene el listado de requisiciones, dependiendo del estatus
     */
    getRequisiciones = () => {
        this.setState({ loading: true })
        axios.get('/requisiciones', {
            params: {
                page: 1,
                estatus: this.props.estatus,
                search: this.props.search,
                ...this.props.filtros,
            }
        }).then(response => {

            let requisiciones = response.data.data

            this.setState({
                data: requisiciones.itemsList,
                page: requisiciones.currentPage,
                total: requisiciones.itemCount,
                hasMore: requisiciones.itemsList.length < requisiciones.itemCount

            })
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los datos')
        }).finally(() => this.setState({ loading: false }))
    }

    /***
     *
     * @methodOf ListasRequisiciones
     * @function getMore
     * @description Obtiene mas requisisciones al hacer scroll sobre los estatus
     */
    getMore = () => {

        if (this.state.total > this.state.data.length) {
            this.setState({ loading: true, })

            axios.get('/requisiciones', {
                params: {
                    page: this.state.page + 1,
                    limit: this.state.limit,
                    search: this.props.search,
                    estatus: this.props.estatus,
                    ...this.props.filtros,
                }
            }).then(({ data }) => {

                let arr = [...this.state.data, ...data.data.itemsList]

                this.setState({
                    data: arr,
                    total: data.data.itemCount,
                    page: data.data.currentPage,
                    hasMore: arr.length < data.data.itemCount,
                })

            })
                .catch(error => {
                    console.log("error", error);
                    message.error('Error al traer la información')
                }).finally(() => this.setState({ loading: false }))
        }
    }

    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function activate
     * @description Actualiza el marcador de activacion de la requisicion
     * 
     * @params requisicion_id id de la requisisicion a actualizar
     */
    activate = (requisicion_id, index) => {
        axios.post('/requisiciones/update', {
            requisicion_id,
            activate: true
        }).then(response => {
            console.log("response", response.data);
            let data = this.state.data
            data[index].activate = response.data.activate
            this.setState({ data })
        }).catch(error => {
            if (error?.response?.status === 403)
                message.error(error?.response?.data.message)
            else
                message.error('Error al actualizar')
        })
    }

    render() {

        const { estatus, title } = this.props

        return (
            <Spin spinning={this.state.loading}>
                <div>
                    <Text className={`list-header list-header-estatus-${estatus}`}>{title}</Text>
                </div>
                {
                    this.state.data.length > 0 ? <InfiniteScroll
                        dataLength={this.state.data.length}
                        next={this.getMore}
                        hasMore={this.state.hasMore}
                        loader={<Spin tip="Cargando..."><h4 style={{ height: '100px' }}></h4></Spin>}
                        height={550}
                        className="mb-2"
                    >
                        {

                            this.state.data.map((item, index) => {
                                return <Card size="small" className="card-list-small hover  " onClick={() => this.props.openModal(item._id)}>
                                    <Row style={{ width: '100%' }} className="">
                                        {/*<Col xs={12} md={1} className="center">
                                            <Checkbox/>
                                        </Col>*/}
                                        <Col xs={12} md={2} className="center">
                                            <Text ellipsis>{item.folio}</Text>
                                        </Col>
                                        <Col xs={12} md={5} className="flex-column text-center">
                                            <Text ellipsis>{item.producto_id?.nombre}</Text>
                                            <Text ellipsis>{item.producto_id.sku_manantial}</Text>
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            <Text ellipsis>{item.producto_id?.categoria_id?.nombre}</Text>
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            <Text ellipsis>{item.volumen} {item.producto_id?.unidad ? item.producto_id?.unidad.toUnidad() : 'unidades'}</Text>
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            <CustomAvatar
                                                style={{ marginRight: '4px', minWidth: '40px' }}
                                                size="large"
                                                image={item.contratista_id.avatar}
                                                name={item.contratista_id.nombre + " " + item.contratista_id.apellido}
                                                color={"#ff8227"} />
                                            <Text ellipsis>{item.contratista_id.nombre + " " + item.contratista_id.apellido}</Text>
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            {item.propiedades.length > 0 ? <Tag color="green" style={{ marginRight: '4px', marginLeft: '0px' }}>{item.propiedades[0].nombre}</Tag> : ''}
                                            {item.propiedades.length > 1 ? <Tag color="green" style={{ marginRight: '4px', marginLeft: '0px' }}>...</Tag> : ''}
                                        </Col>
                                        <Col span={3} className="center">
                                            <Space size={15}>
                                                <CustomAvatar
                                                    size="large"
                                                    image={item.proyecto_id.logo}
                                                    name={item.proyecto_id.nombre}
                                                    color={item.proyecto_id.color} />
                                                <IconFlag fill={colors_flag[item.prioridad] ? colors_flag[item.prioridad] : '#A4A4A4'} />
                                                <Button
                                                    className={`btn-permission ${item.activate ? 'active' : ''}`}
                                                    type="ghost"
                                                    icon={<IconCheckMedalColor />}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.activate(item._id, index)
                                                    }}
                                                />
                                            </Space>
                                        </Col>
                                        <Col xs={12} md={2} className="center">
                                            <Text className="text-gray">{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            })
                        }

                    </InfiniteScroll> : <Empty description={"Sin Requisisciones"} className="mb-3 mt-1" />}
            </Spin>

        )
    }
}
