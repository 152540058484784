import React, { Component } from 'react';
import { Spin, Col, Row, Typography, Progress, Breadcrumb, Pagination } from 'antd'

import { Link, Redirect } from 'react-router-dom';

//componentes
import { CardInfoDashboard, CardProgress, CardInfo, } from '../../Widgets/Cards/Cards';

//css
import '../../../Styles/projectManager.css'

const moment = require('moment');

const axios = require('axios').default;
const { Text } = Typography;

export default class PMInmueble extends Component {


    /**
     * 
     * @param actividades Variable de paginación
     * @param actividades.data Lista de elementos
     * @param actividades.page Número de página
     * @param actividades.limit Límite de elementos
     * @param actividades.search Cuando la busqueda está activida
     * @param actividades.total Número total de elementos
     * 
     * @param inmueble Variable de inmeuble
     * 
     * 
     * @param actividades_terminadas Número de elenentos terminadas
     * @param actividades_proceso Número de elementos en procecso
     * @param actividades_sin_comienzo Elemetos sin comenzar
     * 
     * @param loading 
     * 
     */
    constructor(props) {
        super(props)
        this.state = {
            redirectToActividad: false,
            loading: false,

            modalShowToggle: false,
            manager_proyecto_id :"",


            actividades: {
                data: [],
                page: 1,
                limit: 0,
                search: null,
                total: 0
            },


            inmueble: {

                proyecto_id: {},
                inmueble_id: {},
                manager_proyecto_id: {},

                progreso_proyectado: 0,
                progreso_real: 0,

                costo_presupuestado: 0,
                costo_actual: 0,

                materiales_presupuestados: 0,
                materiales_entregados: 0,
                progresoMateriales: 0,

                m2_totales: 0,
                m2_actuales: 0,
            },
            actividades_terminadas: 0,
            actividades_proceso: 0,
            actividades_sin_comienzo: 0,
        }
    }

    /**
     * 
     * @memberof PMInmueble
     * @method componentDidMount
     * @description Actualizamos el didmount
     * 
     */
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        // const id = this.props.id_inmueble;
        this.getInmueble()
    }


    /**
     *
     *
     * @memberof PMInmueble
     * @method getInmueble
     * @description paginador de las actividades del inmueble
     *
     * 
     * @prop {*} manager_inmueble_id ID del inmueble
     *
     * @param {*} page Página de actividades
     * @param {*} limit Limite de Actividades
     * @param {*} search Busqueda de Atividades
     */
    getInmueble = (page = this.state.actividades.page, limit = this.state.actividades.limit, search = this.state.actividades.search) => {
        this.setState({ loading: true })

        console.log('this.props.match.params.id', this.props.match.params.id)
        axios.get('/project-manager/proyecto/inmueble', {
            params: {
                manager_inmueble_id: this.props.match.params.id
            }
        })
            .then(({ data }) => {
                console.log('traje manager proyectos', data);

                this.setState(state => {
                    state.loading = false;
                    state.actividades = {
                        data: data.data.actividades.itemsList,
                        total: data.data.actividades.paginator.itemCount,
                        page,
                        limit,
                        search,
                    };
                    state.actividades_proceso = data.data.actividades_proceso;
                    state.actividades_sin_comienzo = data.data.actividades_sin_comienzo;
                    state.actividades_terminadas = data.data.actividades_terminadas;

                    state.materiales_presupuestados = data.data.totalMaterialesRequeridos
                    state.materiales_entregados = data.data.totalMaterialesEntregados
                    state.progresoMateriales = data.data.totalProcesoEntrega

                    state.inmueble = data.data.inmueble;
                    
                    state.manager_proyecto_id = data.data.inmueble.manager_proyecto_id._id
                    return state;
                });

            })
            .catch(error => {
                console.log('no traje nanai', error);
                console.log('no traje nanai', error.response);
            })
    }

    /**
     *
     * @memberof PMInmueble
     * @method setRedirect
     * @description undefined
     */
    setRedirect = () => {
        this.setState({ redirectToActividad: true })
    }


    /**
     *
     * @memberof PMInmueble
     * @method renderActividad
     * @description undefined
     */
    renderActividad = () => {
        if (this.state.redirectToActividad) {
            return <Redirect to='/admin/project-manager/actividad/1' />
        }
    }



    /**
    * @memberof Perfiles
    *
    * @method ModalPopUpHandler
    * @description  cambia el state para mostrar u ocultar el modal
    *
    **/
    ModalPopUpHandler = () => {
        const changeModal = this.state.modalShowToggle;
        this.setState({ modalShowToggle: !changeModal });
    }
 
    render() {
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} className="flex-left">
                            <Breadcrumb className="breadcrumb" separator=">">
                                <Breadcrumb.Item>
                                    <Link to="/admin/project-manager">Proyectos</Link>
                                </Breadcrumb.Item>
                                {/*proyecto_id: {},*/}
                                {/*inmueble_id: {},*/}
                                {/*manager_proyecto_id: {},*/}

                                <Breadcrumb.Item>
                                    <Link to={"/admin/project-manager/proyecto/" + this.state.inmueble.manager_proyecto_id._id}>{this.state.inmueble.proyecto_id.nombre}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrumb-actual"> {this.state.inmueble.inmueble_id.nombre} </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <section className="p-1">
                        <Row gutter={[16, 24]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfoDashboard
                                    title='Actividades Terminadas'
                                    number={this.state.actividades_sin_comienzo === 0 ? '0' : this.state.actividades_sin_comienzo}
                                    background='linear-gradient(89.47deg, #39D0BB 0.39%, #00FF38 126.36%)' />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfoDashboard
                                    title='Actividades en Proceso'
                                    number={this.state.actividades_proceso === 0 ? '0' : this.state.actividades_proceso}
                                    background='linear-gradient(87.63deg, #ECC169 1.68%, #E76B38 141.52%)' />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfoDashboard
                                    title='Actividades Planeadas'
                                    number={this.state.actividades_terminadas === 0 ? '0' : this.state.actividades_terminadas}
                                    background='linear-gradient(89.26deg, #534DFC 0.54%, #9B22FF 91.03%)' />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Fecha Inicio"
                                    titleRight="Fecha de Conclusion"

                                    infoLeft={(this.state.inmueble.fecha_inicio) ? moment(this.state.inmueble.fecha_inicio).format('YYYY-MM-DD') : 'N/A'}
                                    infoRight={(this.state.inmueble.fecha_conclusion) ? moment(this.state.inmueble.fecha_conclusion).format('YYYY-MM-DD') : 'N/A'} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Progreso Real"
                                    titleRight="Progreso Ponderado"

                                    infoLeft={<Progress type="circle" percent={this.state.inmueble.progreso_real.toMoney()} width={50} />}
                                    infoRight={<Progress type="circle" percent={this.state.inmueble.progreso_proyectado.toMoney()} width={50} />} />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    // titleLeft="Responsable"
                                    // infoLeft={
                                    //     <div>
                                    //         <Avatar name={['P', '1']}
                                    //             style={{ width: '40px', height: '40px', fontSize: '20px', paddingTop: '0px', marginRight: '5px', }}
                                    //             className="avatar-info-card"
                                    //         />
                                    //         <Text>Nombre Usuario</Text>
                                    //     </div>
                                    // }
 
                                    //infoRight={
                                    //    <div>
                                    //       <Link to={'/admin/project-manager/material/' + this.state.inmueble._id}>
                                    //            <Text>Ver Bitacora</Text>
                                    //        </Link>
                                    //    </div>
                                    //} 
                                />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    //     costo_presupuestado: 0,
                                    // costo_actual: 0
                                    titleLeft="Costo Presupuestado"
                                    infoLeft={`${this.state.inmueble.costo_presupuestado.toMoney()} MXN`}

                                    titleRight="Costo Actual"
                                    infoRight={`${this.state.inmueble.costo_actual.toMoney()} MXN`}
                                />
                            </Col>
                            
                            {/* <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="M2 Totales"
                                    infoLeft={<div>{this.state.inmueble.m2_totales} m<sup>2</sup></div>}

                                    titleRight="M2 Actuales"
                                    infoRight={<div>{this.state.inmueble.m2_actuales} m<sup>2</sup></div>} />
                            </Col> */}

                            <Col xs={24} lg={8} className="gutter-row">
                            
                            <Link to={"/admin/project-manager/material/" + this.state.inmueble.manager_proyecto_id._id}>
                                    <CardInfo
                                        className="hoverable"
                                        titleLeft="Materiales Entregados"
                                        infoLeft={<Progress type="circle" percent={parseFloat(this.state.progresoMateriales).toFixed(2)} width={50} />}

                                        titleRight="Materiales Presupuestados"
                                        infoRight={parseFloat(this.state.materiales_presupuestados).toMoney() + ' tons'} />
                                </Link>
                            </Col>
                        </Row>

                        {/* Actividades Cards */}
                        <Row gutter={[16, 24]}>
                            {this.state.actividades.data.map(actividad => <Col xs={24} lg={8} className="gutter-row">
                                <Link to={'/admin/project-manager/proyecto/inmueble/actividad/' + actividad._id+'_'+ actividad.manager_inmueble_id}>
                                    <CardProgress
                                        hover={true}
                                        title='ACTIVIDAD'
                                        name={actividad.actividad_id?.nombre}
                                        start={(actividad.fecha_inicio ? moment(actividad.fecha_inicio).format('YYYY-MM-DD') : null)}
                                        finish={(actividad.fecha_conclusion ? moment(actividad.fecha_conclusion).format('YYYY-MM-DD') : null)}
                                        percent={actividad.progreso_real} />
                                </Link>
                            </Col>)}
                        </Row>

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <Pagination
                                    defaultCurrent={1}
                                    showSizeChanger={true}
                                    onChange={(page, limit) => this.getInmueble(page, limit)}
                                    total={this.state.actividades.total} />
                            </Col>
                        </Row>
                    </section>
                </Spin>

            </div>
        )
    }
}
