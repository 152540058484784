import React, { Component } from 'react';
import { Button, Col, Popconfirm, Row, Table, message, Spin, Typography } from 'antd'
import {FaBoxes} from "react-icons/fa";
import { Link } from 'react-router-dom';
import {InfoCircleOutlined } from '@ant-design/icons';
//componentes
import { IconDelete, IconEdit, IconPlus, IconDetails, IconEye } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import Logged from "../../../Hooks/Logged";
import axios from 'axios'
//modales
import ModalProyectos from '../Modales/Proyectos/ModalProyectos';
import ModalInventarios from './Inventarios/ModalInventarios';

const { Title } = Typography;

export default class Proyectos extends Component {

    static contextType = Logged;


    constructor(props) {
        super(props)
        this.state = {
            dataProyectos: [],

            accionModal: 'crear',
            registroId: '',
            loading: true,
            modalVisible: false,
            tipo_user: 0,
            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            
            filtroSearch: "",
            searching: true,
        }
    }





    /**
     *
     *
     * @memberof Proyectos
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de los proyectos
     */
   
    componentDidMount() {

        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
        this.receivedData()
    }


    /**
     *
     *
     * @memberof Proyectos
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.receivedData();
            this.state.searching = false;
        }
        if (this.context !==0 && this.state.tipo_user === 0)
            this.setState({
                tipo_user: this.context.tipo
            })
    }


    /**
         *
         * @memberof Proyectos
         * @method receivedData
         * @description Obtiene la informacion de los proyectos y actualiza los valores locales de la tabla.
         */
    receivedData = async (page, size) => {
        this.setState({ loading: true })
        var search = this.props.search;
        axios.get('/proyectos', {
            params: {
                page: page,
                limit: size,
                search:search,
                lookup_inventarios: true, //cabia la forma de realizar la consulta para obetner dertalles del inventario de los proyectos
            }
        })
            .then(res => {
                const data = res.data.data.itemsList
                console.log("data", data);
                this.setState({
                    dataProyectos: data,
                    loading: false,
                    currentPage: res.data.data.currentPage,
                    itemCount: res.data.data.itemCount,
                    perPage: res.data.data.perPage,
                    pageCount: res.data.data.pageCount,
                    searching:true,
                    filtroSearch :search
                })
                
            })
            .catch(res => {
                console.log("error", res);
            });
    }


    /**
     * @methodOf  Proyectos
     * @function showModal
     *
     * @description Muestra el modal del usuario
     *
     * */
    showModal = (id = undefined) => {
        this.setState({
            visible: true,
            id: id
        });
    };

    /**
     * @methodOf  Proyectos
     * @function hideModal
     *
     * @description Oculta el modal del usuario
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };


    render() {
        const columns = [
            {
                title: 'LOGO',
                key: 'logo',
                width: '80px',
                
                render: (text, { nombre, logo, color }) => {

                    return <CustomAvatar
                        image={logo}
                        name={nombre}
                        color={color}
                    />


                },
            },
            {
                title: 'NOMBRE',
                dataIndex: 'nombre',
                //key: 'nombre',
                render: (text, record) => (
                    <h3>{record.nombre}</h3>
                ),
            },
            {
                title: 'DESCRIPCIÓN',
                dataIndex: 'descripcion',
                key: 'descripcion',
                width: '50%',
            },
            {
                title: 'ACCIONES',
                key: 'actions',
                 fixed: 'right',
                width: '200px',
                render: (text, record) => (
                    <div className="tbl-opciones">
                        {(this.state.tipo_user !==3) ?
                            <div className="center">

                                {record.inventario_id?._id ? <Link to={`/admin/proyectos/${record._id}/detalles`}><Button
                                    title="Ver Inventario"
                                    className="btn btn-money"
                                    icon={<InfoCircleOutlined style={{ position: 'relative', top: -2 }}/>}
                                /></Link> : <Button
                                    onClick={()=>this.setState({modal_inventario: true, proyecto_id: record._id})}
                                    title="Crear Inventario"
                                    className="btn btn-mail"
                                    icon={<FaBoxes/>}
                                />}
                                <Link to={`/admin/conceptos/${record._id}`}>
                                    <Button type="primary" className="button-survey btn-eye button-eye" title="Ver Conceptos">
                                        <IconEye />
                                    </Button>
                                </Link>
                                <Button
                                    type="primary"
                                    className="button-survey btn-edit button-edit"
                                    onClick={() => showModal(record._id)}
                                    title="Editar">
                                    <IconEdit />
                                </Button>
                                <Popconfirm
                                    placement="topRight"
                                    title="¿Deseas eliminar este proyecto?"
                                    onConfirm={() => axios.delete('/proyectos/delete', { params: { id: record._id } }).then((response) => {
                                        message.success(response.data.message)
                                        this.receivedData()
                                    })
                                        .catch((error) => {
                                            message.error(error.response.data.message);
                                            this.receivedData();
                                        })
                                    }
                                    okText="Si"
                                    cancelText="No"
                                >
                                    <Button
                                        type="primary"
                                        className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                        title="Eliminar">
                                        <IconDelete />
                                    </Button>
                                </Popconfirm>
                            </div>
                            : null}


                    </div>
                ),
            },
        ];

        const { receivedData, showModal, hideModal, state, props } = this;
        const { visible, page, limit, total, data, id, loading } = state;



        const user = this.context;
        return (
            <div>
                <Logged.Consumer>
                    {value => { this.context = value }}
                </Logged.Consumer>
                <Spin spinning={loading}>
                    <Row className="row-encabezado">

                        <Col xs={12} xl={20} xxl={20} className="flex-left">
                            <Title level={4}className="view-title">Proyectos</Title>
                        </Col>

                        <Col xs={12} xl={4} xxl={4} className="flex-right">
                            <Row align="right" style={{ textAlign: "right", display: "block" }}>
                                 <Title level={5} style={{ textAlign: "right", color: "#FFF", marginRight: '15px', marginTop: '7px', fontSize: '11pt' }}>{this.state.itemCount} Proyectos</Title>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="p-1">
                        <div className="div_contenido" >
                            <Table

                                pagination={{
                                    current: this.state.currentPage,
                                    pageSize: this.state.perPage,
                                    total: this.state.itemCount,
                                    showSizeChanger: true,
                                    position: ['bottomLeft'],
                                }}
                                onChange={({ current, pageSize, total }, b, sort) => {
                                    this.receivedData(current, pageSize)
                                }}

                                columns={columns}
                                dataSource={this.state.dataProyectos}
                                scroll={{ x: 800, y: 800 }}
                                locale={{emptyText:'Sin Proyectos Disponibles'}}
                                className="blankTheme" />
                        </div>
                    </Row>

                </Spin>

                <ModalProyectos
                    visible={visible}
                    hideModal={hideModal}
                    accept={() => {
                        hideModal();
                        receivedData();
                    }}
                    id={id}
                    update={() => receivedData()}
                    user={this.context}
                >
                </ModalProyectos>
                <ModalInventarios
                    visible={this.state.modal_inventario}
                    onCancel={()=>{
                        this.setState({modal_inventario: false, proyecto_id: undefined})
                        receivedData()
                    }}
                    proyecto_id={this.state.proyecto_id}
                />
                {(this.state.tipo_user !==3) ?
                    <Button className="btnAddPlus" title="Nuevo registro" onClick={() => showModal()} >
                        <IconPlus />
                    </Button>
                    : null}

            </div>
        )
    }
}
