import React, { Component } from 'react';
import { Spin, Button, Col, Form, Row, Select, Card, Typography, message, Modal, Dropdown, Menu, Avatar } from 'antd'
import { CloseCircleOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
import { IconGoToLink } from '../../Widgets/Iconos';
import Logged from "../../../Hooks/Logged";

import '../../../Styles/nominas.css';
import { CardHeader, CardBody } from './Styles/Styles';
import { IconCheck } from '../../Widgets/Iconos';

//Componentes
import GraficaNominasPorGrupos from '../Nomina/Widgets/GraficaNominasPorGrupos';
import ListaPagosEmpleados from '../Nomina/Widgets/ListaPagosEmpleados';

const axios = require('axios').default;
const moment = require('moment');
const { Option } = Select;

const { Title, Text, Paragraph, } = Typography;

/**
 *
 *
 * @export
 * @class NominaDashboard
 * @extends {Component}
 *
 * @description Dashboard de la nomina. Ofrece algunos widgets e infomacion general para poder ejecutar la sección
 */
export default class NominaDashboard extends Component {
    static contextType = Logged;
    constructor(props) {
        super(props);
        this.state = {
            start: undefined,
            end: undefined,
            next: null,


            inmuebles: [],
            nominasList: [{}],
            nomina_actual: {},



            monto_total: 0,
            montos_pagados: 0,
            monto_por_pagar: 0,

            

            monto_pagado_total: 0,
            monto_pagar: 0,
            render_reporte: false,
            montos_pagados: 0,
            saldo_remanente: 0,


            loadingHeader: false,
            loadingDashboard: false,
        };
    }

    /**
     * @memberof NominaDashboard
     * @description  Se ejecuta una vez se monta el componente
     */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.LoadFechasNominas();
    }


    /**
     * @memberof NominaDashboard
     * @method   LoadFechasNominas
     * @description  Trae un array con los id y las fechas de todas las nominas de la BD, y manada a llamar a otro metodo para trear la informacion de la ultima nomina
     */
    LoadFechasNominas = (page = 1) => {
        this.setState({ loadingHeader: true, loadingDashboard: true })
        axios.get('/nominas/list/n', {
            params: {
                page: page,
                limit: 10,
            }
        })
            .then(result => {
                console.log('nominas list fechas', result.data.data)
                this.setState({
                    nominasList: result.data.data.itemsList,
                    page: result.data.data.paginator.currentPage,
                    total: result.data.data.paginator.itemCount,
                    next: result.data.data.paginator.next
                })
                if (this.props.match.params.nomina_id !== undefined)
                    this.getNomina(this.props.match.params.nomina_id)
                else if (result.data.latest_nomina && result.data.latest_nomina !== null)
                    this.getNomina(result?.data?.latest_nomina?._id)
                else {
                    this.setState({ loadingDashboard: false })
                    message.error('No hay ninguna nomina registrada')
                }

            })
            .catch(error => {
                console.error('Error al traer nominas', error)
            })
            .finally(() => this.setState({ loadingHeader: false }))
    }

    /**
     * @memberof NominaDashboard
     * @method   LoadFechasNominas
     * @description  Carga mas fechas ne nominas para el select
     */
    loadMoreNominas = (page) => {
        this.setState({ loadMore: true })
        axios.get('/nominas/list/n', {
            params: {
                page: page,
                limit: 10,
            }
        })
            .then(result => {
                console.log(result.data.data.itemsList)
                console.log(this.state.nominasList)
                let nominas = this.state.nominasList.concat(result.data.data.itemsList)
                console.log(nominas)
                this.setState({
                    nominasList: nominas,
                    page: result.data.data.paginator.currentPage,
                    total: result.data.data.paginator.itemCount,
                    next: result.data.data.paginator.next,
                    loadMore: false
                })
            })
            .catch(error => {
                console.error('Error al traer nominas', error)
                this.setState({ loading: false })
            })
    }

    /**
    * @memberof NominaDashboard
    * @method   getNomina
    * @description  Trae la informacion de una nomina
    * @param nomina_id Id de la nomina a traer informacion
    */
    getNomina = (nomina_id) => {
        this.setState({ loadingDashboard: true })

        axios.get('/nominas/get', {
            params: { nomina_id }
        })
            .then(result => {
                let data = result.data.data;

                console.log('result',result);


                this.setState({
                    nomina_actual: data.nomina,
                    inmuebles: data.inmuebles,

                    empleados_pagados: data.empleados_pagados,
                    empleados_no_pagados: data.empleados_no_pagados,


                    monto_total: data.monto_total,
                    montos_pagados: data.montos_pagados,
                    monto_por_pagar: data.monto_por_pagar,
                })

            })
            .catch(error => {
                console.error('Error al traer nominas', error)
            }).finally(e => {
                this.setState({ loadingDashboard: false })
            })
    }
    
    render() {
        const user = this.context;


        return (
            <div>
                <Spin spinning={this.state.loadingHeader}>
                    <Row className="row-encabezado">
                        <Col xs={24} lg={8} xl={8} xxl={{ span: 8 }}>
                            <h2>Dashboard</h2>
                        </Col>
                        <Col xs={24} lg={16} xl={16} xxl={{ span: 8, offset: 8 }}>
                            <Row style={{ width: '100%', height: '100%' }}>
                                <Col lg={{ span: 6, offset: 10 }} lg={{ span: 6, offset: 10 }} xxl={{ span: 6, offset: 10 }} className="flex-left">
                                    <Dropdown
                                        trigger={["click"]}
                                        className="btn-transparent"
                                        overlay={(
                                            <Menu className="dropdown-nominas">

                                                {
                                                    this.state.nominasList.map((nomina, index) => (<Menu.Item
                                                        key={index}
                                                        icon={<Avatar size="small">{moment(nomina.fecha).isoWeek()}</Avatar>}
                                                        className={(this.state.nomina_actual._id && this.state.nomina_actual._id.toString() === nomina._id.toString()) ? " nomina-selected" : null}
                                                    >
                                                        <Link to={'/admin/nomina/previa/' + nomina._id}>&nbsp; {moment(nomina.fecha).format('DD-MM-YYYY')} </Link>
                                                    </Menu.Item>))
                                                }
                                                {this.state.next !== null ? <Menu.Item
                                                    key='N'
                                                >
                                                    <Button disabled={this.state.loadMore} onClick={(e) => {
                                                        this.loadMoreNominas(this.state.page + 1)
                                                        e.stopPropagation();
                                                    }}> Cargar mas </Button>
                                                </Menu.Item> : null}
                                            </Menu>
                                        )}>
                                        <Button style={{ minWidth: '150px' }}>
                                            {(this.state.nomina_actual._id) ? <div style={{ position: 'relative', left: -15 }}>
                                                <Avatar size="small" style={{ position: 'relative' }}>
                                                    {moment(this.state.nomina_actual.fecha).isoWeek()}
                                                </Avatar>
                                                &nbsp;{moment(this.state.nomina_actual.fecha).format('DD-MM-YYYY')}
                                            </div> : <div style={{ position: 'relative', left: -15 }}>
                                                <Avatar size="small" style={{ position: 'relative' }}>
                                                    N
                                                </Avatar>
                                                &nbsp;Lista de Nóminas
                                            </div>}
                                        </Button>
                                    </Dropdown>
                                </Col>
                                <Col lg={{ span: 8 }} lg={{ span: 8 }} xxl={{ span: 8 }} className="center">
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Spin>
                <Spin spinning={this.state.loadingDashboard}>
                    <div className="content p-1">
                        <Row className="card-container" gutter={[24, 24]} style={{ width: '100%' }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Card className="content-card">
                                    <Text className="card-monto-title">Monto Total</Text>
                                    <Text className="card-monto-monto " >$ {this.state.monto_total.toMoney(2)} <small>MXN</small></Text>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Card className="content-card">
                                    <Text className="card-monto-title">Montos Pagado</Text>
                                    <Text className="card-monto-monto monto-green" >$ {this.state.montos_pagados.toMoney(2)} <small>MXN</small></Text>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Card className="content-card">
                                    <Text className="card-monto-title">Monto por Pagar</Text>
                                    <Text className="card-monto-monto monto-red" >$ {this.state.monto_por_pagar.toMoney(2)} <small>MXN</small></Text>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="card-container" gutter={[24, 24]} style={{ width: '100%' }}>
                            <Col xs={24} sm={24} xl={16}>
                                <Row gutter={[24, 24]}>
                                    <Col className="card-container" xs={24} sm={24} xl={12}>
                                        <Card
                                            bordered={false}
                                            className="content-card"
                                            title="Fecha de Nómina"
                                            headStyle={CardHeader}
                                            bodyStyle={CardBody}
                                        >
                                            {(user && (user.tipo === 1 || user.roles['nominas'])) ?
                                                <Row style={{marginTop: '0.5em'}}>
                                                    <Col xs={12} xxl={16}>
                                                        <Text>Cargar Nómina</Text>
                                                    </Col>
                                                    <Col xs={12} xxl={8}>
                                                        <Button style={{ minWidth: '115px' }} className="content-button-manage" ghost>
                                                            <Link to={(user && (user.tipo === 1)) ? `/admin/nomina/inicio/nomina/${this.state.nomina_actual._id}/salariales` : `/admin/nomina/inicio/nomina/${this.state.nomina_actual._id}`}>Cargar</Link>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                : null
                                            }
                                            <Row style={{marginTop: '0.5em'}}>
                                                <Col xs={12} xxl={16}>
                                                    <Text>{moment(this.state.nomina_actual.fecha).format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col xs={12} xxl={8}>
                                                    {/* user && (user.tipo === 1) */}
                                                    <Button className="content-button-manage" ghost disabled={(user && (user.tipo === 1)) ? !this.state.nomina_actual.autorizada_preliminar : false}>
                                                        <Link to={
                                                            (user && (user.tipo === 1))?
                                                                ('/admin/nomina/revision-final/' + this.state.nomina_actual._id)
                                                                    :
                                                                `/admin/nomina/inicio/nomina/${this.state.nomina_actual._id}`
                                                        }>Avances</Link>
                                                    </Button>
                                                </Col>
                                            </Row>
                                            
                                            {(user && (user.tipo === 1 || user.roles['finanzasnominas'])) ?
                                                <Row style={{marginTop: '0.5em'}}>
                                                    <Col xs={12} xxl={16}>
                                                        <Text>Registrar nómina en Finanzas</Text>
                                                    </Col>
                                                    <Col xs={12} xxl={8}>
                                                        <Button style={{ minWidth: '115px' }} ghost className="content-button-manage" disabled={(!this.state.nomina_actual.autorizada_final)} >
                                                            <Link to={"/admin/nomina/revision-finanzas/" + this.state.nomina_actual._id}>Finanzas</Link>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                : null
                                            }

                                            {(user && (user.tipo === 1)) ?
                                                <Row style={{marginTop: '0.5em', marginBottom: '0.5em'}}>
                                                    <Button type="primary" onClick={() => {
                                                        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
                                                        axios.post('/nominas/add', {
                                                            nomina_id: (this.state.nomina_actual) ? this.state.nomina_actual._id : null
                                                        })
                                                            .then(result => {
                                                                //Se asigna a la variable local
                                                                message.success('Nomina Creada');
                                                                this.componentDidMount()
                                                            })
                                                            .catch(error => {
                                                                console.log('Error al registrar la nomina', error)
                                                                if (error.response.data.data) {
                                                                    Modal.error({
                                                                        title: "No es posible generar la nómina.",
                                                                        content: `Ya existe una nómina de esta semana.`
                                                                    })
                                                                } else {
                                                                    message.error('No se creó la nomina, ya existe una esta semana.');

                                                                }
                                                            })

                                                    }
                                                    } >
                                                        {(this.state.nominasList.length < 1 || this.state.nomina_actual._id === this.state.nominasList.reverse()[this.state.nominasList.length - 1]._id) ? "Crear Nómina" : "Actualizar Nómina"}
                                                    </Button>
                                                </Row>
                                                : null
                                            }
                                        </Card>
                                    </Col>
                                    <Col className="card-container" xs={24} sm={24} xl={12}>
                                        <Card
                                            bordered={false}
                                            className="content-card"
                                            title="Autorización Nómina"
                                            headStyle={CardHeader}
                                            bodyStyle={CardBody}
                                        >
                                            <div>
                                                <Text strong className="card-auth-text">
                                                    Avances
                                                    {(this.state.nomina_actual.autorizada_preliminar) ? <IconCheck color="#23C496" style={{ float: "right" }} /> : <CloseCircleOutlined style={{ right: '3px', position: 'relative', fontSize: '20px', width: "20px", height: "20px", float: "right" }} />}
                                                </Text>
                                            </div>
                                            <div className="pt-05">
                                                <Text strong className="card-auth-text">
                                                    Autorización Final
                                                    {(this.state.nomina_actual.autorizada_final) ? <IconCheck color="#23C496" style={{ float: "right" }} /> : <CloseCircleOutlined style={{ right: '3px', position: 'relative', fontSize: '20px', width: "20px", height: "20px", float: "right" }} />}
                                                </Text>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} xl={24}>
                                        <Card className="content-card">
                                            <Link to="/admin/nomina/grupos" className="button-link-to mr-1" type="link" ><IconGoToLink /></Link>
                                            <Title level={3} className="text-gray">Nómina por Grupos</Title>
                                            <GraficaNominasPorGrupos
                                                inmuebles={this.state.inmuebles}
                                                nomina={this.state.nomina_actual}
                                                />
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} xl={8}>
                                <Row gutter={[24, 24]}>
                                    <Col span={24}>
                                        <Card className="content-card">
                                            <Title level={3} className="text-gray">Pagos Realizados</Title>
                                            <Row style={{ width: '100%' }}>
                                                <ListaPagosEmpleados
                                                    nomina={this.state.nomina_actual}
                                                    empleados={this.state.empleados_pagados}
                                                    color="#28A745"
                                                    empty="Sin pagos Realizados" />
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={24}>
                                        <Card className="content-card">
                                            <Title level={3} className="text-gray">Pagos sin Realizar</Title>
                                            <Row style={{ width: '100%' }}>
                                                <ListaPagosEmpleados
                                                    nomina={this.state.nomina_actual}
                                                    empleados={this.state.empleados_no_pagados}
                                                    color="#DC3545" empty="No hay pagos pendientes"/>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Spin>


            </div>
        )
    }
}
