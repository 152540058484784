import React, { Component } from 'react';
import { Row, Col, Table, Button, Popconfirm, Menu, DatePicker, message, Form, Checkbox, Dropdown } from 'antd'
import { Link } from 'react-router-dom';

import { IconArrow as Arrow, IconPlus, IconEye, IconEdit, IconDelete, IconDownloadCustom as Download } from '../../Widgets/Iconos';
import Reporte from './AcreedorReporte'


import ModalAcreedores from '../Modales/Acreedores/ModalAcreedor';
import CustomAvatar from "../../Widgets/Avatar/Avatar";

const axios = require('axios').default
const { RangePicker } = DatePicker;

axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


export default class Acreedores extends Component {

    constructor(props) {
        super(props)
        this.state = {

            dataAcreedores: [],


            accionModal: 'crear',
            registroId: '',
            loading: true,
            modalVisible: false,

            datosConsultados: false,
            numConsulta: 0,
            dataRpt: {
                fecha: '',
                espacio: '',
                dataInfo: [],
                author: '',
                totalTran: 0,
                sinFiltros: true,

                fecha_inicio: '',
                fecha_fin: '',
                area: '',
                rubro: '',
                subrubro: '',
                proyectos: [],
                cuentas: [],
                numConsulta: 0,
                montoPagado: 0,

                totalVenta: 0,
                totalPagado: 0,
                totalDeuda: 0
            },


            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,


            start: undefined,
            end: undefined,
            data: [],


            projects: [],

            areas: [],
            rubros: [],
            subrubros: [],
            tipos: [],
            cuentas: [],

            dropdowns: {
                projects: false,
                areas: false,
                rubros: false,
                subrubros: false,
                tipo: false
            },

            projectsSelected: [],
            cuentasSelected: [],
            area: null,
            rubro: null,
            subrubro: null,
            cuenta: null,
            tipo: null,

            filtroSearch: "",
            searching: true


        }
    }

    //Referencia
    ModalAcreedoresRef = React.createRef();


    /**
   * @memberof Acreedores
   * @method   componentDidMount
   **/
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        //Obtenemos el listado de Acreedores
        this.receivedData();
        this.getProjects();
    }



    /**
     * @memberof Acreedores
     * @method componentDidUpdate
     * @description actualiza la vista si se ha realizado una busqueda
     */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.receivedData();
            this.state.searching = false;
        }
    }


    /**
    * @memberof Acreedores
    * @method receivedData
    * @description Obtiene la informacion y actualiza los valores locales de la lista.
    */
    receivedData = async (
        page = this.state.currentPage,
        quantity = this.state.perPage,
        filter = {},
        projects = this.state.projectsSelected,
        area = this.state.area,
        rubro = this.state.rubro,
        subrubro = this.state.subrubro,
        end = this.state.end,
        start = this.state.start
    ) => {

        this.setState({ loading: true });


        var search = this.props.search;
        var numPage = 1, numReg = 0;

        axios.get('acreedores/list', {
            params: {
                //pagination:false,
                search: search,
                page: page,
                limit: quantity,
                filter,
                projects,
                area,
                rubro,
                subrubro,
                end,
                start
            }
        })
            .then(res => {

                this.state.searching = true;


                var
                    data = res.data.data.itemsList,
                    dataL = data, data_list = [];

                numPage = page;
                if (numPage === null || numPage === 1) { numReg = 0 }
                else { numReg = ((numPage - 1) * 10) }


                for (let index = 0; index < dataL.length; index++) {
                    const element = dataL[index];
                    numReg = numReg + 1;
                    var monto_pagado = 0;


                    if (element.transacciones.length > 0) {
                        for (let t = 0; t < element.transacciones.length; t++) {
                            const tran = element.transacciones[t];
                            monto_pagado += parseFloat(tran.monto)
                        }

                    }


                    data_list.push({
                        _id: element._id,
                        index: numReg,
                        acreedor: element.acreedor,
                        email: element.email,
                        telefono: element.telefono,
                        area_id: element.area_id,
                        rubro_id: element.rubro_id,
                        subrubro_id: element.subrubro_id,
                        proyecto_id: element.proyecto_id,
                        createdAt: element.createdAt,
                        contacto_id: element.contacto_id,
                        monto_pagado: monto_pagado,

                        monto_pendiente: element.monto_pendiente,
                        monto_prestamo: element.monto_prestamo,
                        monto_retorno: element.monto_retorno,
                        fecha_prestamo: element.fecha_prestamo.toString().substring(0, 10),
                        updatedAt: element.updatedAt,

                        __v: element.__v
                    });
                }


                this.setState({
                    filtroSearch: search,
                    dataAcreedores: data_list,// res.data.data.itemsList
                    currentPage: page,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: quantity,
                    pageCount: res.data.data.paginator.pageCount,
                    loading: false,

                });


                this.receivedConsulta();

            })
            .catch(res => {
                console.log("error", res);
            })
            .finally(f => {
                this.setState({ loading: false })
            });


        ///acreedores/list
    }


    /**
    * @memberof Acreedores
    * @method eliminar 
    * @description Elimina registro de acreedor por su id
    */
    eliminarAcreedor = id => {

        axios.post('/acreedores/delete', {
            id: id
        })
            .then((response) => {
                message.success("Eliminado correctamente.");
                this.receivedData();
            })
            .catch((error) => {
                message.error(error.response.data.message)

            })

    }



    /**
     *  @memberof Acreedores
     * @method modalCrear 
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }


    /**
     *  @memberof Acreedores
     * @method modalEditar 
    * @description Abrir modal tipo edición para registro seleccionado desde tabla.
    * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
    */
    modalEditar = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;
        setTimeout(() => {
            this.setState({
                registroId: reg_id,
                accionModal: 'editar',
                modalVisible: true,
            })
        }, 100);
    }


    /**
     *  @memberof Acreedores
     * @method hideModal 
     * @description Cerrar modal y limpiar valor registroId
    */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false,
        })

        this.receivedData();
    }


    /**
     * @memberof Acreedores
     * @description Actualiza los valores del state;
     */
    update = () => this.setState({ update: !this.state.update })


    /**
     * @memberof Acreedores
     * @function getProjects
     * @description Obtenemos los proyectos y los ponemos en el listado para filtro
     *
     * */
    getProjects = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({
                projects: proyectos.data.data
            })
        }).catch((error) => {
        })
    };


    /**
      * @memberof Acreedores
     *
     * @function onProjectsChange
     * @description Se actualiza el arreglo de proyectos seleccionados jutno con los arreglos IDs
     *
     * @param _id
     * @description El ID del proyeccto a agregar al arreglo de proyectos seleccionados
     *
     * @param status
     * @description True, si se va a agregar, ffalse si no
     * */
    onProjectsChange = (_id, status) => {

        let { projectsSelected } = this.state;
        let filter = projectsSelected.findIndex(item => item.toString() === _id.toString())

        if (status) {
            if (filter === -1) projectsSelected.push(_id)
        } else {
            if (filter !==-1) projectsSelected.splice(filter, 1)
        }
        this.setState({ projectsSelected })
        this.receivedData();
    };


    /**
      * @memberof Acreedores
     * @function onDatesChange
     * @description Se actualizan los valores de las fechas.
     * @param values
     * @description Valores de los rangerspicers
     * */
    onDatesChange = async (values) => {
        try {
            this.state.start = values[0].format('YYYY-MM-DD');
            this.state.end = values[1].format('YYYY-MM-DD');
        } catch (e) {
            this.state.start = null;
            this.state.end = null;
        }
        this.update();
        this.receivedData();
    };



    /**
    * @memberof Acreedores
     * @function handleVisibleChange
     * @description Permite mostrar u ocultar los dropdowns en general
     * @param type
     * @description Tipo de dropdown (proyeto)
     * @flag
     * @description Si se mostrará u ocultará.
     *
     * */
    handleVisibleChange = (type, flag) => {
        let { dropdowns } = this.state;
        dropdowns[type] = flag;
        this.setState({ dropdowns });
    };



    /**
        * @memberof Acreedores
       * @function receivedConsulta
       * @description Consulta de acreedores para tabla
       * */
    receivedConsulta = () => {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setState({ loading: true })

        var page = this.state.currentPage,
            quantity = 10000,
            filter = {},
            sort = {},
            projects = this.state.projectsSelected,
            area = this.state.area,
            rubro = this.state.rubro,
            subrubro = this.state.subrubro,
            cuentas = this.state.cuentasSelected,
            tipo = this.state.tipo,
            end = this.state.end,
            start = this.state.start


        var numPage = 1, numReg = 0;

        axios.post('/acreedores/reporteInfo', {
            params: {
                page: page,
                limit: quantity,
                filter,
                sortfield: sort,
                projects,
                cuentas,
                area,
                rubro,
                subrubro,
                tipo,
                end,
                start
            }
        })
            .then(res => {

                var data = res.data.data;
                var consultaN = this.state.dataRpt.numConsulta;
                consultaN = consultaN + 1;


                var data_rpt = {
                    author: data.author,
                    fecha: data.fecha,
                    espacio: '',
                    dataInfo: data.clientes,
                    sinFiltros: data.sinFiltros,


                    totalTran: data.total,

                    fecha_inicio: data.fecha_inicio,
                    fecha_fin: data.fecha_fin,
                    area: data.area,
                    rubro: data.rubro,
                    subrubro: data.subrubro,
                    proyectos: data.proyectos,
                    numConsulta: consultaN,

                    montoPagado: data.montoPagado,
                    totalVenta: data.totalVenta,
                    totalPagado: data.totalPagado,
                    totalDeuda: data.totalDeuda,


                    totalMontoPendiente: data.totalMontoPendiente,
                    totalMontoPrestamo: data.totalMontoPrestamo,
                    totalMontoRetorno: data.totalMontoRetorno,
                    totalMontoPagado: data.totalMontoPagado,


                    _totalMontoPendiente: data._TotalMontoPendiente,
                    _totalMontoPrestamo: data._TotalMontoPrestamo,
                    _totalMontoRetorno: data._TotalMontoRetorno,
                    _totalMontoPagado: data._TotalMontoPagado,

                }


                this.setState({
                    dataRpt: data_rpt,
                    loading: false,
                    datosConsultados: true,
                    numConsulta: consultaN
                });


            })
            .catch(res => {
                console.log("error", res);
                this.setState({ dataClientes: [] });
            });



    };


    render() {
        const { onDatesChange, onProjectsChange, onCuentasChange } = this;

        const columns = [
            {
                title: '#',
                dataIndex: 'index',
                key: 'index',
                width: 100,
                align: "center",
            },
            {
                title: 'Acreedor',
                dataIndex: 'acreedor',
                align: "center",
                render: (text, record) => (
                    <h3>{record.acreedor}</h3>
                ),
            },
            {
                title: 'Monto a pagar',
                dataIndex: 'monto_retorno',
                key: 'monto_retorno',
                align: "right",
                render: (text, record) => (
                    <h3>{record.monto_retorno.toMoney()}</h3>
                ),
            },
            {
                title: 'Monto Pagado',
                dataIndex: 'monto_pagado',
                key: 'monto_pagado',
                align: "right",
                render: (text, record) => (
                    <h3>{record.monto_pagado.toMoney()}</h3>
                ),
            },
            {
                title: 'Monto Pendiente',
                dataIndex: 'monto_pendiente',
                key: 'monto_pendiente',
                align: "right",
                render: (text, record) => (
                    <h3>{record.monto_pendiente.toMoney()}</h3>
                ),
            },
            {
                title: 'Contacto',
                dataIndex: 'contacto',
                width: 200,
                key: 'avatar',
                align: "center",
                render: (text, record, { avatar }) => <div title={record.contacto_id.nombre + " " + record.contacto_id.apellido}>
                    <CustomAvatar name={[record.contacto_id.nombre, record.contacto_id.apellido]} image={record.contacto_id.avatar} />
                </div>
            },

            {
                title: 'Proyecto',
                key: 'proyecto',
                dataIndex: 'proyecto_id',
                render: (text, record) => {
                    let backgroundColor = (record.proyecto_id.color) ? record.proyecto_id.color : "#bbbbbb";
                    const colorHex = function getContrastYIQ(hexcolor) {
                        hexcolor = hexcolor.replace("#", "");
                        var r = parseInt(hexcolor.substr(0, 2), 16);
                        var g = parseInt(hexcolor.substr(2, 2), 16);
                        var b = parseInt(hexcolor.substr(4, 2), 16);
                        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
                        return (yiq >= 128) ? 'black' : 'white';
                    };
                    let color = colorHex(backgroundColor);

                    return (
                        <div className="record text-right border" style={{ backgroundColor: backgroundColor, color: color }}>
                            {record.proyecto_id.nombre}
                        </div>
                    )
                },
            },

            {
                title: 'Fecha prestamo',
                dataIndex: 'fecha_prestamo',
                align: "center",
                render: (text, record) => (
                    (record.fecha_prestamo)
                ),
            },

            {
                title: 'Acciones',
                key: 'actions',
                fixed: "right",
                width: 150,
                render: (text, record) => (
                    <div className="tbl-opciones">
                        <Link title="Ver" className="EditIcon" to={`/admin/acreedores/detalle/${record._id}`} >
                            <Button type="primary" className="button-survey btn-eye button-eye" name={record.nombre} id={record.id} title="Ver detalle"  >
                                <IconEye />
                            </Button>
                        </Link>
                        <Button type="primary" className="button-survey btn-edit button-edit" name={record.nombre} id={record._id} onClick={this.modalEditar} title="Editar"  >
                            <IconEdit />
                        </Button>
                        <Popconfirm placement="topRight" title="¿Eliminar este acreedor?" okText="Si, seguro" cancelText="No" name={record.nombre} id={record._id} onConfirm={() => { this.eliminarAcreedor(record._id) }} >
                            <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" name={record.nombre} title="Eliminar"  >
                                <IconDelete />
                            </Button>
                        </Popconfirm>
                    </div>
                ),
            },
        ];

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={12} xxl={10}>
                        <h2>Deudas por Pagar</h2>
                    </Col>

                    <Col xs={24} xl={12} xxl={14}>
                        <Row style={{ width: '100%', paddingTop: '12px' }} gutter={[10, 10]}>
                            {/*Fechas*/}
                            <Col className="row-filter" xl={10} lg={10} md={11} sm={12} xs={10} >
                                <Form.Item
                                    name="fechas"
                                    noStyle>
                                    <RangePicker
                                        className="input-picker"
                                        style={{
                                            background: 'transparent !important',
                                            color: '#FFF',
                                            float: 'right',
                                            width: '100%'
                                        }}
                                        // format = {moment().format("DD-MM-YYYY")}
                                        onChange={onDatesChange} />
                                </Form.Item>
                            </Col>

                            {/*Proyecto*/}
                            <Col className="row-filter" xl={10} lg={10} md={11} sm={12} xs={12} >
                                <Dropdown
                                    className="filter-element"
                                    // Solamente para la visibilidad del Dropdown
                                    visible={this.state.dropdowns.projects}

                                    //Para que no se cierre al darle click a algun elemento
                                    onVisibleChange={(flag) => this.handleVisibleChange('projects', flag)}
                                    overlay={
                                        <Menu
                                        >
                                            {/*Iteramos el arreglo de Proyectos*/}
                                            {this.state.projects.map(({ _id, nombre, logo, color }, index) => (
                                                <Menu.Item key={index}>
                                                    {/*Este es el checbox, recibe un evento*/}
                                                    <Checkbox
                                                        /*El evento onProjectsChange recibe el ID del evento, y lo arregla y o loq quita, segun sea el caso.*/
                                                        onChange={(status) => onProjectsChange(_id, status.target.checked)}>
                                                        <CustomAvatar
                                                            image={logo}
                                                            name={nombre}
                                                            color={color}
                                                            size="small"
                                                            style={{
                                                                margin: '0 5px'
                                                            }}
                                                        />
                                                        {nombre}
                                                    </Checkbox>
                                                </Menu.Item>
                                            ))}

                                        </Menu>
                                    }>
                                    <p>Proyectos <Arrow className="arrow" /></p>
                                </Dropdown>
                            </Col>

                            {/*Reporte*/}
                            <Col className="row-filter" span={1}>
                                <Button disabled={!this.state.datosConsultados} style={{
                                    background: "transparent", border: "none"
                                }} title="Reporte">

                                    <Reporte
                                        rpt={this.state.dataRpt}
                                        reporte={this.state.datosConsultados}
                                        numConsulta={this.state.numConsulta}

                                        trigger={() =>
                                            <Button
                                                type="link" size="small"
                                                style={{ position: 'relative', top: '0.4em', right: '0.5em' }}
                                                onClick={this.receivedConsulta}>
                                                <Download />
                                            </Button>}
                                    />
                                </Button>
                            </Col>

                        </Row>
                    </Col>
                </Row>

                <section className="p-1">
                    <Row>
                        <div className="div_contenido" >
                            <Table
                                columns={columns}
                                onChange={({ current, pageSize }) => {
                                    this.receivedData(current, pageSize)
                                }}
                                pagination={{
                                    // onChange: this.receivedData(),
                                    current: this.state.currentPage,
                                    pageSize: this.state.perPage,
                                    total: this.state.itemCount,
                                    showSizeChanger: true
                                }}

                                loading={this.state.loading}
                                dataSource={this.state.dataAcreedores} scroll={{ x: 1000 }} className="blankTheme" />
                        </div>
                    </Row>
                </section>

                <ModalAcreedores
                    ref={this.ModalAcreedoresRef}
                    modalVisible={this.state.modalVisible}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                >
                </ModalAcreedores>

                <Reporte
                    rpt={this.state.dataRpt}
                    reporte={this.state.datosConsultados}
                    numConsulta={this.state.numConsulta}

                    trigger={() =>
                        <Button
                            type="link" size="small"
                            style={{ position: 'relative', top: '0.4em', right: '0.5em' }}
                            onClick={this.receivedConsulta}>
                            <Download />
                        </Button>}
                />

                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                    <IconPlus />
                </Button>
            </div>
        )
    }
}
