import React, { Component } from "react";
import { Avatar, Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Spin, Typography, Upload } from 'antd';
import PropTypes from "prop-types";

import { UploadOutlined, } from "@ant-design/icons";
//componentes
import { IconArrowSelect, IconCloseModal, IconDelete, IconEdit } from '../../../Widgets/Iconos';
import { MyUpload } from './../../../Widgets/Upload';
//css
import './../../../../Styles/finanzas.css'

const moment = require('moment');
const axios = require('axios').default;
const { Option } = Select;
const { Title, } = Typography;

class ModalTraspaso extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false,
            edicion: false,
            dataAreas: [],
            dataRubros: [],
            dataSubrubros: [{ nombre: '--' }],
            dataProyectos: [],
            dataCuentas: [],
            cuentas_receptoras: [],
            /*Datos modal detalles*/
            det_concepto: '{{Concepto}}',
            det_fecha: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
            det_proyecto: '{{Proyecto}}',
            det_rubro: '{{Rubro}}',
            det_subrubro: '{{Subrubro}}',
            det_cuentaRem: '{{Cuenta Remitente}}',
            det_cuentaRec: '{{Cuenta Receptora}}',
            det_monto: '{{Monto}}',

        }
    }

    //Referencia de form
    modalTraspaso = React.createRef();

    /**
     *
     *
     * @memberof ModalTraspaso
     * @description Se ejecuta al cambiar una propiedad o un estado de el componente desde la lista principal de Finanzas.
     */
    componentDidUpdate() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.accionModal === 'editar' && !this.state.datosConsultados && this.props.modalVisible) {
            this.modalEditar()
        }

    }

    /**
     *
     *
     * @memberof ModalTraspaso
     * @method componentDidMount
     * @description Se ejecuta al finalizar de montar el modal.
     */
    componentDidMount() {
        this.loadCuentas();
        this.loadProyectos();
        this.loadAreas();
    }


    //Función para limpiar campos
    limpiarCamposModal = () => {
        if (this.modalTraspaso.current !==undefined) {
            this.modalTraspaso.current.setFieldsValue({
                nombre: '',
                numero: '',
                pais: '',
                estado: '',
                estatus: true
            });
        }

    }

    //Función que busca la info del registro seleccionado
    modalEditar = () => {
        const { registroId } = this.props;
        axios.post('/transacciones/get', {
            id: this.props.registroId,
        }).then((transaccion) => {
            let data = transaccion.data.data;

            this.setState({
                edicion: true,
                datosConsultados: true,
            })

            var fechita = new Date(data.fecha)

            this.modalTraspaso.current.setFieldsValue({
                concepto: data.concepto,
                cuentaRemitente: data.cuenta_id._id,
                cuentaReceptora: data.cuenta_destino_id.nombre,
                monto: data.monto,
                proyecto: data.proyecto_id._id,
                area: data.area_id.nombre,
                rubro: data.rubro_id.nombre,
                subrubro: data.sub_rubro_id.nombre,
                tipo: data.tipo,
                docs: data.comprobantes,
                fecha: moment(fechita, 'DD-MM-YYYY')
            })
        })
    }


    /**
     *
     *
     * @memberof ModalTraspaso
     * @method loadCuentas
     * @description Obtiene la informacion de las cuentas registradas en la base de datos
     *
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200,
                transacciones: true
            }
        }).then((cuentas) => {
            this.setState({
                dataCuentas: cuentas.data.data.itemsList
            })
        }).catch((error) => {
        })
    }

    /**
     *
     *
     * @memberof ModalTraspaso
     * @method loadProyectos
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadProyectos = () => {
        axios.get('/proyectos', {
            params: {
                page: 1,
                limit: 200
            }
        }).then((proyectos) => {
            this.setState({
                dataProyectos: proyectos.data.data.itemsList
            })
        }).catch((error) => {
        })
    }


    /**
     *
     *
     * @memberof ModalTraspaso
     * @method onCuentaChange
     * @description Depura las cuentas disponibles para eliminar de las opciones la cuenta seleccionada como remitente.
     */
    onCuentaChange = (e) => {
        let cuentas_disponibles = [];
        //Eliminamos la cuenta seleccionada de el listado de las cuentas disponibles
        //para recibir dinero. No se puede enviar dinero una cuenta a si misma.
        this.state.dataCuentas.map((cuenta, index) => {
            if (cuenta._id !==e) {
                cuentas_disponibles.push(cuenta);
            }
        })
        this.setState({
            cuentas_receptoras: cuentas_disponibles
        })
        //Actualizamos el valor del input de cuenta receptora si hay cuentas disponibles
        if (cuentas_disponibles.length > 0) {
            this.modalTraspaso.current.setFieldsValue({
                cuentaReceptora: cuentas_disponibles[0]._id

            });
        } else {
            this.modalTraspaso.current.setFieldsValue({
                cuentaReceptora: undefined
            })
        }
    }


    //Función de guardado-edición dependiendo de la propiedad "accionModal"
    btnSaveClickModal = values => {
        const { accionModal } = this.props;

        console.log('values',values);
        //Se ejecuta al crear un nuevo traspaso
        if (accionModal === "crear") {
            axios.post('/transacciones/add', {
                concepto: values.concepto,
                cuenta_id: values.cuentaRemitente,
                cuenta_destino_id: values.cuentaReceptora,
                monto: values.monto,
                area_id: values.area,
                proyecto_id: values.proyecto,
                rubro_id: values.rubro,
                sub_rubro_id: values.subrubro,
                tipo: 3,
                comprobantes: values?.comprobantes?.map(({ name }) => name),
                fecha: values.fecha,
                actualiza_saldo: true, 
            })
                .then((res) => {
                    message.success("Traspaso Registrado!");
                    this.props.accept()

                }).catch((error) => {
                    Modal.warning({
                        title: 'Error',
                        content: error.response.data.message

                    });
                });
        } else if (accionModal === "editar") {
            const { registroId } = this.props;

            axios.post('/transacciones/traspaso/update', {
                id: registroId,
                concepto: values.concepto,
                monto: values.monto,
            }).then(() => {
                message.success('Transaccion Actualizada')
                this.props.accept()
            })
                .catch(() => {
                    message.error('Ocurrio un error, no se edito la transaccion.')
                })


        }
    }

    //Función que reinicializa los states y llama al método de cierre en componente padre
    hideModal = () => {
        //Reiniciar valores al cerrar modal
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false,
            edicion: false,
            det_concepto: '{{Concepto}}',
            det_fecha: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
            det_proyecto: '{{Proyecto}}',
            det_rubro: '{{Rubro}}',
            det_subrubro: '{{Subrubro}}',
            det_cuentaRem: '{{Cuenta Remitente}}',
            det_cuentaRec: '{{Cuenta Receptora}}',
            det_monto: '{{Monto}}',
        })

        this.props.closeMethod();
    }



    /**
     *
     *
     * @memberof ModalTran
     * @method loadAreas
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                dataAreas: response.data.data
            })
        }).catch((error) => {
        })
    }


    onAreaChange = (id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        }).then(async (rubros) => {
            this.setState({ dataRubros: rubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataRubros.length > 0) {
                this.modalTraspaso.current.setFieldsValue({
                    rubro: rubros.data.data[0]._id,

                });
                this.onRubroChange(rubros.data.data[0]._id)
            } else {
                this.modalTraspaso.current.setFieldsValue({
                    rubro: undefined,
                    subrubro: undefined
                })
            }
        }).catch((error) => {

        })
    }

    /**
     *
     *
     * @memberof ModalTran
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (e) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: e
            }
        }).then((subrubros) => {
            this.setState({
                dataSubrubros: subrubros.data.data
            })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataSubrubros.length > 0) {
                this.modalTraspaso.current.setFieldsValue({
                    subrubro: subrubros.data.data[0]._id
                });
            } else {
                this.modalTraspaso.current.setFieldsValue({
                    subrubro: undefined
                })
            }


        }).catch((error) => {
        })


    }


        /**
     *
     * @memberof ModalTransaccion
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
         normFile = (e) => {
            const { file, fileList } = e;
    
    
            console.log('file', file);
            console.log('fileList', fileList);
    
            let upload = false;
    
            for (let x = 0; x < fileList.length; x++) {
                if (fileList[x].response) {
                    fileList[x].name = fileList[x].response.filename
    
                }
    
                if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                    this.state.loading = true;
                    this.setState({ update: !this.state.update })
                    break;
                }
            }
    
    
    
    
            // for (let x = 0; x < fileList.length; x++) {
            //     if (fileList[x].status === "done" && !upload && this.state.loading) {
            //         this.state.loading = false;
            //         this.setState({ update: !this.state.update })
            //         upload = false;
            //         break;
            //     } else upload = true;
            // }
    
    
            /**
             * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
             */
            if (file.status === "done") {
                this.setState({
                    image: {
                        url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                        name: e.file.response.filename
                    }
                });
    
            }
            if (file.status === "removed")
                this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
            return e && e.fileList;
        };
    



    render() {
        const { modalVisible, accionModal, registroId } = this.props;

        var titulo = '';
        if (accionModal === "crear") {
            titulo = "Nuevo Traspaso"
        }

        else if (accionModal === "editar")
            titulo = "Editar Traspaso"

        var contenido = "";

        if (accionModal === "crear" || accionModal === "editar") {
            contenido = <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={this.modalTraspaso}
                    className="frm-transacciones"
                    name="formulario-transacciones"
                    onFinish={this.btnSaveClickModal}
                >
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker disabled={this.state.edicion}
                                    format="DD/MM/YYYY"
                                //defaultValue={(!this.state.edicion) ? moment(new Date, 'DD/MM/YYYY') : ''}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el proyecto"
                                }]}
                            >
                                <Select placeholder="Seleccione proyecto" disabled={this.state.edicion}>
                                    {this.state.dataProyectos.map(function (proyecto, index) {
                                        return <Option value={proyecto._id}>{proyecto.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                    </Row>
                    <Row gutter={[18, 45]} style={{ margin: '2em 0' }}>
                        <Col xs={24} lg={8} style={{ margin: '0' }}>
                            <Form.Item
                                label="Area"
                                name="area"
                                listType="text"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Area a que corresponde la transacccion."
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione el Area"
                                    disabled={this.state.edicion}
                                    onChange={this.onAreaChange}
                                >
                                    {this.state.dataAreas.map(function (area, index) {
                                        return <Option value={area._id}>{area.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                        <Col xs={24} lg={8} style={{ margin: '0' }}>
                            <Form.Item
                                label="Rubro"
                                name="rubro"
                                listType="text"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el rubro"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione rubro"
                                    disabled={this.state.edicion}
                                    onChange={this.onRubroChange}
                                >
                                    {this.state.dataRubros.map(function (rubro, index) {
                                        return <Option value={rubro._id}>{rubro.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                        <Col xs={24} lg={8} style={{ margin: '0' }}>
                            <Form.Item
                                label="Subrubro"
                                name="subrubro"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el subrubro"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione subrubro"
                                    disabled={this.state.edicion}
                                    value={this.state.dataRubros[0]}
                                >
                                    {this.state.dataSubrubros.map(function (subrubro, index) {
                                        return <Option value={subrubro._id}>{subrubro.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                    </Row>
                    <Row style={{ margin: '2em 0' }}>
                        <Col xs={24} lg={12} style={{ margin: '0' }}>
                            <Form.Item
                                label="Cuenta Remitente"
                                name="cuentaRemitente"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta remitente"
                                }]}
                            >
                                <Select placeholder="Seleccione cuenta" disabled={this.state.edicion}
                                    onChange={this.onCuentaChange}>
                                    {this.state.dataCuentas.map(function (cuenta, index) {
                                        return <Option value={cuenta._id}>{cuenta.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                        <Col xs={24} lg={12} style={{ margin: '0' }}>
                            <Form.Item
                                label="Cuenta Receptora"
                                name="cuentaReceptora"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta receptora"
                                }]}
                            >
                                <Select placeholder="Seleccione cuenta" disabled={this.state.edicion}>
                                    {this.state.cuentas_receptoras.map(function (cuenta, index) {
                                        return <Option value={cuenta._id}>{cuenta.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                    </Row>
                    <Row style={{ margin: '0' }}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Monto"
                                name="monto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber
                                    prefix={'$'}
                                    min={0}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>


                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                label="Comprobantes"
                                name="comprobantes"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    multiple={true}
                                >
                                    <Button>
                                        <UploadOutlined /> Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        }


        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{titulo}</Title>

                </div>
                {contenido}
            </Modal>
        )
    }
}

export default ModalTraspaso;
