import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, Divider, message, Carousel } from 'antd';

//compoenentes
import { PlusOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { FinanzasForm, ProveedoresForm, CRMForm, TraspasoForm, AcreedoresForm } from "./Formularios";

//css
import './../../../../Styles/modales.css'

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;

class ModalClasificarTransacciones extends Component {

    modalTransaccion = React.createRef();
    carouselRef = React.createRef();
    sliderControl = React.createRef();
    slideForms = React.createRef();

    static defaultProps = {
        onFinish: () => { },
        transacciones: []
    }

    constructor(props) {
        super(props);
        const { transacciones } = this.props;


        // const cantidad =transacciones
        this.state = {
            dataAreas: [],
            dataRubros: [],
            dataSubrubros: [],
            dataProyectos: [],
            proveedores: [],
            concepto: !(Object.keys(transacciones).length > 1),
            viewText: {
                0: 'Finanzas',
                1: 'Proveedores',
                2: 'CRM',
                3: 'Traspaso',
                4: 'Acredores',
            },

            sliderControl: null,
            slideForms: null,
            page: 0,
        }
    }


    /**
    * @memberof ModalClasificarTransacciones
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
        this.loadAreas()
        this.loadProyectos()
        this.loadProveedores()


        this.setState({
            sliderControl: this.sliderControl,
            slideForms: this.slideForms
        });



    }

    /**
     *
     *
     * @memberof ModalTran
     * @method loadProyectos
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadProyectos = () => {
        axios.get('/proyectos', {
            params: {
                page: 1,
                limit: 200
            }
        }).then((proyectos) => {
            this.setState({
                dataProyectos: proyectos.data.data.itemsList
            })
        }).catch((error) => {

        })
    }

    /**
     *
     *
     * @memberof ModalClasificarTransacciones
     * @method loadAreas
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
    */
    loadProveedores = () => {
        axios.get('/proveedores/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                proveedores: response.data.data
            })
        }).catch((error) => {
        })
    }

    /**
     *
     *
     * @memberof ModalClasificarTransacciones
     * @method loadAreas
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                dataAreas: response.data.data
            })
        }).catch((error) => {
        })
    }


    /**
     * @memberof ModalClasificarTransacciones
     *
     * @method onFinish
     * @description Guarda las transacciones en la BD
     *
     */
    onFinish = (values) => {

        console.log({
            ...values,
            cuenta_id: this.props.cuenta_id,
            transacciones: Object.values(this.props.transacciones)
        })

        axios.post('/belvo/cuenta/transacciones/add', {
            ...values,
            cuenta_id: this.props.cuenta_id,
            transacciones: Object.values(this.props.transacciones)
        }).then(response => {
            message.success('Transacciones clasificadas')
            this.props.clearTransacciones()
        }).catch(error => {
            let mensaje = 'Error al Clasificar las Transacciones'
            if (error.response.status === 403)
                mensaje = error.response.data.message
            message.error(mensaje)
        }).finally(() => {
            this.props.onClose()
        })
    }


    prev = () => {
        this.sliderControl.current.prev()
        //this.setTextPage()
    }

    next = () => {
        this.sliderControl.current.next()

    }


    render() {

        const { transacciones } = this.props;
        const { viewText, page } = this.state;

        return (
            <div>
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onClose}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title"> Clasificar</Title>
                </div>
                <div>

                    <Row className="mb-2">
                        <Col span={24} className="center">
                            <div className="btn-carrousel">
                                <Button className="btn-arrow" onClick={()=>this.prev()}><LeftOutlined/></Button>
                                    <Carousel
                                        ref={this.sliderControl}
                                        asNavFor={this.state.slideForms}
                                        dots={false}
                                    >
                                        <div>
                                            <Text>Finanzas</Text>
                                        </div>
                                        <div>
                                            <Text>Proveedores</Text>
                                        </div>
                                        <div>
                                            <Text>CRM</Text>
                                        </div>
                                        <div>
                                            <Text>Traspaso</Text>
                                        </div>
                                        <div>
                                            <Text>Acreedores</Text>
                                        </div>
                                    </Carousel>
                                <Button className="btn-arrow" onClick={()=>this.next()}><RightOutlined/></Button>
                            </div>
                        </Col>
                    </Row>

                    

                    <Carousel
                        //asNavFor={this.state.sliderControl}
                        ref={slider => (this.slideForms = slider)}
                    >
                        <div>
                            <FinanzasForm 
                                proyectos={this.state.dataProyectos}
                                areas={this.state.dataAreas}
                                cuenta_id={this.props.cuenta_id}
                                account_id={this.props.account_id}
                                transacciones = {this.props.transacciones}
                                onClose={this.props.onClose}
                                clearTransacciones={this.props.clearTransacciones}
                            />
                        </div>
                        <div>
                           <ProveedoresForm 
                                proyectos={this.state.dataProyectos}
                                proveedores={this.state.proveedores}
                                areas={this.state.dataAreas}
                                cuenta_id={this.props.cuenta_id}
                                account_id={this.props.account_id}
                                transacciones = {this.props.transacciones}
                                onClose={this.props.onClose}
                                clearTransacciones={this.props.clearTransacciones}
                            />
                        </div>
                        <div>
                            <CRMForm
                                cuenta_id={this.props.cuenta_id}
                                account_id={this.props.account_id}
                                transacciones = {this.props.transacciones}
                                onClose={this.props.onClose}
                                clearTransacciones={this.props.clearTransacciones}
                            />
                        </div>
                        <div>
                            <TraspasoForm
                                proyectos={this.state.dataProyectos}
                                areas={this.state.dataAreas}
                                cuenta_id={this.props.cuenta_id}
                                account_id={this.props.account_id}
                                transacciones = {this.props.transacciones}
                                onClose={this.props.onClose}
                                clearTransacciones={this.props.clearTransacciones}
                                proveedores={this.state.proveedores}
                            />
                        </div>
                        <div>
                            <AcreedoresForm
                                cuenta_id={this.props.cuenta_id}
                                account_id={this.props.account_id}
                                transacciones = {this.props.transacciones}
                                onClose={this.props.onClose}
                                clearTransacciones={this.props.clearTransacciones}
                            />
                        </div>
                    </Carousel>
                </div>
            </div>
        )
    }
}




export default function (props) {


    return < Modal
        visible={props.visible}
        //visible={true}
        onCancel={props.onClose}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}
    >
        <ModalClasificarTransacciones {...props} />
    </Modal >


}



