import React from 'react'
import { Drawer, Form, Select, Cascader, message, Badge, Button, DatePicker } from 'antd'
import axios from 'axios'
import CAvatar from '../Avatar/Avatar'
import { DownloadOutlined } from '@ant-design/icons'





const { Option } = Select





/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
export default class FiltrosDrawer extends React.Component {

    /**
     *
     *
     * @export
     * @class FiltrosDrawer
     * @extends {React.Component}
     * 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state ordenes Objeto para paginar y buscar en el select de ordenes
     * @state ordenes_compras Objeto para paginar y buscar en el select de ordenes de compra
     * @state areas Objeto para paginar y buscar en el select de areas  
     * @state areas_dictionary Diccionario de Areas. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state rubros_dictionary Diccionario de Rubros. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state razones_sociales Objeto para paginar y buscar en el select de Razones Sociales
     * @state clasificadores Objeto para paginar y buscar en el select de Clasificadores 
     * 
     * @state paginator.data Lista de elementos para paginar
     * @state paginator.dictionary Ya que los selects funcionan solo con ID´s, utilizamos el diccionario para obtener el objeto al que el ID hace referencia
     * @state paginator.page Pagina actual
     * @state paginator.limit Limit de elementos por página
     * @state paginator.pages Total de paginas
     * @state paginator.total Total de Elementos en la lista
     * @state paginator.search Para buscar
     * @state paginator.search Loading, para declarar el actualizado.
     */
    state = {

        cuentas: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },


        proyectos: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        clientes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        productos: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        proveedores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        sub_conceptos: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },


        propiedades: {
            data: {},
            dictionary: {},
        },

        tipo: {
            dictionary: {},
        },

        areas: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 10,
            search: null,
            loading: false,
            _ids: []
        },

        rubros: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 10,
            search: null,
            loading: false,
            _ids: []
        },

        subrubros: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 10,
            search: null,
            loading: false
        },

        sub_conceptos: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },


        proveedores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        }
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.cuentas === undefined || this.props.cuentas !== false)
            this.getCuentas()

        if (this.props.clientes === undefined || this.props.clientes !== false)
            this.getClientes()

        if (this.props.proveedores === undefined || this.props.proveedores !== false)
            this.getProveedores()

        this.getProyectos()
        this.getAreas()
        this.getRubros()
        this.getSubrubros()
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * 
     * @description Para obtener las cuentas en la lista
     */
    getCuentas = ({ page, limit, search } = this.state.cuentas, { cuentas } = this.state) => {

        cuentas.loading = true;
        cuentas.page = page;
        cuentas.limit = limit;

        this.setState({ cuentas })

        axios.get('/cuentas/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                cuentas.data = (page === 1) ? response.data.data.itemsList : [...cuentas.data, ...response.data.data.itemsList];

                cuentas.dictionary = {}
                cuentas.data.map(d => cuentas.dictionary[d._id] = d)

                cuentas.total = response.data.data.paginator.itemCount
                cuentas.pages = response.data.data.paginator.pageCount
                cuentas.loading = false;

                this.setState({ cuentas })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.cuentas.loading = false
                    return state
                })
            })
    }


    /**
      * @memberof Almacenes
      * @method getSubConceptos
      * @description  Obtiene la informacion del proyecto seleccionado
      **/
    getSubConceptos = ({ page, limit, search } = this.state.sub_conceptos, { sub_conceptos } = this.state) => {

        axios.get('/sub-conceptos/list', {
            params: {
                proyecto_id: this.props.proyecto_id,
                concepto_id: this.props.concepto_id,
                page,
                search
            }
        }).then(response => {
            console.log("response", response.data.data);

            const items = response.data.data.itemsList

            sub_conceptos.dictionary = {}
            sub_conceptos.data = items
            items.map(d => sub_conceptos.dictionary[d._id] = d)


            this.setState({
                sub_conceptos
            })

        }).catch(error => {
            message.error('Error al obtener los sub conceptos')
        })
    }


    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * 
     * @description Para obtener las cuentas en la lista
     */
    getProyectos = ({ page, limit, search } = this.state.proyectos, { proyectos } = this.state) => {

        proyectos.loading = true;
        proyectos.page = page;
        proyectos.limit = limit;

        this.setState({ proyectos })

        axios.get('/proyectos', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                proyectos.data = (page === 1) ? response.data.data.itemsList : [...proyectos.data, ...response.data.data.itemsList];

                proyectos.dictionary = {}
                proyectos.data.map(d => proyectos.dictionary[d._id] = d)

                proyectos.total = response.data.data.paginator.itemCount
                proyectos.pages = response.data.data.paginator.pageCount
                proyectos.loading = false;

                this.setState({ proyectos })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.cuentas.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.clientes]
     * @param {*} [{ clientes }=this.state]
     * @memberof FiltrosDrawer
     * @method getClientes
     * 
     * @description Para obtener los clientes en la lista
     */
    getClientes = ({ page, limit, search } = this.state.clientes, { clientes } = this.state) => {

        clientes.loading = true;
        clientes.page = page;
        clientes.limit = limit;

        this.setState({ clientes })

        axios.get('/clientes/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {
                clientes.data = (page === 1) ? response.data.data.itemsList : [...clientes.data, ...response.data.data.itemsList];

                clientes.dictionary = {}
                clientes.data.map(d => clientes.dictionary[d._id] = d)

                clientes.total = response.data.data.paginator.itemCount
                clientes.pages = response.data.data.paginator.pageCount
                clientes.loading = false;

                this.setState({ clientes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clientes.loading = false
                    return state
                })
            })
    }



    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getProveedores
    * 
    * @description Obtenemos los proveedores
    */
    getProveedores = ({ page, limit, search } = this.state.proveedores, { proveedores } = this.state) => {

        proveedores.loading = true;
        proveedores.page = page;
        proveedores.limit = limit;
        this.setState({ proveedores })

        axios.get('/proveedores/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                proveedores.data = (page === 1) ? response.data.data.itemsList : [...proveedores.data, ...response.data.data.itemsList];


                proveedores.dictionary = {}
                proveedores.data.map(d => proveedores.dictionary[d._id] = d)

                proveedores.total = response.data.data.paginator.itemCount
                proveedores.pages = response.data.data.paginator.pageCount
                proveedores.loading = false;

                this.setState({ proveedores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.proveedores.loading = false
                    return state
                })
            })
    }





    /**
     *
     *
     * @param {*} [{page, limit, search}=this.state.areas]
     * @param {*} [{areas}=this.state]
     * @memberof FiltrosDrawer
     * @method getAreas
     * @description Obtiene el listado de areas
     */
    getAreas = ({ page, limit, search } = this.state.areas, { areas } = this.state) => {
        this.setState(state => {
            state.areas.loading = true
            return state
        })
        return axios.get('/areas/list', {
            params: {
                page,
                limit,
                search,
                paginate: true
            }
        })
            .then(({ data }) => {
                const items = data.data.itemsList

                areas.dictionary = {}
                areas.data = items
                items.map(d => areas.dictionary[d._id] = d)

                this.setState({ areas })
            })
            .catch(error => {
                console.log(error)
                message.error("Error al obtener las areas")
            })
            .finally(() => {
                this.setState(state => {
                    state.areas.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{page, limit, search}=this.state.rubros]
     * @param {*} [{rubros}=this.state]
     * @param {*} [{_ids}=this.state.areas]
     * @memberof FiltrosDrawer
     * @method getRubros
     * @description Obtiene el listado de rubros
     */
    getRubros = ({ page, limit, search } = this.state.rubros, { rubros } = this.state, { _ids } = this.state.areas) => {

        this.setState(state => {
            state.rubros.loading = true
            return state
        })

        axios.get('/rubros/list', {
            params: {
                page,
                limit,
                search,
                area_ids: _ids,
                paginate: true
            }
        }).then(({ data }) => {
            const items = data.data.itemsList
            rubros.dictionary = {}
            rubros.data = items
            items.map(d => rubros.dictionary[d._id] = d)

            this.setState({ rubros })
        }).catch(error => {
            console.log(error)
            message.error("Error al obtener los rubros")
        }).finally(() => {
            this.setState(state => {
                state.rubros.loading = false
                return state
            })
        })
    }

    /**
     *
     *
     * @param {*} [{page, limit, search}=this.state.subrubros]
     * @param {*} [{subrubros}=this.state]
     * @param {*} [{_ids}=this.state.rubros]
     * @memberof FiltrosDrawer
     * @method getSubrubros
     * @description Obtiene un listado de subrubros
     */
    getSubrubros = ({ page, limit, search } = this.state.subrubros, { subrubros } = this.state, { _ids } = this.state.rubros) => {
        this.setState(state => {
            state.subrubros.loading = true
            return state
        })
        axios.get('/subrubros/list', {
            params: {
                page,
                limit,
                search,
                rubro_ids: _ids,
                paginate: true
            }
        }).then(({ data }) => {
            const items = data.data.itemsList

            subrubros.dictionary = {}
            subrubros.data = items
            items.map(d => subrubros.dictionary[d._id] = d)

            this.setState({ subrubros })
        }).catch(error => {
            console.log(error)
            message.error("Error al obtener los subrubros")
        }).finally(() => {
            this.setState(state => {
                state.subrubros.loading = false
                return state
            })
        })
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = ({ fechas, areas, rubros, subrubros, proyectos, clientes, propiedades, cuentas, tipo }) => {
        let filtros = []

        let addToFilter = (name, objectName, humanizeName, array) => array.map(element => filtros.push({

            //Los elementos a filtrar (normalmente es un arreglo de elementos)
            _id: element,//clasificador,

            //Este es el nombre con el que se ve el filtro (el tag) en transacciones
            name: name,

            //Este es el nombre del campo. 
            objectName,

            //Este es el nombre con el que el reporté visualizará el filtro. 
            humanizeName,

            //Estos son los elemtnso con los que se filtrará, se obtienen mediante el diccionario. 
            filter: this.state[objectName].dictionary[element]
        }))

        if (fechas) filtros.push({
            _id: fechas,
            name: "createdAt",
            objectName: "createdAt",
            humanizeName: "Rango de Tiempo",
            type: "date",
        })

        if (proyectos) addToFilter("proyecto_id", "proyectos", "Proyectos", proyectos)
        if (clientes) addToFilter("cliente_id", "clientes", "Clientes", clientes)
        if (propiedades) addToFilter("propiedad_id", "propiedades", "Propiedades", propiedades)
        if (cuentas) addToFilter("cuenta_id", "cuentas", "Cuentas", cuentas)

        if (areas) {
            this.setState(state => {
                state.areas._ids = areas
                return state
            }, () => this.getRubros())
            addToFilter("area_id", "areas", "Areas", areas)
        }
        if (rubros) {
            this.setState(state => {
                state.rubros._ids = rubros
                return state
            }, () => this.getSubrubros())
            addToFilter("rubro_id", "rubros", "Rubros", rubros)
        }

        if (subrubros) addToFilter("sub_rubro_id", "subrubros", "Subrubros", subrubros)
        if (tipo) addToFilter("tipo", "tipo", "Tipo", tipo)

        if (this.props.updateFilters)
            this.props.updateFilters(filtros)
    }


    /**
     * @memberof FiltrosDrawer
     * @method downloadCSV
     */
    downloadCSV = () => {
        let url = new URL(axios.defaults.baseURL + '/' + this.props.csv)
        url.searchParams.set('csv', true)
        url.searchParams.set('Authorization', sessionStorage.getItem('token'))
        window.open(url, '_blank');
    }

    render() {

        const { onClose, visible } = this.props
        const { clientes, proyectos, propiedades, cuentas, razones_sociales, clasificadores, ordenes, ordenes_compras, negocios, proveedores, sub_conceptos } = this.state

        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            visible={visible}
            extra={
                (this.props.csv !== undefined) ?
                    <Button
                        onClick={this.downloadCSV}
                        icon={<DownloadOutlined />}
                    >CSV</Button>
                    : null
            }>
            <Form
                ref={ref => this.formFiltros = ref}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >
                <Form.Item label="Fechas" name="fechas" >
                    <DatePicker.RangePicker style={{ width: '100%' }} />
                </Form.Item>
                {(this.props.cuentas === undefined || this.props.cuentas !== false) ?
                    <Form.Item label="Cuentas" name="cuentas">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !cuentas?.loading && cuentas.page < clientes.pages) ? this.getCuentas({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getCuentas({ search, page: 1 })}
                        >
                            {cuentas.data.map(cuenta => <Option key={cuenta._id} value={cuenta._id}>
                                <CAvatar
                                    size='small'
                                    image={cuenta.logo}
                                    name={cuenta.nombre}
                                    color={cuenta.color}
                                /> &nbsp;{cuenta.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.proyectos === undefined || this.props.proyectos !== false) ?
                    <Form.Item label="Proyectos" name="proyectos">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onSearch={search => this.getProyectos({ search, page: 1 })}
                        >
                            {proyectos.data.map(cuenta => <Option key={cuenta._id} value={cuenta._id}>
                                <CAvatar
                                    size='small'
                                    image={cuenta.logo}
                                    name={cuenta.nombre}
                                    color={cuenta.color}
                                /> &nbsp;{cuenta.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}

                {(this.props.clientes === undefined || this.props.clientes !== false) ?
                    <Form.Item label="Clientes" name="clientes">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}
                            onSelect={(e, option) => {
                                this.setState(state => {
                                    state.propiedades.data[e] = option.cliente.propiedades
                                    return state
                                })
                            }}
                        >
                            {clientes.data.map(cliente => <Option key={cliente._id} cliente={cliente} value={cliente._id}>{cliente.razon_social || cliente.nombre || cliente.email}</Option>)}
                        </Select>
                    </Form.Item> : null}
                <Form.Item label="Propiedades" name="propiedades">
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        filterOption={false}

                    >
                        {Object.entries(propiedades.data).map(cliente => cliente[1].map(propiedad => <Option key={propiedad._id} value={propiedad._id}>{propiedad?.inmueble_id?.nombre}</Option>))}
                    </Select>
                </Form.Item>

                {this.state.areas.data.length > 0 &&
                    <Form.Item label="Áreas" name="areas">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onSearch={search => this.getAreas({ search, page: 1 })}
                            onSelect={() => { }}
                        >
                            {this.state.areas.data.map(area => <Option key={area._id} value={area._id}>{area.nombre}</Option>)}
                        </Select>
                    </Form.Item>
                }


                {this.state.rubros.data.length > 0 &&
                    <Form.Item label="Rubros" name="rubros">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onSearch={search => this.getRubros({ search, page: 1 })}
                            onSelect={() => { }}
                        >
                            {this.state.rubros.data.map(rubro => <Option key={rubro._id} value={rubro._id}>{rubro.nombre}</Option>)}
                        </Select>
                    </Form.Item>
                }

                {this.state.subrubros.data.length > 0 &&
                    <Form.Item label="Subrubros" name="subrubros">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onSearch={search => this.getSubrubros({ search, page: 1 })}
                            onSelect={() => { }}
                        >
                            {this.state.subrubros.data.map(subrubro => <Option key={subrubro._id} value={subrubro._id}>{subrubro.nombre}</Option>)}
                        </Select>
                    </Form.Item>
                }

                {(this.props.proveedores === undefined || this.props.proveedores !== false) ?
                    <Form.Item label="Proveedores" name="proveedores">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !proveedores?.loading && proveedores.page < proveedores.pages) ? this.getProveedores({ page: proveedores.page + 1 }) : null}
                            onSearch={search => this.getProveedores({ search, page: 1 })}
                        >
                            {proveedores.data.map(proveedor => <Option key={proveedor._id} value={proveedor._id}><Badge color={proveedor.color} />{proveedor.razon_social}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.tipos === undefined || this.props.tipos !== false) ?

                    <Form.Item label="Tipo" name="tipo">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                        >
                            <Option value={1}>Ingreso</Option>
                            <Option value={2}>Egreso</Option>
                            <Option value={3}>Traspaso</Option>
                        </Select>
                    </Form.Item> : null}

                {(this.props.sub_conceptos === undefined || this.props.sub_conceptos !== false) ?
                    <Form.Item label="Sub Conceptos" name="sub_conceptos">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !sub_conceptos?.loading && sub_conceptos.page < sub_conceptos.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getSubConceptos({ search, page: 1 })}
                        >
                            {sub_conceptos.data.map(sub_concepto => <Option key={sub_concepto._id} sub_concepto={sub_concepto} value={sub_concepto._id}>{sub_concepto.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}

            </Form>
        </Drawer>)
    }
};