import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, Table, message, Tooltip} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconPlus, IconArrowBack} from '../../Widgets/Iconos';

//css
import '../../../Styles/presupuesto.css'

const { Text } = Typography;
const axios = require('axios').default

  
export default class PresupuestoCasas extends Component{

	constructor(props){
		super(props);
		this.state= {
            columnasHeader:[],
            casas: [],
            pagado_total: 0,
            por_pagar_total: 0,
            porcentaje_total: 0, 
            loading: false,
		}
	}

    volver = () => {
        window.history.back();
    }

    /**
    * @memberof PresupuestoCasasAct
    *
    * @method   componentDidMount
    * @description  
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.listInmuebles()
    }

     /**
    * @memberof PresupuestoCasasAct
    *
    * @method   componentDidMount
    * @description  
    *
    **/
    listInmuebles = () => {
        this.setState({loading: true})
        let columnas = [
              {
                title:() => <span><img src={'/images/PE.png'} width='140'/></span>,
                dataIndex: 'inmueble_id',
                key: 'inmueble_id',
                fixed: 'left',
                width: '170px',
                render: (text,record) => record.footer ? null : <Link className="hover-effect-all" to={`/admin/presupuesto/inmueble/${text?.inmueble_trabajo_id}/actividades`}> 
                    <span className="text-green">{text?.nombre}</span>
                </Link>
              },{
                title:() => <span className="text-greenLigth">Pagado</span>,
                dataIndex: 'inmueble_pagado',
                key: 'inmueble_pagado',
               
                render: text => <span className="text-greenLigth">$ {text.toMoney(2)} MXN</span>
              },{
                title:() => <span className="text-yellow">Por pagar</span>,
                dataIndex: 'por_pagar',
                key: 'por_pagar',
               
                render: text => <span className="text-yellow">$ {text.toMoney(2)} MXN</span>
              },{
                title:() => <span className="text-purple">Porcentaje</span>,
                dataIndex: 'porcentaje',
                key: 'porcentaje',
               
                render: text => <span className="text-purple">{text.toMoney(2)} %</span>
              },
              
            ];
        axios.get('/presupuesto/proforma',{
            params:{}
        }).then(response => {
            let colsTemp = response.data.data.columnas
            let pagado_total = 0, por_pagar_total = 0, presupuesto_total = 0, porcentaje_total = 0

            response.data.data.casas.forEach(casa => {
                pagado_total += casa.inmueble_pagado
                por_pagar_total += casa.por_pagar
                presupuesto_total += casa.presupuesto_inmueble
            })

            colsTemp.forEach( col => {
                //inserta las actividades a la tabla (columnas)
                columnas.splice(1,0,{
                    title:() => <span className="text-blue">{col.nombre}</span>,
                    dataIndex: `${col.inmueble_id.toString()}`,
                    key: `${col.inmueble_id.toString()}`,
                    render: (text,record) => record.footer ? null : <Tooltip placement="topLeft" title={<div className="hover-info">
                            <div>Mano de Obra: $ { text ? text.monto_MO ? text.monto_MO?.toMoney(2) : '0' : '0'} MXN</div>
                            <div>Materiales: $ { text ? text.monto_MA ? text.monto_MA?.toMoney(2) : '0' : '0'} MXN</div>
                    </div>}>
                        <div  className="hover-effect-all-2"> 
                            <span>$ { text ? text.monto_total.toMoney(2) : '0.00'}</span>
                        </div>
                    </Tooltip> 
                    
                })
            })
            porcentaje_total = (pagado_total * 100) / presupuesto_total

            response.data.data.casas.push({footer: true, inmueble_pagado: pagado_total, por_pagar: por_pagar_total, porcentaje: porcentaje_total})
            this.setState({
                columnasHeader: columnas, 
                inmuebles: response.data.data.casas,
                pagado_total: pagado_total,
                por_pagar_total: por_pagar_total,
                porcentaje_total: porcentaje_total,
                loading: false,
            })

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la informacion')
        })
    }

	render(){
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} xl={6} xxl={8}>
                        <Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
                            <IconArrowBack />
                        </Button>
                        <h2 style={{width: 'auto', float: 'left'}}>Presupuesto Casas</h2>
                    </Col>
				</Row>
				<section className="presupuesto-ctn p-1 mb-3">
                    <div style={{overflowX: 'auto'}}> 
						<Table className="presupuesto-table "
                            loading={this.state.loading} 
							columns={this.state.columnasHeader} 
							dataSource={this.state.inmuebles} 
							pagination={false}
                            // footer={()=>{
                            //     return <div className="table-footer">
                            //         <span className="text-greenLigth" style={{minWidth: '106px'}}>$ {this.state.pagado_total.toMoney(2)} MXN</span>
                            //         <span className="text-yellow" style={{minWidth: '116px'}}>$ {this.state.por_pagar_total.toMoney(2)} MXN</span>
                            //         <span className="text-purple" style={{minWidth: '106px'}}>{this.state.porcentaje_total.toMoney(2)} %</span>                                    
                            //     </div>
                            // }}
						/>
                    </div>	
				</section>
			</div>
		)
	}
}