import React, { Component } from 'react';
import { DatePicker, Select, Card, Statistic, Typography, List, Avatar } from 'antd'



import axios from 'axios';
import CustomAvatar from '../../Widgets/Avatar/Avatar';

import '../../../Styles/dashboard.css'


const { Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;



/**
 *
 *
 * @export
 * @class CardCuentas
 * @extends {Component}
 * 
 * @description Vista del dashboard
 */
export default class CardCuentas extends Component {


    /**
     *
     *
     * @state start Fecha de inicio
     * @state end Fecha Final
     * 
     * @state cuentas Arreglo de Cuentas
     */
    state = {
        start: undefined,
        end: undefined,
        cuentas: []
    };
    
    /**
     *
     *
     * @memberof CardCuentas
     * @description Iniciamlizamos el componente
     */
    componentDidMount(){
        this.getCuentas()
    }
    

    /**
     *
     *
     * @memberof CardCuentas
     * 
     * @method getCuentas
     * @description Obtenemos las cuentas
     */
    getCuentas = () => {
        axios.get('/dashboard/saldo-cuentas',{
            params:{
                sat: true
            }
        })
            .then(({data}) => {
                this.setState({
                    cuentas: data.data,
                })
            })
    }


    render() {
        return (
            <Card className="card-dashboard" justify="center" style={{ textAlign: "center" }}>
                <Statistic title="Saldo Actual" prefix="$" value={this.state.cuentas.reduce((accumulator, cuenta) => accumulator + cuenta.saldo, 0)} precision={2} style={{ fontWeight: "bold", marginBottom: '1em' }} />
                <List
                    style={{
                        overflow: 'scroll',
                        height: '330px',
                        overflowX: 'hidden'
                    }}
                    itemLayout="horizontal"
                    dataSource={this.state.cuentas}

                    renderItem={({ saldo, logo, nombre, color }) => (
                        <List.Item
                            actions={[
                                <Statistic prefix="$" precision={2} value={saldo} />
                            ]}
                        >
                            <List.Item.Meta
                                avatar={
                                    <CustomAvatar
                                        image={logo}
                                        name={nombre}
                                        color={color}
                                    />
                                }
                                title={<Title level={5} style={{ textAlign: "start", fontWeight: "bold" }}>{nombre}</Title>}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        )
    }
}
