import React, { Component } from 'react';
import {
    List,
    Button,
    Col,
    Row,
    Card,
    Typography,
    Pagination,
    InputNumber,
    Modal,
    Spin,
    Checkbox,
    message
} from 'antd'
import { FaMinus } from "react-icons/fa";



import axios from 'axios';


import Avatar from '../../../Widgets/Avatar/Avatar'

import ModalTajos from './ModalTajos'

import {
    DownloadOutlined
} from '@ant-design/icons';


import '../../../../Styles/nominas.css'

import { IconArrowBack } from '../../../Widgets/Iconos';

const accounting = require('accounting-js')
const moment = require("moment")
const { Title, Paragraph, } = Typography;


/**
 * 
 * @class ListaEmpleados
 * @description Lista de empleados del inmueble a agregar a la nómina.
*/
export default class ListaEmpleados extends Component {

    /**
     *
     * @constructor Inicia el componente junto a sus propidades
     *
     * @var nombre Nombre del Grupo
     * @var inmueble Objeto inmueble para poner enla descripción.
     * @var empleados Lista de empleados .
     * @var empleados.data Lista de usuarios del inmueble a agregar.
     * @var empleados.page Pagina de la lista de empleados
     * @var empleados.limit Limite de elementos por pagina
     * @var empleados.total Total de tajos agregados
     * @var empleados.search Empleados a busacar
     * @var modalTajosVisible Modal de tajo a buscar
     * @var empleado_id ID del empleado a enviar al modal
     */
    constructor(props) {
        super(props);

        this.state = {
            inmueble: {
                inmueble_id: {},
                responsable_id: {
                    avatar: '',
                    nombre: '',
                    apellido: ''
                }
            },
            empleados: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                search: null
            },
            modalTajosVisible: false,
            empleado_id: null

        };
    }


    /**
     * @methodOf ListaEmpleados
     * @function componentDidMount
     *
     * @description Obtenemos los empleados y la cantida d de tajos de la nómina.
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false)

        this.actualizarLista()
    }



    actualizarLista = () => {
        if (this.props.salariales === true)
            this.getEmpleados()
        else
            this.getEmpleadoTajos()

    }



    getEmpleados = (page = this.state.empleados.page, limit = this.state.empleados.limit, total = this.state.empleados.total, search = this.state.empleados.search) => {
        this.setState({ loading: true })

        axios.post('/nominas/empleados', {
            nomina_id: this.props.match.params.nomina_id,
            page,
            limit
        })
            .then(({ data }) => {
                const { empleados } = data.data
                this.setState({
                    inmueble: null,
                    empleados: {
                        data: empleados.data,
                        page: page,
                        limit: limit,
                        total: empleados.total,
                        search: search,
                    },
                    loading: false
                })

            }).catch(error=>{
                console.log(error)
                message.error('Error al cargar la información')
            })

    }



    actualizarSalario = (index, e) => {
        this.setState(state => {
            state.empleados.data[index].salario = e;
            return state;
        })
    }

    actualizarEmpleado = (index, e) => {
        this.setState({ loading: true })


        let empleado = this.state.empleados.data[index]

        console.log('EMPLEADO');


        axios.post('/nominas/empleados/aceptar', {
            empleado_id: this.state.empleados.data[index].usuario._id,
            nomina_id: this.props.match.params.nomina_id,
            aceptar_empleado: e.target.checked,
            salario: this.state.empleados.data[index].salario,
        })

            .then(({ data }) => {

                console.log('data', data);
            })
            .catch(({ response }) => {
                console.log('response', response);
            })
            .finally(e => {
                // this.setState(state => {
                //     state.empleados.data[index].loading = false;
                //     return state;
                // })
                this.getEmpleados();
            })


    }

    /**
     * 
     * @methodOf ListaEmpleados
     * @function getEmpleadoTajos
     *
     * @description Paginacion de empleado
     *  
     * @param {*} page pagina del empleado
     * @param {*} limit limite de empleados
     * @param {*} total total de empleados
     * @param {*} search busqueda de empleados
     */
    getEmpleadoTajos = (page = this.state.empleados.page, limit = this.state.empleados.limit, total = this.state.empleados.total, search = this.state.empleados.search) => {

        console.log('page',page);
        console.log('limit',limit);
        this.setState({ loading: true })
        axios.post('/nominas/inmueble/empleados', {
            inmueble_id: this.props.match.params.inmueble_id,
            nomina_id: this.props.match.params.nomina_id,
            page,
            limit
        })
            .then(({ data }) => {
                const { inmueble, empleados } = data.data
                this.setState({
                    inmueble,
                    empleados: {
                        data: empleados.data,
                        page: page,
                        limit: limit,
                        total: empleados.total,
                        search: search,
                    },
                    loading: false
                })
            })
            .catch(e => {

            })

    }

    /**
     * 
     * @methodOf ListaEmpleados
     * @function showModal
     *
     * @description Mostrar el modal del empleado indicado
     *  
     * @param {*} empleado_id ID del empleado
     */
    showModal = (empleado_id) => {
        this.setState({
            modalTajosVisible: true,
            empleado_id
        })
    };

    /**
     * 
     * @methodOf ListaEmpleados
     * @function hideModal
     *
     * @description Ocultar el modal y ponemos el empleado ID oculto. Actualizamos la lista de empleados
     *  
     * @param {*} empleado_id ID del empleado
     */
    hideModal = () => {
        this.setState({
            modalTajosVisible: false,
            empleado_id: null
        })
        this.getEmpleadoTajos()
    };

    /**
     * 
     * @methodOf ListaEmpleados
     * @function actualizarTajosEmpleados
     *
     * @description Actualizamos todos los empleados 
     *  
     * @param {*} index Indicie de empleados
     * @param bool checked CHECKED
     */
    actualizarTajosEmpleados = (index, checked) => {
        this.setState(state => {
            state.empleados.data[index].loading = true;
            return state;
        })

        axios.post('/nominas/inmueble/empleados/tajos/aceptar', {
            empleado_id: this.state.empleados.data[index].usuario._id,
            nomina_id: this.props.match.params.nomina_id,
            inmueble_id: this.props.match.params.inmueble_id,
            acepto_todo: checked.target.checked
        })

            .then(({ data }) => {
                console.log('data', data);
            })
            .catch(({ response }) => {
                console.log('response', response);
            })
            .finally(e => {
                this.setState(state => {
                    state.empleados.data[index].loading = false;
                    return state;
                })
                this.getEmpleadoTajos();
            })
    }


    render() {

        const { empleados, inmueble, modalTajosVisible } = this.state;
        const { deleteEmployee } = this;

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={8} xxl={12}>
                            {(this.state.inmueble !== null) ? 
                            <h2> <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline' }}><IconArrowBack /></Button>  Inmueble</h2> : 
                            <h2> <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline' }}><IconArrowBack /></Button>  Lista de Empleados</h2>}
                            
                        </Col>
                    </Row>
                    <Row style={{ padding: '24px' }} className="content">
                        {(this.state.inmueble !== null) ? <Row gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col xs={24} sm={24} xl={24}>
                                <Card className="content-card">
                                    <Row>
                                        <Col span={18}>
                                            <Title level={3} style={{ color: "#0047FF" }}>{inmueble.inmueble_id.nombre}</Title>
                                        </Col>
                                        <Col span={6} className="information-content">
                                            <Avatar className="information-content-avatar"
                                                image={inmueble.responsable_id.avatar}
                                                name={[
                                                    inmueble.responsable_id.nombre,
                                                    inmueble.responsable_id.apellido
                                                ]}
                                            />
                                            <Title className="information-content-title">{inmueble.responsable_id.nombre}</Title>
                                        </Col>
                                    </Row>
                                    <Row className={"information-widgets-content"}>
                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Empleados</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{empleados.total}</Paragraph>
                                        </Col>
                                    </Row>

                                    <Row className={"information-widgets-content "}>
                                        <Col span={6}>
                                            <Paragraph className="information-widgets-content-title">Responsable</Paragraph>
                                            <Avatar className="information-content-avatar"
                                                image={inmueble.responsable_id.avatar}
                                                name={[
                                                    inmueble.responsable_id.nombre,
                                                    inmueble.responsable_id.apellido
                                                ]}
                                            />
                                            <label className="information-widgets-content-description" style={{ marginLeft: '10px' }}>{inmueble.responsable_id.nombre + ' ' + inmueble.responsable_id.apellido}</label>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                            : null}
                        <Row className="list-container pt-1" gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>Empleados</Title>
                            </Col>
                            <Col span={24} style={{ paddingTop: 0 }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={empleados.data}
                                    className="list-employees"
                                    renderItem={({ loading, usuario, status, nominaSize, tajosSize, salario, enNomina }, index) => (
                                        <Spin spinning={loading} style={{ width: '100%' }}>
                                            <List.Item className="list-employees-item-content">
                                                {console.log('item', usuario)}
                                                <Row className="list-employee-item" style={{ width: '100%' }}>
                                                    <Col span={6}>
                                                        <Avatar className="information-content-avatar"
                                                            image={usuario.avatar}
                                                            name={[
                                                                usuario.nombre,
                                                                usuario.apellido
                                                            ]}
                                                            style={{ position: 'relative', left: -10 }}
                                                        />
                                                        {usuario.nombre + ' ' + usuario.apellido}
                                                    </Col>
                                                    {
                                                        (this.state.inmueble === null) ? <Col span={6} className="center">
                                                            <InputNumber
                                                                precision={2}
                                                                className="input-ghost"
                                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                style={{ width: 'calc(100% - 20px)', margin: '0px auto' }}
                                                                value={salario}

                                                                onChange={(value => this.actualizarSalario(index, value))}
                                                            />
                                                        </Col>
                                                            :
                                                            null
                                                    }
                                                    <Col span={6} className="center">
                                                        <Checkbox
                                                            checked={(status === 1 || status === 2)}
                                                            indeterminate={(this.state.inmueble !== null) ? (status === 2) : enNomina}
                                                            onChange={e => (this.state.inmueble !== null) ? this.actualizarTajosEmpleados(index, e) : this.actualizarEmpleado(index, e)}>
                                                            {(status === 0) ? "Empleado Rechazado" : "Empleado Aceptado"}
                                                        </Checkbox>
                                                    </Col>
                                                    {
                                                        (this.state.inmueble !== null) ? <Col span={6} className="center">
                                                            {nominaSize}/{tajosSize} tajos aceptados.
                                                        </Col> : null
                                                    }
                                                    {
                                                        (this.state.inmueble !== null) ? <Col span={6}>
                                                            <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => this.showModal(usuario._id)} >
                                                                Ver tajos
                                                        </Button>
                                                        </Col> : null
                                                    }
                                                </Row>
                                            </List.Item>
                                        </Spin>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Pagination total={this.state.empleados.total} onChange={(e,f)=>{
                                
                                console.log('e',e);
                                console.log('f',f);
                            if (this.props.salariales === true)
                                this.getEmpleados(e,f)
                            else
                                this.getEmpleadoTajos(e,f)


                            }}/>
                        </Row>
                    </Row>

                </Spin>


                {/* modalTajosVisiblemodalTajosVisible
                modalTajosVisible */}
                <ModalTajos
                    modalVisible={modalTajosVisible}
                    hideModal={this.hideModal}
                    empleado_id={this.state.empleado_id}
                    nomina_id={this.props.match.params.nomina_id}
                    inmueble_id={this.props.match.params.inmueble_id}
                />


            </div>
        )
    }
}
