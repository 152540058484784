import React, { Component } from 'react';
import { Button, Card, Col, message, Popover, Row, Spin, Statistic, Typography, Modal, List, DatePicker, Checkbox, Form, Space, Popconfirm, Tooltip } from 'antd'
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';
//componentes
import { CardTransaccionesSC } from '../../Widgets/Cards/Cards';
import { IconDetails, IconPlus, IconCheckGreen, IconSave, IconCheckAll, IconCheckTree, IconArrowBack, IconDelete } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import Logged from "../../../Hooks/Logged";
import Timer from '../../Widgets/Timer';
//css
import 'react-multi-carousel/lib/styles.css';
import '../../../Styles/finanzas.css'

import { ConsoleSqlOutlined, SyncOutlined } from '@ant-design/icons'

//modales
import ModalClasificar from '../Modales/Syncfy/ModalClasificarTransaccion';
// import FormularioValidacion from '../../Widgets/FormularioValidacion';
// import ValidaciónMFA from "../../Admin/Modales/Belvo/ValidaciónMFA";


import "@paybook/sync-widget/dist/widget.css";
import SyncWidget from "@paybook/sync-widget";

var QRCode = require('qrcode.react');
const moment = require('moment');
const { Text, Title } = Typography;
const { RangePicker } = DatePicker


export default class TRansaccionesSC extends Component {

    static contextType = Logged

    static defaultProps = {
        transacciones: []
    }

    volver = () => {
        window.history.back();
    }

    constructor(props) {

        super(props)

        this.state = {
            // transacciones: [],

            transacciones: {
                data: [],
                page: 1,
                limit: 200,
                total: 0,
                pages: 0,

                filter: {}
            },
            page: 1,
            size: 50,
            of: moment('20210820'),
            to: moment(),
            modalClasificarVisible: false,
            seleccionadas: {},
            vista_guardadas: false,
            update: true,

            cuenta: {},
            actualizarCuentas: true,
            skip: 0
        }
    }

    async componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.guardadas) {
            this.getGuardadas()
        } else {
            await this.getCuenta()
            await this.props.setUserId(this.state.cuenta.syncfy_usuario_id)
            this.getTransacciones()
        }
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method getAccount
     * @description Obtenemos la información de la cuenta, espcificamente dos campos, los de syncfy
     */
    getCuenta = (id = this.props.match.params.cuenta_id) => {
        this.setState({ loading: true })
        return axios.get("/cuentas/get", { params: { id } })
            .then(({ data }) => {

                this.state.cuenta = data.data

                console.log('B N F ',moment().diff(moment(data.data.syncfy_fecha_actualizacion_saldo), 'days') >= 1
                )
                console.log(moment().diff(moment(data.data.syncfy_fecha_actualizacion_saldo), 'days'))
                // a.diff(b, 'days')
                if (moment().diff(moment(data.data.syncfy_fecha_actualizacion_saldo), 'days') >= 1) {
                    this.renderWidget(data.data.cuenta.syncfy_credenciales_id)

                }

            })
            .catch(res => {
                console.log("no obtenida", res);
                message.error('Error al obtener la información de la cuenta.')
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
     *
     * @memberof TRansaccionesSC
     * 
     * @method getTransacciones
     * @description Incia una nueva sesion de Syncfy
     */
    getTransacciones = async () => {
        this.setState({ loading: true })
        axios.get('/syncfy/transacciones', {
            params: {
                cuenta_id: this.props.match.params.cuenta_id,
                clasificadas: this.props.clasificadas,
                token: this.props.token,
                actualizarCuentas: this.state.actualizarCuentas,

                page: this.state.transacciones.page,
                limit: this.state.transacciones.limit,
                fechas: (Array.isArray(this.state.transacciones.filter.fechas)) ? this.state.transacciones.filter.fechas.map(e => e.toDate()) : null

            }
        }).then(response => {
            console.log(response.data)
            this.setState(state => {
                state.transacciones.data = response.data.data.data
                state.transacciones.total = response.data.data.total
                state.transacciones.pages = response.data.data.pages
                return state;
            })
            this.props.restartTimer()
            //this.props.actions.start(1000 * 60 * 5)

        }).catch(error => {
            if (error?.response?.data?.data?.nombre) {
                Modal.warning({
                    title: error.response?.data?.data?.nombre,
                    content: error.response?.data?.data?.description,
                    onOk: (error.response?.data?.data?.nombre === "Unauthorized" || error.response?.data?.data?.nombre === "Invalidtoken") ? this.renderWidget() : undefined
                })
            }

            // this.renderWidget(this.state.cuenta?.syncfy_credenciales_id)
        }).finally(() => this.setState({ loading: false }))

    }



    /**
    *
    * @memberof TRansaccionesSC
    * 
    * @method actualizarTodas
    * @description Selecciona toas si se hace click en el boton
    */
    actualizarTodas = () => {

        let { seleccionadas, transacciones } = this.state

        const selecionadasLenght = Object.keys(this.state.seleccionadas).length

        if (selecionadasLenght !== 0 && selecionadasLenght === this.state.transacciones.length) {

            seleccionadas = {}

        } else {
            for (const transaccion of transacciones)
                seleccionadas[transaccion.id_transaction] = transaccion
        }
        this.setState({ seleccionadas: { ...seleccionadas } })
    }

    /**
    *
    * @memberof TRansaccionesSC
    * 
    * @method guardarTransaccionesSeleccionadas
    * @description Abre el modal si se seleccionaron transacciones
    */
    guardarTransaccionesSeleccionadas = () => {

        const selecionadasLenght = Object.keys(this.state.seleccionadas).length

        if (selecionadasLenght === 0)
            return Modal.error({
                title: "Debe seleccionar algunas transacciones",
                content: "Para poder agregar transacciones "
            })

        this.setState({
            modalClasificarVisible: true
        })
    }

    /**
    * @memberof FacturasSC
    * @method   gurdarDespues
    * @description  Las Facturas seleccionadas la guarda el la coleccion de Guardadas, para clasificarlas despues
    **/
    gurdarDespues = () => {
        if(this.state.loading === true) return;
        this.state.loading = true

        let { seleccionadas } = this.state
        let array_selec = []

        for (var [key, value] of Object.entries(seleccionadas)) {
            array_selec.push(value)
        }

        if (array_selec.length === 0) {
            message.error('No hay Transacciones Seleccionadas')
            this.setState({loading: false})
        } else {
            axios.post('/syncfy/guardar/despues', {
                seleccionadas: array_selec,
                tipo_documento: 0, //transacciones
                cuenta_id: this.props.match.params.cuenta_id,
            }).then(response => {
                this.setState({seleccionadas: {}})
                message.success('Transacciones Guardadas para despues')
                this.getTransacciones()
            }).catch(error => {
                message.error('Error al guardar')
            })
        }

    }

    /**
   * @memberof FacturasSC
   * @method   getGuardadas
   * @description  Obtiene las facturas guardadas para clasificar despues
   **/
    getGuardadas = () => {
        this.setState({ loading: true, vista_guardadas: true })
        axios.get('/syncfy/guardadas', {
            params: {
                tipo_documento: 0, //transacciones
                cuenta_id: this.props.match.params.cuenta_id,
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState(state => {
                state.transacciones.data = response.data.data.data
                state.transacciones.total = response.data.data.total
                state.transacciones.pages = response.data.data.pages
                return state;
            })
        }).catch(error => {
            console.log(error)
        }).finally(() => { this.setState({ loading: false }) })
    }

    /**
    * @memberof FacturasSC
    * @method   deleteGuardados
    * @description  Elimina de Guardados
    **/
    deleteGuardados = (id) => {
        this.setState({ loading: true })
        axios.post('/syncfy/guardadas/delete', {
            _id: id
        }).then(response => {
            message.success('Eliminado de Guardados')
            this.getGuardadas()
        }).catch(error => {
            console.log(error)
        }).finally(() => { this.setState({ loading: false }) })
    }


    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof TransaccionesSC
     * 
     * @method renderWidget
     * @description En caso de que sea invalido, renderiza el widget.
     */
    renderWidget = (credential = this.state.cuenta.syncfy_credenciales_id) => {

        this.setState({ loadingRender: true })
        if (sessionStorage.getItem('syncfy') && sessionStorage.getItem('syncfy') !== null) {
            if (!window.syncWidget)
                window.syncWidget = new SyncWidget({
                    // Set up the token you created in the Quickstart:
                    token: sessionStorage.getItem('syncfy'),
                    config: {
                        // Set up the language to use:
                        locale: 'es',
                        navigation: {
                            displayStatusInToast: true,
                        },

                        ...(credential ? {
                            entrypoint: {
                                credential
                            },
                        } : {

                        })
                    },
                })
            else {
                window.syncWidget.setConfig({
                    ...(credential ? {
                        entrypoint: {
                            credential
                        },
                    } : {

                    })
                })
                window.syncWidget.setToken(sessionStorage.getItem('syncfy'))
            }

            const defaultFunction = () => {
                this.setState({ loadingRender: false })
                this.getTransacciones()
            }
            window.syncWidget.$on("error", defaultFunction);
            window.syncWidget.$on("success", defaultFunction);
            window.syncWidget.$on("closed", () => {
                Modal.warning({
                    title: "Al cerrar, no se actualizarán las credenciales.",
                    content: "Si cierras este modal, no será posible obtener los datos mas recientes de la cuenta.",
                    onOk: () => defaultFunction()
                })
            });
            window.syncWidget.$on("api-error", defaultFunction);

            if (!credential)
                window.window.syncWidget.open()

            return
        }
        return Modal.error({
            title: "Debe seleccionar un Usuario de Syncfy para poder generar las credenciales."
        })
    }


    render() {
        const { vista_guardadas } = this.state
        return (
            <div>

                <Row className="row-encabezado">
                    <Col xs={24} xl={6} xxl={6} className="">
                        <h2>{(this.props.clasificadas === true) ? "Transacciones Clasificadas" : "Transacciones Sin Clasificar"}</h2>
                    </Col>
                    <Col xs={24} xl={17} xxl={17} className="center">
                        <Form
                            noStyle
                            onValuesChange={(value, allValues) => this.setState(state => {
                                state.transacciones.filter = allValues
                                state.transacciones.page = 1
                                return state
                            }, () => this.getTransacciones())}
                            style={{ width: '100%', position: 'relative', left: '-10px' }}
                        >
                            <Space style={{ float: "right" }}>
                                <Form.Item
                                    name="fechas"
                                    noStyle>
                                    <RangePicker
                                        placeholder={['Inicio', 'Fin']}
                                        className="input-picker"
                                        style={{
                                            background: 'transparent !important',
                                            color: '#FFF',
                                            float: 'right',
                                            width: '100%'
                                        }} />
                                </Form.Item>
                                {(this.props.guardadas !== true) ? <Tooltip title="Actualizar las credenciales de Syncfy.">
                                    <Button type="link" style={{ border: '1px solid white', borderRadius: 2 }} disabled={this.state.loadingRender} icon={<SyncOutlined spin={this.state.loadingRender} style={{ color: "white" }} />} onClick={() => this.renderWidget()} />
                                </Tooltip> : null}
                            </Space>
                        </Form>
                    </Col>
                    <Col>
                        {!this.props.guardadas ? <Timer timerCount={this.props.timerCount} startSession={this.props.startSession} /> : null }
                    </Col>
                </Row>
                {/* <Row className="row-encabezado">
                    <Col xs={24} md={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <h2>{(this.props.clasificadas === true) ? "Transacciones Clasificadas" : "Transacciones Sin Clasificar"}</h2>
                    </Col>
                    <Col>
                        <Timer timerCount={this.props.timerCount} startSession={this.props.startSession} />
                    </Col>
                </Row> */}

                <section className="p-1">
                    <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles">
                        <Col span={8} className="center">
                            <Button onClick={this.actualizarTodas} className="filter-purple">Transacciones <div className="title-divider">
                                <IconCheckAll className="ml-1" />
                            </div>
                            </Button>
                        </Col>
                        <Col span={6} className="center">

                        </Col>
                        <Col span={5} className="center">
                            <Text type="secondary">Fecha</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text type="secondary">Monto</Text>
                        </Col>
                    </Row>
                    <div className="card">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            loading={this.state.loading}
                            dataSource={this.state.transacciones.data}
                            locale={{
                                emptyText: <div>
                                    No hay Transacciones registradas
                                    <br />
                                </div>
                            }}
                            renderItem={item => {
                                const { amount, dt_transaction, description, transaccion, id_transaction, category, currency } = item;
                                return (
                                    <List.Item className="component-list-item-small">
                                        <Card className="card-list-small">
                                            <Row style={{ width: '100%' }} className="">
                                                <Col span={2} className="center">
                                                    <Checkbox className="radio-purple"
                                                        disabled={(transaccion && transaccion._id)}
                                                        checked={(transaccion && transaccion._id) || (this.state.seleccionadas[id_transaction])}
                                                        onChange={value => {
                                                            this.setState(state => {
                                                                if (value.target.checked === true)
                                                                    state.seleccionadas[id_transaction] = item
                                                                else
                                                                    delete state.seleccionadas[id_transaction]
                                                                return state;
                                                            })
                                                        }}
                                                    ></Checkbox>
                                                </Col>
                                                <Col span={12} className="center">
                                                    <Text>{description}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text strong>{moment.unix(dt_transaction).format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Statistic value={amount} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: (amount < 0) ? '#FF0000' : '#00FF19' }} prefix={'$'} suffix={currency} />
                                                </Col>
                                                <Col span={1} className="center">
                                                    {vista_guardadas ? <Popconfirm placement="topRight"
                                                        title="¿Eliminar de Guardados?" okText="Si, seguro" cancelText="No"
                                                        onConfirm={() => {
                                                            this.deleteGuardados(item._id)
                                                        }}>
                                                        <Button type="primary"
                                                            className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                            title="Eliminar">
                                                            <IconDelete />
                                                        </Button>
                                                    </Popconfirm> : null
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )
                            }}

                            pagination={{
                                onChange: (page, limit) => this.setState(state => {
                                    state.transacciones.page = page;
                                    state.transacciones.limit = limit;
                                    return state;
                                }, () => this.getTransacciones()),

                                pageSizeOptions: [10, 20, 50, 100, 500],
                                showSizeChanger: true,

                                pageSize: this.state.transacciones.limit,
                                total: this.state.transacciones.total,
                                current: this.state.transacciones.page,

                                hideOnSinglePage: false,
                                showQuickJumper: false,
                                className: "pagination-list"
                            }}


                        />
                    </div>
                </section>
                <ModalClasificar
                    visible={this.state.modalClasificarVisible}
                    onClose={() => {
                        this.setState({ modalClasificarVisible: false })
                        if (this.props.guardadas) {
                            this.getGuardadas()
                        } else {
                            this.getTransacciones()
                        }
                    }}
                    transacciones={this.state.seleccionadas}
                    clearTransacciones={() => { this.setState({ seleccionadas: {} }) }}
                    cuenta_id={this.props.match.params.cuenta_id}
                />
                <Button className="btnCheckGreen" title="" disabled={this.state.loading} onClick={() => this.guardarTransaccionesSeleccionadas()} >
                    <IconCheckGreen />
                </Button>

                {!this.props.guardadas ? <Button className="btnSaveBlue" disabled={this.state.loading} onClick={() => this.gurdarDespues()}>
                    <IconSave />
                </Button> : null}
            </div>
        )
    }
}


