import React, { Component } from "react";
import { Button, Col, Form, InputNumber, Modal, Divider, Input, Row, Spin, Typography } from 'antd';
import PropTypes from "prop-types";
import { IconCloseModal } from '../../../Widgets/Iconos';
import DropzoneAntDesign from './../../../Widgets/Documents/Documents';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './../../../../Styles/modales.css'


const moment = require('moment');
const axios = require('axios').default;
moment.locale('es');
const { Title, } = Typography;


class ModalAdicionales extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            adicionales: [],
            update: false,
        }
    }

    refDocumentos = React.createRef();
    /**
     * @memberof ModalAdicionales
     * @method componentDidMount
     * @description Se ejecuta al finalizar el render de la vista.
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log('se monto modal adicionales')
        this.getComprobantes()
    }


    /**
     * @memberof ModalAdicionales
     * @method getTransacciones
     * @description obtiene las transacciones hechas a una propiedad
     */
    getComprobantes = () => {
        this.setState({ loading: true })
        axios.get('/clientes/propiedad/get', {
            params: {
                id: this.props.propiedad?._id
            }
        })
            .then(cliente => {
                console.log('cliente prop', cliente)
                let data = cliente.data.data;
                let form_adicionales = data.adicionales;


                let adicionales = form_adicionales?.map(
                    (adicional, _index) => {
                        return {
                            monto: adicional.monto,
                            documentos: adicional.documentos?.map(file => {
                                return {
                                    lastModified: new Date().getMilliseconds(),
                                    lastModifiedDate: new Date(),
                                    name: file,
                                    url: axios.defaults.baseURL + '/voucher/' + file,
                                    status: 'start',
                                    percent: 0,
                                    deleteFileEvent: (file, index) => this.deleteDocumento(file, index, _index)
                                }
                            })
                        }
                    })

                this.setState({ adicionales: adicionales })
                this.refDocumentos.current.resetFields()
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }




    /**
     *
     *
     * @memberof ModalAdicionales
     */
    deleteDocumento = async (doc, index, element) => {
        let elements = this.state.adicionales;
        elements[element].documentos.splice(index, 1);

        let adicionales = elements.map(
            adicional => {
                return {
                    monto: adicional.monto,
                    documentos: adicional.documentos.map(doc => doc.name)
                }
            })
        axios.post('/clientes/propiedad/update', {
            propiedad_id: this.props.propiedad?._id,
            adicionales: adicionales
        })
            .then((e) => {
                this.props.onClose()
            })
    };


    /**
     *
     *
     * @memberof ModalAdicionales
     */
    saveDocumentos = async (values) => {
        let adicionales = values.adicionales.map(
            adicional => {
                return {
                    monto: adicional.monto,
                    documentos: adicional?.documentos?.map(doc => doc.name)
                }
            })
        axios.post('/clientes/propiedad/update', {
            propiedad_id: this.props.propiedad?._id,
            adicionales: adicionales
        })
            .then((e) => this.props.onClose())
    }



    render() {
        return (
            [
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onClose}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Adicionales</Title>
                </div>,
                <Spin spinning={this.state.loading}>
                    <Form
                        layout="vertical"
                        ref={this.refDocumentos}
                        className="frm-transacciones"
                        onFinish={this.saveDocumentos}
                        initialValues={{
                            adicionales: this.state.adicionales
                        }}
                    >
                        <Form.List name="adicionales">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Row key={key} gutter={[8, 8]} justify="start" >
                                            <Divider style={{
                                                fontWeight: 700,
                                                color: 'blue',
                                            }}>Adicional # {++restField.fieldKey}</Divider>
                                            <Col span={8}>
                                                <Form.Item
                                                    label="Monto"
                                                    {...restField}
                                                    name={[name, 'monto']}
                                                >
                                                    <InputNumber />
                                                </Form.Item>
                                            </Col>
                                            <Col span={15}>
                                                <Form.Item
                                                    label="Documentos"
                                                    {...restField}
                                                    name={[name, 'documentos']}
                                                    valuePropName="fileList"
                                                >
                                                    <DropzoneAntDesign
                                                        type="list"
                                                        styleDropzone={{ width: '100%', height: '40px', lineHeight: '18px' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}><MinusCircleOutlined style={{ display: 'block', margin: '48px 0px' }} onClick={() => remove(name)} /></Col>
                                        </Row>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Añadir
                                        </Button>
                                    </Form.Item>

                                </>

                            )}

                        </Form.List>

                        <Row className="text-center">
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-azul">
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin >

            ]
        )
    }
}
export default function (props) {

    const { visible, onClose } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className={"modal-form"}
        destroyOnClose={true}
        zIndex={1000}
    >
        <ModalAdicionales {...props} />
    </Modal >

}