import React, {Component} from 'react';
import {Button, Col, Row, Spin, Empty, Typography, List, Card, Popconfirm, Breadcrumb, Pagination, message} from 'antd'
import {FaArrowRight} from "react-icons/fa";
import {Link} from 'react-router-dom';

//componentes
import {CardInfo} from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import {IconEdit, IconDelete, IconPlus, IconEye, IconCopy, IconMaterialBtn, IconGo} from '../../Widgets/Iconos';
//Modales
import ModalActividadesManager from '../Modales/Manager/ModalActividadesManager';
//css
import '../../../Styles/projectManager.css'

const axios = require('axios').default;
const moment = require('moment');
const {Text} = Typography;


export default class InmuebleAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalActividadVisible: false,
            loading: false,
            actividades: [],

            manager_inmueble_id: this.props.match.params.manager_inmueble_id,

            modalTitle: 'Crear',
            actividad_id: undefined,

            /*paginador*/
            total: 0,
            page: 1,
            limit: 100,
            total_pages: 0,


            onEdit: false,

            manager_inmueble: {
                proyecto_id: {},
                manager_proyecto_id: {},
                inmueble_id: {},
                costo_presupuestado: 0,
                progreso_proyectado: 0,
                progreso_real: 0
            },

            inmueble_id: undefined,
            proyecto_id: undefined,

            sumaProgresoProyectado: 0,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadActividades(1)
    }

    /**
     * @memberof InmuebleAdmin
     *
     * @method   hideModal
     * @description  Cambia state para cerrar cualquier modal
     *
     **/
    hideModal = () => {
        this.setState({modalActividadVisible: false, actividad_id: undefined})
        this.loadActividades(this.state.page)
    }

    /**
     * @memberof InmuebleAdmin
     *
     * @method   loadActividades
     * @description  trae la lista de actividades de un inmueble
     *
     * */
    loadActividades = (page = this.state.page, limit = this.state.limit) => {
        this.setState({loading: true})
        axios.get("/manager/proyectos/inmuebles/actividades", {
            params: {
                page: page,
                limit: limit,
                manager_inmueble_id: this.state.manager_inmueble_id //--------id de Inmueble de url
            },
        })
            .then(({data}) => {
                console.log('Actividades',data)
                this.setState({
                    loading: false,

                    actividades: data.data.actividades.itemsList,
                    manager_inmueble: data.data.inmueble,
                    manager_proyecto_id: data.data.inmueble.manager_proyecto_id,

                    inmueble_id: data.data.inmueble.inmueble_id._id,
                    proyecto_id: data.data.inmueble.proyecto_id._id,

                    /*paginator*/
                    total: data.data.actividades.total,
                    page: data.data.actividades.page,
                    total_pages: data.data.actividades.itemCount,

                    sumaProgresoProyectado: data.extra.sumaProgresoProyectado,
                })
            })
            .catch(e => console.log('error al cargar las actividades', e))
    }


    /**
     * @memberof InmuebleAdmin
     *
     * @method   deleteActividad
     * @description  Elimina una Actividad de Manager_actividades
     *
     *
     **/
    deleteActividad = (actividad_id) => {
        this.setState({loading: true})
        axios.delete('/manager/proyectos/inmuebles/actividades/delete', {params: {actividad_id}})
            .then((res) => {
                this.loadActividades(this.state.page)
                message.success("!Se eliminó la actividad!");
            })
            .catch(res => {
                this.setState({loading: false})
                message.error("Error, no se pudo eliminar la actividad");
                console.log("error", res);
            });
    }


    render() {
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} className="flex-left">
                            <Breadcrumb className="breadcrumb" separator=">">
                                <Breadcrumb.Item>
                                    <Link to="/admin/manager/">Proyectos</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to={'/admin/manager/proyecto/' + this.state.manager_proyecto_id?._id}><Avatar
                                        name={this.state.manager_inmueble.proyecto_id.nombre}
                                        color={this.state.manager_inmueble.proyecto_id.color}
                                        image={this.state.manager_inmueble.proyecto_id.logo}
                                        /> {this.state.manager_inmueble.proyecto_id.nombre}
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrumb-actual">
                                    {this.state.manager_inmueble.inmueble_id.nombre}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <section className="p-1">

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo titleLeft="Inmueble"
                                          infoLeft={this.state.manager_inmueble.inmueble_id.nombre}
                                          titleRight="Presupuesto"
                                          infoRight={<div>$ {this.state.manager_inmueble.costo_presupuestado.toMoney(2)}
                                            <small>MXN</small></div>}/>
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Pondaracion Porcentual"
                                    infoLeft={<Link to={'/admin/manager/ponderacion/proyecto/inmueble/' + this.state.manager_inmueble._id}> {this.state.sumaProgresoProyectado.toMoney(2)} % <IconGo /></Link>}
                                    titleRight="Progreso Real"
                                    infoRight={
                                        <div>{this.state.manager_inmueble.progreso_real.toMoney(2)} %</div>}/>
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Fecha de Inicio"
                                    infoLeft={(this.state.manager_inmueble.fecha_inicio) ? moment(this.state.manager_inmueble.fecha_inicio).format('DD-MM-YYYY') : 'N/A'}
                                    titleRight="Fecha Finalizacion"
                                    infoRight={(this.state.manager_inmueble.fecha_conclusion) ? moment(this.state.manager_inmueble.fecha_conclusion).format('DD-MM-YYYY') : 'N/A'}/>
                            </Col>
                        </Row>

                        <div className="scroll">
                            <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                                <Col span={4} className="center ">
                                    <Text type="secondary">Actividad</Text>
                                </Col>
                                <Col span={2} className="center ">
                                    <Text type="secondary">Responsable</Text>
                                </Col>
                                <Col span={3} className="center ">
                                    <Text type="secondary">Progreso Ponderado</Text>
                                </Col>
                                <Col span={3} className="center ">
                                    <Text type="secondary">Progreso Real</Text>
                                </Col>
                                <Col span={4} className="center ">
                                    <Text type="secondary">Costo Presupuestado</Text>
                                </Col>
                                <Col span={4} className="center ">
                                    <Text type="secondary">Costo Actual</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text type="secondary">Acciones</Text>
                                </Col>
                            </Row>

                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.actividades}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{width: '100%'}} className="">
                                                <Col span={4} className="center ">
                                                    <Text strong>{item.actividad_id?.nombre}</Text>
                                                </Col>
                                                <Col span={2} className="center ">
                                                    <Avatar
                                                        name={[item.responsable_id.nombre, item.responsable_id.apellido]}
                                                        image={item.responsable_id.avatar}
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            fontSize: '20px',
                                                            paddingTop: '0px',
                                                            marginRight: '5px',
                                                        }}
                                                        className="avatar-info-card"/>
                                                </Col>
                                                <Col span={3} className="center ">
                                                    <Text>{item.progreso_proyectado.toMoney(2)} %</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Text>{item.progreso_real.toMoney(2)} %</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text>$ {item.costo_presupuestado?.toMoney(2)} MXN</Text>
                                                </Col>
                                                <Col span={4} className="center ">
                                                    <Text>$ {item.costo_actual.toMoney(2)} MXN</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Link to={`/admin/manager/proyecto/inmueble/actividad/material/${item._id}`}>
                                                        <Button className="btn btn-material">
                                                            <IconGo />
                                                        </Button>
                                                    </Link>
                                                    <Button className="btn btn-edit" onClick={(e) => {
                                                        this.setState({
                                                            modalActividadVisible: true,
                                                            modalTitle: 'Editar',
                                                            actividad_id: item._id
                                                        })
                                                    }}
                                                    >
                                                        <IconEdit/>
                                                    </Button>
                                                    <Popconfirm
                                                        title="¿Deseas Eliminar?"
                                                        placement="topRight"
                                                        onConfirm={e => {
                                                            this.deleteActividad(item._id)
                                                        }}
                                                        onCancel={e => e.stopPropagation()}
                                                        okText="Si"
                                                        cancelText="No"
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        <Button className="btn btn-delete"
                                                                onClick={e => e.stopPropagation()}>
                                                            <IconDelete/>
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </div>


                        <Row className="mt-2 pb-3">
                            <Pagination
                                // defaultCurrent={1}
                                total={this.state.total}
                                defaultPageSize={this.state.limit}
                                showSizeChanger={true}
                                onChange={(page,limit) => {
                                    this.loadActividades(page,limit)
                                }}
                            />
                        </Row>

                    </section>


                    <Button
                        className="btnAddPlus"
                        onClick={() => this.setState({modalActividadVisible: true, modalTitle: 'Crear'})}>
                        <IconPlus/>
                    </Button>
                </Spin>
                <ModalActividadesManager
                    proyecto_id={this.state.proyecto_id}
                    inmueble_id={this.state.inmueble_id}
                    actividad_id = {this.state.actividad_id}
                    manager_inmueble = {this.state.manager_inmueble}
                    modalTitle={this.state.modalTitle}
                    modalVisible={this.state.modalActividadVisible}
                    closeMethod={this.hideModal}/>
            </div>
        )
    }
}
