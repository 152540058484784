import React, { Component } from "react";

import { Spin, Row, Col, Button, Modal, DatePicker, Typography, Form, message, InputNumber } from 'antd';


import { IconCloseModal } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'


const moment = require('moment')
const axios = require('axios').default


const { Title } = Typography;

export default class ModalEntrega extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    /**
    * @memberof ModalEntrega
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    /**
    * @memberof ModalEntrega
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props  
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
    }

    /**
    * @memberof ModalEntrega
    *
    * @method onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        var cantidad = parseFloat(values.cantidad);

        if (cantidad > 0) {

            this.setState({ loading: true })
            const { managerActividad, material } = this.props;

            axios.post('/manager/proyecto/actividades/empleado/entregas/add', {
                id_manager_actividad: managerActividad,
                material_id: material,
                cantidad: values.cantidad,
                fecha: new Date(values.fecha)
            })

                .then(res => {
                    message.success('Entrada registrada!');
                    this.setState({ loading: false })
                    this.props.closeMethod();
                })
                .catch(error => {
                    console.log('error al crear entrada', error);
                })
                .finally(f => {
                })
        }
        else {
            message.error('Ingrese cantidad en toneladas');

        }
    }

    render() {

        const {
            accionModal,
            material_id,
            material_nombre,
            manager_actividad_id,
            responsable_id } = this.props;

        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                <Spin spinning={this.state.loading}>
                    <div className="modal-header">
                        <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                            <IconCloseModal />
                        </Button>
                        <Title level={3} className="modal-title">Entrega {material_nombre}</Title>
                    </div>
                    <Form
                        layout="vertical"
                        className="frm-cuentas"
                        name="formulario-entrega"
                        onFinish={this.onFinish}
                    >
                        <Row align="center">
                            <Col span={20}>
                                <Form.Item
                                    label="Fecha"
                                    name="fecha" 
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la fecha de entrada"
                                    }]}
                                >
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="center">
                            <Col span={20}>
                                <Form.Item
                                    label="Cantidad Toneladas"
                                    name="cantidad" 
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la Cantidad"
                                    }]}
                                >
                                    <InputNumber min={0} defaultValue={0} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar

                                </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
