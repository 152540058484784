import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Divider, Spin } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar'
import PrioridadSelect from '../../../Widgets/prioridadSelect'
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;

/**
 *
 *
 * @class ModalRequisiciones
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalRequisiciones extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedores: [],
            proyectos: [],
        }
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function componentDidMount
     * @description Obtiene la informacion inicial
     */
    componentDidMount(){
        this.getProveedores()
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function getProveedores
     * @description Obtiene la lista de proeedores
     */
    getProveedores = (search) => {
        axios.get('/proveedores/list', {
            params: {
                paginate: false,
                search,
            }
        }).then((proyectos) => {
            this.setState({ proveedores: proyectos.data.data })
        }).catch((error) => {
            message.error('Error al obtener a los proveedores')
        })        
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function onFinish
     * @description 
     */
    onFinish = (values) => {
        this.setState({loading: true})
        axios.post('/requisicion/opcion/add',{
            ...values,
            requisicion_id: this.props.requisicion_id
        }).then(response => {
            message.success('Requisicion añadida con éxito')
            this.props.onCancel(true)
        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading: false}))
              
    }

    render() {


        return [

            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title"> Añadir proveedor</Title>
            </div>,
            <Spin spinning={this.state.loading}>


                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.modalMateriales}
                    onFinish={this.onFinish}

                >
                    <Row align="center" gutter={[16]}>

                        <Col xs={24} md={11}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id" //<--------------------------------------- proveedor
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un proveedor"
                                }]}
                            >

                                <Select
                                    className="form-select"
                                    onSearch={(search) => this.getProveedores(search)}
                                    showSearch
                                    filterOption={false}
                                >
                                    {this.state.proveedores.map((proveedor) => <Option value={proveedor._id}>
                                        {proveedor.alias}
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Número de Parte de Manantial"
                                name="np_manantial"
                                rules={[
                                    { required: true, message: "Ingrese el número de parte del fabricante" }
                                ]} >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Número de Parte de Fabrica"
                                name="np_fabrica"
                                rules={[
                                    { required: true, message: "Ingrese el número de parte del fabricante" }
                                ]} >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Número de Parte de Proveedor"
                                name="np_proveedor"
                                rules={[
                                    { required: true, message: "Ingrese el número de parte del proveedor" }
                                ]} >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={22}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                                rules={[
                                    { required: true, message: "Ingrese la descripción" }
                                ]} >
                                <Input.TextArea placeholder="Descripción" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Marca"
                                name="marca"
                                rules={[
                                    { required: true, message: "Ingrese la marca" }
                                ]} >
                                <Input placeholder="Marca" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Costo"
                                name="costo"
                                rules={[
                                    { required: true, message: "Ingrese el costo" }
                                ]}>
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder="Costo"
                                />
                            </Form.Item>
                        </Col>

                    </Row>  

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}



export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalRequisiciones {...props} />
    </Modal>


}
