import React, { Component } from 'react';
import { Button, Col, Popconfirm, Row, Spin, Table, Typography } from 'antd'

//componentes
import { IconDelete, IconEdit, IconPlus } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import Logged from "../../../Hooks/Logged";
import axios from 'axios';
//modal
import ModalUsuarios from '../Modales/Usuarios/ModalUsuarios';
//css
import '../../../Styles/usuarios.css';

const { Title } = Typography;

let items = [
    '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
]

export default class Usuarios extends Component {
    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {

            _id: undefined,
            loading: false,
            modalVisible: false,


            /* Paginado */
            page: 1,
            limit: 10,
            total: 0,
            data: [],
            search: ""
        }
    }


    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getUsersPagination();
    }

    /**
     * @memberof Usuarios
     *
     * @method   componentDidUpdate
     * @description Si se realiza una un busqueda por la barra, actuliza el contenido
     *
    **/
    componentDidUpdate(prevProps) {
        /*Consulta con input header*/
        if (this.props.search !== prevProps.search) {
            this.setState({ search: this.props.search }, () => { this.getUsersPagination() })
        }
    }


    /**
     * @methodOf  Usuarios
     * @function getUsersPagination
     *
     * @description Obtiene la paginación de usuario
     *
     * */
    getUsersPagination = (page = this.state.page, limit = this.state.limit) => {
        this.setState({
            loading: true,
        });
        axios.get("/usuarios", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                page,
                limit,
                search: this.state.search,
            },
        })
            .then(({ data }) => {
                this.setState({
                    page: page,
                    limit: limit,
                    total: data.data.paginator.itemCount,
                    data: data.data.itemsList,
                    loading: false
                })

            })
            .catch(e => console.log('er', e))
    };

    /**
     * @methodOf  Usuarios
     * @function showModal
     *
     * @description Muestra el modal del usuario
     *
     * */
    showModal = (id = undefined) => {
        this.setState({
            visible: true,
            id: id
        });
    };

    /**
     * @methodOf  Usuarios
     * @function hideModal
     *
     * @description Oculta el modal del usuario
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };

    render() {

        const { getUsersPagination, showModal, hideModal, state, props } = this;
        const { visible, page, limit, total, data, id, loading } = state;

        const user = this.context
        return (
            <div>
                <Spin spinning={loading}>
                    <Row className="row-encabezado">
                        <Col xs={12} xl={20} xxl={20}>
                            <h2 style={{ width: 'auto', float: 'left' }}>Usuarios</h2>
                        </Col>
                        <Col xs={12} xl={4} xxl={4} className="flex-right">
                            <Title level={5} style={{
                                textAlign: "right", color: "#FFF", fontSize: '11pt', marginBottom: '0', marginRight: '2rem'
                            }}>
                                {total} Usuarios
                            </Title>
                        </Col>
                    </Row>

                    <Row className="p-1">
                        <div className="div_contenido">
                            <Table
                                scroll={{ x: 1000, y: 1000 }}
                                columns={[
                                    {
                                        title: 'Logo',
                                        key: 'avatar',
                                        width: '80px',
                                        // dataIndex: 'proyecto',
                                        render: (text, { nombre, apellido, avatar }) => {

                                            console.log('nombre', nombre)
                                            console.log('apellido', apellido)
                                            console.log('avatar', avatar)
                                            return <CustomAvatar name={nombre + ' ' + apellido} image={avatar} color={items[Math.floor(Math.random()*items.length)]} />
                                        },

                                    },
                                    {
                                        title: 'Nombre',
                                        dataIndex: 'nombre',
                                        //key: 'nombre',
                                        render: (text, record) => (
                                            <h3>{record.nombre}</h3>
                                        ),
                                    },
                                    {
                                        title: 'Apellido',
                                        dataIndex: 'apellido',
                                        key: 'apellido',
                                    },
                                    {
                                        title: 'Posicion',
                                        dataIndex: 'posicion',
                                        key: 'posicion',
                                    },
                                    {
                                        title: 'Email',
                                        dataIndex: 'email',
                                        key: 'email',
                                    },
                                    // {
                                    //     title: 'Rol',
                                    //     dataIndex: 'roles',
                                    //     key: 'roles',
                                    //     render: (text)=>{
                                    //         //console.log('text')
                                    //     },
                                    // },
                                    {
                                        title: 'Acciones',
                                        key: 'actions',
                                        fixed: 'right',
                                        width: '110px',
                                        render: (text, record) => (
                                            <div className="tbl-opciones">
                                                <Button
                                                    type="primary"
                                                    className="button-survey btn-edit button-edit"
                                                    onClick={() => showModal(record._id)}
                                                    title="Editar">
                                                    <IconEdit />
                                                </Button>
                                                {(user?.tipo === 1) ? <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este usuario?"
                                                    onConfirm={() => axios.delete('/usuarios/delete', { params: { id: record._id } }).then(() => this.getUsersPagination())}
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="primary"
                                                        className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                        title="Eliminar">
                                                        <IconDelete />
                                                    </Button>
                                                </Popconfirm> : null}


                                            </div>
                                        ),
                                    },
                                ]}
                                dataSource={data}
                                pagination={{
                                    onChange: getUsersPagination,
                                    current: page,
                                    pageSize: limit,
                                    total: total,
                                    showSizeChanger: true,
                                    position: ['bottomLeft'],
                                }}
                                locale={{ emptyText: 'Sin Usuarios Disponibles' }}
                                className="blankTheme" />
                        </div>
                    </Row>

                </Spin>
                <ModalUsuarios
                    visible={visible}
                    hideModal={hideModal}
                    accept={() => {
                        hideModal();
                        getUsersPagination();
                    }}
                    update={() => getUsersPagination()}
                    id={id}
                >
                </ModalUsuarios>
                {(user && user.tipo !==3) ?
                    <Button type="ghost" className="btnAddPlus" title="Nuevo registro" onClick={() => showModal()} >
                        <IconPlus />
                    </Button> : null

                }
            </div>
        )
    }
}
