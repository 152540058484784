import React from 'react'
import { Route, Switch } from "react-router-dom";


import Conceptos from '../../Components/Admin/Proyectos/Conceptos/Conceptos';
import SubConceptos from '../../Components/Admin/Proyectos/Conceptos/SubConceptos';


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
 export default function(props) {

    return (
        <Switch>
            <Route exact path="/admin/conceptos/:proyecto_id" render={(props_h) => <Conceptos {...props} {...props_h} />} />
            <Route exact path="/admin/conceptos/:proyecto_id/sub-conceptos/:concepto_id" render={(props_h) => <SubConceptos {...props} {...props_h} />} />
        </Switch>
    )
}

