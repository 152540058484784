import React, { Component } from "react";
import {
    Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, Popconfirm,
    message, Divider, Upload, Switch, Space
} from 'antd';

import { MinusCircleOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;


class ModalProductos extends Component {

    tipoRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            accionModal: '',
            spinning: false,
            datosConsultados: false,
            return: false,
            loadingImage: false,
            activo: true,
            owner: false,
            form: {},
            tipos: [],
            areas: [],
            existencias: []
        }
    }

    formModalProductos = React.createRef();



    /**
    * @memberof ModalProductos
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTipos();
        this.getAreas();
        this.getUserInfo();

        if (this.props.materialId !== null && this.props.materialId !== undefined) {
            this.getMaterial()
        }
        else {
            this.formModalProductos.current.setFieldsValue({
                tipo_id: this.props.tipoId
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        //Si la propiedad cambia y es diferente al estado, actualizamos el estado con el ID de la poppiedad y buscamos al usuario
        if (this.props.materialId !== this.state.id && this.props.materialId !== undefined) {
            this.state.id = this.props.materialId
            this.getMaterial(this.props.materialId)
        }

        if (this.props.materialId !== this.state.id && this.state.id !== undefined && this.props.materialId === undefined) {
            this.state.id = undefined;
            this.state.form = {};
            this.state.image = undefined;
            this.formModalProductos.current.resetFields();
        }

    }



    getUserInfo = () => {
        axios.get('/user/logged', {
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(({ data }) => {
                if (data.success) {
                    if (data.data.tipo === 1) {
                        this.setState({
                            owner: true
                        })
                    }
                }
            })
            .catch((error) => {
            })
    }

    getMaterial = async (id = this.props.materialId) => {
        axios.post('/articulos/get', {
            id
        })
            .then(({ data }) => {
                
                var info = data.data;
                var existencias = data.existencias;

                var arrayExt = [];

                for (let index = 0; index < existencias.length; index++) {
                    const ext = existencias[index];

                    arrayExt.push({
                        _id: ext._id,
                        area_id: ext.locacion_id,
                        cantidad: ext.cantidad
                    })

                }


                const imagen = info.imagen;

                if (info.imagen !== undefined && info.imagen !== null && info.imagen !== "") {
                    this.setState({
                        image: {
                            url: axios.defaults.baseURL + '/upload/' + imagen,
                            name: imagen
                        }
                    })
                    info.imagen = [{
                        uid: -1,
                        name: info.imagen,
                        status: 'done',
                        url: axios.defaults.baseURL + '/upload/' + info.imagen,
                        response: { filename: info.imagen }
                        // e.file.response.filename
                    }];

                } else info.imagen = []


                this.formModalProductos.current.setFieldsValue({
                    nombre: info.nombre,
                    descripcion: info.detalles,
                    tipo_id: info.tipo_articulo_id._id,
                    unidad: info.unidad,
                    imagen: info.imagen,
                    estatus: (info.estatus === 1),
                    existencias: arrayExt
                });

                this.setState({
                    form: info,
                    activo: (info.estatus === 1),
                    existencias: arrayExt
                })

            })
            .catch(error => console.log('error,', error))
    }

    /**
  * @memberof ModalProductos
  * @method   onFinish
  * @description  Al completar formulario :
  * Se valida el tipo de accion modal para crear nuevo material o editar el registro seleccionado
  **/
    onFinish = async (values) => {
        const { materialId } = this.props;


        // imagen: [{uid: "rc-upload-1625595962538-3", lastModified: 1591594723623,…}]
        if (Array.isArray(values.imagen) && values.imagen.length > 0) {
            values.imagen = values.imagen[0].response.filename
        }
        else {
            values.imagen = "";
        }


        if (materialId !== undefined && materialId !== null) {

            axios.post('/articulos/update', {
                id: materialId,

                nombre: values.nombre,
                detalles: values.descripcion,
                tipo_articulo_id: values.tipo_id,
                unidad: values.unidad,
                imagen: values.imagen,
                estatus: (this.state.activo) ? 1 : 0,
                existencias: values.existencias
            })
                .then((res) => {
                    message.success("Producto actualizado!");
                    this.props.onSuccess()

                })
                .catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : <div>
                            Error al procesar edición de registro. Verifique la información.
                            <br />
                            <p>
                                El campo nombre de producto es unico e irrepetible.
                            </p>
                        </div>
                    });
                });



        }
        else {
            axios.post('/articulos/add',
                {
                    nombre: values.nombre,
                    detalles: values.descripcion,
                    tipo_articulo_id: values.tipo_id,
                    unidad: values.unidad,
                    imagen: values.imagen,
                    estatus: (this.state.activo) ? 1 : 0,
                    existencias: values.existencias

                }
            )
                .then((res) => {
                    message.success("Producto Registrado!");
                    this.props.onSuccess()

                })
                .catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : <div>
                            Error al procesar registro. Verifique la información.
                            <br />
                            <p>
                                El campo nombre de producto es unico e irrepetible.
                            </p>
                        </div>
                    });
                });
        }
    }

    getTipos = (search) => {
        axios.get('/tiposArt/list', {
            params: {
                search
            }
        }).then(response => {
            
            this.setState({ tipos: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los areas')
        })
    }



    addTipo = (values) => {

        let tipo = this.tipoRef.current.state.value
        axios.post('/tipos/add', {
            nombre: tipo
        }).then(response => {
            let tipos = this.state.tipos
            tipos.push(response.data.data)
            this.setState({ tipos: tipos })
            this.tipoRef.current.state.value = null
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })
    }



    getAreas = () => {
        axios.get('/locaciones/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                areas: response.data.data
            })
        }).catch((error) => {

        })
    }




    normFile = (e) => {

        const { file } = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            if (this.state.image)
                this.removeFile(this.state.image.name)

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })

            if (this.props.materialId) {
                axios.put('/articulos/update', { imagen: e.file.response.filename, id: this.props.materialId })
                    .finally(() => {
                        this.props.update()
                    });
            }
        }
        if (file.status === "removed") {
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name)
        }


        return e && e.fileList;
    };

    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };


    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        this.state.form.imagen = [];
        this.state.image = undefined;

        this.formModaluser.current.resetFields();

        if (this.props.materialId)
            axios.put('/articulos/update', { imagen: "", id: this.props.materialId })
                .finally(() => {
                    this.props.update()
                    this.setState({ loadingImage: false })
                })
        else {
            this.props.update()
            this.setState({ loadingImage: false })
        }
    };


    UpdateEstatus = async (checked) => {
        this.setState({ activo: checked });
    }


    EliminarExistencia = async (index) => {
        var existencias = this.state.existencias;
        if (existencias.length > 0 && this.props.materialId !== "") {
            var item = existencias[index];

            if (item !== undefined && item !== null && item?._id !== undefined) {
                var id = item._id;
                axios.post('articulos/eliminarExistencia', { params: { id } })
                    .then((res) => {
                        this.getMaterial(this.props.materialId);
                        message.success("¡Se eliminó existencia!");
                    })

                    .catch(error => {
                        Modal.error({
                            title: "No es posible eliminar",
                            content: error.response?.data?.error
                        })

                    });
            }
        }
    }


    render() {
        const { normFile, formModalProductos, saveUser, deleteImage } = this;
        const { modalVisible, registroNombre, materialId } = this.props;
        const { image, loadingImage } = this.state;

        var accionModal = (materialId === null) ? "Agregar" : "Editar";

        var editarExistencia = (materialId !== undefined && materialId !== "" && this.state.owner);
        return (
            <Form
                layout="vertical"
                className="frm-cuentas"
                name="form-materiales"
                ref={formModalProductos}
                onFinish={this.onFinish}

            >
                <Row align="center">

                    <Col span={20}>
                        <Form.Item
                            label="Archivo"
                            name="imagen"
                            align="center"
                            getValueFromEvent={normFile}
                            // valuePropName={props?"fileList":"value"}
                            valuePropName="fileList"
                            help={image ? <Button
                                shape="circle"
                                danger style={{ position: 'absolute', right: '40%', bottom: '20px' }}
                                onClick={deleteImage}
                            ><DeleteOutlined /></Button> : null}
                        >
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={axios.defaults.baseURL + "/upload/add"}
                                accept="image/*"
                            >

                                {(loadingImage) ? <div>
                                    {(this.state.loading || loadingImage) ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div className="ant-upload-text">Upload</div>
                                </div> : ((image) ?
                                    <img src={image.url} alt="avatar" style={{ width: '100%' }} />
                                    :
                                    <div>
                                        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div className="ant-upload-text">Upload</div>
                                    </div>)}
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={20}>
                        <Form.Item
                            label="Nombre"
                            name="nombre" //<--------------------------------------- nombre
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese el nombre del producto"
                            }]}
                        >
                            <Input placeholder="Nombre" />
                        </Form.Item>
                    </Col>

                    <Col span={20}>
                        <Form.Item
                            label="Descripción"
                            name="descripcion" //<--------------------------------------- descripcion
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese la descripcion del producto"
                            }]}
                        >
                            <Input.TextArea placeholder="Descripción" />
                        </Form.Item>
                    </Col>

                    <Col span={12} >
                        <Form.Item
                            label="Tipo"
                            name="tipo_id"
                            listType="text"
                            rules={[{
                                required: true,
                                message: "Por favor, seleccione tipo de producto"
                            }]}
                        >
                            <Select placeholder="Seleccione tipo de producto"
                                onChange={this.onTipoChange}
                                suffixIcon={<IconArrowSelect style={{ top: "40px" }} />}
                            >
                                {this.state.tipos.map(function (tipo, index) {
                                    return <Option value={tipo._id}>{tipo.nombre}</Option>
                                })}
                            </Select>
                        </Form.Item>

                    </Col>


                    <Col span={7} offset={1} >
                        <Form.Item
                            label="Unidad"
                            name="unidad" //<--------------------------------------- unidad
                            rules={[{
                                required: true,
                                message: "Por favor, seleccione la unidad de medida del producto"
                            }]}
                        >
                            <Select
                                placeholder={"Unidad"}
                                filterOption={false}
                                suffixIcon={<IconArrowSelect style={{ top: "40px" }} />}
                            >
                                <Option value={"Piezas"}>Piezas</Option>
                                <Option value={"Toneladas"}>Toneladas</Option>
                            </Select>
                        </Form.Item>


                    </Col>

                    <Col span={20}>
                        <Form.Item
                            label="Activo"
                            name="estatus"
                        >

                            <Switch className="input-box" checked={this.state.activo} onChange={(e) => { this.UpdateEstatus(e) }} />
                        </Form.Item>
                        <strong>
                            {this.state.activo}
                        </strong>

                    </Col>


                    <Col span={20}>
                        <Title level={4}> Existencias </Title >
                    </Col>

                    <Col span={20}>
                        <Form.List name="existencias" >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ _id, key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline" style={{ width: "100%", display: "block" }}>
                                            <Row>
                                                <Col span={13} >
                                                    <Form.Item
                                                        label="Area"
                                                        //name="area_id"
                                                        listType="text"
                                                        {...restField}
                                                        name={[name, 'area_id']}
                                                        fieldKey={[fieldKey, 'area_id']}


                                                    >
                                                        <Select placeholder="Seleccione área"
                                                            suffixIcon={(materialId === null || editarExistencia) ? <IconArrowSelect style={{ top: "40px" }} /> : null}
                                                            onChange={this.onAreaChange}
                                                            disabled={materialId !== null && !editarExistencia}

                                                        >
                                                            {this.state.areas.map(function (area, index) {
                                                                return <Option value={area._id}>{area.nombre}</Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={8} offset={1}>
                                                    <Form.Item
                                                        label="Cantidad"
                                                        name="cantidad" //<--------------------------------------- existencias
                                                        {...restField}
                                                        name={[name, 'cantidad']}
                                                        fieldKey={[fieldKey, 'cantidad']}
                                                    >
                                                        <InputNumber placeholder="Existencias" min={0} defaultValue={0} addonAfter="pz" disabled={materialId !== null && !editarExistencia} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}>{
                                                    (materialId === null || editarExistencia) ?
                                                        (this.state.existencias.length > 0 && this.props.materialId !== "") ?

                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar esta existencia?"
                                                                onConfirm={(e) => { this.EliminarExistencia(key); remove(name, _id, key); }}
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <MinusCircleOutlined className="btn-minusModal" />
                                                            </Popconfirm>
                                                            :

                                                            <MinusCircleOutlined className="btn-minusModal" onClick={(e) => { remove(name) }} />

                                                        : null}
                                                </Col>

                                            </Row>
                                        </Space>
                                    ))}
                                    {(materialId === null || editarExistencia) ?
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Agregar existencia
                                            </Button>
                                        </Form.Item>
                                        : null}
                                </>
                            )}
                        </Form.List>
                    </Col>


                </Row>

                <Row style={{ textAlign: "center" }}>
                    <Divider />
                    <Col span={24}>

                        <Form.Item >
                            <Button htmlType="submit" type="primary" className="btn-azul" >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}



export default function (props) {
    const { materialId, visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}
        width={1200}
        destroyOnClose={true}
        maskClosable={false}
    >
        <div className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={onClose}>
                <IconCloseModal />
            </Button>
            <Title level={3} className="modal-title"> {(materialId === null) ? "Agregar" : "Editar"}  Producto </Title>
        </div>
        <ModalProductos {...props} />
    </Modal>

}