import React, { Component } from 'react';
import {
    List,
    Button,
    Col,
    Row,
    Card,
    Typography, Input,
    InputNumber,Modal,Spin
} from 'antd'
import { FaMinus } from "react-icons/fa";

import axios from 'axios';


import Avatar from '../../../Widgets/Avatar/Avatar'

import {
    SaveOutlined
} from '@ant-design/icons';


import '../../../../Styles/nominas.css'

import { IconArrowBack } from '../../../Widgets/Iconos';

const accounting = require('accounting-js')
const moment = require("moment")
const { Title, Paragraph, } = Typography;


export default class RevisionDeGrupo extends Component {



    /**
     *
     * @constructor Inicia el componente junto a sus propidades
     *
     * @var nombre Nombre del Grupo
     * @var frecuencia Frecuencia del grupo en cuestión
     * @var modalidad Si es por destajo o por salario
     * @var fecha_nomina Fecha de la nómina
     * @var responsable_id  Información del responsable
     * @var empleados Arreglo de empleados que tiene el grupo
     */
    constructor(props) {
        super(props);

        this.state = {
            group: {
                nombre: "",
                frecuencia: "",
                modalidad: "",
                precio_m2: "",
                fecha_nomina: "",
                responsable_id: {
                    nombre: "",
                    apellido: ""
                },
                empleados: [
                ],
            }
        };
    }


    /**
     * @methodOf RevisionDeGrupo
     * @description componentDidMount
     *
     * @description Obtenemos el grupo de la base de datos
     * */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getGroup(this.props.match.params.id)
    }


    /**
     * @methodOf RevisionDeGrupo
     * @description getGroup
     *
     * @description Obtenemos el grupo de la base de datos
     *
     * @param groupId
     * @description Es el id del grupo
     * */
    getGroup = (groupId) => {
        this.setState({ loading: true })
        axios.get('/grupos/get', {
            params: {
                id: groupId
            }
        })
            .then(async ({ data }) => {
                data = data.data;
                data.fecha_nomina = moment(data.fecha_nomina)
                this.setState({
                    group: data
                })
            })
    }


    /**
     * @methodOf RevisionDeGrupo
     * @description getGroup
     *
     * @description Elimina un empleado
     *
     * @param id
     * @description id del empleado a elminar
     * */
    deleteEmployee = (id) => {
        axios.put('/grupos/delete/empleados', {
            group_id: this.props.match.params.id,
            employee_id: id
        })
            .then(e => {
                this.getGroup(this.props.match.params.id)
            })
    }


    /**
     * @methodOf RevisionDeGrupo
     *
     * @function amountChanged
     * @description Actualiza la cantidad
     *
     * @param id
     * @description id del empleado a elminar
     * */
    amountChanged(e) {
        this.setState({
            value: e.target.value,
        });
    }

    /**
     * @methodOf RevisiondeGrupo
     *
     * @function formatValue
     * @description formatea el valor
     *
     * @param value
     * @description Describe el valor a formatear-
     * */
    formatValue(value) {
        // uses accounting.js
        return accounting.formatMoney(parseFloat(value) / 100);
    }



    saveGroup = (_id = this.state.group._id, empleados = this.state.group.empleados) => {        
        Modal.warning({
            title: '¿Estás seguro de que quieres guardar estos valores?',
            cancelText: "Cancelar",
            okText: "Aceptar",
            okCancel: true,
            onOk: () => {
                this.setState({ loading: true })
                axios.put("/grupos/update/empleados", {
                    _id,
                    empleados: empleados.map(e=>{
                        e.usuario_id = e.usuario_id._id
                        return e;
                    }),
                })
                    .then(async ({ data }) => {

                        this.props.history.goBack();
                    })
                    .catch(e => {
                        console.log('e', e)
                    })
            }
        });


    }


    render() {

        const { group } = this.state;


        const { deleteEmployee } = this;


        const columns = (group.modalidad === 1) ? 8 : 6

        const columnsElement = (group.modalidad === 1) ? {

            input: 10,
            action: 4

        } : {
                input: 7,
                action: 3
            }


        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={8} xxl={12}>
                        <h2> <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline' }}><IconArrowBack /></Button> Grupo</h2>
                    </Col>
                </Row>

                <Row style={{ padding: '24px' }} className="content">
                    <Row gutter={[32, 32]} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} xl={24}>
                            <Card className="content-card">
                                <Row>
                                    <Col span={18}>
                                        <Title level={3} style={{ color: "#0047FF" }}>{group.nombre}</Title>
                                    </Col>
                                    <Col span={6} className="information-content">
                                        <Avatar className="information-content-avatar"
                                            image={group.responsable_id.avatar}
                                            name={[
                                                group.responsable_id.nombre,
                                                group.responsable_id.apellido
                                            ]}
                                        />
                                        <Title className="information-content-title">{group.responsable_id.nombre}</Title>
                                    </Col>
                                </Row>
                                <Row className={"information-widgets-content " + ((group.modalidad === 2) ? "information-destajo" : "")}>

                                    <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Empleados</Paragraph>
                                        <Paragraph className="information-widgets-content-description">{group.empleados.length}</Paragraph>
                                    </Col>
                                    <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Frecuencia</Paragraph>
                                        <Paragraph className="information-widgets-content-description">{(group.frecuencia === 1) ? "Semanal" : "Quincenal"}</Paragraph>
                                    </Col>
                                    <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Modalidad</Paragraph>
                                        <Paragraph className="information-widgets-content-description">{(group.modalidad === 1) ? "Salario" : "Destajo"}</Paragraph>
                                    </Col>

                                    {group.modalidad === 2 ? <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Precio m<sub>2</sub></Paragraph>
                                        <Paragraph className="information-widgets-content-description">${group.precio_m2.toString().toMoney(true)}</Paragraph>
                                    </Col> : null}
                                </Row>


                                <Row className={"information-widgets-content " + ((group.modalidad === 2) ? "information-destajo" : "")} style={{ marginTop: '20px' }}>
                                    <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Responsable</Paragraph>
                                        <Avatar className="information-content-avatar"
                                            image={group.responsable_id.avatar}
                                            name={[
                                                group.responsable_id.nombre,
                                                group.responsable_id.apellido
                                            ]}
                                        />
                                        <label className="information-widgets-content-description" style={{ marginLeft: '10px' }}>{group.responsable_id.nombre + ' ' + group.responsable_id.apellido}</label>
                                    </Col>
                                    {(group.inmueble_id !== undefined) ?
                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Inmueble</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{group.inmueble_id.nombre}</Paragraph>
                                        </Col> : ''}

                                    {(group.area_id !== undefined) ?

                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Area</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{group.area_id.nombre}</Paragraph>
                                        </Col> : ''}
                                </Row>

                            </Card>
                        </Col>
                    </Row>
                    <Row className="list-container" gutter={[32, 32]} style={{ width: '100%' }}>
                        <Col span={24}>
                            <Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>Empleados</Title>
                        </Col>
                        <Col span={24} style={{ paddingTop: 0 }}>
                            <List
                                itemLayout="horizontal"
                                dataSource={group.empleados}
                                className="list-employees"
                                renderItem={(item, index) => (
                                    <List.Item className="list-employees-item-content">
                                        <Row className="list-employee-item" style={{ width: '100%' }}>
                                            <Col span={columnsElement.input}>
                                                {(item.usuario_id) ? (item.usuario_id.nombre + ' ' + item.usuario_id.apellido) : <strong>Empleado Inexistente</strong>}
                                            </Col>
                                            {group.modalidad === 2 ? <Col span={columnsElement.input}></Col> : null}
                                            <Col span={columnsElement.input}>
                                                {group.modalidad === 1 ? <Col span={columnsElement.input}>
                                                    <Input.Group compact>
                                                        <InputNumber
                                                            // onBlur={}
                                                            style={{ width: '80%' }}
                                                            precision={2}
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            value={item.monto}
                                                            onChange={value => this.setState(state => {
                                                                state.group.empleados[index].monto = value
                                                                return state
                                                            })}
                                                        />
                                                    </Input.Group>

                                                </Col> : null}
                                            </Col>
                                            <Col span={columnsElement.action}>
                                                <Button onClick={() => deleteEmployee(item.usuario_id._id)} className="button-list-employee-delete" style={{ background: "#FF0000", }} icon={<FaMinus color="white" style={{ position: "relative", top: "4px" }} />} />
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Row>
                <Button type="ghost" className="btn-save" onClick={() => this.saveGroup()} disabled={this.state.uploadingImages}>
                    {this.state.uploadingImages ? <Spin style={{ position: 'relative', top: '3px' }} spinning={true} /> : <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41 3.82586C40.9961 5.11749 40.5257 6.0772 39.6873 6.8994C34.9386 11.551 30.1977 16.211 25.4539 20.8683C22.7917 23.4819 20.1315 26.0964 17.4674 28.7072C15.7045 30.436 13.2626 30.4284 11.5017 28.6996C8.07832 25.3359 4.64628 21.9807 1.23163 18.6094C0.0773175 17.4695 -0.282018 16.0897 0.223177 14.5581C0.722575 13.0446 1.82763 12.1389 3.42242 11.8829C4.80084 11.661 5.98606 12.1019 6.9723 13.0721C9.34759 15.4078 11.7316 17.736 14.0982 20.0802C14.3889 20.3685 14.5425 20.4197 14.87 20.0964C21.2511 13.8089 27.6476 7.53478 34.0422 1.25968C35.1994 0.123585 36.5846 -0.277558 38.1533 0.193762C39.6795 0.651805 40.6001 1.7054 40.9324 3.24074C40.9826 3.47119 40.9874 3.71017 41 3.82586Z" fill="white" />
                    </svg>}
                </Button>
            </div>
        )
    }
}
