import React, { Component, useState } from 'react'
import { Layout } from 'antd';
import Header from "../Components/Header/Header";
import SideBar from "../Components/Sidebar/SideBar";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../Components/Admin/Dashboard";
import { Modal } from "antd";


import Finanzas from "../Components/Admin/Finanzas/Finanzas";
import HistorialEmpleados from "../Components/Admin/Transacciones/HistorialEmpleados";

//widget
import BelvoWidget from '../Components/Admin/Cuentas/belvo_test'
import TransaccionesBelvo from '../Components/Admin/Cuentas/transacciones_belvo'
import FacturasBelvo from '../Components/Admin/Cuentas/facturas_belvo'
import Cuentas from "../Components/Admin/Cuentas/cuentas";
import Usuarios from "../Components/Admin/Usuarios/Usuarios";
import Rubros from "../Components/Admin/Rubros/Rubros";
import Subrubros from "../Components/Admin/Subrubros/Subrubros";
import Proyecciones from "../Components/Admin/Proyecciones/Proyecciones";
import ProyeccionesVentas from "../Components/Admin/Proyecciones/Ventas/ProyeccionesVentas";
import ProyeccionesVentasDetalle from "../Components/Admin/Proyecciones/Ventas/DetalleProyeccionVenta";
import ProyeccionesVentasCreacion from "../Components/Admin/Proyecciones/Ventas/CreacionProyeccionVenta";
import Calendario from "../Components/Admin/Calendario/Calendario";

import Notificaciones from "../Components/Admin/Notificaciones/Notificaciones";

import CRM from "../Components/Admin/CRM/CRM";
import Transacciones from "../Components/Admin/Transacciones/Transacciones";
import Areas from "../Components/Admin/Areas/Areas";

import Clientes from "../Components/Admin/Clientes/Clientes";
import ClientesDetalle from "../Components/Admin/Clientes/DetallesCliente";

import Recibos from "../Components/Admin/Recibos/Recibos";
import TEST from "../Components/Admin/Cuentas/testqr";


//nuevo inventario
import DetalleProducto from "../Components/Admin/Inventarios2/Productos/DetalleProducto";


import Articulos from "../Components/Admin/Inventarios2/Articulos/Articulos";
import DashboardInventario from "../Components/Admin/Inventarios2/Dashboard";

import Locaciones from "../Components/Admin/Inventarios2/Locaciones/Locaciones";
import MovimientosAreas from "../Components/Admin/Inventarios2/Locaciones/movimientosAreas";
import FacturasSyncfy from '../Components/Admin/Contabilidad/FacturasSyncfy';

import {
    NominaDashboard,
    AvanceNomina,
    AdministrarNomina,
    RevisionDeGrupo,
    RevisionEmpleado,
    Grupos,
    EdiciónDeGrupo,
    AdministrarNominaFinanzas,
    RevisionDeGrupoFinanzas,
    TajosNomina,
    RevisionInmuebles,
    InmueblesTrabajo,
    ListaInmuebles,
    ListaEmpleados,
    RevisionTajos,
    Revision,
    RevisionSalariales

} from '../Components/Admin/Nomina/index';

import Empleados from "../Components/Admin/Nomina/Empleados/Empleados";
import EmpleadosDetalle from "../Components/Admin/Nomina/Empleados/EmpleadosDetalle";
import PagosPendientes from "../Components/Admin/Nomina/PagosPendientes/PagosPendientes";
import PagosPendientesDetalle from "../Components/Admin/Nomina/PagosPendientes/Detalle";

//proyect Manager componentes
import ProjectManagerDashboard from "../Components/Admin/ProjectManager/ProjectManagerDashboard";
import PMProject from "../Components/Admin/ProjectManager/PMProject";
import PMInmueble from "../Components/Admin/ProjectManager/PMInmueble";
import PMActividad from "../Components/Admin/ProjectManager/PMActividad";
import PMTajo from "../Components/Admin/ProjectManager/PMTajo";
import PMmateriales from "../Components/Admin/ProjectManager/PMmateriales";
import PMmaterialesEmpleado from "../Components/Admin/ProjectManager/PMmaterialesEmpleado";
import PMmaterialesEmpleadoAct from "../Components/Admin/ProjectManager/PMmaterialesEmpleadoAct";
import PMmaterialActividad from "../Components/Admin/ProjectManager/PMmaterialActividad";


import EstatusAvance from "../Components/Admin/ProjectManager/EstatusAvance/EstatusAvance";
import BitacoraEstatusAvance from "../Components/Admin/ProjectManager/EstatusAvance/BitacoraEstatusAvance";
import DetalleBitacoraEstatusAvance from "../Components/Admin/ProjectManager/EstatusAvance/DetalleBitacoraEstatusAvance";

//Manager
import Manager from "../Components/Admin/Manager/Manager";
import ProjectAdmin from "../Components/Admin/Manager/ProjectAdmin";
import PonderacionInmuebles from "../Components/Admin/Manager/PonderacionInmuebles";
import PonderacionActividades from "../Components/Admin/Manager/PonderacionActividades";
import PonderacionTajos from "../Components/Admin/Manager/PonderacionTajos";
import InmuebleAdmin from "../Components/Admin/Manager/InmuebleAdmin";
import ActividadAdmin from "../Components/Admin/Manager/ActividadAdmin";
import MaterialAdmin from "../Components/Admin/Manager/MaterialAdmin";
import Actividades from "../Components/Admin/Actividades/actividades"

//Ventas
import Plano from "../Components/Admin/Ventas/Plano"
import VentasDashboard from "../Components/Admin/Ventas/VentasDashboard"
import Prospectos from "../Components/Admin/Ventas/Prospectos"
import MensajesWhatsapp from "../Components/Admin/Ventas/MensajesWhatsapp"
import VentasEmail from "../Components/Admin/Ventas/VentasEmail"
import ProspectosEstatus from "../Components/Admin/Ventas/ProspectosEstatus"

//Requsiciones
import Compras from "../Components/Admin/Compras/ComprasDashboard"
import Requisiciones from "../Components/Admin/Compras/RequisicionMateriales"
import Cotizaciones from "../Components/Admin/Compras/CotizacionMaterialesComprador"
import CalendarioMateriales from "../Components/Admin/Compras/Calendario"
import EntregaMateriales from "../Components/Admin/Compras/EntregaMateriales"
import InventarioAlmacenes from "../Components/Admin/Compras/Inventario/Inventario"
import MovimientosAlmacenes from "../Components/Admin/Compras/Movimientos/Movimientos"


//Compras
import Proveedores from "../Components/Admin/Proveedores/Proveedores"
import ProveedoresDash from "../Components/Admin/Proveedores/ProveedoresDashboard"
import ProveedoresDetalle from "../Components/Admin/Proveedores/ProveedorDetalle"
import Facturas from "../Components/Admin/Proveedores/Facturas"

//Acreedores
import Acreedores from "../Components/Admin/Acreedor/Acreedores"
import AcreedoresDetalle from "../Components/Admin/Acreedor/AcreedorDetalle"

//Presupuesto
import PresupuestoDash from "../Components/Admin/Presupuesto/PresupuestoDashboard"
import PresupuestoCasas from "../Components/Admin/Presupuesto/PresupuestoCasas"
import PresupuestoProforma from "../Components/Admin/Presupuesto/PresupuestoProforma"
import PresupuestoCasasAct from "../Components/Admin/Presupuesto/PresupuestoCasasAct"
import ActividadPorCasa from "../Components/Admin/Presupuesto/ActividadPorCasa"
import DetalleActividad from "../Components/Admin/Presupuesto/DetalleActividad"
import PresupuestoProformaConcepto from "../Components/Admin/Presupuesto/PresupuestoProformaConcepto"
import DetalleManoObra from "../Components/Admin/Presupuesto/DetalleManoObra"
import DetalleMaterial from "../Components/Admin/Presupuesto/DetalleMaterial"
import DetalleMaterialConcepto from "../Components/Admin/Presupuesto/DetalleMaterialConcepto"


// C:\var\www\manantia-erp-web-v5\src\Components\Admin\Inventarios2\Productos\DetalleProducto.js

import Logged from '../Hooks/Logged';



//Ordenes Materiales

//Material
import Material from "../Components/Admin/Material/Material";

import OrdenesDashboard from "../Components/Admin/Ordenes/OrdenesDashboard"
import ListaOrdenesMateriales from "../Components/Admin/Ordenes/ListaOrdenesMaterial"
import OrdenMaterial from "../Components/Admin/Ordenes/OrdenMaterial"


//PROYECTOS
import Proyectos from "../Components/Admin/Proyectos/Proyectos";
import Almacenes from "../Components/Admin/Proyectos/Almacenes/Almacenes";
import Inventarios from "../Components/Admin/Proyectos/Inventarios/Inventarios";
import ProductosInventario from "../Components/Admin/Proyectos/Productos/Productos";
import ProductosAlmacen from "../Components/Admin/Proyectos/ProductosAlmacen/ProductosAlmacen";
import ProyectoDetalles from "../Components/Admin/Proyectos/General/ProyectoDetalles";

//Inventarios
import Inventario from '../Components/Admin/Inventario/Inventario'
import Tipos from '../Components/Admin/Tipos/Tipos'
import MaterialesInventario from '../Components/Admin/Inventario/MaterialesInventario'

//Belvo
import FinanzasBelvo from '../Components/Admin/Belvo/Finanzas'
import TransaccionesSC from '../Components/Admin/Belvo/TransaccionesSC'
import FacturasSC from '../Components/Admin/Belvo/FacturasSC'
import BelvoBitacora from '../Components/Admin/Belvo/Bitacora'

//Syncfy
import SyncfyRoutes from './SyncfyRoutes'

//Empresas
import Empresas from '../Components/Admin/Empresas/Empresas'

// import X from '../Components/Admin/X/X'
import EmpleadosAvance from '../Components/Admin/Nomina/Empleados/AvanceEmpleados';
import FinancialHearlth from '../Components/Admin/FinancialHealth/FinancialHealthDashboard';
import Inversiones from '../Components/Admin/FinancialHealth/Inversiones';
import InversionDetalle from '../Components/Admin/FinancialHealth/InversionDetalle';
import InventarioProducto from '../Components/Admin/Inventarios2/Productos/InventarioProducto';
import TokenSyncfy from '../Hooks/TokenSyncfy';
import Productos from '../Components/Admin/Productos/Productos';
import HistorialPrecio from '../Components/Admin/Productos/HistorialPrecio';
import HistorialPreciosLista from '../Components/Admin/Productos/HistorialPreciosLista';

import BudgetingRoutes from './Admin/BudgetingRoutes'
import ConceptosRoutes from './Admin/ConceptosRoutes'
import ContabilidadRoutes from './Admin/ContabilidadRoutes'

import FacturasConta from '../Components/Admin/Contabilidad/FacturasCRUD'
import FacturasList from '../Components/Admin/Contabilidad/Facturas/FacturasList';
import 'iseeyoutech-widget-web/dist/style.css';
import ISYWidget from 'iseeyoutech-widget-web';

const { Content } = Layout;
//Importacion de Axios para las URL.
const axios = require("axios").default;
// const ISYWidget = require("iseeyoutech-widget-web");

axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

/**
 *
 * @export
 * @class AdminRoutes
 * @extends {Component}
 */
export default class AdminRoutes extends Component {

    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            back: false,
            responsiveSidebar: false,

            showSearch: false,
            filterSearch: "",
            searching: false,
            syncfy_token: null
        };
    }


    SetFilterSearch(e) {
        this.setState({ filterSearch: e })
    }

    updateFilterSearch = (ShowFilter) => {
        if (ShowFilter === undefined) { ShowFilter = false }
        this.setState({
            showSearch: ShowFilter,
        })
    };


    render() {
        const { title, back } = this.state;
        const { updateFilterSearch } = this;

        return (
            <Layout className="layout">

                <Logged.Consumer>
                    {value => { this.context = value }}
                </Logged.Consumer>
                <Header
                    user={this.context}
                    back={back}
                    onBack={this.onBack}
                    setUser={this.props.setUser}
                    showResponsiveSidebar={() => this.setState({ responsiveSidebar: !this.state.responsiveSidebar })}
                    showSearch={this.state.showSearch}
                    setFilterSearch={(e) => this.SetFilterSearch(e)}
                    filterSearchVal={this.state.filterSearch}
                />
                <Layout>
                    <SideBar
                        user={this.context}
                        responsiveSidebar={this.state.responsiveSidebar}
                        hideSidebar={() => this.setState({ responsiveSidebar: false })}
                    />
                    <Content >
                        <Switch>

                            <Route exact path="/admin/dashboard"
                                render={(props) => <Dashboard  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/finanzas"
                                render={(props) => <Finanzas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/productos"
                                render={(props) => <Productos  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/productos/historial/:producto_id"
                                render={(props) => <HistorialPrecio  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/productos/historial"
                                render={(props) => <HistorialPreciosLista  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/historial-empleados"
                                render={(props) => <HistorialEmpleados  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/cuentas"
                                render={(props) => <Cuentas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route exact path="/admin/cuentas/belvo/qr/:value" render={(props) => <TEST  {...props} />} />
                            <Route exact path="/admin/cuentas/belvo/:token/update" render={(props) => <BelvoWidget redirect="/admin/belvo/finanzas" {...props} />} />
                            <Route exact path="/admin/cuentas/belvo/:id/register" render={(props) => <BelvoWidget redirect="/admin/belvo/finanzas" {...props} />} />
                            <Route exact path="/admin/cuentas/belvo/:id" render={(props) => <BelvoWidget {...props} redirect="/admin/cuentas" />} />




                            <Route exact path="/admin/cuentas/belvo/transacciones/clasificadas/:link/:account_id" render={(props) => <TransaccionesSC clasificadas={true} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/cuentas/belvo/transacciones/sin-clasificar/:link/:account_id"
                                render={(props) => <TransaccionesSC clasificadas={false} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/cuentas/belvo/transacciones/guardadas/:link/:account_id"
                                render={(props) => <TransaccionesSC clasificadas={false} guardadas={true} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/cuentas/belvo/facturas/clasificadas/:id/:account_id"
                                render={(props) => <FacturasSC clasificadas={true} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/cuentas/belvo/facturas/sin-clasificar/:id/:account_id"
                                render={(props) => <FacturasSC clasificadas={false} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/cuentas/belvo/facturas/guardadas/:id/:account_id"
                                render={(props) => <FacturasSC clasificadas={false} guardadas={true} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />


                            <Route exact path="/admin/belvo/bitacora"
                                render={(props) => <BelvoBitacora clasificadas={false} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />






                            {/*  MODULO DE PROYECTOS */}
                            <Route exact path="/admin/proyectos"
                                render={(props) => <Proyectos  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/proyectos/inventarios"
                                render={(props) => <Inventarios  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/proyectos/:proyecto_id/almacenes/:inventario_id"
                                render={(props) => <Almacenes  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/proyectos/:proyecto_id/productos/:inventario_id"
                                render={(props) => <ProductosInventario  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/proyectos/:proyecto_id/productos/:inventario_id/almacen/:almacen_id"
                                render={(props) => <ProductosAlmacen  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/proyectos/:proyecto_id/detalles"
                                render={(props) => <ProyectoDetalles  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path='/admin/conceptos' render={(props) => <ConceptosRoutes {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />


                            <Route exact path="/admin/usuarios"
                                render={(props) => <Usuarios  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/calendario"
                                render={(props) => <Calendario  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/notificaciones"
                                render={(props) => <Notificaciones  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/transacciones"
                                render={(props) => <Transacciones clasificadas={false}  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/areas"
                                render={(props) => <Areas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />


                            <Route exact path="/admin/areas/rubros/:id"
                                render={(props) => <Rubros  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route exact path="/admin/areas/rubros/subrubros/:id"
                                render={(props) => <Subrubros  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />


                            <Route exact path="/admin/proyecciones"
                                render={(props) => <Proyecciones  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route exact path="/admin/proyecciones/ventas"
                                render={(props) => <ProyeccionesVentas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />


                            <Route exact path="/admin/proyecciones/ventas/detalle/:id"
                                render={(props) => <ProyeccionesVentasDetalle  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route exact path="/admin/proyecciones/ventas/creacion"
                                render={(props) => <ProyeccionesVentasCreacion  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />


                            <Route exact path="/admin/crm"
                                render={(props) => <CRM  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />


                            <Route exact path="/admin/clientes"
                                render={(props) => <Clientes  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/clientes/detalle/:id"
                                render={(props) => <ClientesDetalle  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route exact path="/admin/clientes/detalle/:id/:propiedad_id"
                                render={(props) => <ClientesDetalle  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />


                            {/* NOMINA NUEVA*/}
                            <Route exact path="/admin/nomina" render={(props) => <NominaDashboard  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            {/* CARGA */}
                            <Route exact path="/admin/nomina/inicio/nomina/:nomina_id" render={(props) => <ListaInmuebles {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            {/* PRELIMINAR */}
                            <Route exact path="/admin/nomina/revision-preliminar/:nomina_id/empleado/:empleado_id" render={(props) => <AvanceNomina tipo={'empleado'} etapa={1} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/revision-preliminar/:nomina_id/inmueble/:inmueble_id" render={(props) => <AvanceNomina tipo={'inmueble'} etapa={1} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            {/* Lista de Empleados por Salariales */}
                            <Route exact path="/admin/nomina/revision-final/:nomina_id/salariales" render={(props) => <RevisionSalariales finanzas={false} tipo="salarial" etapa={1} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            {/* FINAL */}
                            <Route exact path="/admin/nomina/revision-final/:nomina_id" render={(props) => <EmpleadosAvance etapa={2} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/revision-final/:nomina_id/empleado/:empleado_id" render={(props) => <AvanceNomina etapa={2} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />


                            {/* FINANZAS */}
                            <Route exact path="/admin/nomina/revision-finanzas/:nomina_id" render={(props) => <EmpleadosAvance etapa={3} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            {/* Lista de Empleados por Salariales */}
                            <Route exact path="/admin/nomina/revision-finanzas/:nomina_id/salariales" render={(props) => <RevisionSalariales finanzas={true} tipo="salarial" etapa={3} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />


                            {/*NOMINA VIEJA  */}
                            <Route exact path="/admin/nomina/grupo/:id" render={(props) => <EdiciónDeGrupo  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/administrar-nomina/:id/:view?" render={(props) => <AdministrarNomina  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/administrar-nomina/:nomina_id/grupo/:id" render={(props) => <RevisionDeGrupo  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/administrar-nomina/:nomina_id/inmueble/:inmueble_id" render={(props) => <RevisionInmuebles  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />


                            <Route exact path="/admin/nomina/administrar-nomina-finanzas/:nomina_id/inmueble/:inmueble_id" render={(props) => <RevisionInmuebles  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} finanzas={true} />} />


                            <Route exact path="/admin/nomina/administrar-nomina/:nomina_id/empleado/:id" render={(props) => <RevisionEmpleado  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/administrar-nomina-finanzas/:id/:view?" render={(props) => <AdministrarNominaFinanzas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/administrar-nomina-finanzas/:nomina_id/grupo/:id" render={(props) => <RevisionDeGrupoFinanzas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/administrar-nomina-finanzas/:nomina_id/empleado/:id" render={(props) => <RevisionEmpleado {...props} finanzas={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/nomina/grupos" render={(props) => <Grupos {...props} finanzas={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/empleados" render={(props) => <Empleados {...props} finanzas={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/empleados/:id" render={(props) => <EmpleadosDetalle {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/tajos" render={(props) => <TajosNomina key="tajos" {...props} finanzas={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} tipo="area_id" />} />
                            <Route exact path="/admin/nomina/inmuebles" render={(props) => <TajosNomina key="inmuebles" {...props} finanzas={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} tipo="inmueble_id" />} />
                            <Route exact path="/admin/nomina/inmuebles-trabajo/" render={(props) => <InmueblesTrabajo {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />



                            <Route exact path="/admin/nomina/inicio/nomina/:nomina_id/inmueble/:inmueble_id" render={(props) => <ListaEmpleados {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/nomina/inicio/nomina/:nomina_id/salariales" render={(props) => <ListaEmpleados {...props} salariales={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />


                            <Route exact path="/admin/nomina/pagosPendientes" render={(props) => <PagosPendientes {...props} finanzas={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/pagosPendientes/:id" render={(props) => <PagosPendientes {...props} finanzas={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/nomina/pagosPendientes/detalle/:usuario_id/:nomina_id" render={(props) => <PagosPendientesDetalle {...props} finanzas={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />


                            <Route exact path="/admin/nomina/previa/:nomina_id" render={(props) => <NominaDashboard key={"nomina-" + props.match.params.nomina_id} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            {/*Para revisar una nomina antigua se le envia el id*/}

                            {/* Proyect Manager */}
                            <Route exact path="/admin/project-manager" render={(props) => <ProjectManagerDashboard {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/project-manager/proyecto/:id" render={(props) => <PMProject {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/project-manager/proyecto/inmueble/:id" render={(props) => <PMInmueble {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/project-manager/proyecto/inmueble/actividad/:id" render={(props) => <PMActividad {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/project-manager/proyecto/inmueble/actividad/tajo/:id" render={(props) => <PMTajo {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            {/* id = manager_proyecto_id */}
                            <Route exact path="/admin/project-manager/material/:id" render={(props) => <PMmateriales {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            {/* id = manager_proyecto_id + _ + empleado_id */}
                            <Route exact path="/admin/project-manager/material-empleado/:id" render={(props) => <PMmaterialesEmpleado {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/project-manager/material-actividad/:manager_actividad_id/:material" render={(props) => <PMmaterialActividad {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            {/* id = manager_actividad_id */}
                            <Route exact path="/admin/project-manager/material-actividad/:id" render={(props) => <PMmaterialActividad {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/project-manager/material-actividad/:id" render={(props) => <PMmaterialActividad {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            {/* // id = manager_actividad_id  */}
                            <Route exact path="/admin/project-manager/material-empleado-actividad/:id" render={(props) => <PMmaterialesEmpleadoAct {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />



                            <Route exact path="/admin/project-manager/estatusAvance" render={(props) => <EstatusAvance {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/project-manager/bitacoraEstatusAvance" render={(props) => <BitacoraEstatusAvance {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/project-manager/bitacoraEstatusAvance/detalle/:id" render={(props) => <DetalleBitacoraEstatusAvance {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/manager" render={(props) => <Manager {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/manager/ponderacion/proyecto/:proyecto_id" render={(props) => <PonderacionInmuebles {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/manager/ponderacion/proyecto/inmueble/:manager_inmueble_id" render={(props) => <PonderacionActividades {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/manager/ponderacion/proyecto/inmueble/actividad/:manager_actividad_id" render={(props) => <PonderacionTajos {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/manager/proyecto/:id" render={(props) => <ProjectAdmin {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/manager/proyecto/inmueble/:manager_inmueble_id" render={(props) => <InmuebleAdmin {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/manager/proyecto/inmueble/actividad/:manager_actividad_id" render={(props) => <ActividadAdmin {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/manager/proyecto/inmueble/actividad/material/:manager_actividad_id" render={(props) => <MaterialAdmin {...props} finanzas={true} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />





                            <Route exact path="/admin/tajos/actividades"
                                render={(props) => <Actividades {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/recibos"
                                render={(props) => <Recibos {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />


                            {/* Vista ventas plano*/}
                            <Route exact path="/admin/ventas/plano"
                                render={(props) => <Plano {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/ventas/dashboard"
                                render={(props) => <VentasDashboard user={this.context} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/ventas/prospectos"
                                render={(props) => <Prospectos {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/ventas/prospectos/mensaje/:id"
                                render={(props) => <MensajesWhatsapp {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/ventas/prospectos/emails"
                                render={(props) => <VentasEmail {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/ventas/prospectos/estatus"
                                render={(props) => <ProspectosEstatus {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            {/* Vistas deudas por pagar*/}

                            <Route exact path="/admin/acreedores"
                                render={(props) => <Acreedores  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/acreedores/detalle/:id"
                                render={(props) => <AcreedoresDetalle {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/presupuesto"
                                render={(props) => <PresupuestoDash {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/presupuesto/casas"
                                render={(props) => <PresupuestoCasas {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/presupuesto/proforma"
                                render={(props) => <PresupuestoProforma {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/presupuesto/proforma/:proforma_id/concepto"
                                render={(props) => <PresupuestoProformaConcepto {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/presupuesto/proforma/:proforma_id/concepto/:proforma_concepto_id/mano-obra"
                                render={(props) => <DetalleManoObra {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/presupuesto/proforma/:proforma_id/concepto/:proforma_concepto_id/material"
                                render={(props) => <DetalleMaterialConcepto {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/presupuesto/casas/actividades"
                                render={(props) => <PresupuestoCasasAct {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            {/*inmueble_id: id del inmueble de la coleccion inmueble trabajado*/}
                            <Route exact path="/admin/presupuesto/inmueble/:inmueble_trabajo_id/actividades"
                                render={(props) => <ActividadPorCasa {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/presupuesto/inmueble/:inmueble_trabajo_id/actividad/:tajo_id/detalle"
                                render={(props) => <DetalleActividad {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/presupuesto/inmueble/:inmueble_id/material/:tajo_id/detalle"
                                render={(props) => <DetalleMaterial {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />


                            {/*Vistas Compras - Requisiciones*/}
                            <Route exact path="/admin/compras/dashboard"
                                render={(props) => <Compras {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/compras/requisiciones"
                                render={(props) => <Requisiciones {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/compras/cotizaciones"
                                render={(props) => <Cotizaciones {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/compras/calendario"
                                render={(props) => <CalendarioMateriales {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/compras/entregas"
                                render={(props) => <EntregaMateriales {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/compras/inventario"
                                render={(props) => <InventarioAlmacenes {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/compras/movimientos"
                                render={(props) => <MovimientosAlmacenes {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />



                            {/* Vistas proveedores*/}
                            <Route exact path="/admin/compras/proveedores"
                                render={(props) => <Proveedores {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/compras/proveedores/dashboard"
                                render={(props) => <ProveedoresDash {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/compras/proveedores/detalle/:id"
                                render={(props) => <ProveedoresDetalle {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/compras/proveedores/facturas"
                                render={(props) => <Facturas {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />




                            {/* OrdenesDashboard */}
                            <Route exact path="/admin/ordenes" render={(props) => <OrdenesDashboard key="OrdenesMateriales"  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/ordenes/lista" render={(props) => <ListaOrdenesMateriales key="ListaOrdenesMateriales"  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/ordenes/nuevo" render={(props) => <OrdenMaterial key="creación" {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/ordenes/:id" render={(props) => <OrdenMaterial key="edición" {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />


                            <Route exact path="/admin/materiales/inventario" render={(props) => <MaterialesInventario inventario={true} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/materiales/inventario/:id" render={(props) => <Inventario key="inventario" {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />





                            {/*Materiales*/}
                            <Route exact path="/admin/material" render={(props) => <Material  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/material/:id" render={(props) => <Material  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/tipos" render={(props) => <Tipos  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />


                            {/*Belvo*/}
                            <Route exact path="/admin/belvo/finanzas" render={(props) => <FinanzasBelvo  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            {/*Syncfy*/}
                            <Route exact path="/admin/syncfy/*" render={(props) => <SyncfyRoutes  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            {/*Financial Hearlth*/}
                            <Route exact path="/admin/financial-health" render={(props) => <FinancialHearlth  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/financial-health/inversiones" render={(props) => <Inversiones  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/financial-health/inversion/detalle/:id" render={(props) => <InversionDetalle  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            {/* Inventarios v2*/}
                            <Route exact path="/admin/articulos" render={(props) => <Articulos  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/inventario/dashboard" render={(props) => <DashboardInventario  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />


                            {/* InventarioProducto */}
                            <Route exact path="/admin/inventario/productos" render={(props) => <Articulos  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/inventario/productos/detalle/:articulo_id" render={(props) => <DetalleProducto inventario={true} {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/inventario/productos/inventario/:producto_id" render={(props) => <InventarioProducto  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route exact path="/admin/inventario/locaciones" render={(props) => <Locaciones  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/inventario/areas" render={(props) => <Locaciones  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />
                            <Route exact path="/admin/inventario/areas/movimientos/:area_id" render={(props) => <MovimientosAreas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />


                            <Route path='/admin/budgeting' render={(props) => <BudgetingRoutes {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            {/* Empresas */}
                            <Route path='/admin/empresas' render={(props) => <Empresas {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />


                            <Route path='/admin/contabilidad' render={(props) => <ContabilidadRoutes {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            <Route path='/admin/facturas' render={(props) => <FacturasConta {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />
                        </Switch>
                        {console.log("this.context?.nombre",this.context)}
                        <ISYWidget
                            isReady={typeof this?.context?.nombre == "string"}
                            nombre={this.context?.nombre + " " + this.context?.apellido}
                            email={this.context?.email}
                            proyectoId="632e04bfb3928934bc432c87"
                            URL="https://ws.admin.iseeyoutech.com"
                        />
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

