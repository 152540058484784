import React, { Component } from 'react';
import { Button, Col, Row, Select, Typography, Tag, Badge, Calendar, message } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
//componentes
import { IconPlus } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import GraficaCompras from './GraficaCompras';
//modal
import ModalRequisicionDetalle from './Modales/ModalRequisicionDetalle'
import ModalCalendario from './Modales/ModalCalendario'

//css
import './../../../Styles/dashboard.css'

const { Title, Text } = Typography;
const moment = require('moment')



/**
 * @class CalenarioMaterial
 * @description Calendario que indca las entregas y recolecciones
 */
export default class CalenarioMaterial extends Component {

    constructor(props){
        super(props)
        this.state = {
            mes: moment().month() + 1,
            año: moment().year(),
            eventos: [],
        }
    }

    /***
     *
     * @methodOf CalenarioMaterial
     * @function componentDidMount
     * @description Obtenemos la informacion 
     */
    componentDidMount = () => {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false); 

        this.getData()

    }

    /***
     *
     * @methodOf CalenarioMaterial
     * @function getData
     * @description Obtenemos la informacion 
     */
    getData = () => {
        axios.get('/requisiciones/entrega/calendario',{
            params: {
                mes: this.state.mes,
                año: this.state.año
            }
        }).then(response => {

            this.setState({eventos: this.getDatesFormat(response.data.data)})

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        })
    }

     /**
     * @memberof Calendario
     * @method   getDatesFormat
     * @description Establecemos el formato de fecha para que el metodo de dateCellRender lo pueda leer,
     *  retorna un objeto con campos fechas, y dentro de estos un array con los eventos que hay es esa fecha
     * @param fechas [{*}] : Array de Objetos con la informacion de cada Evento del mes
     *
     * @return fechasCalendar { 
     *      fecha_1: [{evento_1},...],
     *      fecha_2: [{evento_n}]  
     *  }
     */
    getDatesFormat = (fechas) => {
        let fechasCalendar = {};
        if(fechas){
            for (const fecha of fechas){
                let fechaIndex =  moment(fecha.fecha_entrega).format('YYYY-MM-DD');
                if(!fechasCalendar[fechaIndex]){
                    fechasCalendar[fechaIndex] = []
                }
                fechasCalendar[fechaIndex].push(fecha)
            }
        }
        return fechasCalendar;

    }

    /**
     * @memberof Calendario
     * @method getDatesFormat
     * @description Renderiza los badges dentro del las celdas del calendario
     * 
     */
    dateCellRender = (value) => {
        let cellDayValue = value.format('YYYY-MM-DD');
        if(this.state.eventos[cellDayValue]){
            return (
                <ul className="events">
                    {this.state.eventos[cellDayValue].length > 0 ? 
                        <Tag 
                            className={`tag-sm tag-estatus-${ this.state.eventos[cellDayValue][0].estatus}`}
                            onClick={()=>{
                                this.setState({
                                    modal_calendario: true,
                                    fecha_seleccionada: cellDayValue
                                })
                            }}
                        >{this.state.eventos[cellDayValue].length} Requisiciones</Tag>
                    : ''}
                </ul>
            )
        }
    }

    render() {

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} className="flex-left">
                        <Title level={4} className="view-title">Entregas / Recolecciones de Material</Title>
                    </Col>
                </Row>

                <section className="p-1">
                    
                    <div className="card">
                        <Calendar 
                            dateCellRender={this.dateCellRender}
                            onPanelChange={(value)=>{
                                this.setState({ 
                                    mes: moment(value).month() +1 ,
                                    año: moment(value).year(),
                                },()=>{
                                    this.getData()
                                })
                            }}
                        />
                    </div>
                </section>
                <ModalRequisicionDetalle
                    visible={this.state.modal_visible}
                    onCancel={()=>this.setState({
                        modal_visible: false,
                        requisicion_id: undefined
                    })}
                    requisicion_id={this.state.requisicion_id}
                />

                <ModalCalendario
                    visible={this.state.modal_calendario}
                    onCancel={()=>this.setState({
                        modal_calendario: false,
                        fecha_seleccionada: undefined
                    })}
                    fecha_seleccionada={this.state.fecha_seleccionada}
                    openDetalle={(requisicion_id)=>{
                        this.setState({
                            modal_visible: true,
                            requisicion_id: requisicion_id
                        })
                    }}
                />
            </div>
        )
    }
}
