



import React, { Component } from 'react';
import {
    Tag,
    Space,
    List,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Dropdown,
    Form,
    Menu,
    Radio,
    Row,
    Select,
    Statistic,
    Tree,
    Card,
    Typography,
    InputNumber, Modal, Switch, message
} from 'antd'
import { FaArrowRight, FaCheck, FaMinus } from "react-icons/fa";

import { Link, Redirect } from 'react-router-dom';
import {
    IconPlus,
    IconArrowCustom as Arrow,
    IconDownloadCustom as Download,
    IconGoToLink,
    IconoMaquinaRegistradoraParaElGuardadoNominazFinanzas as IconoGuardado
} from '../../../Widgets/Iconos';
import axios from 'axios';

import Avatar from '../../../Widgets/Avatar/Avatar'
import '../../../../Styles/nominas.css'
import { IconCheck, IconCheckCircle, IconArrowBack } from '../../../Widgets/Iconos';
import GraficaNominasPorGrupos from '../Widgets/GraficaNominasPorGrupos';
import Logged from "../../../../Hooks/Logged";
import { useReactToPrint } from 'react-to-print';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";

const accounting = require('accounting-js')
const moment = require("moment")
const { Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class RevisionDeGrupo
 * @extends {Component}
 */
export default class RevisionDeGrupo extends Component {
    static contextType = Logged;
    state = {
        group: {
            nombre: "",
            frecuencia: "",
            modalidad: "",
            precio_m2: "",
            fecha_nomina: "",
            responsable_id: {
                nombre: "",
                apellido: ""
            },
            empleados: [],
        },
        registrado: false,

        cuentas: [],
        autorizado: [],

        deuda: [],
        monto: [],
        proyectos: [],
        proyecto: null,
        loading: false
    };

    componentDidMount() {
         //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        const {nomina_id, id} = this.props.match.params;
        this.state.nomina_id = nomina_id;
        this.state.grupo_id = id;

        this.getGroup(nomina_id, id)
        this.loadCuentas();
        this.loadProyectos();
    }


    /**
     * @methodOf RevisionDeGrupoFinanzas
     *
     * @funcion getGroup
     * @description Obtenemos el grupo segun la nomina ID y el grupo ID obtenidos del match  params
     * */
    getGroup = (nomina_id, grupo_id) => {


        this.setState({ loading: true })
        axios.get('/nominas/revisiones/get', {
            params: {
                nomina_id,
                grupo_id,
            }
        })
            .then(async ({ data }) => {
                this.setState({
                    group: data.data,
                    autorizado: data.data.empleados.map(i => i.monto),
                    deuda: data.data.empleados.map(i => {
                        return {
                            monto_deuda: 0,
                            usuario_id: (i.usuario_id) ? i.usuario_id._id : undefined,
                            nomina_id,
                            grupo_id,
                        }
                    }),
                    registrado: data.registrado,
                    loading: false
                })
                if (data.data.proyecto_id) {
                    this.setState({
                        proyecto: data.data.proyecto_id._id,
                    })
                }
            })
            .catch(e => {
                console.log('e', e)
            })
    }


    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function saveGroup
     *
     * @param nomina_id Nomina del ID
     * @param grupo_id ID del Grupo
     * @param empleados ID del Grupo
     */
    saveGroup = (nomina_id = this.state.nomina_id, grupo_id = this.state.grupo_id, empleados = this.state.group.empleados, proyecto_id = this.state.proyecto) => {
        let errors = []
        for (const [index, empleado] of empleados.entries()) {
            if (empleado.cuenta_id !== undefined)
                continue;
            errors.push(index)
        }
        if (proyecto_id === null || errors.length > 0)
            return Modal.error({

                title: "No es posible guardar las transacciones",
                content: <div>
                    <List
                        dataSource={errors}
                        renderItem={(item, index) => {
                            return <List.Item>
                                El trabajador número <Tag>{item + 1}</Tag> no tienen ninguna cuenta.
                            </List.Item>
                        }}
                        locale={{ emptyText: <div></div> }}
                    >
                    </List>
                    {(proyecto_id === null) ? <Paragraph>Debe seleccinar un proyecto para continuar.</Paragraph> : null}
                </div>

            })




        let deudaNueva = []
        for (const deuda of this.state.deuda) {
            if (deuda.monto_deuda !== 0)
                deudaNueva.push({ ...deuda, proyecto_id })
        }
        Modal.warning({
            title: '¿Estás seguro de que quieres incluir estas transacciones en Finanzas?',
            cancelText: "Cancelar",
            okText: "Aceptar",
            onOk: () => {
                this.setState({ loading: true })
                axios.post('/nominas/registrar/finanzas', {
                    nomina_id,
                    grupo_id,
                    empleados,
                    proyecto_id,
                    empleados_deuda: deudaNueva

                })
                    .then(async ({ data }) => {
                        switch (data.tipo) {
                            case 1: message.success('Registrado correctamente');
                                break;
                            case 2: message.warn('Error en la nomina de algunos empleados.')
                                break;
                            case 3: message.error('Error al registrar: Verificar el subrubro del grupo')
                                break;
                            case 4: message.error('Ocurrio en error al registrar el grupo.')
                                break;
                        }
                        this.props.history.goBack();
                    })
                    .catch((error) => {
                        switch (error.response.data.tipo) {
                            case 1: message.success('Registrado correctamente');
                                break;
                            case 2: message.warn('Error en la nomina de algunos empleados.')
                                break;
                            case 3: message.error('Error al registrar: Verificar el subrubro del grupo')
                                break;
                            case 4: message.error('Ocurrio en error al registrar el grupo.')
                                break;
                        }

                        this.props.history.goBack();
                    })

            }
        });

    }

    /**
     *
     *
     * @memberof RevisionDeGrupoFinanzas
     * @method loadProyectos
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200
            }
        }).then((cuentas) => {
            this.setState({
                cuentas: cuentas.data.data.itemsList,

            })
        }).catch((error) => {
        })
    }


    /**
     *
     *
     * @memberof RevisionDeGrupoFinanzas
     * @method loadProyectos
     *
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadProyectos = () => {
        axios.get('/proyectos', {
            params: {
                page: 1,
                limit: 200
            }
        })
            .then(proyectos => {
                this.setState({
                    proyectos: proyectos.data.data.itemsList
                })
            })
            .catch(error => {




            })
    }

    /**
     *
     *
     * @memberof RevisionDeGrupo
     * @description Actualizamos el monto del empleado en cuestion
     *
     * @param value
     *
     * @param id Index del empleado al cual se actualizar los montos y la deuda
     *
     * @param valye cantidad a actualizar
     */
    updateMonto = (value, id) => {
        let autorizado = this.state.autorizado;
        let group = this.state.group;
        group.empleados[id].monto = value;
        if (group.empleados[id].monto < autorizado[id])
            this.state.deuda[id].monto_deuda = parseFloat(autorizado[id] - group.empleados[id].monto).toFixed(2)
        else
            this.state.deuda[id].monto_deuda = 0;
        this.setState({ group })
    }



    /**
     *
     * @memberOf RevisionDeGrupoFinanzas
     *
     * @description Actualizar el valor de la deuda. SI es cero se limina
     *
     * @param value Actualizamos el valor
     * @param id Actualizamos el valor del ID
     *
     * */
    updateDeuda = (value, id) => {
        let autorizado = this.state.autorizado;
        let group = this.state.group;
        autorizado[id] = value;


        if (group.empleados[id].monto < autorizado[id])
            this.state.deuda[id].monto_deuda = autorizado[id] - group.empleados[id].monto;
        else
            this.state.deuda[id].monto_deuda = 0;

        this.setState({ autorizado })
    }






    /**
     *
     *
     * @memberof RevisionDeGrupo
     * @description Elimina el empleado.
     *
     * @param i => Index del empleado a eliminar.
     */
    deleteEmployee = (i) => {
        this.setState(state => {
            let newA = []
            //Eliminamos del arreglo de empleados
            for (let index = 0; index < state.group.empleados.length; index++) {
                const element = state.group.empleados[index];
                if (index === i)
                    continue;
                else
                    newA.push(element)
            }
            state.group.empleados = newA;

            //Eliminamos del arreglo de autorizados
            let autorizado = []
            for (let index = 0; index < state.autorizado.length; index++) {
                const element = state.autorizado[index];
                if (index === i)
                    continue;
                else
                    autorizado.push(element)
            }
            state.autorizado = autorizado;


            //Eliminamos del arreglo de deudas
            let deuda = []
            for (let index = 0; index < state.deuda.length; index++) {
                const element = state.deuda[index];
                if (index === i)
                    continue;
                else
                    deuda.push(element)
            }
            state.deuda = deuda;


            return state;
        })

    }



    /**
     *
     * @methodOf RevisionGrupoFinanzas
     *
     * @description Selecciona la cuenta para ese registro en especifico.
     *
     * @param e
     * @description Cuenta
     *
     * @param index
     * @description index en el arreglo
     * */
    selectAccount = (e, index) => this.setState(state => {
        state.group.empleados[index].cuenta_id = e
        return state
    })


    render() {
        const user = this.context;
        const cuentasSelectOptions = this.state.cuentas.map(function ({ _id, nombre, logo, color }, index) {
            return <Option value={_id}>
                <CustomAvatar
                    image={logo}
                    name={nombre}
                    // color={color}
                    size="small"
                    style={{
                        marginRight: '5px'
                    }}
                />
                {nombre}
            </Option>
        })


        const { group, registrado } = this.state;

        const { deleteEmployee, selectAccount, updateMonto, saveGroup, updateDeuda } = this;

        const columns = (group.modalidad === 1) ? 6 : 4
        const columnsElement = {
            input: 6,
            action: 6
        };
        return (
            <div>

                <Row className="row-encabezado">
                    <Col xs={24} xl={8} xxl={12}>
                        <h2><Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline' }}><IconArrowBack /></Button> Grupo
                        </h2>
                    </Col>
                </Row>
                <Row style={{ padding: '24px' }} className="content">
                    <Row gutter={[32, 32]} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} xl={24}>
                            <Card className="content-card">
                                <Row>
                                    <Col span={18}>
                                        <Title level={3} style={{ color: "#0047FF" }}>{group.nombre}</Title>
                                    </Col>

                                    <Col span={6} className="information-content">

                                        <Select
                                            className="project-selection"
                                            placeholder="Seleccione proyecto"
                                            onChange={(id) => this.setState({ proyecto: id })}
                                            value={this.state.proyecto}
                                            disabled={this.state.edicion}>
                                            {this.state.proyectos.map(function ({ _id, nombre, logo, color }, index) {
                                                return <Option value={_id}>
                                                    <CustomAvatar
                                                        image={logo}
                                                        name={nombre}
                                                        color={color}
                                                        size="small"
                                                        style={{
                                                            marginRight: '5px'
                                                        }}
                                                    />
                                                    {nombre}
                                                </Option>
                                            })}
                                        </Select>
                                        <Avatar className="information-content-avatar"
                                            image={group.responsable_id.avatar}
                                            name={[
                                                group.responsable_id.nombre,
                                                group.responsable_id.apellido
                                            ]}
                                        />
                                        <Title
                                            className="information-content-title">{group.responsable_id.nombre}</Title>

                                    </Col>
                                </Row>
                                <Row
                                    className={"information-widgets-content " + ((group.modalidad === 2) ? "information-destajo" : "")}>
                                    <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Empleados</Paragraph>
                                        <Paragraph
                                            className="information-widgets-content-description">{group.empleados.length}</Paragraph>
                                    </Col>
                                    <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Frecuencia</Paragraph>
                                        <Paragraph
                                            className="information-widgets-content-description">{(group.frecuencia === 1) ? "Semanal" : "Quincenal"}</Paragraph>
                                    </Col>
                                    <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Modalidad</Paragraph>
                                        <Paragraph
                                            className="information-widgets-content-description">{(group.modalidad === 1) ? "Salario" : "Destajo"}</Paragraph>
                                    </Col>
                                    {group.modalidad === 2 ? <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Precio
                                            m<sub>2</sub></Paragraph>
                                        <Paragraph
                                            className="information-widgets-content-description"> {group.precio_m2.toString().toMoney(true)}</Paragraph>
                                    </Col> : null}
                                    <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Monto Total</Paragraph>
                                        <Paragraph
                                            className="information-widgets-content-description">${group.empleados.reduce((accumulator, currentValue) => accumulator + currentValue.monto, 0).toString().toMoney(true)}</Paragraph>
                                    </Col>
                                </Row>
                                <Row className={"information-widgets-content " + ((group.modalidad === 2) ? "information-destajo" : "")} style={{ marginTop: '20px' }}>
                                    <Col span={columns}>
                                        <Paragraph className="information-widgets-content-title">Responsable</Paragraph>
                                        <Avatar className="information-content-avatar"
                                            image={group.responsable_id.avatar}
                                            name={[
                                                group.responsable_id.nombre,
                                                group.responsable_id.apellido
                                            ]}
                                        />
                                        <label className="information-widgets-content-description" style={{ marginLeft: '10px' }}>{group.responsable_id.nombre + ' ' + group.responsable_id.apellido}</label>
                                    </Col>

                                    {(group.inmueble_id !== undefined) ?
                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Inmueble</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{group.inmueble_id.nombre}</Paragraph>
                                        </Col> : ''}

                                    {(group.area_id !== undefined) ?

                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Area</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{group.area_id.nombre}</Paragraph>
                                        </Col> : ''}
                                </Row>

                            </Card>
                        </Col>
                    </Row>


                    <Row className="list-container" gutter={[32, 32]} style={{ width: '100%' }}>
                        <Col span={24}>
                            <Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>Empleados</Title>
                        </Col>
                        <Col span={24} style={{ paddingTop: 0 }}>
                            <List
                                itemLayout="horizontal"
                                dataSource={group.empleados}
                                className="list-employees"
                                renderItem={(item, index) => (
                                    <List.Item className="list-employees-item-content">
                                        <p className="number-element">{index + 1}</p>
                                        <Row className="list-employee-item list-employee-financial-item"
                                            style={{ width: '100%' }}>
                                            <Col span={columnsElement.input}>
                                                {(item.usuario_id) ? (item.usuario_id.nombre + ' ' + item.usuario_id.apellido) : <strong>Empleado Inexistente</strong>}
                                            </Col>
                                            <Col span={columnsElement.input}>
                                                <Select disabled={item.pagado} style={{ width: '80%' }} onChange={e => selectAccount(e, index)} defaultValue={item.cuenta_id}>
                                                    {cuentasSelectOptions}
                                                </Select>
                                            </Col>
                                            <Col span={columnsElement.input}>
                                                <InputNumber
                                                    disabled
                                                    className="input-ghost"
                                                    precision={2}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    defaultValue={(item.monto_autorizado) ? item.monto_autorizado : item.monto}

                                                    style={{width: 'calc(100% - 20px)', margin: '0px auto'}}
                                                />
                                            </Col>
                                            <Col span={columnsElement.input}>
                                                <InputNumber
                                                    disabled={item.pagado}
                                                    value={item.monto}
                                                    className="input-ghost"
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => {
                                                        return value.replace(/\$\s?|(,*)/g, '')
                                                    }}
                                                    precision={2}
                                                    min={0}
                                                    onChange={e => updateMonto(e, index)}

                                                    style={{width: 'calc(100% - 20px)', margin: '0px auto'}}
                                                />
                                            </Col>
                                            {(user && user.tipo === 1) ?
                                                <Col span={columnsElement.action}>
                                                    <Button disabled={item.pagado} onClick={() => deleteEmployee(index)}
                                                        className="button-list-employee-delete"
                                                        style={{ background: "#FF0000", }}
                                                        icon={<FaMinus color="white" style={{ position: "relative", top: "4px" }} />} />
                                                </Col>
                                                : null}

                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Row>
                <Button disabled={registrado} type="ghost" className="btn-save-financial"
                    onClick={() => {
                        this.saveGroup()

                    }}>
                    <IconoGuardado />
                </Button>
            </div>
        )
    }
}


