import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Spin, Image, List } from 'antd';


import { FileOutlined } from '@ant-design/icons'
import { IconCloseModal } from '../../../Widgets/Iconos';
import { Player, BigPlayButton, ControlBar } from 'video-react';

import '../../../../../node_modules/video-react/dist/video-react.css'
import './../../../../Styles/modales.css'
import './../../../../Styles/Modal/ModalDetalleBitacoraEstatusAvance.css'


const moment = require('moment');
const axios = require('axios').default;

const { Title, Text } = Typography;



export default class ModalCasaDetalle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            planoTipo: '',

            concepto: '',
            fecha: '',
            tipo: '',
            monto: '',
            bitacora: [],
            loading: true
        }
    }

    /**
    * @memberof ModalRecordatorioDetalle
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {

        this.setState({
            planoTipo: this.props.planoTipo,

            concepto: this.props.concepto,
            fecha: this.props.fecha,
            tipo: this.props.tipo,
            monto: this.props.monto,
        });
    }

  
    /**
    * @memberof ModalRecordatorioDetalle
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props  
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
    }

    

    render() {
        var bitacora = this.props.bitacora;
        let loading = this.state.loading;
        let visible = this.props.modalVisible
        var tipo = this.props.tipo;
        var modal = <Modal> cx </Modal>;

        if (tipo === "archivos") {
            modal = <section>
                {(bitacora !==undefined && bitacora.length > 0 && visible) ?
                    <Spin spinning={loading}>
                        <Col xs={{ span: 24 }} >
                            <Text className="lblTitulo font-20">Última entrega en bitacora :  {this.props.bitacoraFecha.toString().substring(0, 10)}</Text>
                            <Row align="center">
                                <List
                                    loading={loading}
                                    className="component-list-video"
                                    itemLayout="horizontal"
                                    dataSource={bitacora}
                                    renderItem={item => {
                                        var formato = item.split(".")[1].toLocaleUpperCase();
                                        if (formato === "MP4") {
                                            return <Row className="center pd-1 row-video" >

                                                <Player
                                                    // poster="/images/img-video.png"
                                                    className="item-video"
                                                >
                                                    {/* <ControlBar autoHide={false} className="my-class" /> */}
                                                    <BigPlayButton position="center" />
                                                    <source src={axios.defaults.baseURL + '/voucher/' + item} />
                                                </Player>
                                                {item}
                                            </Row>
                                        }
                                        else if (formato === "JPG" || formato === "PNG" || formato === "SVG") {
                                            return <Row className="center pd-1 row-video" >

                                                <Image
                                                    className="item-image"
                                                    src={axios.defaults.baseURL + '/voucher/' + item}
                                                />

                                                <p style={{
                                                    width: "100%",
                                                    bottom: 0,
                                                    textAlign: "center"
                                                }}>
                                                    {item}
                                                </p>
                                            </Row>
                                        }
                                        else {
                                            return <Row className="center pd-1 row-video" >
                                                <a style={{ width: '100%', fontWeight: 'bold', display: 'block', fontSize: "12pt", padding: "20px", marginTop: "50px" }}
                                                    href={axios.defaults.baseURL + '/voucher/' + item} target="_blank"
                                                    title={"Abrir " + item} >
                                                    <FileOutlined /> <p style={{ textAlign: "right", marginTop: "-28px", width: "calc(100% - 30px)" }} >  {item} </p>
                                                </a>
                                            </Row>
                                        }
                                    }}
                                />

                            </Row>
                        </Col>

                        <div className="nView">
                            {setTimeout(() => { this.setState({ loading: false }); }, 3000)}
                        </div>
                    </Spin>
                    : null}

            </section>

        }

        else {

            modal = <section>
                <Row>
                    <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }} >
                        <Text className="lblTitulo font-20">Nombre</Text>
                        <p className="pInfo font-16">{this.props.nombre}</p>
                    </Col>
                    {
                        (this.state.planoTipo === "esmeralda") ?
                            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 1 }}>
                                <Text className="lblTitulo font-20">Sección</Text>
                                <p className="pInfo font-16"> {this.props.seccion} </p>
                            </Col>
                            : null
                    }
                </Row>

                <Row>
                    <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }} >
                        <Text className="lblTitulo font-20">Estatus</Text>
                        <p className="pInfo font-16">{this.props.estatus}</p>
                    </Col>

                    <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 1 }} >
                        <Text className="lblTitulo font-20">Cliente</Text>
                        <p className="pInfo font-16">{this.props.cliente}</p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }}>
                        <Text className="lblTitulo font-20">Monto</Text>
                        <p className="pInfo font-16">$ {this.props.monto} MXN</p>
                    </Col>

                    <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 1 }} >
                        <Text className="lblTitulo font-20">Monto por Pagar</Text>
                        <p className="pInfo font-16">$ {this.props.monto_deuda.toMoney()} MXN</p>
                    </Col>
                </Row>
            </section>
        }

        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className={(tipo === "archivos") ? "modal-form  modal-videos" : "modal-form  modal-recivo"}
                destroyOnClose={true}
                zIndex={1000}

            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    {(this.state.planoTipo === "esmeralda" ) ?
                        <Title level={3} className="modal-title">Detalle {this.props.nombre}, {this.props.seccion} </Title>
                        :
                        <Title level={3} className="modal-title">Detalle {this.props.nombre} </Title>
                    }
                </div>
                {modal}

            </Modal>
        )
    }
}
