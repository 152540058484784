import React, { Component } from 'react';
import { Row, Col, Card, Table, Button, Typography, Popconfirm, Menu, DatePicker, message, Form, Checkbox, Dropdown, Statistic } from 'antd'
import { Link } from 'react-router-dom';

//componentes
import { FileTextOutlined } from "@ant-design/icons";
import { IconVentasCircles, IconUsuariosCircle, IconPlus } from '../../Widgets/Iconos';
import { CardInfoDashboard, CardInfo } from '../../Widgets/Cards/Cards';
import  VentasAnuales from './Graficas/VentasAnuales'
import  Proveedores from './Graficas/Proveedores'
import  MasVendidos from './Graficas/MasVendidos'


//css
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import "../../../Styles/HealthFinance.css";

const { Text, Title } = Typography;

const moment = require('moment');
const axios = require('axios').default
const { RangePicker } = DatePicker;

axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


//Variables
export default class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: []
        }
    }

    componentDidMount(){
        
    }



    render() {
        return (
            <div className="financial-health">
                <Row className="row-encabezado">
                    <Col xs={24} xl={16} xxl={18}>
                        <h2>Dashboard</h2>
                    </Col>
                </Row>

                <section style={{ margin: 24 }}>

                    <Row style={{ width: '100%', marginTop: 24  }} gutter={[24, 24]}>
                        <Col xs={24} lg={12} xl={8}>
                            <Link to={'/admin/financial-health/inversiones'}>
                                <CardInfoDashboard
                                    text="card-info-dsh-fh"
                                    title='Ventas Totales'
                                    textAlign={false}
                                    hover={true}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Col span={16} >
                                            <Statistic prefix={'$'} value={this.state.inversiones_total} precision={2} suffix={<small>MXN</small>} className="statistic-financial" />
                                        </Col>
                                        <Col span={8}>
                                            <IconVentasCircles style={{ maxwidth: '100%', width: '100%', height: '180px' }} />
                                        </Col>

                                    </Row>
                                </CardInfoDashboard>
                            </Link>
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                            <Link to={'/admin/acreedores'}>
                                <CardInfoDashboard
                                    text="card-info-dsh-fh"
                                    title='Productos Totales'
                                    textAlign={false}
                                    hover={true}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Col span={16} >
                                            <Statistic prefix={'$'} value={this.state.acreedores_total} precision={2} suffix={<small>MXN</small>} className="statistic-financial" />
                                        </Col>
                                        <Col span={8}>
                                            <IconUsuariosCircle style={{ maxwidth: '100%', width: '100%', height: '180px' }} />
                                        </Col>

                                    </Row>
                                </CardInfoDashboard>
                            </Link>
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                           <CardInfoDashboard
                                    text="card-info-dsh-fh"
                                    title='Provvedores Totales'
                                    textAlign={false}
                                    hover={true}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Col span={16} >
                                            <Statistic prefix={'$'} value={this.state.acreedores_total} precision={2} suffix={<small>MXN</small>} className="statistic-financial" />
                                        </Col>

                                    </Row>
                                </CardInfoDashboard>
                        </Col>
                    </Row>

                    <Row style={{ width: '100%', marginTop: 24  }} gutter={[24, 24]}>
                        <Col xs={24} lg={16}>
                            <CardInfoDashboard
                                text="card-info-dsh-fh"
                                title='Ventas por año'
                                textAlign={false}
                            >
                                <Row>
                                    <Col span={24} >
                                        <VentasAnuales/>
                                    </Col>
                                </Row>
                            </CardInfoDashboard>
                        </Col>
                        <Col xs={24} lg={8}>
                            <CardInfoDashboard
                                text="card-info-dsh-fh"
                                title='Proveedores'
                                textAlign={false}
                            >
                                <Row>
                                    <Col span={24} >
                                        <Proveedores/>
                                    </Col>
                                </Row>
                            </CardInfoDashboard>
                        </Col>
                        <Col xs={24} lg={16}>
                            <CardInfoDashboard
                                text="card-info-dsh-fh"
                                title='Productos mas Vendidos'
                                textAlign={false}
                            >
                                <Row>
                                    <Col span={24} >
                                        <MasVendidos/>
                                    </Col>
                                </Row>
                            </CardInfoDashboard>
                        </Col>
                        <Col xs={24} lg={8}>
                            <CardInfoDashboard
                                text="card-info-dsh-fh"
                                title='Productos por terminar'
                                textAlign={false}
                            >
                                <Row>
                                    <Col span={24} >
                                        
                                    </Col>
                                </Row>
                            </CardInfoDashboard>
                        </Col>
                    </Row>

                </section>
                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                    <IconPlus />
                </Button>
            </div>
        )
    }
}
