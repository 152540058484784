
import React, {useState} from "react";
import {Avatar} from "antd";
import axios from "axios";

import './Avatar.css';

export default function CustomAvatar(props){

    const {image, name = "", color = "#bbbbbb", size = "default", style = {}, className = ""} = props
    let [error, setError] = useState(true);

    if (image !== undefined && image !== null && image !== "" && error){
        return (
            <Avatar
                style={{
                    ...style,
                    backgroundColor: color
                }}
                size={size}
                src={axios.defaults.baseURL + '/upload/' + image}
                onError={()=>setError(false)}
            />
        )
    }else{
        let title = "";
        if (Array.isArray(name) && name.length > 1){
            title+= name[0] ? name[0].charAt(0) : 'N';
            title+= name[1] ? name[0].charAt(0) : 'N';
        }else{
            const words = ((typeof name === "string")?name:"").split(" ");
            for (const [index, word] of words.entries()) {
                if (index > 1) break;
                title+=word.charAt(0);
            }
        }


        return (
            <Avatar
            {...props}
                className={className}
                style={{
                    ...style,
                    backgroundColor: color,
                }}
                size={size}

            >
                {title.toUpperCase()}
            </Avatar>
        )
    }


}


