import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Divider, Spin, DatePicker } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar'
import PrioridadSelect from '../../../Widgets/prioridadSelect'
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;

/**
 *
 *
 * @class ModalRequisiciones
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalRequisiciones extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            almacenes: [],
            proyectos: [],
        }
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function componentDidMount
     * @description Obtiene la informacion inicial
     */
    componentDidMount(){
        this.getAlmacenes()
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function getAlmacenes
     * @description Obtiene un listado de los almacenes del proyecto ligado a la requisicion
     */
    getAlmacenes = (search) => {
        axios.get('/proyectos/almacenes',{
            params:{
                proyecto_id: this.props.proyecto_id,
                paginate: false,
                search
            }
        }).then(response => {
            console.log("response", response.data.data);
            this.setState({almacenes: response.data.data})

        }).catch(error => {
            console.log("error", error);

        })
    }



    /***
     *
     * @methodOf ListasCotizaciones
     * @function aprobarEntrega
     * @description hacee que la requisicion pase al siguiente estatus (Entrega) y aprueba la requisicion_opcion para la entrega
     */
    aprobarEntrega = (values) => {
        this.setState({loading: true})
        axios.post('/requisiciones/entrega/aprobar',{
            ...values,
            almacen_id: values.almacen_id,
            fecha_entrega: values.fecha_entrega,
            tipo_entrega: values.tipo_entrega,
            requisicion_id: this.props.requisicion_id,
            requisicion_opcion_id: this.props.requisicion_opcion_id,
        }).then(response => {
            message.success('La requisición fue aceptada')
            this.props.onCancel(true)
        }).catch(error => {
            console.log(error)
            message.success('Error al obtener la información')
        }).finally(()=>{
            this.setState({loading: false})
        })
    }


    render() {


        return [

            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title">Aceptar Requisición</Title>
            </div>,
            <Spin spinning={this.state.loading}>


                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.modalMateriales}
                    onFinish={this.aprobarEntrega}

                >
                    <Row align="center" gutter={[16]}>

                        <Col span={22}>
                            <Form.Item
                                label="Almacén"
                                name="almacen_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Almacén."
                                }]}
                            >
                                <Select
                                    className="form-select"
                                    onSearch={(search) => this.getAlmacenes(search)}
                                    showSearch
                                    filterOption={false}
                                >
                                    {this.state.almacenes.map(almacen => <Option value={almacen._id}>{almacen.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="Tipo de Entrega"
                                name="tipo_entrega"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Tipo de entrega"
                                }]}
                            >
                                <Select
                                    className="form-select"
                                >
                                    <Option value={0}>Directa</Option>
                                    <Option value={1}>Envío</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="Fecha de Entrega"
                                name="fecha_entrega"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha de entrega."
                                }]}
                            >
                                <DatePicker 
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Estatus"
                                name="estatus"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el estatus de la requisición"
                                }]}
                            >
                                <Select
                                    className="form-select"
                                >
                                    <Option value={4}>Entrega</Option>
                                    <Option value={5}>Recolección</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                        </Col>

                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}



export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalRequisiciones {...props} />
    </Modal>


}
