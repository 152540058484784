import React, { Component } from 'react';
import { Button, Col, Row, Spin, Popconfirm, Typography, Card, Avatar, Tooltip, message, List, Tag } from 'antd'
import { FaArrowRight } from "react-icons/fa";
import { PlusOutlined, AntDesignOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

//componentes
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import { CardInfoDashboard, CardProveedor, CardFacturas } from '../../Widgets/Cards/Cards';
import { IconEye, IconQR, IconDelete, IconEdit, IconGoToLink } from '../../Widgets/Iconos';

import ModalOrdenMaterial from './ModalOrdenMaterial'
import ModalTicketQr from './ModalTicketQr'
import Logged from "../../../Hooks/Logged";

//css
import '../../../Styles/Proveedores.css'
import '../../../Styles/Ordenes.css'
const axios = require('axios').default;
const { Text, Title } = Typography;
const moment = require('moment');


export default class OrdenesDashboard extends Component {

    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {

            accionModal: "",
            registroId: undefined,
            registroNombre: '',
            ModalOrden: false,

            loading: true,
            dataOrdenes: [],

            ord_creadas: 0,
            ord_completas: 0,
            ord_incompletas: 0,
            ord_mes: 0,
            ord_semana: 0,
            ord_sinRecibir: 0,

            ordenes: [],
        }
    }


    /**
     *
     *
     * @memberof OrdenesDashboard
     * 
     * @method componentDidMount
     * @description 
     */
    componentDidMount() {

        console.log("OrdenesDashboard")
        this.getInfo();
        //this.getOrdenes(1)
    }


    /**
    * @memberof OrdenesDashboard
    * 
    * @method getInfo
    * @description trae información para campos de ordenes y tabla
    */
    getInfo = async () => {
        this.setState({ loading: true });

        axios.get('/ordenes/dashboard', {
            params: {
                pagination: false,
            }
        }).then((data) => {
            if (data.data.success) {
                var res = data.data;
                this.setState({
                    ord_completas: res.ord_completas,
                    ord_creadas: res.ord_creadas,
                    ord_incompletas: res.ord_incompletas,
                    ord_sinRecibir: res.ord_sinRecibir,
                    ord_mes: res.ord_mes,
                    ord_semana: res.ord_semana,
                    dataOrdenes: res.dataOrdenes,
                    loading: false
                })
            }
            else {
                this.setState({
                    dataOrdenes: [],
                    ord_completas: 0,
                    ord_creadas: 0,
                    ord_incompletas: 0,
                    ord_sinRecibir: 0,
                    ord_mes: 0,
                    ord_semana: 0,
                    loading: false
                })

            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            });
        })
    }



    /**
    * @memberof OrdenesDashboard
    * 
    * @method closeMethod
    * @description 
    */
    closeMethod = () => {
        this.setState({ modalDetalleVisible: false, factura: undefined })
    }

    /**
        * @memberof OrdenesDashboard
        * 
        * @method renderStatus
        * @description Regresa el tag del row en la lista,
        *  dependiendo del estatus de la orden
        * 1 -> Creada (la orden recien ha sido creada. )
        * 2 -> Sin Recibir
        * 3 -> Incompleta
        * 4 -> Completa
        
    */
    renderStatus = (status) => {

        let tags = {
            1: <Tag color="#0f6ff2">Creada</Tag>,
            2: <Tag color="#a24be9">Sin Recibir</Tag>,
            3: <Tag color="#d1dc2b">Incompleta</Tag>,
            4: <Tag color="#2bdb9b">Completa</Tag>,
            5: <Tag className="status-tag" color="rgba(57, 136, 208, 0.39)" style={{ color: "white" }}>
                Creadas
            </Tag>
        }

        return tags[status]
    }


    /**
    * @memberof OrdenesDashboard
    * @method   getOrdenes
    * @description  Trae la lista de ordenes
    **/
    getOrdenes = async (page = 1) => {
        axios.get('/ordenes/list', {
            params: {
                page: page
            }
        }).then(response => {
            this.setState({
                ordenes: response.data.data.itemsList,
                currentPage: response.data.data.currentPage,
                itemCount: response.data.data.itemCount,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }



    /**
    * @memberof OrdenesDashboard
    * @method   abrirModal
    * @description  
    * Se abre modal ordenes de material
    **/
    abrirModal = async (id, tipo) => {
        this.setState({
            accionModal: tipo,
            registroId: id,
            ModalOrden: true
        })
    }



    /**
    * @memberof OrdenesDashboard
    * @method   hideModal
    * @description  
    * Se cierra modal, se limpian states
    **/
    hideModal = async () => {
        this.setState({
            accionModal: "",
            registroId: '',
            ModalOrden: false,
            ModalQr: false
        });
        this.getInfo()
    }

    /**
    * @memberof ListaOrdenesMaterial
    * @method   abrirModalQR
    * @description  
    * Se abre modal QR, se envia id y nombre para futuros metodos
    **/
    abrirModalQR = async (id, nombre) => {
        this.setState({
            registroId: id,
            registroNombre: nombre,
            ModalQr: true
        })

        this.getOrdenes()

    }


    /**
     * @memberof OrdenesDashboard
     * @method   eliminarOrden
     * @description Elimina registro de orden por su id
     */
    eliminarOrden = id => {
        console.log(id)
        axios.post('/ordenes/delete', {
            orden_id: id
        })
            .then((response) => {
                message.success("Orden eliminada correctamente.");
                this.getInfo();
            })
            .catch((error) => {

                if (error.response?.status === 403)
                    message.error('No tienes los permisos para eliminar la Orden')
                else
                    message.error('Error al eliminar la Orden')
            })
    }


    render() {

        const user = this.context

        let { loading, dataOrdenes, ord_completas, ord_creadas, ord_incompletas, ord_mes, ord_semana, ord_sinRecibir } = this.state;

        return (
            <div>
                <Spin spinning={loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={6} xxl={8}>
                            <h2 style={{ width: 'auto', float: 'left' }}> Ordenes de Material </h2>
                        </Col>
                    </Row>

                    <section className="p-1 ordenes-dashboard">
                        <Row style={{ width: '100%' }} gutter={[16, 0]}>
                            <Col span={17}>
                                <Row gutter={[16, 24]}>
                                    <Col xs={24} lg={12} className="gutter-row">
                                        <CardInfoDashboard
                                            title='Ordenes Creadas'
                                            background='linear-gradient(89.47deg, #3988D0 0.39%, #0066FF 126.36%)'
                                            textAlign={true}
                                            number={ord_creadas}
                                        />
                                    </Col>
                                    <Col xs={24} lg={12} className="gutter-row">
                                        <CardInfoDashboard
                                            title='Ordenes Sin Recibir'
                                            background='linear-gradient(87.63deg, #6C69EC 1.68%, #C438E7 141.52%)'
                                            textAlign={true}
                                            number={ord_sinRecibir}
                                        />
                                    </Col>
                                    <Col xs={24} lg={12} className="gutter-row">
                                        <CardInfoDashboard
                                            title='Ordenes Incompletas'
                                            background='linear-gradient(89.47deg, #C4D039 0.39%, #FAFF00 126.36%)'
                                            textAlign={true}
                                            number={ord_incompletas}
                                        />
                                    </Col>
                                    <Col xs={24} lg={12} className="gutter-row">
                                        <CardInfoDashboard
                                            title='Ordenes Completas'
                                            background='linear-gradient(89.47deg, #39D0BB 0.39%, #00FF38 126.36%)'
                                            textAlign={true}
                                            number={ord_completas}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={7}>
                                <Card className="info-card">
                                    <Title level={3} className="title-info-card">Ordenes en esta Semana</Title>
                                    <Title level={3} className="title-info-card info">{ord_semana}</Title>
                                    <Title level={3} className="title-info-card">Ordenes en este Mes</Title>
                                    <Title level={3} className="title-info-card info">{ord_mes}</Title>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="flex-between">
                                <Title className="title" level={3}>Ordenes</Title>
                                <Link to="/admin/ordenes/lista" className="button-link-to mr-1" type="link" ><IconGoToLink /></Link>
                            </Col>
                        </Row>
                        <Row>
                            <List
                                className="component-list dshb-lista-ordenes"
                                itemLayout="horizontal"
                                dataSource={dataOrdenes}
                                locale={{ emptyText: 'Sin Facturas' }}
                                header={<Row>
                                    <Col span={3} className="center">
                                        <Text>Fecha Creada</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        Folio ID
                                    </Col>
                                    <Col span={4} className="center">
                                        Proyectos
                                    </Col>
                                    <Col span={4} className="center">
                                        No. de Piezas
                                    </Col>
                                    <Col span={4} className="center">
                                        No. de Toneladas
                                    </Col>
                                    <Col span={3} className="center">
                                        Estatus
                                    </Col>
                                    <Col span={3} className="center">
                                        ACCIONES
                                    </Col>
                                </Row>}
                                renderItem={(item, index) => (
                                    <List.Item className="component-list-item lista-materiales">
                                        <Card className="card-list">
                                            <Row style={{ width: '100%' }} className="">
                                                <Col span={3} className="center">
                                                    <Text strong>{moment(item.fecha_creacion).format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    {item.folio_id}
                                                </Col>
                                                <Col span={4} className="center">

                                                    <Avatar.Group>
                                                        {item.proyectos_ids.map(({ _id, nombre, logo, color }, index) => (

                                                            <CustomAvatar
                                                                image={logo}
                                                                name={nombre}
                                                                title={nombre}
                                                                color={(color !== undefined) ? color : '#1890ff'}
                                                                style={{ backgroundColor: (color !== undefined) ? color : '#1890ff', marginRight: "2px", cursor: "pointer" }}
                                                            />
                                                        ))}
                                                    </Avatar.Group>

                                                </Col>

                                                <Col span={4} className="center">
                                                    <Text>{item.piezas ? item.piezas : 0} piezas</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text>{item.toneladas ? item.toneladas : 0} toneladas</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    {this.renderStatus(item.estatus)}
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Button type="primary" className="button-survey btn-eye button-eye"
                                                        onClick={(e) => { this.setState({ registroId: item._id, ModalOrden: true }) }}
                                                        title="Ver detalle">
                                                        <IconEye />
                                                    </Button>
                                                    <Link to={`/admin/ordenes/${item._id}`}>
                                                        <Button className="btn btn-edit" title="Editar" >
                                                            <IconEdit />
                                                        </Button>
                                                    </Link>
                                                    <Button
                                                        className={`btn ${item.qrs_cantidad ? 'btn-printed-qr' : 'btn-edit'}`}
                                                        title="QR"
                                                        disabled={(item.estatus === 1 || item.qr_status === true)}
                                                        onClick={(e) => this.abrirModalQR(item._id, item.nombre)}
                                                    >
                                                        <IconQR />
                                                    </Button>
                                                    {
                                                        user?.tipo === 1 ?
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title={"¿Deseas eliminar la Orden ?"}
                                                                onCancel={e => e.stopPropagation()}
                                                                okText="Si"
                                                                cancelText="No"
                                                                onConfirm={e => this.eliminarOrden(item._id)}

                                                            >
                                                                <Button className="btn btn-delete"
                                                                    onClick={e => e.stopPropagation()}
                                                                >
                                                                    <IconDelete title="Eliminar" />
                                                                </Button>
                                                            </Popconfirm>
                                                            :
                                                            <Button disabled={true} className="btn btn-delete"> <IconDelete /> </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Row>
                    </section>

                </Spin>
                <ModalOrdenMaterial
                    modalVisible={this.state.ModalOrden}
                    accionModal={'detalle'}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                />

                <ModalTicketQr
                    modalVisible={this.state.ModalQr}
                    closeMethod={this.hideModal}
                    orden_id={this.state.registroId}
                />

            </div>
        )
    }
}
