import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, Select, List, Typography, message, Spin } from 'antd';
import './../../../../Styles/modales.css'
import { IconCloseModal, IconEdit, IconDelete } from '../../../Widgets/Iconos';
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const moment = require('moment');
const axios = require('axios').default;

const { Option } = Select;

const { Title, } = Typography;


export default class ModalTag extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            
            id: null,
            form: {},
            
            canEdit: false,
            update: false,
            loading: false
       
        }
    }

    //Referencia de form
    formModalTag = React.createRef();

    componentDidMount(): void {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     *
     *
     * @memberof ModalSubRubro
     *
     * @function componentDidUpdate
     * @description Se ejecuta el actualizar cualqueir estado. Veririca que el groupID de las propiedades sea diferente al del estado. En caso de que sea nulo, se inicia el proceso de nuevo grupo, caso contrario, obtenemos el grupo para editarlo.
     */
    componentDidUpdate() {
        //Si se abre el modal y el editar esa desactivo, se activa para poder editar
        if (this.props.modalVisible && !this.state.canEdit && !(this.props.id === null || this.props.id === undefined)) {
            this.state.canEdit = true;
            this.state.id = this.props.id;
            if (this.props.id !== null) {
                this.getTag(this.props.id)
            }
        }

        //Si se cierra el modal y el editar esta acctivo, lo desactivamos para esperar un nuevo to update y limpia el modalcini.
        if (!this.props.modalVisible && this.state.canEdit){
            this.state.canEdit = false;
            this.state.form = {};
            this.state.tipo = null
            this.state.id = undefined
            this.setState({ update: !this.state.update   })
            this.formModalTag.current.resetFields();
        }

        //En caso de que sea nuevo, pues solo se limppia
        if (!this.props.modalVisible && (this.props.id === null || this.props.id === undefined)) {
            this.state.form = {}
        }
    }


    /**
     *
     *
     * @memberof ModalTag
     * @function getTag
     * 
     * @description Se obtienen los valores del tag
     * 
     * @param id Obtenemos el ID
     */
    getTag = (id) => {
        axios.get('/tags/get',{
            params: {
                id
            }
        })
        .then(e => {
            this.state.form = e.data.data;
            this.formModalTag.current.setFieldsValue(e.data.data);
        })


    }



    /**
     *
     *
     * @memberof ModalTag
     * @function save
     * 
     * @description Se guardan los valores del fomulario del TAG
     * 
     * @param values Valores del formulario
     */
    save = values => {
        const { accionModal } = this.props;
        if (values._id) {
            axios.put('/tags/update',values)
            .then(e=>this.props.accept())
            .catch(error=>{
                Modal.error(error.response.data.message)
            })
        } else {
            axios.post('/tags/add',values)
            .then(e=>this.props.accept())
            .catch(error=>{
                message.error(error.response.data.message)
            })
        }
    }


    render() {
        const { modalVisible, hideModal, id,} = this.props;
        const {formModalTag} = this;
        return (
            <Modal
                visible={modalVisible}
                onCancel={hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-rubro"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{(id === null) ? "Crear" : "Editar"}{this.props.tipo === 'inmueble_id' ? ' Inmueble' : ' Actividad' }</Title>
                </div>
                <Spin spinning={this.state.loading}>
                    <Form
                        layout="vertical"
                        ref={formModalTag}
                        className="frm-cuentas"
                        name="formulario-transacciones"
                        onFinish={this.save}
                        initialValues={this.state.form}
                    >
                        <Form.Item
                            name="_id"
                            noStyle
                        >
                            <Input type="hidden" ></Input>
                        </Form.Item>
                        <Row align="center">
                            <Col span={20}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Input placeholder="Nombre" ></Input>
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row align="center">
                            <Col span={20}>
                                <Form.Item
                                    label="Tipo"
                                    name="tipo"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el tipo de Tag"
                                    }]}
                                >
                                    <Select>
                                        <Select.Option value={1}>
                                            Actividad
                                        </Select.Option>
                                        <Select.Option value={2}>
                                            Inmueble
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>



                        <Row align="center">
                            <Col span={20} >
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                >
                                    <Input.TextArea placeholder="Desarollo de proyectos." />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                            </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
