import React, { Component } from 'react';
import { Form, DatePicker, Dropdown, Menu, Avatar, Modal, Checkbox, Select, Button, Col, Row, message, Typography, List, Card, Pagination, Popconfirm, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
//componentes
import { IconEye, IconQR, IconDelete, IconPlus, IconArrowCustom as Arrow, IconDownloadCustom as Download, IconEdit } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import Logged from "../../../Hooks/Logged";

import ModalOrdenMaterial from './ModalOrdenMaterial'
import ModalTicketQr from './ModalTicketQr'

//import ModalOrdenMaterial from './ModalOrdenMaterial'
//css


const moment = require('moment');

const axios = require('axios').default;

const { Text, Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default class ListaOrdenesMaterial extends Component {
    timer = null
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            proyectos: [],
            proyectos_select: [],
            estatus_select: [],
            ordenes: [],

            currentPage: 0,
            itemCount: 0,

        }
    }

    /**
    * @memberof ListaOrdenesMaterial
    * @method   componentDidMount
    * @description  Al montar el componente:
    *
    **/
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProyectos()
        this.getOrdenes()
    }


    /**
    * @memberof ListaOrdenesMaterial
    * @method   handleVisibleChange
    * @description   
    *  Cambiar el valor de un dropdown
    **/
    handleVisibleChange = (type, flag) => {
        let { dropdowns } = this.state;
        dropdowns[type] = flag;
        this.setState({ dropdowns });
    };

    /**
    * @memberof ListaOrdenesMaterial
    * @method   abrirModalQR
    * @description  
    * Se abre modal QR, se envia id y nombre para futuros metodos
    **/
    abrirModalQR = async (id, nombre) => {
        this.setState({
            registroId: id,
            registroNombre: nombre,
            ModalQr: true
        })
    }


    /**
    * @memberof ListaOrdenesMaterial
    * @method   hideModal
    * @description  
    * Se cierra modal QR, se limpian states
    **/

    hideModal = async () => {
        this.setState({
            accionModal: "",
            registroId: undefined,
            registroNombre: '',
            ModalQr: false,
            ModalOrden: false,
            loading: true,
        });

        this.getOrdenes(this.props.currentPage)
        //this.getOrdenes(this.props.currentPage)

    }

    /**
    *
    * @memberof ListaOrdenesMaterial
    * @method getProyectos
    * @description obtiene la lista de todos los proyectos
    */
    getProyectos = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
            }
        }).then((proyectos) => {
            this.setState({ proyectos: proyectos.data.data })
        }).catch((error) => {

        })
    }

    /**
    * @memberof ListaOrdenesMaterial
    * @method   getOrdenes
    * @description  Trae la lista de ordenes
    **/
    getOrdenes = (page = 1) => {
        this.setState({ loading: true })
        axios.get('/ordenes/list', {
            params: {
                page: page,
                dates: this.state.dates,
                proyectos: this.state.proyectos_select,
                estatus: this.state.estatus_select,
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({
                ordenes: response.data.data.itemsList,
                currentPage: response.data.data.currentPage,
                itemCount: response.data.data.itemCount,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => { this.setState({ loading: false }) })
    }

    /**
    * @memberof ListaOrdenesMaterial
    * @method   deleteOrden
    * @description  Elimina una lista
    **/
    deleteOrden = (id) => {
        axios.post('/ordenes/delete', {
            orden_id: id
        }).then(response => {
            message.success('Orden eliminada')
            this.getOrdenes()
        }).catch(error => {
            if (error.response?.status === 403)
                message.error('No tienes los permisos para eliminar la Orden')
            else
                message.error('Error al eliminar la Orden')
        })
    }

    /**
    * @memberof ListaOrdenesMaterial
    * @method   renderEstatus
    * @description 
    **/
    renderEstatus = (status) => {
        let tags = {
            1: <Tag color="#0f6ff2">Creada</Tag>,
            2: <Tag color="#a24be9">Sin Recibir</Tag>,
            3: <Tag color="#d1dc2b">Incompleta</Tag>,
            4: <Tag color="#2bdb9b">Completa</Tag>,
            5: <Tag className="status-tag" color="rgba(57, 136, 208, 0.39)" style={{ color: "white" }}>
                Creadas
            </Tag>
        }

        return tags[status]
    }

    /**
     *
     *
     * @memberof ListaOrdenesMaterial
     * 
     * @method onChangeDates
     * @description filtra por fecha de creacion
     */
    onChangeDates = (dates, datesString) => {
        this.setState({ dates: datesString }, () => { this.getOrdenes(1) })
    }

    /**
    *
    *
    * @memberof Facturas
    * 
    * @method onChangeProyecto
    * @description cambia al estatus para filtrar por los proyectos selecionados
    */
    onChangeProyecto = (proyecto_id) => {
        const { proyectos_select } = this.state

        let index = proyectos_select.indexOf(proyecto_id)
        if (index !== -1) proyectos_select.splice(index, 1)
        else proyectos_select.push(proyecto_id)

        this.setState({ proyectos_select }, () => { this.getOrdenes(1) })
    }

    /**
    *
    *
    * @memberof Facturas
    * 
    * @method onChangeEstatus
    * @description cambia al estatus para filtrar por los estatus selecionados
    */
    onChangeEstatus = (estatus) => {
        const { estatus_select } = this.state

        let index = estatus_select.indexOf(estatus)
        if (index !== -1) estatus_select.splice(index, 1)
        else estatus_select.push(estatus)

        this.setState({ estatus_select }, () => { this.getOrdenes(1) })
    }

    abrirModal = async (id, tipo) => this.setState({
        accionModal: tipo,
        registroId: id,
        ModalOrden: true
    })
    
    /**
    * @memberof ListaOrdenesMaterial
    * @method   hideModal
    * @description  
    * Se cierra modal QR, se limpian states
    **/
    hideModal = async () => {
        this.setState({
            accionModal: "",
            registroId: '',
            ModalOrden: false,

            ModalQr: false,
            registroNombre: '',
        })

        this.getOrdenes(this.props.currentPage)
    }
    

    


    render() {
        const user = this.context
        
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={8} className="flex-left">
                        <Title level={4} className="view-title">Ordenes de Material</Title>
                    </Col>
                    <Col xs={24} lg={5} className="center">
                        <RangePicker
                            className="input-picker"
                            style={{
                                background: 'transparent !important',
                                color: '#FFF'
                            }}
                            onChange={this.onChangeDates} />
                    </Col>
                    <Col xs={24} lg={4} className="center">
                        <Dropdown
                            className="filter-element"
                            trigger={['click']}
                            overlay={
                                <Menu>
                                    {this.state.proyectos.map(({ _id, nombre, logo, color }, index) => (
                                        <Menu.Item key={index}>
                                            <Checkbox
                                                onChange={(e) => this.onChangeProyecto(_id, e.target.checked,)}
                                            >
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        margin: '0 5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Checkbox>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }>
                            <p>Proyectos <Arrow className="arrow" /></p>
                        </Dropdown>
                    </Col>
                    <Col xs={24} sm={12} lg={3} className="center">
                        <Dropdown
                            className="filter-element"
                            trigger={['click']}
                            overlay={
                                <Menu
                                    style={{
                                        minWidth: '260px',
                                        padding: '10px 5px'
                                    }}

                                >
                                    <Menu.Item><Checkbox onChange={() => { this.onChangeEstatus(1) }} >Creada</Checkbox></Menu.Item>
                                    <Menu.Item><Checkbox onChange={() => { this.onChangeEstatus(2) }}>Sin Recibir</Checkbox></Menu.Item>
                                    <Menu.Item><Checkbox onChange={() => { this.onChangeEstatus(3) }}>Incompleta</Checkbox></Menu.Item>
                                    <Menu.Item><Checkbox onChange={() => { this.onChangeEstatus(4) }}>Completa</Checkbox></Menu.Item>

                                </Menu>
                            }>
                            <p>Estatus <Arrow className="arrow" /></p>
                        </Dropdown>
                    </Col>
                </Row>

                <section className="p-1" style={{ width: "100%", overflow: "auto" }}>
                    <Row style={{ width: "auto", marginTop: "20px", minWidth: "1000px", paddingRight: "10px" }}>
                        <Col span={24}>

                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.ordenes}
                                header={
                                    <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles title-list-ordenesMaterial">
                                        <Col span={3} className="center">
                                            <Text type="secondary">Folio</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <Text type="secondary">Proyecto</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text type="secondary">No de Piezas</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text type="secondary">No de Toneladas</Text>
                                        </Col>

                                        <Col span={3} className="center">
                                            <Text type="secondary">Fecha Creación</Text>
                                        </Col>

                                        <Col span={3} className="center">
                                            <Text type="secondary">Fecha Entrega</Text>
                                        </Col>
                                        <Col span={2} className="center">
                                            <Text type="secondary">Estatus</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text type="secondary">ACCIONES</Text>
                                        </Col>
                                    </Row>
                                }
                                renderItem={(item, index) => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row style={{ width: '100%' }} className="row-itemList-ordenesMaterial">
                                                <Col span={3} className="center">
                                                    <Text strong>{item.folio_id}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Avatar.Group>
                                                        {item.proyectos_ids.map((p, index) => <CustomAvatar image={p.logo} name={p.nombre} color={p.color} style={{ marginRight: '5px' }} />)}
                                                    </Avatar.Group>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Text strong>{item.piezas ? item.piezas.toMoney() : 0}</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Text strong>{item.toneladas ? item.toneladas.toMoney() : 0}</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Text strong>{moment(item.fecha_creacion).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Text strong>{moment(item.fecha_entrega).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col span={2} className="center">
                                                    {this.renderEstatus(item.estatus)}
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Button type="primary" className="button-survey btn-eye button-eye"
                                                        onClick={(e) => { this.setState({ registroId: item._id, ModalOrden: true }) }}
                                                        title="Ver detalle">
                                                        <IconEye />
                                                    </Button>
                                                    <Link to={`/admin/ordenes/${item._id}`}>
                                                        <Button className="btn btn-edit" title="Editar" >
                                                            <IconEdit />
                                                        </Button>
                                                    </Link>
                                                    <Button
                                                        className={`btn ${item.qrs_cantidad ? 'btn-printed-qr' : 'btn-edit'}`}
                                                        title="QR"
                                                        disabled={(item.estatus === 1 || item.qr_status === true)}
                                                        onClick={(e) => this.abrirModalQR(item._id, item.nombre)}
                                                    >
                                                        <IconQR />
                                                    </Button>
                                                    {
                                                        user?.tipo === 1 ?
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title={"¿Deseas eliminar la Orden ?"}
                                                                onCancel={e => e.stopPropagation()}
                                                                okText="Si"
                                                                cancelText="No"
                                                                onConfirm={e => this.deleteOrden(item._id)}

                                                            >
                                                                <Button className="btn btn-delete"
                                                                    onClick={e => e.stopPropagation()}
                                                                >
                                                                    <IconDelete title="Eliminar" />
                                                                </Button>
                                                            </Popconfirm>
                                                            :
                                                            <Button disabled={true} className="btn btn-delete"> <IconDelete /> </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </section>
                <Row className="mt-2 pb-3 pl-1">
                    <Pagination
                        current={this.state.currentPage}
                        total={this.state.itemCount}
                        onChange={(page) => {
                            this.getOrdenes(page)
                        }}
                    />
                </Row>
                <Link to={"/admin/ordenes/nuevo"}>
                    <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear}>
                        <IconPlus />
                    </Button>
                </Link>
                <ModalTicketQr
                    modalVisible={this.state.ModalQr}
                    closeMethod={this.hideModal}
                    orden_id={this.state.registroId}
                />
                <ModalOrdenMaterial
                    modalVisible={this.state.ModalOrden}
                    accionModal={'detalle'}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                />
            </div>
        )
    }
}
