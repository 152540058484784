import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Divider, Spin, DatePicker, Checkbox } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar'
import ModalTicketQr from '../ImpresionQR'
import PrioridadSelect from '../../../Widgets/prioridadSelect'
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'
import GenerarEtiquetas from "../ImpresionQR";

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment')

/**
 *
 *
 * @class ModalEntrega
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalEntrega extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            requisicion: {}
        }
    }

    /**
     *
     * @methodOf ModalEntrega
     * @function componentDidMount
     * @description Obtiene la informacion inicial
     */
    componentDidMount() {
        if (this.props.requisicion_id)
            this.getRequisicion()
    }

    /**
     *
     * @methodOf ModalEntrega
     * @function getRequisicion
     * @description Obtiene la informacion de una requisicion
     */
    getRequisicion = () => {
        this.setState({ loading: true })
        axios.get('/requisiciones/get', {
            params: {
                requisicion_id: this.props.requisicion_id,
                historial: true
            }
        }).then(response => {

            let requisicion = response.data.data, cantidad = 0

            let volumen = requisicion.requisicion_opcion_id?.volumen_final ? requisicion.requisicion_opcion_id?.volumen_final : 0
            if (response.data.historial.length > 0) {
                if (this.props.estatus === 4 && response.data.historial[0]?.tipo === 0)
                    cantidad = response.data.historial[0].total
                else if (this.props.estatus === 5 && response.data.historial[1]?.tipo === 1)
                    cantidad = response.data.historial[1]?.total

            }

            this.setState({
                requisicion: response.data.data,
                cantidad,
                max: volumen - cantidad
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los datos')
        }).finally(() => this.setState({ loading: false }))

    }


    /**
     *
     * @methodOf ModalEntrega
     * @function onFinish
     * @description Obtiene la informacion de una requisicion
     */
    onFinish = (values) => {
        axios.post('/requisiciones/entregas', {
            ...values,
            requisicion_id: this.props.requisicion_id
        }).then(response => {
            message.success('Material entregado')
            this.props.onCancel(true)
        }).catch(error => {
            console.log("error", error);
            message.error('Error al entregar el matarial')
        })
    }




    render() {

        const { requisicion, cantidad, max } = this.state

        return <div style={{ padding: "1em" }}>

            <div className="modal-header">
                <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title">{this.props.estatus === 4 ? 'Entrega' : 'Recolección'} de Materiales</Title>
            </div>
            <Spin spinning={this.state.loading}>
                <Row align="center"  gutter={[16, 16]}>
                    <Col span={14}>
                        <Title level={5}>Volumen Solicitado:</Title>
                    </Col>
                    <Col span={10} align="end">
                        <Text strong>{requisicion.requisicion_opcion_id?.volumen_final - cantidad} {requisicion.producto_id?.unidad?.toUnidad()}</Text>
                    </Col>
                    <Col span={14}>
                        <Title level={5}>{this.props.estatus === 4 ? 'Volumen Entregado:' : 'Volumen Recolectado:'}</Title>
                    </Col>
                    <Col span={10} align="end">
                        <Text strong> {cantidad} {requisicion.producto_id?.unidad?.toUnidad()}</Text>
                    </Col>
                </Row>
                <Form
                    layout="vertical"
                    // className="frm-cuentas"
                    // name="form-materiales"
                    ref={this.modalMateriales}
                    onFinish={this.onFinish}
                    initialValues={{
                        fecha: moment()
                    }}
                    onValuesChange={formValues => this.setState({ formValues })}

                >
                    <Row align="center" gutter={[16, 16]}>

                        <Col span={12}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[{
                                    required: true,
                                    message: "Por favor, la cantidad entregada"
                                }]}
                            >
                                <InputNumber min={0} max={max} className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Fecha de Entrega"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha de entrega."
                                }]}
                            >
                                <DatePicker  className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="etiquetas"
                            >
                                <Checkbox onChange={e => this.setState({ etiquetasQR: e.target.checked })} >¿Deseas generar las etiquetas de producto?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {this.state.etiquetasQR ? <GenerarEtiquetas
                        requisicion={this.state.requisicion}
                        cantidad={this.state.cantidad}
                        max={this.state.max}
                        formValues={this.state.formValues} /> : null}
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </div>
    }
}



export default function (props) {

    const { visible, onCancel } = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}


    >
        <ModalEntrega {...props} />
    </Modal>


}
