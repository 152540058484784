import React, { Component, useState } from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { Layout } from 'antd';
import 'antd/dist/antd.css'
import './Styles/Global.css';
import PublicRoutes from "./Routes/PublicRoutes";
import AuthVerify from "./Routes/Middleware/AuthVerify";
import AdminRoutes from "./Routes/AdminRoutes";
import Cookies from 'universal-cookie';
import axios from 'axios'
import Logged from './Hooks/Logged'


const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        AuthVerify === true
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />
);


class App extends Component {


    constructor(props) {
        super(props);

        this.state = {
            user: 0
            //0 => Se está esperando por una sesion,
            //Undeinfed =>  no hay sesion
            //Object => hay una sesion

        }
    }

    componentDidMount() {
        const cookies = new Cookies();
        const tokenCookie = cookies.get('token');
        if (sessionStorage.getItem('token') === undefined && tokenCookie !== undefined)
            sessionStorage.setItem('token', tokenCookie);
        //Obtenemos la información del usuario que tiene un token guardado
        axios.get('/user/logged', {
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(({ data }) => this.setUser(data.data))
            .catch((error) => {
            })
    }

    setUser = (user) => {
        this.setState({ user })
    };

   

    render() {
        const { setUser,getUser } = this;
        const { user } = this.state;

        return (
            <BrowserRouter>

                <Logged.Provider value={user}>
                        <PrivateRoute path='/admin'>
                            <AdminRoutes setUser={setUser}  user={user}/>
                        </PrivateRoute>
                        <PublicRoutes setUser={setUser}  user={user}/>
                </Logged.Provider>
            </BrowserRouter>

        );
    }


}

export default (App);
