import React, { Component } from 'react';
import { Button, Checkbox, Col, DatePicker, Dropdown, Form, Menu, Radio, Row, Select, Card, Statistic, Typography, List, Avatar } from 'antd'
import { GraficaCategorias, GraficaEgresos, GraficaIngresos, GraficaReve, GraficaSaldos } from '../Widgets/graficas'
import { FaArrowRight } from "react-icons/fa";

import { Link } from 'react-router-dom';
import { IconPlus, IconArrowCustom as Arrow, IconDownloadCustom as Download } from '../Widgets/Iconos';
import axios from 'axios';
import CustomAvatar from '../Widgets/Avatar/Avatar';


import CardsCuentas from './DashboardWidgets/Cuentas';
import CardsEgresosProyecto from './DashboardWidgets/EgresosProyecto';
import CardsRubros from './DashboardWidgets/Rubros';
import CardsIngresosPorProyecto from './DashboardWidgets/IngresosPorProyecto';



import './../../Styles/dashboard.css'




const { Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * 
 * @description Vista del dashboard
 */
export default class Dashboard extends Component {

    /**
     *
     *
     * @state values Objeto de filtros
     * @state update Actualización del valor update
     */
    state = {
        values: {},
        update: false
    }

    /**
     *
     *
     * @param {*} valuesChanges
     * @param {*} values
     * @memberof Dashboard
     * 
     * @description Si se cambia cualquier valor en el formulario de antd, se actualizan los campos y se ordena la actualización
     */
    onValuesChange = (valuesChanges, values) => this.setState({ values: this.ref.current.getFieldsValue(), update: !this.state.update })
    
    /**
     *
     *
     * @memberof Dashboard
     * 
     * @description Referencia al formulario
     */
    ref = React.createRef()
    
    render() {

        return (
            <div style={{ height: 'calc(100% - 75px)' }}>
                <Form ref={this.ref} onValuesChange={this.onValuesChange}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={7} xxl={10} className="flex-left">
                            <Title level={4} className="view-title">Dashboard</Title>
                        </Col>
                        <Col xs={24} xl={17} xxl={14} style={{ paddingTop: '10px' }} className="center">
                            <Row style={{ width: '100%' }} gutter={[10, 10]} justify="end">
                                <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="fechas"
                                        noStyle>
                                        <RangePicker
                                            className="input-picker"
                                            style={{
                                                background: 'transparent !important',
                                                color: '#FFF'
                                            }} />
                                    </Form.Item>
                                </Col>
                                <Col span={1} >
                                    <Button type="link" size="small"
                                        style={{ position: 'relative', top: '0.4em', right: '0.5em' }}>
                                        <Download />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <section className="p-1 main-dashboard" style={{ height: '100%' }}>
                    <div className="main-background" style={{ backgroundImage: 'url(/images/dashboard_background.jpg)' }}></div>
                    <Row style={{ width: '100%', marginBottom: 25 }} gutter={[25, 25]}>
                        <Col xl={8} md={12} sm={12}>
                            <CardsCuentas filters={this.state.values}  />
                        </Col>
                        <Col xl={8} md={12} sm={12}>
                            <CardsEgresosProyecto filters={this.state.values} update={this.state.update} />
                        </Col>

                        <Col xl={8} md={12} sm={12}>
                            <CardsRubros filters={this.state.values} update={this.state.update} />
                        </Col>
                    </Row>
                    <Row style={{ width: '100%' }} gutter={[25, 25]}>
                        <Col span={24}>
                            <Card className="card-dashboard">
                                <CardsIngresosPorProyecto filters={this.state.values} update={this.state.update} />
                            </Card>
                        </Col>
                    </Row>
                </section>
            </div>
        )
    }
}
