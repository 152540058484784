import React, { Component } from 'react';
import {
    Button, Col, Row, message, Typography,
    Pagination, List, Card, Popconfirm,
    Spin, Collapse, Empty
} from 'antd'
import { IconEdit, IconDelete, IconPlus, IconPlusGroup } from '../../Widgets/Iconos';

import ModalActividades from '../Modales/Actividades/ModalActividad';
import ModalAnexarTajo from "./ModalAnexarTajo";
import '../../../Styles/projectManager.css'

const axios = require('axios').default;
const { Text, Title, } = Typography;
const { Panel } = Collapse;


export default class Actividades extends Component {


    constructor(props) {
        super(props)
        this.state = {

            visible: false,
            visibleTajo: false,

            filtroSearch: "",


            dataActividades: [],
            loading: false,
            accionModal: 'Crear',
            selected_actividad_id: '',
            selected_actividad_nombre: '',


            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,




            data: [],
            page: 1,
            limit: 100,
            loading: false,
            itemCount: 0,
            tipo: null,
        }
    }


    /**
     *
     *
     * @memberof Actividades
     * @method componentDidMount
     * @description Manda a llamar al metodo getActividades para cargar la lista de actividades
     */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getActividades(1);
    }


    /**
     *
     *
     * @memberof Actividades
     *
     * @method componentDidUpdate
     * @description actualiza la vista si se ha realizado una busqueda
     */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.state.searching = false;
            this.getActividades();
        }
    }



    /**
     *
     *
     * @memberof Actividades
     * @method getActividades
     * @description Manda la peticion al WebService para traer la lista de actividades
     */
    getActividades = async (page, limit = this.state.perPage) => {
        var search = this.props.search;
        this.setState({ loading: true });
        axios.post("/actividades/list", {
            page,
            limit,
            search: search
        })
            .then(res => {
                console.log("res")
                console.log(res)

                this.setState({
                    dataActividades: res.data.data.itemsList,
                    itemCount: res.data.data.paginator.itemCount,
                    currentPage: res.data.data.paginator.currentPage,
                    pageCount: res.data.data.paginator.pageCount,
                    filtroSearch: search
                })
                this.state.searching = true;
            })
            .then(() => {
                //Si se elimina el utlimo registro de una pagina, te manda a la primera pagina
                if (this.state.currentPage > this.state.pageCount) {
                    this.getActividades(1)
                }
            })
            .catch(e =>
                console.log('error actividad get', e)
            )
            .finally(f => {
                this.setState({ loading: false })
            })


    }


    /**
    * @memberof Actividades
    *
    * @method   hideModal
    * @description  Cambia state para cerrar cualquier modal
    *
    **/
    hideModal = () => {

        this.setState({
            selected_actividad_id: '',
            selected_actividad_nombre: '',
            visible: false,
            visibleTajo: false
        });

        this.getActividades(1);
    }


    /**
    * @memberof Actividades
    * @method   modalCrear
    * @description Abrir modal tipo creación para nuevo registro.
    *
    **/
    modalCrear = () => {
        this.setState({
            accionModal: 'Crear',
            selected_actividad_id: undefined,
            selected_actividad_nombre: undefined,
            visible: true,

        })
    }



    /**
    * @memberof Actividades
    * @method   modalEditar
    * @description Abrir modal tipo edición para registro seleccionado desde tabla.
    * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
    */
    modalEditar = (id, nombre) => {

        this.setState({
            accionModal: 'Editar',
            selected_actividad_id: id,
            selected_actividad_nombre: nombre,
            visible: true,
        })

    }



    /**
    * @memberof Actividades
    * @method   deleteActividades
    * @description Elimina un elemento de la tabla
    * @param id Elemento seleccionado para eliminar
    */
    deleteActividades = (id) => {
        this.setState({ loading: true })
        axios.post('/actividades/delete', { id })
            .then((res) => {
                message.success("¡Se eliminó Actividad!");
                this.getActividades(this.state.currentPage)
            })
            .catch(res => {
                message.error("Error, no se pudo eliminar actividad");
                console.log("error", res);
            });

    }



    /**
   * @memberof Actividades
   * @method   agregarTajo
   * @description Asigna tajos a una actividad
   * @param id Elemento seleccionado de actividad
   */
    agregarTajo(id, nombre) {
        console.log('Wenas')
        console.log(id)
        console.log(nombre)


        this.setState({
            selected_actividad_id: id,
            selected_actividad_nombre: nombre,
            visibleTajo: true,

        })


    }



    /**
   * @memberof Actividades
   * @method   deleteTajoActividad
   * @description Elimina un elemento de la tabla
   * @param id Elemento seleccionado para eliminar
   */
    deleteTajoActividad = (id, act_id) => {
        console.log("delete")
        console.log(id)
        console.log(act_id)

        //   this.setState({ loading: true })
        axios.put('/actividades/delete/tajos',
            {
                tajo_id: id,
                actividad_id: act_id
            })
            .then((res) => {
                console.log(res);

                message.success("¡Se eliminó Tajo!");
                this.getActividades(1)
            })
            .catch(res => {
                message.error("Error, no se pudo eliminar tajo");
                console.log("error", res);
            });

    }

    render() {

        const {
            frecuenciaDropdown,
            modalidadesDropdown,

            modalGroup,
            modalGroupId,
            modalEmployee,
            modalTipo,

            data,
            loading,

            tipo,
            itemCount,
            page,
            count
        } = this.state;

        const {
            handleVisibleChange,
            showModal,
            handleCancel,
            addEmployee,
            deleteGroup,
            editGroup,
            getGroups
        } = this;

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Title level={4} className="view-title">Actividades</Title>
                    </Col>
                </Row>
                <section className="p-1">

                    <Row style={{ padding: '1rem' }} className="content">
                        <Spin spinning={loading}>
                            <Row className="list-container-act scroll" gutter={[32, 32]} style={{ width: '100%', overflow: "auto" }}>
                                <Col span={24} style={{ paddingTop: 0 }}>
                                    <Collapse
                                        bordered={false}
                                        expandIcon={({ isActive }) => <div>
                                            <div className={"plus_icon" + (isActive ? " open" : "")} />
                                        </div>}
                                        className="group-collapse group-collapse-act"
                                        style={{ minWidth: "100px !important" }}
                                    >
                                        {this.state.dataActividades.map(({ _id, nombre, empleados, tajos, frecuencia, fecha_nomina, modalidad, responsable_id, precio_m2, area_id, inmueble_id, proyecto_id }, index) => {
                                            var act_nombre = nombre, act_id = _id;
                                            return <Panel header={<Row style={{ width: '100%' }} key={index}>
                                                <Col xs={18} xl={20} xxl={22} className="" >
                                                    <Row>
                                                        <Col span={18} className="center-left"
                                                            style={{ marginLeft: "18px" }}
                                                        >
                                                            <Title className="group-title ">{act_nombre}</Title>
                                                        </Col>

                                                        <Col span={5} className="center-left"
                                                        >
                                                            <Title className="group-title">{"Tajos : " + (tajos.length)}</Title>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col xs={6} xl={4} xxl={2} className="center " style={{ padding: "0px" }} >
                                                    <Button className="button button-plus" title="Anexar tajo" onClick={e => {
                                                        e.stopPropagation();
                                                        this.agregarTajo(_id)
                                                    }}>
                                                        <IconPlusGroup />
                                                    </Button>

                                                    <Button className="btn btn-edit" title="Editar"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.modalEditar(_id, act_nombre)
                                                        }}>
                                                        <IconEdit />
                                                    </Button>

                                                    <Popconfirm
                                                        placement="topRight"
                                                        title={
                                                            (tajos.length > 0) ? "Estas apunto de eliminar una actividad con tajos asignados, ¿seguro que quieres continuar?"
                                                                :
                                                                "¿Deseas eliminar la Actividad " + act_nombre + " ? "
                                                        }
                                                        descripcion={(tajos.length > 0) ? "Estas apunto de eliminar una actividad con tajos asignados, seguro que quieres continuar?" : ""}
                                                        onConfirm={e => { }}
                                                        onCancel={e => e.stopPropagation()}
                                                        okText="Si"
                                                        cancelText="No"
                                                        onConfirm={e => { e.stopPropagation(); this.deleteActividades(_id) }}

                                                    >
                                                        <Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
                                                            <IconDelete title="Eliminar" />
                                                        </Button>
                                                    </Popconfirm>


                                                </Col>

                                            </Row>} className="group-collapse-panel" onClick={e => e.stopPropagation()}>

                                                {(tajos.length > 0) ?
                                                    <div style={{ padding: "0px" }}>
                                                        <Col span={24} style={{ padding: "0px 16px", marginTop: "5px" }} >
                                                            <Title style={{ color: "#0047FF", marginLeft: "1em", fontSize: "15pt", padding: "0px" }} level={3}>Tajos</Title>
                                                        </Col>

                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={tajos}
                                                            className="list-employees"
                                                            renderItem={({ _id, nombre, descripcion }, index) => (
                                                                <List.Item
                                                                    style={{ width: "90%", borderBottom: "solid 1px rgb(234 234 234);" }}>

                                                                    <Row style={{ width: '100%' }}>
                                                                        <Col span={2}>
                                                                            <strong> {index + 1} </strong>
                                                                        </Col>

                                                                        <Col span={12}>
                                                                            {(_id) ? <strong> {nombre} </strong> : <strong>Tajo Inexistente</strong>}
                                                                        </Col>
                                                                        <Col xs={8} xl={9}>
                                                                            <strong> {descripcion} </strong>
                                                                        </Col>

                                                                        <Col span={1}>
                                                                            <Popconfirm
                                                                                placement="topRight"
                                                                                title={"¿Deseas eliminar la tajo " + nombre + " de actividad " + act_nombre + " ?"}
                                                                                onConfirm={e => { }}
                                                                                onCancel={e => e.stopPropagation()}
                                                                                okText="Si"
                                                                                cancelText="No"
                                                                                onConfirm={e => { e.stopPropagation(); this.deleteTajoActividad(_id, act_id) }}

                                                                            >
                                                                                <Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
                                                                                    <IconDelete title="Eliminar" />
                                                                                </Button>
                                                                            </Popconfirm>

                                                                        </Col>
                                                                    </Row>
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </div>

                                                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "0px" }} description={"Sin tajos"} />}
                                            </Panel>
                                        })}
                                    </Collapse>

                                </Col>

                            </Row>
                            <Pagination
                                current={this.state.currentPage}
                                total={this.state.itemCount}
                                showSizeChanger={false}
                                onChange={(page) => {
                                    this.getActividades(page)
                                }}
                            />
                        </Spin>
                    </Row>

                </section>

                <section className="p-1">

                    <Row gutter={[16, 24]}>
                        <Col xs={24} lg={4} className="gutter-row">
                            <Text className="ml-2" strong>#</Text>
                        </Col>
                        <Col xs={24} lg={14} className="gutter-row">
                            <Text strong>NOMBRE</Text>
                        </Col>
                        <Col xs={24} lg={6} className="gutter-row center">
                            <Text strong>ACCIONES</Text>
                        </Col>
                    </Row>

                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.dataActividades}
                        renderItem={(item, i) => (

                            <List.Item className="component-list-item" key={item._id}>
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">
                                        <Col span={4} className="flex-left">
                                            <Text strong> {i + 1}</Text>
                                        </Col>
                                        <Col span={16} className="flex-left">
                                            <Text strong>{item.nombre}</Text>
                                        </Col>
                                        <Col span={4} className="center">

                                            <Button className="btn btn-edit" title="Editar" onClick={(e) => this.modalEditar(item._id, item.nombre)}>
                                                <IconEdit />
                                            </Button>

                                            <Popconfirm
                                                placement="topRight"
                                                title={"¿Deseas eliminar la Actividad " + item.nombre + " ?"}
                                                onConfirm={e => { }}
                                                onCancel={e => e.stopPropagation()}
                                                okText="Si"
                                                cancelText="No"
                                                onConfirm={e => this.deleteActividades(item._id)}

                                            >
                                                <Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
                                                    <IconDelete title="Eliminar" />
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.currentPage}
                            total={this.state.itemCount}
                            showSizeChanger={false}
                            onChange={(page) => {
                                this.getActividades(page)
                            }}
                        />
                    </Row>
                </section>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} style={{ padding: "20px 20px" }}>
                    <IconPlus />
                </Button>

                <ModalActividades
                    modalVisible={this.state.visible}
                    closeMethod={this.hideModal}
                    accionModal={this.state.accionModal}
                    id={this.state.selected_actividad_id}
                    nombre_actividad={this.state.selected_actividad_nombre}
                    nombreView={this.state.accionModal}

                    accept={() => {
                        this.getActividades(this.state.currentPage);
                        this.hideModal();
                    }}
                />

                <ModalAnexarTajo
                    visible={this.state.visibleTajo}
                    id={this.state.selected_actividad_id}
                    nombre_actividad={this.state.selected_actividad_nombre}

                    closeMethod={this.hideModal}
                    hideModal={() => this.hideModal()}
                    accept={() => {
                        this.getActividades(this.state.currentPage);
                        this.hideModal();
                    }}


                />
            </div>
        )
    }
}
