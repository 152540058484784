import React, { Component } from 'react';
import { Button, Checkbox, Col, DatePicker, Dropdown, Spin, Menu, Radio, Row, Select, Card, Statistic, Typography, List, Avatar } from 'antd'

import echarts from 'echarts';

import ReactEcharts from 'echarts-for-react';
import axios from 'axios';


const { Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;


export default class GraficaNominasPorGrupos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            inversiones:[],
            acreedores:[],
            // nomina_id: null
        }
    }

    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
    
    }





    render() {


        return (
            <Spin spinning={false}>
                <ReactEcharts
                    option={
                        {
                          xAxis: {
                            type: 'category',
                            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                          },
                          yAxis: {
                            type: 'value'
                          },
                          series: [
                            {
                              data: [150, 230, 224, 218, 135, 147, 260],
                              type: 'line'
                            }
                          ]
                        }
                    }
                    style={{ height: '400px', width: '100%' }}
                    opts={{ renderer: 'svg' }}
                    className='react_for_echarts' />
            </Spin>
        )
    }
}


