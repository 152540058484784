import React, { Component } from "react";
import {
    Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List,
    message, Divider, Upload, Spin
} from 'antd';

import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;

/**
 *
 *
 * @class ModalMateriales
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalTipo extends Component {

    tipoRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            folio: null
        }
    }


    /**
    * @memberof ModalMateriales
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTipo();

        setTimeout(() => {
            this.setState({ loading: false });
        }, 500);

    }


    getTipo = async () => {
        if (this.props.tipo_id !== undefined && this.props.tipo_id !== null) {
            axios.get('/tipos/get', {
                params: {
                    id: this.props.tipo_id
                }
            })
                .then(({ data }) => {
                    if (data.data !== null) {
                        let cmp = [];
                        if (data.data.imagen !== "" && data.data.imagen !== undefined && data.data.imagen !== null)

                            cmp.push({
                                name: data.data.imagen
                            });

                        setTimeout(() => {
                            this.modalMateriales.setFieldsValue(
                                {
                                    detalles: data.data.detalles,
                                    imagen: cmp,
                                    nombre: data.data.nombre,
                                    unidad: data.data.unidad
                                });
                        }, 500);
                    }
                })
        }
    }



    /**
     * @memberof ModalTipo
     * @method   onFinish
     * @description  Al completar formulario :
     * Se valida el tipo de accion modal para crear nuevo material o editar el registro seleccionado
     **/
    onFinish = async (values) => {

        let cmp = ""

        if (Array.isArray(values.imagen) && values.imagen.length > 0) {
            cmp = values.imagen[0].name;
        }


        if (this.props.tipo_id !== null && this.props.tipo_id !== undefined)
            axios.put('/tipos/update', {
                nombre: values.nombre,
                detalles: values.detalles,
                unidad: values.unidad,
                imagen: cmp,
                id: this.props.tipo_id
            })
                .then((res) => {
                    message.success("Se ha actualizado correctamente!");
                    this.props.onSuccess()

                })
                .catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : <div>
                            Error al procesar registro. Verifique la información.
                            <br />
                            <p>
                                El campo nombre de material es unico e irrepetible.
                            </p>
                        </div>
                    });
                });

        else axios.post('/tipos/add',
            {
                nombre: values.nombre,
                detalles: values.detalles,
                unidad: values.unidad,
                imagen: cmp,
            })
            .then((res) => {
                message.success("¡Se ha registrado correctamente!")
                this.props.onSuccess()
            })
            .catch((error) => {
                Modal.warning({
                    title: 'Información de Registro Incorrecta',
                });
            });
    }



    /**
   *
   * @memberof ModalTipo
   *
   * @method normFile
   * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
   *
   * @param images (string)
   * Recibe el nombre de la imagen.
   */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
                fileList[x].url = `${axios.defaults.baseURL}/upload/${fileList[x].response.filename}`
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };



    /**
     *
     * @memberof ModalTipo
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {

        axios.post("/upload/delete", {
            filename: image
        }).then(res => {
            console.log("imagen removida con éxito", res);
        }).catch(res => {
            console.log("imagen no se puedo remover", res);
        })
    };


    render() {

        return [
            <Spin spinning={this.state.loading} >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title"> {(this.props.tipo_id !== null && this.props.tipo_id !== undefined) ? "Editar" : "Nuevo"} Tipo</Title>
                </div>,
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={ref => this.modalMateriales = ref}
                    onFinish={this.onFinish}

                >
                    <Row align="center">
                        <Col span={20}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                            >
                                <Input placeholder="Nombre del Tipo de Material" />
                            </Form.Item>
                        </Col>


                        <Col span={20}>
                            <Form.Item
                                label="Detalles"
                                name="detalles" //<--------------------------------------- detalles
                                rules={[{
                                    required: false,
                                    message: "Por favor, ingrese detalles de material"
                                }]}
                            >
                                <Input.TextArea placeholder="Detalles" />
                            </Form.Item>
                        </Col>


                        <Col span={20}>
                            <Form.Item
                                label="Unidad"
                                name="unidad" //<--------------------------------------- unidad
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la unidad de medida del material"
                                }]}
                            >
                                <Select
                                    clearIcon={<IconArrowSelect />}
                                    placeholder={"Unidad"}
                                    showArrow={false}
                                    filterOption={false}
                                >
                                    <Option value={0}>Piezas</Option>
                                    <Option value={1}>Toneladas</Option>

                                </Select>
                            </Form.Item>
                        </Col>



                        <Col span={20}>
                            <Form.Item
                                label="Imagen"
                                name="imagen"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    multiple={false}
                                    maxCount={1}
                                >
                                    <Button>
                                        <UploadOutlined /> Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Spin >
        ]
    }
}



export default function (props) {
    return <Modal
        visible={props.modalVisible}
        onCancel={props.onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}
    >

        <ModalTipo {...props} />
    </Modal>


}
