import React, { Component } from "react";
import { Avatar, Button, Col, DatePicker, Form, List, Input, InputNumber, message, Modal, Row, Select, Spin, Typography, Statistic } from 'antd';
import PropTypes from "prop-types";
//componentes
import { IconArrowSelect, IconCloseModal, IconDelete, IconEdit } from '../../../Widgets/Iconos';
import { MyUpload } from './../../../Widgets/Upload';
//css
import './../../../../Styles/finanzas.css'

const moment = require('moment');
const axios = require('axios').default;
const { Option } = Select;
const { Title, } = Typography;
var contConsulta = 0;

class ModalTransaccionProgramada extends Component {



    /**
     *
     * @param {*} props
     * @memberof ModalTransaccionProgramada

     */
    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            dataCuentas: [],
        }
    }

    //Referencia de form
    modalTranProgramadaRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCuentas();
    }



    /**
     *
     *
     * @memberof ModalTransaccionProgramada
     * 
     * @function getDatosDelCliente
     * @description Obtenemos las cuenta para el select.
     */
    getCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                paginate: false
            }
        }).then((cuentas) => {
            this.setState({
                dataCuentas: cuentas.data.data.itemsList
            })
        }).catch((error) => {

        })
    }
    
    /**
     *
     *
     * @memberof ModalTransaccionProgramada
     * 
     * @function onFinish
     * @description Guarda o edita la transacción segun corresponda. 
     */
    onFinish = async values => {   
        const { accionModal, propiedad } = this.props;

        if (accionModal === 'editar') {
            axios.post('/clientes/update/transaccionProgramada', {
                propiedad_id: this.props.propiedad._id,
                _id: values._id,
                concepto: values.concepto,
                cuenta_id: values.cuenta_id,
                monto: values.monto,
                fecha: values.fecha,
            }).then((res) => {
                message.success("Transaccion actualizada.");
                this.props.hideModal('t_programadas');
            }).catch((error) => {
                message.error('Error al actualizar la transaccion')
            });

        } else {
            axios.post('/clientes/add/transaccionProgramada', {
                propiedad_id: this.props.propiedad._id,
                concepto: values.concepto,
                cuenta_id: values.cuenta_id,
                monto: values.monto,
                fecha: values.fecha,

                proyecto_id: propiedad.proyecto_id._id,
                usuario_id: propiedad.asesor_id._id,
                area_id: propiedad.area_id._id,
                rubro_id: propiedad.rubro_id._id,
                sub_rubro_id: propiedad.subrubro_id._id,
                tipo: 1,
                tipo_cliente: 'Transaccion',
            }).then((res) => {
                message.success("Transaccion guardada.");
                this.props.hideModal();
            }).catch((error) => {
                console.log(error)
                message.error('Error al guardar las transaccion')
            });

        }

    }

    render() {

        const { visible, hideModal,  detalle, transaccionProgramada } = this.props;
        return (
            <Modal
                visible={visible}
                onCancel={hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Transacción programada</Title>
                </div>
                {/* Si el detalle viene true, ponemos el formulario de información, caso contrario, solo el formulario de editar.  */}
                {(detalle === true) ?
                    <Form
                        layout="vertical"
                        ref={this.modalTranProgramadaRef}
                        className="frm-transacciones"
                        name="formulario-transacciones"

                    >
                        <Row>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Cuenta</label>
                                <p className="pInfo">{transaccionProgramada.cuenta_id.nombre}</p>
                            </Col>

                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Fecha</label>
                                <p className="pInfo">{moment(transaccionProgramada.fecha).format('DD-MM-YYYY')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Proyecto</label>
                                <p className="pInfo">{(transaccionProgramada.proyecto_id)?transaccionProgramada.proyecto_id.nombre:null}</p>
                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                                <label className="lblTitulo">Area</label>
                                <p className="pInfo">{(transaccionProgramada.area_id)?transaccionProgramada.area_id.nombre:null}</p>
                            </Col>

                        </Row>
                        <Row style={{ margin: '0' }}>
                            <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                                <label className="lblTitulo">Rubro</label>
                                <p className="pInfo">{(transaccionProgramada.rubro_id)?transaccionProgramada.rubro_id.nombre:null}</p>
                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                                <label className="lblTitulo">Subrubro</label>
                                <p className="pInfo">{(transaccionProgramada.sub_rubro_id)?transaccionProgramada.sub_rubro_id.nombre:null}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Tipo</label>
                                <p className="pInfo">{'Ingreso'}</p>

                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Monto</label>
                                <p className="pInfo">
                                    <Statistic
                                        value={transaccionProgramada.monto}
                                        valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF19' }}
                                        precision={2}

                                        prefix={'$'}
                                        suffix={'MXN'}
                                    />

                                </p>
                            </Col>
                        </Row>

                        <Row style={{ textAlign: "" }}>
                            <Col span={24} className="readOnlyFiles">
                                <label className="lblTitulo">Comprobantes</label>
                                <MyUpload
                                    tipo="detalle"
                                    fileList={Array.isArray(transaccionProgramada.comprobantes)?transaccionProgramada.comprobantes.map((imagen, index) => new Object({
                                        uid: index * -1,
                                        name: imagen,
                                        status: 'done',
                                        url: axios.defaults.baseURL + '/voucher/' + imagen,
                                    })):null}
                                />
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>

                            <span className="item-divider"></span>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <div className="div-titulo">
                                    <h2 className="titulo-divCont">Autor</h2>
                                </div>
                                <Row className="row-transaccion">
                                    <Col>
                                        <Avatar />
                                    </Col>
                                    <Col>
                                        <h2>{(transaccionProgramada.usuario_id)?transaccionProgramada.usuario_id.nombre + ' ' + transaccionProgramada.usuario_id.apellido:null}</h2>
                                    </Col>
                                    <Col>
                                        <p>{transaccionProgramada.createdAt?moment(transaccionProgramada.createdAt).format('DD-MM-YYYY'):null}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    :
                    <Form
                        layout="vertical"
                        ref={this.modalTranProgramadaRef}
                        className="frm-transacciones"
                        name="formulario-transacciones"
                        onFinish={this.onFinish}
                        initialValues={this.props.transaccionProgramada}

                    >
                        <Form.Item
                            name="_id"
                            noStyle
                            ><Input type="hidden"></Input></Form.Item>
                        <Row>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    label="Concepto"
                                    name="concepto"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el concepto"
                                    }]}
                                >
                                    <Input placeholder="Concepto"></Input>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <Form.Item
                                    label="Fecha"
                                    name="fecha"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la fecha"
                                    }]}
                                >
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ margin: '0' }}>
                            <Col xs={24} lg={11} style={{ margin: '0' }}>
                                <Form.Item
                                    label="Monto"
                                    name="monto"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese monto"
                                    }]}
                                >
                                    <InputNumber min={0} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                                <Form.Item
                                    label="Cuenta"
                                    name="cuenta_id"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione la cuenta"
                                    }]}
                                >
                                    <Select placeholder="Seleccione cuenta" disabled={this.state.edicion}>
                                        {this.state.dataCuentas.map(function ({ _id, nombre, logo, color }, index) {
                                            return <Option value={_id}>
                                                {nombre}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <IconArrowSelect />
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-azul"
                                        disabled={this.state.loading}>
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                }
            </Modal>
        )
    }
}

export default ModalTransaccionProgramada;
