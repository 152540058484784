import React, { Component } from 'react';
import { Row, Col, Typography, Table, Button, Popconfirm, message, Spin } from 'antd'


import { IconPlus, IconEdit, IconDelete, IconDetails, IconEye } from '../../Widgets/Iconos';

import { Link } from 'react-router-dom';

import Logged from "../../../Hooks/Logged";

import ModalProveedores from '../Modales/Proveedores/ModalProveedor';

const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

const { Title, Paragraph, } = Typography;

export default class Proveedores extends Component {

    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            
            accionModal: 'crear',
            registroId: '',
            modalVisible: false,
            dataProveedores: [],


            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            slNo: 1,
            hasPrevPage: false,
            hasNextPage: false,
            prev: null,
            next: null,

            searching: true,
            filtroSearch: ""
        }
    }

    ModalProveedoresRef = React.createRef();

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Obtenemos el listado de Proveedores
        this.receivedData();
    }


    
    /**
     *
     * @method componentDidUpdate
     * @description Acualiza la vista cuando se busca un elemento en la barra 
     */
    componentDidUpdate() {

        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.receivedData();
            this.state.searching = false;
        }
        if (this.context !==0 && this.state.tipo_user === 0)
            this.setState({
                tipo_user: this.context.tipo
            })
    }

    

    /**
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }


    /**
   * @description Abrir modal tipo edición para registro seleccionado desde tabla.
   * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
   */
    modalEditar = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;

        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisible: true,
        })
    }


    /**
     * @description Cerrar modal y limpiar valor registroId
    */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false,
        })
    }

    /**
        *
        * @memberof Proveedores
        * @method receivedData
        * @description Obtiene la informacion y actualiza los valores locales de la lista.
        */
    receivedData = async (page, size) => {
        this.setState({ loading: true })
        var numPage = (page === undefined) ? 1 : page, numReg = 0, search = this.props.search;

        axios.get('/proveedores/list', {
            params: {
                page: page,
                limit: size,
                area_id: this.props.match.params.id,
                search: search,
                paginate: true
            }
        })
            .then(res => {
                const data = res.data.data.itemsList

                var dataL = data, data_list = [];

                if (numPage === null || numPage === 1) { numReg = 0 }
                else { numReg = ((numPage - 1) * 10) }

                for (let index = 0; index < dataL.length; index++) {
                    const element = dataL[index];
                    numReg = numReg + 1;

                    data_list.push({
                        _id: element._id,
                        alias: element.alias,
                        linea_credito: element.linea_credito,
                        rfc: element.rfc,
                        razon_social: element.razon_social,
                        plazo: element.plazo,
                        index: numReg
                    });
                }


                this.setState({
                    dataProveedores: data_list,
                    loading: false,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: size,
                    pageCount: res.data.data.paginator.pageCount,
                    currentPage: page,
                    slNo: res.data.data.paginator.slNo,
                    hasPrevPage: res.data.data.paginator.hasNextPage,
                    hasNextPage: res.data.data.paginator.hasNextPage,
                    prev: res.data.data.paginator.prev,
                    next: res.data.data.paginator.next,
                    filtroSearch: search,
                    searching: true
                })
            })
            .catch(res => {
                console.log("error", res);
            });
    }


    /**
    *
    * @memberof Proveedores
    * @method eliminar
    * @description Elimina el proveedor
    */
    eliminarProveedor = e => {
        axios.post('/proveedores/delete', {
            id: e
        })
            .then((response) => {
                message.success("Eliminado correctamente.");
                this.receivedData();
            })
            .catch((error) => {
                message.error(error.response.data.message)

            })
    }


    render() {
        const user = this.context;
        const columns = [
            {
                title: '#',
                key: 'index',
                dataIndex: 'index',
                width: '60px'
            },
            {
                title: 'Alias',
                dataIndex: 'alias',
                render: (text, record) => (
                    <h3>{record.alias}</h3>
                ),
            },
            {
                title: 'Razón social',
                dataIndex: 'razon_social',
                render: (text, record) => (
                    <h3>{record.razon_social}</h3>
                ),
            },

            {
                title: 'RFC',
                dataIndex: 'RFC',
                render: (text, record) => (
                    <h3>{record.rfc}</h3>
                ),
            },
            {
                title: 'Linea de credito',
                dataIndex: 'linea_credito',
                render: (text, record) => (
                    <h3>$ {record.linea_credito?.toMoney()} </h3>
                ),
            },
            {
                title: 'Plazo',
                dataIndex: 'plazo',
                render: (text, record) => (
                    <h3>{record.plazo} días</h3>
                ),
            },

            {
                title: 'Acciones',
                key: 'actions',
                width: 180,
                render: (text, record) => (
                    <div className="tbl-opciones">
                        <Link to={`/admin/compras/proveedores/detalle/${record._id}`}>
                            <Button type="primary" className="button-survey btn-eye button-eye"
                                title="Ver detalle">
                                <IconEye />
                            </Button>
                        </Link>
                         
                        {(user.tipo !==3) ? 
                        <div> <Button type="primary" className="button-survey btn-edit button-edit" name={record.alias} id={record._id} onClick={this.modalEditar} title="Editar"  >
                            <IconEdit />
                        </Button>

                            <Popconfirm placement="topRight" title={"¿Eliminar proveedor " + record.alias+" ?"} okText="Si, seguro" cancelText="No" name={record.alias} id={record._id} onConfirm={() => { this.eliminarProveedor(record._id) }} >
                                <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" name={record.alias} title="Eliminar"  >
                                    <IconDelete />
                                </Button>
                            </Popconfirm> </div> : null}
                    </div>
                ),
            },
        ];

        return (
            <div>
                <Spin spinning={this.state.loading}>

                    <Row className="row-encabezado">
                        <Col xs={12} xl={20} xxl={20} className="flex-left">
                            <Title level={4} className="view-title">Proveedores </Title>
                        </Col>

                        <Col xs={12} xl={4} xxl={4} className="flex-right">
                            <Row align="right" style={{ textAlign: "right", display: "block" }}>
                                <Title level={5} style={{ textAlign: "right", color: "#FFF", marginRight: '15px', marginTop: '7px', fontSize: '11pt' }}> {this.state.itemCount}  Proveedores</Title>
                            </Row>
                        </Col>
                    </Row>

                    <section className="p-1">
                        <Row style={{ background: '#FFF' }}>
                            <Table
                                columns={columns}
                                dataSource={this.state.dataProveedores}
                                scroll={{ x: 800, y: 570 }}

                                pagination={{
                                    current: this.state.currentPage,
                                    pageSize: this.state.perPage,
                                    total: this.state.itemCount,
                                    showSizeChanger: true,
                                    position: ['bottomLeft'],
                                }}
                                onChange={({ current, pageSize, total }, b, sort) => {
                                    this.receivedData(current, pageSize)
                                }}

                                className="blankTheme" />
                        </Row>
                    </section>

                    <ModalProveedores
                        ref={this.ModalProveedoresRef}
                        modalVisible={this.state.modalVisible}
                        accionModal={this.state.accionModal}
                        registroId={this.state.registroId}
                        area_id={this.props.match.params.id}
                        closeMethod={this.hideModal}
                        accept={() => {
                            this.hideModal();
                            this.receivedData();
                        }}
                    >
                    </ModalProveedores>
                    {(user.tipo !==3) ? <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                        <IconPlus />
                    </Button> : null}
                </Spin>
            </div>
        )
    }
}
