import React, {Component} from "react";
import { Row, Col, Input, Button, Modal, Form, Typography, message, Spin, InputNumber, DatePicker } from 'antd';
import PropTypes from "prop-types";
import axios from 'axios'
//componentes
import {IconCloseModal,} from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const {Title,} = Typography;
var contConsulta = 0;

export default class ModalCliente extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }

    };


    constructor(props) {
        super(props);
        this.state = {

            loading: false

        }
    }


    //Referencia de form
    ModalClienteRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     * @memberof ModalCliente
     * @function hideModal
     * @description Cierra el modal
     * */
    hideModal = () => {
        this.props.closeMethod();
    }

    /**
     * @memberof ModalCliente
     * @function onFinish
     * @description Se ejecuta al aceptar el formulario 
     * */
    onFinish = (values) => {
        if(this.props.modalTitle === "Editar"){
            this.updateCliente(values)
        }else
            this.addCliente(values)
    }

    /**
     * @memberof ModalCliente
     * @function addCliente
     * @description añade un nuevo Cliente
     * */
    addCliente = (values) => {
        this.setState({loading: true})
        axios.post('/clientes/add',{
            ...values
        }).then(response => {
            message.success('Cliente registrado')
            this.hideModal()
        }).catch(error => {
            console.log(error)
            message.error('Error al registrar')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalCliente
     * @function updateCliente
     * @description añade un nuevo Cliente
     * */
    updateCliente = (values) => {
        this.setState({loading: true})
        axios.post('/clientes/update',{
            ...values,
            id: this.props.cliente._id
        }).then(response => {
            message.success('Cliente actualizado')
            this.hideModal()
        }).catch(error => {
            console.log(error)
            message.error('Error al actualizar')
        }).finally(()=>this.setState({loading: false}))
    }



    render() {

        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                <Spin spinning={this.state.loading}>
                    <div className="modal-header">
                        <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                            <IconCloseModal/>
                        </Button>
                        <Title level={3} className="modal-title">{this.props.modalTitle} Cliente</Title>
                    </div>
                    <Form
                        layout="vertical"
                        ref={this.ModalClienteRef}
                        onFinish={this.onFinish}
                        initialValues={this.props.cliente}
                    >
                        <Row gutter={22} style={{margin: '0px'}}>
                            <Col xs={24} lg={{span: 12}} className="gutter-row">
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"//-----------------------------------------------nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Input placeholder="Nombre"></Input>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={{span: 12}} className="gutter-row">
                                <Form.Item
                                    label="Apellido"
                                    name="apellido"//-----------------------------------------------apellido
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese apellido"
                                    }]}
                                >
                                    <Input placeholder="Apellido"></Input>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={{span: 12}} className="gutter-row">
                                <Form.Item
                                    label="Email"
                                    name="email"//-----------------------------------------------email
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese email"
                                    }]}
                                >
                                    <Input placeholder="Email"></Input>
                                </Form.Item>
                            </Col>


                            <Col xs={24} lg={{span: 12}} className="gutter-row">
                                <Form.Item
                                    label="Teléfono"
                                    name="telefono"//-----------------------------------------------telefono
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese teléfono"
                                    }]}
                                >
                                    <InputNumber placeholder="Teléfono"/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={{span: 12}} className="gutter-row">
                                <Form.Item
                                    label="RFC"
                                    name="rfc"//-----------------------------------------------rfc
                                    rules={[{
                                        pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                                        message: 'RFC invalido'
                                    }]}
                                >
                                    <Input placeholder="RFC" ></Input>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row style={{textAlign: "center"}}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-azul">
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
