import React, { Component } from 'react';
import { List, Button, Col, Row, Card, Typography, Input, Popconfirm, InputNumber, Modal, Spin, message, Tooltip } from 'antd'
import axios from 'axios';

import '../../../../Styles/nominas.css'
import { IconPlus, IconCheckCircle, IconDelete, IconEdit, IconArrowBack } from '../../../Widgets/Iconos';
import ModalCrearInmuebleEmpleado from '../../Modales/Empleados/ModalCrearInmuebleEmpleado';
const { Title, Paragraph, } = Typography;


export default class EmpleadosDetalle extends Component {



    /**
     *
     * @constructor Inicia el componente junto a sus propidades
     *
     * @var nombre Nombre del Grupo
     * @var frecuencia Frecuencia del grupo en cuestión
     * @var modalidad Si es por destajo o por salario
     * @var fecha_nomina Fecha de la nómina
     * @var responsable_id  Información del responsable
     * @var empleados Arreglo de empleados que tiene el grupo
     */
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            loading: false,
            modalCrearvisible: false,
            empleado: {
                usuario_id: {
                    _id: null
                },
                nombre: "",
                apellido: "",
                frecuencia: "",
                modalidad: "",
                fecha_nomina: "",

            },
            inmuebles: [],
            tajos_seleccionados: [] //Tajos que son parte del inmueble para editar
        };
    }


    /**
     * @methodOf EmpleadosDetalle
     * @description componentDidMount
     *
     * @description Obtenemos el grupo de la base de datos
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getEmpleado(this.props.match.params.id)
    }



    /**
     *
     *
     * @memberof EmpleadosDetalle
     * @function getEmpleado
     * 
     * @description Se obtienen los valores del tag
     * 
     * @param id Obtenemos el ID
     */
    getEmpleado = (id) => {
        this.setState({ loading: true })
        axios.get('/empleados/get', {
            params: {
                id
            }
        })
            .then(e => {
                let d = e.data.data
                this.setState({
                    empleado: d,
                    inmuebles: d.inmuebles,
                    loading: false

                })
            }).catch(error => {
                console.log(error)
                this.setState({ loading: false })
            })


    }

    /**
     * @memberof Empleados
     * @method modalCrearEmpleado
     * * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrearInmueble = () => {
        this.setState({
            modalCrearvisible: true,
            tajos_seleccionados: [],
            id: null,
        })
    }



    /**
     * @memberof EmpleadosDetalle
     * @description Envia la informacion actual al modal
     */
    modalEditarInmueble = (inmueble_id, tajos_array) => {
        console.log('modal editar inmueble tajos to load', tajos_array)
        this.setState({
            id: inmueble_id,
            tajos_seleccionados: tajos_array,
            modalCrearvisible: true,
        })
    }



    /**
     * @memberof Empleados
     * @method hideModal
     * @description Cerrar modal y limpiar valor registroId
     */
    hideModal = () => {
        this.setState({
            id: null,
            modalCrearvisible: false,
        })
        this.getEmpleado(this.props.match.params.id)
    }

    /**
     * @memberof EmpleadosDetalle
     * @description Elimina un inmueble de la lista de inmuebles del empleado
     */
    deleteInmueble = (inmueble_id) => {
        this.setState({ loading: true })
        axios.post('/empleados/delete/inmueble', {
            usuario_id: this.props.match.params.id,
            inmueble_id: inmueble_id

        })
            .then(e => {
                this.getEmpleado(this.props.match.params.id)
                message.success(e.data.message)
            })
            .catch(error => {
                Modal.error(error.data.message)
                this.setState({ loading: false })
            })

    }

    render() {

        let { empleado, inmuebles } = this.state;
        const columns = 6


        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={8} xxl={12}>
                        <h2> <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline' }}><IconArrowBack /></Button> Empleado - Inmuebles</h2>
                    </Col>
                </Row>
                <Spin spinning={this.state.loading}>
                    <Row style={{ padding: '24px' }} className="content">
                        <Row gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col xs={24} sm={24} xl={24}>
                                <Card className="content-card">
                                    <Row>
                                        <Col span={18}>
                                            <Title level={3} style={{ color: "#0047FF" }}>{empleado.usuario_id.nombre + ' ' + empleado.usuario_id.apellido}</Title>
                                        </Col>

                                    </Row>
                                    <Row className={"information-widgets-content " + ((empleado.modalidad === 2) ? "information-destajo" : "")}>

                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Inmuebles</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{inmuebles.length}</Paragraph>
                                        </Col>
                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Frecuencia</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{(empleado.frecuencia === 1) ? "Semanal" : "Quincenal"}</Paragraph>
                                        </Col>
                                        <Col span={columns}>
                                            <Paragraph className="information-widgets-content-title">Modalidad</Paragraph>
                                            <Paragraph className="information-widgets-content-description">{(empleado.modalidad === 1) ? "Salario" : "Destajo"}</Paragraph>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="list-container" gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>Inmuebles</Title>
                            </Col>
                            <Col span={24} style={{ paddingTop: 0 }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={inmuebles}
                                    className="list-employees"
                                    renderItem={(item, index) => (


                                        < List.Item className="list-employees-item-content">
                                            <Row className="list-employee-item" style={{ width: '100%' }}>
                                                <Col span={20}>
                                                    {(item.inmueble_id?.nombre) ? (item.inmueble_id?.nombre) : <strong>Inmueble Inexistente</strong>}
                                                </Col>
                                                <Col span={2}>
                                                    <Button onClick={() => this.modalEditarInmueble(item.inmueble_id._id, item.tajos)} className="button-survey btn-edit button-edit" icon={<IconEdit color="white" />} />
                                                    <Popconfirm placement="topRight" title="¿estas seguro de eliminar el inmueble de  este empleado?" okText="Si, seguro" cancelText="No" onConfirm={() => this.deleteInmueble(item.inmueble_id._id)} >
                                                        <Button className="button-survey btn-delete button-delete" icon={<IconDelete color="white" />} />
                                                    </Popconfirm>

                                                </Col>
                                                <Col span={1} offset={1}>
                                                    <Tooltip title={(item.tajos[0]?.porcentaje_avance === undefined) ? "Se activa cuando el porcentaje de avance esta definido" : "El porcentaje de avance esta definido "}>
                                                        <IconCheckCircle color={(item.tajos[0]?.porcentaje_avance === undefined) ? "#dadada" : null} />
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                        <ModalCrearInmuebleEmpleado
                            modalVisible={this.state.modalCrearvisible}
                            hideModal={this.hideModal}
                            usuario_id={this.props.match.params.id}
                            id={this.state.id}
                            tajos={this.state.tajos_seleccionados}
                            accept={() => {
                                this.hideModal();

                            }}
                        />

                        <Button type="ghost" className="btnAddPlus" onClick={this.modalCrearInmueble}>
                            <IconPlus />
                        </Button>
                    </Row>
                </Spin>
            </div>
        )
    }
}
