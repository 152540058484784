import React, { Component } from 'react';
import { Button, Popconfirm, Space, Row, List, Col, Spin, PageHeader, Form, Layout, message, Typography, Avatar, Collapse, Select } from 'antd'
import axios from 'axios';
import ReactEcharts from "echarts-for-react";
import { IconPlus } from '../../Widgets/Iconos';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

//Componentes
import { IconDelete, IconMoney, IconEdit } from '../../Widgets/Iconos';
import VerDetalle from '../../Widgets/VerDetalle';


import ModalProductos from "./ModalProductos.js";
import ModalPrecio from "./ModalPrecio.js";

import '../../../Styles/productos.css'

const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography;
const { Option } = Select;
const moment = require('moment')
let unidades = {
    'H87': 'Pieza',
    'EA': 'Elemento',
    'KGM': 'Kilogramo',
    'MTR': 'Metro',
    'KT': 'Kit',
    'SET': 'Conjunto',
    'LTR': 'Litro',
    'XBX': 'Caja',
    'xun': 'Unidad',
    'GRM': 'Gramo',
    'BUT': 'Bulto',
    'TON': 'Tonelada',
}

/**
 * @export
 * @class HistorialPrecio
 * @extends {Component}
 * @description Vista de HistorialPrecio
 */
export default class HistorialPrecio extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			productos: [],
			producto: {},
			proveedores: 0,
			mejor_precio_actual:{
				costo: 0
			},
			peor_precio_actual: {
				costo: 0
			},
			mejor_precio:{
				precio: 0
			},
			peor_precio:{
				precio: 0
			},
			meses: [],
			historial: [],
			producto_id: this.props.match.params.producto_id,
		}
	}

	refForm = React.createRef()
	chart = React.createRef()

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		window.scrollTo(0, 0)

		this.getProductos('',true)
		if(this.state.producto_id){
			this.selectProducto(this.state.producto_id)
		}

		let meses = []
        for(let i = 0; i < 6; i++){
            let month  = moment().subtract(i, "month").format('MMMM YYYY');
            meses.unshift(month)
            this.setState({meses})
        }

	}


	/**
	 * @method getProductos
	 * @descripcion Obtiene la lista de productos padre
	 * 
	 * @params search Texto pára filtrar el nombre del los productos
	 * 
	 * */
	getProductos = (search,url) => {
		axios.get('/productos/list',{
			params:{
				search,
				limit: 40
			}
		}).then(response => {
			this.setState({productos: response.data.data.data})
			if(response.data.data.data.length > 0 && url === true ){
				this.selectProducto(response.data.data.data[0]._id)
				this.refForm.current.setFieldsValue({
					producto_id: response.data.data.data[0]._id
				})
			}

		}).catch(error => {
			console.log("error", error);
			message.error('Error al cargar los productos')
		})
	}


	/**
	 * @method selectProducto
	 * @descripcion la informacion del producto seleccionado
	 * */
	selectProducto = (producto_id) => {
		console.log("producto_id", producto_id);

		axios.get('/productos/historial',{
			params:{
				producto_id
			}
		}).then(response => {
				console.clear()
				console.log("response.data.data.precios", response.data.data.precios);
			this.setState({
				historial: []
			},()=>{
				this.setState({
					producto: response.data.data.producto,
					proveedores: response.data.data.proveedores.proveedores_count,
					mejor_precio_actual: response.data.data.mejor_precio_actual,
					peor_precio_actual: response.data.data.peor_precio_actual,
					mejor_precio: response.data.data.mejor_precio,
					peor_precio: response.data.data.peor_precio,
					historial: response.data.data.precios.map(e => ({
				  		name: e.proveedor_id.alias,
				  		type: 'line',
				  		data: e.montos 
				  	}))
				})
			})

		}).catch(error => {
			console.log("error", error);
			message.error('Error al obtener la información')
		})

	}

	render() {

		const { producto, proveedores, peor_precio_actual, mejor_precio_actual, mejor_precio, peor_precio } = this.state

		return (
			<div>
				<Spin spinning={false}>
					<Row className="row-encabezado">
						<Col xs={12} xl={11} className="flex-left">
							<Title level={4}className="view-title">Historial de precio</Title>
						</Col>
						<Col xs={12} xl={4} className="center">
							{ (Array.isArray(producto?.imagenes) && producto?.imagenes.length  > 0) ? 				
								<Avatar shape="square" src={axios.defaults.baseURL + "/upload/" + producto?.imagenes[0].filename } size={60} /> : null
							}
						</Col>

						<Col xs={12} xl={6} className="center modal-form">
							<Form
								className="w100"	
								layout="vertical"
								ref={this.refForm}
							>
								<Row >
									<Col span={24} className="m-0">
										<Form.Item 
											className="m-0"
											name="producto_id"
										>
											<Select
												className="form-select sm"
												onSearch={(search) => this.getProductos(search)}
				                                onSelect={(producto_id)=>this.selectProducto(producto_id)}
				                                showSearch
				                                filterOption={false}
				                                placeholder="Selecciona un producto"
											>
												{this.state.productos.map(producto => <Option value={producto._id} key={producto._id}>
													<Row>
														<Col className="center" span={4}>
				                                            <Avatar
				                                                shape="square" 
				                                                src={(Array.isArray(producto.imagenes) && producto.imagenes?.length > 0) ? (axios.defaults.baseURL + '/upload/' + producto?.imagenes[0].filename) : undefined} 
				                                            />
			                                            </Col>
			                                            <Col span={20} style={{lineHeight: '20px'}}>
			                                            	<Text strong style={{fontSize: 11}}>{producto?.nombre}</Text> <br/> <Text style={{fontSize: 10}}>{producto?.sku_manantial}</Text>
			                                            </Col>
			                                        </Row>
												</Option>)}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
					<Content className="admin-content content-bg p-1 historial-precio">
						<Row gutter={[16,16]} className="mb-1">
							<Col xs={24} xl={8} className="grid">
								<div className="card">
									<Title level={5}>Producto <small>Unidad: {unidades[producto.unidad]}</small></Title>
									<div className="flex-left-column">
										<Text className="text-grey">Nombre</Text>
										<Text className="text-blue">{producto.nombre}</Text>
										<Text className="text-grey">Fabricante</Text>
										<Text className="text-blue">{producto.fabricante} {producto.sku_fabricante}</Text>
										<Text className="text-grey">Grupo Manantial</Text>
										<Text className="text-blue">{producto.sku_manantial}</Text>
									</div>
								</div>
							</Col>
							<Col xs={24} xl={8} className="grid">
								<div className="card">
									<Title level={5}>Proveedores</Title>
									<Row gutter={[16,16]}>
										<Col span={16} className="flex-left-column">
											<Text className="text-grey">Mejor Precio Actual</Text>
											<Text className="text-green">$ {mejor_precio_actual.costo.toMoney()} MXN</Text>
										</Col>
										<Col span={8} className="flex-left-column">
											<Text className="text-proveedor">Proveedor</Text>
											<Text className="text-alias">{mejor_precio_actual.proveedor_id?.alias}</Text>
										</Col>

										<Col span={16} className="flex-left-column">
											<Text className="text-grey">Peor Precio Actual</Text>
											<Text className="text-red">$ {peor_precio_actual.costo.toMoney()} MXN</Text>
										</Col>
										<Col span={8} className="flex-left-column">
											<Text className="text-proveedor">Proveedor</Text>
											<Text className="text-alias">{peor_precio_actual.proveedor_id?.alias}</Text>
										</Col>

										<Col span={24} className="flex-left-column">
											<Text className="text-grey">Número de Proveedores</Text>
											<Text className="text-grey-lg">{proveedores} Proveedores</Text>
										</Col>
									</Row>
								</div>
							</Col>
							<Col xs={24} xl={8} className="grid">
								<div className="card">
									<Title level={5}>Precio</Title>
									<Row gutter={[16,16]}>
										<Col span={16} className="flex-left-column">
											<Text className="text-grey">Precio Más Bajo</Text>
											<Text>$ {mejor_precio.precio?.toMoney()} MXN</Text>
										</Col>
										<Col span={8} className="flex-left-column">
											<Text className="text-proveedor">Proveedor</Text>
											<Text className="text-alias">{mejor_precio.proveedor_id?.alias}</Text>
										</Col>

										<Col span={16} className="flex-left-column">
											<Text className="text-grey">Precio Más Alto</Text>
											<Text>$ {peor_precio.precio?.toMoney()} MXN</Text>
										</Col>
										<Col span={8} className="flex-left-column">
											<Text className="text-proveedor">Proveedor</Text>
											<Text className="text-alias">{peor_precio.proveedor_id?.alias}</Text>
										</Col>

										
									</Row>
								</div>
							</Col>
							<Col xs={24}>
								<div className="card">
									<Title level={5}>Precios Historicos</Title>
									<ReactEcharts 
									ref={this.chart}
									notMerge={true}
									option = {{
									  	tooltip: {
									    	trigger: 'axis'
									  	},
									  	grid: {
									    	left: '3%',
									    	right: '4%',
									    	bottom: '3%',
									    	containLabel: true
									  	},
									  	xAxis: {
									    	type: 'category',
									    	boundaryGap: false,
									    	data: this.state.meses
									  	},
									  	yAxis: {
									    	type: 'value'
									  	},
									  	series:this.state.historial 
									}} style = {{ minHeight: '400px'}}/> 
								</div>
							</Col>
						</Row>
					</Content>
					
				</Spin>
			</div>
			
		)
	}
}