import React, { Component } from 'react';
import { Row, Col, Typography, Table, Button, Popconfirm, message, Pagination, List, Card } from 'antd'
import { Link } from 'react-router-dom';

//componentes
import { IconPlus, IconEdit, IconDelete, IconEye } from '../../../Widgets/Iconos';
import Logged from "../../../../Hooks/Logged";

//modales
import ModalLocaciones from './ModalLocaciones';

const { Title, Text } = Typography;
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

export default class Locaciones extends Component {
    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            dataAreas: [],
            loading: true,

            accionModal: 'crear',
            registroId: '',
            modalVisible: false,
            tipo_user: 0,

            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            filtroSearch: ""

        }
    }

    ModalLocacionesRef = React.createRef();

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Obtenemos el listado de Areas
        this.receivedData();
    }
    componentDidUpdate() {

        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;
       
        if (filtro !== propFiltro) {
            this.receivedData();
        }


        if (this.context !==0 && this.state.tipo_user === 0)
            this.setState({
                tipo_user: this.context.tipo
            })
    }

    /**
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }


    /**
     * @description Abrir modal tipo edición para registro seleccionado desde tabla.
     * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
     */
    modalEditar = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;

        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisible: true,
        })
    }


    /**
     * @description Cerrar modal y limpiar valor registroId
     */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false,
        })
    }

    /**
        * @method receivedData
        * @description Obtiene la informacion y actualiza los valores locales de la lista.
        */
    receivedData = async (
        page = this.state.currentPage,
        quantity = this.state.perPage,
    ) => {

        var numPage = (page === undefined) ? 1 : page, numReg = 0;
        var search = this.props.search;

        axios.get('/locaciones/list', {
            params: {
                page: page,
                limit: quantity,
                search: search

            }
        })
            .then(res => {
                const data = res.data.data.itemsList

                this.setState({
                    dataAreas: res.data.data.itemsList,
                    loading: false,
                    currentPage: page,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: quantity,
                    pageCount: res.data.data.paginator.pageCount,
                    filtroSearch: search

                })

            })
            .catch(res => {
                console.log("error", res);
            });
    }

    /**
     * @method eliminarArea
     * @description Elimina area
     */
    eliminarArea = e => {
        axios.post('/locaciones/delete', {
            id: e
        })
            .then((response) => {
                message.success("Eliminado correctamente.");
                this.receivedData();
            })
            .catch((error) => {
                message.error(error.response.data.message)

            })
    }

    render() {
        const user = this.context;
        

        return (
            <div>
                <Row className="row-encabezado">

                   <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Title level={4}className="view-title">Áreas</Title>
                    </Col>

                    <Col xs={12} xl={4} xxl={4}>
                        <Row align="right" style={{ textAlign: "right", display: "block" }}>
                           <Title level={5} style={{ textAlign: "right", color: "#FFF", marginRight: '15px', marginTop: '7px', fontSize: '11pt' }}> {this.state.itemCount}  Áreas</Title>
                        </Row>
                    </Col>
                </Row>

                <section className="p-1">
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.dataAreas}
                        header={<Row className="header-list" style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>

                            <Col span={2} className="gutter-row center">
                                <Text className="ml-2" type="secondary">#</Text>
                            </Col>

                            <Col xs={18} className="gutter-row center">
                                <Text type="secondary">NOMBRE</Text>
                            </Col>

                            <Col xs={4} className="gutter-row center">
                                <Text type="secondary">ACCIONES</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={(item,index) => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row style={{ width: '100%' }} className="">

                                            <Col span={2} className="center">
                                                <Text strong>{index + 1 * this.state.currentPage }</Text>
                                            </Col>

                                            <Col xs={18} className="center">
                                                <Text strong>{item.nombre}</Text>
                                            </Col>

                                            <Col xs={4} className="center">
                                                <Link to={`/admin/inventario/areas/movimientos/${item._id}`}>
                                                    <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle">
                                                        <IconEye/>
                                                    </Button>
                                                </Link>
                                                <Button className="btn btn-edit" title="Editar" onClick={(e) => this.setState({modalVisible: true, accionModal: 'editar', registroId: item._id})}>
                                                    <IconEdit />
                                                </Button>
                                                <Popconfirm 
                                                    placement="topRight" 
                                                    title="¿Eliminar esta Area?" 
                                                    okText="Si, seguro" 
                                                    cancelText="No" 
                                                    onConfirm={() => this.eliminarArea(item._id)} >
                                                    <Button className="btn btn-delete" title="Eliminar">
                                                        <IconDelete />
                                                    </Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                                
                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.currentPage}
                            total={this.state.itemCount}
                            onChange={(page) => {
                                this.receivedData(page)
                            }}
                        />
                    </Row>

                </section>


                <ModalLocaciones
                    ref={this.ModalLocacionesRef}
                    modalVisible={this.state.modalVisible}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                    accept={() => {
                        this.hideModal();
                        this.receivedData();
                    }}
                >
                </ModalLocaciones>
                {(this.state.tipo_user !==3) ?
                    <Button className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                        <IconPlus />
                    </Button> : null}

            </div>
        )
    }
}
