import React, { Component } from 'react';
import { Button, Col, Row, Spin, Typography, message } from 'antd'
import _ImageMapper from 'react-image-mapper';

//componentes
import { IconPlus } from '../../../Widgets/Iconos';
import Logged from "../../../../Hooks/Logged";
import axios from 'axios';

//modales
import ModalCasaDetalle from "../../Modales/Plano/ModalCasaDetalle"

import ModalEstatusAvance from "../../Modales/ProjectManager/ModalEstatusAvance"

//css
import '../../../../Styles/plano.css';

const { Title } = Typography;

var ImageMapper = require("react-image-mapper");

export default class EstatusAvance extends Component {

    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {

            _id: undefined,
            loading: true,

            modalDetalleVisible: false,
            modalEstatusVisible: false,

            view_name: 'Catalogo',

            currentSlide: 0,
            slides: 0,

            cantVendidas: 0,
            cantApartadas: 0,
            cantDisponible: 0,

            totalVendidas: 0,
            totalApartadas: 0,
            totalDisponible: 0,

            totalTotal: 0,
            totalMontoCobrado: 0,
            totalMontoPendiente: 0,

            avanceMontoCobrado: 0,
            avanceMontoPendiente: 0,

            v_nombre: "",
            v_seccion: "",
            v_cliente: "",
            v_monto: "0",
            v_estatus: "",
            v_monto_deuda: 0,
            v_bitacora: [],


            dataCasas: {
                name: "",
                areas: [
                    { id: 1, name: "Casa 1 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [579, 1187, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 2, name: "Casa 2 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [514, 1187, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 3, name: "Casa 3 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [448, 1187, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 4, name: "Casa 4 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [384, 1187, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 5, name: "Casa 5 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [318, 1187, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 6, name: "Casa 6 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [255, 1187, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 7, name: "Casa 7 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [192, 1187, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 8, name: "Casa 8 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [124, 1187, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 9, name: "Casa 9 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [61, 1187, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 10, name: "Casa 10 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [107, 912, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 11, name: "Casa 11 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [150, 863, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 12, name: "Casa 12 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [195, 817, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 13, name: "Casa 13 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [239, 771, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 14, name: "Casa 14 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [283, 722, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 15, name: "Casa 15 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [327, 672, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 16, name: "Casa 16 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [370, 624, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 17, name: "Casa 17 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [412, 575, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 18, name: "Casa 18 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [457, 528, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 19, name: "Casa 19 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [501, 482, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 20, name: "Casa 20 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [543, 433, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 21, name: "Casa 21 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [587, 384, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 22, name: "Casa 22 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [631, 334, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 23, name: "Casa 23 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [673, 288, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 24, name: "Casa 24 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [445, 926, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 25, name: "Casa 25 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [489, 880, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 26, name: "Casa 26 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [534, 832, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 27, name: "Casa 27 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [576, 785, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 28, name: "Casa 28 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [621, 734, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 29, name: "Casa 29 ", section: "Seccion 1", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [664, 686, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },

                    { id: 31, name: "Casa 1 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [910, 1186, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 32, name: "Casa 2 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [975, 1186, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 33, name: "Casa 3 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1039, 1186, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 34, name: "Casa 4 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1104, 1186, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 35, name: "Casa 5 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1169, 1186, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 36, name: "Casa 6 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1234, 1186, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 37, name: "Casa 7 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1298, 1188, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 38, name: "Casa 8 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1228, 983, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 39, name: "Casa 9 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1216, 917, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 40, name: "Casa 10 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1206, 852, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 41, name: "Casa 11 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1196, 788, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 42, name: "Casa 12 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1184, 727, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 43, name: "Casa 13 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1174, 663, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 44, name: "Casa 14 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1163, 598, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 45, name: "Casa 15 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1149, 537, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 46, name: "Casa 16 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1138, 473, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 47, name: "Casa 17 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1110, 351, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 48, name: "Casa 18 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1060, 309, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 49, name: "Casa 19 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [1013, 264, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 50, name: "Casa 20 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [966, 222, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 51, name: "Casa 21 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [916, 178, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 52, name: "Casa 22 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [866, 136, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 53, name: "Casa 23 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [963, 960, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 54, name: "Casa 24 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [950, 895, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 55, name: "Casa 25 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [939, 834, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 56, name: "Casa 26 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [927, 762, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 57, name: "Casa 27 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [914, 696, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 58, name: "Casa 28 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [904, 634, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                    { id: 59, name: "Casa 29 ", section: "Seccion 2", estatus: "Disponible", cliente: null, monto: "0.00", shape: "circle", coords: [893, 568, 5], preFillColor: "#000000", fillColor: "#000000", monto_pagado: "0.00", bitacora: [] },
                ]
            }
        }
    }



    /**
    * @memberof BitacoraEstatusAvance
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se oculta el filtro superior
    * Se establece el valor del header en axios
    * Se llama al metodo setDataCasas que hace la consulta de la vista
    **/
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setDataCasas();
    }



    /**
    * @memberof BitacoraEstatusAvance
    * @method   setDataCasas
    * @description  Consulta de estatus de casas - inmuebles
    **/
    setDataCasas = () => {
        this.state.loading = true;
        try {
            var dataCasas = this.state.dataCasas.areas;
            axios.get('/ventas/get')
                .then(success => {
                    //Se iteran las consultas
                    success.data.data.map((inmueble, index) => {
                        if (inmueble.cliente) {
                            //Tenemos cliente, no es nulo
                            //Se busca el indice en los valores predeterminados para actualizar el state.
                            const casa_seccion = (element) => (element.name).trim() === (inmueble.inmueble).trim() && (element.section).trim() === (inmueble.seccion).trim()
                            let indice = dataCasas.findIndex(casa_seccion)

                            //console.log('Indice predeterminado', indice, index)
                            //Se busca el valor predeterminado para actualizarlo con el indice
                            if (indice !== -1) {
                                if (inmueble.cliente !==null) {
                                    dataCasas[indice].cliente = inmueble.cliente;
                                    dataCasas[indice].estatus = inmueble.estatus;
                                    dataCasas[indice].monto = inmueble.cliente.monto_venta;
                                    dataCasas[indice].monto_pagado = inmueble.cliente.monto_pagado;
                                }

                                if (inmueble.bitacora !==undefined && inmueble.bitacora.length > 0) {
                                    dataCasas[indice].fillColor = "#FF008A";
                                    dataCasas[indice].preFillColor = "#FF008A";
                                    
                                    dataCasas[indice].bitacora = inmueble.bitacora;
                                }
                            }
                        }
                    });
                    this.setState({ loading: false })
                })

                .catch(error => {
                    console.log('error', error);
                    this.setState({ loading: false })
                })

        } catch (error) {
            console.log('error', error);
            this.setState({ loading: false })
        }
    }


    /**
    * @memberof BitacoraEstatusAvance
    * @method   clicked
    * @description  click en area botón
    **/
    clicked(area) {
        this.modalDetalleCasa(area);

        this.setState({
            msg: `You clicked on ${area.shape} at coords ${JSON.stringify(
                area.coords
            )} !`
        });

    }


    /**
    * @memberof BitacoraEstatusAvance
    * @method   clickedOutside
    * @description  se definen coordenadas 
    **/
    clickedOutside(evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        this.setState({
            msg: `You clicked on the image at coords ${JSON.stringify(coords)} !`
        });
    }


    /**
    * @memberof BitacoraEstatusAvance
    * @method   moveOnImage
    * @description  se definen coordenadas 
    **/
    moveOnImage(evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        this.setState({
            moveMsg: `You moved on the image at coords ${JSON.stringify(coords)} !`
        });
    }

    
    /**
    * @memberof BitacoraEstatusAvance
    * @method   enterArea
    * @description  se definen coordenadas 
    **/
    enterArea(area) {
        this.setState({
            hoveredArea: area,
            msg: `You entered ${area.shape} ${area.name} at coords ${JSON.stringify(
                area.coords
            )} !`
        });
    }


    /**
    * @memberof BitacoraEstatusAvance
    * @method   leaveArea
    * @description  se definen coordenadas 
    **/
    leaveArea(area) {
        this.setState({
            hoveredArea: null,
            msg: `You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(
                area.coords
            )} !`
        });
    }


    
    
    /**
    * @memberof BitacoraEstatusAvance
    * @method   leaveArea
    * @description  se definen coordenadas 
    **/
    moveOnArea(area, evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        this.setState({
            moveMsg: `You moved on ${area.shape} ${area.name
                } at coords ${JSON.stringify(coords)} !`
        });
    }



    /**
    * @memberof BitacoraEstatusAvance
    * @method   getTipPosition
    * @description  se definen coordenadas para tooltip
    **/
    getTipPosition(area) {
        //return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
        let top = area.center[1] + 230
        let left = area.center[0] + 180
        if (area.estatus !== "Disponible") {
            top = top - 30
        }

        return { top: `${top}px`, left: `${left}px` };
    }


    /**
    * @memberof BitacoraEstatusAvance
    * @method   modalDetalleCasa
    * @description  se definen detalles en modal detalle casa 
    **/
    modalDetalleCasa = (record) => {
        if (record.bitacora !==undefined && record.bitacora.length > 0) {
            this.setState({
                modalDetalleVisible: true,
                v_nombre: record.name,
                v_seccion: record.section,
                v_cliente: (record.cliente) ? record.cliente.nombre + ' ' + record.cliente.apellido : 'Sin cliente',
                v_monto: (record.monto).toMoney(),
                v_estatus: record.estatus,
                v_monto_deuda: record.cliente ? record.cliente.monto_deuda : 0.00,
                v_bitacora: (record.bitacora !==undefined && record.bitacora.length > 0) ? record.bitacora[(record.bitacora.length - 1)].archivos : [],
                v_bitacoraFecha: (record.bitacora !==undefined && record.bitacora.length > 0) ? record.bitacora[(record.bitacora.length - 1)].fecha : ""
            });
        }
        else {
            message.warning("El inmueble " +record.name + " de "+record.section + " no tiene entradas de avance")
        }
    }


    
    /**
    * @memberof BitacoraEstatusAvance
    * @method   subirEstatus
    * @description  Mostrar modal estatus
    **/
    subirEstatus = () => {
        this.setState({
            modalEstatusVisible: true,
        })
    }



    /**
    * @memberof BitacoraEstatusAvance
    * @method   hideModal
    * @description  Ocultar modal estatus
    **/
    hideModal = () => {
        this.setState({
            modalDetalleVisible: false,
            modalEstatusVisible: false,
            v_nombre: "",
            v_seccion: "",
            v_cliente: "",
            v_estatus: "",
            v_monto: "0.00",
            v_bitacora: [],
            v_bitacoraFecha: ""


        });
        this.setDataCasas();
    }



    render() {
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={12} xl={20} xxl={20}>
                            <h2 style={{ width: 'auto', float: 'left' }}>Project Manager</h2>
                        </Col>
                    </Row>

                    <Row className="p-1">
                        <Col xs={24} >
                            <div className="div_contenido cardPlano divEstadisticas" >
                                <div className="div-titulo">
                                    <h2 className="titulo-divCont TituloCardPlano" style={{ fontSize: "20pt" }}>Estatus de Avance</h2>
                                </div>
                                <div style={{ float: "left", minWidth: "300px", width: "100%" }}>
                                    <Col className="div-entradas">
                                        <div className="divInfo cardInfo" >
                                            <span className="spnVendidas spnEntradas"></span>
                                            <h1>Con entradas</h1>
                                        </div>
                                    </Col>
                                    <Col className="div-entradas">
                                        <div className="divInfo cardInfo" >
                                            <span className="spnApartadas spnSinEntradas"></span>
                                            <h1>Sin entradas</h1>
                                        </div>
                                    </Col>
                                </div>

                            </div>
                        </Col>
                    </Row>

                    <Row className="p-1">
                        <div className="div_contenido"
                            style={{
                                background: "transparent",
                                display: "block",
                                overflow: "auto",
                                position: "static",
                                padding: "5px",
                            }}>

                            <div className="grid">
                                <div className="presenter">
                                    <div className="center">

                                        <_ImageMapper
                                            map={this.state.dataCasas}
                                            src={`/images/plano.png`}
                                            width={1400}

                                            onClick={area => this.clicked(area)}
                                            onMouseEnter={area => this.enterArea(area)}
                                            onMouseLeave={area => this.leaveArea(area)}
                                            onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
                                            onImageClick={evt => this.clickedOutside(evt)}
                                            onImageMouseMove={evt => this.moveOnImage(evt)}

                                            lineWidth={4}
                                            strokeColor={"white"}
                                        />

                                        {this.state.hoveredArea && (
                                            <span className="tooltip" style={{ ...this.getTipPosition(this.state.hoveredArea) }} >
                                                <label style={{ display: "block" }}>{this.state.hoveredArea.name}</label>
                                                <label style={{ display: "block" }}>{this.state.hoveredArea.section}  </label>
                                                <label style={{ display: "block" }}>{'Estatus : ' + this.state.hoveredArea.estatus}  </label>

                                                {(this.state.hoveredArea.estatus !=="Disponible") ?
                                                    <div style={{ width: '250px' }}>
                                                        <label style={{ display: "block" }}>{'Cliente : ' + (this.state.hoveredArea.cliente) ? this.state.hoveredArea.cliente.nombre + ' ' + this.state.hoveredArea.cliente.apellido : 'Sin cliente'}  </label>
                                                        <label style={{ display: "block" }}>{'Monto : $ ' + (this.state.hoveredArea.monto).toMoney()}  </label>
                                                        <label style={{ display: "block" }}>{'Monto por Pagar : $ ' + (this.state.hoveredArea.cliente.monto_deuda).toMoney()}  </label>
                                                    </div>
                                                    : ""}

                                            </span>
                                        )}

                                    </div>
                                </div>
                            </div>

                            <Row className="p-1">
                                <img src={'/images/PE.png'} width="200" />
                            </Row>
                        </div>
                    </Row>


                    <ModalCasaDetalle
                        modalVisible={this.state.modalDetalleVisible}
                        closeMethod={this.hideModal}
                        nombre={this.state.v_nombre}
                        seccion={this.state.v_seccion}
                        cliente={this.state.v_cliente}
                        monto={this.state.v_monto}
                        estatus={this.state.v_estatus}
                        monto_deuda={this.state.v_monto_deuda}
                        bitacora={this.state.v_bitacora}
                        bitacoraFecha={this.state.v_bitacoraFecha}
                        tipo="archivos"
                    >
                    </ModalCasaDetalle>


                    <ModalEstatusAvance
                        visible={this.state.modalEstatusVisible}
                        closeMethod={this.hideModal}
                    >
                    </ModalEstatusAvance>

                    <Button type="ghost" className="btnAddPlus" title="Subir Estatus" onClick={() => this.subirEstatus()} >
                        <IconPlus />
                    </Button>

                </Spin>
            </div>
        )
    }
}

