import React, { Component } from 'react';
import { Col, Row, Button, Radio, } from 'antd'
import { Link } from 'react-router-dom';
import {  IconArrowBack } from '../../../Widgets/Iconos';


import Logged from "../../../../Hooks/Logged";

import '../../../../Styles/nominas.css'

// INFORMACION
import NominaAsalariados from './Asalariados';
import NominaDestajo from './Destajo';
import NominaInmuebles from './Inmueble';


/**
 *
 * @param {*} usuario Usuario logeado
 * @param {*} objGrupo Grupo de la revision comparando
 * @param {*} nomina Tipo de vista que se esta trabajando 1 = finanzas nominas 2 =  revisiones
 * @returns
 */

export default class NominaDashboard extends Component {
    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {
            data_revisiones: [],
            view: '',
            nomina_id: '',
        };
    }

    /**
     * @memberof NominaDashboard
     * @description Se ejecuta al terminar de montar la vista de administar nominas;
     */

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        let id = this.props.match.params.id;
        let view = this.props.match.params.view; 
        const user = this.context;
        if (user && user === 1)
            this.setState({ nomina_id: id, view: (view) ? view : 'Asalariados' });
        else
            this.setState({ nomina_id: id, view: (view) ? view : 'Inmueble' });
    }

    renderView(view, nomina_id) {
        switch (view) {
            case 'Asalariados':
                return <NominaAsalariados nomina_id={nomina_id} />
                break;
            case 'Destajo':
                return <NominaDestajo nomina_id={nomina_id} />
                break;
            case 'Inmueble':
                return <NominaInmuebles nomina_id={nomina_id} />
                break;

        }
    }

    /**
    * @memberof Index
    *
    * @method changeView
    * @description Cambia la vista para visualizar los datos dependiendo de si grupo o empleado
    **/
    changeView = (view) => {
        this.setState({ view: view.target.value })
    }

    render() {
        const { nomina } = this.state;

        const user = this.context;
        let nomina_id = this.props.match.params.id;
        // Solo para el dueño usuario ->tipo :1
        //boss y finanzas
        if (user && (user.tipo === 1)) {
            return (
                <div>
                    <Row className="row-encabezado">
                        <Col xs={16} xl={16} xxl={16} style={{ paddingTop: "5px" }}>

                            <Link to={`/admin/nomina`} style={{ color: "white" }}>
                                <Button className="IconArrowBack" style={{ marginTop: "0px" }}>
                                    <IconArrowBack />
                                </Button>
                            </Link>

                            <h2 style={{ margin: "0px", padding: "0px" }} >{(user && user.tipo === 1) ? "Revisión Final" : "Revisión Preliminar"}</h2>


                        </Col>

                        <Col xs={8} xl={8} xxl={8} className="">
                            <Radio.Group onChange={this.changeView} className="toogle-nominas-checkbox" style={{ marginTop: '9px' }}>
                                <Radio.Button value="Asalariados">Asalariados</Radio.Button>
                                <Radio.Button value="Destajo">Destajo</Radio.Button>
                                <Radio.Button value="Inmueble">Inmueble</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row className="page-content">
                        {this.renderView(this.state.view, nomina_id)}
                    </Row>
                </div>
            )
        } else {
            return <div>
                <Row className="row-encabezado">
                    <Col xs={16} xl={16} xxl={16}>
                        <h2>Revisón Preeliminar </h2>
                    </Col>
                    <Col xs={8} xl={8} xxl={8} className="">
                        <Radio.Group onChange={this.changeView} className="toogle-nominas-checkbox" style={{ marginTop: '9px' }} value={this.state.view}>
                            <Radio.Button value="Inmueble">Inmueble</Radio.Button>
                            <Radio.Button value="Asalariados">Asalariados</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row className="page-content">
                    {this.renderView(this.state.view, nomina_id)}
                </Row>
            </div>

        }
    }
}
