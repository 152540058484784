import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, message, Spin} from 'antd'
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { Link } from "react-router-dom";
//css
import '../../../Styles/presupuesto.css'

const axios = require('axios').default
const { Text } = Typography;
  
export default class PresupuestoDashboard extends Component{

	constructor(props){
		super(props);
		this.state= {
			proforma_monto: 0,
			casas_monto: 0,
			total: 0,
			inmuebles: [],
		}
	}

	/**
	* @memberof PresupuestoCasasAct
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getData()
	}

	/**
	* @memberof PresupuestoCasasAct
	*
	* @method   getData
	* @description trae el total que se ha gastado en proforma y en casas
	*
	**/
	getData = () => {
		this.setState({loading: true})
		axios.get('/presupuesto/dashboard',{
			params:{}
		}).then(response => {
			this.setState({
				proforma_monto: response.data.data.proforma,
				casas_monto: response.data.data.casas.total_casas,
				inmuebles: response.data.data.casas.inmuebles,
				total:  response.data.data.casas.total_casas + response.data.data.proforma,
				loading: false,
			})
		}).catch(error =>{
			message.error('Error al cargar la información')
		})
	}

	/**
	* @memberof PresupuestoCasasAct
	*
	* @method   dataChart
	* @description Retorna la informacion para la grafica
	*
	**/
	dataChart = () => {
		let { inmuebles } = this.state
		return {
			color: ['rgba(77, 119, 255)','rgba(55, 162, 255)',],
		    title: {
		        text: 'Presupuesto Y Monto Pagado'
		    },
		    tooltip: {
		        trigger: 'axis',
		        axisPointer: {
		            type: 'cross',
		            label: {
		                backgroundColor: '#6a7985'
		            }
		        }
		    },
		    toolbox: {
		        feature: {
		            saveAsImage: {}
		        }
		    },
		    grid: {
		        left: '3%',
		        right: '4%',
		        bottom: '3%',
		        containLabel: true
		    },
		    xAxis: [
		        {
		            type: 'category',
		            boundaryGap: false,
		            data: this.state.inmuebles.map(inm => inm.inmueble)
		        }
		    ],
		    yAxis: [
		        {
		            type: 'value'
		        }
		    ],
		    series: [
		        {
		            name: 'Monto Pagado',
		            type: 'line',
		            stack: '总量',
		            smooth: true,
		            lineStyle: {
		                width: 0
		            },
		            showSymbol: false,
		            areaStyle: {
		                opacity: 0.8,
		                color: 'rgba(77, 119, 255)'
		            },
		            emphasis: {
		                focus: 'series'
		            },
		            data: this.state.inmuebles.map(inm => inm.monto)
		        },{
		            name: 'Presupuesto',
		            type: 'line',
		            stack: '总量',
		            smooth: true,
		            lineStyle: {
		                width: 0
		            },
		            showSymbol: false,

		            areaStyle: {
		                opacity: 0.8,
		                color: 'rgba(55, 162, 255)'
		            },
		            emphasis: {
		                focus: 'series'
		            },
		            data: this.state.inmuebles.map(inm => inm.presupuesto)
		        }
		    ]
		}
}



	render(){
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} xl={6} xxl={8}>
						<h2 style={{width: 'auto', float: 'left'}}>Presupuesto</h2>
					</Col>
				</Row>
				<section className="presupuesto-ctn p-1">
					<Spin spinning={this.state.loading}>
						<Row gutter={[16,16]}>
							<Col xs={24} md={7} className="gutter-row" >
								<div className="card med center">
									<img src={'/images/PE.png'} />
								</div>
							</Col>
							<Col xs={24} md={7} className="gutter-row">
								<div className="card med">
									<Text className="card-title">Inversión Restante</Text>
									<Text className="card-data">$ {this.state.total.toMoney(2)} MXN</Text>
								</div>
							</Col>
							<Col xs={24} md={10} className="gutter-row flex-column-between" style={{height: '166px',paddingBottom: '15px'}}>
								<Link to={"/admin/presupuesto/proforma"} className="card sm center hover-effect" style={{width: '100%'}}>
									<Row>
										<Col span={12} className="card-title">Proforma</Col>
										<Col span={12} className="card-data">$ {this.state.proforma_monto.toMoney(2)} MXN</Col>
									</Row>
								</Link>
								<Link to={"/admin/presupuesto/casas"} className="card sm center hover-effect" style={{width: '100%'}}>
									<Row>
										<Col span={12} className="card-title">Casas</Col>
										<Col span={12} className="card-data">$ {this.state.casas_monto.toMoney(2)} MXN</Col>
									</Row>
								</Link>
							</Col>	
						</Row>
						<Row className="mt-2">
							<Col span={24} className="card card-grafica center">
								<ReactECharts option={this.dataChart()} style={{width:'90%', height:'400px'}} />	
							</Col>
						</Row>
					</Spin>
				</section>
			</div>
		)
	}
}