import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, Upload, List } from 'antd';
import './../../../../Styles/modales.css'

import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';

import { UploadOutlined } from '@ant-design/icons'
import PropTypes from "prop-types";
import Logged from '../../../../Hooks/Logged';
// LoadingOutlined

const moment = require('moment');
const axios = require('axios').default;

const { Option } = Select;

const { Title, } = Typography;

export default class ModalEstatusAvance extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    static contextType = Logged;

    formModalEstatusAlcance = React.createRef();


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},
            dataInmuebles: [],
            actualizado: false,

            det_fecha: "",
            det_videos: [],

        }
    }


    /**
     * @methodOf ModalEstatusAvance
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //console.log("x");

        this.loadInmuebles();


    }

    /**
     * @methodOf ModalEstatusAvance
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualiza una propiedad o un estado.
     *
     * */
    componentDidUpdate(prevProps, prevState, snapshot) {
        //Si la propiedad cambia y es diferente al estado, actualizamos el estado con el ID de la poppiedad y buscamos al usuario
        if (this.props.id !== this.state.id && this.props.id !== undefined) {
            this.state.id = this.props.id

        }

        if (this.props.id !== this.state.id && this.state.id !== undefined && this.props.id === undefined) {
            this.state.id = undefined;
            this.state.form = {};
            this.state.image = undefined;
            this.formModalEstatusAlcance.current.resetFields();
        }



        if (this.props.visible && this.props.accionModal === "editar" && this.props.regIdModal !=="" && this.props.regIdModal !==this.state.det_id) {
            this.state.loading = true;
            this.state.loadingImage = true;
            this.actualizarConsultaArchivos();

            setTimeout(() => {
                this.state.loading = false;
                this.state.loadingImage = false;
            }, 1000);
        }


        if (!this.props.visible && this.props.accionModal === "editar" && this.state.actualizado) {
            this.setState({
                form: {},
                dataInmuebles: [],

                det_fecha: "{{Fecha}}",
                det_videos: [],
                actualizado: false
            });
        }
    }


    /**
     * @methodOf ModalEstatusAvance
     *
     * @function actualizarConsultaArchivos
     * @description Se actualizó consulta de archivos
     */
    actualizarConsultaArchivos = async () => {
        if (!this.state.actualizado) {
            var data = this.props.dataDetBitacora;
            var idReg = this.props.regIdModal;
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                //console.log(element)

                if (idReg === element._id) {
                    this.setState({
                        det_fecha: moment(element.fecha).format('DD-MM-YYYY'),
                        det_videos: element.archivos,
                        actualizado: true,
                        det_id: idReg,
                        loading: false,
                        loadingImage: false

                    });

                    break;
                }
            }
        }
    }




    /**
     * @methodOf ModalEstatusAvance
     * @function hideModal
     * @description Se oculta modal
     */
    hideModal = async () => {
        this.setState({
            loading: false,
            loadingImage: false,
            form: {},
            dataInmuebles: [],
            det_fecha: "{{Fecha}}",
            det_videos: [],
            actualizado: false
        });

        this.props.closeMethod();

    }

    /**
     * @method normFile
     * @description Segun la lista, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     * @param e (string)
     * Recibe el nombre del archivo.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed" && file.response !==undefined)
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);

        return e && e.fileList;
    };


    /**
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre del archivo.
     */
    removeFile = (image) => {
        axios.post("/voucher/delete", {
            filename: image
        })
            .then(res => {
                console.log("Archivo removido con éxito", res);
            })
            .catch(res => {
                console.log("Archivo no se pudo remover", res);
            })
    };



    /**
    * @method loadInmuebles
    * @description Cargar inmuebles.
    *
    */
    loadInmuebles = async (search) => {
        this.setState({ loading: true })
        axios.get('/inmuebles-trabajo/list', {
            params: {
                page: 1,
                limit: 1000,
                search: search
            }
        })
            .then(res => {
                var dataRes = res.data.data.itemsList;
                var dataInm = [];
                for (let index = 0; index < dataRes.length; index++) {
                    const element = dataRes[index];
                    dataInm.push({
                        nombre: element.inmueble.nombre,
                        inmueble_id: element.inmueble._id,
                        _id: element._id,
                    });
                }

                this.setState({
                    dataInmuebles: dataInm,
                    loading: false,
                })
            })
            .catch(e =>
                console.log('error inmuebles get', e)
            )
    }


    /**
     * @function saveEstatus
     *
     * @description Guarda nueva entrada para inmueble seleccionado.
     *
     * */
    saveEstatus = values => {
        axios.post('/inmuebles-trabajo/setBitacora', {
            id: values.inmueble,
            archivos: (Array.isArray(values.video)) ? values.video.map(video =>
                video.response.filename
            ) : [],
        })
            .then((res) => {
                message.success("Bitacora Registrada!");
                this.state.updateChecker = false
                this.props.accept();
                this.setState({ spinning: false });
                this.props.closeMethod();
            })
            .catch((error) => {
                Modal.warning({
                    title: 'Error',
                    content:
                        <List
                            size="small"
                            bordered
                            dataSource="No se ha podido registrar la bitacora"
                        />
                });
            });
    }


    /**
  * @function updateEstatus
  *
  * @description Actualiza entrada para inmueble seleccionado.
  *
  * */
    updateEstatus = values => {
        //console.log(values);
        var archivos = (Array.isArray(values.video)) ? values.video.map(video => (video.response === undefined) ? video.name : video.response.filename) : [];
        axios.post('/inmuebles-trabajo/bitacora/entrada/update', {
            inmuebleId: this.props.inmuebleId,
            id: this.props.regIdModal,
            archivos: archivos,
        })
            .then((res) => {
                message.success("Bitacora Actualizada!");
                this.state.updateChecker = false
                this.props.accept();

                this.setState({ spinning: false });
                this.props.closeMethod();
            })
            .catch((error) => {
                Modal.warning({
                    title: 'Error',
                    content:
                        <List
                            size="small"
                            bordered
                            dataSource="No se ha podido actualizar la bitacora"
                        />
                });
            });

    }


    render() {
        const { normFile, valPreFile, onChangeFileList, formModalEstatusAlcance, saveUser, deleteImage } = this;
        const { visible, closeMethod, accionModal, inmueble } = this.props;
        const { form, image, loading, id, proyectos, loadingImage, dataInmuebles } = this.state;

        var contenido = null;

        if (accionModal === "editar") {
            contenido = <section>
                <div className="modal-header">
                    <Button type="ghost" title="Cerrar" className="btn-close-modal" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title" style={{
                        marginTop: "-35px",
                    }}>Editar registro {this.state.det_fecha}</Title>
                </div>

                <Spin spinning={loading}>

                    <Row align="center">

                        <Form
                            layout="vertical"
                            ref={formModalEstatusAlcance}
                            name="formulario-transacciones"
                            onFinish={this.updateEstatus}
                            initialValues={form}
                        >
                            <Row style={{ textAlign: "center" }}>
                                <Col xs={24} lg={24} style={{ textAlign: "center" }}>
                                    <Form.Item
                                        label="Imnueble"
                                        name="inmueble"
                                        disabled={true}
                                    >
                                        <Input disabled defaultValue={inmueble} />
                                    </Form.Item>
                                </Col>

                                <Col span={24} className="colDocsComprobantes">
                                    <Form.Item
                                        label="Archivos"
                                        name="video"
                                        valuePropName="fileList"
                                        className="content-uploader"
                                        getValueFromEvent={normFile}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione archivo"
                                        }]}
                                    >
                                        <Upload
                                            className="avatar-uploader"
                                            action={axios.defaults.baseURL + "/voucher/add"}
                                            multiple={false}

                                            tipo="detalle"
                                            defaultFileList={this.state.det_videos.map((imagen, index) => new Object({
                                                uid: index * -1,
                                                name: imagen,
                                                status: 'done',
                                                url: axios.defaults.baseURL + '/voucher/' + imagen,
                                            }))}
                                        >
                                            <Button>
                                                <UploadOutlined /> Upload
                                             </Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>


                                <Col span={24}>
                                    <Form.Item>
                                        <Button htmlType="submit" type="primary" className="btn-azul"  >
                                            Guardar
                    </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>


                    </Row>
                </Spin>
            </section>
        }
        else {
            contenido = <Spin spinning={loading}>
                <div className="modal-header">
                    <Button type="ghost" title="Cerrar" className="btn-close-modal" onClick={closeMethod}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title" style={{
                        marginTop: "-35px",
                    }}> Subir Estatus</Title>
                </div>

                <Form
                    layout="vertical"
                    ref={formModalEstatusAlcance}
                    name="formulario-transacciones"
                    onFinish={this.saveEstatus}
                    initialValues={form}
                >
                    <Row style={{ textAlign: "center" }}>
                        <Col xs={24} lg={24} style={{ textAlign: "center" }}>
                            <Form.Item
                                label="Imnueble"
                                name="inmueble"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione inmueble"
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={false}
                                    onSearch={search => this.loadInmuebles(search)}
                                >
                                    {dataInmuebles.map(function ({ nombre, descripcion, _id }, index) {
                                        return <Option value={_id}> {nombre} </Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>

                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                label="Archivos"
                                name="video"
                                valuePropName="fileList"
                                className="content-uploader"
                                getValueFromEvent={normFile}
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione archivo"
                                }]}
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    multiple={false}
                                >
                                    <Button> <UploadOutlined /> Upload </Button>
                                </Upload>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul"  >
                                    Guardar
                    </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        }


        return (
            <Modal

                visible={visible}
                onCancel={this.hideModal, this.state.visible = false, closeMethod}
                title={null}
                footer={null}
                closable={false}
                maskClosable={!loadingImage}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                {contenido}
            </Modal>
        )
    }
}

