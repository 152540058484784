import React, { Component } from 'react';
import { Row, Col, Typography, DatePicker, Dropdown, Button, Menu, Table, Avatar } from 'antd'

import { IconArrow, IconPlus, IconArrowBack, IconDelete, IconMail, IconPDF, IconEye } from '../../../Widgets/Iconos';
import { Link } from 'react-router-dom';

import './../../../../Styles/proyecciones.css'

const moment = require('moment');

const { Title, Paragraph, } = Typography;

const { RangePicker } = DatePicker;

const menuProyectos = (
  <Menu>
    <Menu.Item>
      <a >
        Proyecto 1
        </a>
    </Menu.Item>
    <Menu.Item>
      <a >
        Proyecto 2
        </a>
    </Menu.Item>
    <Menu.Item>
      <a >
        Proyecto 3
        </a>
    </Menu.Item>
  </Menu>
);

const menuSubrubros = (
  <Menu>
    <Menu.Item>
      <a >
        Subrubro 1
        </a>
    </Menu.Item>
    <Menu.Item>
      <a >
        Subrubro 2
        </a>
    </Menu.Item>
    <Menu.Item>
      <a >
        Subrubro 3
        </a>
    </Menu.Item>
  </Menu>
);

const menuUsuarios = (
  <Menu>
    <Menu.Item>
      <a >
        Usuario 1
        </a>
    </Menu.Item>
    <Menu.Item>
      <a >
        Usuario 2
        </a>
    </Menu.Item>
    <Menu.Item>
      <a >
        Usuario 3
        </a>
    </Menu.Item>
  </Menu>
);

const menuMontos = (
  <Menu>
    <Menu.Item>
      <a >
        100
        </a>
    </Menu.Item>
    <Menu.Item>
      <a >
        1000
        </a>
    </Menu.Item>
    <Menu.Item>
      <a >
        5000
        </a>
    </Menu.Item>
  </Menu>
);



const dataTable = [
  {
    id: 1,
    fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 2, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 3, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 4, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 5, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 6, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 7, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 8, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 9, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 10, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 11, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
  {
    id: 12, fecha: '{{Fecha}}',
    cliente: '{{Cliente}}',
    usuario: '{{Usuario}}',
    subrubro: '{{Subrubro}}',
    proyecto: '{{Proyecto}}',
    monto: '{{Monto}}',
  },
];

export default class ProyeccionesVentas extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataVentas: [],
      loading: true,

      accionModal: 'crear',
      registroId: '',
      loading: true,
      modalVisible: false,

      /* Paginado */
      itemCount: undefined,
      perPage: 10,
      pageCount: 1,
      currentPage: 1,
      slNo: 1,
      hasPrevPage: false,
      hasNextPage: false,
      prev: null,
      next: null,
    }
  }

  componentDidMount() {
    //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        

    this.setState({
      loading: false,
      dataVentas: dataTable
    });
  }

  render() {
    const columns = [
      {
        title: 'Fecha',
        key: 'fecha',
        dataIndex: 'fecha',
      },
      {
        title: 'Cliente',
        key: 'cliente',
        dataIndex: 'cliente',
      },

      {
        title: 'Usuario',
        dataIndex: 'usuario',
        render: (text, record) => (
          <Avatar size="large" />
        ),
      },
      {
        title: 'Subrubro',
        dataIndex: 'subrubro',
        key: 'subrubro',
      },
      {
        title: 'Proyecto',
        dataIndex: 'proyecto',
        key: 'proyecto',
        // dataIndex: 'proyecto',
        render: (text, record) => (
          <span className="spnProyecto" >
            {record.proyecto}
          </span>
        ),
      },
      {
        title: 'Monto',
        dataIndex: 'monto',
        key: 'monto',
      },
      {
        title: 'Acciones',
        key: 'actions',
        width: 220,
        render: (text, record) => (
          <div className="tbl-opciones">

            <Button type="primary" className="button-survey btn-mail button-mail" name={record.nombre} id={record._id} title="Enviar"  >
              <IconMail />
            </Button>

            <Button type="primary" className="button-survey btn-pdf button-pdf" name={record.nombre} id={record._id} title="PDF"  >
              <IconPDF />
            </Button>

            <Link title="Ver" className="EditIcon" to={`/admin/proyecciones/ventas/detalle/${record.id}`} >
              <Button type="primary" className="button-survey btn-eye button-eye" name={record.nombre} id={record.id} title="Ver detalle"  >
                <IconEye />
              </Button>
            </Link>

            <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" name={record.nombre} id={record._id} title="Eliminar"  >
              <IconDelete />
            </Button>
          </div>
        ),
      },
    ];

    return (
      <div>
        <Row className="row-encabezado">

          <Col xs={24} xl={6} xxl={8}>
            <Button className="IconArrowBack" >
              <IconArrowBack />
            </Button>
            <h2 style={{ width: 'auto', float: 'left' }}> Ventas</h2>
          </Col>

          <Col xs={24} xl={18} xxl={16} >
            <div style={{ float: 'left', marginRight: '20px' }}>
              <RangePicker style={{ background: 'transparent !important' }} />
            </div>

            <div style={{ float: 'left' }} className="div-enc-filtros">
              <Row align="center">
                <Col span={6}>
                  <Dropdown overlay={menuProyectos}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>  Proyecto <IconArrow /> </a>
                  </Dropdown>
                </Col>
                <Col span={6}>
                  <Dropdown overlay={menuSubrubros}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>  Subrubro <IconArrow /> </a>
                  </Dropdown>
                </Col>
                <Col span={6}>
                  <Dropdown overlay={menuUsuarios}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>  Usuario <IconArrow /> </a>
                  </Dropdown>
                </Col>
                <Col span={6}>
                  <Dropdown overlay={menuMontos}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>  Monto <IconArrow /> </a>
                  </Dropdown>
                </Col>
              </Row>

            </div>
          </Col>
        </Row>

        <section>
          <Row>
            <div className="div_contenido div-contenedor" >
              <Table columns={columns} dataSource={this.state.dataVentas} scroll={{ x: 800, y: 550 }} className="blankTheme" />
            </div>
          </Row>
        </section>

        <Button className="btnAddPlus" title="Nuevo registro" to="/admin/proyecciones/ventas/creacion">
          <IconPlus />
        </Button>
      </div>
    )
  }
}
