import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Form, InputNumber, Checkbox, Upload, Select, message   } from 'antd';
import axios from 'axios'
import { IconCloseModal, IconArrowSelect  } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import './../../../../Styles/modales.css'
import {CheckCircleFilled, UploadOutlined} from '@ant-design/icons';

const moment = require('moment');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalCotizacion extends Component {
 
    modalRef = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            proyectos: [],
            proveedores: [],
            cotizacionList: [],
            uso_credito: false,
        }
    }

    /**
    * @memberof ModalCotizacion
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getProyectos()
        this.getProveedores()
    }


    /**
    * @memberof ModalCotizacion
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props  
    **/
    hideModal = () => {
        this.props.closeMethod(true);
        this.setState({uso_credito: false})
    }

    /**
     *
     *
     * @memberof ModalCotizacion
     * @function getProyectos
     *
     * @description Obtener proyectos.
     */
    getProyectos = (search) => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
                search,
            }
        }).then((proyectos) => {
            this.setState({proyectos: proyectos.data.data})
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }

    /**
     *
     *
     * @memberof ModalCotizacion
     * @function getProveedores
     *
     * @description Obtener provedores.
     */
    getProveedores = (search) => {
        axios.get('/proveedores/list', {
            params: {
                paginate: false,
                search,
            }
        }).then((proyectos) => {
            this.setState({proveedores: proyectos.data.data})
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }

    /**
     *
     *
     * @memberof ModalCotizacion
     * @function onFinish
     *
     * @description Crea una cotizacion.
     */
    onFinish = (values) => {
        console.log(values)
        if(this.props.crearCotizacion && this.props.factura === undefined){
            this.addCotizacion(values)
        }else if(this.props.crearCotizacion && this.props.factura !== undefined ){
            this.editCotizacion(values)
        }else if(this.props.crearCotizacion === false && this.props.factura !== undefined ){
            this.aprovarCotizacion(values)
        }
    }

    /**
     *
     *
     * @memberof ModalCotizacion
     * @function addCotizacion
     *
     * @description Crea una cotizacion.
     */
    addCotizacion = (values) => {
        let name = null
        if(values.archivo && values.archivo.length > 0){
            name = values.archivo[0].name
        }

        axios.post('/factura/add', {
            concepto: values.concepto,
            proyecto_id: values.proyecto_id,
            proveedor_id: this.props.proveedor ? this.props.proveedor._id : values.proveedor_id,
            monto: values.monto,
            cotizacion_pdf: name
        }).then((response) => {
            message.success('Se creo la Cotización')
            this.props.closeMethod(true)
            this.setState({uso_credito: false})
        }).catch((error) => {
            console.log(error)
            message.error('No se creo la Cotización')
        })
    }

    /**
     *
     *
     * @memberof ModalCotizacion
     * @function aprovarCotizacion
     *
     * @description actualiza una cotizacion para convertirla a Factura
     */
    aprovarCotizacion = (values) => {

        console.log(values)
        let factura_pdf = null, factura_xml = null 
        if(values.archivo.length > 0){
            values.archivo.forEach((file,index) => {
                if(index === 2) return

                if (file.type === 'text/xml') factura_xml = file.name
                else  factura_pdf = file.name

            })
        }

        axios.post('/facturas/update', {
            factura_id: this.props.factura._id,
            monto: values.monto,
            fecha_vigencia: values.fecha_vigencia,
            credito: values.credito,
            factura_pdf: factura_pdf,
            factura_xml: factura_xml,
            estatus: 2,
        }).then((response) => {
            message.success('Se aprobo la Cotización')
            this.props.closeMethod(true)
            this.setState({uso_credito: false})
        }).catch((error) => {
            console.log(error)
            if(error.response?.status === 403)
                message.error('Ha excedido la línea de crédito')
            else
                message.error('No se aprobo la Cotización')
        })
    }

    /**
     *
     *
     * @memberof ModalCotizacion
     * @function editCotizacion
     *
     * @description actualiza una cotizacion.
     */
    editCotizacion = (values) => {
        let name = null
        if(values.archivo && values.archivo.length > 0){
            name = values.archivo[0].name
        }
        axios.post('/facturas/update', {
            factura_id: this.props.factura._id,
            monto: values.monto,
            concepto: values.concepto,
            proyecto_id: values.proyecto_id,
            cotizacion_pdf: name
        }).then((response) => {
            message.success('Se actualizó la Cotización')
            this.props.closeMethod(true)
            this.setState({uso_credito: false})
        }).catch((error) => {
            console.log(error)
            message.error('No se actualizó la Cotización')
        })
    }

    /**
     *
     * @memberof ModalTransaccion
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
                fileList[x].url = `${axios.defaults.baseURL}/voucher/${fileList[x].response.filename}`
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalCuenta
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/voucher/delete", {
            filename: image
        }).then(res => {
            console.log("imagen removida con exito", res);
        }).catch(res => {
            console.log("imagen no se puedo remover", res);
        })
        //elimina el archivo del la factura
        if(this.props.crearCotizacion && this.props.factura !== undefined ){
            axios.post('/facturas/update', {
                factura_id: this.props.factura._id,
                cotizacion_pdf: null
            }).then((response) => {}).catch((error) => {
                console.log(error)
                message.error('Error al eliminar el archivo')
            })
        }
    };

    /**
     *
     * @memberof ModalCuenta
     *
     * @method beforeUpload
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    beforeUpload = (file, fileList) => {
        if(fileList.length > 1) return false
    };

    /**
    * @memberof ModalCotizacion
    *
    * @method   renderForm
    * @description  renderiza el formulario para crear una cotizacion
    *
    **/
    renderForm = () => {
        return <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                    initialValues={{
                        ...this.props.factura,
                        proveedor_id: this.props.proveedor?._id, 
                        proyecto_id: this.props.factura?.proyecto_id?._id,
                    }}
                >
                    <Row align="center">
                        <Col span={23}>
                            <Row>
                                <Col xs={22} md={11}>
                                    <Form.Item
                                        label="Concepto"
                                        name="concepto" //<--------------------------------------- concepto
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese un Concepto"
                                        }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item
                                        label="Proveedor"
                                        name="proveedor_id" //<--------------------------------------- proveedor
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione un proveedor"
                                        }]}
                                    >

                                        <Select
                                            className="form-select"
                                            onSearch={(search) => this.getProveedores(search)}
                                            showSearch
                                            filterOption={false}
                                            disabled={this.props.proveedor ? true : false}
                                        >
                                            {this.state.proveedores.map((proveedor) =>  <Option value={proveedor._id}>
                                                {proveedor.alias}
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>
                            </Row>

                            <Row >
                                <Col xs={22} md={11}>
                                    <Form.Item
                                        label="Monto"
                                        name="monto" //<--------------------------------------- monto
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese el monto"
                                        }]}
                                    >
                                        <InputNumber 
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        min={1}/>
                                    </Form.Item>
                                </Col>

                                <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item
                                        label="Proyecto"
                                        name="proyecto_id" //<--------------------------------------- proyecto_id
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione un proyecto"
                                        }]}
                                    >
                                        <Select
                                            className="form-select"
                                            onSearch={(search) => this.getProyectos(search)}
                                            showSearch
                                            filterOption={false}
                                        >
                                            {this.state.proyectos.map((proyecto) =>  <Option value={proyecto._id}>
                                                <CustomAvatar
                                                    image={proyecto.logo}
                                                    name={proyecto.nombre}
                                                    color={proyecto.color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {proyecto.nombre}
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                    <IconArrowSelect />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={22} md={11}>
                                    <Form.Item
                                        label="Archivo"
                                        name="archivo"
                                        getValueFromEvent={this.normFile}
                                        valuePropName="fileList"
                                        className="content-uploader"
                                    >
                                       <Upload
                                        className="avatar-uploader"
                                        action={axios.defaults.baseURL + "/voucher/add"}
                                        multiple={false}
                                        maxCount = {1}

                                        >
                                            <Button>
                                                <UploadOutlined /> Upload (Max: 1)
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col xs={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
    }

    /**
    * @memberof ModalCotizacion
    *
    * @method   renderCotizacion
    * @description  renderiza el formulario para crear una cotizacion
    *
    **/
    renderCotizacion = () => {
        return <>
            <Row>
                <Col span={24} className="center">
                    <Text>La cotizacion ha sido aprobada y se convertira en factura.</Text>
                </Col>
                <Col span={24} className="center pt-2 pb-2">
                    <CheckCircleFilled style={{fontSize: '65px', color: '#52c41a'}} />
                </Col>
            </Row>
            <Form
                layout="vertical"
                onFinish={this.onFinish}
                initialValues={this.props.factura}
            >
                <Row align="center">
                    <Col span={23}>
                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item
                                    label="Monto"
                                    name="monto" //<--------------------------------------- monto
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el monto"
                                    }]}
                                >
                                    <InputNumber min={1}/>
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={{span: 11, offset:2}}>
                                <Form.Item
                                    valuePropName="checked"
                                    label="Linea de Credito"
                                    name="credito" //<--------------------------------------- credito
                                >
                                   <Checkbox onChange={e=>{this.setState({uso_credito: e.target.checked})}}>Si</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row >
                            <Col xs={22} md={11}  style={{minHeight: '100px'}}>
                                <Form.Item
                                    label="PDF y XML"
                                    name="archivo"
                                    getValueFromEvent={this.normFile}
                                    valuePropName="fileList"
                                    className="content-uploader"
                                >
                                   <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    multiple={false}
                                    maxCount = {2}

                                    >
                                        <Button>
                                            <UploadOutlined /> Upload
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>

                            <Col xs={22} md={{span: 11, offset:2}}>
                                { !this.state.uso_credito ? <Form.Item
                                    label="Fecha de Vencimiento"
                                    name="fecha_vigencia" //<--------------------------------------- fecha_vigencia
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la fecha de monto"
                                    }]}
                                >
                                    <DatePicker/>
                                </Form.Item> : null}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                    <Col xs={24}>
                        <Form.Item >
                            <Button htmlType="submit" type="primary" className="btn-azul" >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{this.props.crearCotizacion ? 'Subir Cotización' : 'Cotización Aprobada'}</Title>
                </div>
                
                {this.props.crearCotizacion ? this.renderForm() : this.renderCotizacion()}
                
            </Modal>
        )
    }
}
