import React, { Component, createRef } from "react";
import { Button, Col, Form, Modal, Row, Spin, Typography } from 'antd';

import PropTypes from "prop-types";
//componentes
import { IconCloseModal } from '../../../Widgets/Iconos';
import DropzoneAntDesign from './../../../Widgets/Documents/Documents';

//css
import './../../../../Styles/modales.css'


const moment = require('moment');
const axios = require('axios').default;
moment.locale('es');
const { Title, } = Typography;


class ModalTranCliente extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            accionModal: '',
            datosConsultados: false,

            return: false,
            edicion: false,
            dataDetalleCliente: [],

            dataCuentas: [],
            det_concepto: '',
            det_fecha: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
            det_comprobantes: [],
            loading: false,
            update: false,
            titulo: "",
        }
    }

    //Referencia de form dentro del modal.
    modalTransaccion = createRef();
    refDocumentos = React.createRef();
    /**
     * @memberof ModalTran
     * @method componentDidMount
     * @description Se ejecuta al finalizar el render de la vista.
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getComprobantes()
    }


    /**
     * @memberof DetallePropiedad
     * @method getTransacciones
     * @description obtiene las transacciones hechas a una propiedad
     */
    getComprobantes = (page) => {
        this.setState({ loading: true })
        axios.get('/clientes/propiedad/get', {
            params: {
                id: this.props.propiedad?._id
            }
        })
            .then(cliente => {
                let data = cliente.data.data;
                this.state.det_comprobantes = data.documentos
                this.setState({ det_comprobantes: data.documentos })
                this.refDocumentos.current.resetFields()
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
     *
     * @memberof ModalCuenta
     *
     * @method normFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = async (e) => {
        const { file, fileList } = e;
        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].status !== "done" && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].status === "done" && !upload && this.state.loading) {
                this.state.loading = false;
                this.setState({ update: !this.state.update })
                upload = false;
                break;
            } else upload = true;
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalCuenta
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/voucher/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con éxito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };


    eliminarDocumento = async (doc, index) => {
        console.log("Eliminar archivo")
        console.log(doc)
        console.log(index)

        var docs = this.refDocumentos.current.getFieldValue('documentos')

        let filesTemp = docs;
        filesTemp.splice(index, 1);

        axios.post('/clientes/propiedad/update', {
            propiedad_id: this.props.propiedad?._id,
            documentos: docs.map(doc => doc.name),
        })
            .then((e) => {
                this.props.onClose()
            })
    };


    


    saveDocumentos = async (values) => {
        axios.post('/clientes/propiedad/update', {
            propiedad_id: this.props.propiedad?._id,
            documentos: values.documentos.map(doc => doc.name),
        })
            .then((e) => {
                this.props.onClose()
            })
    }



    render() {
        return (
            [
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.props.onClose}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Documentos</Title>
                </div>,
                <Spin spinning={this.state.spinning}>
                    <Form
                        layout="vertical"
                        ref={this.refDocumentos}
                        className="frm-transacciones"
                        name="formulario-transacciones"
                        //getValueFromEvent={normFile}
                        onFinish={this.saveDocumentos}
                        initialValues={{
                            documentos: this.state.det_comprobantes.map(file => {
                                return {
                                    lastModified: new Date().getMilliseconds(),
                                    lastModifiedDate: new Date(),
                                    name: file,
                                    url: axios.defaults.baseURL + '/upload/' + file,
                                    status: 'start',
                                    percent: 0,
                                    deleteFileEvent: (e, index) => this.eliminarDocumento(e, index)
                                }
                            })
                        }}
                    >
                        <Row style={{ textAlign: "center" }}>
                            <Col span={24} className="colDocsComprobantes">
                                <Form.Item
                                    name="documentos"
                                    valuePropName="fileList"
                                >
                                    <DropzoneAntDesign
                                        type="list"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-azul"
                                        disabled={this.state.loading}>
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>

            ]
        )
    }
}
export default function (props) {

    const { visible, onClose } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        className={"modal-form"}
        destroyOnClose={true}
        zIndex={1000}
    >
        <ModalTranCliente {...props} />
    </Modal >

}