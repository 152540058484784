import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, List, Typography, message, Spin, InputNumber } from 'antd';
import './../../../../Styles/modales.css'

import { IconCloseModal } from '../../../Widgets/Iconos';


import axios from 'axios'
const moment = require('moment');

const { Title, } = Typography;

class ModalSubConcepto extends Component {

    constructor(props) {
        super(props);
        this.state = {
        	loading: false
            
        }
    }

    //Referencia de form
    formConcepto = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.sub_concepto_id)
        	this.getSubConcepto()
    }

    /**
    * @memberof ModalSubConcepto
    * @method onFinish
    * @description Se ejecuta al realizar submit al formulario
    **/
    onFinish = (values) => {
    	if(this.props.sub_concepto_id){
    		this.updateSubConcepto(values)
    	}else{
    		this.addSubConcepto(values)
    	}
    }

    /**
    * @memberof ModalSubConcepto
    * @method getSubConcepto
    * @description Obtiena la informacion del Almacen
    **/
    getSubConcepto = () => {
    	this.setState({loading: true})
    	axios.get('/sub-conceptos/get',{
    		params:{
    			sub_concepto_id: this.props.sub_concepto_id
    		}
    	}).then(response => {
    		return this.formConcepto.current?.setFieldsValue({
    			nombre: response.data.data.nombre,
                presupuesto: response.data.data.presupuesto,
    		})
    		
    	}).catch(error => {
    		let msg = 'Error al obtener el subconcepto'
    		if(error.response?.status === 403)
    			msg = error.response.data.message
    		message.error(msg)
    	}).finally(()=>this.setState({loading: false}))
    }

    /**
    * @memberof ModalSubConcepto
    * @method addSubConcepto
    * @description Añade una nuevo almacen
    **/
    addSubConcepto = (values) => {
    	this.setState({loading: true})
    	axios.post('/sub-conceptos/add',{
    		...values,
            proyecto_id: this.props.proyecto_id,
            concepto_id: this.props.concepto_id,
    	}).then(response => {
    		message.success('subconcepto creado con exito')
    		this.props.onCancel()
    	}).catch(error => {
            console.log("error", error);
    		let msg = 'Error al crear el subconcepto'
    		if(error.response?.status === 403)
    			msg = error.response.data.message
    		message.error(msg)
    	}).finally(()=>this.setState({loading: false}))
    }

    /**
    * @memberof ModalSubConcepto
    * @method updateSubConcepto
    * @description Actualiza un almacen
    **/
    updateSubConcepto = (values) => {
    	this.setState({loading: true})
    	axios.post('/sub-conceptos/update',{
    		...values,
    		concepto_id: this.props.concepto_id,
            proyecto_id: this.props.proyecto_id,
            sub_concepto_id: this.props.sub_concepto_id,
    	}).then(response => {
    		message.success('subconcepto actualizado con éxito')
    		this.props.onCancel()
    	}).catch(error => {
    		let msg = 'Error al actualizar el suboncepto'
    		if(error.response?.status === 403)
    			msg = error.response.data.message
    		message.error(msg)
    	}).finally(()=>this.setState({loading: false}))
    }




    render() {


        return [
            <div className="modal-header flex">
                <Button type="ghost" className="flex-left btn-close-modal" title="Cerrar" onClick={this.props.onCancel}>
                    <IconCloseModal />
                </Button>
                <Title level={3} className="modal-title"> {this.props.sub_concepto_id ? 'Editar' : 'Añadir'} Sub Concepto</Title>
            </div>,
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.formConcepto}
                    onFinish={this.onFinish}

                >
                    <Row align="center" gutter={[16]}>

                        <Col xs={22}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[
                                    { required: true, message: "Ingrese el nombre del subconcepto" }
                                ]} >
                                <Input placeholder="Nombre" maxLength={50}/>
                            </Form.Item>
                        </Col>

                        <Col xs={22}>
                            <Form.Item
                                label="Presupuesto"
                                name="presupuesto"
                                rules={[
                                    { required: true, message: "Ingrese el presupuesto" }
                                ]} >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder="Presupuesto"
                                />
                            </Form.Item>
                        </Col>
                    </Row>  

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        ]
    }
}

export default function (props) {

    const {visible, onCancel} = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-form"
        destroyOnClose={true}
        zIndex={1000}

    >
        <ModalSubConcepto {...props} />
    </Modal>


}
