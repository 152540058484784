import React, { Component } from 'react';
import { Button, Col, Row, Select, Typography, message, Form, Modal, Space } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
//componentes
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import ListasRequeridos from './Listas/ListaCotizacionRequerido';
import ListaCotizaciones from './Listas/ListaCotizaciones';
import ListaEntregasRecoleccion from './Listas/ListaEntregasRecoleccion';
import ListaEntregado from './Listas/ListaEntregado';
import { IconPlus, IconArrowBack, IconPDFColor } from '../../Widgets/Iconos';
//modal

//css
import './../../../Styles/dashboard.css'

const { Title,Text } = Typography;
const moment = require('moment')
const { Option } = Select;



/**
 * @class CotizacionMaterialesComprador
 * @description Vista que se encarga de desplegar las requsiciones divididas por estatus, al comprador, que se encarga de realizar las cotizaciones de cada requesicion
 */
export default class CotizacionMaterialesComprador extends Component {

    volver = () => {
        window.history.back();
    }

    constructor(props){
        super(props)
        this.state = {
            modal_visible: false,
            modal_detalle: false,
            proyectos: [],
            proyecto_id: undefined,

            search:  this.props.search,
        }
    }

    estatus_0 = React.createRef()
    estatus_1 = React.createRef()
    estatus_2 = React.createRef()
    estatus_3 = React.createRef()
    estatus_4 = React.createRef()
    estatus_5 = React.createRef()
    estatus_6 = React.createRef()

    /**
     *
     * @methodOf CotizacionMaterialesComprador
     * @function componentDidMount
     * @description Obtenemos los proyectos y las Areas
     */
    componentDidMount = () => {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);
        window.scrollTo(0, 0);
        this.getProyectos()
    }

    componentDidUpdate(prevProps){

        if(prevProps.search != this.props.search){
            this.setState({
                search: this.props.search
            })
        }

    }

    /**
     *
     * @methodOf CotizacionMaterialesComprador
     * @function openModal
     * @description Abre el modal para per el detalle de la requesicion
     */
    openModal = () => {
        this.setState({modal_detalle: true})
    }

    /**
     *
     * @methodOf CotizacionMaterialesComprador
     * @function getRequisiciones
     * @description Obtiene las requesiciones del estatus 1
     */
    getRequisiciones = () => {
        try{
            this.estatus_1.current.getRequisiciones()
            this.estatus_2.current.getRequisiciones()
            this.estatus_3.current.getRequisiciones()
            this.estatus_4.current.getRequisiciones()
            this.estatus_5.current.getRequisiciones()
        }catch(e){
            message.error('Error al actualizar')
        }
        
    }

    /**
     *
     * @methodOf CotizacionMaterialesComprador
     * @function getRequisicionesEntregas
     * @description Obtiene las requesiciones del estatus 1
     */
    getRequisicionesEntregas = (flag) => {
        try{
            this.estatus_4.current.getRequisiciones()
            this.estatus_5.current.getRequisiciones()
            this.estatus_6.current.getRequisiciones()
            if(flag === true) 
                this.estatus_1.current.getRequisiciones()
        }catch(e){
            message.error('Error al actualizar')
        }
        
    }

    /**
    * @memberof CotizacionMaterialesComprador
    * @function getProyectos
    *
    * @description Obtener proyectos.
    */
    getProyectos = (search) => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
                search,
            }
        }).then((proyectos) => {
            this.setState({ proyectos: proyectos.data.data })

        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los proyectos.",
                content: "No fue posible obtener los proyectos."
            })
        })
    }


    /**
     *
     *
     * @memberof CotizacionMaterialesComprador
     * 
     * @method getPDF
     * @description Generamos el PDF de la cotizacion
     */
    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/requisiciones/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('estatus', [4,5])
        urlPDF.searchParams.append('proyecto_id', this.state.proyecto_id)
        return urlPDF.href
    }


    render() {
        const { onDatesChange, onProjectsChange } = this;
        const { start, end, projectsSelected, area, rubro } = this.state;
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={10} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <Title level={4} className="view-title">Cotización de Materiales</Title>
                    </Col>
                    <Col xs={24} sm={11}  className="center modal-form">
                        <Space>
                            <Select
                                className="form-select"
                                onSearch={(search) => this.getProyectos(search)}
                                onSelect={(proyecto_id) => this.setState({proyecto_id})}
                                showSearch
                                filterOption={false}
                                placeholder="Selecciona un Proyecto"
                                style={{minWidth: '300px'}}
                            >
                                {this.state.proyectos.map((proyecto) => <Option value={proyecto._id}>
                                    <CustomAvatar
                                        image={proyecto.logo}
                                        name={proyecto.nombre}
                                        color={proyecto.color}
                                        size="small"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    />
                                    {proyecto.nombre}
                                </Option>)}
                            </Select>
                            {this.state.proyecto_id  ? <a href={this.getPDF()} download target="_blank">
                                    <Button 
                                    size="large" 
                                    icon={<IconPDFColor/>}
                                    style={{
                                        height: '50px',
                                        borderRadius: '6px'
                                    }}/>
                            </a>: <Button 
                                onClick={()=>Modal.error({
                                        title: "No hay ningún proyecto seleccionado",
                                        content: "Seleccione uno para obtener el archivo"
                                    })}
                                size="large" 
                                icon={<IconPDFColor/>}
                                style={{
                                    height: '50px',
                                    borderRadius: '6px'
                                }}
                            />}
                        </Space>

                    </Col>
                </Row>

                <section className="p-1">

                    <ListasRequeridos ref={this.estatus_0} getRequisiciones={this.getRequisiciones} search={this.state.search}/>

                    <ListaCotizaciones title="Cotizado" estatus={1} ref={this.estatus_1} search={this.state.search} getRequisiciones={this.getRequisiciones}/>

                    <ListaCotizaciones title="Pausado" estatus={2} ref={this.estatus_2} search={this.state.search} getRequisiciones={this.getRequisiciones}/>

                    <ListaCotizaciones title="Cancelado" estatus={3} ref={this.estatus_3} search={this.state.search} getRequisiciones={this.getRequisiciones}/>

                    <ListaEntregasRecoleccion 
                        title="Entrega" 
                        estatus={4} 
                        ref={this.estatus_4} 
                        search={this.state.search} 
                        getRequisicionesEntregas={this.getRequisicionesEntregas} />

                    <ListaEntregasRecoleccion title="Recoleccion" estatus={5} ref={this.estatus_5} search={this.state.search} getRequisicionesEntregas={this.getRequisicionesEntregas}/>

                    <ListaEntregado title="Entregado" estatus={6} ref={this.estatus_6} search={this.state.search} getRequisicionesEntregas={this.getRequisicionesEntregas}/>

                </section>

            </div>
        )
    }
}
    