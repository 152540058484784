import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Upload, Select, InputNumber, AutoComplete, Divider } from 'antd';


import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
const axios = require('axios').default;


const { Title, Text } = Typography;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class FormProducto
 * @extends {Component}
 */
class FormProducto extends Component {


    /**
     *
     *
     * @static
     * @memberof FormProducto
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            proveedores: [],
            prod: {},
            claves: {
                data: [],

                page: 1,
                limit: 20,

                total: 0,
                pages: 0,
                search: null
            },
            categorias: [],
            sub_categorias: [],
            imagenes: [],
            skus_manantial: [],
            value: null,
            sku_cargado: false,
        }
    }

    formModalProductos = React.createRef();
    categoriaRef = React.createRef();
    subCategoriaRef = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.id !== undefined)
            this.get()

        this.getProveedores();
        this.getCategorias();
        this.formatSku();
    }

    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method get
     * @description Obtenemos la informacion de producto (padre e hijo en uno)
     */
    get = (id = this.props.id) => {
        this.setState({ loading: true })
        axios.get("/producto/get", {
            params: { id }
        })
            .then(async ({ data }) => {
                data = data.data
                console.log("data", data);

                this.formModalProductos.current.setFieldsValue({
                    ...data,
                    sku_fabricante: data.producto.sku_fabricante,
                    fabricante: data.producto.fabricante,
                    nombre: data.producto.nombre,//data.nombre,
                    sku_manantial: data.producto.sku_manantial,
                    unidad: data.producto.unidad,
                    categoria_id: data.producto.categoria_id,
                    sub_categoria_id: data.producto.sub_categoria_id,
                    imagenes: data.producto.imagenes.map(({ name, filename }, index) => ({
                        uid: index,
                        name: name,
                        filename: filename,
                        status: 'done',
                        url: axios.defaults.baseURL + '/upload/' + filename
                    })),
                })

                this.setState({
                    producto_code: data.producto.codigo_string,
                    imagenes: data.producto.imagenes,
                })

                this.onChangeCategoria(data.producto.categoria_id, false)
            })
            .catch(res => {
                message.error('No se pudo cargar el Producto')
                console.log("no obtenida", res);
            }).finally(() => this.setState({ loading: false }))
    }


    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method onFinish
     * @description Cuando se guarda el producto
     */
    onFinish = values => {
        return (this.props.id !== undefined) ? this.update(values) : this.save(values)
    }

    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method save
     * @description Guardamos el producto
     */
    save = (values) => {
        console.log("values", values);

        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            axios.post('/producto/add', { ...values, id: values._id })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el producto!")
                    this.props.onCancel();
                })
                .catch((e) => {
                    message.error('No se pudo guardar el producto')
                    console.log('e', e)
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        })
    }

    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method update
     * @description Actualizamos el producto.
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/producto/update', { ...values, id: this.props.id })
            .then(() => {
                message.success("¡Se ha guardado correctamente el producto!")
                this.props.onCancel();
            })
            .catch((e) => {
                message.error('No se pudo guardar el producto')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }


    /**
    * @memberof ModalTransacciones
    * @method getProveedores
    * @description Trae los proveedores en lista
    */
    getProveedores = (search) => {
        axios.get('/proveedores/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {
            this.setState({ proveedores: response.data.data })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Negocios')
        })
    }


    /**
    * @memberof ModalTransacciones
    * @method getSKU
    * @description Obtiene la informacion del producto padre por medio del sku_fabricante, y llena la informacion del formualario
    */
    getSKU = (sku) => {

        axios.get('/producto/getSKU', {
            params: {
                sku,
                paginate: false
            }
        }).then(response => {

            this.getCategorias()

            this.formModalProductos.current.setFieldsValue({
                sku_fabricante: response.data.data.sku_fabricante,
                producto_id: response.data.data._id,
                fabricante: response.data.data.fabricante,
                sku_manantial: response.data.data.sku_manantial,
                nombre: response.data.data.nombre,

                imagenes: response.data.data.imagenes.map(({ name, filename }, index) => ({
                    uid: index,
                    name: name,
                    filename: filename,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + filename
                })),

                categoria_id: response.data.data.categoria_id,
                sub_categoria_id: response.data.data.sub_categoria_id,
                unidad: response.data.data.unidad
            })

            this.setState({
                producto_code: response.data.data.codigo_string,
                sku_cargado: true,
            })

            this.onChangeCategoria(response.data.data.categoria_id, false)

            message.success('Se encontro un Producto')
        }).catch(error => {

            this.setState({
                producto_code: this.props.id ? this.state.producto_code : this.props.producto_code,
                sku_cargado: false,
            })

            this.formModalProductos.current.setFieldsValue({
                fabricante: null,
                categoria_id: null,
                sub_categoria_id: null,
                unidad: null
            })

            this.formatSku()
        })
    }



    /**
     * @memberOf ModalProductos
     * @method addCategoria
     * @descripcion Añade una categoria nueva a la BD y la selecciona automaticamente
    */
    addCategoria = () => {

        let nombre = this.categoriaRef.current.input?.value;
        if (nombre !== null && nombre !== '' && nombre !== undefined) {
            axios.post('/categorias/add', {
                nombre
            }).then(response => {
                let categoria = response.data.data
                this.setState({
                    categorias: [...this.state.categorias, { ...categoria }],
                    value: ''
                })

                this.onChangeCategoria(categoria._id)

                return this.formModalProductos.current?.setFieldsValue({
                    categoria_id: categoria._id
                })



            }).catch(error => {
                let msg = 'Error al crear la categoria'
                if (error.response?.status === 403)
                    msg = error.response?.data?.message
                message.error(msg)
            })
        }
    }

    /**
    * @memberOf ModalProductos
    * @method addSubCategoria
    * @descripcion Añade una categoria nueva a la BD y la selecciona automaticamente
   */
    addSubCategoria = () => {

        let nombre = this.subCategoriaRef.current.input?.value;
        if (nombre !== null && nombre !== '' && nombre !== undefined) {
            axios.post('/sub-categorias/add', {
                nombre,
                categoria_id: this.state.categoria_id,
            }).then(response => {
                let sub_categoria = response.data.data
                this.setState({
                    sub_categorias: [...this.state.sub_categorias, { ...sub_categoria }],
                    value_sub_categoria: ''
                })
                
                this.onChangeSubCategoria(sub_categoria._id)

                return this.formModalProductos.current?.setFieldsValue({
                    sub_categoria_id: sub_categoria._id
                })


            }).catch(error => {
                let msg = 'Error al crear la sub categoria'
                if (error.response?.status === 403)
                    msg = error.response?.data?.message
                message.error(msg)
            })
        }
    }

    /**
     * @memberOf ModalProductos
     * @method getCategorias
     * @descripcion Obtiene la lista de categorias
    */
    getCategorias = (search) => {
        axios.get('/categorias/list', {
            params: {
                paginate: false,
                search
            }
        }).then(response => {
            this.setState({ categorias: response.data.data })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtner las categorias')
        })
    }

    /**
     * @memberOf ModalProductos
     * @method onChangeCategoria
     * @descripcion Obtiene la lista de categorias
    */
    onChangeCategoria = (categoria_id, update = true) => {
        axios.get('/sub-categorias/list', {
            params: {
                paginate: false,
                categoria_id
            }
        }).then(response => {

            if (update) {
                this.formModalProductos.current.setFieldsValue({
                    sub_categoria_id: null
                });
            }

            this.setState({
                sub_categorias: response.data.data,
                editCategoria: false,
                editSubCategoria: false,
                edit_categoria_id: undefined,
                edit_sub_categoria_id: undefined,
                value: '',
                value_sub_categoria: '',
                categoria_id
            }, () => {
                if (update) {
                    this.formatSku()
                }
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtner las categorias')
        })
    }

    /**
     * @memberOf ModalProductos
     * @method getSubCategorias
     * @descripcion Obtiene la lista de sub categorias
    */
    getSubCategorias = (search) => {
        axios.get('/sub-categorias/list', {
            params: {
                paginate: false,
                categoria_id: this.state.categoria_id,
                search
            }
        }).then(response => {

            this.setState({
                sub_categorias: response.data.data,
            }, () => {
                this.formatSku()
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtner las categorias')
        })
    }


    /**
     * @memberOf ModalProductos
     * @method formatSku
     * @descripcion formatea el codifo sku_manantial, dependiendo del la categoria y sub categoria seleccionada, 
     * los ultimos 5 digitos son los correspondientes a si es un producto nuevo o uno ya creado
    */
    formatSku = () => {


        let categoria_code = '000', sub_categoria_code = '000', producto_code = this.props.id ? this.state.producto_code : this.props.producto_code


        let categoria_id = this.state.categoria_id, sub_categoria_id = this.state.sub_categoria_id

        let index_c = this.state.categorias.findIndex(cat => cat._id.toString() === categoria_id)

        if (index_c != -1) {
            categoria_code = this.state.categorias[index_c].codigo
        }

        let index_s = this.state.sub_categorias.findIndex(cat => cat._id.toString() === sub_categoria_id)

        if (index_s != -1) {
            sub_categoria_code = this.state.sub_categorias[index_s].codigo
        }

        this.formModalProductos.current.setFieldsValue({
            sku_manantial: `${categoria_code}-${sub_categoria_code}-${producto_code}`
        });

    }


    /**
     * @memberOf ModalProductos
     * @method onChangeSubCategoria
     * @descripcion Obtiene la lista de categorias
    */
    onChangeSubCategoria = (sub_categoria_id) => {
        this.setState({ 
            sub_categoria_id,
            editCategoria: false,
            editSubCategoria: false,
            edit_categoria_id: undefined,
            edit_sub_categoria_id: undefined,
            value: '',
            value_sub_categoria: '',
        }, () => { this.formatSku() })
    }

    /**
     *
     * @memberof ModalProductos
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file, fileList } = e;
        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
                fileList[x].name = fileList[x].response.name
            }
        }


        if (file.status === "done") {
            let imagenes = this.state.imagenes

            imagenes.push({
                name: file.response.name,
                filename: file.response.filename,
            })

            this.setState({ imagenes })

            e.file.filename = file.response.filename

        }
        return e.fileList;
    };

    /**
     *
     * @memberof ModalComprobante
     *
     * @method removeFile
     * @description Quita el archivo del state de comprobantes para ponerlos en files_to_delete, asi cuando el usuario guarde, se eliminan, y no se borren automaticamente
     */
    deleteImage = (file) => {
        let imagenes = this.state.imagenes
        let index = imagenes.findIndex(img => img.filename === file.filename)
        if (index != -1) {
            imagenes.splice(index, 1)
        }
    }


    /**
     *
     * @memberof ModalHacienda
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con éxito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     * @memberOf ModalProductos
     * @method onSearchSKU
     * @descripcion Obtiene la lista de productos padre, por medio de la busqueda del sku_manantial
    */
    onSearchSKU = (search) => {
        axios.get('/productos/list', {
            params: {
                search,
            }
        }).then(response => {
            this.setState({
                skus_manantial: response.data.data.data.map(e => ({
                    label: <Text>{e.sku_manantial} <small>{e.fabricante}</small></Text>,
                    value: e.sku_manantial
                }))
            })

        }).catch(error => {
            console.log("error", error);

        })
    }


    /**
     * @memberOf ModalProductos
     * @method editCategoria
     * @descripcion Pone la categoria en el input para editar
    */
    editCategoria = (categoria_id) => {
        let categoria = this.state.categorias.find(cat => cat._id.toString() === categoria_id.toString())
        if(categoria){
            this.setState({editCategoria: true, edit_categoria_id: categoria_id, value: categoria.nombre})
        }
    }

    /**
     * @memberOf ModalProductos
     * @method editSubCategoria
     * @descripcion Pone la categoria en el input para editar
    */
    editSubCategoria = (sub_categoria_id) => {
        let sub_categoria = this.state.sub_categorias.find(cat => cat._id.toString() === sub_categoria_id.toString())
        if(sub_categoria){
            this.setState({editSubCategoria: true, edit_sub_categoria_id: sub_categoria_id, value_sub_categoria: sub_categoria.nombre})
        }
    }

    /**
     * @memberOf ModalProductos
     * @method updateCategoria
     * @descripcion actualiza la categoria en la bd y en el front, y lo selcciona automaticamente
    */
    updateCategoria = () => {
        axios.post('/categorias/update',{
            categoria_id: this.state.edit_categoria_id,
            nombre: this.state.value
        }).then(response => {
            let index = this.state.categorias.findIndex(cat => cat._id.toString() === this.state.edit_categoria_id.toString())
            let arr = [...this.state.categorias]

            if(index !== -1){
                arr[index] = response.data.data
            }

            this.setState({
                categorias: arr,
                value: '',
                edit_categoria_id: undefined,
                editCategoria: false,
                editSubCategoria: false
            })

            this.onChangeCategoria(response.data.data._id)

            this.formModalProductos.current.setFieldsValue({
                categoria_id: response.data.data._id
            })

            message.success('Categoria actualizada')
        }).catch(error => {
            let msj = 'Error al actualizar'
            if(error.response.status === 403){
                msj = error.response.data.message
            }
            message.error(msj)
        })
    }

    /**
     * @memberOf ModalProductos
     * @method updateSubCategoria
     * @descripcion actualiza la categoria en la bd y en el front, y lo selcciona automaticamente
    */
    updateSubCategoria = () => {
        axios.post('/sub-categorias/update',{
            sub_categoria_id: this.state.edit_sub_categoria_id,
            nombre: this.state.value_sub_categoria
        }).then(response => {
            let index = this.state.sub_categorias.findIndex(cat => cat._id.toString() === this.state.edit_sub_categoria_id.toString())
            let arr = [...this.state.sub_categorias]

            if(index !== -1){
                arr[index] = response.data.data
            }

            this.setState({
                sub_categorias: arr,
                value_sub_categoria: '',
                edit_sub_categoria_id: undefined,
                editSubCategoria: false
            })


            this.formModalProductos.current.setFieldsValue({
                sub_categoria_id: response.data.data._id
            })

            message.success('Sub Categoria actualizada')
        }).catch(error => {
            console.log("error", error);
            message.error('Error al actualizar')
        })
    }


     /**
     * @memberOf ModalProductos
     * @method modalDelete
     * @descripcion Abre el modal para confirmar la eliminacion de una categoria
    */
    modalDelete = (categoria_id) => {
        return Modal.confirm({
            title: 'Eliminar Categoria',
            content: '¿Seguro de eliminar esta categoria?',
            onOk:()=>{
                this.deleteCategoria(categoria_id)
            },
            cancelText: 'Cancelar',
            okText: 'Si'
        })
    }

    /**
     * @memberOf ModalProductos
     * @method modalDeleteSub
     * @descripcion Abre el modal para confirmar la eliminacion de una sub categoria
    */
    modalDeleteSub = (sub_categoria_id) => {
        return Modal.confirm({
            title: 'Eliminar Sub Categoria',
            content: '¿Seguro de eliminar esta sub categoria?',
            onOk:()=>{
                this.deleteSubCategoria(sub_categoria_id)
            },
            cancelText: 'Cancelar',
            okText: 'Si'
        })
    }

    /**
     * @memberOf ModalProductos
     * @method deleteCategoria
     * @descripcion Elimina una categoria
    */
    deleteCategoria = (categoria_id) => {
        axios.post('/categorias/delete',{
            categoria_id
        }).then(response => {
            this.getCategorias()
            this.setState({
                categoria_id: undefined, 
                sub_categoria_id: undefined,
                editCategoria: false,
                editSubCategoria: false,
                edit_categoria_id: undefined,
                edit_sub_categoria_id: undefined,
                value: '',
                value_sub_categoria: '',
            })
            this.formModalProductos.current.setFieldsValue({
                categoria_id: null,
                sub_categoria_id: null
            })
            message.success('Categoria eliminada')
        }).catch(error => {
            let msj = 'Error al eliminar'
            if(error.response.status === 403){
                msj = error.response.data.message
            }
            message.error(msj)
        })
    }

    /**
     * @memberOf ModalProductos
     * @method deleteSubCategoria
     * @descripcion Elimina una categoria
    */
    deleteSubCategoria = (sub_categoria_id) => {
        axios.post('/sub-categorias/delete',{
            sub_categoria_id
        }).then(response => {
            this.getSubCategorias()
            this.setState({
                sub_categoria_id: undefined,
                editSubCategoria: false,
                edit_sub_categoria_id: undefined,
                value_sub_categoria: '',
            })
            this.formModalProductos.current.setFieldsValue({
                sub_categoria_id: null
            })
            message.success('Sub Categoria eliminada')
        }).catch(error => {
            console.log("error", error);
            let msj = 'Error al eliminar'
            if(error.response.status === 403){
                msj = error.response.data.message
            }
            message.error(msj)
        })
    }

    render() {
        const { normFile, formModalProductos, } = this;
        const { form, loading, } = this.state;
        return (

            <Spin spinning={loading}>
                <Title level={3} className="text-center mb-1">{this.props.id ? "Editar Producto" : "Nuevo Producto"}</Title>
                <Form
                    layout="vertical"
                    ref={formModalProductos} initialValues={form} onFinish={this.onFinish} >
                    <Row gutter={[16, 0]} justify="center">

                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="SKU Fabricante"
                                name="sku_fabricante"
                                rules={[
                                    { required: true, message: "Ingrese el SKU del fabricanta" }
                                ]}
                            >
                                <Input 
                                    placeholder="SKU Fabricante" 
                                    onChange={(value)=>this.getSKU(value.target.value)}
                                    max={100}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Marca (Fabricante)"
                                name="fabricante"
                                rules={[
                                    { required: true, message: "Ingrese la marca del fabicante" }
                                ]}
                            >
                                <Input placeholder="Fabricante" disabled={!this.props.id && this.state.sku_cargado} max={100}/>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="SKU Grupo Manantial"
                                name="sku_manantial"
                                rules={[
                                    { required: true, message: "Ingrese el SKU" }
                                ]} >
                                <AutoComplete
                                    disabled={this.props.id}
                                    placeholder="SKU Manantial"
                                    options={this.state.skus_manantial}
                                    onSearch={this.onSearchSKU}
                                    onSelect={this.getSKU}
                                    allowClear={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item

                                disabled={this.props.id}
                                label="Nombre"
                                name="nombre"
                                rules={[
                                    { required: true, message: "Ingrese el nombre" }
                                ]} >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>

                        <Col xs={22} lg={11}>
                            <Form.Item
                                label="Categoria"
                                name="categoria_id"
                                rules={[
                                    { required: true, message: "Seleccione la categoria del producto" }
                                ]} >
                                <Select
                                    placeholder="Categoria"
                                    className="form-select select-actions"
                                    onChange={(value) => this.onChangeCategoria(value)}
                                    disabled={!this.props.id && this.state.sku_cargado}
                                    onSearch={(search) => this.getCategorias(search)}
                                    showSearch
                                    filterOption={false}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input
                                                    style={{ flex: 'auto' }}
                                                    ref={this.categoriaRef}
                                                    value={this.state.value}
                                                    onChange={(e) => this.setState({ value: e.target.value })}
                                                />
                                                {this.state.editCategoria ? <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.updateCategoria} >
                                                    <IconEdit /> Editar
                                                </a>
                                                : <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.addCategoria} >
                                                    <PlusOutlined /> Añadir
                                                </a>}
                                            </div>
                                        </div>
                                    )}
                                >
                                    {this.state.categorias?.map(categoria => {
                                        return <Option 
                                            className="option-actions"
                                            key={categoria._id} 
                                            value={categoria._id}>

                                                    {categoria.nombre}
                                                    

                                                    <Button
                                                        className="btn btn-ghost float-right"
                                                        type="ghost"
                                                        icon={<IconDelete/>}
                                                        onClick={(e)=>{
                                                            e.stopPropagation()
                                                            this.modalDelete(categoria._id)
                                                        }}
                                                    />

                                                    <Button
                                                        className="btn btn-ghost float-right"
                                                        type="ghost"
                                                        icon={<IconEdit/>}
                                                        onClick={(e)=>{
                                                            this.editCategoria(categoria._id)
                                                            e.stopPropagation()
                                                        }}
                                                    />

                                            </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={22} lg={11}>
                            <Form.Item
                                label="Sub Categoria"
                                name="sub_categoria_id"
                                rules={[
                                    { required: true, message: "Seleccione la categoria del producto" }
                                ]} >
                                <Select
                                    placeholder="Sub Categoria"
                                    className="form-select select-actions"
                                    onChange={(value) => this.onChangeSubCategoria(value)}
                                    disabled={!this.props.id && this.state.sku_cargado}
                                    onSearch={(search) => this.getSubCategorias(search)}
                                    showSearch
                                    filterOption={false}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input
                                                    style={{ flex: 'auto' }}
                                                    ref={this.subCategoriaRef}
                                                    value={this.state.value_sub_categoria}
                                                    onChange={(e) => this.setState({ value_sub_categoria: e.target.value })}
                                                />
                                                {this.state.editSubCategoria ? <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.updateSubCategoria} >
                                                    <IconEdit /> Editar
                                                </a> : <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.addSubCategoria} >
                                                    <PlusOutlined /> Añadir
                                                </a>}
                                            </div>
                                        </div>
                                    )}
                                >
                                    {this.state.sub_categorias?.map(sub_categoria => {
                                        return <Option 
                                            className="option-actions"
                                            key={sub_categoria._id} 
                                            value={sub_categoria._id}
                                        >
                                            {sub_categoria.nombre}

                                            <Button
                                                className="btn btn-ghost float-right"
                                                type="ghost"
                                                icon={<IconDelete/>}
                                                onClick={(e)=>{
                                                    e.stopPropagation()
                                                    this.modalDeleteSub(sub_categoria._id)
                                                }}
                                            />

                                            <Button
                                                className="btn btn-ghost float-right"
                                                type="ghost"
                                                icon={<IconEdit/>}
                                                onClick={(e)=>{
                                                    this.editSubCategoria(sub_categoria._id)
                                                    e.stopPropagation()
                                                }}
                                            />
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={22} lg={11}>
                            <Form.Item
                                label="Unidad"
                                name="unidad"
                                rules={[
                                    { required: true, message: "Seleccione la unidad de medida del producto" }
                                ]} >
                                <Select
                                    placeholder="Unidad"
                                    className="form-select"
                                    disabled={!this.props.id && this.state.sku_cargado}
                                >
                                    <Option key={'BUT'} value={'BUT'}>Bulto</Option>
                                    <Option key={'XBX'} value={'XBX'}>Caja</Option>
                                    <Option key={'SET'} value={'SET'}>Conjunto</Option>
                                    <Option key={'EA'} value={'EA'}>Elemento</Option>
                                    <Option key={'GRM'} value={'GRM'}>Gramo</Option>
                                    <Option key={'KGM'} value={'KGM'}>Kilogramo</Option>
                                    <Option key={'KT'} value={'KT'}>Kit</Option>
                                    <Option key={'LTR'} value={'LTR'}>Litro</Option>
                                    <Option key={'MTR'} value={'MTR'}>Metro</Option>
                                    <Option key={'H87'} value={'H87'}>Pieza</Option>
                                    <Option key={'TON'} value={'TON'}>Tonelada</Option>
                                    <Option key={'xun'} value={'xun'}>Unidad</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={22} lg={11}>
                            {/**/}
                        </Col>

                        <Col xs={22} lg={22}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                                rules={[
                                    { required: true, message: "Ingrese la descripción" }
                                ]} >
                                <Input.TextArea placeholder="Descripción" />
                            </Form.Item>
                        </Col>

                        <Col xs={22}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[
                                    { required: true, message: "Seleccione el Proveedor" }
                                ]} >
                                <Select
                                    className="form-select"
                                    placeholder="Seleccione el Proveedor"
                                    showSearch
                                    onSearch={(value) => this.getProveedores(value)}
                                    filterOption={false}
                                >
                                    {this.state.proveedores.map(({ alias, _id }) => <Option key={_id} value={_id}>{alias}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="SKU Proveedor"
                                name="sku_proveedor"
                                rules={[
                                    { required: true, message: "Ingrese el SKU del proveedor" }
                                ]} >
                                <Input placeholder="SKU Proveedor" />
                            </Form.Item>
                        </Col>

                        <Col xs={22} lg={11}>
                            <Form.Item
                                label="Costo (con IVA)"
                                name="costo"
                                rules={[
                                    { required: true, message: "Ingrese el costo" }
                                ]}>
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder="Costo"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={22} lg={11}>
                            <Form.Item
                                label="Marca"
                                name="producto_id"
                                hidden='true'
                            >
                                <Input placeholder="Marca" />
                            </Form.Item>
                        </Col>

                        <Col xs={22} >
                            <Form.Item
                                label="Imagenes del producto"
                                name="imagenes"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                            >
                                <Upload
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    multiple={true}
                                    listType="picture-card"
                                    accept="image/*"
                                    onRemove={this.deleteImage}
                                >
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Subir Archivo</div>
                                    </div>
                                </Upload>
                            </Form.Item>

                        </Col>

                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul" loading={this.state.loading} >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            className="modal-form"
        >
            <FormProducto {...props} />
        </Modal>
    )
}
