import React, { Component, createRef } from "react";
import {
    Avatar, Button, Col, DatePicker, Form, Input, InputNumber, List,
    message, Modal, Row, Select, Spin, Statistic, Typography, Upload
} from 'antd';
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
//componentes
import { IconArrowSelect, IconCloseModal, IconTemplate } from '../../../Widgets/Iconos';
import DropzoneAntDesign from '../../../Widgets/Documents/Documents';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { MyUpload } from '../../../Widgets/Upload';
//css
import './../../../../Styles/modales.css'


const moment = require('moment');
const axios = require('axios').default;
const { RangePicker } = DatePicker;
moment.locale('es');
const { Option } = Select;
const { Title, } = Typography;
var contConsulta = 0;

export default class ModalTranAcreedor extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };


    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            accionModal: '',
            datosConsultados: false,

            return: false,
            edicion: false,
            dataDetalleAcreedor: [],
            dataCuentas: [],
            det_concepto: '',

            det_fecha: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
            det_comprobantes: [],
            loading: false,
            update: false,
            titulo: "",
        }
    }

    //Referencia de form dentro del modal.
    modalTransaccion = createRef();
    refDocumentos = React.createRef();
    /**
     * @memberof ModalTran
     * @method componentDidMount
     * @description Se ejecuta al finalizar el render de la vista.
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        const { accionModal } = this.props;


        const { dataAcreedor } = this.props;
        console.log(dataAcreedor);

        this.loadCuentas();
    }


    /**
     * @memberof ModalTran
     * @description Se ejecuta al cambiar una propiedad o un estado de el componente desde la lista principal de Finanzas.
     */
    componentDidUpdate() {

        if ((this.props.accionModal === 'editar') && !this.state.datosConsultados && this.props.modalVisible) {
            this.state.accionModal = 'editar';
            this.modalEditar()
            // this.consultaDatosAcreedor();
        }

        if ((this.props.accionModal === 'detalle') && !this.state.datosConsultados && this.props.modalVisible) {
            this.state.accionModal = 'detalle';
            //this.modalEditar()
            this.consultaDatosAcreedor();
        }

        
        if (this.props.accionModal === 'crear' && !this.state.datosConsultados && this.props.modalVisible) {
            this.state.accionModal = 'crear';
            this.consultaDatosAcreedor();
        }
    }

    /**
       * @memberof ModalTran
       * @method loadCuentas
       * @description Obtiene la informacion de las cuentas registradas en la base de datos
       *
       */
    loadCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 200
            }
        }).then((cuentas) => {
            this.setState({
                dataCuentas: cuentas.data.data.itemsList
            })
        }).catch((error) => {
        })
    }


    //Función para limpiar campos
    limpiarCamposModal = () => {
        if (this.ModalTran.current !==undefined) {
            this.ModalTran.current.setFieldsValue({});
        }

    }

 

    //Consulta valores 
    consultaDatosAcreedor = () => {
        //contConsulta++;

        const { transaccion_id } = this.props;

        console.log(transaccion_id);

        const { dataAcreedor } = this.props;
        console.log(dataAcreedor);

        if (dataAcreedor !==undefined && this.state.det_concepto === "") {
            var trans = dataAcreedor.transacciones;
            console.log(trans)

            for (let index = 0; index < trans.length; index++) {
                const element = trans[index];
                console.log(element);
                if (element._id === transaccion_id) {

                    var fecha = (element.fecha).toString().substring(0, 10)
                    console.log(fecha)
                    this.setState({

                        det_concepto: element.concepto,
                        det_cuenta: element.cuenta_id.nombre,
                        det_monto: element.monto,
                        det_proyecto: element.proyecto_id.nombre,
                        det_area: element.area_id.nombre,
                        det_rubro: element.rubro_id.nombre,
                        det_subrubro: element.sub_rubro_id.nombre,
                        det_comprobantes: element.comprobantes,
                        det_fecha: fecha
                    });

                    break;
                }
            }
        }
    }


    //Función que busca la info del registro seleccionado para la edicion y establece esos valores en el Form
    modalEditar = () => {

        const { registroId } = this.props;
        axios.post('/transacciones/get', {
            id: this.props.registroId,
        }).then((transaccion) => {
            let data = transaccion.data.data;
            this.setState({
                edicion: true,
                datosConsultados: true,
            })
            this.modalTransaccion.current.setFieldsValue({
                concepto: data.concepto,
                cuenta: data.cuenta_id?._id,
                monto: data.monto,
                proyecto: data.proyecto_id._id,
                rubro: data.rubro_id._id,
                subrubro: data.sub_rubro_id.nombre,
                tipo: data.tipo,
                comprobantes: data.comprobantes,
                fecha: moment(data.fecha, 'DD-MM-YYYY')
            })
        })


    }

    //Función de guardado-edición dependiendo de la propiedad "accionModal"
    btnSaveClickModal = values => {
        const { accionModal } = this.props;

        let cmp = []
        if (Array.isArray(values.comprobantes)) {
            for (let index = 0; index < values.comprobantes.length; index++) {
                const comprobante = values.comprobantes[index];
                console.log(comprobante)
                cmp.push(comprobante.response.filename);
            }
        }


        console.log(cmp)

        if (accionModal === "crear") {
            var dataCliente = this.state.dataDetalleCliente;



            const { dataAcreedor } = this.props;
            console.log("Crear : " + dataAcreedor);
            console.log("values : " + values);


            axios.post('/acreedores/transaccion/add', {
                concepto: values.concepto,
                cuenta_id: values.cuenta,
                monto: values.monto,
                fecha: values.fecha,

                acreedor_id: dataAcreedor._id,
                proyecto_id: dataAcreedor.proyecto_id._id,
                usuario_id: dataAcreedor.contacto_id._id,
                area_id: dataAcreedor.area_id._id,
                rubro_id: dataAcreedor.rubro_id._id,
                sub_rubro_id: dataAcreedor.subrubro_id._id,
                tipo: 1,
                comprobantes: cmp

            })
                .then((res) => {
                    console.log(res)
                    message.success("Transaccion Registrada!");
                    this.props.accept();

                }).catch((error) => {
                    Modal.warning({
                        title: 'Error',
                        content:
                            <List
                                size="small"
                                bordered
                                dataSource="No se ha podido registrar la transacción"
                            />
                    });
                });

        }


    }


    //Función que reinicializa los states y llama al método de cierre en componente padre
    hideModal = () => {
        contConsulta = 0;
        //Reiniciar valores al cerrar modal
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
            edicion: false,
            det_concepto: "",
            det_cuenta: "",
            det_monto: "",
            det_proyecto: "",
            det_area: "",
            det_rubro: "",
            det_subrubro: "",
            det_comprobantes: "",
            det_fecha: "",
        })

        this.props.closeMethod();
    }




    normFile = (e) => {
        const { file, fileList } = e;


        console.log('file', file);
        console.log('fileList', fileList);

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalCuenta
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/voucher/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/admin/acreedores" />;
        }
    };



    render() {
        const { normFile } = this;
        const { modalVisible, accionModal, registroId, dataAcreedor } = this.props;


        const { modalTransaccion } = this;


        var titulo = '';
        if (accionModal === "crear") {
            titulo = "Nueva  Transacción"

        } else if (accionModal === "especie") {
            titulo = "Nuevo Pago en Especie"

        } else if (accionModal === "documentos") {
            titulo = "Documentos"
        }

        var contenido = "";

        if (accionModal === "crear" || accionModal === "editar") {
            contenido = <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={modalTransaccion}
                    className="frm-transacciones"
                    name="formulario-transacciones"
                    onFinish={this.btnSaveClickModal}

                >
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                initialValue={(!this.state.edicion) ? moment(new Date, 'DD/MM/YYYY') : ''}
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker showTime disabled={this.state.edicion}
                                    defaultValue={(!this.state.edicion) ? moment(new Date, 'DD/MM/YYYY') : ''} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ margin: '0' }}>
                        <Col xs={24} lg={11} style={{ margin: '0' }}>
                            <Form.Item
                                label="Monto"
                                name="monto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={0} defaultValue={0} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                            <Form.Item
                                label="Cuenta"
                                name="cuenta"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <Select placeholder="Seleccione cuenta" disabled={this.state.edicion}>
                                    {this.state.dataCuentas.map(function ({ _id, nombre, logo, color }, index) {
                                        return <Option  value={_id}>
                                            <CustomAvatar
                                                image={logo}
                                                name={nombre}
                                                // color={color}
                                                size="small"
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            />
                                            {nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }} >
                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                label="Comprobantes"
                                name="comprobantes"
                                getValueFromEvent={normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    multiple={true}
                                >
                                    <Button>
                                        <UploadOutlined /> Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul"
                                    disabled={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        }

        else if (accionModal === "detalle") {
            contenido = <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={modalTransaccion}
                    className="frm-transacciones"
                    name="formulario-transacciones"
                    onFinish={this.hideModal}
                >
                    <Row>

                        <Col xs={24} lg={12} xl={12}>
                            <label className="lblTitulo" >Concepto</label>
                            <p className="pInfo">{this.state.det_concepto}</p>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                            <label className="lblTitulo">Fecha</label>
                            <p className="pInfo">{this.state.det_fecha}</p>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                            <label className="lblTitulo">Proyecto</label>
                            <p className="pInfo">{this.state.det_proyecto}</p>
                        </Col>


                        <Col xs={24} lg={12} xl={12}>
                            <label className="lblTitulo" >Area</label>
                            <p className="pInfo">{this.state.det_area}</p>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                            <label className="lblTitulo">Rubro</label>
                            <p className="pInfo">{this.state.det_rubro}</p>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                            <label className="lblTitulo">Subrubro</label>
                            <p className="pInfo">{this.state.det_subrubro}</p>
                        </Col>


                        <Col xs={24} lg={12} xl={12}>
                            <label className="lblTitulo" >Monto</label>
                            <p className="pInfo">{this.state.det_monto}</p>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                            <label className="lblTitulo">Cuenta</label>
                            <p className="pInfo">{this.state.det_cuenta}</p>
                        </Col>
                        <Col xs={24} >
                            <label className="lblTitulo">Comprobantes</label>
                            <div className="pInfo document">{
                                (Array.isArray(this.state.det_comprobantes)) ? this.state.det_comprobantes.map(item => {
                                    //<a className="link-document" download href={axios.defaults.baseURL  "/upload/" + item} ></a>
                                    return <a target="_blank" className="link-document"
                                        style={{ display: "block" }} download
                                        href={axios.defaults.baseURL + "/voucher/" + item}>
                                        {item}
                                    </a>
                                }) : null
                            }</div>
                        </Col>

                    </Row>

                </Form>
            </Spin>

        }



        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className={(accionModal === "detalle") ? "modal-form modal-form-det" : "modal-form modal-transaccion"}
                destroyOnClose={true}
                zIndex={1000}
                width={800}

            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>

                    <Title level={3} className="modal-title">
                        {accionModal === "detalle" ? "Detalle Transacción" : (this.state.det_concepto !== "") ? this.state.det_concepto : titulo}
                    </Title>
                </div>

                {contenido}

                {(registroId !==undefined && registroId !=="" && contConsulta < 2) ? this.consultaDatosAcreedor() : ''}
            </Modal>
        )
    }
}
