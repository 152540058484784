import React, { Component } from 'react';
import { DatePicker, Select, Card, Statistic, Typography, List, Spin } from 'antd'


import axios from 'axios'
import CustomAvatar from '../../Widgets/Avatar/Avatar'



const { Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;




/**
 *
 *
 * @export
 * @class CardRubros
 * @extends {Component}
 * 
 * @description Card de la lista de Rubros.
 */
export default class CardRubros extends Component {



    /**
     *
     *
     * @memberof CardRubros
     * 
     * @state projects Arreglo de proyectos.
     * @state rubros Arreglo de rubros.
     * @state selectedProyecto Proyecto actualiza seleccionado. Los rubros son de este proyecto.
     * @state loading Para el spining.
     */
    state = {
        projects: [],
        rubros: [],
        selectedProject: null,
        filters: {},
        loading: false

    }

    /**
     *
     *
     * @memberof CardEgresosPorProyecto
     * @method componentDidMount Inicializamos el componente
     */
    componentDidMount() {
        this.state.update = this.props.update
        this.getProyectos()
    }

    /**
     *
     *
     * @memberof CardEgresosPorProyecto
     * @method componentDidMount Actualizamos los filtros en caso de algún cambio
     */
    componentDidUpdate() {
        if (this.state.update !== this.props.update) {
            this.state.update = this.props.update
            this.state.filters = this.props.filters
            this.getRubros()
        }
    }


    /**
     *
     *
     * @param {*} [{ filters = this.state.filters }={}]
     * @memberof CardEgresosPorProyecto
     * 
     * @method getProyectos Obtenemos los proyectos.
     */
    getProyectos = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        })
            .then(({ data }) => {
                this.setState({
                    projects: data.data,
                    selectedProject: data.data[0]?._id,
                })

                this.getRubros(data.data[0]?._id)
            })
    }


    /**
     *
     *
     * @param {*} [{ filters = this.state.filters }={}]
     * @memberof CardEgresosPorProyecto
     * 
     * @method getProyectos Obtenemos los rubros del proyecto seleccionado.
     */
    getRubros = ({
        proyecto_id = this.state.selectedProject,
        filters = this.state.filters
    } = {}) => {
        this.setState({

            loading: true,
            selectedProject: proyecto_id
        })
        axios.post('/dashboard/rubros', {
            proyecto_id,
            ...filters
        })
            .then(({ data }) => {
                this.setState({
                    loading: false,
                    rubros: data.data,
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ filters = this.state.filters }={}]
     * @memberof CardEgresosPorProyecto
     * 
     * @method updateProject Actualizamos el proyecto en cuestión
     */
    updateProject = (proyecto_id) => this.getRubros({ proyecto_id })

    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Card className="card-dashboard" justify="center" style={{ textAlign: "center" }}>
                    <Select value={this.state.selectedProject} onChange={this.updateProject} style={{ marginBottom: '1em', width: '100%' }} className="select-project">
                        {this.state.projects.map(project => <Option value={project._id}>
                            <CustomAvatar
                                image={project.logo}
                                name={project.nombre}
                                color={project.color}
                                size="small"
                            />&nbsp;<Title level={5} style={{ fontWeight: "bold", display: "inline" }}>{project.nombre}</Title>
                        </Option>)}

                    </Select>

                    <List
                        itemLayout="horizontal"
                        dataSource={this.state.rubros}

                        renderItem={item => (
                            <List.Item
                                actions={[
                                    <Statistic value={item.monto} prefix="$" precision={2} />
                                ]}
                            >
                                <List.Item.Meta
                                    title={<Title level={5} style={{ textAlign: "start", fontWeight: "bold" }}>{item.nombre}</Title>}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Spin>
        )
    }
}
