import React, { Component } from 'react';
import { Col, Row, Typography, Button, Spin, Breadcrumb, Modal, message } from 'antd'
import axios from 'axios';
//compònentes
import Logged from "../../../../Hooks/Logged";
import { Link } from 'react-router-dom'
import ModalDiferencia from "../Avances/ModalDiferencia";
import { IconArrowBack } from '../../../Widgets/Iconos';
import { EmpleadoCard } from '../Avances/AvanceCards';


/**
 *
 *
 * @export
 * @class EmpleadosAvance
 * @extends {Component}
 */
export default class EmpleadosAvance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            empleados: [],
            data: [],
            loading: false,
            processing: false,
            accounts: [],
            nomina_id: '',

            images: [],
            loadingImage: false,

            visibleModalDiferencias: false,

            empleado: undefined
        }
    }


    /**
     * 
     * @param contextType Obtenemos el usuario loggeado
     */
    static contextType = Logged;



    componentDidMount() {
        //this.pagarEmpleado(30000,'wsss')
        this.getEmpleados()
        //solo para pruebas
        console.log('registrar empleado?')
    }

    /**
    * @memberof EmpleadosAvance
    * @method getEmpleados
    * @description informacion de los empleados, dependiendo de el tipo de etapa en la que se encuentra
    */
    getEmpleados = ({ nomina_id = this.props.match.params.nomina_id } = {}) => {
        axios.post((this.props.etapa !==3) ? '/nominas/revision' : '/nominas/revision/finanzas', {
            nomina_id
        })
        .then(({ data }) => {
            console.log('data', data.data.list);
            this.setState({ data: data.data.list })
        })
        .catch(({ response }) => {
            console.log('response', response);
            let message = 'Error al cargar los empleados'
            if(response?.status === 403)
                message = response.data?.message

            Modal.warning({
                title: 'Error',
                content: message
            })
        })
            
    }

    /**
    * @memberof EmpleadosAvance
    * @method pagarEmpleado
    * @description registra los pagos en finanzas
    */
    pagarEmpleado = (values) => {
        axios.post('/nominas/registro/finanzas', {
            usuario_id: this.state.usuario_id,
            nomina_id: this.props.match.params.nomina_id,
            montos: values.montos,
        }).then(res => {
            message.success('Pago realizado')
            this.hideModal()
        }).catch(error => {
            let messa = 'Error al realizar el pago'
            console.log(error.response)
            if (error.response?.status !== 400)
                messa = error.response?.data.message
            message.error(messa)
        })
    }


    /**
    * @memberof EmpleadosAvance
    * @method hideModal
    * @description cierra el modal y actualiza la informacion
    */
    hideModal = () => {
        this.setState({
            visibleModalDiferencias: false,
            empleado: undefined
        })
        this.getEmpleados()
    }
    
    
    render() {

        let title = ""

        switch (this.props.etapa) {
            case 2:
                title = "Revisión Final"
                break;
            case 3:
                title = "Nóminas a Finanzas"
                break;
        }

        const { data } = this.state
        // Solo para el dueño usuario ->tipo :1
        return (
            <Row className="content">
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={16} xxl={12} className="flex-left ">
                            <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline', paddingTop: '0px' }}><IconArrowBack /></Button>
                            <Breadcrumb separator={<span style={{ color: 'white' }}></span>}>
                                <Breadcrumb.Item style={{ color: 'white', fontSize: '1.5em' }}>
                                    {title}
                                </Breadcrumb.Item>
                                {(this.props.match.params.inmueble_id || this.props.match.params.empleado_id) ? <Breadcrumb.Item style={{ color: 'white', fontSize: '14' }}>
                                    Sublista
                                </Breadcrumb.Item> : null}
                            </Breadcrumb>
                        </Col>
                        <Col xs={24} xl={8} xxl={12} className="flex-left ">
                            <div>
                                <Button disabled={this.props.tipo === "salarial"}>
                                    <Link to={`/admin/nomina/${(this.props.etapa === 3) ? "revision-finanzas" : "revision-final"}/${this.props.match.params.nomina_id}/salariales/`}>Ver por Salario</Link>
                                </Button>
                                <Button disabled={this.props.tipo === "destajo"}>
                                    <Link to={`/admin/nomina/${(this.props.etapa === 3) ? "revision-finanzas" : "revision-final"}/${this.props.match.params.nomina_id}`}>Ver por destajo</Link>
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="p-1" gutter={[16, 16]}>
                        {data.map((item, index) => {
                            const { usuario, empleado, total_autorizado } = item
                            return <Col span={8} xs={12} sm={8} md={8} lg={6} xl={8} xxl={6} >
                                <EmpleadoCard
                                    usuario={usuario}
                                    monto={total_autorizado}
                                    nomina_id={this.props.match.params.nomina_id}
                                    status={this.props.etapa === 2 ? empleado?.autorizado_final : empleado?.registrado_finanzas}
                                    etapa={this.props.etapa}
                                    onClick={() => this.setState({
                                        visibleModalDiferencias: true,
                                        empleado: item,
                                        usuario_id: usuario._id,
                                        total_autorizado: total_autorizado

                                    })}
                                />
                            </Col>
                        })}

                    </Row>
                </Spin>

                <ModalDiferencia
                    visible={this.state.visibleModalDiferencias}
                    onClose={this.hideModal}

                    nomina_id={this.props.match.params.nomina_id}
                    empleado={this.state.empleado}
                    onFinish={this.pagarEmpleado}
                    etapa={this.props.etapa}
                    total_autorizado={this.state.total_autorizado}
                />
            </Row>

        )
    }
}
