import React, { Component } from 'react';
import { Button, Col, Row, Spin, Empty, Typography, Card, Menu, Dropdown, message } from 'antd'
import { FaArrowRight } from "react-icons/fa";
import { DownOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';

//componentes
import { CardInfoDashboard, CardProgress, CardActivity } from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import Chart from "react-google-charts";
//css
import '../../../Styles/projectManager.css'

const axios = require('axios').default;
const { Text } = Typography;
const moment = require('moment');
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};



export default class ProjectManagerDashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            proyects: [],
            loading: false,
            dataGant: [],
            obras_terminadas: 0,
            obras_proceso: 0,
            obras_sin_comienzo: 0,
            inmuebles: [],
            inmuebles_dropdown_disable: false,
            inmuebles_dropdown: [],
        }
    }


    /**
     *
     *
     * @memberof ProjectManagerDashboard
     * 
     * @method componentDidMount
     * @description Manda a llamar getDashboardInformation para cargar los datos de la vista
     */
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDashboardInformation();
    }


    /**
     * @memberof ProjectManagerDashboard
     * 
     * @method getDashboardInformation
     * @description Manda la solicitud al servidor para cargar los datos del dashboard
     *
     * */
    getDashboardInformation() {

        this.setState({ loading: true })
        axios.get('/project-manager/dashboard')
            .then(res => {
                console.log('traje manager proyectos', res.data.data);
                this.setState({
                    proyects: res.data.data.proyects,
                    inmuebles: res.data.data.inmuebles,
                    obras_proceso: res.data.data.obras_proceso,
                    obras_sin_comienzo: res.data.data.obras_sin_comienzo,
                    obras_terminadas: res.data.data.obras_terminadas
                });

                this.formatGantInfo(res.data.data.proyects);

            })
            .catch(error => {
                console.log('no traje nanai', error);
            }).finally(f => {
                this.setState({ loading: false });
            })
    }


    /**
     *
     *
     * @param {*} proyects
     * @memberof ProjectManagerDashboard
     * 
     * @method getInmueblesporProyecto
     * @description manda la peticion al server para cargar los inmuebles del proyecto enviado
     */
    getInmueblesporProyecto(m_proyecto_id) {

        return axios.post('/manager/proyectos/inmuebles', {
            manager_proyecto_id: m_proyecto_id,
            pagination: false
        })
        .then(res=>{
            let inmuebles = res.data.data;
            console.log('inmuebles', inmuebles);
            this.formatGantInmuebles(inmuebles);
           
        })
        .catch(error=>{
            console.log('error al cargar inmuebles',error);
        });

    }


    /**
     *
     *
     * @param {*} proyects
     * @memberof ProjectManagerDashboard
     * 
     * @method formatGantInmuebles
     * @description ordena los datos de los inmuebles para mostrarlos en la grafica
     */
    formatGantInmuebles(inmuebles){
        let data = [
            [
                { type: 'string', label: 'ID' },
                { type: 'string', label: 'Nombre' },
                { type: 'string', label: 'Etiqueta' },
                { type: 'date', label: 'Start Date' },
                { type: 'date', label: 'End Date' },
                { type: 'number', label: 'duracion' },
                { type: 'number', label: 'Percent Complete' },
                { type: 'string', label: 'Dependencies' },
            ]
        ]
        inmuebles.forEach(inmueble => {

            let start = moment(inmueble.fecha_inicio)._d;
            let end = moment(inmueble.fecha_conclusion)._d;
            data.push(
                [
                    inmueble.inmueble_id.nombre,
                    inmueble.inmueble_id.nombre,
                    null,
                    start,
                    end,
                    null,
                    inmueble.progreso_real,
                    null
                ]
            )
        });
        this.setState({ dataGant: data })
    }


    /**
     *
     *
     * @param {*} proyects
     * @memberof ProjectManagerDashboard
     * 
     * @method formatGantInfo
     * @description ordena los datos de los proyectos para mostrarlos en la grafica
     */
    formatGantInfo(proyects) {
        let data = [
            [
                { type: 'string', label: 'ID' },
                { type: 'string', label: 'Nombre' },
                { type: 'string', label: 'Etiqueta' },
                { type: 'date', label: 'Start Date' },
                { type: 'date', label: 'End Date' },
                { type: 'number', label: 'duracion' },
                { type: 'number', label: 'Percent Complete' },
                { type: 'string', label: 'Dependencies' },
            ]
        ]
        proyects.forEach(proyect => {

            let start = moment(proyect.fecha_inicio)._d;
            let end = moment(proyect.fecha_conclusion)._d;
            data.push(
                [
                    proyect.proyecto_id.nombre,
                    proyect.proyecto_id.nombre,
                    null,
                    (start !== null)?start : Date.now(),
                    (end !== null)?end : Date.now(),
                    null,
                    proyect.progreso_real,
                    null
                ]
            )
        });
        this.setState({ dataGant: data })

    }

    /**
     *
     *
     * @param {*} proyects
     * @memberof ProjectManagerDashboard
     * 
     * @method updateGantt
     * @description Manda a llamar al metodo getInmueblesporProyecto con el elemento seleccionado
     */
    updateGantt = ({ key }) => {
        this.getInmueblesporProyecto(key);        
    };

    menuproyectos = () => {
        return <Menu onClick={this.updateGantt}>
            {this.state.proyects.map(m_proyecto => {
                return <Menu.Item key={m_proyecto._id}>{m_proyecto.proyecto_id.nombre}</Menu.Item>
            })}
        </Menu>
    };



    render() {
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={6} xxl={8}>
                            <h2 style={{ width: 'auto', float: 'left' }}> Project Manager</h2>
                        </Col>
                    </Row>

                    <section className="p-1">

                        <Row gutter={[16, 24]} style={{marginLeft: '0px', marginRight: '0px', width: '100%', marginBottom: '1rem'}}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfoDashboard title='Obras Terminadas' number={this.state.obras_terminadas === 0 ? '0' : this.state.obras_terminadas}
                                    background='linear-gradient(89.47deg, #39D0BB 0.39%, #00FF38 126.36%)' />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfoDashboard title='Obras en Proceso' number={this.state.obras_proceso === 0 ? '0' : this.state.obras_proceso}
                                    background='linear-gradient(87.63deg, #ECC169 1.68%, #E76B38 141.52%)' />
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfoDashboard title='Obras sin Comenzar' number={this.state.obras_sin_comienzo === 0 ? '0' : this.state.obras_sin_comienzo}
                                    background='linear-gradient(89.26deg, #534DFC 0.54%, #9B22FF 91.03%)' />
                            </Col>
                        </Row>

                        {/* Carousel de Proyectos del Project Manager */}
                        <Carousel responsive={responsive} centerMode={false} className="carousel-pm" slidesToSlide={1}>

                            {this.state.proyects.map((project, index) =>
                                (<Col xs={24} className="col-carousel">
                                    <Link to={`/admin/project-manager/proyecto/${project._id}`}>
                                        <CardProgress
                                            title='Proyecto'
                                            name={project.proyecto_id.nombre}
                                            start={moment(project.fecha_inicio).format('YYYY-MM-DD')}
                                            finish={moment(project.fecha_conclusion).format('YYYY-MM-DD')}
                                            percent={project.progreso_real}
                                            hover={true}
                                            avatar={
                                                <Avatar
                                                    name={project.proyecto_id.nombre}
                                                    image={project.proyecto_id.logo}
                                                    color={project.proyecto_id.color}
                                                    style={{
                                                        width: '60px',
                                                        height: '60px',
                                                        fontSize: '30px'
                                                    }}
                                                    className="avatar-group-card"
                                                />
                                            } />
                                    </Link>
                                </Col>)
                            )}
                        </Carousel>
                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={16} className="gutter-row">

                                <Card className="card-table">
                                    <div className="card-table-header">
                                        <Col span={8}>
                                            <Text className="card-table-title">Tabla Gantt</Text>
                                        </Col>
                                        <Col span={8}>
                                            <Dropdown overlay={this.menuproyectos}>
                                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                    Proyectos <DownOutlined />
                                                </a>
                                            </Dropdown>
                                        </Col>

                                        {/* <Link to={"/admin/project-manager/projecto"} >
                                            <Button className="btn-go"> <FaArrowRight /> </Button>
                                        </Link> */}

                                    </div>
                                    <div className="center card-table-body">
                                        <Chart
                                            className="chart-gantt"
                                            chartLanguage="es"
                                            width={'100%'}
                                            height={'100%'}
                                            chartType="Gantt"
                                            loader={<div>Cargando Gráfica...</div>}
                                            data={this.state.dataGant}
                                            options={{ height: '100%', gantt: { trackHeight: 30, }, }}
                                        // rootProps={{ 'data-testid': '2' }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} lg={8} className="gutter-row next-activities-col">
                                <div className='next-activities-header'>
                                    <Text className='next-activities-title'>Finalizaciones Proximas</Text>
                                    {/* <Link to={"/admin/project-manager/projecto"}>
                                        <Button className="btn-go"> <FaArrowRight /> </Button>
                                    </Link> */}
                                </div>
                                <div className="next-activities-body">
                                    {
                                        (this.state.inmuebles.length > 0) ?
                                            this.state.inmuebles.map(item =>
                                                <CardActivity
                                                    tag={item.proyecto_id?.nombre}
                                                    name={item.inmueble_id?.nombre}
                                                    date={moment(item.fecha_conclusion).format('YYYY-MM-DD')}
                                                    percent={item.progreso_real} />) :
                                            <Card className="PM-card-activity">
                                                <Empty description={"Sin Actividades"} />
                                            </Card>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Spin>
            </div>
        )
    }
}
