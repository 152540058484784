import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Spin, Form, Select, message, InputNumber  } from 'antd';
//componentes
import { IconCloseModal  } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import ColorPicker from '../../../Widgets/ColorPicker/ColorPicker'; 
//css
import './../../../../Styles/modales.css'

const moment = require('moment');
const axios = require('axios');
const { Title, Text } = Typography;
const { Option } = Select;


export default class ModalProspectosEstatus extends Component {

	formEstatus = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		}
	}

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
	}

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	hideModal = () => {
		this.props.closeMethod()
		this.setState({loading: false})
	}	

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   onFinish
	* @description	Se ejecuta al aceptar el formualario
	*
	**/
	onFinish = (values) => {
		if(this.props.modalTitle === 'Editar'){
			this.updateEstatus(values)
		}else{
			this.addEstatus(values)	
		}
	}

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   addEstatus
	* @description	Agrega un estatus a la BD
	*
	**/
	addEstatus = (values) => {
		this.setState({loading: true})
		axios.post('/estatus/add',{
			nombre: values.nombre,
			color: values.color,
		}).then((proyectos) => {
			message.success('¡Estatus agregado con éxito!');
			this.hideModal();
		}).catch((error) => {
			console.log(error)
			message.error('No se agrego el Estatus');
			this.setState({loading: true})
		})
	}

	/**
	* @memberof ModalProspectosEstatus
	*
	* @method   updateEstatus
	* @description	Agrega un estatus a la BD
	*
	**/
	updateEstatus = (values) => {
		this.setState({loading: true})
		axios.post('/estatus/update',{
			_id: this.props.estatus._id,
			nombre: values.nombre,
			color: values.color,
		}).then((proyectos) => {
			message.success('¡Estatus actualizado con éxito!');
			this.hideModal();
		}).catch((error) => {
			console.log(error)
			message.error('No se actualizó el Estatus');
			this.setState({loading: true})
		})
	}




	render() {
		return (
			<Modal
				visible={this.props.modalVisible}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				closable={false}
				className="modal-form"
				destroyOnClose={true}
				zIndex={1000}
				width={750}
			>

				<div className="modal-header">
					<Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
						<IconCloseModal />
					</Button>
					<Title level={3} className="modal-title">{this.props.modalTitle} Estatus</Title>
				</div>

				<Spin spinning={this.state.loading}>
					<Form
						layout="vertical"
						name="formulario-transacciones"
						onFinish={this.onFinish}
						initialValues={this.props.estatus}
					>
						<Row align="center">
							<Col xs={20} md={13}>
								<Form.Item
									label="Nombre"
									name="nombre"//----------------------------------------------------------nombre
									rules={[{
										required: true,
										message: "Por favor, ingrese un Nombre"
									}]}
								>
									<Input/>
								</Form.Item>
							</Col>
							<Col xs={20} md={13}>
								<Form.Item
									label="Color"
									name="color"//----------------------------------------------------------color
									rules={[{
										required: true,
										message: "Por favor, seleccione un color"
									}]}
								>
									<ColorPicker
                                        colors = {['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                    />
								</Form.Item>
							</Col>
							<Col span={20} className="center">
								<Button htmlType="submit" type="primary" className="btn-azul">
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		)
	}
}
