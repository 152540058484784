import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import { Row, Col, Tag } from 'antd';

import '../../../Styles/reporteFacturas.css'

const moment = require('moment');

class Facturas extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	/**
	*
	*
	* @memberof Facturas
	* 
	* @method renderTags
	* @description renderiza tags dependiendo del estatus o el proyecto
	*/
	renderTags = (estatus) => {

		let tags = {
			'1': <Tag className="tag-proyecto-small" color="#226dff">Vigente</Tag>,
			'2': <Tag className="tag-proyecto-small" color="#e1c634">Por Vencer</Tag>,
			'3': <Tag className="tag-proyecto-small" color="#fa1e1e">Vencida</Tag>,
			'4': <Tag className="tag-proyecto-small" color="#3e8c12">Liquidada</Tag>,
			'5': <Tag className="tag-proyecto-small" color="purple">Factura</Tag>
		}

		return tags[estatus]
	}


	render() {

		const facts = this.props.facturas.map(f => <tr>
			<td>{f.concepto}</td>
			<td>{moment(f.fecha_vigencia).format('DD-MM-YYYY')}</td>
			<td>{this.renderTags(f.info_estatus)}</td>
			<td>$ {f.monto.toMoney()}</td>
		</tr>)

		const tran = this.props.transacciones?.map(t => <tr>
			<td>{t.concepto}</td>
			<td>{moment(t.fecha).format('DD-MM-YYYY')}</td>
			<td>$ {t.monto.toMoney()}</td>
		</tr>)

		let balance = ((this.props.saldos?.total * 100) - (this.props.saldos?.monto_pagado * 100)) / 100



		return (
			<div className="print-view">
				<div className="report-header">
					<div className="header-left">
						<h3 className="text-proveedor">{this.props.proveedor.alias}</h3>
						<p className="text">{this.props.proveedor.razon_social}</p>
						<p className="text">{this.props.proveedor.rfc}</p>
					</div>
					<div className="report-logo">
						<img src="/images/logo-reporte.png" width="180" alt="" />
					</div>
					<div className="header-rigth">
						<div className="div-blue">ESTADO DE CUENTA</div>
						<div className="div-direction">
							<h3 className="text-proveedor">GRUPO MANANTIAL</h3>
							<p className="text">Lote 1 de la Manzana 42, Zona 1</p>
							<p className="text">del Poblado Espiritu Santo</p>
							<p className="text">Jilatzingo, Estado de México</p>
						</div>
					</div>
				</div>
				<div className="report-title">
					FACTURAS
				</div>
				<div>
					<table className="factura-table">
						<thead>
							<tr>
								<th>CONCEPTO</th>
								<th>FECHA</th>
								<th>ESTATUS</th>
								<th>MONTO</th>
							</tr>
						</thead>
						<tbody>
							{facts}
						</tbody>
						<tfoot>
							<tr>
								<td colspan='4' className="pageFooter"> </td>
							</tr>
						</tfoot>
					</table>
				</div>
				<div className="pagebreak">

				</div>
				<div className="report-logo-lg">
					<img src="/images/logo-reporte.png" width="180" alt="" />
				</div>

				<div className="report-title">
					Abonos
				</div>
				<div>
					<table className="factura-table">
						<thead>
							<tr>
								<th>CONCEPTO</th>
								<th>FECHA</th>
								<th>MONTO</th>
							</tr>
						</thead>
						<tbody>
							{tran}
						</tbody>
					</table>
				</div>
				<div className="flex-right">
					<div className="totales-container">
						<div className="div-totales">
							<div className="totales-title">TOTAL DE FACTURAS:</div>
							<div className="totales-monto">$ {this.props.saldos?.total ? this.props.saldos?.total?.toMoney() : '0.00'}</div>
							<div className="totales-moneda">MXN</div>
						</div>
						<div className="div-totales">
							<div className="totales-title">ABONOS TOTALES: </div>
							<div className="totales-monto">$ {this.props.saldos?.monto_pagado ? this.props.saldos?.monto_pagado?.toMoney() : '0.00'}</div>
							<div className="totales-moneda">MXN</div>
						</div>
						<div className="div-balance">
							<div className="balance-title">BALANCE</div>
							<div className="balance-monto">$ {balance ? balance?.toMoney() : '0.00'} MXN</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


class Reporte extends Component {

	constructor(props) {
		super(props);
		this.state = {
			facturas: [],
			proveedor: {},
			contenido: 'facturas',
			transacciones: [],
			saldos: {}
		}
	}

	ImpresionRptTransaccion = React.createRef();
	linkRef = React.createRef();

	/**
	 *
	 *
	 * @memberof Reporte
	 * 
	 * @method loadFacturas
	 * @description El meotodo se manda a llamar desde le compoenente padre, para imprimir el PDF
	 */
	loadFacturas = (facturas, proveedor, transacciones, saldos) => {
		this.setState({ facturas: facturas, proveedor: proveedor, contenido: 'facturas', transacciones: transacciones, saldos: saldos })
		this.linkRef.current.click();
	}


	render() {
		return (
			<div style={{ width: '100%' }}>
				<ReactToPrint
					trigger={() => <a href="#" ref={this.linkRef} ></a>}
					content={() => this.ImpresionRptTransaccion}
				/>
				<Facturas
					facturas={this.state.facturas}
					proveedor={this.state.proveedor}
					transacciones={this.state.transacciones}
					saldos={this.state.saldos}
					ref={el => (this.ImpresionRptTransaccion = el)} />


			</div>
		);
	}
}




class Proveedores extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	/**
	*
	*
	* @memberof Facturas
	* 
	* @method renderTags
	* @description renderiza tags dependiendo del estatus o el proyecto
	*/
	renderTags = (estatus) => {

		let tags = {
			'1': <Tag className="tag-proyecto-small" color="#226dff">Vigente</Tag>,
			'2': <Tag className="tag-proyecto-small" color="#e1c634">Por Vencer</Tag>,
			'3': <Tag className="tag-proyecto-small" color="#fa1e1e">Vencida</Tag>,
			'4': <Tag className="tag-proyecto-small" color="#3e8c12">Liquidada</Tag>,
			'5': <Tag className="tag-proyecto-small" color="purple">Factura</Tag>
		}

		return tags[estatus]
	}


	render() {

		const { vencido_total, por_vencer_total, saldo_total } = this.props;

		const rows = this.props.proveedores?.map(p => <tr>
			<td style={{ fontSize: 13 }}>{p.alias}</td>
			<td>{this.renderTags(p.info_estatus)}</td>
			<td>$ {p.vencido.toMoney()}</td>
			<td>$ {p.por_vencer.toMoney()}</td>
			<td>$ {p.saldo.toMoney()}</td>
		</tr>)


		return (
			<div className="print-view">
				<div className="report-header black">
					<div className="header-left top">
						<h3 className="text-proveedor">PROVEEDORES</h3>
						<h4 className="text-proveedor">{this.props.proveedores?.length}</h4>
					</div>
					<div className="report-logo">
						<img src="/images/GM-logo.png" width="180" alt="" />
					</div>
					<div className="header-rigth">
						<div className="div-black">REPORTE PROVEEDORES</div>
						{/* <div className="div-direction top">
							<h3 className="text-proveedor">FECHAS</h3>
						</div> */}
					</div>
				</div>
				<div className="report-title">
					REPORTE PROVEEDORES
				</div>
				<div>
					<table className="factura-table">
						<thead>
							<tr>
								<th>PROVEEDOR</th>
								<th>ESTATUS</th>
								<th>VENCIDO</th>
								<th>POR VENCER</th>
								<th>SALDO TOTAL</th>
							</tr>
						</thead>
						<tbody>
							{rows}
						</tbody>
						<tfoot>
							<tr>
								<td colspan='4' className="pageFooter"> </td>
							</tr>
						</tfoot>
					</table>
				</div>
				<div className="flex-right">
					<div className="totales-container">
						<div className="div-totales">
							<div className="totales-title">SALDO VENCIDO:</div>
							<div className="totales-monto red">$ {vencido_total ? vencido_total.toMoney() : '0.00'}</div>
							<div className="totales-moneda red">MXN</div>
						</div>
						<div className="div-totales">
							<div className="totales-title">SALDO POR VENCER: </div>
							<div className="totales-monto yellow">$ {por_vencer_total ? por_vencer_total.toMoney() : '0.00'}</div>
							<div className="totales-moneda yellow">MXN</div>
						</div>
						<div className="div-balance black">
							<div className="balance-title">SALDO TOTAL</div>
							<div className="balance-monto" style={{ color: 'white' }}>$ {parseFloat(saldo_total) ? saldo_total?.toMoney() : '0.00'} MXN</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class ProveedoresReporte extends Component {

	constructor(props) {
		super(props);
		this.state = {
			facturas: [],
			proveedor: {},
			contenido: 'facturas',
			transacciones: [],
			saldos: {}
		}
	}

	ImpresionRptProveedores = React.createRef();
	linkRef = React.createRef();

	/**
	 *
	 *
	 * @memberof Reporte
	 * 
	 * @method loadFacturas
	 * @description 
	 */
	printReporte = (data) => {
		console.log('data reporte saldo_total', data.saldo_total)
		this.setState({
			proveedores: data.proveedores,
			vencido_total: data.vencido_total,
			por_vencer_total: data.por_vencer_total,
			saldo_total: data.saldo_total
		}, () => {
			this.linkRef.current.click();
		})

	}


	render() {
		return (
			<div style={{ width: '100%' }}>
				<ReactToPrint
					trigger={() => <a href="#" ref={this.linkRef} ></a>}
					content={() => this.ImpresionRptProveedores}
				/>
				<Proveedores
					proveedores={this.state.proveedores}
					vencido_total={this.state.vencido_total}
					por_vencer_total={this.state.por_vencer_total}
					saldo_total={this.state.saldo_total}
					ref={el => (this.ImpresionRptProveedores = el)} />

			</div>
		);
	}
}

export {
	Reporte,
	ProveedoresReporte
}