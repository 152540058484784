import React, { Component } from 'react';
import { Layout, Button, Col, Popconfirm, Row, message, Card ,PageHeader, List, Spin, Typography, Space, Tooltip, Collapse, Tag } from 'antd'
import axios from 'axios'
import { BankOutlined, ConsoleSqlOutlined, PlusOutlined, CloseOutlined,EyeOutlined } from "@ant-design/icons";

//componentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { IconDelete, IconPlus ,IconEdit, IconBankError, IconSATSCR } from '../../Widgets/Iconos';

//modales
import ModalFacturas from './ModalFacturas';
/* import ModalServicio from '../Servicios/ModalServicios'; */
import ModalConfiguraciónSyncfy from '../Syncfy/ModalConfiguraciónSyncfy';
/* import ModalConfiguraciónTaxpayer from '../Syncfy/ModalConfiguraciónTaxpayer'; */



const { Title ,Text } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;
const moment = require('moment');
/**
 *
 *
 * @export
 * @class Empresas
 * @extends {Component}
 */
export default class Facturas extends Component {

    constructor(props) {
        super(props)
        this.state = {

            facturas: [],

            filtroSearch: '',
            searching: true,
        }
    }


    /**
     *
     *
     * @memberof Empresas
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de los Empresas
     */

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getFacturas()
    }

    /**
     *
     * @memberof Empresas
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra las empresas
     */
     componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getFacturas();
            this.state.searching = false;
        }
    }


    /**
    * @memberof FacturasSC

    * @description  Lista las facturas de una cuenta, en un rango de fechas
    **/
     getFacturas = (page = this.state.page) => {
        this.setState({ loading: true });
        let search = this.props.search;
        axios.get('/facturas/list', {
            params: {
                page,
                search,
            }
        })
            .then(response => {
                console.log('response getFacturas', response)
                this.setState({
                    facturas: response.data.data.itemsList,
                    page: response.data.data.currentPage,
                    total: response.data.data.itemCount,

                    filtroSearch: search,
                    searching: true,
                    loading: false
                })
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }

    renderTipo = (value) => {
        switch (value) {
            case 1:
                return "Ingreso"
            case 0:
                return "Sin cambios"
            case 2:
                return "Egreso"
        }
    }

    /**
     *
     *
     * @param {*} item
     * @memberof FacturasSC
     * 
     * @description 
     */
     renderViews = (item) => {


        return <Space>
            <Button
                className='btn'
                type="primary"
                icon={<IconEdit />}
                title="Editar"
                onClick={() => { this.showModal(item._id) }}
            />
            <Button
                className='btn'
                type="primary"
                icon={<EyeOutlined style={{ color: "currentColor" }} />}
                onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                title="Ver XML"
                disabled={!item.factura_xml}
            ></Button>
            <Popconfirm
				placement="topRight"
				title="¿Deseas rechazar esta Factura?"
				onConfirm={() => axios.post('/facturas/rechazar', { factura_id: item._id        }).then((response) => {
				    message.success('Factura Rechazada')
					this.getFacturas()
				}).catch((error) => {
					message.success('Factura NO Rechazada')
					this.getFacturas();
				    })
				}
				okText="Si"
				cancelText="No"
			>
				<Button className='btn' type="primary" danger icon={<CloseOutlined style={{ color: "currentcolor" }} />} title="Rechazar" />
			</Popconfirm>
            {/* <Button
                type="primary"
                icon={<FilePdfOutlined style={{ color: "currentColor" }} />}
                onClick={() => window.open(`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`, '_blank')}
                title="Ver PDF"
                disabled={!(item.tipo_facturacion === 1)}
            ></Button>
            <Button
                disabled={typeof item?.factura_xml !== "string"}
                icon={<GoFileCode style={{ color: "currentColor", fontSize: 25, position: "relative", top: 5 }} />}
                type="primary"
                target={"_blank"}
                download={true}
                href={`${axios.defaults.baseURL}/facturas/cfdi/xml?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`} /> */}
        </Space>
        //     return <> 
        // </Button>
        // </>

        //     if (item.factura_xml) {
        //         return <> <Button
        //             type="primary"
        //             icon={<EyeOutlined style={{ color: "#fff" }} />}
        //             onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
        //             title="Ver XML" >
        //         </Button>
        //         </>
        //     } else {
        //         return <><Button
        //             type="primary"
        //             icon={<EyeOutlined />}
        //             disabled={true}
        //             title="Ver XML" />
        //         </>
        //     }
    }


    /**
     * @methodOf  Empresas
     * @function showModal
     *
     * @description Muestra el modal de la Empresa
     *
     * */
    showModal = (id) => {
        this.setState({
            visible: true,
            id: typeof id === 'string' ? id : undefined
        });
    };

    /**
     * @methodOf  Empresas
     * @function hideModal
     *
     * @description Oculta el modal de la Empresa
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };

    /**
     *
     *
     * @memberof Facturas
     * 
     * @method renderTags
     * @description renderiza tags dependiendo del estatus o el proyecto
     */
     renderTags = (tipo, item) => {
        if(tipo === 'estatus'){
 
             let tags = {
                 '0': <Tag className="tag-proyecto" color="#fa1e1e">Rechazada</Tag>,
                 '1': <Tag className="tag-proyecto" color="#226dff">Vigente</Tag>,
                 '2': <Tag className="tag-proyecto" color="#e1c634">Por Vencer</Tag>,
                 '3': <Tag className="tag-proyecto" color="#fa1e1e">Vencida</Tag>,
                 '4': <Tag className="tag-proyecto" color="#3e8c12">Liquidada</Tag>,
                 '5': <Tag className="tag-proyecto" color="purple">Factura</Tag> 
             }
 
             return tags[item]
         }else{
             return <Tag className="tag-proyecto" color={item.color}>{item.nombre}</Tag>
         }
     }


    render() {
        const { getEmpresas, showModal, hideModal } = this;
        const { visible, empresas, id } = this.state;
        const user = this.context;

        return (
            <>
                <Spin spinning={false}>
                    <Row className="row-encabezado">
						<Col xs={24} xl={24} xxl={24} className="flex-left">
							<Title level={4}className="view-title">Facturas</Title>
						</Col>
					</Row>
                    <Content className="admin-content content-bg p-1">

                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Facturas " }}
                                loading={this.state.loading}
                                dataSource={this.state.facturas}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: 10,
                                    total: this.state.total,
                                    position: 'bottom',
                                    className: "flex-left",
                                    onChange: (page) => this.getFacturas(page)
                                }}
                                header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col span={4} className="center">
                                    <Text ellipsis strong>Estatus</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text ellipsis strong>Concepto</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text ellipsis strong>Fecha</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text ellipsis strong>Cantidad</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text ellipsis strong>Acciones</Text>
                                </Col>
                            </Row>}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <div className={`badge-card tipo-${item.tipo === 0 ? 3 : item.tipo === 1 ? 1 : 2}`}></div>
                                            <Row className="width-100 ">
                                                <Col span={4} className="center">
                                                    <Text ellipsis>{item.estatus === 0 ? this.renderTags('estatus',item.estatus) : this.renderTags('estatus',item.info_estatus)}</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                <Text strong ellipsis>{item.concepto}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text ellipsis strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text ellipsis>$ {item.monto ? item.monto.toMoney(true) : '0.00'} <small>{item.moneda}</small></Text>
                                                </Col>
                                                <Col span={5} className="center">
    
                                                    {this.renderViews(item)}
    
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        {/* <FloatingButton title="Nuevo registro" onClick={() => showModal()} /> */}
                    </Content>
                    {(user.tipo !==3) ? <Button className="btnAddPlus" title="Nuevo registro" onClick={this.showModal} >
                        <IconPlus />
                    </Button> : null}
                </Spin>
                
                <ModalFacturas
                    visible={visible}
                    hideModal={hideModal}
                    closeMethod={hideModal}
                    accept={() => {
                        hideModal();
                        getEmpresas();
                    }}
                    update={() => getEmpresas()}
                    id={id}

                />
                <ModalConfiguraciónSyncfy
                    visible={this.state.modalSyncfy}
                    id={this.state.syncfy_id}
                    onCancel={() => {
                        this.setState({ modalSyncfy: false, id: null, cuenta_id: undefined })
                        this.getEmpresas();
                    }}
                    tipo={2}
                />
                {/* <ModalConfiguraciónTaxpayer
                    visible={this.state.modalKeys}
                    empresa_id={this.state.empresa_id}
                    onCancel={() => {
                        this.setState({ modalKeys: false, empresa_id: null, cuenta_id: undefined })
                        this.getEmpresas();
                    }}
                />
                <ModalServicio
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, empresa_id: undefined, servicio_id: undefined})
                        this.getEmpresas()
                    }}
                    empresa_id={this.state.empresa_id}
                    servicio_id={this.state.servicio_id}
                /> */}
            </>

        )
    }
}
