import React, { Component } from "react";
import { Row, Col, Button, Modal, DatePicker, Typography, Form, Spin, Select } from 'antd';
//componentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { IconCloseModal } from '../../../Widgets/Iconos';
//css
import './../../../../Styles/modales.css'


const moment = require('moment');
const axios = require('axios');


const { Title } = Typography;
const { Option } = Select;

//Modal para agregar proyecto al modulo Manager
export default class ModalProyectoAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {


            proyectos: [],
            loading: false,
            updateProyectosList: false,

            update: true
        }
    }

    /**
    * @memberof ModalProyectoAdmin
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount(){this.getProyectos()}

    componentDidUpdate(){
        if (this.props.modalVisible === true && this.state.update === true){
            this.state.update = false;
            this.getProyectos()
        }
    }

    /**
    * @memberof ModalProyectoAdmin
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
        this.state.update = true;
    }

    /**
    * @memberof ModalProyectoAdmin
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        this.setState({loading: true})

        axios.post('/manager/proyectos/add', values)
            .then((proyectos) => {
                this.setState({loading: false})
                this.props.closeMethod();
            })
            .catch((error) => {
                console.log("r",error)
                Modal.error({
                    title: "No es posible guardar el proyecto.",
                })
            })
            .finally(()=>{
                this.state.update = true;
            })


        // /project-manager/proyects/add


    }

    /**
     *
     *
     * @memberof ModalProyectoAdmin
     * @function getProyectos
     *
     * @description Obtener proyectos.
     */
    getProyectos = (search) => {
        axios.get('/proyectos', {
            params: {
                pagination: false,
                inManager: true,
                search,
            }
        }).then((proyectos) => {
            this.setState({proyectos: proyectos.data.data})
        }).catch((error) => {
            Modal.error({
                title: "No es posible obtener los productos.",
                content: "No fue posible obtener los productos."
            })
        })
    }

    render() {

        const {proyectos} = this.state;

        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                <Spin spinning={this.state.loading}>
                    <div className="modal-header">
                        <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                            <IconCloseModal />
                        </Button>
                        <Title level={3} className="modal-title">Agregar Proyecto</Title>
                    </div>
                    <Form
                        layout="vertical"
                    className="frm-cuentas"
                    name="formulario-entrega"
                    onFinish={this.onFinish}
                >
                    <Row align="center">
                        <Col span={20}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id" //<--------------------------------------- verificar nombre
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un proyecto"
                                }]}
                            >
                                <Select
                                    onSearch={(search) => this.getProyectos(search)}
                                    showSearch
                                    filterOption={false}
                                >
                                    {proyectos.map((proyecto) =>  <Option value={proyecto._id}>
                                        <CustomAvatar
                                            image={proyecto.logo}
                                            name={proyecto.nombre}
                                            color={proyecto.color}
                                            size="small"
                                            style={{
                                                marginRight: '5px'
                                            }}
                                        />
                                        {proyecto.nombre}
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                </Spin>
            </Modal>
        )
    }
}
