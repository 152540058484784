import React, { Component } from 'react';
import { Col, Row, Card, Typography, Button, Spin, Breadcrumb, Pagination } from 'antd'
import { Link } from "react-router-dom";
import axios from 'axios';
//compònentes
import Logged from "../../../../Hooks/Logged";

import { InmuebleCard, EmpleadoCard } from './RevisionCards'

import { IconArrowBack } from '../../../Widgets/Iconos';
import Finanzas from '../../Finanzas/Finanzas';

const { Title } = Typography;


/**
 * 
 * @class Revision
 * @description Esta vista permite ver todas las cartas de la seccion de revision de nominas. Hace la diferencia segun sea Prelminar, Final o Finanzas. 
 * 
 * Dependiendo de las variables que tenga el componente ruta, es lo que va a mostrar.
 * 
 * @property tipo Hace la diferencia entre inmueble o destajo. Si es destajo, muestra las cartas modo empleado, si es inmueble muestra las cartas con la info de los inmuebles
 * @property redireccion Si es tajos va a redirigir a tajos. EN la vista de inmuebles o empleados agrupados, no estára definida, ya que es hasta la segunda vista (cuando ya hay un inmuelbe ID o un empleado). 
 * @property finanzas Es solo para distinguir si la vista es finanzas o nó. 
 */
export default class Revision extends Component {

    /**
     * 
     * @param contextType Obtenemos el usuario loggeado
     */
    static contextType = Logged;


    /**
     * 
     * @state loading Para establecer la carga de elementos
     * 
     * @state list Para la lista de empleados o inmuebles.
     * @state list.data Lista de empleados o inmuebles
     * @state list.page Lista de empleados o inmuebles
     * @state list.limit Limite de elmeentos por pagina
     * @state list.search Para buscar elementos
     * 
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            list: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                search: null,
            },
        };
    }

    /**
     * @memberof NominaInmuebles
     * @description Se ejecuta al terminar de montar la vista de administar nominas;
     */

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getList()
    }


    /**
     * @memberof Revision
     * @function getList
     * 
     * @param {} page Pagina actual 
     * @param {*} limit Limite de elementos por pagina
     * @param {*} total Cantidad total de elementos
     * @param {*} search Busqueda de elementos 
     */
    getList = (page = this.state.list.page, limit = this.state.list.limit, total = this.state.list.total, search = this.state.list.search) => {
        this.setState({ loading: true })
        axios.post('/nominas/revision', {
            page,
            limit,
            nomina_id: this.props.match.params.nomina_id,
            tipo: (this.props.tipo === "inmueble") ? 1 : 2,
            inmueble_id: this.props.match.params.inmueble_id,
            empleado_id: this.props.match.params.empleado_id,
        })
            .then(({ data }) => {
                console.log('data', data.data.list);
                this.setState({
                    list: {
                        data: data.data.list,
                        page,
                        limit,
                        total: data.data.list.total,
                        search: null,
                    },
                })
            })
            .catch(response => {

            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    }


    render() {
        const { list } = this.state;
        const { getList } = this;

        const user = this.context;

        let etapa = 1;
        let title = ""

        // Establcemos la etapa actual
        if (this.props.finanzas === true) {
            // Finanzas
            etapa = 3;
            title = "Nominas a Finanzas"
        } else if (user && user.tipo === 1) {
            // Final
            etapa = 2
            title = "Revisión Final"
        } else {
            // Preliminar
            etapa = 1
            title = "Revisión Preliminar"
        }


        // Solo para el dueño usuario ->tipo :1
        return (
            <Row className="content">
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={24} xl={8} xxl={12} className="flex-left ">
                            <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline', paddingTop: '0px' }}><IconArrowBack /></Button>
                            <Breadcrumb separator={<span style={{ color: 'white' }}>></span>}>
                                <Breadcrumb.Item style={{ color: 'white', fontSize: '1.5em' }}>
                                    {title}
                                </Breadcrumb.Item>
                                {(this.props.match.params.inmueble_id || this.props.match.params.empleado_id) ? <Breadcrumb.Item style={{ color: 'white', fontSize: '14' }}>
                                    Sublista
                                </Breadcrumb.Item> : null}
                            </Breadcrumb>
                        </Col>

                        <Col xs={24} xl={8} xxl={12} className="flex-left ">
                            {(this.props.redireccion !== "tajos") ? <div>
                                <Button disabled={this.props.tipo === "destajo"}>
                                    {/* this.props.match.params.nomina_id */}
                                    <Link to={`/admin/nomina/${(this.props.finanzas === true)?"finanzas":"revision"}/${this.props.match.params.nomina_id}/destajo/`}>Ver por destajo</Link>
                                </Button>
                                <Button disabled={this.props.tipo === "inmueble"}>
                                    <Link to={`/admin/nomina/${(this.props.finanzas === true)?"finanzas":"revision"}/${this.props.match.params.nomina_id}/inmuebles/`}>Ver por Inmueble</Link>
                                </Button>
                                {etapa !==1?
                                <Button disabled={this.props.tipo === "salarial"}>
                                    <Link to={`/admin/nomina/${(this.props.finanzas === true)?"finanzas":"revision"}/${this.props.match.params.nomina_id}/salariales/`}>Ver por Salario</Link>
                                </Button>: null}
                            </div> : null}
                        </Col>
                    </Row>
                    <Row style={{ padding: 24 }}>
                        <Row gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col xs={24} sm={24} xl={12}>
                                <Card className="content-card">
                                    <Title level={3} style={{ color: "#858997" }}>Número de Empleados</Title>
                                    <Title level={2} style={{ textAlign: "center" }}>{''}</Title>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} xl={12}>
                                <Card className="content-card">
                                    <Title level={3} style={{ color: "#858997" }}>Número de Inmuebles</Title>
                                    <Title level={2} style={{ textAlign: "center" }}>{''}</Title>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={[32, 32]} style={{ width: '100%' }}>
                            {this.state.list.data.map((e) => {


                                //Status dependiendo de la etapa.
                                let status = false;
                                let monto = 0;


                                //Segun la etapa, es el monto que se va a mostrar.
                                switch (etapa) {
                                    case 1:
                                        //Preliminar
                                        status = e.status_autorizados_preliminar;
                                        monto = e.tajos_monto_autorizado
                                        break;
                                    case 2:
                                        //Final
                                        status = e.status_autorizados_final;
                                        monto = e.tajos_monto_autorizado
                                        break;
                                    case 3:
                                        //Finanzas
                                        status = e.status_registrados_finanzas;
                                        monto = (status === true) ? e.tajos_monto_pagado : e.tajos_monto_autorizado
                                        break;
                                }

                                return <Col xs={24} sm={24} xl={8}>
                                    {(this.props.tipo === "destajo") ?
                                        <EmpleadoCard
                                            etapa={etapa}
                                            nomina_id={this.props.match.params.nomina_id}
                                            usuario={e.usuario}
                                            inmueble={e.inmueble}
                                            proyecto={e.proyecto}
                                            status={status}
                                            redireccion={this.props.redireccion}
                                            monto={monto}
                                            responsable={e.responsable}
                                            logged={(typeof user === "object") ? user : {}}
                                            disabled={(etapa === 1 && e.status_autorizados_final)}
                                        /> :
                                        <InmuebleCard
                                            etapa={etapa}
                                            nomina_id={this.props.match.params.nomina_id}
                                            usuario={e.usuario}
                                            inmueble={e.inmueble}
                                            proyecto={e.proyecto}
                                            status={status}
                                            redireccion={this.props.redireccion}
                                            monto={monto}
                                            responsable={e.responsable}
                                            logged={(typeof user === "object") ? user : {}}
                                            disabled={(etapa === 1 && e.status_autorizados_final)}
                                        />}
                                </Col>

                            })}
                        </Row>
                        <Row gutter={[32, 32]} style={{ width: '100%' }}>
                            <Col>
                               {/* <Pagination defaultCurrent={1} total={list.total} onChange={getList} />*/}
                            </Col>
                        </Row>
                    </Row>
                </Spin>
            </Row>

        )
    }
}
