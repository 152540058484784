//Dependencias
import React, { Component } from 'react';
import { Calendar, Badge, Modal, Row, Col, Spin, Menu, Dropdown, Popover, Button } from 'antd';
//componentes
import { IconArrow, IconPlus } from '../../Widgets/Iconos';
import DrawerListaRecordatorios from "./DrawerListaRecordatorios";
//modales
import ModalRecordatorios from "../Modales/Calendario/ModalRecordatorios";
//css
import '../../..//Styles/calendario.css'

const moment = require('moment');

const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


const menuRubros = (
    <Menu>
        <Menu.Item>
            <a >
                En desarrollo..
        </a>
        </Menu.Item>
    </Menu>
);

const menuSubrubros = (
    <Menu>
        <Menu.Item>
            <a >
                En desarrollo..
        </a>
        </Menu.Item>
    </Menu>
);




/**
 * @class Calendario
 * @description Elemento calendario.
 * */
export default class Calendario extends Component {

    state = {
        loading: false,

        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        tipo: 'month',

        calendarReminders: {},


        drawerVisible: false,
        calendarSelectedDate: null,



        formModal: false
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getRecordatorios(moment().format('MM'), moment().format('YYYY'), "month")
    }

    /**
     * @description Consulta de recordatorios
     * @param {*} values
     */
    getRecordatorios = (month = this.state.month, year = this.state.year, type = this.state.type) => {
        this.setState({ loading: true })
        if (type === "year")
            this.state.month = month = undefined;
        axios.get('/recordatorios/list', {
            params: {
                calendar: true,
                month,
                year,
                type
            }
        })
            .then(({ data }) => {
                this.setState({
                    calendarReminders: this.getDatesFormat(data.data),
                    loading: false,
                    type,
                    month,
                    year

                })
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    calendarReminders: {},
                });
                console.log(error.response);
                Modal.error({
                    title: "Ha ocurrido un error al cargar los datos"
                });
            });
    }


    /**
     *
     *
     * @memberof Calendario
     * 
     * @description Establecemos el formato de fecha para que el metodo de dateCellRender lo pueda leer
     */
    getDatesFormat = (fechas) => {
        let fechasCalendar = {};
        for (const fecha of fechas)
            fechasCalendar[fecha._id] = fecha.recordatorios;

        return fechasCalendar;
    }



    /**
     *
     *
     * @memberof Calendario
     * 
     * 
     * @description Render por celda de calendario, recorre los registros consultados y si coinciden con la fecha de la celda se agrega evento a celda.
     * 
     * @param {*} cellDayMomentValue
     * @description En el renderizado del calendario, es el Objeto Moment del Dia en cestion de la celda que se está renderizando.
     */
    cellRender = (cellDayMomentValue) => {
        const cellDayValue = cellDayMomentValue.format((this.state.type === "month") ? 'YYYY-MM-DD' : "YYYY-MM");
        const { calendarReminders } = this.state;
        if (calendarReminders[cellDayValue]) {
            const reminders = calendarReminders[cellDayValue];
            return (
                <Popover >
                    <ul className="events">
                        {reminders.map(({ nombre }) => (
                            <Badge color="yellow" text={nombre} style={{ width: '100%' }} />
                        ))}

                    </ul>
                </Popover>

            );
        }
    }


    /**
     *
     *
     * @memberof Calendario
     * @function selectedDate
     * 
     * @description Fecha seleccionada..
     */
    selectedDate = (value) => this.setState({
        drawerVisible: true,
        calendarSelectedDate: value,

    })


    /**
     *
     *
     * @memberof Calendario
     * @function hideDrawer
     * 
     * @description Ocultamos el drawer de la lista de elementos.
     */
    hideDrawer = () => {
        this.setState({
            drawerVisible: false,
            calendarSelectedDate: null,
        })
        this.getRecordatorios(moment().format('MM'), moment().format('YYYY'), "month")
    }

    /**
     *
     *
     * @memberof Calendario
     * @function showModal
     * 
     * @description Mostramos el modal de nuevo recordatorio.
     */
    showModal = () => this.setState({ formModal: true })

    /**
     *
     *
     * @memberof Calendario
     * @function hideModal
     * 
     * @description Ocultamos el modal de nuevo
     */
    hideModal = () => { this.setState({ formModal: false }); this.getRecordatorios(moment().format('MM'), moment().format('YYYY'), "month") }

    render() {
        const { cellRender, getRecordatorios, selectedDate, hideDrawer, hideModal, showModal } = this;
        const { formModal, loading, drawerVisible, calendarSelectedDate } = this.state;

        return (

            <div>
                <Row className="row-encabezado">
                    <Col xs={24} xl={5} xxl={4}>
                        <h2 style={{ width: 'auto', float: 'left' }}> Calendario</h2>
                    </Col>

                    <Col xs={24} xl={19} xxl={20} className="">
                        <div style={{ float: 'right' }} className="div-enc-filtros">
                            <Row align="center">
                                <Col span={8} xs={24} md={12} xl={10} xxl={6}>
                                    <Dropdown overlay={menuRubros}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> Rubro <IconArrow /> </a>
                                    </Dropdown>
                                </Col>
                                <Col span={8} xs={24} md={12} xl={8} xxl={6}>
                                    <Dropdown overlay={menuSubrubros}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> Subrubro <IconArrow /> </a>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row className="p-1">
                    <Spin spinning={loading}>
                        <div className="div_contenido">
                            <div className="contenedor-etiquetas">
                                <div className="etiqueta">
                                </div>
                                <Row >
                                </Row>
                            </div>

                            <Calendar
                                dateCellRender={cellRender}
                                monthCellRender={cellRender}
                                onPanelChange={(date, type) => getRecordatorios(date.format('MM'), date.format('YYYY'), type)}

                                onSelect={selectedDate}
                                // fullscreen={true}
                                className="div-calendario darkTheme"

                            />
                        </div>
                    </Spin>
                </Row>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={showModal} >
                    <IconPlus />
                </Button>
                <ModalRecordatorios
                    visible={formModal}
                    hideModal={hideModal}

                />
                <DrawerListaRecordatorios
                    visible={drawerVisible}
                    selectedDate={calendarSelectedDate}
                    hideDrawer={hideDrawer}
                />
            </div>
        );
    }
}
