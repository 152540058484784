import React, {Component} from 'react';
import {Button, Col, Row, Spin, Statistic, Typography, List, Card, Divider, Breadcrumb, InputNumber, Modal} from 'antd'
import {FaArrowRight} from "react-icons/fa";
import {Link, Redirect} from 'react-router-dom';

//componentes
import {CardInfo} from '../../Widgets/Cards/Cards';
import Avatar from "../../Widgets/Avatar/Avatar";
import {
    IconEdit,
    IconDelete,
    IconPlus,
    IconEye,
    IconCopy,
    IconoMaquinaRegistradoraParaElGuardadoNominazFinanzas as IconoGuardado, IconGo, IconArrowBack
} from '../../Widgets/Iconos';
//css
import '../../../Styles/projectManager.css'
import CustomAvatar from "../../Widgets/Avatar/Avatar";

const axios = require('axios').default;
const moment = require('moment')
const {Text} = Typography;


export default class PonderacionInmuebles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            manager_actividad_id: this.props.match.params.manager_actividad_id,
            actividad: {
                actividad_id: {},
                manager_proyecto_id: {
                    proyecto_id: {}
                },
                inmueble_id: {},
                progreso_proyectado: 0,
                progreso_real:0,
            },
            tajos: [],
            proyecto: {},


            loadingHeader: false,
            loadingContent: false,
            total: 0,
            sumaProgresoPonderadoTajos: 0,

            redirect: false
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search

        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getActividad()
        this.loadTajos()

    }

    /**
     * @memberof ActividadAdmin
     * @method getActividad
     *
     * @description Obtiene la informacion de la actividad
     *
     */
    getActividad = (id) => {
        this.setState({loadingHeader: true})
        axios.get("/manager/proyects/inmuebles/actividades/get", {
            params: {
                id: this.props.match.params.manager_actividad_id
            }
        })
            .then(res => {
                this.setState({
                    loadingHeader: false,
                    actividad: res.data.data,
                    proyecto: res.data.data.manager_proyecto_id.proyecto_id,
                })
            })
            .catch(e =>
                console.log('error actividad get', e)
            )
    }

    /**
     * @memberof ActividadAdmin
     * @method loadTajos
     *
     * @description Obtiene los tajos de una actividad
     */
    loadTajos = (page) => {
        this.setState({loadingContent: true})
        axios.get("/manager/proyectos/inmuebles/actividades/tajos/list", {
            params: {
                pagination: false,
                manager_actividad_id: this.props.match.params.manager_actividad_id
            }
        })
            .then(res => {
                this.setState({
                    loadingContent: false,
                    tajos: res.data.data,
                    sumaProgresoPonderadoTajos: res.data.extra.sumaProgresoPonderadoTajos,
                    total: res.data.extra.sumaProgresoPonderadoTajos,
                })
            })
            .catch(e =>
                console.log('error actividad get', e)
            )
    }



    /**
     *
     * @memberof PonderacionInmuebles
     * @method updatePorcentaje
     * @description Actualizamos los porcentajes de los tajos.
     *
     * @param {*} value Valor a actualizar en el tajo
     * @param {*} index Posición en el tajo
     */
    updatePorcentaje = (value, index)=> {
        let {tajos, total} = this.state;
        total = parseFloat(total);
        value = parseFloat(value)
        tajos[index].progreso_proyectado = 0
        total = tajos.reduce((total, item)=>{return total + item.progreso_proyectado}, 0)
        if ((total + value) > 100)
        {
            tajos[index].error = true;
            tajos[index].progreso_proyectado = 100 - total
            setTimeout(() => {
                tajos[index].error = false;
                this.setState({
                    tajos,
                    total : tajos.reduce((total, item)=>{return total + item.progreso_proyectado}, 0)
                })
            }, 1500);
        }else{
            tajos[index].error = false;
            tajos[index].progreso_proyectado = value;
            total = tajos.reduce((total, item)=>{return total + item.progreso_proyectado}, 0)
        }

        this.setState({
            tajos,
            total
        })
    }

    saveProject = () => {
        axios.put('/manager/proyectos/inmuebles/actividades/tajos/porcentajes',{
            manager_actividad_id: this.props.match.params.manager_actividad_id,
            tajos: this.state.tajos
        })
            .then(e=>{

                this.props.history.goBack()
            })
            .catch(e=>{
                Modal.error({
                    title: "No es posible guardar los porcentajes",
                    content: "Ocurrio un error al guardar."
                })
            })
    }

    render() {
        let {saveProject} = this
        return (
            <div style={{ paddingBottom: "65px" }}>
                <Spin spinning={this.state.loadingHeader}>
                    <Row className="row-encabezado">
                        <Col xs={24} md={1} className="mb-1">
                            <Button type="link" onClick={() => this.props.history.goBack()} style={{ display: 'inline', padding: '3px', top: '12px' }}>
                                <IconArrowBack size={0.7} />
                            </Button>
                        </Col>
                        <Col xs={23} className="flex-left">
                            <Breadcrumb className="breadcrumb" separator=">">
                                <Breadcrumb.Item>
                                    <Link to="/admin/manager">Proyectos</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={'/admin/manager/proyecto/' + this.state.actividad.manager_proyecto_id._id}>
                                        <Avatar
                                            name={this.state.actividad.manager_proyecto_id.proyecto_id.nombre}
                                            color={this.state.actividad.manager_proyecto_id.proyecto_id.color}
                                            image={this.state.actividad.manager_proyecto_id.proyecto_id.logo}
                                            className="mr-1"/>
                                        {this.state.actividad.manager_proyecto_id.proyecto_id.nombre}
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={"/admin/manager/proyecto/inmueble/" + this.state.actividad.manager_inmueble_id}>{this.state.actividad.inmueble_id.nombre}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrumb-actual">
                                    {this.state.actividad.actividad_id.nombre}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Spin>
                <Spin spinning={this.state.loadingContent}>
                    <section className="p-1">

                        <Row gutter={[16, 24]}>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Proyecto"
                                    infoLeft={this.state.proyecto.nombre}
                                    infoRight={
                                        <CustomAvatar
                                            image={this.state.proyecto.logo}
                                            name={this.state.proyecto.nombre}
                                            color={this.state.proyecto.color}
                                            size={"large"}
                                            style={{width:"55px", height: "55px", fontSize:"20pt", display: 'flex', alignItems: 'center' }}
                                        />
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={8} className="gutter-row">

                                <CardInfo
                                    titleLeft="Pondaracion Porcentual"
                                    infoLeft={` ${this.state.sumaProgresoPonderadoTajos.toMoney(2)} %`}
                                    titleRight="Progreso Real"
                                    infoRight={` ${this.state.actividad.progreso_real.toMoney(2)} %`}
                                   />

                            </Col>
                            <Col xs={24} lg={8} className="gutter-row">
                                <CardInfo
                                    titleLeft="Fecha Inicial"
                                    titleRight="Fecha Final"
                                    infoLeft={moment(this.state.actividad.fecha_inicio).format('DD-MM-YYYY')}
                                    infoRight={moment(this.state.actividad.conclusion).format('DD-MM-YYYY')}
                                />
                            </Col>
                        </Row>

                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.tajos}
                            header={<List.Item className="component-list-item" style={{width: '100%'}}>
                            <Row style={{width: '100%'}} >
                                <Col span={18}>
                                    <p>Nombre</p>
                                </Col>
                                <Col span={6}>
                                    <p>Porcentaje</p>
                                </Col>
                            </Row>
                        </List.Item>}
                            renderItem={(item, index) => (
                                <List.Item className="component-list-item" style={{width: '100%'}}>
                                    <Row style={{width: '100%'}} gutter={[16, 0]}>
                                        <Col xs={12} md={18}>
                                            <Card className="card-list">
                                                <Row style={{width: '100%'}} className="">
                                                    <Col xs={24} md={10} className="center ">
                                                        <Text strong>{item.tajo_id.nombre}</Text>
                                                    </Col>

                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Card className={"card-list ponderacion-cantidad " + (item.error?"error-porcentaje":"")}  style={{padding: '7px'}}>
                                                <Row style={{width: '100%'}} className="">
                                                    <Col span={24} className="center " style={{ position: 'relative' }}>
                                                        {( item.error ?<p style={{
                                                            position: 'absolute',
                                                            bottom: '-27px',
                                                            left:'unset',
                                                            fontSize: '10px',
                                                            color: 'red'
                                                        }} >El porcentaje total debe de ser menor a 100%</p>:null)}
                                                        <InputNumber
                                                            style={{
                                                                textAlign: 'center',
                                                                color:  (item.error?"red":"unset")
                                                            }}

                                                            onChange={(value)=>this.updatePorcentaje(value, index)}
                                                            value={item.progreso_proyectado}
                                                            precision={2}
                                                            formatter={value => `${value}%`}
                                                            parser={value => value.replace('%', '')}
                                                            className="input-ghost"
                                                            bordered={false}
                                                            max={100}/>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                            footer={<List.Item className="component-list-item" style={{width: '100%'}}>
                                <Row style={{width: '100%'}} gutter={[16, 0]}>
                                    <Col xs={{span: 12, offset: 12}} md={{span: 6, offset: 18}}>
                                        <Divider />
                                        <Card className="card-list">
                                            <Row style={{width: '100%'}} className="">
                                                <Col span={24} className="center ">
                                                    <Statistic precision={2} suffix={'%'} title="Total" value={this.state.total} />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </List.Item>}
                        />

                    </section>


                    <Button type="ghost" className="btn-save" onClick={() => saveProject()} disabled={this.state.uploadingImages}>
                        <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41 3.82586C40.9961 5.11749 40.5257 6.0772 39.6873 6.8994C34.9386 11.551 30.1977 16.211 25.4539 20.8683C22.7917 23.4819 20.1315 26.0964 17.4674 28.7072C15.7045 30.436 13.2626 30.4284 11.5017 28.6996C8.07832 25.3359 4.64628 21.9807 1.23163 18.6094C0.0773175 17.4695 -0.282018 16.0897 0.223177 14.5581C0.722575 13.0446 1.82763 12.1389 3.42242 11.8829C4.80084 11.661 5.98606 12.1019 6.9723 13.0721C9.34759 15.4078 11.7316 17.736 14.0982 20.0802C14.3889 20.3685 14.5425 20.4197 14.87 20.0964C21.2511 13.8089 27.6476 7.53478 34.0422 1.25968C35.1994 0.123585 36.5846 -0.277558 38.1533 0.193762C39.6795 0.651805 40.6001 1.7054 40.9324 3.24074C40.9826 3.47119 40.9874 3.71017 41 3.82586Z" fill="white" />
                        </svg>
                    </Button>
                </Spin>
            </div>
        )
    }
}
