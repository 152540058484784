import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Col, Form, Input, Modal, Row, Spin, Layout, Checkbox } from 'antd';

import '../../Styles/usuarios.css';
import '../../Styles/login.css';


import Cookies from 'universal-cookie';

const axios = require('axios').default;
const { Content } = Layout;

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,

            isRecovery: false,
            isRecoveryLoading: false,
            redirect: false,


            disponible: false,
            latitud: null,
            longitud: null
        }
    }

    //Referencia
    recoveryPassword = React.createRef();
    to = '/admin/dashboard';


    componentDidMount() {
        if ("geolocation" in navigator) { } else {
            Modal.error({
                title: "Tu navegador no acepta geolocalizaicón",
                content: <div>Debes acceder desde un navegador con geolocalización. (Google Chrome, Mozilla Firefox o Microsoft Edge 2020)</div>,

            })
        }

        // this.setLocalization()

        // let component = this;
        // navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {

        //     console.log('geolocation permission state is ', permissionStatus.state);
        //     permissionStatus.onchange = function () {
        //         if (this.state === "granted"){
        //             component.setLocalization()
        //         }else{
        //             Modal.error({
        //                 title: "Debes aceptar la geolocalización para iniciar sesión",
        //                 content: <div>Para ingresar, debes acceder a la geolocalización. Acepta en tu navegador o cambia la configuración de tu navegador.</div>,
        //             })
        //             component.setState({
        //                 disponible: false, 
        //             })
        //         }

        //         console.log('geolocation permission state has changed to ', this.state);
        //     };
        // });


    }


    setLocalization = () => {

        let component = this;
        navigator.geolocation.getCurrentPosition(function (position) {
            component.setState({
                latitud: position.coords.latitude,
                longitud: position.coords.longitude
            })
        });
    }

    setLocalization = () => {
        
    }



    /**
     * @memberof Login
     * @method   renderRedirect
     * @description Renderisa el redireccionamiento a vista si el inicio de sesión fue exitoso
     *
     **/
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.to} />;
        }
    };



    /**
  * @memberof Login
  * @method   redirectTo
  * @description Establece valores a redirect
  *
  **/
    redirectTo = (to) => {
        this.to = to;
        this.setState({ redirect: true })
    }



    /**
     * @memberof Login
     * @method   onFinish
     * @description Metodo de inicio de sesión
     *
    */
    onFinish = async values => {
        axios.post("/login", {
            email: values.email,
            password: values.password
        })
            .then(({ data, headers }) => {
                const { setUser } = this.props;

                sessionStorage.setItem('token', headers.authorization);
                axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

                if (data.user.tipo === 1 || data.user.roles.finanzas)
                    this.redirectTo('/admin/dashboard')
                else
                    this.redirectTo('/admin/welcome')


                setUser(data.user)



                // data.permisos
                // finanzas: Boolean,
                // crm: Boolean,
                // p_manager: Boolean,
                // legal: Boolean,
                // cuentas: Boolean,
                // categorias: Boolean,
                // areas: Boolean,
                // proyectos: Boolean,
                // usuarios: Boolean,

                if (values.remember) {
                    const cookies = new Cookies();
                    cookies.set('token', headers.authorization);
                }
            })
            .catch(error => {
                Modal.warning({
                    title: "No es posible iniciar sesión",
                });
            });
    };


    /**
     * @memberof Login
     * @method   handleRecoveryPassword
     * @description Metodo de recuperación de contraseña
     *
     **/
    handleRecoveryPassword = async value => {
        await this.setState({
            isRecoveryLoading: true
        })
        axios.put("/password/recovery", { email: value.email })
            .then(res => {
                this.setState({
                    isRecovery: false
                })
                Modal.success({
                    title: 'Restablece tu contraseña.',
                    content: '¡El correo ha sido enviado! Revisa tu bandeja de entrada.'
                })
                this.recoveryPassword.current.resetFields();
            })
            .catch(res => {
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que sea el mismo con el que te registraste.'
                });
            })
            .finally(() => {
                this.setState({
                    isRecoveryLoading: false
                })
            })
    }


    render() {
        return (
            <Content className="login">
                {this.renderRedirect()}
                <Row align="center" style={{ width: '100%', maxWidth: '1650px', margin: 'auto', paddingTop: '20px' }}>
                    <Col xs={22} sm={20} md={18} lg={{ span: 8, pull: 0 }} xl={{ span: 8, pull: 1 }} xxl={{ span: 8, pull: 1 }} style={{ paddingTop: '30px' }}>

                        <Row style={{ marginBottom: '50px' }}>
                            <img width='100%' style={{ maxWidth: '400px' }} src="/images/logo-login.png" alt="Logo" />
                        </Row>

                        <h1 className="titulo-login">Inicia Sesión</h1>


                        <Form onFinish={this.onFinish} layout="vertical" className="login-form" name="basic" >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{
                                    required: true,
                                    message: 'Para poder iniciar sesión, debe haber ingresado su correo electrónico'
                                }]}
                            >
                                <Input disabled={this.state.disponible} />
                            </Form.Item>

                            <Form.Item
                                label="Contraseña"
                                name="password"
                                rules={[{
                                    required: true,
                                    message: 'Para poder iniciar sesión, debe haber ingresado su contraseña'
                                }]}
                            >
                                <Input.Password disabled={this.state.disponible} />
                            </Form.Item>

                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Mantener la sesión iniciada</Checkbox>
                            </Form.Item>

                            <Link
                                style={{ color: '#6C6AFF' }}
                                onClick={() => this.setState({ isRecovery: true })}>
                                ¿Olvidaste tu contraseña?
                            </Link>

                            <Form.Item >
                                <Button type="primary" htmlType="submit" className="btn-azul btn-login" disabled={this.state.disponible}>
                                    Iniciar Sesión
                                </Button>
                            </Form.Item>
                        </Form>

                    </Col>
                    <Col xs={22} sm={20} md={18} lg={{ span: 15, push: 0 }} xl={{ span: 13, push: 0 }} xxl={{ span: 10, push: 1 }} style={{ paddingTop: '30px', height: '100%' }} className="cnt-imagen-login">
                        <div className="contenedor-login" align="center">
                            <img src="/images/GM-logo-blanco.png" width="50%" alt="" />
                        </div>
                    </Col>

                </Row>
                <Spin spinning={this.state.isRecoveryLoading}>
                    <Modal
                        title="Olvidé mi contraseña"
                        className="modal-recovery"
                        visible={this.state.isRecovery}
                        onOk={() => this.recoveryPassword.current.submit()}
                        onCancel={() => this.setState({ isRecovery: false })}
                        maskClosable={!this.state.isRecoveryLoading}
                        okText="Enviar"
                        cancelText="Cancelar"
                        cancelButtonProps={{ className: 'btnCancel' }}
                        okButtonProps={{ className: 'btnOk recovery-button' }}
                    >
                        <Form onFinish={this.handleRecoveryPassword} layout={"vertical"} className="login-form pd-2" ref={this.recoveryPassword}>
                            <Form.Item
                                name="email"
                                label="Escribe tu correo electrónico."
                                rules={[{
                                    required: true,
                                    message: 'Por favor, ingresa tu correo electronico.'
                                }]}>
                                <Input type="email" size="large" className="input-box" placeholder="Escribe tu correo electronico" />
                            </Form.Item>
                        </Form>
                    </Modal>
                </Spin>
            </Content>
        )
    }
}
