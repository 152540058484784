import React, { Component, createRef } from "react";
import { Avatar, Button, Col, DatePicker, Form, Input, Tag, List, message, Modal, Row, Select, Spin, Statistic, Typography, Upload, Checkbox, Tooltip } from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Redirect, Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from 'moment'
import 'moment/locale/es';
//componentes
import { IconArrowSelect, IconCloseModal, IconTemplate } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { MyUpload } from './../../../Widgets/Upload';
import './../../../../Styles/finanzas.css'

const axios = require('axios').default;
moment.locale('es');
const { Option } = Select;
const { Title, } = Typography;

export default class ModalTran extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            accionModal: '',
            datosConsultados: false,
            return: false,
            edicion: false,
            dataRubros: [],
            dataSubrubros: [{ nombre: '--' }],
            dataProyectos: [],
            dataCuentas: [],

            dataAreas: [],
            dataTipos: [{
                _id: 1,
                nombre: 'Ingreso'
            },
            {
                _id: 2,
                nombre: 'Egreso',
            },
            ],

            det_concepto: '',
            det_fecha: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
            det_fecha_creacion: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
            det_proyecto: '',
            det_rubro: '',
            det_subrubro: '',
            det_cuenta: '',
            det_tipo: '',
            det_monto: '',
            det_usuario: '',
            det_area: '',
            det_comprobantes: [],

            loading: false,

            update: false,
            titulo: "",



            actualiza_saldo: false
        }
    }

    //Referencia de form dentro del modal.
    modalTransaccion = createRef();

    /**
     * @memberof ModalTran
     * @method componentDidMount
     * @description Se ejecuta al finalizar el render de la vista.
     */
    componentDidMount() {axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');}


    componentDidUpdate() {
        //Si se abre el modal y el editar esa desactivo, se activa para poder editar
        if (this.props.modalVisible && !this.state.updateChecker && !(this.props.registroId === null || this.props.registroId === undefined)) {
            this.state.updateChecker = true;
            this.state.registroId = this.props.registroId;
            if (this.props.registroId !== null) {
                this.state.edicion = true;
                this.getTransaccionInfo()
            }
        }


        if (!this.props.modalVisible && this.state.updateChecker && (this.props.registroId === null || this.props.registroId === undefined)) {
            this.state.updateChecker = false;
            this.setState({
                actualiza_saldo: false,
                det_concepto: '',
                det_fecha: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
                det_fecha_creacion: moment(new Date).format('MMMM Do YYYY, h:mm:ss a'),
                det_proyecto: '',
                det_rubro: '',
                det_subrubro: '',
                det_cuenta: '',
                det_tipo: '',
                det_monto: '',
                det_usuario: '',
                det_area: '',
                det_comprobantes: [],
            })
        }
    }


    //Función que reinicializa los states y llama al método de cierre en componente padre
    hideModal = () => {
        //Reiniciar valores al cerrar modal
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
            edicion: false,
        })

        this.props.closeMethod();
    }

    /**
     * @memberof ModalTran
     * @method getTransaccionInfo
     * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
     * con informacion obtenida.
     */
    getTransaccionInfo = () => {
        this.setState({ spinning: true})
        if (this.props.registroId !=="")
            axios.post('/transacciones/get', {
                id: this.props.registroId,
            }).then((transaccion) => {
                let data = transaccion.data.data;


                console.log('data', data);

                this.setState({


                    actualiza_saldo: data.actualiza_saldo,

                    datosConsultados: true,
                    det_concepto: data.concepto,
                    
                    det_descripcion: data.descripcion,
                    det_fecha: moment(data.fecha).format('LLL'),
                    det_fecha_creacion: moment(data.createdAt).format('LLL'),
                    det_proyecto: data.proyecto_id?.nombre,
                    det_area: data.area_id.nombre,
                    det_rubro: data.rubro_id.nombre,
                    det_subrubro: data.sub_rubro_id.nombre,

                    det_tipo_index: data.tipo,
                    det_tipo: (data.tipo !==3) ? ((data.tipo === 1) ? 'Ingreso' : 'Egreso') : 'Traspaso',
                    det_monto: data.monto,
                    det_usuario: (data.usuario_id !==undefined) ? data.usuario_id.nombre + ' ' + data.usuario_id.apellido : '',
                    det_comprobantes: data.comprobantes,


                    det_cuenta: (data.cuenta_id) ? data.cuenta_id.nombre : "N/A",
                    spinning: false

                })
            })

    }


    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/admin/finanzas" />;
        }
    };

    /**
     *
     * @memberof ModalTransaccion
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;


        console.log('file', file);
        console.log('fileList', fileList);

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }




        // for (let x = 0; x < fileList.length; x++) {
        //     if (fileList[x].status === "done" && !upload && this.state.loading) {
        //         this.state.loading = false;
        //         this.setState({ update: !this.state.update })
        //         upload = false;
        //         break;
        //     } else upload = true;
        // }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalCuenta
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/voucher/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };


    render() {
        const { normFile } = this;
        const { modalVisible } = this.props;
        const { accionModal } = this.props;
        const { registroId } = this.props;
        const { modalTransaccion } = this;


        var titulo = '';
        // if (accionModal === "crear") {
        //     titulo = "Nueva Transacción"

        // } else if (accionModal === "editar") {
        //     titulo = "Editar Transacción"

        // } else if (accionModal === "multiple") {
        //     titulo = "Agregar Múltiples Transacciones"

        // } else if (accionModal === "detalle" && (!this.state.datosConsultados)) {
        //     this.getTransaccionInfo();
        //     titulo = "Detalles"
        //     titulo = this.state.det_concepto;

        // }

        // var contenido = "";

        const gutter = [15, 45]
        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className={"modal-form modal-det modal-transaccion"}
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">{"Detalle " + this.state.det_concepto}</Title>
                </div>
                <Spin spinning={this.state.spinning}>
                    <Form
                        layout="vertical"
                        ref={this.modalTran}
                        className="frm-transacciones"
                        name="formulario-transacciones"
                        onFinish={this.btnSaveClickModal}
                    >
                        {(this.state.det_descripcion)?<Row>
                            <Col xs={24} lg={{ span: 23, push: 1 }}>
                                <label className="lblTitulo">Descripcion</label>
                                <p className="pInfo">{this.state.det_descripcion}</p>
                            </Col>
                        </Row>:null}

                        <Row>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Cuenta</label>
                                <p className="pInfo">{this.state.det_cuenta  }{(this.state.actualiza_saldo === true)?<Tag color="#87d068" style={{ 
                                    
                                    backgroundColor: 'rgb(135, 208, 104)',
                                    fontWeight: 'lighter',
                                    fontSize: '10px',
                                    letterSpacing: '1px',
                                    position: 'relative',
                                    left: '6px',
                                    top: '-1px'

                                 }}>Actualiza Saldo</Tag>:null} </p>
                            </Col>

                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Fecha</label>
                                <p className="pInfo">{this.state.det_fecha}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Proyecto</label>
                                <p className="pInfo">{this.props.proyecto?.nombre}</p>
                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                                <label className="lblTitulo">Area</label>
                                <p className="pInfo">{this.state.det_area}</p>
                            </Col>

                        </Row>
                        <Row style={{ margin: '0' }}>
                            <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                                <label className="lblTitulo">Rubro</label>
                                <p className="pInfo">{this.state.det_rubro}</p>
                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }} style={{ margin: '0' }}>
                                <label className="lblTitulo">Subrubro</label>
                                <p className="pInfo">{this.state.det_subrubro}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Tipo</label>
                                <p className="pInfo">{this.state.det_tipo}</p>

                            </Col>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <label className="lblTitulo">Monto</label>
                                <p className="pInfo">
                                    {(this.state.det_tipo_index === 2) ?
                                        <Statistic
                                            value={this.state.det_monto}
                                            valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}
                                            prefix={'$'}
                                            suffix={'MXN'}
                                        /> :
                                        <Statistic
                                            value={this.state.det_monto}
                                            valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF19' }}
                                            prefix={'$'}
                                            suffix={'MXN'}
                                        />
                                    }
                                </p>
                            </Col>
                        </Row>


                        <Row style={{ textAlign: "" }}>
                            <Col span={24} className="readOnlyFiles">
                                <label className="lblTitulo">Comprobantes</label>
                                <MyUpload
                                    tipo="detalle"
                                    fileList={this.state.det_comprobantes?.map((imagen, index) => new Object({
                                        uid: index * -1,
                                        name: imagen,
                                        status: 'done',
                                        url: axios.defaults.baseURL + '/voucher/' + imagen,
                                    }))}
                                />
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>

                            <span className="item-divider"></span>
                            <Col xs={24} lg={{ span: 11, push: 1 }}>
                                <div className="div-titulo">
                                    <h2 className="titulo-divCont">Autor</h2>
                                </div>
                                <Row className="row-transaccion">
                                    <Col>
                                        <Avatar />
                                    </Col>
                                    <Col>
                                        <h2>{this.state.det_usuario}</h2>
                                    </Col>
                                    <Col>
                                        <p>{this.state.det_fecha_creacion}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}
