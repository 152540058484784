import React, { Component } from 'react';
import { Button, Col, Row, message, Typography, List, Card, Pagination, Popconfirm } from 'antd'
import { Link } from 'react-router-dom';
//componentes
import { IconEdit, IconDelete, IconPlus, IconArrowBack } from '../../../Widgets/Iconos';
import { DownOutlined, CodepenOutlined } from '@ant-design/icons';

//Modales
import ModalAlmacen from './ModalAlmacenes'

const axios = require('axios').default;
const { Text, Title } = Typography;


export default class Almacenes extends Component {

    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            almacenes: [],
            proyecto_id: this.props.match.params.proyecto_id,
            inventario_id: this.props.match.params.inventario_id,
            proyecto: {},

            page: 1,
            total: 0,
            limit: 10,
        }
    }



    /**
    * @memberof Almacenes
    * @method   componentDidMount
    * @description
    **/
    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        this.getProyecto()
        this.getAlmacenes()
    }


    /**
    * @memberof Almacenes
    * @method getProyecto
    * @description  Obtiene la informacion del proyecto
    **/
    getProyecto = () => {
        axios.get('/proyectos/get',{
            params:{
                id: this.props.match.params.proyecto_id
            }
        }).then(response => {
            this.setState({proyecto: response.data.data})
        }).catch(error => {
            message.error('Error al obtener la información del proyecto')
        })
    }

    /**
    * @memberof Almacenes
    * @method getAlmacenes
    * @description  Obtiene el listado de almacnes de un proyecto
    **/
    getAlmacenes = (page = this.state.page) => {
        this.setState({loading: true})
        axios.get('/proyectos/almacenes',{
            params:{
                inventario_id: this.props.match.params.inventario_id
            }
        }).then(response => {
            this.setState({
                almacenes: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })
        }).catch(error => {
            message.error('Error al obtener los almacenes')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @memberof Almacenes
    * @method   componentDidUpdate
    * @description 
    **/
    componentDidUpdate() {

    }



    render() {
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={20} className="flex-left">
                        <Button className="IconArrowBack" onClick={this.volver}>
                            <IconArrowBack />
                        </Button>
                        <Title level={4} className="view-title">Almacenes de {this.state.proyecto?.nombre}</Title>
                    </Col>
                </Row>
                <section className="p-1">
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.almacenes}
                        header={<Row className="header-list" style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>
                            <Col xs={10} className="gutter-row center">
                                <Text strong>Nombre</Text>
                            </Col>

                            <Col xs={10} className="gutter-row  center">
                                <Text strong>Dirección</Text>
                            </Col>

                            <Col xs={4} className="gutter-row center">
                                <Text strong>Acciones</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">

                                        <Col xs={10} className="center">
                                            <Text strong>{item.nombre}</Text>
                                        </Col>

                                        <Col xs={10} className="center">
                                            <Text strong>{item.direccion}</Text>
                                        </Col>

                                        <Col xs={4} className="center">
                                            <Link to={`/admin/proyectos/${this.state.proyecto_id}/productos/${this.state.inventario_id}/almacen/${item._id}`}>
                                                <Button
                                                    type="" 
                                                    className="detTable-icon" 
                                                    title="Productos"
                                                    icon={<CodepenOutlined style={{ position: 'relative', top: -2 }} />}
                                                />
                                            </Link>
                                            <Button className="btn btn-edit" title="Editar" onClick={(e) => this.setState({almacen_id: item._id, modal_visible: true})}>
                                                <IconEdit />
                                            </Button>

                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar este Almacén?"
                                                onConfirm={() => axios.delete('/proyectos/almacenes/delete', { params: { almacen_id: item._id } })
                                                    .then((response) => {
                                                        message.success("Eliminado Correctamente")
                                                        this.getAlmacenes(1)
                                                    })
                                                    .catch((error) => {
                                                        message.error("No es posible eliminar")
                                                        this.getAlmacenes(this.state.page);
                                                    })
                                                }
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button className="btn btn-delete" icon={<IconDelete />} title="Eliminar" />
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.page}
                            total={this.state.total}
                            pageSize={this.state.limit}
                            showSizeChanger={false}
                            onChange={(page) => {
                                this.receivedData({ page })
                            }}
                        />
                    </Row>

                </section>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={()=>this.setState({modal_visible: true})}>
                    <IconPlus />
                </Button>
                <ModalAlmacen
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, almacen_id: undefined})
                        this.getAlmacenes()
                    }}
                    inventario_id={this.state.inventario_id}
                    almacen_id={this.state.almacen_id}
                />

            </div>
        )
    }
}
