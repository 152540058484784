import React, { Component } from "react";
import { Button,  Modal, Row, Select, Spin, Image, Typography, List } from 'antd';
import { IconCloseModal } from '../../../Widgets/Iconos';
import { FileOutlined } from '@ant-design/icons'
import PropTypes  from "prop-types";
import Logged from '../../../../Hooks/Logged'; 
import { Player, BigPlayButton } from 'video-react';

//css
import '../../../../../node_modules/video-react/dist/video-react.css'
import './../../../../Styles/modales.css'


const moment = require('moment');
const axios = require('axios').default;

const { Option } = Select;

const { Title, } = Typography;


export default class ModalEstatusAvanceDetalle extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => { },
        accept: () => { }
    };

    static contextType = Logged;

    formModalEstatusAlcance = React.createRef();


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},
            dataInmuebles: [],

            det_id: "",
            det_fecha: "{{Fecha}}",
            det_videos: [],
            actualizado: false

        }
    }


    /**
     * @methodOf ModalEstatusAvanceDetalle
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //dataDetBitacora

        this.state.det_videos = [];
    }


    /**
     * @methodOf ModalEstatusAvanceDetalle
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualiza una propiedad o un estado.
     *
     * */
    componentDidUpdate(prevProps, prevState, snapshot) {
        //Si la propiedad cambia y es diferente al estado, actualizamos el estado con el ID de la poppiedad y buscamos al usuario


        if (this.props.visible && this.props.accionModal === "detalle" && this.props.regIdModal !=="" && this.props.regIdModal !==this.state.det_id) {
            this.state.loading = true;
            this.state.loadingImage = true;

            this.actualizarConsultaArchivos();
        }

        if (this.props.id !== this.state.id && this.props.id !== undefined) {
            this.state.id = this.props.id

        }

        if (this.props.id !== this.state.id && this.state.id !== undefined && this.props.id === undefined) {
            this.state.id = undefined;
            this.state.form = {};
            this.state.image = undefined;
            this.formModalEstatusAlcance.current.resetFields();
        }
    }

    

    /**
     * @methodOf ModalEstatusAvanceDetalle
     *
     * @function actualizarConsultaArchivos
     * @description Se busca info de registro seleccionado
     *
     * */
    actualizarConsultaArchivos = async () => {

        if (!this.state.actualizado) {

            var data = this.props.dataDetBitacora;
            var idReg = this.props.regIdModal;
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if (idReg === element._id) {
                    this.setState({
                        det_fecha: moment(element.fecha).format('DD-MM-YYYY'),
                        det_videos: element.archivos,
                        actualizado: true,
                        det_id: idReg,
                        loading: false,
                        loadingImage: false

                    });

                    break;
                }
            }
        }

    }


        /**
     * @methodOf ModalEstatusAvanceDetalle
     *
     * @function hideModal
     * @description Oculta modales
     *
     * */
    hideModal = async () => {
        this.setState({
            loading: true,
            loadingImage: false,
            form: {},
            dataInmuebles: [],

            det_fecha: "{{Fecha}}",
            det_videos: [],
            actualizado: false
        });

        this.props.closeMethod();
    }



    render() {
        const { normFile, valPreFile, onChangeFileList, formModalEstatusAlcance, saveUser, deleteImage } = this;
        const { visible, closeMethod, accionModal } = this.props;
        const { form, image, loading, id, proyectos, loadingImage } = this.state;
        const user = this.context;
        var contenido = null;


        if (accionModal === "detalle") {
            contenido = <section>
                <div className="modal-header">
                    <Button type="ghost" title="Cerrar" className="btn-close-modal" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title" style={{
                        marginTop: "-35px",
                    }}>{this.state.det_fecha}</Title>
                </div>

                <Spin spinning={loading}>

                    <Row align="center">
                        <List
                            loading={loading}
                            className="component-list-video"
                            itemLayout="horizontal"
                            dataSource={this.state.det_videos}
                            renderItem={item => {
                                var formato = item.split(".")[1].toLocaleUpperCase();

                                if (formato === "MP4") {
                                    return <Row className="center pd-1 row-video" >
                                        
                                        <Player
                                            // poster="/images/img-video.png"
                                            className="item-video"
                                        >
                                            {/* <ControlBar autoHide={false} className="my-class" /> */}
                                            <BigPlayButton position="center" />
                                            <source src={axios.defaults.baseURL + '/voucher/' + item} />
                                        </Player>
                                        {item}
                                    </Row>
                                }
                                else if (formato === "JPG" || formato === "PNG" || formato === "SVG" ) {
                                    return <Row className="center pd-1 row-video" >

                                        <Image
                                            className="item-image"
                                            src={axios.defaults.baseURL + '/voucher/' + item}
                                        />

                                        <p style={{
                                            width: "100%",
                                            bottom: 0,
                                            textAlign: "center"
                                        }}>
                                            {item}
                                        </p>
                                    </Row>
                                }
                                else {
                                    return <Row className="center pd-1 row-video" >
                                        <a style={{ width: '100%', fontWeight: 'bold', display: 'block', fontSize: "12pt", padding: "20px", marginTop: "50px" }}
                                            href={axios.defaults.baseURL + '/voucher/' + item} target="_blank"
                                            title={"Abrir " + item} >
                                            <FileOutlined /> <p style={{ textAlign: "right", marginTop: "-28px", width: "calc(100% - 30px)" }} >  {item} </p>
                                        </a>
                                    </Row>
                                }
                            }}
                        />

                    </Row>

                </Spin>
            
            </section>
        }

        else {
            contenido = <section>
                <div className="modal-header">
                    <Button type="ghost" title="Cerrar" className="btn-close-modal" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title" style={{
                        marginTop: "-35px",
                    }}>Por definir</Title>
                </div>

                <Spin spinning={loading}>

                    {this.state.dataInmuebles.map(function ({ _id, nombre }, index) {
                        return <Option value={_id}>
                            {nombre}
                        </Option>
                    })}
                    <Row>
                        c:
                </Row>
                </Spin>
            </section>
        }

        return (
            <Modal
                visible={visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                maskClosable={!loadingImage}
                className="modal-form modal-videos"
                destroyOnClose={true}
                zIndex={1000}
                width={2000}
                style={{
                    width: "auto !important",
                    maxWidth: "2000px !important"
                }}
            >
                {contenido}
            </Modal>
        )
    }
}
