import React, { Component } from 'react';
import { Row, Progress, Col, Spin, Select, Card, Button, Layout, message, Typography, Form, Collapse, Modal, Radio, Space } from 'antd'
import axios from 'axios';
import { MinusOutlined, PlusOutlined, WarningFilled, CloseCircleFilled } from '@ant-design/icons';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import { Link } from "react-router-dom"
import '../../../Styles/budgeting.css'
import { CardPrespuesto, CardProyecto, CardTransaccionesClasificacion } from '../../Widgets/Cards/Cards';
import { IconEye } from '../../Widgets/Iconos';


const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography
const { Option } = Select

/**
 * @export
 * @class Budgeting
 * @extends {Component}
 * @description Vista de Budgeting
 */
export default class Budgeting extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,

			proyectos: [],
			proyecto: {},
			presupuesto: {},
			transacciones: {
				clasificadas: 0,
				no_clasificadas: 0,
				cantidad: 0
			},
			conceptos: {
				data: [],
				limit: 100,
				total: 0,
				pages: 1,
				page: 1,


				tipo: 2

			},
			ventas: [],
			sum_ejercido: 0,
			sum_presupuesto: 0,
			promedio: 0,
			proyecto_id: this.props.match.params.proyecto_id,
		}
	}

	refForm = React.createRef()

	componentDidMount() {
		//Mostrar input superior de busqueda 
		//Para acceder al valor del input = this.props.search
		this.props.updateFilterSearch(true);
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		window.scrollTo(0, 0)
		this.getProyectos()
		if (this.props.match.params.proyecto_id) {
			this.selectProyecto(this.props.match.params.proyecto_id)
		}


	}

	/**
	* @memberof Budgeting
	* @function getProyectos
	*
	* @description Obtener proyectos.
	*/
	getProyectos = (search) => {
		axios.get('/proyectos', {
			params: {
				pagination: false,
				search,
			}
		}).then((proyectos) => {
			this.setState({ proyectos: proyectos.data.data })

			if (this.props.match.params.proyecto_id == undefined) {
				if (proyectos.data.data.length > 0) {
					this.selectProyecto(proyectos.data.data[0]._id)
					this.refForm.current.setFieldsValue({
						proyecto_id: proyectos.data.data[0]._id,
						tipo: 2
					})
				}
			}
		}).catch((error) => {
			Modal.error({
				title: "No es posible obtener los proyectos.",
				content: "No fue posible obtener los proyectos."
			})
		})
	}

	/**
	* @memberof Budgeting
	* @method selectProyecto
	* @description  Obtiene la informacion del proyecto seleccionado
	**/
	selectProyecto = (proyecto_id) => {
		axios.get('/proyectos/get', {
			params: {
				id: proyecto_id
			}
		}).then(response => {
			this.setState({ proyecto: response.data.data, proyecto_id });
			this.getConceptos({ proyecto_id });
			this.getPresupuesto({ proyecto_id });
			this.getTransacccionesClasificadas(proyecto_id,)
			this.refForm.current.setFieldsValue({
				proyecto_id: proyecto_id
			})
		}).catch(error => {
			message.error('Error al obtener la información del proyecto')
		})
	}

	changeTipo = (tipo) => {
		this.getConceptos({ tipo });
		this.getPresupuesto({ tipo });
		this.getTransacccionesClasificadas({ tipo })
	}

	changeProyecto = (proyecto_id) => {
		this.props.history.push(`/admin/budgeting/${proyecto_id}`)
		this.selectProyecto(proyecto_id)
	}

	/**
	 *
	 *
	 * @memberof Budgeting
	 * @description Obtiene el presupuesto del proyecto seleccionado
	 */
	getPresupuesto = ({
		proyecto_id = this.state.proyecto_id,
		tipo = this.state.conceptos.tipo,
	}) => {
		axios.get('/conceptos/proyecto/presupuesto',
			{
				params: {
					proyecto_id,
					tipo
				}
			})
			.then(res => {
				let data = res.data;
				console.log('res buggetting', res)
				let presupuesto = {
					presupuesto: (data?.presupuesto?.presupuesto) ? data.presupuesto.presupuesto : 0,
					ultima_transaccion: (data?.ultimo_registro) ? data?.ultimo_registro : 0,
					ventas: (data?.ventas?.ventas) ? data.ventas?.ventas : 0
				}
				this.setState({ presupuesto })

			})
			.catch(error => {
				console.log('error presupuesto', error)
			})
	}


	/**
	 *
	 *
	 * @memberof Budgeting
	 * @description Obtiene los contadores de las transacciones clasificadas y sin clasificar
	 */
	getTransacccionesClasificadas = ({
		proyecto_id = this.state.proyecto._id,
		tipo = this.state.proyecto._id,


	}) => {
		axios.get('/transacciones/clasificacion/montos', {
			params: {
				proyecto_id,
				tipo
			}
		})
			.then(res => {
				this.setState({ transacciones: res.data.data })
			})
			.catch(error => {
				console.log('error al traer transacciones clasificacion', error)
				message.error('No se pudo cargar los datos de las transacciones')
			})
	}

	/**
	*
	*
	* @memberof Budgeting
	* @method getConceptos
	* @description Obtiene todas todas los conceptos con subconceptos
	* @param page página actual de la lista
	* @param limit cantidad de elementos por página
	* @param filters Filtros a aplicar a la página
	* @param sort Ordenar elementos
	* 
	*/
	getConceptos = ({
		page = this.state.conceptos.page,
		limit = this.state.conceptos.limit,
		filters = this.state.conceptos.filters,
		search = this.props.search,
		proyecto_id = this.state.proyecto._id,
		tipo = this.state.conceptos.tipo
	} = this.state.conceptos) => {
		this.setState({ conceptos: { ...this.state.conceptos, page, limit, filters, tipo } });
		axios.get('/conceptos/list',
			{
				params: {
					page,
					limit,
					filters,
					search,
					proyecto_id,
					subconceptos: true,
					tipo
				}
			})
			.then(({ data }) => {
				let list = data.data.itemsList;

				this.setState({
					conceptos: {
						...this.state.conceptos,
						// ventas:ventas,
						data: list,
						total: data.data.total,
						pages: data.data.pages,
						loading: false,



					},
					searching: true,
					filtroSearch: search
				});
				
				this.setState({
					sum_presupuesto: data.data.presupuesto,
					sum_ejercido: data.data.ejercido,
					promedio: data.data.porcentaje
				})
			})
			.catch(error => {
				console.log('error', error)
				message.error('No se pudieron cargar las conceptos')
			})
	}

	render() {

		return (
			<Spin spinning={this.state.conceptos.loading}>
				<Row className="row-encabezado" justify='space-around'>
					<Space className='width-100' style={{ justifyContent: "space-between", width: "100%" }}>
						<Title level={4} className="view-title">Budgeting</Title>
						<Form className="width-100" layout="vertical" ref={this.refForm} 						>
							<Space style={{ marginRight: "1em" }}>
								<Form.Item className="m-0" name="proyecto_id" >
									<Select
										className="form-select"
										onSearch={(search) => this.getProyectos(search)}
										onSelect={(proyecto_id) => this.changeProyecto(proyecto_id)}
										showSearch
										filterOption={false}
										placeholder="Selecciona un Proyecto"
										style={{ minWidth: '230px' }}
									>
										{this.state.proyectos.map((proyecto) => <Option value={proyecto._id}>
											<CustomAvatar
												image={proyecto.logo}
												name={proyecto.nombre}
												color={proyecto.color}
												size="small"
												style={{ marginRight: '5px' }}
											/>
											{proyecto.nombre}
										</Option>)}
									</Select>
								</Form.Item>
								<Form.Item className="m-0" name="tipo" >
									<Radio.Group
										onChange={e => {
											console.log("tipo", e.target.value)
											this.changeTipo(e.target.value)
										}}
										optionType="button"
										buttonStyle="solid"
										options={[
											{ label: 'Egresos', value: 2 },
											{ label: 'Ingresos', value: 1 },
											{ label: 'Ambos', value: null },
										]} />
								</Form.Item>
							</Space>

						</Form>
					</Space>

				</Row>
				<Content className="admin-content content-bg p-1 budgeting">
					<Row gutter={[16, 16]}>
						<Col span={8} className="grid">
							<CardProyecto
								image={this.state.proyecto?.logo}
								fecha={this.state.proyecto?.createdAt}
								color={this.state.proyecto?.color}
								nombre={this.state.proyecto?.nombre} />
						</Col>
						<Col span={8} className="grid">
							<CardPrespuesto
								presupuesto={this.state.presupuesto?.presupuesto}
								ejercer={(this.state.presupuesto?.presupuesto - this.state.sum_ejercido)}
								ventas={this.state.presupuesto?.ventas}
								ejercido={this.state.sum_ejercido}
								monto_ultima_transaccion={this.state.presupuesto?.ultima_transaccion?.monto}
							/>
						</Col>
						<Col span={8} className="grid">
							<CardTransaccionesClasificacion
								total={this.state.transacciones?.total}
								clasificadas={this.state.transacciones?.clasificadas}
								proyecto_id={this.state.proyecto._id}
							/>
						</Col>
					</Row>
					<Row className="row-blue">
						<Col span={6}>
							<Text> Concepto </Text>
						</Col>
						<Col span={5}>
							<Text> Presupuesto </Text>
						</Col>
						<Col span={6}>
							<Text> Ejercido </Text>
						</Col>
						<Col span={7}>
							<Text> Avance </Text>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							{this.state.conceptos?.data?.map(concepto =>
								<Collapse
									bordered={false}
									defaultActiveKey={['1']}
									expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
									className=" width-100 budgeting-collapse"
								>
									<Panel
										header={
											<Row justify='center' className="budgeting-collapse-header" style={{ width: "100%" }}>
												<Col span={6} className="center">
													<Link to={"/admin/budgeting/conceptos/" + concepto._id}>
														<Text strong ellipsis>{concepto.nombre}</Text>
													</Link>
												</Col>
												<Col span={5} className="center">
													<Text strong ellipsis>$ {concepto.presupuesto ? concepto.presupuesto.toMoney(true) : 0}</Text>
												</Col>
												<Col span={6} className="center">
													<Text strong ellipsis>$ {concepto.ejercido ? concepto.ejercido.toMoney(true) : 0}</Text>
												</Col>
												<Col span={7}>
													<Progress
														className='progressbar'
														percent={(concepto.porcentaje ? (parseInt(concepto.porcentaje)) : 0)}
														strokeColor={(concepto.porcentaje >= 100 ? 'rgba(245, 6, 6, 0.67)' : (concepto.porcentaje >= 90 ? '#FCFDC9' : 'rgba(61, 69, 251, 0.47)'))}
														format={(percent) => {
															switch (true) {
																case (percent >= 100): return <Text>{`${(concepto.porcentaje ? (parseInt(concepto.porcentaje)) : 0)}%`} <CloseCircleFilled style={{ color: 'rgba(245, 6, 6, 0.87)' }} /></Text>
																case (percent >= 90): return <Text>{`${percent}%`} <WarningFilled style={{ color: '#FCFDC9' }} /></Text>
																case (percent < 90): return <Text>{`${percent}%`}</Text>
															}
														}}
														success={{ percent: (concepto.porcentaje > 100 ? parseInt(concepto.presupuesto / concepto.ejercido * 100) : 0), strokeColor: "#FCFDC9" }} />
												</Col>
											</Row>
										}
										className="width-100"
									>
										{concepto.subconceptos?.map(subconcepto => {
											return <Card className="card-list borderless ml-1" key={subconcepto?._id} style={{ width: "100%" }}>
												<Row className="width-100 w-100">
													<Col span={6} className="center">
														<Link to={"/admin/budgeting/conceptos/" + concepto._id + "/" + subconcepto._id}>
															<Text ellipsis className="text-ligth">{subconcepto.nombre}</Text>
														</Link>

													</Col>
													<Col span={5} className="center">
														<Text ellipsis className="text-ligth">$ {subconcepto.presupuesto.toMoney(true)}</Text>
													</Col>
													<Col span={6} className="center">
														<Text ellipsis className="text-ligth">$ {subconcepto.ejercido ? subconcepto.ejercido.toMoney(true) : 0.00}</Text>
													</Col>
													<Col span={5} >
														<Progress
															percent={subconcepto.porcentaje ? parseInt(subconcepto.porcentaje) : 0}
															strokeColor={(subconcepto.porcentaje >= 100 ? 'rgba(245, 6, 6, 0.87)' : (subconcepto.porcentaje >= 90 ? '#FCFDC9' : 'rgba(61, 69, 251, 0.87)'))}
															format={(percent) => {
																switch (true) {
																	case (percent >= 100): return <Text>{`${subconcepto.porcentaje ? parseInt(subconcepto.porcentaje) : 0}%`} <CloseCircleFilled style={{ color: 'rgba(245, 6, 6, 0.87)' }} /></Text>
																	case (percent >= 90): return <Text>{`${percent}%`} <WarningFilled style={{ color: '#FCFDC9' }} /></Text>
																	case (percent < 90): return <Text>{`${percent}%`}</Text>
																}
															}}
															success={{ percent: (subconcepto.porcentaje > 100 ? parseInt(subconcepto.presupuesto / subconcepto.ejercido * 100) : 0), strokeColor: "#FCFDC9" }}
														/>
													</Col>
												</Row>
											</Card>
										})}
									</Panel>
								</Collapse>
							)}
						</Col>
					</Row>
					<Row className="row-blue">
						<Col span={6}>
						</Col>
						<Col span={6}>
							<Text> $ {this.state.sum_presupuesto.toMoney(true)}</Text>
						</Col>
						<Col span={6}>
							<Text> $ {this.state.sum_ejercido.toMoney(true)} </Text>
						</Col>
						<Col span={5}>
							<Text> {this.state.promedio.toMoney(true)} % </Text>
						</Col>
					</Row>
				</Content>
			</Spin>
		)
	}
}