import React, { Component } from 'react';
import { Collapse, List, Button, Col,Dropdown, Menu, Row,  Typography, Pagination, Spin, Popconfirm, Tooltip, Modal, Tag } from 'antd'

import { Link } from 'react-router-dom';
import { IconPlus, IconArrowCustom as Arrow,  IconPeople, IconPlusGroup, IconEdit, IconDelete, IconCopy, IconArrow } from '../../../Widgets/Iconos';
import axios from 'axios';

//componentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//modales
import ModalNuevoGrupo from "../../Modales/Nomina/ModalNuevoGrupo";
import ModalAnexarEmpleado from "../../Modales/Nomina/ModalAnexarEmpleado";
import ModalCopiaGrupo from "../../Modales/Nomina/ModalCopiaGrupo";
//css
import '../../../../Styles/nominas.css'

const { Title, Paragraph, } = Typography;
const { Panel } = Collapse;


/**
 *
 *
 * @export
 * @class RevisionDeGrupo
 * @extends {Component}
 *
 * @description Vista para la revisión de grupos.
 */
export default class RevisionDeGrupo extends Component {

    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @var state
     * @description Valores del estado.
     *
     * @var frecuenciaDropdown
     * @description Valores para el dropdown
     *
     * @var modalidadesDropdown
     * @description Valores para el dropdown
     *
     * @var modalGroup
     * @description Para la visiblidad del modal de agrgar o editar
     *
     * @var modalGroupId
     * @description ID de los modales para editar o agregar empleados
     *
     * @var modalEmployee
     * @description Para la visbilidad del modal de agregar empleado.
     *
     * @var data
     * @description Lista de elementos de grupo
     *
     * @var page
     * @description Número de página.
     *
     * @var limit
     * @description Limite de elementos en la tabla
     *
     * @var search
     * @description para buscar grupos
     *
     * @var loading
     * @description El spinner de loading
     *
     * @var itemCount
     * @description Numero de elementos.
     *
     */
    constructor(props) {
        super(props);
        this.state = {
            frecuenciaDropdown: {
                visible: false,
                list: [
                    {
                        nombre: "Salario",
                        valor: 1
                    },
                    {
                        nombre: "Destajo",
                        valor: 2
                    }
                ]
            },
            frecuencia: null,
            modalidadesDropdown: {
                visible: false,
                list: [
                    {
                        nombre: "Semanal",
                        valor: 1
                    },
                    {
                        nombre: "Quincenal",
                        valor: 2
                    }
                ]
            },
            modalidad: null,


            count: 0,

            modalGroup: false,
            modalGroupId: null,
            modalEmployee: false,
            modalTipo: '',


            searching: true,

            data: [],
            page: 1,
            limit: 100,
            loading: false,
            itemCount: 0,
            tipo: null,

            /*Modal para copiar grupo*/
            ModalCopyGroupVisible: false,
            dataCopyGroup: [],

            filtroSearch:"",
            searching:true
        };
    }



    /**
     *
     *
     * @memberof RevisionDeGrupo
     * @description Iniciamos los valores para obtener los grupos
     */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getGroups();
    }


      /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @method componentDidUpdate
     * @description actualiza la vista si se ha realizado una busqueda
     */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;
      
        if (filtro !== propFiltro && this.state.searching === true) {
            this.state.searching = false;
            this.getGroups();
        }
    }

    /**
      *
      *
      * @memberof Grupos
      *
      * @method componentDidUpdate
      * @description actualiza la vista si se ha realizado una busqueda
      */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.search;
        var propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching === true) {
            this.getGroups();
                this.state.searching = false;
        }
    }


    /**
     * @memberof  RevisionDeGrupo
     *
     * @function handleVisibleChange
     * @description Permite mostrar u ocultar los dropdowns en general
     *
     * @param type
     * @description Nombre del Dropdown a Ocultar o Mostrar
     *
     * @param flag
     * @description Si se mostrará u ocultará.
     *
     * */
    handleVisibleChange = (type, flag) => this.setState(state => {
        let element = state[type];
        element.visible = flag;
        state[type] = element;
        return state
    })


    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     *
     * @function showModal
     * @description Permite mostrar cualquier modal
     *
     * @param type
     * @description Nombre de la variable estado que controla el modal a mostrar
     *
     */
    showModal = modalVisibleStateControl => this.setState(state => {
        state[modalVisibleStateControl] = true;
        return state;
    });

    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     *
     * @function showModal
     * @description Permite mostrar cualquier modal
     *
     * @param type
     * @description Nombre de la variable estado que controla el modal a mostrar
     *
     */
    handleCancel = modalVisibleStateControl => {
        this.setState(state => {
            state[modalVisibleStateControl] = false;
            state.modalGroupId = null;
            return state;
        });
    };


    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function getGrupos
     * @description obtenemos los grupos por lista
     */
    getGroups = async (
        page = this.state.page,
        limit = this.state.limit,
        search = this.props.search,
        modalidad = this.state.modalidad,
        frecuencia = this.state.frecuencia
    ) => {
        this.setState({ loading: true })
        axios.get('/grupos/list', {
            params: {
                page,
                limit,
                search,
                modalidad,
                frecuencia
            }
        })
            .then(({ data }) => {

                let count = data.count;
                data = data.data
                this.setState({
                    data: data.itemsList,
                    page,
                    limit,

                    search: search,
                    itemCount: data.paginator.itemCount,
                    loading: false,
                    count: count,
                    searching:true,
                    filtroSearch: search,

                })
                this.state.searching = true;
              
            })
            .catch(error => {
            }).finally(f=>{
                this.setState({loading:false})
            })
    }




    /**
     *
     * @memberof RevisionDeGrupo
     *
     * @function addEmployee
     * @description Abre el modal de agregar empleado.
     *
     * @param group_id
     * @description Id del grupo
     *
     * @param tipo
     * @param Tipo de grrupo, si es salario o si es destajo
     *
     */
    addEmployee = (group_id, tipo) => {
        //<Title className="group-description">{(modalidad === 1) ? "Salario" : "Destajo"}</Title>
        var tipo = (tipo === 1) ? "salario" : "destajo"
        this.setState({
            modalGroupId: group_id,
            modalEmployee: true,
            modalTipo: tipo,
            tipo: tipo
        })

    }



    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function deleteGroup
     * @description Eliminamos el grupos.
     *
     * @param group_id
     * @description ID del grupoi a elimianr
     */
    deleteGroup = (group_id) => {
        axios.delete('/grupos/delete', {
            params: { group_id }
        })
            .then(({ data }) => {
                this.getGroups();
            })
            .catch(({ data }) => {
                Modal.error({
                    title: "NO TIENES PERMISOS",
                    content: "Usted no cuenta con los permisos necesarios para eliminar este grupo."
                })
            })
    }



    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function editGroup
     * @description Abre el modal para editar un grupo
     */
    editGroup = (group_id) => {

        this.setState({
            modalGroup: true,
            modalGroupId: group_id
        })
    }

    /**
     *
     *
     * @memberof RevisionDeGrupo
     * @function Actualizamos el filtro de la freencuencia
     *
     * @param valor Segun el tipo de freencuencia, puede ser 1 o 2
     */
    updateFrecuencia = async (valor) => {
        await this.setState({ frecuencia: (valor === this.state.frecuencia) ? null : valor });
        this.getGroups()
    }

    /**
     *
     *
     * @memberof RevisionDeGrupo
     * @function updateModalidad
     *
     * @param valor Segun la modalidad a buscar
     *
     */
    updateModalidad = async (valor) => {
        await this.setState({ modalidad: (valor === this.state.modalidad) ? null : valor });
        this.getGroups()
    }

    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function showModalCopyGroup Abre el modal de copia. Copiamos la copia.
     * @param values Valores a copiar
     */
    showModalCopyGroup = (values) => {
        if (values.area_id !== null && values.area_id !== undefined)
            values.area_id = values.area_id._id

        if (values.inmueble_id !== null && values.inmueble_id !== undefined)
            values.inmueble_id = values.inmueble_id._id

        this.setState({ ModalCopyGroupVisible: true, dataCopyGroup: values })
    }

    /**
     *
     *
     * @memberof RevisionDeGrupo
     *
     * @function hideModalCopyGroup Oculatmos el modal de copia
     *
     */
    hideModalCopyGroup = () => {
        this.setState({ ModalCopyGroupVisible: false })
        this.getGroups();
    }

    render() {

        const {
            frecuenciaDropdown,
            modalidadesDropdown,

            modalGroup,
            modalGroupId,
            modalEmployee,
            modalTipo,

            data,
            loading,

            tipo,
            itemCount,
            page,
            count
        } = this.state;

        const {
            handleVisibleChange,
            showModal,
            handleCancel,
            addEmployee,
            deleteGroup,
            editGroup,
            getGroups
        } = this;

        return (
            <div>
                <Row className="row-encabezado text-white">
                    <Col xs={24} xl={8} xxl={12}>
                        <h2>Grupos</h2>
                    </Col>
                    <Col xs={24} xl={16} xxl={12} className="center">
                        <Row style={{ width: '100%' }} gutter={[10, 10]}>
                            <Col className="center" xl={6} lg={8} md={12} sm={12} xs={11}>
                                <Dropdown
                                    className=""
                                    // Solamente para la visibilidad del Dropdown
                                    visible={frecuenciaDropdown.visible}
                                    //Para que no se cierre al darle click a algun elemento
                                    onVisibleChange={(flag) => handleVisibleChange('frecuenciaDropdown', flag)}
                                    overlay={
                                        <Menu>
                                            {/*Iteramos el arreglo de Proyectos*/}
                                            {frecuenciaDropdown.list.map(({ nombre, valor }, index) => (
                                                <Menu.Item key={index} onClick={() => this.updateFrecuencia(valor)}>
                                                    {/*Este es el checbox, recibe un evento*/}

                                                    <input
                                                        onChange={() => this.updateFrecuencia(valor)}
                                                        type="radio"
                                                        name="frecuencia"
                                                        value={valor}
                                                        style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                                        checked={this.state.frecuencia === valor}
                                                    />
                                                    {nombre}
                                                </Menu.Item>
                                            ))}

                                        </Menu>
                                    }>
                                   <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>Tipos <IconArrow/></a>
                                </Dropdown>
                            </Col>
                            <Col className="center" xl={6} lg={8} md={12} sm={12} xs={11}>
                                <Dropdown
                                    className=""
                                    // Solamente para la visibilidad del Dropdown
                                    visible={modalidadesDropdown.visible}
                                    //Para que no se cierre al darle click a algun elemento
                                    onVisibleChange={(flag) => handleVisibleChange('modalidadesDropdown', flag)}
                                    overlay={
                                        <Menu>
                                            {/*Iteramos el arreglo de Proyectos*/}
                                            {modalidadesDropdown.list.map(({ nombre, valor }, index) => (
                                                <Menu.Item key={index} onClick={() => this.updateModalidad(valor)}>
                                                    {/*Este es el checbox, recibe un evento*/}

                                                    <input
                                                        onChange={() => this.updateModalidad(valor)}
                                                        type="radio"
                                                        name="modalidad"
                                                        value={valor}
                                                        style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                                        checked={this.state.modalidad === valor}
                                                    />
                                                    {nombre}
                                                </Menu.Item>
                                            ))}

                                        </Menu>
                                    }>
                                   <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>Tipos <IconArrow/></a>
                                </Dropdown>
                            </Col>
                            <Col className="center" xl={6} lg={8} md={12} sm={12} xs={11}>
                                 <Title level={5} style={{  
                                    textAlign: "right", color: "#FFF", fontSize: '11pt', marginBottom: '0', marginRight: '2rem' }}> 
                                    {count} Grupos
                                </Title>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row style={{ padding: '1rem' }} className="content">
                    <Spin spinning={loading}>
                        <Row className="list-container scroll" gutter={[32, 32]} style={{ width: '100%'}}>
                            <Col span={24}>
                                <Title style={{ color: "#0047FF", marginLeft: "1em" }} level={3}>Empleados</Title>
                            </Col>
                            <Col span={24} style={{ paddingTop: 0 }}>

                                <Collapse
                                    bordered={false}
                                    expandIcon={({ isActive }) => <div>
                                        <div className={"plus_icon" + (isActive ? " open" : "")} />
                                    </div>}
                                    className="group-collapse"
                                >
                                    {data.map(({ _id, nombre, empleados, frecuencia, fecha_nomina, modalidad, responsable_id, precio_m2, area_id, inmueble_id, proyecto_id }, index) => {
                                        return <Panel header={<Row style={{ width: '100%' }} key={index}>
                                            <Col xs={14} xl={14} className="">
                                                <Row>
                                                    <Col span={8} className="center-left">
                                                        <Title className="group-title ">{nombre}</Title>
                                                    </Col>
                                                    <Col span={2} className="center-left">
                                                        <Title className="group-description">{empleados.length}</Title>
                                                    </Col>
                                                    <Col span={5} className="center-left">
                                                        <Title className="group-description">{(frecuencia === 1) ? "Semanal" : "Quincenal"}</Title>
                                                    </Col>
                                                    <Col span={4} className="center-left">
                                                        <Title className="group-description">{(modalidad === 1) ? "Salario" : "Destajo"}</Title>
                                                    </Col>
                                                    <Col span={5} className="center-left">
                                                        <Title className="group-description">{(modalidad === 1) ? ("$ " + empleados.reduce((accumulator, currentValue) => accumulator + currentValue.monto, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : null}</Title>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={1} xl={1} className="center ">
                                                <CustomAvatar
                                                    image={responsable_id ? responsable_id.avatar : ''}
                                                    name={[responsable_id ? responsable_id.nombre : 'N', responsable_id ? responsable_id.apellido : 'A']}
                                                    size="larget"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={5} xl={5} className="">
                                                <Row>
                                                    <Col span={24} className="center-left">
                                                        {(area_id) ? <Tooltip className="flex" title={area_id.nombre}><Tag color="cyan" className="tag-element">{area_id.nombre}</Tag> </Tooltip> : null}
                                                        {(inmueble_id) ? <Tooltip title={inmueble_id.nombre}><Tag color="blue" className="tag-element">{inmueble_id.nombre}</Tag></Tooltip> : null}
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col xs={4} xl={4} className="center ">
                                                <Button className="button button-people" onClick={e => e.stopPropagation()}>
                                                    <Link to={`/admin/nomina/grupo/${_id}`}>
                                                        <IconPeople />
                                                    </Link>
                                                </Button>
                                                <Button className="button button-plus" onClick={e => {
                                                    e.stopPropagation();

                                                    addEmployee(_id, modalidad)
                                                }}>
                                                    <IconPlusGroup />
                                                </Button>
                                                <Button className="button button-edit" onClick={(e) => {
                                                    e.stopPropagation()
                                                    editGroup(_id, modalidad)
                                                }}>
                                                    <IconEdit />
                                                </Button>
                                                <Button className="button button-copy-group" onClick={(e) => {
                                                    e.stopPropagation()

                                                    this.showModalCopyGroup({ _id, nombre, empleados, frecuencia, fecha_nomina, modalidad, responsable_id, precio_m2, area_id, inmueble_id, proyecto_id })
                                                }}>
                                                    <IconCopy />
                                                </Button>
                                                <Popconfirm
                                                    placement="topLeft"
                                                    title="¿Estás seguro de querer eliminar el grupo?"
                                                    onConfirm={e => {
                                                        e.stopPropagation();
                                                        deleteGroup(_id);
                                                    }}
                                                    onCancel={e => e.stopPropagation()}
                                                    okText="Si"
                                                    cancelText="No"
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <Button className="button button-delete" onClick={e => e.stopPropagation()}>
                                                        <IconDelete />
                                                    </Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>} className="group-collapse-panel" onClick={e => e.stopPropagation()}>
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={empleados}
                                                className="list-employees"
                                                renderItem={({ usuario_id, monto }, index) => (
                                                    <List.Item>
                                                        <Row style={{ width: '100%' }}>
                                                            {
                                                                (usuario_id) ? <Col span={14}>
                                                                    <CustomAvatar
                                                                        image={usuario_id.avatar}
                                                                        name={[usuario_id.nombre, usuario_id.apellido]}
                                                                        size="small"
                                                                        style={{
                                                                            marginRight: '5px'
                                                                        }}
                                                                    />
                                                                 &nbsp; {usuario_id.nombre + " " + usuario_id.apellido}
                                                                </Col> : <Col span={14}><strong>Empleado Inexistente</strong></Col>
                                                            }
                                                            <Col span={7}>
                                                                {(modalidad === 1) ? `$ ${monto.toString().toMoney(true)}` : ''}
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                )}
                                            />
                                        </Panel>
                                    })}
                                </Collapse>

                            </Col>
                            <Col >
                                <Pagination defaultPageSize={this.state.limit} defaultCurrent={page} total={itemCount} onChange={(page, limit) => getGroups(page, limit)} style={{ float: "right" }} />
                            </Col>
                        </Row>
                    </Spin>
                </Row>
                <Tooltip title="Crear nuevo grupo">
                    <Button type="ghost" className="btnAddPlus" onClick={() => showModal("modalGroup")}>
                        <IconPlus />
                    </Button>
                </Tooltip>
                <ModalAnexarEmpleado
                    hideModal={() => handleCancel("modalEmployee")}
                    groupId={modalGroupId}
                    visible={modalEmployee}
                    tipo={modalTipo}
                    modalidad={tipo}
                    accept={() => this.getGroups()}
                />
                <ModalNuevoGrupo
                    hideModal={() => {
                        this.getGroups()
                        handleCancel("modalGroup")
                    }}
                    groupId={modalGroupId}
                    visible={modalGroup} />

                <ModalCopiaGrupo
                    hideModal={this.hideModalCopyGroup}
                    data={this.state.dataCopyGroup}
                    visible={this.state.ModalCopyGroupVisible} />
            </div>
        )
    }
}
