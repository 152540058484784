import React, { Component } from "react";
import {
    Row, Col, Input, Button, Modal, Form, List, Menu, DatePicker,
    Typography, message, Select, Spin
} from 'antd';
import './../../../../Styles/modales.css'

import { IconCloseModal, IconEdit, IconDelete, IconArrowSelect } from '../../../Widgets/Iconos';
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import axios from 'axios'
const moment = require('moment');

const { Title, } = Typography;
const { Option } = Select;

const { TextArea } = Input;



export default class ModalRecordatorio extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {

            dataAreas: [],
            dataRubros: [],
            dataSubrubros: [],
            dataProyectos: [],
            dataUsuarios: [],

            form: {}



            // reminderId: null

        }
    }



    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.loadAreas();
        this.loadProyectos();
        this.getUsuarios()
    }


    componentDidUpdate(){
        if (this.state.reminderId !== this.props.reminderId){
            this.state.reminderId = this.props.reminderId;

            if (this.props.reminderId === null) {
            }else{
                this.getReminder(this.props.reminderId)
            }

        }

    }

    /**
     * 
     * @memberof ModalNuevoGrupo
     * 
     * @function getReminder
     * @description Obtenemos el recordatorio especifico
     * 
     * @param {*} id ID del recordatorio
     */
    getReminder = (id) => {

        axios.get('/recordatorios/get',{
            params: {
                id
            }
        }).then(async ({data})=>{
            data = data.data
            data.fecha = moment(data.fecha)
            await this.setState({form: data})
            this.formReference.current.resetFields()

            await this.onAreaChange(data.area_id)
            await this.onRubroChange(data.rubro_id)
        })


    }




    /**
     * 
     * @memberof ModalNuevoGrupo
     * 
     * @function getResponsables
     * @description Obtenemos los usuarios y se desplegan como opcion de asesor
     * */
    getUsuarios = async () => {
        await axios.get("/usuarios", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                paginate: false
            }
        })
            .then(({ data }) =>
                this.setState({
                    dataUsuarios: data.data
                })
            )
            .catch(e => console.log('er', e))

    }



    /**
     *
     *
     * @memberof ModalRecordatorio
     * @method loadProyectos
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadProyectos = () => {
        axios.get('/proyectos', {
            params: {
                page: 1,
                limit: 200
            }
        }).then((proyectos) => {
            this.setState({
                dataProyectos: proyectos.data.data.itemsList
            })
        }).catch((error) => {
        })
    }

    /**
     *
     *
     * @memberof ModalRecordatorio
     * @method loadAreas
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    loadAreas = () => {
        axios.get('/areas/list', {
            params: {
                paginate: false
            }
        }).then((response) => {
            this.setState({
                dataAreas: response.data.data
            })
        }).catch((error) => {
        })
    }

    /**
   * @methodOF ModalRecordatorio
   *
   * @function onAreaChange
   * @description Al actualizar un area, se deben traer los rubros y subrubros iniciales.
   * */
    onAreaChange = (id) => {
        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: id
            }
        }).then(async (rubros) => {

            this.setState({ dataRubros: rubros.data.data })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataRubros.length > 0) {
                this.ModalRecordatorioRef.current.setFieldsValue({
                    rubro: rubros.data.data[0]._id,
                });
                this.onRubroChange(rubros.data.data[0]._id)
            } else {
                this.ModalRecordatorioRef.current.setFieldsValue({
                    rubro: undefined,
                    subrubro: undefined
                })
            }
        }).catch((error) => {

        })
    }

    /**
     *
     *
     * @memberof ModalRecordatorio
     * @method onRubroChange
     * @description Actualiza el listado de subRubros al seleccionar un rubro diferente.
     */
    onRubroChange = (e) => {
        return axios.get('/subrubros/list', {
            params: {
                paginate: false,
                rubro_id: e
            }
        }).then((subrubros) => {
            this.setState({
                dataSubrubros: subrubros.data.data
            })
            //Actualizamos el valor del input de los subrubros en caso de haber disponibles
            if (this.state.dataSubrubros.length > 0) {
                this.ModalRecordatorioRef.current.setFieldsValue({
                    subrubro: subrubros.data.data[0]._id
                });
            } else {
                this.ModalRecordatorioRef.current.setFieldsValue({
                    subrubro: undefined
                })
            }


        }).catch((error) => {
        })
    }


    /**
      *
      *
      * @memberof ModalRecordatorio
      * @method onProyectoChange
      * @description Actualiza el listado de usuarios disponibles al cambiar un proyecto.
      */
    onProyectoChange = (e) => {
        return axios.get('/usuarios/proyecto', {
            params: {
                paginate: false,
                projecto_id: e
            }
        }).then((usuarios) => {
            this.setState({
                dataUsuarios: usuarios.data.data
            })
            //Limpiamos el cambo de usuarios
            this.ModalRecordatorioRef.current.setFieldsValue({
                usuarios: undefined
            })

        }).catch((error) => {
        })
    }


    /**
     * 
      * @memberof ModalRecordatorio
      * @method saveReminder
      * @description Actualiza el listado de usuarios disponibles al cambiar un proyecto.
     * 
     * @param {*} values Valores generador por el formualrio
     */
    saveReminder = (values) => {

        if (values._id){
            axios.post('/recordatorios/update',values)
            .then((re)=>{
                this.props.hideModal()
            })
            .catch(e=>{
                console.log("e",e)
            })
        }else{
            axios.post('/recordatorios/add',values)
            .then((re)=>{
                this.props.hideModal()
            })
            .catch(e=>{
                console.log("e",e)
            })
        }
    }

    formReference = React.createRef();


    render() {
        const {form} = this.state;
        const { visible, hideModal} = this.props;
        const {formReference, saveReminder} = this;
        return (
            <Modal
                visible={visible}
                onCancel={hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form  modal-recordatorio"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Recordatorios</Title>
                </div>
                <Form
                    layout="vertical"
                    name="formulario-recordatorio"
                    ref={formReference}
                    initialValues={form}
                    onFinish={saveReminder}
                >

                    <Form.Item
                        name="_id"
                        noStyle
                    >
                        <Input type="hidden" ></Input>
                    </Form.Item>
                    <Row>
                        <Col xs={24} lg={11} >
                            <Form.Item

                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" autoFocus={true} id="inp-name" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 1 }} >
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese fecha"
                                }]}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm"
                                    showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Area"
                                name="area_id"
                                listType="text"
                            >
                                <Select placeholder="Seleccione área" /*disabled={this.state.dataRubros}*/
                                    onChange={this.onAreaChange}>
                                    {this.state.dataAreas.map(function (area, index) {
                                        return <Option value={area._id}>{area.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item
                                label="Rubro"
                                name="rubro_id"
                                listType="text"
                            >
                                <Select placeholder="Seleccione rubro" /*disabled={this.state.dataRubros}*/
                                    onChange={this.onRubroChange}>
                                    {this.state.dataRubros.map(function (rubro, index) {
                                        return <Option value={rubro._id}>{rubro.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Subrubro"
                                name="subrubro_id"
                            >
                                <Select placeholder="Seleccione subrubro">
                                    {this.state.dataSubrubros.map(function (subrubro, index) {
                                        return <Option value={subrubro._id}>{subrubro.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id"
                                listType="text"
                            >
                                <Select placeholder="Seleccione proyecto"
                                    onChange={this.onProyectoChange}>
                                    {this.state.dataProyectos.map(function (proyecto, index) {
                                        return <Option value={proyecto._id}>{proyecto.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <IconArrowSelect />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} lg={11}>
                            <Col xs={24} >
                                <Form.Item
                                    label="Notificar a:"
                                    name="usuarios_id"
                                    listType="text"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione a quien notificar"
                                    }]}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Norificar a">
                                        {this.state.dataUsuarios.map((user, index) => (
                                            <Option key={index} value={user._id}>
                                                {user.nombre + ' ' + user.apellido}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item
                                label="Detalle"
                                name="detalle"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese detalle"
                                }]}

                            >
                                <TextArea placeholder="Detalle" className="text-area" />
                            </Form.Item>

                        </Col>

                    </Row>



                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul"  >
                                    Guardar
                                    </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        )
    }
}
