import React, { Component } from "react";
import { Row, Col, Button, Modal,  Typography, Form,  } from 'antd';

import { IconCloseModal  } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const moment = require('moment');
const { Title } = Typography;

/*
* Modal formulario para  ver datalle de Entrega
* ruta: /project-manager/material-entregado
*/
export default class ModalEntregaDetalle extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    /**
    * @memberof ModalEntregaDetalle
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el states
    *
    **/
    componentDidMount() {
        
    }



    /**
    * @memberof ModalEntregaDetalle
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props  
    *
    **/
    hideModal = () => {
        this.props.closeMethod();
    }



    /**
    * @memberof ModalEntregaDetalle
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        this.props.closeMethod();
    }


    
    render() {
        return (
            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Materiales Requeridos</Title>
                </div>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="formulario-entrega"
                    onFinish={this.onFinish}
                >
                   

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Button htmlType="submit" type="primary" className="btn-azul" >
                                Cerrar			
                            </Button>
                        </Col>
                    </Row>   
                </Form>
            </Modal>
        )
    }
}

