import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin } from 'antd';



import {IconCloseModal} from '../../Widgets/Iconos'; 

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');




/**
 *
 *
 * @class ModalAsociacionFacturasTransacciones
 * @extends {Component}
 */
class ModalAsociacionFacturasTransacciones extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orden: undefined,
            facturas: {
                data: [],
                loading: false,
                page: 1,
                limit: 10,
                total: 0,
                filtroSearch: '',
                searching: true,

            },
            transacciones: {
                data: [],
                loading: false,
                page: 1,
                limit: 10,
                total: 0,
                filtroSearch: '',
                searching: true,

            },

        }
    }

    modal_form = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.factura_id !== undefined) {
            this.getTransacciones()
        }
        if (this.props.transaccion_id !== undefined) {
            this.getTransaccion();
            this.getFacturas();
        }
    }



    /**
       *
       *
       * @memberof getTransaccion
       * @description Obtiene la informacion de la transaccion
       * @param values pagina
       */
    getTransaccion = () => {
        axios.post(`/transacciones/get`, {
            id: this.props.transaccion_id
        }).then((transaccion) => {
            const data = transaccion.data;
            this.setState({ transaccion: data.data });
        }).catch(error => {
            console.log('error', error.response)
            message.error('Error al obtener la Transaccion')
        })


    }



    /**
     * @memberof ModalAsociacionFacturasTransacciones
     * @method getFacturas
     * @description Las facturas que aun no han sido liquidadas
     * 
     */
    getFacturas = (
        {
            page = this.state.facturas.page,
            limit = this.state.facturas.limit,
            search = this.state.facturas.search,

        } = this.state.facturas,
        { facturas } = this.state,
    ) => {
        facturas.loading = true;
        facturas.page = page;
        facturas.limit = limit;
        facturas.search = search;
        this.setState({ facturas })

        axios.get('/facturas/list', {
            params: {
                page,
                limit,
                search,
                empresa_id: this.props.empresa_id,
                sin_transacciones: true
            }
        })
            .then(response => {
                this.setState({
                    facturas: {
                        ...this.state.facturas,
                        data: response.data.data.itemsList,
                        page: response.data.data.currentPage,
                        limit: response.data.data.prePage,
                        total: response.data.data.itemCount,
                    }
                })
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })

    }


    /**
       *
       *
       * @memberof Transacciones
       * @description Obtiene todas todas las transacciones
       * @param values pagina
       */
    getTransacciones = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
            search = this.state.transacciones.search,

        } = this.state.transacciones,
        { transacciones } = this.state,
    ) => {
        axios.post('/transacciones', {
            page,
            limit,
            search,
            empresa_id: this.props.empresa_id,
            sin_facturas: true,
        })
            .then(res => {
                this.setState({
                    transacciones: res.data.data
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            })
    }




    /**
      *
      *
      * @memberof Transacciones
      * @description Obtiene todas todas las transacciones
      * @param values pagina
      */
    onFinish = (values) => {

        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {

            if (this.props.factura_id !== undefined) {
                // this.addTransaccionToFactura(values);
                axios.post('/transacciones/link/facturas', {
                    facturas_ids: [this.props.factura_id],
                    ...values
                })
                    .then(res => {
                        console.log('res factura asignar', res)
                        message.success('Se asigno factura a transaccion')
                        this.props.onClose(true)
                    })
                    .catch(error => {
                        if(error.response.status === 403 )
                            message.error(error.response.data.message)
                        else
                            message.error('No se puso asignar factura a transaccion')
                    })
                    .finally(() => {
                      this.setState({loading: false})
                    })
            }
            else {
                axios.post('/transacciones/link/facturas', {
                    transaccion_id: this.props.transaccion_id,
                    ...values
                })
                    .then(res => {
                        console.log('res transaccion asignar', res)
                        message.success('Se asigno transacción a factura')
                        this.props.onClose(true)
                    })
                    .catch(error => {
                        if(error.response.status === 403 )
                            message.error(error.response.data.message)
                        else
                            message.error('No se puso asignar transacción a factura')
                        
                    })
                    .finally(() => {
                      this.setState({loading: false})
                    })
    
            }
        })



    }

    render() {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };
        return (
            <Form 
                layout="vertical"
                name="form-link"
                {...layout} 
                onFinish={this.onFinish} 
                className="mt-1"
            >

                {(this.props.factura_id != undefined) ?
                    <Form.Item 
                        label="Transacciones" 
                        name="transaccion_id" 
                    >
                        <Select
                            placeholder="Seleccione el Folio"
                            showSearch
                            onSearch={(search) => this.getTransacciones({ search })}
                            filterOption={false}
                        >
                            {this.state.transacciones.data.map(transaccion => {

                                let monto_diponible = transaccion.monto

                                transaccion.facturas.forEach(f =>{
                                    monto_diponible = (monto_diponible - f.monto_abonado)
                                })

                                return <Option value={transaccion._id} >
                                    {transaccion.concepto || transaccion.cliente_id?.razon_social || transaccion.cliente_id?.nombre}
                                    <div className="text-gray">Monto diponible: $ {monto_diponible.toMoney(true)} MXN</div>
                                    <div className="text-gray">{transaccion.orden_compra_id?.folio}</div>
                                </Option>
                            })}
                        </Select>
                    </Form.Item>
                    :
                    <Form.Item 
                        label="Facturas" 
                        name="facturas_ids"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione las facturas"
                        }]}
                    >
                        <Select
                            placeholder="Seleccione el Folio"
                            showSearch
                            onSearch={(search) => this.getFacturas({search})}
                            filterOption={false}
                            mode="multiple"
                        >
                            {this.state.facturas.data.map(factura => {
                                return <Option value={factura._id} >
                                    {factura.concepto || factura.proveedor_id?.nombre}
                                    <div className="text-gray">Monto restante: $ {factura.monto_restante.toMoney(true)} MXN</div>
                                    <div className="text-gray">{factura.proveedor_id?.nombre}</div>
                                </Option>
                            })}
                        </Select>
                    </Form.Item>
                }
                <Row style={{textAlign: "center"}}>
                    <Col span={24}>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" className="btn-azul">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        closable={false}
        destroyOnClose={true}
        zIndex={1000}
        className="modal-form"
        footer={false}
    >   
        <div className="modal-header">
            <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={onClose}>
                <IconCloseModal/>
            </Button>
            <Title level={3} className="modal-title">{`Asignar ${factura_id ? 'Transacción a Factura' : 'Factura a Transacción'}`}</Title>
        </div>
        {
            props.factura ? <Text level={4} className="pb-1">Monto Pendiente: $ {props.factura.monto_restante.toMoney()} MXN</Text> : null
        }
        <ModalAsociacionFacturasTransacciones {...props} />
    </Modal>

}