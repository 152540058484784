import React, { Component } from 'react';
import { Button, Col, Row, message, Typography, List, Card, Popconfirm, Pagination, Popover, Form, Select, Checkbox, Avatar, Space, Tag, Modal, Image } from 'antd'
import { Link } from 'react-router-dom';
//componentes
import { IconEdit, IconDelete, IconPlus, IconEye } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import { DownOutlined } from '@ant-design/icons';

//Modales
import ModalNuevaInversion from '../Modales/FinancialHealth/ModalNuevaInversion'
//css
import '../../../Styles/projectManager.css'
import qs from 'qs';

const axios = require('axios').default;
const { Text, Title } = Typography;


const { Option } = Select;


export default class Material extends Component {

    constructor(props) {
        super(props)
        this.state = {

            inversiones: {

                data: [],

                page: 1,
                limit: 100,
                
                total: 0,
                pages: 0,

                loading: false,
            },

            modalInversion: false,
            modalTitle: 'Nueva',
            inversion_id: undefined,
        }
    }

    //Referencia


    /**
    * @memberof Material
    * @method   componentDidMount
    * @description 
    * */
    componentDidMount() {
        this.getInversiones()
    }


    getInversiones = (
        {
            page = this.state.inversiones.page,
            limit = this.state.inversiones.limit,
            
            // total = this.state.inversiones.total,
            // pages = this.state.inversiones.pages

        } = this.state.inversiones
    ) => {

        this.setState({ inversiones: { ...this.state.inversiones, page, limit, loading: true } })

        axios.get('/inversiones/list', {
            params: {
                page, limit
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({ 
                inversiones: {
                    ...this.state.inversiones,
                    data: response.data.data.itemsList,
                    total: response.data.data.itemCount,
                    pages: response.data.data.pageCount,
                    loading: false
                }
            })
        }).catch(error => {
            console.log(error)
        })
    }

    deleteInversion = (_id) => {
        axios.post('/inversiones/delete', {
            _id
        }).then(response => {
            message.success('Inversion Eliminada')
            this.getInversiones();
        }).catch(error => {
            console.log(error)
            message.success('Error')
        })
    }






    render() {
        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={12} xl={20} xxl={21} className="flex-left">
                        <Title level={4} className="view-title">Inversiones </Title>
                    </Col>
                </Row>

                <section className="p-1">
                    <List
                        loading={this.state.inversiones.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.inversiones.data}
                        header={<Row className="header-list" style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>
                            <Col span={1} className="gutter-row center">
                                <Text strong>Proyecto</Text>
                            </Col>
                            <Col span={5} className="gutter-row center">
                                <Text strong>Nombre</Text>
                            </Col>
                            <Col xs={5} className="gutter-row center">
                                <Text strong>MONTO TOTAL</Text>
                            </Col>
                            <Col xs={5} className="gutter-row  center">
                                <Text strong>MONTO PAGADO</Text>
                            </Col>
                            <Col xs={4} className="gutter-row  center">
                                <Text strong>MONTO POR PAGAR</Text>
                            </Col>
                            <Col xs={4} className="gutter-row center">
                                <Text strong>ACCIONES</Text>
                            </Col>
                        </Row>}
                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">
                                        <Col span={1} className="center">
                                            <Text strong>
                                                {/* logo:null
                                                nombre:"Softima"
                                                descripcion:"Softima SA de CV"
                                                color:"#73d8ff" */}
                                                <CustomAvatar 
                                                    image={item.proyecto?.logo}
                                                    name={item.proyecto?.nombre}
                                                    color={item.proyecto?.color}
                                                    />
                                            </Text>
                                        </Col>

                                        <Col span={5} className="center">
                                            <Text strong>{item.nombre}</Text>
                                        </Col>

                                        <Col xs={5} className="center">
                                            <Text strong>$ {item.monto_venta?.toMoney()} MXN</Text>
                                        </Col>

                                        <Col xs={5} className="center">
                                            <Text strong>$ {item.monto_pagado?.toMoney()} MXN</Text>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Text strong>$ {item.monto_restante?.toMoney()} MXN</Text>
                                        </Col>

                                        <Col xs={4} className="center">
                                            <Link title="Ver" to={`/admin/financial-health/inversion/detalle/${item._id}`} >
                                                <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle"  >
                                                    <IconEye />
                                                </Button>
                                            </Link>
                                            <Button className="btn btn-edit" title="Editar" onClick={() => { this.setState({ inversion_id: item._id, modalInversion: true, modalTitle: 'Editar' }) }}>
                                                <IconEdit />
                                            </Button>
                                            <Popconfirm placement="topRight" title="¿Eliminar esta inversion?" okText="Si, seguro" cancelText="No"
                                                onConfirm={() => this.deleteInversion(item._id)}>
                                                <Button className="btn btn-delete" title="Editar">
                                                    <IconDelete />
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                    {console.log("this.state.inversiones", this.state.inversiones)}
                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.inversiones.page}
                            pageSize={this.state.inversiones.limit}

                            total={this.state.inversiones.total}

                            showSizeChanger={true}
                            onChange={(page, limit) => {
                                this.getInversiones({ page, limit })
                            }}
                        />
                    </Row>

                </section>

                <Button className="btnAddPlus" title="Nuevo registro" onClick={() => this.setState({ modalInversion: true, modalTitle: 'Nueva', inversion_id: undefined })}>
                    <IconPlus />
                </Button>

                <ModalNuevaInversion
                    visible={this.state.modalInversion}
                    onClose={() => {
                        this.setState({ modalInversion: false, inversion_id: undefined })
                        this.getInversiones()
                    }}
                    modalTitle={this.state.modalTitle}
                    inversion_id={this.state.inversion_id}
                />
            </div>
        )
    }
}
