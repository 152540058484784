import React, { Component } from 'react';
import { Button, Popconfirm, Space, Row, List, Col, Spin, PageHeader, Card, Layout, message, Typography, Avatar, Collapse, Pagination } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { MinusOutlined, PlusOutlined, FilterOutlined } from '@ant-design/icons';
//Componentes
import VerDetalle from '../../../Widgets/VerDetalle';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconDelete, IconMoney, IconEdit, IconPlus } from '../../../Widgets/Iconos';

//modales
import ModalSalidaInventario from "./ModalSalidaInventario";

//drawer
import DrawerInventarios from './DrawerInventario'


const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography

/**
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista de Productos
 */
export default class Productos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            drawer_filtros: false,
            filtros: {},
            page: 1,
            limit: 20,
            total: 0
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        window.scrollTo(0, 0)

        this.getProductosAlmacen()
    }


    componentDidUpdate(prevProps) {
        var propFiltro = this.props.search;
        console.log("propFiltro", propFiltro);
        if(prevProps.search != this.props.search){
            this.getProductosAlmacen()
        }
       
    }


    /**
    * @memberof Almacenes
    * @method getProductosAlmacen
    * @description  Obtiene los productos registrados de todos los almacenes de todos los proyectos
    **/
    getProductosAlmacen = ({
        page = this.state.page, 
        limit = this.state.limit,
        filtros = this.state.filtros
    } = this.state) => {
        console.log("page", page);
        this.setState({loading: true})
        axios.get('/almacen/productos',{
            params:{
                page,
                limit,
                ...filtros,
                search: this.props.search,
            }
        }).then(response => {
            console.log("respo", response.data.data);
            this.setState({
                data: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,
                limit: response.data.data.perPage,
                filtros: filtros
            })
            
        }).catch(error => {
            message.error('Error al obtener la información del almacén')
        }).finally(()=>this.setState({loading: false}))
    }




    render() {

        return (
            <div>
                <Spin spinning={false}>
                    <Row className="row-encabezado">
                        <Col xs={12} xl={15} xxl={15} className="flex-left">
                            <Title level={4}className="view-title">Inventario</Title>
                        </Col>
                        <Col xs={12} xl={6} className="flex-right">
                            <Button
                                style={{ backgroundColor: '#007BFF'}}
                                onClick={() => this.setState({ drawer_filtros: true })}
                                icon={<FilterOutlined style={{ color: '#fff', fontWeight: 600 }} />}
                            />
                        </Col>
                    </Row>
                    <Content className="admin-content content-bg p-1">
                        <Row style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }} className="title-list-inmuebles mb-1">
                            <Col span={6} className="center">
                                <Text type="secondary">NOMBRE</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text type="secondary">SKU GM</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text type="secondary">CATEGORIA</Text>
                            </Col>
                            <Col span={1} className="center">
                                <Text type="secondary">PROYECTO</Text>
                            </Col>
                            <Col span={5} className="center">
                                <Text type="secondary">ALAMCEN</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text type="secondary">CANTIDAD</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text type="secondary">UNIDAD</Text>
                            </Col>

                        </Row>
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.data}
                            locale={{emptyText: 'Sin Requisisciones'}}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row style={{ width: '100%' }} className="">

                                            <Col xs={12} md={6} className="flex-left">
                                                { (Array.isArray(item.producto_id?.imagenes) && item?.producto_id?.imagenes.length  > 0) ? 
                                                    <Avatar style={{ marginRight: 10 }} shape="square" src={axios.defaults.baseURL + "/upload/" + item?.producto_id?.imagenes[0].filename } size={60} /> : null
                                                }
                                                <Text>{item.producto_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Text>{item.producto_id?.sku_manantial}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                               <Text>{item.categoria?.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={1} className="center">
                                                <CustomAvatar
                                                    image={item.proyecto_id?.logo}
                                                    name={item.proyecto_id?.nombre}
                                                    color={item.proyecto_id?.color}
                                                />
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text>{item.almacen_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Text>{item?.cantidad}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Text>{item.producto_id?.unidad?.toUnidad()}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                        <Row>
                            <Col span={24}>

                                <Pagination
                                    current={this.state.page}
                                    total={this.state.total}
                                    pageSize={this.state.limit}
                                    showSizeChanger={true}
                                    onChange={(page, limit) => this.getProductosAlmacen({page, limit})}

                                />
                            </Col>
                        </Row>
                   
                    </Content>
                </Spin>
                <Button className="btnAddPlus" title="Nuevo registro" 
                    onClick={()=>{
                        this.setState({modal_visible: true})
                    }} 
                >
                    <IconPlus />
                </Button>
                <ModalSalidaInventario
                    visible={this.state.modal_visible}
                    onCancel={(flag)=>{
                        this.setState({modal_visible: false})
                        if(flag === true){
                            this.getProductosAlmacen()
                        }
                    }}
                />
                <DrawerInventarios
                    title="Filtrar Inventario"
                    visible={this.state.drawer_filtros}
                    onClose={()=>{
                        this.setState({drawer_filtros: false})
                    }}
                    updateFilters={filtros => {
                        this.getProductosAlmacen({
                            filtros
                        })
                    }}
                />
            </div>
            
        )
    }
}