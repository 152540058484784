import React, { Component } from 'react';
import { Button, Popconfirm, Space, Row, Col, Spin, Layout, message, Typography, Collapse, List, } from 'antd'
import axios from 'axios';
import { PlusOutlined } from "@ant-design/icons"
import { IconEdit, IconEye, IconDelete, IconPlus, IconArrowBack, IconAddAcount } from '../../../Widgets/Iconos'
import ModalConcepto from './ModalConcepto'
import ModalSubConcepto from './ModalSubConcepto';
import SubConceptosPanel from './SubConceptosPanel';
import '../../../../Styles/budgeting.css'

const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography

/**
 * @export
 * @class Conceptos
 * @extends {Component}
 * @description Vista de Conceptos
 */
export default class Conceptos extends Component {

	volver = () => {
		window.history.back();
	}

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			loadingSubconceptos: false,
			data: [],
			proyectos: [],
			proyecto: {},
			page: 1,
			limit: 500,
			total: 0,
			pages: 0,
			proyecto_id: this.props.match.params.proyecto_id,
			sub_concepto_id: null,
			subConceptos: {
				data: [],
				page: 1,
				limit: 500,

				total: 0,
				pages: 0,
			},
			reload: false,
			activePanel: null,
		}
	}

	refForm = React.createRef()

	refSubConceptos = React.createRef()

	componentDidMount() {
		//Mostrar input superior de busqueda 
		//Para acceder al valor del input = this.props.search
		this.props.updateFilterSearch(true);
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		window.scrollTo(0, 0)
		this.getProyecto()
		this.getConceptos()

	}


	/**
	* @memberof Conceptos
	* @method getProyecto
	* @description  Obtiene la informacion del proyecto seleccionado
	**/
	getProyecto = () => {
		axios.get('/proyectos/get', {
			params: {
				id: this.props.match.params.proyecto_id
			}
		}).then(response => {
			this.setState({ proyecto: response.data.data })
		}).catch(error => {
			message.error('Error al obtener la información del proyecto')
		})
	}


	/**
	* @memberof Conceptos
	* @method getConceptos
	* @description  Obtiene la lista de conceptos existentes para el proyecto seleccionado
	**/
	getConceptos = (page = this.state.page, limit = this.state.limit) => {
		this.setState({loading: true})
		axios.get('/conceptos/list', {
			params: {
				proyecto_id: this.props.match.params.proyecto_id,
				page,
				limit
			}
		}).then(response => {
			console.log("response", response.data.data);
			this.setState({
				data: response.data.data.itemsList,
				page: response.data.data.currentPage,
				total: response.data.data.itemCount,
			})

		}).catch(error => {
			message.error('Error al obtener los Conceptos')
			console.log("El error es", error)
		}).finally(()=>this.setState({loading: false}))
	}


	subConceptosRef = []


	render() {

		const { proyecto } = this.state

		return (
			<div>
				<Spin spinning={false}>
					<Row className="row-encabezado">
						<Col xs={12} xl={15} xxl={15} className="flex-left">
							<Button className="IconArrowBack" onClick={this.volver}>
								<IconArrowBack />
							</Button>
							<Title level={4} className="view-title">Conceptos de {proyecto.nombre}</Title>
						</Col>
					</Row>
					<Content className="admin-content content-bg p-1 budgeting">
						{console.log("data", this.state.data)}
						<List
							loading={this.state.loading}
							className='component-list width-100'
							locale={{ emptyText: 'No hay conceptos' }}
							dataSource={this.state.data}
							pagination={{
								onChange: (page) => this.getConceptos(page),
								current: this.state.page,
								pageSize: this.state.limit,
								total: this.state.total,
								position: 'bottom',
								hideOnSinglePage: true
							}}

							header={
								<Row className="header-list w100 pr-1 pl-1" >
									<Col xs={2} lg={2} className="center">
										<Text strong> </Text>
									</Col>
									<Col xs={10} lg={15} className="flex-left">
										<Text strong>NOMBRE</Text>
									</Col>
									<Col xs={6} lg={4} className="center">
										<Text strong>PRESUPUESTO</Text>
									</Col>
									<Col xs={6} lg={3} className="center">
										<Text strong>ACCIONES</Text>
									</Col>
								</Row>
							}

							renderItem={(item, index) => (
								<Collapse className="custom-collapse" /* onChange={this.getSubConceptos(this.state.subConceptos.page, item._id)} */ >
									<Panel
										forceRender={true}
										className='panel-list'
										extra={
											<Row className="width-100">
												<Col xs={2} lg={2} className="center">
													<Text>{item.alias}</Text>
												</Col>
												<Col xs={10} lg={15} className="flex-left">
													<Text>{item.nombre}</Text>
												</Col>
												<Col xs={6} lg={4} className="center">
													<Text>{item.presupuesto ? `$ ${item.presupuesto?.toMoney()} MXN` : `No establecido`}</Text>
												</Col>
												<Col xs={6} lg={3} className="center">
													<Button type="" icon={<IconEdit style={{ color: "white", width: 12, heigth: 12, marginTop: '5px' }} />} size='small' className="btn btn-edit" title="Editar" onClick={event => { event.stopPropagation(); this.setState({ modal_visible: true, concepto_id: item._id }) }} />
													<Button type="" icon={<PlusOutlined style={{ color: "white" }} />} size='small' className="btn btn-edit" title="Editar" onClick={event => { event.stopPropagation(); this.setState({ modal_visible_subConceptos: true, concepto_id: item._id, referenceIndex: index }) }} />
													<Popconfirm
														placement="topRight"
														title="¿Deseas eliminar este Concepto?"
														onConfirm={() => axios.post('/conceptos/delete', { id: item._id })
															.then((response) => {
																message.success("Eliminado Correctamente")
																this.getConceptos(1)
															})
															.catch((error) => {
																console.log("error", error);
																message.error("No es posible eliminar")
																this.getConceptos(this.state.page);
															})
														}
														okText="Si"
														cancelText="No"
													>
														<Button className="btn btn-delete" icon={<IconDelete style={{ width: 12, heigth: 12, marginTop: '5px' }} />} size='small' title="Eliminar" onClick={event => { event.stopPropagation() }} />
													</Popconfirm>
												</Col >
											</Row>
										}
										key={item._id}
									>
										<SubConceptosPanel
											ref={r => this.subConceptosRef[index] = r}
											proyecto_id={this.state.proyecto_id} concepto_id={item._id}
											updateFather={this.getConceptos} />
									</Panel>
								</Collapse>
							)}
						/>
					</Content>
				</Spin>
				<Button className="btnAddPlus" title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} >
					<IconPlus />
				</Button>
				<ModalConcepto
					visible={this.state.modal_visible}
					onCancel={() => {
						this.setState({ modal_visible: false, concepto_id: undefined })
						this.getConceptos()
					}}
					proyecto_id={this.state.proyecto_id}
					concepto_id={this.state.concepto_id}
				/>
				<ModalSubConcepto
					visible={this.state.modal_visible_subConceptos}
					onCancel={() => {
						this.setState({ modal_visible_subConceptos: false, sub_concepto_id: undefined, concepto_id: undefined })
						this.getConceptos()
						this.subConceptosRef[this.state.referenceIndex].getSubConceptos()
					}}
					proyecto_id={this.state.proyecto_id}
					concepto_id={this.state.concepto_id}
					sub_concepto_id={this.state.sub_concepto_id}
				/>
			</div>

		)
	}
}