import React, { Component } from "react";
import { Row, Col, Button, Modal,  DatePicker, Typography, Input, Form, InputNumber, Checkbox, Upload, Select, message, Tag, Tabs, Empty  } from 'antd';
import axios from 'axios'
import {CheckCircleFilled, UploadOutlined} from '@ant-design/icons';
//componentes
import { IconCloseModal,IconArrowSelect  } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import './../../../../Styles/modales.css'
const moment = require('moment');
const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;


export default class ModalDetalle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transaccion: undefined,
        }
    }

    /**
    * @memberof ModalDetalle
    *
    * @method   componentDidUpdate
    * @description  
    *
    **/
    componentDidUpdate(prevProps) {
        if(this.props.transaccion_id !== prevProps.transaccion_id && this.props.transaccion_id !== undefined && this.props.visible === true){
            this.getTransaccion(this.props.transaccion_id)
        }

        if(this.props.factura  && this.props.factura.factura_xml && this.props.factura !== prevProps.factura && this.props.visible === true){
            this.getXMLjson(this.props.factura.factura_xml)
        }
    }

    /**
    * @memberof ModalDetalle
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props  
    *
    **/
    hideModal = () => {
        this.setState({transaccion: undefined, xml: undefined})
        this.props.closeMethod();
    }

    /**
    * @memberof ModalDetalle
    *
    * @method   renderEstatus
    * @description renderiza tags dependiendo del estatus 
     */
    renderTags = (estatus) => {
        let tags = {
            '1': <Tag className="tag-proyecto" color="#226dff">Vigente</Tag>,
            '2': <Tag className="tag-proyecto" color="#e1c634">Por Vencer</Tag>,
            '3': <Tag className="tag-proyecto" color="#fa1e1e">Vencida</Tag>,
            '4': <Tag className="tag-proyecto" color="#3e8c12">Liquidada</Tag>,
            '5': <Tag className="tag-proyecto" color="purple">Factura</Tag> 
        }

        return tags[estatus]
    }


    /**
     *
     *
     * @memberof ModalDetalle
     * @function getTransaccion
     *
     * @description obtiene los datos de una transaccion
     */

    getTransaccion = (id) => {
        axios.post('/transacciones/get', {
            id: id
        }).then((transaccion) => {
            let data = transaccion.data.data;
            this.setState({transaccion: data})
        }).catch(error=>{
            console.log(error)
        })  
    }


    /**
     *
     *
     * @memberof ModalDetalle
     * @function getXMLjson
     *
     * @description obtiene los datos de una transaccion
     */

    getXMLjson = (filename) => {
        axios.get('/factura/xml', {
            params: { filename: filename}
        }).then((response) => {
            let obj = response.data.data
            this.setState({xml: response.data.data})
        }).catch(error=>{
            console.log(error)
        })  
    }

    /**
     *
     *
     * @memberof ModalDetalle
     * @function renderXML
     *
     * @description obtiene los datos de una transaccion
     */

    renderObj = (element) => {
        if(Array.isArray(element)){
            let columnas = []

            element.forEach(elem => {
                let addColumns = Object.keys(elem);

                addColumns.forEach(col=>{
                    let index = columnas.indexOf(col)
                    if(index === -1){
                        columnas.push(col)
                    }
                })
            })
            return <div className="table-container"> 
                <table className="factura-xml-table">
                    <thead>
                        <tr>
                            {columnas.map(col=><th>{col}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {element.map(elem=> {
                            return <tr>
                              {columnas.map(col=><td>{elem[col] ? typeof elem[col] === 'string' ? <Text>{elem[col]}</Text> : this.renderObj(elem[col]) : '-'}</td>)}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            



        } else if(typeof element === 'object'){
            return <div>
                {
                    Object.keys(element).map((key,i)=>{
                        if(typeof element[key] === 'object'){
                            return <div >
                                <div className="div-title">{key}</div>
                                 <div className="div-obj">
                                    {this.renderObj(element[key])}
                                 </div>
                             </div>
                        }else if (typeof element[key] === 'string'){
                            return <div className="div-row">
                                <div className="div-key">{key}</div>
                                <div className="div-string">{element[key]}</div>
                            </div>
                        }
                    })
                }
            </div>
        }
    }

    /**
     *
     *
     * @memberof ModalDetalle
     * @function renderObj
     *
     * @description obtiene los datos de una transaccion
     */

    renderText = (obj) => {
    
        return (
            <div>
                {
                    Object.keys(obj).map((key,i)=> (
                        <div className="div-row">
                            <div className="div-key">{key} :</div>
                            {typeof obj[key] === 'string' ? <div className="div-string">{obj[key]}</div> : <div>{typeof obj[key]}</div>}
                        </div>
                    ))
                }
            </div>
        )
    }


    /**
    * @memberof ModalDetalle
    *
    * @method   renderFactura
    * @description muestra a detalle la informacion de la factura
    *
    **/
    renderFactura = (factura) => {
        return <Tabs defaultActiveKey="1">
            <TabPane tab="Detalle" key='1'>
                <Row align="center">
                    <Col span={23}>
                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item label="Concepto">
                                   <Text strong> {factura.concepto} </Text>
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={{span: 11, offset:2}}>
                                <Form.Item label="Proyecto" >
                                    <Text strong> <CustomAvatar
                                        image={factura.proyecto_id.logo}
                                        name={factura.proyecto_id.nombre}
                                        color={factura.proyecto_id.color}
                                        size="small"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    />
                                    {factura.proyecto_id.nombre}</Text>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item label="Monto Total" >
                                   <Text strong> $ {factura.monto.toMoney(2)} MXN </Text>
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={{span: 11, offset:2}}>
                                <Form.Item label="Monto por Pagar" >
                                   <Text strong> $ {factura.monto_restante.toMoney(2)} MXN </Text>
                                </Form.Item>
                            </Col>  
                        </Row>

                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item label="Fecha de Vigencia" >
                                   <Text strong> {moment(factura.fecha_vigencia).format('DD-MM-YYYY')} </Text>
                                </Form.Item>
                            </Col>
                            <Col xs={22} md={{span: 11, offset:2}}>
                                <Form.Item label="Estatus" >
                                   {this.renderTags(factura.info_estatus)}
                                </Form.Item>
                            </Col>  
                        </Row>
                        <Row>
                            <Col xs={22} md={11}>
                                <Form.Item label="Archivos" >
                                    {factura.cotizacion_pdf ? <a target="_blank" href={`${axios.defaults.baseURL}/voucher/${factura.cotizacion_pdf}`}>{factura.cotizacion_pdf}</a> : null}
                                    {factura.factura_pdf ? <a target="_blank" href={`${axios.defaults.baseURL}/voucher/${factura.factura_pdf}`}>{factura.factura_pdf}</a> : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        {factura.factura_xml ? <>
                            <Row>
                                <Col span={22}>
                                    <Title level={4}>Dartos Generados por el XML</Title>
                                </Col>
                                <Col xs={22} md={11}>
                                    <Form.Item label="Folio" >
                                        <Text strong> {factura.folio} </Text>
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item label="Fecha" >
                                       <Text strong> {moment(factura.fecha).format('DD-MM-YYYY')} </Text>
                                    </Form.Item>
                                </Col> 
                                <Col xs={22} md={11}>
                                    <Form.Item label="Descuento" >
                                        <Text strong>$ {factura.descuento?.toMoney(2)} {factura.moneda} </Text>
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item label="Sub Total" >
                                       <Text strong> $ {factura.sub_total?.toMoney(2)} {factura.moneda} </Text>
                                    </Form.Item>
                                </Col> 
                                <Col xs={22} md={11}>
                                    <Form.Item label="Impuestos Retenidos" >
                                        <Text strong> $ {factura.total_impuestos_retenidos?.toMoney(2)} {factura.moneda} </Text>
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item label="Impuestos Trasladados" >
                                        <Text strong> $ {factura.total_impuestos_trasladados?.toMoney(2)} {factura.moneda} </Text>
                                    </Form.Item>
                                </Col>
                                <Col xs={22} md={11}>
                                    <Form.Item label="Total" >
                                        <Text strong> $ {factura.total?.toMoney(2)} {factura.moneda} </Text>
                                    </Form.Item>
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={24} className="hr"></Col>
                                <Col span={22}>
                                    <Title level={5}>Emisor</Title>
                                </Col>
                                <Col xs={22} md={11}>
                                    <Form.Item label="RFC" >
                                        <Text strong>{factura.emisor?.rfc} </Text>
                                    </Form.Item>
                                </Col> 
                                 <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item label="Nombre" >
                                        <Text strong>{factura.emisor?.nombre}</Text>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className="hr"></Col>
                                <Col span={22}>
                                    <Title level={5}>Receptor</Title>
                                </Col>
                                <Col xs={22} md={11}>
                                    <Form.Item label="RFC" >
                                        <Text strong>{factura.receptor?.rfc} </Text>
                                    </Form.Item>
                                </Col> 
                                 <Col xs={22} md={{span: 11, offset:2}}>
                                    <Form.Item label="Nombre" >
                                        <Text strong>{factura.receptor?.nombre}</Text>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>: null }
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab="XML" key='2'>
                <Row className="row-index">
                    <Col span={24}>
                    {this.state.xml ? this.renderObj(this.state.xml) : <Empty description="No hay ningún archivo XML" />}
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    }

    /**
    * @memberof ModalDetalle
    *
    * @method   renderTran
    * @description muestra a detalle la transaccion
    *
    **/
    renderTran = (transaccion) => {
        return <Row align="center">
            <Col span={23}>
                <Row>
                    <Col xs={22} md={11}>
                        <Form.Item label="Concepto">
                           <Text strong> {transaccion.concepto}  </Text>
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={{span: 11, offset:2}}>
                        <Form.Item label="Proyecto" >
                            <Text strong><CustomAvatar
                                image={transaccion.proyecto_id.logo}
                                name={transaccion.proyecto_id.nombre}
                                color={transaccion.proyecto_id.color}
                                size="small"
                                style={{
                                    marginRight: '5px'
                                }}
                            />
                            {transaccion.proyecto_id.nombre}</Text>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xs={22} md={11}>
                        <Form.Item label="Monto" >
                           <Text strong> $ {transaccion.monto.toMoney(2)} MXN   </Text>
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={{span: 11, offset:2}}>
                        <Form.Item label="Cuenta" >
                           <Text strong> <CustomAvatar
                                name={transaccion.cuenta_id.nombre}
                                size="small"
                                style={{
                                    marginRight: '5px'
                                }}
                            />
                            {transaccion.cuenta_id.nombre} </Text>
                        </Form.Item>
                    </Col>  
                </Row>

                <Row>
                    <Col xs={22} md={11}>
                        <Form.Item label="Area" >
                           <Text strong> {transaccion.area_id.nombre} </Text>
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={{span: 11, offset:2}}>
                        <Form.Item label="Rubro" >
                           <Text strong> {transaccion.rubro_id.nombre} </Text>
                        </Form.Item>
                    </Col>  
                </Row>
                <Row>
                    <Col xs={22} md={11}>
                        <Form.Item label="Sub Rubro" >
                            <Text strong> {transaccion.sub_rubro_id.nombre} </Text>
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={{span: 11, offset:2}}>
                        <Form.Item label="Fecha" >
                           <Text strong> {moment(transaccion.createdAt).format('DD-MM-YYYY')} </Text>
                        </Form.Item>
                    </Col> 
                </Row>
                <Row>
                    <Col xs={22} md={11}>
                        <Form.Item label="Usuario" >
                            <Text strong> {transaccion.usuario_id?.nombre} {transaccion.usuario_id?.apellido} </Text>
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    render() {

        const { factura } = this.props
        const { transaccion } = this.state
 
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-form-large"
                destroyOnClose={true}
                zIndex={1000}
                width={700}
            >

                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Detalle {this.props.tipo}</Title>
                </div>
                
                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                >
                    { factura ? this.renderFactura(factura): transaccion ? this.renderTran(transaccion) : null}

                    <Row style={{ textAlign: "center" }}>
                        <Col xs={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" className="btn-azul"  onClick={this.hideModal}>
                                    Cerrar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                
            </Modal>
        )
    }
}
