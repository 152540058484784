import React, {useState, useEffect} from "react";
import {message} from 'antd'
import useCountDown from "react-countdown-hook";


export default function ColorPicker (props){

	const [timer, actions] = useCountDown(1000 * 60 * 5)
	const [count, setCount] = useState(props.timerCount)

	let { timerCount } = props


	//Convierte los milisegundos a formato mm:ss
	const millisToMinutesAndSeconds = (millis) => {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);

            //return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;

        if(millis <= 1000 * 60 * 3)
       		return <h2 className="timer-container">
		    	<small>Tiempo de Sesión</small>
		    	<p>{`${minutes} : ${seconds < 10 ? '0' : ''}${seconds}`}</p>
		    </h2>
        else return ''

    }


	//componenteUpdate
    useEffect(()=> {

    	//si el contador aumento, reiniciamos el conteo
		if(timerCount > 0 && timerCount !== count){
			setCount( timerCount )
			actions.start(1000 * 60 * 5)
		}

		if(timer === 1000){ //Pide un nuevo token al llegar a 1 segundo
        	props.startSession()
        }

        if(timer === 1000 * 60 * 3){  //Retorna un mensaje de advertencia a los 3 minutos
            message.warning('En 3 minutos se cerrará la sesión')
        }


	},[timerCount, timer])
    
    return  <>{millisToMinutesAndSeconds(timer)}</>

}
