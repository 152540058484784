import React, { Component } from 'react';
import { Button, Col, Modal, Radio, Row, Typography, Carousel, Input, Form, Space, Select, InputNumber } from 'antd'
import MinusCircleOutlined from "@ant-design/icons/lib/icons/MinusCircleOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
//componentes
import { IconArrowSelect, IconCloseModal, IconArrowBack } from "../../../Widgets/Iconos";
import Avatar from "../../../Widgets/Avatar/Avatar";
//css
import "../../../../Styles/Modal/ModalNuevoGrupo.css";

const axios = require("axios").default;
const moment = require('moment');

const { Title, Paragraph } = Typography;
const { Option } = Select;



/**
 * @class ModalNuevoGrupo
 * @extends Component
 *
 * @description Modal de seleccionar el tipo de encuesta
 *
 * */
export default class ModalNuevoGrupo extends Component {


    carousel = React.createRef();

    /**
     * @state tipo
     * @description Almacena el tipo de encuesta
     * */
    constructor(props) {
        super(props);
        this.state = {
            groupId: null,
            tipo:'',
            usuarios: {
                data: [],
                page: 1,
                limit: 8,
                search: null
            },



            update: false
        };
    }

    /**
     *
     * @methodOf ModalAnexarEmpleado
     *
     * @function componentDidMount
     *
     * @description Obtenemos los repsonsbales para ponerlos en el objeto.
     *
     * */
    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    componentDidUpdate() {
        if (this.props.visible && !this.state.update) {
            this.state.update = true;
            this.state.groupId = this.props.groupId;
            this.state.tipo = this.props.tipo;
            this.getResponsables()
        }




        if (!this.props.visible && this.state.update) {
            this.state.update = false;
            this.state.groupId = this.props.groupId;
            this.state.tipo = this.props.tipo;
        }
    }

    /**
    * @function getAsesores
    * @description Obtenemos los usuarios y se desplegan como opcion de asesor
    * */
    getResponsables = async (
        page = this.state.usuarios.page,
        limit = this.state.usuarios.limit,
        search = this.state.usuarios.search
    ) => {
        await axios.get("/usuarios", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                group_id: this.state.groupId,
                desactivarDueños: true,
                page,
                limit,
                search,
            }
        })
            .then(({ data }) =>
                this.setState(state => {
                    state["usuarios"].data = data.data.itemsList;
                    state["usuarios"].page = page;
                    state["usuarios"].limit = limit;
                    return state;
                })
            )
            .catch(e => console.log('er', e))

    }


    /**
     *
     * @memberof ModalPregunta
     *
     *
     * @function accept
     * @description Valida si ya esta selccionado el tipo de modal. Activamos el slider en caso de que sea de texto.
     *
     * @param values
     * @description valores del formulario de
     * */
    accept = (values) => {

        if(values.monto === undefined) {}
        var monto = (values.monto === undefined) ? null : values.monto;
        axios.post('/grupos/add/empleados', {
            group_id: this.state.groupId,
            // usuario_id: values.usuario_id,
            //  monto: monto
             ...values
        })
            .then(({ data }) => {
                this.props.hideModal()
                this.props.accept();
            })
            .catch((error) => {

            })
    };


    /**
     * @param formQuestion
     * @description Referencia para las referencias
     * */
    formQuestion = React.createRef();


    render() {
        const { visible, hideModal, tipo } = this.props;
        const { usuarios, } = this.state;
        const { getResponsables, accept } = this;

        return (
            <Modal
                visible={visible}
                onCancel={hideModal}
                title={null}
                footer={null}
                className={"modal-form"}
                destroyOnClose={true}
                zIndex={1000}
                afterClose={hideModal}
                closable={false}
            >
                <div className="modal-header">
                    <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={hideModal}>
                        <IconCloseModal />
                    </Button>
                    <Title level={3} className="modal-title">Anexar Empleado</Title>
                </div>
                <Form
                    onFinish={accept}
                    layout="vertical"
                >
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Empleado"
                                    name="usuario_id"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el usuario responsable del grupo."
                                    }]}
                                >
                                    <Select
                                        clearIcon={<IconArrowSelect />}
                                        showSearch
                                        placeholder={"Responsable"}
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={search => getResponsables(usuarios.page, usuarios.limit, search)}
                                        notFoundContent={null}

                                    >
                                        {usuarios.data.map(function ({ nombre, apellido, _id, avatar }, index) {
                                            return <Option value={_id}>
                                                <Avatar image={avatar} name={[nombre, apellido]} size="small" /> &nbsp;{nombre + " " + apellido}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {(tipo === "salario") ?
                            <Col xs={24}>
                                <Form.Item
                                    label="Monto"
                                    name="monto"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese descripción"
                                    }]}
                                >
                                    <InputNumber 
                                        defaultValue={1000}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        precision={2}
                                    />
                                </Form.Item>
                            </Col>
                            :''}
                        </Row>
                        <Row>
                            <Col xs={24} lg={24}>
                                <Button htmlType="submit" type="primary" className="continue" >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </Form>
            </Modal>
        )
    }
}
