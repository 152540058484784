import React from 'react'
import { Drawer, Form, Select, Cascader, message, Badge, Button, DatePicker } from 'antd'
import axios from 'axios'
import { DownloadOutlined } from '@ant-design/icons'





const { Option } = Select





/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
export default class FiltrosDrawer extends React.Component {

    state = {

        contratistas: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 50,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },

        propiedades: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 50,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getContratistas()
        this.getPropiedades()
        
    }


    formFiltros = React.createRef()


    /**
    * @memberof FiltrosDrawer
    * @method getContratistas
    * 
    * @description Obtenemos los proveedores
    */
    getContratistas = ({ page, limit, search } = this.state.contratistas, { contratistas } = this.state) => {

        axios.get('/usuarios', {
            params: {
            	page,
            	limit,
                search,
            }
        })
            .then(response => {
            	this.setState({
            		contratistas: {
            			data: response.data.data.itemsList,
            			page: response.data.data.paginator.currentPage,
            			total:  response.data.data.paginator.itemCount,
            		}
            	})
            })
            .catch(error => {
                console.log(error)
            })
    }

    /**
     * @memberof FiltrosDrawer
     * @function getPropiedades
     *
     * @description Obtiene Propiedades
     */
    getPropiedades = ({ page, limit, search } = this.state.propiedades) => {
        axios.get('/tags', {
            params: {
                pagination: false,
                type: "inmuebles", //tipo: 2
                search: search,
            }
        })
            .then(res => {
            	console.log("res", res.data);
                this.setState({ propiedades: {
                	...this.state.propiedades,
                	data: res.data.data 
                }})
            })
            .catch(e =>
                console.log('error inmuebles get', e)
            )
    }


    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = (values) => {
        this.props.updateFilters(values)
    }


    render() {

        const { onClose, visible } = this.props
        const { clientes, contratistas, propiedades, cuentas, razones_sociales, clasificadores, ordenes, ordenes_compras, negocios, proveedores, sub_conceptos } = this.state

        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            visible={visible}
            >
            <Form
                ref={this.formFiltros}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >
                
                <Form.Item label="Contratistas" name="contratista_id">
                    <Select
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={search => this.getContratistas({ search, page: 1 })}
                    >
                        {this.state.contratistas.data.map(contratista => <Option key={contratista._id} value={contratista._id}>{contratista.nombre} {contratista.apellido}</Option>)}
                    </Select>
                </Form.Item>


                <Form.Item label="Propiedades" name="propiedades">
                    <Select
                    	mode="multiple"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={search => this.getPropiedades({ search })}
                    >
                        {this.state.propiedades.data.map(propiedad => <Option key={propiedad._id} value={propiedad._id}>{propiedad.nombre}</Option>)}
                    </Select>
                </Form.Item>

            </Form>
        </Drawer>)
    }
};