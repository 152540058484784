import React, { Component } from 'react';
import { Button, Checkbox, Col, DatePicker, Dropdown, Spin, Menu, Radio, Row, Select, Card, Statistic, Typography, List, Avatar } from 'antd'

import echarts from 'echarts';

import ReactEcharts from 'echarts-for-react';
import axios from 'axios';


const { Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;


export default class GraficaNominasPorGrupos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            inversiones:[],
            acreedores:[],
            // nomina_id: null
        }
    }

    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
    
    }





    render() {


        return (
            <Spin spinning={false}>
                <ReactEcharts
                    option={
                        {
							  legend: {},
							  tooltip: {},
							  dataset: {
							    source: [
							      ['product', '2015', '2016', '2017'],
							      ['Matcha Latte', 43.3, 85.8, 93.7],
							      ['Milk Tea', 83.1, 73.4, 55.1],
							      ['Cheese Cocoa', 86.4, 65.2, 82.5],
							      ['Walnut Brownie', 72.4, 53.9, 39.1]
							    ]
							  },
							  xAxis: { type: 'category' },
							  yAxis: {},
							  // Declare several bar series, each will be mapped
							  // to a column of dataset.source by default.
							  series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
							}
                    }
                    style={{ height: '400px', width: '100%' }}
                    opts={{ renderer: 'svg' }}
                    className='react_for_echarts' />
            </Spin>
        )
    }
}


