import React, { Component } from 'react';
import { Col, Row, Card, Typography, message, Spin, Button } from 'antd'
import { Link } from "react-router-dom";
import axios from 'axios';
//compònentes
import Logged from "../../../../Hooks/Logged";
import { InmuebleCard } from '../GroupCard'

const { Title } = Typography;

/**
 *
 * @param {*} usuario Usuario logeado
 * @param {*} objGrupo Grupo de la revision comparando
 * @param {*} nomina Tipo de vista que se esta trabajando 1 = finanzas nominas 2 =  revisiones
 * @returns
 */

export default class NominaInmuebles extends Component {
    static contextType = Logged;
    constructor(props) {
        super(props)
        this.state = {
            Inmuebles: [],
            modalidad: 'salario',
            loading: false,


            inmuebles: []

            // inmuebles: {
            //     data: [],
            //     page: 1,
            //     limit: 10, 

            //     total: 0,

            //     search: null, 
            // }
        };
    }

    /**
     * @memberof NominaInmuebles
     * @description Se ejecuta al terminar de montar la vista de administar nominas;
     */

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getAgrupaciónInmuebles()
    }



    /**
     * 
     * @methodOf NominaInmuebles
     * @function getAgrupaciónInmuebles
     * @description Obtenemos los inmuebles agrupados del server
     * 
     * @param nomina_id ID de la nómina a obtener los grupos
     * @param modalidad Si es Destajo o salarial ( solo para el filtros )
     */
    getAgrupaciónInmuebles = (nomina_id = this.props.nomina_id, modalidad = this.state.modalidad, finanzas = this.props.finanzas) => {
        this.setState({ loading: true })
        const user = this.context;


        console.log('user',user);


        const preliminar = (this.props.finanzas === true || user.tipo === 1)?undefined:true

        console.log('preeliminar',preliminar);

        axios.get('/nominas/revisiones/inmuebles', {
            params: {
                nomina_id,
                modalidad,
                finanzas,
                preliminar
            }
        })
            .then(({ data }) => {
                this.setState({
                    inmuebles: data.data,
                    loading: false
                })
            })
            .catch(e => {

            })

    }


    render() {
        const user = this.context;
        // Solo para el dueño usuario ->tipo :1
        return (
            <Row style={{ padding: '24px' }} className="content">
                <Row gutter={[32, 32]} style={{ width: '100%' }}>
                    <Col xs={24} sm={24} xl={8}>
                        <Card className="content-card">
                            <Title level={3} style={{ color: "#858997" }}>Monto a Pagar</Title>
                            <Title level={2} style={{ textAlign: "center" }}>${ this.state.inmuebles.reduce(function (a, b) { return (parseFloat(a) + parseFloat(b.monto_total)).toFixed(2) }, 0)  }<small>MXN</small></Title>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} xl={8}>
                        <Card className="content-card">
                            <Title level={3} style={{ color: "#858997" }}>Número de Empleados</Title>
                            <Title level={2} style={{ textAlign: "center" }}>{ this.state.inmuebles.reduce(function (a, b) { return (parseFloat(a) + parseFloat(b.total_autorizar)) }, 0)   }</Title>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} xl={8}>
                        <Card className="content-card">
                            <Title level={3} style={{ color: "#858997" }}>Número de Inmuebles</Title>
                            <Title level={2} style={{ textAlign: "center" }}>{this.state.inmuebles.length }</Title>
                        </Card>
                    </Col>
                </Row>
                <Spin spinning={this.state.loading}>
                    <Row gutter={[32, 32]} style={{ width: '100%' }}>
                        {this.state.inmuebles.map(inmuebleQuery => {
                            const {
                                autorizada,
                                monto_total,
                                inmueble,
                                total_autorizados,
                                total_autorizar,
                                _id
                            } = inmuebleQuery

                            // console.log('inmueble', inmuebleQuery);

                            // console.log('i',i);
                            // autorizada: true
                            // monto_total: 0
                            // nombre: "Casa 29 Seccion 1"
                            // total_autorizados: 6
                            // total_autorizar: 6
                            // _id: "5f89ee40c9b9c97ed7348990"

                            return <Col xs={24} sm={24} xl={8}>
                                <InmuebleCard
                                    autorizada={autorizada}
                                    monto_total={monto_total}
                                    inmueble={inmueble}
                                    total_autorizados={total_autorizados}
                                    total_autorizar={total_autorizar}
                                    _id={_id}
                                    usuario={user}
                                    nomina_id={this.props.nomina_id}
                                    vistaNomina = {(this.props.finanzas)?1:0}
                                />
                            </Col>

                        })}
                    </Row>
                </Spin>
            </Row>

        )
    }
}
