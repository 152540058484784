import React, { Component } from 'react';
import { Avatar, Select, Form, Checkbox, Button, Col, Row, message, Typography, List, Card, Pagination, Spin, Image, Popover, Popconfirm, Tag, Tooltip } from 'antd'

import { DeleteOutlined, DownOutlined } from '@ant-design/icons'

//componentes
import { IconEdit, IconDelete, IconPlus } from '../../Widgets/Iconos';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
//Modales
import ModalInventario from '../Modales/Inventarios/ModalInventario';
//css
import '../../../Styles/projectManager.css'
import moment from 'moment';

const axios = require('axios').default;
const { Text, Title } = Typography;
const { Option } = Select

export default class Material extends Component {

    constructor(props) {
        super(props)
        this.state = {

            loading: true,
            material_id: "",

            modalInventario: false,
            movimiento_id: null,

            inventario: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                totalPages: 0,
                filters: {}
            },

            material: {
                _id: "",
                existencias: 0,
                nombre: "",
                tipo_id: {
                    _id: "",
                    nombre: "",
                    detalles: "",
                    unidad: 0,
                    imagen: "",
                }
            },

            materiales: [],

            tipo: {}
        }
    }


    componentDidMount() {

        console.log(this.props)
        this.getMaterial()


        //this.getMateriales()
        this.getInventario()
    }


    /**
     *
     *
     * @param {*} search
     * @memberof Material
     * 
     * @description Obtenemos la lista de materiales.
     */
 
  
    getMaterial = async (id = this.props.match.params.id) => {
        axios.post('/materiales/get', { id, tipo: true })
            .then(({ data }) => {
                console.log(data);
                if (data.success) {
                    var material = data.data;
                    console.log(material)
                    this.setState({ material: material, loading: false });
                }
            })
            .catch(error => {
                console.log('error,', error)

            });
    }


    // /tipos/get

    /**
     *
     *
     * @param {*} search
     * @memberof Material
     * 
     * @description Obtenemos la lista de materiales.
     */
    getMateriales = (search) => {
        axios.get('/materiales/list', {
            params: {
                search,
                pagination: false,
            }
        }).then(response => {
            console.log(response)

            this.setState({ materiales: response.data.data })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })
    }

    /**
     *
     *
     * @param {*} [{
     *         page = this.state.inventario.page,
     *         limit = this.state.inventario.limit,
     *         filters = this.state.inventario.filters
     *     }={}]
     * @memberof Material
     * 
     * @description Obtenemos la lista de inventarios.
     */
    getInventario = ({
        page = this.state.inventario.page,
        limit = this.state.inventario.limit,
        filters = this.state.inventario.filters
    } = {}) => {
        this.setState(state => {
            state.inventario.page = page
            state.inventario.limit = limit
            state.inventario.filters = filters
            state.loading = true
            return state;
        })

        axios.post('/inventarios', {
            page,
            limit,
            filters,
            material_id: this.props.match.params.id
        })
            .then(({ data }) => {

                console.log(data);

                this.setState(state => {

                    // total: 0,
                    // totalPages: 0,
                    state.inventario.data = data.data.itemsList
                    state.inventario.total = data.data.paginator.itemCount
                    state.inventario.totalPages = data.data.paginator.pageCount
                    state.loading = false

                    return state;

                })

                console.log('data', data);
            })


    }


    /**
     *
     *
     * @param {*} movimiento_id
     * @memberof Material
     * 
     * @description Eliminamos el tipo
     */
    deleteInventario = (movimiento_id) => {
        axios.delete('/inventarios/delete', {
            params: {
                material_movimiento_id: movimiento_id
            }
        }).then(({ data }) => {
            this.getInventario()
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los tipos')
        })
    }


    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    onValuesChange={(valuesChanged, allValues) => this.getInventario({ filters: allValues })}
                >
                    <Row className="row-encabezado">

                        <Col xs={24} md={16} xxl={19} className="flex-left">
                            <Title level={4} className="view-title">Inventario de material  <strong style={{ textDecoration: "underline" }} > {this.state.material.nombre ?  ("  "+ this.state.material.nombre) : null}</strong> </Title>

                        </Col>

                        <Col xs={24} md={5} xxl={3}>
                            <Form.Item name="movimiento" className="input-filter">
                                <Select allowClear placeholder="Tipo de Movimiento">
                                    <Option value={true}>Entrada</Option>
                                    <Option value={false}>Salida</Option>
                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>

                <Row>
                    <Col span={24}>
                        <Card
                            title=""
                            loading={this.state.loading}
                            bordered
                            className="card-proyeccionesDetalle"
                        >
                            <Row>
                                <h1>{this.state.material.nombre}</h1>
                            </Row>

                            <Row>
                                <Col xs={24} lg={5} >
                                    <label className="lblTitulo">Tipo</label>
                                    <p className="pInfo">{this.state.material?.tipo_id.nombre}</p>
                                </Col>

                                <Col xs={24} lg={8} >
                                    <label className="lblTitulo">Detalles</label>
                                    <p className="pInfo">{this.state.material?.tipo_id.detalles}</p>
                                </Col>

                                <Col xs={24} lg={7} >
                                    <label className="lblTitulo">Existencias / Unidad</label>
                                    <p className="pInfo"> {this.state.material?.existencias}  {(this.state.material?.tipo_id.unidad !== "" && this.state.material?.tipo_id.unidad !== undefined) ? (this.state.material?.tipo_id.unidad === 0) ? "Piezas" : "Toneladas" : ""} </p>
                                </Col>

                                <Col xs={24} lg={4} >
                                    <img
                                        style={{ maxWidth: "90%", padding: "1px" }}
                                        src={(this.state.material?.tipo_id.imagen !== "" && this.state.material?.tipo_id.imagen !== undefined) ? `${axios.defaults.baseURL}/upload/${this.state.material?.tipo_id.imagen}` : ""}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>


                <section className="p-1">

                <Row className="card-proyeccionesDetalle">
                        <h1>Movimientos</h1>
                    </Row>

                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.inventario.data}

                        header={<Row>

                            <Col span={6} className="gutter-row center">
                                Usuario
                            </Col>

                            <Col span={6} className="gutter-row center">
                                Fecha movimiento
                            </Col>
                            <Col span={3} className="gutter-row center">
                                Tipo
                            </Col>
                            <Col span={6} className="gutter-row center">
                                Movimiento del día
                            </Col>
                         
                            <Col span={3} className="gutter-row center">
                                Acciones
                            </Col>


                        </Row>}
                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">

                                        <Col span={6} className="gutter-row center">
                                            <CustomAvatar
                                                image={`${axios.defaults.baseURL}/upload/${item.usuario_id.avatar}`}
                                                name={item.usuario_id.nombre + " " + item.usuario_id.apellido}
                                                size="small"
                                                style={{
                                                    margin: '0 10px'
                                                }}
                                            />
                                            {item.usuario_id.nombre + " " + item.usuario_id.apellido}

                                        </Col>

                                        <Col span={6} className="gutter-row center">
                                            <Text strong>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
                                        </Col>
                                        <Col span={3} className="gutter-row center">
                                            <Text strong>{(item.movimiento) ? "Entrada" : "Salida"}</Text>
                                        </Col>
                                        <Col span={6} className="gutter-row center">
                                            {/* <Tag color="#f50">#f50</Tag>
                                            <Tag color="#108ee9">#108ee9</Tag> */}
                                            {(item.movimiento === true) ?
                                                <Tag color="#87d068" style={{ fontWeight: "bold", fontSize: 14 }}> + {item.cantidad} {(item?.material_id?.unidad === 0) ? "piezas." : "tons"}</Tag>
                                                :
                                                <Tag color="#f50" style={{ fontWeight: "bold", fontSize: 14 }}> - {item.cantidad} {(item?.material_id?.unidad === 0) ? "piezas." : "tons"}</Tag>
                                            }
                                            <Text strong></Text>
                                        </Col>
                                     

                                        <Col span={3} className="gutter-row center">
                                            <Button className="btn btn-edit" title="Editar" onClick={(e) => this.setState({
                                                modalInventario: true,
                                                movimiento_id: item._id
                                            })}>
                                                <IconEdit />
                                            </Button>
                                            <Popconfirm
                                                title="¿Quieres eliminar esto?"
                                                onConfirm={() => this.deleteInventario(item._id)}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button className="btn btn-delete" title="Eliminar">
                                                    <DeleteOutlined style={{ position: "relative", bottom: 2, color: "white" }} />
                                                </Button>
                                            </Popconfirm>

                                        </Col>

                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                    <Row className="mt-2 pb-3">
                        <Pagination
                            current={this.state.inventario.page}
                            total={this.state.inventario.total}
                            showSizeChanger={false}
                            onChange={(page) => this.getInventario({ page })}
                        />
                    </Row>
                </section>
                <Button className="btnAddPlus" title="Nuevo registro" onClick={() => this.setState({
                    modalInventario: true,
                    movimiento_id: null
                })}>
                    <IconPlus />
                </Button>

                <ModalInventario
                    modalVisible={this.state.modalInventario}
                    onCancel={() => this.setState({ modalInventario: false, movimiento_id: null })}
                    movimiento_id={this.state.movimiento_id}

                    material = {this.state.material}
                    
                    onSuccess={() => {
                        this.getMaterial();
                        this.getInventario();
                        this.setState({ modalInventario: false, movimiento_id: null })
                    }}
                />


            </Spin>
        )
    }
}
