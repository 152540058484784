import React, { Component } from 'react';

import { Col, Row, Radio, Button, Breadcrumb } from 'antd'

import Logged from "../../../../Hooks/Logged";

import NominaAsalariados from '../Administrar/Asalariados';
import NominaDestajo from '../Administrar/Destajo';
import NominaInmuebles from '../Administrar/Inmueble';

import { Link } from 'react-router-dom';
import { IconEye, IconArrowBack } from '../../../Widgets/Iconos';

import '../../../../Styles/nominas.css'

/**
 *
 * @param {*} usuario Usuario logeado
 * @param {*} objGrupo Grupo de la revision comparando
 * @param {*} nomina Tipo de vista que se esta trabajando 1 = finanzas nominas 2 =  revisiones
 * @returns
 */

export default class AdministrarNominaFinanzas extends Component {

    static contextType = Logged;

    /**
     *
     * @memberof AdministrarNominaFinanzas
     *
     * @var start Valor de fecha inicial a buscar
     *
     * @var end Valor de fecha final a buscar
     *
     * @var data_revisiones Aqui se almacenan todos los registros a buscar.
     */
    constructor(props) {
        super(props)
        this.state = {
            start: undefined,
            end: undefined,
            data_revisiones: [],
            nomina_id: '',
            view: "Asalariados"
        };
    }


    /**
      * @memberof NominaDashboard
      * @description Se ejecuta al terminar de montar la vista de administar nominas;
      */
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        let id = this.props.match.params.id;
        this.setState({ nomina_id: id });
    }

    /**
     *
     *
     * @param {*} view
     * @param {*} nomina_id
     * @returns
     * @memberof AdministrarNominaFinanzas
     */
    renderView(view, nomina_id) {
        switch (view) {
            case 'Asalariados':
                return <NominaAsalariados nomina_id={nomina_id} finanzas={true} />
                break;
            case 'Destajo':
                return <NominaDestajo nomina_id={nomina_id} finanzas={true} />
                break;
            case 'Inmueble':
                return <NominaInmuebles nomina_id={nomina_id} finanzas={true} />
                break;

        }
    }
    /**
     * @memberof Index
     *
     * @method changeView
     * @description Cambia la vista para visualizar los datos dependiendo de si grupo o empleado
     **/
    changeView = (view) => {
        this.setState({ view: view.target.value })
    }

    render() {
        const user = this.context;
        let nomina_id = this.props.match.params.id;
        //boss y finanzas

        return (
            <div>
                <Row className="row-encabezado">
                    <Col xs={16} xl={16} xxl={16}>

                        <Link to={`/admin/nomina`} style={{ color: "white" }}>
                            <Button className="IconArrowBack" style={{ marginTop: "8px" }}>
                                <IconArrowBack />
                            </Button>
                        </Link>
                        <h2>Nomina a Finanzas </h2> 

                    </Col>
                    <Col xs={8} xl={8} xxl={8}>
                        <Radio.Group onChange={this.changeView} className="toogle-nominas-checkbox">
                            <Radio.Button value="Asalariados">Asalariados</Radio.Button>
                            <Radio.Button value="Destajo">Destajo</Radio.Button>
                            <Radio.Button value="Inmueble">Inmueble</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row className="page-content">
                    {this.renderView(this.state.view, nomina_id)}
                </Row>
            </div>
        )

    }
}
