import React, { Component } from 'react';
import { Row, Col, Table, Button, Modal, Form, Select } from 'antd'
import { Link, Redirect } from 'react-router-dom';
import { IconEye } from '../../../Widgets/Iconos';

import Logged from '../../../../Hooks/Logged';

const axios = require('axios').default
const moment = require('moment');
const { Option } = Select
export default class PagosPendientes extends Component {


    static contextType = Logged;

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            data: [],


            // paginador
            page: 1,
            limit: 10,
            total: 0,


            // select de nominas
            nominasList: [],
            nomina_actual: undefined,
            filtroSearch: "",
            searching:true,


        }
    }

    refModalPP = React.createRef();


    /**
     * @memberof PagosPendientes
     *
     * @method componentDidMount 
     * @description Manda a llamar a GetPendingPayments para cargar los datos en la tabla
     *
     */
    componentDidMount() {
        //Mostrar input superior de busqueda
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.GetPendingPayments();
        this.GetNominas();
    }


    /**
     *
     *
     * @memberof PagosPendientes
     *
     * @method componentDidUpdate
     * @description actualiza la vista si se ha realizado una busqueda
     */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;
      
        if (filtro !== propFiltro && this.state.searching === true) {
            this.state.searching = false;
            this.GetPendingPayments();
        }
    }

    /**
     * @memberof PagosPendientes
     *
     * @method GetPendingPayments 
     * @param {*} value
     * @param page (Integer) pagina actual en la que se encuentra la tabla
     * @param limit (Integer) limite de elementos a cargar en la tabla
     *
     */
    GetPendingPayments = async (page = this.state.page, limit = this.state.limit, nomina_id = this.state.nomina_actual) => {

        var search = this.props.search
        this.setState({ loading: true })
        axios.get('/pendientes/list', {
            params: {
                page,
                limit,
                nomina_id,
                search: search
            }
        })
            .then(res => {
                console.log("Pagos pendientes list: ", res.data.data.itemsList)
                this.setState({
                    data: res.data.data.itemsList,
                    page,
                    limit,
                    totalPages: res.data.data.itemCount,
                    filtroSearch: search
                })
                this.state.searching = true;
            })
            .catch(e => {
                console.log("pagos pendientes error", e)
            })
            .finally(f => {
                this.setState({ loading: false })
            })
    }

    /**
    * @memberof PagosPendientes
    *
    * @method GetNominas
    * @description Trae todas las nominas que existen y las asigna al state.nominasList
    *
    */
    GetNominas() {
        axios.get('/nominas/list')
            .then(result => {
                //Se asigna a la variable local
                this.setState(state => {

                    state.nominasList = result.data.data
                    if (result.data.data[0])
                        state.nomina_actual = result.data.data[0]

                    return state;
                })
            })
            .catch(error => {
                console.error('Error al traer nominas', error)
            })
    }

    /**
     * @memberof PagosPendientes
     * 
     * @description Se ejecuta al seleccionar un ID de nomina y establece esa nomina como seleccionada
     * @param value ID de la nomina seleccionada
     */
    onNominaChange = (value) => {
        this.setState({ nomina_actual: value });
        this.GetPendingPayments(this.state.page, this.state.limit, value);
    }




    /**
     * @memberof PagosPendientes
     * @method eliminarPago
     * @description Para eliminar un elemento de la tabla
     * 
     * @param nomina_id (ID) _id de la nomina que se encuentra cargada los pagos pendientes
     * @param usuario_id (ID) usuario logeado en el sistema  
     */
    eliminarPago = (nomina_id, usuario_id) => {
        Modal.confirm({
            title: '¿Deseas eliminar los pagos pendinetes de este empleado   correspondientes a esta nómina?',
            content: 'La deuda de est   e empleado referente a esta nómina será eliminada',
            onOk: () => {
                axios.post('/pendientes/user/delete', {
                    nomina_id,
                    usuario_id
                })
                    .then(e => {
                        Modal.success({
                            title: "",
                            content: "Todos los pagos de este usuario relacionados a esta nomina se han eliminado correctamente"
                        })
                        this.GetPendingPayments();
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }



    /**
      * @memberof PagosPendientes
      *
      * @method renderRedirect
      * 
      */
    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to={`/admin/nomina/pagosPendientes`} />;
        }
    };


    render() {
        const columns = [
            {
                title: 'Nombre',
                key: 'nombre',
                render: (text, record) => (
                    <div>
                        { (record.usuario_id) ? record.usuario_id.nombre : null}
                    </div>
                ),
            },
            {
                title: 'Apellido',
                key: 'apellido',
                render: (text, record) => (
                    <div>
                        { (record.usuario_id) ? record.usuario_id.apellido : null}
                    </div>
                ),
            },
            {
                title: 'Monto Total Pendiente',
                dataIndex: 'monto_total',
                render: text => '$' + text.toString().toMoney()
            },
            {
                title: 'Acciones',
                key: 'actions',
                width: 150,
                render: (text, record) => (
                    <div className="tbl-opciones">
                        <Link title="Ver" className="EditIcon" to={`/admin/nomina/pagosPendientes/detalle/${record._id}/${record.nomina_id}`} >
                            <Button type="primary" className="button-survey btn-eye button-eye" title="Ver detalle"  >
                                <IconEye />
                            </Button>
                        </Link>
                    </div>
                ),
            },
        ];

        return (
            <div>
                <Row className="row-encabezado pr-1">
                    <Col xs={20} xl={20} xxl={20}>
                        <h2>Pagos Pendientes</h2>
                    </Col>
                    <Col xs={4} xl={4} xxl={4} className="center">
                        <Form layout={'inline'}>
                            <Form.Item name="select-nomina" style={{ width: '100%' }}  >
                                <Select onChange={this.onNominaChange} placeholder="Nomina" className="select-input" >
                                    {this.state.nominasList.map(nomina => {
                                        return <Option value={nomina._id}>{moment(nomina.fecha).format('DD-MM-YYYY')} </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Form>

                    </Col>
                </Row>
                <Row className="p-1">
                    <Col span={24} >
                        <div className="div_contenido">
                            <Table
                                columns={columns}
                                dataSource={this.state.data}
                                scroll={{ x: 800, y: 650 }}
                                loading={this.state.loading}
                                locale={{ emptyText: 'No hay montos pendientes' }}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: this.state.limit,
                                    total: this.state.totalPages,
                                    showSizeChanger: true
                                }}
                                onChange={({ current, pageSize, total }, b, sort) => {
                                    this.GetPendingPayments(current, pageSize)
                                }}
                            />
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }
}