import React, { Component } from 'react';
import { Row, Col, Table, Button, Popconfirm, message, Spin, Typography } from 'antd'
import { IconPlus, IconEdit, IconDelete } from '../../../Widgets/Iconos';
import ModalTag from '../../Modales/Nomina/ModalTag';
import '../../../../Styles/Global.css'
const axios = require('axios').default
const { Title, } = Typography;
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

export default class Subrubros extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            modalVisible: false,
            data: [],
            page: 1,
            limit: 10,
            id: null,

            search: "",
            searching: true,


            filtroSearch: "",

        }
    }

    refModalSubrubro = React.createRef();



    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.receivedData();
    }



    /**
     *
     *
     * @memberof SubRubros
     *
     * @method componentDidUpdate
     * @description actualiza la vista si se ha realizado una busqueda
     */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.state.searching = false;
            this.receivedData();
        }
    }

    /**
         *
         *
         * @memberof Tags
         *
         * @method componentDidUpdate
         * @description actualiza la vista si se ha realizado una busqueda
         */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.search;
        var propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching === true) {
            this.receivedData();
            this.state.searching = false;
        }
    }



    /**
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }

    /**
     * @description Abrir modal tipo edición para registro seleccionado desde tabla.
     * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
     */
    modalEditar = (id = null) => {
        this.setState({
            id,
            modalVisible: true,
        })
    }

    /**
     * @description Cerrar modal y limpiar valor registroId
     */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            id: null,
            modalVisible: false,
        })
    }

    /**
     *
     * @memberof Tags
     * @method receivedData
     * @description Obtiene la informacion y actualiza los valores locales de la lista.
     */

    receivedData = async (page = this.state.page, limit = this.state.limit, search = this.props.search) => {
        this.setState({ loading: true });
        var search = this.props.search

        axios.get('/tags', {
            params: {
                page,
                limit,
                search,
                type: this.props.tipo
            }
        })
            .then(res => this.setState(state => {
                state.limit = limit;
                state.page = page;
                state.total = res.data.data.paginator.itemCount;
                state.data = res.data.data.itemsList;

                state.search = search;
                state.searching = true;
                state.loading = false;
                state.search = search;
                this.filtroSearch = search;
                return state;
            }))

            .catch(res => {
                console.log("error", res);
            }).finally(f => {
                this.setState({ loading: false })
            });
    }

    /**
     *
     *
     * @memberof Tags
     * @method eliminarTag
     *
     * @description Permite eliminar tags
     */
    eliminarTag = e => {
        axios.post('/tags/delete', {
            id: e
        })
            .then((response) => {
                message.success("Eliminado correctamente.");
                this.receivedData();
            })
            .catch((error) => {
                message.error(error.response.data.message)
            })
    }



    render() {
        const user = this.context;
        const columns = [
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                render: (text, record) => (
                    <h3>{record.nombre}</h3>
                ),
            },
            {
                title: 'Tipo de Tag',
                dataIndex: 'tipo',
                render: (text) => (text === 1) ? "Actividad" : "Inmueble"
            },
            {
                title: 'Descripción',
                dataIndex: 'nombre',
                render: (text, record) => record.descripcion
            },
            {
                title: 'Acciones',
                key: 'actions',
                fixed: "right",
                width: 150,
                render: (text, record) => (
                    <div className="tbl-opciones">
                        {(user.tipo !==3) ?
                            <div><Button type="primary" className="button-survey btn-edit button-edit" onClick={() => this.modalEditar(record._id)} title="Editar"  >
                                <IconEdit />
                            </Button>
                                <Popconfirm placement="topRight" title="¿Eliminar " okText="Si, seguro" cancelText="No" name={record.nombre} id={record._id} onConfirm={() => this.eliminarTag(record._id)} >
                                    <Button type="primary" className="ant-btn button-survey btn-delete button-delete ant-btn-primary" title="Eliminar"  >
                                        <IconDelete />
                                    </Button>
                                </Popconfirm>
                            </div>
                            : null}
                    </div>
                ),
            },
        ];



        let { data } = this.state;

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row className="row-encabezado">
                        <Col xs={12} xl={20} xxl={20}>
                            <h2>{this.props.tipo === 'inmueble_id' ? 'Inmuebles' : 'Actividades' }</h2>
                        </Col>
                        <Col xs={12} xl={4} xxl={4} className="flex-right">
                            <Title level={5} style={{  
                            textAlign: "right", color: "#FFF", fontSize: '11pt', marginBottom: '0', marginRight: '2rem' }}> 
                                {this.state.total} {this.props.tipo === 'inmueble_id' ? 'Inmuebles' : 'Actividades' }
                            </Title>
                        </Col>
                    </Row>

                    <Row className="p-1">
                        <Col span={24} >
                            <div className="div_contenido">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    loading={this.state.loading}
                                    scroll={{ x: 800, y: 570 }}
                                    pagination={{
                                        current: this.state.page,
                                        pageSize: this.state.limit,
                                        total: this.state.total,
                                        showSizeChanger: true
                                    }}
                                    onChange={({ current, pageSize, total }, b, sort) => {
                                        this.receivedData(current, pageSize)
                                    }}
                                    className="table-bordered" />

                            </div>
                        </Col>
                    </Row>


                    <ModalTag
                        modalVisible={this.state.modalVisible}
                        tipo={this.props.tipo}
                        hideModal={this.hideModal}
                        id={this.state.id}
                        accept={() => {
                            this.hideModal();
                            this.receivedData();
                        }}
                    >
                    </ModalTag>


                    <Button type="ghost" className="btnAddPlus" onClick={this.modalCrear}>
                        <IconPlus />
                    </Button>
                </Spin>
            </div>
        )
    }
}
