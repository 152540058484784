import React, { Component, useState } from "react";
import Dropzone from "react-dropzone";
import { Avatar, Button, List, Modal, Progress, Row, Space, Typography } from "antd";
import {
    DeleteOutlined,
    UploadOutlined,
    EyeOutlined,
    SearchOutlined,
    DownloadOutlined
} from '@ant-design/icons';

import "./Document.css";
import PropTypes from "prop-types";


const axios = require('axios').default;

const { Title } = Typography;



/**
 * @interface DropzoneAntDesignProps
 *
 * @description Para establecer los valores necesrios para que funcione como un componente de ANTD
 * */
interface DropzoneAntDesignProps {
    fileList?: [];
    onChange?: (value: []) => void;
}

/**
 * @interface DropzoneAntDesignProps
 * @description Para establecer los valores necesrios para que funcione como un componente de ANTD
 * */
const DropzoneAntDesign: React.FC<DropzoneAntDesignProps> = ({ type = "picture-box", fileList = [], onChange, deleteFileEvent = () => { }, styleDropzone = {} }) => {
    let [filesArray, setFilesArray] = useState(fileList)
    // Si el Modal e visible
    let [visible, setVisible] = useState(false)

    //El URL para previsualizar la imagen en el mdal
    let [previewUrl, setPreviewUrl] = useState(null)


    //Este metodo actauliza el valor en el formulario de ANTD
    const triggerChange = changedValue => {
        if (onChange) {
            onChange(changedValue);
        }
    };


    //Este metodo actualiza los valores del ANTD Form junto con el estado
    const onFilesList = (newFilesList) => {
        setFilesArray([...newFilesList])
        triggerChange(newFilesList);
    }



    //Elimina un archivo del servidor (y del arreglo)
    const deleteFile = async (file, index) => {
        console.log(file);

        onFilesList(deleteFromArray(index));


        try {

            if (file._id !== undefined)
                return deleteFileEvent(file._id)

            if (file.status === "done")
                await axios.post("/upload/delete", { filename: file.name });

            else if (file.response.filename !== undefined)
                await axios.post("/upload/delete", { filename: file.response.filename });
        } catch (error) {

            console.log(error)
        }


    };



    //Elimina un archivo del arreglo y devuelve un nuevo arreglo con el archivo eliminado
    const deleteFromArray = (deletedFile) => {
        let filesTemp = filesArray;
        filesTemp.splice(deletedFile, 1);
        return filesTemp;
    };


    //Cuando se suben archivos
    const onDrop = acceptedFiles => {
        //Se iteran los archivos subidos
        acceptedFiles.map((file, index) => {

            let files = filesArray;

            //El archivo se agega al arrego de archivos
            index = files.push({
                lastModified: new Date().getMilliseconds(),
                lastModifiedDate: new Date(),
                name: file.name,
                url: URL.createObjectURL(file),
                status: 'start',
                percent: 0
            }) - 1;

            // Se actualiza el nuevo arreglo
            onFilesList(files)


            let formData = new FormData();
            formData.append("file", file);

            //Ennviamos el archivo subirdpo
            axios.post('/voucher/add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data;'
                },
                /******     SOLAMENTE PARA EL PROGRESS BAR, NO TIENE UTILIDAD FUNCIONAL**********/
                onUploadProgress: (progressEvent) => {
                    let filesTemp = filesArray
                    filesTemp[index].status = "uploading";
                    filesTemp[index].percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onFilesList(filesTemp)

                }
            })
                .then(async ({ data }) => {
                    //Me espero hasta que aparezca el boton
                    function sleep(ms) {
                        return new Promise(resolve => setTimeout(resolve, ms));
                    }
                    await sleep(600)

                    //Actualizo el elemento, quito el progress bar y actualizo la info del arreglo
                    let filesTemp = filesArray;
                    filesTemp[index].status = "upload";
                    filesTemp[index].response = data;
                    filesTemp[index].name = data.filename;
                    onFilesList(filesTemp)
                    // thisComponenet.state.fileList[index].status = "done";
                    // thisComponenet.state.fileList[index].response = data;
                    // thisComponenet.setState({update: !thisComponenet.state.update})
                })
                .catch(err => console.log(err))
        })
    };


    const showModal = (url) => {
        setPreviewUrl(url)
        setVisible(true)
        // this.setState({previewUrl: url, visible: true})

    }

    const hideModal = async () => {
        setVisible(false)
    }


    return (

        <div  >
            <Dropzone onDrop={onDrop} >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        {(() => {
                            switch (type) {

                                case 'picture-box':

                                    return <Row >
                                        {filesArray.map((file, index) => (
                                            <div className="dropzone-element dropzone-child">
                                                <div key={file.name} className="thumb">
                                                    <div className="thumb-container">
                                                        <Avatar
                                                            shape="square"
                                                            src={file.url} />
                                                    </div>
                                                    <div key={file.name} className="thumb-menu">
                                                        {(file.status === "uploading") ?
                                                            <Progress type="circle" percent={file.percent} width="50px" /> :
                                                            <Space>
                                                                <Button color="white" type="link"
                                                                    onClick={e => deleteFile(file, index)}
                                                                    className="opacity-button">
                                                                    <DeleteOutlined style={{ color: 'white' }} />
                                                                </Button>
                                                                <Button color="white" type="link" onClick={e => showModal(file.url)}
                                                                    className="opacity-button">
                                                                    <EyeOutlined />
                                                                    {/*<Prevoi style={{color: 'white'}} />*/}
                                                                </Button>
                                                            </Space>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div {...getRootProps({ className: 'dropzone-element main-dropzone ' })}>
                                            <input {...getInputProps()} />
                                            <Title className="main-dropzone-title" level={5}
                                                style={{ textAlign: "center" }}>+<br />Upload</Title>
                                        </div>
                                    </Row>
                                    break;


                                case 'list':
                                    return <Row>
                                        <Row style={{ marginBottom: '1em', justifyContent: 'center'}} >
                                            <Button type="default" icon={<UploadOutlined />} style={styleDropzone }  {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <span style={{ marginLeft: '5px' }}>Upload</span>
                                            </Button>
                                        </Row>
                                        <List
                                            className="file-list"
                                            itemLayout="horizontal"
                                            dataSource={filesArray}
                                            renderItem={(file, index) => {
                                                const { status, percent, name, url } = file;
                                                console.log('url',url)
                                                return (

                                                    <List.Item
                                                    >
                                                        <List.Item.Meta
                                                            title={<a className="file-name" download href={url} target="_blank">{name}</a>}
                                                            description={(status === "uploading") ?
                                                                <Progress percent={percent} /> :
                                                                <Button icon={<DeleteOutlined />}
                                                                    className="btn-deleteFile"
                                                                    type="link"
                                                                    title="Eliminar documento"
                                                                    onClick={e => {
                                                                        if (file.deleteFileEvent !== null) {
                                                                            file.deleteFileEvent(file, index);
                                                                            onFilesList(deleteFromArray(index));
                                                                        }
                                                                        else {
                                                                            deleteFile(file, index)
                                                                        }
                                                                    }} />

                                                            }
                                                        />
                                                       
                                                    </List.Item>
                                                )

                                            }}
                                        />
                                    </Row>
                                    break;
                            }


                        })()}

                    </section>
                )}
            </Dropzone>
            <Modal
                visible={visible}
                onCancel={hideModal}
                title={null}
                footer={null}
                className="modal-form preview-image"
                closeIcon={<Button type="ghost" className="closable-button" onClick={hideModal}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
                            fill="#E1E4ED" />
                        <path
                            d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
                            fill="#E1E4ED" />
                    </svg>
                </Button>}
            >
                <img
                    style={{
                        // marginTop: '2.5em',
                        maxWidth: '100%',
                        borderRadius: '5px'
                    }}
                    src={previewUrl}
                    onError={() => {

                    }}
                />
            </Modal>
        </div>
    )


}


export default DropzoneAntDesign