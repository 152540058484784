import React, { Component } from 'react';
import {Button, Col, Row, Card, Typography, List, Popconfirm, message, Pagination} from 'antd'
import { Link } from "react-router-dom";
//componentes
import { IconEye, IconEdit,  IconDelete, IconPlus, IconArrowBack, IconOpen } from '../../Widgets/Iconos';
//modal
import ModalBitacoraConcepto from '../Modales/Presupuesto/ModalBitacoraConcepto';
//css
import '../../../Styles/presupuesto.css'

const { Title, Text } = Typography;
const axios = require('axios').default
const moment = require('moment');

export default class DetalleManoObra extends Component{

	constructor(props){
		super(props)
		this.state = {
			proforma_id: this.props.match.params.proforma_id,
			proforma_concepto_id: this.props.match.params.proforma_concepto_id,
			bitacora:[],
			bitacora_edit: undefined,
			concepto:{
				conceptos:{}
			},
			/* Paginado */
			page: 1,
			total: 0,

		}
	}

	volver = () => {
        window.history.back();
    }


	/**
	* @memberof DetalleManoObra
	*
	* @method   componentDidMount
	* @description  
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getConcepto();
	}

	/**
	 * @methodOf  DetalleManoObra
	 * @function hideModal
	 *
	 * @description Cierra el modal, refresca la informacion
	 *
	 * */
	hideModal = () => {
		this.setState({ ModalBitacoraConceptoVisible: false, bitacora_edit: undefined });
		this.getConcepto(this.state.page)
	};

	/**
	 * @methodOf  DetalleManoObra
	 * @function getConcepto
	 *
	 * @description Retorna la informacion de el concepto y su bitacora
	 *
	 * */
	getConcepto = (page=1) => {
		this.setState({loading: true})
		axios.get('/proforma/conceptos/bitacora/list',{
			params:{
				proforma_id: this.state.proforma_id,
				proforma_concepto_id: this.state.proforma_concepto_id,
				page: page
			}
		}).then(response => {
			this.setState({
				concepto: response.data.data.concepto,
				bitacora: response.data.data.bitacora.itemsList,
				page: response.data.data.bitacora.currentPage,
				total: response.data.data.bitacora.itemCount,
				loading: false,
			})
		}).catch(error=>{
			message.error('Error al traer los datos')
		})
	};

	/**
	 * @methodOf  DetalleManoObra
	 * @function deleteRegistro
	 *
	 * @description elimina un registro de la bitacora
	 *
	 * */
	deleteRegistro = (id) => {
		this.setState({loading: true})
		axios.post('/proforma/conceptos/bitacora/delete',{
			proforma_id: this.state.proforma_id,
			proforma_concepto_id: this.state.proforma_concepto_id,
			bitacora_id: id
		}).then(response => {
			this.getConcepto()
			message.success('¡Registro eliminado exitosamente!')
		}).catch(error=>{
			message.error('Error al eliminar el registro')
			this.setState({loading: false})
		})
	};



	render() {
		return(
			<div style={{minHeight: '100'}}>
				<Row className="row-encabezado">
					<Col xs={24} className="">
					 	<Button className="IconArrowBack" onClick={this.volver} style={{top:"12px"}}>
                            <IconArrowBack />
                        </Button>
						<h2 style={{width: 'auto', float: 'left'}}>Mano de Obra</h2>
					</Col>
				</Row>
				<section className="p-1">
					<Row>
                        <Col span={24}>
                            <Card
                                loading={this.state.loading}
                                bordered
                                className="card-proyeccionesDetalle"
                            >
                                <Row>
                                    <h1>Mano de Obra de concepto: {this.state.concepto.conceptos.nombre} </h1>
                                </Row>

                                <Row>
                                   <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Monto Pagado</label>
                                       	<div>
                                        	<p className="pInfo">$ {this.state.concepto.conceptos?.monto_bitacora?.toMoney(2)} MXN</p>
                                        	{/*<IconOpen className="card-svg-open" onClick={()=>{this.setState({ModalBitacoraConceptoVisible: true})}}/>*/}
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <label className="lblTitulo">Presupuesto Inmueble</label>
                                        <div>
                                        	<p className="pInfo">$ {this.state.concepto.presupuesto?.toMoney(2)} MXN</p>
                                        	{/*<IconOpen className="card-svg-open"/>*/}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <div className="list-title">
                    	Bitacora
                    </div>
					<div className="scroll">
						
						<List
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.bitacora}
							loading={this.state.loading}
							locale={{ emptyText: 'Sin Nominas' }}
							renderItem={item =>
								(<List.Item className="component-list-item">
									<Card className="card-list">
										<Row style={{ width: '100%' }} className="">
											<Col span={3} className="center">
												<Text strong>{moment(item.conceptos.bitacora_pagos.fecha).format('DD / MM / YYYY')}</Text>
											</Col>
											<Col span={6} className="center">
												<Text strong>{item.conceptos.bitacora_pagos.concepto}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>{item.conceptos.bitacora_pagos.sub_rubro_id.nombre}</Text>
											</Col>
											<Col span={6} className="center">
												<Text>$ {item.conceptos.bitacora_pagos.monto.toMoney(2)} MXN</Text>
											</Col>
											<Col span={3} className="center">
												<Button className="btn btn-edit" 
													onClick={(e) => { this.setState({ModalBitacoraConceptoVisible: true, modalTitle: 'Editar', bitacora_edit: item.conceptos.bitacora_pagos}) }}>
													<IconEdit />
												</Button>
												<Popconfirm
													placement="topRight"
													title={"¿Deseas eliminar el concepto " + item.conceptos?.bitacora_pagos.concepto + " ?"}
													onCancel={e => e.stopPropagation()}
													okText="Si"
													cancelText="No"
													onClick={e => e.stopPropagation()}
													onConfirm={e => {this.deleteRegistro(item.conceptos?.bitacora_pagos._id)}}
												>
													<Button className="btn btn-delete" onClick={e => e.stopPropagation()}>
														<IconDelete />
													</Button>
												</Popconfirm>

											</Col>
										</Row>
									</Card>
								</List.Item>
								)}
						/>
					</div>
					<Row className="mt-2 pb-3">
						<Pagination
							total={this.state.total}
							current={this.state.page}
							onChange={(page) => {
									
								}}
							/>
						</Row>
				</section>
				<Button
					className="btnAddPlus"
					onClick={() => this.setState({ModalBitacoraConceptoVisible: true, modalTitle: 'Añadir'})}>
					<IconPlus/>
				</Button>
				<ModalBitacoraConcepto
					modalVisible={this.state.ModalBitacoraConceptoVisible}
					closeMethod={this.hideModal}
					modalTitle={this.state.modalTitle}
					proforma_id={this.state.proforma_id}
					proforma_concepto_id={this.state.proforma_concepto_id}
					bitacora_edit={this.state.bitacora_edit}
				/>
			</div>
		)
	}
}