import React, { Component } from 'react';
import { Button, Checkbox, Col, DatePicker, Dropdown, Spin, Menu, Radio, Row, Select, Card, Statistic, Typography, List, Avatar } from 'antd'

import echarts from 'echarts';

import ReactEcharts from 'echarts-for-react';
import axios from 'axios';


const { Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;


export default class GraficaNominasPorGrupos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            // nomina_id: null
        }
    }

    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
        this.getProyectos()
    }


    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   componentDidUpdate
    * @description Actualiza el state al recibir nuevos valores del props
    *
    **/
    componentDidUpdate() {
        if (this.state.update !== this.props.update) {
            this.state.update = this.props.update
            this.state.filters = this.props.filters
            this.getProyectos()
        }
    }

    /**
     *
     *
     * @param {*} [{ filters = this.state.filters }={}]
     * @memberof CardEgresosPorProyecto
     * 
     * @method getProyectos Obtenemos los proyectos.
     */
    getProyectos = ({
        filters = this.state.filters
    } = {}) => {
        this.setState({
            loading: true
        })
        axios.post('/dashboard/grafico', filters)
            .then(({ data }) => {
                this.setState({
                    projects: data.data,
                    loading: false
                })
            })
    }


    render() {

        return (
            <Spin spinning={this.state.loading}>
                <ReactEcharts
                    option={
                        {
                            title: {
                                text: 'Ingresos / Egresos por Proyecto',
                                // subtext: '纯属虚构'
                            },
                            tooltip: {
                                trigger: 'axis'
                            },
                            legend: {
                                data: ['Ingreso', 'Egreso']
                            },
                            calculable: true,
                            grid: {
                                containLabel: true,
                                height: 340,
                                left: 10,
                                right: 10,
                            },
                            width: "100%",
                            xAxis: [
                                {
                                    type: 'category',
                                    data: this.state.projects.map(({ nombre }) => nombre),
                                    axisLabel: {
                                        interval: 0,
                                        // rotate: 30 //If the label names are too long you can manage this by rotating the label.
                                    },


                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value'
                                }
                            ],
                            series: [
                                {
                                    name: 'Ingreso',
                                    type: 'bar',
                                    data: this.state.projects.map(({ ingreso }) => ingreso),

                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            { offset: 0, color: '#6E83FF' },
                                            { offset: 0.5, color: '#6E83FF' },
                                            { offset: 1, color: '#192FFF' }
                                        ]
                                    )
                                },
                                {
                                    name: 'Egreso',
                                    type: 'bar',
                                    data: this.state.projects.map(({ egreso }) => egreso),
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            { offset: 0, color: '#FF865E' },
                                            { offset: 0.5, color: '#FF865E' },
                                            { offset: 1, color: '#FFC5B0' }
                                        ]
                                    )
                                },

                            ]
                        }
                    }
                    style={{ height: '400px', width: '100%' }}
                    opts={{ renderer: 'svg' }}
                    className='react_for_echarts' />
            </Spin>
        )
    }
}


