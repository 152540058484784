import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


String.prototype.toMoney = function (toFixed = true, fractionDigits = 2) {
    if (!toFixed)
        return parseFloat(this).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    else
        return parseFloat(this).toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

Number.prototype.toMoney= function (fractionDigits = 2) {
    return this.toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

String.prototype.toUnidad = function () {
    let unidades = {
        'H87': 'Piezas',
        'EA': 'Elementos',
        'KGM': 'Kilogramos',
        'MTR': 'Metros',
        'KT': 'Kits',
        'SET': 'Conjuntos',
        'LTR': 'Litros',
        'XBX': 'Cajas',
        'xun': 'Unidades',
        'GRM': 'Gramos',
        'BUT': 'Bultos',
        'TON': 'Toneladas',
    }
    return unidades[this] ? unidades[this] : 'A/A'
}

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
