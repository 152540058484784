import React, { Component } from 'react';
import { Button, Popconfirm, Space, Row, Progress, Col, Spin, Select, Card, Layout, message, Typography, Form, Collapse, Modal, List, Avatar, Pagination } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

//Componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar'
import { IconEdit, IconEye, IconDelete, IconPlus, IconArrowBack } from '../../../Widgets/Iconos'
//modales
import ModalSubConcepto from './ModalSubConcepto'
//css
import '../../../../Styles/budgeting.css'

const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography
const { Option } = Select
const moment = require('moment')

/**
 * @export
 * @class Conceptos
 * @extends {Component}
 * @description Vista de Conceptos
 */
export default class Conceptos extends Component {

	volver = () => {
        window.history.back();
    }

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			data: [],
			proyectos: [],
			proyecto: {},
			concepto: {},
			page: 1,
			limit: 1,
			total: 0,
			pages: 0,
			proyecto_id: this.props.match.params.proyecto_id,
			concepto_id: this.props.match.params.concepto_id

		}
	}

	refForm = React.createRef()

	componentDidMount() {
		//Mostrar input superior de busqueda 
		//Para acceder al valor del input = this.props.search
		this.props.updateFilterSearch(true);
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		window.scrollTo(0, 0)
		this.getProyecto()
		this.getConcepto()
		this.getSubConceptos()

	}

	/**
    * @memberof ModalSubConcepto
    * @method getConcepto
    * @description Obtiena la informacion del Almacen
    **/
    getConcepto = () => {
    	this.setState({loading: true})
    	axios.get('/conceptos/get',{
    		params:{
    			concepto_id: this.state.concepto_id
    		}
    	}).then(response => {
    		this.setState({concepto: response.data.data})
    		
    	}).catch(error => {
    		let msg = 'Error al obtener el Concepto'
    		if(error.response?.status === 403)
    			msg = error.response.data.message
    		message.error(msg)
    	}).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof Almacenes
    * @method getProyecto
    * @description  Obtiene la informacion del proyecto seleccionado
    **/
    getProyecto = () => {
        axios.get('/proyectos/get',{
            params:{
                id: this.props.match.params.proyecto_id
            }
        }).then(response => {
            this.setState({proyecto: response.data.data})
        }).catch(error => {
            message.error('Error al obtener la información del proyecto')
        })
    }


    /**
    * @memberof Almacenes
    * @method getSubConceptos
    * @description  Obtiene la informacion del proyecto seleccionado
    **/
    getSubConceptos = (page = this.state.page) => {
        axios.get('/sub-conceptos/list',{
            params:{
                proyecto_id: this.props.match.params.proyecto_id,
                concepto_id: this.props.match.params.concepto_id,
                page,
            }
        }).then(response => {
        	console.log("response", response.data.data);
        	this.setState({
        		data: response.data.data.itemsList,
        		page: response.data.data.paginator.currentPage,
        		total: response.data.data.paginator.itemCount,
        	})
            
        }).catch(error => {
            message.error('Error al obtener los Conceptos')
        })
    }





	render() {

		const { proyecto, concepto } = this.state 

		return (
			<div>
				<Spin spinning={false}>
					<Row className="row-encabezado">
						<Col xs={12} xl={15} xxl={15} className="flex-left">
							<Button className="IconArrowBack" onClick={this.volver}>
	                            <IconArrowBack />
	                        </Button>
							<Title level={4}className="view-title">Sub Conceptos de {concepto.nombre}</Title>
						</Col>



					</Row>
					<Content className="admin-content content-bg p-1 budgeting">
						<List
	                        loading={this.state.loading}
	                        className="component-list mt-1 scroll"
	                        itemLayout="horizontal"
	                        dataSource={this.state.data}
	                        renderItem={(item, i) => (

	                            <List.Item className="component-list-item" key={item._id}>
	                                <Card className="card-list">
	                                    <Row style={{ width: '100%' }} className="">
	                                        <Col span={10} className="flex-left">
	                                        	<Text>{item.nombre}</Text>
	                                        </Col>
	                                        <Col span={10} className="flex-left">
	                                        	<Text>$ {item.presupuesto.toMoney()} MXN</Text>
	                                        </Col>
	                                        <Col span={4} className="center">
						                        <Button type="" className="btn btn-edit" title="Editar" onClick={()=>this.setState({modal_visible: true, sub_concepto_id: item._id})}>
						                            <IconEdit />
						                        </Button>
	                                        	<Popconfirm
	                                                placement="topRight"
	                                                title="¿Deseas eliminar este Concepto?"
	                                                onConfirm={() => axios.post('/sub-conceptos/delete', { id: item._id  })
	                                                    .then((response) => {
	                                                        message.success("Eliminado Correctamente")
	                                                        this.getSubConceptos(1)
	                                                    })
	                                                    .catch((error) => {
	                                                    	console.log("error", error);
	                                                        message.error("No es posible eliminar")
	                                                        this.getSubConceptos(this.state.page);
	                                                    })
	                                                }
	                                                okText="Si"
	                                                cancelText="No"
	                                            >
	                                                <Button className="btn btn-delete" icon={<IconDelete />} title="Eliminar" />
	                                            </Popconfirm>
	                                        </Col>

	                                    </Row>
	                                </Card>
	                            </List.Item>
	                        )}
	                    />
	                    <Row className="mt-2 pb-3">
	                        <Pagination
	                            current={this.state.page}
	                            total={this.state.total}
	                            pageSize={this.state.limit}
	                            showSizeChanger={false}
	                            onChange={(page) => {
	                                this.getSubConceptos(page)
	                            }}
	                        />
	                    </Row>
					</Content>	
				</Spin>
				<Button className="btnAddPlus" title="Nuevo registro" onClick={() => this.setState({modal_visible: true})} >
                    <IconPlus />
                </Button>
                <ModalSubConcepto
                	visible={this.state.modal_visible}
                	onCancel={()=>{
                		this.setState({modal_visible: false, sub_concepto_id: undefined})
                		this.getSubConceptos()
                	}}
                	proyecto_id={this.state.proyecto_id}
                	concepto_id={this.state.concepto_id}
                	sub_concepto_id={this.state.sub_concepto_id}
                />
			</div>
			
		)
	}
}