import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Popconfirm, Card, Typography, List, message, Pagination, Tag } from "antd";
import { ReloadOutlined, WarningFilled, MinusOutlined, PlusOutlined, CloseCircleFilled, CheckCircleFilled, AuditOutlined } from '@ant-design/icons'

//componentes
import { IconDelete, IconEdit, IconPlus, IconFlag } from './../../../../Widgets/Iconos';

//css



const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios');
const moment = require('moment');

let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

let unidades = {
    'H87': 'Pieza',
    'EA': 'Elemento',
    'KGM': 'Kilogramo',
    'MTR': 'Metro',
    'KT': 'Kit',
    'SET': 'Conjunto',
    'LTR': 'Litro',
    'XBX': 'Caja',
    'xun': 'Unidad',
    'GRM': 'Gramo',
    'BUT': 'Bulto',
    'TON': 'Tonelada',
}

let estatus = {
    0: 'Requerido',
    1: 'Cotizado',
    2: 'Pausado',
    3: 'Cancelado',
    4: 'Entrega',
    5: 'Recoleccion',
    6: 'Entregado',
}


/**
 * @export
 * @class RequisicionesList
 * @extends {Component}
 * @description Lista de requisisciones del proyecto
 */
export default class RequisicionesList extends Component {


	constructor(props) {
		super(props)
		this.state = {
			requisiciones: [],
			loading: false,
			page: 1,
			limit: 10,
			total: 0,      

		}
	}

	componentDidMount = () => {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		this.getRequisisciones()
	}

	/**
    * @memberof Almacenes
    * @method getRequisisciones
    * @description  Obtiene los productos registrados del almacen
    **/
    getRequisisciones = (page = this.state.page) => {
        axios.get('/requisiciones',{
            params:{
                page: page,
                proyecto_id: this.props.proyecto_id
            }
        }).then(response => {
            console.log("response", response.data.data);
            this.setState({
            	requisiciones: response.data.data.itemsList,
            	page: response.data.data.currentPage,
            	total: response.data.data.itemCount
            })
            

        }).catch(error => {
            message.error('Error al obtener los alamcenes')
        })
    }


	render() {

		return (
			<>
				<Content>
					<Row className="">
						<Col span={24}>
							<List
		                        loading={this.state.loading}
		                        className="component-list"
		                        itemLayout="horizontal"
		                        dataSource={this.state.requisiciones}
		                        locale={{emptyText: 'Sin Requisisciones'}}
		                        header={<Row className="header-list width-100 pr-1 pl-1" >
		                            <Col span={6} className="center">
		                                <Text>Producto</Text>
		                            </Col>
		                            <Col span={4} className="center">
		                                <Text>Estatus</Text>
		                            </Col>
		                            <Col span={3} className="center">
		                                <Text>SKU Manantial</Text>
		                            </Col>
		                            <Col span={2} className="center">
		                                <Text>Volumen</Text>
		                            </Col>
		                            <Col span={3} className="center">
		                                <Text>Unidad</Text>
		                            </Col>
		                            <Col span={3} className="center">
		                                <Text>Prioridad</Text>
		                            </Col>
		                            <Col span={3} className="center">
		                                <Text>Fecha</Text>
		                            </Col>
		                        </Row>
		                        }
		                        renderItem={item => (
		                            <List.Item className="component-list-item">
		                                <Card className="card-list">
		                                    <Row style={{ width: '100%' }} className="">

		                                        <Col xs={12} md={6} className="flex-column">
				                                    <Text>{item.producto_id?.nombre}</Text>
				                                </Col>
				                                <Col xs={12} md={4} className="center">
				                                    <Tag className={`tag-estatus tag-estatus-${item.estatus}`}>{estatus[item.estatus]}</Tag>
				                                </Col>
				                                <Col xs={12} md={3} className="center">
				                                    <Text>{item.producto_id.sku_manantial}</Text>
				                                </Col>
				                                <Col xs={12} md={2} className="center">
				                                    <Text>{item.volumen}</Text>
				                                </Col>
				                                <Col xs={12} md={3} className="center">
				                                    <Text>{unidades[item.producto_id?.unidad] ? unidades[item.producto_id?.unidad] : '-'}</Text>
				                                </Col>
				                                <Col xs={12} md={3} className="center">
				                                    <IconFlag fill={colors_flag[item.prioridad] ? colors_flag[item.prioridad] : '#A4A4A4'}/>
				                                </Col>
				                                <Col xs={12} md={3} className="center">
				                                    <Text className="text-gray">{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
				                                </Col>
		                                    </Row>
		                                </Card>
		                            </List.Item>
		                        )}
		                    />

						</Col>

						<Col span={24}>
							<Pagination
								current={this.state.page}
								total={this.state.total}
								pageSize={10}
								showSizeChanger={false}
								onChange={(page) => this.getRequisisciones(page)}

							/>
						</Col>
					</Row>
				</Content>


			</>
		)
	}
}