import React, {Component} from "react";
import {Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, Upload,} from 'antd';
import './../../../../Styles/modales.css'

import {IconCloseModal} from '../../../Widgets/Iconos';

import {DeleteOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons'
import PropTypes from "prop-types";
import Logged from '../../../../Hooks/Logged';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
// LoadingOutlined

const moment = require('moment');
const axios = require('axios').default;

const {Option} = Select;

const {Title,} = Typography;


class ModalUsuarios extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    static contextType = Logged;


    formModaluser = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},

            proyectos: []

        }
    }


    /**
     * @methodOf ModalUsuarios
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount(): void {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProjects()
    }

    /**
     * @methodOf ModalUsuarios
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualiza una propiedad o un estado.
     *
     * */
    componentDidUpdate(prevProps, prevState, snapshot) {


        //Si la propiedad cambia y es diferente al estado, actualizamos el estado con el ID de la poppiedad y buscamos al usuario
        if (this.props.id !== this.state.id && this.props.id !== undefined) {
            this.state.id = this.props.id
            this.getUser(this.props.id)
        }

        if (this.props.id !== this.state.id && this.state.id !== undefined && this.props.id === undefined) {
            this.state.id = undefined;
            this.state.form = {};
            this.state.image = undefined;
            this.formModaluser.current.resetFields();
        }

    }


    /**
     *
     * @methodOf ModalUsuarios
     *
     * @function getUser
     * @description Obtiene el usuario
     *
     * */
    getUser = (id) => axios.get("/usuarios/get", {
        params: {id}
    })
        .then(async ({data}) => {
            const avatar = data.data.avatar;
            if (data.data.avatar !== undefined && data.data.avatar !== null && data.data.avatar !== "") {
                this.setState({
                    image: {
                        url: axios.defaults.baseURL + '/upload/' + avatar,
                        name: avatar
                    }
                })
                data.data.avatar = [{
                    uid: -1,
                    name: data.data.avatar,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + data.data.avatar,
                    response: {filename: data.data.avatar}
                    // e.file.response.filename
                }];

            } else data.data.avatar = []



            data.data.proyectos_permisos = data.data.proyectos_permisos.map(p => p._id)

            let permisos = [];
            const roles = data.data.roles;
            console.log(roles);

            if (roles.finanzas) permisos.push("finanzas");
            if (roles.crm) permisos.push("crm");
            if (roles.p_manager) permisos.push("p_manager");
            if (roles.legal) permisos.push("legal");
            if (roles.cuentas) permisos.push("cuentas");
            if (roles.areas) permisos.push("areas");
            if (roles.categorias) permisos.push("categorias");
            if (roles.rubros) permisos.push("rubros");
            if (roles.proyectos) permisos.push("proyectos");
            if (roles.usuarios) permisos.push("usuarios");


            if (roles.nominas) permisos.push("nominas");
            if (roles.empleados) permisos.push("empleados");
            if (roles.finanzasnominas) permisos.push("finanzasnominas");

            if (roles.materiales) permisos.push("materiales");
            if (roles.manager) permisos.push("manager");

            if (roles.ventas) permisos.push("ventas");
            if (roles.compras) permisos.push("compras");


            if (roles.bitacora_estatus_avance) permisos.push("bitacora_estatus_avance");
            if (roles.estatus_avance) permisos.push("estatus_avance");


            if (roles.compras) permisos.push("compras");
            if (roles.ordenes) permisos.push("ordenes");
            if (roles.inventario) permisos.push("inventario");
            if (roles.presupuestos) permisos.push("presupuestos");
            if (roles.compras_activate) permisos.push("compras_activate");
            if (roles.financial_health) permisos.push("financial_health");



            data.data.permisos = permisos;

            this.setState({
                form: data.data,
            })
            await this.formModaluser.current.resetFields();
        })
        .catch(res => {
            console.log("no obtenida", res);
        })


    /**
     * @methodOf  Usuarios
     * @function saveUser
     *
     * @description Guardao actualiza el usuario, segun si elID está definido.
     *
     * */
    saveUser = values => {


        this.setState({
            loading: true
        })

        if (this.state.image)
            values.avatar = this.state.image.name;
        else
            values.avatar = null;
        if (values._id) {

            axios.put('/usuarios/update', {...values, id: values._id})
                .then(()=>{
                    message.success("¡Se ha guardado correctamente el usuario!")
                    this.props.accept();
                })
                .catch((e) => {
                    console.log('e', e)
                })
                .finally(() => this.setState({
                    loading: false,
                    image: undefined
                }))
        } else {
            axios.post('/usuarios/add', {...values, id: values._id})
                .then(()=>{
                    message.success("¡Se ha guardado correctamente el usuario!")
                    this.props.accept();
                }).catch((e) => {
                    console.log('e', e)
                })
                .finally(() => this.setState({
                    loading: false,
                    image: undefined
                }))
        }
    }


    /**
     *
     *
     * @memberof ModalUsuarios
     * @method loadProyectos
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    getProjects = () => {
            axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({
                proyectos: proyectos.data.data
            })
        }).catch((error) => {
                console.log('error',error)
        })
    }




    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const {file} = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            if (this.state.image)
                this.removeFile(this.state.image.name)

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })

            if  (this.state.form._id)
                axios.put('/usuarios/update', {avatar: e.file.response.filename, id: this.state.form._id})
                    .finally(()=>{
                        this.props.update()
                    });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !==undefined) ? file.response.filename : file.name)


        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const {image}  = this.state;
        this.removeFile(image.name);
        this.state.form.avatar = [];
        this.state.image = undefined;
        this.formModaluser.current.resetFields();
        if (this.state.form._id)
            axios.put('/usuarios/update', {avatar: 0, id: this.state.form._id})
                .finally(()=>{
                    this.props.update()
                    this.setState({ loadingImage: false })
                })
        else {
            this.props.update()
            this.setState({ loadingImage: false })
        }
    };

    render() {
        const {normFile, formModaluser, saveUser, deleteImage} = this;
        const {visible, hideModal} = this.props;
        const {form, image, loading, id, proyectos, loadingImage} = this.state;

        const user = this.context;


        const option = [
            {
                name: "Administrador de Proyectos",
                value: "p_manager"
            },
            {
                name: "Areas",
                value: "areas"
            },
            {
                name: "Categorias",
                value: "categorias"
            },
            {
                name: "CRM",
                value: "crm"
            },
            {
                name: "Compras",
                value: "compras"
            },
            {
                name: 'Compras Activación',
                value: 'compras_activate'
            },
            {
                name: "Cuentas",
                value: "cuentas"
            },
            {
                name: "Empleados",
                value: "empleados"
            },
            {
                name: "Estatus Avance",
                value: "estatus_avance"
            },
            {
                name: "Finanzas",
                value: "finanzas"
            },
            {
                name: "Finanzas Nominas",
                value: "finanzasnominas"
            },
            {
                name: "Financial Health",
                value: "financial_health"
            },
            {
                name: "Inventario",
                value: "inventario"
            },
            {
                name: "Legal",
                value: "legal"
            },
            {
                name: "Manager",
                value: "manager"
            },
            {
                name: "Nóminas",
                value: "nominas"
            },
            {
                name: "Ordenes Estatus Avance",
                value: "ordenes"
            },
            {
                name: "Presupuestos",
                value: "presupuestos"
            },
            {
                name: "Proyectos",
                value: "proyectos"
            },
            {
                name: "Usuarios",
                value: "usuarios"
            },
            {
                name: "Ventas",
                value: "ventas"
            },


            // compras: (body.permisos.findIndex(item => item === "compras") !== -1),
            // ordenes: (body.permisos.findIndex(item => item === "ordenes") !== -1),
            // inventario: (body.permisos.findIndex(item => item === "inventario") !== -1),


        ];

        return (
            <Modal
                visible={visible}
                onCancel={hideModal}
                title={null}
                footer={null}
                closable={false}
                maskClosable={!loadingImage}
                className="modal-form"
                destroyOnClose={true}
                zIndex={1000}
            >
                <div className="modal-header">
                    <Button type="ghost" title="Cerrar" className="btn-close-modal" onClick={hideModal}>
                        <IconCloseModal/>
                    </Button>
                    <Title level={3} className="modal-title">{id?"Editar Usuario":"Nuevo Usuario"}</Title>
                </div>
                <Spin spinning={loading}>
                    <Form
                        layout="vertical"
                        ref={formModaluser}
                        name="formulario-transacciones"
                        onFinish={saveUser}
                        initialValues={form}
                    >
                        <Form.Item
                            name="_id"
                            noStyle
                        >
                            <Input type="hidden"/>
                        </Form.Item>
                        <Row align="center">
                            <Form.Item
                                name="avatar"
                                align="center"
                                getValueFromEvent={normFile}
                                // valuePropName={props?"fileList":"value"}
                                valuePropName="fileList"
                                help={image ? <Button
                                    shape="circle"
                                    danger style={{position: 'absolute',right: '40%', bottom: '20px' }}
                                    onClick={deleteImage}
                                ><DeleteOutlined /></Button> : null}
                            >
                                <Upload
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    accept="image/*"
                                >

                                    { (loadingImage)? <div>
                                        {(this.state.loading || loadingImage) ? <LoadingOutlined/> : <PlusOutlined/>}
                                        <div className="ant-upload-text">Upload</div>
                                    </div> :  ((image) ?
                                        <img src={image.url} alt="avatar" style={{width: '100%'}}/>
                                        :
                                        <div>
                                            {this.state.loading ? <LoadingOutlined/> : <PlusOutlined/>}
                                            <div className="ant-upload-text">Upload</div>
                                        </div>)}
                                </Upload>
                            </Form.Item>
                        </Row>
                        <Row>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el nombre"
                                    }]}
                                >
                                    <Input placeholder="Nombre"></Input>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={{span: 11, push: 1}}>
                                <Form.Item
                                    label="Apellido"
                                    name="apellido"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese apellido"
                                    }]}
                                >
                                    <Input placeholder="Apellido"></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    label="Posición"
                                    name="posicion"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese posición"
                                    }]}
                                >
                                    <Input placeholder="Posición"></Input>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={{span: 11, push: 1}}>
                                <Form.Item
                                    label="Correo Electrónico"
                                    name="email"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese correo electrónico"
                                    }]}
                                >
                                    <Input placeholder="Correo Electrónico"></Input>
                                </Form.Item>
                            </Col>


                            {(user && user.tipo === 1)?<Col xs={24} >
                                <Form.Item
                                    label="Tipo de Usuario"
                                    name="tipo"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese el tipo de Usuario"
                                    }]}
                                >
                                    <Select>
                                        <Option key="1" value={1}>Dueño</Option>
                                        <Option key="2" value={2}>Administrador</Option>
                                        <Option key="3" value={3}>Usuario</Option>
                                    </Select>
                                </Form.Item>
                            </Col>:null}


                        </Row>
                        <Row>
                            <Col xs={24}>
                                <Form.Item
                                    label="Permisos"
                                    name="permisos"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese permisos"
                                    }]}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Inserted are removed">
                                        {option.map((option, index) => (
                                            <Option key={index} value={option.value}>
                                                {option.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <Form.Item
                                    label="Proyectos"
                                    name="proyectos_permisos"
                                    // rules={[{
                                    //     required: true,
                                    //     message: "Por favor, ingrese permisos"
                                    // }]}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Inserted are removed">
                                        {proyectos.map(function ({_id, nombre, logo, color}, index) {
                                            return <Option value={_id}  style={{margin:'2px 0 2px 0'}}>
                                            <CustomAvatar
                                            image={logo}
                                            name={nombre}
                                            color={color}
                                            size="small"
                                            style={{
                                            marginRight: '5px'
                                        }}
                                            />
                                            {nombre}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{textAlign: "center"}}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-azul" loading={loading || loadingImage}>
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

export default ModalUsuarios;
