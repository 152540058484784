import React, { Component } from "react";
import { Row, Col, Button, Modal, DatePicker, Typography, Spin, Form, Select, Input, InputNumber, List, message } from 'antd';

import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import './../../../../Styles/modales.css'

const { Title } = Typography;
const { Option } = Select;


const axios = require('axios').default;


//Modal para agregar una obra en ProjectAdmin
export default class ModalObra extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            update: true,
            _dataObras: [],
            dataObras: [],
            obras: {},

        }
    }

    modalObra = React.createRef();


    /**
    * @memberof ModalObra
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getObras();

    }

    /**
    * @memberof ModalObra
    *
    * @method   componentDidUpdate
    * @description  Cuando Se detecte un cambio en props manager_proyecto_id, actualiza la lista del select, al igual cuando se cierre 
    *   o abra el modal para siepre tenerlo actualizado
    *
    **/
    componentDidUpdate(prevProps) {
        if(this.props.manager_proyecto_id !== prevProps.manager_proyecto_id){
            this.getObras();
        }
        if (this.props.modalVisible !== prevProps.modalVisible){
            this.getObras();
        }
    }


    /**
    * @memberof ModalObra
    *
    * @method   getObras
    * @description  trae un listado de las obras (inmuebles) para desplgarlos en el select, que no esten siendo usados
    *
    **/
    getObras = (search) => {
        axios.get('/tags', {
            params: {
                page: 1,
                limit: 5000,
                type: "inmueble_id",
                search: search,
                manager_proyecto_id: this.props.manager_proyecto_id,
                proyecto_id: this.props.proyecto_id,
            }
        })
            .then(res => {
                this.setState({ dataObras: res.data.data.itemsList })
            })
            .catch(e =>
                console.log('error inmuebles get', e)
            )
    }

    /**
    * @memberof ModalObra
    *
    * @method   hideModal
    * @description  Cierra el modal por medio del metodo closeMethod() que es pasado por props
    *
    **/
    hideModal = () => {
        this.setState({
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false
        });


        this.props.closeMethod();
    }


    /**
    * @memberof ModalObra
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al guardar el formulario
    *
    **/
    onFinish = (values) => {
        this.setState({loading: true})
        const { accionModal, registroId, proyecto_id, manager_proyecto_id } = this.props;
        if (accionModal === "crear") {
            axios.post('/manager/proyectos/inmuebles/add', {
                inmueble_id: values.obra_id,
                proyecto_id: proyecto_id,
                manager_proyecto_id: manager_proyecto_id
            })
                .then((res) => {
                    message.success("Inmueble Registrado!");
                    this.hideModal();
                    this.props.accept()

                }).catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : 'Error al procesar registro. Verifique la información.'
                    });
                });
        }

        else if (accionModal === "editar") {

            axios.post('/materiales/update', {
                id: registroId,
                nombre: values.nombre,
            })
                .then((res) => {
                    message.success("Material actualizado!");
                    this.setState({
                        datosConsultados: false
                    })
                    setTimeout(this.hideModal(), 1000);

                }).catch((error) => {
                    console.log(error)
                    Modal.warning({
                        title: 'Información de Registro Incorrecta',
                        content: (Array.isArray(error.response.data.error)) ? (
                            <List
                                size="small"
                                bordered
                                dataSource={error.response.data.error}
                                renderItem={field => (
                                    <List.Item>
                                        {field.message}
                                    </List.Item>
                                )}
                            />
                        ) : 'Error al procesar edición de registro. Verifique la información.'
                    });
                });
        }
    }

    render() {
     
        const { accionModal } = this.props;
        return (

            <Modal
                visible={this.props.modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                closable={false}
                className="modal-form modal-obras"
                destroyOnClose={true}
                zIndex={1000}

            >

                <Spin spinning={this.state.loading}>

                    <div className="modal-header">
                        <Button type="ghost" className="btn-close-modal" title="Cerrar" onClick={this.hideModal}>
                            <IconCloseModal />
                        </Button>
                        <Title level={3} className="modal-title"> {(accionModal === "crear") ? "Agregar" : "Editar"} Obra</Title>
                    </div>
                    <Form
                        layout="vertical"
                        className="frm-cuentas"
                        name="formulario-entrega"
                        ref={this.modalObra}
                        onFinish={this.onFinish}
                    >

                        <Row align="center">
                            <Col span={20}>
                                <Form.Item label="Obra" name="obra_id" //<--------------------------------------- verificar nombre
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione una obra."
                                    }]}
                                >
                                    <Select clearIcon={<IconArrowSelect />}
                                        showSearch
                                        filterOption={false}
                                        onSearch={search => this.getObras(search)}
                                    >
                                        {this.state.dataObras.map(function ({ _id, nombre, }, index) {
                                            return <Option value={_id}> {nombre} </Option>
                                        })}

                                    </Select>
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row style={{ textAlign: "center" }}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button htmlType="submit" type="primary" className="btn-azul" >
                                        Guardar
                                </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Spin>
            </Modal>
        )
    }
}
