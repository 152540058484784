import React, { Component } from 'react';
import { Button, Space, Row, Checkbox, Col, Spin, Select, Card, Layout, message, Typography, Form, Modal, List, Tag, Popconfirm } from 'antd'
import axios from 'axios';
import CustomAvatar from '../../Widgets/Avatar/Avatar'
import { IconDetails, IconPlus, IconArrowBack, IconDelete } from '../../Widgets/Iconos';
import { PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, } from "@ant-design/icons";

import ModalTransaccion from '../Modales/Finanzas/ModalTransaccion';
import { CardConcepto, CardPresupuestoSimple, CardProyecto } from '../../Widgets/Cards/Cards';

import '../../../Styles/budgeting.css'
import ModalTran from '../Modales/Finanzas/ModalTransacciónDetalle';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'


const { Content } = Layout;
const { Text, Title } = Typography
const { Option } = Select
const moment = require('moment')

/**
 * @export
 * @class Budgeting
 * @extends {Component}
 * @description Vista de Budgeting
 */
export default class Budgeting extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			proyectos: [],
			proyecto: {},
			concepto: {},
			transacciones: {
				data: [],
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
				filters: null
			},
			sub_conceptos: {
				data: [],
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
			},
			total_subconceptos: 0,
			presupuesto: {
				presupuesto: 0,
				fecha: new Date(),
				ejercido: 0

			},
			modal_detalle_visible: false,
			concepto_id: this.props.match.params.concepto_id,
			sub_concepto_id: this.props.match.params.sub_concepto_id
		}
	}

	refForm = React.createRef()
	drawerFiltros = React.createRef()

	componentDidMount() {
		//Mostrar input superior de busqueda 
		//Para acceder al valor del input = this.props.search
		this.props.updateFilterSearch(true);
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		console.log('props route', this.props)
		window.scrollTo(0, 0)

		this.getProyectos()

		if (this.props.match.params.sub_concepto_id) {
			this.setState({
				transacciones: {
					filters: [{
						_id: this.props.match.params.sub_concepto_id,
						name: 'conceptos.sub_concepto_id',
						objectName: 'sub_conceptos'
					}]
				}
			})
		}
		this.getConcepto()
	}


	/**
	* @memberof Budgeting
	* @function getProyectos
	*
	* @description Obtener proyectos.
	*/
	getProyectos = (search) => {
		axios.get('/proyectos', {
			params: {
				pagination: false,
				search,
			}
		}).then((proyectos) => {
			this.setState({ proyectos: proyectos.data.data })
			if (proyectos.data.data.length > 0) {
				this.selectProyecto(proyectos.data.data[0]._id)
				this.refForm.current.setFieldsValue({
					proyecto_id: proyectos.data.data[0]._id
				})
			}

		}).catch((error) => {
			Modal.error({
				title: "No es posible obtener los proyectos.",
				content: "No fue posible obtener los proyectos."
			})
		})
	}

	/**
	* @memberof Conceptos
	* @method selectProyecto
	* @description  Obtiene la informacion del proyecto seleccionado
	**/
	selectProyecto = (proyecto_id) => {
		axios.get('/proyectos/get', {
			params: {
				id: proyecto_id
			}
		}).then(response => {
			this.setState({ proyecto: response.data.data })
			this.getPresupuesto(proyecto_id)
			 this.getTransacciones(proyecto_id)

		}).catch(error => {
			message.error('Error al obtener la información del proyecto')
		})
	}

	/**
		* @memberof Transacciones
		* @method getTransacciones
		* @description Obtiene todas todas las transacciones
		* @param page página actual de la lista
		* @param limit cantidad de elementos por página
		* @param filters Filtros a aplicar a la página
		* @param sort Ordenar elementos
		* 
		*/
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		filters = this.state.transacciones.filters,
		sort = this.state.transacciones.sort,
		concepto_id = this.props.match.params.concepto_id,
		search = this.state.search
	} = this.state.transacciones) => {
		this.setState({ search, transacciones: { ...this.state.transacciones, page, limit, filters, sort, loading: true } });
		axios.post('/transacciones', {
			page,
			limit,
			filters,
			sort,
			search,
			concepto_id,
		})
			.then(({ data }) => {
				//console.clear()
				console.log("res trans", data)

				data = data.data;

				this.setState({
					transacciones: {
						...this.state.transacciones,
						data: data.data,
						total: data.total,
						pages: data.pages,
						loading: false
					}
				});
			})
			.catch(error => {
				console.log('error', error)
				message.error('No se pudieron cargar las transacciones')
			})
			.finally(() => this.setState({ loading: false }))
	}


	/**
	 *
	 *
	 * @memberof Budgeting
	 */
	getPresupuesto = () => {
		axios.get('/conceptos/proyecto/presupuesto',
			{
				params: {
					proyecto_id: this.state.proyecto._id,
				}
			})
			.then(res => {
				console.log('presupuesto card', res)
				let data = res.data;
				let presupuesto = {
					presupuesto: data?.presupuesto?.presupuesto.presupuesto,
					ultima_transaccion: data?.ultimo_registro,
					ejercido: data?.presupuesto.ejercido,
					porcentaje: data?.presupuesto.porcentaje
				}
				this.setState({ presupuesto })
			})
			.catch(error => {
				console.log('error presupuesto', error)
			})
	}


	/**
	 *
	 *
	 * @memberof Budgeting
	 */
	getConcepto = () => {
		axios.get('/conceptos/get', {
			params: {
				concepto_id: this.props.match.params.concepto_id,
			}
		}).then(res => {
			this.setState({
				concepto: res.data.data.concepto,
				total_subconceptos: res.data.data.sub_conceptos_total
			})
			this.refForm.current.setFieldsValue({
				proyecto_id: res.data.data.proyecto_id
			})
				.catch(error => console.log('error', error))
		})
	}


	back = () => {
		window.history.back();
	}

	render() {

		return (
			<Spin spinning={false}>
				<Row align='middle' className="row-encabezado">
					<Col xs={1} xl={1} xxl={1} className="flex-left"><Button className="IconArrowBack" onClick={this.back} >
						<IconArrowBack />
					</Button>
					</Col>
					<Col xs={11} xl={14} xxl={18} className="flex-left">
						<Title level={4} className="view-title">Budgeting</Title>
					</Col>
					<Col xs={11} xl={6} xxl={4} className="center modal-form">
						<Form className="width-100" layout="vertical" ref={this.refForm} >
							<Row >
								<Col span={22} className="m-0">
									<Form.Item className="m-0" name="proyecto_id">
										<Select
											className="form-select"
											onSearch={(search) => this.getProyectos(search)}
											onSelect={(proyecto_id) => this.selectProyecto(proyecto_id)}
											showSearch
											disabled
											filterOption={false}
											placeholder="Selecciona un Proyecto"
										>
											{this.state.proyectos.map((proyecto) => <Option value={proyecto._id}>
												<CustomAvatar
													image={proyecto.logo}
													name={proyecto.nombre}
													color={proyecto.color}
													size="small"
													style={{
														marginRight: '5px'
													}}
												/>
												{proyecto.nombre}
											</Option>)}
										</Select>
									</Form.Item>
								</Col>

							</Row>
						</Form>
					</Col>
					<Col xs={1} xl={1} xxl={1} flex="flex-right">
						<Button
							style={{ backgroundColor: '#007BFF', marginRight: "1em" }}
							onClick={() => this.setState({ visibleFilters: true })}
							icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />}
						/>
					</Col>

				</Row>
				<Content className="admin-content content-bg p-1 budgeting">
					<Row gutter={[16, 16]}>
						<Col span={8} className="grid">
							<CardProyecto
								image={this.state.proyecto.logo}
								fecha={this.state.proyecto.createdAt}
								color={this.state.proyecto.color}
								nombre={this.state.proyecto?.nombre} />
						</Col>
						<Col span={8} className="grid">
							<CardConcepto
								concepto={this.state.concepto.nombre}
								subconcepto={this.state.total_subconceptos} />
						</Col>
						<Col span={8} className="grid">
							<CardPresupuestoSimple
								presupuesto={this.state.concepto?.presupuesto}
								fecha={this.state.presupuesto?.ultima_transaccion?.fecha}
								ejercido={this.state.presupuesto?.ejercido}
								porcentaje={this.state.presupuesto.porcentaje}
							/>
						</Col>
					</Row>
					<List
						loading={this.state.transacciones.loading}
						className="component-list mt-1 scroll"
						itemLayout="horizontal"
						dataSource={this.state.transacciones.data}
						renderItem={(item, i) => (
							<List.Item className="component-list-item" key={item._id}>
								<Card className="card-list">
									<Row className="width-100" className="">
										<Col span={1} className="center">
											<Checkbox />
										</Col>
										<Col span={2} className="center">
											{moment(item.fecha).format('DD-MM-YYYY')}
										</Col>
										<Col span={3} className="center">
											<Text ellipsis>{item.concepto}</Text>
										</Col>
										<Col span={3} className="center">
											<Text ellipsis>{item.rubro_id.nombre}</Text>
										</Col>
										<Col span={3} className="center">
											<Text ellipsis>{item.sub_rubro_id.nombre}</Text>
										</Col>
										<Col span={4} className="center">
											<Tag color="orange" className='tag-concepto'>[{item.concepto_id.nombre}] > [{item.sub_concepto_id.nombre}]</Tag>
										</Col>
										<Col span={3} className="center">
											<Text ellipsis>{item.cuenta_id?.nombre}</Text>
										</Col>
										<Col span={3} className="center">
											<Text ellipsis strong>$ {item.monto.toMoney(true)} MXN</Text>
										</Col>

										<Col span={2} className="center">
											<Space>
												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta Transacción?"
													onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
														message.success('Transaccion Eliminada')
														this.getTransacciones()
														this.getPresupuesto()
													}).catch((error) => {
														message.success('Transacción NO Eliminada')
													})
													}
													okText="Si"
													cancelText="No"
												>
													<Button type="primary" className="btn ant-btn" danger icon={<IconDelete />} title="Eliminar" />
												</Popconfirm>
												<Button
													type=""
													ghost
													className="detTable-icon"
													title="Ver detalles"
													icon={<IconDetails />}
													onClick={() => this.setState({ modal_detalle_visible: true, transaccion_id: item._id })} />
											</Space>
										</Col>

									</Row>
								</Card>
							</List.Item>
						)}
					/>

				</Content>
				<Button className="btnAddPlus" onClick={() => this.setState({ modal_visible: true, transaccion_id: null })}>
					<IconPlus />
				</Button>
				<ModalTransaccion
					ref={this.modalTransaccion}
					modalVisible={this.state.modal_visible}
					accionModal={this.state.accionModal}
					registroId={this.state.transaccion_id}
					closeMethod={() => this.setState({ modal_visible: false })}
					budgeting={true}
					accept={() => {
						this.setState({ modal_visible: false, transaccion_id: null });
						this.getTransacciones();
					}}
				>
				</ModalTransaccion>
				<ModalTran
					modalVisible={this.state.modal_detalle_visible}
					registroId={this.state.transaccion_id}
					proyecto={this.state.proyecto}
					closeMethod={
						() => this.setState({ modal_detalle_visible: false, transaccion_id: null })
					}
					accept={() => {
						this.setState({ modal_detalle_visible: false, transaccion_id: null })
						this.getTransacciones();
					}}
				/>
				<DrawerFiltros
					title="Filtrar Transacciones"
					ref={ref => this.drawerFiltros = ref}
					updateFilters={filters => this.getTransacciones({
						page: 1,
						filters
					})}
					csv="transacciones/list"
					onClose={() => this.setState({ visibleFilters: false })}
					visible={this.state.visibleFilters}
					proyecto_id={this.state.proyecto._id}
					concepto_id={this.props.match.params.concepto_id}
					cuentas={false}
					clientes={false} 
					tipos={false}
					proyectos={false}
					areas={false}
					rubros={false}
					subrubros={false}
					proveedores={false}
				/>

			</Spin>
		)
	}
}