import React, { Component } from 'react';
import { Typography, List ,Statistic} from 'antd';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";

const { Text } = Typography;
const moment = require('moment')

/**
 *
 *
 * @export
 * @class ListaPagosEmpleados
 * @extends {Component}
 * @descripcion Lista de Pagos por Empleado
 */
export default class ListaPagosEmpleados extends Component {


    /**
     *Creates an instance of ListaPagosEmpleados.
     * @param {*} props
     * @memberof ListaPagosEmpleados
     */
    constructor(props) {
        super(props)
        this.state = {
            inmuebles: [],
            datosFiltrados: [],
            modalGrupoVisible: false,
            grupoView: [],
        }
    }

    /**
    * @memberof ListaPagosEmpleados
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los almacena en el state
    *
    **/
    componentDidMount() {

        this.setState({ empleados: this.props.empleados })
    }

    /**
    * @memberof ListaPagosEmpleados
    *
    * @method   componentDidUpdate
    * @description Actualiza el state al recibir nuevos valores del props
    *
    **/
    componentDidUpdate(prevProps) {
        if (this.props.empleados !== prevProps.empleados) {
            this.setState({ empleados: this.props.empleados })
        }
    }



    /**
    * @memberof ListaPagosEmpleados
    *
    * @method   modalToogle
    * @description Cambia el state para abrir el modal y mandarle la info del elemento seleccionado
    *
    **/
    modalToogle = (item) => {
        let modalvisible = this.state.modalGrupoVisible;
        this.setState({ modalGrupoVisible: !modalvisible, grupoView: item })
    }




    render() {
        return (
            <>
                <List
                    style={{ width: '100%', maxHeight: '203px', overflow: ' auto' }}
                    itemLayout="horizontal"
                    dataSource={this.state.empleados}
                    className="list"
                    locale={{ emptyText: 'Sin Pagos Registrados' }}
                    renderItem={item => (
                        <List.Item className="list-item hover-effect" >
                            <List.Item.Meta
                                avatar={<CustomAvatar
                                    image={item?.empleado?.nombre.concat(' ', item?.empleado?.apellido) ? item?.empleado?.nombre.concat(' ', item?.empleado?.apellido) : ''}
                                    name={[item?.empleado?.nombre.concat(' ', item?.empleado?.apellido) ? item?.empleado?.nombre.concat(' ', item?.empleado?.apellido) : 'na']}
                                    size="large"
                                    style={{ marginRight: '5px' }}
                                />}
                                title={<a className="item-title"><strong>{item.empleado.nombre.concat(' ', item.empleado.apellido)}</strong></a>}
                                description={<Text className="item-description">{moment(item.fecha).format('DD-MM-YYYY')}</Text>}
                            />
                            {console.log('item', item)}
                            <Statistic
                                className="text-right"
                                valueStyle={{ color: this.props.color }}
                                value={parseFloat((item.modalidad === 1)? item.salario : item.registrado_finanzas ? item.monto_pagado : item.monto_autorizado).toFixed(2)}
                                prefix={'$'}
                            />
                        </List.Item>
                    )}
                />
            </>
        )
    }
}
